import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
import { jsPDF } from 'jspdf'
import { ReportService } from '../services/report.service';
@Component({
  selector: 'app-hr-document-upload',
  templateUrl: './hr-document-upload.component.html',
  styleUrls: ['./hr-document-upload.component.scss']
})
export class HrDocumentUploadComponent implements OnInit {
  // @Input() selectedAppt:any;
  @Output() close: EventEmitter<any>;
  @Input() selectedPatient: any;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Input() uploadType: any;
  @Input() appointment: any;

  public uploadForm: UntypedFormGroup;
  public reportType: any;
  public selectOptions = { name: 'value', value: 'code', choose: 'value', locale: true, key: 'name' }
  public reportTypeList: any;
  public search: any;
  public selectedPatient1: any;
  public imgURL: Array<any>=[];
  public canUpload: boolean = true;
  public isShare: boolean = true;
  public accept: string = 'image/jpeg,image/png,application/pdf';
  public curDate: string;
  public shareDoctorRadioOptions: object;
  public shareDoctorRadioList: Array<object>;

  constructor(private formBuilder: UntypedFormBuilder, private user: UserService, private reportService: ReportService) { 
    this.close = new EventEmitter<any>();
    this.uploadForm = new UntypedFormGroup({});
    this.reportType = {
      show: false,
      search: '',
      selected: {},
      prevent: false
    };
    this.reportTypeList = [];
    this.curDate = this.user.getMoment().format('YYYY-MM-DD');
    // this.curDate = this.user.getMoment().format('YYYY-MMM-DD');
    this.selectedPatient1 = this.selectedPatient;
    // console.log("selectedPatient1", this.selectedPatient)
    this.shareDoctorRadioOptions = { name: 'name', value: 'value' };
    this.shareDoctorRadioList = [{ name: 'Yes', checked: true, value: 'Y'}, { name: 'No', checked: false, value: 'N'}];
  }

  ngOnInit(): void {
    this.selectedPatient1 = this.selectedPatient;
    // this.selectedPatient1 = this.selectedPatient.personname ? this.selectedPatient.personname : this.selectedPatient.personName;
    console.log("selectedPatient1",this.selectedPatient);
    this.uploadForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('', [Validators.required]),
      practAccess: new UntypedFormControl('', [Validators.required]),
      date: new UntypedFormControl('', [Validators.required, this.ageRangeValidator])
    });
    // this.updateForm(this.user.getMoment().format('DD-MM-YYYY'), 'date')
    this.updateForm(this.user.getMoment().format('DD-MMM-YYYY'), 'date')
    this.user.getMasterConfig.subscribe(data => {
      let list = data.list;
      let reportTypeList = list.find(arr => {
        return arr.domain == 'REPORT_TYPE'
      })
      let allowedReportTypes = []
      if (reportTypeList && this.uploadType == 'OTH') {
        allowedReportTypes = ['LAB', 'RAD', 'OTH', 'CLN']
      }
      else if (reportTypeList && this.uploadType == 'PRE') {
        allowedReportTypes = ['PRE']
      }
      else if (reportTypeList) {
        allowedReportTypes = allowedReportTypes = ['LAB', 'RAD', 'OTH', 'CLN']
      }
      if (allowedReportTypes.length && reportTypeList && reportTypeList.codes) {
        this.reportTypeList = reportTypeList.codes.filter(type => { 
          return allowedReportTypes.includes(type.code);
        })
        console.log('reportTypeList', this.reportTypeList);
        if (this.reportTypeList?.length) this.selectReportType(this.reportTypeList[0])
      }

    });
  }
  
  private ageRangeValidator = (control: AbstractControl): { futureDate: boolean } => {
    let today = this.user.getMoment().format('DD-MMM-YYYY');
    let selectedDate = this.user.getMoment(control.value, 'DD-MMM-YYYY')
    if ( selectedDate.isAfter(this.user.getMoment(today,'DD-MMM-YYYY')) ) {
            return { futureDate : true };
        }
        return null; 
  };
  public closeModel() {
    this.close.emit();
  }
  public isRequiredField(field: string) {
    let form_field = this.uploadForm.get(field);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  public choosePatient(patient) {
    this.selectedPatient1 = patient;
  }

  public selectReportType(value, text?) {
    if(!value && text) {
      value = this.reportTypeList.find(r =>  r['value'].some(({name}) => name == text))
    }
    if (value) {
      this.reportType = {
        show: false,
        search: value['value'],
        selected: value
      }
      this.updateForm(value.code, 'type');
    } else {
      this.reportType = {
        show: false,
        search: this.reportType['selected']['value'] ? this.reportType['selected']['value'] : '',
        selected: this.reportType.selected
      }
    }
  }
  public updateForm(value, key) {
    this.uploadForm.patchValue({
      [key]: value
    });
  }
  public reportDateChange(event) {
    console.log("event['target']",event['target']['__data'].value)
    this.uploadForm.get('date').setValidators(this.ageRangeValidator);
    this.uploadForm.get('date').markAsDirty();
    this.updateForm(this.user.getMoment(event['target']['__data'].value).format('DD-MMM-YYYY'), 'date');
  }

  public preview(files, event) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    const fileName = files[0].name;
    if (!(mimeType == 'image/jpeg' || mimeType == 'image/png' || mimeType == 'application/pdf')) {
      return
    }
    if (mimeType == 'application/pdf') {
      this.canUpload = false;
    } else {
      this.accept = 'image/jpeg,image/png';
    }
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL.push({
        img: reader.result,
        file: files[0],
        name: fileName
      });
    }
    this.fileUpload.nativeElement.value = "";
  }
  get canContinue() {
    return this.imgURL.length>0&&this.uploadForm.valid
  }
  public initUpload() {
    if (this.canUpload) {
      var doc = new jsPDF('p', 'pt', 'a4');
            var width = doc.internal.pageSize.width;    
            var height = doc.internal.pageSize.height;
            var h1=20;
            var aspectwidth1= (height+100)*(9/16);
      // var doc = new jsPDF(undefined, null, null, true);
      this.imgURL.forEach((img, i) => {
        if (i != 0) {
          doc.addPage();
        }
        let imgData = img.img;
        imgData = imgData.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
        imgData = "data:image/JPEG;base64," + imgData;
        // doc.addImage(imgData, 'JPEG', 10, 10, 0, 0, null, 'FAST', 0);
        doc.addImage(imgData, 'JPEG', 10, 10, (aspectwidth1), (height-h1), null, 'FAST', 0);
      });
      let uristring = doc.output('datauristring');
      uristring = uristring.replace('filename=generated.pdf;', '')
      this.startUpload(uristring);
    } else {
      let uristring = this.imgURL[0].img;
      this.startUpload(uristring);
    }
  }
  private startUpload(pdf) {
    let reportDate = this.uploadForm.get('date').value;
    let params: any = {
      'filename': 'random.pdf',
      'patientId': this.selectedPatient1.uhid,
      'category': this.uploadForm.get('type').value,
      'reportDate': moment(reportDate, 'DD-MM-YYYY').format('DD-MMM-YYYY'),
      'note': this.uploadForm.get('name').value,
      'practAccess': this.uploadForm.get('practAccess').value,
      'orderedBy':this.user.getPersonName()
    };
    if (this.appointment) {
      params['apptRefno'] = this.appointment['appointmentId'];
      params['facilityId'] = this.appointment['facilityId'];
    }
    this.reportService.uploadDocs(pdf, params).subscribe(data => {
      this.closeModel();
    })
  }
  public removeFile(index) {
    this.imgURL.splice(index, 1);
    if (this.imgURL.length == 0) {
      this.canUpload = true;
      this.accept = 'image/jpeg,image/png,application/pdf';
    }
  }
}
