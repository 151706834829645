import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { PaymentService } from '../services/payment.service';
import { AppointmentService } from '../services/appointment.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { POINTSTYPE, apptFlowType } from '@type';
import { CouponService } from '../services/coupon.service';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { GoogleTagsService } from '../services/google-tags.service';
import * as _ from 'lodash';
import { COUPON_PARAMS, POINTSTYPEDEFAULT, PROMO_PARAMS } from '../app.const';
import { EventsService } from '../services/events.service';
import { EXPRESS_CARE_TERMS_COND } from '@string';
import { AltpaymentInputComponent } from '../components/altpayment-input/altpayment-input.component';

@Component({
  selector: 'app-express-clinic-appointment-confirm',
  templateUrl: './express-clinic-appointment-confirm.component.html',
  styleUrls: ['./express-clinic-appointment-confirm.component.scss'],
  providers: [TranslatePipe]
})
export class ExpressClinicAppointmentConfirmComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Input() flowType: apptFlowType;
  @Input() couponData: any;
  @Input() promoData: any;
  public isCouponApplied: boolean;
  public couponCode: string;
  public practDetails: object;
  public totalServiceTime: any;
  public slotDate: any;
  public currency_code: string;
  public patient: object;
  public selectedServices: Array<object>;
  public serviceToOrder: Array<object>;
  public paymentGatewayUrl: string;
  public isTermsAndConditionAccepted: boolean;
  public practitioner: any;
  public canPay: boolean;
  public payLater: boolean;
  public apiUrl: string = environment['API_URL'];
  public lang: string;
  public isHelpEnabled: boolean;
  public clinicMobileNumber: any;
  public position: object;
  public clinicLatLng: object;
  public inputObj: object;
  public appServiceCost: any;
  public serviceInfo: any;
  public hideRefundPolicyInExpress: boolean;

  public isPromoApplied: boolean = false;
  public promoCode: string = '';
  public isFlip: boolean = false;
  public couponDiscount: number = 0;
  public promoDiscount: number = 0;
  public payableCharge: number;
  public hasDiscount: boolean = false;
  public promoPayable: any;
  public promoInput: any;
  public payType: string = 'C';
  public canContinue: boolean = false;
  public isEnablePromo: boolean;
  public isEnableCoupon: boolean;
  public isEnablePoints: boolean;
  public appliedpromocodedtls: any;
  public appliedcouponcodedtls: any;
  public couponTitleArr: any = [];
  public promoApplicableExpService: any = [];
  public totalCostForPromo: number;
  public couponInput: any;
  public enterReferral: boolean;
  public isReferralApplicable: boolean = false;
  public referralCode: any;
  public referralControl: any;
  public promoParams: any;
  public promoInputs: any;
  public couponParams: any = COUPON_PARAMS;
  public showBlockSlotPrompt: any;
  public fromSlotBlockPrompt: boolean = false;
  public showCoupon: boolean = false;

  //New flow
  public chargeDetails: any = {};
  public isApplyingCode: boolean = false;
  public selections: any;

  @ViewChild('coupon', { static: false }) coupon: AltpaymentInputComponent;
  @ViewChild('points', { static: false }) points: AltpaymentInputComponent;

	public readonly pointsType: POINTSTYPE = POINTSTYPEDEFAULT
  public chargeVatDetails: any;
	public chargeVatInfo: any;

  constructor(private user: UserService, private paymentService: PaymentService, private apptService: AppointmentService, private translatePipe: TranslatePipe, private couponService: CouponService, private pracDetailsPipe: PartitionerDetailsPipe, private gtmService: GoogleTagsService, private events: EventsService, private cdf: ChangeDetectorRef) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.selectedServices = new Array<object>();
    this.serviceToOrder = new Array<object>();
    this.isCouponApplied = false;
    this.isTermsAndConditionAccepted = false;
  }

  ngOnInit() {
    console.log('input EC', this._input)
    this.user.getConfigurationInfo.subscribe(data => {
      this.isEnablePromo = data['pxAppSettings'] && data['pxAppSettings']['enablePromoYn'] == 'Y' ? true : false;
      this.isEnableCoupon = data['pxAppSettings'] && data['pxAppSettings']['enableCouponYn'] == 'Y' ? true : false;
      this.isEnablePoints = data['pxAppSettings'] && data['pxAppSettings']['enablePointsYn'] == 'Y' ? true : false;
      this.isReferralApplicable = data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['canReferForEC'] == true;
      this.referralControl = data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['controls'];
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl'];
      this.hideRefundPolicyInExpress = data['pxAppSettings'] && data['pxAppSettings']['hideRefundPolicyInExpressYn'] === 'Y';
    })
    this.user.getUpdateInfo('directives').subscribe(data => {
      if (data.userDefined.find(def => { def.code == 'help' })) {
        this.isHelpEnabled = true;
      }
    });
    if (this._input['in']['coupon'])
      this.isCouponApplied = true;
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.lang = this.user.currentLanguage;
    this.mapDefaultParams()
    this.modifyStructForServicesToOrder();
    if (this.flowType != 'RESCHEDULE') {
      this.checkConfig();
      this.constructInputObject();
    }
    this.getAppSerCost()
  }
  private mapDefaultParams() {
    this.practDetails = this._input['in']['inputService']['practDetails'][0];
    this.totalServiceTime = moment.duration(this._input['in']['service']['totalTime'], 'minutes');
    this.slotDate = this.user.getMoment(this._input['in']['slot']['date']).locale(this.user.currentLocale).format('DD MMM YYYY');
    this.patient = this._input['in']['patient'];
    this.selectedServices = Object.assign([], this._input['in']['service']['services']);
    this.serviceInfo = Object.assign({}, this._input['in']['service']);
    this.clinicMobileNumber = this._input['in']['facility']['mobileNo'];
    this.position = this._input['in']['facility']['position'];
    this.clinicLatLng = this._input['in']['facility']['facilityLocation'];

    this.practitioner = this.user.findPractitioner(this._input['in']['facility']['facilityId'], this.practDetails['practitionerId']);
    this.selections = {
      facility: {
        id: this._input['in']['facility']['facilityId'],
        ...this._input['in']['facility']['facilityId']
      },
      patient: {
        id: this._input['in']['patient']['id'],
        ...this._input['in']['patient']
      },
      practitioner: {
        id: this.practDetails['practitionerId'],
        ...this.practDetails
      }
    }
  }
  public modifyStructForServicesToOrder() {
    this.selectedServices.map(data => {
      if (this.serviceToOrder.find(service => service['directiveCode'] == data['directiveCode'])) {
        let addedService = this.serviceToOrder.find(service => service['directiveCode'] == data['directiveCode']);
        let concept = {
          code: data['conceptCode'],
          desc: data['conceptName'],
          localNames: data['details']['localNames'],
          serviceTime: data['details']['servicetime'],
          serviceCost: data['details']['servicecost'],
          serviceHm: data['details']['servicetimehm']
        }
        addedService['concepts'].push(concept);
      }
      else {
        let obj = {
          directiveCode: data['directiveCode'],
          directiveDesc: data['directiveName'],
          concepts: []
        }
        let concept = {
          code: data['conceptCode'],
          desc: data['conceptName'],
          localNames: data['details']['localNames'],
          serviceTime: data['details']['servicetime'],
          serviceCost: data['details']['servicecost'],
          serviceHm: data['details']['servicetimehm']
        }
        obj['concepts'].push(concept);
        this.serviceToOrder.push(obj);
      }
    })
  }
  public checkConfig() {
    if (!this.practitioner || !this.practitioner['schedulerControls']) {
      this.canPay = false;
      this.payLater = false;
      return;
    }
    if (this.practitioner['schedulerControls']['payableOnline'] == 'true')
      this.canPay = true;
    if (this.practitioner['schedulerControls']['optionalPay'] == 'Y')
      this.payLater = true;
    
  }
  public onTextInput(ev?: any) {
    let regexValidation = this.referralControl.regex;
    if (regexValidation) {
      const regex = new RegExp(regexValidation, 'g')
      let value = ev.target.value;
      let newVal = value.replace(regex, '');
      ev.target.value = newVal;
      this.referralCode = newVal;
    }
  }
  public switchAction(type) {
    this.action.emit({ name: type })
  }
  public navigateUser() {
    this.user.openLocation("https://maps.google.com/?saddr=" + this.position['lat'] + "," + this.position['lng'] + "&daddr=" + this.clinicLatLng['lat'] + "," + this.clinicLatLng['lng']);
  }
  public call() {
    this.user.openLocation("tel:" + this.clinicMobileNumber);
  }
  public opentc() {
    this.events.on(EXPRESS_CARE_TERMS_COND, _ => {
      this.isTermsAndConditionAccepted = true;
    });
    this.action.emit({ name: 'opentc' })
  }
  public openFaq() {
    this.action.emit({ name: 'openfaq' })
  }
  public completeSelection(data) {
    this.complete.emit({ confirm: data });
  }
  public getServiceName(data, defaultName) {
    let names = data['localNames'] ? data['localNames']['names'] : null;
    if (!names || names.length == 0) {
      return defaultName ? defaultName : '';
    } else {
      let t = names.find(name => {
        return name.locale == this.user.currentLanguage
      })
      if (t) {
        return t.name;
      } else {
        return names[0].name;
      }
    }
  }
  public openSlotBlockPrompt(err) {
    this.showBlockSlotPrompt = true;
  }
  public goBackSlotBlockPrompt() {
    this.showBlockSlotPrompt = false;
    this.fromSlotBlockPrompt = true;
  }
  public goBackToSlotView() {
    this.showBlockSlotPrompt = false;
    this.fromSlotBlockPrompt = true;
    this.apptService.setSlotBlockRouteInfo(true)
    this.action.emit({ name: 'choose_slot' });
  }
  public getAppSerCost() {
    const serviceDtls = (this._input['in']['service']['services'] || []).map(ser => ser.conceptCode).join(",");
    const payload = {
      patientId: this._input['in']['patient']['id'],
      facilityId: this._input['in']['facility']['facilityId'],
      patientClass: "OP",
      orderCatalogCode: serviceDtls,
      billingGroupId: null,
      insuranceFinDtls: null,
      locale: this.user.currentLanguage
    }
    this.apptService.getAppointmentServiceCost(payload).subscribe(response => {
      const data = response.data
      let returnedConcept = data.patientPayableAmount;
      let chargeArr = data.charge
      if (data['disableCoupon']) {
        this.isEnableCoupon = false
      }
      if (data['disablePoints']) {
        this.isEnablePoints = false
      }
      let applicableService = []
      let promoApplicableService = data.discount;
      this.promoApplicableExpService = promoApplicableService

      if (promoApplicableService && promoApplicableService.length > 0) {
        promoApplicableService = promoApplicableService.filter(element => {
          if (element['discountApplicable'] == 'Y') {
            applicableService.push(element.code)
          }
          return element['discountApplicable'] == 'Y'
        })
        this.selectedServices = this.selectedServices.map(service => {
          if (applicableService.includes(service['conceptCode'])) {
            service['details']['discountApplicable'] = "Y"
          }
          let concept = chargeArr.find(d => d.code == service['conceptCode']);
          service['details']['chargeStr'] = concept ? concept['chargeStr'] : "";
          return service;
        })
      }
      if (this.selectedServices.length == 1) {
        this.selectedServices[0]['details']['servicecost'] = returnedConcept;
      } else {
        this.selectedServices = this.selectedServices.map(service => {
          let concept = returnedConcept.find(d => d.code == service['conceptCode']);
          service['details']['servicecost'] = concept ? Number(concept['charge']) : service['details']['servicecost'];
          return service;
        })
      }

      this.updateSelectedServices(data);
    })
  }

  public updateSelectedServices(data) {
    this.serviceInfo.totalCost = this.selectedServices.reduce((total, service) => {
      total += Number(service['details']['servicecost']);
      return total;
    }, 0)
    this.serviceInfo.totalCost = this.user.fixDecimalPlaces(Number(this.serviceInfo.totalCost));
    this.serviceInfo.discountedPayable = this.user.fixDecimalPlaces(Number(this.serviceInfo.totalCost));

    this.serviceInfo.discountApplicableTtlAmt = this.selectedServices.reduce((total, service) => {
      if (service['details']['discountApplicable'] == "Y") {
        total += Number(service['details']['servicecost']);
      }
      return total;
    }, 0)
    this.serviceInfo.discountApplicableTtlAmt = this.user.fixDecimalPlaces(Number(this.serviceInfo.discountApplicableTtlAmt));

    this.totalCostForPromo = this.serviceInfo.discountApplicableTtlAmt ? this.serviceInfo['discountApplicableTtlAmt'] : 0
    let chargeDetails: any = {
      promoCodeDetails: { applicable: false, charge: null },
      discountDetails: { applicable: false, charge: null },
      consultationCharge: Number(this.serviceInfo.totalCost),
      promoDiscount: 0,
      couponDiscount: 0,
      isPromoApplied: false,
      isCouponApplied: false,
      isPointsApplied: false,
      isFreeFollowUp: false,
      couponTitle: [],
      canPayOptional: false
    }

    console.info('selected services',this.selectedServices)
			let tmp=[]
			this.selectedServices.forEach((service) => {
				if (service['details'] && service['details']['chargeStr']) {
					tmp.push(service['details']['chargeStr'])
				}
			})
			if(tmp.length>0)
			chargeDetails['discountDetails'] = {
				applicable: true,
				charge: tmp.join('|')
			}
			this.chargeVatInfo=_.cloneDeep(data.parsedCharges);
			if(data.parsedCharges && data.parsedCharges.length > 0) {
				this.calculateVatcharges(data.parsedCharges)
			}

    this.chargeDetails = chargeDetails;
    this.calculatePayable()
    this.isApplyingCode = false;
    this.cdf.detectChanges();
  }
  public constructInputObject() {
    this.inputObj = {};
    this.inputObj = {
      patientId: this._input['in']['patient']['id'],
      practId: this.practDetails['practitionerId'],
      facility: {
        facilityId: this._input['in']['facility']['facilityId'],
        clinicCode: this._input['in']['facility']['clinicCode'],
        name: this._input['in']['facility']['name'],
        address: this._input['in']['facility']['address'],
        distance: this._input['in']['facility']['distance'],
        position: this._input['in']['facility']['position']['lat'] + ',' + this._input['in']['facility']['position']['lng'],
        facilityLocation: this._input['in']['facility']['facilityLocation']['lat'] + ',' + this._input['in']['facility']['facilityLocation']['lng']
      },
      service: this._input['in']['service']['services'].map(data => { delete data['isSelectable']; delete data['selected']; return data }),
      slot: {
        name: this._input['in']['slot']['name'],
        date: this._input['in']['slot']['date'] + ' ' + this._input['in']['slot']['slot']['slot'],
        slotsNeeded: this._input['in']['slot']['slotsNeeded']
      }
    }
  }

  private generateTransactionParams() {
    return {
      linkedPersonId: this.patient['personid'],
      patientId: this.patient['id'],
      amount: this.user.fixDecimalPlaces(this.serviceInfo.totalCost),
      currency: this.currency_code,
      episodeType: 'R',
      encounterId: '0',
      facilityId: this._input['in']['facility']['facilityId'],
      requestType: 'APPTBK',
      resourceDetails: this.inputObj
    };
  }
  private generateRegistrationParams() {
    return {
      resourceId: this.practDetails['practitionerId'],
      uhid: this.patient['id'],
      facilityId: this._input['in']['facility']['facilityId'],
      clinicCode: this._input['in']['facility']['clinicCode'],
      date: this.user.getMoment(this._input['in']['slot']['date']).format("YYYY-MM-DD"),
      time: this._input['in']['slot']['slot']['slot'],
      // endTime: this.selections['selectedSlot']['endTime'],
      visitType: 'CLINIC',
      patientPersonId: this.patient['personid'],
      personName: this.patient['name'],
      dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
      gender: this.patient['gender'],
      localeCode: this.lang,
      practitionerName: this.translatePipe.transform(this.practDetails['practitionerName'], 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.practDetails['specialityName'], 'LOCALE', 'name'),
      patientName: this.patient['name'],
      servicesToOrder: this.serviceToOrder,
      // duration: this.selections['selectedSlot']['serviceInfo']['totalDuration'],
      noOfSlots: this._input['in']['slot']['slotsNeeded'],
      isPromoCodeAppliedYn: this.getPromoCodeApplystatus('PROMO'),
      isCouponAppliedYn: this.getPromoCodeApplystatus('COUPON'),
      isPointsAppliedYn: this.getPromoCodeApplystatus('POINTS'),
      coupons: this.getAppiledCoupons('COUPON'),
      appliedPromoCodes: this.getAppiledCoupons('PROMO'),
      appliedPoints: this.getAppiledCoupons('POINTS'),
      referralCode: this.referralCode ? this.referralCode : null,
      billingGroup: 'CASH',
      source: 'PXPORTAL'
    }
  }

  public payForAppt() {
    var payload = this.generateTransactionParams();
    let request = this.generateRegistrationParams()
    
    let obj = {
      'payment_option': this.payType == 'X' ? 'Insurance' : 'Credit Card',
      'value': payload['amount']
    };
    payload['resourceDetails']['sourceRequest'] = _.cloneDeep(request)
    this.gtmService.pushGTMEvent('Express Clinics', 'step_option_payment', obj);
    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.patient['id'],
        email: this.patient['email'] || environment.DEFAULT_EMAIL,
        mobile_no: ''
      }
      let url = window['location'];
      let transaction_id = response['transaction_id'];

      let params = `orderid=${transaction_id}`
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=EXPRESS&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() || this.currency_code,
        charge: this.user.fixDecimalPlaces(this.serviceInfo.totalCost),
        paymentgatewayurl: this.paymentGatewayUrl
      }
      if (this.hasDiscount) {
        paymentInfo['charge'] = this.user.fixDecimalPlaces(this.payableCharge)
      }

      if (response['blockedExpiryDateTime']) {
        appointment['blockedExpiryDateTime'] = response['blockedExpiryDateTime'];
        console.log("blockedExpiryDateTime from now", response['blockedExpiryDateTime'], moment(response['blockedExpiryDateTime']).diff(moment(), 'minutes'))
      }
      if (response['blockId']) {
        appointment['blockId'] = response['blockId'];
      }
      if (request['facilityId'])
        appointment['facilityId'] = request['facilityId'];
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        this.action.emit({ name: 'openPaymentCheckout' });
      })
    }, err => {
      console.log('startTransaction', err, err.includes('HPEM130'));
      if (err.includes('HPEM130'))
        this.openSlotBlockPrompt(err);
    })
  }
  public confirmAppt() {
    console.log("confirm appt")
    let data = this.generateRegistrationParams()
    let obj = {
      'payment_option': 'Pay Later',
      'value': 0
    };
    this.gtmService.pushGTMEvent('Express Clinics', 'step_option_payment', obj);
    this.user.confirmAppointment(data).subscribe(response => {
      let d = {
        name: 'book_success',
        status: 'S',
        amount_to_be_paid: this.serviceInfo.totalCost,
        currency: this.currency_code,
        category: 'CASH',
        medium: 'FACILITY',
        apptData: { ...response['data'], ...data }
      }
      if (!this.hasDiscount) {
        d['amount_to_be_paid'] = this._input['in']['service']['totalCost']
      }
      let obj = {
        'payment_option': 'Pay Later',
        'value': 0
      };
      this.gtmService.pushGTMEvent('Express Clinics', 'appointment_confirmation', { value: +d['amount_to_be_paid'], booking_no: response['apptRefNumber'] });

      this.completeSelection(d);
    })
  }
  public rescheduleAppt() {
    let data = {
      patientPersonId: this.patient['personid'],
      uhid: this.patient['id'],
      facilityId: this._input['in']['facility']['facilityId'],
      clinicCode: this._input['in']['facility']['clinicCode'],
      resourceId: this.practDetails['practitionerId'],
      date: this.user.getMoment(this._input['in']['slot']['date']).format("YYYY-MM-DD"),
      time: this._input['in']['slot']['slot']['slot'],
      personName: this.patient['name'],
      dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
      gender: this.patient['gender'],
      practitionerName: this.translatePipe.transform(this.practDetails['practitionerName'], 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.practDetails['specialityName'], 'LOCALE', 'name'),
      patientName: this.patient['name'],
      visitType: 'CLINIC',
      appointmentNumber: this._input.in['exist']['apptRefNo'],
      noOfSlots: this._input['in']['slot']['slotsNeeded'],
      servicesToOrder: this.serviceToOrder
    }
    this.apptService.rescheduleAppointment(data).subscribe(response => {
      let d = {
        name: 'book_success',
        status: 'S',
        currency: this.currency_code,
        category: 'CASH',
        medium: 'FACILITY'
      }
      this.completeSelection(d);
    })
  }


  public applyingPromoCode(event) {
    this.isApplyingCode = event.status
  }
  public applyingCouponCode(event) {
    this.isApplyingCode = event.status
  }

  public applyingPoints(event) {
    this.isApplyingCode = event.status
  }

  public appliedPromocode(event) {
    if (event.type == 'APPLY') {
      this.applyDiscount(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeDiscounts();
    }
  }
  public couponApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.couponTitle = []
      this.applyCouponcode(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeCouponCode()
    }
  }

  public pointsApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.pointsData = {}
      this.applyPoints(event.data)
    } else if (event.type == 'REMOVE') {
      this.removePoints()
    }
  }

  private applyDiscount(response) {
    this.chargeDetails.appliedpromocodedtls = response.appliedpromocodedtls;
    this.chargeDetails.promoDiscount = Number(response.calculatedDiscountAmt);
    this.serviceInfo.totalCost = Number(response.netservicecharge)
    this.serviceInfo.discountedPayable = Number(response.netservicecharge)
    this.chargeDetails.isPromoApplied = response.appliedpromocodedtls?.length > 0;
    this.chargeVatDetails.vatAmount = response.vatAmount ? response.vatAmount : 0;
		this.chargeVatDetails.vatExemptAmount = response.vTaxExemptAmount ? response.vTaxExemptAmount : 0;
		this.chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(this.chargeVatDetails.vatAmount,this.chargeVatDetails.vatExemptAmount);
    if (response.pointsApplied) {
      this.applyPoints({ pointsDerivative: response.pointsApplied })
    } else {
      this.removePoints()
    }
    this.handlePostDiscount()
  }

  public removeDiscounts() {
    this.serviceInfo.totalCost = Number(this.chargeDetails.consultationCharge)
    this.serviceInfo.discountedPayable = Number(this.chargeDetails.consultationCharge)
    this.chargeDetails.isPromoApplied = false;
    this.chargeDetails.promoDiscount = 0;
    this.removePoints()
    this.handlePostDiscount()
  }

  private handlePostDiscount() {
    let isCouponHandled = this.coupon && this.chargeDetails.isCouponApplied
    let isPointsHandled = this.points && this.chargeDetails.isPointsApplied && this.pointsType == 'PAYMENT'

    if (isCouponHandled || isPointsHandled) {
      if (isCouponHandled) this.coupon.removeAllCoupons(true);
      if (isPointsHandled) this.points.removeAllPoints(!isCouponHandled);
    } else {
      this.calculatePayable()
    }
  }

  private applyCouponcode(response) {
    this.chargeDetails.appliedcouponcodedtls = response.appliedcouponcodedtls;
    this.chargeDetails.couponTitle = this.chargeDetails.appliedcouponcodedtls.map(item => item.couponTitle)
    this.chargeDetails.couponDiscount = Number(response.value);
    this.chargeDetails.isCouponApplied = response.appliedcouponcodedtls.length > 0;
    this.calculatePayable()
  }

  private applyPoints(response) {
    console.log('applypoints', response)
    this.chargeDetails.appliedPoints = response.pointsDerivative;
    this.chargeDetails.pointsDiscount = Number(response.pointsDerivative.amount);
    this.chargeDetails.isPointsApplied = this.chargeDetails.pointsDiscount > 0
    if(this.pointsType == 'PAYMENT')
    this.calculatePayable()
  }
  private removeCouponCode() {
    this.chargeDetails.appliedcouponcodedtls = null
    this.chargeDetails.couponTitle = []
    this.chargeDetails.isCouponApplied = false;
    this.chargeDetails.couponDiscount = 0;
    this.calculatePayable()
  }

  private removePoints() {
    this.chargeDetails.appliedPoints = null;
    this.chargeDetails.pointsDiscount = 0;
    this.chargeDetails.isPointsApplied = false;
    if (this.pointsType == 'PAYMENT')
    this.calculatePayable()
    this.calculateVatcharges(this.chargeVatInfo)
  }

  private calculatePayable() {
    let couponDiscount = this.chargeDetails.couponDiscount || 0;
    let pointsDiscount = this.pointsType == 'PAYMENT' ? (this.chargeDetails.pointsDiscount || 0) : 0;
    this.serviceInfo.totalCost = this.serviceInfo.discountedPayable - couponDiscount - pointsDiscount;
    this.chargeDetails._payableWithoutCoupon = this.serviceInfo.totalCost + couponDiscount;
    this.chargeDetails._payableWithoutPoints = this.serviceInfo.totalCost + pointsDiscount;
    this.resetPaymentFlow()
  }
  private resetPaymentFlow() {
    this.serviceInfo.totalCost = this.user.fixDecimalPlaces(Number(this.serviceInfo.totalCost));
    setTimeout(_ => {
      this.isApplyingCode = false
      this.cdf.detectChanges();
    }, 500)
  }
  getPromoCodeApplystatus(type) {
    if (type == 'PROMO' && this.chargeDetails && this.chargeDetails.isPromoApplied)
      return 'Y'
    else if (type == 'COUPON' && this.chargeDetails && this.chargeDetails.isCouponApplied)
      return 'Y'
    else if (type == 'POINTS' && this.chargeDetails && this.chargeDetails.isPointsApplied)
      return 'Y'
    else
      return 'N'
  }

  getAppiledCoupons(type) {
    if (type == 'PROMO' && this.chargeDetails && this.chargeDetails.isPromoApplied)
      return this.chargeDetails.appliedpromocodedtls
    else if (type == 'COUPON' && this.chargeDetails && this.chargeDetails.isCouponApplied)
      return this.chargeDetails.appliedcouponcodedtls
    else if (type == 'POINTS' && this.chargeDetails && this.chargeDetails.isPointsApplied)
      return this.chargeDetails.appliedPoints
    else
      return null
  }

  private calculateVatDisplayAmount(vatamt,vatexpamt){
		let vatDisplayAmount;
		vatDisplayAmount = vatamt - vatexpamt;
		vatDisplayAmount = vatDisplayAmount.toString();
		return vatDisplayAmount;
	}

	private calculateVatcharges(parsedCharges){
		let baseAmount=0,vatAmount=0,vatExemptAmount=0;
		parsedCharges.forEach(charge => {
			baseAmount= baseAmount+ parseFloat(charge.baseServiceAmount);
			vatAmount= vatAmount+ (charge.vatAmount? parseFloat(charge.vatAmount) : 0);
			vatExemptAmount= vatExemptAmount+ (charge.vatExemptedAmt ? parseFloat(charge.vatExemptedAmt): 0);
		})
		this.chargeVatDetails = {
			baseServiceAmount: baseAmount,
			vatAmount: vatAmount,
			vatExemptAmount: vatExemptAmount
		}
		this.chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(this.chargeVatDetails.vatAmount,this.chargeVatDetails.vatExemptAmount);
		console.info('chargeVatDetails',this.chargeVatDetails,parsedCharges)
	}

}
