import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { DirectiveModule } from '../directive/directive.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module';
import { ListItemModule } from '../list-item/list-item.module';
import { NodataModule } from '../nodata/nodata.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { UpcomingAppointmentDetailModule } from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { ConsultAgainComponent } from './consult-again.component';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { RecentlyConsultedModule } from '../recently-consulted/recently-consulted.module';
import { AppointmentsModule } from '../appointments/appointments.module';
import { ChooseSlotModule } from '../choose-slot/choose-slot.module';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';



@NgModule({
  declarations: [ConsultAgainComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule, BookAppointmentModule, RegisterPatientModule, NodataModule,
    CustomModalModule,
    PersonAvatarModule, PractitionerAvatarModule, ChooseSlotModule,
    UpcomingAppointmentDetailModule, ExpressClinicModalModule, DirectiveModule, RecentlyConsultedModule, AppointmentsModule, ApptTimeModule
  ],
  exports: [
    ConsultAgainComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsultAgainModule { }
