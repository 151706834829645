/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { Platform } from '@angular/cdk/platform';
import * as _ from 'lodash';
@Component({
  selector: 'app-express-clinic-facility',
  templateUrl: './express-clinic-facility.component.html',
  styleUrls: ['./express-clinic-facility.component.scss']
})
export class ExpressClinicFacilityComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;
  @ViewChild('mapaddresstext', { static: true }) mapaddresstext: ElementRef;
  public facilityList: Array<any>;
  public map: google.maps.Map;
  public isMapOrList: 'MAP' | 'LIST' = 'LIST';
  public isEditLocation: boolean;
  public searchText: any;
  public locationAddress: any;
  public position: any;
  public curMarker: any;
  public geoErrorMessage: string = null;
  public htmlDirection = document.getElementsByTagName("html")[0].getAttribute("dir");
  public searchKey: string = '';
  public mapSelectedFacility: any = {};
  public showMapPopover: boolean = false;
  public careExpressContact:any;

  constructor(private user: UserService,public platform: Platform) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.facilityList = new Array<any>();
    this.position = {};
  }

  ngOnInit() {
    this.getCurrentLatLon();
    console.log('Express clinic -- Google map opbject ---', google)
    if (this.mapaddresstext) {
      let mapautocomplete = new google.maps.places.Autocomplete(this.mapaddresstext.nativeElement, {
        types: []
      });
      mapautocomplete.addListener('place_changed', () => {
        let place = mapautocomplete.getPlace();
        this.searchText = '';
        this.locationAddress = place.formatted_address;
        this.isEditLocation = false;
        this.position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        console.log("Responsive map->", this.position, this.locationAddress);
        this.getFacilityList();
      });
    }
    let facilityData = this.user.getAttributeForListingType('facilities', true)['facilities'];
		facilityData.forEach((f) => {
			if(f.directory && f.directory.length>0){
			  f.directory.forEach((d)=>{
				if(d.type=='CAREEXPRESS'){
				  this.careExpressContact = d.number;
				}
			  })
			}
		})
    this.getFacilityList();
    this.isMapOrList = 'LIST';
  }

  public getCurrentLatLon() {
    this.toggleMap();
    if (this.platform.IOS) {
      var self = this;
      navigator.geolocation.getCurrentPosition(
        function(position){
          console.log("success geolocation",position)
          if (position) {
            let lat = position.coords.latitude;
            let lon = position.coords.longitude;
            self.geoErrorMessage = null;
            self.position = {
              lat: lat,
              lng: lon
            }
            self.getAddress();
            self.getFacilityList();
          } else {
            self.geoErrorMessage = 'error_getting_location';
            self.isEditLocation = true;
            this.focusMapAddress()
          }
        },
        function(){
          console.log("error geo location")
          self.geoErrorMessage = 'error_getting_location';
          self.isEditLocation = true;
          this.focusMapAddress()
        }
      );
    }else{
      this.user.getGeoLocation().subscribe(data => {
        console.log('Express clinic choose-----',data)
        if (data.status) {
          this.geoErrorMessage = null;
          this.position = {
            lat: data['data']['lat'],
            lng: data['data']['lon']
          }
          this.getAddress();
          this.getFacilityList();
        } else {
          switch (data.message) {
            case 'DECLINED':
              this.geoErrorMessage = 'location_declined';
              break;
            case 'NOOPTION':
              this.geoErrorMessage = 'no_location_service';
              break;
            default:
              this.geoErrorMessage = 'error_getting_location';
              break;
          }
          this.isEditLocation = true;
          this.focusMapAddress()
        }
      })
    }
  }

  private focusMapAddress() {
    const el = document.getElementById('mapaddresstext')
    if (el) {
      document.getElementById('mapaddresstext').focus();
    }
  }

  public loadMap(){
    var mapProp = {
      zoom: 20,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    var latLong = new google.maps.LatLng(this.position['lat'] || 0, this.position['lng'] || 0);
    console.log("current location->", latLong);
    this.curMarker = null;
    this.curMarker = new google.maps.Marker({
      position: latLong,
      icon: 'assets/icons/locate.svg',
      draggable: true,
      map: this.map
    });
    google.maps.event.addListener(this.curMarker, 'dragend', () => {
      this.position = {
        lat: this.curMarker.position.lat(),
        lng: this.curMarker.position.lng(),
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.position }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.locationAddress = results[0].formatted_address;
            // if (this.isEditLocation) {
            //   this.searchText = results[0].formatted_address;
            // }
          }
        }
      })
      this.getFacilityList();
    });
    this.map.setZoom(12);
    this.map.setCenter(this.curMarker.getPosition());
    this.map.addListener('click', (e) => {
      if (this.isEditLocation || true) {
        this.position = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        }
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: this.position }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this.locationAddress = results[0].formatted_address;
              // this.searchText = results[0].formatted_address;
            }
          }
        })
        this.getFacilityList();
      }
    });
    let bounds = new google.maps.LatLngBounds();
    bounds.extend(this.curMarker.getPosition());
    if (Array.isArray(this.facilityList))
      this.addMarker(bounds);
  }

  public addMarker(bounds) {
    this.facilityList = _.cloneDeep(this.facilityList).map(facility => {
      facility['latitude'] = facility['latitude'] || 0;
      facility['longitude'] = facility['longitude'] || 0;
      facility['distance'] = this.getDistanceFromLatLonInKm(this.position['lat'], this.position['lng'], facility['latitude'], facility['longitude']);
      return facility;
    }).sort((a, b) => (Number(a.distance) > Number(b.distance)) ? 1 : ((Number(b.distance) > Number(a.distance)) ? -1 : 0))
    console.log('facilityList', this.facilityList)
    let allMarkers = this.facilityList.map(facility => {
      return new Promise((resolve) => {
        var latLong = new google.maps.LatLng(facility['latitude'], facility['longitude']);
        var marker = new google.maps.Marker({
          position: latLong,
          icon: 'assets/icons/gmap_marker_notify_icon.svg'
        });
        marker.setMap(this.map);
        facility['_marker'] = marker;
        marker.addListener('click', (event) => {
          console.log(event);
          this.facilityList.map(facility => facility['selected'] = false);
          facility['selected'] = true;
          this.showMapPopover = true;
          this.mapSelectedFacility = facility;
          let popover = document.getElementById('map-popover');
          popover.style.display = 'block';
          popover.style.top = '50%';
          popover.style.left = '50%';
          popover.style.transform = 'translate(-50%, -50%)';
        });
        resolve(true);
      })
    });
    console.log("facility list->", this.facilityList);
    Promise.all(allMarkers).then(() => {
      let f = this.facilityList.slice(0, 3);
      console.log(f);
      f.forEach(({ _marker }) => {
        bounds.extend(_marker.getPosition());
      })
      this.map.fitBounds(bounds)
    });;
  }

  public getFacilityList() {
    this.geoErrorMessage = null;
    //lat: 9.915846100000001
    //lon: 78.1304885
    console.log("--- _input" , this._input)
    let filter = {
      lat: this.position.lat,
      lng: this.position.lng,
      skillSets: this._input ? this._input.in.home : null,
      localeCode: this.user.currentLanguage,
      patient: this._input ? this._input.in.patient.patientPayload : null,
    }
    this.user.getClinics(filter).subscribe(data => {
      console.log("data->", data);
     data['data'].forEach(data => {
        if (data['geo_coordinates']) {
          data['latitude'] = data['geo_coordinates'].split(',')[0];
          data['longitude'] = data['geo_coordinates'].split(',')[1];
        }
      });
      console.log("data after Manupulation", data['data']);
      this.facilityList = data['data'];
      this.loadMap();
    });
  }

  public navigateUser(destlat, destlng) {
    this.user.openLocation("https://maps.google.com/?saddr=" + this.position['lat'] + "," + this.position['lng'] + "&daddr="+ destlat + "," + destlng);
  }

  public getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(2);
  }

  public deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  public call(number){
    this.user.openLocation("tel:" + number);
  }

  public completeSelection(event: any) {
    let obj = {
      facilityId: event['facility_id'],
      clinicCode: event['code'],
      name: [{ locale: 'en', name: event['desc'] }],
      address: event['address'],
      mobileNo: event['mobileNo'],
      distance: event['distance'],
      position: this.position,
      facilityLocation:{ lat: event['latitude'], lng: event['longitude'] }
    }
    this.complete.emit({ facility: obj });
  }

  public getAddress() {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: this.position }, (results, status) => {
      console.log('expres-----geocoder ---', status)
      if (status === 'OK') {
        if (results[0]) {
          this.locationAddress = results[0].formatted_address;
        }
      }
    })
  }

  public autoFocus(id) {
    document.getElementById(id).focus();
  }

  public toggle() {
    let map: 'MAP' | 'LIST' = 'MAP'
    if(this.isMapOrList == 'MAP') 
      map = 'LIST'
    this.isMapOrList = map;
    this.showMapPopover = false;
  }

  public toggleMap() {
    this.isMapOrList = 'MAP';
    this.showMapPopover = false;
  }
    
}
