<div class="visit-main-wrapper">
    <div class="visit-form-container">
        <div class="visit-form-header">
            <div class="back-button" *ngIf="step && [3,4].includes(step)" (click)="previousStep()">
                <img src="./assets/icons/header/next_arrow.svg" alt="">
            </div>
            <p class="form-title">{{'Outpatient_visit_registration' | translate}}</p>
            <div class="cursor-pointer task-lang-icon type-image" id="language-actions" (click)="switchLanguage =  !switchLanguage">
                <img src="assets/icons/header/world.svg" />
              </div>
              <div class="actions" id="language-action-options" *ngIf="switchLanguage">
                <ul class="list-group">
                  <li class="list-group-item cursor-pointer"  *ngFor="let item of languageList">
                    <input type="radio" name="label" [checked]="isSelected === item.localeCode ? true: false"
                      (change)="onItemChange(item)" [id]="'lang'+item.localeCode">
                    <label class="label" [for]="'lang'+item.localeCode">{{item.localName}} </label>
                  </li>
                </ul>
              </div>
        </div>
        <ng-container [ngSwitch]="step">
            <ng-container *ngSwitchCase="0">
                <div class="visit-form-content">
                    <app-step-welcome [facility]="facility" [practitioners]="practitioners"
                      [practitionerState]="practitionerState" [languageList]="languageList" [queryParams]="queryParams"
                        (selectPract)="choosePractitioner($event)"></app-step-welcome>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="visit-form-content">
                    <app-step-signin [facility]="facility" [selectedPract]="selectedPractitioner" [queryParams]="queryParams" [visitCharges]="visitCharges" (patient)="setPatient($event)" (back)="step = 0" (registerPatient)="registerPatient()"></app-step-signin>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <div class="visit-form-content">
                    <app-step-confirm [facility]="facility" [selectedPract]="selectedPractitioner"
                        [selectedPatient]="selectedPatient" [visitCharges]="visitCharges"
                        (visitCreated)="onVisitCreated($event) " [queryParams]="queryParams"
                        (initPay)="initPay($event)"></app-step-confirm>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <div class="visit-form-content">
                    <app-step-success [facility]="facility" [selectedPract]="selectedPractitioner" [successState]="successState" [printerConfig]="printerConfig"
                    [selectedPatient]="selectedPatient" [visitCharges]="visitCharges" [visitDetails]="visitDetails"
                    [queryParams]="queryParams" (restartFlow)="restartFlow($event)"></app-step-success>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <div class="visit-form-content">
                    <app-step-register-review [facility]="facility" [selectedPract]="selectedPractitioner" [regParams]="patRegParams"
                    [selectedPatient]="selectedPatient" [visitCharges]="visitCharges" [visitDetails]="visitDetails"
                    [queryParams]="queryParams" (restartFlow)="restartFlow($event)"></app-step-register-review>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <div class="visit-form-content">
                    <app-step-register-review [facility]="facility" [selectedPract]="selectedPractitioner" [regParams]="patRegParams"
                    [selectedPatient]="selectedPatient" [visitCharges]="visitCharges" [visitDetails]="visitDetails"
                    [queryParams]="queryParams" (restartFlow)="restartFlow()"></app-step-register-review>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<app-register-new-patient  *ngIf="isRegisterPatient" [standalone]="true" (close)="closeRegisterPatient($event)"
  [standaloneParams]="queryParams" [charges]="visitCharges" (standaloneRegister)="payAndRegister($event)"></app-register-new-patient>

<app-payment-checkout [showModalView]="true" *ngIf="isPaymentGateway"></app-payment-checkout>