import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { NORMALCYARROWS } from '@const';

@Pipe({
  name: 'normalcyind'
})
export class NormalcyindPipe implements PipeTransform {

  constructor(private user: UserService) { }
  transform(key: string, type: 'color' | 'arrow'): any {
    let normalcyInds = this.user.getNormalcyInd();

    let normalcyInd = normalcyInds.find(item => item.code == key);
    let controlsSection = normalcyInd?.controls?.section;
    if (type == 'color') {
      return controlsSection?.normalIndColor || '#000';
    } else if (type == 'arrow') {
      console.log('key', key, controlsSection)
      let indType = controlsSection?.normalIndType || 'N';
      return NORMALCYARROWS[indType];
    }
  }

}
