import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CookieService } from './cookie.service';
import { USER_TOKEN } from '../app.string';

@Injectable({
  providedIn: 'root'
})
export class GaurdService implements CanActivate {

  constructor(private router: Router, private cookie: CookieService) { }

  canActivate() {
    let token = this.cookie.check(USER_TOKEN);
    let time = this.cookie.checkTime();
    if (token && time)
      return true;
    else
      this.router.navigate(['/']);
  }
}
