<div id="patient-bill-details-component">
  <div class="flip-card" [ngClass]="{ flipped:isCouponCode }">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <div class="installment-payment-container">
          <div class="payment-card-header border-bottom">
            <span class="font-primary-subtitle bold">{{'Installment_payments' |translate}}</span>
            <div (click)="closeModal()" class="float-right closeicon pointer">
              <img src="./assets/icons/close_black.svg">
            </div>
          </div>
          <div class="payment-card-content">
            <div class="content-block-1">
              <div class="block-header font-primary-subtitle bold">
                {{selectedBill['patientName'] || selectedBill['patientId']}}
              </div>
              <div class="block-content">
                <table>
                  <tr>
                    <td>
                      <div class="view-type-1">
                        <div class="primary-section font-default-subtitle">{{selectedBill['serviceGroups']}}</div>
                        <div class="secondary-section font-light-small1">{{selectedBill['billDate'] | moment : 'DD MMM YYYY, hh:mm a'}}</div>
                      </div>
                    </td>
                    <td>
                      <div class="view-type-2">
                        <div class="primary-section bold">{{currencyCode}} {{selectedBill['billAmount']}}</div>
                        <div class="secondary-section font-light-small1">{{'Total_bill' | translate}}</div>
                      </div>
                    </td>
                    <td>
                      <div class="view-type-2">
                        <div class="primary-section">{{currencyCode}}
                          {{selectedBill['billOutstandingAmount']?(selectedBill['billAmount'] - selectedBill['billOutstandingAmount']):selectedBill['billAmount']}}
                          </div>
                        <div class="secondary-section font-success-small1">Paid</div>
                      </div>
                    </td>
                    <td>
                      <div class="view-type-2">
                        <div class="primary-section">{{currencyCode}} {{selectedBill['billOutstandingAmount'] || 0}}
                        </div>
                        <div class="secondary-section font-danger-small1">Remaining</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="content-block-2">
              <div class="block-header font-default-subtitle">
                Installment details
              </div>
              <div class="block-content">
                <table class="sticky-table">
                  <thead>
                    <tr>
                      <th class="font-light-small1">Date</th>
                      <th class="font-light-small1">Amount</th>
                      <th class="font-light-small1">Status</th>
                      <th class="font-light-small1">Paid on</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="installmentDetails && installmentDetails.length>0">
                    <tr *ngFor="let item of installmentDetails">
                      <td>{{item['installement_date']|moment:'DD MMM, YYYY'}}</td>
                      <td>{{currencyCode}} {{item['installement_amount']}}</td>
                      <td>{{(item['installement_status']=='P'?'Paid':'Due')|translate}}</td>
                      <td>
                        <ng-container *ngIf="item['paid_date']">{{item['paid_date']|moment:'DD MMM,YYYY'}}
                        </ng-container>
                        <ng-container *ngIf="item['showPay']">
                          <button class="primary-button" (click)="initPay(item)">{{'Pay_now'|translate}}</button>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="!installmentDetails || installmentDetails.length==0">
                    <tr>
                      <td colspan="4" style="text-align: center;">{{'No_installment_details' | translate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flip-card-back">
        {{'back_view'}}
      </div>
    </div>
  </div>
</div>
