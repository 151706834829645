<div id="search-speciality">
  <div class="search-section">
    <div class="patient-title bold" *ngIf="!settings || !settings.hidePatientHeader">{{'select_a_patient'|translate:'LABELS'}}</div>
    <app-patient-list [linkedPatients]="linkedPatients" (selcetedPatient)="choosePatient($event)" [_selectedPatient]="selectedPatient"></app-patient-list>
    <div class="insurance-alert" *ngIf="msg">
      <div class="alert font-warning-small">
        <img class="type-image" src="assets/icons/alert.svg" />
        {{msg}}
      </div>
    </div>
  </div>
  <div class="search-patient-footer">
    <button class="button-secondary" (click)="openModal('register-patient')">{{'msg_register_new_patient'|translate:'LABELS'}}</button>
    <button class="button-primary" [disabled]="!selectedPatient" (click)="completeSelection(selectedPatient)">{{'Continue' | translate}}</button>
  </div>
  <div class="delayRunning" *ngIf="delayRunning">
      <div class="three-quarter-spinner"></div>
  </div>

</div>

<!-- <app-custom-modal-container id="manage-identity-modal" (complete)="closeManageIdentities()">
  <app-manage-identities *ngIf="isManageIdentities"></app-manage-identities>
</app-custom-modal-container> -->
