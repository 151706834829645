<div id="hr-doc-upload" class="">
  <div (click)="closeModel()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">{{'upload_docs'|translate}}</div>
      <div (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <!-- <div class="patient-select">
      <div class="sub-title">
        <span class="bold">{{ selectedPatient.personName }}</span>
      </div>
    </div> -->
    <div class="content">
      <div class="content-wrapper">
        <div class="image-upload">
          <!-- <div class="report-share sub-title">
                <span class="bold">{{ selectedPatient.personname ? selectedPatient.personname : selectedPatient.personName }}</span>
              </div> -->
          <div class="upload-section">
            <p class="file-label"> {{ 'Add_image_or_file' | translate}} 
              <span class="mandatory font-danger-small">*</span>
            </p>
            <ng-container *ngIf="imgURL.length>0">
              <div class="add-new-container" *ngFor="let item of imgURL; let index=index;">
                <div class="add-new cursor-pointer">
                  <img src="assets/icons/Health_records/file.svg">
                  <p class="file-upload-placeholder"> {{item.name}}</p>
                  <img class="close-icon" src="assets/icons/close.svg" (click)="removeFile(index)">
                </div>
              </div>
            </ng-container>
            <div class="add-new-container" *ngIf="canUpload">
              <div class="add-new cursor-pointer">
                <img src="assets/icons/Health_records/Upload.svg">
                <p class="file-upload-placeholder"> {{'Drag_and_drop_or_click_to_browse' | translate}}</p>
                <input #fileUpload type="file" [accept]="accept" (change)="preview(fileUpload.files, $event)" />
              </div>
            </div>
          </div>
        </div>
        <div class="image-warning" *ngIf="false">
          <img class="type-image" src="assets/icons/info.svg" />
          <span class="font-primary-small">{{ 'image_upload_warning'|translate}}</span>
        </div>
        <div class="user-form">
          <form [formGroup]="uploadForm" autocomplete="off">
            <div class="form-container">
              <app-custom-input-group [label]="'report_title'" formControlName="name" type="text"
                [required]="isRequiredField('name')"></app-custom-input-group>
            </div>
            <div class="form-container">
              <app-custom-input-group [label]="'report_date'" type="date" [(ngModel)]="curDate"
              formControlName="date"
                [required]="isRequiredField('name')" (dateChange)="reportDateChange($event)"
                [dateMax]="curDate"></app-custom-input-group>
              <app-form-input-error [control]="uploadForm.controls.date"></app-form-input-error>
            </div>
            <div class="form-container">
              <app-custom-input-group label="{{'report_type'|translate}}" [ngModel]="reportType.search" [ngModelOptions]="{standalone: true}" type="select" [selectOptions]="selectOptions"
              [required]="isRequiredField('type')" [selectList]="reportTypeList" (change)="selectReportType(null,$event.target.value)"
              [readonly]="false"
              [selectedValue]="reportType.search">
            </app-custom-input-group>
            </div>
            <!-- As discussed with saravanan share doctor sending by default as Y -->
              <div class="form-container">
              <app-custom-input-group label="{{'Share_with_doctor'|translate}}" formControlName="practAccess" type="default_radio"
                [required]="false" [radioOptions]="shareDoctorRadioOptions" [radioList]="shareDoctorRadioList"></app-custom-input-group>
            </div>
          </form>
        </div>
      </div>

    </div>
    <div class="footer">
      <button class="primary-button" [disabled]="!canContinue" (click)="initUpload()">{{'btn_upload'|translate}}</button>
    </div>
  </div>
</div>