import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrPatientSelectComponent } from './hr-patient-select.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
import { UpdateRelationModalModule } from '../update-relation-modal/update-relation-modal.module';
import { DirectiveModule } from '../directive/directive.module';
import { LinkOtherMobileModule } from '../link-other-mobile/link-other-mobile.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';



@NgModule({
  declarations: [HrPatientSelectComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule, RegisterNewPatientModule, UpdateRelationModalModule, DirectiveModule, LinkOtherMobileModule, CustomModalModule, ManageIdentitiesModule
  ],
  exports: [
    HrPatientSelectComponent
  ]
})
export class HrPatientSelectModule { }
