import { FormsModule } from '@angular/forms';
import { BrowserModule,   HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginModule } from './login/login.module';
import { CrmModule } from './crm/crm.module';
import { HomeModule } from './home/home.module';
import { CalendarModule } from './calendar/calendar.module';

import { InterceptorService } from './services/interceptor.service';
import { CookieService } from './services/cookie.service';
import { LoaderService } from './services/loader.service';
import { ToastServiceService } from './services/toast-service.service';
import { GaurdService } from './services/gaurd.service';
import { LoaderModule } from './loader/loader.module';
import { ToastModule } from './toast/toast.module';
import { PrebootstrapLoaderModule } from './prebootstrap-loader/prebootstrap-loader.module';
import { DirectiveModule } from './directive/directive.module';
import { AppointmentRecentModule } from './appointment-recent/appointment-recent.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HealthRecordsModule } from './health-records/health-records.module';
import { TaskModule } from './task/task.module';
import { AppGuardService } from './services/app-guard.service';
import { VaccinationFormModule } from './vaccination-form/vaccination-form.module';

import { UserService } from './services/user.service';
import { PatientBillingModule } from './patient-billing/patient-billing.module';
import { TranslatePipe } from './pipes/translate.pipe';
import { PartitionerDetailsPipe } from './pipes/partitioner-details.pipe';
import { FacilityDetailsPipe } from './pipes/facility-details.pipe';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PromotionPopupModule } from './promotion-popup/promotion-popup.module';
import { NgxHijriGregorianDatepickerModule } from 'ngx-hijri-gregorian-datepicker';
import { environment } from '@env/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { VisitStandaloneModule } from './visit-standalone/visit-standalone.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CommonViewModule } from './common-view/common-view.module';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppointmentPreviewModule } from './appointment-preview/appointment-preview.module';
import { BillPaymentModule } from './bill-payment/bill-payment.module';
import * as Hammer from "hammerjs";
import { ApptDtlCardModule } from './appt-dtl-card/appt-dtl-card.module';
import { FilterModule } from './filter/filter.module';
import { EventsService } from './services/events.service';
import { QueuePreviewModule } from './queue-preview/queue-preview.module';
import { NpsRatingModule } from './nps-rating/nps-rating.module';
import { SkeletonLoaderModule } from './skeleton-loader/skeleton-loader.module';
import { EmbedApptModule } from './embed-appt/embed-appt.module';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRETION_ALLL }
  };
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    BrowserAnimationsModule,
    DropdownModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(), LoaderModule, ToastModule,
    HttpClientModule, LoginModule, CrmModule, HomeModule,
    BrowserAnimationsModule, PrebootstrapLoaderModule, DirectiveModule,
    AppointmentRecentModule,
    CalendarModule, NgbModule, HealthRecordsModule, TaskModule, VaccinationFormModule, PatientBillingModule, SignaturePadModule, PromotionPopupModule, NgxHijriGregorianDatepickerModule, CommonViewModule, RecaptchaV3Module,
    NpsRatingModule,
    GoogleTagManagerModule.forRoot({
      id: environment['GTMKey'] || 'GTM-',
    }),
    VisitStandaloneModule,  AppointmentPreviewModule, BillPaymentModule, ApptDtlCardModule, FilterModule,QueuePreviewModule, SkeletonLoaderModule, EmbedApptModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, InterceptorService, CookieService, GaurdService, LoaderService,
    ToastServiceService, AppGuardService, TranslatePipe, PartitionerDetailsPipe, FacilityDetailsPipe, { provide: RECAPTCHA_V3_SITE_KEY, deps: [UserService], useFactory: (userService) => userService.getCaptchaKey(3) },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    EventsService,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
