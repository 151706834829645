<div class="avatar-container {{additionalClass}}" [ngClass]="classNames">
  <ng-container *ngIf="isImage">
    <div class="avatar-content actual">
      <img [src]="imgUrl" alt="" class="person-image type-image">
    </div>
  </ng-container>
  <ng-container *ngIf="isInitial">
    <div class="avatar-content font-primary-title" [ngClass]="{'avt-bg' : fromPatientTask}">
      {{ imgUrl | personinitial}}
    </div>
  </ng-container>
  <ng-container *ngIf="isDummy">
    <div class="avatar-content dummy" [ngClass]="{'checkin-default': default}">
      <img *ngIf="additionalClass!=='circular'" src="assets/icons/user.svg" alt="" class="person-image type-image">
      <img *ngIf="additionalClass==='circular'" src="assets/icons/user_notify.svg" alt="" class="person-image type-image">
    </div>
  </ng-container>
</div>
