import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import * as moment from 'moment';
import { NormalcyindPipe } from 'src/app/pipes/normalcyind.pipe';
import annotationPlugin from 'chartjs-plugin-annotation';
import { UserService } from 'src/app/services/user.service';
Chart.register(annotationPlugin);
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  providers:[NormalcyindPipe]
})
export class GraphComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  @Input() eventhistory: any;
  @Input() event: any;
  constructor(private user:UserService, private normalcyIndPipe: NormalcyindPipe) { }
  
  ngOnInit() {
  }

  ngAfterViewInit() {
    Chart.register(...registerables);
    this.initChart();
  }
  private initChart() {
    if (!this.canvas || !this.canvas.nativeElement) return
    let canvas = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement['height'] = '100%';
    this.eventhistory=this.eventhistory.sort((a, b) => new Date(a['Event Date']).getTime() - new Date(b['Event Date']).getTime());
    let label = this.eventhistory.map(h => {
      return [
        this.user.getMoment(h['Event Date'], 'YYYY-MM-DD hh:mm').format("DD"),
        this.user.getMoment(h['Event Date'], 'YYYY-MM-DD hh:mm').format("MMM"),
        this.user.getMoment(h['Event Date'], 'YYYY-MM-DD hh:mm').format("YY"),
      ]
      
    });
    let value = this.eventhistory.map(h => h['Result_num']);
    let pointBorderColors = this.eventhistory.map(h => {
      return this.normalcyIndPipe.transform(h['Normalcy Ind'], 'color');
    });
    console.log('pointBorderColors', pointBorderColors, this.event)
    let yValues = [0, ...value]
    let options: any = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: ''
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: this.event.Uom
          },

        }
      },
    }

    if (this.event && this.event['Range']) {
      let range = this.event['Range'].split('-');
      if (range && range.length > 1) {
        options.plugins.annotation = {
          annotations: {
            box1: {
              type: 'box',
              xMin: 0,
              xMax: value.length - 1,
              yMin: Number(range[0]),
              yMax: Number(range[1]),
              backgroundColor: 'rgba(99, 255, 132, 0.25)',
              borderWidth: 0
            }
          }
        }
        yValues.push(Number(range[0]));
        yValues.push(Number(range[1]));
      }
    }

    if (this.event && this.event.sourceEvent && this.event.sourceEvent.resultNormalCyDtls) {
      let normalcyValues = this.event.sourceEvent.resultNormalCyDtls.map(n => [n.resultMinValue, n.resultMaxValue]);
      normalcyValues = normalcyValues.flat();
      yValues = [...normalcyValues, ...yValues];
    }

    let minY = Math.min(...yValues);
    let maxY = Math.max(...yValues);
    options.scales.y.min = minY < 0 ? minY : 0;
    options.scales.y.max = maxY + 50;
    let chart = new Chart(canvas, {
      type: 'line',
      data: {
        labels: label,
        datasets: [
          {
            label: this.event.Test,
            data: value,
            borderColor: '#2173B8',
            pointBackgroundColor: 'rgb(255, 255, 255)',
            fill: false,
            tension: 0,
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 3,
            pointBorderWidth: 2,
            pointBorderColor: pointBorderColors
          }
        ]
      },
      options: options
    });
  chart.update();
  }


}
