<div class="embed-appt-container">
    <div class="embed-appt-wrapper">
        <div class="embed-appt-header">
            {{'EMBED_APPT#HEADER#TITLE' | translate}} <img src="./assets/icons/close_white.svg" alt="">
        </div>
        <div class="embed-appt-context">
            <div class="embed-appt-side-bar">
                <div class="embed-topbar-mobileview" *ngIf="isMobileView">
                    <div class="embed-topbar-item"  *ngFor="let f of flow" [ngClass]="{'active': f['state'] == state}" (click)="bookingInfo[f['attribute']] ? editFlow(f['state']) : ''">
                            <img [src]="bookingInfo[f['attribute']] || f['state'] == state ? './assets/icons/embed-appt/'+f['activeIcon']+'.svg' :'./assets/icons/embed-appt/'+f['inactiveIcon']+'.svg'"
                                alt="">
                        <p class="topbar-label" *ngIf="f['state'] == state">
                            {{f['label'] | translate}}
                        </p>
                    </div>
                </div>
                <div class="embed-appt-absolute">

                    <div class="embed-appt-sidebar-list" *ngIf="!isMobileView || (isMobileView && expandedSidebar)">
                        <div class="embed-sidebar-item" *ngFor="let f of flow">
                            <div class="flow-icon-wrapper">
                                <img [src]="bookingInfo[f['attribute']] || f['state'] == state ? './assets/icons/embed-appt/'+f['activeIcon']+'.svg' :'./assets/icons/embed-appt/'+f['inactiveIcon']+'.svg'"
                                alt="">
                        </div>
                        <p class="flow-label">{{f['label'] | translate}} <img class="flow-edit" *ngIf="bookingInfo[f['attribute']] && !f['static']"
                                (click)="editFlow(f['state'])" src="./assets/icons/embed-appt/edit.svg" alt=""></p>
                        <p class="flow-placeholder" [ngClass]="{'current-state': f['state'] == state}"
                            *ngIf="!bookingInfo[f['attribute']]">{{f['title'] | translate}}</p>
                        <p class="flow-value" *ngIf="bookingInfo[f['attribute']]">{{bookingInfo[f['attribute']]['name'] |
                            translate:'LOCALE':'name'}}
                        </p>
                    </div>
                </div>
                <div class="embed-appt-action-bar" *ngIf="isMobileView" (click)="expandedSidebar = !expandedSidebar">
                    <img src="./assets/icons/embed-appt/arrow_down.svg" *ngIf="!expandedSidebar" alt="">
                    <img src="./assets/icons/embed-appt/arrow_up.svg" *ngIf="expandedSidebar" alt="">
                </div>
            </div>


            </div>
            <div class="embed-appt-main">
                <ng-container *ngFor="let f of flow">
                    <ng-container *ngIf="f['state'] == state">
                        <div class="embed-appt-toolbar">
                            <p class="embed-main-title">{{f['title'] | translate}}</p>
                            <div class="search-bar" *ngIf="f['placeholder']">
                                <img src="./assets/icons/embed-appt/search.svg" alt="">
                                <input type="text" [(ngModel)]="searchStr" [placeholder]="f['placeholder'] | translate">
                            </div>
                        </div>
                        <div class="embed-appt-content">
                            <ng-container [ngSwitch]="state">
                                <div class="embed-appt-cards-list" *ngSwitchCase="'FACILITY'">
                                    <div class="embed-appt-card" (click)="setNextState(f)"
                                        *ngFor="let f of facilityList  | filterSearch:searchStr:'facilityName':'LANGARRAY':'name'">
                                        <div class="embed-card-context">
                                            <p class="embed-card-title">{{f['facilityName'] |
                                                translate:'LOCALE':'name'}}</p>
                                            <p class="embed-card-subtext">{{(f['facilityAddress'] |
                                                translate:'LOCALE':'text') || ' ' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-appt-cards-list" *ngSwitchCase="'SPECIALITY'">
                                    <div class="embed-appt-card" (click)="setNextState(f)"
                                        *ngFor="let f of specialityList | filterSearch:searchStr:'specialityName':'LANGARRAY':'name'">
                                        <div class="embed-card-context">
                                            <p class="embed-card-title">{{f['specialityName'] |
                                                translate:'LOCALE':'name'}}</p>
                                            <p class="embed-card-subtext">{{f['pracCount']}} {{'Practitioners'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-appt-cards-list" *ngSwitchCase="'PRACTITIONER'">
                                    <div class="embed-appt-card" (click)="setNextState(f)"
                                        *ngFor="let f of practitionerList | filterSearch:searchStr:'resourceName':'LANGARRAY':'name'">
                                        <div class="embed-card-context">
                                            <p class="embed-card-title">{{f['resourceName'] |
                                                translate:'LOCALE':'name'}}</p>
                                            <p class="embed-card-primary" *ngIf="f['preview']">
                                                {{f['preview']['payableCharge']}} {{currency}}</p>
                                        </div>
                                        <img class="info-icon" src="./assets/icons/embed-appt/info.svg"
                                            (click)="f['_showDetails'] = !f['_showDetails'];$event.stopPropagation()" alt="">
                                        <div class="pract-card-details-wrapper" *ngIf="f['_showDetails']">
                                            <div class="pract-card-details">

                                            <div class="pract-details-header">
                                                <img src="./assets/icons/embed-appt/heart_grad.svg" alt="">
                                                <div class="pract-details-info">
                                                    <p class="embed-card-title">{{f['resourceName'] |
                                                        translate:'LOCALE':'name'}}</p>
                                                    <p class="embed-card-subtext">{{f['specialityDesc']}}</p>
                                                </div>
                                            </div>
                                            <div class="pract-details-content">
                                                <div class="pract-details-item" *ngIf="f['preview']">
                                                    <p class="embed-card-subtext">
                                                        {{'Consultation_charges' | translate}}
                                                    </p>
                                                    <p class="embed-card-primary">{{f['preview']['payableCharge']}} {{currency}}</p>
                                                </div>
                                                <div class="pract-details-item" *ngIf="f['daysAvailableString']">
                                                    <p class="embed-card-subtext">
                                                        {{'Available_days' | translate}}
                                                    </p>
                                                    <p class="embed-card-avail">{{f['daysAvailableString']}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-appt-cards-list" *ngSwitchCase="'SERVICE_CATAGORY'">
                                    <div class="embed-appt-card" (click)="setNextState(f)"
                                        *ngFor="let f of serviceCatagoryList | filterSearch:searchStr:'name':'LANGARRAY':'name'">
                                        <div class="embed-card-context">
                                            <p class="embed-card-title">{{f['name'] |
                                                translate:'LOCALE':'name'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-appt-cards-list" *ngSwitchCase="'SERVICE'">
                                    <div class="embed-appt-card" (click)="setNextState(f)"
                                        *ngFor="let f of serviceList | filterSearch:searchStr:'name':'LANGARRAY':'name'">
                                        <div class="embed-card-context">
                                            <p class="embed-card-title">{{f['name'] |
                                                translate:'LOCALE':'name'}}</p>
                                            <p class="embed-card-primary" *ngIf="f['facilities']?.length">
                                                {{f['facilities'][0]?.['controls']?.['charge']}} {{currency}}</p>
                                        </div>
                                        <img class="info-icon" src="./assets/icons/embed-appt/info.svg"
                                            (click)="f['_showDetails'] = !f['_showDetails'];$event.stopPropagation()" alt="">
                                        <div class="pract-card-details-wrapper" *ngIf="f['_showDetails']">
                                            <div class="pract-card-details">

                                            <div class="pract-details-header">
                                                <img src="./assets/icons/embed-appt/service_grad.svg" alt="">
                                                <div class="pract-details-info">
                                                    <p class="embed-card-title">{{f['name'] |
                                                        translate:'LOCALE':'name'}}</p>
                                                </div>
                                            </div>
                                            <div class="pract-details-content">
                                                <div class="pract-details-item" *ngIf="f['facilities']?.length">
                                                    <p class="embed-card-subtext">
                                                        {{'Charges' | translate}}
                                                    </p>
                                                    <p class="embed-card-primary">{{f['facilities'][0]?.['controls']?.['charge']}} {{currency}}</p>
                                                </div>
                                                <!-- <div class="pract-details-item" *ngIf="f['daysAvailableString']">
                                                    <p class="embed-card-subtext">
                                                        {{'Available_days' | translate}}
                                                    </p>
                                                    <p class="embed-card-avail">{{f['daysAvailableString']}}</p>
                                                </div> -->
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>
                                    <div class="slot-list-wrapper">
                                        <div class="slot-dates-list">
                                            <div class="slots-action left" (click)="changeSlots(-5)"><img
                                                    src="./assets/icons/embed-appt/arrow.svg" alt=""></div>
                                            <div class="slot-item" (click)="selectedDate = d"
                                                *ngFor="let d of slotList['dates'] | slice : startIndex : endIndex"
                                                [ngClass]="{'selected': selectedDate == d}">
                                                <p class="slot-month">{{d | moment:'MMM'}}</p>
                                                <p class="slot-date">{{d | moment:'D'}}</p>
                                            </div>
                                            <div class="slots-action" (click)="changeSlots(5)"><img
                                                    src="./assets/icons/embed-appt/arrow.svg" alt=""></div>
                                        </div>
                                        <div class="selected-date-slots">
                                            <ng-container *ngIf="slotList['slots']?.[selectedDate] as date">
                                                <ng-container *ngIf="date['mrng']?.length">
                                                    <p class="date-period">{{'Morning' | translate}}</p>
                                                    <div class="slots-list">
                                                        <div class="slot-item" *ngFor="let s of date['mrng']"
                                                            (click)="chooseSlot(s)">
                                                            {{s['time'] | moment:'h:mm a' :'HH:mm'}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="date['noon']?.length">
                                                    <p class="date-period">{{'Noon' | translate}}</p>
                                                    <div class="slots-list">
                                                        <div class="slot-item" *ngFor="let s of date['noon']"
                                                            (click)="chooseSlot(s)">
                                                            {{s['time'] | moment:'h:mm a' :'HH:mm'}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="date['eve']?.length">
                                                    <p class="date-period">{{'Evening' | translate}}</p>
                                                    <div class="slots-list">
                                                        <div class="slot-item" *ngFor="let s of date['eve']"
                                                            (click)="chooseSlot(s)">
                                                            {{s['time'] | moment:'h:mm a' :'HH:mm'}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>