<div class="task-main-wrapper">
    <div class="task-container">
        <app-event-info *ngIf="state == 'INIT'" [workflow]="workflow" [patientInfo]="patientInfo" [task]="task"
            [lang]="lang" [languageList]="languageList" [applicableStatus]="applicableStatus"
            [APPOINTMENTS]="APPOINMENTS" [CERTIFICATE]="CERTIFICATE" [disableBtn]="disableBtn"(action)="action($event, mpiPatientInfo)">
        </app-event-info>
        <div class="form-container" *ngIf="state == 'FORM'">
            <div class="header">
                <p class="title">{{task['taskDesc']}}</p>
                <img src="./assets/icons/close_white.svg" alt="" (click)="state='INIT'">
            </div>
            <div class="patient-info-wrapper">
                <app-person-avatar
                    [name]="patientInfo['patientNameLocale'] || patientInfo['patientName'] | translate:'LOCALE':'text':lang"
                    [fromPatientTask]="true"></app-person-avatar>

                <div class="patient-info">
                    <p class="patient-name">{{patientInfo['patientNameLocale'] || patientInfo['patientName'] |
                        translate:'LOCALE':'text':lang}}
                        <span class="patient-number">{{patientInfo['sex'] | translate : 'LABELS' : null : lang}},
                            {{patientInfo['dob'] | age}}</span>
                    </p>
                </div>
            </div>
            <div *ngIf="contractSeq" class="contract-seq">{{contractSeq}}</div>
            <div class="form-wrapper">
                <app-clinicalform [ngClass]="{'form-height': contractSeq}" [params]="clinicalParams"
                  [taskAction]="taskAction" (response)="saveResponse($event)">
                </app-clinicalform>
            </div>
        </div>
        <div class="certificate-container" *ngIf="state == 'CERT'">
            <app-vaccination-certificate [isCertificateDetails]="isCertificateDetails" [patientInfo]="patientInfo"
                [taskInfo]="task">
            </app-vaccination-certificate>
        </div>
        <div *ngIf="state == 'COMPLETE'" class="complete-wrapper">
            <div class="header-wrapper">
                <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
            </div>
            <div class="content-wrapper">
                <div class="success-wrapper">
                    <div class="success-bg">
                        <img class="tick-image"
                            [src]="content['iconIndex'] ? 'assets/icon/task/completion/'+ content['iconIndex'] +'.svg' : 'assets/icons/task/done.svg'"
                            alt="">
                        <p class="title-txt">{{content['title']}}</p>
                    </div>
                    <p class="sub-title-txt">{{content['message']}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="state == 'INVALID'" class="invalid-wrapper">
            <div class="header-wrapper">
                <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
            </div>
            <div class="content-wrapper">
                <div class="nodata-wrapper">
                    <img class="nodata-image" src="assets/icons/task/search.svg" alt="">
                    <p class="title-txt">{{'lbl_unavailable'| translate : 'LABELS' : null : lang}}</p>
                    <p class="sub-title-txt">
                        {{'lbl_task_invalid' | translate : 'LABELS' : null : lang}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-express-clinic-modal *ngIf="isApptModal" [isPatientTask]="true" [patient]="patientInfo['_info']"
    (close)="closeModal()" (completeAppt)="completeTaskOnBookAppt($event)">
</app-express-clinic-modal>