<!-- <div id="hr-visit-prescription-component">
  <div class="search-header">
    <div class="search" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon"  (click)="searchIcon()">
          <img class="type-image" src="assets/icons/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_medicine'|translate:'LABELS'}}" [(ngModel)]="searchTerm" (input)="searchFilter($event)" />
        </div>
      </div>
    </div>
    <div class="extras">
      <div class="uploadDocs">
        <button class="secondary-button btn-flat btn-small" (click)="openDocUpload()"
          [appRemoveFeatureOff]="'FT_APP_HR_UPLOADDOCS'">
          <img src="assets/icons/uploadreport.svg">
          <span class="font-primary-default hide">{{'upload_docs'|translate}}</span>
        </button>
      </div>
    <div class="filter" [ngClass]="{active: (searchExpand === true)}" >
      <div class="dp-down cursor-pointer">
        <div class="dp-select" (click)="openCloseFilter()">
          <span class="font-primary-default">{{selectedFilter.value |translate:'LABELS'}}</span>
        </div>
        <div class="dp-option" *ngIf="isFilterOpen">
          <span class="option" (click)="changeFilter(item)" *ngFor="let item of filters">{{item.value |translate:'LABELS'}}</span>
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class="list">
    <div class="no-data" *ngIf="(!prescriptions || prescriptions.length === 0) && uploadedDocs['length'] == 0">
      <app-nodata></app-nodata>
    </div>
  <ng-container *ngIf="prescriptions && prescriptions.length > 0">
    <div class="content" *ngFor="let orders of prescriptions | groupBy : 'encounterId' ">
      <ng-container *ngFor="let items of orders | groupBy : 'orderId'">
        <div class="content-header">
          <div class="group-title">
            <div class="title-facility">
              <p class="title-facility bold">{{( items[0].prescriberId | partitionerDetails: items[0].facilityId:'resourceName'| translate : 'LOCALE' : 'name') || items[0]['prescribedByName']}}</p>
              <span class="font-light-small">{{(items[0]['facilityId']| facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name')|| items[0]['facilityName']}}</span>
            </div>
            <div class="title-sub font-light-small">
              {{items[0].startDateTime | moment : 'DD MMM YYYY' }}
            </div>
          </div>
          <div class="title-black bold" *ngIf="false">
            {{( items[0].prescriberId | partitionerDetails: items[0].facilityId:'resourceName'| translate : 'LOCALE' : 'name') }}
            <p class="subtitle-ternary">
            {{items[0].startDateTime | moment : 'DD MMM YYYY' }}
            </p>
          </div>
          <div class="float-right print-icon" *ngIf="enablePrintPrescriptionYn && false">
            <img class="type-image" src="assets/icons/printing.svg" (click)="printPrescription(items[0])" />
          </div>
        </div>
        <div class="order-header">
          <div class="title-black bold">
            {{'Order_id' | translate}}: {{items[0].orderId}}
          </div>
          <div class="float-right print-icon" *ngIf="enablePrintPrescriptionYn">
            <img class="type-image" src="assets/icons/printing.svg" (click)="printPrescription(items[0])" />
          </div>
        </div>
        <div class="outer-list border-bottom" *ngFor="let item of items">
          <div class="list">
            <div class="list-header">
              <div class="prescription-icon">
                <img class="type-image" src="assets/icons/medicine.svg" />
              </div>
              <div class="list-content">
                <div class='title-label'>
                  <p><span class="title-black bold ">{{item.genericText || item.drugText}}</span>
                  <br class="show"> {{ 'prescricption_lbl_dose' |translate:'LABELS'}} 
                  <span class="subtitle-ternary">{{item.dosage}} {{item.dosageUomCode}}</span>
                  {{ 'prescricption_lbl_frequency' |translate:'LABELS'}} <span class="subtitle-ternary">{{item.frequencyText}}</span></p>
                </div>
                <div class='hide'>
                  <p class="label">
                  {{ 'prescricption_lbl_startdate' |translate:'LABELS'}}<span class="subtitle-ternary">{{item.startDateTime | moment : 'DD MMM YYYY'}}</span>
                  {{ 'prescricption_lbl_enddate' |translate:'LABELS'}}<span class="subtitle-ternary">{{item.endDateTime ? (item.endDateTime | moment : 'DD MMM YYYY') : '-' }}
                  <span>{{item.dosageBreakdown}}</span></span></p>
                  </div>
              </div>
            </div>
            <div class="prescription-icon hide" *ngIf="false">
              <img class="transform" src="assets/icons/more.svg" />
            </div>
        </div>
        <div class='show padding-top'>
          {{ 'prescricption_lbl_startdate' |translate:'LABELS'}}<span class="subtitle-ternary">{{item.startDateTime | moment : 'DD MMM YYYY'}}</span>
          {{ 'prescricption_lbl_enddate' |translate:'LABELS'}}<span *ngIf="item.endDateTime" class="subtitle-ternary end">{{item.endDateTime ? (item.endDateTime | moment : 'DD MMM YYYY') : '-' }}</span>
          <p class="subtitle-ternary padding-top">{{item.dosageBreakdown}}</p>
          </div>
        </div>
        <div class="order-cart-con"  (click)="openPharmacy(items)">
          <img src="assets/icons/cart.svg">
          <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
          <img src="assets/icons/logo.svg">
          <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
        </div>
      </ng-container>
    </div>
</ng-container>
<div class="group"
*ngFor="let report of uploadedDocs | searchFilter:search:'note'" >
<div class="group-data cursor-pointer" (click)="initViewPersonal(report)">
  <div class="data-cell  l-align flex">
    <div class="align_center">
      <span >
        <img class="type-image" src="assets/icons/uploadreport.svg" />
      </span>
    </div>
    <div class="line-spacing"> 
      <span class="bold">{{report['header']['note'] }}</span>
      <br>
      <span class="title-sub font-light-small">
        {{report['header']['reportDate'] | moment : 'DD MMM YYYY'}}
      </span>
    </div>

  </div>
  <div class="data-cell r-align">
    <div class="button-style danger cursor-pointer"
      (click)="$event.stopPropagation();removeDownloadPersonal(report)" [appRemoveFeatureOff]="'FT_APP_HR_DELETEDOCS'">
      <img src="assets/icons/delete.svg" />
    </div>
    <div class="button-style cursor-pointer"
      (click)="$event.stopPropagation();initDownloadPersonal(report)" [appRemoveFeatureOff]="'FT_APP_HR_UPLOADDOCS'">
      <img src="assets/icons/download.svg" />
    </div>
  </div>
</div>
</div>
</div>
</div> -->

<div class="hr-prescriptions-container">
  <div class="hr-prescription-toolbar" *ngIf="!hideFilters">
    <div class="toggle-option">
      <ng-container *ngFor="let tab of viewTabs">
        <div class="option" [ngClass]="{selected: selectedTab == tab['code']}"
        (click)="chooseTab(tab)">
        {{tab['label'] | translate}}</div>
      </ng-container>
    </div>
    <div class="search" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon"  (click)="searchIcon()">
          <img class="type-image" src="./assets/icons/Book_new/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_medicine'|translate:'LABELS'}}" [(ngModel)]="searchTerm" (input)="searchFilter($event)" />
        </div>
      </div>
    </div>
  </div>

  <div class="hr-meds-wrapper" *ngIf="selectedTab !== 'UP'">
    <div class="hr-meds-accordian" *ngFor="let items of prescriptions  | groupBy : 'orderId'">
      <div class="hr-meds-header" (click)="items[0]['_expanded'] = !items[0]['_expanded']">
        <div class="order-name-wrapper">
          <p class="med-title">{{( items[0].prescriberId | partitionerDetails: items[0].facilityId:'resourceName'| translate : 'LOCALE' : 'name') || items[0]['prescribedByName']}}</p>
          <p class="med-subtitle">{{(items[0]['facilityId']| facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name')|| items[0]['facilityName']}}</p>
        </div>
        <div class="order-id-wrapper">
          <p class="med-subtitle">{{items[0].startDateTime | moment : 'DD MMM YYYY' }}</p>    
          <p class="med-subtitle sm-hidden">{{'Order_id' | translate}}: {{items[0].orderId}}</p>    
        </div>
        <div class="print-wrapper sm-hidden" *ngIf="enablePrintPrescriptionYn" (click)="printPrescription(items[0]);$event.stopPropagation()">
          <img src="./assets/icons/Health_records/print.svg" alt="">
        </div>
        <ng-container *ngIf="items[0].erxInfo">
          <ng-container *ngIf="items[0].erxInfo.isOrderPlaced; else canPlaceOrder">
            <div class="dispatched-con sm-hidden"  (click)="openPharmacy(items);$event.stopPropagation()" >
              <p>{{'PRESCRIPTION#CART#ORDERED' | translate}}</p>
              <img src="assets/icons/success.svg">
            </div>
          </ng-container>
          <ng-template #canPlaceOrder>
            <div class="sm-hidden">
              <ng-container *ngIf="!items[0].erxInfo.canPlaceOrder">
                <div class="order-cart-con sm-hidden"  [ngClass]="{'disabled-but-css':!items[0].erxInfo.canPlaceOrder}" >
                  <img src="assets/icons/cart.svg">
                  <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
                  <img src="assets/icons/logo.svg">
                  <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
                </div>
              </ng-container>
              <div class="order-cart-con sm-hidden"  *ngIf="items[0].erxInfo.canPlaceOrder" (click)="openPharmacy(items);$event.stopPropagation()" >
                <img src="assets/icons/cart.svg">
                <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
                <img src="assets/icons/logo.svg">
                <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
              </div>
              <div class="unavail-item" *ngIf="!items[0].erxInfo.canPlaceOrder">
                <img src="assets/icons/infoicon.svg">
                <p>{{'PRESCRIPTION#CART#UNAVAIL' | translate}}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <!-- <div class="order-cart-con sm-hidden"  (click)="openPharmacy(items);$event.stopPropagation()" >
          <img src="assets/icons/cart.svg">
          <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
          <img src="assets/icons/logo.svg">
          <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
        </div> -->
        <div class="arrow-icon" [ngClass]="{'transforms': items[0]['_expanded']}" (click)="items[0]['_expanded'] = !items[0]['_expanded']">
          <img src="assets/icons/Home/right arrow.svg" alt="">
        </div>
      </div>
      <div class="hr-meds-content"*ngIf="items[0]['_expanded']">
        <div class="hr-meds-item md-hidden">
          <div class="prescription-info">
            <p class="med-subtitle">{{'Order_id' | translate}}: {{items[0].orderId}}</p>    
          </div>
          <div class="prescription-info">
            <div class="print-wrapper" *ngIf="enablePrintPrescriptionYn" (click)="printPrescription(items[0])">
              <img src="./assets/icons/Health_records/print.svg" alt="">
            </div>
          </div>
        </div>
        <div class="hr-meds-item" *ngFor="let item of items">
          <div class="prescription-info">
            <p class="med-title"><img src="./assets/icons/Health_records/medication.svg" alt=""> {{item.genericText || item.drugText}}</p>
            <p class="med-subtitle" *ngIf="item.genericText && item.drugText && showBrandName">{{'Brand_name' | translate}}: 
              <span class="subtitle-black">{{item.drugText}}</span></p>
          </div>
          <div class="prescription-details">
            <p class="med-subtitle">{{ 'prescricption_lbl_dose' |translate:'LABELS'}} 
              <span class="subtitle-black">{{item.dosage}} {{item.dosageUomCode}}</span>
              {{ 'prescricption_lbl_frequency' |translate:'LABELS'}} <span class="subtitle-black">{{item.frequencyText}}</span></p>
              <p class="med-subtitle">
                {{ 'prescricption_lbl_startdate' |translate:'LABELS'}} <span class="subtitle-black">{{item.startDateTime | moment : 'DD MMM YYYY'}}</span>
                  {{ 'prescricption_lbl_enddate' |translate:'LABELS'}} <span class="subtitle-black">{{item.endDateTime ? (item.endDateTime | moment : 'DD MMM YYYY') : '-' }}
                  <span> {{item.dosageBreakdown}}</span></span>
              </p>
          </div>
        </div>
        <ng-container *ngIf="items[0].erxInfo">
          <ng-container *ngIf="items[0].erxInfo.isOrderPlaced; else canPlaceOrder">
            <div class="hr-md-view-dispatched-con dispatched-con md-hidden"  (click)="openPharmacy(items);$event.stopPropagation()" >
              <p>{{'PRESCRIPTION#CART#ORDERED' | translate}}</p>
              <img src="assets/icons/success.svg">
            </div>
          </ng-container>
          <ng-template #canPlaceOrder>
            <div class="hr-meds-item md-hidden">
              <ng-container *ngIf="!items[0].erxInfo.canPlaceOrder">
                <div class="order-cart-con"  [ngClass]="{'disabled-but-css':!items[0].erxInfo.canPlaceOrder}" >
                  <img src="assets/icons/cart.svg">
                  <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
                  <img src="assets/icons/logo.svg">
                  <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
                </div>
              </ng-container>
              <div class="order-cart-con"  *ngIf="items[0].erxInfo.canPlaceOrder" >
                <img src="assets/icons/cart.svg">
                <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
                <img src="assets/icons/logo.svg">
                <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
              </div>
              <div class="unavail-item" *ngIf="!items[0].erxInfo.canPlaceOrder">
                <img src="assets/icons/infoicon.svg">
                <p>{{'PRESCRIPTION#CART#UNAVAIL' | translate}}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <!-- <div class="hr-meds-item md-hidden">
          <div class="order-cart-con"  (click)="openPharmacy(items)" >
            <img src="assets/icons/cart.svg">
            <p class="order-from">{{'epres_order_from_label' |translate:'LABELS'}}</p>
            <img src="assets/icons/logo.svg">
            <p class="order-pharmacy">{{'epres_order_from_value' |translate:'LABELS'}}</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="hr-visit-report-container" *ngIf="selectedTab == 'UP'">
    <div class="hr-visit-report-header sm-hidden">
      <div class="doc-name header-item">{{'Document_name' | translate}}</div>
      <div class="doc-date header-item">{{'Date_and_time' | translate}}</div>
    </div>
    <div class="hr-visit-report-content">
      <div class="hr-visit-report-item" *ngFor="let report of uploadedDocs" (click)="initViewPersonal(report)">
        <div class="doc-name content-item"> <img src="./assets/icons/Health_records/file.svg" alt=""> {{report['header']['chatHeader']? report['header']['fileName'] : report['header']['note']}}</div>
        <div class="doc-date content-item">{{report['header']['reportDate'] | moment : 'D MMM YYYY, hh:mm A'}}</div>
      </div>
    </div>
  </div>
</div>