import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import {AppointmentService} from '../services/appointment.service';
import * as moment from 'moment';
import { TranslatePipe } from '../pipes/translate.pipe';
import { LinkPatientService } from '../services/link-patient.service';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public messages:any={
    events:  {},
    alerts:  {}
  };
  public originalAlertData:any;

  constructor(private user: UserService, private http: HttpClient, private appointmentservice: AppointmentService, private translatePipe:TranslatePipe,
    private linkPatientService: LinkPatientService) { }

  public getAlerts(isBackground): Observable<any> {
    let payload = {
      'personId': this.user.getPersonId(),
      isBackgroundProcess: isBackground
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/content/getAlerts`, payload, { reportProgress: true }).subscribe(success => {
         if (success['status'] == 'SUCCESS') {
           setTimeout(async () => {
           await this.setMyAlerts(success);
            observer.next(this.getMyAlerts());
          }, 100);
        }
      }, error => {
          observer.error()
          observer.complete();
      })
    })
  }

  public setMyAlerts(value:any){
    let event:any=[];
    var upcomingAppointments = this.appointmentservice.getUpcomingAppointmentsValue();
    console.log(upcomingAppointments);
    if(value['events']){
      event = value['events'].filter(eve => {
        eve.enableupcomingAppointments =false;
        if(eve.category=='UPDATES' || (!eve.notificationContent.appointmentRefNumber && !eve.category)){
          eve.updateEvent=true;
          eve.category='UPDATES';
        }
        // console.log(eve,'sas');
        if(upcomingAppointments && upcomingAppointments.length > 0) {
          upcomingAppointments.forEach(function (appoiment) {
            if (appoiment['appointmentId'] == eve.notificationContent.appointmentRefNumber){
              eve.upcomingAppointments = appoiment;   
              eve.enableupcomingAppointments =true;
              if(!eve.category)
              eve.category  = 'reminders';
            }
          });
        }
        if(eve.enableupcomingAppointments || eve.updateEvent)
            return eve;
      }).sort((a, b) => {
        return moment(b.notificationContent.startDate).valueOf() - moment(a.notificationContent.startDate).valueOf();
      });
    }else
      event = value['events'];
      let tmpApptEvent=[],tmpUpdateEvent=[];
      let grouped;
      event.forEach((e)=>{
        if(e['appointmentRefNumber']) tmpApptEvent.push(e)
        else tmpUpdateEvent.push(e);
      })
      this.messages.events =tmpApptEvent;
      // let hasApptRef=event.every(obj => obj.hasOwnProperty('appointmentRefNumber'))
      // console.log('before filter event--',tmpUpdateEvent,tmpApptEvent)
      if(tmpApptEvent && tmpApptEvent.length>0){  
      grouped = Object.values(this.groupItemBy(tmpApptEvent, 'appointmentRefNumber'));
      let tmp=grouped.map(g=>g[0])
      // console.log('unique event--',tmpApptEvent,grouped,tmp)
      tmpApptEvent.forEach((e)=>{
        grouped.forEach((g)=>{
          if(e['appointmentRefNumber'] && g[0]['appointmentRefNumber'])
          if(e['appointmentRefNumber']==g[0]['appointmentRefNumber']){
            e['_sameEvents']=g
            if(g.length>=2){
              e['upcomingAppointments']['alertApptTitle']=this.translatePipe.transform('NOTIFICATION#PAGE#TITLE#REMINDER');// 'Reminder'
            }else{
              if(this.checkIsafter(e['upcomingAppointments']))
                e['upcomingAppointments']['alertApptTitle']=this.translatePipe.transform('NOTIFICATION#PAGE#TITLE#REMINDER');//'Reminder'
              else
                e['upcomingAppointments']['alertApptTitle']=this.translatePipe.transform('NOTIFICATION#PAGE#TITLE#UPCOMING#APPOINTMENT');
            }
          }
        })
      })
      this.messages.events = tmp//.concat(tmpUpdateEvent)//event;
      }
      this.originalAlertData=value['data'];
      if(value['data'] && value['data'].length>0){
        value['data']=this.unique(value['data'], ['msgId', 'startDate'])//_.uniqBy(value['data'], 'msgId');
      }
      if(tmpUpdateEvent && tmpUpdateEvent.length>0){
        tmpUpdateEvent.forEach((u)=>{
          if(u['title'])
            u['title']=this.translatePipe.transform(u['title']);//'Service request';
          else  
            u['title']=this.translatePipe.transform('NOTIFICATION#PAGE#TITLE#SERVICEREQ');//'Service request';
          u['startDate']=u['notifySentTime'];
          u['body']=this.removeTags(u['notifyMessage'])
        })
      }
      let finalAlerts=value['data'].concat(tmpUpdateEvent);
    this.linkPatientService.getLinkedPatient(this.user.getPersonId()).subscribe(({ data }) => {
      finalAlerts.forEach((a) => {
        a['patientInfo'] = data.find(p => a['notificationContent'] && a['notificationContent']['patientId'] == p['patientId']);
      })
    });

      this.messages.alerts = finalAlerts;
      return Promise.resolve(finalAlerts);
  }

  public getMyAlerts(){
    console.log('this.messages;',this.messages);
    return this.messages;
}

public groupItemBy(array, property) {
  var hash = {},
      props = property.split('.');
  for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function(acc, prop) {
          return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
  }
  return hash;
}

public checkIsafter(appt){
  return moment(moment().format()).isAfter(moment(appt['appointmentDateTime'],'YYYY-MM-DD hh:mm'))
}

unique(arr, keyProps) {
  const kvArray = arr.map(entry => {
   const key = keyProps.map(k => entry[k]).join('|');
   return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
}

removeTags(str) {
  if (!str || (str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  return str.replace( /(<([^>]+)>)/ig, '');
}

public updateAlertStatus(id,type, isBackgroundProcess:boolean = false): Observable<any> {
  let payload = {
    'personId':this.user.getPersonId(),
    'ids': id,
    'action': type,
    isBackgroundProcess: isBackgroundProcess
  }
  return new Observable(observer => {
    this.http.post(`${environment['API_URL']}api/content/updateAlertStatus`, payload, { reportProgress: true }).subscribe(success => {
      if (success['status'] == 'SUCCESS') {
        setTimeout(_ => {
          this.getAlerts(true).subscribe(_=>{

          });
          observer.next(success);
        }, 100);
      }
    }, error => {
        observer.error()
        observer.complete();
    })
  })
}

public getOriginalAlerts(){
  return this.originalAlertData;
}

}
