import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { ROUTE_LOGIN } from '@string';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-pre-login-modal',
  templateUrl: './pre-login-modal.component.html',
  styleUrls: ['./pre-login-modal.component.scss']
})
export class PreLoginModalComponent {
  @Input() context:any;
  @Output() close = new EventEmitter();
  public currentLanguage:any;
  public bannerImages = {
    'en' : './assets/icons/Home/Home Screens.png',
    'ar' : './assets/icons/Home/Arabic phone.png'
  }
  constructor(private user:UserService, private router:Router, private auth: AuthService) {

  }

  ngOnInit() {
    this.currentLanguage = this.user.getCurrentLanguage();
  }
  
  closeModal() {

    this.close.emit();
  }

  public login() {
    
    this.user.getAutoSignon().subscribe(autoSignon => {
      if (autoSignon) {
        this.auth.initSSORedirection({})
      } else {
        this.router.navigate([`${ROUTE_LOGIN}`], { queryParams: this.context })
      }
    }, err => {
      this.router.navigate([`${ROUTE_LOGIN}`], { queryParams: this.context })
    })
  }

  public download(type){
    if(type == 'android'){
      let link =  environment['urlInfo']['android'];
      this.user.openLocation(link);
    }
    if(type == 'apple'){
      let link =  environment['urlInfo']['iOS'];
      this.user.openLocation(link);
    }
  }
}
