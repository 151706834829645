<div class="common-view-container" [ngClass]="{hide: state == 'INVALID'}">
    <ng-template #appFormats></ng-template>
</div>
<div *ngIf="state == 'INVALID'" class="invalid-wrapper">
  <div class="header-wrapper">
    <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
  </div>
  <div class="content-wrapper">
    <div class="nodata-wrapper">
      <img class="nodata-image" src="assets/icons/task/search.svg" alt="">
      <p class="title-txt">{{'lbl_unavailable'| translate : 'LABELS' : null}}</p>
      <p class="sub-title-txt">
        {{'lbl_view_invalid' | translate : 'LABELS' : null}}
      </p>
    </div>
  </div>
</div>