import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClinicalFormUtils } from 'src/app/clinicalform/clinicalform.utils';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { ConsultationNoteService } from 'src/app/services/consultation-note.service';

@Component({
  selector: 'app-directive-linked',
  templateUrl: './directive-linked.component.html',
  styleUrls: ['./directive-linked.component.scss']
})
export class DirectiveLinkedComponent implements OnInit {
  @Input() public section;
  @Input() public sections;
  @Input() public patient;
  @Input() public encounter;
  public controls:any;
  @Output('openConsultationNote') openConsultationNote:EventEmitter<null>; 
  constructor(private clinicalFormService: ClinicalformService, private consultationNoteService: ConsultationNoteService, private clinicalFormUtils: ClinicalFormUtils) { 
    this.openConsultationNote = new EventEmitter();
  }

  ngOnInit() {
    if(this.section.domainControls && Array.isArray(this.section.domainControls.directives) && this.section.domainControls.directives.length > 0){
      let dirs=this.section.domainControls.directives.map(d=>d.directiveCode);
      this.clinicalFormService.directiveLinkedControls(dirs, '', true)
        .subscribe((controls:any)=>{
          this.controls = controls;
          if(controls.directiveControls)
            this.section.domainControls.directives.map(d=>d['directiveControls'] = controls['directiveControls'][d.directiveCode])
        })
    }

    if(Array.isArray(this.section.selectedValues) && this.section.selectedValues.length){
      const needUpdate = this.section.selectedValues.some(s=>s._copied);
      if(!needUpdate) return;
      if(!Array.isArray(this.section._filledConcepts)) this.section._filledConcepts = [];
      this.section.selectedValues.forEach((sv,index)=>{
          if(sv._copied){
            const facilityId = this.patient.patientInfo && this.patient.patientInfo.facilityId ? this.patient.patientInfo.facilityId : this.patient.facilityId;
            let cpConcept = this.consultationNoteService.getConceptFromLocal(sv['directiveCode'], sv['id'], facilityId)
            if(cpConcept){
              if(Array.isArray(sv.values) && sv.values.length){
                let domainValues={};
                sv.values.forEach(v=> domainValues[v['code']] =v['values']);
                cpConcept = this.consultationNoteService.findAndFillForm(cpConcept,domainValues);
              }
              const savedForm = this.clinicalFormUtils.save(cpConcept.noteContent.topics, cpConcept.noteCode);
              console.log(savedForm);
              savedForm['id'] = cpConcept.id;
              savedForm['label'] = cpConcept.label;
              savedForm['directiveCode'] = cpConcept.directiveCode;
              savedForm['_copied'] = true;
              if(savedForm.topics)
                cpConcept.noteContent.topics = savedForm.topics;
              // delete res['topics'];
              savedForm['form'] = cpConcept;
              this.section['_filledConcepts'][index] = savedForm;
            }
          }
      });
    }

  }

  ngOnChanges(change){
    console.log('change in directive linked field', change)
  }

  openEditMode(){
    console.log('emitting data');
    this.openConsultationNote.emit();
  }

  editSelectedValues(index) {
    console.log(index, this.section['selectedValues'][index]);
      this.section['selectedValues'][index]['editable'] = true;
      this.openEditMode();
  }

  public deleteSelectedValues(index) {
    if(this.section.selectedValues[index]['_copied'] && this.section.selectedValues[index]['copiedToForm']){
      this.section.selectedValues[index]['copiedToForm'] = false;
      this.section._filledConcepts[index]['copiedToForm'] = false;
    }else{
      this.section.selectedValues.splice(index,1);
      this.section._filledConcepts.splice(index,1);
    }
  }

  addToForm(index){
    console.log('copying section',index);
    this.section.selectedValues[index]['copiedToForm'] = true;
    this.section._filledConcepts[index]['copiedToForm'] = true;
  }

}
