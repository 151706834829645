<div id="hr-visit-details" *ngIf="false">
  <div class="card-layout">
    <div class="card">
      <div class="card-head border-bottom hide" *ngIf="_selectedVisit">
        <span class="bold">{{'hr_vst_dtl_label_showing'|translate:'LABELS'}}</span>
        <span class="visit-type" *ngIf="_selectedVisit['patientClass'] == 'IP'">
          <img class="inactive-icon hide type-image" src="assets/icons/bed.svg" />
          <span class="type-name font-primary-small">{{'in_patient'|translate:'LABELS'}}</span>
        </span>
        <span class="visit-type" *ngIf="_selectedVisit['patientClass'] == 'OP'">
          <img class="inactive-icon hide type-image" src="assets/icons/facility.svg" />
          <span class="type-name font-primary-small">{{'out_patient_visit'|translate:'LABELS'}}</span>
        </span>
        <span class="visit-type" *ngIf="_selectedVisit['patientClass'] == 'EM'">
          <img class="inactive-icon hide type-image" src="assets/icons/ambulance.svg" />
          <span class="type-name font-primary-small">{{'emergency'|translate:'LABELS'}}</span>
        </span>
        <span class="visit-type">
          <img class="inactive-icon hide type-image" src="assets/icons/calendar-simple.svg" />
          <span class="type-name font-primary-small">{{_selectedVisit['admnDate']|moment:'DD MMM YYYY'}}</span>
        </span>
        <span class="visit-type hide">
          <img class="inactive-icon hide type-image" src="assets/icons/doctor.svg" />
          <span class="type-name font-primary-small">
            {{(_selectedVisit['practitionerId'].split('#').shift() | partitionerDetails:_selectedVisit['facilityId']:'resourceName'| translate : 'LOCALE' : 'name')||(_selectedVisit['practitionerId'].split('#').pop())}}
          </span>
        </span>
        <span class="visit-type hide">
          <span class="type-name font-light-small">
            {{(_selectedVisit['specialtyCode'].split('#').shift() | specialityDetails:_selectedVisit['facilityId']:'specialityName'| translate : 'LOCALE' : 'name')||_selectedVisit['specialtyCode'].split('#').pop()}}
          </span>
        </span>
        <span class="close-button cursor-pointer" (click)="clear()">
          <img src="assets/icons/close.svg"  />
        </span>
      </div>
      <div class="card-content">
        <div class="nav-tabs-custom">
          <ul>
            <li class="cursor-pointer visitHistory" (click)="changeActiveTab('visitHistory')">
              <a [ngClass]="{active: tab=='visitHistory', bold: tab=='visitHistory'}">{{'ttl_visit_history'|translate:'LABELS'}}</a>
            </li>
            <li class="cursor-pointer" (click)="changeActiveTab('summary')"
            [appRemoveFeatureOff]="'FT_APP_HR_SUMMARY'">
            <a [ngClass]="{active: tab=='summary', bold: tab=='summary'}">{{'tab_summary'|translate:'LABELS'}}</a>
          </li>
            <li class="cursor-pointer" (click)="changeActiveTab('reports')"
              [appRemoveFeatureOff]="'FT_APP_HR_REPORT'">
              <a [ngClass]="{active: tab=='reports', bold: tab=='reports'}">{{'tab_reports'|translate:'LABELS'}}</a>
            </li>
            <li class="cursor-pointer" (click)="changeActiveTab('prescription')"
              [appRemoveFeatureOff]="'FT_APP_HR_PRESCRIPTION'">
              <a [ngClass]="{active: tab=='prescription', bold:
                tab=='prescription'}">{{'tab_prescription'|translate:'LABELS'}}</a>
            </li>
            <li class="cursor-pointer" (click)="changeActiveTab('bills')"
              [appRemoveFeatureOff]="'FT_APP_HR_BILLS'">
              <a [ngClass]="{active: tab=='bills', bold: tab=='bills'}">{{'tab_bills'|translate:'LABELS'}}</a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <app-hr-visit-history *ngIf="isVisitHistory" (chooseVisit)="chooseVisit($event)"
            [selectedPatient]="_selectedPatient" [selectedVisit]="_selectedVisit">
          </app-hr-visit-history>
          <app-hr-visit-summary *ngIf="isVisitSummary" (chooseVisit)="chooseVisit($event)"
          [selectedPatient]="_selectedPatient" [selectedVisit]="_selectedVisit">
        </app-hr-visit-summary>
          <app-hr-visit-report *ngIf="isVisitReport" [selectedVisit]="_selectedVisit"
            [selectedPatient]="_selectedPatient" (selectReport)="chooseReport($event)"
            (openDocsUpload)="openDocUpload()" [refresh]="refresh1.asObservable()">
          </app-hr-visit-report>
          <app-hr-visit-prescription *ngIf="isVisitPrescription" [selectedVisit]="_selectedVisit"
            [selectedPatient]="_selectedPatient" (selectReport)="chooseReport($event)" (openDocsUpload)="openPrescriptionUpload()" [refresh]="refresh1.asObservable()" ></app-hr-visit-prescription>
          <app-hr-visit-bills *ngIf="isVisitBills" [selectedVisit]="_selectedVisit"
            [selectedPatient]="_selectedPatient"></app-hr-visit-bills>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="hr-visit-details-wrapper">
  <div class="empty">
  </div>
  <div class="hr-visit-details-modal">
    <div class="hr-visit-details-header">{{'Visit_details' | translate}} <img src="./assets/icons/close_black.svg" alt="" class="close-icon" (click)="clear()"></div>
    <div class="selected-patient-banner" *ngIf="_selectedPatient">
      <p class="selected-patient">{{_selectedPatient['personname']}} <span class="selected-patient-info">     {{_selectedPatient.gender}},
        {{_selectedPatient.dob | age}},
        {{_selectedPatient.relationshiptype|translate:'RELATION'}},
        {{_selectedPatient.uhid}}</span></p>
    </div>
    <div class="hr-visit-details-toolbar">
      <div class="toggle-option">
        <ng-container *ngFor="let t of visitTabs">
          <div class="option" [appRemoveFeatureOff]="t['flag']" [ngClass]="{selected: tab == t['code']}"
            (click)="tab = t['code']">
            {{t['label'] | translate}}</div>
        </ng-container>
      </div>
    </div>
    <div class="hr-visit-details-content">
      <ng-container [ngSwitch]="tab">
        <ng-container *ngSwitchCase="'DETAILS'">
          <div class="visit-details-wrapper">
            <div class="visit-details-partition">

            <div class="visit-details-card">
              <div class="visit-details-section">
                <p class="visit-details-title">{{'Appointment_type' | translate}}</p>
                <p class="visit-details-label">{{_selectedVisit['admissionType']}}</p>
              </div>
              <div class="visit-details-section">
                <p class="visit-details-title">{{'Appointment_for' | translate}}</p>
                <div class="person-card">
                  <div class="person-avatar-wrapper">
                    <app-person-avatar [url]="_selectedPatient['imageUrl']"> </app-person-avatar>
                  </div>
                  <div class="person-context">
                    <div class="person-title">{{_selectedPatient['personname']}}</div>
                    <div class="person-subtitle"> {{_selectedPatient['uhid']}}</div>
                  </div>
                </div>
              </div>
              <div class="visit-details-section">
                <p class="visit-details-title">{{'Consulting_doctor' | translate}}</p>
                <div class="person-card">
                  <div class="person-avatar-wrapper">
                    <app-person-avatar [url]=""> </app-person-avatar>
                  </div>
                  <div class="person-context">
                    <div class="person-title">{{(_selectedVisit['practitionerId'].split('#').shift() | partitionerDetails:_selectedVisit['facilityId']:'resourceName'| translate : 'LOCALE' : 'name')||(_selectedVisit['practitionerId'].split('#').pop())}}</div>
                    <div class="person-subtitle"> {{(_selectedVisit['specialtyCode'].split('#').shift() | specialityDetails:_selectedVisit['facilityId']:'specialityName'| translate : 'LOCALE' : 'name')||_selectedVisit['specialtyCode'].split('#').pop()}}</div>
                  </div>
                </div>
              </div>
              <div class="visit-details-section">
                <p class="visit-details-title">{{'Date_and_time' | translate}}</p>
                <p class="visit-details-label">{{_selectedVisit['admnDate'] | moment:'D MMM YYYY hh:mm A'}}</p>
              </div>
            </div>
          </div>
          <div class="visit-details-partition">
            <p class="visit-partition-title">{{'Payment_details' | translate}}</p>
            <div class="visit-details-card">
              <div class="visit-payment-section">
                <span class="visit-payment-label">{{'Payment_catagory' | translate}}</span>
                <span class="visit-payment-value">{{_selectedVisit['settlementIndicator']}}</span>
              </div>
            </div>
          </div>

          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PRES'">
          <div class="hr-visit-prescription-wrapper">
            <app-hr-visit-prescription [selectedVisit]="_selectedVisit" [hideFilters]="true"
              [selectedPatient]="_selectedPatient" (selectReport)="chooseReport($event)"
              (openDocsUpload)="openPrescriptionUpload()" [refresh]="refresh1.asObservable()"></app-hr-visit-prescription>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'REPORTS'">
          <div class="hr-visit-report-wrapper">
            <app-hr-visit-report [selectedVisit]="_selectedVisit" [selectedPatient]="_selectedPatient" type="REPORTS"
            [isCommonView]="isCommonView" [qrCodeUrl]="qrCodeUrl" [reportId]="reportId"
              (selectReport)="chooseReport($event)" (openDocsUpload)="openDocUpload()" [refresh]="refresh1.asObservable()">
            </app-hr-visit-report>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'BILLS'">
          <div class="hr-visit-bill-wrapper">
              <app-hr-visit-bills [selectedVisit]="_selectedVisit" [selectedPatient]="_selectedPatient"></app-hr-visit-bills>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'TRANSCRIPT'">
          <div class="hr-visit-transcript-wrapper">
              <app-patient-chat [encounter]="_selectedVisit" [patient]="_selectedPatient"></app-patient-chat>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>

      </ng-container>
    </div>
  </div>
</div>