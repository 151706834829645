import { Component, ElementRef, Input, OnInit, OnDestroy,ViewEncapsulation, Output, EventEmitter, } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { enterAnimation, enterAnimationAxisX } from './../animations';
@Component({
  selector: 'app-custom-modal-container',
  templateUrl: './custom-modal-container.component.html',
  styleUrls: ['./custom-modal-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
//   animations: [enterAnimation, enterAnimationAxisX]
})

export class CustomModalContainerComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() isCommonView: boolean;
    @Input() isModalLeftAlign: boolean;
    @Output() complete: EventEmitter<any>;
    private element: any;
    public isClosed: boolean;
    public content = false;

    constructor(private modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
        this.complete = new EventEmitter<any>();
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', function (e: any) {
            if (e.target.className === 'jw-modal') {
                modal.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        console.log("open",this.id)
        this.element.style.display = 'block';
        document.body.classList.add('jw-modal-open');
        // document.body.classList.add('showModal');
        this.isClosed = false;
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('jw-modal-open');
        // document.body.classList.remove('showModal');
        this.isClosed = true;
        this.complete.emit();
    } 
}