import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { Subject } from "rxjs";
@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {

  public viewProfileForm: any;
  public personDetails: any;
  public extInterface: boolean;
  
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  constructor(private user: UserService, private authService:AuthService) { 
    this.viewProfileForm = { sub: "", firstname: "", email_verified: "", phone_verified: "", language: "", phone: "", gender: "", dob: "", email: "", lastname: "", username: "" }
    this.user.getExternalInterface().subscribe(data => {
      this.extInterface = data;
    })
  }

  ngOnInit(): void {
    this.refresh.subscribe(response => {
      if (response) {
        console.log("observable", response)
        this.setName();
      }
    })
    this.getUserProfile();
    this.setName();
  }
  private getUserProfile() {
    
    this.authService.getUserProfile().subscribe(data => {
      let gender = data.gender ? data.gender : data.Gender;
      let language = data.language ? data.language : data.Language;
      let birth_date;
      if(data.birth_date){
        birth_date = this.user.getMoment(data.birth_date).format('DD MMM YYYY')
      }
      // let birth_date = data.birth_date ? data.birth_date : data['Birth Date'];
      this.viewProfileForm = {
        ...data,
        dob: birth_date,
        gender: gender,
        language: language
      };
      console.log(this.user.getMoment(data.birth_date).format('DD MMM YYYY'));
    })
  }

  public setName(){
    if(this.extInterface){
      this.user.getSSOUserObject().subscribe( data => {
        this.personDetails = {
          personName: data.firstname + " " + data.lastname
        }
        console.log("setName data", data)
      })
    }else {
      this.user.getUserInfo('personDetails').subscribe(data => {
        this.personDetails = data;
      })
    }
  }

}
