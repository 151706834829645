import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicPatientComponent } from './express-clinic-patient.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [ExpressClinicPatientComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, PersonAvatarModule, RegisterPatientModule, CustomModalModule,
    PractitionerAvatarModule,
    ManageIdentitiesModule, BrowserModule
  ],
  
  exports: [ExpressClinicPatientComponent]
})
export class ExpressClinicPatientModule { }
