<div id="view-report" class="right-nav showModal" [ngClass]="{'common-view' :isCommonView}">
  <div (click)="closeModel()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">
        <span>
          {{report['header']['chatHeader']? report['header']['fileName'] : report['header']['note']}}
        </span>
      </div>
      <div *ngIf="!isCommonView" (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="options">
      <div class="practitioner font-primary-subtitle bold">
      </div>
      <div class="actions">
        <ngx-qrcode (click)="expandQR()" [elementType]="'url'" [value]="qrcodeUrl.thumbnail" cssClass="asclass"
          errorCorrectionLevel="L">
        </ngx-qrcode>
        <div class="button-style cursor-pointer" [appRemoveFeatureOff]="'FT_APP_HR_DOWNLOADDOCS'"
          (click)="startDownload()">
          <img src="assets/icons/download.svg" />
        </div>
        <div class="button-style cursor-pointer" *ngIf="false" (click)="startDownload()">
          <img class="type-image" src="assets/icons/share.svg" />
        </div>
      </div>
    </div>
    <div class="container-content">
     <pdf-viewer [src]="report['url']" (after-load-complete)="stopLoader()" style="display: block;"></pdf-viewer>
    </div>
  </div>
</div>
<div class="qrPreview" *ngIf="expandQRCode">
  <ngx-qrcode [elementType]="'url'" [value]="qrcodeUrl.location" cssClass="abclass" errorCorrectionLevel="L">
  </ngx-qrcode>
  <div (click)="expandQR()" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div>
</div>