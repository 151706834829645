import { Routes } from '@angular/router';
import { PATH_MATCH_FULL, ROUTE_LOGIN, ROUTE_CRM_HOME, ROUTE_CRM_CALENDAR, ROUTE_CRM_HEALTH_RECORDS, ROUTE_CRM_PAYMENT, ROUTE_TASK, ROUTE_UNAUTHORIZED, ROUTE_VACCINE, ROUTE_VIEW, ROUTE_CRM_PATIENT_BILLING, ROUTE_WEBVIEW, ROUTE_VISIT, ROUTE_APPT, ROUTE_BILLPAY, ROUTE_EMBED_APPT } from './app.string';
import { LoginComponent } from './login/login.component';
import { CrmComponent } from './crm/crm.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { HealthRecordsComponent } from './health-records/health-records.component';
import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { GaurdService } from './services/gaurd.service';
import { TaskComponent } from './task/task.component';
import { AppEnabledService } from './services/app-enabled.service';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { VaccinationFormComponent } from './vaccination-form/vaccination-form.component';
import { CommonViewComponent } from './common-view/common-view.component';
import { PatientBillingComponent } from './patient-billing/patient-billing.component';
import { VisitStandaloneComponent } from './visit-standalone/visit-standalone.component';
import { AppointmentPreviewComponent } from './appointment-preview/appointment-preview.component';
import { BillPaymentComponent } from './bill-payment/bill-payment.component';
import { QueuePreviewComponent } from './queue-preview/queue-preview.component';
import { EmbedApptComponent } from './embed-appt/embed-appt.component';


export const ROUTES: Routes = [
  { path: '', pathMatch: PATH_MATCH_FULL, redirectTo: 'portal' },
  { path: ROUTE_LOGIN, component: LoginComponent, canActivate: [AppEnabledService] },
  { path: ROUTE_WEBVIEW, component: LoginComponent, canActivate: [AppEnabledService] },
  {
    path: 'portal',
    component: CrmComponent,
    canActivate: [AppEnabledService],
    children: [
      { path: '', pathMatch: PATH_MATCH_FULL, redirectTo: ROUTE_CRM_HOME },
      { path: ROUTE_CRM_HOME, component: HomeComponent, data: { name: ROUTE_CRM_HOME, permission: 'FT_APP_TAB_HOME'}, canActivate: [AppEnabledService] },
      { path: ROUTE_CRM_CALENDAR, component: CalendarComponent, data: { name: ROUTE_CRM_CALENDAR, permission: 'FT_APP_TAB_CALENDAR' }, canActivate: [AppEnabledService, GaurdService] },
      { path: ROUTE_CRM_HEALTH_RECORDS, component: HealthRecordsComponent, data: { name: ROUTE_CRM_HEALTH_RECORDS, permission: 'FT_APP_TAB_HR' }, canActivate: [AppEnabledService,GaurdService] },
      { path: ROUTE_CRM_PAYMENT, component: PaymentCheckoutComponent, data: { name: ROUTE_CRM_PAYMENT} },
      { path: ROUTE_CRM_PATIENT_BILLING, component: PatientBillingComponent, data: { name: ROUTE_CRM_PATIENT_BILLING, permission: 'FT_APP_TAB_BILLING' }, canActivate: [AppEnabledService,GaurdService] },
    ]
  },
  { path: ROUTE_TASK + '/:tid', component: TaskComponent },
  { path: ROUTE_TASK, component: TaskComponent, canActivate: [GaurdService, AppEnabledService] },
  { path: ROUTE_VIEW + '/:key', component: CommonViewComponent },
  { path: ROUTE_APPT + '/:key', component: AppointmentPreviewComponent },
  { path: ROUTE_VACCINE, component: VaccinationFormComponent },
  { path: ROUTE_VISIT, component:  VisitStandaloneComponent },
  { path: ROUTE_BILLPAY+'/:key', component: BillPaymentComponent },
  { path: ROUTE_UNAUTHORIZED, component: UnauthorizedPageComponent, canActivate: [AppEnabledService], data: { permission:'FT_UNAUTHORIZED'}},
  { path:'queue'+'/:key',component : QueuePreviewComponent },
  { path:'speak'+'/:key',component : QueuePreviewComponent },
  { path:ROUTE_EMBED_APPT,component : EmbedApptComponent },
  { path:ROUTE_EMBED_APPT + '/:key',component : EmbedApptComponent },
]
