import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { Object, HpApiResponse } from '../app.type';
import { UserService } from './user.service';
import * as AppConstant from '../app.string';
import { LANG_SELECTED } from "../app.const";

import { CookieService } from './cookie.service';
import { InterceptorService } from './interceptor.service';
import { UtilitiesService } from './utilities.service';
import { SocketService } from "./socket.service";
import { Idle } from '@ng-idle/core';
import { getLocaleWeekEndRange } from '@angular/common';
import { ToastServiceService } from './toast-service.service';
import { TOAST_SUCCESS, PORTAL_SOURCE } from '@string';
import { Location } from '@angular/common';
import { GoogleTagsService } from './google-tags.service';
import { FeatureFlagsService } from './feature-flags.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private externalInterface: boolean;
  
  constructor(private user: UserService, private cookie: CookieService, private interceptor: InterceptorService, public socket: SocketService, private http: HttpClient, private idle: Idle, private util: UtilitiesService, private toast: ToastServiceService,
    private location: Location, private gtmService: GoogleTagsService, private featureService: FeatureFlagsService) {
    this.user.getExternalInterface().subscribe(data => { 
      this.externalInterface = data;
    })

  }
  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    return 'Something went wrong';
  }
  public preCheck(countryCode: string, mobile: string, natid: string): Observable<any>{
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      // localeCode: LANG_SELECTED
      localeCode: this.user.currentLanguage,
      externalId: natid,
      source: PORTAL_SOURCE,
      device: {
        uuid: "a735823e1d9f00d6"
      }
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/precheck`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public login(countryCode: string, mobile: string, pin: string, otp?: string, patientId?: string, natid?:string): Observable<any>{
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      pin: pin,
      patientId: patientId,
      source: PORTAL_SOURCE,
      device: {
        uuid: "a735823e1d9f00d6"
      },
      externalId: natid
    };
    if (otp) {
      payload['otp'] = otp;
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/signin`, payload,{ reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
          this.gtmService.pushGTMEvent(null, 'login',{event_label:`Login Failed:${error?.error?.error}`})
        observer.error(err);
      })
    })
  }
  public sendOTP(countryCode: string, mobile: string, natid, isVerifyMobile: boolean = false, captcha?:any): Observable<any> {
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      // localeCode: LANG_SELECTED
      localeCode: this.user.currentLanguage,
      source: PORTAL_SOURCE,
      externalId: natid,
      isVerifyMobile: isVerifyMobile,
      captcha: captcha
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/sendOtp`, payload,{ reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
        // this.gtmService.pushGTMEvent(null, 'signup',{event_label:`Signup Failed:${err}`})
      observer.error(err);
      })
    })
  }
  public signUp(countryCode: string, mobile: string, pin: string, otp: string, fname: string = '', lname: string = '', email: string = '', postcode: string = '', patientId: string, natid: string): Observable<any> {
    fname = fname ? fname : '';
    lname = lname ? lname : '';
    let personName = (`${fname} ${lname}`).trim();
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      pin: pin,
      otp: otp,
      personName: personName,
      email: email,
      postcode: postcode,
      localeCode: this.user.currentLanguage,
      source: PORTAL_SOURCE,
      device: {
        uuid: "a735823e1d9f00d6"
      },
      patientId: patientId,
      externalId: natid
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/signup`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
        this.gtmService.pushGTMEvent(null, 'signup',{event_label:`Signup Failed:${error?.error?.error}`})
      observer.error(err);
      })
    })
  }
  public resetPin(countryCode: string, mobile: string, pin: string, otp: string): Observable<any> {
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      pin: pin,
      otp: otp,
      source: PORTAL_SOURCE,
      device: {
        uuid: "a735823e1d9f00d6"
      }
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/resetpin`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public signOut(): Observable<any>{
    return Observable.create(observer => { 
      this.user.removeUserInfo();
      observer.next();
    })
  }

  //SSO FLOW
  public ssoLogin(countryCode: string, mobile: string, pin: string, refresh_token: string): Observable<any>{
    let payload;
    if (!refresh_token) {
      payload = {
        mobile: mobile,
        countryCode: countryCode,
        password: pin,
        source: PORTAL_SOURCE
      };
    } else {
      payload = {
        source: PORTAL_SOURCE,
        refreshToken: refresh_token
      }
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/signin`, payload, { reportProgress: true}).subscribe(success => {
        if(success['status']=== 'SUCCESS'){
          this.user.setAccessToken(success['ssocredentials']['access_token']);
        }
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
        this.gtmService.pushGTMEvent(null, 'login',{event_label:`Login Failed:${error?.error?.error}`})
      observer.error(err);
      })
    })
  }
  public validateSSORedirection(code, state): Observable<any>{
    let payload = {
      authorization_code: code,
      state: state,
      source: PORTAL_SOURCE
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/validateRedirection`, payload, { reportProgress: true}).subscribe(success => {
        if(success['status']=== 'SUCCESS'){
          this.user.setAccessToken(success['ssocredentials']['access_token']);
        }
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
        this.gtmService.pushGTMEvent(null, 'login',{event_label:`Login Failed:${error?.error?.error}`})
      observer.error(err);
      })
    })
  }

  public initSSORedirection(params) {
    console.log(params)
    let queryParams = Object.keys(params).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
    }).join('&')
    const redirectUrl = [`${environment['API_URL']}sso/ssoRedirect`, queryParams].filter(a => !!a).join('?');
    console.log(redirectUrl)
    // return;
    window.open(redirectUrl, '_self');
  }

  public ssoSignUp(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/signup`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        let err = this.getErrorMessage(error['error']);
        this.gtmService.pushGTMEvent(null, 'signup',{event_label:`Signup Failed:${error?.error?.error}`})
      observer.error(err);
      })
    })
  }
  public activateAccount(txId: string = '', otp: string = '', countryCode: string = '', mobile: string = '', pin: string = '', signupPayload): Observable<any> {
    let payload = {
      txId: txId,
      mobile: mobile,
      countryCode: countryCode,
      password: pin,
      otp: otp,
      source: PORTAL_SOURCE,
      signupPayload: signupPayload
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/activate`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
        this.gtmService.pushGTMEvent(null, 'signup',{event_label:`Signup Failed:${error?.error?.error}`})

      })
    })
  }
  public resetPasswordRequest(countryCode: string='',mobile: string=''): Observable<any> {
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      preferredMethod: "SMS",
      source: PORTAL_SOURCE
    };
    console.log("resetPasswordRequest", payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/forgotPassword`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
          observer.error(this.getErrorMessage(error));
      })
    })
  }
  public resetPassword(txId: string='' , otp: string='', pin: string=''): Observable<any> {
    let payload = {
      txId: txId,
      otp: otp,
      password: pin,
      confirmPassword: pin,
      source: PORTAL_SOURCE
    };
    console.log("resetPasswordRequest", payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/verifyForgotPassword`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public resendOTP(txId: string=''): Observable<any> {
    let payload = {
      txId: txId,
      source: PORTAL_SOURCE
    };
    console.log("resendOTP", payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/resendActivationCode`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public ssoSignOut(): Observable<any>{
    return Observable.create(observer => {
      if (this.user.getIsOpenSSOLogin()) {
        this.user.clearStorage();
        const redirectUrl = `${environment['API_URL']}patientview/ssoLogout`;
        window.open(redirectUrl, '_self');
      } else {
        this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/logout`, '', { reportProgress: true }).subscribe(data => {
          observer.next(data);
        }, error => {
          observer.next(this.getErrorMessage(error));
        })
      }
    })
  }
  public changePassword(oldPass, newPass): Observable<any>{
    let payload = {
      currentPassword: oldPass,
      newPassword: newPass,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/updatePassword`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.changePassword(payload.currentPassword,payload.newPassword);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }
  public changePin(oldPass, newPass, mobile, cc): Observable<any>{
    let payload = {
      newPin: newPass,
      oldPin: oldPass,
      mobile: mobile,
      countryCode: cc,
    }
    if(this.featureService.featureOn('FT_APP_EXTIDLOGIN'))
      payload['externalId'] = this.user.getPersonNationalId();
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/changepin`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
          observer.error(this.getErrorMessage(error));
        // observer.error(this.getErrorMessage(error));
      })
    })
  }

  public updateMobile(countryCode: string='',mobile: string=''): Observable<any>{
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/updateUserMobile`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.updateMobile(payload.countryCode,payload.mobile);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }
  public precheckMobile(countryCode: string='',mobile: string=''): Observable<any>{
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      device: {
        uuid: "a735823e1d9f00d6"
      }
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/precheck`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
          observer.error(this.getErrorMessage(error));
        // observer.error(this.getErrorMessage(error));
      })
    })
  }

  public verifyUpdateMobile(txId: string='',otp: string='',countryCode: string='',mobile: string='',userId: string=''): Observable<any> {
    let payload = {
      txId: txId,
      mobile: mobile,
      countryCode: countryCode,
      personId: userId,
      otp: otp,
      source: PORTAL_SOURCE
    };
    console.log("verifyUpdateMobile", payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/verifyUserMobile`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.verifyUpdateMobile(payload.txId, payload.otp, payload.countryCode, payload.mobile, payload.personId);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }
  public changeLoginId(otp: string='',countryCode: string='',mobile: string='',userId: string=''): Observable<any> {
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      personId: userId,
      otp: otp
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/changeloginid`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
          
          observer.error(this.getErrorMessage(error));
        // observer.error(this.getErrorMessage(error));
      })
    })
  }

  public updateEmail(email: string=''): Observable<any>{
    let payload = {
      email: email,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/updateUserEmail`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.updateEmail(payload.email);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }

  public verifyUpdateEmail(txId: string='',otp: string='',email: string='',userId: string=''): Observable<any> {
    let payload = {
      txId: txId,
      otp: otp,
      email: email,
      personId: userId,
      source: PORTAL_SOURCE
    };
    console.log("verifyUpdateMobile", payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/verifyUserEmail`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.verifyUpdateEmail(payload.txId, payload.otp, payload.email, payload.personId);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }
  public updateUserProfile(userData, consentVal?: string): Observable<any>{
    let payload = {
      ...userData,
      source: PORTAL_SOURCE
    }
    if(consentVal)
      payload.sourceConsent = consentVal;
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/updateUserProfile`, payload, { reportProgress: true }).subscribe(success => {
        this.toast.showToast(null, success['message'], TOAST_SUCCESS);
        observer.next(success);
      }, error => {
        if(error.error.message == 'invalid_token' || 'Invalid Authorization.'){
          this.refreshSession().subscribe( data =>{
            if(data.status == 'SUCCESS'){
              this.user.setAccessToken(data.access_token);
              this.updateUserProfile(userData);
            }
            else{
              this.location.replaceState('/login')
            }
          }),(err => {
            this.location.replaceState('/login')
          })
        }
        else{
          observer.error(this.getErrorMessage(error));
        }
        // observer.error(this.getErrorMessage(error));
      })
    })
  }
  public refreshSession(): Observable<any>{
    if (!this.externalInterface)
      return;
    let refresh = JSON.parse(this.user.getStorage(AppConstant.SSO_CREDENTIAL));
    let payload = {
      refresh_token: refresh.refresh_token,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/refreshSession`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getUserProfile(): Observable<any> {
    if (!this.externalInterface) {
      return Observable.create(observer => {
        this.user.getUserInfo().subscribe(data => {
          observer.next(data.personDetails);
        });
      })
    } else {
      return Observable.create(observer => {
        this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/getUserProfile`, {}, { reportProgress: true }).subscribe(success => {
          observer.next(success);
        }, error => {
          if (error.error.message == 'invalid_token' || 'Invalid Authorization.') {
            this.refreshSession().subscribe(data => {
              if (data.status == 'SUCCESS') {
                this.user.setAccessToken(data.access_token);
                this.getUserProfile();
              }
              else {
                this.location.replaceState('/login')
              }
            }), (err => {
              this.location.replaceState('/login')
            })
          }
          else {
            observer.error(this.getErrorMessage(error));
          }
          // observer.error(this.getErrorMessage(error));
        })
      })
    }
  }
  public updateProfile(userData): Observable<any> {
    let payload = {
      ...userData,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/updateProfile`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
          observer.error(this.getErrorMessage(error));
        // observer.error(this.getErrorMessage(error));
      })
    })
  }

  public generateOTPforPatientId(patientId, isRegistered): Observable<any> {
    let payload = {
      patientId: patientId,
      registered: isRegistered,
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/security/generateOTPforPatientId`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getSignUpConfig(): Observable<any> {
    let payload = {
      
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/security/getSignUpConfig`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public validateWebView(hash, params): Observable<any>{
    let payload = {
      hash, params
    }
    return new Observable(observer => { 
      this.http.post(`${environment['API_URL']}patientview/validate`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['payload']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public verifyOTP(natid:string, otp: string): Observable<any> {
    let payload = {
      localeCode: this.user.currentLanguage,
      source: PORTAL_SOURCE,
      externalId: natid,
      otp: otp
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SECURITY}/verifyNatId`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success['data']);
      }, error => {
        let err = this.getErrorMessage(error);
        this.gtmService.pushGTMEvent(null, 'precheck', { event_label: `Verifyotp Failed:${err}` })
        observer.error(err);
      })
    })
  }
}
