import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService} from '../services/modal.service';
import {LinkPatientService} from '../services/link-patient.service';
import {UserService} from '../services/user.service';
import { REPO_TOKEN } from '@string';
import * as _ from 'lodash';
import { FeatureFlagsService } from '../services/feature-flags.service';
@Component({
  selector: 'app-manage-identities',
  templateUrl: './manage-identities.component.html',
  styleUrls: ['./manage-identities.component.scss']
})
export class ManageIdentitiesComponent implements OnInit {
  @Input() isPatReg: boolean;
  @Input() modalId: string;
  public otherOptions;
  public linkedPatients = [];
  public recordsToLink = [];
  public linkedId = [];
  public notlinkedId = [];
  public entity;
  public itemId;
  public buttonId;
  public relations;
  public relationType;
  public relationArray = [];
  public facilityList = [];
  public selfLinked = [];
  public selectRelations:boolean = false;
  public getRelations = false;
  public registerPatient = false;
  public manageLinkedRecords = false;
  public isEdit = false;
  public findOtherPatient = false;
  public editDetails: any;
  public popoverpatient: any;
  public canUnlinkSameIdentity: boolean = false;
  public canUnlinkDifferentIdentity: boolean = false;
  public canUnlinkProvidedIdentity: boolean = false;
  public canLinkDifferentIdentityHOF: boolean = false;
  public canLinkDifferentIdentityNonHOF: boolean = false;
  public isHof: boolean = false;
  public linkWithoutRelation: boolean = false;
  public prefillNationalId: string = null;
  @Output() updateLinkedPatient: EventEmitter<any>;
  
  public addMemberModal : boolean = false;
  radioSelected: any;
  isUpdateRelation;
  linkSameNumber: boolean;

  constructor(private modalService: ModalService, private linkPatient: LinkPatientService, private user: UserService, private featureService: FeatureFlagsService) {
    this.updateLinkedPatient = new EventEmitter<any>();
    this.canUnlinkSameIdentity = this.featureService.featureOn('FT_APP_UNLINKSAMEIDENTITY');
    this.canUnlinkDifferentIdentity = this.featureService.featureOn('FT_APP_UNLINKDIFFIDENTITY');
    this.canUnlinkProvidedIdentity = this.featureService.featureOn('FT_APP_UNLINKPROVIDEDIDENTITY');
    this.canLinkDifferentIdentityHOF = this.featureService.featureOn('FT_APP_HOFLINKDIFFIDENTITY');
    this.canLinkDifferentIdentityNonHOF = this.featureService.featureOn('FT_APP_NONHOFLINKDIFFIDENTITY');
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
    this.isHof = this.featureService.getIsHOF()
  }

  ngOnInit(): void {
    this.getLinkedPatient();
    // this.getPatientsToLink();
    this.getRelationList();
    this.getEntityId();
    // this.otherOptions = options;
    this.otherOptions =  options.filter(ele =>{ return this.featureService.featureOn(ele.display) })
    if (this.isPatReg) {
      this.createPatientWithPayload()
    }
  }

  closeModal(id: string) {
    this.editDetails = null;
    this.prefillNationalId = null
    if(id === 'register-patient'){
      this.registerPatient = false;
      this.getLinkedPatient();
    } else if(id === 'register-new-patient'){
      this.registerPatient = false;
      this.getLinkedPatient();
    } else if (id == 'find-record') {
      this.linkSameNumber = false;
      this.findOtherPatient = false;
      this.getLinkedPatient();
    } else{
      if(this.modalId) this.modalService.close(this.modalId);
      else this.modalService.close(id);
      this.getPatientsToLink();
    }
  }

  openModal(id: string) {
    if (id == 'register-patient'){
      this.registerPatient = true;
      this.isEdit = false
    }
    if (id == 'find-record') {
      if ((this.isHof && this.canLinkDifferentIdentityHOF) || (!this.isHof && this.canLinkDifferentIdentityNonHOF))
        this.findOtherPatient = true;
    }
    // this.modalService.open(id);
  }

  selectRelation(item){
    // this.itemId = item.patientUhid
    // this.selectRelations = true;
    if(this.selectRelations){
      this.itemId = null;
      this.selectRelations = false;
    }
    else{
      this.itemId = item.patientUhid;
      this.selectRelations = true;
    }
  }

  getRelation(item){
    this.itemId = item.patientUhid
    // this.getRelations = true;
  }

  setRelation(_id, relation){
    this.relationArray[_id] = relation;
    this.buttonId = _id;
    this.selectRelations = false;
  }

  manageLinkRecord(){
    this.manageLinkedRecords = true;
  }

  cancel(){
    this.manageLinkedRecords = false;
  }

  getRelationList(){
    this.user.getMasterConfig.subscribe(data => {
      this.relationType = data.list;
      this.relationType = this.relationType.filter(arr => {
          return arr.domain === 'RELATIONSHIP_TYPE'
      })
      this.relations = this.relationType[0].codes
    });
  }

  getEntityId(){
    this.user.getConfigurationInfo.subscribe(data => {
      this.entity = data.entityId;
    })
  }

  getLinkedPatient(){
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data, true).subscribe(result => {
          if(result.status === 'SUCCESS'){
            this.linkedPatients = result.data;
            console.log("this.linkedPatients->>",this.linkedPatients)
            this.selfLinked =result.source
            this.getPatientsToLink();
          }
        })
      }
    }) 
  }

  getPatientsToLink(){
    this.linkedId = this.linkedPatients.map( item =>{
      return item.uhid;
    })
    this.user.getUserCredential().subscribe(data => {
      if (data) {
        this.linkPatient.getPatientsToLink(data).subscribe(result => {
          if(result.status === 'SUCCESS'){
            this.recordsToLink = result.data.filter((item) =>{
              return this.linkedId.indexOf(item.patientUhid) === -1 
            })
          }
        })
      }
    }) 
    this.updateLinkedPatient.emit();
  }

  removeLinkedPatient(linkedPatient){
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.removeLinkedPatient(data,linkedPatient.personid).subscribe(result => {
          if(result.status === 'SUCCESS'){
            console.log("***After removing pat***")
            this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
            this.getLinkedPatient()
          }
        })
      }
    }) 
  }
  removeLinkedMe(linkedPatient) {
    // Developed For MOH - To remove access of my records in some other login
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.removeMyLinked(linkedPatient.personid, linkedPatient.selfPersonId || data).subscribe(result => {
          if(result.status === 'SUCCESS'){
            this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
            this.getLinkedPatient();
            this.popoverpatient = null;
          }
        })
      }
    }) 
  }

  toLinkPatients(patientInfo){
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          profilesToLink:[
            {
              uhid: patientInfo.patientUhid,
              facilityId: patientInfo.facilityId,
              patientName: patientInfo.patientName,
              dob: patientInfo.birthDate,
              gender: patientInfo.gender,
              relationshipType: this.relationArray[patientInfo.patientUhid]['code'],
              mobile: patientInfo.mobile,
              imageUrl: "",
              email: patientInfo.email
            }
          ]
        }
        this.initLinkData(patientInfo, linkData);
      }
    }) 
  }

  private initLinkData(patientInfo, linkData) {
    this.linkPatient.toLinkPatients(linkData).subscribe(result => {
      if (result.status === 'SUCCESS') {
        this.closeModal('find-record')
        console.log("***After linking pat***")
        this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
        this.getLinkedPatient()
      }
    })
  }
  public editPatient(patient) {
    this.editDetails = _.cloneDeep(patient);
    this.isEdit = true;
    this.registerPatient = true;
    return
    this.linkPatient.getPatientDetails(patient).subscribe(data => {
      this.editDetails = data;
      this.isEdit = true;
      this.registerPatient = true;
    })
    
  }

  public createPatientWithPayload() {
    let prefillData = _.cloneDeep(this.user.getRegistrationPayload())
    if (prefillData) {
      let data = {
        dob: prefillData['dob'],
        email: prefillData['email'],
        firstName: prefillData['firstName'],
        lastName: prefillData['familyName'],
        mobile: prefillData['mobile'],
        gender: prefillData['gender']
      }
      this.editDetails = data;
      this.isEdit = false;
      this.registerPatient = true;
    }
    this.user.setRegistrationPayload(null);
  }
  public openPopover(patient, enablePhoto, enableUnlink, enableLink, myAccess) {
    console.log("patient",patient)
    let unlinkMyAccess = false
    if (myAccess) {
      if (patient.isSameIdentity && !this.canUnlinkSameIdentity) enableUnlink = false;
      if (!patient.isSameIdentity && !this.canUnlinkDifferentIdentity) enableUnlink = false;
    } else {
      unlinkMyAccess = true;
    }
    this.popoverpatient = { patient, enablePhoto, enableUnlink, enableLink, unlinkMyAccess }
  }

  public chooseOption(option: 'PHOTO' | 'UNLINK' | 'LINK' | 'UNLINKMYACCESS') {
    switch (option) {
      case 'PHOTO':
        break;
      case 'LINK':
        if (this.popoverpatient.patient.directLink) {
          this.relationArray[this.popoverpatient.patient.patientUhid] = { code: null }
          this.toLinkPatients(this.popoverpatient.patient)
        } else {
          this.prefillNationalId = this.popoverpatient.patient.nationalId;
          this.openModal('find-record')
        }
        break;
      case 'UNLINK':
        this.removeLinkedPatient(this.popoverpatient.patient);
        break;
      case 'UNLINKMYACCESS':
        this.removeLinkedMe(this.popoverpatient.patient);
        break;
    }
  }

  public initLinkPatients(patient) {
    this.relationArray[patient.patientUhid] = { code: null }
    this.toLinkPatients(patient)
  }

  public initAddPatients({ natid, otp }) {
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          externalIdsToLink: [
            {
              externalId: natid,
              otp: otp
            }
          ]
        }
        this.initLinkData(null, linkData);
      }
    })
  }

  public uploadPatientPhoto(files: FileList) {
    let fileToUpload: File = files.item(0)
    console.log(fileToUpload)
    this.linkPatient.uploadPatientPhoto(fileToUpload, this.popoverpatient.patient).subscribe(data => {
      this.getLinkedPatient()
    })
  }
  addMember() {
    this.addMemberModal = !this.addMemberModal;
  }

  changePhoto() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = "image/png, image/gif, image/jpeg";
    input.onchange = ($event) => {
      this.uploadPatientPhoto($event.target['files'])
          };
    input.click();
  }
  updatedRelationship(patient) {
    this.isUpdateRelation = patient;
    patient['_openOptions'] = false;

  }
  changeRelationType(radioSelected) {
    console.log(radioSelected);
  }

  public triggerMoreOptions(item) {
    this.linkedPatients.forEach(p => {
      p['_openOptions'] = false;
    })
    item['_openOptions'] = !item['_openOptions'];
    this.openPopover(item, true, true, true, true);
  }

  closeUpdateRelation(ev) {
    this.isUpdateRelation = null;
    if(ev) {
      this.getLinkedPatient();
    }
  }
}

export const options = [{
  icon:'assets/icons/navigation/search_blue.svg',
  title: 'find_a_record',
  subtitle: 'find_a_record_desc',
  modal_id: 'find-record',
  display: 'FT_APP_FIND_FAMILY_MEMBER'
},
{
  icon:'assets/icons/header/user_blue_add.svg',
  title: 'msg_register_new_patient',
  subtitle: 'new_record_desc',
  modal_id: 'register-patient',
  display: 'FT_APP_REGISTER_FAMILY_MEMBER'
}]

