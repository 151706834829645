<div class="lngTxt">
    <p class="lngText-label"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span> <sup
            *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
    <div class="ui input fluid focus lngTxt-aria">
        <!-- <select class="lngTxt" (change)="selectMaster($event.target.value)">
            <option value="1">Select {{section.question[0].text}}</option>
            <option *ngFor="let master of masters" value="{{ master['code'] }}">{{ master['name'] }}</option>
        </select> -->
        <div class="master-link-new-css" [ngClass]="{'selcted-master-con':section.selectedValue}">
            <span (click)="getMastersData()" class="srh-icon-ml">
                <img class="ms-search-icon-css" src="./assets/icons/Book_new/search.svg" >
            </span>
            <input *ngIf="!section.selectedValue" class="ms-input-css" (keyup)="changeText(searchText)" [(ngModel)]="searchText">
            <div class="after-select-con" *ngIf="section.selectedValue">
                <p class="slt-master-name">{{section.selectedValue}}</p>
                <img class="ms-search-icon-css-1" src="assets/icon/close_grey.svg" (click)="clearMaster()">
            </div>
        </div>
        <div class="options-con" *ngIf="showOptions">
            <div class="options-con-div">
                <p *ngFor="let master of masters" (click)="chooseMaster(master)" class="options-name">{{master['localeName'] || master['name'] | translate:'LOCALE':'text'}}</p>
            </div>
        </div>
    </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
        <p>{{'form_field_mandatory' | translate}}</p>
    </div>
</div>