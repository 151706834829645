import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitSummaryComponent } from './hr-visit-summary.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { DirectiveModule } from '../directive/directive.module';



@NgModule({
  declarations: [HrVisitSummaryComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, DirectiveModule
  ],
  exports: [
    HrVisitSummaryComponent
  ]
})
export class HrVisitSummaryModule { }
