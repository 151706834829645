import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PAGINATION_NUMBERS } from '@const';
import { Pagination } from '@type';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent {
  @Input() set currentPage(val: number) {
    this.paginationOptions['currentPage'] = val;
    this.calculatePaginationOptions();
  }
  @Input() set totalRecords(val: number) {
    this.paginationOptions['totalRecords'] = val;
    this.calculatePaginationOptions();
  }
  @Input() set recordsPerPage(val: number) {
    this.paginationOptions['recordsPerPage'] = val;
    this.calculatePaginationOptions();
  }
  @Output() navigateTable: EventEmitter<any>;
  @Output() changePerPageCount: EventEmitter<any>;
  public paginationOptions: Pagination = {
    currentPage: 0,
    totalRecords: 0,
    recordsPerPage:0
  }
  public showPageCount: boolean = false;
  public pagination_numbers: Array<any> = PAGINATION_NUMBERS;

  constructor() {
    this.navigateTable = new EventEmitter<any>();
    this.changePerPageCount = new EventEmitter<any>();
  }

  private calculatePaginationOptions() {
    this.paginationOptions['fromRecords'] = this.paginationOptions.totalRecords>0?((this.paginationOptions['currentPage'] - 1) * this.paginationOptions['recordsPerPage']) + 1:0;
    let toRecords = this.paginationOptions['fromRecords'] + this.paginationOptions['recordsPerPage']-1;
    this.paginationOptions['toRecords'] = toRecords < this.paginationOptions['totalRecords'] ? toRecords : this.paginationOptions['totalRecords']
    this.paginationOptions['navigatePrev'] = this.paginationOptions['currentPage'] != 1
    this.paginationOptions['navigateNext'] = toRecords < this.paginationOptions['totalRecords']
  }

  public navigate(count) {
    console.log('NAVIGATE>', count);
    if (count == -1 && this.paginationOptions['navigatePrev']) {
      this.navigateTable.emit(count);
    } else if (count == 1 && this.paginationOptions['navigateNext']) {
      this.navigateTable.emit(count);
    }
  }
  public changePerPage(count) {
    this.showPageCount = false;
    if (count == this.paginationOptions.recordsPerPage) return;
    this.changePerPageCount.emit(count);
  }
  public openPageCount() {
    if (this.paginationOptions.totalRecords > PAGINATION_NUMBERS[0] || true) {
      this.showPageCount = true;
    }
  }

}
