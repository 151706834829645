<div class="formula-section">
    <div class="formula-wrapper border">
        <div class="formula-left">
            <p class="font-default-small"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span></p>
            <p class="no-margin formula-txt font-default-small bold">{{  getScore() }}</p>
        </div>
        <div *ngIf="section.results" class="formula-right border-left">
            <p class="font-default-small">{{'lbl_interpretation' | translate}}</p>
            <p class="no-margin font-default-small bold">{{section.selectedInterpretation}}</p>
        </div>
    </div>
</div>