import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Pagination } from '@type';
import { AppointmentService } from '../services/appointment.service';
import { HealthRecordsService } from '../services/health-records.service';
import { ModalService } from '../services/modal.service';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { Location } from '@angular/common';
import { PAGINATION_NUMBERS } from '@const';

@Component({
  selector: 'app-patient-billing',
  templateUrl: './patient-billing.component.html',
  styleUrls: ['./patient-billing.component.scss']
})
export class PatientBillingComponent implements OnInit {
  @Input() info: any;
  @Input() isCommonView: boolean;

  public isOpenPatientBillDetails: boolean;
  public isCheckout: boolean = false;
  public settledBills: Array<any>;
  public allBills: Array<any>;
  public settledBillsPatientInfo: Array<any>;
  public unsettledBill: Array<any>;
  public currencyCode: String;
  public unsettledBillsPagination: Pagination;
  public settledBillsPagination: Pagination;
  public allBillsPagination: Pagination;
  public selectedBill: any;
  public selectedBillDetails: any;
  public paymentGatewayUrl: string;
  private transactionBillNo: string;
  private checkCount = 0;
  public searchExpand: boolean;
  public isFilterOpen: boolean;
  public selectedFilter: any;
  public filters: Array<any>;
  constructor(private hrService: HealthRecordsService, private user: UserService, private modalService: ModalService, private apptService: AppointmentService, private paymentService: PaymentService, private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {
    this.filters = [
      { key: 'A', value: 'All' },
      { key: 'O', value: 'Outstanding' },
      { key: 'P', value: 'Settled' }
    ];
    this.selectedFilter = this.filters[0];
    this.isOpenPatientBillDetails = false;
    this.settledBills = [];
    this.unsettledBill = [];
    this.currencyCode = this.user.getCurrency();
    this.initPagination();
  }

  initPagination() {
    this.unsettledBillsPagination = {
      totalRecords: 0,
      currentPage: 1,
      recordsPerPage: PAGINATION_NUMBERS[0]
    }
    this.settledBillsPagination = {
      totalRecords: 0,
      currentPage: 1,
      recordsPerPage: PAGINATION_NUMBERS[0]
    }
    this.allBillsPagination = {
      totalRecords: 0,
      currentPage: 1,
      recordsPerPage: PAGINATION_NUMBERS[0]
    }
  }

  ngOnInit(): void {
    //JB00001601

    this.getBillDetails()
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl'];
    })
    this.checkUrl()
    
  }

  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("checkUrl params", params)
      let payment = params['s'];
      if (payment == 1) {
        let method = params['method'];
        let details = params['p'];
        switch (method) {
          case 'INSTLPMT':
            this.storeBillDetail(details);
            this.location.replaceState('/portal/patient-billing')
            break;
        }
      }
    });
  }

  private storeBillDetail(detail) {
    let orderData = atob(detail);
    let arr = orderData.split('&');
    let params = {};
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    console.log("params", params)
    if (params['billNo']) {
      this.transactionBillNo = params['billNo'];
    }
  }

  public openPatientBillDetails(bill, billdetails) {
    this.isOpenPatientBillDetails = true;
    this.selectedBill = bill;
    this.selectedBillDetails = billdetails;
  }

  public closePatientBillDetails(event) {
    if (event && event.action) {
      switch (event.action) {
        case 'INITPAY':
          let bill = event.bill;
          let billDetail = event.billDetail;
          this.initPay(bill, billDetail);
          this.isOpenPatientBillDetails = false;
          this.selectedBill = null;
          this.selectedBillDetails = null;
          break;
        default:
          this.isOpenPatientBillDetails = false;
          this.selectedBill = null;
          this.selectedBillDetails = null;
          break;
      }
    } else {
      this.isOpenPatientBillDetails = false;
      this.selectedBill = null;
      this.selectedBillDetails = null;
    }
  }

  private getBillDetails() {
    // this.getFinancialBillingSummary()
   
      // this.getPendingBill();
      // this.getSettledBill()
    
    // else {
      this.getAllBills();
    // }
  }

  private getPendingBill() {
    let page = this.unsettledBillsPagination;
    this.hrService.getWidgetBills('O', page.currentPage, page.recordsPerPage ).subscribe(data => {
      if (data && data['unsettledBills'] && data['unsettledBills'].length > 0) {
        this.unsettledBill = data['unsettledBills'];
        this.unsettledBillsPagination['totalRecords'] = data['noOfRecords'] ? data['noOfRecords'] : this.unsettledBill.length;
        this.checkBillNoFromUrl(data['unsettledBills']);
      }
    })
  }

  private getAllBills() {
    let page = this.allBillsPagination;
    this.hrService.getWidgetBills(this.selectedFilter.key, page.currentPage, page.recordsPerPage).subscribe(data => {
      if (data && data['unsettledBills'] || data['settledBills']) {
        let unsettledBills = data['unsettledBills'].map(b => {
          if (Number(b.billOutstandingAmount)>0)
            b.showPayButton = true;
          else
            b.showPayButton = false;
          b.billType = 'US';
          return b;
        })
        let settledBills = data['settledBills'].map(b => {
          b.showPayButton = false;
          b.billType = 'SB';
          return b;
        })
        this.allBills = unsettledBills.concat(settledBills);
        this.allBillsPagination['totalRecords'] = data['noOfRecords'] ? data['noOfRecords'] : this.allBills.length;
        this.checkBillNoFromUrl(this.allBills);
      }
    })
  }

  private getSettledBill() {
    let page = this.settledBillsPagination;
    this.hrService.getWidgetBills('P', page.currentPage, page.recordsPerPage).subscribe(data => {
      if (data && data['settledBills'] && data['settledBills'].length > 0) {
        this.settledBills = data['settledBills'];
        this.settledBillsPagination['totalRecords'] = data['noOfRecords'] ? data['noOfRecords'] : this.settledBills.length;
        this.checkBillNoFromUrl(data['settledBills']);
      }
    })
  }

  public getPatientBillDetails(billObj, type: 'VIEW'|'PAY') {
    this.hrService.getBillDetailsOfBillNo(billObj['patientId'], billObj['billNo']).subscribe(data => {
      if(type == 'VIEW')
        this.openPatientBillDetails(billObj, data);
      else
        this.payPatientBillDetails(billObj, data)
    })
  }
  public navigateUnsettledTable(count) {
    console.log('navigateUnsettledTable');
    this.unsettledBillsPagination['currentPage'] = this.unsettledBillsPagination['currentPage'] + count;
    this.getPendingBill();
  }
  public navigateSettledTable(count) {
    console.log('navigateSettledTable');
    this.settledBillsPagination['currentPage'] = this.settledBillsPagination['currentPage'] + count;
    this.getSettledBill();
  }
  public navigateTable(count) {
    console.log('navigateSettledTable');
    this.allBillsPagination['currentPage'] = this.allBillsPagination['currentPage'] + count;
    this.getAllBills();
  }

  public payPatientBillDetails(bill, billdetails) {
    bill.facility_id = billdetails['pbillhdr'][0]['operating_facility_id']
    if (billdetails && billdetails.installementdtls && billdetails.installementdtls.length > 0) {
      let installmentDetails = billdetails.installementdtls;
      let firstUnpaidIndex = installmentDetails.findIndex(i => (!i.installement_status || i.installement_status != 'P'));
      if (firstUnpaidIndex > -1) {
        let billDetail = installmentDetails[firstUnpaidIndex];
        this.initPay(bill, billDetail);
      }
    } else {
      bill.bill_outstanding_amt = billdetails['pbilltotal'][0]['bill_outstanding_amt']
      this.initPay(bill, {});
    }
  }
  public initPay(selectedBill, billDetail) {
    // let paymentAmount = 100;
    let paymentAmount = billDetail['installement_amount'] || selectedBill['bill_outstanding_amt'];
    var payload = {
      patientId: selectedBill['patientId'],
      amount: paymentAmount,
      currency: this.currencyCode,
      episodeType: 'R',
      encounterId: selectedBill['encounterId'] || '0',
      requestType: 'INSTLPMT',
      facilityId: selectedBill['facility_id']
    };
    let request = {
      patientId: selectedBill['patientId'],
      patientName: selectedBill['patientName'],
      keySeqNo: billDetail['key_seq_no'],
      installementNo: billDetail['installement_no'],
      dtlKeySeqNo: billDetail['dtl_key_seq_no'],
      billNo: selectedBill['billNo'],
      facilityId: selectedBill['facility_id'],
      encounterId: selectedBill['encounterId'] || '0',
      settlementAmount: paymentAmount,
      billingGroup: selectedBill['billingGroup'],
      episodeType: selectedBill['episodeType'],
    }
    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: selectedBill['patientId'],
        email: selectedBill['email'] || environment.DEFAULT_EMAIL,
        mobile_no: ''
      }
      let url = window['location'];

      let transaction_id = response['transaction_id'];
      let payment = true;
      let method = 'SETTLEINSTALLMENT';
      let params = `billNo=${billDetail['bill_no']}`
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=INSTLPMT&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() ||   this.currencyCode,
        charge: paymentAmount,
        paymentgatewayurl: this.paymentGatewayUrl
      }
      console.log(paymentInfo);
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        this.showCheckout();
      })
    });
  }
  private showCheckout() {
    this.isCheckout = true
  }

  public closeCheckout() {
    this.isCheckout = false;
  }

  private checkBillNoFromUrl(bills) {
    if (this.transactionBillNo) {
      let bill = bills.find(b => b.billNo == this.transactionBillNo);
      this.checkCount++;
      if (bill) {
        this.getPatientBillDetails(bill, 'VIEW');
      }
      if (this.checkCount >= 2) {
        this.checkCount = 0;
        this.transactionBillNo = null;
      }
    }
  }

  public changePerPageCount(count, from: 'UNSETTLED' | 'SETTLED' | 'ALL') {
    switch (from) {
      case 'UNSETTLED':
        this.unsettledBillsPagination = {
          totalRecords: 0,
          currentPage: 1,
          recordsPerPage: count
        }
        this.getPendingBill();
        break;
      case 'SETTLED':
        this.settledBillsPagination = {
          totalRecords: 0,
          currentPage: 1,
          recordsPerPage: count
        }
        this.getSettledBill()
        break;
      case 'ALL':
        this.allBillsPagination = {
          totalRecords: 0,
          currentPage: 1,
          recordsPerPage: count
        }
        this.getAllBills()
        break;
    }
  }

  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
    this.initPagination();
    this.getAllBills()
  }
  public openFilter() {
    this.isFilterOpen = true;
  }
  public closeFilter() {
    this.isFilterOpen = false;
  }
}
