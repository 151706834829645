<div class="ui">
    <p class="draw-label"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span> <sup
        *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
    <div class="draw-container" id="drawcontainer">
        <!-- <ng-container *ngIf="!section._drawCompleted"> -->
        <button class="draw-clear" (click)="clearSignaturePad()">
            <img src="./assets/icons/reload.svg" alt="">
        </button>

        <!-- </ng-container> -->
        <!-- <ng-container *ngIf="section.selected && section._drawCompleted">
      <div class="draw-filled-image" >
          <img [src]="section.publicPath || section.selected">
      </div>
      <button ion-button clear class="draw-clear" (click)="removeImage()">
          <ion-icon name="md-refresh"></ion-icon>
      </button>
  </ng-container> -->
        <signature-pad [options]="signaturePadOptions" #signaturepad
            (onEndEvent)="drawComplete()"> </signature-pad>
    </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']=='' && !section['selectedPic']))">
        <p>{{'form_field_mandatory' | translate}}</p>
    </div>
</div>