import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-appt-time',
  templateUrl: './appt-time.component.html',
  styleUrls: ['./appt-time.component.scss']
})
export class ApptTimeComponent {
  @Input() time: string;
  @Input() toTime: string;
  @Input() type: 'DETAIL1' | 'DETAILEXPRESS' | 'CARD1' | 'CARD2';
  @Input() facilityId: string;
  @Input() showLocalTime: boolean = false;
  @Input() sourceFormat: string = 'YYYY-MM-DD HH:mm';
  @Input() destinationFormat: string = 'D MMM YYYY, hh:mm a';
  @Input() toFormat: string = 'D MMM YYYY, hh:mm a';

  constructor() { }

  ngOnInit() { }
}
