<div class="ui">
<sm-segment class="compact">{{ section['selected'] | date }}</sm-segment>
<!--<div style="display: -webkit-inline-flex;margin-top:10px">-->
  <!--<sm-button class="medium teal" (click)=" today() ">Today</sm-button>-->
  <!--<sm-button class="medium teal" (click)=" tommorow() ">Tommorow</sm-button>-->
  <!--<sm-button class="medium teal" (click)="showChange()">Change</sm-button>-->
<!--</div>-->
<!--<div style="margin-top:10px " *ngIf="show">-->
  <!--<div style="display: -webkit-inline-flex">-->
    <!--<sm-select class="fluid select-date" [(model)]="section['day']" (onChange)="onChange()">-->
      <!--<option [selected]="day === section['day']" *ngFor="let day of section['days']">{{ day }}</option>-->
    <!--</sm-select>-->
    <!--<sm-select class="fluid select-month" [(model)]="section['month']" (onChange)="onChange()">-->
      <!--<option [selected]="month['name'] === section['month']" *ngFor="let month of section['months']">{{ month['name'] }}</option>-->
    <!--</sm-select>-->
    <!--<sm-select class="fluid" [(model)]="section['year']" (onChange)="onChange()">-->
      <!--<option [selected]="year === section['year']" *ngFor="let year of section['years']">{{ year }}</option>-->
    <!--</sm-select>-->
  <!--</div>-->
<!--</div>-->
<div style="display: -webkit-inline-flex;margin-top:10px">
  <vaadin-button theme="secondary" class="today-but" (click)=" today() ">Today</vaadin-button>
  <vaadin-button theme="secondary" class="tmrw-but" (click)=" tommorow() ">Tommorow</vaadin-button>
  <vaadin-button theme="secondary" class="show-change" (click)="showChange()">Change</vaadin-button>
</div>
<div style="margin-top:10px " *ngIf="show">
  <vaadin-combo-box class="vaadin-sel-box1" #myDiv [(model)]="section['day']" [items]="section['days']" (onchange)="onChange()" ></vaadin-combo-box>
  <vaadin-combo-box class="vaadin-sel-box2" [(model)]="section['month']" [items]="section['months']" (onchange)="onChange()"></vaadin-combo-box>
  <vaadin-combo-box class="vaadin-sel-box3" [(model)]="section['year']" [items]="section['years']" (onchange)="onChange()"></vaadin-combo-box>
</div>
</div>