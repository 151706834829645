import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServicerequestService } from '../services/servicerequest.service';


@Component({
  selector: 'app-service-request-details',
  templateUrl: './service-request-details.component.html',
  styleUrls: ['./service-request-details.component.scss']
})
export class ServiceRequestDetailsComponent implements OnInit {

  @Output() close: EventEmitter<any>;
  @Input() selectedServiceRequest;
  @Input() AllServiceRequest;
  @Output() cancelServiceRequest: EventEmitter<any>;

  constructor(public serviceRequest: ServicerequestService) {
    this.close = new EventEmitter<any>();
    this.cancelServiceRequest = new EventEmitter<any>();

  }

  ngOnInit(): void {
  }

  public closeModal() {
    this.close.emit();
  }

  public cancelRequest(data) {
    this.serviceRequest.dismissRequest(data).subscribe(data => {
      if (data.status === 'SUCCESS') {
        this.cancelServiceRequest.emit();
      }
    });
  }

}


