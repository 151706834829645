<div class="ui">
<p class="check-label">
  <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
  <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
<div style="display: -webkit-inline-flex;margin-top: 10px;width: 100%">
  <input placeholder="Select time" [tabindex]="tabindex"
         [(ngModel)]="section['selected']"
         [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" class="time-only-custom">
  <owl-date-time #dt1 [pickerType]="'timer'" [pickerMode]="'dialog'"></owl-date-time>
</div>
<div class="mandatory right" *ngIf="((section['mandatory']=='true') && ( (section['selected']=='')))">
  <p>{{'form_field_mandatory' | translate}}</p>
</div>
</div>
