import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { isEmpty as _isEmpty, concat as _concat, cloneDeep as _cloneDeep} from 'lodash';
import { taskstate, WFDriverElement } from '@type';
import { REPO_TOKEN, USER_TOKEN } from '@string';
import { CookieService } from '../services/cookie.service';
import * as moment from 'moment';
import { ClinicalFormUtils } from '../clinicalform/clinicalform.utils';
import { OnspotConsultationService } from '../services/onspot-consultation.service';
import { LinkPatientService } from '../services/link-patient.service';
import { forkJoin } from 'rxjs';
import { RichTextEditor } from '../section/type/richTextEditor/richTextEditor.component';
import { ClinicalformService } from '../services/clinicalform.service';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import { environment } from '@env/environment';
import { ToastServiceService } from '../services/toast-service.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-task',
  templateUrl: environment['TASK_FULLSCREEN_VIEW'] ? './task-fullscreen.component.html': './task.component.html',
  styleUrls: ['./task.component.scss', './task-fullscreen.component.scss'],
  providers: [PartitionerDetailsPipe, TranslatePipe, FacilityDetailsPipe]
})
export class TaskComponent extends RichTextEditor implements OnInit {

  public patientInfo: Object;
  public mpiPatientInfo: Object;
  public workflow: Object;
  public topic: any;
  public task: any;
  public taskInfo: any;
  public userInfo: any;
  public state: taskstate;
  public applicableStatus: any;
  public clinicalParams: any;
  public content: any;
  public taskAction: any;
  public taskStatus: any;
  public isApptModal: boolean;
  public lang: string;
  public languageList: any;
  public readonly APPOINMENTS = ['EXPCAPPT'];
  public readonly CERTIFICATE = ['CERTIFICATE'];
  public isCertificateDetails: boolean = false;
  public appointmentsList: Array<any>;
  public disableBtn: boolean = true;
  public noAvailableKeyInParams: boolean = false;
  public noAvailableKeyInQueryParams: boolean = false;
  public isFormPreview: boolean = false;
  public contractSeq: string;
  public taskToPreview: any;
  public currentStatus: any;
  public autoOpen: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, public user: UserService, private cookie: CookieService, private utils: ClinicalFormUtils, private router: Router, private onspotService: OnspotConsultationService, private linkPatient: LinkPatientService, private formService: ClinicalformService, private practitionerPipe: PartitionerDetailsPipe, private translatePipe: TranslatePipe, private facilityPipe: FacilityDetailsPipe, private toast: ToastServiceService, private loader: LoaderService) {
    super(user, formService);
    this.content = {
      title: this.translatePipe.transform('task_success_title'),
      subTitle: this.translatePipe.transform('task_success_subtitle')
    }
  }

  ngOnInit(): void {
    this.lang = this.user.currentLanguage;
    this.activatedRoute.params.subscribe(params => {
      const key = params['tid'];
      if (key) {
        console.log(key, params);
        // this.state = 'INIT';
        this.validatePatientTask(key);
      } else {
        this.noAvailableKeyInParams = true;
      }
      this.activatedRoute.queryParams.subscribe(qp => {
        this.user.getLanguages().subscribe(data => {
          if (qp && qp['lang']) {
            let lang = data.find(d => d['localeCode'] == qp['lang']);
            this.user.setCurrentLanguage(lang);
            this.user.getLabels().subscribe(labels => {
              this.user.setLocaleLabels(labels);
              this.lang = qp['lang'];
              this.changeCompletionContent();
            })
          } else {
            this.user.getLabels().subscribe(labels => {
              this.user.setLocaleLabels(labels);
              this.lang = this.user.currentLanguage;
              this.changeCompletionContent();
            })
          }
          this.languageList = data;
          if (qp && qp.ctx && (qp.ctx == true || qp.ctx == 'true')) {
            this.getPatientTask()
          } else if (this.noAvailableKeyInParams){
            this.noAvailableKeyInQueryParams = true;
            this.state = 'INVALID';
            this.disableBtn = false;
          }
        });
      });
    });
    this.activatedRoute.fragment.subscribe(fragment => {
      console.log('fragment', fragment);
      this.isCertificateDetails = fragment && fragment == 'details';
    })
  }

  public validatePatientTask(key) {
    this.user.validatePatientTask(key).subscribe(response => {
      this.setStorage(USER_TOKEN, response['token']['token']);
      this.setStorage(REPO_TOKEN, response['repoToken']['token']);
      this.user.setRepoUrl(response['repoToken']['url']);
      this.linkPatient.getPatientDetails(response['eventInfo']['patientInfo']).subscribe(patient => {
        this.workflow = response['eventInfo'];
        this.mpiPatientInfo = patient;
        this.patientInfo = response['eventInfo']['patientInfo'];
        this.patientInfo['mobileNumber'] = response['eventInfo']['mobileNumber'];
        this.taskInfo = response['taskInfo'];
        this.userInfo = response['userInfo'];
        this.user.setUserInfoForTask(this.userInfo, response['token'], response['repoToken']);
        if (response['eventInfo'] && response['eventInfo']['taskGroup'] && !_isEmpty(response['eventInfo']['taskGroup']['tasks'])) {
          this.task = response['eventInfo']['taskGroup']['tasks'].find(t => t['taskdetailacsnno'] == this.taskInfo['taskDtlAccnNo']);
          this.task['taskGroupId'] = this.taskInfo['taskId'];
          this.task['facilityId'] = this.workflow['eventInfo']['facilityId'];
          this.changeCompletionContent();
        }
        if (this.task['taskCode'] == 'CINTSR5' || this.task['taskCode'] == 'TASK1')
          this.getContractSeq();

        this.state = this.isCertificateDetails && this.CERTIFICATE.includes(this.task['externalEventType']) ? 'CERT' : 'INIT';
        this.applicableStatus = this.user.getApplicableStatus(this.task);
        console.log('response', this.task, this.workflow, this.patientInfo);
        if (this.workflow['eventInfo'] && this.workflow['eventInfo']['facilityId'] && this.workflow['eventInfo']['externalInfo'] && (Array.isArray(this.workflow['eventInfo']['externalInfo']['apptRefNums']) && this.workflow['eventInfo']['externalInfo']['apptRefNums'].length) || (this.workflow && this.workflow['eventInfo'] && this.workflow['eventInfo']['externalInfo'] && this.workflow['eventInfo']['externalInfo']['singlePatientContract'])) {
          let apptRefNum = [];
          if (this.workflow['eventInfo']['externalInfo']['singlePatientContract'] && this.workflow['eventInfo']['externalInfo']['apptDtls'] && this.workflow['eventInfo']['externalInfo']['apptDtls'].length) {
            this.workflow['eventInfo']['externalInfo']['apptDtls'].forEach(appt => {
              apptRefNum = _concat(apptRefNum, appt['apptRefNums'])
            });
          } else {
            apptRefNum = this.workflow['eventInfo']['externalInfo']['apptRefNums']
          }
          const payload = {
            "facilityId": this.workflow['eventInfo']['facilityId'],
            "refNo": apptRefNum
          };
          forkJoin([this.onspotService.getAppointmentsByRefNo(payload), this.user.getCountryList()]).subscribe(data => {
            this.disableBtn = false;
            if (data && data[0] && Array.isArray(data[0]['appointmentList'])) {
              this.appointmentsList = data[0]['appointmentList'];
            }
          });

        } else this.disableBtn = false;
      }, err => {
        this.disableBtn = false;
      })
    }, err => {
      this.state = 'INVALID';
      this.disableBtn = false;
    })
  }

  public setStorage(key, value) {
    this.cookie.set(key, value);
  }

  public saveResponse(response) {
    console.log('response', response);
    this.updateTaskStatus(response);
  }

  public updateTaskStatus(result) {
    let task = this.task;
    let taskDraft = result['_taskDraft']
    let response = result['response'];
    const newTaskStatusCode = response['newTaskStatusCode'] || null;
    let noteContent = response['data'][0]['noteContent'];
    let reportingOutcome = response && response['reportingOutComes'] ? response['reportingOutComes'] : this.getReportingFields()
    this.user.updateTaskInfo(this.user.getPayLoad(task, this.workflow ? this.workflow['eventInfo'] : null, response['data'][0]['noteCode'], response['data'][0]['noteContent'], newTaskStatusCode, response['outcome'], response['type'], reportingOutcome, task['taskGroupId'], this.userInfo, this.contractSeq)).subscribe(result => {
      //task complete
      if (!taskDraft) {
        this.state = 'COMPLETE';
      } else {
        let message = this.translatePipe.transform('Assessment_saved_successfully')
        this.toast.showToast(true, message, 'success');
        if (environment['TASK_DRAFT_ACTION'] == 1) {
          this.toggleSection('ASSESSMENTS')
        }
      }
    });
  }

  public action(status: Object, patientInfo) {
    this.taskStatus = status;
    console.log('status', status);
    // this.task['externalEventType'] = 'EXPRESS_CLINIC';
    if (this.APPOINMENTS.includes(this.task['externalEventType'])) {
      return this.launchBookAppoinmentModal(status);
    } else if (this.CERTIFICATE.includes(this.task['externalEventType'])) {
      return this.state = 'CERT';
    } else if (status['actionCode'] == 'PRINT#TASK') {
      this.prepareForPrintTask();
    }else {
      if (this.task && this.task['noteApplicable'] == 'Y') {
        this.openClinicalModa(status, patientInfo);
      }
      else {
        this.user.updateTaskInfo(this.user.getPayLoad(this.task, this.workflow['eventInfo'], null, null, status['code'], null, 'submit', this.getReportingFields(), this.task['taskGroupId'], this.userInfo, this.contractSeq)).subscribe(response => {
          //task complete
          this.state = 'COMPLETE';
        });
      }
    }
  }

  public completeTaskOnBookAppt({ apptData }) {
    this.task['noteContent']['topics'] = this.fillAppointmentForm(apptData, this.task['noteContent']['topics']);
    console.log('noteocne', this.task);
    this.user.updateTaskInfo(this.user.getPayLoad(this.task, this.mergeEventInfoWithAppointment(this.workflow['eventInfo'], apptData), this.task['noteCode'], this.task['noteContent'], this.applicableStatus[0]['code'] || 'COMP', this.utils.computeOutComes(this.task['noteContent']['topics'])['outcomes'], 'submit', this.getReportingFields(), this.task['taskGroupId'], this.userInfo, this.contractSeq)).subscribe(result => {
      this.closeModal();
      this.state = 'COMPLETE';
    })
  }

  public fillAppointmentForm(bookingInfo: any, topic: Array<Object>) {
    let apptDttm = `${this.user.getMoment(bookingInfo['date'], 'YYYY-MM-DD').format('DD MMM YYYY')} ${this.user.getMoment(bookingInfo['time'], 'HH:mm').format('hh:mm a')}`;
    let _filledInfo = {
      APPTREFID: bookingInfo['apptRefNumber'],
      PROCPRACT: bookingInfo['practitionerName'],
      APND: apptDttm
    }
    console.log('_filledInfo', _filledInfo)
    topic.map(t => {
      t['sections'].map(s => {
        if (_filledInfo[s['domainCode']]) {
          s['selected'] = _filledInfo[s['domainCode']];
        }
      })
    })
    return topic;
  }

  public mergeEventInfoWithAppointment(eventInfo, appoinmentReference) {
    let externalinfoId;
    console.log(appoinmentReference);
    if (appoinmentReference)
      externalinfoId = appoinmentReference.facilityId + '/' + appoinmentReference.apptRefNumber;
    return { ...eventInfo, ...{ externalinfoId: externalinfoId } };
  }

  private openClinicalModa(status: Object, patientInfo) {
    let wfDrivers: Array<WFDriverElement> = [];
    this.taskAction = this.applicableStatus;
    let applStatus = this.applicableStatus.find(s => s.code == status['code']);
    let concept = this.user.getConcept(this.workflow, this.task);
    if (this.workflow['statusInfo'] && Array.isArray(this.workflow['statusInfo']['wfDrivers'])) {
      wfDrivers = this.workflow['statusInfo']['wfDrivers'];
    }

    const clinicalParams = {
      concept: this.user.prefillWorkflowDrivers(concept, wfDrivers),
      patient: {
        patientInfo,
        patientId: patientInfo['patientId'],
        isNewPatient: this.workflow['eventInfo'] && this.workflow['eventInfo']['externalInfo'] ? this.workflow['eventInfo']['externalInfo']['isNewPatient'] : null,
        externalInfo: this.workflow['eventInfo'] && this.workflow['eventInfo']['externalInfo'] ? this.workflow['eventInfo']['externalInfo'] : null
      },
      directiveCode: this.task.directiveCode,
      preview: false,
      enableDraft: false,
      taskApplicableStatus: applStatus,
      editNote: status['editNote'],
      addToNote: status['addToNote'],
      appointmentsList: this.appointmentsList
    };
    this.clinicalParams = _cloneDeep(clinicalParams);
    console.log('this.clin', this.clinicalParams);
    this.state = 'FORM';
  }

  public launchBookAppoinmentModal(status) {
    this.patientInfo['_info'] = {
      dob: this.patientInfo['dob'],
      id: this.patientInfo['patientId'],
      gender: this.patientInfo['sex'],
      name: this.patientInfo['patientName'],
      personid: this.userInfo['personId'],
      relation: this.userInfo['relationshipType']
    }
    this.isApptModal = true;
  }

  public closeModal() {
    this.isApptModal = false;
  }

  public changeCompletionContent() {
    this.content = this.task && this.task['taskCompletionDisplay'] && this.task['taskCompletionDisplay'][this.user.currentLanguage] ? this.task['taskCompletionDisplay'][this.user.currentLanguage] : {
      title: this.translatePipe.transform('task_success_title'),
      message: this.translatePipe.transform('task_success_subtitle')
    }
  }

  public getContractSeq() {
    this.user.getContractSeq(this.task, this.patientInfo).subscribe(data => {
      this.contractSeq = data;
    })
  }

  public getReportingFields() {
    const stat = this.contractSeq ? [{
      name: 'Contact Seq',
      code: 'SYS_CONTRACT_S_NO',
      values: this.contractSeq,
      displayTxt: this.contractSeq,
    }] : null;
    const reportingFields = this.utils.getReportingOutcomes(this.task['noteContent'] ? this.task['noteContent']['topics'] : [], this.task['noteCode'], stat);
    console.log('reportingFields', reportingFields)
    return reportingFields
  }

  public prepareForPrintTask() {
    const noteContent = _cloneDeep(this.task['noteContent']);
    let value = {
      SYS_CONTRACT_S_NO: this.contractSeq
    };
    noteContent['topics'].forEach(t => {
      t['sections'].forEach(s => {
        if (s['type'] == 'richTextEditor') {
          super.checkTemplateFromChildClass({ section: s, sections: t['sections'], topics: noteContent['topics'], patient: {patientInfo: this.patientInfo}, appointmentsList: this.appointmentsList });
          if (s['templateVaraibleValues'])
            Object.assign(value, s.templateVaraibleValues);
        }
      });
    });
    noteContent['templateVaraibleValues'] = value;
    this.formDownloadData(noteContent);
  }


  private formDownloadData(downloadcontent) {
    console.log("downloadcontent", downloadcontent)
    let appointment = this.appointmentsList.length ? this.appointmentsList[0] : {};
    let practitioner = this.practitionerPipe.transform(appointment['practitionerId'] || appointment['practitioner_id'], this.task['facilityId'], 'resourceName');
    let practitionerName = this.translatePipe.transform(practitioner, 'LOCALE', 'name');
    let facility = this.facilityPipe.transform(this.task['facilityId'], 'facilityName');
    let facilityName = this.translatePipe.transform(facility, 'LOCALE', 'name')
    let data = {
      content: downloadcontent,
      facilityName: facilityName,
      patientName: this.patientInfo['patientName'],
      uhid: this.patientInfo['patientId'],
      personId: this.userInfo['personId'],
      gender: this.patientInfo['sex'] == 'M' ? 'Male' : this.patientInfo['sex'] == 'F' ? 'Female' : '',
      repDateTime: this.user.getMoment().format("DD-MM-YYYY hh:mm a"),
      age: this.user.getMoment().diff(this.user.getMoment(this.patientInfo['dob']), 'years'),
      reportedBy: this.userInfo['personId'],
      isShare: false,
      filename: "random.pdf",
      reportType: "FORMFORMAT",
      entityId: this.userInfo['entityId'],
      doctorName: practitionerName || appointment['practitioner_name'],
      reportName: this.task['taskDesc'],
      encounterId: 54219,
      eventCode: "CONOLDPAT",
    }
    this.getPDF(data);
  }
  private getPDF(data) {
    this.user.convertToPDF(data).subscribe(data => {
      let link = data['downloadLink'];
      this.user.openLocation(link, '_blank')
    })
  }

  public getPatientTask(loginContext = this.user.getLoginContext(), invalidState: boolean = true) {
    // let loginContext = this.user.getLoginContext()
    this.user.getPatientTaskDetails(loginContext).subscribe(response => {
      if (response && response['eventInfo'] && response.taskInfo.taskStatus == 'OPEN') {
        let patient = this.user.findLinkedPatient(response['eventInfo']['patientInfo']['patientId'])
        if (!patient) {
          this.linkPatient.getPatientDetails(response['eventInfo']['patientInfo']).subscribe(patient => {
            this.mpiPatientInfo = patient;
            this.setTaskResponse(response)
          }, err => {
            this.disableBtn = false;
          })
        } else {
          this.mpiPatientInfo = patient;
          this.setTaskResponse(response)
        }
      } else if (response && response.taskInfo.taskStatus == 'COMPLETE') {
        let patient = this.user.findLinkedPatient(response['eventInfo']['patientInfo']['patientId']);
        if (!patient) {
          this.linkPatient.getPatientDetails(response['eventInfo']['patientInfo']).subscribe(patient => {
            this.mpiPatientInfo = patient;
            this.patientInfo = response['eventInfo']['patientInfo'];
            this.patientInfo['mobileNumber'] = response['eventInfo']['mobileNumber'];
            this.taskInfo = response['taskInfo'];
            this.userInfo = response['userInfo'];
            this.toggleSection('ASSESSMENTS', true)
          }, err => {
            this.disableBtn = false;
          })
        } else {
          this.mpiPatientInfo = patient;
          this.patientInfo = response['eventInfo']['patientInfo'];
          this.patientInfo['mobileNumber'] = response['eventInfo']['mobileNumber'];
          this.taskInfo = response['taskInfo'];
          this.userInfo = response['userInfo'];
          this.toggleSection('ASSESSMENTS', true)
        }
      }

    }, err => {
      if (invalidState) {
        this.state = 'INVALID';
        this.content = {
          title: this.translatePipe.transform('task_link_invalid_title'),
          message: this.translatePipe.transform('task_link_invalid_subtitle')
        }
      } else {
        let message = this.translatePipe.transform('Error_fetching_task_details');
        this.toast.showToast(true, message, "error")
      }
      console.log("ERROR STATE--->",this.state);
      this.disableBtn = false;
    })
  }



  private setTaskResponse(response) {
    this.workflow = response['eventInfo'];
    this.patientInfo = response['eventInfo']['patientInfo'];
    this.patientInfo['mobileNumber'] = response['eventInfo']['mobileNumber'];
    this.taskInfo = response['taskInfo'];
    this.userInfo = response['userInfo'];
    if (response['eventInfo'] && response['eventInfo']['taskGroup'] && !_isEmpty(response['eventInfo']['taskGroup']['tasks'])) {
      this.task = response['eventInfo']['taskGroup']['tasks'].find(t => t['taskdetailacsnno'] == this.taskInfo['taskDtlAccnNo']);
      this.task['taskGroupId'] = this.taskInfo['taskId'];
      this.task['facilityId'] = this.workflow['eventInfo']['facilityId'];
      this.changeCompletionContent();
    }
    if (this.task['taskCode'] == 'CINTSR5' || this.task['taskCode'] == 'TASK1')
      this.getContractSeq();

    this.state = this.isCertificateDetails && this.CERTIFICATE.includes(this.task['externalEventType']) ? 'CERT' : 'INIT';
    this.applicableStatus = this.user.getApplicableStatus(this.task);
    this.currentStatus = this.user.getCurrentStatus(this.task);
    console.log('response', this.task, this.workflow, this.patientInfo);
    if (this.workflow['eventInfo'] && this.workflow['eventInfo']['facilityId'] && this.workflow['eventInfo']['externalInfo'] && (Array.isArray(this.workflow['eventInfo']['externalInfo']['apptRefNums']) && this.workflow['eventInfo']['externalInfo']['apptRefNums'].length) || (this.workflow && this.workflow['eventInfo'] && this.workflow['eventInfo']['externalInfo'] && this.workflow['eventInfo']['externalInfo']['singlePatientContract'])) {
      let apptRefNum = [];
      if (this.workflow['eventInfo']['externalInfo']['singlePatientContract'] && this.workflow['eventInfo']['externalInfo']['apptDtls'] && this.workflow['eventInfo']['externalInfo']['apptDtls'].length) {
        this.workflow['eventInfo']['externalInfo']['apptDtls'].forEach(appt => {
          apptRefNum = _concat(apptRefNum, appt['apptRefNums'])
        });
      } else {
        apptRefNum = this.workflow['eventInfo']['externalInfo']['apptRefNums']
      }
      const payload = {
        "facilityId": this.workflow['eventInfo']['facilityId'],
        "refNo": apptRefNum
      };
      forkJoin([this.onspotService.getAppointmentsByRefNo(payload), this.user.getCountryList()]).subscribe(data => {
        this.disableBtn = false;
        if (data && data[0] && Array.isArray(data[0]['appointmentList'])) {
          this.appointmentsList = data[0]['appointmentList'];
        }
      });

    } else this.disableBtn = false;

    if (this.task && this.task['noteApplicable'] == 'Y') {
      this.openClinicalModa({}, this.mpiPatientInfo)
    }
  }

  public toggleSection(event, autoOpen = false) {
    if (event == 'ASSESSMENTS') {
      console.log(event)
      this.state = 'ASSESSMENTSLIST',
      this.autoOpen = autoOpen;
    }
  }

  public openTaskDetail(task) {
    this.autoOpen = false;
    if (task.completed != 100) {
      let taskInfo = task['_task']
      let loginContext = {
        type: 'T',
        facilityId: taskInfo.facilityId,
        patientId: taskInfo.patientId,
        reportId: null,
        billNo: null,
        apptRefNo: null,
        taskId: taskInfo.taskId,
        taskKey: taskInfo.taskUId,
        personName: taskInfo.patientInfo.patientName,
        contextDesc: taskInfo.taskDetails.task_desc
      }
      this.getPatientTask(loginContext, false);
    } else {
      let taskInfo = task['_task']
      this.getTaskInfo(taskInfo['facilityId'], taskInfo['taskGroupCode'], taskInfo['taskId'], taskInfo, task['_task']['_taskName']);
    }
  }

  private getTaskInfo(facilityId, taskGroup, taskId, taskInfo, _taskName) {
    this.loader.changeState(true);
    const payload = {
      facilityId: facilityId,
      taskGroup: taskGroup,
      taskId: parseInt(taskId)
    }
    this.onspotService.getTaskInfo(payload).subscribe(data => {
      console.log(data)
      let patientTaskId = taskInfo.taskDetails.task_code;
      if (data['taskGroup'] && data['taskGroup']['tasks']) {
        let task = data['taskGroup']['tasks'].find(task => task.taskCode == patientTaskId);
        task['_taskName'] = _taskName;
        this.taskToPreview = task;
        this.isFormPreview = true
      }
      this.loader.changeState(false);
    }, err => {
      this.loader.changeState(false);
    })

  }

  public closeWindow() {
    this.user.closeWindow()
  }
  get taskDesc() {
    let taskDesc = this.task.taskDesc
    if (this.task.taskDescLocale) {
      taskDesc = this.translatePipe.transform(this.task.taskDescLocale, 'LOCALE', 'text')
    } else {
      let taskNameLabel = this.task.taskDesc.replace(/\s/g, '_');
      taskDesc = this.translatePipe.transform(taskNameLabel, 'LABELS', 'text')
    }
    return taskDesc;
  }

}
