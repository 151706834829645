import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ExpressClinicModalComponent } from './express-clinic-modal.component';
import { DirectiveModule } from '../directive/directive.module';
import { PipesModule } from '../pipes/pipes.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { ExpressClinicFacilityModule } from '../express-clinic-facility/express-clinic-facility.module';
import { ExpressClinicServiceModule } from '../express-clinic-service/express-clinic-service.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { ExpressClinicPatientModule } from '../express-clinic-patient/express-clinic-patient.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { ExpressClinicChooseSlotModule } from '../express-clinic-choose-slot/express-clinic-choose-slot.module';
import { ExpressClinicAppointmentConfirmModule } from '../express-clinic-appointment-confirm/express-clinic-appointment-confirm.module';
import { ExpressClinicHomeModule } from '../express-clinic-home/express-clinic-home.module';
import { ExpressClinicAppointmentReviewModule } from '../express-clinic-appointment-review/express-clinic-appointment-review.module';
import {CouponModule} from '../coupon/coupon.module';
import { ExpressClinicPractitionerModule } from '../express-clinic-practitioner/express-clinic-practitioner.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
@NgModule({
  declarations: [ExpressClinicModalComponent],
  imports: [
    CommonModule, DirectiveModule, PipesModule, PaymentCheckoutModule, ManageIdentitiesModule,
    ExpressClinicFacilityModule, ExpressClinicServiceModule, PractitionerAvatarModule, ExpressClinicPatientModule,
    PersonAvatarModule, ExpressClinicChooseSlotModule,ExpressClinicAppointmentConfirmModule, FormsModule,
    ExpressClinicHomeModule,ExpressClinicAppointmentReviewModule,ExpressClinicPractitionerModule,
    CouponModule, RegisterNewPatientModule
  ],
  
  exports:[ExpressClinicModalComponent]
})
export class ExpressClinicModalModule { }
