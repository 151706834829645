import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import * as _ from 'lodash';
import { environment } from '@env/environment';
import { AppointmentService } from '../services/appointment.service';
import { USER_TOKEN } from '@string';
import { CookieService } from '../services/cookie.service';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-bill-payment',
  templateUrl: './bill-payment.component.html',
  styleUrls: ['./bill-payment.component.scss']
})
export class BillPaymentComponent implements OnInit {
  public langSet: boolean = false;
  public fetchingBillDetail: boolean = false;
  public curLang: string;
  public key: string;
  public currency_code: string;
  public paymentGatewayUrl: string;
  public orderExpanded: boolean;
  public guidelineExpanded: boolean;
  public canExpand: boolean;
  public billingInformation: any;
  public serviceDetails: any = []
  public state: 'INVALID' | 'PENDING' | 'CANCELLED' | 'COMPLETED' | 'LOADING' = 'LOADING';
  public linkInformation: any;
  public hdrInfo: any;
  public patientInfo: any;
  public paymentInfo: any;
  public isCheckout: boolean = false;
  public errorTransaction: boolean = false;
  public showTC: boolean = false;
  public accept: boolean = false;
  public tandc: any = '';
  public showApplePay: boolean = false;
  public transactionRef: any;
  public myIP: any;
  public emerNumber: any;
  public isApplePay: boolean;
  public isPaying: boolean;
  public showOrderId: boolean = false; //default to hide order id on customer requirement
  constructor(private user: UserService, private activatedRoute: ActivatedRoute, private apptService: AppointmentService, private cookie: CookieService, private paymentService: PaymentService) {
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
  }

  ngAfterViewInit() {
    if ((window as any).ApplePaySession) {
      console.log('3')
      if ((window as any).ApplePaySession.canMakePayments) {
        this.showApplePay = true;
      }
    }
  }

  ngOnInit(): void {
    this.user.clearStorage()
    this.getMyip()
    const params = this.activatedRoute.snapshot.params;
    let qplang = this.activatedRoute.snapshot.queryParams.lang;
    let txnId = this.activatedRoute.snapshot.queryParams.txn;
    this.user.getLanguages().subscribe(data => {
      if (!qplang) qplang = 'en';
      let lang = data.find(d => d['localeCode'] == qplang);
      if (!lang) lang = data[0];
      this.user.setCurrentLanguage(lang);
      this.user.getLabels(true).subscribe(labels => {
        this.user.setLocaleLabels(labels);
        this.curLang = qplang;
        this.langSet = true;
      })
    })
    console.log(params);
    this.findCallNumber()
    const key = params['key'];
    this.key = key;
    if (key != 'NOTVALID') {
      this.fetchingBillDetail = true;
      this.validateBill(key, txnId);
    } else {
      this.state = 'INVALID'
    }
  }

  private findCallNumber() {
    this.user.getUpdateInfo('directives').subscribe(data => {
      const code = data.userDefined.find(def => {
        return def.code == 'BILLTS';
      })
      if (code && code.concepts && code.concepts[0]) {
        this.tandc = code.concepts[0].detail
      }
    })
    this.user.getUpdateInfo('facilities').subscribe(data => {
      let facility = data.facilities[0];
      if (facility) {
        let dir = facility.directory[0];
        if (dir) {
          this.emerNumber = dir.number
        }
      }
    })
  }

  private validateBill(key, txnId) {
    this.user.getUpdateInfo('configuration').subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    })
    this.user.validatePatientBill(key).subscribe(data => {
      this.isPaying = false;
      console.log(data);
      if (data['token'] && data['token']['token'])
        this.setStorage(USER_TOKEN, data['token']['token']);
      this.handleBillDetail(data);
      this.getTransactionStatus(txnId)
      this.fetchingBillDetail = false
      this.checkState()
    }, err => {
      this.isPaying = false;
      this.state = 'INVALID'
      this.fetchingBillDetail = false
      this.checkState()
    })
  }

  public call() {
    this.user.openLocation("tel:" + this.emerNumber, true);
  }

  private checkState() {
    if (['CANCELLED', 'COMPLETED'].includes(this.state)) {
      this.canExpand = true;
    } else {
      this.canExpand = false;
    }
  }

  public expand(type) {
    if (!this.canExpand) return;
    switch (type) {
      case "ORDER":
        this.orderExpanded = !this.orderExpanded;
        break;
      case "GUIDELINES":
        this.guidelineExpanded = !this.guidelineExpanded
        break;
    }
  }
  public handleBillDetail(response) {
    console.log(response);
    if (response.state == 'INVALID') {
      this.state = 'INVALID';
      return
    }
    this.mapBillInformation(response['billDetail'], response['externalOrderInfo'], response.state)
    this.linkInformation = response['externalOrderInfo'];
    this.patientInfo = response['patientInfo'];
  }

  private mapBillInformation(billDetail, externalOrderInfo, state) {
    if (billDetail) {
      const billInformation = billDetail.billTotal && billDetail.billTotal[0] ? billDetail.billTotal[0] : null;
      const serviceInformation = billDetail.serviceDetails;
      if (billInformation) {
        this.billingInformation = {
          billAmount: billInformation.billAmount,
          amountPaid: Number(billInformation.paidAmount),
          amountToBePaid: Number(billInformation.totalOutstanding),
          netPayable: billInformation.netPayableAmount,
          payableByPatient: billInformation.payableByPatient,
          payableByOthersDetails: billInformation.payableByOthersDetails,
          payableByOthers: billInformation.payableByOthers,
          billNumber: externalOrderInfo.billNumber,
          receiptDetails: billInformation.receiptDetails,
          vatExemption: billInformation.vatExemption,
          taskDtlAscnNo: externalOrderInfo.taskDtlAscnNo,
          eventDtlAsnNo: externalOrderInfo.eventDtlAsnNo,
        }
      }
      if (serviceInformation) {
        const serviceGroup = _.values(_.groupBy(serviceInformation, 'serviceGroupCode'))
        this.serviceDetails = serviceGroup
        console.log(serviceGroup)
      }
      this.hdrInfo = billDetail.billHdr && billDetail.billHdr[0] ? billDetail.billHdr[0] : null;
      if (Number(this.billingInformation.amountToBePaid) > 0) {
        this.state = 'PENDING';
      } else if (Number(this.billingInformation.amountToBePaid == 0)) {
        this.state = 'COMPLETED';
      }
    }
    if (state && (!this.state || !['PENDING', 'COMPLETED'].includes(state)))
      this.state = state;
  }

  public initPayment(isApplePay: boolean = false) {
    this.accept = false;
    this.showTC = true;
    this.isApplePay = isApplePay
  }

  public closeTC(start) {
    this.showTC = false;
    if (start && !this.isApplePay) {
      this.startPayment();
    }
    if (start && this.isApplePay) {
      this.initApplePay()
    }
  }

  private validateMerchant(validationUrl) {
    let self = this;
    return new Promise(async function (resolve, reject) {
      let param = {
        validationURL: validationUrl
      }
      self.apptService.verifyMerchant(param).subscribe(data => {
        console.log('validate merchant data---', data)
        return resolve(data);
      }, err => {
        console.log('validate merchant data error---', err)
        return reject(err)
      });
    })
  }

  private initApplePay() {
    this.isPaying = true;
    let charge = this.user.fixDecimalPlaces(this.billingInformation['amountToBePaid'])
    const paymentRequest = {
      countryCode: 'SA',
      currencyCode: 'SAR',
      shippingMethods: [
      ],
      lineItems: [
        {
          label: "Bill amount",
          amount: charge,
        }
      ],
      total: {
        label: 'Total Amount',
        amount: charge,
      },
      supportedNetworks: ['visa', 'mada', 'masterCard'], //["visa", "mada","masterCard"]
      merchantCapabilities: ['supports3DS']
    };
    const session = new (window as any).ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = async (event: any) => {
      console.log('Inside On Validate Merhant', event, event['validationURL'])
      const merchantSession = await this.validateMerchant(event['validationURL']);
      console.log('merchantsession', merchantSession);
      session.completeMerchantValidation(merchantSession);
    };

    session.onpaymentauthorized = (event: any) => {
      console.log('Payment authorized----', event, event['payment'], JSON.stringify(event['payment']))
      const result = {
        "status": (window as any).ApplePaySession.STATUS_SUCCESS
      };
      let inparams = {
        "merchant_reference": this.transactionRef,
        "amount": charge,
        "currency": this.currency_code,
        "customer_email": this.patientInfo['email'],
        "appleData": event['payment'],
        "mobileNumber": this.patientInfo['mobile'],
        "interfaceFrom": "WEB",
        "customer_ip": this.myIP
      }
      this.updateApplePayTransaction(this.transactionRef, inparams)
      session.completePayment(result);
    };

    session.oncancel = (event: any) => {
      console.log('check cancel event---', event);
      this.isPaying = false;
    };
    const { payload, request } = this.getRequestPayload(true);

    console.log('start transaction request object---', payload)
    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      console.log('start transaction response---', response)
      this.transactionRef = response.transaction_id;
      setTimeout(() => {
        session.begin();
      }, 500)
    }, err => {
      // this.isTransaction = false
    })
  }

  public updateApplePayTransaction(txnId, inparam) {
    console.log('update apple pay inparamss---', inparam)
    setTimeout(() => {
    }, 1000)
    this.apptService.updateApplePayStatus(inparam).subscribe(data => {
      console.log('update apple pay status---', data)
      setTimeout(() => {
      }, 500)
      this.getTransactionStatus(txnId, true)
    }, err => {
      this.isPaying = false;
      console.log('update apple pay error---', err)
      setTimeout(() => {
        this.getTransactionStatus(txnId, true)
      }, 1000)
    });
  }

  public getRequestPayload(forApplePay: boolean = false) {
    let payload = {
      patientId: this.patientInfo['patientId'],
      amount: this.user.fixDecimalPlaces(this.billingInformation['amountToBePaid']),
      currency: this.currency_code,
      episodeType: this.hdrInfo['episodeType'],
      episodeId: this.hdrInfo['episodeId'],
      encounterId: this.hdrInfo['encounterId'],
      facilityId: this.hdrInfo['facilityId'],
      requestType: 'SETTLEBILL',
      forApplePay: forApplePay
    };
    let request = {
      patientId: this.patientInfo['patientId'],
      amount: this.user.fixDecimalPlaces(this.billingInformation['amountToBePaid']),
      currency: this.currency_code,
      episodeType: this.hdrInfo['episodeType'],
      episodeId: this.hdrInfo['episodeId'],
      encounterId: this.hdrInfo['encounterId'],
      facilityId: this.hdrInfo['facilityId'],
      patientClass: this.hdrInfo['patientClass'],
      billNo: this.billingInformation['billNumber'],
      updateExternalOrder: true,
      externalOrderId: this.linkInformation.orderId,
      taskDtlAscnNo: this.billingInformation['taskDtlAscnNo'],
      eventDtlAsnNo: this.billingInformation['eventDtlAsnNo']
    }
    return { payload, request}
  }

  public startPayment() {
    const { payload, request } = this.getRequestPayload(false);
    this.apptService.startTransaction(payload, request).subscribe((response: any) => { 
      let bill = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.patientInfo['patientId'],
        email: this.patientInfo['email'],
        mobile_no: this.patientInfo['mobile']
      }
      let url = window['location'];
      let queryParams = `?txn=${response['transaction_id']}`;
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + queryParams;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() || this.currency_code,
        charge: this.user.fixDecimalPlaces(this.billingInformation['amountToBePaid']),
        paymentgatewayurl: this.paymentGatewayUrl
      }
      this.paymentService.setPaymentParams(bill, paymentInfo).subscribe(_ => {
        this.isCheckout = true;
      })
    })
  }

  public setStorage(key, value) {
    this.cookie.set(key, value);
  }

  public closeModel() {
    this.paymentService.setPaymentParams(null, null).subscribe(_ => { })
    this.isCheckout = false;
  }

  private getTransactionStatus(txnId, recheck: boolean = false) {
    if (!txnId) return;
    this.paymentService.getTransactionDetail(txnId).subscribe(data => {
      console.log('transaction data', data)
      if (data.status != 'S') {
        this.errorTransaction = true
      }
      if(recheck)
        this.validateBill(this.key, null);
      else
        this.isPaying = false;
    }, err => {
      this.isPaying = false;
      console.log('transaction error---', err)
    });
  }

  public confirmErrorModal(retry: boolean = false) {
    this.errorTransaction = false;
    if (retry) {
      this.initPayment()
    }
  }

  public async getMyip() {
    let res = await fetch("https://api.ipify.org?format=text");
    this.myIP = await res.text()
    console.log('ip ==', this.myIP);
  }

}
