import { Component, Input } from "@angular/core";
import * as moment from 'moment';
@Component({ template: '' })
export abstract class VisitAbstractClass {
    @Input() facility;
    @Input() selectedPract;
    @Input() selectedPatient;
    @Input() visitCharges;
    @Input() visitDetails;
    @Input() queryParams;
    @Input() languageList;
    @Input() practitionerState;
    constructor() {
    }

    getNextAvailableTime(pract) {
        if (!pract['nextAvailableTime'])
            return '';
        const [start, end] = pract['nextAvailableTime'].split('-');
        if (start && moment(start, 'HH:mm').isAfter(moment()))
            return moment(start, 'HH:mm').fromNow(true);
    }
    isAvailable(pract) {
        if (!pract['nextAvailableTime'])
            return false;
        const [start, end] = pract['nextAvailableTime'].split('-');
        const datetime = moment();
        pract['nextAvailable'] = start;
        return datetime.isBetween(moment(start, 'HH:mm'), moment(end, 'HH:mm'));
    }

    isWorking(pract) {
        return pract['isWorking'] === 'Y' && pract['nextAvailableTime']
    }

}