import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskComponent } from './task.component';
import { EventInfoModule } from '../event-info/event-info.module';
import { ClinicalformModule } from '../clinicalform/clinicalform.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module';
import { VaccinationCertificateModule } from '../vaccination-certificate/vaccination-certificate.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { HeaderModule } from '../header/header.module';
import { TaskAssessmentListsModule } from '../task-assessment-lists/task-assessment-lists.module';
import { FormpreviewModule } from '../formpreview/formpreview.module';


@NgModule({
  declarations: [TaskComponent],
  imports: [
    CommonModule, EventInfoModule, ClinicalformModule, ExpressClinicModalModule, VaccinationCertificateModule, PersonAvatarModule,
    PipesModule, HeaderModule, TaskAssessmentListsModule, FormpreviewModule
  ],
  exports:[TaskComponent]
})
export class TaskModule { }
