import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { AppointmentService } from '../services/appointment.service';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-patient-bill-details',
  templateUrl: './patient-bill-details.component.html',
  styleUrls: ['./patient-bill-details.component.scss']
})
export class PatientBillDetailsComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  @Input() selectedBill: any;
  @Input() selectedBillDetails: any;
  public isCouponCode: boolean = false;
  public isCheckout: boolean = false;
  public currencyCode: string;
  public paymentGatewayUrl: string;
  public installmentDetails: Array<any> = [];
  constructor(private user: UserService, private apptService: AppointmentService, private paymentService: PaymentService) { 
    this.close = new EventEmitter<any>();
    this.currencyCode = this.user.getCurrency()
  }

  ngOnInit(): void {
    console.log(this.selectedBillDetails);
    if (this.selectedBillDetails && this.selectedBillDetails.installementdtls && this.selectedBillDetails.installementdtls.length>0) {
      this.installmentDetails = this.selectedBillDetails.installementdtls;
      let firstUnpaidIndex = this.installmentDetails.findIndex(i => (!i.installement_status || i.installement_status != 'P'));
      if (firstUnpaidIndex > -1) {
        this.installmentDetails[firstUnpaidIndex]['showPay'] = true;
      }
    }
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl'];
    })
    
  }

  public closeModal(data?:any) {
    this.close.emit(data);
  }
  public initPay(billDetail) {
    this.closeModal({ action: 'INITPAY', bill: this.selectedBill, billDetail: billDetail })
  }
}
