<ng-container *ngIf="_input">
  <div class="card">
    <div class="header">
      <div class="font-default-small bold">{{'Reschedule_appointment' | translate}}</div>
      <img (click)="confirmClose(false)" class="cursor-pointer" src="./assets/icons/close_black.svg">
    </div>
    <div class="content">
      <app-choose-slot [_input]="_input" [visitType]="visitType" [flowType]="'RESCHEDULE'"
        (complete)="complete($event)" [isStandalone]="true"></app-choose-slot>
    </div>
    <div class="footer">
      <div class="accept-terms">
        <div class="input-group">
          <input type="checkbox" name="accept" [(ngModel)]="accept" id="accept_tc" />
          <label class="lbl">
            <span class="font-default-small">{{'msg_agree'|translate:'LABELS'}}</span>
            <span class="font-primary-small cursor-pointer"
              (click)="opentc()">{{'msg_terms_conditions'|translate:'LABELS'}} </span>
            <span class="font-default-small">{{'of_policy'|translate:'LABELS'}} </span>
          </label>
        </div>
      </div>
      <button class="primary-action" [disabled]="!accept || !selectedSlot" (click)="confirmReschedule()">{{'Confirm' | translate}}</button>
    </div>
    <ng-container *ngIf="showTC">
      <div class="tc-modal">
        <div class="content">
          <div class="modal-header border-bottom">
            <div class="font-primary-subtitle bold">{{'lbl_terms_conditions'|translate}}</div>
            <div (click)="closetc()" class="float-right closeicon pointer">
              <img src="./assets/icons/close_black.svg">
            </div>
          </div>
          <div class="modal-content font-default-small" [innerHTML]="tandc|translate:'LOCALE':'packageContent'">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>