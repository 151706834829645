<div class="search-f-s-p-wrapper">
  <div class="selected-facility-wrapper">
    <img src="./assets/icons/Book_new/clinic.svg" alt="">{{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}
    <span class="change-button" (click)="changeFacility()">{{'Change' | translate}}</span>
  </div>
  <div class="search-result">
    <div class="clinics-list pract-list">
      <div class="clinic-block">
        <div class="clinic-item-card practitioner-card" *ngFor="let pract of practList"
          (click)="completeSelection(pract)">
          <div class="card-main">
            <div class="image">
              <app-practitioner-avatar [url]="pract['photoUrl']"></app-practitioner-avatar>
            </div>
            <div class="card-content">
              <div class="container">
                <div class="title">{{ 'dr_label' |translate: 'LABELS'}} {{ pract['practitionerName'] | translate : translateType : 'name' }}</div>
                <div class="font-light-small">
                  <span>
                    {{ pract['specialityName'] | translate : translateType : 'name' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>