import { I18N_CUSTOM_TYPE } from './../../../app.const';
import { Component, OnInit, Input , ViewChild,ElementRef, Output, EventEmitter} from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import * as moment from 'moment';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { UserService } from 'src/app/services/user.service';
// import '@vaadin/vaadin-select/vaadin-select.js';
// import '@vaadin/vaadin-combo-box/vaadin-combo-box.js';
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent extends Type implements OnInit, SectionInterface {
  
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  @ViewChild('myDiv') myDiv: ElementRef;
  @Output() valueChangeComplete: EventEmitter<any>
  public _min:string;
  public _max:string;
  public restrictOldDates:boolean = false;
  public restrictFutureDates:boolean = false;  
  
  /**
   *  @refer @vaadin/src/vaadin-date-picker-mixin
   *  To change the default localization, replace the entire
   *  _i18n_ object or just the property you want to modify.
   */
  public DATE_PICKER_FORMAT = I18N_CUSTOM_TYPE;

  
  constructor(cfs: ClinicalformService, private user: UserService) {
    super(cfs);
    this.valueChangeComplete = new EventEmitter<any>();
    this._min = this.user.getMoment().subtract(120, 'years').format('YYYY-MM-DD');
    this._max = this.user.getMoment().add(1 , 'years').format('YYYY-MM-DD');

  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    if(this.section['domainCode'] == 'DOB')
      this._max = this.user.getMoment().format('YYYY-MM-DD');
    this.restrictOldDates = this.section.codify ==='Y' && this.section.domainControls ?  (this.section.domainControls.restrictPastDateEntry==='Y') : (this.section.restrictPastDateEntry==='Y');
    this.restrictFutureDates = this.section.codify === 'Y' && this.section.domainControls ? (this.section.domainControls.restrictFutureDateEntry === 'Y') : (this.section.restrictFutureDateEntry === 'Y');
    if (this.restrictOldDates && this.section['domainCode'] !== 'DOB') {
      this._min = this.user.getMoment().format('YYYY-MM-DD');
    }
    if (this.restrictFutureDates)
      this._max = this.user.getMoment().format('YYYY-MM-DD');

    if (this.section['domainCode'] == "DATTECON" || this.section['default'] == 'SYS_NOW')
      this.section.selected = this.user.getMoment().format('YYYY-MM-DD');
    
    if(this.section.selected){
      setTimeout(()=>{
        var datepicker = document.querySelector('#vaadin-dp-'+this.section.domainCode);
        datepicker['value']=this.user.getMoment(this.section.selected).format('YYYY-MM-DD')
      },100)
    }
    
  }

  onChange(ev) {
    console.log(ev,ev.target['__data'].value);
    setTimeout(()=>{
      if(ev.target['__data'].value){
        if(this.section['domainCode'] === 'DOB'){
          let dateVal = this.user.getMoment(ev.target['__data'].value,'YYYY-MM-DD');
          dateVal.isSameOrBefore(this.user.getMoment(),'day') &&  dateVal.isSameOrAfter(this.user.getMoment().subtract(120,'years')) ? this.section['selected'] =  new Date(ev.target['__data'].value) : this.clearValue();
        }else if(this.restrictOldDates){
          this.user.getMoment(ev.target['__data'].value,'YYYY-MM-DD').isSameOrAfter(this.user.getMoment(),'day') ? this.section['selected'] = new Date(ev.target['__data'].value) : this.clearValue();
        }else{
          this.section['selected'] = new Date(ev.target['__data'].value)
        }
      }else{
        this.section['selected']='';
      }
      if (this.section['selected']) {
        this.valueChangeComplete.emit(true);
      }
    },200);
  }

  clearValue(){
    var datepicker = document.querySelector('#vaadin-dp-'+this.section.domainCode);
    datepicker['value']=null;
  }

  
}
