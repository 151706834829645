<ng-container *ngIf="showModalView">
  <div id="register-patient" class="right-nav">
    <div (click)="closeModal()" class="empty"></div>
    <ng-container *ngIf="paymentInfo">
      <div class="checkout component">
        <div class="header border-bottom">
          <div class="font-primary-subtitle bold">{{'Checkout' | translate}}</div>
          <div (click)="closeModal()" class="float-right closeicon pointer">
            <img class="type-image" src="./assets/icons/close_black.svg">
          </div>
        </div>
        <ng-container *ngTemplateOutlet="paymentTemplateNew"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!showModalView">
  <ng-container *ngTemplateOutlet="paymentTemplateNew"></ng-container>
</ng-container>

<ng-template #paymentTemplateNew>
  <div class="overflow-container new-payment">
    <div class="clock-count-cont" id="clock-con">
      <p>Time remaining :<span id="clock"></span></p>
    </div>
    <div class="payment-content">
      <div class="nahdi-logo">
        <img class="type-image" src="assets/images/signin/logo_grey.png">
      </div>
      <div class="payment-amount">
        <div class="payment-card">
          <div class="label bold">{{'Total_amount' | translate}}</div>
          <div class="amount bold" id="payable-amount">{{paymentInfo['currency']}} {{paymentInfo['charge']}}</div>
        </div>
      </div>
      <div class="paymethod-title">
        <img class="prefix-icon type-image" src="assets/icons/cards/secure.svg" />
        <p checktranslate>{{'All_your_information_is_safe_and_secure' | translate}}</p>
      </div>
      <div class="payment-type-container check-payment-type" [ngClass]="{loading: isLoading}" id="stc-payment-type" *ngIf="canPaySTC">
        <div class="payment-type unchecked" id="stc-container" [ngClass]="{checked: selectedPayment == 'STC'}">
          <div class="choose-option"  [ngClass]="{'selected-option': ((!appointment['saved_stc_details'] || !appointment['saved_stc_details'].length) && selectedOption[0] && selectedOption[0]=='stc' && selectedOption[1] == 'UNSET')}" id="stc-payment" (click)="setSelectedPayment('STC')">
            <div class="label" checktranslate>
              <img class="type-image bit-extra-size" src="assets/icons/payment/stc_payment.svg" />
              {{'STC_Pay' | translate}}
            </div>
            <div class="icon">
              <img src="assets/icons/payment/right_arrow.svg" />
              <span class="spinner spinner-slow"></span>
            </div>
          </div>
          <div id="render-stc-tokens" *ngIf="appointment['saved_stc_details'] && appointment['saved_stc_details'].length">
            <div class="list-option loop choose-stc-token" *ngFor="let stc of appointment['saved_stc_details']" (click)="payExistingSTC(stc)" [ngClass]="{'selected-option': (selectedOption[0] && selectedOption[0]=='stc' && selectedOption[1] == stc.savedCardToken)}">
              <div class="label">
                <img class="type-image" src="assets/icons/payment/phone.svg" />
                <div class="card-info">
                  <span class="card-number" checktranslate>{{stc.mobile}}</span>
                </div>
              </div>
              <div class="icon">
                <img src="assets/icons/payment/right_arrow.svg" />
                <span class="spinner spinner-slow"></span>
              </div>
            </div>
          </div>
          <div class="list-option new" id="pay-new-stc" (click)="payNewSTC()" [ngClass]="{'selected-option': (selectedOption[0] && selectedOption[0]=='stc' && selectedOption[1] == 'UNSET')}">
            <div class="label">
              <img src="assets/icons/payment/add.svg" />
              <span class="add-label" checktranslate>{{'Add_new_number' | translate}}</span>
            </div>
            <div class="icon">
              <img src="assets/icons/payment/right_arrow.svg" />
              <span class="spinner spinner-slow"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-type-container check-payment-type" [ngClass]="{loading: isLoading}" id="card-payment-type" *ngIf="canPayCard">
        <div class="card-payment-title bold">
          {{'Card_Payment' | translate}}
        </div>
        <div class="payment-type checked" id="card-container" [ngClass]="{checked: selectedPayment == 'CARD'}">
          <div id="render-card-tokens" *ngIf="appointment['saved_card_details'] && appointment['saved_card_details'].length">
            <div class="list-option loop choose-card-token" *ngFor="let card of appointment['saved_card_details']" [ngClass]="{'exp-card': isCardExpired(card['expiryDate']), 'selected-option': (selectedOption[0] && selectedOption[0]=='card' && selectedOption[1] == card.savedCardToken)}" (click)="payExistingCard(card)">
              <div class="label">
                <img class="type-image" [attr.d]="card.paymentMode" [src]="card.paymentMode && cardMapping[card.paymentMode]? cardMapping[card.paymentMode]['icon'] : cardMapping['DEFAULT']['icon']" />
                <div class="card-info">
                  <span class="card-number" checktranslate>{{card.cardNumber}}</span>
                  <span class="card-expiry" checktranslate>
                    <span class="card-available">{{ 'Expires_on' | translate }}</span> <span class="card-expired">{{ 'Expired' | translate }}</span> {{card['expiryDate'] | moment : 'MM/YY' : 'YYMM'}}
                  </span>
                </div>
              </div>
              <div class="icon">
                <img src="assets/icons/payment/right_arrow.svg" />
                <span class="spinner spinner-slow"></span>
              </div>
            </div>
          </div>
          <div class="list-option new" id="pay-new-card" (click)="payNewCard()" [ngClass]="{'selected-option': (selectedOption[0] && selectedOption[0]=='card' && selectedOption[1] == 'UNSET')}">
            <div class="label">
              <img src="assets/icons/payment/add.svg" />
              <span class="add-label" checktranslate>{{'Add_new_card' | translate}}</span>
            </div>
            <div class="icon">
              <img src="assets/icons/payment/right_arrow.svg" />
              <span class="spinner spinner-slow"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div hidden id="preset-values">
      <form method="POST" name="customerData" [action]="paymentInfo['paymentgatewayurl'] + 'ccavRequestHandler'" id="customerForm">
        <input type="text" name="currency" id="currency_id" readonly class="currency-class" [(ngModel)]="paymentInfo['currency']"/>
        <input type="text" name="amount" id="amount_id" readonly class="amount-class" [(ngModel)]="paymentInfo['charge']" />
        <input type="text" name="merchant_reference" id="order_id" readonly [(ngModel)]="appointment['_orderId']" />
        <input type="text" name="language" id="language" readonly  [(ngModel)]="lang"/>
        <input type="text" name="order_description" id="patient_id" readonly   [(ngModel)]="appointment['uhid']"/>
        <input type="text" name="customer_email" id="billing_email" readonly [(ngModel)]="defaultEmail"/>
        <input type="text" name="merchant_extra" id="billing_tel" readonly [(ngModel)]="appointment['mobile_no']"/>
        <input type="text" name="token_name" id="saved_card_token" readonly value="">
        <input type="text" name="digital_wallet" id="digital_wallet" readonly value="" *ngIf="selectedPaymentCode == 'STC'">
        <input type="text" name="merchant_extra4" id="return_url" [(ngModel)]="paymentInfo['_returnURL']">
      </form>
    </div>
  </div>
  
</ng-template>

<ng-template #paymentTemplate>
  <div class="payment-checkout" [ngClass]="{'add-hdr': showModalView }">
    <div class="logo">
      <img class="type-image" src="assets/images/signin/logo_grey.png" alt="">
    </div>
    <form ngNoForm method="POST" class="form-data" name="customerData"
      [action]="paymentInfo['paymentgatewayurl'] + 'ccavRequestHandler'">
      <p class="patient-payable"> {{'PATIENT_PAYABLE'|translate}}</p>
      <div class="align_center">
      <input type="text" name="currency" id="currency_id" readonly="" [(ngModel)]="paymentInfo['currency']"
        class="currency-class" placeholder="{{ 'search_placeholder_schedule' | translate }}" >
      <input type="text" name="amount" id="amount_id" readonly="" [(ngModel)]="paymentInfo['charge']"
        class="amount-class" placeholder="{{ 'search_placeholder_schedule' | translate }}" >
      </div>
      <p class="checkout-text"> {{'CHECKOUT'|translate}}</p>

      <div *ngIf="appointment['saved_card_details'] && appointment['saved_card_details'].length" class="card-wrapper">
        <div class="card-dtls">
          <div *ngFor="let c of appointment['saved_card_details']" class="card-item cursor-pointer" (click)="selectCard(c)">
            <p class="card-no">{{c['cardNumber']}}</p>
            <p class="card-name" [ngClass]="{'expired-card-name': isCardExpired(c['expiryDate'])}">
              {{c['cardHolderName']}}
              - {{ 'card_expire' | translate }}
              {{c['expiryDate'] | moment : 'MM/YY' : 'YYMM'}}</p>
            <img  src="./assets/icons/drop_arrow_grey.svg" alt="">
          </div>
          <div class="card-item cursor-pointer" (click)="payWithSTC()">
            {{'Pay_with_stc' | translate}}
          </div>
          <input type="submit" value="{{'btn_pay_new_card'|translate}}" class="primary-button">
          
        </div>
      </div>
      <div class="footer">
        <input *ngIf="!appointment['saved_card_details'] || appointment['saved_card_details'].length == 0" type="submit"
          value="{{'btn_checkout'|translate}}" class="primary-button">
        <input type="hidden" name="merchant_reference" id="order_id" [(ngModel)]="appointment['_orderId']" readonly="">
        <input type="hidden" name="token_name" id="saved_card_token"
          [(ngModel)]="appointment['saved_card_token']" readonly="">
        <input type="hidden" name="digital_wallet" id="digital_wallet"
          [(ngModel)]="appointment['digital_wallet']" readonly="">
        <input type="hidden" name="language" id="language" [(ngModel)]="lang">
        <input type="hidden" id="patient_id" name="order_description" [(ngModel)]="appointment['uhid']">
        <input type="hidden" name="customer_email" id="billing_email" [(ngModel)]="defaultEmail">
        <input type="hidden" name="merchant_extra" id="billing_tel" [(ngModel)]="appointment['mobile_no']">
        <input type="hidden" name="merchant_extra4" id="return_url" [(ngModel)]="paymentInfo['_returnURL']">
      </div>
      <div class="payment-logo">
        <img class="type-image" [src]="'assets/images/mada_'+lang+'.png'" alt="">
      </div>
    </form>
  </div>
</ng-template>