import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { FilterComponent } from './filter.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../directive/directive.module';

@NgModule({
  declarations: [ FilterComponent ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    DirectiveModule
  ],
  exports: [
    FilterComponent
  ]
})
export class FilterModule { }