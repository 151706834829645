import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-appointment-overlap-modal',
  templateUrl: './appointment-overlap-modal.component.html',
  styleUrls: ['./appointment-overlap-modal.component.scss']
})
export class AppointmentOverlapModalComponent implements OnInit {

  @Output() confirm: EventEmitter<any>;
  @Output() goBack: EventEmitter<any>;
  constructor() {
    this.goBack = new EventEmitter<any>();
    this.confirm = new EventEmitter<any>();
  }


  ngOnInit(): void { }

  public back() {
    this.goBack.emit();
  }

  public continue() {
    this.confirm.emit()
  }


}
