import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { LOGIN_CONTEXT } from '@const';

@Component({
  selector: 'app-hr-visit-details',
  templateUrl: './hr-visit-details.component.html',
  styleUrls: ['./hr-visit-details.component.scss']
})
export class HrVisitDetailsComponent implements OnInit {
  @Input() set selectedVisit(value: any) {
    this._selectedVisit = value;
  }
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
  }
  @Input() isCtx: boolean;
  @Input() context: any;
  @Input() isCommonView: any;
  @Input() qrCodeUrl: any;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() tab: string;
  @Input() reportId: any;
  @Output() selectReport: EventEmitter<any>;
  @Output() clearVisit: EventEmitter<any>;
  @Output() openDocsUpload: EventEmitter<any>;
  public isVisitReport: boolean;
  public isVisitPrescription: boolean;
  public isVisitBills: boolean;
  public isVisitHistory: boolean;
  public isVisitSummary: boolean;
  public _selectedVisit: any;
  public _selectedPatient: any;
  refresh1: Subject<boolean> = new Subject<boolean>();
  public visitTabs = TABS;
  constructor(private user: UserService, private featureFlagService: FeatureFlagsService) { 
    this.selectReport = new EventEmitter<any>();
    this.clearVisit = new EventEmitter<any>();
    this.openDocsUpload = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.refresh.subscribe(response => {
      if (response) {
        this.refresh1.next(true);
      }
    })
    if (this.isCtx && this.context && this.context.type == LOGIN_CONTEXT['BILL']) {
      this.changeActiveTab('BILLS')
    } else if(!this.tab)
        this.changeActiveTab('DETAILS')
  }

  public changeActiveTab(name:string) {
    this.tab = name;
  }
  public chooseReport(event) {
    this.selectReport.emit(event);
  }

  public chooseVisit(visit) {
    this._selectedVisit = visit;
    this.selectedVisit = visit;
    
  }
  public clear() {
    this.clearVisit.emit();
  }

  public openDocUpload() {
    this.openDocsUpload.emit({type: 'OTH' });
  }

  public openPrescriptionUpload() {
    this.openDocsUpload.emit({type: 'PRE' });
  }
}

const TABS = [{
  code: 'DETAILS',
  label: 'tab_visit_details',
}, {
  code: 'REPORTS',
  label: 'tab_reports',
  flag: 'FT_APP_HR_REPORT'
}, {
  code: 'PRES',
  label: 'tab_prescription',
  flag: 'FT_APP_HR_PRESCRIPTION'
}, {
  code: 'BILLS',
  label: 'tab_bills',
  flag: 'FT_APP_HR_VW_VST_HIS'
}, {
  code: 'TRANSCRIPT',
  label: 'tab_transcript',
}]