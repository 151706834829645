export const environment = {
  production: true,
  ENTITY_TOKEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbnRpdHlJZCI6IkFMTkFIREkiLCJpYXQiOjE1NDM5MzQ0OTksImF1ZCI6IkhlYWx0aHBsdWcgQXBwcyIsImlzcyI6IkhlYWx0aHBsdWcgc2VydmVyIiwic3ViIjoiSGVhbHRocGx1ZyBBcHBzIHRva2VuIiwianRpIjoib2VyNDQ1amRsZHNramZoMzhvaGQifQ.04TXfCw4uWw0POwQ7_BO3AbZXQPHH6LJoR5lTU5e1WY',
  API_URL: 'https://healthp.nahdi.sa/',
  APP_VERSION: '1',
  SOCKET_URL: 'https://healthp.nahdi.sa/',
  SOCKET_PATH: '/socket.io',
  COUNTRY_CODE: '+966',
  CURRENCY: 'SAR',
  DEFAULT_EMAIL: 'info@bionworks.com',
  TOKEN_EXPIRY_HOURS: 8,
  urlInfo: {
    specifyLanguage: true,
    index: 'https://healthp.nahdi.sa/patientportal/#/portal/home',
    facebook: 'https://www.facebook.com/NahdiCareClinics',
    youtube:	 'https://www.youtube.com/channel/UCrzQ3QaY0i-cJvE2-QRsilw',
    instagram: 'https://instagram.com/nahdiclinics?igshid=1kyvyh5mwj93r',
    twitter:	 'https://twitter.com/CareNahdi',
    linkedin: 'https://www.linkedin.com/company/al-nahdi-medical-co-',
    android: 'https://play.google.com/store/apps/details?id=com.nahdi.nahdipx',
    iOS: 'https://apps.apple.com/nz/app/nahdicare-clinics/id1462753220',
    termscondition: 'https://healthp.nahdi.sa/static/tos/%language%.html',
    privacypolicy: 'https://healthp.nahdi.sa/static/pp/%language%.html',
    contact: 'https://nahdicareclinics.sa/index.php/%language%/contact'
  },
  RETRIEVE_LOCALSTORAGE: false,
  APP_ENABLED_DEFAULT: true,
  LOGIN_VERSION: 'NORMAL',
  isGTMEnabled: true,
  GTMKey: 'GTM-KZS36LV',
  enableHotJor:true,
  hotJorKey: 3114026,
  ENTITY: 'ALNAHDI',
  COOKIESCRIPT: {
    enable: true,
    directAttributes: [
      {
        key: 'src',
        value: 'https://cdn-prod.eu.securiti.ai/consent/cookie-consent-sdk.js'
      },
      {
        key: 'defer',
        value: true
      },
    ],
    setAttributes: [
      {
        key: 'data-tenant-uuid',
        value: 'e10966bd-6e4e-46e0-86e7-13769c672979'
      },
      {
        key: 'data-domain-uuid',
        value: 'ff166a10-c0be-45f2-86fc-79ae07fdb6d1'
      },
      {
        key: 'data-backend-url',
        value: 'https://app.eu.securiti.ai'
      },
    ],
    supportedDomain: ["https://healthp.nahdi.sa", "https://healtht.nahdi.sa", "http://localhost:4400", "http://nahdicareclinics.sa"],
    onloadFunctions: ["initCmp", "setConsentBannerParams"]
  },
  PORTAL_BASE_URL: 'https://healthp.nahdi.sa/patientportal/#/',
};