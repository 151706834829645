import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss']
})
export class ConsentModalComponent implements OnInit{
  @Output() complete: EventEmitter<any>
  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>()
  }

  ngOnInit() {}

  public close() {
    this.complete.emit({type: 'CLOSE'})
  }

  public continue() {
    this.complete.emit({type: 'CONSENT'})
  }

}
