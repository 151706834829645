<div id=hlth-pkg-detail>
    <ng-container *ngIf="!openPayment && false" > 
        <div class="header border-bottom">
            <div class="font-primary-subtitle bold" *ngIf="!isReqRecieved">{{ 'title_confirm_pkg_req'|translate:'LABELS'}} </div>
            <div class="font-primary-subtitle bold" *ngIf="isReqRecieved">
              <span *ngIf="paymentStatus=='S' || paymentStatus =='REQ'">{{ 'title_pkg_req_received' |translate:'LABELS'}} </span>
              <span *ngIf="paymentStatus=='F'|| paymentStatus=='P'"> {{ 'title_confirm_pkg_req'|translate:'LABELS'}} </span>
              <span *ngIf="paymentStatus=='R'">{{ 'title_confirm_pkg_req'|translate:'LABELS'}}</span>
            </div>
            <div (click)="closeConfirmModal()" class="float-left closeicon pointer">
                <img src="./assets/icons/close_black.svg">
            </div>
        </div>
        <div class="modal-pkg-content">
            <div class="card-review" *ngIf="isReqRecieved">
                <div class="card row-content">
                  <img class="type-image img-alert" src="assets/icons/alert.svg" />
                    <div class="font-light-small padding">
                        <!-- {{'msg_request_alert'|translate }} -->
                        <span *ngIf="paymentStatus=='S' || paymentStatus =='REQ'"> {{'msg_request_alert'|translate }}</span>
                        <span *ngIf="paymentStatus=='F'|| paymentStatus=='P'">  {{ 'msg_failed' | translate}} </span>
                        <span *ngIf="paymentStatus=='R'">{{'msg_retry' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="card-review">
                <div class="card">
                  <div class="card-head border-bottom">
                    <span class="font-default-small bold">{{'ttl_selected_pkg'|translate:'LABELS'}}</span>
                  </div>
                  <div class="card-content">
                    <div class="partitioner-details">
                      <div class="image" *ngIf="detail && detail.imageURL">
                        <img [src]="detail.imageURL">
                        <!-- <app-practitioner-avatar [url]="this.detail.imageURL"></app-practitioner-avatar> -->
                      </div>
                      <div class="partitioner-content">
                        <div class="title">    {{getTitle(concept.name)}}    </div>
                        <div class="font-light-small">    {{getSubTitle(concept.detail)}}    </div>
                      </div>
                    </div>
                    <div class="vertical-divider">
                      <div class="divider"></div>
                    </div>
                    <div class="patient-details">
                      <div class="image">
                        <app-person-avatar [url]="pkgPrefer['patient']?pkgPrefer['patient']['imageurl']:''"> </app-person-avatar>
                      </div>
                      <div class="partitioner-content">
                        <div class="title">
                          {{pkgPrefer['patient']?pkgPrefer['patient']['personname']:''}}
                        </div>
                        <div class="font-light-small">
                          {{pkgPrefer['patient']?(pkgPrefer['patient']['relationshiptype'] | translate : 'RELATION'):''}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-foot border-top">
                    <div class="prefer-content" >
                        <div class="font-light-small">{{ 'lbl_preferred_date' | translate }}</div>
                        <div class=" ">{{ pkgPrefer['preferredDate'] | moment:'DD MMM YYYY':'YYYY-MM-DD' }}</div>
                    </div>
                    <div class="prefer-content" >
                        <div class="font-light-small">{{ 'lbl_preferred_facility' | translate }}</div>
                        <div class=" "> 
                            <!-- {{ pkgPrefer['facility'] | translate : 'LOCALE' : 'name'}} -->
                            {{(pkgPrefer['facility']['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                        </div>
                    </div>
                    <div class="prefer-content" >
                        <div class="font-light-small">{{ 'lbl_package_cost' | translate }}</div>
                        <div class="bold ">{{currency_code}} {{ pkgPrefer['pkgCost'] }}</div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="btn-container" *ngIf="!isReqRecieved">
                <span *ngIf="appConfig.features.paymentGateway.enabled && appConfig.features.payableServices.services"
                    style="background-color: whitesmoke !important;">
                    <div *ngIf="pkgPrefer['facility'].controls.payableOnline">
                        <button class="primary-button btn-customize"  (click)="checkFunction('PAY', concept.name)" >{{ 'btn_confirm_appt_pay' | translate }} {{currency_code}} {{ pkgPrefer['pkgCost'] }}</button>
                    </div>
                    <div *ngIf="!pkgPrefer['facility'].controls.payableOnline">
                        <button class="primary-button btn-customize" (click)="request('NEWREQ', concept.name)" >{{'lbl_confirm_req'|translate}}</button>
                    </div>
                </span>
                <span *ngIf="!appConfig.features.paymentGateway.enabled || !appConfig.features.payableServices.services">
                    <button class="primary-button btn-customize" (click)="request('NEWREQ', concept.name)" >{{'lbl_confirm_req'|translate}}</button>
                </span>
                <!-- <button class="primary-button btn-customize" (click)="request('NEWREQ', concept.name)" >{{'lbl_confirm_req'|translate}}</button> -->
            </div>
            <div class="font-light-small padding align"  *ngIf="!isReqRecieved"> {{ 'lbl_confirm_req_msg' | translate }} </div>
            <div class="card-review" *ngIf="isReqRecieved && paymentStatus !='REQ' ">
              <div class="card">
                <div class="card-head border-bottom">
                  <span class="font-default-small bold">{{'transaction_details'|translate:'LABELS'}}</span>
                </div>
                <div class="card-foot">
                  <div class="prefer-content" >
                    <div class="font-light-small">{{'transaction_id'|translate:'LABELS'}}</div>
                    <div>{{paymentData['transactionId']||'-'}}</div>
                  </div>
                  <div class="prefer-content" >
                    <div class="font-light-small">{{'gateway_ref_no'|translate:'LABELS'}}</div>
                    <div>{{paymentData['gatewayRefId']||'-'}}</div>
                  </div>
                  <div class="prefer-content" >
                    <div class="font-light-small">{{'bank_ref_no'|translate:'LABELS'}}</div>
                    <div>{{paymentData['bankRefId']||'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-container" *ngIf="isReqRecieved">
                <button class="button-transparent btn-customize" (click)="closeConfirmModal()" >{{'btn_close'|translate }}</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!openPayment" > 
      <div class="header border-bottom spac-btw">
        <div class="flex">
            <div (click)="closeModal('BACK')" class="float-left closeicon pointer">
                <img src="./assets/icons/arrow.svg">
            </div>
            <div class="font-primary-subtitle bold" *ngIf="!isReqRecieved">{{ 'title_review'|translate:'LABELS'}} </div>
            <div class="font-primary-subtitle bold" *ngIf="isReqRecieved">
              <span *ngIf="paymentStatus=='S' || paymentStatus =='REQ'">{{ 'title_pkg_req_received' |translate:'LABELS'}} </span>
              <span *ngIf="paymentStatus=='F'|| paymentStatus=='P'"> {{ 'title_confirm_pkg_req'|translate:'LABELS'}} </span>
              <span *ngIf="paymentStatus=='R'">{{ 'title_confirm_pkg_req'|translate:'LABELS'}}</span>
            </div>
        </div>
        <div (click)="closeModal('CLOSE')" class="float-right closeicon pointer">
            <img src="./assets/icons/close_black.svg">
        </div>
    </div>

      <div class="modal-pkg-content">
          <div class="card-review-alert" *ngIf="isReqRecieved">
            <div class="success-container">
              <img src="assets/icons/health-package/check.svg">
              <span class="font-default-small bold" *ngIf="paymentStatus=='S' || paymentStatus =='REQ'">{{'cal_modal_success_msg'|translate:'LABELS'}}</span>
              <span *ngIf="paymentStatus=='F'|| paymentStatus=='P'">  {{ 'msg_failed' | translate}} </span>
              <span *ngIf="paymentStatus=='R'">{{'msg_retry' | translate}}</span>
            </div>
            <div class="info-container">
              <div class="font-default-small col-9">{{'appt_dtl_msg_download_app_now'|translate:'LABELS'}}</div>
              <div class="logo-container col-3">
                <div class="logo">
                  <img class="type-image" src="assets/images/app-googleplay.png" alt="">
                </div>
                <div class="logo">
                  <img class="type-image" src="assets/images/apple_play.png" alt="">
                </div>
                <div class="logo" *ngIf="false">
                  <img class="type-image" src="assets/images/apple_play.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="card-review">
            <app-appt-dtl-card [apptInfo]="requestInfo" (editAction)="switchActions($event)" [isEditApplicable]="isEditApplicable" ></app-appt-dtl-card>
          </div>
          <div class="card-review" *ngIf="isReqRecieved && paymentStatus !='REQ' ">
            <div class="card">
              <div class="card-head border-bottom">
                <span class="font-default-small bold">{{'transaction_details'|translate:'LABELS'}}</span>
              </div>
              <div class="card-foot">
                <div class="prefer-content" >
                  <div class="font-light-small">{{'transaction_id'|translate:'LABELS'}}</div>
                  <div>{{paymentData['transactionId']||'-'}}</div>
                </div>
                <div class="prefer-content" >
                  <div class="font-light-small">{{'gateway_ref_no'|translate:'LABELS'}}</div>
                  <div>{{paymentData['gatewayRefId']||'-'}}</div>
                </div>
                <div class="prefer-content" >
                  <div class="font-light-small">{{'bank_ref_no'|translate:'LABELS'}}</div>
                  <div>{{paymentData['bankRefId']||'-'}}</div>
                </div>
              </div>
            </div>
          </div>

      </div>

      <div class="modal-footer">
        <div class="btn-container" *ngIf="!isReqRecieved">
              <span  *ngIf="canPay">
                  <button class="primary-button btn-customize"  (click)="checkFunction('PAY', requestInfo.selectedPkg.name)" >{{ 'btn_confirm_appt_pay' | translate }} {{ patientPayable }} {{currency_code}}</button>
              </span>
              <span  *ngIf="!canPay">
                  <button class="primary-button btn-customize" (click)="request('NEWREQ', requestInfo.selectedPkg.name)" >{{'lbl_submit'|translate}}</button>
              </span>
        </div>
        <div class="btn-container" *ngIf="isReqRecieved">
          <button class="primary-button" (click)="closeModal('CLOSE')" >{{'btn_back_to_home'|translate }}</button>
        </div>
      </div>
  </ng-container>

    <ng-container *ngIf="openPayment">
      <div class="header border-bottom">
        <div class="font-primary-subtitle bold">{{'title_confirm_pkg_req'|translate}}
        </div>
        <div (click)="closeConfirmModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="modal-payment-content">
        <app-payment-checkout></app-payment-checkout>
      </div>
    </ng-container>
</div>

