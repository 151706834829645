import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import {ExpressClinicFacilityComponent} from './express-clinic-facility.component';
import { NodataModule } from '../nodata/nodata.module';

@NgModule({
  declarations: [ExpressClinicFacilityComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule
  ],
  
  exports:[ExpressClinicFacilityComponent]
})
export class ExpressClinicFacilityModule { }
