<!-- <div id="hr-visit-bill-component">
  <div class="search-header">
    <div class="search" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon"  (click)="searchIcon()">
          <img class="type-image" src="assets/icons/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_bill'|translate:'LABELS'}}" [(ngModel)]="searchTerm"
            (input)="searchFilter($event)" />
        </div>
      </div>
    </div>
    <div class="filter" [ngClass]="{active: (searchExpand === true)}" >
      <div class="dp-down cursor-pointer">
        <div class="dp-select" (click)="openCloseFilter()">
          <span class="font-primary-default">{{selectedFilter.value|translate:'LABELS'}}</span>
        </div>
        <div class="dp-option" *ngIf="isFilterOpen">
          <span class="option" (click)="changeFilter(item)"
            *ngFor="let item of filters">{{item.value|translate:'LABELS'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="outer-container" *ngIf="!hrPatientBillingClaimSummary">
    <div class="content-header" *ngIf="depositList && depositList.length > 0 && !selectedFilter['key']">
      <div class="title-black bold">{{'lbl_deposit'|translate:'LABELS'}}</div>
    </div>
    <div class="content" *ngIf="depositList && depositList.length > 0 && !selectedFilter['key']">
      <div class="outer-list border-bottom" *ngFor="let item of depositList">
        <div class="list" >
          <div class="list-header">
            <div class="prescription-icon">
              <img class="type-image" *ngIf="item.episodeType === 'O'"  src="assets/icons/billing/cash_grey.svg" />
            </div>
            <div class="list-content" (click)="openReceipt('hr-receipt-details-modal',item)">
              <div class="header-section">
              <p class='label'>
                <span class="title-black bold">{{item['docDate'] | moment : 'DD MMM YYYY'}}</span>
              </p>
            </div>
              <p class="title-primary bold show" *ngIf="false">{{currency_code}} {{item.billAmount}}</p>
              <div class="footer-section ">
                <span class="subtitle-ternary">{{item['docRef'] }}</span>
            </div>
            </div>
          </div>
          <div class="currency deposit">
            <span class="title-primary bold ">{{currency_code}} {{ -(item['amount'])}}</span>
            <div class="footer-section ">
              <span class="subtitle-ternary">{{'deposit_amt' | translate :'LABELS'}} : {{currency_code}} {{item['docAmoount']}}</span>
            </div>
            <button class="primary-button btn-icon" *ngIf="false">
              <img class="type-image" src="assets/icons/calendar/pay.svg" />
            </button>
          </div>
      </div>
    </div>
    </div>
    <div class="content-header" *ngIf='unsettledBill && unsettledBill.length > 0'>
      <div class="title-black bold">{{'pending_bills'|translate:'LABELS'}}</div>
    </div>
    <div class="content" *ngIf='unsettledBill && unsettledBill.length > 0'>
      <div class="outer-list border-bottom" *ngFor="let item of unsettledBill">
        <div class="list" >
          <div class="list-header">
            <div class="prescription-icon">
              <img class="type-image" *ngIf="item.episodeType === 'O'"  src="assets/icons/calendar/op.svg" />
              <img class="type-image" *ngIf="item.episodeType === 'I'"  src="assets/icons/bed.svg" />
              <img class="type-image" *ngIf="item.episodeType === 'P'"  src="assets/icons/medicine.svg" />
              <img class="type-image" *ngIf="item.episodeType === 'E'"  src="assets/icons/ambulance.svg" />
            </div>
            <div class="list-content" (click)="openBill('hr-visit-bill-details-modal',item)">
              <div class="header-section">
              <p class='label'>
                <span class="title-black bold" *ngIf="item.episodeType === 'O' ">{{'bill_label_out_patient'|translate:'LABELS'}}</span>
                <span class="title-black bold" *ngIf="item.episodeType === 'I' ">{{'bill_label_in_patient'|translate:'LABELS'}}</span>
                <span class="title-black bold" *ngIf="item.episodeType === 'P' ">{{'bill_label_pharmacy'|translate:'LABELS'}}</span>
                <span class="title-black bold" *ngIf="item.episodeType === 'E' ">{{'bill_label_emergency'|translate:'LABELS'}}</span>
                <span class="title-black">{{item.billDate | moment : 'DD MMM YYYY'}}</span>
              </p>
            </div>
              <p class="title-primary bold show" *ngIf="false">{{currency_code}} {{item.billAmount}}</p>
              <div class="footer-section ">
                <span class="subtitle-black bold">
                  {{(item['practitionerId'] | partitionerDetails:item['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || item.practitionerName }}      
                </span>
                <span class="subtitle-ternary ">{{item.clinicName}}</span>
            </div>
            </div>
          </div>
          <div class=" currency">
            <span class="title-primary bold ">{{currency_code}} {{item.billAmount}}</span>
            <button class="primary-button btn-icon" *ngIf="false">
              <img class="type-image" src="assets/icons/calendar/pay.svg" />
            </button>
          </div>
        </div>
        <div class="footer-section show" *ngIf="false">
            <span class="subtitle-black ">
              {{(item['practitionerId'] | partitionerDetails:item['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || item.practitionerName }}      
            </span>
            <span class="subtitle-ternary ">{{item.clinicName}}</span>
        </div>
    </div>
    </div>
    <div class="content-header" *ngIf='settledBill && settledBill.length > 0'>
      <div class="title-black bold">{{'settled_bills'|translate:'LABELS'}}</div>
    </div>
    <div class="content" *ngIf='settledBill && settledBill.length > 0'>
      <div class="outer-list border-bottom" *ngFor="let item of settledBill">
        <div class="list">
        <div class="list-header">
          <div class="prescription-icon">
            <img class="type-image" *ngIf="item.encounterType === 'O'"  src="assets/icons/calendar/op.svg" />
            <img class="type-image" *ngIf="item.encounterType === 'I'"  src="assets/icons/bed.svg" />
            <img class="type-image" *ngIf="item.encounterType === 'P'"  src="assets/icons/medicine.svg" />
            <img class="type-image" *ngIf="item.encounterType === 'E'"  src="assets/icons/ambulance.svg" />
          </div>
          <div class="list-content" (click)="openBill('hr-visit-bill-details-modal',item)">
            <div class="header-section">
            <p class='label'>
              <span class="title-black bold" *ngIf="item.encounterType === 'O'" >{{'bill_label_out_patient'|translate:'LABELS'}}</span>
              <span class="title-black bold" *ngIf="item.encounterType === 'I'" >{{'bill_label_in_patient'|translate:'LABELS'}}</span>
              <span class="title-black bold" *ngIf="item.encounterType === 'P'" >{{'bill_label_pharmacy'|translate:'LABELS'}}</span>
              <span class="title-black bold" *ngIf="item.encounterType === 'E'" >{{'bill_label_emergency'|translate:'LABELS'}}</span>
              <span class="title-black"> {{item.billDate | moment : 'DD MMM YYYY'}}</span>
            </p>
          </div>
          <div class="footer-section ">
              <span class="subtitle-black bold">
                {{(item['practitionerId'] | partitionerDetails:item['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || item.practitionerName }}      
              </span>
              <span class="subtitle-ternary">{{item.clinicName}}</span>
          </div>
            <p class="show"  *ngIf="false">
              <span class="title-primary bold">{{currency_code}} {{item.billPaidAmount}}</span>
            </p>
          </div>
        </div>
        <div class=" currency">
          <span class="title-primary bold">{{currency_code}} {{item.billPaidAmount}}</span>
        </div>
      </div>
      <div class="footer-section show"  *ngIf="false">
          <span class="subtitle-black">
            {{(item['practitionerId'] | partitionerDetails:item['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || item.practitionerName}}      
          </span>
          <span class="subtitle-ternary">{{item.clinicName}}</span>
      </div>
      </div>
    </div>
    <div class="lists" *ngIf=" (!unsettledBill || unsettledBill.length === 0) && (!settledBill || settledBill.length === 0) && (!depositList || depositList.length === 0)">
      <div class="no-data" >
        <app-nodata></app-nodata>
      </div>
    </div>
  </div>
  <div class="applicable-bills" *ngIf="hrPatientBillingClaimSummary">
    <div class="billing-card-table border-bottom">
      <div class="bill-table">
        <div class="bill-table-row table-header" *ngIf="allBills && allBills.length > 0">
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: a;"></div>
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: b;">{{'Bill_number' | translate}}</div>
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: c;">{{'Bill_date' | translate}}</div>
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: d;">{{'Amount'|translate}}</div>
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: e;">{{'Status'|translate}}</div>
          <div class="bill-table-cell font-light-small1 bold" style="grid-area: f;">{{'Actions'|translate}}</div>
        </div>
        <div class="bill-table-row table-content" *ngFor="let item of allBills; let i = index;">
          <div class="bill-table-cell" style="grid-area: a;">
            <div class="view-type-thumbnail">
              <img class="thumbnail-image type-image" src="assets/icons/bills_prim.svg" />
            </div>
          </div>
           <div class="bill-table-cell" style="grid-area: b;">
             <div class="view-type-2 billdetails">
               <div class="primary-section">{{item['billNo']}}</div>
             </div>
           </div>
          <div class="bill-table-cell" style="grid-area: c;">
            <div class="view-type-2 bill-date">
              <div class="primary-section font-default-subtitle">{{item['billDate'] | moment : 'DD MMM YYYY, hh:mm a'}}
              </div>
            </div>
          </div>
          <div class="bill-table-cell" style="grid-area: d;">
            <div class="view-type-2 payamount">
              <div class="primary-section">{{currency_code}} {{item['totalBill']}}</div>
            </div>
          </div>
          <div class="bill-table-cell" style="grid-area: e;">
            <div class="view-type-2 paydetails">
              <button class="{{billStatusColorMapping?.[item['billStatus']]?.class }} bold btn-flat btn-small" read-only>{{item['billStatus'] | translate}}</button>
            </div>
          </div>
          <div class="bill-table-cell" style="grid-area: f;">
            <div class="view-type-action">
              <button class="infolight-font-primary-button view-button"
                (click)="openBillPreview(item, 'VIEW')"><img src="assets/icons/signin/eye_active.svg"/></button>
            </div>
          </div>
          <div class="click-overlay" (click)="openBillPreview(item, 'VIEW')"></div>
        </div>
        <div class="no-data"  *ngIf="!allBills || allBills.length == 0">
          <app-nodata></app-nodata>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="hr-visit-bills-wrapper">
  <div class="hr-visit-bills-list">
    <div class="search" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon"  (click)="searchIcon()">
          <img class="type-image" src="./assets/icons/Book_new/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_bill'|translate:'LABELS'}}" [(ngModel)]="searchTerm"
            (input)="searchFilter($event)" />
        </div>
        <div class="clear-search cursor-pointer" (click)="searchTerm=''" *ngIf="searchTerm">
          <img src="assets/icons/close_grey.svg" alt="clear-icon" class="clear-icon">
        </div>
      </div>
    </div>
    <div class="hr-visit-bills-item" *ngFor="let item of allBills | searchFilter:searchTerm:'billNo'" (click)="openBill(item)" [ngClass]="{'selected-bill': setBillDetail == item}">
      <p class="hr-visit-bill-title">
        <img src="./assets/icons/Health_records/Bills.svg" alt="">
        <span *ngIf="item.encounterType === 'O'" >{{'bill_label_out_patient'|translate:'LABELS'}}</span>
        <span *ngIf="item.encounterType === 'I'" >{{'bill_label_in_patient'|translate:'LABELS'}}</span>
        <span *ngIf="item.encounterType === 'P'" >{{'bill_label_pharmacy'|translate:'LABELS'}}</span>
        <span *ngIf="item.encounterType === 'E'" >{{'bill_label_emergency'|translate:'LABELS'}}</span>
        <span class="charges">{{currency_code}} {{item.billAmount}}</span>
      </p>
      <p class="hr-visit-bill-subtitle"><span *ngIf="item['billDate']">{{item['billDate'] | moment : 'DD MMM YYYY'}} </span>
        <span class="right-aligned bill-status">{{item['_type'] == 'PAID' ? 'Paid' : 'Pending' | translate}}</span></p>
      <p class="hr-visit-bill-subtitle">{{'Due_by' | translate}} {{item['billDate'] | moment : 'DD MMM YYYY'}} 
        <span class="right-aligned">#{{item['billNo']}}</span></p>
    </div>
  </div>
  <div class="hr-visit-bills-details" *ngIf="!isMobileView || viewBillDetail">
    <app-hr-visit-bill-details *ngIf='viewBillDetail' (close)="closeBillDetail()" [viewBillDetails]="viewBill" [selectedBill]="setBillDetail" [selectedPatient]="_selectedPatient"></app-hr-visit-bill-details>
  </div>
</div>
<app-custom-modal-container id="hr-visit-bill-details-modal" (complete)="closeBillDetail()">
  <app-hr-visit-bill-details *ngIf='viewBillDetail' (close)="closeBillDetail()" [viewBillDetails]="viewBill" [selectedBill]="setBillDetail" [selectedPatient]="_selectedPatient"></app-hr-visit-bill-details>
</app-custom-modal-container>

<app-custom-modal-container id="hr-receipt-details-modal" (complete)="closeBillDetail()">
  <app-hr-visit-bill-details *ngIf='selectedReceipt' (close)="closeBillDetail()" [selectedPatient]="_selectedPatient"
    [selectedReceipt]="selectedReceipt"></app-hr-visit-bill-details>
</app-custom-modal-container>

<app-hr-visit-bill-preview (close)="closeBillPreview()" *ngIf="billToPreview" [billNo]="billToPreview" [billDtl]="billDtl" [patient]="_selectedPatient"></app-hr-visit-bill-preview>
