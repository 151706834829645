<div id="pat-list-card">
  <ng-container *ngIf="source !='PACKAGE'">
    <div class="card-layout" *ngFor="let pat of linkedPatients">
      <div class="card cursor-pointer" 
      [ngClass]="{'selected-card': (selectedPat && pat['uhid'] == selectedPat['uhid']),'bg-opacity': pat['disablePatient'] }"
       (click)="choosePatient(pat)">
        <div class="row">
          <input  type="radio" 
          [id]="'random'+pat['uhid']" 
          [name]="'random'">
          <div class="image">
            <app-person-avatar [url]="pat.imageurl"> </app-person-avatar>
          </div>
          <label class="card-content"  [for]="'random'+pat.uhid">
            <div class="subheader-font bold txt_flow">{{pat.personname}}</div>
            <div class="subtitle txt_flow">
              {{pat['gender'] == 'F'? 'Female':'Male'}}, {{pat.dob | age}}, {{pat.relationshiptype | translate : 'RELATION'}}, {{pat.uhid}}
            </div>
          </label>
        </div>
        <div *ngIf="pat['disablePatient']" class="restrict-applied" >
          <img src="./assets/icons/restrict.svg" class="restrict-app-info">
          <div>
            <span *ngIf="!pat.ageGenderRestricted">
              <p class="restrict-info" *ngIf="pat.genderRestricted">{{'SELECT_PAT#MODAL#GENDER#RESTRICTED' | translate}}</p>
              <p class="restrict-info" *ngIf="pat.ageRestricted">{{'SELECT_PAT#MODAL#AGE#RESTRICTED' | translate}}</p>
            </span>
            <span>
              <p class="restrict-info" *ngIf="pat.ageGenderRestricted">{{'SELECT_PAT#MODAL#GENDER#AGE#RESTRICTED' | translate}}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="source =='PACKAGE'">
    <div class="wid-100" *ngFor="let patient of linkedPatients" >
      <p *ngIf="patient[0]['_hidePatient']" class="label">{{'SELECT_PAT#MODAL#DES#THE#PATIENT#HASACTIVE#PACKAGE' | translate }}</p>
      <div class="pat-list-container">
        <div class="card-layout" *ngFor="let pat of patient">        
          <div class="card cursor-pointer" 
          [ngClass]="{'selected-card': (selectedPat && pat['uhid'] == selectedPat['uhid'] && !pat['_hidePatient']), 'bg-opacity': pat['_hidePatient'] }"
          (click)="choosePatient(pat)">
            <div class="row">
              <input  type="radio" 
              [id]="'random'+pat['uhid']" 
              [name]="'random'">
              <div class="image">
                <app-person-avatar [url]="pat.imageurl"> </app-person-avatar>
              </div>
              <label class="card-content"  [for]="'random'+pat.uhid">
                <div class="subheader-font bold txt_flow">{{pat.personname}}</div>
                <div class="subtitle txt_flow">
                  {{pat['gender'] == 'F'? 'Female':'Male'}}, {{pat.dob | age}}, {{pat.relationshiptype | translate : 'RELATION'}}, {{pat.uhid}}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
