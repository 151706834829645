
<!-- <div class="modal-header header">
    <div class="title bold" *ngIf="bill && bill.netPayableAmount === bill.paidAmount">{{'settled_bills'|translate:'LABELS'}}
    </div>
    <div class="title bold" *ngIf="bill && bill.netPayableAmount != bill.paidAmount">{{'pending_bills'|translate:'LABELS'}}
    </div>
    <div class="title bold" *ngIf="selectedReceipt">{{'lbl_deposit'|translate:'LABELS'}}
    </div>
    <div *ngIf="!isCommonView" (click)="closeModal(selectedReceipt ? 'hr-receipt-details-modal' : 'hr-visit-bill-details-modal')" class="float-right closeicon pointer">
      <img src="./assets/icons/close.svg">
    </div>
  </div>
<div class="header patient-header">
  <div class="title bold" *ngIf="viewPatientDetail">{{viewPatientDetail.patientName || selectedPatient['personname']}}
  </div>
  <div class="title bold" *ngIf="!viewPatientDetail && selectedPatient">{{selectedPatient['personname']}}</div>
  <div (click)="closeModal(selectedReceipt ? 'hr-receipt-details-modal' : 'hr-visit-bill-details-modal')" class="float-right closeicon pointer">
    <img (click)="initPrint()" class="type-image" src="./assets/icons/print.svg">
    <img *ngIf="false" src="./assets/icons/share.svg">
  </div>
</div>

  <div class="modal-content">
  <div class="content">
      <div class="bill-content" id="bill_content" #bill_content>
      <div id="content" #content [innerHtml]='billPreviewTemplate | safe'></div>
      </div>
  </div>
</div> -->

<div class="bill-details-wrapper">
  <div class="bill-details-header sm-hidden">
    <div class="bill-details-header-context">
      <p class="bill-title">
        <span *ngIf="selectedBill.encounterType === 'O'" >{{'bill_label_out_patient'|translate:'LABELS'}}</span>
        <span *ngIf="selectedBill.encounterType === 'I'" >{{'bill_label_in_patient'|translate:'LABELS'}}</span>
        <span *ngIf="selectedBill.encounterType === 'P'" >{{'bill_label_pharmacy'|translate:'LABELS'}}</span>
        <span *ngIf="selectedBill.encounterType === 'E'" >{{'bill_label_emergency'|translate:'LABELS'}}</span>
      </p>
      <p class="bill-subtitle">
        {{viewPatientDetail['practitionerName']}}, {{viewPatientDetail['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name'}}, 
        {{viewPatientDetail['billDate'] | moment:'D MMM YYYY hh:mm A':'YYYY/MM/DD HH:mm'}}
      </p>
    </div>
    <button class="button-secondary" (click)="initPrint()">{{'Print' | translate}}</button>
  </div>
  <div class="bill-details-header md-hidden">
    <div class="bill-details-header-context">
      <p class="bill-title">{{'Bill_details' | translate}}</p>
    </div>
    <div class="close-icon" (click)="closeModal(selectedReceipt ? 'hr-receipt-details-modal' : 'hr-visit-bill-details-modal')">
      <img src="./assets/icons/close_black.svg" alt="">
    </div>
  </div>
  <div class="modal-content">
    <div class="content">
      <div class="bill-content" id="bill_content" #bill_content>
        <div id="content" #content [innerHtml]='billPreviewTemplate | safe'></div>
      </div>
    </div>
    <div class="print-button-wrapper md-hidden">
      <button class="button-secondary" (click)="initPrint()">{{'Print' | translate}}</button>
    </div>
</div>