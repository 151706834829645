import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientBillDetailsComponent } from './patient-bill-details.component';
import { PipesModule } from '../pipes/pipes.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';



@NgModule({
  declarations: [PatientBillDetailsComponent],
  imports: [
    CommonModule, PipesModule, PaymentCheckoutModule
  ],
  exports: [
    PatientBillDetailsComponent
  ]
})
export class PatientBillDetailsModule { }
