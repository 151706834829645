import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { EnglishToLangPipe } from '../pipes/english-to-lang.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { UserService } from '../services/user.service';
import { DATE_FORMAT_LONG, TIME_FORMAT_12 } from './../app.string';

@Component({
  selector: 'app-section-preview',
  templateUrl: './section-preview.component.html',
  styleUrls: ['./section-preview.component.scss'],
  providers: [TranslatePipe, EnglishToLangPipe]
})
export class SectionPreviewComponent implements OnInit, OnChanges {

  @Input() public section:any;
  @Input() public isFromReadOnlySection:boolean;
  @Input() public selected:any;
  @Input() public editable:any;
  @Input() public changedTimestamp: any;
  @Output() enableEdit: EventEmitter<any>
  constructor(private translate: TranslatePipe, private englishToLangPipe: EnglishToLangPipe, private user: UserService) {
    this.enableEdit = new EventEmitter<any>()
  }


  ngOnInit() {
    if (this.section['selected']) {
      this.section['key'] = this.getKey();
      this.section['value'] = this.parseType(this.section);
    } else if (this.section['type'] == 'caption')
      this.section['key'] = this.getKey();
    else {
      delete this.section['value']
    }
  }

  ngOnChanges() {
    if (this.section['selected']) {
      this.section['key'] = this.getKey();
      this.section['value'] = this.parseType();
    } else if (this.section['type'] == 'caption')
      this.section['key'] = this.getKey();
  }
  
  public codify(data: string): boolean {
    return data == 'N';
  }

  public type(data: string): boolean {
    return data == "dateAndTime";
  }

  public getKey(section: Object = this.section) {
    if (section['codify'] == 'N')
      return section['question'][0]['text'];
    return section['domainName'];
  }


  private parseType(section: Object = this.section) {
    switch (section['type']) {
      case 'date':
        return this.user.getMoment(section['selected']).format(DATE_FORMAT_LONG);
      case 'time':
        return this.user.getMoment(section['selected']).format(TIME_FORMAT_12);
      case 'dateAndTime':
        return this.user.getMoment(section['selected']).format(DATE_FORMAT_LONG + ' ' + TIME_FORMAT_12);
      case 'photo':
        return section['selected'];
      case 'masterLinked':
        return this.translate.transform(section['selectedMaster']['localeName'] || section['selectedMaster']['name'], 'LOCALE', 'text');
      case 'segment': {
        if (section['trueType'] == 'radio')
          return this.englishToLangPipe.transform(section['selected'], section['options']);
        const response = [];
        section['selected'].split(',').forEach(selected =>
          response.push(this.englishToLangPipe.transform(selected, section['options']))
        );
        return response;
      }
      case 'check': {
        const response = [];
        section['selected'].split(',').forEach(selected =>
          response.push(this.englishToLangPipe.transform(selected, section['options']))
        );
        return response;
      }
      case 'radio':
        return this.englishToLangPipe.transform(section['selected'], section['options']);

      default:
        return section['selected'];
    }
  }

  editSection() {
    this.enableEdit.emit(true)
  }

}