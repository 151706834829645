import { Injectable } from '@angular/core';
import { ClinicalformService } from './clinicalform.service';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { HpApiResponse } from '@type';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConsultationNoteService {

  constructor(private clinicalFormService: ClinicalformService, private user: UserService, private http: HttpClient) { }

  public getParsedDirectiveControl(section, facilityId: string = '') {
    return Observable.create(observer => {
      const directiveCodes = this.getDirectiveCodes(section);
      this.clinicalFormService.directiveLinkedControls(directiveCodes, facilityId)
        .subscribe((controls: any) => {
          controls['hideCartScreen'] = (section.codify == 'Y' && section.domainControls) ? section.domainControls.hidePreview : section.hidePreview;
          if (!controls['hideCartScreen'])
            controls['hidePayable'] = (section.codify == 'Y' && section.domainControls) ? section.domainControls.hidePayableInfo : section.hidePayableInfo;
          observer.next(controls);
        })
    });
  }

  public getDirectiveList(section) {
    return (section && section.codify === 'Y' && section.domainControls && Array.isArray(section.domainControls.directives) && section.domainControls.directives.length) ? section.domainControls.directives : [];
  }

  public getDirectiveCodes(section) {
    return this.getDirectiveList(section).map(d => d.directiveCode);
  }

  public getAvailableConcepts(section, facilityId: string = '') {
    const directivesList = this.getDirectiveCodes(section);
    let directives = this.user.getUserCatalogs();
    console.log(directives);
    directives = directives.filter(d => d.facilityId === facilityId && directivesList.includes(d['directiveCode']));
    const concepts = [];
    directives.forEach(d => {
      if (Array.isArray(d.selectedConcepts)) {
        d.selectedConcepts.forEach(c => {
          c['directiveCode'] = d['directiveCode'];
          concepts.push(c)
        })
      }
    });
    console.log(concepts);
    return concepts;
  }
  public getConceptforDirective(directiveCode: string) {
    const directive = this.user.getUserCatalogs().find(d => d['directiveCode'] === directiveCode);
    if (Array.isArray(directive['selectedConcepts']))
      return directive['selectedConcepts'];
    return [];
  }

  public getDirectiveControls(directiveCode: string) {
    const directive = this.user.getUserCatalogs().find(d => d['directiveCode'] === directiveCode);
    return directive ? directive['directiveControls'] : null;
  }

  public getConceptFromLocal(directiveId, conceptId, facilityId) {
    // && d['facilityId'] === facilityId
    const directive = this.user.getUserCatalogs().find(d => d['directiveCode'] === directiveId);
    if (Array.isArray(directive['selectedConcepts']))
      return directive['selectedConcepts'].find(c => c['id'] === conceptId);
    return;
  }

  public parseSelectedConcepts = (res) => {
    if (!res.reportingOutComes || !res.reportingOutComes.topics) return [];
    let val = [];
    res.reportingOutComes.topics.forEach(t => {
      if (t.codes) {
        t.codes.forEach(c => {
          val.push(c)
        })
      }
    });
    return val;
  };

  generateFilledConceptValue = (form, values) => ({ conceptName: form[0].label, id: form[0].id, directiveCode: form[0].directiveCode, values: values, parsedValue: this.parsedOutcomeValue(values), team: form[0].team || form[0].teamPractName, teamPractId: form[0].teamPractId, teamPractName: form[0].teamPractName, teamType: form[0].teamType || form[0].type, _isCrossReferral: form[0]._isCrossReferral });

  parsedOutcomeValue = (values) => {
    if (!Array.isArray(values) || values.length === 0) return;
    let vals = values.map(v => v.displayTxt || v.values);
    vals = vals.filter(v => v);
    return vals.join(', ');
    //return '( '+vals.join(', ')+' )';
  };


  public getSmartSuggestionJson(topics) {
    const smartSuggestDomainCodes = {};
    topics.forEach(t => {
      if (t['sections'])
        t['sections'].forEach(s => {
          if (s['enableSmartSuggestYn'])
            smartSuggestDomainCodes[s['domainCode']] = {
              domainCode: s['domainCode'],
              directives: this.getDirectiveCodes(s),
              domainCodesForSmartSuggest: s['domainCodesForSmartSuggest'] ? s['domainCodesForSmartSuggest'] : []
            }
        })
    })
    return smartSuggestDomainCodes;
  }

  public getServiceAmount(patientId: string, encounterId: any, facilityId: string, patientClass: string, catalogCodes: any, catalogsCodeWithDomainValues: any = null, blngGroupId: any = null, custCode: string = null) {
    const payload = {
      patientId: patientId,
      encounterId: encounterId,
      facilityId: facilityId,
      patientClass: patientClass,
      catalogCodes: catalogCodes,
      catalogsCodeWithDomainValues: catalogsCodeWithDomainValues,
      blngGroupId: blngGroupId,
      custCode: custCode
    };
    return new Observable(observer => {
      const route = 'outpatients/getServiceAmount';
      this.http.post(this.user.getRepoUrl + route, payload, { reportProgress: false }).subscribe((result: any) => {
        if (result && result.status === 'SUCCESS' && Array.isArray(result.data))
          return observer.next(result);
        return observer.error();
      })
    })
  }


  public searchReferral(type: string, facilityId: string, searchString: string = '') {
    const payload = {
      type: type,
      searchString: searchString,
      facilityId: facilityId
    }
    return new Observable(observer => {
      if (type === 'S')
        this.http.get(environment.API_URL + '/visits/specialities', { reportProgress: true }).subscribe((response: HpApiResponse) => {
          if (response && response.status === 'SUCCESS' && Array.isArray(response.data))
            return observer.next(response.data);
          return observer.error();
        })
      else
        this.http.post(environment.API_URL + '/workflow/referralSearch', payload, { reportProgress: true }).subscribe((response: HpApiResponse) => {
          return observer.next(response);
        })
    })
  }

  getPresMapping = () => {
    return {
      "PRESDOSE": "dosage",
      "PRESDOSEUOM": "dosageUomCode",
      "PRESDURN": "duration",
      "PRESFREQ": "frequencyCode",
      "PRESFREQDTL": "frequencyDtl",
      "PRESFREQDTLBAFOOD": "frequencyDtlBAFood",
      "PRESROUTE": "routeCode"
    }
  }
  findAndFillForm = (concept, domainValues) => {
    if (concept && concept.noteContent && Array.isArray(concept.noteContent.topics)) {
      concept.noteContent.topics.map(t => {
        t.sections.map(s => {
          if (s.type === 'compositeField') {
            s.multipleFields.map(m => {
              m.type === 'select' ? this.fillSelectedSection(m, domainValues) : this.fillSection(m, domainValues);
            });
            let selectedVal = s.multipleFields.map(m => m.selected);
            s.selected = Array.isArray(selectedVal) ? selectedVal.join('') : undefined;
          } else {
            this.fillSection(s, domainValues);
          }
        })
      })
    }
    return concept;
  };
  fillSection = (s, domainValues) => s.domainCode && domainValues[s.domainCode] ? s.selected = domainValues[s.domainCode] : '';

  fillSelectedSection = (s, domainValues) => {
    if (s.domainCode && domainValues[s.domainCode]) {
      let answers = s.codify === 'Y' && s.domainControls ? s.domainControls.answer : s.answer;
      if (Array.isArray(answers)) {
        answers.forEach(a => {
          if (a.localName && a.localName[0] && a.localName[0].text === domainValues[s.domainCode])
            s.selected = a.code;
        })
      }
    }
  };

  public checkForSingleInstanceConceptsToPopulateNote(patientId, directiveCode: string, encounterId?: string) {
    return new Observable(observer => {
      const concepts = this.getConceptforDirective(directiveCode);
      let recordedNotes = [];
      concepts.forEach(c => {
        this.clinicalFormService.getRecordedNotes(directiveCode, c['id'], patientId, encounterId || null, c['label'])
          .subscribe(data => {
            recordedNotes = recordedNotes.concat(data);
            if (recordedNotes.length === concepts.length)
              observer.next(recordedNotes);
          })
      })
    })
  }

  public getFacilityForSpeciality(specCode: string, facilityId: string) {
    return new Observable(observer => {
      this.http.get(environment.API_URL + '/visits/specialities/facility/' + specCode + '?facilityId=' + facilityId, { reportProgress: true }).subscribe((response: HpApiResponse) => {
        if (response && response.status === 'SUCCESS' && Array.isArray(response.data))
          return observer.next(response.data);
        return observer.error();
      })
    })
  }

  public getSlotsForSpec(facilityId: string, practId: string = null, fromDate: string = null, toDate: string = null, specialityCode: string = null, noOfAvailableDays: number = 10) {
    return new Observable(observer => {
      let payload = {
        facilityId: facilityId,
        practitionerId: practId,
        fromDate: fromDate || this.user.getMoment().format('YYYY/MM/DD'),
        toDate: toDate || this.user.getMoment().add(6, 'months').format('YYYY/MM/DD'),
        pNoOfAvailableDays: noOfAvailableDays,
        specialityCode: specialityCode
      }
      this.http.post(this.user.getRepoUrl + 'outpatients/getSlotsForClinic', payload, { reportProgress: false }).subscribe((response: HpApiResponse) => {
        if (response && response.status === 'SUCCESS' && response.data && Array.isArray(response.data.availability))
          return observer.next(response.data.availability);
        return observer.error();
      })
    })
  }


}