import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitBillPreviewComponent } from './hr-visit-bill-preview.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    HrVisitBillPreviewComponent
  ],
  imports: [
    CommonModule, PipesModule
  ], exports: [
    HrVisitBillPreviewComponent
  ]
})
export class HrVisitBillPreviewModule { }
