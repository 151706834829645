import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import * as _ from 'lodash';
import { REPO_TOKEN } from '@string';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-hr-patient-select',
  templateUrl: './hr-patient-select.component.html',
  styleUrls: ['./hr-patient-select.component.scss']
})
export class HrPatientSelectComponent implements OnInit {
  @Input() selectedPatient: any;
  popoverpatient: any;
  canUnlinkSameIdentity: any;
  canUnlinkDifferentIdentity: any;
  public relationArray: Object = {};
  prefillNationalId: any;
  registerPatient: boolean;
  canLinkDifferentIdentityHOF: any;
  canLinkDifferentIdentityNonHOF: any;
  isHof: boolean;
  findOtherPatient: boolean;
  entity: any;
  relationType: any;
  relations: any;
  @Input() modalId: any;
  editDetails: any;
  isEdit: boolean;
  @Input() set contextPatientId(value: any) {
    this._contextPatientId = value;
    if (value && this.linkedPatients && this.linkedPatients.length > 0 ) {
      let patient = this.linkedPatients.find(pat => pat.uhid == value);
      if (patient) {
        this.selectPatient(patient);
      }
    }
  }
  get contextPatientId() {
    return this._contextPatientId;
  }
  @Input() source: 'upload-docs'|'hr'='hr';
  @Input() isMobileView: boolean;
  @Output() choosePatient: EventEmitter<any>;
  @ViewChild('container', { read: ElementRef }) public container: ElementRef<any>;
  public linkedPatients: Array<any>;
  public showPatient : boolean;
  public selectedPatients;
  public _contextPatientId;
  public position: any;
  public linkWithoutRelation: boolean = false;
  public isUpdateRelation;
  public addMemberModal: boolean = false;
  public linkSameNumber: boolean;
  public isManageIdentities: boolean;
  constructor(private user: UserService, private linkPatient: LinkPatientService, private featureService: FeatureFlagsService, private modalService:ModalService) {
    this.choosePatient = new EventEmitter<any>();
    this.position = 0;
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
    this.canUnlinkSameIdentity = this.featureService.featureOn('FT_APP_UNLINKSAMEIDENTITY');
    this.canUnlinkDifferentIdentity = this.featureService.featureOn('FT_APP_UNLINKDIFFIDENTITY');
    this.canLinkDifferentIdentityHOF = this.featureService.featureOn('FT_APP_HOFLINKDIFFIDENTITY');
    this.canLinkDifferentIdentityNonHOF = this.featureService.featureOn('FT_APP_NONHOFLINKDIFFIDENTITY');
    this.isHof = this.featureService.getIsHOF()
  }

  ngOnInit(): void {
    this.setLinkedPatients();
    this.getRelationList();
    this.getEntityId();
    if(this.selectedPatient)
      this.selectPatient(this.selectedPatient)
  }
  public setLinkedPatients(forceNewData?) {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data, forceNewData).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            this.selectedPatients = result.data;
            let index = 0;
            if (this.contextPatientId) {
              index =this.linkedPatients.findIndex(pat => pat.uhid == this.contextPatientId);
            }
            if(!this.isMobileView || index)
            this.selectPatient(this.linkedPatients[index])
          }
        })
      }
    }) 
  }
  public selectPatient(patient) {
    this.linkedPatients.forEach(p => {
      p['_openOptions'] = false;
    })
    this.choosePatient.emit(patient);
  }
  public showPatients(){
    this.showPatient = !this.showPatient;
  }
  public hidePatientList(patient){
    this.choosePatient.emit(patient);
    this.selectedPatients = [];
    this.selectedPatients[0] = patient;
    this.showPatient = false;
  }
  public scrollleft() {
    this.container.nativeElement.scrollLeft -= ((this.container.nativeElement as HTMLElement).offsetWidth / 4)
  }
  public scrollright() {
    this.container.nativeElement.scrollLeft += ((this.container.nativeElement as HTMLElement).offsetWidth / 4)
  }

  public openPopover(patient, enablePhoto, enableUnlink, enableLink, myAccess) {
    console.log("patient",patient)
    let unlinkMyAccess = false
    if (myAccess) {
      if (patient.isSameIdentity && !this.canUnlinkSameIdentity) enableUnlink = false;
      if (!patient.isSameIdentity && !this.canUnlinkDifferentIdentity) enableUnlink = false;
    } else {
      unlinkMyAccess = true;
    }
    this.popoverpatient = { patient, enablePhoto, enableUnlink, enableLink, unlinkMyAccess }
  }

  changePhoto() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = "image/png, image/gif, image/jpeg";
    input.onchange = ($event) => {
      this.uploadPatientPhoto($event.target['files'])
          };
    input.click();
  }

  public uploadPatientPhoto(files: FileList) {
    let fileToUpload: File = files.item(0)
    console.log(fileToUpload)
    this.linkPatient.uploadPatientPhoto(fileToUpload, this.popoverpatient.patient).subscribe(data => {
      this.setLinkedPatients(true)
    })
  }
  public chooseOption(option: 'PHOTO' | 'UNLINK' | 'LINK' | 'UNLINKMYACCESS') {
    switch (option) {
      case 'PHOTO':
        break;
      case 'LINK':
        if (this.popoverpatient.patient.directLink) {
          this.relationArray[this.popoverpatient.patient.patientUhid] = { code: null }
          this.toLinkPatients(this.popoverpatient.patient)
        } else {
          this.prefillNationalId = this.popoverpatient.patient.nationalId;
          this.openModal('find-record')
        }
        break;
      case 'UNLINK':
        this.removeLinkedPatient(this.popoverpatient.patient);
        break;
      case 'UNLINKMYACCESS':
        this.removeLinkedMe(this.popoverpatient.patient);
        break;
    }
  }

  removeLinkedPatient(linkedPatient){
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.removeLinkedPatient(data,linkedPatient.personid).subscribe(result => {
          if(result.status === 'SUCCESS'){
            console.log("***After removing pat***")
            this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
            this.setLinkedPatients(true)
          }
        })
      }
    }) 
  }
  removeLinkedMe(linkedPatient){
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.removeMyLinked(linkedPatient.personid, linkedPatient.selfPersonId || data).subscribe(result => {
          if(result.status === 'SUCCESS'){
            this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
            this.setLinkedPatients(true);
            this.popoverpatient = null;
          }
        })
      }
    }) 
  }
  
  toLinkPatients(patientInfo){
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          profilesToLink:[
            {
              uhid: patientInfo.patientUhid,
              facilityId: patientInfo.facilityId,
              patientName: patientInfo.patientName,
              dob: patientInfo.birthDate,
              gender: patientInfo.gender,
              relationshipType: this.relationArray[patientInfo.patientUhid]['code'],
              mobile: patientInfo.mobile,
              imageUrl: "",
              email: patientInfo.email
            }
          ]
        }
        this.initLinkData(patientInfo, linkData);
      }
    }) 
  }

  getEntityId(){
    this.user.getConfigurationInfo.subscribe(data => {
      this.entity = data.entityId;
    })
  }
  openModal(id: string) {
    if (id == 'register-patient')
      this.registerPatient = true;
    if (id == 'find-record') {
      if ((this.isHof && this.canLinkDifferentIdentityHOF) || (!this.isHof && this.canLinkDifferentIdentityNonHOF))
        this.findOtherPatient = true;
    }
    // this.modalService.open(id);
  }

  private initLinkData(patientInfo, linkData) {
    this.linkPatient.toLinkPatients(linkData).subscribe(result => {
      if (result.status === 'SUCCESS') {
        this.closeModal('find-record')
        console.log("***After linking pat***")
        this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
        this.setLinkedPatients(true)
      }
    })
  }
  getRelationList(){
    this.user.getMasterConfig.subscribe(data => {
      this.relationType = data.list;
      this.relationType = this.relationType.filter(arr => {
          return arr.domain === 'RELATIONSHIP_TYPE'
      })
      this.relations = this.relationType[0].codes
    });
  }

  closeModal(id: string) {
    this.prefillNationalId = null
    if(id === 'register-patient'){
      this.registerPatient = false;
      this.isEdit = false;
      this.editDetails = null;
      this.setLinkedPatients(true);
    } else if(id === 'register-new-patient'){
      this.registerPatient = false;
      this.isEdit = false;
      this.editDetails = null;
      this.setLinkedPatients(true);
    } else if (id == 'find-record') {
        this.findOtherPatient = false;
        this.linkSameNumber = false;
    }
     else{
      if(this.modalId) this.modalService.close(this.modalId);
      else this.modalService.close(id);
    }
  }

  public editPatient(patient) {
    this.editDetails = _.cloneDeep(patient);
    this.isEdit = true;
    this.registerPatient = true;
    return
    
  }

  closeUpdateRelation(ev) {
    this.isUpdateRelation = null;
    if(ev) {
      this.setLinkedPatients(true);
    }
  }

  updatedRelationship(patient) {
    this.isUpdateRelation = patient;
    patient['_openOptions'] = false;
  }
  public triggerMoreOptions(item) {
    this.linkedPatients.forEach(p => {
      p['_openOptions'] = false;
    })
    item['_openOptions'] = !item['_openOptions'];
    this.openPopover(item, true, true, true, true);
    this.addMemberModal = false;
  }

  public closeFilter(){
    this.linkedPatients.map(ele => {
      ele['_openOptions']  = false;
    })
  }

  addMember() {
    this.addMemberModal = !this.addMemberModal;
  }

  public initLinkPatients(patient) {
    this.relationArray[patient.patientUhid] = { code: null }
    this.toLinkPatients(patient)
  }

  public initAddPatients({ natid, otp }) {
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          externalIdsToLink: [
            {
              externalId: natid,
              otp: otp
            }
          ]
        }
        this.initLinkData(null, linkData);
      }
    })
  }

  public manageFamily() {
    this.isManageIdentities = true;
    this.modalService.open('manage-identity-modal');
  }

  public closeManageIdentities() {
    this.isManageIdentities = false;
  }
}
