import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentVisitsComponent } from './recent-visits.component';
import { PipesModule } from '../pipes/pipes.module';
import { ListItemModule } from '../list-item/list-item.module';

@NgModule({
  declarations: [RecentVisitsComponent],
  imports: [
    CommonModule, PipesModule, ListItemModule
  ],
  exports: [
    RecentVisitsComponent
  ]
})
export class RecentVisitsModule { }
