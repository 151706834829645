import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
// import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import {PatientListComponent} from './patient-list.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import {PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PatientListComponent],
  imports: [
    CommonModule, PipesModule,
    //  CustomModalModule,
     PersonAvatarModule,
     PractitionerAvatarModule,
     FormsModule
  ],
  exports: [
    PatientListComponent
  ]
})
export class PatientListModule { }
