import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitReportComponent } from './hr-visit-report.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { DirectiveModule } from '../directive/directive.module';
import { ReportsViewModalModule } from '../reports-view-modal/reports-view-modal.module';



@NgModule({
  declarations: [HrVisitReportComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, DirectiveModule, ReportsViewModalModule
  ],
  exports: [
    HrVisitReportComponent
  ]
})
export class HrVisitReportModule { }
