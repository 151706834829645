import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { ServiceRequestComponent } from './service-request.component';
import { ServiceRequestDetailsModule } from '../service-request-details/service-request-details.module';
import { DirectiveModule } from '../directive/directive.module';


@NgModule({
  declarations: [
    ServiceRequestComponent
  ],
  imports: [
    CommonModule, PipesModule, ServiceRequestDetailsModule, DirectiveModule
  ],
  exports: [ServiceRequestComponent],
})
export class ServiceRequestModule { }
