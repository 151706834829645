<div id="bill-preview">
  <div class="card-container">
    <div class="card" *ngIf="langSet">
      <div class="header">
        <div class="logo">
          <img class="logo type-image" src="assets/images/signin/logo_grey.png" alt="">
        </div>
      </div>
      <div class="appt-content">
        <ng-container [ngSwitch]="state">
          <ng-container *ngSwitchCase="'LOADING'">
            <ng-container *ngTemplateOutlet="LOADING"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'PENDING'">
            <ng-container *ngTemplateOutlet="PENDING"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'CANCELLED'">
            <ng-container *ngTemplateOutlet="CANCELLED"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'COMPLETED'">
            <ng-container *ngTemplateOutlet="COMPLETED"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="INVALID"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<ng-template #LOADING>
  <div class="content">
    
  </div>
</ng-template>
<ng-template #PENDING>
  <div class="content seperated-child">
    <div class="order-content-container">
      <ng-container *ngTemplateOutlet="ORDERINFORMATION"></ng-container>
    </div>
    <div class="guidelines-content-container">
      <ng-container *ngTemplateOutlet="GUIDELINESINFO"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #INVALID>
  <div class="content">
    <div class="invalid-link">
      <img class="cursor-pointer" src="./assets/icons/appt-preview/alert.svg">
      <div class="bold margin-top">{{'Payment_no_longer_valid' | translate : 'LABELS' : null : curLang}}</div>
      <div class="alert">
        <img class="type-image callprev-icon" src="assets/icons/appt-preview/info.svg" />
        <div>
          <span class="font-default-small">{{'invalid_bill_call_us_prefix'|translate : 'LABELS' : null : curLang}}</span> 
          <span class="font-primary_small cursor-pointer call-span" (click)="call()">{{'Call_us'|translate : 'LABELS' : null : curLang}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #CANCELLED>
  <div class="content seperated-child">
    <div class="invalid-link with-accordian single-accordian"
      [ngClass]="{childExpanded: orderExpanded || guidelineExpanded, bothChileExpanded: orderExpanded && guidelineExpanded}">
      <img class="cursor-pointer" src="./assets/icons/appt-preview/alert.svg">
      <div class="bold margin-top">{{'The_order_is_cancelled' | translate : 'LABELS' : null : curLang}}</div>
      <div class="refund-note">{{"refund_within_days"  | translate : 'LABELS' : null : curLang}}</div>
      <div class="refund-details">
        <div class="refund-row">
          <div class="refund-label">{{'Amount_to_be_refunded' | translate : 'LABELS' : null : curLang}}</div>
          <div class="refund-value bold">{{linkInformation.payableAmount}} {{currency_code}}</div>
        </div>
        <div class="refund-row" *ngIf="linkInformation.refundEffectiveDate">
          <div class="refund-label">{{'Refund_effective_date' | translate : 'LABELS' : null : curLang}}</div>
          <div class="refund-value bold">{{linkInformation.refundEffectiveDate|moment: 'DD MMM YYYY'}}</div>
        </div>
      </div>
      <div class="alert">
        <img class="type-image callprev-icon" src="assets/icons/appt-preview/info.svg" />
        <div>
          <span class="font-default-small">{{'invalid_bill_call_us_prefix'|translate : 'LABELS' : null : curLang}}</span> 
          <span class="font-primary_small cursor-pointer call-span" (click)="call()">{{'Call_us'|translate : 'LABELS' : null : curLang}}</span>
        </div>
      </div>
    </div>
    <div class="order-content-container">
    <ng-container *ngTemplateOutlet="ORDERINFORMATION"></ng-container>
    </div>
    <div class="guidelines-content-container" *ngIf="false">
    <ng-container *ngTemplateOutlet="GUIDELINESINFO"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #COMPLETED>
  <div class="content seperated-child">
    <div class="invalid-link with-accordian"
      [ngClass]="{childExpanded: orderExpanded || guidelineExpanded, bothChileExpanded: orderExpanded && guidelineExpanded}">
      <img class="cursor-pointer" src="./assets/icons/appt-preview/check.svg">
      <div class="bold margin-top">{{'Payment_completed' | translate : 'LABELS' : null : curLang}}</div>
      <div class="refund-details">
        <div class="refund-row">
          <div class="refund-label">{{'Amount_paid' | translate : 'LABELS' : null : curLang}}</div>
          <div class="refund-value bold">{{billingInformation['amountPaid']}} {{currency_code}}</div>
        </div>
        <div class="refund-row">
          <div class="refund-label">{{'Bill_number' | translate : 'LABELS' : null : curLang}}</div>
          <div class="refund-value bold">{{billingInformation['billNumber']}}</div>
        </div>
        <div class="refund-row">
          <div class="refund-label">{{'Receipt_number' | translate : 'LABELS' : null : curLang}}</div>
          <div class="refund-value bold">{{billingInformation['receiptDetails']}}</div>
        </div>
      </div>
      <div class="alert">
        <img class="type-image callprev-icon" src="assets/icons/appt-preview/info.svg" />
        <div>
          <span class="font-default-small">{{'valid_bill_call_us_prefix'|translate : 'LABELS' : null : curLang}}</span> 
          <span class="font-primary_small cursor-pointer call-span" (click)="call()">{{'Call_us'|translate : 'LABELS' : null : curLang}}</span>
        </div>
      </div>
    </div>
    <div class="order-content-container">
      <ng-container *ngTemplateOutlet="ORDERINFORMATION"></ng-container>
    </div>
    <div class="guidelines-content-container">
      <ng-container *ngTemplateOutlet="GUIDELINESINFO"></ng-container>
    </div>
  </div>
</ng-template>


<ng-template #ORDERINFORMATION>
  <div class="order-info-title information-title cursor-pointer" (click)="expand('ORDER')"
    [ngClass]="{'minimized-title': (canExpand && !orderExpanded), 'hover-effect': canExpand, expanded: orderExpanded}">
    <span class="bold">{{'Order_information' |translate: 'LABELS' : null : curLang}}</span>
    <span class="order-row">
      <span class="font-light-small margin-right show-for-mobileview" *ngIf="showOrderId">{{'Order_id' |translate: 'LABELS' : null : curLang}}:{{linkInformation.orderId}}</span>
      <img *ngIf="canExpand" class="arrow-image" src="assets/icons/calendar/arrow.svg" alt="">
    </span>
  </div>
  <ng-container *ngIf="!canExpand || orderExpanded">
    <div class="order-information">
      <div class="order-row details-row">
        <div class="patient-details detail-column">
          <div class="pat-title font-light-small">{{'Patient' |translate: 'LABELS' : null : curLang}}</div>
          <div class="pat-name bold">{{patientInfo.patientName}}</div>
          <div class="pat-id font-light-small">{{patientInfo.patientId}}</div>
        </div>
        <div class="order-details detail-column hide-for-mobileview" *ngIf="showOrderId">
          <div class="pat-title font-light-small">{{'Order_id' |translate: 'LABELS' : null : curLang}}</div>
          <div class="pat-name bold">{{linkInformation.orderId}}</div>
        </div>
      </div>
    </div>
    <div class="service-information" *ngIf="serviceDetails && serviceDetails.length > 0">
      <div class="details-row">
        <div class="patient-details detail-column detail-full-width">
          <div class="pat-title font-light-small">{{'Service' |translate: 'LABELS' : null : curLang}}</div>
          <ng-container *ngFor="let group of serviceDetails; let first=first;">
            <div class="pat-name bold">{{group[0]['serviceGroupDesc']}}</div>
            <div class="order-list">
              <div class="order-row" *ngFor="let item of group">
                <div class="order-name">{{item['serviceDesc']}}</div>
                <div class="order-cost">{{item['totalServicePayable']}} {{currency_code}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="total-information" *ngIf="billingInformation['amountPaid'] && billingInformation['amountPaid'] > 0">
      <div class="details-row">
        <div class="detail-column detail-full-width">
          <div class="order-list">
            <ng-container *ngIf="billingInformation['vatExemption']">
              <ng-container *ngTemplateOutlet="CALCULATIONSECTION"></ng-container>
            </ng-container>
            <div class="order-row">
              <div class="order-name bold">{{'Amount_paid' |translate: 'LABELS' : null : curLang}}</div>
              <div class="order-total-cost bold">{{billingInformation['amountPaid']}} {{currency_code}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="total-information" *ngIf="billingInformation['amountToBePaid'] && billingInformation['amountToBePaid']>0">
      <div class="details-row">
        <div class="detail-column detail-full-width">
          <div class="order-list">
            <ng-container *ngIf="billingInformation['vatExemption']">
              <ng-container *ngTemplateOutlet="CALCULATIONSECTION"></ng-container>
            </ng-container>
            <div class="order-row">
              <div class="order-name bold">{{'Amount_to_pay' |translate: 'LABELS' : null : curLang}}</div>
              <div class="order-total-cost bold">{{billingInformation['amountToBePaid']}} {{currency_code}}</div>
            </div>
          </div>
          <div class="pat-title font-light-small">{{'Pay_on_or_before' |translate: 'LABELS' : null : curLang}}
            {{linkInformation['expDate']|moment: 'DD MMM YYYY'}}</div>
          <div class="payment-options" *ngIf="state == 'PENDING'">
            <ng-container *ngIf="!isPaying">
              <button class="primary-button" (click)="initPayment()">{{'Make_payment' |translate: 'LABELS' : null : curLang}}</button>
              <div class="apple-pay-container" *ngIf="showApplePay">
                <div id="apple-con-id" class="apple-pay-button" (click)="initPayment(true)"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="isPaying">
              <div class="payment-loader">
                <span class="loader"></span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #CALCULATIONSECTION>
  <div class="order-row">
    <div class="order-name">{{'Total_amount' |translate: 'LABELS' : null : curLang}}</div>
    <div class="order-total-cost">{{billingInformation['payableByPatient']}} {{currency_code}}</div>
  </div>
  <div class="order-row">
    <div class="order-name">{{'VAT_excemption' |translate: 'LABELS' : null : curLang}}</div>
    <div class="order-total-cost">{{billingInformation['vatExemption']}} {{currency_code}}</div>
  </div>
</ng-template>

<ng-template #GUIDELINESINFO>
  <div class="guidelines-title information-title cursor-pointer" (click)="expand('GUIDELINES')"
    [ngClass]="{'minimized-title': (canExpand && !guidelineExpanded), 'hover-effect': canExpand, expanded: guidelineExpanded}">
    <span class="bold">{{'Guidelines' | translate : 'LABELS' : null : curLang}}</span>
    <span *ngIf="canExpand">
      <img class="arrow-image" src="assets/icons/calendar/arrow.svg" alt="">
    </span>
  </div>
  <div class="guidelines-information" *ngIf="!canExpand || guidelineExpanded">
    <p class="font-light-small">{{'bill_pay_guidelines' | translate : 'LABELS' : null : curLang}}</p>
  </div>
</ng-template>


<div class="payment-modal" *ngIf="isCheckout">
  <div class="modal-content">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">{{'Checkout'|translate}}
      </div>
      <div (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content">
      <app-payment-checkout></app-payment-checkout>
    </div>
  </div>
</div>

<ng-container *ngIf="errorTransaction">
  <div class="modal-container">
    <div class="modal-content error-modal-content">
      <div class="card">
        <div class="header only-close">
          <img (click)="confirmErrorModal(false)" class="cursor-pointer" src="./assets/icons/close_black.svg">
        </div>
        <div class="content direction-column">
          <img class="cursor-pointer" src="./assets/icons/appt-preview/alert.svg">
          <div class="bold">{{'payment_failed' | translate : 'LABELS' : null : curLang}}</div>
          <div class="font-light-small">{{'payment_failed_desc' | translate : 'LABELS' : null : curLang}}</div>
          <button class="primary-button"
            (click)="confirmErrorModal(true)">{{'try_again' | translate : 'LABELS' : null : curLang}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showTC">
  <div class="tc-modal-container">
    <div class="modal-content">
      <div class="card">
        <div class="header bold">
          <span>{{'Terms_and_conditions' | translate : 'LABELS' : null : curLang}}</span>
          <img (click)="closeTC(false)" class="cursor-pointer" src="./assets/icons/close_black.svg">
        </div>
        <div class="content direction-column">
          <div class="font-default-small" [innerHTML]="tandc| translate:'LOCALE':'packageContent'"></div>
        </div>
        <div class="footer">
          <div class="row">
            <input type="checkbox" name="accept" [(ngModel)]="accept" id="accept_tc" />
            <label for="accept_tc" class="font-light-small cursor-pointer">{{'bill_agree_tc_msg' | translate : 'LABELS' : null : curLang}}</label>
          </div>
          <div class="row button-row">
            <button (click)="closeTC(true)" class="primary-action" [ngClass]="{disabled: !accept}"
              [disabled]="!accept">{{'Confirm' | translate : 'LABELS' : null : curLang}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>