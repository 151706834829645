import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT_LONG, TIME_FORMAT_12 } from './../app.string';
import { ReportService } from '../services/report.service';
import { ConditionaltopicPipe } from '../pipes/conditionaltopic.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { EnglishToLangPipe } from '../pipes/english-to-lang.pipe';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-formpreview',
  templateUrl: './formpreview.component.html',
  styleUrls: ['./formpreview.component.scss'],
  providers:[ConditionaltopicPipe, TranslatePipe, EnglishToLangPipe]
})
export class FormpreviewComponent {

  @Input() topics: any;
  @Input() borderColor: string;
  @Input() isEventInfo: boolean = false;
  @Input() showTopicTitle: boolean = true;
  constructor(public service: ReportService, private conditionaltopicPipe:ConditionaltopicPipe, private translatePipe: TranslatePipe, private englishToLangPipe:EnglishToLangPipe, private user: UserService) { }


  ngOnChanges() {
    this.topics = this.topics.slice();
    this.topics = this.conditionaltopicPipe.transform(this.topics);
    this.resolveUrlsForTask();
    for (let index = this.topics.length - 1; index > -1; index--) {
      for (let j = this.topics[index]['sections'].length - 1; j > -1; j--) {
        if (this.topics[index]['sections'][j]['selected']) {
          this.topics[index]['sections'][j]['key'] = this.getKey(this.topics[index]['sections'][j]);
          this.topics[index]['sections'][j]['value'] = this.parseType(this.topics[index]['sections'][j]);
        } else {
            this.topics[index]['sections'].splice(j, 1);
        }
      }
      if (this.topics[index]['sections'].length == 0) {
        this.topics.splice(index, 1);
      }
    }
    console.log('topics to show', this.topics);
  }

  public codify(data: string): boolean {
    return data == 'N';
  }

  public type(data: string): boolean {
    return data == "dateAndTime";
  }

  public getKey(section: Object) {
    if (section['codify'] == 'N')
      return this.translatePipe.transform(section['question'],'LOCALE', 'text');
    return this.translatePipe.transform(section['domainNameLocale'] || section['domainName'],'LOCALE', 'text');
  }


  private parseType(section: Object) {
    switch (section['type']) {
      case 'date':
        return this.user.getMoment(section['selected']).format(DATE_FORMAT_LONG);
      case 'time':
        return this.user.getMoment(section['selected']).format(TIME_FORMAT_12);
      case 'dateAndTime':
        return this.user.getMoment(section['selected']).locale('en').format(DATE_FORMAT_LONG + ' ' + TIME_FORMAT_12);
      case 'photo':
        return section['selected'];
      case 'masterLinked':
        return this.translatePipe.transform(section['selectedMaster']['localeName'] || section['selectedMaster']['name'], 'LOCALE', 'text');
      case 'segment': { 
        // if (section['trueType'] == 'radio')
        //   return this.englishToLangPipe.transform(section['selected'], section['options']);
        // const response = [];
        // section['selected'].split(',').forEach(selected =>
        //   response.push(this.englishToLangPipe.transform(selected, section['options']))
        // );
        // return response;
        if (section['trueType'] == 'radio' && section['options']) {
          let selectedOpt = section['options'].find(opt => opt.localName[0].text == section['selected'])
          if (selectedOpt) {
            return this.englishToLangPipe.transform(section['selected'], selectedOpt.localName);
          } else {
            return section['selected'];
          }
        } else {
          let selectedArray = section['selected'].split(',')
          let selectedTranslated = [];
          selectedArray.forEach(sel => {
            let selectedOpt = null
            if (section['options']) selectedOpt = section['options'].find(opt => opt.localName[0].text == sel)
            if (selectedOpt) {
              selectedTranslated.push(this.englishToLangPipe.transform(sel, selectedOpt.localName));
            } else {
              selectedTranslated.push(sel);
            }
          });
          return selectedTranslated.join(',');
        }
      }
      case 'check': {
        const response = [];
        section['selected'].split(',').forEach(selected =>
          response.push(this.englishToLangPipe.transform(selected, section['options']))
        );
        return response;
      }
      case 'radio':
        return this.englishToLangPipe.transform(section['selected'], section['options']);
    
      default:
        return section['selected'];
    }
  }

  private resolveUrlsForTask() {
    var sections;
    var payload;
    this.topics.filter(topic => {
      sections = topic['sections'];
      sections = sections.map(section => {
        if (((section['type'] === 'draw') || (section['type'] === 'photo') || (section['type'] == 'file') ) && section['selected']) {
          switch (section['type']) {
            case 'draw':
              payload = {
                paths: [section['selected']]
              };
              break;
            default:
              payload = {
                paths: section['selected']
              };
              break;
          }

          this.service.resolveFileURL(payload).subscribe(result => {
            switch (section['type']) {
              case 'draw':
                section['selected'] = result[0];
                break;
              case 'file':
                result.forEach((r, i) => {
                  section['selectedFile'][i]['url'] = r;
                });
                break;
              default:
                result.forEach(r => {
                  section['selected'].push({
                    url: r
                  })
                });
                break;
            }
          });
        }

      })
    });
  }

}
