<div id="change-mobile-number" class="right-nav">
  <div class="content">
    
    <div class="form">
      <div class="title bold">
        {{'lbl_change_mobile_number'|translate}}
        <div (click)="closeModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_grey.svg">
        </div>
      </div>

      <div class="precheck-form" *ngIf="isPreCheck">
          <form [formGroup]="precheckForm" class="">
            <div class="group">
              <div class="mobile-no-country-code">
                <label>{{'lbl_registered_mobile_no' | translate}}</label>
                <div class="phoneinput">
                  <app-phone-input formControlName="oldMobile" direction="row" [readonly]="true"></app-phone-input>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="mobile-no-country-code">
                <label>{{'lbl_enter_new_mobile_no' | translate}}</label>
                <div class="phoneinput">
                  <app-phone-input formControlName="mobile" direction="row"></app-phone-input>
                </div>
              </div>
              <p class="error-p" [ngClass]="{show: precheckForm['controls']['mobile']['value'] && precheckForm['controls']['mobile']['dirty'] && precheckForm['controls']['mobile']['touched'] && !precheckForm['controls']['mobile']['pending'] && !precheckForm['controls']['mobile']['valid']}">
                * Invalid mobile number *
              </p>
            </div>
          </form>
        </div>

        <div class="footer" *ngIf="isPreCheck">
          <button type="submit" (click)="sendOTP(false)" [disabled]="!precheckForm['valid']"
          class="primary-button" *ngIf="extAuth"> {{'btn_send_otp'|translate}} </button>   
          <button type="submit" (click)="sendDOTP()" [disabled]="!precheckForm['valid']"
          class="primary-button" *ngIf="!extAuth"> {{'btn_send_otp'|translate}} </button>   
      </div>

      <div class="precheck-form" *ngIf="isVerifyMobile">
          <form [formGroup]="changeMobileNoForm" class="">
            <div class="group">
              <div class="mobile-no-country-code" (click)="changeNumber()">
                <label>{{'lbl_enter_new_mobile_no' | translate}}</label>
                <div class="phoneinput">
                  <app-phone-input formControlName="mobile" direction="row" [readonly]="true"></app-phone-input>
                  <img class="type-image" src="assets/icons/signin/pen.svg">
                </div>
              </div>
            </div>

            <div class="otp-group-wrapper">
            <div class="group">
              <app-custom-input-group 
                label="{{'lbl_enter_otp'|translate}}"
                formControlName="otp"
                type="text"
                [required]="true"
              ></app-custom-input-group>
            </div>
            <div class="resend-otp" (click)='sendOTP(true)'>
              <span class="resend-otp-btn" >{{'Resend_OTP' | translate}}</span>
            </div>
          </div>
            <div class="group text-input notification">
              <label>{{'lbl_please_enter_otp_message' | translate}} {{ mobile }}</label>
            </div>
          </form>
        </div>

        <div class="footer" *ngIf="isVerifyMobile">
          <!-- <button class="primary-button" (click)="changeNumber()">{{'btn_cancel'|translate}}</button> -->
          <button type="submit" (click)="verifyOTP()" [disabled]="!changeMobileNoForm['valid']"
          class="primary-button" *ngIf="extAuth"> {{'btn_verify_otp'|translate}} </button>   
          <button type="submit" (click)="verifyDOTP()" [disabled]="!changeMobileNoForm['valid']"
          class="primary-button" *ngIf="!extAuth"> {{'btn_verify_otp'|translate}} </button>   
      </div>

    </div>
  </div>
</div>