import { Component, OnInit, Input, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import { REPO_TOKEN } from '@string';
import { FeatureFlagsService } from '../services/feature-flags.service';
@Component({
  selector: 'app-link-records',
  templateUrl: './link-records.component.html',
  styleUrls: ['./link-records.component.scss']
})
export class LinkRecordsComponent {
  @Input() recordsToLink:Array<any>;
  @Input() relations: Array<any>;
  @Input() entityId: any;
  @Output() updateLinkedPatient: EventEmitter<any>;
  public linkWithoutRelation: boolean = false;
  public canUnlinkSameIdentity: boolean = false;
  public canUnlinkDifferentIdentity: boolean = false;
  public prefillNationalId:string=null
  public canUnlinkProvidedIdentity: boolean = false;
  public canLinkDifferentIdentityHOF: boolean = false;
  public canLinkDifferentIdentityNonHOF: boolean = false;
  public isHof: boolean = false;
  public selectedRecord: string;
  public showRelation: boolean;
  public isRegisterPatient: boolean;
  public relationArray: Object = {};
  // public showData: boolean = false;
  public maxLinkedPatientToDisplay;
  public popoverpatient;
  public entity;
  public findOtherPatient: boolean = false;
  public linkedPatients: any;
  constructor(private modalService: ModalService, private user: UserService, private linkPatientService: LinkPatientService, private elementRef: ElementRef, private featureService: FeatureFlagsService) {
    this.updateLinkedPatient = new EventEmitter<any>();
    this.user.getConfigurationInfo.subscribe(data => {
      this.maxLinkedPatientToDisplay = (data['pxAppSettings'] && data['pxAppSettings']['maxLinkedPatToDisplay']) ? data['pxAppSettings']['maxLinkedPatToDisplay'] : null
    })
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
    this.canUnlinkSameIdentity = this.featureService.featureOn('FT_APP_UNLINKSAMEIDENTITY');
    this.canUnlinkDifferentIdentity = this.featureService.featureOn('FT_APP_UNLINKDIFFIDENTITY');
    this.canUnlinkProvidedIdentity = this.featureService.featureOn('FT_APP_UNLINKPROVIDEDIDENTITY');
    this.canLinkDifferentIdentityHOF = this.featureService.featureOn('FT_APP_HOFLINKDIFFIDENTITY');
    this.canLinkDifferentIdentityNonHOF = this.featureService.featureOn('FT_APP_NONHOFLINKDIFFIDENTITY');
    this.isHof = this.featureService.getIsHOF()
  }
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && document.getElementsByClassName('select-relation') && document.getElementsByClassName('select-relation').length>0&&document.getElementsByClassName('select-relation')[0].contains(targetElement)) {
      return;
    }
    if (targetElement && this.elementRef.nativeElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.showRelation = false;
      this.selectedRecord = null;
    }
  }
  ngOnInit() {
    this.getEntityId()
    this.getLinkedPatients();
  }
  public openRegisterPatient(id: string) {
    this.isRegisterPatient = true;
    this.modalService.open(id);
  }

  public closeModal(id: string) {
    if (id == 'find-record') {
      this.findOtherPatient = false;
    } else {
      this.isRegisterPatient = false;
      this.modalService.close(id);
    }
  } 
  public closeRegisterPatient(id: string) {
    this.isRegisterPatient = false;
  }

  public selectRecord(uhid) {
    if (!this.linkWithoutRelation)
      this.selectedRecord = uhid;
  }
  public openDp() {
    // this.showRelation = true;
    if(this.showRelation){
      this.showRelation = false;
      this.selectedRecord = null;
    }
    else{
      this.showRelation = true;
    }
  }
  public mapRelation(_id, relType) {
    this.selectedRecord = _id;
    this.relationArray[_id] = relType;
    this.showRelation = false;
  }

  public linkPatient(patientInfo) {
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entityId,
          personId: _id,
          profilesToLink: [
            {
              uhid: patientInfo.patientUhid,
              facilityId: patientInfo.facilityId,
              patientName: patientInfo.patientName,
              dob: patientInfo.birthDate,
              gender: patientInfo.gender,
              relationshipType: this.relationArray[patientInfo.patientUhid]['code'],
              mobile: patientInfo.mobile,
              email: patientInfo.email,
              imageUrl: "",
            }]
        }

        this.linkPatientService.toLinkPatients(linkData).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.recordsToLink = this.recordsToLink.filter(data => {
              return data.patientUhid != patientInfo.patientUhid;
            });
            console.log("***After linking pat***")
            this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
            this.updateLinkedPatient.emit();
          }
        })
      }
    }) 
  }

  public openPopover(patient, enablePhoto, enableUnlink, enableLink, myAccess) {
    let unlinkMyAccess = false
    if (myAccess) {
      enableUnlink = false;
    } else {
      unlinkMyAccess = true;
    }
    this.popoverpatient = { patient, enablePhoto, enableUnlink, enableLink, unlinkMyAccess }
  }
  public chooseOption(option: 'PHOTO' | 'UNLINK' | 'LINK' | 'UNLINKMYACCESS') {
    switch (option) {
      case 'PHOTO':

        break;
      case 'LINK':
        // this.relationArray[this.popoverpatient.patient.patientUhid] = this.relations[0]
        // this.linkPatient(this.popoverpatient.patient)
        if (this.popoverpatient.patient.directLink) {
          this.relationArray[this.popoverpatient.patient.patientUhid] = { code: null }
          this.linkPatient(this.popoverpatient.patient)
        } else {
          this.prefillNationalId = this.popoverpatient.patient.nationalId;
          this.openModal('find-record')
        }
        break;
    }
  }
  openModal(id: string) {
    if (id == 'find-record') {
      if ((this.isHof && this.canLinkDifferentIdentityHOF) || (!this.isHof && this.canLinkDifferentIdentityNonHOF))
        this.findOtherPatient = true;
      else  console.log('FALSE')
    }
    // this.modalService.open(id);
  }
  public initLinkPatients(patient) {
    this.relationArray[patient.patientUhid] = { code: null }
    this.linkPatient(patient)
  }
  public initAddPatients({ natid, otp }) {
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          externalIdsToLink: [
            {
              externalId: natid,
              otp: otp
            }
          ]
        }
        this.initLinkData(null, linkData);
      }
    })
  }
  getEntityId() {
    this.user.getUpdateInfo('configuration').subscribe(data => {
      this.entity = data.entityId;
    })
  }
  private initLinkData(patientInfo, linkData) {
    this.linkPatientService.toLinkPatients(linkData).subscribe(result => {
      if (result.status === 'SUCCESS') {
        this.closeModal('find-record')
        console.log("***After linking pat***")
        this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
        this.updateLinkedPatient.emit();
      }
    })
  }

  private getLinkedPatients() {
    try {
      this.user.getUserId().subscribe(data => {
        if (data) {
          this.linkPatientService.getLinkedPatient(data).subscribe(result => {
            if (result.status === 'SUCCESS') {
              this.linkedPatients = result.data;
            }
          })
        }
      })
    }catch (e) {
    }
  }

  //undefined functions
  public uploadPatientPhoto(files){}
}
