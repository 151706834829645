import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appSection]'
})
export class SectionDirective {

  constructor(public vcf: ViewContainerRef) { }

}
