<div id=hlth-pkg-detail *ngIf="false" >
    <ng-container *ngIf="!isManageIdentities">
        <div class="header border-bottom">
            <div (click)="closePkgModal()" class="float-left closeicon pointer" *ngIf=!choosePreference>
                <img  src="./assets/icons/arrow.svg">
            </div>
            <div (click)="goToPkgDtl()" class="float-left closeicon pointer" *ngIf=choosePreference>
                <img src="./assets/icons/arrow.svg">
            </div>
            <div class="font-primary-subtitle bold hide">{{ 'title_pkg_dtl'|translate:'LABELS'}} </div>
            <div class="card-content show-header">
                <div>
                    <p class="font-primary-subtitle">{{getTitle(selectedPkg.name)}}</p>
                    <span class="font-ternary-subtitle">{{getSubTitle(selectedPkg.detail)}}</span>
                </div>
                <div class="font-primary-subtitle bold clmn">
                    <span [ngClass]="{ 'lstrike' : getDiscountChargeByFacility() }">{{currency_code}} {{getChargeByFacility()}}</span>
                    <span *ngIf="getDiscountChargeByFacility()">{{currency_code}} {{getDiscountChargeByFacility()}}</span>
                </div>
            </div>
        </div>
        <div class="modal-pkg-content hide">
            <div class="package-section">
                <div class="card-content">
                    <div>
                        <p class="font-primary-subtitle">{{getTitle(selectedPkg.name)}}</p>
                        <span class="font-ternary-subtitle">{{getSubTitle(selectedPkg.detail)}}</span>
                    </div>
                    <div class="font-primary-subtitle bold clmn">
                        <span [ngClass]="{ 'lstrike' : getDiscountChargeByFacility() }">{{currency_code}} {{getChargeByFacility()}}</span>
                        <span *ngIf="getDiscountChargeByFacility()">{{currency_code}} {{getDiscountChargeByFacility()}}</span>
                        <!-- {{currency_code}} {{selectedPkg.facilities[0].controls.charge}} -->
                    </div>
                </div>
                <div class="innerImg type-image" *ngIf="getPackageImg(selectedPkg.detail)">
                    <img [src]="getPackageImg(selectedPkg.detail)" alt="" class="type-image" *ngIf="getPackageImg(selectedPkg.detail)">
                </div>
                <div class="innerImg type-image" [innerHTML]="getPackageContent(selectedPkg.detail)">
                </div>
            </div>
            <div class="filter-section">
                <div class="group">
                <div class="label">
                    <label class="font-light-xssmall">{{'lbl_choose_facility' | translate }}</label>
                </div>
                    <div class="inputs ">
                    <div class="input-group select-input">
                        <div class="location-input" (click)="showFacility()">
                        <!-- <div class="location-input" (click)="searchFacility.show = true"> -->
                        <div class="image">
                            <img src="assets/icons/current.svg" alt="">
                        </div>
                        <div class="value  font-default-small">
                            <!-- {{searchFacility.selected['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                            {{(searchFacility.selected['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                        </div>
                        </div>
                        <div class="dropdown" *ngIf="searchFacility.show == true">
                        <div class="dropdown-options">
                            <a class="dropdown-item" *ngFor="let x of facilityList" (click)="selectFacility(x)">
                            <!-- {{x['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                            {{(x['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="group">
                    <div class="label">
                        <label class="font-light-xssmall">{{'lbl_select_patient' | translate }}</label>
                    </div>
                    <div class="inputs ">
                        <div class="input-group select-input">
                            <div class="location-input spac-btw" (click)="dpDown()">
                                <div  class="value  font-default-small">
                                    {{searchPatient.selected.personname || 'lbl_select_patient' | translate }}
                                </div>
                                <div class="image" >
                                    <img  src="assets/icons/calendar/drop.svg" alt="">
                                </div>
                            </div>
                            <div class="dropdown" *ngIf="searchPatient.show == true">
                                <div class="dropdown-options">
                                    <div class="list-item ">
                                        <div class="cursor-pointer list" *ngFor="let item of linkedPatients"
                                        [ngClass]="{active: (searchPatient && item.personid == searchPatient.selected.personid)}"
                                        (click)="selectPatient(item)">
                                        <div class="inner-cards row pat-card">
                                            <div class="image">
                                                <app-person-avatar [url]="item.imageurl"> </app-person-avatar>
                                            </div>
                                            <div class="pat-card-content">
                                                <div class="title">{{item.personname}}</div>
                                                <div class="subtitle">{{item.relationshiptype | translate : 'RELATION'}}</div>
                                            </div>
                                            <div class="checked"  [ngClass]="{active: (searchPatient && item.personid == searchPatient.selected.personid)}">
                                                <img class="type-image" src="assets/icons/checked_blue.svg">
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                    <div class="pat-card row cursor-pointer no-padding" (click)="openModal('isManageIdentities')">
                                        <div class="image manage-img">
                                            <app-person-avatar [url]="'assets/icons/group.svg'"> </app-person-avatar>
                                        </div>
                                        <div class="pat-card-content">
                                            <div class="title bold">{{'manage_identities' | translate:'LABELS'}}</div>
                                            <div class="subtitle">{{'onspot_link_or_reg_patient' | translate:'LABELS'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <form [formGroup]="uploadForm" autocomplete="off">
                    <div class="group">
                        <div class="label">
                            <label class="font-light-xssmall">{{'preferred_date' | translate }}</label>
                        </div>
                    <div class="inputs ">
                    <div class="input-group date-input">
                        <div class="value">
                        <vaadin-date-picker [value]="date" id="date"  [min]="curDate"
                            (change)="vaadinChange($event, 'date')" class="vaddin-form-date"
                            [ngModelOptions]="{standalone: true}" placeholder="{{dateId }}">
                        </vaadin-date-picker>
                        <app-form-input-error [control]="uploadForm.controls.date"></app-form-input-error>
                        </div>
                    </div>
                    </div>
                    </div>
                </form>
                <div class="btn-container">
                    <button class="primary-button btn-customize" (click)="choosePackage()" [disabled]="!uploadForm.valid">{{'choose_this_pkg'|translate}}</button>
                </div>
            </div>
        </div>
        <div class="modal-pkg-content show">
            <div class="package-section" *ngIf=!choosePreference>
                <div class="innerImg type-image" *ngIf="getPackageImg(selectedPkg.detail)">
                    <img [src]="getPackageImg(selectedPkg.detail)" alt="" class="type-image" *ngIf="getPackageImg(selectedPkg.detail)">
                </div>
                <div class="innerImg type-image" [innerHTML]="getPackageContent(selectedPkg.detail)">
                </div>
                <div class="btn-container btn-bottom">
                    <button class="primary-button btn-customize" (click)="selectPackage()">{{'choose_this_pkg'|translate}}</button>
                </div>
            </div>
            <div class="filter-section" *ngIf=choosePreference>
                <div class="group">
                <div class="label">
                    <label class="font-light-xssmall">{{'lbl_choose_facility' | translate }}</label>
                </div>
                    <div class="inputs ">
                    <div class="input-group select-input">
                        <div class="location-input" (click)="showFacility()">
                        <!-- <div class="location-input" (click)="searchFacility.show = true"> -->
                        <div class="image">
                            <img src="assets/icons/current.svg" alt="">
                        </div>
                        <div class="value  font-default-small">
                            <!-- {{searchFacility.selected['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                            {{(searchFacility.selected['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                        </div>
                        </div>
                        <div class="dropdown" *ngIf="searchFacility.show == true">
                        <div class="dropdown-options">
                            <a class="dropdown-item" *ngFor="let x of facilityList" (click)="selectFacility(x)">
                            <!-- {{x['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                            {{(x['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="group">
                    <div class="label">
                        <label class="font-light-xssmall">{{'lbl_select_patient' | translate }}</label>
                    </div>
                    <div class="inputs ">
                        <div class="input-group select-input">
                            <div class="location-input spac-btw" (click)="dpDown()">
                                <div  class="value  font-default-small">
                                    {{searchPatient.selected.personname || 'lbl_select_patient' | translate }}
                                </div>
                                <div class="image" >
                                    <img  src="assets/icons/calendar/drop.svg" alt="">
                                </div>
                            </div>
                            <div class="dropdown" *ngIf="searchPatient.show == true">
                                <div class="dropdown-options">
                                    <div class="list-item ">
                                        <div class="cursor-pointer list" *ngFor="let item of linkedPatients"
                                        [ngClass]="{active: (searchPatient && item.personid == searchPatient.selected.personid)}"
                                        (click)="selectPatient(item)">
                                        <div class="inner-cards row pat-card">
                                            <div class="image">
                                                <app-person-avatar [url]="item.imageurl"> </app-person-avatar>
                                            </div>
                                            <div class="pat-card-content">
                                                <div class="title">{{item.personname}}</div>
                                                <div class="subtitle">{{item.relationshiptype | translate : 'RELATION'}}</div>
                                            </div>
                                            <div class="checked"  [ngClass]="{active: (searchPatient && item.personid == searchPatient.selected.personid)}">
                                                <img class="type-image" src="assets/icons/checked_blue.svg">
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                    <div class="pat-card row cursor-pointer no-padding" (click)="openModal('isManageIdentities')">
                                        <div class="image manage-img">
                                            <app-person-avatar [url]="'assets/icons/group.svg'"> </app-person-avatar>
                                        </div>
                                        <div class="pat-card-content">
                                            <div class="title bold">{{'manage_identities' | translate:'LABELS'}}</div>
                                            <div class="subtitle">{{'onspot_link_or_reg_patient' | translate:'LABELS'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <form [formGroup]="uploadForm" autocomplete="off">
                    <div class="group">
                        <div class="label">
                            <label class="font-light-xssmall">{{'preferred_date' | translate }}</label>
                        </div>
                    <div class="inputs ">
                    <div class="input-group date-input">
                        <div class="value">
                        <vaadin-date-picker [value]="date" id="date"  [min]="curDate"
                            (change)="vaadinChange($event, 'date')" class="vaddin-form-date"
                            [ngModelOptions]="{standalone: true}" placeholder="{{dateId }}">
                        </vaadin-date-picker>
                        <app-form-input-error [control]="uploadForm.controls.date"></app-form-input-error>
                        </div>
                    </div>
                    </div>
                    </div>
                </form>
                <div class="btn-container">
                    <button class="primary-button btn-customize" (click)="choosePackage()" [disabled]="!uploadForm.valid">{{'btn_request'|translate}}</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isManageIdentities">
        <div class="tc-modal">
          <div class="content">
            <div class=" modal-header-identity border-bottom">
              <div class="font-primary-subtitle bold">{{'manage_identities'|translate:'LABELS'}}</div>
              <div (click)="closeManageIdentity()" class="float-right closeicon pointer">
                <img src="./assets/icons/close_black.svg">
              </div>
            </div>
            <div class="font-default-small manage-content">
              <app-manage-identities></app-manage-identities>
            </div>
          </div>
        </div>
    </ng-container>
</div>

<div id=hlth-pkg-detail>
    <div >
        <div class="header border-bottom spac-btw">
            <div class="flex">
                <div (click)="closeModal('BACK')" class="float-left closeicon pointer" *ngIf=!choosePreference>
                    <img src="./assets/icons/arrow.svg">
                </div>
                <div (click)="goToPkgDtl()" class="float-left closeicon pointer" *ngIf=choosePreference>
                    <img src="./assets/icons/arrow.svg">
                </div>
                <div class="font-primary-title bold">{{ 'title_health_chkup_pkgs'|translate:'LABELS'}} </div>
            </div>
            <div (click)="closeModal('CLOSE')" class="float-right closeicon pointer">
                <img src="./assets/icons/close_black.svg">
            </div>
        </div>
        <div class="modal-pkg-content cards">
            <div class="package-section ">
                <div class="pkg-image type-image">
                    <img class="type-image add-blur hide"  [src]="selectedPkg.detail | translate : 'LANGARRAY' : 'imageURL'" alt="" *ngIf="selectedPkg.detail | translate : 'LANGARRAY' : 'imageURL'">
                    <img class="actual-img type-image"  [src]="selectedPkg.detail | translate : 'LANGARRAY' : 'imageURL'" alt="" *ngIf="selectedPkg.detail | translate : 'LANGARRAY' : 'imageURL'">
                </div>
                <div class="card-content font-default-subtitle border-bottom">
                    <div>
                        <p class="font-default-subtitle bold">{{selectedPkg.name | translate : 'LANGARRAY' : 'name'}}</p>
                    </div>
                    <div class="font-primary-subtitle bold clmn">
                        <span *ngIf="getDiscountChargeByFacility()">
                            {{getDiscountChargeByFacility()}} {{currency_code}}</span>
                        <span *ngIf="getChargeByFacility()" [ngClass]="{ 'lstrike' : getDiscountChargeByFacility() }">
                            {{getChargeByFacility()}} {{currency_code}}</span>
                    </div>
                </div>
                <div class="innerImg type-image"
                    innerHTML="{{ selectedPkg.detail | translate : 'LANGARRAY' : 'packageContent'}}">
                </div>
            </div>
        </div>
        <div class="modal-footer ">
            <div class="btn-container">
                <button class="primary-button btn-customize" (click)="choosePackage()" >{{'btn_book_pkg'|translate}}</button>
            </div>
        </div>
    </div>
</div>