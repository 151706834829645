<div class="prelogin-container">
    <div (click)="closeModal()" class="empty sm-hidden"></div>
    <div class="prelogin-modal">
        <div class="prelogin-modal-header">
            <div class="close-btn cursor-pointer">
                <img (click)="closeModal() "src="./assets/icons/close_black.svg" alt="" class="close-icon">
            </div>
        </div>
        <div class="prelogin-modal-content">
            <div class="prelogin-modal-content-left">
                <div class="prelogin-login-context">
                    <p class="prelogin-label">{{'You_need_to_login_/_create_a_new_account_to_continue' | translate}}</p>
                    <div class="prelogin-actions">
                        <button class="button-white-outline" (click)="login()">{{'Login_or_signup'| translate}}</button>
                    </div>
                </div>
                <div class="prelogin-app-wrapper">
                    <p class="prelogin-app-label">{{'lbl_experience_app_text' | translate}}</p>
                    <div class="prelogin-app-actions-bar">
                        <img alt="android" class="app-image type-image" src="assets/icons/Home/google-play-badge.svg"
                            (click)="download('android')" />
                        <img alt="ios" class="app-image type-image" src="assets/icons/Home/apple_play.png" (click)="download('apple')" />
                        </div>
                </div>
            </div>
            <div class="prelogin-modal-content-right">
                <img  class="app-preview-image type-image" [src]="bannerImages[currentLanguage] || bannerImages['en']" alt="">
            </div>
        </div>
        <!-- <div class="prelogin-images">
            <img (click)="closeModal() "src="./assets/icons/Home/close_black.svg" alt="" class="close-icon">
            <img class="app-preview-image" src="./assets/icons/Home/Home Screens.png" alt="">
        </div> -->
    </div>
</div>