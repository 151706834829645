<div id="list-item" class="{{className}}" (click)="listItemClick()">
  <div class="list-item">
    <div class="icon">
      <ng-content select=".thumbnail"></ng-content>
    </div>
    <div class="content">
      <div class="header-section">
        <ng-content select=".prefix"></ng-content>
        <ng-content select=".title"></ng-content>
        <ng-content select=".sub-title"></ng-content>
        <ng-content select=".chip"></ng-content>
      </div>
      <ng-content select=".description"></ng-content>
      <div class="footer-section">
        <ng-content select=".primary-content"></ng-content>
        <ng-content select=".secondary-content"></ng-content>
      </div>
    </div>
  </div>
  <ng-content select=".list-action"></ng-content>
</div>
<ng-content select=".footor-temp"></ng-content>
<div id="relation-div">
  <ng-content select=".select-relation"></ng-content>
</div>
<div class="border-bottom" id="border-bottom"></div>


