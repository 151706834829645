import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookAppointmentComponent } from './book-appointment.component';
import { DirectiveModule } from '../directive/directive.module';
import { AppointmentRecentModule } from '../appointment-recent/appointment-recent.module';
import { SearchFacilityModule } from '../search-facility/search-facility.module';
import { SearchSpecialityModule } from '../search-speciality/search-speciality.module';
import { SearchPractitionerModule } from '../search-practitioner/search-practitioner.module';
import { ChooseSlotModule } from '../choose-slot/choose-slot.module';
import { LinkedPatientsModule } from '../linked-patients/linked-patients.module';
import { AppointmentConfirmModule } from '../appointment-confirm/appointment-confirm.module';
import { PipesModule } from '../pipes/pipes.module';
import { AppointmentReviewModule } from '../appointment-review/appointment-review.module';
import { AppointmentVisitTypeModule } from '../appointment-visit-type/appointment-visit-type.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { OnspotConsultDoctorModule } from '../onspot-consult-doctor/onspot-consult-doctor.module';
import {CouponModule} from '../coupon/coupon.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module';
import { ReferralActionsModule } from '../referral-actions/referral-actions.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { SearchFSPModule } from '../search-f-s-p/search-f-s-p.module';
import { HomeCareServicesModule } from '../home-care-services/home-care-services.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';

@NgModule({
  declarations: [BookAppointmentComponent],
  imports: [
    CommonModule, DirectiveModule, AppointmentRecentModule, SearchFacilityModule, SearchSpecialityModule, SearchPractitionerModule, ChooseSlotModule, LinkedPatientsModule, AppointmentConfirmModule, PipesModule, AppointmentReviewModule, AppointmentVisitTypeModule, PaymentCheckoutModule,
    ManageIdentitiesModule,OnspotConsultDoctorModule,
    CouponModule,SearchFSPModule,
    ExpressClinicModalModule, ReferralActionsModule, RegisterPatientModule,HomeCareServicesModule, RegisterNewPatientModule
  ],
  
  exports:[BookAppointmentComponent]
})
export class BookAppointmentModule { }
