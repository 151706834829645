import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { TOAST_INFO } from './../app.string';
import { HttpClient } from '@angular/common/http';
import { ToastServiceService } from '../services/toast-service.service';
import { LoaderService } from '../services/loader.service';
import { TOAST_ERROR } from '@string';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-pdfxviewer',
  templateUrl: './pdfxviewer.component.html',
  styleUrls: ['./pdfxviewer.component.scss']
})
export class PdfxviewerComponent implements OnInit {

  @Input() public URL: string
  @Input() public title: string;
  @Output() public close: EventEmitter<boolean>;
  constructor(private http: HttpClient, private toast: ToastServiceService, private loader: LoaderService, private user: UserService) {
    this.close = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.loader.changeState(true);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event['keyCode'] == 27)
      this.close.emit(false);
  }


  public print() {
    this.http.get(this.URL, { responseType: 'arraybuffer' }).subscribe((file: ArrayBuffer) => {
      this.toast.showToast(null, 'Printing', TOAST_INFO);
      var blob = new Blob([<any>file], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    }, error => {
      console.log(error)
      this.toast.showToast(null, 'Error in printing PDF', TOAST_ERROR);
    });
  }


  public download() {
    var win = this.user.openLocation(this.URL, '_blank');
    win.focus();
  }

  public closeWindow() {
    this.close.emit(false);
  }

  public error(event: any) {
    this.toast.showToast(null, 'Error in rendering PDF', TOAST_ERROR);
  }

  public stopLoader() {
    console.log("ev")
    this.loader.changeState(false);
  }
  
}
