import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HeaderModule } from '../header/header.module';
import { AppointmentsModule } from '../appointments/appointments.module';
import { ToDoModule } from '../to-do/to-do.module';
import { CarePlanModule } from '../care-plan/care-plan.module';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { CompanyDescriptionModule } from '../company-description/company-description.module';
import { LinkRecordsModule } from '../link-records/link-records.module';
import { RecentVisitsModule } from '../recent-visits/recent-visits.module';
import { HomeFeedbackCardModule } from '../home-feedback-card/home-feedback-card.module';
import { HomeFeedbackModule } from '../home-feedback/home-feedback.module';
import { NavigationModule } from '../navigation/navigation.module';
import { PipesModule } from '../pipes/pipes.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { ManageAccountModule } from '../manage-account/manage-account.module';
import { FooterModule } from '../footer/footer.module';
import { from } from 'rxjs';
import { OngoingAppointmentModule } from '../ongoing-appointment/ongoing-appointment.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module'
import { DirectiveModule } from '../directive/directive.module';
import { HealthPackageModule } from '../health-package/health-package.module';
import { HomeCareServicesModule } from '../home-care-services/home-care-services.module';
import { OpenreferralsModule } from '../openreferrals/openreferrals.module';
import { HealthPackageFlowModule } from '../health-package-flow/health-package-flow.module';
import { ServiceRequestModule } from '../service-request/service-request.module';
import { PreLoginModalModule } from '../pre-login-modal/pre-login-modal.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
import { MyPackagesModule } from '../my-packages/my-packages.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { HealthPackagePreviewModule } from '../health-package-preview/health-package-preview.module';
import { ContactUsModalModule } from '../contact-us-modal/contact-us-modal.module';
import { AlertModalModule } from '../alert-modal/alert-modal.module';
import { ConsultAgainModule } from '../consult-again/consult-again.module';
import { RecentlyConsultedModule } from '../recently-consulted/recently-consulted.module';
import { NpsRatingModule } from '../nps-rating/nps-rating.module';
import { ManageInsuranceModule } from '../manage-insurance/manage-insurance.module';
import { CouponModalModule } from '../coupon-modal/coupon-modal.module';
import { RemovePoliyModalModule } from '../remove-poliy-modal/remove-poliy-modal.module';
import { InsuranceDetailsModule } from '../insurance-details/insurance-details.module';
@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule, HeaderModule, AppointmentsModule, ToDoModule, CarePlanModule, BookAppointmentModule, CompanyDescriptionModule, LinkRecordsModule, RecentVisitsModule, RegisterNewPatientModule, RegisterPatientModule, HomeFeedbackCardModule, HomeFeedbackModule,
    NavigationModule,
    PipesModule,
    CustomModalModule,
    ManageIdentitiesModule,
    ManageAccountModule,
    FooterModule,
    OngoingAppointmentModule,
    ExpressClinicModalModule,
    DirectiveModule,
    HealthPackageModule,
    HomeCareServicesModule,
    OpenreferralsModule,
    HealthPackageFlowModule,
    OpenreferralsModule, ServiceRequestModule,
    PreLoginModalModule,
    MyPackagesModule,
    HealthPackagePreviewModule,
    ContactUsModalModule, AlertModalModule, ConsultAgainModule, RecentlyConsultedModule,
    NpsRatingModule, CouponModalModule, ManageInsuranceModule, InsuranceDetailsModule, RemovePoliyModalModule
  ],
  exports: [HomeComponent]
})
export class HomeModule { }
