import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { DirectiveModule } from '../directive/directive.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { CouponModalComponent } from './coupon-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { NodataModule } from '../nodata/nodata.module';



@NgModule({
  declarations: [CouponModalComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule, NodataModule
  ],
  exports: [
    CouponModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CouponModalModule { }
