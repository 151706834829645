import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterPatientComponent } from './register-patient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import {NgxHijriGregorianDatepickerModule} from 'ngx-hijri-gregorian-datepicker';
import { PhoneInputModule } from '../phone-input/phone-input.module';

@NgModule({
  declarations: [RegisterPatientComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, PipesModule, FormInputErrorModule, PaymentCheckoutModule,NgxHijriGregorianDatepickerModule, PhoneInputModule
  ],
  exports: [
    RegisterPatientComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegisterPatientModule { }
