import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { VisitService } from '../services/visit.service';

@Component({
  selector: 'app-visit-standalone',
  templateUrl: './visit-standalone.component.html',
  styleUrls: ['./visit-standalone.component.scss']
})
export class VisitStandaloneComponent implements OnInit {
  public step = 0;
  public phoneCtrl: UntypedFormControl;
  public otpCtrl: UntypedFormControl;
  public viewOTPModal: boolean;
  public viewPatientsModal: boolean = false;

  public queryParams;
  public practitioners;
  public practitionerState:'P'|'C'|'E' = 'P'
  public selectedPractitioner;
  selectedPatient: any;
  public isRegisterPatient: boolean = false;
  public facility;
  printerConfig: any;
  visitCharges: any = {};
  visitDetails: any;
  isPaymentGateway: boolean = false
  languageList: any;
  switchLanguage: boolean;
  isSelected: any;
  patRegParams: any;
  successState;
  constructor(private visitS: VisitService, private route: ActivatedRoute, private user: UserService, private paymentService: PaymentService) {
    this.route.queryParams.subscribe(qp => {
      this.queryParams = qp;
      this.isSelected = qp?.lang;
      if (qp['r'] && qp['r'] == '1') {
        let method = qp['method']
        let orderDetail = qp['p']
        let orderData = atob(orderDetail);
        let arr = orderData.split('&');
        let params = {};
        for (var i = 0; i < arr.length; i++) {
          var pair = arr[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }
        console.log("params", params)
        let orderId = params['o'];
        let retryParams = {
          currency_code: params['c'],
          patientPayable: params['a'],
          uhid: params['i'],
          email: params['e'],
          mobile_no: params['m'],
        }
        this.queryParams = {
          fid: qp['fid'],
          sid: qp['sid'],
          pid: qp['pid'],
          printid:qp['printid']
        }

        this.checkTransactionStatus(orderId, retryParams, qp['method'])
      } else {
        this.initValidation(this.queryParams);
      }
      this.user.getLanguages().subscribe(data => {
        this.languageList = data;
        if (this.isSelected) {
          const lang = this.languageList.find(d => d['localeCode'] = this.isSelected);
          if (lang)
            this.onItemChange(lang);
        } else this.isSelected = this.user.currentLanguage;
      });
    })
  }

  initValidation(qp) {
    let state;
    if (qp['s']) {
      state = qp['s']
    }
    if (qp['t'])
      this.visitS.setRepoToken(null, state, qp['t']);
    this.validateQR();
  }

  ngOnInit(): void {

  }

  nextStep() {
    this.viewOTPModal = false;
    this.viewPatientsModal = false;
    this.step += 1;
    console.log('step', this.step);
  }

  previousStep() {
    this.step -= 1;
  }
  validateQR() {
    const params = {
      facilityId: this.queryParams['fid'],
      clinicCode: this.queryParams['sid'],
      practId: this.queryParams['pid'],
      ...this.queryParams
    }

    this.visitS.validateVisitQR(params)
      .subscribe(({ practitioners, facility, printerConfig }: any) => {
        this.practitionerState = 'C'
        this.step = 0;
        console.log('validateVisitQR', practitioners);
        this.practitioners = practitioners;
        // this.practitioners = [{
        //   "practId": "HIMANSHUD",
        //   "practitionerName": "Dr Himanshu Dabral",
        //   "isWorking": "Y",
        //   "photoUrl": null,
        //   "nextAvailableTime": "23:00-23:15",
        //   clinicCode: "CL02",
        //   clinicName:"Cardiology Clinic"
        // }];
        this.facility = facility;
        sessionStorage.setItem('selectedFacility', JSON.stringify(facility));
        this.printerConfig = printerConfig;
        if (this.practitioners.length == 1 && this.practitioners[0].isWorking == 'Y' && this.practitioners[0].nextAvailableTime && this.practitioners[0].nextAvailableTime!="")
          this.choosePractitioner(this.practitioners[0])
      }, err => {
        this.practitionerState = 'E'
      })
  }

  public choosePractitioner(p) {
    this.selectedPractitioner = p;
    sessionStorage.setItem('selectedPract', JSON.stringify(p));
    this.nextStep();
    this.getVisitCharges().then(_ => {});
  }

  public setPatient(patient) {
    if (!patient)
      return;
    this.selectedPatient = patient;
    this.getVisitCharges().then(_ => {
      this.nextStep();
    });
  }

  public registerPatient() {
    this.isRegisterPatient = true;
  }

  public closeRegisterPatient(event) {
    this.isRegisterPatient = false;
    let { patients, patientId } = event;
    console.log(patients, patientId)
    if (patients && Array.isArray(patients) && patientId) {
      let patient = patients.find(pat => pat.patientId == patientId);
      if (patient) {
        this.setPatient(patient)
      }
    }
  }
  public getVisitCharges() {
    return new Promise((resolve, reject) => {
    this.visitS.getVisitCharges(this.facility['facilityId'], this.selectedPractitioner['clinicCode'], this.selectedPractitioner['practId'], this.selectedPatient?.['patientId'])
      .subscribe(data => {
        console.log('visitCharges', data);
        this.visitCharges = data;
        if (!this.visitCharges['currency']) {
          this.visitCharges['currency'] = environment['CURRENCY']
        }
        return resolve('');
      })
    })
  }

  onVisitCreated(data) {
    this.visitDetails = data;
    this.step = 2;
    this.nextStep();
  }

  public initPay({appointment, paymentParams}) {
    this.paymentService.setPaymentParams(appointment, paymentParams).subscribe(_ => {
      this.isPaymentGateway = true
    })
  }

  private checkTransactionStatus(orderId, retryParams, method?) {
    this.visitS.checkTransactionStatus(orderId, this.queryParams['fid'])
      .subscribe(data => {
        console.log('visitCharges', data);
        this.selectedPractitioner = {
          practitionerName: data.practitionerName,
          practId: data.practitionerId,
          clinicCode: data.clinicCode,
          clinicName: data.clinicName
        }
        this.selectedPatient = {
          patientId: data.patientId,
          patientName: data.patientName
        }
        if (retryParams) {
          data.retryParams = retryParams
        }
        if (method == 'REGPAT') {
          this.visitDetails = data;
          this.step = 3;
         return this.successState = 'REGPAT';
        } 
        this.onVisitCreated(data)
    })
  }

  public restartFlow(regPatData?) {
    const selectedPractString = sessionStorage.getItem('selectedPract'),
    selectedFacilityString = sessionStorage.getItem('selectedFacility');
    if (!(regPatData && selectedPractString && selectedFacilityString)) {
      return this.validateQR();
    }
    this.facility = JSON.parse(selectedFacilityString);
    this.selectedPractitioner = JSON.parse(selectedPractString);
    this.getVisitCharges().then(_ =>{});
    this.selectedPatient = regPatData;
    this.step = 2;
  }
  onItemChange(item){
    this.user.setDirection(item);
    this.isSelected = item.localeCode;
    this.switchLanguage = false;
  }

  payAndRegister(data) {
    console.log('payAndRegister',data);
    this.isRegisterPatient = false;
    this.patRegParams = data;
    this.step = 4;
  }
}
