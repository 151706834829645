import { Component, OnInit } from '@angular/core';
import { vaccineFlow } from '@type';
import { UserService } from '../services/user.service';
import {isEmpty as _isEmpty, isArray as _isArray, cloneDeep as _cloneDeep} from 'lodash';
import { ClinicalformService } from '../services/clinicalform.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vaccination-form',
  templateUrl: './vaccination-form.component.html',
  styleUrls: ['./vaccination-form.component.scss']
})
export class VaccinationFormComponent implements OnInit {

  public state: vaccineFlow = 'INIT';
  public nationalId: string;
  public otp: string;
  public authSurveyMessage: string;
  public directiveInfo: Object;
  public clinicalParams: any;
  public content: any;
  public patientInfo: any;
  public lang: string;
  public languageList: any;
  public switchLanguage: boolean;
  constructor(private user: UserService, private form: ClinicalformService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.directiveInfo = {
      directiveCode: 'CVDAL',
      conceptCode: 'CVDCITSELFREG'
    };
    this.content = {
      title: 'Thank you for your registration and Self Assessment',
      subTitle: 'Please follow all preventive measures against COVID-19 to stay safe.'
    };
  }

  ngOnInit(): void {
    console.log('sttaete', this.state);
    this.activatedRoute.queryParams.subscribe(qp => {
      this.user.getLanguages().subscribe(data => {
        if (qp && qp['lang']) {
          let lang = data.find(d => d['localeCode'] == qp['lang']);
          this.user.setCurrentLanguage(lang);
          this.user.getLabels().subscribe(labels => {
            this.user.setLocaleLabels(labels);
            this.lang = qp['lang'];
          })
        } else {
          this.user.getLabels().subscribe(labels => {
            this.user.setLocaleLabels(labels);
            this.lang = this.user.currentLanguage;
          })
        }
        this.languageList = data;
      });
    });
  }

  public startSurvey() {
    this.user.startSurvey(this.nationalId).subscribe(response => {
      this.authSurveyMessage = response;
    })
  }

  public authSurvey() {
    const payload = {
      ...this.directiveInfo,
      key: this.nationalId,
      otp: this.otp,

    }
    this.user.authSurvey(payload).subscribe(response => {
      console.log('response', response);
      const { data, formData } = response;
      this.user.setUserInfo(data, false);
      if (!_isEmpty(formData['directives']) && _isArray(formData['directives']) && !_isEmpty(formData['directives'][0]['concepts']) && _isArray(formData['directives'][0]['concepts'])) {
        const concept = formData['directives'][0]['concepts'][0];
        const personInfo = this.user.getPersonInfo();
        console.log('personInfo', personInfo);
        this.patientInfo = personInfo['linkedPatients'] ? personInfo['linkedPatients'][0] : {};
        let clinicalParams = {
          concept: {
            id: concept['id'],
            label: concept['label'],
            noteCode: concept['noteCode'],
            noteContent: concept['noteContent'],
            directiveCode: this.directiveInfo['directiveCode'],
            directiveControls: concept['directiveControls'],
            facilityId: concept['facilityId'],
            careElementType: concept['careElementType'],
            noteControls: concept['noteControls'],
            routeApplicable: concept['routeApplicable'],
            frequencyApplicable: concept['frequencyApplicable']
          },
          patient: {
            patientId: this.patientInfo['patientId'],
            patientInfo: {
              dob: '',
              patientName: this.patientInfo['patientName'],
              patientId: this.patientInfo['patientId'],
              facilityId: this.patientInfo['facilityId'],
              sex: ''
            }
          },
          preview: false
        }
        this.clinicalParams = _cloneDeep(clinicalParams);
        console.log('consef', this.clinicalParams);
        this.state = 'FORM';
      }
    })
  }


  public createEvent(eventInfo) {
    console.log('event', eventInfo);
    this.form.saveEvent(this.form.getParamsForCreateEvent(eventInfo['response'], eventInfo['response']['data'], this.patientInfo, false, this.lang)).subscribe(response => {
      this.state = 'COMPLETE';
    })
  }

  public switchLanguageAction() {
    // this.switchLanguage = true;
    // this.openUserProfileActions = false;
    if (this.switchLanguage) {
      this.switchLanguage = false;
    }
    else {
      this.switchLanguage = true;
    }
  }

  public switchLanguageClose() {
    this.switchLanguage = false;
  }

  onItemChange(item) {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { lang: item.localeCode } })
    this.switchLanguage = false;
  }

}
