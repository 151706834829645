import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreviewComponent } from './image-preview.component';
import { DirectiveModule } from '../directive/directive.module';



@NgModule({
  declarations: [
    ImagePreviewComponent
  ],
  imports: [
    CommonModule, DirectiveModule
  ], 
  exports: [
    ImagePreviewComponent
  ]
})
export class ImagePreviewModule { }
