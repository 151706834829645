import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageInsuranceComponent } from './manage-insurance.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { InsuranceDetailsModule } from '../insurance-details/insurance-details.module';



@NgModule({
  declarations: [ManageInsuranceComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule,
    PersonAvatarModule, PractitionerAvatarModule, InsuranceDetailsModule
  ],
  exports: [ManageInsuranceComponent]
})
export class ManageInsuranceModule { }
