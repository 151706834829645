import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrebootstrapLoaderComponent } from './prebootstrap-loader.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [PrebootstrapLoaderComponent],
  imports: [
    CommonModule, LoaderModule
  ],
  exports:[PrebootstrapLoaderComponent]
})
export class PrebootstrapLoaderModule { }
