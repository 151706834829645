import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {uniqBy as _uniqBy} from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  private colorList: Array<any>;
  constructor() {
    this.colorList = new Array<any>();
  }

  public getPersonNameInitials(pName) {
    var regex = /((Mr|MR|Ms|Miss|Mrs|Dr|Sir|)\.? )?([^ ]*) ?([^ ]*)/;
    var pNameCpy = regex.exec(pName);
    var personInitials = '';

    if (Array.isArray(pNameCpy) && pNameCpy.length > 0) {
      personInitials = pNameCpy[3].charAt(0) + pNameCpy[4].charAt(0);
    }
    return personInitials;
  }

  public getPersonAge(dob) {
    return this.getMoment().diff(dob, 'years', false) + 'Y'
  }

  public preciseAge(dob) {
    if (this.getMoment().diff(dob, 'years', false) == 0) {
      if (this.getMoment().diff(dob, 'months', false) == 0) {
        return this.getMoment().diff(dob, 'days', false) + 'D';
        // if (this.getMoment().diff(dob, 'weeks', false) == 0) {
        //   return this.getMoment().diff(dob, 'days', false) + 'D';
        // } else return this.getMoment().diff(dob, 'weeks', false) + 'W';
      } else return this.getMoment().diff(dob, 'months', false) + 'M';
    } else return this.getMoment().diff(dob, 'years', false) + 'Y';
  }

  public getPersonGender(gender) {
    return gender == 'M' ? 'Male' : 'Female';
  }

  public groupData(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  public groupAssigneeData(array, at, pat, userid) {
    var groups = {};
    array.forEach(function (o) {
      if (o[pat] == o[at]) {
        groups['unassinged'] = groups['unassinged'] || []
        groups['unassinged'].push(o)
      }
      else if (o[at] == userid) {
        groups['assigned'] = groups['assigned'] || []
        groups['assigned'].push(o)
      }
      else {
        groups['others'] = groups['others'] || []
        groups['others'].push(o)
      }
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }
  public groupDataByDate(array, col) {
    let groups = array.reduce((group, item) => {
      let date = this.getMoment(item[col]).format('DD MM YYYY');
      if (!group[date]) {
        group[date] = [];
      }
      group[date].push(item);
      return group;
    }, {})
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  public getFilterData(array, selectedFilter, filterData?) {
    return array.filter(t => {
      return selectedFilter.some(f => {
        if (t[f['id']['item']] === f['item']) {
          if (f['subItem']) {
            return f['subItem'].includes(t[f['id']['categoryItem']])
          } else {
            return true;
          }
        }
        return false;
      })
    });
  }

  public getRandomColor(code: any) {
    let index = this.colorList.findIndex(c => c['item'] == code)
    if (index >= 0)
      return this.colorList[index]['value'];
    else {
      let color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      this.colorList.push({
        item: code,
        value: color
      })
      return color;
    }
  }

  public checkInterval(actualSlots: Array<string>, date: string) {
    let interval = [];
    const dateFormat = "HH:mm";
    let slotStart = this.getMoment(actualSlots[0].split('-')[0], dateFormat);
    let slotEnd = this.getMoment(actualSlots[0].split('-')[1], dateFormat);
    let diff = slotEnd.diff(slotStart, 'minutes');
    let slots = actualSlots.map(s => s.split('-')[0]);
    let startTime = this.getMoment(slots[0], dateFormat);
    slots.forEach(s => {
      if (!(this.getMoment(this.getMoment(s, dateFormat)).isSame(startTime))) {
        let duration = this.getMoment(s, dateFormat).diff(startTime, 'minutes');
        interval.push({
          intervalTime: startTime.format(dateFormat),
          intervalDuration: `${Math.floor(duration / 60)}:${duration % 60}`,
          intervalDate: date
        })
        startTime = this.getMoment(s, dateFormat);
      }
      startTime.add(diff, 'minutes');
    })
    return interval;
  }

  public replaceLanguage(url: string, language: string) {
    return url.replace('%language%', language);
  }

  public getFacilityByConcept(concepts){
    let arrayToFilter = concepts
    let facility = [];
    arrayToFilter.map((item) => {
      item.facilities.map((i)=>{
        facility.push(i);
      })
    });
    let facilityList =_uniqBy(facility, 'facilityId');
    return facilityList
  }

  public print(printData, mimeType) {
    var blob = new Blob([printData], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
  }

  public getMoment(val?, format?) {
    if (format) {
      return moment(val, format).locale('en');
    } else if (val) {
      return moment(val).locale('en');
    } else {
      return moment().locale('en')
    }
  }
}

