import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { HealthRecordsService } from '../services/health-records.service';

@Component({
  selector: 'app-hr-feedback-form',
  templateUrl: './hr-feedback-form.component.html',
  styleUrls: ['./hr-feedback-form.component.scss']
})
export class HrFeedbackFormComponent implements OnInit {

  @Input() selectedAppt: any;
  @Output() close: EventEmitter<any>;
  @ViewChild('clinicalForm') clinicalForm: ElementRef;
  public params: any;
  public showPreview: boolean;
  public _topicsToPreview: Array<any>;
  public formContent: Array<any>;
  public isComplete: boolean;
  constructor(private apptService: AppointmentService, private hrService: HealthRecordsService) {
    this.close = new EventEmitter<any>();
    this.params = {
      patient: null,
      directiveCode: null,
      preview: false,
      enableDraft: false,
      taskApplicableStatus: null,
      editNote: null,
      addToNote: null,
      concept: {
        noteContent: {}
      }
    }
  }

  ngOnInit(): void {
    this.getQuestionnarieForm();
  }
  public closeModal() {
    this.close.emit();
  }
  private getQuestionnarieForm() {
    // this.apptService.getFormContent(payload).subscribe(data => {
    //   this.params['concept']['noteContent'] = {
    //     topics: data.formContent.topics
    //   }
    //   this.formContent = data;
    // })
    this.hrService.getFeedbackData(this.selectedAppt.patientId, this.selectedAppt.encounterId, this.selectedAppt.patientClass).subscribe(data => {
      this.params['concept']['noteContent'] = {
        topics: data.feedbackForm.topics
      }
      this.isComplete = data.isComplete;
      this.formContent = data;
    })
  }
  public togglePreview() {
    this.showPreview = !this.showPreview;
  }
  public saveResponse(event) {
    console.log(event);
    let d = event['response'];
    let body = {
      status: event.save ? 'COMP' : 'DRFT',
      patientId: this.selectedAppt.patientId,
      facilityId: this.selectedAppt.facilityId,
      encounterId: this.selectedAppt.encounterId,
      visitDetail: {
        practitionerId: this.selectedAppt.practitionerId,
        mobileNumber: this.selectedAppt.mobileNumber,
        patientInfo: this.selectedAppt.patientInfo,
        specialtyCode: this.selectedAppt.specialtyCode,
        admnDate: this.selectedAppt.admnDate,
        dischargeDate: this.selectedAppt.dischargeDate,
        patientClass: this.selectedAppt.patientClass
      },
      form: {
        clinicalNote: (Array.isArray(d.data) && d.data.length >= 1 && d.data[0].noteContent) ? d.data[0].noteContent : null,
        topicOutcomes: (Array.isArray(d.topicOutcomes) && d.topicOutcomes.length > 0) ? d.topicOutcomes : null,
        outcomes: (Array.isArray(d.outcome) && d.outcome.length > 0) ? d.outcome : null
      }
    };
    // console.log(body); return;
    this.hrService.recordFeedback(body).subscribe(_ => {
      this.closeModal();
    })
  }
}
