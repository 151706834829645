import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenreferralsComponent } from './openreferrals.component';
import { PipesModule } from '../pipes/pipes.module';
import { ListItemModule } from '../list-item/list-item.module';



@NgModule({
  declarations: [OpenreferralsComponent],
  imports: [
    CommonModule, PipesModule, ListItemModule
  ],
  exports: [
    OpenreferralsComponent
  ]
})
export class OpenreferralsModule { }
