import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrFeedbackFormComponent } from './hr-feedback-form.component';
import { ClinicalformModule } from '../clinicalform/clinicalform.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormpreviewModule } from '../formpreview/formpreview.module';



@NgModule({
  declarations: [HrFeedbackFormComponent],
  imports: [
    CommonModule, ClinicalformModule, PipesModule, FormpreviewModule
  ],
  exports: [
    HrFeedbackFormComponent
  ]
})
export class HrFeedbackFormModule { }
