import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'specialityDetails'
})
export class SpecialityDetailsPipe implements PipeTransform {
  private facilitiesList: any;
  constructor(private user: UserService) {
    this.user.getUpdateInfo('facilityServices').subscribe(data => {
      this.facilitiesList = data.facilities;
    });
  }
  transform(value: any, fId: any, key: string): any {
    let val = this.facilitiesList.filter(fac => {
      return fac.facilityId == fId;
    })[0]
    if (val) {
      val = val['specialities'].filter(spec => {
        return spec.specialityId == value;
      })[0]
      val= val?val[key]:'';
    }
    return val;
  }

}
