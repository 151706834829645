import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
// import { LeadService } from "../../../service/lead.service";
@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends Type implements OnInit, SectionInterface, OnChanges {

  @Input() public section;
  @Input() public sections;
  @Input() public route;
  @Input() public frequency;
  @Input() public tabindex;
  public showAlertEmail: boolean;
  // public leadService: LeadService
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    if (this.section['_selected'])
      this.checkForPatient();
    this.section['isValid'] = false;
  }

  ngOnChanges() {

  }

  changeInText(value: string) {
    super.listenToInput(value, this.section);
  }

  checkForPatient() {
    if (!this.section['_selected'])
      return;
    this.section['_selected'] = this.section['_selected'].toUpperCase();
    // this.leadService.findPatient(this.section['_selected']).subscribe(data => {
    //   if (Array.isArray(data) && data.length > 0) {
    //     this.section['selected'] = this.section._selected;
    //     this.section['selectedPatient'] = data[0];
    //     this.section['_error'] = null;
    //     this.section['isValid'] = true;
    //     // if (data[0]['gender'])
    //     //   data[0]['gender'] = data[0]['gender'] == 'M' ? 'Male' : data[0]['gender'] == 'F' ? 'Female' : '';
    //     if (data[0]['mobile'])
    //       data[0]['mobile'] = data[0]['mobile'].length > 11 ? data[0]['mobile'] : `+91${data[0]['mobile']}`
    //     let filledData = this.leadService.mapPatientInfoToDomainCode(data[0]);
    //     if (filledData)
    //       super.fillDomainCodeValue(this.sections, filledData);
    //   } else {
    //     this.section._error = "Invalid Patient ID";
    //     this.section['isValid'] = false;
    //   }
    // }, err => {
    //   this.section._error = "Invalid Patient ID";
    //   this.section['isValid'] = false;
    // })
  }

  clearPatient() {
    this.section.selected = null;
    this.section._selected = null;
    this.section.selectedPatient = null;
    this.section._error = null;
    // let filledData = this.leadService.mapPatientInfoToDomainCode(this.getPatientData());
    // if (filledData)
    //   super.clearDomainCodeValue(this.sections, filledData);
  }

  checkIsEmailValid(email) {
    console.log('Check---->', email);
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (email) {
      if (reg.test(email) == false)
        this.showAlertEmail = true;
      else
        this.showAlertEmail = false;
    } else {
      this.showAlertEmail = false;
    }
  }

  getPatientData() {
    return {
      "firstName": '',
      "lastName": '',
      "dob": '',
      "gender": '',
      "email": '',
      "addrLine1": '',
      "addrLine2": '',
      "addrLine3": '',
      "mobile": '+91'
    }
  }
}
