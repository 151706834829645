import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToDoComponent } from './to-do.component';
import { ListItemModule } from '../list-item/list-item.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [ToDoComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule
  ],
  exports:[ToDoComponent]
})
export class ToDoModule { }
