<div class="right-nav showModal">
  <div (click)="closeModal()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold" *ngIf="!isEditPatient">{{'lbl_add_address' | translate}}</div>
      <div class="font-primary-subtitle bold" *ngIf="isEditPatient">{{'lbl_update_address' | translate}}</div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content" *ngIf="addressDetails">
        <form class="form" [formGroup]="changeAddressForm">
          <div class="input-group select-input">
            <div class="label">
              <label for="addressType" class="font-light-xssmall">{{'frm_addresstype'|translate}}</label>
              <span class="mandatory font-danger-small">*</span>
            </div>
            <div class="value" [ngClass]="{'disable-add':isAddressTypeDisabled }">
              <input name="addressType" type="text" id="addressType" placeholder="{{'plhd_addressType'|translate}}"
                [(ngModel)]="addressDetails['addressType']" formControlName="addressType" autocomplete="off"
                (blur)="blurAddressType()" (focus)="initAddressType()" [readOnly]="true"/>
              <app-form-input-error [control]="changeAddressForm['controls']['addressType']"></app-form-input-error>
            </div>
            <div class="dropdown" *ngIf="focusAddressType">
              <div class="dropdown-options">
                  <a class="dropdown-item" (click)="selectAddrType(x)"
                        *ngFor="let x of applicableAddressTypes">
                        {{x['type']}}
                      </a>
              </div>
            </div>
          </div>
          <div class="input-group text-input" *ngIf="!hideFormInput['address']">
            <div class="label">
              <label for="addressLine1" class="font-light-xssmall">{{'address_line1'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('address')">*</span>
            </div>
            <div class="value">
              <input name="addressLine1" [(ngModel)]="addressDetails['line1']" formControlName="address" type="text" id="addressLine1"
                placeholder="{{'plhld_address1'|translate}}" />
              <app-form-input-error [control]="changeAddressForm['controls']['address']"></app-form-input-error>
            </div>
          </div>
          <div class="input-group text-input" *ngIf="!hideFormInput['address2']">
            <div class="label">
              <label for="addressLine2" class="font-light-xssmall">{{'address_line2'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('address1')">*</span>
            </div>
            <div class="value">
              <input name="addressLine2" [(ngModel)]="addressDetails['line2']" formControlName="address1" type="text" id="addressLine2"
                placeholder="{{'plhld_address2'|translate}}" />
              <app-form-input-error [control]="changeAddressForm['controls']['address1']"></app-form-input-error>
            </div>
          </div>
          <div class="input-group text-input" *ngIf="!hideFormInput['pincode']">
            <div class="label">
              <label for="pincode" class="font-light-xssmall">{{'pincode'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('pinCode')">*</span>
            </div>
            <div class="value">
              <input name="pincode" [(ngModel)]="addressDetails['pinCode']" formControlName="pinCode" type="text" id="pincode"
                placeholder="{{'plhld_pincode'|translate}}" />
              <app-form-input-error [control]="changeAddressForm['controls']['pinCode']"></app-form-input-error>
            </div>
          </div>
          <div class="input-group select-input" *ngIf="!hideFormInput['state']">
            <div class="label">
              <label for="state" class="font-light-xssmall">{{'frm_state'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('state')">*</span>
            </div>
            <div class="value">
              <input name="state" type="text" id="state" placeholder="{{'plhd_sl_state'|translate}}"
                [(ngModel)]="addressDetails['stateDesc']" formControlName="state" autocomplete="off"
                (blur)="blurState()" (focus)="focusState=true" />
              <app-form-input-error [control]="changeAddressForm['controls']['state']"></app-form-input-error>
            </div>
            <div class="dropdown" *ngIf="focusState">
              <div class="dropdown-options">
                <a class="dropdown-item" *ngFor="let x of cityList | searchFilter:addressDetails['stateDesc']:'stateDesc'"
                  (click)="populateCityStateRegionField(x, 'state')">
                  {{joinString([x['townDesc'], x['cityDesc'], x['stateDesc']])}}
                </a>
              </div>
            </div>
          </div>
          <div class="input-group select-input" *ngIf="!hideFormInput['city']">
            <div class="label">
              <label for="city" class="font-light-xssmall">{{'frm_city'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('city')">*</span>
            </div>
            <div class="value">
              <input name="city" type="text" id="city" placeholder="{{'plhd_sl_city'|translate}}"
                [(ngModel)]="addressDetails['cityDesc']" formControlName="city" autocomplete="off"
                (blur)="blurCity()" (focus)="focusCity=true" />
              <app-form-input-error [control]="changeAddressForm['controls']['city']"></app-form-input-error>
            </div>
            <div class="dropdown" *ngIf="focusCity">
              <div class="dropdown-options">
                <a class="dropdown-item"
                  *ngFor="let x of cityList | searchFilter:addressDetails['cityDesc']:'cityDesc':[(
                  addressDetails['stateCode'])?addressDetails['stateCode']:null, null]"
                  (click)="populateCityStateRegionField(x, 'city')">{{joinString([x['townDesc'], x['cityDesc'], x['stateDesc']])}}</a>
              </div>
            </div>
          </div>
          <div class="input-group select-input" *ngIf="!hideFormInput['town']">
            <div class="label">
              <label for="region" class="font-light-xssmall">{{'frm_town'|translate}}</label>
              <span class="mandatory font-danger-small" *ngIf="isRequiredField('region')">*</span>
            </div>
            <div class="value">
              <input name="region" type="text" id="region" placeholder="{{'plhd_sl_town'|translate}}"
              [(ngModel)]="addressDetails['areaDesc']" formControlName="town" autocomplete="off"
                (blur)="blurTown()" (focus)="focusTown=true" />
              <app-form-input-error [control]="changeAddressForm['controls']['town']"></app-form-input-error>
            </div>
            <div class="dropdown" *ngIf="focusTown">
              <div class="dropdown-options">
                <a class="dropdown-item" *ngFor="let x of cityList| searchFilter:addressDetails['areaDesc']:'townDesc':[(
                addressDetails['stateCode'])?addressDetails['stateCode']:null, (
                addressDetails['cityCode'])?addressDetails['cityCode']:null]"
                  (click)="populateCityStateRegionField(x, 'region')">{{joinString([x['townDesc'], x['cityDesc'], x['stateDesc']])}}</a>
              </div>
            </div>
          </div>
        </form>
        <div class="link-all-checkbox">
            <input type="checkbox" [(ngModel)]="linkAddress" (change)="print()"/>
            <label class="no-margin">{{'apply_to_all_linked_rec'| translate}}</label>
        </div>
    </div>
    <div class="footer">
      <button class="primary-button" (click)="updateAddress()" *ngIf="!isEditPatient"
        [disabled]="changeAddressForm.invalid">{{'lbl_add_address'|translate}}</button>
      <button class="primary-button" (click)="updateAddress()" *ngIf="isEditPatient"
        [disabled]="changeAddressForm.invalid">{{'lbl_update_address'|translate}}</button>
    </div>
  </div>
</div>
