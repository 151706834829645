import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { REPO_TOKEN, USER_TOKEN } from '@string';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-queue-preview',
  templateUrl: './queue-preview.component.html',
  styleUrls: ['./queue-preview.component.scss'],
  providers:[TranslatePipe]
})
export class QueuePreviewComponent {

  public appointment: any;
  public patient: any;
  public state: string = null;
  public langSet: boolean = false;
  public curLang: string = 'en';
  private key: any;
  private fetchingAppt: boolean = false;
  public triangleArr=[1,2,3,4,5,6,7,8,9,11,12,13,14,15];
  public queueNo: any;
  public mappingFloorInfo: any;//={floor:"1",waitingArea:"2",room:"1"};
  public viewType:any;
  public mappingFloorControls:any;
  constructor(private user:UserService,private activatedRoute: ActivatedRoute,private cookie:CookieService){
   for(let i=1;i<100;i++){
    this.triangleArr.push(i)
   }
  }

  ngOnInit(): void {
    this.user.clearStorage()
    const params = this.activatedRoute.snapshot.params;
    let qplang = this.activatedRoute.snapshot.queryParams.lang;
    this.user.getLanguages().subscribe(data => {
      if (!qplang) qplang = 'en';
      let lang = data.find(d => d['localeCode'] == qplang);
      if (!lang) lang = data[0];
      this.user.setCurrentLanguage(lang);
      this.user.getLabels(true).subscribe(labels => {
        this.user.setLocaleLabels(labels);
        this.curLang = qplang;
        this.langSet = true;
      })
    })
    console.log(params);
    // this.findCallNumber()
    const key = params['key'];
    this.key = key;
    if (key != 'NOTVALID') {
      this.fetchingAppt = true;
      this.validateAppointment(key);
    } else {
      this.state = 'INVALID'
    }
  }

  private validateAppointment(key) {
    this.user.validatePatientAppointment(key).subscribe(data => {
      console.log(data);
      this.viewType=data.type;
      if(data.type=='SPEAK'){
        this.queueNo=data.queue;
      }else{
        const appointment = data['appointment']
        this.patient = data['patientInfo']
        const repoInfo = data['repoInfo']
        const token = data['token']
        this.handleAppointmentDetails(appointment, repoInfo, token);
        this.fetchingAppt = false
        if (!appointment || !appointment.appointmentId) {
          this.state = 'INVALID'
        }
        // this.queueNo=1;
        if(!appointment.queueNo && appointment.visitStatus){
          // console.log('no queue')
          this.getQueueNo()
        }
        if(appointment.visitStatus){
          this.mappingFloorInfo=appointment.visitStatus.mappingFloorInfo;
          this.mappingFloorControls = appointment.visitStatus.mappingFloorControls;
        }
      }
    }, err => {
      this.state = 'INVALID'
      this.fetchingAppt = false
    })
  }

  private handleAppointmentDetails(appointment, repoInfo, token) {
    this.setStorage(USER_TOKEN, token['token']);
    this.setStorage(REPO_TOKEN, repoInfo['token']);
    this.user.setRepoUrl(repoInfo['url']);
    this.appointment = appointment;
    // this.managePolicies();
  }
  public getQueueNo() {
    let body={
      facilityID :this.appointment.facilityId,
      patientId:this.appointment.patientId,
      encounterId:this.appointment.visitStatus.encounterId,
      apptRefnumb:this.appointment.appointmentId    
    }
    this.user.getQueueNo(body).subscribe(data=>{
      // console.log('queue onfo',data)
      if(data.status=='SUCCESS'){
        this.appointment.queueNo=data.data;
        this.queueNo=data.data;
      }
    },err=>{
    })
  }
  public getFloorName(data) {
    return data[this.user.getCurrentLanguage()]
  }

  public setStorage(key, value) {
    this.cookie.set(key, value);
  }

}
