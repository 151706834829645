import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  @ViewChild('dt1') owlDateTime;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideTime(this.section);
    super.getSelectedTime(this.section);
    super.complete(this.section);
  }

  onChange() {
    this.getSelectedTime(this.section);
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyup(event: KeyboardEvent) {
    let activeElement = document['_activeElement'];
    if (!activeElement.closest('app-section'))
      return;
    if (event.keyCode === 32 && this.section['domainCode'] === activeElement.closest('app-section')['id'].split('#')[1])
      this.owlDateTime.open();
  }

}
