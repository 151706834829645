<div id="appt-dtl-card">
  <div class="card">
    <div class="card-head" *ngIf="type!='REVIEW' && type!='DETAILS'">
        <div class="conatiner border-bottom">
        <p class="subtitle m-btm">{{'appt_dtl_ttl_appt_type'|translate:'LABELS'}}</p>
        <span class="font-default-small bold">
            <span class="appt-type" *ngIf="apptInfo['appointmentType'] === 'VIDEO' && !apptInfo['isOnspot']" >{{'appt_dtl_lbl_video_consultation'|translate:'LABELS'}}</span>
            <span class="appt-type" *ngIf="apptInfo['appointmentType'] === 'VIDEO' && apptInfo['isOnspot']" >{{'onspot_req_ttl_video_consult_onspot'|translate:'LABELS'}}</span>
            <span class="appt-type" *ngIf="apptInfo['appointmentType'] != 'VIDEO' && apptInfo['appointmentType'] != 'HC' && apptInfo['appointmentType'] != 'HP'" >{{'appt_dtl_lbl_clinic_consultation'|translate:'LABELS'}}</span>
            <span class="appt-type" *ngIf="apptInfo['appointmentType'] == 'HC'">{{'appt_dtl_lbl_home_care_consultation'|translate:'LABELS'}}</span>
            <span  class="appt-type" *ngIf="apptInfo['appointmentType'] == 'HP'">{{'appt_dtl_lbl_health_pkg'|translate:'LABELS'}}</span>
        </span>
        </div>
        <div class="card-content">
        <div>
            <p class="subtitle lbl">{{'appt_dtl_ttl_appt_for'|translate:'LABELS'}}</p>
            <div class="patient-details border-bottom">
            <div class="image">
                <app-person-avatar [url]="apptInfo['imageurl']"> </app-person-avatar>
            </div>
            <div class="partitioner-content">
                <div class="title">
                    {{apptInfo['personName'] ? apptInfo['personName']: (apptInfo['personname'] ? apptInfo['personname'] : "") }}
                </div>
                <div class="font-light-small">
                {{apptInfo['uhid']}}
                </div>
            </div>
            <div class="prim-ttl-txt bold a-center"  *ngIf="isEditApplicable" (click)="switchAction('PATIENT')">
                <img src="./assets/icons/health-package/Switch.svg">
            </div>
            </div>
        </div>
        <div *ngIf="apptInfo['appointmentType'] != 'HP'">
            <p class="subtitle lbl">{{'appt_dtl_ttl_consult_doc'|translate:'LABELS'}}</p>
            <div class="partitioner-details border-bottom">
            <div class="image">
                <app-practitioner-avatar
                [url]="(apptInfo['resourceId']|partitionerDetails:apptInfo['facilityId']:'resourceAvatarURL')">
                </app-practitioner-avatar>
            </div>
            <div class="partitioner-content">
                <div class="title">
                    <ng-container *ngIf="apptInfo.practitionerLocalName">
                        {{apptInfo.practitionerLocalName | translate : translateType : 'name'}}
                      </ng-container>
                      <ng-container *ngIf="!apptInfo.practitionerLocalName && apptInfo.practitionerName">
                        {{apptInfo.practitionerName}}
                      </ng-container>
                      <ng-container *ngIf="!apptInfo.practitionerLocalName && !apptInfo.practitionerName">
                        {{(apptInfo['resourceId']|partitionerDetails:apptInfo['facilityId']:'resourceName'| translate : translateType : 'name') || 'Doctor'}}
                      </ng-container>
                </div>
                <div class="font-light-small">
                    {{apptInfo['resourceId']|partitionerDetails: apptInfo['facilityId']:'specialityId' | specialityDetails: apptInfo['facilityId']:'specialityName'| translate : translateType : 'name'}}
                </div>
            </div>
            <div class="prim-ttl-txt bold a-center" *ngIf="scheduleCost">
                {{ scheduleCost['cc_cost'].value }} {{currency_code}} 
            </div>
            </div>
        </div>
        <div class="conatiner" *ngIf="apptInfo['appointmentType'] != 'HP'">
            <p class="subtitle m-btm">
                <span>{{'appt_dtl_ttl_appt_date_loc'|translate:'LABELS'}}</span>
            </p>
            <span class="font-default-small bold">
                <span  class="appt-date">
                    <app-appt-time type="DETAIL1" [time]="apptInfo['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'ddd DD MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: apptInfo['facilityId'])" [facilityId]="apptInfo['facilityId']"></app-appt-time>

                </span>
                <p class="r-mgn">
                    <span *ngIf="apptInfo['appointmentType'] != 'VIDEO' && apptInfo['appointmentType'] != 'HC'" >{{apptInfo['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}</span>
                    <span  *ngIf="apptInfo['appointmentType'] == 'HC'">{{apptInfo['homeCareServices']['location']['address'] }}</span>
                </p>
            </span>
        </div>
        <ng-container *ngIf="apptInfo['appointmentType'] == 'HP'">
            <div >
                <p class="subtitle lbl">{{'appt_dtl_ttl_services'|translate:'LABELS'}}</p>
                <div class="partitioner-details border-bottom">
                <div class="partitioner-content no-padding">
                    <div class="title">
                        <ng-container *ngIf="apptInfo.selectedPkg">
                            {{ apptInfo.selectedPkg.name | translate : 'LANGARRAY' : 'name'}}
                        </ng-container>
                    </div>
                    <!-- <div class="font-light-small">
                        {{apptInfo['resourceId']|partitionerDetails: apptInfo['facilityId']:'specialityId' | specialityDetails: apptInfo['facilityId']:'specialityName'| translate : translateType : 'name'}}
                    </div> -->
                </div>
                <div class="prim-ttl-txt bold a-center">
                    <span *ngIf="apptInfo.selectedFacility.controls.discountCharge">{{ apptInfo.selectedFacility.controls.discountCharge }} {{currency_code}} </span>
                    <span *ngIf="!apptInfo.selectedFacility.controls.discountCharge">{{ apptInfo.selectedFacility.controls.charge }} {{currency_code}} </span>
                </div>
                </div>
            </div>
            <div class="conatiner border-bottom" >
                <p class="subtitle m-btm">{{'appt_dtl_ttl_location'|translate:'LABELS'}}</p>
                <div class="font-default-small spc-btw bold">
                    <div class="r-mgn">
                        <span >{{apptInfo['selectedFacility']['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}</span>
                    </div>
                    <div class="a-center" *ngIf="isEditApplicable" (click)="switchAction('FACILITY')">
                        <img src="./assets/icons/health-package/edit.svg">
                    </div>
                </div>
            </div>
            <div class="conatiner"  >
                <p class="subtitle m-btm">{{'appt_dtl_ttl_appt_date'|translate:'LABELS'}}</p>
                <div class="font-default-small spc-btw bold">
                    <div class="r-mgn">
                        <span *ngIf="apptInfo['appointmentType'] == 'HP'">{{ apptInfo.selectedSlot.slot.date | moment : 'ddd DD MMM YYYY, '}} {{ apptInfo.selectedSlot.slot.slot | moment:'hh:mm a': 'HH:mm' }}</span>
                    </div>
                    <div class="a-center" *ngIf="isEditApplicable" (click)="switchAction('SLOT')">
                        <img src="./assets/icons/health-package/edit.svg">
                    </div>
                </div>
            </div>
        </ng-container>
        </div>
    </div>
    <div class="card-head" *ngIf="type=='REVIEW' || type=='DETAILS'">
      <div class="conatiner border-bottom" *ngIf="type=='DETAILS'">
        <p class="subtitle m-btm">{{'appt_dtl_ttl_appt_type'|translate:'LABELS'}}</p>
        <span class="font-default-small bold">
            <span  class="appt-type" *ngIf="apptInfo['appointmentType'] === 'VIDEO'" >{{'appt_dtl_lbl_video_consultation'|translate:'LABELS'}}</span>
            <span  class="appt-type" *ngIf="apptInfo['appointmentType'] != 'VIDEO' && apptInfo['appointmentType'] != 'HC' && apptInfo['appointmentType'] != 'HP'" >{{'appt_dtl_lbl_clinic_consultation'|translate:'LABELS'}}</span>
            <span   class="appt-type" *ngIf="apptInfo['appointmentType'] == 'HC'">{{'appt_dtl_lbl_home_care_consultation'|translate:'LABELS'}}</span>
            <span   class="appt-type" *ngIf="apptInfo['appointmentType'] == 'HP'">{{'appt_dtl_lbl_health_pkg'|translate:'LABELS'}}</span>
        </span>
      </div>
      <div class="card-content">
        <div>
            <p class="subtitle lbl">{{'appt_dtl_ttl_appt_for'|translate:'LABELS'}}</p>
            <div class="patient-details border-bottom">
            <div class="image">
                <app-person-avatar [url]="apptInfo.in['patient']['image']"> </app-person-avatar>
            </div>
            <div class="partitioner-content">
                <div class="title">
                    {{apptInfo.in['patient']['name']}}
                </div>
                <div class="font-light-small">
                    <ng-container *ngIf="!linkWithoutRelation">{{apptInfo.in['patient']['relation'] | translate : 'RELATION'}}</ng-container>
                    <ng-container *ngIf="linkWithoutRelation">{{apptInfo.in['patient']['id']}}</ng-container>
                </div>
            </div>
            <div class="prim-ttl-txt bold a-center" *ngIf="isEditApplicable"  (click)="switchAction('select_patient')">
                <img src="./assets/icons/health-package/Switch.svg">
            </div>
            </div>
        </div>
        <div *ngIf="apptInfo['appointmentType'] != 'HP'">
            <p class="subtitle lbl">{{'appt_dtl_ttl_consult_doc'|translate:'LABELS'}}</p>
            <div class="partitioner-details border-bottom">
            <div class="image">
                <app-practitioner-avatar
                [url]="apptInfo.in['practitioner']['resourceAvatarURL']">
                </app-practitioner-avatar>
            </div>
            <div class="partitioner-content">
                <div class="title">
                    <ng-container>
                        {{apptInfo.in['practitioner']['name'] | translate : translateType : 'name'}}
                      </ng-container>
                </div>
                <div class="font-light-small">
                  <ng-container *ngIf="apptInfo.in['speciality']['facilityId']">
                    {{ apptInfo.in['speciality']['id'] | specialityDetails: (apptInfo.in['speciality']['facilityId']?apptInfo.in['speciality']['facilityId'] :null) :'specialityName'| translate : translateType : 'name'}}
                  </ng-container>
                  <ng-container *ngIf="!apptInfo.in['speciality']['facilityId'] && apptInfo.in['speciality']['desc']">
                    {{apptInfo.in['speciality']?apptInfo.in['speciality']['desc']:''}}
                  </ng-container>
                </div>
            </div>
            <div class="prim-ttl-txt bold a-center"  *ngIf="scheduleCost && scheduleCost['cc_cost']">
                {{ scheduleCost['cc_cost'].value }} {{currency_code}} 
            </div>
            </div>
        </div>
        <div class="conatiner" *ngIf="apptInfo['appointmentType'] != 'HP'" style="position: relative;">
            <ng-container *ngIf="!fromSlotBlockPrompt">
                <p class="subtitle m-btm">
                    <span>{{'appt_dtl_ttl_appt_date_loc'|translate:'LABELS'}}</span>
                </p>
                <span class="font-default-small bold">
                    <span  class="appt-date">
                        <app-appt-time type="DETAIL1" [time]="apptInfo.in['slot']['date']+' '+apptInfo.in['slot']['slot']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'ddd DD MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: apptInfo.in['facility']['facilityId'])" [facilityId]="apptInfo.in['facility']['facilityId']"></app-appt-time>
                    </span>
                    <p class="r-mgn">
                        <span *ngIf="apptInfo['appointmentType'] != 'VIDEO' && apptInfo['appointmentType'] != 'HC'" >{{apptInfo.in['facility']?(apptInfo.in['facility']['name'] | translate : translateType : 'name'):''}}</span>
                        <span  *ngIf="apptInfo['appointmentType'] == 'HC'">{{apptInfo['homeCareServices']['location']['address'] }}</span>
                    </p>
                </span>
                <div class="policy-expire-img-con" (click)="openPolicyExpirePop()" *ngIf="showpolicyInfo">
                    <img src="./assets/icons/error-info.svg">
                </div>
            </ng-container>
            <div *ngIf="fromSlotBlockPrompt" class="slot-with-block-err-con">
                <div>
                    <p class="subtitle m-btm">
                        <span>{{'appt_dtl_ttl_appt_date_loc'|translate:'LABELS'}}</span>
                    </p>
                    <span class="font-default-small bold">
                        <span  class="appt-date">
                            <app-appt-time type="DETAIL1" [time]="apptInfo.in['slot']['date']+' '+apptInfo.in['slot']['slot']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'ddd DD MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: apptInfo.in['facility']['facilityId'])" [facilityId]="apptInfo.in['facility']['facilityId']"></app-appt-time>

                        </span>
                        <p class="r-mgn">
                            <span *ngIf="apptInfo['appointmentType'] != 'VIDEO' && apptInfo['appointmentType'] != 'HC'" >{{apptInfo.in['facility']?(apptInfo.in['facility']['name'] | translate : translateType : 'name'):''}}</span>
                            <span  *ngIf="apptInfo['appointmentType'] == 'HC'">{{apptInfo['homeCareServices']['location']['address'] }}</span>
                        </p>
                    </span>
                </div>
                <div class="error-img-con">
                    <img src="./assets/icons/error.svg" style="width: 1.3em;"> 
                </div>
            </div>
        </div>
        <div class="" [ngClass]="{ 'default-bg':  !enterReferral , 'referral-card': enterReferral}" *ngIf="isReferralApplicable">
            <p class="prim-ttl-txt " (click)="enterReferral = !enterReferral" *ngIf="!enterReferral">{{'appt_dtl_ttl_enter_referral_code'|translate:'LABELS'}}</p>
            <ng-container *ngIf="enterReferral">
                <p class="subtitle lbl">{{'appt_dtl_ttl_referral_code'|translate:'LABELS'}}</p>
                <div class="referral-input">
                    <input class="ref-input" [type]="refControl?refControl.type:'text'" (input)="onTextInput($event)" placeholder="{{ 'appt_dtl_placeholder_enter' | translate}}" [(ngModel)]="result.referralCode" (input)="competeAction()"
                    [minLength]="refControl?refControl.minLength:null"
                    [maxLength]="refControl?refControl.maxLength:null"/>
                    <div class="close float-right closeicon cursor-pointer" (click)="removeReferral()"
                        *ngIf="result.referralCode">
                        <img src="./assets/icons/close_grey.svg">
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="apptInfo['appointmentType'] == 'HP'">
            <div >
                <p class="subtitle lbl">{{'appt_dtl_ttl_services'|translate:'LABELS'}}</p>
                <div class="partitioner-details border-bottom">
                <div class="partitioner-content no-padding">
                    <div class="title">
                        <ng-container *ngIf="apptInfo.selectedPkg">
                            {{ apptInfo.selectedPkg.name | translate : 'LANGARRAY' : 'name'}}
                        </ng-container>
                    </div>
                    <!-- <div class="font-light-small">
                        {{apptInfo['resourceId']|partitionerDetails: apptInfo['facilityId']:'specialityId' | specialityDetails: apptInfo['facilityId']:'specialityName'| translate : translateType : 'name'}}
                    </div> -->
                </div>
                <div class="prim-ttl-txt bold a-center">
                    <span *ngIf="apptInfo.selectedFacility.controls.discountCharge">{{ apptInfo.selectedFacility.controls.discountCharge }} {{currency_code}} </span>
                    <span *ngIf="!apptInfo.selectedFacility.controls.discountCharge">{{ apptInfo.selectedFacility.controls.charge }} {{currency_code}} </span>
                </div>
                </div>
            </div>
            <div class="conatiner border-bottom" >
                <p class="subtitle m-btm">{{'appt_dtl_ttl_location'|translate:'LABELS'}}</p>
                <div class="font-default-small spc-btw bold">
                    <div class="r-mgn">
                        <span >{{apptInfo['selectedFacility']['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}</span>
                    </div>
                    <div class="a-center" *ngIf="isEditApplicable" (click)="switchAction('FACILITY')">
                        <img src="./assets/icons/health-package/edit.svg">
                    </div>
                </div>
            </div>
            <div class="conatiner"  >
                <p class="subtitle m-btm">{{'appt_dtl_ttl_appt_date'|translate:'LABELS'}}</p>
                <div class="font-default-small spc-btw bold">
                    <div class="r-mgn">
                        <span *ngIf="apptInfo['appointmentType'] == 'HP'">

                            <app-appt-time type="DETAIL1" [time]="apptInfo.in['slot']['date']+' '+apptInfo.in['slot']['slot']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'ddd DD MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: apptInfo['selectedFacility']['facilityId'])" [facilityId]="apptInfo['selectedFacility']['facilityId']"></app-appt-time>
                        </span>
                    </div>
                    <div class="a-center" *ngIf="isEditApplicable" (click)="switchAction('SLOT')">
                        <img src="./assets/icons/health-package/edit.svg">
                    </div>
                </div>
            </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>