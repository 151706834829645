<div *ngIf="!hideOnCtx && false" id="health-records-component" >
  <div class="header-component" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header></app-header>
  </div>
  <div class="health-records-component">
    <div class="select-patient">
      <app-hr-patient-select (choosePatient)="choosePatient($event)" [selectedPatient]="selectedPatient"
        [contextPatientId]="context.patientId"></app-hr-patient-select>
    </div>
    <div class="visit-section">
      <div class="visit-history" [appRemoveFeatureOff]="'FT_APP_HR_VW_VST_HIS'">
        <app-hr-visit-history (chooseVisit)="chooseVisit($event)" [selectedPatient]="selectedPatient"
          [selectedVisit]="selectedVisit" (getFeedback)="getFeedBackForAppt($event)">
        </app-hr-visit-history>
      </div>
      <div class="visit-details" [appRemoveFeatureOff]="'FT_APP_HR_VISIT_SECTION'">
        <app-hr-visit-details [selectedVisit]="selectedVisit" [selectedPatient]="selectedPatient"
          (selectReport)="chooseReport($event)" (clearVisit)="clearVisit()" (openDocsUpload)="openDocsUpload($event)" [isCtx]="isCtx" [context]="context" [refresh]="refresh.asObservable()">
        </app-hr-visit-details >
      </div>
    </div>
    <app-footer [appRemoveFeatureOff]="'SHOW_APP_FOOTER'" class="sm-hidden"></app-footer>
  </div>
</div>

<div class="health-records-container">
  <div class="header-componen" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header></app-header>
  </div>

  <div class="health-records-wrapper">
    <div class="selected-patient-banner" *ngIf="isMobileView && selectedPatient">
      <p class="selected-patient">{{selectedPatient['personname']}} <span class="selected-patient-info">{{selectedPatient['uhid']}}</span></p>
      <img class="choose-patinet" src="assets/icons/Book_new/Switch.svg" alt="" (click)="selectedPatient = null">
    </div>
    <div class="health-records-header" *ngIf="!isMobileView || selectedPatient">
      <div class="health-records-title sm-hidden">{{'Health_records' | translate}}</div>
      <div class="health-records-tabs">
        <div class="toggle-option">
          <ng-container *ngFor="let tab of viewTabs">
            <div class="option" [appRemoveFeatureOff]="tab['flag']" [ngClass]="{selected: selectedTab == tab['code']}"
              (click)="selectedTab = tab['code']">
              {{tab['label'] | translate}}</div>
          </ng-container>
        </div>
      </div>
      <div class="upload-documents-wrapper" *ngIf="selectedTab == 'REPORTS'" (click)="openDocsUpload({type:'OTH'})">
        <button class="button-primary">{{'Upload_documents' | translate}}</button>
      </div>
    </div>
    <div class="health-records-content">
      <div class="select-patient-wrapper" *ngIf="!isMobileView || !selectedPatient">
        <p class="pat-sel-hdr" *ngIf="isMobileView">{{  'Select_patient' | translate }}</p>
        <app-hr-patient-select (choosePatient)="choosePatient($event)" [isMobileView]="isMobileView" [selectedPatient]="selectedPatient"
          [contextPatientId]="context.patientId"></app-hr-patient-select>
      </div>
      <div class="records-history-wrapper" *ngIf="selectedPatient">

        <ng-container [ngSwitch]="selectedTab">
          <app-hr-visit-summary *ngSwitchCase="'SUMMARY'" (chooseVisit)="chooseVisit($event)"
            [selectedPatient]="selectedPatient">
          </app-hr-visit-summary>
          <app-hr-visit-history *ngSwitchCase="'VISITS'" (chooseVisit)="chooseVisit($event)"
            [selectedPatient]="selectedPatient"
            (getFeedback)="getFeedBackForAppt($event)" [visitId]="visitId">
          </app-hr-visit-history>
          <app-hr-visit-report *ngSwitchCase="'REPORTS'" [selectedPatient]="selectedPatient"
            (selectReport)="chooseReport($event)" (openDocsUpload)="openDocsUpload($event)"
            [refresh]="refresh.asObservable()">
          </app-hr-visit-report>
          <app-hr-visit-prescription *ngSwitchCase="'PRES'" [selectedPatient]="selectedPatient"
            (selectReport)="chooseReport($event)" (openDocsUpload)="openDocsUpload({type: 'PRE' })"
            [refresh]="refresh.asObservable()"></app-hr-visit-prescription>
        </ng-container>
      </div>
    </div>
  </div>
  <app-footer [appRemoveFeatureOff]="'SHOW_APP_FOOTER'" class="sm-hidden"></app-footer>
</div>


<app-hr-report-clobformat 
  *ngIf="isClobReport" 
  [report]="selectedReport['report']" 
  [reportData]="selectedReport['data']"
  [selectedPatient]="selectedPatient"
  [isCommonView]="isCommonView"
  (close)="closeClobReport()"
  (initDownload)="initDownload($event)"
  [qrcodeUrl]="qrCodeUrl"
  (toggleQrCode)="toggleQrCode()"
></app-hr-report-clobformat>
<app-hr-report-formformat 
  *ngIf="isFormReport" 
  [report]="selectedReport['report']" 
  [reportData]="selectedReport['data']"
  [selectedPatient]="selectedPatient" 
  [isCommonView]="isCommonView"
  (close)="closeFormReport()" 
  (initDownload)="initDownload($event)"
  [qrcodeUrl]="qrCodeUrl"
  (toggleQrCode)="toggleQrCode()"
  >
</app-hr-report-formformat>
<app-hr-report-jsonformat 
  *ngIf="isJsonReport" 
  [report]="selectedReport['report']" 
  [reportData]="selectedReport['data']"
  [patientMetaData]="selectedReport['patientMetaData']"
  [selectedPatient]="selectedPatient" 
  [isCommonView]="isCommonView"
  (close)="closeJsonReport()" 
  (initDownload)="initDownload($event)"
  [qrcodeUrl]="qrCodeUrl"
  (toggleQrCode)="toggleQrCode()"
  >
</app-hr-report-jsonformat>
<app-hr-document-upload *ngIf="isDocumentUpload" [selectedPatient]="selectedPatient" [uploadType]="uploadType"
  (close)="closeDocsUpload()">
</app-hr-document-upload>
<app-hr-feedback-form *ngIf="isFeedBackOpen" [selectedAppt]="feedBackAppt" (close)="closeFeedback($event)"></app-hr-feedback-form>
<app-hr-report-pdfformat
  *ngIf="isPdfReport"
  [report]="pdfReport"
  [isCommonView]="isCommonView"
  (initDownload)="downloadPersonalReport($event)"
  (close)="closePdfReport()"
  [qrcodeUrl]="qrCodeUrl"
  (toggleQrCode)="toggleQrCode()"
></app-hr-report-pdfformat>

<app-image-preview [url]="imageReport.url" [title]="imageReport.header.note" *ngIf="isImageReport" (close)="closeImagePreview()"></app-image-preview>

<app-document-preview [url]="docReport.url" [title]="docReport.header.note" *ngIf="isDocReport" (close)="closeDocPreview()"></app-document-preview>

<div class="qrPreview" *ngIf="expandQrCode">
  <!-- <ngx-qrcode [elementType]="'url'" [value]="qrcodeUrl.location" cssClass="abclass" errorCorrectionLevel="L">
  </ngx-qrcode> -->
  <img [src]="qrCode" class="qr-code" alt="">
  <div (click)="expandQrCode = false" class="float-right closeicon pointer close-icon">
    <img class="type-image" src="./assets/icons/close_white.svg">
  </div>
</div>
<app-hr-visit-details [selectedVisit]="selectedVisit['visit']" [selectedPatient]="selectedPatient" [isCommonView]="isCommonView" [isCtx]="isCtx" [context]="context" 
[qrCodeUrl]="qrCodeUrl" [refresh]="refresh.asObservable()" *ngIf="selectedVisit" [tab]="selectedVisit['tab']"
(selectReport)="chooseReport($event)" (clearVisit)="clearVisit()" (openDocsUpload)="openDocsUpload($event)" [reportId]="reportId"></app-hr-visit-details>