import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrReportPdfformatComponent } from './hr-report-pdfformat.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';



@NgModule({
  declarations: [HrReportPdfformatComponent],
  imports: [
    CommonModule, PdfViewerModule, PipesModule, DirectiveModule, NgxQRCodeModule
  ],
  exports: [
    HrReportPdfformatComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HrReportPdfformatModule { }
