import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSpecialityComponent } from './search-speciality.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { NodataModule } from '../nodata/nodata.module';

@NgModule({
  declarations: [SearchSpecialityComponent],
  imports: [
    CommonModule, FormsModule, PipesModule, PersonAvatarModule, NodataModule
  ],
  
  exports:[SearchSpecialityComponent]
})
export class SearchSpecialityModule { }
