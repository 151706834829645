import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PackageService } from '../services/package.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-my-packages',
  templateUrl: './my-packages.component.html',
  styleUrls: ['./my-packages.component.scss']
})
export class MyPackagesComponent {
  @Output() close = new EventEmitter();
  linkedPatients: any;
  selectedTab: string;
  packageList: any[];
  healthPackages: any;
  tabs = PACKAGETABS;
  selectedPackage: any;
  constructor(private packageService:PackageService, private user:UserService) {}

  ngOnInit() {
    this.user.getUserInfo('linkedPatients').subscribe(data => {
      this.linkedPatients = data
      this.user.getUpdateInfo('directives').subscribe(data => {
        this.healthPackages = data.userDefined.find(def => def.directiveGroup == 'HOSPSERV')
        this.changeActiveTab(this.tabs[0]);
      });
    });
  }

  getAllPackages() {
    let inparam={
      "patientIds":this.linkedPatients.map(p => p['uhid']),
    }

    this.packageService.getPackages(inparam).subscribe(data => {
      console.log(data);
    })
  }


  public changeActiveTab(tab) {
    this.selectedTab = tab['id'];

    //Mock data - for dev purpose
    // this.packageList = pkg
    this.packageList=[]
    if(this.selectedTab=='CALNCELLED')
      this.getActivePackages('X')
    else if(this.selectedTab=='COMPLETED')
      this.getActivePackages('C')
    else if(this.selectedTab=='UPCOMING')
      this.getActivePackages('A') 
  }
  public getActivePackages(status){
    let inparam={
      "patientIds":this.linkedPatients.map(p => p['uhid']),
      "status":status

    }
    this.packageService.getPackages(inparam).subscribe(data=>{
      let healthPackages = this.healthPackages;
      console.log('my packages--',data,this.healthPackages)
      data.map((p)=>{
        healthPackages['concepts'].forEach((c)=>{
          if(p['packageCode']==c['code']){
            p['_healthPackage'] = c;
            if(c['detail'] && c['detail'].length>0){
              c['detail'].forEach((d)=>{
                if(d['locale']==this.user.getCurrentLanguage())
                  p['imageURL']=d['imageURL']
              })
            }
          }
        })
      })
      this.packageList=data;
      // setTimeout(()=>{
      //   if(this.packageList.length>0 && this.selectedPackageToDetail){
      //     this.packageList.forEach((p)=>{
      //       if(p['packageCode']==this.selectedPackageToDetail['code'] && p['patientId']==this.selectedPatientToDetail['patientId']){
      //         this.navigateFrom.next(p);
      //       }
      //     })
      //   }
      // },2000)
    })
  }
  
  public openPackageDetails(p) {
    this.selectedPackage = p;
    console.log('selectedPackage', this.selectedPackage);
  }

  closeModal() {
    this.close.emit();
  }

  onClose(data) {
    if(data) {
      this.selectedPackage =  null
      this.closeModal();
    }else {
      this.selectedPackage =  null
    }
  }
}

export const PACKAGETABS = [
	{
	  id: "UPCOMING",
	  label:"Active",
	  permission:'ENABLEUPCOMING',
	  display:true
	},
	{
		id: "COMPLETED",
		label:"Completed",
		permission:'ENABLECOMPLETED',
		display:true
	}, 
	{
	  id: "CALNCELLED",
	  label:"Cancelled",
	  permission : 'ENABLECANCELLED',
	  display:true
	}
]