import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import * as moment from "moment";
import { TranslatePipe } from '../pipes/translate.pipe';
import { AppointmentService } from '../services/appointment.service';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { apptFlowType, visitType } from '@type';
import * as _ from 'lodash';

@Component({
  selector: 'app-express-clinic-appointment-review',
  templateUrl: './express-clinic-appointment-review.component.html',
  styleUrls: ['./express-clinic-appointment-review.component.scss'],
  providers: [TranslatePipe]
})
export class ExpressClinicAppointmentReviewComponent implements OnInit {
  @Input() _input: any;
  @Input() visitType: visitType;
  @Input() flowType: apptFlowType;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  public facility: any;
  public speciality: any;
  public practitioner: any;
  public patient: any;
  public currency_code: any;
  public paymentGatewayUrl: any;
  public totalCost: number;
  public practDetails: object;
  public totalServiceTime: any;
  public clinicMobileNumber: any;
  public position: object;
  public clinicLatLng: object;
  public lang: string;
  public serviceToOrder: Array<object>;
  public selectedServices: Array<object>;
  public slotDate: any;
  public inputObj: object;
  public translateType = 'LOCALE';

  constructor(private translatePipe: TranslatePipe, private apptService: AppointmentService, private paymentService: PaymentService, private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.selectedServices = new Array<object>();
    this.serviceToOrder = new Array<object>();
  }

  ngOnInit(): void {
    this.totalCost = this._input['in']['service']['totalAmount'];
    this.practDetails = this._input['in']['inputService']['practDetails'][0];
    this.totalServiceTime = moment.duration(this._input['in']['service']['totalTime'], 'minutes');
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.patient = this._input['in']['patient'];
    this.slotDate = this.user.getMoment(this._input['in']['slot']['date']).format('DD MMM YYYY');
    this.selectedServices = this._input['in']['service']['services'];
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    });
    this.clinicMobileNumber = this._input['in']['facility']['mobileNo'];
    this.position = this._input['in']['facility']['position'];
    this.clinicLatLng = this._input['in']['facility']['facilityLocation'];
    if(this.flowType!='RESCHEDULE') {
      this.constructInputObject();
      this.modifyStructForServicesToOrder();
    }
    console.log('appointment review',this._input);
  }

  public constructInputObject() {
    this.inputObj = {};
    this.inputObj = {
      patientId: this._input['in']['patient']['id'],
      practId: this.practDetails['practitionerId'],
      facility: {
        facilityId: this._input['in']['facility']['facilityId'],
        clinicCode: this._input['in']['facility']['clinicCode'],
        name: this._input['in']['facility']['name'],
        address: this._input['in']['facility']['address'],
        distance: this._input['in']['facility']['distance'],
        position: this._input['in']['facility']['position']['lat']+','+this._input['in']['facility']['position']['lng'],
        facilityLocation: this._input['in']['facility']['facilityLocation']['lat']+','+this._input['in']['facility']['facilityLocation']['lng']
      },
      service: this._input['in']['service']['services'].map(data=> {delete data['isSelectable'];delete data['selected'];return data}),
      slot: {
        name: this._input['in']['slot']['name'],
        date: this._input['in']['slot']['date']+' '+this._input['in']['slot']['slot']['slot'],
        slotsNeeded: this._input['in']['slot']['slotsNeeded']
      }
    }
    console.log("input Obj->",JSON.stringify(this.inputObj));
  }

  public navigateUser() {
    this.user.openLocation("https://maps.google.com/?saddr=" + this.position['lat'] + "," + this.position['lng'] + "&daddr="+ this.clinicLatLng['lat'] + "," + this.clinicLatLng['lng']);
  }

  public modifyStructForServicesToOrder() {
    this.selectedServices.map( data => {
      if(this.serviceToOrder.find( service => service['directiveCode'] == data['directiveCode'])) {
        let addedService = this.serviceToOrder.find( service => service['directiveCode'] == data['directiveCode']);
        let concept = {
         code: data['conceptCode'],
         desc: data['conceptName'],
         serviceTime: data['details']['servicetime'],
         serviceCost: data['details']['servicecost'],
         serviceHm: data['details']['servicetimehm']
       }
       addedService['concepts'].push(concept);
      }
      else {
         let obj = {
         directiveCode: data['directiveCode'],
         directiveDesc: data['directiveName'],
         concepts: []
       }
       let concept = {
         code: data['conceptCode'],
         desc: data['conceptName'],
         serviceTime: data['details']['servicetime'],
         serviceCost: data['details']['servicecost'],
         serviceHm: data['details']['servicetimehm']
       }
       obj['concepts'].push(concept);
       this.serviceToOrder.push(obj);
       }
     })
     console.log('this.serviceToOrder',this.serviceToOrder);
  }

  public payForAppt() {
    var payload = {
      linkedPersonId: this.patient['personid'],
      patientId: this.patient['id'],
      amount: this._input['in']['service']['totalAmount'],
      currency: this.currency_code,
      episodeType: 'R',
      encounterId: '0',
      facilityId: this._input['in']['facility']['facilityId'],
      requestType: 'APPTBK',
      resourceDetails: this.inputObj || {}
    };
    let sourceRequest = this._input.in?.confirm?.retry?.sourceRequest;
    console.log('sourceRequest', sourceRequest)
    let request
    if (sourceRequest) {
      request = sourceRequest
    } else {
      request = {
        resourceId: this.practDetails['practitionerId'],
        patientPersonId: this.patient['personid'],
        uhid: this.patient['id'],
        facilityId: this._input['in']['facility']['facilityId'],
        clinicCode: this._input['in']['facility']['clinicCode'],
        date: this.user.getMoment(this._input['in']['slot']['date']).format("YYYY-MM-DD"),
        time: this._input['in']['slot']['slot']['slot'],
        personName: this.patient['name'],
        dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
        gender: this.patient['gender'],
        localeCode: this.lang,
        practitionerName: this.translatePipe.transform(this.practDetails['practitionerName'], 'LOCALE', 'name'),
        specialityName: this.translatePipe.transform(this.practDetails['specialityName'], 'LOCALE', 'name'),
        patientName: this.patient['name'],
        visitType: 'CLINIC',
        noOfSlots: this._input['in']['slot']['slotsNeeded'],
        servicesToOrder: this.serviceToOrder
      }
    }
    payload['resourceDetails']['sourceRequest'] = _.cloneDeep(request)

    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.patient['id'],
        email: this.patient['email'] || environment.DEFAULT_EMAIL,
        mobile_no: ''
      }
      let url = window['location'];
      let transaction_id = response['transaction_id'];
      let params = `orderid=${transaction_id}`
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=EXPRESS&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() ||  this.currency_code,
        charge: this._input['in']['service']['totalAmount'],
        paymentgatewayurl: this.paymentGatewayUrl
      }
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        this.action.emit({ name: 'openPaymentCheckout' });
      })
    })
  }

  public call(){
    this.user.openLocation("tel:" + this.clinicMobileNumber);
  }

  public getServiceName(data, defaultName){
    let names = data['localNames'] ? data['localNames']['names'] : null;
    if (!names || names.length == 0) {
      return defaultName ? defaultName : '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

  public closeModal() {
    this.complete.emit(this._input['in']);
  }
}
