import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeMobileNumberComponent } from './change-mobile-number.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
@NgModule({
  declarations: [ChangeMobileNumberComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule, PhoneInputModule
  ],
  exports: [
    ChangeMobileNumberComponent
  ]
})
export class ChangeMobileNumberModule { }
