import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInputGroupComponent } from './custom-input-group.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
  declarations: [CustomInputGroupComponent],
  imports: [
    CommonModule, PipesModule, FormInputErrorModule, FormsModule, ReactiveFormsModule, DropdownModule
  ],
  exports: [
    CustomInputGroupComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomInputGroupModule { }
