<div id="appt-confirm">
  <div class="appt-modal-content">
    <div class="success-container" *ngIf="_input.in['confirm'] && (_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS')">
      <img class="type-image" src="assets/icons/check.svg">
      <span class="font-default-small bold">{{'cal_modal_success_msg'|translate:'LABELS'}}</span>
    </div>
    
    <div class="info-container">
      <div class="font-default-small col-9">{{'appt_dtl_msg_download_app_now'|translate:'LABELS'}}</div>
      <div class="logo-container col-3">
        <div class="logo">
          <img class="type-image" src="assets/images/app-googleplay.png" alt="">
        </div>
        <div class="logo">
          <img class="type-image" src="assets/images/apple_play.png" alt="">
        </div>
        <div class="logo" *ngIf="false">
          <img class="type-image" src="assets/images/apple_play.png" alt="">
        </div>
      </div>
    </div>
    <div class="new-card-review">
      <div class="col">
        <app-appt-dtl-card [apptInfo]="_input" [type]="'DETAILS'" [isReferralApplicable]="false"></app-appt-dtl-card>
      </div>
      <div class="col">
        <ng-container
          *ngIf="_input.in['confirm']&&(_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS')">
          <p class="font-default-small bold side-hdr">{{'appt_dtl_pmt_details'|translate:'LABELS'}}</p>
          <div class="payment-details">
            <div class="card">
              <div class="card-content" *ngIf="_input.in['confirm']">
                <div class="payment-group">
                  <div class="payment-content border-bottom">
                    <div class="subheader-font">{{'appt_dtl_pmt_details_category'|translate:'LABELS'}}
                    </div>
                    <div class="def-subtitle"
                      [attr.check]="'lbl_billcategory_'+_input.in['confirm']['category'] | translate">
                      {{('lbl_billcategory_'+_input.in['confirm']['category'] | translate: 'LABELS': null:null:null: true)
                      || (_input.in['confirm']['category'] == 'CASH'? 'CASH': 'Insurance')}}
                    </div>
                  </div>
                  <div class="payment-content" *ngIf="_input.in['confirm']['amount']">
                    <div class="subheader-font">{{'amount_paid'|translate:'LABELS'}}
                    </div>
                    <div class="prim-ttl-txt bold">
                      {{_input.in['confirm']['amount']}} {{currency_code}}
                    </div>
                  </div>
                  <div class="payment-content" *ngIf="_input.in['confirm']['amount_to_be_paid'] || _input.in['confirm']['amount_to_be_paid'] == 0">
                    <div class="subheader-font">{{'amount_to_be_paid'|translate:'LABELS'}}</div>
                    <div class="prim-ttl-txt bold">
                      {{_input.in['confirm']['amount_to_be_paid']}} {{currency_code}}
                    </div>
                  </div>
                  <div class="payment-content" *ngIf="_input.in['confirm']['prev_amt_paid']">
                    <div class="subheader-font">{{'prev_amt_paid'|translate:'LABELS'}}
                    </div>
                    <div class="prim-ttl-txt bold">
                      {{_input.in['confirm']['prev_amt_paid']}} {{currency_code}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
    
        <ng-container
          *ngIf="_input.in['confirm']&&!(_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS')">
          <p class="font-default-small bold side-hdr">{{'appt_dtl_pmt_failed'|translate:'LABELS'}}</p>
          <div class="payment-details">
            <div class="card">
              <div class="card-content">
                <div class="payment-group payment-error">
                  <img class="type-image" src="assets/icons/alert_red.svg" />
                  <span class="font-danger-small">{{'msg_payment_err'|translate}} <span class="click_here cursor-pointer"
                      (click)="payForAppt()">{{'appt_click_here'|translate}}</span></span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
    
        <ng-container *ngIf="_input.in['confirm']&&_input.in['confirm']['status']=='S'">
          <p class="font-default-small bold">{{'ttl_appt_guidelines'|translate:'LABELS'}}</p>
          <div class="card payment-item ">
            <p class="font-default-small padding">{{'cont_appt_guidelines' |translate:'LABELS'}}</p>
          </div>
        </ng-container>
    
        <ng-container *ngIf="_input.in['confirm']&&_input.in['confirm']['status']=='S'">
          <p class="font-default-small bold">{{'ttl_visit_reg_checkin'|translate:'LABELS'}}</p>
          <div class="card payment-item ">
            <p class="font-default-small padding">{{'cont_visit_reg_checkin' |translate:'LABELS'}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
    <div class="modal-footer">
      <div class="btn-container">
        <button class="primary-button" (click)="goToHome()">
          {{ 'btn_back_to_home' | translate }} </button>
      </div>
    </div>
</div>