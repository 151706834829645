<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
  <div class="component" [ngClass]="{'coupon-list': type == 'VIEWCOUPON'}">
    <div class="header border-bottom">
      <div class="header-row">
        <div (click)="closeModal()" class="back-btn float-left closeicon pointer">
          <img src="assets/icons/arrow.svg">
        </div>
        <div class="font-primary-subtitle bold">{{"COUPONS#MODAL#LABEL#MYCOUPONS" | translate}}</div>
      </div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content" [ngClass]="{'coupon-content': type == 'VIEWCOUPON'}" style="overflow-y: scroll;">
      <div *ngIf="type == 'COUPON'">
        <ng-container *ngIf="couponList && couponList.length > 0">
          <div class="coupon-list-card">
            <div class="card-layout" *ngFor="let item of couponList" (click)="chooseFilter(item)">
              <div class="card cursor-pointer"
                [ngClass]="{'selected-coupon': item.checked, 'disabled-coupon': (couponBalPatientPayable == 0 && !item.checked)}">
                <div class="circle1"></div>
                <div class="circle2"></div>
                <div class="row">
                  <label class="card-content">
                    <div class="subheader-font bold txt_flow">{{item['cashDiscount']}} {{ currency_code }}</div>
                    <div class="subtitle txt_flow">
                      <p>{{item['desc'] | translate : 'LOCALE' : 'name'}}</p>
                    </div>
                    <div class="subtitle txt_flow">
                      <p>{{item['couponSequenceNo']}}</p>
                    </div>
                  </label>
                  <div class="image" dir="ltr" *ngIf="item.checked">
                    <img class="type-image" src="assets/icons/coupon/coupon_selected.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- </ng-container *ngIf="couponList.length == 0">
        <ng-container> -->
      </div>
      <div *ngIf="type == 'VIEWCOUPON'">
        <div class="loyalty-container" *ngIf="pointsInfo && pointsInfo.availablePoints !== null && pointsInfo.availablePoints !== undefined">
          <img class="gender-female" *ngIf="gender == 'female' || gender == 'F'">
          <img class="gender-male" *ngIf="gender == 'male' || gender == 'M'">
          <img class="nuhdeek-icon" src="assets/icons/nuhdeek/nuhdeekicon.svg" alt="" [ngClass]="{'nuhdeek-card-rtl-icon': appDirection == 'rtl'}">
          <div class="points">
            <h2 [ngClass]="{'nuhdeek-card-rtl-title': appDirection == 'rtl'}">{{ "NUHDEEK#POINTS#MODAL#AVAILABLEPOINTS" | translate }}</h2>
            <p [ngClass]="{'nuhdeek-card-rtl-points': appDirection == 'rtl'}">{{pointsInfo?pointsInfo.availablePoints : ''}}</p>
          </div>
          <div class="expiry-alert" *ngIf="isConsent && pointsInfo && pointsInfo.expiryDetails && pointsInfo.expiryDetails.value > 0">
            <img class="alert-icon" src="./assets/icons/nuhdeek/expiry_alert.svg">
            <label class="expiry-label">{{'NUHDEEK#POINTS#LABEL#POINTS#EXPIRY' | translate : 'LABELS' : null: null:null:null:pointsDerivative}}</label>
          </div>
          <div class="consent-nuhdeek-card" *ngIf="consentInit && !isConsent && (consentDetails && consentDetails.enableConcent == 'Y')">
            <h3 class="consent-title">{{'NUHDEEK#CONSENT#LABEL#NUHDEEK#LOYALTY#POINTS' | translate}}</h3>
            <label class="consent-label">{{'NUHDEEK#CONSENT#LABEL#NUHDEEK#LOYALTY#POINTS#MSG' | translate}}</label>
            <button class="consent-button" (click)="nuhdeekConsent()">{{'NUHDEEK#CONSENT#LABEL#NUHDEEK#LOYALTY#POINTS#BTN' | translate}}</button>
          </div>
        </div>
        <label *ngIf="type == 'VIEWCOUPON' && pointsInfo && pointsInfo.availablePoints && couponList && couponList.length > 0" class="coupon-label" >{{'COUPON#TITLE#AVAILABLECOUPONS' | translate}}</label>
        <ng-container *ngIf="couponList && couponList.length > 0">
          <div class="coupon-list-card">
            <div class="card-layout" *ngFor="let item of couponList">
              <div class="card cursor-pointer">
                <div class="circle1"></div>
                <div class="circle2"></div>
                <div class="row">
                  <label class="card-content">
                    <div class="subheader-font bold txt_flow">{{item['cashDiscount']}} {{ currency_code }}</div>
                    <div class="subtitle txt_flow">
                      <p>{{item['desc'] | translate : 'LOCALE' : 'name'}}</p>
                    </div>
                    <div class="subtitle txt_flow">
                      <p>{{item['couponSequenceNo']}}</p>
                    </div>
                  </label>
                  <!-- <div class="image" *ngIf="item.checked">
                    <img src="assets/icons/coupon/coupon_selected.svg">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- </ng-container *ngIf="couponList.length == 0">
        <ng-container> -->
      </div>
      <ng-container *ngIf="!couponList || couponList.length==0">
        <app-nodata [source]="'COUPONS'" [pointsView]="pointsAvailable" [availablePoints]="availableNuhdeekPoints"></app-nodata>
      </ng-container>
    </div>
    <div class="modal-footer" *ngIf="type == 'COUPON'">
      <div class="total-bill">
        <p>{{"COUPON#LABEL#NO#TOTAL#BILL#AMOUNT" | translate}}</p>
        <div class="display-direction-row">
          <div *ngIf="amount" class="before-amount amount-size-small">
            <label class="strikethrough">
              <strong>{{amount}} {{currency_code}}</strong>
            </label>
          </div>
          <div class="correct-amount amount-size-small" [ngClass]="{'add-margin': amount}">
            <label class="primary">
              <strong>{{couponBalPatientPayable}} {{currency_code}} </strong>
            </label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <ng-container>
          <button class="primary-button" (click)="dismiss('APPLY')" [disabled]="!selectedCoupons">{{'btn_apply' |
            translate}}</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>