import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralActionsComponent } from './referral-actions.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';



@NgModule({
  declarations: [ReferralActionsComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule
  ],
  exports: [
    ReferralActionsComponent
  ]
})
export class ReferralActionsModule { }
