import { ChangeDetectorRef, Directive, ElementRef, HostListener } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[appCssEllipsis]'
})
export class CssEllipsisDirective {
  /** The native HTMLElement. */
  private get el(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  /** The original innerText; */
  private innerText: string;

  constructor(
    private readonly elementRef: ElementRef, private user: UserService, private cdf: ChangeDetectorRef
  ) { }

  public ngAfterViewInit(): void {
    // setTimeout(_ => {
    this.truncate();
    // }, 500)
  }

  @HostListener('window:resize')
  private onWindowResize() {
    this.truncate();
  }

  private truncate(): void {
    // verify execution context is the browser platform

    // store the original innerText
    if (this.innerText === undefined) {
      this.innerText = this.el.innerText.trim();
    }
    var arabic = /[\u0600-\u06FF]/;
    if (!arabic.test(this.innerText) && this.user.getAppDirection() == 'rtl') {
      this.el.style.direction = 'ltr';
      this.cdf.detectChanges()
    }
  }

}
