import { Component, ComponentFactoryResolver, EventEmitter, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LOGIN_CONTEXT } from '@const';
import { REPO_TOKEN, USER_TOKEN } from '@string';
import { HealthRecordsComponent } from '../health-records/health-records.component';
import { HrVisitBillDetailsComponent } from '../hr-visit-bill-details/hr-visit-bill-details.component';
import { CookieService } from '../services/cookie.service';
import { HealthRecordsService } from '../services/health-records.service';
import { UserService } from '../services/user.service';
import { TaskLoginComponent } from '../task-login/task-login.component';

@Component({
  selector: 'app-common-view',
  templateUrl: './common-view.component.html',
  styleUrls: ['./common-view.component.scss']
})
export class CommonViewComponent implements OnInit {

  @ViewChild('appFormats', { read: ViewContainerRef }) appFormats: ViewContainerRef;
  public info: any;
  public loginInfo: any;
  public state: string = null;
  constructor(private activatedRoute: ActivatedRoute, private user: UserService, private cookie: CookieService, private hrService: HealthRecordsService, public cfr: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const key = params['key'];
      if (key != 'NOTVALID') {
        this.validatePatientView(key);
      } else {
        this.state = 'INVALID'
      }
    });
  }

  private validatePatientView(key: string) {
    this.user.validatePatientView(key).subscribe(response => {
      if (response['token']) {
        this.info = response;
        this.setStorage(USER_TOKEN, response['token']['token']);
        this.setStorage(REPO_TOKEN, response['repoToken']['token']);
        this.user.setRepoUrl(response['repoToken']['url']);
        this.loadType(response['type']);
      } else {
        this.loginInfo = response;
        this.loadType('LOGIN');
      }
    }, err => { })
  }

  private loadType(type: string) {
    switch (type) {
      case LOGIN_CONTEXT.BILL:
        this.hrService.getBillDetails(this.info['facilityId'], this.info, this.info['patientInfo']).subscribe(response => {
          this.loadComponent(HrVisitBillDetailsComponent, type, response);
        })
        break;
      case LOGIN_CONTEXT.REPORT:
        const { patientInfo, reportId, facilityId } = this.info;
        this.user.geReportById(patientInfo['patient_id'], reportId, facilityId).subscribe(response => {
          this.loadComponent(HealthRecordsComponent, type, { report: response['report'], token: response['accessToken'], type: 'V', _data:this.info });
        }, err => { })
        break;
      case 'LOGIN':
        this.loadComponent(TaskLoginComponent, type, null)
        break;
      default:
        break;
    }
  }

  private loadComponent(_component, type: string, info?: any) {
    console.log('thisa.app', this.appFormats);
    let componentFactory = this.cfr.resolveComponentFactory(_component);
    this.appFormats.clear();
    let componentRef = this.appFormats.createComponent(componentFactory);
    let patient = {}
    if (this.info && this.info.patientInfo) {
      const { patientInfo } = this.info;
      patient = {
        personname: patientInfo['person_name'],
        personid: patientInfo['person_id'],
        uhid: patientInfo['patient_id'],
        gender: patientInfo['gender'],
        dob: patientInfo['dob']
      }
    } else if (this.loginInfo) {
      patient = {
        uhid: this.loginInfo.patientId,
        maskedMobile: this.loginInfo.maskedNumber
      }
    }
    if (type === LOGIN_CONTEXT.BILL) {
      (<CommonView>componentRef.instance).viewBillDetails = info;
      (<CommonView>componentRef.instance).selectedBill = this.info;
      (<CommonView>componentRef.instance).selectedPatient = patient;
      (<CommonView>componentRef.instance).isCommonView = true;
    } else if (type === LOGIN_CONTEXT.REPORT) {
      info['patient'] = patient;
      (<CommonView>componentRef.instance).info = info;
      (<CommonView>componentRef.instance).isCommonView = true;
    } else if (type == 'LOGIN') {
      (<CommonView>componentRef.instance).selectedPatient = patient;
      (<CommonView>componentRef.instance).isCommonView = true;
      (<CommonView>componentRef.instance).complete.subscribe((event) => {
        if (event) {
          this.validatePatientLogin(event)
        }
      })
    }
  }

  public setStorage(key, value) {
    this.cookie.set(key, value);
  }

  private validatePatientLogin(response) {
    response = { ...response, ...this.loginInfo };
    this.info = response;
    this.setStorage(USER_TOKEN, response['token']['token']);
    this.setStorage(REPO_TOKEN, response['repoToken']['token']);
    this.user.setRepoUrl(response['repoToken']['url']);
    this.loadType(response['type']);
  }

}

export interface CommonView {
  viewBillDetails?: any,
  selectedBill?: any,
  selectedPatient?: any,
  isCommonView: boolean,
  info: any,
  complete: EventEmitter<any>
}