import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-express-clinic-service',
  templateUrl: './express-clinic-service.component.html',
  styleUrls: ['./express-clinic-service.component.scss']
})
export class ExpressClinicServiceComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;

  public translateType = 'LOCALE';
  public latlon: Object;
  public availableServices: Array<any>;
  public selectedServices: Array<any>;
  public totalAmount: number;
  public practDetails: object;
  public maxServiceTime: number;
  public position: object;
  public clinicLatLng: object;
  public clinicMobileNumber: any;
  public currency_code: any;
  public searchKey: string= '';

  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.latlon = { lat: 0, lon: 0 };
    this.selectedServices = new Array<any>();
    this.totalAmount = 0;
    this.user.getConfigurationInfo.subscribe(data => {
      if(data['pxAppSettings']) {
        this.maxServiceTime = data['pxAppSettings']['expressClinicMaxApptDur'];
      }
    })
  }

  ngOnInit() {
    console.log("service input->",this._input);
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.getAvailableServices();
    this.practDetails = this._input['in']['inputService']['practDetails'][0];
    this.clinicMobileNumber = this._input['in']['facility']['mobileNo'];
    this.position = this._input['in']['facility']['position'];
    this.clinicLatLng = this._input['in']['facility']['facilityLocation'];
  }

  public getAvailableServices() {
    this.availableServices = this._input['in']['inputService']['details'];
    this.availableServices = this.availableServices.filter(service=>service['conceptName']);
    // const defaultService = this.availableServices[0];
    if(this.availableServices && this.availableServices.length == 1) {
      this.availableServices[0]['selected'] = true;
      this.onSelectService(this.availableServices[0], true);
    }
    this.availableServices.map( service => {
      //service['isSelectable'] = false;
      //service['selected'] = false;
      service['details']['servicetime'] = parseInt(service['details']['servicetime']);
      service['details']['servicecost'] = parseInt(service['details']['servicecost']);
      /* if(service['details']['servicetimehm'] == 'M') {
        if(service['details']['servicetime'] <= this.maxServiceTime)
          service['isSelectable'] = true;
      } else if(service['details']['servicetimehm'] == 'H'){
        if( (service['details']['servicetime'] * 60) <= this.maxServiceTime)
          service['isSelectable'] = true;
      } */
    })
  }

  public selectService(service: object) {
    service['selected'] = !service['selected']
    this.onSelectService(service, service['selected'])
  }

  public onSelectService(service: object, isSelected: boolean) {
    if(isSelected) {
      this.selectedServices.push(service);
    }else {
      this.selectedServices = this.selectedServices.filter(selecserv => selecserv != service);
    }
    console.log('this.selectedServices',this.selectedServices)
    this.calculateAmount();
    //this.checkSelectableServices();
  }

  public checkSelectableServices() {
    let totalTime = this.calculateTotalTime();
    this.availableServices.map( service => {
      if(!service['selected'])
        service['isSelectable'] = false;
      if(service['details']['servicetimehm'] == 'M') {
        if( (service['details']['servicetime'] + totalTime) <= this.maxServiceTime)
          service['isSelectable'] = true;
      } else if(service['details']['servicetimehm'] == 'H'){
        if( ( (service['details']['servicetime'] * 60) + totalTime) <= this.maxServiceTime)
          service['isSelectable'] = true;
      }
    })
  }

  public calculateAmount() {
    if(this.selectedServices.length)
      this.totalAmount = this.selectedServices.map(service => service.details.servicecost).reduce((prev, next) => parseInt(prev) + parseInt(next));
    else
      this.totalAmount = 0;
  }

  public calculateTotalTime() {
    let totalTime = 0;
    this.selectedServices.forEach( service => {
      if(service['details']['servicetimehm'] == 'H')
        totalTime = totalTime + (service['details']['servicetime'] * 60);
      else if(service['details']['servicetimehm'] == 'M')
        totalTime = totalTime + service['details']['servicetime'];
    })
    if(totalTime < this.maxServiceTime)
      return totalTime;
    else
      return this.maxServiceTime;
  }

  public navigateUser() {
    console.log("btn clincked")
    this.user.openLocation("https://maps.google.com/?saddr=" + this.position['lat'] + "," + this.position['lng'] + "&daddr="+ this.clinicLatLng['lat'] + "," + this.clinicLatLng['lng']);
  }

  public call(){
    this.user.openLocation("tel:" + this.clinicMobileNumber);
  }

  public completeSelection() {
    if(!this.selectedServices.length)
      return;
    let obj = {
      services: this.selectedServices,
      name: this.selectedServices.length + ' Services',
      totalTime: this.calculateTotalTime(),
      totalAmount: this.totalAmount
    }
    this.complete.emit({ service: obj });
  }

  public getServiceName(data, defaultName){
    let names = data['localNames'] ? data['localNames']['names'] : null;
    if (!names || names.length == 0) {
      return defaultName ? defaultName : '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

}
