import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, ComponentFactoryResolver, HostListener, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../services/cookie.service';
import { ToastServiceService } from '../services/toast-service.service';
import * as moment from 'moment';
import { SectionComponent } from '../section/section.component';
import { ConditionaltopicPipe } from '../pipes/conditionaltopic.pipe';
import { ClinicalformService } from '../services/clinicalform.service';
import { ConsultationNoteService } from '../services/consultation-note.service';
import { ClinicalFormUtils } from './clinicalform.utils';
import { debounceTime, retry } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-clinicalform',
  templateUrl: './clinicalform.component.html',
  styleUrls: ['./clinicalform.component.scss'],
  providers:[TranslatePipe]
})
export class ClinicalformComponent implements OnChanges {

  @Input() concept: any;
  @Input() directiveCode: any;
  @Input() navigation: any;
  @Input() formViewType: any;
  @Input() taskDesc: any;
  @Output() response: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @ViewChild(SectionComponent) section;
  @ViewChild('myDiv') myDiv: ElementRef;
  @Input() public params: Object;
  @Input() public isFromActivityModal: boolean;
  @Output() public onBack: EventEmitter<string>;
  @Input() public canShowBack: boolean;
  @Input() public showSaveDraftBtn: boolean;
  @Output() onConsultationNoteOpen: EventEmitter<any>;
  @Input() isInlineForm: boolean;
  @Input() encounter: any;
  @Input() showTopicTitle: boolean=true;
  @Input() taskAction: any;
  @Input() currentStatus: any;

  public currentTopic: any;
  private domainCodes: Array<any>;
  public numberOfTopics: number;
  public currentTopicIndex: number;
  public topics: any;
  public patient;
  public date;
  public showSave: boolean;
  public taskApplicableStatus;

  /** Modal Functional Variables **/
  public minimizeModal: boolean;
  public normalizeModal: boolean;
  public fullScreenModal: boolean;
  public enableDraft: boolean;
  public preview: boolean;



  public data: any;

  public _showPreview: boolean;
  public _topicsToPreview: any;
  public _currentAddendumId: any;
  public _addToNote: boolean;
  public _isOPDForm: boolean;
  public consultationNoteSection: any;
  public consultationNoteTopic: any;
  public filterMandatory: boolean = false;
  public printable: boolean;
  public frequencyApplicable: any;
  public routeApplicable: any;
  public appointmentsList: any;
  public closedSideNav: boolean = false;
  public openMobileNav: boolean = false;
  public showSaveAlert: boolean = false;
  public actionOpen: boolean = false;
  public savedOption: any;
  public _triggerPoint: any = null;
  public nextTopicIndex: any;
  constructor(
    public http: HttpClient,
    public user: CookieService,
    public formService: ClinicalformService,
    public cfr: ComponentFactoryResolver,
    public consultationNoteService: ConsultationNoteService,
    public utils: ClinicalFormUtils, public toast: ToastServiceService, private cdf: ChangeDetectorRef, private translate: TranslatePipe) {

    this.showSave = true;
    this.date = moment.now();
    this.minimizeModal = false;
    this.normalizeModal = true;
    this.fullScreenModal = false;
    this.onBack = new EventEmitter<string>();
    this.onConsultationNoteOpen = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.formService.sectionValueListener.subscribe(ts => {
      this._triggerPoint = ts;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ClinicalformComponent changes', changes)
    try {
      this.patient = this.params['patient'] || null;
      this.appointmentsList = Array.isArray(this.params['appointmentsList']) ? this.params['appointmentsList'] : [];
      if (this.params && this.params['concept'] && this.params['concept']['noteContent'] && this.params['concept']['noteContent']['topics']) {
        this.params['concept']['noteContent']['topics']=this.params['concept']['noteContent']['topics'].map(t => {
          t.sections.map(s => {
            if (s.type === 'select') {
              s.type = 'check';
            }
            return s;
          })
          return t;
        })
        this.defaultOnFormLoad();
        if (this.params['addToNote']) {
          this._addToNote = true;
          const addendumOption = this.formService.createAddField(this.params['concept']['noteContent']['topics']);
          this.params['concept']['noteContent']['topics'] = addendumOption['topics'];
          this._currentAddendumId = addendumOption['currentId'];
        }
        this.frequencyApplicable = this.params['concept']['frequencyApplicable'];
        this.routeApplicable = this.params['concept']['routeApplicable'];
      }
      if (!this.data || changes['params'])
        this.data = this.params ? this.params : this.data;
      this.initVariables();
      this.initProcess();
    } catch (err) {
      console.error('Error in Date', err);
      this.response.emit(null);
      console.log('Unexpected JSON Error');
    }
    this.onScroll();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (!(document.getElementById('clinicalform-dropdown-menu') && document.getElementById('clinicalform-dropdown-menu').contains(targetElement)))
      this.closeActionList();
  }

  initVariables() {
    if (this.data) {
      this.concept = this.data['concept'];
      this.directiveCode = this.data['directiveCode'];
      this.taskApplicableStatus = this.data['taskApplicableStatus'];
    }
    this.preview = this.data['preview'] ? this.data['preview'] : false;
    this.enableDraft = this.data['enableDraft'] ? this.data['enableDraft'] : false;
    this.patient = this.data['patient'] ? this.data['patient'] : this.patient;
    if (typeof (this.patient) == 'string') {
      this.patient = JSON.parse(this.patient);
    }
    if ('noteContent' in this.concept && this.concept['noteContent'] && this.concept['noteContent']['topics']){
      console.log(this.concept['noteContent']['topics'])
      this.topics = this.concept['noteContent']['topics'];
    }
    else
      this.topics = [];

    this.topics = this.topics.filter(topic => {
      if (topic['displayOption']) {
        if (topic['displayOption'] == 'H') {
          return false;
        }
      } else {
        topic['displayOption'] = 'E';
      }
      return true;
    });
    this.toggleTopics(0);
    this.numberOfTopics = this.topics.length;
    this._isOPDForm = this.formService.isOPD(this.concept);
    this.printable = this.concept && this.concept.noteControls && this.concept.noteControls.allowPrint === 'Y';
  }


  openTopic(indexOfTopic) {
    this.currentTopicIndex = indexOfTopic;
    this.checkNextTopicIndex()
    if (this.topics.length == 0)
      return;
    this.currentTopic = this.topics[indexOfTopic];
    this.topics.forEach((t) => {
      if (t['id'] == this.currentTopic['id'])
        t['selectedTopic'] = true
      else
        t['selectedTopic'] = false
    })
    this.currentTopic['addedToForm'] = true;
    let topics = ConditionaltopicPipe.prototype.transform(this.topics);
    if ((topics.length - 1) == this.currentTopicIndex) {
      this.showSave = false;
    }
    if (this.currentTopic.id) {
      const container = document.getElementById('F_Container');
      const ele = document.getElementById('F_T_' + this.currentTopic.id);
      if (container && ele) {
        container.scrollTo({
          top: container.offsetTop - 10,
          left: 0,
          behavior: 'smooth'
        });
        // this.scrollToTop()
      }
    }
  }
  initProcess() {
    const firstIndex = this.getFirstEditableTopic();
    const index = (firstIndex !== -1) ? firstIndex : 0;
    this.openTopic(index);
    this.pipeData();
  }
  public pipeData() {
    for (let i = 0; i < this.topics.length; i++) {
      for (let j = 0; j < this.topics[i]['sections'].length; j++) {
        if (this.topics[i]['sections'][j]['mandatory'] === 'true') {
          this.topics[i]['mandatory'] = true;
          break;
        }
      }
    }
  }
  getFirstEditableTopic(): number {
    for (let i = 0; i < this.topics.length; i++) {
      if (this.topics[i]['displayOption'] === 'E') {
        return i;
      }
    }
    this.checkForEnablingSaveButton();
    return -1;
  }
  navigateToNextTopic(index, length?: number) {
    if(!this.isTopicFilled) return
    if (index >= length)
      return this._showPreview = true;
    if (this.topics[index]['multiple']) {
      const topicoftype = this.topics.filter(t => t['id'] == this.topics[index]['id']);
      const t = this.topics.find(el => el['id'] == this.topics[index]['id']);
      this.currentTopicIndex = index;
      this.checkNextTopicIndex()
      if (!this.topics[index]['subTopic']) {
        t['_selectedIndex'] = 0;
      }
      else if (t['_selectedIndex'] <= topicoftype.length - 1)
        t['_selectedIndex'] += 1;
      return this.openTopic(index);
    }
    if (this.utils.checkFormFilled(this.topics[this.currentTopicIndex])) {
      if (this.utils.checkConditionalTopic(this.topics[index], this.topics)) {
        this.checkForEnablingSaveButton();
        const container = document.getElementById('sections');
        if (container)
          container.scrollTop = 0;
        this.openTopic(index);
      }
      else {
        this.navigateToNextTopic(index + 1);
      }
    } else {
        this.scrollToTop();
    }
  }

  checkForEnablingSaveButton() {
    let enable = true;
    console.log(this.topics)
    let topics = this.topics;
    topics = this.filterAddedTopics(topics);
    topics = ConditionaltopicPipe.prototype.transform(topics);
    topics.forEach(topic => {
      if (!this.utils.checkFormFilled(topic)) {
        enable = false;
      }
    });
    this.showSave = !enable;
  }

  navigateToPreviousTopic(index) {
    if (this.topics[index]['multiple']) {
      const topicoftype = this.topics.filter(t => t['id'] == this.topics[index]['id']);
      const t = this.topics.find(el => el['id'] == this.topics[index]['id']);
      this.currentTopicIndex = index;
      this.checkNextTopicIndex()
      if (this.topics[index]['subTopic']) {
        t['_selectedIndex'] = topicoftype.length - 1;
      }
      else if (t['_selectedIndex'] > 0)
        t['_selectedIndex'] -= 1;
      return this.openTopic(index);
    }
    if (this.utils.checkConditionalTopic(this.topics[index], this.topics)) {
      this.openTopic(index);
    }
    else {
      this.navigateToPreviousTopic(index - 1);
    }
  }

  save(newTaskStatusCode: string, isPrint?: boolean, status?: Object, taskDraft: boolean = false) {
    if (taskDraft || this.topics.every(topic => !this.utils.checkConditionalTopic(topic, this.topics) || this.utils.checkFormFilled(topic)) ) {
      let response = this.utils.save(this.topics, this.concept['noteCode'], this.patient);
      this.concept['noteContent']['topics'] = response['topics'];
      response['data'] = [this.concept];
      if(taskDraft)
        response['type'] = 'save';
      response['newTaskStatusCode'] = newTaskStatusCode;
      if (this.data) {
        this.response.emit({
          response: response,
          save: true,
          editNote: status ? status['editNote'] : this.params && this.params['editNote'],
          addToNote: status ? status['addToNote'] : this.params && this.params['addToNote'],
          isPrint: isPrint,
          _taskDraft: taskDraft
        });
      }
    } else {
      this._showPreview ? this.goPrevFromPreview(true) : this.scrollToTop();
    }
  }

  saveDraft() {
    let response = this.utils.save(this.topics, this.concept['noteCode']);
    this.concept['noteContent']['topics'] = response['topics'];
    response['data'] = [this.concept];
    response['type'] = 'save';
    if (this.data) {
      this.response.emit({
        response: response,
        save: false
      });
    }
  }

  filterAddedTopics(topics) {
    console.log(topics)
    return topics = topics.filter(topic => {
      return topic['addedToForm'];
    });
  }

  scrollToTop() {
    let section = null;
    let topicChanged = false;
    for (let index = 0; index < this.topics.length; index++) {
      section = this.utils.checkMandatory(this.topics[index])
      if (section) {
        if (index != this.currentTopicIndex) {
          this.toggleTopics(index);
          topicChanged = true;
        }
        break;
      }
    }
    if (section) {
      if(topicChanged) {
        setTimeout(() => {
          this.scrollFunc(section);
        }, 1000);
      } else
        this.scrollFunc(section);
    }
  }

  private scrollFunc(section) {
    let sectionView = document.getElementById(`${this.currentTopicIndex}#${section['domainCode']}`);
    if (!sectionView)
      return;
    sectionView.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });
    setTimeout(() => {
      if (section['type'] === 'masterLinked' && sectionView.getElementsByTagName('select').length > 0)
        sectionView.getElementsByTagName('select')[0].focus();
      else if (section['type'] === 'longText' && sectionView.getElementsByTagName('textarea').length > 0)
        sectionView.getElementsByTagName('textarea')[0].focus();
      else if (section['type'] === 'date' && sectionView.querySelectorAll('vaadin-date-picker').length > 0)
        (sectionView.querySelectorAll('vaadin-date-picker')[0] as HTMLElement).focus();
      else if (sectionView.querySelectorAll('input').length > 0) {
        sectionView.querySelectorAll('input')[0].focus();
      }
    }, 500)
  }

  close() {
    this.response.emit(null);
  }

  navigateBack() {
    this.back.emit(true)
  }

  isFilesUploaded() {
    for (let topic of this.topics) {
      for (let section of topic["sections"]) {
        if (section['type'] === "file") {
          if (section["selectedFile"]) {
            for (let file of section["selectedFile"]) {
              if (file["uploaded"] === true)
                continue;
              else
                return false;
            }
            return true;
          }
          else {
            return false;
          }
        }
        if (section['type'] === 'draw' && section['selectedPic']) {
          if (section["uploaded"] === true)
            continue;
          else
            return false;
        }
      }
    };
    return true;
  }

  updateTask(action, isPrint = false, status=null, alertConfirm = false, saveDraft = false) {
    if (!saveDraft && !this.isFormFilled) {
      let message = this.translate.transform('msg_fill_mandatory_fields_form');
      this.toast.showToast(true, message, "error")
      return;
    }
    this.closeActionList();
    if (saveDraft || this.topics.every(topic => !this.utils.checkConditionalTopic(topic, this.topics) || this.utils.checkFormFilled(topic))) {
      if (this.formViewType == 'category' && !alertConfirm) {
        this.showSaveAlert = true;
        this.savedOption = {
          action: action,
          isPrint: isPrint,
          status: status,
          saveDraft: saveDraft
        }
        return;
      }

      if (!this.isFilesUploaded()) {
        this.uploadFiles().then(() => {
          this.save(action.code, isPrint, status, saveDraft);
        }, (err) => {
          console.log(err);
          this.toast.showToast(true, "Error in uploading the files", "error");
        })
      } else
        this.save(action.code, isPrint, status, saveDraft);
      } else this.scrollToTop();
  }

  get isFormFilled() {
    return this.topics.every(topic => !this.utils.checkConditionalTopic(topic, this.topics) || this.utils.checkFormFilled(topic))
  }

  get isTopicFilled() {
    let topic = this.topics[this.currentTopicIndex]
    return !this.utils.checkConditionalTopic(topic, this.topics) || this.utils.checkFormFilled(topic);
  }

  uploadFiles() {
    var promises = [];
    this.topics.forEach(topic => {
      topic["sections"].forEach(section => {
        if (section.type === 'file' && section.selectedFile) {
          for (const droppedFile of section.selectedFile) {
            if (droppedFile.fileEntry.isFile) {
              const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                if (file["uploaded"] !== true)
                  promises.push(this.upload(droppedFile, file, section));
              });
            }
          }
        }
        if (section.type === 'draw' && section.selectedPic && section["uploaded"] !== true) {
          let file = this.utils.dataURItoFile(section['selectedPic'], 'random.png');
          promises.push(this.upload(section, file, section));
        }
      });
    });
    return Promise.all(promises);
  }
  public upload(element, file, section) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('upload', file, file['name'])
      let options = {
        reportProgress: true,
        body: JSON.stringify({
          fileName: file['name'] || 'random',
          fileKey: 'upload'
        }),
        params: {
          patientId: this.patient.patientId,
          filename: file['name'] || 'random',
          fileKey: 'upload'
        }
      }
      this.formService.uploadFiles(formData, options)
        .subscribe(data => {
          if (section['type'] == 'draw')
            section.selected = data['filePath'];
          else if (Array.isArray(section.selected)) {
            section.selected.push(data['filePath']);
          }
          else {
            section.selected = [];
            section.selected.push(data['filePath']);
          }
          element['uploaded'] = true;
          resolve(true);

        }, err => {
          console.log(err);
          reject(false);
        });

    });
  }

  public goBack() {
    this.onBack.emit(null);
  }
  public openPreview() {
    this._showPreview = true;
    let topics = JSON.parse(JSON.stringify(this.topics));
    this._topicsToPreview = topics.map(t => {
      t['selectedTopic'] = false;
      return t;
    });
  }
  public goPrevFromPreview(isScroll?: boolean) {
    this._showPreview = false;
    // let topics = [...this.topics]
    // this.topics = topics.map(t => {
    //   t['selectedTopic'] = t['id'] == this.currentTopic['id'];
    //   return t;
    // });
    if (isScroll)
      setTimeout(_ => this.scrollToTop(), 500)
  }

  public addAnotherTopic(topic) {
    console.log('adding new topic', topic.id);
    const topicIndex = this.topics.lastIndexOf( t => t['id'] == topic['id']);
    let newTopic = {...topic};
    newTopic = this.formService.clearTopic(newTopic);
    if (newTopic) {
      this.topics.splice(topicIndex + 1, 0, newTopic);
      if ('noteContent' in this.concept && this.concept['noteContent'] && this.concept['noteContent']['topics'])
        this.concept['noteContent']['topics'].splice(topicIndex + 1, 0, newTopic);
    }
    let topics = this.topics.filter(t => t['id'] === topic.id);
    topic['_selectedIndex'] = topics.length - 1;
    console.log(this.topics);
  }

  public removeTopic(topic, index) {
    let i = this.topics.findIndex(el => el['id'] == topic['id']);
    topic['_selectedIndex'] = (index == 0 && topic['_selectedIndex'] == index) ? topic['_selectedIndex'] + 1 : topic['_selectedIndex'] - 1;
    if (i >= 0) {
      if (index == 0) {
        this.topics[i + 1]['multiple'] = true;
        this.topics[i + 1]['subTopic'] = false;
        this.topics[i + 1]['_selectedIndex'] = this.topics[i]['_selectedIndex'];
        this.topics.splice(i, 1);

      } else {
        i = i + index;
        this.topics.splice(i, 1);
      }
      console.log('test', this.topics, topic)
    }
  }

  public openConsultationNote(section) {
    console.log('section to open', section);
    this.consultationNoteSection = section;
    this.consultationNoteTopic = this.currentTopic;
    this.onConsultationNoteOpen.emit({
      section: section,
      topic: this.currentTopic
    })

  }

  public defaultOnFormLoad() {
    let defaults = [];
    this.params['concept']['noteContent']['topics'].forEach(t => {
      if (Array.isArray(t['sections'])) {
        t['sections'].forEach(s => {
          if (s['defaultOnFormLoad'] && s['codify'] == 'Y' && s['domainCode'])
            defaults.push(s['domainCode']);
        })
      }
    })
    if (defaults.length && this.params['patient'] && this.params['patient']['patientId']) {
      this.formService.getRecentParams(defaults, this.params['patient']['patientId']).subscribe(res => {
        if (Array.isArray(res) && res.length)
          this.applyToForm(res);
      })
    }
  }

  public applyToForm(res) {
    res.forEach(r => {
      if (this.params && this.params['concept'] && this.params['concept']['noteContent'] && Array.isArray(this.params['concept']['noteContent']['topics']))
        this.params['concept']['noteContent']['topics'].forEach(t => {
          let section = t['sections'].find(s => s['domainCode'] == r['masterCode']);
          if (section) {
            if (r['isNumeric'] === 'Y')
              section.selected = r['resultNum'];
            else {
              switch (section.type) {
                case 'date':
                  section.selected = this.user.getMoment(r['resultText']).format('YYYY-MM-DD');
                  break;
                case 'dateAndTime':
                  section.selected = this.user.getMoment(r['resultText']).format();
                  break;
                default:
                  section.selected = r['resultText'];
                  break;
              }
            }
          }
        })
    })
  }

  public onScroll() {
    const _this = this;
    setTimeout(() => {
      const topicsContainer = document.getElementById('F_Container');
      if (!topicsContainer)
        return
      const containerBounds = topicsContainer.getBoundingClientRect();
      const obs = fromEvent(topicsContainer, 'scroll');
      var ttt = [];
      obs.pipe(d => d, debounceTime(100)).subscribe(event => {
        ttt = _this.topics.filter(t => {
          let topicDOM = document.getElementById('F_T_' + t.id);
          if (topicDOM) {
            return topicDOM.getBoundingClientRect().top <= containerBounds.top + 30;
          }
          return false;
        })
        // if (Array.isArray(ttt) && ttt.length)
        //   _this.topics = _this.topics.map(t => {
        //     t['selectedTopic'] = ttt[ttt.length - 1]['id'] === t['id']
        //     return t;
        //   });
      })
    }, 100);
  }

  public selectTopic(topic) {
    this.topics = this.topics.map(t => {
      t['selectedTopic'] = false;
      return t;
    });
    topic['selectedTopic'] = true;
  }

  public changeMandatoryFilter(event) {
    this.filterMandatory = event.target.checked;
  }


  public toggleTopics(index: number, toggleMobileNav: boolean = false) {
    if (index > this.currentTopicIndex && (this.currentTopicIndex + 1 != index || !this.isTopicFilled)) {
      if (!this.isTopicFilled) {
        let message = this.translate.transform('msg_fill_mandatory_fields_topic');
        this.toast.showToast(true,message , "error")
      }
      return
    };
    this.currentTopicIndex = index;
    this.checkNextTopicIndex()
    let loopIndex = 0;
    this.topics.map((t, i) => {
      if (!this.utils.checkConditionalTopic(t, this.topics)) { 
        return;
      }
      if (index === loopIndex )
        t['_isOpen'] = true;
      else
        t['_isOpen'] = false;
      loopIndex = loopIndex + 1;
    })
    if (toggleMobileNav)
      this.toggleMobileNav();
    if (true) {
      const container = document.getElementById('F_Container');
      if (container ) {
        container.scrollTo({
          top: container.offsetTop - 10,
          left: 0,
          behavior: 'smooth'
        });
        // this.scrollToTop()
      }
    }
  }

  public toggleSideNav() {
    this.closedSideNav = !this.closedSideNav;
  }

  public toggleMobileNav() {
    this.openMobileNav = !this.openMobileNav;
  }

  public closeSaveAlert(ev) {
    if (ev && this.savedOption) {
      this.updateTask(this.savedOption.action, this.savedOption.isPrint, this.savedOption.status, true, this.savedOption.saveDraft);
    }
    this.showSaveAlert = false;
    this.savedOption = null;
  }

  public toggleActionList() {
    this.actionOpen = !this.actionOpen;
  }
  private closeActionList() {
    this.actionOpen = false;
  }

  private checkNextTopicIndex(checkOnlyValue = false) {
    const nextTopicIndex = this.topics.findIndex((topic, index) => {
      return this.utils.checkConditionalTopic(topic, this.topics) && this.currentTopicIndex < index
    })
    if (checkOnlyValue) return nextTopicIndex;
    this.nextTopicIndex = nextTopicIndex
    this.cdf.detectChanges()
  }
}
