import { Object } from './../../../app.type';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
// import { PatientService } from 'src/app/service/patient.service';
// import { User } from '../../../service/user.service';
import { ClinicalformService } from '../../../services/clinicalform.service';
@Component({
  selector: 'app-masterlinked',
  templateUrl: './masterlinked.component.html',
  styleUrls: ['./masterlinked.component.scss']
})
export class MasterlinkedComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  public masters: Array<Object>;
  public domainCodesArr: any = ['CRMATTDOCT', 'ADMITDOC', 'CRMCR2SPL', 'SPEMAST', 'CRMCNSSPL', 'CRMRFACORP', 'CRMRFATPA','CRMPRACT'];
  public showOptions:boolean=false;
  public searchText:any;
  public mastersCpy: Array<object>;
  // public selectedMasterName:any;
  public masterStackFlow:String;
  private facilityId: string;
  // public patientService: PatientService, private user: User
  constructor(private forms:ClinicalformService) {
    super(forms);
    this.masters = new Array<Object>();
    // this.facilityId = this.user.getFacilityId();
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    // this.getMasterList('');
  }

  public getMasterList(searchText:String) {
    console.log(searchText)
    if ((!this.mastersCpy) || this.masterStackFlow !== 'ALL') {
      this.forms.getMasterLinkedData(this.section['domainControls']['masterLinked']['masterCode'],searchText,this.facilityId).subscribe(data => {
        this.masters = data;
        this.mastersCpy = data;
        if(!this.showOptions)
          this.showOptions=true;

        if (!this.section['selected'])
          return;
        let selected = this.masters.find(m=> m['name'] === this.section['section']);

        if(!selected) return;
        this.section.selectedMaster = selected;
        this.section.selectedValue = selected.name;
        this.section.selectedCode = selected.code;
        this.section['selected'] = selected['name'];
        if (this.domainCodesArr.includes(this.section['domainCode']))
          this.section.selected = selected.code + '#' + selected.name;
          
      }, error => {
      }) 
    }else{
      this.masters=this.mastersCpy;
      this.showOptions=(this.showOptions ? this.showOptions=false : this.showOptions=true)
    }
  }

  public getMastersData(){
    console.log('get master',this.masterStackFlow,this.masters)
    if(this.masterStackFlow=='SERDT'){
      this.masterStackFlow='RESTDT';
      this.getMasterList('')
    }else if(this.masterStackFlow=='RESTDT'){
      this.masterStackFlow='ALL';
      this.getMasterList('')
    }
    else{
      this.getMasterList('')
      this.masterStackFlow='ALL';
    }
      this.searchText='';
      // this.showOptions=(this.showOptions ? this.showOptions=false : this.showOptions=true)
  }
  public changeText(string){
    console.log('str',string,this.masterStackFlow)
    if(string){
      if(!this.masterStackFlow || this.masterStackFlow=='SERDT'){
        if(string.length>2){
          this.masterStackFlow='SERDT';
          this.getMasterList(string)
        }else if(string.length==0 || string.length<3){
          this.masterStackFlow=null;
          this.showOptions=false;
          this.mastersCpy=null;
        }
      }else{
        this.masterStackFlow='ALL';
        this.masters=this.mastersCpy;
        this.masters = this.masters.filter((v) => {
          if (v['name'].toLowerCase().indexOf(string.toLowerCase()) > -1 || v['name'].toLowerCase().indexOf(string.toLowerCase()) > -1) {
              return true;
          }
          return false;
        });
        if(!this.showOptions)
          this.showOptions=true;
      } 
    }
  }

  public chooseMaster(selected){
    console.log(selected)
    this.showOptions=false;
    // this.selectedMasterName=selected.name;
    // let selected = this.masters.find(d => d['code'] == master.code);
    this.section.selectedMaster = selected;
    this.section.selectedValue = selected.name;
    this.section.selectedCode = selected.code.split('#') ? selected.code.split('#')[0] : selected['code'];
    this.section['selected'] = selected['name'];
    if (this.domainCodesArr.includes(this.section['domainCode']))
      this.section.selected = selected.code.split('#')? selected['code'] : selected.code + '#' + selected.name;
  }
  clearMaster(){
    // this.selectedMasterName=null;
    this.section.selected = null;
    this.section.selectedMaster = null;
    this.section.selectedValue = null;
    this.section.selectedCode = null;
    this.searchText='';
  }
}
