import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TOAST_INFO } from '@string';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { VisitService } from 'src/app/services/visit.service';
import { VisitAbstractClass } from '../visit-standalone.abstract';

@Component({
  selector: 'app-step-success',
  templateUrl: './step-success.component.html',
  styleUrls: ['./step-success.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class StepSuccessComponent extends VisitAbstractClass implements OnInit {
  @Output() restartFlow: EventEmitter<any> = new EventEmitter<any>();
  @Input() successState;
  @Input() printerConfig;
  constructor(private visitS:VisitService, private toast:ToastServiceService) {
    super();
   }

  ngOnInit(): void {
  }

  printBill() {
    this.visitS.printVisitBill({...this.visitDetails, printerId:this.queryParams['printid']}).subscribe((data:string) => {
      this.toast.showToast(null, data, TOAST_INFO);
    })
  }

  retry() {
    this.initPayment()
  }

  public initPayment() {
    const reqParams = this.visitDetails.visitRequest;
    const params = {
      amount: this.visitDetails.visitRequest.settlementAmount,
      deviceId: 'AAAAA',
      ipAddress: null,
      orderDetails: "CV_" + this.visitDetails.visitRequest.patientId,
      facilityId: this.visitDetails.visitRequest.facilityId,
      patientId: this.visitDetails.visitRequest.patientId,
      reqParams: reqParams,
      mobileNo: this.visitDetails.visitRequest.mobile,
      email: this.visitDetails.visitRequest.email,
    }
    this.visitS.startTransaction(params).subscribe(data => {
      this.navigateToCheckOut(data);
    })
  }

  private navigateToCheckOut(data) {
    let appointment = {
      _orderId: data.orderId,
      saved_card_token: null,
      saved_card_details: [],
      uhid: this.visitDetails.visitRequest.patientId,
      email: this.visitDetails.visitRequest.email,
      mobile_no: this.visitDetails.visitRequest.mobile,
      isStandalone: true
    }
    let orderId = data.orderId;
    let paymentUrl = data.transactionUrl
    let queryParams = {}
    queryParams['o'] = orderId;
    console.log(queryParams)
    let params = Object.keys(queryParams).reduce((r, p) => {
      let val = [p, queryParams[p]].join('=');
      r.push(val);
      return r;
    }, []).join('&')
    let url = window['location'];
    let diff = (href, origin) => href.split(origin).join('');


    let mainParams = {
      r: 1,
      method: 'VSTCF',
      p: btoa(params),
      fid: this.queryParams['fid'],
      sid: this.queryParams['sid'],
      pid: this.queryParams['pid'],
      printid:this.queryParams['printid']
    }
    let mainParamString = Object.keys(mainParams).reduce((r, p) => {
      if (p && mainParams[p]) {
        let val = [p, mainParams[p]].join('=');
        r.push(val);
      }
      return r;
    }, []).join('&')
    let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?${mainParamString}`;

    let paymentParams = {
      _returnURL: returnURL,
      currency: this.visitDetails.visitRequest.currency,
      charge: this.visitDetails.visitRequest.settlementAmount,
      paymentgatewayurl: paymentUrl
    }

    console.log(appointment, paymentParams)
    this.visitS.openCheckout(appointment, paymentParams);

  }

  close() {
    this.restartFlow.emit();
  }
  proceedToCreateVisit() {
    const patient = this.visitDetails.visitRequest;
    patient['patientId'] = this.visitDetails.patientId;
    patient['patientName'] = patient['firstName'] + ' ' + patient['familyName'];
    this.restartFlow.emit(patient);
  }
}
