import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { PaymentService } from '../services/payment.service';
import * as AppConstant from '../app.string';
import { UtilitiesService } from '../services/utilities.service';
import * as moment from 'moment';
import { GoogleTagsService } from '../services/google-tags.service';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import { HEALTHPACKFILTER } from '../app.const';
import { TranslatePipe } from  '../pipes/translate.pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';
@Component({
  selector: 'app-health-package',
  templateUrl: './health-package.component.html',
  styleUrls: ['./health-package.component.scss']
})
export class HealthPackageComponent implements OnInit {

  @Output() close: EventEmitter<any>;
  @Output() next: EventEmitter<any>;
  @Input() pkgdetails: any;
  @Input() selectedPackage: any;
  public searchFacility: any;
  public facilityList: Array<any>;
  public gender: any;
  public genderList:Array<any>;
  public isSelectedGender: string = 'A';
  public packs: any;
  public currency_code: string;
  public selectedFacilityId: any;
  public packages: any;
  public searchkey: string = "";
  public hideHealthPackages: boolean;
  public openPkgReq: boolean;
  public selectedPkg: any;
  public userPkgPeferrence: any;
  public reqConfirm: any;
  public showFacility: boolean;
  public selectedGenderValue: any;
  public searchGender: boolean;
  public chosenFacility: any;
  public selectedFacilityFilter: any;
  public facFilterInput = HEALTHPACKFILTER
  public selectedFacilityList: Array<any>;
  public selectedSpecialityList: Array<any>;
  public selectedGenderList: Array<any>;
  public selectedAgeList: Array<any>;

  public applicableFilter: Array<any> ; 
  public isShowExternalFilter: boolean;

  constructor(private user: UserService, private paymentService:PaymentService, private util: UtilitiesService, private gtmService:GoogleTagsService, private facilityPipe: FacilityDetailsPipe, private translate: TranslatePipe, private titleCasePipe: TitleCasePipe) {
    this.close = new EventEmitter<any>();
    this.next = new EventEmitter<any>();

    this.reqConfirm = {
      confirmReq : false,
      status: null,
      data: null
    }
    this.searchFacility = {
      show: false,
      search: '',
      selected: {},
      prevent:false
    };
    this.gender = {
      show: false,
      selected: ''
    }
    this.genderList = [
      {
        code: 'A',
        desc: 'frm_other_opt',
      },
      {
        code: 'M',
        desc: 'frm_male_opt',
      },
      {
        code: 'F',
        desc: 'frm_female_opt',
      }
    ]
    this.selectedGenderValue = this.genderList[0].desc;
   }

  ngOnInit(): void {
    this.applicableFilter = _.cloneDeep(HEALTHPACKFILTER) 
    if(this.applicableFilter[2] && this.applicableFilter[2].options.length === 0) {
      let facList = this.user.getAttributeForListingType('facilities', true)['facilities'];
      let facilityList = facList.map(a => ({'key': a.facilityId, 'label': this.titleCasePipe.transform(this.translate.transform(a.facilityName, 'LANGARRAY', 'name', null)), 'selected': false}))
      this.applicableFilter[2].options.push(...facilityList)
    }
    if(this.applicableFilter[3] && this.applicableFilter[3].options.length === 0) {
      let specialities = [];
      this.user.getUpdateInfo('facilityServices').subscribe(data => {
        data.facilities.forEach(f => {
          f['specialities'].forEach(s => {
            if (specialities.every(k => k['key'] !== s['specialityId']))
              specialities.push({
                'key': s['specialityId'],
                'label': this.translate.transform(s.specialityName, 'LANGARRAY', 'name', null)
              })
          })
        })
      });
      console.log('specialities', specialities);
      this.applicableFilter[3].options.push(...specialities)
      
    }
    console.log("pkgdetails on req 123", this.pkgdetails)
    if (this.pkgdetails) {
      this.checkOrderDetails();
      // this.openPkgReq =true;
    }
    else if (this.selectedPackage) {
      this.hideHealthPackages = true;
      this.selectedPkg = this.selectedPackage;
      console.log('selectedPackage', this.selectedPackage)
      if (Array.isArray(this.selectedPkg['detail']) && this.selectedPkg['detail'].length) {
        const obj = {
          value: +this.getDiscountChargeByFacility(this.selectedPkg),
          package_name:this.selectedPkg['name'][0]['name']
        }
        this.gtmService.pushGTMEvent('Health Packages', 'package_details', obj);
      }
    } else
      this.gtmService.pushGTMEvent('Health Packages', 'choose_package');
    // else{
      this.user.getUpdateInfo('directives').subscribe(data => {
        data.userDefined.find(def => { 
          if(def.directiveGroup == 'HOSPSERV'){
            // this.user.setStorage(AppConstant.HEALTHPKG_LIST, JSON.stringify(def));
            this.packs = def;
            this.getFacilityByConcept(this.packs['concepts']);
          }
        })
      });
      // this.user.getUpdateInfo('facilities').subscribe(data => {
      //   this.facilityList = data.facilities;
      //   this.selectFacility(this.facilityList[0]);
      // });
    // }
    this.currency_code = this.user.getCurrency();

  }

  public closeModal() {
    this.close.emit('CLOSE');
  }

  public selectFacility(value: any) {
    console.log("pkg selectFacility",value, value.facilityId)
    this.selectedFacilityId = value.facilityId
    let packsArray = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_LIST))
    this.getFiltered(packsArray['concepts']);
    if (value) {
      this.searchFacility = {
        show: false,
        search: '',
        selected: value
      }
    } 
  }

  public closeFacility(){
    this.searchFacility = {
      show: false,
    }
  }

  public selectGender(ev){
    console.log("ev htpkg", ev)
    this.isSelectedGender = ev.code;
    this.selectedGenderValue = ev.desc;
    this.searchGender = false;
    let packsArray = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_LIST))
    this.getFiltered(packsArray['concepts']);
  }

  getSubTitle(names){
    for (let l in names){
      if(names[l].locale==this.user.currentLanguage){
        return names[l].description;
      }
      else
      {
        return names[l].description;
      }
    }
  }

  getTitle(names){
    if (names.length == 0) {
        return '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

  getFiltered(data?:any){
    let arrayToFilter = data
    console.log("Before filtering", arrayToFilter)

    //Filter by gender
    // arrayToFilter = arrayToFilter.filter( item => {
    //   return item.filters.gender == this.isSelectedGender
    // })
    // console.log("After filtering Gender", arrayToFilter)

    //Filter by facility ID
    arrayToFilter = arrayToFilter.filter((item) => {
      if(item.facilities.filter((i)=>{
         if(i.facilityId==this.selectedFacilityId){
           return i
         }
      }).length>0){
        return item;
      }
    });
    console.log("After filtering Facility", arrayToFilter)

    //Filter by search keyword
    if(this.searchkey){
      arrayToFilter = arrayToFilter.filter(item => {
        // console.log("item", item.name , this.getTitle(item.name))
        return this.getTitle(item.name).toLowerCase().indexOf(this.searchkey.toLowerCase()) > -1;
      });
    }
    this.packs['concepts'] = arrayToFilter
    console.log("After final filtering", this.packs)
  }

  protected onchange(evt) {
    console.log(evt.srcElement.value," event item",evt)
    this.searchkey = evt.srcElement.value;
    let packsArray = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_LIST))
    this.getFiltered(packsArray['concepts']);
  }

  public getPackageDetail(data){
    // console.log("this.checkExpire(data.facilities)", this.checkExpire(data.facilities))
    if (!this.checkExpire(data.facilities)) return;
    else{
      this.hideHealthPackages = true;
      this.selectedPkg = data;
      this.selectedPkg['selectedFacilityId'] = this.selectedFacilityId;
      if (Array.isArray(data['detail']) && data['detail'].length) {
        const obj = {
          value: +this.getDiscountChargeByFacility(data),
          package_name:data['name'][0]['name']
        }
        this.gtmService.pushGTMEvent('Health Packages', 'package_details', obj);
      }
      
    }
  }

  public closePkgDetail(){
    this.hideHealthPackages = false;
    this.openPkgReq = false;
  }

  public openConfirm(ev){
    this.hideHealthPackages = false;
    this.openPkgReq = true;
    this.userPkgPeferrence = ev;
    this.userPkgPeferrence['notify']=  this.packs.facilities[0]['controls'];
    console.log("open cfrm", this.userPkgPeferrence)
    this.gtmService.pushGTMEvent('Health Packages', 'package_request_confirmation');
  }

  private checkOrderDetails() {
    console.log("hlthpkg checkOrderDetails", this.pkgdetails)
    let orderData = atob(this.pkgdetails);
    let arr = orderData.split('&');
    let params = {};
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    console.log("params",params)
    this.paymentService.getTransactionDetail(params['orderid']).subscribe(data => {
        console.log("getTransactionDetail", data, params['h']);
        if(data.status=='S'){
          // this.userPkgPeferrence = params['d']
          this.userPkgPeferrence = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_REQ))
          this.openPkgReq =true;
          this.reqConfirm = {
            confirmReq : true,
            status:data.status,
            data: data
          }
        }
        else if(data.status=='R' || data.status=='F' || data.status == 'P'){
          this.userPkgPeferrence = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_REQ))
          this.openPkgReq =true;
          this.reqConfirm = {
            confirmReq : true,
            status:data.status,
            data: data
          }
        }
    })
  }

  public getFacilityByConcept(concepts){
    this.facilityList = this.util.getFacilityByConcept(concepts)
    this.facilityList.map(i => {
      i.key = i['facilityId']
      i.value = this.facilityPipe.transform(i['facilityId'], 'facilityName');
    })
    this.selectFacility(this.facilityList[0])
  }

  public checkExpire(facilities){
    let facility;
    facility = facilities.find(fac => {
      return fac.facilityId == this.selectedFacilityId
    })
    if(facility){
      if(facility['expires'])
        return this.checkTime(facility['expires'])
    }else{
      facility = facilities[0]
      if(facility['expires'])
        return this.checkTime(facility['expires'])
    }
  }

  public checkTime(expire){
    if(this.user.getMoment().isBefore(expire)){
      return true;
    }
    else {
      return false;
    }
  }

  public getChargeByFacility(pack){
    let charge: any;
    pack.facilities.find( item => {
      if(item.facilityId == this.selectedFacilityId){
        this.chosenFacility = item;
      }
    })
    if(this.chosenFacility){
      charge = this.chosenFacility.controls.charge
    }
    return charge;
  }
  public getDiscountChargeByFacility(pack){
    let discountCharge:any;
    pack.facilities.find( item => {
      if(item.facilityId == this.selectedFacilityId){
        this.chosenFacility = item;
      }
    })
    if(this.chosenFacility){
      discountCharge = this.chosenFacility.controls.discountCharge
    }
    return discountCharge;
  }

  public getPackageImg(detail){
    let t = detail.find(name => {
      return name.locale == this.user.currentLanguage
    })
    if (t) {
        return t.imageURL;
    } else {
        return detail[0].imageURL;
    }
  }

  public filterPackages(event){
    // console.log("===== filterPackages", event)
    if(event)
      this.applicableFilter = event
    if(this.isShowExternalFilter){
      this.isShowExternalFilter = false
    }
  }

  public openFilterModal(ev){
    this.isShowExternalFilter = true
  }

  public checkAction(event){
    // console.log("checkAction =====>", event);
    if(event == 'CLOSE')
      this.closeModal();
  }

  public getSelectedPackageDetail(data){
    // console.log("this.checkExpire(data.facilities)", this.checkExpire(data.facilities))
    if (!this.checkExpire(data.facilities)) return;
    else{
      // this.hideHealthPackages = true;
      this.selectedPkg = data;
      this.selectedPkg['selectedFacilityId'] = this.selectedFacilityId;
      if (Array.isArray(data['detail']) && data['detail'].length) {
        const obj = {
          value: +this.getDiscountChargeByFacility(data),
          package_name:data['name'][0]['name']
        }
        this.gtmService.pushGTMEvent('Health Packages', 'package_details', obj);
      }
      this.next.emit({ data: this.selectedPkg, state: 'CHOOSE_PACKAGE', key: 'selectedPkg'} );
    }
  }
}

