import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as AppConstant from '../app.string';
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private remaindMe: boolean;
  
  public set(key: string, value: any, moment?: moment.Moment) {
    if (!key || !value)
      return;
    this.setStorage(key, value);
    return;
  }
  public setCookie(cookie: string) {
    document.cookie = cookie;
  }
  private setStorage(key: string, value: any) {
    if (this.remaindMe) {
      localStorage.setItem(key, value);
    }
    sessionStorage.setItem(key, value);
  }
  get(key: string): any {
    let value = null;
    let chunk = document.cookie.split(';');
    for (var i = 0; i < chunk.length; i++) {
      let cookie = chunk[i].split('=');
      if (cookie[0].toString().trim() == key) {
        value = cookie[1];
        break;
      }
    }
    return (typeof value == 'string' || 'number') ? value : JSON.parse(value);
  }
  public check(name: string) {
    let value = sessionStorage.getItem(name) || localStorage.getItem(name);
    if (value)
      return true;
    return false;
  }
  public clear() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var spcook = cookies[i].split("=");
      document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
    }
  }
  public setRemaindMe(val: boolean = false) {
    this.remaindMe = val;
    localStorage.setItem(AppConstant.REMAIND_ME, this.remaindMe.toString())
  }
  public clearStorage() {
    sessionStorage.clear();

    let tokens = [AppConstant.FILE_ACCESS_TOKEN, AppConstant.USER_TOKEN, AppConstant.USER_OBJECT, AppConstant.SSO_USER_OBJECT, AppConstant.SSO_CREDENTIAL, AppConstant.ACCESS_TOKEN, AppConstant.REMAIND_ME, AppConstant.REPO_TOKEN, AppConstant.EXPIRY_TIME,]
    tokens.forEach(tok => {
      localStorage.removeItem(tok);
    })
    var myItem = localStorage.getItem('HOMECAREMOCKJSON');
    var userLang = localStorage.getItem(AppConstant.USER_LANGUAGE);
    localStorage.clear();
    localStorage.setItem('HOMECAREMOCKJSON', myItem);
    localStorage.setItem(AppConstant.USER_LANGUAGE, userLang);
  }
  public getStorage(key) {
    return sessionStorage.getItem(key) || localStorage.getItem(key);
  }
  public getLocalStorage(key) {
    return localStorage.getItem(key);
  }
  public checkTime() {
    let expTime = Number(sessionStorage.getItem(AppConstant.EXPIRY_TIME)) || Number(localStorage.getItem(AppConstant.EXPIRY_TIME));
    let curTime = moment().locale('en').valueOf();
    let diff = curTime - expTime;
    if (diff >= 0) {
      this.clearStorage();
    }
    return diff < 0;
  }
  public getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
  }
  public getMoment(val?, format?) {
    if (format) {
      return moment(val, format).locale('en');
    } else if (val) {
      return moment(val).locale('en');
    } else {
      return moment().locale('en')
    }
  }
}
