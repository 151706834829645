import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { REPO_TOKEN, USER_TOKEN } from '@string';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { SpecialityDetailsPipe } from '../pipes/speciality-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { AppointmentService } from '../services/appointment.service';
import { CookieService } from '../services/cookie.service';
import { GoogleTagsService } from '../services/google-tags.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-appointment-preview',
  templateUrl: './appointment-preview.component.html',
  styleUrls: ['./appointment-preview.component.scss'],
  providers: [TranslatePipe, PartitionerDetailsPipe, SpecialityDetailsPipe]
})
export class AppointmentPreviewComponent implements OnInit {

  public state: string = null;
  public appointment: any;
  public patient: any;

  public canCancelAppt: boolean = false;
  public canVideoConsult: boolean = false;
  public canVisitConsult: boolean = false;
  public canRescheduleAppt: boolean = false;
  private facility: any;
  private appConfig: any;
  private emerNumber: any;
  private key: any;
  private fetchingAppt: boolean = false;
  public isCancelModal: boolean = false;
  public isCancelSuccessModal: boolean = false;
  public isRescheduleModal: boolean = false;
  public isRescheduleSuccessModal: boolean = false;
  public exceedCancelWindow: boolean = false;
  public exceedRescheduleWindow: boolean = false;
  public langSet: boolean = false;
  public curLang: string = 'en';
  constructor(private activatedRoute: ActivatedRoute, private user: UserService, private cookie: CookieService, private translatePipe: TranslatePipe, private gtmService: GoogleTagsService, private apptService: AppointmentService, private pracDetailsPipe: PartitionerDetailsPipe, private specDetailsPipe: SpecialityDetailsPipe) { }

  ngOnInit(): void {
    this.user.clearStorage()
    const params = this.activatedRoute.snapshot.params;
    let qplang = this.activatedRoute.snapshot.queryParams.lang;
    this.user.getLanguages().subscribe(data => {
      if (!qplang) qplang = 'en';
      let lang = data.find(d => d['localeCode'] == qplang);
      if (!lang) lang = data[0];
      this.user.setCurrentLanguage(lang);
      this.user.getLabels(true).subscribe(labels => {
        this.user.setLocaleLabels(labels);
        this.curLang = qplang;
        this.langSet = true;
      })
    })
    console.log(params);
    this.findCallNumber()
    const key = params['key'];
    this.key = key;
    if (key != 'NOTVALID') {
      this.fetchingAppt = true;
      this.validateAppointment(key);
    } else {
      this.state = 'INVALID'
    }
  }

  private findCallNumber() {
    this.user.getUpdateInfo('facilities').subscribe(data => {
      let facility = data.facilities[0];
      if (facility) {
        let dir = facility.directory[0];
        if (dir) {
          this.emerNumber = dir.number
        }
      }
    })
  }

  private validateAppointment(key) {
    this.user.validatePatientAppointment(key).subscribe(data => {
      console.log(data);
      const appointment = data['appointment']
      this.patient = data['patientInfo']
      const repoInfo = data['repoInfo']
      const token = data['token']
      this.handleAppointmentDetails(appointment, repoInfo, token);
      this.fetchingAppt = false
      if (!appointment || !appointment.appointmentId) {
        this.state = 'INVALID'
      }
    }, err => {
      this.state = 'INVALID'
      this.fetchingAppt = false
    })
  }

  private handleAppointmentDetails(appointment, repoInfo, token) {
    this.setStorage(USER_TOKEN, token['token']);
    this.setStorage(REPO_TOKEN, repoInfo['token']);
    this.user.setRepoUrl(repoInfo['url']);
    this.appointment = appointment;
    this.managePolicies();
  }

  public setStorage(key, value) {
    this.cookie.set(key, value);
  }

  private managePolicies() {
    this.user.getUpdateInfo('configuration').subscribe(data => {
      this.appConfig = data;
      this.facility = this.user.findFacility(this.appointment['facilityId']);
      this.manageCancel();
      this.manageReschedule();
      this.manageCheckin()
    })
  }

  private manageCancel() {
    this.canCancelAppt = false;
    if (this.appointment['cancelAllowed'] !== 'true') return;
    if (this.appointment['consultationStatus'] && ['FINISH', 'CANCEL'].includes(this.appointment.consultationStatus)) return;
    const policy = this.facility['facilityControls'] && this.facility['facilityControls']['schedulingRules'] && this.facility['facilityControls']['schedulingRules']['cancelPolicy'] ? this.facility['facilityControls']['schedulingRules']['cancelPolicy'] : null;
    if (!policy) return;
    const dateTimeToCheck = this.user.getMoment(this.appointment['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
    this.canCancelAppt = this.checkWithinPolicyTime(dateTimeToCheck, policy, this.facility?.facilityId);
    this.exceedCancelWindow = !this.canCancelAppt
    console.log('canCancelAppt', this.canCancelAppt);
  }

  private manageReschedule() {
    this.canRescheduleAppt = false;
    if (this.appointment['changeAllowed'] !== 'true') return;
    if (this.appointment['consultationStatus'] && ['FINISH', 'CANCEL'].includes(this.appointment.consultationStatus)) return;
    const policy = this.facility['facilityControls'] && this.facility['facilityControls']['schedulingRules'] && this.facility['facilityControls']['schedulingRules']['changePolicy'] ? this.facility['facilityControls']['schedulingRules']['changePolicy'] : null;
    if (!policy) return;
    const dateTimeToCheck = this.user.getMoment(this.appointment['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
    this.canRescheduleAppt = this.checkWithinPolicyTime(dateTimeToCheck, policy, this.facility?.facilityId);
    this.exceedRescheduleWindow = !this.canRescheduleAppt

    console.log('canRescheduleAppt', this.canRescheduleAppt);

  }

  private manageCheckin() {
    if (this.appointment['appointmentType'] == 'VIDEO') {
      this.checkVCCheckinTime()
    } else {
      this.checkIPCheckinTime();
    }
  }

  private checkVCCheckinTime() {
    this.canVideoConsult = false;
    if (this.appointment['consultationStatus'] && ['FINISH', 'CANCEL'].includes(this.appointment.consultationStatus)) return;
    let policy = null;
    if (this.appointment['isOnspot']) {
      policy = this.facility?.facilityControls?.schedulingRules?.onspotConsult?.policy;
      
    } else {
      policy = this.facility?.facilityControls?.schedulingRules?.videoConsult?.policy;
    }
    if (!policy) return;
    const dateTimeToCheck = this.user.getMoment(this.appointment['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
    this.canVideoConsult = this.checkWithinPolicyTime(dateTimeToCheck, policy, this.facility?.facilityId);
    console.log('canVideoConsult', this.canVideoConsult)
  }

  private checkIPCheckinTime() {
    this.canVisitConsult = false;
    if (!this.appConfig.features.appointmentCheckIn) return;
    if (this.appointment.checkedIn == 'true' || this.appointment.checkInAllowed !== 'true') return;
    const policy = this.facility['facilityControls'] && this.facility['facilityControls']['schedulingRules'] && this.facility['facilityControls']['schedulingRules']['checkInPolicy'] ? this.facility['facilityControls']['schedulingRules']['checkInPolicy'] : null;
    if (!policy) return;
    const dateTimeToCheck = this.user.getMoment(this.appointment['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
    this.canVisitConsult = this.checkWithinPolicyTime(dateTimeToCheck, policy, this.facility?.facilityId);
    console.log('canVisitConsult', this.canVisitConsult);
  }

  private checkWithinPolicyTime(dateTimeToCheck, policy, facilityId) {
    let validate: boolean = true;
    let currDateTime = this.user.getMoment();
    let facilityTimezone = this.user.getFacilityTimezone(facilityId);
    let lowerRefDateTime = this.user.getMoment('1999-01-01');
    let upperRefDateTime = this.user.getMoment('2099-12-31');

    if (policy) {
      if (policy.minPeriod != "") {
        lowerRefDateTime = this.getRefDateTime(policy.minPeriod, policy.minPeriodUnit, policy.minPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
      if (policy.maxPeriod != "") {
        upperRefDateTime = this.getRefDateTime(policy.maxPeriod, policy.maxPeriodUnit, policy.maxPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
    }
    if ((currDateTime.isBefore(lowerRefDateTime)) || (currDateTime.isAfter(upperRefDateTime))) {
      validate = false;
    }
    return validate;
  }
  private getRefDateTime(period, unit, direction, dateTimeToCheck, facilityTimezone) {
    let refDateTime = this.user.getMoment(dateTimeToCheck, null, null, facilityTimezone);
    if (unit == "H") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'hours');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'hours');
      }
    }
    else if (unit == "M") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'minutes');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'minutes');
      }
    }
    else if (unit == "D") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'days');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'days');
      }
    }
    return refDateTime;
  }

  public confirmCancel() {
    this.isCancelModal = true
  }

  public confirmReschedule() {
    this.isRescheduleModal = true
  }

  public confirmClose(confirm) {
    if (confirm) {
      this.cancelAppt()
    } else {
      this.isCancelModal = false;
    }
  }
  public closeReschedule(confirm) {
    if (confirm) {
      this.isRescheduleSuccessModal = true;
    }
    this.isRescheduleModal = false;
    this.validateAppointment(this.key);
  }

  public cancelAppt() {
    const practName = this.pracDetailsPipe.transform(this.appointment.resourceId, this.appointment['facilityId'], 'resourceName');
    let specId = this.pracDetailsPipe.transform(this.appointment.resourceId, this.appointment['facilityId'], 'specialityId');
    const specName = this.specDetailsPipe.transform(specId, this.appointment['facilityId'], 'specialityName');
    let data = {
      resourceId: this.appointment.resourceId,
      patientPersonId: this.appointment.personId,
      uhid: this.appointment.uhid,
      facilityId: this.appointment.facilityId,
      clinicCode: this.appointment.clinicCode,
      appointmentNumber: this.appointment.appointmentId,
      practitionerName: this.translatePipe.transform(practName, 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(specName, 'LOCALE', 'name'),
      patientName: this.appointment.personName,
      billDetails: this.appointment.billDetails, //for future reference
      // localeCode: this.resource.lan?
    }
    this.gtmService.pushGTMEvent(null, 'appointment_cancelation', {
      booking_no: this.appConfig.appointmentId
    });
    this.apptService.cancelAppt(data).subscribe(response => {
      console.log(response)
      this.isCancelModal = false;
      this.isCancelSuccessModal = true;
    })
  }

  public confirmSuccessClose() {
    this.isCancelSuccessModal = false;
    this.validateAppointment(this.key);
  }
  public confirmRescheduleClose() {
    this.isRescheduleSuccessModal = false;
  }
  public call() {
    this.user.openLocation("tel:" + this.emerNumber);
  }

  public navigateToPortal() {
    const baseurl = window.location.href.split('/#/')[0]
    const url = `${baseurl}/#/login?action=openappt&aptrefno=${this.appointment.appointmentId}`
    this.user.openLocation(url)
  }
}
