import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitStandaloneComponent } from './visit-standalone.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { StepWelcomeComponent } from './step-welcome/step-welcome.component';
import { StepSigninComponent } from './step-signin/step-signin.component';
import { StepConfirmComponent } from './step-confirm/step-confirm.component';
import { StepSuccessComponent } from './step-success/step-success.component';
import { StepInvalidComponent } from './step-invalid/step-invalid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { PartitionInputModule } from '../partition-input/partition-input.module';
import { StepRegisterReviewComponent } from './step-register-review/step-register-review.component';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';



@NgModule({
  declarations: [
    VisitStandaloneComponent,
    StepWelcomeComponent,
    StepSigninComponent,
    StepConfirmComponent,
    StepSuccessComponent,
    StepInvalidComponent,
    StepRegisterReviewComponent
  ],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule, PhoneInputModule, FormsModule, ReactiveFormsModule,
    RegisterPatientModule, PaymentCheckoutModule, SpinnerModule, PartitionInputModule, RegisterNewPatientModule
  ],
  exports:[VisitStandaloneComponent]
})
export class VisitStandaloneModule { }
