import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './svg-icon.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    SvgIconComponent
  ],
  imports: [
    CommonModule, PipesModule
  ], exports: [SvgIconComponent]
})
export class SvgIconModule { }
