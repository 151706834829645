<div #sectionReference id="section-ref-{{topic.id}}-{{section.domainCode}}" class="section"
  [ngClass]="{'category-view-type': (formViewType=='category')}">
  <div class="type">
    <div class="section-id" [ngClass]="{'section-enabled': (section._sectionIsEditable || section._sectionIsPending || formViewType !=='category')}">
      <ng-template appSection></ng-template>
    </div>
    <div class="preview" [ngClass]="{'section-enabled': formViewType=='category' && !(section._sectionIsEditable || section._sectionIsPending)}">
      <app-section-preview [section]="section" [editable]="true" [changedTimestamp]="changedTimestamp"
        (enableEdit)="enableEdit($event)">
      </app-section-preview>
      <!-- {{section.selected}} -->
    </div>
  </div>
</div>
