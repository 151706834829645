import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ServicerequestService {

  constructor(private user: UserService, private http:HttpClient) { }

  public getServiceReqList(): Observable<any> {
    console.log("service request is called");
    const payload = {
      personId: this.user.getPersonId(),
    } 
    return new Observable(observer => {
      this.http.post(environment['API_URL']+'api/services/list', payload, { reportProgress: true }).subscribe(data => {
        let responseData = this.groupList(data)
        console.log("responseData->>",responseData)
        observer.next(responseData);
      }, error => {

      })
    });
  }
  public dismissRequest(req): Observable<any> {
    const payload = {
      personId: this.user.getPersonId(),
      requestId: req.reqId
    } 

    return new Observable(observer => {
      this.http.post(environment['API_URL']+'api/services/dismissRequest', payload, { reportProgress: true }).subscribe(data => {
        observer.next(data);
      }, error => {

      })
    });
  }

  private groupList(data) {
    const groupedData = data.sort((a, b)=> {
      return new Date(b.dttm).getTime() - new Date(a.dttm).getTime();
    }).reduce((r, a) => {
      let label = moment(a.dttm).locale(this.user.getCurrentLanguage()).fromNow();
      let key = label.replace(/[ ]/g, '');
      if (!r[key]) {
        r[key] = {
          key: key,
          label: label,
          list: []
        }
      }
      r[key].list.push(a);
      return r;
    }, {});
    return Object.values(groupedData);
  }
}
