import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  constructor(private user: UserService) {}

  transform(value: string|number, format: string ='hh:mm a', inputFormat?: string, duration?:boolean) {

    // To fetch date based on locale
    // moment;

    if (duration) {
      let secs = Number(value);
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60)
      minutes = minutes % 60;
      if(format = 'mm:ss')
        return `${this.pad(minutes)}:${this.pad(secs)}`;
      else
        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    } 
    else if(format == 'fromNow'){
        let fromnow = moment(value, inputFormat).clone().locale(this.user.currentLocale).fromNow();
        return fromnow.replace(/minutes/g, "min");;
    }
    else if (format == 'SD') {
      return moment(value, inputFormat).format('dddd').toUpperCase().substring(0, 1)
    }
    else {
      return inputFormat ? this.user.getMoment(value, inputFormat).locale(this.user.currentLocale).format(format) : this.user.getMoment(value).locale(this.user.currentLocale).format(format);
    }
  }
  private pad(num) {
    return ("0" + num).slice(-2);
  }

}
