import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'personinitial'
})
export class PersoninitialPipe implements PipeTransform {

  transform(value: string): any {
    var regex = /((Mr|MR|Ms|Miss|Mrs|Dr|Sir|)\.? )?([^ ]*) ?([^ ]*)/;
    var pNameCpy = regex.exec(value);
    var personInitials = '';

    if (Array.isArray(pNameCpy) && pNameCpy.length > 0) {
      personInitials = pNameCpy[3].charAt(0) + pNameCpy[4].charAt(0);
    }
    return personInitials;
  }

}
