import { enterAnimationAxisY } from './../animations';
import { Component, OnInit } from '@angular/core';
import { ToastServiceService } from '../services/toast-service.service';
import { ToastType, ToastOption } from '../app.type';
import { TOAST_INFO, TOAST_WARNING, TOAST_ERROR, TOAST_SUCCESS } from '../app.string';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [enterAnimationAxisY]
})
export class ToastComponent implements OnInit {

  public timer;
  public toastMessage: ToastOption;
  public moreNotification: number;
  constructor(public toast: ToastServiceService) {
    this.toastMessage = null;
    this.moreNotification = 0;
  }
  ngOnInit() {
    this.toast.source.subscribe((data: ToastOption) => {
      if (this.toastMessage)
        this.moreNotification++;
      if (data)
        this.toastMessage = data;
      this.timer = setTimeout(() => {
        this.moreNotification = 0;
        this.toastMessage = null;
      }, 5000)
    });
  }

  toastColor(type: ToastType) {
    switch (type) {
      case TOAST_INFO:
        return 'blue';
      case TOAST_WARNING:
        return 'orange';
      case TOAST_ERROR:
        return 'red';
      case TOAST_SUCCESS:
        return 'green';
    }
  }

}
