import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideRating(this.section);
  }
  public rate(index) {
    this.section['selected'] = index + 1;
  }
}
