import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[bookAppoinment]'
})
export class BookappointmentDirective {

  constructor(public vcr: ViewContainerRef) { 

  }

}
