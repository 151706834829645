import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPackageDetailsComponent } from './my-package-details.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    MyPackageDetailsComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports:[MyPackageDetailsComponent]
})
export class MyPackageDetailsModule { }
