import { trigger, transition, style, animate } from '@angular/animations';

export const switchAnimation =
  trigger('switchAnimation', [
    transition(':enter', [
      style({
        height: '0px'
      }),
      animate('100ms', style({
        height: '*'
      }))
    ]),
    transition(':leave', [
      style({
        height: '*'
      }),
      animate('100ms', style({
        height: '0px'
      }))
    ])
  ])