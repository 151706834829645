import { Component, OnInit, Input, forwardRef, ElementRef, Output, EventEmitter, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FormBuilder, FormGroup } from '@angular/forms';

const COUNTER_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomInputGroupComponent),
  multi: true
};

@Component({
  selector: 'app-custom-input-group',
  templateUrl: './custom-input-group.component.html',
  styleUrls: ['./custom-input-group.component.scss'],
  providers: [COUNTER_CONTROL_ACCESSOR]
})
export class CustomInputGroupComponent implements OnInit, ControlValueAccessor {
  @Input() enableRefresh: boolean;
  @Input() fromInsurance: boolean;
  @Input() hideSearch: boolean;
  @Input() hasError: any;
  @Input() date: any;
  @Input() emit: boolean = false;
  @Input() formControlName: string;
  @Input() readonly: boolean = false;
  @Input() label: string;
  @Input() type: 'text' | 'radio' | 'number' | 'select' | 'name' | 'date' | 'password' | 'custom_radio' | 'default_radio' | 'select_dobCal' | 'checkbox' | 'select-unit' | 'tel' | 'search_select' | 'country_select_img' | 'label' | 'phonenumber' | 'textarea' | 'rating' | 'incrementnumber' = 'text';
  @Input() showOptions: boolean = false;
  @Input() optionsList: any;
  @Input() optionText: string;
  @Input() dateValue: string;
  @Input() placeholder: string;
  @Input() dateMax: string;
  @Input() characterType: string;
  @Input() relationshipList: string;
  @Input() dateMin: string;
  @Input() search: boolean = false;
  @Input() radioList: Array<any> = [];
  @Input() selectOptions: any;
  @Input() radioOptions: any;
  @Input() maxLength: number = null;
  @Input() minLength: number = null;
  @Input() icon: string;
  @Input() textValidation: 'none' | 'number' | 'alphabet' = 'none';
  @Input() options: string;
  @Input() idval: string;
  @Input() conditionalFieldValue: string;
  @Input() prefix: string;
  @Input() addclass: string = '';
  @Input() skipCountryCode: Array<string>;
  @Input() format: string;
  @Input() usePipe: boolean = false;
  @Input() pipeName: 'RELATIONFILTER' = null;
  @Input() regexValidation: string = null;
  @Input() updateDOB: boolean;
  @Input() preferWheel: boolean = false;
  @Input() resize: boolean = true;
  @Input() disableDefaultDate: boolean = false;
  @Input() incrementonly: boolean = false;
  @Input() incrementMultiple: number = 100;
  @Input() minIncrement: number = 0;
  @Input() maxIncrement: number = 600;

  searchForm: FormGroup;
  filteredOptions: string[] = [];
  items: any = [];

  @Output() blur: EventEmitter<any>;
  @Output() focus: EventEmitter<any>;
  @Output() dateChange: EventEmitter<any>;
  @Output() dropdownValue: EventEmitter<any>;
  // @Output() selectedValue: EventEmitter<any>;

  @Input() set selectList(list: any) {
    this._selectList = list || [];
  };
  get selectList(): any { return this._selectList; }
  // @Input() required: boolean = false;
  @Input() set required(list: any) {
    this._required = list;
  };
  get required(): any { return this._required; }
  @Input() set selectedValue(list: any) {
    this._selectedValue = list;
    console.log('selectedValue', list)
    if (this.type == 'search_select' && !list) {
      this.userInput = null;
    } else if (this.type == 'search_select' && list && this.selectList) {
      let userInput = this.selectList.find(s => {
        return s[this.selectOptions['value']] == list[this.selectOptions['value']];
      })
      if (userInput) {
        this.userInput = userInput
      } else {
        this.userInput = null
      }
    }
  };
  get selectedValue(): any { return this._selectedValue; }

  public nameRegex = /[^A-Za-z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF ]/g;
  private contentWrapper: ElementRef;
  public random: string;
  public selectedData: string;
  private _selectList: any = [];
  private _selectedValue: any;
  private _required: boolean = false;
  public selectedDateView: any
  public selectedCountry: any
  public placeholderPayload=[]
  @ViewChild('content', { static: false }) set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.contentWrapper = content;
    }
  }

  @ViewChild('datePicker', { static: false }) datePicker: ElementRef
  public content1 = "";
  @Output() public readonly contentChanged = new EventEmitter<string>();




  public value: any;
  public value1: any;
  public userInput: any;
  public selectedDate: any;
  public formDate: any;
  public onModelChangesource: Function;
  public onTouch: Function;
  public countryCode: Array<any>;
  public searchSelectRef: any;

  public countryList: Array<any> = []


  writeValue(value: any): void {
    let defaultvalue = this.type == 'incrementnumber' ? 0 : ''
    this.value = value || defaultvalue;
    if (this.type != 'select') {
      this.userInput = this.value;
      if (this.type == 'date') {
        console.log('date write', this.value, this.format)
        if (this.value) {
          if (this.format)
            this.formDate = moment(this.value, this.format).format()
          else
            this.formDate = moment(this.value).format()
        } else if (!this.disableDefaultDate) {
          this.formDate = moment().format()
        }

        if (this.formDate) {
          this.selectedDateView = moment(this.formDate).format('DD MMM yyyy')
          this.selectedDate = moment(this.formDate).format('DD-MM-YYYY')
        }
        if (this.datePicker && this.datePicker.nativeElement) {
          this.datePicker.nativeElement.value = moment(this.formDate).format('YYYY-MM-DD')
        }
      }
      if (this.type == 'country_select_img') {
        this.countryCode = this.value;
        if (!this.countryList || this.countryList.length == 0) {
          this.getCountryList(true)
        } else {
          this.setDefaultCountry()
        }
      }
      if (this.type == 'search_select') {
        console.log('search_select value', this.value, this.userInput);

      }
    }
    else
      this.onRegisterChange(value)
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };

    this.placeholderPayload = [
      {
        key: 'label',
        with: this.label,
        subtranslate: true
      }
    ]
  }
  private onModelChange(val) {
    if (this.onModelChangesource) {
      if (this.type == 'search_select') {
        console.log(val)
        console.trace();
      }
      if(this.emit) {
        this.dropdownValue.emit(val)
      }
      this.onModelChangesource(val)
    }
  }
  registerOnChange(fn: any): void {
    this.onModelChangesource = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  onRegisterChange(list) {
    console.log('in register change--', list, this.selectList)
    if (this.type == 'select' && list && this.selectList) {
      let userInput = this.selectList.find(s => {
        return s[this.selectOptions['value']] == list;
      })
      console.log('write metod select--', userInput, this.selectOptions)
      if (userInput) {
        this.value1 = userInput;
      }
    }
  }

  constructor(private eRef: ElementRef, private changeDetectorRef: ChangeDetectorRef, private user: UserService, private translate: TranslatePipe, private formBuilder: FormBuilder) {
    this.blur = new EventEmitter<any>();
    this.focus = new EventEmitter<any>();
    this.dateChange = new EventEmitter<any>();
    this.dropdownValue = new EventEmitter<any>();
    this.selectedValue = new EventEmitter<any>();
    this.value1 = {}
    this.random = this.user.getMoment().valueOf().toString();
    this.getCountryList()
  }

  private getCountryList(setDefaultCountry:boolean = false) {

    if (this.type == 'country_select_img') {
      this.user.getCountryList().subscribe(data => {
        let defaultCC = this.user.getDefaultCountryDetailForSignIn()
        this.countryList = data.map(d => {
          return {
            name: d['countryDesc'],
            code: d['countryCode'].toLowerCase()
          }
        })
        this.countryList.push({
          name: defaultCC[0],
          code: defaultCC[1]
        })
        if (setDefaultCountry) {
          this.setDefaultCountry()
        }
      })
    }
  }

  private setDefaultCountry() {
    console.log(this.value);
    if (this.value) {
      this.selectedCountry = this.countryList.find(c => c.code == this.value[1])
    } else {
      this.selectedCountry = null;
    }
  }

  ngOnInit(): void {
    if (this.dateValue) {
      this.selectedDate = this.user.getMoment(this.dateValue);
      // this.selectedDate = moment(this.dateValue, 'DD-MM-YYYY').format('DD-MM-YYYY');
    }
    if (!this.date) {
      if (this.dateMax) {
        this.date = this.dateMax
      } else {
        this.date = this.user.getMoment().format('YYYY-MM-DD')
      }
    }
  }

  public selectOptionChange(event) {
    console.log('dataaaaa', event, this.userInput);
    this.userInput = event.value
    this.value = event?.target?.value || event?.value;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }


  OnFocus(ev) {
    console.log("OnFocus");
  }
  OnBlur() {
    console.log("OnBlur");
  }

  public onTextInput(ev?: any) {
    console.log(ev)
    if (this.maxLength && ev.target.value.length > this.maxLength) {
      ev.target.value = ev.target.value.substring(0, this.maxLength);
      this.userInput = ev.target.value;
      if (this.type == 'number')
        this.userInput = Number(this.userInput);
    }

    if (this.type == 'checkbox') {
      this.userInput = this.radioList
    }
    let regexValidation = this.regexValidation;
    if (this.type == 'incrementnumber') {
      regexValidation = "[^0-9]"
    }
    if (regexValidation) {
      const regex = new RegExp(regexValidation, 'g')
      let value = ev.target.value;
      let newVal = value.replace(regex, '');
      ev.target.value = newVal;
      this.userInput = newVal;
    }
    this.value = this.userInput;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }

  public onCheckChange(ev) {
    this.userInput = this.radioList
    this.value = this.userInput;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }

  public onNameInput(event) {
    var value = event.target.value;
    var newVal = value.replace(this.nameRegex, '');
    event.target.value = newVal;
    this.userInput = newVal;
    this.value = this.userInput;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }
  public onBlur() {
    setTimeout(() => {
      this.showOptions = false;
    }, 300);
  }
  public onBlur1(event) {
    // console.log(event)
  }
  public onFocus() {
    this.showOptions = true;
  }
  public selectOption(x) {
    this.value = x[this.selectOptions.value];
    this.value1 = x;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }
  public vaadinChange(event) {
    this.dateChange.emit(event);
    this.selectedDate = this.user.getMoment(event['target']['__data'].value).format('DD-MM-YYYY');
    this.selectedDateView = this.user.getMoment(event['target']['__data'].value).format('DD MMM yyyy');
    if (this.onModelChange)
      this.onModelChange(this.selectedDate)
  }

  selectValue(event) {
    console.log('event selectvalue', event, this.userInput)
    this.selectedValue.emit(event)
  }
  ngAfterContentInit(): void {
    if (this.contentWrapper) {
      // console.log('contentWrapper', this.contentWrapper)
      this.content1 = this.contentWrapper.nativeElement.innerHTML;
      this.contentChanged.emit(this.content1);
    }
  }

  ngAfterContentChecked(): void {
    if (this.contentWrapper) {
      let c = this.contentWrapper.nativeElement.innerHTML;
      // console.log(c);
      if (c !== this.content1) {
        this.content1 = c;
        this.contentChanged.emit(this.content1);
      }
    }
  }

  public joinString(arr) {
    return arr.filter(a => a && a != '' && a != null && a != undefined).join(',')
  }


  public validateKey() {
    if (this.type == 'text' && this.textValidation == 'alphabet') {
      if (this.nameRegex.test(event['key'])) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  myChange(e) {
    console.log("e", e)
  }

  public charType(evt: any, type) {
    if (type == 'isNumeric') {
      let charCode = evt['which'] ? event['which'] : event['keyCode'];
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      } else {
        return true;
      }
    } else if (type == 'isAlphaNumeric') {
      let code = evt['which'] ? event['which'] : event['keyCode'];
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
        return false;
        // e.preventDefault();
      }
      else {
        return true;
      }
    }
  }

  public async refreshPayer() {
    console.log('user input--', this.userInput, this.searchSelectRef)
    // this.user.setPayerGrpSubject();
    this.userInput = this.searchSelectRef[this.selectOptions['name']];
    if (this.selectOptions.translate)
      this.userInput = await this.translate.transform(this.searchSelectRef[this.selectOptions['name']], null, null, null)

    this.value = this.searchSelectRef[this.selectOptions['value']];
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }

  get userInputValue() {
    let userInput = this.selectList.find(s => {
      return s[this.selectOptions['value']] == this.value;
    })
    if (userInput) {
      return userInput[this.selectOptions['name']]
    } else {
      return ''
    }
  }

  public onDpChange(value) {
    if (this.type == 'country_select_img') {
      this.value = [value.name, value.code]
    }
    if (this.type == 'search_select') {
      console.log('userInput', this.userInput)
      this.value = this.userInput
    }
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
  }
  public decrementCounter() {
    let value = Number(this.userInput) || 0;
    if (value <= this.minIncrement) return;
    let modVal = (value % this.incrementMultiple) || this.incrementMultiple;
    this.userInput = value - modVal
    this.updateIncrement()
  }

  public incrementCounter() {
    let value = Number(this.userInput) || 0;
    if (value >= this.maxIncrement) return;
    let modVal = value % this.incrementMultiple
    let addValue = modVal ? (this.incrementMultiple - modVal) : this.incrementMultiple
    let totValue = value + addValue
    this.userInput = totValue > this.maxIncrement ? this.maxIncrement : totValue;
    this.updateIncrement()
  }

  private updateIncrement() {
    this.value = this.userInput;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    }
  }
  public inputBlur() {
    this.blur.emit()
  }
}
