<div id="book-appointment" class="right-nav">
  <div class="component">
    <ng-container>
      <ng-container>
        <div class="header border-bottom">
          <div class="font-primary-title bold">{{ 'recently_consulted'|translate:'LABELS'}} </div>
          <div class="float-right closeicon pointer">
            <img src="./assets/icons/close_black.svg" (click)="closeModal()">
          </div>
        </div>
        <div class="appt-list-container">
          <div class="appt-list-view">
            <div class="appt-list-card" *ngFor="let item of consultAgain | sort : 'consultDttm' : 'moment'">
              <div class="appt-card-header video-header" *ngIf="item['appointmenttype'] == 'VIDEO'">
                <img class="type-image" src="assets/icons/Appointments/video.svg" alt=""> {{'Video_consultation' | translate}}</div>
              <div class="appt-card-header clinic-header" *ngIf="item['appointmenttype'] != 'VIDEO' && item['appointmentType'] != 'HC'">
                <img class="type-image" src="assets/icons/Appointments/clinic.svg" alt=""> {{'Clinic_consultation' | translate}}
              </div>
              <div class="appointment-content">
                <div class="person-avatar-wrapper">
                  <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
                </div>
                <div class="appt-context">
                  <div class="appt-title">{{(item['resourceId']|partitionerDetails:item['facility_id']:'resourceName'| translate : 'LOCALE' : 'name') || 'Doctor'}}</div>
                  <div class="appt-subtitle">{{(item['resourceId']|partitionerDetails: item['facility_id']:'specialityId') | specialityDetails: item['facility_id']:'specialityName'| translate : 'LOCALE' : 'name'}}</div>
                </div>
                <div class="item-detail">
                  <div class="timeline-button">
                      <ng-container>
                        <button class="button-primary" (click)="bookAgain(item); $event.stopPropagation();">
                          {{'consult_again' | translate}}
                        </button>
                      </ng-container>
                  </div>
                </div>
              </div>
              <div class="appointment-name-status" *ngIf="item && item['patientDetails']">
                <div class="appointment-for-content">
                  <p class="appt-for"> {{'For:' | translate}}
                    <span class="bold"*ngIf="item && item['patientDetails']">{{item['patientDetails']['personname']}}</span>
                  </p>
                </div>
                <div class="appointment-status" >
                  <!-- <p class="appt-status">
                    {{'Status:' | translate}}
                    <span class="bold appt-status__desc">{{item['consultationStatus'] || item['visitStatus']?.['status'] ||
                      item['paymentDetail']?.['status'] || '-'}} </span>
                  </p> -->
                </div>
              </div>
              <div class="appt-date-context">
                <div class="appt-date-section">
                  <img src="./assets/icons/Appointments/calendar.svg" alt="">
                  <app-appt-time type="CARD2" [time]="item['consultDttm']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facility_id'])" [facilityId]="item['facility_id']"></app-appt-time>
                </div>
                <div class="appt-date-section" *ngIf="item['appointmenttype'] !== 'VIDEO'">
                  <img src="./assets/icons/Book_new/location.svg" alt="">
                   {{item['facility_id'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
                </div>
              </div>
            </div>
            <!-- <div class="no-data-template" *ngIf="!appointment_history || !appointment_history.length">
              <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
            </div> -->
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
