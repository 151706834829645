import { ROUTE_TASK, ROUTE_VIEW } from './../app.string';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppGuardService implements CanActivate {

  constructor(private router: Router) {  }
  canActivate() {
    if (!environment['ALLOW_ONLY_TASK'] && !environment['ALLOW_ONLY_VIEW'])
      return true;
    else if (environment['ALLOW_ONLY_TASK'])
      this.router.navigate(['/' + ROUTE_TASK + '/NOTVALID']);
    else if (environment['ALLOW_ONLY_VIEW'])
      this.router.navigate(['/' + ROUTE_VIEW + '/NOTVALID']);
  }

}
