import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientBillingComponent } from './patient-billing.component';
import { PipesModule } from '../pipes/pipes.module';
import { HeaderModule } from '../header/header.module';
import { PatientBillDetailsModule } from '../patient-bill-details/patient-bill-details.module';
import { TablePaginationModule } from '../table-pagination/table-pagination.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { DirectiveModule } from '../directive/directive.module';



@NgModule({
  declarations: [PatientBillingComponent],
  imports: [
    CommonModule, PipesModule, HeaderModule, PatientBillDetailsModule, TablePaginationModule, PaymentCheckoutModule, CustomModalModule, DirectiveModule
  ],
  exports: [
    PatientBillingComponent
  ]
})
export class PatientBillingModule { }
