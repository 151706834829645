import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {

  @Input() title: string;
  @Input() url: any;
  @Output() close: EventEmitter<any>

  private viewerType: 'google' | 'office' = 'google'
  public viewerUrl: SafeResourceUrl
  constructor(private domSanitizer: DomSanitizer) {
    this.close = new EventEmitter<any>()
  }

  ngOnInit(): void {
    switch (this.viewerType) {
      case 'google':
        this.viewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${this.url}&embedded=true`);
        break;
      case 'office':
        this.viewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${this.url}`);
        break;
    }
  }

  public closeModel() {
    this.close.emit()
  }

  public startDownload() {
    var win = window.open(this.url, '_blank');
    win.focus();
  }

}
