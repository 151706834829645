import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import {EditProfileComponent} from '../edit-profile/edit-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
@NgModule({
  declarations: [EditProfileComponent],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    CustomInputGroupModule,
    PipesModule,
    FormInputErrorModule
    // CustomModalModule,
  ],
  // 
  exports: [
    EditProfileComponent
  ]
})
export class EditProfileModule { }