<div class="ui">
  <p style="margin: 0px;font-weight: 300">{{section['toggle']}} <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
  <label class="switch">
    <input (click)="toggle()" type="checkbox" [checked]="section['selected']==true" [tabindex]="tabindex">
    <span class="slider round"></span>
  </label>
  <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
    <p>{{'form_field_mandatory' | translate}}</p>
  </div>
</div>
