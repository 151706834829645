<div class="appointments-view-wrapper" view="default">
  <div class="appointments-view-header">
    <p class="appointments-title">{{'Appointments' | translate}}</p>
    <div class="appointments-actions">
      <div class="appointments-segment hp-segment">
        <div class="hp-segment-item" [ngClass]="{'selected': selectedTab == 'LIST'}" (click)="updateSelectedTab('LIST')">
          <img
            [src]="selectedTab == 'LIST' ? 'assets/icons/Appointments/card_selected.svg' :'assets/icons/Appointments/card.svg'"
            alt="">
        </div>
        <div class="hp-segment-item" [ngClass]="{'selected': selectedTab == 'CAL'}" (click)="updateSelectedTab('CAL')">
          <img
            [src]="selectedTab == 'CAL' ? 'assets/icons/Appointments/calendar_selected.svg' :'assets/icons/Appointments/calendar.svg'"
            alt="">
        </div>
      </div>
      <div class="book-appt-wrapper">
        <button class="button-primary" (click)="openBookAppointment()" [appRemoveFeatureOff]="'FT_APP_BOOK_APPT'">{{'cal_btn_book_new_appt'|translate}}</button>
      </div>
    </div>
  </div>
  <div class="appointments-view-content" *ngIf="selectedTab == 'LIST'">
    <div class="appointments-view-toolbar">
      <div class="appointments-toolbar-segment">
        <div class="toggle-option">
          <div *ngFor="let item of toggleOptions; let i=index" class="option"
            [ngClass]="{'selected': item.code == selectedType}" (click)="selectType(item)">
            {{item.label | translate}}
          </div>
        </div>
      </div>
      <div class="appointments-toolbar-segment appointment-toolbar">
        <div class="select-year-wrapper" *ngIf="selectedType == 'past'">
          <button class="button-secondary" (click)="openMenu('YEARFILTER')">
            <img class="button-prefix" src="./assets/icons/Appointments/calendar.svg" alt=""> {{selectedYear | moment : 'YYYY, MMM'}}
          </button>
          <p-calendar
            [(ngModel)]="selectedYear"
            view="month"
            dateFormat="mm/yy"
            [readonlyInput]="true"
            (onSelect)="selectYear()"
            inputId="monthpickerpast"
            id="p-calendar-past"
            class="p-calendar-past"
          ></p-calendar>
        </div>
        <div class="appt-type-wrapper">
          <button class="button-secondary" (click)="openMenu('APPTTYPE')">
            {{'Appointment_type' |translate}} <img class="button-suffix" style="transform: rotate(180deg);"
              src="./assets/icons/Home/up arrow.svg" alt="">
          </button>
          <div class="menu-list" *ngIf="showMenu">
            <ul class="menu">
              <li class="menu__item" *ngFor="let m of appointmentTypeFilters; let i = index">
                <label [for]="'menu_'+i" class="custom-checkbox__group menu__name" class="menu__checkbox">
                  <input [id]="'menu_'+i" type="checkbox" class="custom-checkbox__input menu__input" [value]="m.value" (change)="onChangeFilter($event)" [checked]="selectedTypes.includes(m.value)">
                  <span class="custom-checkbox__button"></span>
                  <span class="custom-checkbox__text">{{m.key | translate}}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="appt-list-container"*ngIf="selectedType == 'upcoming'">
      <div class="appt-list-view">
        <div class="appt-list-card" *ngFor="let item of (appointment_history | apptFilter: filterUpdatedOn :selectedTypes) | sort : 'appointmentDateTime' : 'moment'" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
          <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
            <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}} <span *ngIf="item['onspot'] == 'Y'"> - {{'Onspot' | translate}}</span>
          </div>
          <div class="appt-card-header clinic-header" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
            <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
          </div>
          <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
            <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
          </div>
          <div class="appointment-content">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
            </div>
            <div class="appt-context">
              <div class="appt-title">{{item['practitionerName']}}</div>
              <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
            </div>
            <div class="item-detail">
              <div class="timeline-button"*ngIf="item['appointmentType']">
                  <ng-container *ngIf="item['_actions']?.['actionType'] == 'CHECKIN' && !item['_actions']?.canRejoin">
                    <button class="button-primary" (click)="handleActions(item,item['_actions']?.['actionType'], null); $event.stopPropagation();">
                      {{'cal_modal_btn_checkin'|translate}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="item['_actions']?.['actionType'] == 'CHECKIN' && item['_actions']?.canRejoin">
                     <button class="button-primary" (click)="handleActions(item,item['_actions']?.['actionType'], null); $event.stopPropagation();">
                      {{'cal_modal_btn_join'|translate}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="item['_actions']?.['actionType'] == 'PAYCHECKIN'">
                    <button class="button-primary" (click)="handleActions(item,item['_actions']?.['actionType'], null); $event.stopPropagation();">
                      {{'cal_modal_btn_pay_and_checkin'|translate}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="item['_actions']?.['actionType'] == 'PAY'">
                    <button class="button-primary" (click)="handleActions(item,item['_actions']?.['actionType'], null); $event.stopPropagation();">
                      {{'appt_but_pay'|translate}}
                    </button>
                  </ng-container>
              </div>
            </div>
          </div>
          <div class="appointment-name-status">
            <div class="appointment-for-content">
              <p class="appt-for"> {{'For:' | translate}} 
                <span class="bold">{{item['personName']}}</span>
              </p>
                <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span></p> -->
            </div>
            <div class="appointment-status">
              <p class="appt-status">
                {{'Status:' | translate}}
                <span class="bold appt-status__desc">{{item['consultationStatus'] || item['visitStatus']?.['status'] ||
                  item['paymentDetail']?.['status'] || '-'}} </span>
              </p>
            </div>
          </div>
          <div class="appt-date-context">
            <div class="appt-date-section">
              <img src="./assets/icons/Appointments/calendar.svg" alt="">

               <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
            </div>
            <div class="appt-date-section" *ngIf="item['appointmentType'] !== 'VIDEO'">
              <img src="./assets/icons/Book_new/location.svg" alt="">
               {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
            </div>
          </div>
        </div>
        <div class="no-data-template" *ngIf="!appointment_history || !appointment_history.length">
          <!-- {{'No_appointments_found' | translate}} -->
          <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
        </div>
      </div>
    </div>
    <div class="appt-list-container"*ngIf="selectedType == 'past'">
      <div class="appt-list-title" *ngIf="toggleOptions[2]['showCompleted']">{{'Completed' | translate}}</div>
      <div class="appt-list-view" *ngIf="toggleOptions[2]['showCompleted']">
        <div class="appt-list-card" *ngFor="let item of ((pastCompleted | apptFilter: filterUpdatedOn:selectedTypes) | sort : 'appointmentDateTime' : 'moment')"  (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
          <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
            <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}} <span *ngIf="item['isOnspot']"> - {{'Onspot' | translate}}</span>
          </div>
          <div class="appt-card-header clinic-header" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
            <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
          </div>
          <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
            <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
          </div>
          <div class="appointment-content">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
            </div>
            <div class="appt-context">
              <div class="appt-title">{{item['practitionerName']}}</div>
              <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
            </div>
          </div>
          <div class="appointment-name-status">
            <div class="appointment-for-content">
              <p class="appt-for"> {{'For:' | translate}} 
                <span class="bold">{{item['personName']}}</span>
              </p>
                <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span></p> -->
            </div>
            <div class="appointment-status">
              <p class="appt-status"> 
                {{'Status:' | translate}} 
                <span class="bold appt-status__desc">{{(statusMap[item['consultationStatus'] || item['visitStatus']?.['status'] || item['paymentDetail']?.['status']] || '-') | translate}} </span>
              </p>
            </div>
          </div>
          <div class="appt-date-context">
            <div class="appt-date-section">
              <img src="./assets/icons/Appointments/calendar.svg" alt="">

               <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
            </div>
            <div class="appt-date-section" *ngIf="item['appointmentType'] !== 'VIDEO'">
              <img src="./assets/icons/Book_new/location.svg" alt="">
               {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
            </div>
          </div>
        </div>
        <div class="no-data-template" *ngIf="!pastCompleted || !pastCompleted.length">
          <!-- {{'No_appointments_found' | translate}} -->
          <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
        </div>
      </div>
      <div class="appt-list-title" *ngIf="toggleOptions[2]['showMissed']">{{'Missed' | translate}}</div>
      
      <div class="appt-list-view" *ngIf="toggleOptions[2]['showMissed']">
        <div class="appt-list-card" *ngFor="let item of ((pastMissed | apptFilter: filterUpdatedOn :selectedTypes) | sort : 'appointmentDateTime' : 'moment')" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
          <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
            <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}} <span *ngIf="item['isOnspot']"> - {{'Onspot' | translate}}</span>
          </div>
          <div class="appt-card-header clinic-header" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
            <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
          </div>
          <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
            <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
          </div>
          <div class="appointment-content">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
            </div>
            <div class="appt-context">
              <div class="appt-title">{{item['practitionerName']}}</div>
              <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
            </div>
          </div>
          <div class="appointment-name-status">
            <div class="appointment-for-content">
              <p class="appt-for"> {{'For:' | translate}} 
                <span class="bold">{{item['personName']}}</span>
              </p>
                <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span></p> -->
            </div>
            <div class="appointment-status">
              <p class="appt-status"> 
                {{'Status:' | translate}} 
                <span class="bold appt-status__desc">{{(statusMap[item['consultationStatus'] || item['visitStatus']?.['status'] || item['paymentDetail']?.['status']] || '-') | translate}} </span>
              </p>
            </div>
          </div>
          <div class="appt-date-context">
            <div class="appt-date-section">
              <img src="./assets/icons/Appointments/calendar.svg" alt="">

               <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
            </div>
            <div class="appt-date-section" *ngIf="item['appointmentType'] !== 'VIDEO'">
              <img src="./assets/icons/Book_new/location.svg" alt="">
               {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
            </div>
          </div>
        </div>
        <div class="no-data-template" *ngIf="!pastMissed || !pastMissed.length">
          <!-- {{'No_appointments_found' | translate}} -->
          <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
        </div>
      </div>
      <div class="appt-list-title" *ngIf="toggleOptions[2]['showCancelled']">{{'Cancelled' | translate}}</div>
      
      <div class="appt-list-view" *ngIf="toggleOptions[2]['showCancelled']">
        <div class="appt-list-card" *ngFor="let item of ((pastCancelled | apptFilter: filterUpdatedOn:selectedTypes) | sort : 'appointmentDateTime' : 'moment')" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
          <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
            <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}} <span *ngIf="item['isOnspot']"> - {{'Onspot' | translate}}</span>
          </div>
          <div class="appt-card-header clinic-header" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
            <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
          </div>
          <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
            <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
          </div>
          <div class="appointment-content">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
            </div>
            <div class="appt-context">
              <div class="appt-title">{{item['practitionerName']}}</div>
              <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
            </div>
          </div>
          <div class="appointment-name-status">
            <div class="appointment-for-content">
              <p class="appt-for"> {{'For:' | translate}} 
                <span class="bold">{{item['personName']}}</span>
              </p>
                <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span></p> -->
            </div>
            <div class="appointment-status">
              <p class="appt-status"> 
                {{'Status:' | translate}} 
                <span class="bold appt-status__desc">{{ 'cancelled' | translate}} </span>
              </p>
            </div>
          </div>
          <div class="appt-date-context">
            <div class="appt-date-section">
              <img src="./assets/icons/Appointments/calendar.svg" alt="">

               <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
            </div>
            <div class="appt-date-section" *ngIf="item['appointmentType'] !== 'VIDEO'">
              <img src="./assets/icons/Book_new/location.svg" alt="">
               {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
            </div>
          </div>
        </div>
        <div class="no-data-template" *ngIf="!pastCancelled || !pastCancelled.length">
          <!-- {{'No_appointments_found' | translate}} -->
          <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
        </div>
      </div>
    </div>
    <div class="appt-list-container"*ngIf="selectedType == 'inpg'">
      <div class="appt-list-view">
        <div class="appt-list-card" *ngFor="let item of inProgress | sort : 'appointmentDateTime' : 'moment'" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
          <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
            <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}} <span *ngIf="item['isOnspot']"> - {{'Onspot' | translate}}</span>
          </div>
          <div class="appt-card-header clinic-header" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
            <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
          </div>
          <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
            <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
          </div>
          <div class="appointment-content">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
            </div>
            <div class="appt-context">
              <div class="appt-title">{{item['practitionerName']}}</div>
              <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
            </div>
          </div>
          <div class="appointment-name-status">
            <div class="appointment-for-content">
              <p class="appt-for"> {{'For:' | translate}} 
                <span class="bold">{{item['personName']}}</span>
              </p>
                <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span></p> -->
            </div>
            <div class="appointment-status">
              <p class="appt-status"> 
                {{'Status:' | translate}} 
                <span class="bold appt-status__desc">{{(statusMap[item['consultationStatus'] || item['visitStatus']?.['status'] || item['paymentDetail']?.['status']] || '-') | translate}} </span>
              </p>
            </div>
          </div>
          <div class="appt-date-context">
            <div class="appt-date-section">
              <img src="./assets/icons/Appointments/calendar.svg" alt="">
               <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
            </div>
            <div class="appt-date-section" *ngIf="item['appointmentType'] !== 'VIDEO'">
              <img src="./assets/icons/Book_new/location.svg" alt="">
               {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
            </div>
          </div>
        </div>
        <div class="no-data-template" *ngIf="!inProgress || !inProgress.length">
          <!-- {{'No_appointments_found' | translate}} -->
          <ng-container [ngTemplateOutlet]="NOAPPT"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="appointments-view-content" *ngIf="selectedTab == 'CAL'">
    <div class="calendar-wrapper">
      <div class="calendar-header">
        <div class="appointments-view-toolbar">
          <div class="calendar-header-segment">
            <img class="button-action cursor-pointer" (click)="chooseDate('PREV')"  src="./assets/icons/Home/left arrow.svg" alt="">
            <p-calendar
              [(ngModel)]="datePickerValue"
              view="month"
              dateFormat="mm/yy"
              [readonlyInput]="true"
              [minDate]="minDate"
              (onSelect)="onDateChange()"
              inputId="monthpicker"
              id="p-calendar-upcoming"
              class="p-calendar-custom"
            ></p-calendar>
            <button class="button-secondary">
              <img class="button-prefix" src="./assets/icons/Appointments/calendar.svg" alt=""> {{selectedMonth| moment:'MMM YYYY'}}
            </button> <img class="button-action cursor-pointer"  (click)="chooseDate('NEXT')" 
              src="./assets/icons/Home/right arrow.svg" alt="">
          </div>
          <div class="calendar-header-segment cursor-pointer" (click)="gotoDate()">
            <button class="button-secondary sm-hidden1">
              {{'Go_to_today' |translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="calendar-content">
        <div class="calendar" *ngIf="calendarOptions">
          <full-calendar [options]="calendarOptions" #calendar>
          </full-calendar>
          <ng-template #fcEventContent let-appt='appt'>
            <p>{{appt}}</p>
          </ng-template>
        </div>
      </div>
      <div class="timeline-view md-hidden">
        <div class="timeline-date-wrapper" *ngFor="let items of appointment_history | groupBy:'appointmentDateTime':'DATE'">
          <div class="timeline-date">
            <div class="date-value">{{items[0].appointmentDateTime |moment :'DD':'YYYY-MM-DD HH:mm'}}</div>
            <div class="month-value">{{items[0].appointmentDateTime |moment :'MMM':'YYYY-MM-DD HH:mm'}}</div>
          </div>
          <div class="appointments-wrapper">
            <div class="appointment-item cursor-pointer" *ngFor="let item of items" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
              <div class="action-icon video-icon" *ngIf="item['appointmentType'] == 'VIDEO'">
                <img class="type-image" src="assets/icons/Appointments/video.svg" alt="">
              </div>
              <div class="action-icon clinic-icon" *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
                <img class="type-image" src="assets/icons/Appointments/clinic.svg" alt="">
              </div>
              <div class="action-icon hc-icon" *ngIf="item['appointmentType'] == 'HC'">
                <img class="type-image" src="assets/icons/Appointments/HC.svg" alt="">
              </div>
              <div class="appointment-info">
                <p class="pract-name">{{item['practitionerName']}}</p>
                <p class="facility-name">{{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="book-appt-footer">
  <button class="button-primary" (click)="openBookAppointment()" [appRemoveFeatureOff]="'FT_APP_BOOK_APPT'">{{'cal_btn_book_new_appt'|translate}}</button>
</div>
<div id="upcoming-appointment" view="royal-hayat">
  <div class="appointment-calendar-card">
    <div class="calendar-card-header border-bottom">
      <div class="header-title bold font-default-title">{{'lbl_my_calendar' | translate}}</div>
    </div>
    <div class="appointment-lists">
      <div class="appointment-table">
        <div class="appointment-table-row table-header border-bottom">
          <!-- hidden till view past appointment is confirmed -->
          <div class="appointment-table-cell font-default-small1" *ngFor="let item of tableHeaders">{{item['label'] |
            translate}}</div>
        </div>
        <div class="appointment-table-row table-content"
          *ngFor="let item of appointment_history | sort : 'appointmentDateTime' : 'moment'">
          <div class="appointment-table-cell" [ngStyle]="{'grid-area': header.class }"
            [ngClass]="{'hide-for-mobile':header.code == 'action'}" *ngFor="let header of tableHeaders">
            <ng-container [ngSwitch]="header.code">
              <div class="font-primary-subtitle" *ngSwitchCase="'practitioner'">
                <div *ngIf="item.practitionerLocalName">
                  {{item.practitionerLocalName | translate :translateType : 'name'}}
                </div>
                <div *ngIf="!item.practitionerLocalName && item.practitionerName">
                  {{item.practitionerName}}
                </div>
                <div *ngIf="!item.practitionerLocalName && !item.practitionerName">
                  {{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType
                  : 'name') || 'Doctor'}}
                </div>
              </div>
              <div class="font-light-small" *ngSwitchCase="'speciality'">
                {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' |
                specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}
              </div>
              <div class="font-light-small" *ngSwitchCase="'date'">
                {{item.appointmentDateTime | moment : 'DD MMM YYYY, hh:mm a':'YYYY-MM-DD HH:mm'}}</div>
              <div class="font-default-subtitle" *ngSwitchCase="'patient'">{{ item['personName'] }}</div>
              <div class="font-light-small" *ngSwitchCase="'type'">{{'lbl_appttype_'+item['appointmentType'] |
                translate}}</div>
              <div *ngSwitchCase="'action'">
                <div class="action-view">
                  <img (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')" class="view-image"
                    src="assets/icons/homecare/eye_active.svg">
                </div>
              </div>
              <div *ngSwitchDefault>item[header]</div>
            </ng-container>
          </div>
          <div (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')" class="click-overlay"></div>
        </div>
        <div class="appointment-table-row table-nodate" *ngIf="!appointment_history || appointment_history.length == 0">
          <div class="appointment-table-cell font-default-small1">{{'No_data_found' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDataTemplate>
  <div class="no-data-template">
    {{'No_appointments_found' | translate}}
  </div>
</ng-template>

<ng-template #NOAPPT>
  <div class="icon">
    <img class="button-prefix" src="./assets/icons/Appointments/noappt.svg" alt="">
  </div>
  <div class="noappt-text-ttl font-default-title bold margin">{{'No_appointments_found' | translate}}</div>
  <div class="noappt-text-sttl font-light-small1">{{'No_appointments_found_desc' | translate}}</div>

</ng-template>

<app-custom-modal-container id="calendar-appointment-details-modal" [isCommonView]="isCommonView"
  (complete)="closeApptDetail()">
  <app-upcoming-appointment-detail *ngIf="appointmentDetail" [viewAppointment]="appointmentData"
    [isCommonView]="isCommonView" (reschedule)="openApptView($event)" (expressReschedule)="openExpressView($event)"
    (uploadQuestionnarie)="openuploadQuestionnarie($event)" (uploadDocs)="openuploadDocs($event)"
    [initCheckin]="initCheckin">
  </app-upcoming-appointment-detail>
</app-custom-modal-container>
<app-book-appointment *ngIf="isApptView" (close)="closeApptView($event)" [rescheduleData]="rescheduleData"
  [orderdetails]="orderdetails" [prefillData]="prefillData"></app-book-appointment>
<app-book-appointment *ngIf="isBookAppointment" (close)="closeBookAppointment($event)" [reserve]="bookingAttributes"
  [orderdetails]="orderdetails">
</app-book-appointment>
<app-register-new-patient *ngIf="isRegisterPatient" (close)="closeRegisterPatient()"></app-register-new-patient>

<app-custom-modal-container id="preconsult-questionnaire-modal" (close)="closeuploadQuestionnarie()">
  <app-appointment-upload-questionnarie [selectedAppt]="selectedAppt" *ngIf="isUploadQuestionnarie"
    (close)="closeuploadQuestionnarie()"></app-appointment-upload-questionnarie>
</app-custom-modal-container>

<app-custom-modal-container id="upload-document-modal" (close)="closeuploadDocs()">
  <app-hr-document-upload *ngIf="isUploadDocs" [selectedPatient]="selectedAppt" [appointment]="selectedAppt"
    (close)="closeuploadDocs()">
  </app-hr-document-upload>
</app-custom-modal-container>
<app-express-clinic-modal *ngIf="isExpress" (close)="closeExpressClinic()"
  [rescheduleData]="rescheduleData"></app-express-clinic-modal>
 <app-home-care-services *ngIf="homeServices" (close)="closehomeServices($event)"></app-home-care-services>

<app-map [routeItem]="routeItem" (complete)="closeMap()" *ngIf="openRoute"></app-map>
