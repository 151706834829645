import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeFeedbackComponent } from './home-feedback.component';
import { ClinicalformModule } from '../clinicalform/clinicalform.module';
import { FormpreviewModule } from '../formpreview/formpreview.module';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [HomeFeedbackComponent],
  imports: [
    CommonModule, ClinicalformModule, FormpreviewModule, PipesModule
  ], exports: [
    HomeFeedbackComponent
  ]
})
export class HomeFeedbackModule { }
