import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpcomingAppointmenDetailComponent } from './upcoming-appointment-detail.component';
import {PipesModule} from '../pipes/pipes.module';
import { ListItemModule } from '../list-item/list-item.module';
import {StageComponentComponentModule} from '../stage-component/stage-component.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import {CouponModule} from '../coupon/coupon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApptDtlCardModule } from '../appt-dtl-card/appt-dtl-card.module';
import { DisplayCurrencyModule } from '../components/display-currency/display-currency.module';
import { AltpaymentInputModule } from '../components/altpayment-input/altpayment-input.module';
import { PromoInputModule } from '../components/promo-input/promo-input.module';
@NgModule({
  declarations: [UpcomingAppointmenDetailComponent],
  imports: [
    CommonModule,PipesModule,ListItemModule,StageComponentComponentModule, PersonAvatarModule, PaymentCheckoutModule,
    PractitionerAvatarModule,
    CouponModule, FormsModule, ReactiveFormsModule, ApptDtlCardModule, DisplayCurrencyModule, AltpaymentInputModule, PromoInputModule
  ],
  exports:[UpcomingAppointmenDetailComponent]
})
export class UpcomingAppointmentDetailModule { }