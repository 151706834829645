import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentReviewComponent } from './appointment-review.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ApptDtlCardModule } from '../appt-dtl-card/appt-dtl-card.module';


@NgModule({
  declarations: [AppointmentReviewComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule,
    PractitionerAvatarModule,
    ApptDtlCardModule
  ]
})
export class AppointmentReviewModule { }
