<p class="section-title">{{'Patient_details' | translate}}</p>
<div class="section-card payment-due">
    <div class="section-item" *ngIf="regParams['firstName']">
        <p class="section-label">{{'First_name' | translate}} </p>
        <p class="section-value">{{regParams['firstName']}}</p>
    </div>
    <div class="section-item" *ngIf="regParams['familyName']">
        <p class="section-label">{{'Last_name' | translate}} </p>
        <p class="section-value">{{regParams['familyName']}}</p>
    </div>
    <div class="section-item" *ngIf="regParams['gender']">
        <p class="section-label">{{'Gender' | translate}} </p>
        <p class="section-value">{{regParams['gender'] == 'M'? 'Male' :'Female' }}</p>
    </div>
    <div class="section-item" *ngIf="regParams['dob']">
        <p class="section-label">{{'Date of birth' | translate}} </p>
        <p class="section-value">{{regParams['dob'] | moment:'D MMM YYYY':'YYYY-MM-DD' }}</p>
    </div>
    <div class="section-item" *ngIf="regParams['bloodgroup']">
        <p class="section-label">{{'Blood_group' | translate}} </p>
        <p class="section-value" style="text-transform: capitalize;">{{regParams['bloodgroup'] }}</p>
    </div>
    <div class="section-item" *ngIf="regParams['nationality']">
        <p class="section-label">{{'Nationality' | translate}} </p>
        <p class="section-value">{{regParams['nationality'] == 'IND' ? 'Indian' :'Others'}}</p>
    </div>
    <div class="section-item" *ngIf="regParams['mobile']">
        <p class="section-label">{{'Mobile number' | translate}} </p>
        <p class="section-value">+{{regParams['countryCode']}} {{regParams['mobile']}}</p>
    </div>
    <div class="section-item" *ngIf="regParams['email']">
        <p class="section-label">{{'Email' | translate}} </p>
        <p class="section-value">{{regParams['email']}}</p>
    </div>
</div>
<p class="section-title">{{'Registration_charges' | translate}}</p>
<div class="section-card payment-due">
    <div class="section-item">
        <p class="section-label">{{'Amount' | translate}} </p>
        <p class="section-value">{{visitCharges.currency}} {{visitCharges?.registrationCharges || 0}}</p>
    </div>
</div>
<div class="section-button-wrapper">
    <button class="section-action" (click)="initPayment()">{{'Proceed_to_Pay' | translate}}</button>
</div>