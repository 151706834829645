import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { environment } from '@env/environment';
import { FeatureFlagsService } from '../services/feature-flags.service';
@Component({
  selector: 'app-appt-dtl-card',
  templateUrl: './appt-dtl-card.component.html',
  styleUrls: ['./appt-dtl-card.component.scss']
})
export class ApptDtlCardComponent {
  @Output() polciyExpireAction: EventEmitter<any>;
  @Input() flowType:any;
  @Input() showpolicyInfo:boolean;
  @Input() fromSlotBlockPrompt:boolean;
  @Input() apptInfo: any;
  @Input() scheduleCost: any;
  @Input() type: string;
  @Input() isReferralApplicable: boolean;
  @Input() refControl: any;
  @Output() editAction: EventEmitter<any>;
  @Output() complete: EventEmitter<any>;
  @Input() isEditApplicable: boolean;
  public translateType = 'LOCALE';
  public currency_code: string;
  public linkWithoutRelation: boolean;
  public result: any = {
    "referralCode": ""
  };
  public enterReferral: boolean;
  public regexValidation: string = null;

  constructor(private user: UserService,  private featureService: FeatureFlagsService) {
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.editAction = new EventEmitter<any>();
    this.complete = new EventEmitter<any>();
    this.polciyExpireAction = new EventEmitter<any>();

  }

  ngOnInit(){
    console.log('isReferralApplicable', this.isReferralApplicable)
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
    if(!this.apptInfo['appointmentType'] && this.apptInfo?.in?.home) {
      this.apptInfo['appointmentType'] = this.apptInfo?.in?.home?.visitType;
    }
  }

  public onTextInput(ev?: any) {
    console.log('event',ev)
    let regexValidation = this.refControl.regex;
    if (regexValidation) {
      const regex = new RegExp(regexValidation, 'g')
      let value = ev.target.value;
      let newVal = value.replace(regex, '');
      ev.target.value = newVal;
      this.result.referralCode = newVal;
    }
  }

  switchAction(type){
    this.editAction.emit(type);
  }

  competeAction(){
    this.complete.emit(this.result);
  }

  public removeReferral() {
    this.result.referralCode = ''
  }

  public openPolicyExpirePop(){
    this.polciyExpireAction.emit();
  }
}
