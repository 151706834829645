import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-video-consult-tc",
  templateUrl: "./video-consult-tc.component.html",
  styleUrls: ["./video-consult-tc.component.scss"],
})
export class VideoConsultTCComponent implements OnInit {
  @Output() complete: EventEmitter<any>;
  @Output() close: EventEmitter<any>;
  public tandc: any;
  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.close = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getUpdateInfo('directives').subscribe(data => {
      let tandc = data.userDefined.find(def => {
        return def.code == 'VIDCONSTC';
      });
      if (tandc && tandc.concepts && tandc.concepts.length > 0) {
        this.tandc = tandc.concepts[0].detail;
      }
    });
  }

  public proceed() {
    this.complete.emit();
  }

  closeModal() {
    this.close.emit()
  }
}
