<img class="logo" src="assets/images/signin/logo_grey.png" alt="">
<p class="welcome-text">{{'Welcome_to' | translate}}</p>
<p class="facility-name">{{facility?.facilityName | translate}}</p>
<p class="selected-team">{{practitioners?.[0]?.clinicName | translate}}</p>
<p class="select-hint">{{'Please_choose_doctor_from_below_list' | translate}}</p>
<div class="doctor-cards-wrapper">
    <div class="doctor-card" (click)="choosePract(p)" *ngFor="let p of practitioners" [ngClass]="{unavailable: !isWorking(p)}">
        <div class="doctor-card-main">
            <div class="image">
                <app-person-avatar [url]="p.photoUrl" [name]="p['practitionerName']"> </app-person-avatar>
            </div>
            <div class="doctor-card-content">
                <p class="doc-name">{{p['practitionerName']}}</p>
                <p class="doc-text">{{p['clinicName']}}</p>
                <ng-container *ngIf="isAvailable(p) && isWorking(p); else nextAvailableTemp">
                    <p class="doc-text">{{'Currently_Available' | translate}}</p>
                </ng-container>
                <ng-template #nextAvailableTemp>
                    <p class="doc-text" *ngIf="!isAvailable(p) && isWorking(p)">
                      {{'Next_available_in' | translate}} {{getNextAvailableTime(p)}}</p>
                    <p class="doc-text unavailable" *ngIf="!isWorking(p)">{{'Currently_Unavailable' | translate}}
                    </p>
                </ng-template>
            </div>
            <div class="doc-card-arrow">
                <img src="./assets/icons/drop_arrow_grey.svg" alt="">
            </div>
        </div>
    </div>
    <div *ngIf="practitionerState == 'C' && (!practitioners || practitioners.length == 0)" class="practitioner-state">
        <p class="unavailable-doctors font-primary-default">{{'Doctors_unavailable_currently' | translate}}
        </p>
    </div>
    <div *ngIf="practitionerState == 'E'" class="practitioner-state">
        <p class="unavailable-doctors font-primary-default">{{'Error_getting_practitioner_details' | translate}}
        </p>
    </div>
    <div *ngIf="practitionerState == 'P'" class="practitioner-state">
        <p class="unavailable-doctors font-primary-default">{{'Fetching_pracitioner_details' | translate}}
        </p>
        <app-spinner></app-spinner>
    </div>
</div>