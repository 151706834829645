<div id="appointment-visit-type">
  <div class="service-list-wrapper" [appRemoveFeatureOff]="'FT_APP_BOOK_VC_APPT'">
    <p class="service-list-header" (click)="showVideoOpt = !showVideoOpt">{{'Tele_medicine' | translate}}
      <img class="down-arrrow md-hidden" src="./assets/icons/Book_new/arrow drop.svg">
    </p>
    <div class="service-list" [ngClass]="{'show-service': showVideoOpt}">
      <div class="service-list-item" (click)="chooseType('VIDEO')">
        <div class="service-icon">
          <img src="./assets/icons/Home/vc.svg" alt="">
        </div>
        <div class="service-context">
          <p class="service-label">
            {{'hme_lbl_online_consultation' | translate}}
          </p>
          <p class="service-desc">
            {{'hme_lbl_online_consultation_desc' | translate}}
          </p>
        </div>
      </div>
      <div class="service-list-item" (click)="chooseType('VIDEO-CONSULT-NOW')" *ngIf="isOnspot">
        <div class="service-icon">
          <img src="./assets/icons/Home/onspot.svg" alt="">
        </div>
        <div class="service-context">
          <p class="service-label">
            {{'hme_lbl_consult_doctor_now'|translate}}
          </p>
          <p class="service-desc">
            {{'hme_lbl_consult_doctor_now_desc'|translate}}
          </p>
        </div>
      </div>
    </div>

  </div>
  <div class="service-list-wrapper clinic" [appRemoveFeatureOff]="'FT_APP_BOOK_DIRECT_APPT'">
    <p class="service-list-header" (click)="showClinicOpt = !showClinicOpt">{{'Clinic_and_Home_care' | translate}}
      <img class="down-arrrow md-hidden" src="./assets/icons/Book_new/arrow drop.svg">
    </p>
    <div class="service-list" [ngClass]="{'show-service': showClinicOpt}">
      <div class="service-list-item" (click)="chooseType('HOSPITAL')">
        <div class="service-icon">
          <img src="./assets/icons/Home/clinicc.svg" alt="">
        </div>
        <div class="service-context">
          <p class="service-label">
            {{'Clinic_appointment' | translate}}
          </p>
          <p class="service-desc">
            {{'hme_lbl_clinic_appointment_desc' | translate}}
          </p>
        </div>
      </div>
        <!-- express and home care -->
      <div class="service-list-item" (click)="chooseType('EXPRESS')">
        <div class="service-icon">
          <img src="./assets/icons/Home/care express.svg" alt="">
        </div>
        <div class="service-context">
          <p class="service-label">
            {{'Nahdi_care_express' | translate}}
          </p>
          <p class="service-desc">
            {{'hme_lbl_clinic_appointment_desc' | translate}}
          </p>
        </div>
      </div>

      <div class="service-list-item" (click)="chooseType('HOME-CARE')">
        <div class="service-icon">
          <img src="./assets/icons/Home/home health copy.svg" alt="">
        </div>
        <div class="service-context">
          <p class="service-label">
            {{'Home_health_care_services' | translate}}
          </p>
          <p class="service-desc">
            {{'hme_lbl_clinic_appointment_desc' | translate}}
          </p>
        </div>
      </div>

    </div>
  </div>
</div>
