import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthPackagePreviewComponent } from './health-package-preview.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    HealthPackagePreviewComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports:[HealthPackagePreviewComponent]
})
export class HealthPackagePreviewModule { }
