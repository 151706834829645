<div id="appointments">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img src="assets/icons/hos.svg" />
        </div>
        <div class="font-default-title title">{{'sub_recent_visits' | translate}}</div>
      </div>
    </div>
    <div class="list">
      <app-list-item *ngFor="let item of [1,2,3]">
        <img class="thumbnail" src="assets/icons/bed.svg" *ngIf="item==1" />
        <img class="thumbnail" src="assets/icons/ambulance.svg" *ngIf="item==2" />
        <img class="thumbnail" src="assets/icons/facility.svg" *ngIf="item==3" />
        <span
          class="title">
          <img class="prefix"  src="assets/icons/bed.svg" *ngIf="item==1" />
          <img class="prefix" src="assets/icons/ambulance.svg" *ngIf="item==2" />
          <img class="prefix" src="assets/icons/facility.svg" *ngIf="item==3" />Dr Sara Scholz</span>
        <span
          class="sub-title">General Medicine</span>

        <span class="primary-content">
          <img src="assets/icons/calendar-timer.svg">
          <span>30 Apr(20 days)</span>
        </span>
        <span class="secondary-content">
          <img src="assets/icons/doctor.svg">
          <span>1st floor, ICU Bed</span>
        </span>
        <span class='list-action'>
          Mr Johns Williams
        </span>
      </app-list-item>
    </div>
  </div>
</div>
