import { Component, OnInit, Input ,ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { ModalService} from '../services/modal.service';
import { UserService } from '../services/user.service';
import { HealthRecordsService } from '../services/health-records.service';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import * as moment from 'moment';
import { jsPDF } from 'jspdf'
// import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas'; 
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl  } from '@angular/platform-browser';
// import * as rasterizeHTML from 'rasterizehtml';
import { UtilitiesService } from '../services/utilities.service';
@Component({
  selector: 'app-hr-visit-bill-details',
  templateUrl: './hr-visit-bill-details.component.html',
  styleUrls: ['./hr-visit-bill-details.component.scss'],
  providers: [PartitionerDetailsPipe, TranslatePipe, FacilityDetailsPipe]
})
export class HrVisitBillDetailsComponent implements OnInit {
  
  @Input() viewBillDetails: any;
  @Input() selectedBill: any;
  @Input() selectedPatient:any
  @Input() isCommonView:any
  @Input() selectedReceipt: any
  @Output() close: EventEmitter<any>
  // @ViewChild('billDetail', {static: false}) billDetail: ElementRef;
  public viewPatientDetail;
  public bill;
  public billPreview;
  public billPreviewTemplate = '';
  // template: any;
  @ViewChild('content', {static: false}) content: ElementRef;
  constructor(private modalService:ModalService, private user: UserService,
     private hrService:HealthRecordsService,private practitionerPipe: PartitionerDetailsPipe,
     private translatePipe: TranslatePipe, private facilityPipe: FacilityDetailsPipe,
     private sanitizer: DomSanitizer, private utility: UtilitiesService
  ) {
    this.close = new EventEmitter<any>();
     }
      
  ngOnInit(): void {
    if(this.selectedReceipt) {
      this.viewPatientDetail = this.selectedPatient;
      this.getReceiptHtml();
    } else {
      this.viewPatientDetail = this.viewBillDetails['billHdr'][0];
      this.bill = this.viewBillDetails['billTotal'][0] ;
      this.getBillHtml();
    }
  }

  closeModal(id: string){
    this.modalService.close(id);
    this.close.emit();
  }

  initDownload(){
    this.formDownloadData(this.viewPatientDetail,this.billPreviewTemplate);
  }

  public captureScreen()  
  {  
    var data = document.getElementById('bill_content');  //Id of the table
    window.scrollTo(0,0); 
    html2canvas(data, { 
      allowTaint: true,
      useCORS: true, 
      logging: false,
      height: window.outerHeight + window.innerHeight + data.scrollHeight,
      width:  window.outerWidth + window.innerWidth + data.scrollWidth ,
      backgroundColor: null,

     })
    .then((canvas) => {      
        const imgData = canvas.toDataURL('image/png');
        console.log("imgData",imgData)
       var doc = new jsPDF('p', 'pt', 'a4');
      //  var doc = new jsPDF('p', 'pt', 'a4');
       doc.addImage(imgData, 'PNG', 20, 20, window.outerWidth + window.innerWidth + data.scrollWidth, window.outerHeight + window.innerHeight + data.scrollHeight);
       doc.save('test.pdf');
    });
  } 

  
  getBillHtml(){
    let data = {
      data: this.viewBillDetails,
      reqBody:{
        facilityId: this.viewPatientDetail['facilityId'],
        patientId: this.viewPatientDetail['patientId'],
        settlementDocRef: '',
        settlementType: '',
        chargeIndicator:'Y',
        card:true
      },
      settledVisit:{
        billNo: this.selectedBill.billNo,
        receiptNo: ''
      },
      patientId: this.viewPatientDetail['patientId'],
      localeCode: this.user.currentLanguage
    }
    this.hrService.generateBillHtml(data).subscribe(
      data =>{
        this.billPreview = {
          content: data.printFormat
        }
        this.billPreviewTemplate = data.printFormat
      }
    )
    
  }

  private getReceiptHtml() {
    this.hrService.generateRecieptHtml(this.viewPatientDetail['facilityid'], this.viewPatientDetail['uhid'], this.selectedReceipt).subscribe(response => {
      this.billPreview = {
        content: response
      }
      this.billPreviewTemplate = response;
    })
  }

  private getPDF(data) {
    this.user.convertToPDF(data).subscribe(data => {
      let link = data['downloadLink'];
      this.user.openLocation(link, '_blank')
    })
  }

  private formDownloadData(selectedReport, downloadcontent) {
    console.log("downloadcontent",this.selectedPatient)
    let practitioner = this.practitionerPipe.transform(selectedReport['practitionerId'], selectedReport['facilityId'], 'resourceName');
    let practitionerName = this.translatePipe.transform(practitioner, 'LOCALE', 'name');
    let facility = this.facilityPipe.transform(selectedReport['facilityId'], 'facilityName');
    let facilityName = this.translatePipe.transform(facility, 'LOCALE', 'name')
    if (selectedReport['format'] == 'FORMFORMAT') {
      // downloadcontent = downloadcontent['topics'];
    }
    let data = {
      content: downloadcontent,
      facilityName: facilityName,
      patientName: this.selectedPatient['personname'],
      uhid: this.selectedPatient['uhid'],
      personId: this.selectedPatient['personid'],
      gender: this.selectedPatient['gender'] == 'M' ? 'Male' : this.selectedPatient.gender == 'F' ? 'Female' : '',
      repDateTime: this.user.getMoment(selectedReport['reportDateTime']).format("DD-MM-YYYY hh:mm a"),
      age: this.user.getMoment().diff(this.user.getMoment(this.selectedPatient['dob']), 'years'),
      reportedBy: selectedReport['authorizedBy'],
      isShare: false,
      filename: "random.pdf",
      reportType: 'CLOBFORMAT',
      // reportType: 'FORMFORMAT',
      recordType: selectedReport['reportType'],
      encounterId: selectedReport['encounterId'],
      specialtyCode: selectedReport['specialtyCode'],
      entityId: selectedReport['entityid'],
      doctorName: practitionerName,
      // reportName: selectedReport['format'] == 'JSONFORMAT' ? selectedReport['eventGroupDesc'] : selectedReport['eventDesc']
    }
    
    console.log("bill download data", data)
    this.getPDF(data);
  }

  public initPrint() {
    // let popupWinindow
    // popupWinindow = this.user.openLocation('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    // popupWinindow.document.open();
    // popupWinindow.document.write(this.billPreviewTemplate);
    // popupWinindow.document.close();
    // popupWinindow.focus();
    // popupWinindow.print();
    // popupWinindow.close();

    this.utility.print(this.billPreviewTemplate, 'text/html')
  }
}
