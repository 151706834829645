import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastType } from '../app.type';

@Injectable({
  providedIn: 'root'
})
export class ToastServiceService {
  source = new BehaviorSubject<Object>(false);
  ob = this.source.asObservable();
  

  showToast(state: boolean, msg: string, type: ToastType = 'info', translate: boolean = false) {
    this.source.next({ state: state, msg: msg, type: type ? type : type, translate: translate });
  }
}
