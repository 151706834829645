import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { VisitService } from 'src/app/services/visit.service';
import { VisitAbstractClass } from '../visit-standalone.abstract';
import * as _ from 'lodash';
@Component({
  selector: 'app-step-confirm',
  templateUrl: './step-confirm.component.html',
  styleUrls: ['./step-confirm.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class StepConfirmComponent extends VisitAbstractClass implements OnInit {
  @Output() visitCreated = new EventEmitter();
  @Output() initPay = new EventEmitter();
  @Input() queryParams;

  constructor(private visitS:VisitService) {
    super();
   }

  ngOnInit(): void {
  }

  nextStep() {

  }

  public initPayment() {
    const reqParams = this.generateVisitParams();
    const params = {
      amount: this.visitCharges.patientPayable.toString(),
      deviceId: 'AAAAA',
      ipAddress: null,
      orderDetails: "CV_" + this.selectedPatient?.patientId,
      facilityId: this.facility.facilityId,
      patientId: this.selectedPatient?.patientId,
      reqParams: reqParams,
      mobileNo: this.selectedPatient?.mobile,
      email: this.selectedPatient?.email
    }
    this.visitS.startTransaction(params).subscribe(data => { 
      this.navigateToCheckOut(data);
    })
  }

  private navigateToCheckOut(data) {
    let appointment = {
      _orderId: data.orderId,
      saved_card_token: null,
      saved_card_details: [],
      uhid: this.selectedPatient?.patientId,
      email: this.selectedPatient?.email,
      mobile_no: '',
      isStandalone: true
    }
    let orderId = data.orderId;
    let paymentUrl = data.transactionUrl
    let queryParams = {}
    queryParams['o'] = orderId;
    console.log(queryParams)
    let params = Object.keys(queryParams).reduce((r, p) => {
      let val = [p, queryParams[p]].join('=');
      r.push(val);
      return r;
    }, []).join('&')
    let url = window['location'];
    let diff = (href, origin) => href.split(origin).join('');


    let mainParams = {
      r: 1,
      method: 'VSTCF',
      p: btoa(params),
      fid: this.queryParams['fid'],
      sid: this.queryParams['sid'],
      pid: this.queryParams['pid'],
      printid:this.queryParams['printid']
    }
    let mainParamString = Object.keys(mainParams).reduce((r, p) => {
      if (p && mainParams[p]) {
        let val = [p, mainParams[p]].join('=');
        r.push(val);
      }
      return r;
    }, []).join('&')
    let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?${mainParamString}`;

    let paymentParams = {
      _returnURL: returnURL,
      currency:  this.visitCharges?.currency,
      charge: this.visitCharges?.patientPayable,
      paymentgatewayurl: paymentUrl
    }

    console.log(appointment, paymentParams)
    this.visitS.openCheckout(appointment, paymentParams);
    // this.initPay.emit({appointment, paymentParams})

  }

  createFollowupVisit() {
    const params = this.generateVisitParams();
    this.visitS.createFollowupVisit(params).subscribe(data => {
      console.log('data');
      this.visitDetails = params;
      this.visitDetails['encounterId'] = data['encounterId'];
      this.visitDetails['status'] = 'S';
      this.visitCreated.emit(this.visitDetails);
    })
  }
  generateVisitParams(paymentRef?: string) {
    let body = {
      facilityId: this.facility.facilityId,
      appointmentNo: null,
      clinicCode: this.selectedPract?.clinicCode,
      clinicName: this.selectedPract?.clinicName,
      practitionerId: this.selectedPract.practId,
      practitionerName: this.selectedPract.practitionerName,
      visitTypeCode: this.visitCharges?.visitType,
      billingGroupCode: this.visitCharges?.billingGroupCode,
      patientId: this.selectedPatient?.patientId,
      customerCode: null,
      settlementAmount: this.visitCharges?.patientPayable || 0,
      patientName: this.selectedPatient?.patientName,
      mobile: this.selectedPatient?.mobile,
      email: this.selectedPatient?.email,
      adjustDeposit: 'N',
      isPromoCodeAppliedYn: "N",
      isCouponAppliedYn: "N",
      locale: 'en',
      currency: this.visitCharges?.currency,
      freefollowUpYn:this.visitCharges?.isFreeVisit
    }
    if (this.visitCharges?.isFreeVisit !== 'Y' && this.visitCharges.patientPayable > 0) {
      if (paymentRef) {
        if (paymentRef.includes('|ONLINE')) {
          body['paymentType'] = 'ONLINE';
          body['settlementType'] = 'ONLINE';
          body['settlementDocRef'] = paymentRef.split('|ONLINE')[0];
        } else {
          body['paymentType'] = 'PAYTM';
          body['settlementType'] = 'PAYTM';
          body['settlementDocRef'] = paymentRef;
        }
        body['bankCode'] = '';
        // body['transactionId'] = this.orderId;
      }
    } else {
      body['paymentType'] = 'CA';
      body['settlementType'] = 'CA';
      body['settlementDocRef'] = null;
      body['bankCode'] = null;
    }
    // if (this.retryCount === 0)
    //   body['registrationParams'] = this.generateRegistrationParams(paymentRef);
    return body;
  }


  
}
