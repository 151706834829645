import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApptTimeComponent } from './appt-time.component';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    ApptTimeComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [ApptTimeComponent]
})
export class ApptTimeModule { }
