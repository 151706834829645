import { Component, OnInit, EventEmitter, ViewChild, ComponentFactoryResolver, Input, Output } from '@angular/core';
import { BookappointmentDirective } from '../directive/bookappointment.directive';
import { AppointmentRecentComponent } from '../appointment-recent/appointment-recent.component';
import { SearchFacilityComponent } from '../search-facility/search-facility.component'
import { SearchSpecialityComponent } from '../search-speciality/search-speciality.component';
import { SearchPractitionerComponent } from '../search-practitioner/search-practitioner.component';
import { ChooseSlotComponent } from '../choose-slot/choose-slot.component';
import { LinkedPatientsComponent } from '../linked-patients/linked-patients.component';
import { AppointmentConfirmComponent } from '../appointment-confirm/appointment-confirm.component';
import { inOutData, flowType, discountType } from '../app.type';
import { AppointmentService } from '../services/appointment.service';
import { PaymentService } from '../services/payment.service';
import { AppointmentReviewComponent } from '../appointment-review/appointment-review.component';
import { visitType, apptFlowType } from '../app.type';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { ModalService } from '../services/modal.service';
import { Subject } from "rxjs";
import { OnspotConsultDoctorComponent } from '../onspot-consult-doctor/onspot-consult-doctor.component';
import { OnspotConsultationService } from '../services/onspot-consultation.service';
import { COUPON_PARAMS, PROMO_PARAMS } from '../app.const';
import { COUPON } from '../coupon/coupon.component';
import { GoogleTagsService } from '../services/google-tags.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { SearchFSPComponent } from '../search-f-s-p/search-f-s-p.component';
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss'],
  providers: [PartitionerDetailsPipe]
})
export class BookAppointmentComponent implements OnInit {
  @ViewChild(BookappointmentDirective, { static: true }) bookAppoinment: BookappointmentDirective;
  @Output() close: EventEmitter<any>;
  refresh: Subject<boolean> = new Subject<boolean>();
  refreshFlip: Subject<Object> = new Subject<Object>();
  @Input() reserve: any;
  @Input() orderdetails: any;
  @Input() rescheduleData: any;
  @Input() prefillData: any;
  @Input() chooseSlot:any;
  @Input() expressdetails: any;
  @Input() ongoingAppts: any;
  @Input() isReferral: boolean;
  @Input() referralDetail: any;
  @Input() bookingAttr: any;
  @Input() skipPatientSelect: boolean = false;
  @Output() openLogin = new EventEmitter();
  public apptParams: any;
  public facilityList: any;
  public isManageIdentities: boolean;
  public hasBreadcrumbs: boolean;
  public hasComponent: boolean;
  public bookingAttributes: Object;
  public _index: number;
  public state: 'CHOOSE_TYPE' | 'START_SELECTION' | 'PAYMENT_CHECKOUT' | 'ONSPOT' | 'EXPRESS_CLINIC' | 'REFERRAL_DETAIL' | 'HOME_CARE_SERVICE';
  public visitType: visitType;
  public showTC: boolean;
  public tandc: any;
  public currentTimeline: Array<any>;
  public apptInfo: any;
  public eventInfo: any;
  public isCoupon: any;
  public isFlip: boolean = false;
  public couponData: any;
  public promoData: any;
  public couponParams: any = COUPON_PARAMS;
  public promoParam: any;
  public isCouponType: any;
  public promoInputs: any;
  public couponInputs: any;
  public isPromoApplied: boolean;
  public isCouponApplied: boolean;
  public promoApplicable: boolean;
  public couponApplicable: boolean;
  public isDiscountType: discountType;
  private patientBasedApptbooking: boolean;
  public isPolicyValidated: any;
  public flowType: apptFlowType;
  public flow: Array<flowType> = []
  public isRegisterPatient: boolean;
  // public couponList: Array<any> = [];
  public flowAvailable: Array<flowType> = [
    {
      name: "Home",
      code: "HOME",
      component: AppointmentRecentComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        let next = event['home'] ? 1 : 0;
        let add = this.patientBasedApptbooking?0:0
        return next+add
      },
      attribute: 'home',
      timeLineView: true,
      hide: () => {
        return false;
      },
    },
    {
      name: "lbl_select_patient",
      code: "PATSEL",
      gtmEvent: 'select_person',
      component: LinkedPatientsComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        let next = event['patient']['name'] ? 2 : 1;
        let add = this.patientBasedApptbooking ? 0 : 0
        return next + add
      },
      attribute: 'patient',
      timeLineView: true,
      hide: () => {
        return !this.patientBasedApptbooking;
      }
    },
    {
      name: "lbl_search_clinic_practitioner",
      code: "FACSEARCH",
      gtmEvent: 'choose_clinic',
      component: SearchFSPComponent,
      isEditable: () => {
        return false;
      },
      fullfillment:this.FSPFullfillment.bind(this),
      attribute: 'facility',
      timeLineView: true,
      hide: () => {
        return false;
      },
    },
    {
      name: "lbl_search_speciality",
      code: "SPECSEARCH",
      gtmEvent: 'choose_specialty',
      component: SearchFSPComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: this.FSPFullfillment.bind(this),
      attribute: 'speciality',
      timeLineView: true,
      hide: () => {
        return false;
      },
    },
    {
      name: "lbl_search_practitioner",
      code: "PARTSEARCH",
      gtmEvent: 'choose_practitioner',
      component: SearchFSPComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: this.FSPFullfillment.bind(this),
      attribute: 'practitioner',
      timeLineView: true,
      hide: () => {
        return false;
      },
    },
    {
      name: "lbl_choose_slot",
      code: "SLOTCHOSE",
      gtmEvent: 'select_date_time',
      component: ChooseSlotComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        let slotBlock=this.apptService.getSlotBlockRouteInfo()
        console.log('choose slot fullfillment', event,this.bookingAttributes)
        let nxt = (this.flowType == 'RESCHEDULE' || this.skipPatientSelect || (slotBlock && this.bookingAttributes['patient'])) ? 6 : 5;
        let next = event['slot']['date'] && event['slot']['slot'] ? nxt : 4;
        let add = (this.patientBasedApptbooking && this.flowType != 'RESCHEDULE')? 1 : 0
        return next + add
      },
      attribute: 'slot',
      timeLineView: true,
      hide: () => {
        return false;
      }
    },
    {
      name: "lbl_select_patient",
      code: "PATSEL",
      gtmEvent: 'select_person',
      component: LinkedPatientsComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        return event['patient']['name'] ? 6 : 5
      },
      attribute: 'patient',
      timeLineView: true,
      hide: () => {
        return this.patientBasedApptbooking;
      }
    },
    {
      name: "lbl_appointment_confirm",
      code: "CONFAPPT",
      gtmEvent: 'payment',
      component: AppointmentConfirmComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        return event['confirm'] ? 7 : 6
      },
      attribute: 'confirm',
      timeLineView: true,
      hide: () => {
        return false;
      }
    },
    {
      name: "lbl_appointment_review",
      code: "APPTRVW",
      component: AppointmentReviewComponent,
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        return -1
      },
      timeLineView: false,
      hide: () => {
        return false;
      }
    }
  ]
  public timeline = [
    {
      name: "Home",
      attribute: 'home',
      index: 0,
      map: () => { return 0},
      translate: false,
      timeLineView: () => {
        return false
      },
      icon: {
        active: '',
        inactive: '',
        current: ''
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Select Patient",
      attribute: 'patient',
      index: 1,
      map: () => { return 0 },
      translate: false,
      placeholder: "select_patient",
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/user_white.svg',
        inactive: 'assets/icons/bookappt/user_grey.svg',
        current: 'assets/icons/bookappt/user_grey.svg'
      },
      hide: () => {
        return !this.patientBasedApptbooking;
      }
    },
    {
      name: "Search Facility",
      attribute: 'facility',
      index: 1,
      map: (val) => { return val ? 1 : 0 },
      translate: 'LOCALE',
      placeholder: 'choose_facility',
      timeLineView: () => {
        this.user.getUpdateInfo('facilities').subscribe(data => {
          this.facilityList = data.facilities;
        })
        if (this.visitType == 'VIDEO' || this.flowType == 'RESCHEDULE' || this.facilityList.length == 1) {
          return false;
        } else {
          return true;
        }
      },
      icon: {
        active: 'assets/icons/bookappt/pin_white.svg',
        inactive: 'assets/icons/bookappt/pin_grey.svg',
        current: 'assets/icons/bookappt/pin_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Search Speciality",
      attribute: 'speciality',
      index: 2,
      map: (val) => { return val ? 1 : 0 },
      translate: 'LOCALE',
      placeholder: 'choose_speciality',
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/hermes_white.svg',
        inactive: 'assets/icons/bookappt/hermes_grey.svg',
        current: 'assets/icons/bookappt/hermes_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Search Practitioner",
      attribute: 'practitioner',
      translate: 'LOCALE',
      placeholder: 'choose_practitioner',
      index: 3,
      map: (val) => { return val ? 1 : 0 },
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/doctor_white.svg',
        inactive: 'assets/icons/bookappt/doctor_grey.svg',
        current: 'assets/icons/bookappt/doctor_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Choose Slot",
      attribute: 'slot',
      translate: 'MOMENT',
      placeholder: 'choose_slot',
      index: 4,
      map: (val) => { return val ? 1 : 0 },
      timeLineView: () => {
        return true
      },
      icon: {
        active: 'assets/icons/bookappt/calendar_white.svg',
        inactive: 'assets/icons/bookappt/calendar_grey.svg',
        current: 'assets/icons/bookappt/calendar_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Select Patient",
      attribute: 'patient',
      index: 5,
      map: (val) => { return 0 },
      translate: false,
      placeholder: "select_patient",
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/user_white.svg',
        inactive: 'assets/icons/bookappt/user_grey.svg',
        current: 'assets/icons/bookappt/user_grey.svg'
      },
      hide: () => {
        return this.patientBasedApptbooking;
      }
    },
    {
      name: "Appointment Confirm",
      attribute: 'confirm',
      index: 6,
      map: (val) => { return 0 },
      translate: "LABELS",
      placeholder: "confirm_appt",
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/tick_white.svg',
        inactive: 'assets/icons/bookappt/tick_grey.svg',
        current: 'assets/icons/bookappt/tick_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Confirm Reschedule",
      attribute: 'confirm',
      index: 6,
      map: (val) => { return 0 },
      translate: "LABELS",
      placeholder: "confirm_reschedule",
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return true;
        } else {
          return false;
        }
      },
      icon: {
        active: 'assets/icons/bookappt/tick_white.svg',
        inactive: 'assets/icons/bookappt/tick_grey.svg',
        current: 'assets/icons/bookappt/tick_grey.svg'
      },
      hide: () => {
        return false;
      }
    },
    {
      name: "Appointment Review",
      index: 7,
      map: (val) => { return 0 },
      placeholder: "confirm_appt",
      timeLineView: () => {
        return false
      },
      icon: {
        active: 'assets/icons/bookappt/',
        inactive: 'assets/icons/bookappt/',
        current: 'assets/icons/bookappt/'
      },
      hide: () => {
        return false;
      }
    }
  ]
  isLoggedIn: boolean;
  constructor(private cfr: ComponentFactoryResolver, private apptService: AppointmentService, private paymentService: PaymentService, private user: UserService, private pracDetailsPipe: PartitionerDetailsPipe,
    public modalService: ModalService, private onspotService: OnspotConsultationService, private gtmService: GoogleTagsService, private feature: FeatureFlagsService) {
    this.patientBasedApptbooking = this.feature.featureOn('FT_APP_BOOKWITHRESTRICTIONS');
    this.close = new EventEmitter<any>();
    this.timeline = this.timeline.filter(time => !time.hide()).map(time => {
      let add = time.map(this.patientBasedApptbooking)
      time.index += add;
      return time;
    });
    this.flow = this.flowAvailable.filter(flow => !flow.hide())
    console.log("flow->>",this.flow)
    this._index = 1;
    this.bookingAttributes = {};
  }

  ngOnInit() {
    console.log('bookingAttributes', this.bookingAttr);
    this.isLoggedIn = this.user.isLoggedIn();
    this.constructResponseObject();
    this.precheckComponent();
    this.user.getUpdateInfo('directives').subscribe(data => {
      let tandc = data.userDefined.find(def => {
        return def.code == 'APPTTS';
      });
      if (tandc && tandc.concepts && tandc.concepts.length > 0) {
        this.tandc = tandc.concepts[0].detail;
      }
    });
  }
  private constructResponseObject() {
    this.flow.forEach((item, index) => {
      this.bookingAttributes[item['attribute']] = null;
    });
  }
  private precheckComponent() {
    if (this.reserve) {
      this.state = "START_SELECTION";
      this.flowType = "BOOKING";
      this.bookingAttributes = this.reserve;
      this.selectVisitType(this.bookingAttributes['home']['visitType']);
      if (this.bookingAttributes['slot']) {
        let add = this.patientBasedApptbooking ? 1 : 0;
        this._index = 5 + add;
      }
      this.loadComponent(this._index);
    } else if (this.orderdetails) {
      this.state = "START_SELECTION";
      this.checkOrderDetails();
    } else if (this.rescheduleData) {
      this.state = "START_SELECTION";
      this.flowType = 'RESCHEDULE';
      this.initRescheduleData();
      let add = this.patientBasedApptbooking ? 1 : 0;
      this._index = 4 + add;
      this.loadComponent(this._index);
    } else if (this.prefillData) {
      if (this.prefillData.type == 'VIDEO-CONSULT-NOW') {
        this.selectVisitType(this.prefillData.type);
      }
      else if (this.prefillData.type == 'EXPRESS') {
        this.selectVisitType(this.prefillData.type);
      }
      else {
        this.state = "START_SELECTION";
        this.flowType = "BOOKING";
        this.selectVisitType(this.prefillData.type);
      }
    } else if (this.chooseSlot) {
      this.rescheduleData = this.chooseSlot
      this.state = "START_SELECTION";
      this.flowType = 'BOOKING';
      this.initExternalScheduleData();
      if(this.rescheduleData['slot']) {
        this._index = 5;
      } else if (this.rescheduleData.resourceId) {
        this._index = 4;
      } else if (this.rescheduleData.specialityId) {
        this._index = 3;
      } else if (this.rescheduleData.facilityId) {
        this._index = 2;
      } else {
        this.selectVisitType(this.bookingAttributes['home']['visitType']);
      }
      if(this.bookingAttr) {
        let add = this.patientBasedApptbooking ? 1 : 0;
        this._index = 4 + add;
        this.bookingAttributes = this.bookingAttr;
        console.log('bookingAttr', this.bookingAttr, this.bookingAttributes, this._index);
      }
      this.loadComponent(this._index);
    } else if (this.isReferral) {
      this.state = "REFERRAL_DETAIL";
    } else {
      this.state = "CHOOSE_TYPE";
      this.flowType = "BOOKING";
    }

  }
  public removeComponent() {
    let viewContainerRef = this.bookAppoinment.vcr;
    viewContainerRef.clear();
  }
  closePayment() {
    this.state = 'START_SELECTION'
    this.loadComponent(this._index);
    console.log('closePayment');
    this.initUnBlockSlot();
  }
  public loadComponent(index: number, msg?: any) {
    if (index <= 5) {
      this.promoApplicable = false;
      this.couponApplicable = false;
      this.promoData = null;
      this.couponData = null;
    }
    if (this.flow[index]['gtmEvent'] && this.flowType !=='RESCHEDULE')
      this.gtmService.pushGTMEvent(this.visitType == 'VIDEO' ? 'Online Consultation' : 'Clinic Appointments', this.flow[index]['gtmEvent']);
    let componentFactory = this.cfr.resolveComponentFactory(this.flow[index]['component']);
    let viewContainerRef = this.bookAppoinment.vcr;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<ExBookApptComponent>componentRef.instance)._input = <inOutData>{ in: this.bookingAttributes };
    (<ExBookApptComponent>componentRef.instance).visitType = this.visitType;
    (<ExBookApptComponent>componentRef.instance).flowType = this.flowType;
    (<ExBookApptComponent>componentRef.instance).msg = msg;
    (<ExBookApptComponent>componentRef.instance).couponData = this.couponData;
    (<ExBookApptComponent>componentRef.instance).promoData = this.promoData;
    (<ExBookApptComponent>componentRef.instance).couponApplicable = this.couponApplicable;
    (<ExBookApptComponent>componentRef.instance).promoApplicable = this.promoApplicable;
    (<ExBookApptComponent>componentRef.instance).isDiscountType = this.isDiscountType;
    (<ExBookApptComponent>componentRef.instance).patientBasedApptbooking = this.patientBasedApptbooking;
    (<ExBookApptComponent>componentRef.instance).isReferral = this.isReferral;
    (<ExBookApptComponent>componentRef.instance).referralDetail = this.referralDetail;
    (<ExBookApptComponent>componentRef.instance).isPolicyValidated = this.isPolicyValidated;
    (<ExBookApptComponent>componentRef.instance).settings = {
      hidePatientHeader: true
    };
    (<ExBookApptComponent>componentRef.instance).complete.subscribe((event: inOutData['out']) => {
      Object.assign(this.bookingAttributes, event);
      // if (this._index == 5) {
      //   this.showTC = true;
      // }
      if (this.flow[index]['fullfillment'](event) != index  && this.flowType !=='RESCHEDULE')
        this.updateGTMEvents(index, event);
      this._index = this.flow[index]['fullfillment'](event);
      if (this._index == -1) {
        this.closeModel(true);
      } else {
        this.loadComponent(this._index);
      }
    });
    (<ExBookApptComponent>componentRef.instance).action.subscribe((event: any) => {
      console.log('action', event);
      if (event.name == 'toggleRegisterPatient') {
        this.closeModel({ bookingAttributes: this.bookingAttributes });
      } else if (event.name == 'openPaymentCheckout') {
        this.state = 'PAYMENT_CHECKOUT';
        this.removeComponent();
      } else if (event.name == 'VisitError') {
        this._index = this.flowType == 'BOOKING' ? (this._index - 1) : (this._index - 2);
        this.loadComponent(this._index, event.value.msg)
      } else if (event.name == 'Reject') {
        this._index = this.flowType == 'BOOKING' ? (this._index - 1) : (this._index - 2);
        this.loadComponent(this._index)
      } else if (event.name == 'opentc') {
        this.showTC = true;
      } else if(event.name == 'select_patient') {
        this._index = 5;
        this.loadComponent(this._index)
      }
      else if (event.name == 'isManageIdentities') {
        this.isManageIdentities = true;
      }else if(event.name == 'registerpatient')
        this.isRegisterPatient = true;
      else if (event.name == 'openCoupon') {
        this.promoApplicable = event.isPromoApplied
        this.couponApplicable = false;
        this.promoData = event.promoData;
        this.couponData = event.couponData;
        // this.couponList = event.couponData.couponList;
        this.couponParams = COUPON_PARAMS;
        this.couponInputs = event.couponInput;
        this.isPolicyValidated = event.isPolicyValidated
        this.isCouponType = true;
        this.isFlip = true;
        this.refreshFlip.next({
          'enable': true,
          'type': 'COUPON',
          "appliedcouponcodedtls": this.couponData.appliedcouponcodedtls
        });
      }
      else if (event.name == 'openPromo') {
        this.couponApplicable = event.isCouponApplied;
        this.promoApplicable = false;
        this.promoData = event.promoData;
        this.couponData = event.couponData;
        this.couponParams = PROMO_PARAMS;
        this.promoInputs = event.promoInput;
        this.couponInputs = event.couponInput;
        this.isPolicyValidated = event.isPolicyValidated
        this.isCouponType = false;
        this.isFlip = true;
        this.refreshFlip.next({
          'enable': true,
          'type': 'PROMO'
        });
      }
      else if (event.name == 'CLOSEMODAL') {
        this.closeModel(false);
      }
      else if(event.name == 'choose_slot') {
        this._index = 4;
        this.loadComponent(this._index)
      }
    })
  }
  public closeModel(status) {
    // if(this.eventInfo){
    //   this.cancelRequest();
    // }
    this.user.clearInclinicSelection();
    console.log('closeModel', status,this.state,this._index);
    if(this.state=='PAYMENT_CHECKOUT'){
      this.initUnBlockSlot();
      let interval = this.user.getPaymentIntervalForReference();
      clearInterval(interval);
    }
    this.close.emit(this._index == 7 ? true : status);
  }

  public setEvent(event) {
    this.eventInfo = event;
  }

  public cancelRequest = () => {
    const payload = {
      eventId: this.eventInfo.event_ascn_no,
      conceptCode: this.eventInfo.concept_code,
      ioChart: false,
      note: false,
      patientId: this.eventInfo.patient_id
    }

    this.onspotService.cancelTask(payload).subscribe(data => {
      if (data.status == 'SUCCESS') {
        this.eventInfo = null;
      }
    })
  }
  private checkOrderDetails() {
    let orderData = atob(this.orderdetails);
    let arr = orderData.split('&');
    let params = {};
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    this.paymentService.getTransactionDetail(params['orderid']).subscribe(data => {
      if (params['v'] == 'VCN') {
        this.apptInfo = data;
        this.apptParams = params;
        this.refresh.next(true);
        this.state = 'ONSPOT';
      }
      else {
        if (data.status != 'S') {
          console.log('unblocking clinic slot', data);
          if(data && data.transactionRequest && data.transactionRequest.blockId) {
            let payload = {
              facilityId: data.facilityId,
              blockId: data.transactionRequest.blockId,
            }
            this.initUnBlockSlot(payload);
          }
        }
        this.generateData(params, data)
      }

    })
  }
  public selectVisitType(type: visitType) {
    if (!this.prefillData && this.flowType !== 'RESCHEDULE') {
      let obj = {
        'event': 'clinic_appointment_button',
        'funnel_name': 'Clinic Appointments',
      };
      if (type == 'VIDEO') {
        obj['event'] = 'online_consultation_button';
        obj['funnel_name'] = 'Online Consultation';
      }
      if (type == 'VIDEO-CONSULT-NOW') {
        obj['event'] = 'consult_doctor_now_button';
        obj['funnel_name'] = 'Instant Consultation';
      }
      this.gtmService.pushGTMEvent(obj['funnel_name'], obj['event']);
    }
    if(!['HOSPITAL', 'VIDEO'].includes(type) && !this.isLoggedIn)
    return this.showLogin({action:'schedule', type:'onspot'})
    if (type == 'VIDEO-CONSULT-NOW') {
      this.state = 'ONSPOT';
    }
    else if (type == 'EXPRESS') {
      this.state = 'EXPRESS_CLINIC';
      this.close.emit(type)
    }
    else if (type == 'HOME-CARE') {
      this.state = 'HOME_CARE_SERVICE';
      this.close.emit(type)
    }
    else {
      let bookingAttributes = this.bookingAttributes['home'] || {}
      this.bookingAttributes['home'] = { ...bookingAttributes, visitType: type };
      this.visitType = type;
      this.user.getUpdateInfo('facilities').subscribe(data => {
        this.facilityList = data.facilities;
        if (this.facilityList.length == 1) {
          this.bookingAttributes['facility'] = {
            id: data.facilities[0]['facilityId'],
            name: data.facilities[0]['facilityName'],
            address: data.facilities[0]['facilityAddress']
          };
        }
      })
      this.state = 'START_SELECTION';
      if (this.visitType == 'VIDEO') {
        this._index = 2;
      }
      else if (this.facilityList.length == 1) {
        this._index = 2;
      }
      this.currentTimeline = this.timeline.filter(t => {
        return t.timeLineView();
      })

      this.loadComponent(this._index);
    }
  }
  private generateData(params, transaction) {
    console.log('generateData', params, transaction)
    let facility = this.user.findFacility(params['f']);
    this.bookingAttributes['facility'] = {
      id: facility['facilityId'],
      name: facility['facilityName'],
      address: facility['facilityAddress']
    };
    let speciality = this.user.findSpeciality(params['f'], params['s']);
    this.bookingAttributes['speciality'] = {
      id: speciality.specialityId,
      code: speciality.sourceSpecialityCode,
      desc: speciality.specialityDesc,
      name: speciality.specialityName,
    }
    let practitioner = this.user.findPractitioner(params['f'], params['p']);
    this.bookingAttributes['practitioner'] = {
      id: practitioner.resourceId,
      personId: practitioner.personId,
      name: practitioner.resourceName,
      resourceAvatarURL: practitioner.resourceAvatarURL,
      info: practitioner.additionalInfo['info'],
      availDays: practitioner['schedulerControls']['daysAvailable'],
      schedulerControls: practitioner['schedulerControls'],
      additionalInfo: practitioner['additionalInfo']
    }
    this.bookingAttributes['slot'] = {
      name: this.user.getMoment(params['d'], 'YYYYMMDD').format('DD MMM YYYY,') + this.user.getMoment(params['t'], 'HHmm').format('hh:mm a'),
      date: this.user.getMoment(params['d'], 'YYYYMMDD').format('YYYY-MM-DD'),
      slot: this.user.getMoment(params['t'], 'HHmm').format('HH:mm'),
      clinicCode: params['c']
    }
    let patient = this.user.findLinkedPatient(params['h']);
    console.log('generate pat param', patient)
    this.bookingAttributes['patient'] = {
      id: patient.uhid ? patient.uhid : patient.patientid,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    console.log('transaction.resourceDetails', transaction.resourceDtls, this.bookingAttributes)
    if (transaction.status == 'S') {
      this.bookingAttributes['confirm'] = {
        name: 'pay_success',
        status: 'S',
        amount: transaction['response']['amount'],
        currency: transaction['currency'],
        category: params['g'],
        medium: 'ONLINE',
        gatewayRefId: transaction['gatewayRefId'],
        transactionId: transaction['transactionId'],
        bankRefId: transaction['bankRefId'],
      }
    } else {
      this.bookingAttributes['confirm'] = {
        name: 'pay_failed',
        status: 'F',
        amount: null,
        currency: null,
        category: params['g'],
        medium: 'ONLINE',
        gatewayRefId: transaction['gatewayRefId'],
        transactionId: transaction['transactionId'],
        bankRefId: transaction['bankRefId'],
        retry: {
          flowType: params['c'],
          freeFollowUpYn: params['ff'] ? params['ff'] : null,
          payType: params['pt'],
          discount: params['dis'] ? params['dis'] : null,
          cost: params['cost'],
          billingGroupCode: params['g'],
          insuranceFinDtls: transaction && transaction.resourceDtls ? transaction.resourceDtls.insuranceFinDtls : null,
          sourceRequest: transaction.resourceDtls ? transaction.resourceDtls.sourceRequest : null
        },
      }
    }
    this.visitType = params['v'] == 'V' ? 'VIDEO' : 'HOSPITAL';
    this.flowType = params['c'] == 'B' ? 'BOOKING' : 'RESCHEDULE';
    if (this.flowType == 'BOOKING' && transaction.status == 'S') {
      let obj = {
        value: transaction['response']['amount'],
        booking_no: transaction['response']['apptRefNumber'],

      };
      let eev = 'appointment_confirmation',
        funnel_name = 'Clinic Appointments';
      if (this.visitType == 'VIDEO') {
        funnel_name = 'Online Consultation';
      }
      this.gtmService.pushGTMEvent(funnel_name, eev, obj);
    }
    this.state = 'START_SELECTION';
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
    this._index = 7;
    this.loadComponent(this._index);
  }
  public changeSelection(index) {
    if (this._index > 6 || this._index < index) {
      return;
    } else {
      this._index = index;
      this.loadComponent(this._index);
    }
  }
  public closetc(status: boolean) {
    if (status) {
      this._index = this._index + 1;
      this.loadComponent(this._index);
      this.showTC = false;
    } else {
      this.showTC = false;
    }
  }
  public initRescheduleData() {
    let practitionerId = this.rescheduleData.resourceId;
    let facilityId = this.rescheduleData.facilityId;
    let specialityId = this.pracDetailsPipe.transform(practitionerId, facilityId, 'specialityId');
    let patientUhid = this.rescheduleData.uhid;

    let practitioner = this.user.findPractitioner(facilityId, practitionerId);
    this.bookingAttributes['practitioner'] = {
      id: practitioner.resourceId,
      personId: practitioner.personId,
      name: practitioner.resourceName,
      resourceAvatarURL: practitioner.resourceAvatarURL,
      info: practitioner.additionalInfo['info'],
      availDays: practitioner['schedulerControls']['daysAvailable'],
      schedulerControls: practitioner['schedulerControls'],
      additionalInfo: practitioner['additionalInfo'],
      facList: practitioner['facList']
    }
    let speciality = this.user.findSpeciality(facilityId, specialityId);
    this.bookingAttributes['speciality'] = {
      id: speciality.specialityId,
      code: speciality.sourceSpecialityCode,
      desc: speciality.specialityDesc,
      name: speciality.specialityName,
    }
    this.visitType = (this.rescheduleData.appointmentType == 'VIDEO' || this.rescheduleData.appointmentType == 'video') ? 'VIDEO' : 'HOSPITAL';
    this.bookingAttributes['home'] = { visitType: this.visitType };
    this.bookingAttributes['alias'] = this.chooseSlot?.alias;
    this.bookingAttributes['exist'] = {
      slot: {
        date: this.user.getMoment(this.rescheduleData.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
        slot: this.user.getMoment(this.rescheduleData.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        facilityId: facilityId
      },
      payment: {
        ...this.rescheduleData.paymentDetail
      },
      apptRefNo: this.rescheduleData.appointmentId
    }
    let patient = this.user.findLinkedPatient(patientUhid);
    console.log('generate param', patient)
    this.bookingAttributes['patient'] = {
      id: patient.uhid ? patient.uhid : patient.patientId,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    console.log('generate this.bookingAttributes', this.bookingAttributes)
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
  }

  public selectExternalVisitType(type: visitType) {
    let bookingAttributes = this.bookingAttributes['home'] || {}
    this.bookingAttributes['home'] = { ...bookingAttributes, visitType: type };
    this.visitType = type;
    this.state = 'START_SELECTION';
    if (this.visitType == 'VIDEO') {
      this._index = 2;
    }
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
    this.loadComponent(this._index);
  }

  public initExternalScheduleData() {
    let practitionerId = this.rescheduleData.resourceId;
    let facilityId = this.rescheduleData.facilityId;
    let specialityId = this.pracDetailsPipe.transform(practitionerId, facilityId, 'specialityId');
    let slot = this.rescheduleData.slot;
    if(slot) {
      slot = decodeURIComponent(slot).split('__');
      this.bookingAttributes['slot'] = {
        name: this.user.getMoment(slot[1], 'YYYY-MM-DD').format('DD MMM YYYY,') + this.user.getMoment(slot[2], 'HH:mm').format('hh:mm a'),
        date: this.user.getMoment(slot[1], 'YYYY-MM-DD').format(),
        slot: this.user.getMoment(slot[2], 'HH:mm').format('HH:mm'),
        clinicCode: slot[0]
      }
      console.log('testttt',this.bookingAttributes['slot']);
    }
    // let patientUhid = this.rescheduleData.uhid;
    if (facilityId) {
      let facility = this.user.findFacility(facilityId);
      this.bookingAttributes['facility'] = {
        id: facility['facilityId'],
        name: facility['facilityName'],
        address: facility['facilityAddress']
      };
    }
    if (practitionerId) {
      let practitioner = this.user.findPractitioner(null, practitionerId);
      this.bookingAttributes['practitioner'] = {
        id: practitioner.resourceId,
        personId: practitioner.personId,
        name: practitioner.resourceName,
        resourceAvatarURL: practitioner.resourceAvatarURL,
        info: practitioner.additionalInfo['info'],
        availDays: practitioner['schedulerControls']['daysAvailable'],
        schedulerControls: practitioner['schedulerControls'],
        additionalInfo: practitioner['additionalInfo'],
        facList: practitioner['facList']
      }
      if (!specialityId || specialityId == '') {
        specialityId = practitioner.specialityId;
      }
      if (!facilityId) {
        facilityId = practitioner.tfacilityId
      }
      let speciality = this.user.findSpeciality(null, specialityId);
      this.bookingAttributes['speciality'] = {
        id: speciality.specialityId,
        code: speciality.sourceSpecialityCode,
        desc: speciality.specialityDesc,
        name: speciality.specialityName,
      }
    } else if (this.rescheduleData.specialityId) {
      let speciality = this.user.findSpeciality(null, this.rescheduleData.specialityId);
      this.bookingAttributes['speciality'] = {
        id: speciality.specialityId,
        code: speciality.sourceSpecialityCode,
        desc: speciality.specialityDesc,
        name: speciality.specialityName,
      }
    }
    this.visitType = (this.rescheduleData.appointmentType == 'VIDEO' || this.rescheduleData.appointmentType == 'video')  ? 'VIDEO' : 'HOSPITAL';
    console.log(this.rescheduleData);
    this.bookingAttributes['home'] = {visitType: this.visitType };
    this.bookingAttributes['alias'] = this.rescheduleData['alias'];
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  closeManageIdentities() {
    this.isManageIdentities = false;
  }
  public closeManageIdentity(status: boolean) {
    if (status) {
      this._index = this._index + 1;
      this.loadComponent(this._index);
      this.isManageIdentities = false;
    } else {
      this._index = this._index;
      this.loadComponent(this._index);
      this.isManageIdentities = false;
    }
    this.refresh.next(true);
  }

  public closeCoupon(event) {
    if (event) {
      if ((event.type == 'COUPON' || event.type =='COUPONLIST') && event.data) {
        this.couponData = event.data;
        this.isDiscountType = 'COUPON';
      }
      else if (event.type == 'PROMO' && event.data) {
        this.promoData = event.data;
        this.isDiscountType = 'PROMO';
      }
      this._index = this._index;
      this.loadComponent(this._index);
      this.isFlip = false;
    }
    else {
      this.couponData = null;
      this._index = this._index;
      this.loadComponent(this._index);
      this.isFlip = false;
    }
  }

  public selectActionType(event) {
    if (event.name == 'isManageIdentities') {
      this.isManageIdentities = true;
    }
    else if (event.name == 'isRegisterPatient') {
      this.isRegisterPatient = true;
    }
  }

  public updateGTMEvents(index, event) {
    if (this.flowType == 'RESCHEDULE')
      return;
    let obj = {},
      funnel_name = this.visitType == 'VIDEO' ? 'Online Consultation' : 'Clinic Appointments',
      gtmevent = '';

    switch (index) {
      case 1:
        if (event['facility'] && event['facility']['name']) {
          gtmevent = 'step_option_choose_clinic';
          obj['clinic_name'] = event['facility']['name'][0]['name'];
        }
        break;
      case 2:
        if (event['speciality'] && event['speciality']['desc']) {
          gtmevent = 'step_option_choose_specialty';
          obj['specialty'] = event['speciality']['desc'];
        }
        break;
      case 3:
        if (event['practitioner'] && event['practitioner']['name']) {
          gtmevent = 'step_option_choose_practitioner';
          obj['practitioner'] = event['practitioner']['name'][0]['name'];
        }
        break;
      case 7:
        if (event['confirm']) {
          gtmevent = 'appointment_confirmation';
          obj['practitioner'] = event['practitioner']['name'][0]['name'];
        }
        break;
      default:
        break;
    }
    if (gtmevent)
      this.gtmService.pushGTMEvent(funnel_name, gtmevent, obj);
  }

  public referralAction(event) {
    switch (event.action) {
      case 'BOOK_APPT':
        this.initReferralBookingAttributes(event);
        break;
    }
  }

  private initReferralBookingAttributes(event) {
    let referral = event.referral;
    console.log(referral)
    let facilityId = referral.facilityId;
    let specialityId = referral.specialityId;
    let speciality = this.user.findSpeciality(null, specialityId);
    let patientUhid = referral.uhid;

    let patient = this.user.findLinkedPatient(patientUhid);
    this.bookingAttributes['patient'] = {
      id: patient.uhid,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    this.bookingAttributes['speciality'] = {
      id: speciality.specialityId,
      code: speciality.sourceSpecialityCode,
      desc: speciality.specialityDesc,
      name: speciality.specialityName,
    }
    this.visitType = 'HOSPITAL';
    this.bookingAttributes['home'] = { visitType: this.visitType };
    if (facilityId) {
      let facility = this.user.findFacility(facilityId);
      this.bookingAttributes['facility'] = {
        id: facility['facilityId'],
        name: facility['facilityName'],
        address: facility['facilityAddress']
      };
    }
    this.bookingAttributes['bookingRestrictions'] = {
      allowedFacilities: [
        {
          facilityId: facilityId || '*',
          allowedSpecialities: [{
            specialityId: specialityId || "*"
          }],
          allowedPractitioners: [{
            practitionerId: "*"
          }],
          clinicCode: "*"
        }
      ]
    }
    console.log(this.bookingAttributes)
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
    this.state = "START_SELECTION";
    this.flowType = "BOOKING";
    this.loadComponent(this._index+1);
  }

  public closeRegPatient(ev) {
    this.isRegisterPatient = false;
    if (this.state != 'ONSPOT') {
      this.loadComponent(this._index);
    }
    this.refresh.next(true);
  }

  public showLogin(context) {
    this.openLogin.emit(context);
  }

  FSPFullfillment(event: any) {
    let condition = event['speciality']['name'] && event['practitioner']['name'];
    if (condition && !this.isLoggedIn) {
      let context = {
        action: 'schedule',
        type: this.visitType,
        facility: event['facility']?.['id'],
        speciality: event['speciality']['id'],
        resource: event['practitioner']['id'],
      }
    this.showLogin(context);
    return this._index;
    }
    let next = condition ? 4 : 3;
    let add = this.patientBasedApptbooking ? 1 : 0
    return next + add
  }
  public navBack() {
    if (this._index <= 1) return
    this._index -= 1;
    if (this._index == 3) {
      this._index = 1;
    }
    this.loadComponent(this._index)
  }

  public initUnBlockSlot(data?) {
    let appointment = this.paymentService.getAppointmentInfo()
    let payload;
    if(data) {
      payload = data;
    } else {
       payload = {
        facilityId: appointment.facilityId,
        blockId: appointment.blockId,
      }
    }
    if (payload && payload.blockId) {
      this.paymentService.unBlockSlot(payload).subscribe(data => {
        console.log('unblock slot', data)
      })
    }
  }
}
export interface ExBookApptComponent {
  _input?: inOutData;
  complete?: EventEmitter<Object>;
  action?: EventEmitter<Object>;
  visitType?: visitType;
  msg?: any;
  flowType?: apptFlowType;
  couponData?: inOutData;
  promoData?: inOutData;
  promoApplicable?: boolean;
  couponApplicable?: boolean;
  isDiscountType?: discountType;
  patientBasedApptbooking?: boolean;
  isReferral?: boolean;
  referralDetail?: any;
  isPolicyValidated?: inOutData;
  settings?:any
}
