<div id="navigation-component" *ngIf="viewType == 'OLD'">
  <div *ngFor="let item of menu" class="nav-item" (click)="setRoute(item)" [ngClass]="{'active': item['routeTo']==state['route_name'] }">
    <div class="image thumbnail small" >
      <img class="type-image" [src]="item['activeIcon']"></div>
    <div class="nav-title">{{item['name']|translate}}</div>
  </div>
</div>
<div id="new-nav-component" *ngIf="viewType == 'NEW'">
  <div *ngFor="let item of menu" class="nav-item" (click)="setRoute(item)" [ngClass]="{'active': item['routeTo']==state['route_name'] }">
    <div class="image thumbnail small" >
      <img class="type-image" [src]="item['routeTo']==state['route_name'] ? item['activeIcon']: item['inactiveIcon']"></div>
    <div class="nav-title">{{item['name']|translate}}</div>
  </div>
</div>
