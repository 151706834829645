import { Component, OnInit, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
// import '@vaadin/vaadin-checkbox/vaadin-checkbox.js';
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent extends Type implements OnInit, SectionInterface {

  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  @Output() valueChangeComplete: EventEmitter<any>;

  private isClicked: boolean = false;
  private checked = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: any;
  selectedValueArray = [];
  constructor(cfs: ClinicalformService) {
    super(cfs);
    this.valueChangeComplete = new EventEmitter<any>();
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideSelectSection(this.section);
    super.setDataForSelect(this.section);
    this.domainLevelDefaultValue()
    if (this.section['selected']) {
      var selectedValueArray = this.section['selected'].split(',');
      var selectedArray = [];
      this.section['selectTypeArray'].forEach((s) => {
        selectedValueArray.forEach((v) => {
          if (s['item_text'] == v) {
            selectedArray.push({
              item_id: s['item_id'],
              item_text: s['item_text']
            })
          }
        })
      })
      this.section['selectedOption'] = selectedArray;
    }
    this.dropdownSettings = {
      singleSelection: this.section['multiSelect'] && (this.section['multiSelect'] == 'true' || this.section['multiSelect'] == true) ? false : true,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    }
    if (!this.section['multiSelect'] || this.section['multiSelect'] == 'false' || this.section['multiSelect'] == false)
      this.dropdownSettings['closeDropDownOnSelection'] = true;

  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    if (event.target['className'] == 'dropdown-btn')
      this.isClicked = true;
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyup(event: KeyboardEvent) {
    let activeElement = document['_activeElement'];
    if (!activeElement.closest('app-section'))
      return;
    if (event.keyCode === 9 && this.section['domainCode'] === activeElement.closest('app-section')['id'].split('#')[1]) {
      let nodeList = activeElement.closest('app-section').getElementsByTagName('ng-multiselect-dropdown')[0].querySelectorAll('input[type="checkbox"]')
      if (event.target === nodeList[nodeList.length - 1])
        document.body.click();
    } else if (event.keyCode === 32 && this.section['domainCode'] === activeElement.closest('app-section')['id'].split('#')[1] && !this.isClicked) {
      this.isClicked = true;
      let multiselectView: HTMLElement = activeElement.closest('app-section').getElementsByClassName('dropdown-btn')[0] as HTMLElement;
      multiselectView.focus();
      multiselectView.click();
      let inputElement: HTMLElement = activeElement.closest('app-section').getElementsByTagName('ng-multiselect-dropdown')[0].querySelector('input[type="text"]');
      if (inputElement)
        setTimeout(() => { inputElement.focus() }, 100)
      event.preventDefault();
    } else if (this.section['domainCode'] !== activeElement.closest('app-section')['id'].split('#')[1]) {
      this.isClicked = false;
    }
  }

  @HostListener('window:focusin', ['$event'])
  public onFocusIn(event: any) {
    if (!event.target.closest('app-section'))
      return;
    if (this.section['domainCode'] !== event.target.closest('app-section')['id'].split('#')[1])
      this.isClicked = false;
  }



  public onItemSelect(item: any) {
    this.setSelectedValueForSection()

  }

  public onItemDeSelect(items: any) {
    this.setSelectedValueForSection()
  }

  /**
   *  ngrDropDown result value is present as 
   *  {
   *    item_id: 2
   *    item_text: "Diagnostics"
   *  }
   */
  public setSelectedValueForSection() {
    if (this.section['selectedOption'].length == 0) {
      this.section['selected'] = '';
    } else if (this.section['selectedOption'].length === 1) {
      this.section['selected'] = this.section['selectedOption'][0]['item_text'];
      super.complete(this.section);
    } else if (this.section['selectedOption'].length > 1) {
      this.section['selected'] = this.section['selectedOption'][0]['item_text'];
      for (let i = 1; i < this.section['selectedOption'].length; i++) {
        this.section['selected'] = this.section['selected'] + ',' + this.section['selectedOption'][i]['item_text'];
      }
      super.complete(this.section);
    }
    if (this.section.selected) {
      this.valueChangeComplete.emit(true)
    }
    console.log(this.section['selected']);
  }

  public domainLevelDefaultValue() {
    if (!this.section['defaultOnFormLoad'])
      return;
    var answers = this.section['domainControls']['answer']
    for (var i = 0; i < answers.length && Array.isArray(answers); i++) {
      if (!answers[i]['default'])
        continue;
      if (!this.section['selected'])
        this.section['selected'] = '';
      if (!this.section['selectedOption'])
        this.section['selectedOption'] = [];

      if (!this.section['multiSelect'] || this.section['multiSelect'] == 'false')
        this.section['selected'] = answers[i]['localName'][0]['text'];

      if (this.section['multiSelect'] || this.section['multiSelect'] == 'true')
        this.section['selected'] = this.section['selected'] + ',' + answers[i]['localName'][0]['text'];

      this.section['selectedOption'].push(answers[i]['localName'][0]['text'])
      console.log(this.section['selectedOption']);
    }

  }
}
