<div class="ui form" id="check-list" *ngIf="!(section['domainCode'] == 'PRESDOSAGETYPE' && section['options'].length == 1  && section['options'][0].localName[0].text === section['selected'])">
  <div class="grouped fields">
    <div class="identifer">
      <p class="check-label font-default-small bold">
        <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
        <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup> 
        <span class="add-more cursor-pointer" [ngClass]="{'disabled': enableCustomAns}"*ngIf="(section['codify'] == 'Y' && section['domainControls']['customizeAnswer'] == 'Y') || section['customizeAnswer'] == 'Y'" (click)="enableCustomAns = true">
          {{'btn_add' | translate}}
        </span>
      </p>
    </div>
    <ng-container *ngIf="(section['codify'] == 'Y' && section['domainControls']['iconsApplicable']) || section['iconsApplicable']; else elseTemplate">
      <div class="check-list iconsApplicable">
        <div class="item" *ngFor="let a of section['options']" (click)="listenToEvent(a.localName[0].text, a.localName)"
          [ngClass]="{'checked': section['trueType']=='radio'?section['selected'] === a.localName[0].text:section['checked'].includes(a.localName[0].text)}">
          <img src="assets/new/clinicalform/{{section['trueType']=='radio'?section['selected'] === a.localName[0].text:section['checked'].includes(a.localName[0].text)? a['imageIndex']+'.svg':a['imageIndex']+'_inactive.svg'}}" alt="">
          <label class="option">{{a.localName | translate:'LOCALE':'text'}}</label>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="check-list">
        <div class="field" *ngFor="let a of section['options'];let i=index">
          <div class="ui checkbox">
            <input
              [checked]="section['trueType']=='radio'?section['selected'] ===
              a.localName[0].text:section['checked'].includes(a.localName[0].text)" [id]="randomId+'segment'+i"
              (click)="listenToEvent(a.localName[0].text, a.localName);" [name]="section['domainCode']"
              [tabindex]="tabindex"
              type="{{ section['trueType'] }}">
            <label class="check-opt" [for]="randomId+'segment'+i">
                {{a.localName | translate:'LOCALE':'text'}}
            </label>
          </div>
        </div>
        <div *ngIf="enableCustomAns" class="margin-left custom-ans">
          <input type="text" placeholder="Enter text here" [(ngModel)]="customAns" [tabindex]="tabindex">
          <span (click)="enableCustomAns = false; customAns = '';" class="icon"> <img src="assets/icons/close2.svg" alt=""></span> 
          <span (click)="addCustomAns()" class="icon"> <img src="assets/icons/tick.svg" alt=""></span> 
        </div>
      </div>
    </ng-template>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
      <p>{{'form_field_mandatory' | translate}}</p>
    </div>
  </div>
</div>