import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ModalService } from '../services/modal.service';
import * as _ from 'lodash';
import { AppointmentService } from '../services/appointment.service';
import { UserService } from '../services/user.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { environment } from '@env/environment';
import { PaymentService } from '../services/payment.service';
import { POINTSTYPE, visitType } from '@type';
import { HOMECARE, POINTSTYPEDEFAULT } from '../app.const';
import { GoogleTagsService } from '../services/google-tags.service';
import { Subject } from "rxjs";
import { APPTACTION, APPTFLAGS } from '@type';
import { LinkPatientService } from '../services/link-patient.service';
import { AltpaymentInputComponent } from '../components/altpayment-input/altpayment-input.component';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { SpecialityDetailsPipe } from '../pipes/speciality-details.pipe';
import { GeodistancePipe } from '../pipes/geodistance.pipe';
@Component({
  selector: 'app-upcoming-appointment-detail',
  templateUrl: './upcoming-appointment-detail.component.html',
  styleUrls: ['./upcoming-appointment-detail.component.scss'],
  providers: [TranslatePipe, PartitionerDetailsPipe, SpecialityDetailsPipe, GeodistancePipe]
})
export class UpcomingAppointmenDetailComponent implements OnInit {
  @Input() viewAppointment: any;
  @Input() initCheckin: APPTACTION;
  @Input() isCommonView: boolean;
  @Input() modalId: string;
  refresh: Subject<boolean> = new Subject<boolean>();
  refreshFlip: Subject<Object> = new Subject<Object>();
  @Output() reschedule: EventEmitter<any>;
  @Output() expressReschedule: EventEmitter<any>;
  @Output() uploadQuestionnarie: EventEmitter<any>;
  @Output() uploadDocs: EventEmitter<any>;
  //private used
  private facility: any;
  private selected_lang: string;
  private practName: any;
  private specName: any;
  private visitType: visitType;
  private paymentGatewayUrl: string;
  private geoDistanceSatisfied: boolean;
  private canFillQuestionnarie: boolean;
  private discountValue;
  private enableQueueOnFacility: boolean = false;
  private getQueueInterval: any;
  private linkedPatients = [];
  private canCancelAppt: boolean = false
  private canRescheduleAppt: boolean = false

  public actionType: APPTACTION
  public visitInfo: boolean = false;
  public primaryActions: Array<any>;
  public isCheckout: boolean;
  public serviceDetails: Array<any>;
  public currency_code: string;
  public homecareService: Array<any>;
  public alreadyPaid: boolean;
  public hasDiscount: boolean = false;
  public isPromoApplied: boolean = false;
  public isEnablePromo: boolean;
  public isEnableCoupon: boolean;
  public isEnablePoints: boolean;
  public freeFollowUpYn: string;
  public canPay: boolean;
  public applyPromoDiscYn: boolean
  public isCouponApplied: boolean = false;
  public payableCharge: number;
  public HOMECARE = HOMECARE;
  public isActionOpen: boolean;
  public checkinResponseData: any;
  public mappingFloorControls: any;
  public mappingFloorInfo: any;
  public triangleArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15];
  public promoCodeParam: any;
  public promoAmtForPaidAppt: any;
  public totalCouponBurntAmt: number;
  public totalPointsPaid: number;


  @ViewChild('coupon', { static: false }) coupon: AltpaymentInputComponent;
  @ViewChild('points', { static: false }) points: AltpaymentInputComponent;
	public readonly pointsType: POINTSTYPE = POINTSTYPEDEFAULT

  public chargeVatDetails:any;
  public chargeVatInfoRef:any;

  constructor(private modalService: ModalService, private apptService: AppointmentService, private user: UserService, private translatePipe: TranslatePipe, private paymentService: PaymentService, private gtmService: GoogleTagsService, private linkPatient: LinkPatientService, private cdf: ChangeDetectorRef, private pracDetailsPipe: PartitionerDetailsPipe, private specDetailsPipe: SpecialityDetailsPipe, private geodistance: GeodistancePipe) {
    this.isCheckout = false
    this.reschedule = new EventEmitter<any>();
    this.expressReschedule = new EventEmitter<any>();
    this.uploadQuestionnarie = new EventEmitter<any>();
    this.uploadDocs = new EventEmitter<any>();
    this.serviceDetails = new Array<any>();
    this.homecareService = new Array<any>();
    this.getUpdatesInfo();
    for (let i = 1; i < 100; i++) {
      this.triangleArr.push(i)
    }
  }

  ngOnInit() {
    console.log('viewappt', this.viewAppointment)
    this.selected_lang = this.user.currentLanguage;
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.setRequiredParam()
    this.processScheduleFlags();
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            console.log("this.linkedPatients->>", this.linkedPatients)
          }
        })
      }
    })
  }
  private getUpdatesInfo() {
    this.user.getConfigurationInfo.subscribe(data => {
      this.isEnablePromo = data['pxAppSettings']['enablePromoYn'] == 'Y' ? true : false;
      this.isEnableCoupon = data['pxAppSettings']['enableCouponYn'] == 'Y' ? true : false;
      this.isEnablePoints = data['pxAppSettings']['enablePointsYn'] == 'Y' ? true : false;
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl'];
    })
  }

  private processScheduleFlags(recheckActions:boolean = false) {
    console.info("=== viewAppointment ===", this.viewAppointment)
    if (this.viewAppointment.chargeDetails && this.viewAppointment.chargeDetails.disableCoupon) {
      this.isEnableCoupon = false
    }
    if (this.viewAppointment.chargeDetails && this.viewAppointment.chargeDetails.disablePoints) {
      this.isEnablePoints = false
    }
    if (this.viewAppointment.chargeDetails && !recheckActions) {
      this.viewAppointment.chargeDetails.discountedPayable = this.viewAppointment.chargeDetails.totalAmount
      this.calculatePayable()
    }
    this.chargeVatInfoRef=_.cloneDeep(this.viewAppointment.chargeDetails.parsedCharge);
    if(this.viewAppointment.chargeDetails && this.viewAppointment.chargeDetails.parsedCharge){
      this.calculateVatCharges(this.viewAppointment.chargeDetails.parsedCharge);
    }
    const recievedFlags: APPTFLAGS = this.apptService.processScheduleFlag(this.viewAppointment, 'PREVIEW');
    console.log("=== recievedFlags ====", recievedFlags)
    const mappedFlag: APPTFLAGS = {
      canCancelAppt: recievedFlags.canCancelAppt,
      canRescheduleAppt: recievedFlags.canRescheduleAppt,
      canCheckInVC: recievedFlags.canCheckInVC,
      canCheckInIP: recievedFlags.canCheckInIP,
      actionType: recievedFlags.actionType,
      checkinStatus: recievedFlags.checkinStatus,
      canRejoin: recievedFlags.canRejoin
    }
    this.canCancelAppt = mappedFlag.canCancelAppt;
    this.canRescheduleAppt = mappedFlag.canRescheduleAppt;
    if (this.viewAppointment.appointmentType == 'VIDEO') {
      this.actionType = mappedFlag.actionType;
    } else {
      this.actionType = mappedFlag.actionType;
    }
    this.presentAppointmentActions();
  }
  private async presentAppointmentActions() {
    let buttons = [
      {
        text: this.translatePipe.transform('appt_but_pay'),
        icon: './assets/icons/Appointments/check in.svg',
        enable: this.actionType == 'PAY',
        actionType: 'PAY',
        cssClass: 'primary-action',
        handler: () => {
          this.handleActions('onlyPay', null);
        }
      },
      {
        text: this.translatePipe.transform('cal_modal_btn_pay_and_checkin'),
        icon: './assets/icons/Appointments/check in.svg',
        enable: this.actionType == 'PAYCHECKIN',
        actionType: 'PAYCHECKIN',
        cssClass: 'primary-action',
        handler: () => {
          if (this.visitType == 'VIDEO') this.handleActions('payCheckinVC', null);
          else this.handleActions('payCheckinIV', null);
        }
      },
      {
        text: this.translatePipe.transform('cal_modal_btn_checkin'),
        icon: './assets/icons/Appointments/check in.svg',
        enable: this.actionType == 'CHECKIN',
        actionType: 'CHECKIN',
        cssClass: 'primary-action',
        handler: () => {
          if (this.visitType == 'VIDEO') this.handleActions('checkinVC', null)
          else this.handleActions('checkinIV', null)
        }
      },
      {
        text: this.translatePipe.transform('APPTPREVIEW#ACTIONSHEET#LABEL#FILLQUES'),
        icon: './assets/icons/Appointments/questionnaire.svg',
        enable: this.canFillQuestionnarie,
        handler: () => {
          console.log('FILLQUES clicked');
          this.openQuestionnarie();
        },
        isRight: true
      },
      {
        text: this.translatePipe.transform('APPTPREVIEW#ACTIONSHEET#LABEL#UPLOADDOCS'),
        icon: './assets/icons/Appointments/upload.svg',
        enable: this.viewAppointment.appointmentType == 'VIDEO',
        handler: () => {
          console.log('FILLQUES clicked');
          this.openUploadDocs();
        }
      },
      {
        text: this.translatePipe.transform('APPTPREVIEW#ACTIONSHEET#LABEL#CANCEL'),
        icon: './assets/icons/Appointments/cancel appt.svg',
        enable: this.canCancelAppt,
        handler: () => {
          console.log('CANCEL clicked');
          this.initCancelAppointment();
        }
      },
      {
        text: this.translatePipe.transform('APPTPREVIEW#ACTIONSHEET#LABEL#RESCHEDULE'),
        icon: './assets/icons/Appointments/reschedule.svg',
        enable: this.canRescheduleAppt,
        handler: () => {
          console.log('RESCHEDULE clicked');
          this.rescheduleVisit();
        }
      }
    ];

    buttons = buttons.filter((b) => b.enable)
    console.log("====== btns", buttons)
    buttons.forEach(b => {
      if (b.text === this.translatePipe.transform('cal_modal_btn_checkin') &&
        !_.isEmpty(this.viewAppointment['consultationStatus'])) {
        b.text = this.translatePipe.transform('cal_modal_btn_join');
      }
    })
    console.log("this.primaryActions", this.primaryActions)
    this.primaryActions = buttons;
    if (this.initCheckin) {
      const handler = this.primaryActions.find(h => h.actionType == this.initCheckin)
      if(handler) handler.handler()
    }
  }
  public closeModal(id: string) {
    console.log("---- upcoming appt modalId---", id, this.modalId)
    if (this.modalId) {
      console.log("this.modalId", this.modalId)
      this.modalService.close(this.modalId);
    }
    else {
      console.log("id", id)
      this.modalService.close(id);
      this.visitInfo = false;
    }
  }
  private handleActions(event: 'checkinVC' | 'checkinIV' | 'onlyPay' | 'payCheckinVC' | 'payCheckinIV', data) {
    console.log('checkin event->', event);
    switch (event) {
      case 'checkinVC':
        this.checkinVC();
        break;
      case 'checkinIV':
        this.checkinIV();
        break;
      case 'onlyPay':
        this.initPay('PAY');
        break;
      case 'payCheckinVC':
        this.initPay('checkinVC');
        break;
      case 'payCheckinIV':
        if (!this.viewAppointment.chargeDetails.netPayable) {
          this.checkinIV()
        } else {
          this.initPay('checkinIV');
        }
        break;
    }
  }

  private handleCallBack(callback, close) {
    switch (callback) {
      case 'checkinVC':
        this.checkinVC()
        break;
      default:
        this.closeModal('calendar-appointment-details-modal')
    }
  }
  private checkinVC() {
    this.closetc(true);
  }
  private checkinIV() {
    if (this.facility.facilityControls?.schedulingRules?.checkInPolicy?.restrictions?.geolocation)
      var geoRestriction = this.facility.facilityControls.schedulingRules.checkInPolicy.restrictions.geolocation;
    console.log('satisfy', geoRestriction, this.geoDistanceSatisfied, this.viewAppointment.discount)
    if (!geoRestriction || (geoRestriction?.enabled && this.geoDistanceSatisfied) || !geoRestriction.enabled) {
      const selectedAppointment = this.viewAppointment
      let payload = {
        personId: this.user.getPersonId(),
        patientPersonId: selectedAppointment.personId,
        patientId: selectedAppointment.uhid,
        facilityId: selectedAppointment.facilityId,
        apptRefNumber: selectedAppointment.appointmentId,
        clinicCode: selectedAppointment.clinicCode,
        localeCode: this.user.getCurrentLanguage(),
        patientName: selectedAppointment.personName,
        practitionerName: this.translatePipe.transform(this.practName, 'LOCALE', 'name'),
        specialityName: this.translatePipe.transform(this.specName, 'LOCALE', 'name'),
        transactionNo: selectedAppointment.transactionNo,
        gatewayRefNo: selectedAppointment.paymentDetail.reference,
        discount: selectedAppointment.discount
      }
      if (selectedAppointment.chargeDetails.freeFollowUpYn == 'Y') payload['freeFollowUpYn'] = selectedAppointment.chargeDetails.freeFollowUpYn;

      if (selectedAppointment.chargeDetails.billingGroupCode) payload['billingGroupCode'] = selectedAppointment.chargeDetails.billingGroupCode
      if (selectedAppointment.chargeDetails.insuranceFinDtls) {
        payload['insuranceFinDtls'] = selectedAppointment.chargeDetails.insuranceFinDtls;
        payload['isInsurancePatient'] = true;
      }

      if (selectedAppointment.chargeDetails.isPromoApplied) {
        payload['appliedPromoCodes'] = selectedAppointment.chargeDetails.appliedpromocodedtls
        payload['discountType'] = "A"
        payload['discount'] = selectedAppointment.chargeDetails.promoDiscount,
          payload['isPromoCodeAppliedYn'] = 'Y'
      } else {
        payload['isPromoCodeAppliedYn'] = 'N'
      }
      if (selectedAppointment.chargeDetails.isCouponApplied) {
        payload['coupons'] = selectedAppointment.chargeDetails.appliedcouponcodedtls
        payload['holdCoupon'] = true
      }
      if (selectedAppointment.chargeDetails.isPointsApplied) {
        payload['appliedPoints'] = selectedAppointment.chargeDetails.appliedPoints
        payload['holdPoints'] = true
      }
      this.apptService.checkInAppt(payload).subscribe(response => {
        this.visitInfo = true;
        this.checkinResponseData = response;
        console.log("this.checkinResponseData", this.checkinResponseData)
        if (this.checkinResponseData && this.checkinResponseData.mappingFloorControls) {
          this.mappingFloorControls = this.checkinResponseData.mappingFloorControls;
        }
        if (this.checkinResponseData && this.checkinResponseData.mappingFloorInfo) {
          let info = this.checkinResponseData.mappingFloorInfo;
          this.mappingFloorInfo = {
            floor: info.floor,
            waitingArea: info.waitingArea,
            room: info.room
          }
        }

        console.log("this.mappingFloorInfo", this.mappingFloorInfo);
        if (this.enableQueueOnFacility) {
          console.log("enableQueueOnFacility", this.enableQueueOnFacility)
          if (!this.checkinResponseData['queueNo']) {
            console.log("this.checkinResponseData['queueNo']", !this.checkinResponseData['queueNo'])
            let tmpCount = 0;
            this.getQueueInterval = setInterval(() => {
              console.log('tmpcount====', tmpCount)
              if (tmpCount < 3 && !this.checkinResponseData['queueNo']) {
                console.log("inside test", (tmpCount < 3 && !this.checkinResponseData['queueNo']))
                this.getQueueNo('CHECKIN')
                tmpCount++;
              } else {
                console.log("else")
                clearInterval(this.getQueueInterval)
              }
            }, 2000)
          }
        }

      })
    }
  }
  private initPay(callback) {
    var payload = {
      personId: this.user.getPersonId(),
      linkedPersonId: this.viewAppointment['personid'],
      patientId: this.viewAppointment['uhid'],
      amount: this.user.fixDecimalPlaces(Number(this.viewAppointment.chargeDetails.netPayable)),
      currency: this.currency_code,
      episodeType: 'R',
      encounterId: '0',
      facilityId: this.viewAppointment.facilityId,
      requestType: this.viewAppointment.appointmentType == 'VIDEO' ? 'APTPAYMENT' : ((callback && (callback == 'PAY')) ? 'APTPAYMENT' : 'CHECKIN')
    };
    const selectedAppointment = this.viewAppointment
    let request = {
      personId: this.user.getPersonId(),
      patientPersonId: selectedAppointment.personId,
      patientId: selectedAppointment.uhid,
      facilityId: selectedAppointment.facilityId,
      apptRefNumber: selectedAppointment.appointmentId,
      clinicCode: selectedAppointment.clinicCode,
      localeCode: this.user.getCurrentLanguage(),
      patientName: selectedAppointment.personName,
      practitionerName: this.translatePipe.transform(this.practName, 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.specName, 'LOCALE', 'name'),
      isPromoCodeAppliedYn: selectedAppointment.chargeDetails.isPromoApplied ? 'Y' : 'N',
      isCouponAppliedYn: selectedAppointment.chargeDetails.isCouponApplied ? 'Y' : 'N',
      isPointsAppliedYn: selectedAppointment.chargeDetails.isPointsApplied ? 'Y' : 'N',
      coupons: selectedAppointment.chargeDetails.isCouponApplied ? selectedAppointment.chargeDetails.appliedcouponcodedtls : null,
      appliedPromoCodes: selectedAppointment.chargeDetails.isPromoApplied ? selectedAppointment.chargeDetails.appliedpromocodedtls : null,
      appliedPoints: selectedAppointment.chargeDetails.isPointsApplied ? selectedAppointment.chargeDetails.appliedPoints : null,
      billingGroupCode: selectedAppointment.chargeDetails.billingGroupCode ? selectedAppointment.chargeDetails.billingGroupCode : null,
      insuranceFinDtls: selectedAppointment.chargeDetails.insuranceFinDtls ? selectedAppointment.chargeDetails.insuranceFinDtls : null,
      isInsurancePatient: selectedAppointment.chargeDetails.insuranceFinDtls ? true : false,
      patientPayableAmount: this.user.fixDecimalPlaces(Number(selectedAppointment.chargeDetails.netPayable)),
      visitType: selectedAppointment.appointmentType
    };
    if (selectedAppointment?.chargeDetails?.netPayable == 0) {
      this.apptService.handleAlternatePayments(payload, request).subscribe(data => {
        this.handleCallBack(callback, true)
      })
    } else {
      this.apptService.startTransaction(payload, request).subscribe((response: any) => {
        let appointment = {
          _orderId: response['transaction_id'],
          saved_card_token: response['saved_card_token'],
          saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
          saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
          uhid: this.viewAppointment['uhid'],
          email: this.viewAppointment['email'] || environment.DEFAULT_EMAIL,
          mobile_no: ''
        }
        let url = window['location'];
        let params = `apptRefNumber=${this.viewAppointment.appointmentId}&callback=${callback}`
        let diff = (href, origin) => href.split(origin).join('');
        let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=APTPAYMENT&p=${btoa(params)}`;
        let paymentInfo = {
          _returnURL: returnURL,
          currency: this.user.getCurrencyForPayment() || this.currency_code,
          charge: this.user.fixDecimalPlaces(Number(this.viewAppointment.chargeDetails.netPayable)),
          paymentgatewayurl: this.paymentGatewayUrl
        }
        console.log(paymentInfo);
        this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
          this.showCheckout();
        })
      });
    }
  }
  private showCheckout() {
    this.isCheckout = true;
  }
  private initCancelAppointment() {
    if (this.viewAppointment.packageInfo && this.viewAppointment.checkedIn) {
      this.confirmCancelPackage()
    } else {
      this.confirmCancelAppt();
    }
  }
  private confirmCancelPackage() {
    let payload = {
      resourceId: this.viewAppointment.resourceId,
      patientPersonId: this.viewAppointment.personId,
      uhid: this.viewAppointment.uhid,
      facilityId: this.viewAppointment.facilityId,
      clinicCode: this.viewAppointment.clinicCode,
      appointmentNumber: this.viewAppointment.appointmentId,
      practitionerName: this.translatePipe.transform(this.practName, 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.specName, 'LOCALE', 'name'),
      patientName: this.viewAppointment.personName,
      packageInfo: this.viewAppointment.packageInfo,
      subscriptionInfo: this.viewAppointment.subscribedPackageData,
      encounterId: this.viewAppointment.visitId
    };
    if (!payload.encounterId && (this.viewAppointment.visitStatus && this.viewAppointment.visitStatus.encounterId)) {
      payload.encounterId = this.viewAppointment.visitStatus.encounterId
    }
    this.apptService.initCancelPackage(payload).subscribe((data) => {
      this.closeModal('calendar-appointment-details-modal');
    });
  }
  private confirmCancelAppt(isAllAppts?) {
    let payload = {
      resourceId: this.viewAppointment.resourceId,
      patientPersonId: this.viewAppointment.personId,
      uhid: this.viewAppointment.uhid,
      facilityId: this.viewAppointment.facilityId,
      clinicCode: this.viewAppointment.clinicCode,
      appointmentNumber: this.viewAppointment.appointmentId,
      practitionerName: this.translatePipe.transform(this.practName, 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.specName, 'LOCALE', 'name'),
      patientName: this.viewAppointment.personName
    };
    if (this.viewAppointment.appointmentType == 'HC') {
      let cancelPayload = {
        facilityId: this.viewAppointment.facilityId,
        appointmentId: this.viewAppointment.appointmentId,
        patientId: this.viewAppointment.uhid
      }
      if (isAllAppts && this.viewAppointment['recurringYn'] == 'Y') {
        cancelPayload['isRecurringAppt'] = true;
        cancelPayload['recurringAppointments'] = this.viewAppointment?.['appointments']?.map(ele => ele.appointmentId);
      }
      this.apptService.initHCCancelAppt(cancelPayload).subscribe((data) => {
        this.closeModal('calendar-appointment-details-modal');
      });
    } else {
      this.apptService.cancelAppt(payload).subscribe((data) => {
        this.closeModal('calendar-appointment-details-modal');
      });
    }
  }
  private rescheduleVisit() {
    if (this.serviceDetails.length)
      this.expressReschedule.emit(this.viewAppointment);
    else {
      this.reschedule.emit(this.viewAppointment);
    }
  }
  private openQuestionnarie(event?) {
    this.uploadQuestionnarie.emit(this.viewAppointment);
  }
  private closetc(status: boolean) {
    if (status) {
      if (!this.selected_lang) this.selected_lang = 'en';
      this.user.openLocation(this.viewAppointment['joinUrl'] + "&source=px&lan=" + this.selected_lang);
      this.closeModal('calendar-appointment-details-modal');
    }
  }
  private openUploadDocs() {
    this.uploadDocs.emit(this.viewAppointment);
  }
  private getQueueNo(type) {
    console.log("checkinResponseData", this.checkinResponseData)

    let param = {
      facilityID: this.viewAppointment.facilityId,
      patientId: this.viewAppointment.patientId,
      encounterId: type == 'CHECKIN' ? this.checkinResponseData['encounterId'] : this.viewAppointment.visitId,
      apptRefnumb: this.viewAppointment.appointmentId
    }
    this.apptService.getQueue(param).subscribe((data) => {
      if (data) {
        if (type == 'CHECKIN') {
          this.checkinResponseData['queueNo'] = data;
        } else this.viewAppointment['queueNo'] = data;
      }
    }, err => {
      
    })
  }

  public getFloorName(data) {
    return data[this.user.getCurrentLanguage()]
  }
  public getServiceName(data) {
    console.log("getServiceName", data)
    let names = data['localNames'] ? data['localNames']['names'] : null;
    if (!names || names.length == 0) {
      return data ? data.desc : '';
    } else {
      let t = names.find(name => {
        return name.locale == this.user.currentLanguage
      })
      if (t) {
        return t.name;
      } else {
        return names[0].name;
      }
    }
  }
  public checkIsNaN(num) {
    return isNaN(num);
  }
  public showAction() {
    this.isActionOpen = !this.isActionOpen
  }

  //Promo coupon

  public pointsApplied(event) {
    if (event.type == 'APPLY') {
      this.viewAppointment.chargeDetails.pointsData = {}
      this.applyPoints(event.data)
    } else if (event.type == 'REMOVE') {
      this.removePoints()
    }
  }
  public applyingPoints(event) {
    this.setIsApplyingCode(event.status)
  }
  public applyingPromoCode(event) {
    this.setIsApplyingCode(event.status)
  }
  public applyingCouponCode(event) {
    this.setIsApplyingCode(event.status)
  }

  private setIsApplyingCode(val) {
    // this.isApplyingCode = val;
    // this.applyingCode.emit({ status: val });
  }

  public appliedPromocode(event) {
    if (event.type == 'APPLY') {
      this.applyDiscount(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeDiscounts();
    }
  }
  public couponApplied(event) {
    if (event.type == 'APPLY') {
      this.viewAppointment.chargeDetails.couponTitle = []
      this.applyCouponcode(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeCouponCode()
    }
  }

  private applyDiscount(response) {
    this.viewAppointment.chargeDetails.appliedpromocodedtls = response.appliedpromocodedtls;
    this.viewAppointment.chargeDetails.promoDiscount = Number(response.calculatedDiscountAmt);
    this.viewAppointment.chargeDetails.netPayable = Number(response.netservicecharge)
    this.viewAppointment.chargeDetails.discountedPayable = Number(response.netservicecharge)
    this.viewAppointment.chargeDetails.isPromoApplied = response.appliedpromocodedtls?.length > 0;
    if(response.netchargedtls && response.netchargedtls.length>0){
      this.chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(response.netchargedtls[0].taxAmount,response.netchargedtls[0].taxExemptAmount);
    }
    if (response.pointsApplied) {
      this.applyPoints({ pointsDerivative: response.pointsApplied })
    } else {
      this.removePoints()
    }
    this.handlePostDiscount()
  }

  public removeDiscounts() {
    this.viewAppointment.chargeDetails.netPayable = Number(this.viewAppointment.chargeDetails.totalAmount)
    this.viewAppointment.chargeDetails.discountedPayable = Number(this.viewAppointment.chargeDetails.totalAmount)
    this.viewAppointment.chargeDetails.isPromoApplied = false;
    this.viewAppointment.chargeDetails.promoDiscount = 0;
    this.removePoints()
    this.handlePostDiscount()
  }
  
  private handlePostDiscount() {
    let isCouponHandled = this.coupon && this.viewAppointment.chargeDetails.isCouponApplied
    let isPointsHandled = this.points && this.viewAppointment.chargeDetails.isPointsApplied && this.pointsType == 'PAYMENT'

    if (isCouponHandled || isPointsHandled) {
      if (isCouponHandled) this.coupon.removeAllCoupons(true);
      if (isPointsHandled) this.points.removeAllPoints(!isCouponHandled);
    } else {
      this.calculatePayable()
    }
  }

  private applyCouponcode(response) {
    this.viewAppointment.chargeDetails.appliedcouponcodedtls = response.appliedcouponcodedtls;
    this.viewAppointment.chargeDetails.couponTitle = this.viewAppointment.chargeDetails.appliedcouponcodedtls.map(item => item.couponTitle)
    this.viewAppointment.chargeDetails.couponDiscount = Number(response.value);
    this.viewAppointment.chargeDetails.isCouponApplied = response.appliedcouponcodedtls.length > 0;
    this.calculatePayable(true)
  }
  private removeCouponCode() {
    this.viewAppointment.chargeDetails.appliedcouponcodedtls = null
    this.viewAppointment.chargeDetails.couponTitle = []
    this.viewAppointment.chargeDetails.isCouponApplied = false;
    this.viewAppointment.chargeDetails.couponDiscount = 0;
    this.calculatePayable(true)
  }
  private applyPoints(response) {
    console.log('applypoints', response)
    this.viewAppointment.chargeDetails.appliedPoints = response.pointsDerivative;
    this.viewAppointment.chargeDetails.pointsDiscount = Number(response.pointsDerivative.amount);
    this.viewAppointment.chargeDetails.isPointsApplied = this.viewAppointment.chargeDetails.pointsDiscount > 0
    if(this.pointsType == 'PAYMENT')
    this.calculatePayable(true)
  }
  private removePoints() {
    this.viewAppointment.chargeDetails.appliedPoints = null;
    this.viewAppointment.chargeDetails.pointsDiscount = 0;
    this.viewAppointment.chargeDetails.isPointsApplied = false;
    if (this.pointsType == 'PAYMENT')
    this.calculatePayable(true)
    this.calculateVatCharges(this.chargeVatInfoRef);
  }
  private calculatePayable(recheckActions:boolean = false) {
    let couponDiscount = this.viewAppointment.chargeDetails.couponDiscount || 0;
    let pointsDiscount = this.pointsType == 'PAYMENT' ? (this.viewAppointment.chargeDetails.pointsDiscount || 0) : 0;

    this.viewAppointment.chargeDetails.netPayable = this.viewAppointment.chargeDetails.discountedPayable - couponDiscount - pointsDiscount;
    this.viewAppointment.chargeDetails._payableWithoutCoupon = this.viewAppointment.chargeDetails.netPayable + couponDiscount;
    this.viewAppointment.chargeDetails._payableWithoutPoints = this.viewAppointment.chargeDetails.netPayable + pointsDiscount;
    this.resetPaymentFlow(recheckActions)
    console.log('calculatePayable', this.viewAppointment)
  }
  private resetPaymentFlow(recheckActions: boolean = false) {
    if (Number(this.viewAppointment.chargeDetails.netPayable) <= 0) {
      this.viewAppointment.chargeDetails.netPayable = 0;
    } else {

    }
    this.viewAppointment.chargeDetails.netPayable = this.user.fixDecimalPlaces(Number(this.viewAppointment.chargeDetails.netPayable));
    this.viewAppointment.chargeDetails = _.cloneDeep(this.viewAppointment.chargeDetails)
    if (recheckActions) {
      this.processScheduleFlags(recheckActions)
    }
    setTimeout(_ => {
      this.cdf.detectChanges();
    }, 500)
  }
  private setRequiredParam() {
    this.facility = this.user.findFacility(this.viewAppointment['facilityId']);
    this.visitType = this.viewAppointment['appointmentType'];
    this.practName = this.pracDetailsPipe.transform(this.viewAppointment.resourceId, this.viewAppointment['facilityId'], 'resourceName');
    let specId = this.pracDetailsPipe.transform(this.viewAppointment.resourceId, this.viewAppointment['facilityId'], 'specialityId');
    this.specName = this.specDetailsPipe.transform(specId, this.viewAppointment['facilityId'], 'specialityName');
    this.canFillQuestionnarie = this.viewAppointment['_state'] == 'UPCOMING' && this.viewAppointment.appointmentType == 'VIDEO' && (!this.viewAppointment.consultationStatus || (this.viewAppointment.consultationStatus && this.viewAppointment.consultationStatus != 'FINISH' && this.viewAppointment.consultationStatus != 'CANCEL'))
    if (this.facility) {
      if (this.facility.facilityControls && this.facility.facilityControls.schedulingRules && this.facility.facilityControls.schedulingRules.others && this.facility.facilityControls.schedulingRules.others.showQueueNo
        && this.facility.facilityControls.schedulingRules.others.showQueueNo == 'Y') {
        this.enableQueueOnFacility = true;
        if (this.viewAppointment && this.viewAppointment['checkedIn'] == "true" && !this.viewAppointment['queueNo']) {
          this.getQueueNo('APPT')
        }
      } else {
        this.enableQueueOnFacility = false;
      }
    }
    this.isWithinGeoRange();
    if (this.viewAppointment['homeCareServices']) {
      if (this.viewAppointment?.homeCareServices?.homeCareServices?.length) { 
        this.homecareService = this.viewAppointment['homeCareServices']['homeCareServices']
      }
      if (this.viewAppointment['homeCareServices']['paymentMode']) {
        this.viewAppointment['_apptMode'] = this.viewAppointment['homeCareServices']['paymentMode']['code']
      }
    }
    if (this.viewAppointment['paymentDetail']['status'] == 'PAID') {
      this.alreadyPaid = true;
      this.discountValue = Number(this.viewAppointment['discount'])
      if (this.viewAppointment?.couponDetails?.couponAmount?.length) {
        this.totalCouponBurntAmt = this.viewAppointment['couponDetails']['couponAmount'].map(element => element).reduce((prev, next) => parseFloat(Number(prev).toFixed(2)) + parseFloat(Number(next).toFixed(2)));
      }
      if (this.viewAppointment['promoCodeAppliedYn'] == 'Y' && this.viewAppointment['promoDetails']) {
        this.promoAmtForPaidAppt = this.viewAppointment['promoDetails'][0]['calculatedDiscountAmt']
      }
      this.totalPointsPaid = this.viewAppointment?.couponDetails?.pointsAmount || null
      if (this.viewAppointment && this.viewAppointment.billLevelDiscountAmt) {
        this.viewAppointment['discount'] += Number(this.viewAppointment.billLevelDiscountAmt);
      }
    }
    if (this.viewAppointment['appointmentType'] && this.viewAppointment['appointmentType'] == 'CLINIC') {
      let services = this.viewAppointment['resourceDetail'] ? this.viewAppointment['resourceDetail']['servicesToOrder'] : null
      if (services && services.length > 0) {
        services.forEach(data => {
          let concepts = data['concepts'];
          concepts.map(concept => {
            this.serviceDetails.push(concept);
          })
        })
      }
    }
    this.promoCodeParam = {
      facility: {
        id: this.viewAppointment.facilityId
      },
      patient: {
        id: this.viewAppointment.uhid
      },
      practitioner: {
        id: this.viewAppointment.resourceId
      }
    }
  }
  private isWithinGeoRange() {
    this.user.getGeoLocation().subscribe(res => {
      console.log('location data=------', res)
      if (res.status) {
        let data = res.data;
        let latlon = data;
        if (latlon) {
          if (this.facility.facilityControls.schedulingRules.checkInPolicy && this.facility.facilityControls.schedulingRules.checkInPolicy.restrictions && this.facility.facilityControls.schedulingRules.checkInPolicy.restrictions.geolocation) {
            let geoRestriction = this.facility.facilityControls.schedulingRules.checkInPolicy.restrictions.geolocation;
            console.log("isWithinGeoRange", geoRestriction.enabled)
            if (geoRestriction.enabled) {
              let faclatlon = this.facility.facilityGeoLocation;
              let distance = this.geodistance.transform(faclatlon, latlon['lat'], latlon['lon']);
              if (geoRestriction.unit == 'm') {
                distance = distance * 1000;
              }
              if (distance <= geoRestriction.distance) {
                this.geoDistanceSatisfied = true;
                console.log("getGeoLocation data", this.geoDistanceSatisfied)
              } else {
                this.geoDistanceSatisfied = false;
                console.log("getGeoLocation data", this.geoDistanceSatisfied)
              }
            } else {
              this.geoDistanceSatisfied = true;
            }
          } else {
            this.geoDistanceSatisfied = true;
            console.log("getGeoLocation data", this.geoDistanceSatisfied)
          }
        }
      }
    })
    return false;
  }

  private calculateVatCharges(parsedCharge){
    let chargeVatDetails = parsedCharge;
    chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(chargeVatDetails.vatAmount,chargeVatDetails.vatExemptedAmt)
    this.chargeVatDetails=chargeVatDetails;
    console.info('chargeVatDetails',this.chargeVatDetails)
  }

  private calculateVatDisplayAmount(vatamt,vatexpamt){
    let vatAmount, vatExemptedAmt,vatDisplayAmount;
    vatAmount = (vatamt && vatamt!='') ? parseFloat(vatamt) : 0;
    vatExemptedAmt = (vatexpamt && vatexpamt!='') ? parseFloat(vatexpamt) : 0;
    vatDisplayAmount = vatAmount - vatExemptedAmt;
    vatDisplayAmount = vatDisplayAmount.toString();
    return vatDisplayAmount;
  }

}
