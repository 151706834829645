<ng-container>
  <ng-container *ngIf="isEnablePoints && !promocodeApplied">
    <ng-container [ngTemplateOutlet]="POINTS"></ng-container>
  </ng-container>
  <ng-container *ngIf="isEnablePromo && !pointsApplied">
    <ng-container [ngTemplateOutlet]="PROMO"></ng-container>
  </ng-container>
</ng-container>

<ng-template #PROMO>
  <div id="promo-input" class="promo-input-card">
    <div class="set-row">
      <label class="set-column col-size-12">
        <p class="title-label">{{"PROMO#LABEL#TITLE#PROMOCODE" | translate}}</p>
      </label>
    </div>
    <div class="set-row input-row">
      <div class="set-column col-size-9">
        <input placeholder="{{'COUPON#LABEL#ENTER#PROMO#CODE' | translate}}" fill="outline" [(ngModel)]="promocode"
          (input)="onInput($event)" [readonly]="promocodeChecking" *ngIf="!promocodeApplied">
        <input placeholder="{{'COUPON#LABEL#ENTER#PROMO#CODE' | translate}}" fill="outline" [(ngModel)]="maskedPromoCode"
          [readonly]="true" *ngIf="promocodeApplied">
      </div>
      <div class="set-column col-size-3">
        <button expand="full" class="apply-button" (click)="checkPromoCode()"
          [disabled]="promocodeChecking || promocodeChecked || promocode.length == 0" *ngIf="!promocodeApplied">
          <ng-container *ngIf="!promocodeChecking">
            {{'PROMO#BTN#LABEL#APPLY' | translate}}
          </ng-container>
          <ng-container *ngIf="promocodeChecking">
            <div class="page-loader-container">
              <div class="pageloader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </ng-container>
        </button>
        <button expand="full" class="apply-button" *ngIf="promocodeApplied" (click)="removePromo()">
          {{'PROMO#BTN#LABEL#REMOVE' | translate}}
        </button>
      </div>
    </div>
    <ng-container *ngIf="promocodeStatus == 'SUCCESS' || promocodeStatus == 'FAILURE'">
      <div class="set-row input-error" *ngIf="promocodeStatus == 'SUCCESS'">
        <!-- <ion-icon class="no-flip" size="small" src="./assets/icon/check.svg"></ion-icon> -->
        <p class="set-column col-size-12"><span class="set-color font-success-small">{{'PROMO#APPLIED#LABEL#SUCCESS' |
            translate}}</span></p>
      </div>
      <div class="set-row input-error" *ngIf="promocodeStatus == 'FAILURE'">
        <!-- <ion-icon size="small" src="./assets/icon/error_circle.svg"></ion-icon> -->
        <p class="set-column col-size-12"><span class="set-color font-danger-small"
            color="danger">{{'PROMO#APPLIED#LABEL#FAILURE' | translate}}</span></p>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #POINTS>
  <div id="points-input">
    <button class="choose-coupon-btn"
      *ngIf="!appliedPoints || !appliedPoints.pointsDerivative || !appliedPoints.pointsDerivative.amount" size="block"
      fill="outline" (click)="openPointsModal()">
      <img src="./assets/icons/nudheek-icon.svg" class="nudheek-icon">
      {{"POINTS#LABEL#SELECT#POINTS" | translate}}
    </button>
    <div class="set-item choose-coupon-btn"
      *ngIf="appliedPoints && appliedPoints.pointsDerivative && appliedPoints.pointsDerivative.amount > 0"
      (click)="openPointsModal()">
      <div>
        <img src="./assets/icons/nudheek-icon.svg" class="nudheek-icon">
        <label>
          <p>{{"POINTS#LABEL#APPLIED#POINTS" | translate : 'LABELS' : null:
            null:appliedPoints.pointsDerivative}}</p>
        </label>
      </div>
      <img src="./assets/icons/close_black.svg" (click)="$event.stopPropagation; removeAllPoints()" alt="">
    </div>
  </div>
</ng-template>
<app-altpayment-modal *ngIf="showPointsModal" [pointsProps]="pointsProps" [parsedChargeInfo]="parsedChargeInfo"  [discountDetails]="discountDetails"
  (complete)="completePoints($event)"></app-altpayment-modal>
<app-consent-modal *ngIf="showConsentModal" (complete)="completeConsent($event)"></app-consent-modal>