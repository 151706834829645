<div class="flip-cards">
  <div class="flip-cards-inner">
    <div class="flip-cards-front">
      <div class="component">
        <div class="header border-bottom">
          <div (click)="navigateToPreviousTopic()" class="float-right closeicon pointer hide">
            <img src="./assets/icons/calendar/arrow.svg" alt="">
          </div>
          <div class="font-primary-subtitle bold">{{'sub_schedule_consult_doctor_now'|translate}}</div>
          <div (click)="closeConsultation()" class="float-right closeicon pointer"><img
              src="./assets/icons/close_black.svg">
          </div>
        </div>
        <div id="appt-confirm">
          <div class="appt-review-details">
            <div class="new-card-review">
              <div class="col">
                <div class="card">
                  <div class="card-head">
                    <div class="conatiner border-bottom">
                      <p class="subtitle m-btm">{{'onspot_req_ttl_appt_type'|translate:'LABELS'}}</p>
                      <span class="font-default-small bold">{{'onspot_req_ttl_video_consult_onspot'|translate:'LABELS'}}
                      </span>
                    </div>
                    <div class="card-content">
                      <div>
                        <p class="subtitle lbl">{{'onspot_req_ttl_appt_for'|translate:'LABELS'}}</p>
                        <div class="patient-details border-bottom">
                          <div class="image">
                            <app-person-avatar [url]="consultData['patientInfo']['imageurl']"> </app-person-avatar>
                          </div>
                          <div class="partitioner-content">
                            <div class="title bold">
                              {{consultData['patientInfo']['personname']}}
                            </div>
                            <div class="font-light-small">
                              {{consultData['patientInfo']['relationshiptype'] | translate : 'RELATION'}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="subtitle lbl">{{'onspot_req_ttl_consult_doc'|translate:'LABELS'}}</p>
                        <div class="partitioner-details border-bottom">
                          <div class="image">
                            <app-practitioner-avatar
                              [url]="(consultData['practInfo']['practId']|partitionerDetails: consultData['patientInfo']['facilityid']:'resourceAvatarURL')">
                            </app-practitioner-avatar>
                          </div>
                          <div class="partitioner-content">
                            <div class="title bold">
                              {{(consultData['practInfo']['practId'] |
                              partitionerDetails:consultData['patientInfo']['facilityid']:'resourceName'| translate :
                              'LOCALE' : 'name') || consultData.practInfo['practName'] }}
                            </div>
                            <div class="font-light-small">
                              {{ "onspot_req_ttl_visiting_consultant" | translate }}
                            </div>
                          </div>
                          <div class="prim-ttl-txt bold a-center">
                            {{ consultData['consultationCost']['charge'] }} {{
                            consultData['consultationCost']['chargeCurrency']}}
                          </div>
                        </div>
                      </div>
                      <div class="conatiner">
                        <p class="subtitle m-btm">{{'onspot_req_ttl_appt_date_time'|translate:'LABELS'}}</p>
                        <span class="font-default-small bold">{{ currentTime }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="referral-card" *ngIf="enterReferral">
                    <ng-container>
                      <p class="subtitle lbl">{{'appt_dtl_ttl_referral_code'|translate:'LABELS'}}</p>
                      <div class="padding">
                        <input class="ref-input" [type]="referralControl?referralControl.type:'text'"
                          (input)="onTextInput($event)"
                          placeholder="{{ 'appt_dtl_placeholder_enter' | translate:'LABELS'}}"
                          [(ngModel)]="referralCode" [minLength]="referralControl?referralControl.minLength:null"
                          [maxLength]="referralControl?referralControl.maxLength:null" />
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="default-bg" *ngIf="isReferralApplicable">
                  <p class="prim-ttl-txt " (click)="enterReferral = !enterReferral" *ngIf="!enterReferral">
                    {{'appt_dtl_ttl_enter_referral_code'| translate:'LABELS'}}</p>
                </div>
              </div>
              <div class="col payment-col">
                <div class="policy-validation-card-container" *ngIf="policyValidation">
                  <div class="policy-validation-card card">
                    <div class="card-head bold border-bottom">
                      {{'lbl_head_policy_details' | translate}}
                    </div>
                    <div class="card-content">
                      <div class="success-failure-img">
                        <img src="./assets/icons/insurance_icons/success.svg" *ngIf="!policyValidation.promptCashAppt">
                        <img src="./assets/icons/insurance_icons/alert.svg" *ngIf="policyValidation.promptCashAppt">
                      </div>
                      <div class="validation-text">
                        <div class="policy-name bold">{{policyValidation.promptLabels.title | translate}}</div>
                        <div class="policy-status font-light-small" *ngIf="!policyValidation.promptCashAppt">
                          {{'lbl_insurance_applied_success' | translate}}</div>
                        <div class="policy-status font-light-small" *ngIf="policyValidation.promptCashAppt">
                          {{'lbl_insurance_applied_failure' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="chargeDetails">
                  <div *ngIf="(patientPayable > 0 || chargeDetails.isPointsApplied) && isEnablePoints && pointsType == 'PAYMENT'">
                    <app-altpayment-input [input]="'POINTS'" [totalAmount]="chargeDetails.consultationCharge"
                      [netPayable]="chargeDetails._payableWithoutPoints"
                      [promoCodeDetails]="chargeDetails['discountDetails']" (pointsApplied)="pointsApplied($event)" #points
                      (applyingPoints)="applyingPoints($event)"></app-altpayment-input>
                  </div>
                  <ng-container *ngIf="(isEnablePoints && pointsType == 'DISCOUNT' && chargeDetails.isPointsApplied) || (isEnablePromo && patientPayable > 0 && chargeDetails.discountDetails && chargeDetails.discountDetails.applicable || chargeDetails.isPromoApplied)">
                    <app-promo-input [patientPayable]="chargeDetails.consultationCharge" [selections]="selections"
                      [discountDetails]="chargeDetails['discountDetails']" (applyingDiscount)="applyingPromoCode($event)"
                      (appliedDiscount)="appliedPromocode($event)" [isEnablePoints]="isEnablePoints && pointsType == 'DISCOUNT'"
                      [isEnablePromo]="isEnablePromo" [parsedChargeInfo]="chargeVatDetails ? chargeVatDetails: null"></app-promo-input>
                  </ng-container>
                  <div>
                    <ng-container *ngIf="patientPayable > 0 && isEnableCoupon || chargeDetails.isCouponApplied">
                      <app-altpayment-input [input]="'COUPON'" [totalAmount]="chargeDetails.consultationCharge"
                        [netPayable]="chargeDetails._payableWithoutCoupon"
                        [promoCodeDetails]="chargeDetails['promoCodeDetails']" (couponApplied)="couponApplied($event)" #coupon
                        (applyingCoupon)="applyingCouponCode($event)"></app-altpayment-input>
                    </ng-container>
                  </div>
                </ng-container>
                <p class="font-default-small bold">{{'onspot_req_lbl_summary_of_charges'|translate:'LABELS'}}</p>
                <div class="payment-details">
                  <div class="card">
                    <div class="card-content"  *ngIf="!fetchingCharge">
                      <div class="payment-group">
                        <div class="payment-content border-bottom" *ngIf="!chargeVatDetails">
                          <div class="subheader-font">{{'onspot_req_lbl_consult_charges'|translate:'LABELS'}}
                          </div>
                          <div class="prim-ttl-txt bold">
                            <app-display-currency [amount]="chargeDetails.consultationCharge">
                            </app-display-currency>
                          </div>
                        </div>
                        <div class="payment-content border-bottom" *ngIf="chargeVatDetails && chargeVatDetails.baseServiceAmount">
                          <div class="subheader-font">{{'onspot_req_lbl_consult_charges'|translate:'LABELS'}}
                          </div>
                          <div class="prim-ttl-txt bold">
                            <app-display-currency [amount]="chargeVatDetails.baseServiceAmount">
                            </app-display-currency>
                          </div>
                        </div>
                        <div class="payment-content border-bottom" *ngIf="chargeDetails.isPromoApplied && chargeDetails.promoDiscount > 0">
                          <div class="subheader-font">{{'lbl_Promocode'|translate:'LABELS'}}</div>
                          <div class="prim-ttl-txt bold ">
                            <app-display-currency [amount]="chargeDetails.promoDiscount"></app-display-currency>
                          </div>
                        </div>
                        <div class="payment-content border-bottom" *ngIf="chargeDetails.isPointsApplied&& chargeDetails.pointsDiscount > 0">
                          <div class="subheader-font">{{'lbl_points'|translate:'LABELS'}}</div>
                          <div class="prim-ttl-txt bold">
                            <app-display-currency [amount]="chargeDetails.pointsDiscount" [prefix]="'-'"></app-display-currency>
                          </div>
                        </div>
                        <div class="payment-content border-bottom" *ngIf="chargeDetails.isCouponApplied && chargeDetails.couponDiscount > 0">
                          <div class="subheader-font">{{'lbl_coupon'|translate:'LABELS'}}</div>
                          <div class="prim-ttl-txt bold"><app-display-currency [amount]="chargeDetails.couponDiscount"></app-display-currency>
                          </div>
                        </div>

                        <div class="payment-content border-bottom" *ngIf="chargeVatDetails && chargeVatDetails.vatDisplayAmount">
                          <div class="subheader-font">{{'onspot_lbl_Vat_amount'|translate:'LABELS'}}</div>
                          <div class="prim-ttl-txt bold"><app-display-currency [amount]="chargeVatDetails.vatDisplayAmount"></app-display-currency>
                          </div>
                        </div>

                        <div class="payment-content">
                          <div class="subheader-font bold">{{'onspot_req_lbl_total_to_be_paid'|translate:'LABELS'}}
                          </div>
                          <div class="def-subtitle bold">
                            <app-display-currency [amount]="patientPayable"></app-display-currency></div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="fetchingCharge">
                      <div class="page-loader-container">
                        <div class="pageloader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer" *ngIf="!fetchingCharge">
          <div class="button-bar">
            <button class="primary-button" (click)="initAppointment()" *ngIf="patientPayable > 0" [disabled]="chargeError">
              {{ 'lbs_continue_with_payment' | translate }}
            </button>
            <button class="primary-button" (click)="confirmAppt()" *ngIf="(chargeDetails.isCouponApplied && patientPayable == 0 )|| (patientPayable == 0 || patientPayable < 0)" [disabled]="chargeError">{{ 'btn_confirm_appt' | translate
              }}</button>
            <div class="apple-pay-container" *ngIf="showApplePay && patientPayable > 0">
              <div id="apple-con-id" class="apple-pay-button" (click)="initApplePay()"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flip-cards-back">
      <ng-container>
      </ng-container>
    </div>
  </div>
</div>


<div id="cash-prompt" *ngIf="showCashPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-subtitle bold">{{'ttl_insurance' | translate}}</span>
        <div class="close float-right closeicon pointer" (click)="proceedWithCash()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <img src="./assets/icons/insurance_icons/badge.svg">
        <div class="ref-title bold">{{promptLabels.title | translate}}</div>
        <div class="ref-subtitle font-light-small">{{promptLabels.subtitle | translate}}</div>
        <div class="ref-alert" *ngIf="promptLabels.alert">
          <img src="./assets/icons/insurance_icons/info.svg">
          {{promptLabels.alert | translate}}
        </div>
        <button class="primary-button" (click)="proceedWithCash()">{{'btn_proceed_with_cash' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div id="ins-prompt" *ngIf="showInsPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-title bold">{{'ttl_insurance' | translate}}</span>
        <div class="close float-right closeicon pointer" (click)="proceedWithIns()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <img src="./assets/icons/insurance_icons/badge.svg">
        <div class="ref-title bold">{{promptLabels.title | translate}}</div>
        <div class="ref-subtitle font-light-small">{{promptLabels.subtitle | translate}}</div>
        <div class="ref-alert" *ngIf="promptLabels.alert">
          <img src="./assets/icons/insurance_icons/info.svg">
          {{promptLabels.alert | translate}}
        </div>
        <div class="ref-subtitle">{{'lbs_proceed_with_ins' | translate}}</div>
        <div class="btn-row">
          <button class="footer-button-small white-font-primary-button border"
            (click)="retrievePaymentWithCash()">{{'btn_proceed_with_cash_yes' | translate}}</button>
          <button class="footer-button-small primary-button" (click)="proceedWithIns()">{{'btn_proceed_with_ins_yes' |
            translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="videoConsultationTC">
  <app-video-consult-tc (complete)="continuePay()" (close)="closeVcModal()"></app-video-consult-tc>
</div>