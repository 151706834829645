import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { ServiceRequestDetailsComponent } from './service-request-details.component';



@NgModule({
  declarations: [
    ServiceRequestDetailsComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [ServiceRequestDetailsComponent],
})
export class ServiceRequestDetailsModule { }
