import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import * as STRINGS from '@string';
import { VideoService } from './video.service';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public socket: any;
  public homecareLocationUpdateSubject: Subject<any>;
  constructor(private video: VideoService) {
    this.socket = io(environment['SOCKET_URL'], { path: environment['SOCKET_PATH'], transports: ['websocket'] });
    this.homecareLocationUpdateSubject = new Subject();
  }

  public getSocket() {
    return this.socket;
  }

  public userLeft(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.USER_LEFT, (data) => {
        observer.next(data);
      });
    })
  }
  public startConversation(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.START_CONVERSATION, (data) => {
        observer.next(data);
      });
    })
  }
  public docBusy(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.DOC_BUSY, (data) => {
        observer.next(data);
      });
    })
  }
  public joinVideoCall(appointment) {
    const data = {
      token: this.video.getUserToken(),
      entityId: 'HUMBER',
      appointments: {
        facilityId: appointment.facility_id,
        apptNo: appointment.appointmentno
      },
      userContext: {
        userType: 'PAT',
        patientId: appointment.uhid
      }
    }
    this.socket.emit('join-video-call', data);
  }
  public initVideoCall(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.INIT_VIDEO_CALL, (data) => observer.next(data));
    })
  }

  public joinPatientEvent(data) {
    const request = {
      entityId: data.entityId, 
      patients: data.uhid 
    }
    this.socket.emit('join-patient-events', request);
  }
  public joinHomecareLocationUpdate(apptId, facilityId, practId) {
    const request = {
      apptId: apptId, 
      facility_id: facilityId,
      pract_id: practId
    }
    console.log("joinPatientEvent", request)
    this.socket.emit('join-homecare-pract-position-for-appt', request);
    this.socket.on('homecare_pract_location_updated', (data) => {
      this.homecareLocationUpdateSubject.next(data);
    })
  }

  public leaveHomecareLocationUpdate(apptId, facilityId, practId) {
    const request = {
      apptId: apptId, 
      facility_id: facilityId,
      pract_id: practId
    }
    console.log("joinPatientEvent", request)
    this.socket.emit('leave-homecare-pract-position', request);
  }

  public onTaskChange(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.PATIENT_TASK_CHANGE, (data) => observer.next(data));
    })
  }

  public onAppointmentChange(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.PATIENT_APPT_CHANGE, (data) => observer.next(data));
    })
  }

  public onspotPatientEmit(data) {
    this.socket.emit('onspot-status-changed', data);
  }

  public onPatNotification(): Observable<any> {
    return new Observable(observer => {
      this.socket.on(STRINGS.PATIENT_NOTIFICATION, (data) => observer.next(data));
    })
  }

}
