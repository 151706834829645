<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">{{"REMOVEINSURANCE#MODAL#REMOVEINSURANCE" | translate}}</div>
      <div (click)="close()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content" style="overflow-y: scroll;">
      <div class="remove-insu">
        <div class="remove-insu-error">
          <img src="./assets/icons/manage-insurance/error.svg" alt="">
        </div>
        <div class="insu-msg">
          <label class="label">{{"REMOVEINSURANCE#PAGE#WARNING#MSG" | translate}}</label>
        </div>
        <div class="button-row">
          <div class="primary-outline" (click)="close()">
            <p>
              {{"REMOVEINSURANCE#BUTTON#ACTION#LABEL#CANCEL" | translate}}
            </p>
          </div>
          <div class="update-button-con" (click)="changeBlnGrp()">
            <p>
              {{"REMOVEINSURANCE#BUTTON#ACTION#LABEL#REMOVE" | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
