import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
import * as moment from 'moment';
import { EventsService } from 'src/app/services/events.service';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  providers:[EventsService]
})
export class SegmentComponent extends Type implements OnInit, SectionInterface {

  @Input() public section;
  @Input() public sections;
  @Input() public prescriptionRules;
  @Input() topic:any;
  @Input() frequencyApplicable:any;
  @Input() routeApplicable:any;
  @Input() tabindex: any;
  @Output() valueChangeComplete: EventEmitter<any>;
  public enableCustomAns:boolean;
  public customAns: string;
  public randomId: string;
  constructor(public events: EventsService, cfs: ClinicalformService) {
    super(cfs);
    this.valueChangeComplete = new EventEmitter<any>();
    let randLetter = (Math.random() + 1).toString(36).substring(7) + String.fromCharCode(65 + Math.floor(Math.random() * 26));
    const domaincode = this.section ? this.section.domainCode: moment().valueOf()
    this.randomId = domaincode + randLetter + Date.now()
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    if ((this.section['codify'] == 'Y' && this.section['domainControls']['iconsApplicable']) || this.section['iconsApplicable']) {
      this.section['trueType'] = 'checkbox';
      if (!this.section['checked'])
        this.section['checked'] = [];
    }
    else
      super.decideCheckBoxType(this.section);
    super.getValuesforSection(this.section);
    if(this.section.domainCode === 'PRESFRQ' && Array.isArray(this.frequencyApplicable)){
      this.section['options'] = this.parseCustomValueToOptions(this.frequencyApplicable,null);
    }else if(this.section.domainCode === 'PRESROUTE' && Array.isArray(this.routeApplicable)){
      this.section['options'] = this.parseCustomValueToOptions(null,this.routeApplicable);
      if(this.section['options'].length === 1 && !this.section['selected'])
        this.listenToEvent(this.section['options'][0].localName[0].text, this.section['options'][0].localName);
    }
    this.domainLevelDefaultValue();
    if(this.prescriptionRules) 
      this.loadPrescriptionRules();
    if (this.section['conditionalFieldCode']) {
      this.changeOnSourceDomainValue();
      this.events.on('reload_' + this.section['domainCode'], () => {
        // console.log(this.section);
        this.changeOnSourceDomainValue();
      })
    }
      
  }

  private parseCustomValueToOptions(freqValue, routeValue){
      let value = [];
      if(freqValue)
        freqValue.forEach(f=> value.push({
          code: f['Freqcode'],
          localName:[{locale:'en', text:f['Freqdesc']}]
        }))
      else if (routeValue)
        routeValue.forEach(r=> value.push({
          code: r['routecode'],
          localName:[{locale:'en', text:r['routedesc']}]
        }))
     return value;   
  }


  listenToEvent(value, locale) {
    // console.log('selected value ', value);
    if ((this.section['_noOfSelectionsAllowedBySourceDomain'] ? this.section['checked'].length >= this.section['_noOfSelectionsAllowedBySourceDomain'] : this.section['checked'].length >= this.section['noOfSelectionsAllowed']) && !this.section['checked'].includes(value))
        this.section['checked'].shift();//so that user can switch to other option without deselecting current value.
      // return;
    let valueChanged = false;
    switch (this.section['trueType']) {
      case 'radio':
        if (this.section['selected'] == value) {
          this.section['selected'] = '';
          this.section['selectedLocale'] = null
          this.section['completed'] = false;
        }
        else {
          this.section['selected'] = value;
          this.section['selectedLocale'] = [locale];
          super.complete(this.section);
        }
        valueChanged = true;
        break;
      case 'checkbox':
        this.verifyCheckedValue(value, locale);
        // if (this.section['checked'].length === 1) {
        //   this.section['selected'] = value;
        //   super.complete(this.section);
        // } else 
        if (this.section['checked'].length > 0) {
          this.section['selected'] = this.section['checked'][0];
          for (let i = 1; i < this.section['checked'].length; i++) {
            this.section['selected'] = this.section['selected'] + ',' + this.section['checked'][i];
          }
          super.complete(this.section);
        } else {
          super.incomplete(this.section);
        }
        break;
    }
    let conditionalSections = this.sections.filter(s => s['conditionalFieldCode'] == this.section['domainCode']);
    if (Array.isArray(conditionalSections)) {
      conditionalSections.forEach(s => {
        s['_sourceDomainValue'] = this.section['selected'];
        this.events.broadcast('reload_' + s['domainCode']);
      });
    }
    if(this.prescriptionRules && this.prescriptionRules['applicableDosage'] && this.prescriptionRules['applicableDosage'].length && this.section['domainCode'] == "PRESDOSAGETYPE") {
      this.events.broadcast('presc_'+ this.section['domainCode']);
    }
    if (this.section.selected) {
      setTimeout(_ => {
        this.valueChangeComplete.emit(valueChanged)
      })
    }
  }

  verifyCheckedValue(value: any, locale) {
    // console.log('checked value', value)
    if (!this.section['selectedLocale']) this.section['selectedLocale'] = [];
    if (this.section['checked'].includes(value)) {
      this.section['checked'].splice(this.section['checked'].indexOf(value), 1);
      this.section['selectedLocale'].splice(this.section['selectedLocale'].findIndex(locale => locale.text == value), 1);
    } else {
      this.section['checked'].push(value);
      this.section['selectedLocale'].push(locale);
    }
  }

  public domainLevelDefaultValue() {
    if (!this.section['defaultOnFormLoad'])
      return;
    var answers = this.section['domainControls']['answer']
    for (var i = 0; i < answers.length && Array.isArray(answers); i++) {
      if (!answers[i]['default'])
        continue;
      if (!this.section['selected'])
        this.section['selected'] = '';
      if (!this.section['selectedOption'])
        this.section['selectedOption'] = [];

      if (!this.section['multiSelect'] || this.section['multiSelect'] == 'false')
        this.section['selected'] = answers[i]['localName'][0]['text'];

      if (this.section['multiSelect'] || this.section['multiSelect'] == 'true')
        this.section['selected'] = this.section['selected'] + ',' + answers[i]['localName'][0]['text'];

      this.section['selectedOption'].push(answers[i]['localName'][0]['text'])
      // console.log(this.section['selectedOption']);
    }

  }

  public addCustomAns() {
    if(!this.customAns) {
      return;
    }
    let locale = [{ locale: "en", text: this.customAns }]
    this.section['options'].push({ localName: locale});
    this.listenToEvent(this.customAns, locale);
    this.customAns = '';
    this.enableCustomAns = false;
  }

  public loadPrescriptionRules() {
    if(!this.section.selected && this.prescriptionRules['applicableDosage'] && this.prescriptionRules['applicableDosage'].length && this.section['domainCode'] == "PRESDOSAGETYPE") {
      this.section['options'] = this.section['options'].filter(o => this.prescriptionRules['applicableDosage'].some(({code}) => code == o['code']));
      let defaul =  this.prescriptionRules['applicableDosage'].find(e => e['default']);
      // console.log('defaut value', defaul);
      if (defaul) {
        let opt = this.section['options'].find(e => e['code'] == defaul['code'])
        this.listenToEvent(opt['localName'][0]['text'], opt['localName']);
      }
    }
  }

  public changeOnSourceDomainValue() {
    if (this.section['_sourceDomainValue'] && this.section['domainControls'] && Array.isArray(this.section['domainControls']['restrictions'])) {
      const restriction = this.section['domainControls']['restrictions'].find(r => r['value'] == this.section['_sourceDomainValue']);
      if (restriction) {
        this.section['_noOfSelectionsAllowedBySourceDomain'] = restriction['noOfSelectionsAllowed'];
        if (Array.isArray(restriction['defaults'])) {
          this.section['checked'] = [];
          restriction['defaults'].map(d => this.section['checked'].push(d['localName'][0]['text']));
          if (this.section['checked'].length > 0) {
            this.section['selected'] = this.section['checked'].join(',')
          }
        }
      }
    }
  }
}
