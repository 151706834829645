import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {
  @Input() title: string;
  @Input() url: any;
  @Output() close: EventEmitter<any>
  constructor() { 
    this.close = new EventEmitter<any>()
  }

  ngOnInit(): void {
  }

  public closeModel() {
    this.close.emit()
  }

  public startDownload() {
    const win = window.open(this.url, '_blank');
    win.focus();
  }

}
