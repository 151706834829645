import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsViewModalComponent } from './reports-view-modal.component';
import { HrReportPdfformatModule } from '../hr-report-pdfformat/hr-report-pdfformat.module';
import { HrReportClobformatModule } from '../hr-report-clobformat/hr-report-clobformat.module';
import { HrReportFormformatModule } from '../hr-report-formformat/hr-report-formformat.module';
import { HrReportJsonformatModule } from '../hr-report-jsonformat/hr-report-jsonformat.module';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    ReportsViewModalComponent
  ],
  imports: [
    CommonModule, HrReportPdfformatModule, HrReportClobformatModule, HrReportFormformatModule, HrReportJsonformatModule, PipesModule
  ],
  exports:[ReportsViewModalComponent]
})
export class ReportsViewModalModule { }
