import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../pipes/translate.pipe';
import { AppointmentService } from '../services/appointment.service';
import { LinkPatientService } from '../services/link-patient.service';
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/user.service';
import { enterAnimation, switchAnimation, enterAnimationAxisX } from '../animations';
import * as _ from 'lodash';

@Component({
  selector: 'app-recently-consulted',
  templateUrl: './recently-consulted.component.html',
  styleUrls: ['./recently-consulted.component.scss']
})
export class RecentlyConsultedComponent implements OnInit {
  public consultAgain: any;
  public linkedPatients: any;

  @Output() close: EventEmitter<any>;
  @Output() complete: EventEmitter<any>;
  constructor(private user: UserService, private modalService: ModalService, private appointmentService: AppointmentService, private activatedRoute: ActivatedRoute, private translatePipe: TranslatePipe, private linkPatient: LinkPatientService, private router: Router,) {
    this.close = new EventEmitter<any>();
    this.complete = new EventEmitter<any>();
  }

  ngOnInit() {
    this.getRCAppts();
  }

  public getRCAppts() {
    this.user.getUserId().subscribe(personId => {
      if (personId) {
        this.appointmentService.getRecentlyConsultedAppts(personId).subscribe((res) => {
          console.log('consult again data', res);
          this.consultAgain = res;
          this.mapPatientDetails(personId)
        })
      }
    });
  }

  closeModal() {
    this.close.emit();
  }

  public mapPatientDetails(personId) {
    this.linkPatient.getLinkedPatient(personId).subscribe(result => {
      if (result.status === 'SUCCESS') {
        this.linkedPatients = result.data;
        this.consultAgain.forEach(consultData => {
          let patient = this.linkedPatients.find(pat => pat.uhid == consultData.patient_id)
          if (patient) {
            consultData.patientDetails = patient
          }
        });
        console.log('linked patient', this.consultAgain);
      }
    })
  }

  bookAgain(item) {
    this.complete.emit(item)
  }

}
