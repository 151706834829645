<div class="ui fluid input">
  <div class="task-section-title bold">
    <span
      *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span>
    <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
  </div>
  <div class="input-group text-input" [ngClass]="{valid: section['selected']}">
    <div class="label">
      <label for="inputId" class="input-label">
        <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
        <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
      </label>
    </div>
    <div class="value">
      <div *ngIf="section.codify==='Y' && section.domainCode === 'PHNO'" class="mobile-no-country-code-register">
        <int-phone-prefix [locale]="'en'" class="ph-vaild" [(ngModel)]="section['selected']" (keyup)="checkMobile(section['selected'])"></int-phone-prefix>
        <p *ngIf="section['notValid']" class="elrt-ph" padding-left padding-right>
          {{'please_enter_valid_number' | translate}}
        </p>
      </div>
      <ng-container *ngIf="section.domainCode !== 'PHNO'">
        <input class="num-type" value="{{section['selected']}}" (change)="changeVal($event.target)"
          (keyup)="changeVal($event.target)" min="{{minValue}}" max="{{section.maxValue}}" [tabindex]="tabindex"
          step="{{section.stepCounter}}" type="number" [(ngModel)]="section.selected">
      </ng-container>
      <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
        <p>{{'form_field_mandatory' | translate}}</p>
      </div>
      <p *ngIf="section['_validationErr']" class="validation-err-msg">
        <ion-icon name="md-warning"></ion-icon>{{section['_validationErr'] | translate | translate:undefined:undefined:undefined:[{key:"Value", with:(section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text')}]}}
      </p>
    </div>
  </div>
</div>
