<div id="manage-identity-modal-parent" class="manage-identity-wrapper" [ngClass]="{'fullscreen showModal': source=='HOME'}">
  <div class="header-modal border-bottom">
    <div class="title bold">{{'manage_identities'|translate:'LABELS'}}</div>
    <div (click)="closeModal()" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close_black.svg">
    </div>
  </div>
  <div class="modal-content adjust-height">
    <div class="form" *ngIf="!isRecordToLink">
      <form [formGroup]="linkpatientForm" class="linkpatient-form">
        <div class="group" (click)="handlePrecheckNatIdClick()">
          <div [ngClass]="{'mobile-no-country-code':!isExtIdLogin, 'input-container text-input': isExtIdLogin}">
            <ng-container *ngIf="!isExtIdLogin">
              <label>{{'prtl_auth_lbl_mobile' | translate}}</label>
              <div class="phoneinput">
                <app-phone-input formControlName="mobile" [readonly]="isOTPInput" direction="row"></app-phone-input>
              </div>
            </ng-container>
            <ng-container *ngIf="isExtIdLogin">
              <label>{{'prtl_auth_lbl_natid' | translate}}</label>
              <div class="input-group">
                <input formControlName="natid" placeholder="{{'prtl_auth_plh_natid' | translate}}"
                  [attr.type]="'text'" [readonly]="isOTPInput">
              </div>
            </ng-container>
          </div>
        </div>
        <div class="group" *ngIf="isOTPInput">
          <div class="input-container text-input">
            <label>{{'prtl_auth_lbl_lgotp' | translate}}</label>
            <div class="input-group password-direc-change" [ngClass]="{otpinput: true}">
              <ng-container>
                <app-partition-input type="number" [readonly]="false" [length]="otplength" formControlName="otp">
                </app-partition-input>
              </ng-container>
            </div>
            <div class="help-link cursor-pointer">
              <div class="padding-top float-right" *ngIf="resendSeconds>0">
                {{'resendotpin' | translate}} ({{resendSeconds | moment : 'mm:ss':null:true}})
              </div>
              <div class="padding-top float-right" *ngIf="resendSeconds == 0" (click)="verifymobile(true)">
                {{'prtl_auth_otpresend' | translate}}
              </div>
            </div>
          </div>
        </div>
        <button type="submit" (click)="verifymobile()" *ngIf="!isOTPInput" [disabled]="!linkpatientForm['valid']"
          type="button" class="primary-button">
          {{'Continue'|translate}}
        </button>
        <button type="submit" (click)="verifyotp()" *ngIf="isOTPInput && !isExtIdLogin" [disabled]="!linkpatientForm['valid']"
          type="button" class="primary-button">
          {{'Verify_OTP'|translate}}
        </button>
        <button type="submit" (click)="chooseOption('ADD')" *ngIf="isOTPInput && isExtIdLogin" [disabled]="!linkpatientForm['valid']"
          type="button" class="primary-button">
          {{'Add_member'|translate}}
        </button>
      </form>
    </div>
    <div id="content-section" *ngIf="isRecordToLink">
      <ng-container *ngIf="recordsToLink?.length > 0; else noData">
        <div class="fam-tolink identity-group">
          <div class="sub-header" *ngIf="recordsToLink && recordsToLink.length > 0">
            <div class="sub-title">{{ 'lbl#link_pat_msg' | translate }}</div>
          </div>
          <div class="card-block">
            <div class="card-elements">
              <div class="card-element" *ngFor="let patient of recordsToLink">
                <div class="card">
                  <div class="row">
                    <div class="image">
                      <app-person-avatar [url]="patient.imageurl"></app-person-avatar>
                    </div>
                    <div class="name"> {{patient.patientName}} </div>
                    <div class="more">
                      <div class="more-button" appPopover host="choose-pop-option" (click)="openRelationModal(patient)">
                      <img src="./assets/icons/link.svg" />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noData>
        <span class="no-data">
            {{'No_data_found' | translate}}
        </span>
      </ng-template>
    </div>
  </div>
</div>
<div class="popover" id="choose-pop-option">
  <div class="options">
    <ul *ngIf="popoverpatient">
      <li (click)="chooseOption('LINK')" *ngIf="popoverpatient.enableLink">
        <img src="./assets/icons/link.svg" /> {{'btn_link'|translate}}
      </li>
    </ul>
  </div>
</div>

<app-update-relation-modal *ngIf="isRelationModal" [patient]="relationChoosedPatient" (close)="closeUpdateRelation()"></app-update-relation-modal>