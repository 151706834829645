import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-facility',
  templateUrl: './list-facility.component.html',
  styleUrls: ['./list-facility.component.scss']
})
export class ListFacilityComponent {

  @Input() facilityList: Array<any>;
  @Output() action: EventEmitter<any>;
  @Output() next: EventEmitter<any>;
  public selectedFacility: any;

  constructor(){
    this.action = new EventEmitter();
    this.next = new EventEmitter();
  }

  public closeModal(type) {
      this.action.emit(type);
  }

  public completeSelection(item){
    this.selectedFacility = item;
    const query = window.matchMedia("(max-width: 780px)")
    if (!query.matches) { 
      this.next.emit({data: item, state: 'CHOOSE_PATIENT', key: 'selectedFacility'});
    }
  }

  public continue(){
    this.next.emit({data: this.selectedFacility, state: 'CHOOSE_PATIENT', key: 'selectedFacility'});
  }

}

