<div class="chat-container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
  (scrolledUp)="onScroll()" [scrollWindow]="false">
  <div class="chat-group" *ngFor="let items of messages.group">
    <div class="chat-messages" *ngFor="let item of items">
      <div class="chat">
        <div class="avatar-wrapper">
          <app-person-avatar *ngIf="item.sentBy===personId" [url]="participantDetails.patient_photo"
            [name]="item.patientName" [fromPatientTask]="true"></app-person-avatar>
          <app-person-avatar *ngIf="item.sentBy!==personId" [url]="participantDetails.pract_photo"
            [name]="item.practName" [fromPatientTask]="true"></app-person-avatar>
        </div>

        <div class="chat-context">
          <p class="chat-sent-by">{{item.sentBy===personId? item.patientName : item.practName}}<span
              class="chat-sent-on">{{item.dateTime | moment:"hh:mm a"}}</span></p>
          <p class="chat-content">{{item.message}}</p>
          <div class="chat-attachments" *ngIf="item.images?.length">
            <div class="chat-attachment-item" *ngFor="let image of item.images; let index=index;"
              (click)="fileToPreview = image">
              <div class="view-new cursor-pointer" *ngIf="image.status !='D'">
                <img class="type-image" src="./assets/icons/chat/attachment.svg">
                <p>{{image.name}}</p>
              </div>
              <div class="deleted-message font-danger-small" *ngIf="image.status =='D'">{{'Attachment_deleted' |
                translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-nodata *ngIf="fetchedMessages && (!messages || !messages.data || messages.data.length == 0)"></app-nodata>
</div>
<app-file-preview *ngIf="fileToPreview" [file]="fileToPreview" (close)="fileToPreview = null"></app-file-preview>