import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPackagesComponent } from './my-packages.component';
import { PipesModule } from '../pipes/pipes.module';
import { MyPackageDetailsModule } from '../my-package-details/my-package-details.module';



@NgModule({
  declarations: [
    MyPackagesComponent
  ],
  imports: [
    CommonModule, PipesModule, MyPackageDetailsModule
  ],
  exports:[MyPackagesComponent]
})
export class MyPackagesModule { }
