import { Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import  * as _ from 'lodash';

@Component({
  selector: 'app-health-package-preview',
  templateUrl: './health-package-preview.component.html',
  styleUrls: ['./health-package-preview.component.scss']
})
export class HealthPackagePreviewComponent {
  @Output() package = new EventEmitter();
  packs: any;
  currency_code: any;
  constructor(private user:UserService){}

  ngOnInit(): void {
    this.currency_code = this.user.getCurrency();

    this.user.getUpdateInfo('directives').subscribe(data => {
      data.userDefined.find(def => { 
        if(def.directiveGroup == 'HOSPSERV'){
          // this.user.setStorage(AppConstant.HEALTHPKG_LIST, JSON.stringify(def));
          this.packs = _.cloneDeep(def);
          if(this.packs['concepts'].length >3)
          this.packs['concepts'].length = 3;
        }
      })
    });
  }

  openPackages(selectedPackage?) {
    this.package.emit(selectedPackage)
  }

  public getChargeByFacility(pack){
    let charge: any;
    if(pack.facilities.length){
      charge = pack.facilities[0].controls.charge
    }
    return charge;
  }
  public getDiscountChargeByFacility(pack){
    let discountCharge:any;

    if(pack.facilities.length){
      discountCharge = pack.facilities[0].controls.discountCharge
    }
    return discountCharge;
  }
}
