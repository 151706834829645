import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-express-clinic-home',
  templateUrl: './express-clinic-home.component.html',
  styleUrls: ['./express-clinic-home.component.scss']
})
export class ExpressClinicHomeComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;

  public listOfSkillSets: Array<object>;
  public selectedSkillSet: object;

  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.listOfSkillSets = new Array<object>();
    this.selectedSkillSet = {};
  }

  ngOnInit() {
    this.getListSkillSetMasters();
  }

  public getListSkillSetMasters() {
    this.user.getListSkillSetMasters().subscribe(data => {
      this.listOfSkillSets = data['data'];
      this.getSkills();
      // this.listOfSkillSets.forEach( skillSet => {
      //   skillSet['selected'] = false
      //   if(skillSet['applicableSkillSet'].length) {
      //     skillSet['applicableSkillSet'].forEach(applicableSkillSet => {
      //       applicableSkillSet['selected'] = false;
      //     });
      //   }
      // })
    });
  }

  public getSkills(){
    this.listOfSkillSets = this.listOfSkillSets.filter(s => s['applicableSkillSet'].length > 0).map(s => {
      s['selected'] = '';
      if (s['mandatory'] && s['single_or_muti_select'] != 'M') {
        s['selected'] = s['applicableSkillSet'][0]['code'];
      }
      s['applicableSkillSet'] = s['applicableSkillSet'].map((m, i) => {
        let defaultSelected = m.default ? true : false;
        if (s['single_or_muti_select'] != 'M' && m.default) { 
          s['selected'] = m['code'];
        }
        return {
          ...m,
          selected: defaultSelected
        }
      });
      return s;
    });
  }

  public findClinic() {
    this.listOfSkillSets.forEach( skillSet => {
      if(skillSet['single_or_muti_select'] != 'M' && skillSet['applicableSkillSet'].length && skillSet['selected']){
        this.selectedSkillSet[skillSet['code']] = new Array<string>();
        this.selectedSkillSet[skillSet['code']].push(skillSet['selected']);
      } else if(skillSet['single_or_muti_select'] == 'M' && skillSet['applicableSkillSet'].length) {
        this.selectedSkillSet[skillSet['code']] = new Array<string>();
        skillSet['applicableSkillSet'].forEach( applSkillSet => {
          if(applSkillSet['selected'] == true)
            this.selectedSkillSet[skillSet['code']].push(applSkillSet['code'])
        })
      }
    });
    this.completeSelection();
    console.log(this.listOfSkillSets);
    console.log('selected skillset->',this.selectedSkillSet);
  }

  public completeSelection() {
    this.complete.emit({ home: this.selectedSkillSet });
  }
}
