import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user.service';

import * as moment from 'moment';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import { Subject } from "rxjs";
import { HealthRecordsService } from '../services/health-records.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LinkPatientService } from '../services/link-patient.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-health-records',
  templateUrl: './health-records.component.html',
  styleUrls: ['./health-records.component.scss'],
  providers: [PartitionerDetailsPipe, TranslatePipe, FacilityDetailsPipe]
})
export class HealthRecordsComponent implements OnInit {

  @Input() info: any;
  @Input() isCommonView: boolean;
  public selectedPatient: any;
  public selectedVisit: any;
  public selectedReport: any;
  public isClobReport: boolean;
  public isFormReport: boolean;
  public isJsonReport: boolean;
  public isDocumentUpload: boolean;
  public isFeedBackOpen: boolean;
  public feedBackAppt: any;
  public isPdfReport: boolean;
  public pdfReport: any;
  public imageReport: any;
  public isImageReport: boolean;
  public docReport: any;
  public docMime: string;
  public isDocReport: boolean;
  public selectedLanguage: string;
  public context: any = {};
  public isCtx: boolean;
  public hideOnCtx: boolean;
  public qrCodeUrl: any = {};
  public expandQrCode: boolean = false
  public qrCode: string;
  refresh: Subject<boolean> = new Subject<boolean>();
  public patientId: string;
  public reportId: any;
  public resetChildForm() {
    this.refresh.next(true);
  }

  public uploadType: string = '';
  public viewTabs = TABS;
  public selectedTab = TABS[0]['code'];
  isMobileView = false;
  public clinicalHistory: any;
  public visitId: any;
  public reportDetails: any;
  public patientMetaData: any;
  public reportToken: any;
  constructor(private user: UserService, private practitionerPipe: PartitionerDetailsPipe, private translatePipe: TranslatePipe, private facilityPipe: FacilityDetailsPipe, private hrService: HealthRecordsService, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, private linkPatient: LinkPatientService, private route: ActivatedRoute) {
    this.isDocumentUpload = false;
    this.selectedLanguage = this.user.currentLanguage;
    this.qrCodeUrl = { type: 'url', location: location.href + '#details', src: null, thumbnail: location.href };
   }

  ngOnInit(): void {
    this.isMobileView = document.body.clientWidth <=780;
    if (this.isCommonView) {
      this.hideOnCtx = true;
      this.selectedPatient = this.info['patient'];
      this.chooseReport(this.info);
    }
    this.route.paramMap.subscribe(params => {
      this.patientId = params.get('id');
      if(params.get('reportId'))
      this.reportId = params.get('reportId').replace(/__/g, "#");
      console.info('patient Id', this.patientId, this.reportId);
      if(this.patientId) 
        this.getPatientDetails(this.patientId)
      if(params.get('action') == 'healthrecords')
        this.selectedTab = 'VISITS';
    });
    this.checkUrl();
  }

  public getPatientDetails(id) {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            console.info('getLinkedPatient', result);
            let info = result.data.find(a => a.uhid == id);
            console.info('getUserId', info);
            this.choosePatient(info);
            this.getVisitDetails(info);
            this.selectedTab = 'VISITS';
          }
        })
      }
    })
  }

  private getVisitDetails(info) {
    if (info) {
      this.user.getVisitReport(info['uhid'], null, null).subscribe(data => {
        this.clinicalHistory = data.reports;
        this.patientMetaData = data.patient
        this.reportToken = data.accessToken;
        if (this.clinicalHistory.length > 0)
          this.checkReport(this.clinicalHistory);
      })
    }
  }

  checkReport(reports) {
    console.info('reports', reports)
    this.reportDetails = reports.find(a => a.reportId == this.reportId)
    console.info('reportDetails', this.reportDetails);
    this.visitId = this.reportDetails['encounterId'];
    console.info('checkReport', this.visitId)
  }

  public choosePatient(patient) {
    console.log('event', patient);
    this.selectedPatient = patient;
    this.selectedPatient['facilityId'] = patient['facilityid'] || patient['facilityId'];
    this.selectedVisit = null;
  }
  public chooseVisit(visit) {
    console.info('chooseVisit', visit);
    this.selectedVisit = visit;
  }
  public chooseReport(event) {
    let selectedReport = event.report;
    let type = event.type;
    let token = event.token;
    let patientMetaData = event.patientMetaData;
    this.getReportData(selectedReport, token, type, patientMetaData)
  }

  private openReport(selectedReport, data, patientMetaData) {
    this.selectedReport = { report: selectedReport, data: data, patientMetaData: patientMetaData };
    if (selectedReport['format'] == 'CLOBFORMAT') {
      this.openClobReport();
    }
    if (selectedReport['format'] == 'FORMFORMAT') {
      this.openFormReport();
    }
    if (selectedReport['format'] == 'JSONFORMAT') {
      this.selectedReport.data = JSON.parse(this.selectedReport.data); 
      this.openJsonReport();
    }
    if (selectedReport['format'] == 'NULLFORMAT') {
      this.selectedReport.report = '<div align="center">{{"msg_try_again"|translate:"LABELS"}}</div>'
      this.openClobReport();
    }
  }

  private openClobReport() {
    this.isClobReport = true;
  }
  public closeClobReport() {
    this.isClobReport = false;
    this.selectedReport = null;
  }
  private openFormReport() {
    this.isFormReport = true;
  }
  public closeFormReport() {
    this.isFormReport = false;
    this.selectedReport = null;
  }
  private openJsonReport() {
    this.isJsonReport = true;
  }
  public closeJsonReport() {
    this.isJsonReport = false;
    this.selectedReport = null;
  }

  private getReportData(selectedReport, token, type, patientMetaData) {
    console.log('patientMetaData', patientMetaData)
    if (type != 'DP' && type!='RP' && type!='VP') {
      this.user.getDownloadContent(selectedReport.url, token, this.isCommonView).subscribe(data => {
        if (selectedReport['format'] && selectedReport['format'] == 'FORMFORMAT') {
          data = JSON.parse(data);
        }
        switch (type) {
          case 'V':
            this.openReport(selectedReport, data, patientMetaData);
            break;
          case 'D':
            this.formDownloadData(selectedReport, data);
            break;
          case 'S':
            break;
          case 'DP':

            break;
        }
        if(this.isCommonView) this.hideOnCtx = true;
      })
    } else if(type=='DP') {
      this.downloadPersonalReport(selectedReport);
      if(this.isCommonView) this.hideOnCtx = true;
    } else if (type == 'RP') {
      this.removePersonalReport(selectedReport);
      if(this.isCommonView) this.hideOnCtx = true;
    } else if(type == 'VP'){
      this.viewPersonalReport(selectedReport);
      if(this.isCommonView) this.hideOnCtx = true;
    }
    
  }

  public initDownload(event) {
    let report = null;
    let data= null
    if (event.report['format'] == 'CLOBFORMAT' || event.report['format'] == 'FORMFORMAT') {
      report = event.report;
      data = event.data;
    }
    if (event.report['format'] == 'JSONFORMAT') {
      report = event.report;
      data = JSON.stringify(event.data);
    }
    if (report && data) {
      this.formDownloadData(report, data);
    }
  }

  private formDownloadData(selectedReport, downloadcontent) {
    console.log("downloadcontent",downloadcontent)
    let practitioner = this.practitionerPipe.transform(selectedReport['practitionerId'], selectedReport['facilityId'], 'resourceName');
    let practitionerName = this.translatePipe.transform(practitioner, 'LOCALE', 'name');
    let facility = this.facilityPipe.transform(selectedReport['facilityId'], 'facilityName');
    let facilityName = this.translatePipe.transform(facility, 'LOCALE', 'name')
    if (selectedReport['format'] == 'FORMFORMAT') {
      // downloadcontent = downloadcontent['topics'];
    }
    let data = {
      content: downloadcontent,
      facilityName: facilityName,
      facilityId:selectedReport['facilityId'],
      reportId:selectedReport['reportId'],
      patientName: this.selectedPatient['personname'],
      uhid: this.selectedPatient['uhid'],
      personId: this.selectedPatient['personId'],
      gender: this.selectedPatient['gender'] == 'M' ? 'Male' : this.selectedPatient.gender == 'F' ? 'Female' : '',
      repDateTime: this.user.getMoment(selectedReport['reportDateTime']).format("DD-MM-YYYY hh:mm a"),
      age: this.user.getMoment().diff(this.user.getMoment(this.selectedPatient['dob']), 'years'),
      reportedBy: selectedReport['authorizedBy'],
      isShare: false,
      filename: "random.pdf",
      reportType: selectedReport['format'],
      recordType: selectedReport['reportType'],
      encounterId: selectedReport['encounterId'],
      specialtyCode: selectedReport['specialtyCode'],
      entityId: selectedReport['entity'],
      doctorName: practitionerName,
      reportName: selectedReport['format'] == 'JSONFORMAT' ? selectedReport['eventGroupDesc'] : selectedReport['eventDesc']
    }
    this.getPDF(data);
  }
  private getPDF(data) {
    this.user.convertToPDF(data).subscribe(data => {
      let link = data['downloadLink'];
      this.user.openLocation(link, '_blank')
    })
  }
  protected downloadPersonalReport(data) {
    this.user.openLocation(data.url, '_blank')
  }
  public clearVisit() {
    this.selectedVisit = null;
  }

  public openDocsUpload(event) {
    this.isDocumentUpload = true;
    if(event){
      console.log("isDocumentUpload", event.type);
      this.uploadType = event.type;
    }
  }

  public closeDocsUpload() {
    this.isDocumentUpload = false;
    this.resetChildForm();
  }

  public removePersonalReport(data) {
    this.user.removePersonalReport(data.docId).subscribe(_ => {
      this.resetChildForm();
    })
  }

  public getFeedBackForAppt(event) {
    this.feedBackAppt = event;
    this.isFeedBackOpen = true;
  }
  public closeFeedback(event) {
    this.feedBackAppt = null;
    this.isFeedBackOpen = false;
  }

  private viewPersonalReport(data) {
    if (!data.header || !data.header['mimeType']) {
      this.pdfReport = data;
      this.isPdfReport = true;
    } else {
      this.handleReportFormat(data);
    }
  }
  public closePdfReport() {
    this.pdfReport = null;
    this.isPdfReport = false;
  }
  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      let ctx = params['ctx'];
      if (ctx) {
        this.isCtx = true;
        let loginContext = this.user.getLoginContext();
        this.isCommonView = loginContext['enableOnlyCommonView'];
        this.handleContext(loginContext);
      }
    })
  }
  private handleContext(context) {
    console.log('context',context);
    if (context) {
      this.context = context;
      this.user.getUserId().subscribe(data => {
        if (data) {
          this.linkPatient.getLinkedPatient(data).subscribe(result => {
            if (result.status === 'SUCCESS') {
              let linkedPatients = result.data;
              let patient = linkedPatients.find(pat => pat.uhid == context.patientId);
              if (patient) {
                this.choosePatient(patient)
              }
            }
          })
        }
      })
    }
  }
  public toggleQrCode() {
    if (this.qrCode)
     return this.expandQrCode = !this.expandQrCode;
    
    this.hrService.generateQRCodeForReport(this.info['_data']).subscribe(data => {
      console.log('testttt', data);
      this.qrCode = data;
      this.expandQrCode = !this.expandQrCode;
    })
  }

  private handleReportFormat(report) {
    switch (report.header.mimeType) {
      case 'application/pdf':
        this.pdfReport = report;
        this.isPdfReport = true;
        break;
      case 'image/jpeg':
      case 'image/gif':
        this.imageReport = report;
        this.isImageReport = true;
        break;
      default:
        this.docReport = report;
        this.isDocReport = true
        break;
    }
  }

  public closeImagePreview() {
    this.imageReport = null;
    this.isImageReport = false;
  }

  public closeDocPreview() {
    this.docReport = null;
    this.isDocReport = false;
  }
}

const TABS = [{
  code:'SUMMARY',
  label:'tab_summary',
  flag:'FT_APP_HR_SUMMARY'
}, {
  code:'VISITS',
  label:'tab_visits',
  flag:'FT_APP_HR_VW_VST_HIS'
}, {
  code:'REPORTS',
  label:'tab_uploads',
  flag:'FT_APP_HR_REPORT'
}, {
  code:'PRES',
  label:'tab_prescription',
  flag:'FT_APP_HR_PRESCRIPTION'
}]