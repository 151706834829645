import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { HealthPackageFlowComponent } from '../health-package-flow/health-package-flow.component';
import { HealthPackageDetailsModule } from '../health-package-details/health-package-details.module';
import { HealthPackageConfirmRequestModule } from '../health-package-confirm-request/health-package-confirm-request.module';
import { FilterModule } from '../filter/filter.module';
import { FormsModule } from '@angular/forms';
import { ListFacilityModule } from '../list-facility/list-facility.module';
import { HealthPackageModule } from '../health-package/health-package.module';
import { PatientListModule } from '../patient-list/patient-list.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { ChooseSlotModule } from '../choose-slot/choose-slot.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
@NgModule({
  declarations: [HealthPackageFlowComponent],
  imports: [
    CommonModule,
    PersonAvatarModule, 
    PipesModule,
    ManageIdentitiesModule,
    HealthPackageDetailsModule,
    HealthPackageConfirmRequestModule,
    FilterModule,
    FormsModule,
    ListFacilityModule,
    HealthPackageModule,
    PatientListModule,
    RegisterPatientModule,
    ChooseSlotModule,
    RegisterNewPatientModule
  ],
  exports: [
    HealthPackageFlowComponent
  ],
  providers: [TitleCasePipe]
})
export class HealthPackageFlowModule { }
