import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-prebootstrap-loader',
  templateUrl: './prebootstrap-loader.component.html',
  styleUrls: ['./prebootstrap-loader.component.scss']
})
export class PrebootstrapLoaderComponent {

  constructor(public user: UserService) { }

}
