<div id="notification-modal-parent">
  <div class="header-modal border-bottom">
    <div class="title bold">{{'Notification_center'|translate:'LABELS'}}</div>
    <div (click)="closeModal('notification-modal')" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close_black.svg">
    </div>
  </div>
  <div class="search-header border-bottom">
    <div class="filter">
      <div class="filter-content">
        <app-filter [filterInput]="filters" (complete)="filterPackages($event)" [isHideMobileView]="true"></app-filter>
      </div>

      <div class="dp-down cursor-pointer" *ngIf="false">
        <div class="dp-select" (click)="openCloseFilter()">
          <span class="font-primary-default">{{ 'Filter(s):' | translate }}
            <span [ngClass]="{'circle-css': selectedFilter != 'All' }">{{ selectedFilter |translate:'LABELS'}}</span>
          </span>
        </div>
        <div class="dp-option" *ngIf="isFilterOpen">
          <div class="input-group border-bottom" *ngFor="let alert of filters" >
            <input type="checkbox" id="{{alert.code}}" (click)="chooseFilter($event, alert)" [checked]="alert.checked" />
            <label for="{{alert.code}}">{{alert.name|translate:'LABELS'}}</label>
          </div>
        </div>
      </div>

    </div>
    <div class="dismiss" *ngIf="messages.alerts.length > 0 || messages.events.length > 0 " (click)="dimissAll()">
      {{'Dismiss_all'|translate:'LABELS'}} <img class="type-image" src="./assets/icons/close_black.svg">
    </div>
  </div>


    <div class="modal-content" *ngIf="(messages.alerts && messages.alerts.length > 0) || (messages.events && messages.events.length > 0)">
      <ng-container *ngIf="messages.alerts.length > 0 ">
        <div class="gesture__zone" *ngFor="let alert of messages.alerts |  myAlerts : filterdata ; let id=index " id="gesture-{{id}}"
          (swipeleft)="swiper($event, id)" (swiperight)="swiper($event, id)">
          <div id="hide-{{id}}" class="hide" (click)="deleteAlert(alert,id,'alert')">
            <ng-container *ngTemplateOutlet="trashTemplate"></ng-container>
          </div>
          <div class="visit-cards content" id="vcard-{{id}}"  (click)="updateAlertStatus(alert,id,'alert');navigateToDetail(alert)">
            <div class="card-layout" *ngIf="alert">
              <div class="card cursor-pointer" [ngClass]="{ 'border-left': alert['category'].toUpperCase() == 'UPDATES'}" >
                <div class="head-row" [ngClass]="{ 'border-bottom' : alert['category'].toUpperCase() == 'REMINDER' }">
                  <div class="visit-type">
                    <img class="inactive-icon type-image"  *ngIf="alert?.notificationContentSrc?.transactionType=='FAILED' && notificationIcon['ERROR']; else OTHERCATICON"  [src]="notificationIcon['ERROR']"/>
                    <ng-template #OTHERCATICON>
                      <img class="inactive-icon type-image" *ngIf=" notificationIcon[alert['category'].toUpperCase()]" [src]="notificationIcon[alert['category'].toUpperCase()]" />
                    </ng-template>
                    <span *ngIf="alert.title">{{ alert['title'] }}</span>
                  </div>
                  <div class="visit-time font-light-xsmall">
                    <!-- {{ alert['startDate'] | dateAgo }} -->
                    <span  *ngIf="alert['startDate']">{{alert.startDate | moment : 'fromNow' : 'YYYY-MM-DD HH:mm' }}</span>
                    <img class="type-image hide-close" (click)="deleteAlert(alert,id,'alert')" src="./assets/icons/close_black.svg">
                  </div>
                </div>
                <div class="font-light-small vcontent" *ngIf="alert['body']">
                  <p class="">{{ alert['body'] }}</p>
                </div>
                <div class="font-light-small vcontent" *ngIf="alert['bannerImageUrl']">
                  <div class="padding">
                    <img class="type-image wid-100" [src]="alert.bannerImageUrl">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="messages.events.length > 0 ">
        <div class="gesture__zone" *ngFor="let alert of messages.events | myAlerts : filterdata; let id=index " id="gesture-{{id}}"
          (swipeleft)="swiper($event, id)" (swiperight)="swiper($event, id)">
          <div id="hide-{{id}}" class="hide" (click)="deleteAlert(alert,id,'event')">
            <ng-container *ngTemplateOutlet="trashTemplate"></ng-container>
          </div>
          <div class="visit-cards content" id="vcard-{{id}}">
            <div class="card-layout" *ngIf="alert">
              <div class="card cursor-pointer" [ngClass]="{ 'border-left': alert['type'] == 'UPDATE'}" >
                <ng-container *ngIf="alert.enableupcomingAppointments"  (click)="updateAlertStatus(alert,id,'event')" >
                  <app-upcoming-appt-preview [selectedAppointment]="alert.upcomingAppointments" [viewType]="'MYALERT'" [readStatus]="alert.readStatus" [alertInfo]="alert"
                  (openAppointment)="openAppointment(alert)" (deleteAlert)="deleteAlert(alert, id, 'event')">
                 </app-upcoming-appt-preview>
                </ng-container>

                <ng-container *ngIf="!alert.enableupcomingAppointments && false">

                  <div class="flx" *ngIf="alert.notificationContent.middleTitle || alert.notificationContent.middleDescrription">
                    <div class="image" *ngIf="alert.notificationContent.leftImageUrl">
                      <img
                      [src]="alert.notificationContent.leftImageUrl"/>
                    </div>
                    <div class="practitoner-content">
                      <div class="title bold" *ngIf="alert.notificationContent.middleTitle ">
                        {{alert.notificationContent.middleTitle}}
                      </div>
                      <div class="font-light-xsmall" *ngIf="alert.notificationContent.middleDescrription">
                        {{alert.notificationContent.middleDescrription}}
                      </div>
                    </div>
                  </div>
                  <div class="visit-footer" >
                    <div class="actions ">
                      <div class="option font-black-small" *ngIf="alert.notificationContent.startDate">
                        <img class="inactive-icon type-image" src="assets/icons/calendar_notify.svg" />
                        <span>{{alert.notificationContent.startDate | moment : 'DD MMM YY HH:mm a' : 'YYYY-MM-DD HH:mm' }}</span>
                      </div>
                      <div class="option font-black-small" *ngIf="alert.notificationContent.appointmentName">
                        <img class="inactive-icon type-image" src="assets/icons/user.svg" />
                        <span>{{alert.notificationContent.appointmentName}}</span>
                      </div>
                      <div class="option font-black-small" *ngIf="alert.notificationContent.paymentStatus">
                        <img class="inactive-icon type-image" src="assets/icons/calendar/cash.svg" />
                        <span>{{alert.notificationContent.paymentStatus}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="font-light-small vcontent" *ngIf="alert.notificationContent.bannerImageUrl">
                    <div class="padding">
                      <img class="type-image wid-100" [src]="alert.notificationContent.bannerImageUrl">
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
</div>

<ng-template #trashTemplate>
  <div class="trash">
    <img class="type-image" src="assets/icons/trash_white.svg">
  </div>
</ng-template>

<app-custom-modal-container id="notify-calendar-appointment-details-modal"  (complete)="closeApptDetail()">
  <app-upcoming-appointment-detail *ngIf="openApptDtl" [viewAppointment]="selectedAppointment" 
    (reschedule)="openApptView($event)" (expressReschedule)="openExpressView($event)" [modalId]="'notify-calendar-appointment-details-modal'"  
    (uploadQuestionnarie)="openuploadQuestionnarie($event)"(uploadDocs)="openuploadDocs($event)"
    >
  </app-upcoming-appointment-detail>
</app-custom-modal-container>

<app-custom-modal-container id="notify-manage-identity-modal" (complete)="closeManageIdentities()">
  <app-manage-identities  *ngIf="isManageIdentities" [modalId]="'notify-manage-identity-modal'" ></app-manage-identities>
</app-custom-modal-container>