import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-nodata",
  templateUrl: "./nodata.component.html",
  styleUrls: ["./nodata.component.scss"],
})
export class NodataComponent {
  @Input() source: string;
  @Input() pointsView: boolean;
  @Input() availablePoints: any;
  public minimumExchangeEligibleNuhdeekPoints: any;
  public exchangeCouponsUrl: any;
  constructor(private user: UserService) {}

  ngOnInit() {
    this.minimumExchangeEligibleNuhdeekPoints = this.user.canViewOption("MINIMUMEXCHANGEELIGIBLENUHDEEKPOINTS");
    this.exchangeCouponsUrl = this.user.canViewOption("EXCHANGECOUPONSLINK");
  }

  public exchangeCoupons(url) {
    console.info("exchangeCoupons");
    this.user.openLocation(url);
  }
}
