import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitPrescriptionComponent } from './hr-visit-prescription.component';
import { PipesModule } from '../pipes/pipes.module';
import { NodataModule } from '../nodata/nodata.module';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../directive/directive.module';
@NgModule({
  declarations: [HrVisitPrescriptionComponent],
  imports: [
    CommonModule,
    PipesModule,
    NodataModule,
    FormsModule, DirectiveModule
  ],
  exports: [
    HrVisitPrescriptionComponent
  ]
})
export class HrVisitPrescriptionModule { }
