import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDescriptionComponent } from './company-description.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [CompanyDescriptionComponent],
  imports: [
    CommonModule, NgbCarouselModule, PipesModule
  ],
  exports:[CompanyDescriptionComponent]
})
export class CompanyDescriptionModule { }
