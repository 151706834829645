import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationDtlComponent } from './notification-dtl.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { FormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { DirectiveModule } from '../directive/directive.module';
import { UpcomingApptPreviewModule } from '../upcoming-appt-preview/upcoming-appointment-preview.module';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { FilterModule } from '../filter/filter.module';

@NgModule({
  declarations: [NotificationDtlComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule,
    PractitionerAvatarModule, FormsModule, HammerModule, DirectiveModule,
    UpcomingApptPreviewModule, UpcomingAppointmentDetailModule, CustomModalModule, ManageIdentitiesModule, FilterModule
  ],
  exports: [
    NotificationDtlComponent
  ]
})
export class notificationDtlModule { }