import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { AppointmentOverlapModalComponent } from './appointment-overlap-modal.component';



@NgModule({
  declarations: [
    AppointmentOverlapModalComponent
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, PipesModule,
  ],
  exports: [AppointmentOverlapModalComponent]
})
export class AppointmentOverlapModalModule { }
