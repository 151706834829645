<div id="link-records">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img class="type-image" src="assets/icons/link_blue.svg" />
          <!-- <img src="assets/icons/link-color.svg" /> -->
        </div>
        <div class="font-default-title title">{{'sub_records_link' | translate}}</div>
      </div>
      <div class="font-primary-default actions captilize" *ngIf="false">
        <span class="new font-primary-title bold cursor-pointer"
          (click)="openRegisterPatient('register-patient')">+</span>
      </div>
    </div>
    <div class="content" *ngIf="recordsToLink.length==0">
      <div class="no-records">
        <div>{{'msg_no_linked_patients' | translate}}</div>
        <button class="primary-button" (click)="openRegisterPatient('register-patient')">
          <img class="type-image" src="assets/icons/user-white-add.svg" alt="">
          {{'btn_online_register' | translate}}
        </button>
      </div>
    </div>
    <div class="list" *ngIf="recordsToLink.length>0" [ngStyle]="{'max-height.em': maxLinkedPatientToDisplay ? ((4 * maxLinkedPatientToDisplay) - 1) : 16  }">
      <app-list-item *ngFor="let item of recordsToLink" className="no-image reverse-footer" (clicked)="selectRecord(item.patientUhid)">
        <span class="title">{{item.patientName}}</span>
        <span class="primary-content" *ngIf="false">
          <span>{{item.facilityId | facilityDetails : 'facilityName' | translate : 'LOCALE': 'name'}}</span>
        </span>
        <span class="secondary-content" *ngIf="false">
          <span>{{item.patientUhid}}</span>
        </span>
        <div class="more list-action" *ngIf="false">
          <div class="more-button" appPopover host="choose-option-home"
            (click)="openPopover(item, false, false, true, true)">
            <img src="./assets/icons/more.svg" />
          </div>
        </div>
        <!-- <span class="list-action">
          <button class="secondary-button btn-icon" [disabled]="!relationArray[item.patientUhid]"
            (click)="linkPatient(item); $event.stopPropagation();">
            <img src="assets/icons/link.svg" />
        </span> -->
        <div class="select-relation" *ngIf="selectedRecord == item.patientUhid ">
          <div class="relation-dropdown">
            <div class="font-light-small flex">
              <p><img class="type-image" src="assets/icons/agreement.svg"> 
              <span class="">{{'mng_label_relationship'|translate:'LABELS'}} </span>
            </p>
              <button class="secondary-button btn-icon" [disabled]="!relationArray[item.patientUhid]"
              (click)="linkPatient(item); $event.stopPropagation();">
              <!-- <img src="assets/icons/link.svg" /> -->
              <img class="type-image" src="assets/icons/link_white.svg" />
            </button>
            </div>
            <div class="relation-content" (click)="openDp()" >
              {{relationArray[item.patientUhid]?(relationArray[item.patientUhid]['value']|translate:'LOCALE':'name'):("msg_relatedalert"|translate:'LABELS')}}
              <span class="align-right">
                <img class="image type-image" src="assets/icons/calendar/drop.svg">
              </span>
            </div>
            <div class="dropdown" *ngIf="showRelation">
              <div class="actions">
                <div class="border-bottom title-black cursor-pointer" *ngFor='let type of relations'
                  (click)="mapRelation(item.patientUhid, type);$event.stopPropagation();">
                  <label class="container">
                    {{ type.value|translate:'LOCALE':'name'}}
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-list-item>
    </div>
  </div>
</div>
<div class="popover" id="choose-option-home">
  <div class="options">
    <ul *ngIf="popoverpatient">
      <li (click)="chooseOption('PHOTO')" *ngIf="popoverpatient.enablePhoto">
        <label>
          <input type="file" name="patient-photo" id="patient-photo" size="chars"
            accept="image/png, image/gif, image/jpeg" (change)="uploadPatientPhoto($event.target.files)">
          <img src="./assets/icons/dslr-camera.svg" /> {{'btn_change_picture'|translate}}
        </label>
      </li>
      <li (click)="chooseOption('UNLINK')" *ngIf="popoverpatient.enableUnlink">
        <img src="./assets/icons/unlink.svg" /> {{'btn_remove_link'|translate}}
      </li>
      <li (click)="chooseOption('UNLINKMYACCESS')" *ngIf="popoverpatient.unlinkMyAccess">
        <img src="./assets/icons/unlink.svg" /> {{'btn_remove_link'|translate}}
      </li>
      <li (click)="chooseOption('LINK')" *ngIf="popoverpatient.enableLink">
        <img src="./assets/icons/link.svg" /> {{'btn_link'|translate}}
      </li>
    </ul>
  </div>
</div>
<app-custom-modal-container id="register-patient" (complete)="closeRegisterPatient('register-patient')">
  <app-register-new-patient *ngIf="isRegisterPatient" (close)="closeModal('register-patient')">
  </app-register-new-patient>
</app-custom-modal-container>
<app-link-other-mobile *ngIf="findOtherPatient" (initLinkPatients)="initLinkPatients($event)"
  (initAddPatients)="initAddPatients($event)" (close)="closeModal('find-record')" [linkedPatients]="linkedPatients"
  [prefillNationalId]="prefillNationalId" source="HOME">
</app-link-other-mobile>
