import { Component, OnInit, EventEmitter, Output , Input} from '@angular/core';
import { UserService } from '../services/user.service';
import { AppointmentService } from '../services/appointment.service';
import { PaymentService } from '../services/payment.service';
import { environment } from '@env/environment';
import * as AppConstant from '../app.string';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import { GoogleTagsService } from '../services/google-tags.service';
import * as moment from 'moment';
@Component({
  selector: 'app-health-package-confirm-request',
  templateUrl: './health-package-confirm-request.component.html',
  styleUrls: ['./health-package-confirm-request.component.scss'],
  providers: [TranslatePipe, FacilityDetailsPipe]
})
export class HealthPackageConfirmRequestComponent implements OnInit {

  @Input() pkgPrefer: any;
  @Input() requestInfo: any;
  @Input() reqConfirm:any;
  @Output() close: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Output() switchAction: EventEmitter<any>;

  public currency_code: any;
  public concept: any;
  public appConfig: any;
  public detail: any;
  public isReqRecieved: boolean;
  public openPayment: boolean;
  public payType: string;
  public paymentGatewayUrl: string;
  private facilityId;
  public chosenFacility: any;
  public paymentStatus: string;
  public paymentData: any;

  public selectedDirective: any;
  public selectedPackage;
  public selectedFacilityId;
  public selectedPatient;
  public canPay: boolean = false;
  public patientPayable: any = 0;
  public isPaymentGatewayEnabled: boolean;
  public isPayableServices: any;
  public isEditApplicable: boolean;

  constructor(private user: UserService, private apptService: AppointmentService, private paymentService:PaymentService, private translatePipe: TranslatePipe,private facilityPipe: FacilityDetailsPipe, private gtmService:GoogleTagsService) {
    this.close = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.switchAction = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.selectedDirective = this.requestInfo.selectedDirective;
    this.selectedPackage = this.requestInfo.selectedPkg;
    this.selectedFacilityId = this.requestInfo.selectedFacility.facilityId
    this.chosenFacility = this.requestInfo.selectedFacility;
    this.selectedPatient = this.requestInfo.selectedPatient;
    console.log(" this.reqConfirm", this.reqConfirm)
    this.isReqRecieved = this.reqConfirm? this.reqConfirm['confirmReq'] : false;
    if(!this.isReqRecieved){
      this.isEditApplicable = true
    }
    this.paymentStatus = this.reqConfirm? this.reqConfirm['status'] : null
    this.paymentData = this.reqConfirm? this.reqConfirm['data'] : null
    this.user.getConfigurationInfo.subscribe(data => {
      this.appConfig = data;
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
      this.isPaymentGatewayEnabled = data.features.paymentGateway.enabled
      this.isPayableServices = data.features.payableServices['services']
      this.getPageConfiguration();
    })
    this.currency_code = this.user.getCurrency();
    // console.log("pkgPrefer", this.pkgPrefer['facility'])
    this.concept = this.requestInfo.selectedPkg //this.pkgPrefer['pkgConcept']
    for(let l in this.concept.detail){
      if(this.concept.detail[l].locale==this.user.currentLanguage){
          this.detail=this.concept.detail[l];
          console.log("DETAIL", this.detail);
      }
    }
    // this.concept.facilities.find( item => {
    //   if(item.facilityId == this.requestInfo.selectedFacility['facilityId']){
    //     this.chosenFacility = item.controls;
    //   }
    //   console.log("ccccc", this.chosenFacility)
    // })
  }

  private getPageConfiguration() {
    this.canPay = false;
    let patientPayable = this.chosenFacility.controls.discountCharge ? this.chosenFacility.controls.discountCharge : this.chosenFacility.controls.charge
    if (this.isPaymentGatewayEnabled && this.isPayableServices && this.chosenFacility.controls.payableOnline && patientPayable > 0) {
      this.canPay = true;
    }
    if (this.canPay) {
      this.patientPayable = patientPayable;
    }
  }

  public closeConfirmModal(){
    this.close.emit('CLOSE');
  }

  getSubTitle(names){
    for (let l in names){
      if(names[l].locale==this.user.currentLanguage){
        return names[l].description;
      }
      else
      {
        return names[l].description;
      }
    }
  }

  getTitle(names){
    if (names.length == 0) {
        return '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

  checkFunction(type,name){
    this.request(type,name);
  }

  request(type, name){

    let notify = this.chosenFacility.controls.notification
    if (!notify) {
      let directiveFacility = this.selectedDirective.facilities.find(fac => fac.facilityId == this.chosenFacility.facilityId)
      if (!directiveFacility)
        directiveFacility = this.selectedDirective.facilities.find(fac => fac.facilityId == '*ALL')
      if (directiveFacility)
        notify = directiveFacility.controls.notification;
    }
    this.facilityId = this.requestInfo.selectedFacility['facilityId'] //this.concept['selectedFacilityId']
    var conceptName = this.getTitle(name);
    let facility = this.facilityPipe.transform(this.requestInfo.selectedFacility['facilityId'], 'facilityName');
    let facilityName = this.translatePipe.transform(facility, 'LOCALE', 'name')
    console.log("Details",this.detail, "Concept", conceptName);
    console.log("This concept-->",this.concept)
    // console.log("this.pkgPrefer['patient']", this.pkgPrefer['patient'])
    var tempBody={
        payment:false,
        description: this.translatePipe.transform(this.selectedPackage.name, 'LANGARRAY', 'name'), //conceptName,
        personId: this.user.getPersonId(),
        mobile:this.user.getPersonMobile(),
        email:this.user.getPersonEmail(),
        preferredDate: moment(this.requestInfo['selectedSlot']['slot']['date'], 'YYYY-MM-DD').format('YYYY-MM-DD'),
        facilityName: facilityName,
        charge: this.patientPayable,
        chargeCurrency: this.currency_code,
        patientName: this.selectedPatient.personname,
        patientId: this.selectedPatient.uhid,
        notify: notify || null ,
        locale:this.user.currentLanguage,
        directiveName: this.translatePipe.transform(this.selectedPackage.name, 'LANGARRAY', 'name'), //conceptName,
        packComment: null,
        packageDetails: {
          directiveCode: this.selectedDirective.code,
          directiveGroup: this.selectedDirective.directiveGroup,
          conceptCode: this.selectedPackage.code,
          directiveDesc:this.selectedDirective.name,
          conceptDesc: this.selectedPackage.name
        },
    }
    // if(this.chosenFacility.controls.payableOnline){
    //     tempBody.charge=this.pkgPrefer['pkgCost'];
    //     tempBody.chargeCurrency=this.chosenFacility.chargeCurrency;
    //     if(type=='PAY'){
    //         tempBody.payment=true;
    //         tempBody.patientName=this.pkgPrefer['patient']['personname'];
    //     }
    // }
    // if(type=='REQ'){
    //     tempBody.patientId= this.selectedPatient.uhid
    //     tempBody.patientName=this.selectedPatient.personname
    // }else if(type=='NEWREQ'){
    //     tempBody.patientName=this.selectedPatient.personname
    // }
    // if(this.patient === 'new'){
    //     tempBody.patientName = this.patientName
    // }
    // else if(this.patient != 'new'){
    //   tempBody.patientId=this.pkgPrefer['patient'].uhid;
    //   tempBody.patientName=this.pkgPrefer['patient'].personname;
    // }
    console.log("tenpoBoddyyy",tempBody);
    // this.pay(tempBody.charge,tempBody);
    if(type=='PAY'){
        this.pay(tempBody.charge,tempBody);
    }else{
        this.serviceRequest(tempBody);
    }

}
serviceRequest(Body) {
  this.apptService.confirmPackageRequest(Body).subscribe( data => {
    console.log("serviceRequest res data",data)
    if (data.status == "SUCCESS"){
        this.isReqRecieved = true;
        this.isEditApplicable = false
      this.paymentStatus = 'REQ'
      this.gtmService.pushGTMEvent('Health Packages', 'appointment_confirmation', { value: +Body['charge'], request_status:'Success' , booking_no:data['data']?.reqId?.request_id});
    }
  }, err => {
    this.gtmService.pushGTMEvent('Health Packages', 'appointment_confirmation', { value: +Body['charge'], request_status:'Failed:' + err });
  })
}

public pay(amount,reqBody) {

  this.payType = 'C'
  var tempType;
  var body
    if(reqBody==null){
      tempType=false;
      body={
          personId: this.user.getPersonId(),
          linkedPersonId: this.selectedPatient.personid,
          patientId: this.selectedPatient.uhid,
          amount:amount,
          currency:this.currency_code,
          episodeType:'R',
          encounterId:'0',
          requestType:'DEPOSIT',
      }
      reqBody={
          personId: this.user.getPersonId(),
          linkedPersonId: this.selectedPatient.personid,
          patientId: this.selectedPatient.uhid,
          amount:amount,
          currency:this.currency_code,
          episodeType:'R',
          encounterId:'0',
    }
  }else{
      tempType=true;
      body={
          personId: this.user.getPersonId(),
          linkedPersonId: this.selectedPatient.personid,
          patientId:  this.selectedPatient.uhid,
          amount:amount,
          currency:this.currency_code,
          episodeType:'R',
          encounterId:'0',
          requestType:'SRVREQ',
          // request:reqBody
      }
  }
    this.apptService.startTransaction(body, reqBody).subscribe((response: any) => {
      console.log("environment.DEFAULT_EMAIL", environment.DEFAULT_EMAIL);
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.selectedPatient.uhid,
        email:  environment.DEFAULT_EMAIL || null,
        mobile_no: ''
      }
      let url = window['location'];
      let transaction_id = response['transaction_id'];
      let payment = true;
      let method = 'SRVREQ';
      // let date = this.user.getMoment(this.slot['date']).format("YYYYMMDD");
      // let slot = this.user.getMoment(this.slot['slot'], 'HH:mm').format('HHmm')
      let retry = `pt=${this.payType}&cost=${amount}`;
      // let visitType =  'VCN' ;
     
      let retryJson = JSON.stringify(retry);
      this.gtmService.pushGTMEvent('Health Packages', 'appointment_confirmation', { value:amount,booking_no: response['transaction_id'] });
      
      let params = `orderid=${transaction_id}&f=${this.facilityId}&h=${this.selectedPatient.uhid}&${retry}`
      // let params = `orderid=${transaction_id}&f=${this.facilityId}&s=${this.speciality['id']}&p=${this.practitioner['id']}&d=${date}&t=${slot}&h=${this.patient['id']}&v=${visitType}&c=${flowType}&${retry}`
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=SRVREQ&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() ||  this.currency_code,
        charge: amount,
        paymentgatewayurl: this.paymentGatewayUrl
      }
      console.log("paymentInfo",paymentInfo)
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        // this.payment.emit({ name: 'openPaymentCheckout' });
        this.openPayment = true;
        this.user.setStorage(AppConstant.HEALTHPKG_REQ, JSON.stringify(this.requestInfo));
      })
    })
}

public closeModal(type) {
  this.action.emit(type);
}

public switchActions(type){
  this.switchAction.emit(type);
}

}
