import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OngoingAppointmentComponent } from './ongoing-appointment.component';
import { ListItemModule } from '../list-item/list-item.module';
import { PipesModule } from '../pipes/pipes.module';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { NodataModule } from '../nodata/nodata.module';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { AppointmentUploadQuestionnarieModule } from '../appointment-upload-questionnarie/appointment-upload-questionnarie.module';
import { HrDocumentUploadModule } from '../hr-document-upload/hr-document-upload.module';

@NgModule({
  declarations: [OngoingAppointmentComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule, BookAppointmentModule, RegisterPatientModule, NodataModule,
    CustomModalModule, 
    PersonAvatarModule, AppointmentUploadQuestionnarieModule, HrDocumentUploadModule,
    UpcomingAppointmentDetailModule
  ],
  exports: [
    OngoingAppointmentComponent
  ]
})
export class OngoingAppointmentModule { }
