<div class="composite" *ngIf="section">
  <p class="label"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span> <sup *ngIf="section['mandatory']=='true'"
      style="color: red">*</sup></p>
  <div *ngIf="section['multipleFields'] && section['multipleFields'].length" class="field-wrapper cursor-pointer">
    <div *ngFor="let field of section['multipleFields']" class="fields">
      <div [ngSwitch]="field['type']">
        <ng-container *ngSwitchCase="'numericInteger'">
          <input type="number" min="{{field['minValue']}}" max="{{field['maxValue']}}" step="{{field['stepCounter']}}"
            [(ngModel)]="field['selected']" class="num" (keyup)="changeInValues()" [disabled]="apptBooked" [tabindex]="tabindex">
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <select name="" id="" [(ngModel)]="field['selected']" class="select" (change)="changeInValues()"
            [disabled]="apptBooked" [tabindex]="tabindex">
            <option [value]="ans['code']" *ngFor="let ans of field['options']">{{ans.localName | translate:'LOCALE':'text'}}</option>
          </select>
        </ng-container>
      </div>
    </div>
  </div>
  
  <div
    *ngIf="section['quickSelection'] && section['quickSelection'].length && section['multipleFields'].length && !hideQuickSelect"
    class="quick-selection">
    <span *ngFor="let s of section['quickSelection']" (click)="quickSelect(s)" class="check">
      <input type="radio" name="" id="" style="margin-right:5px" [checked]="s['selected']" [disabled]="apptBooked"
        [tabindex]="tabindex"> {{s['displayValue']}}
    </span>
  </div>
  <app-appointment-slot [patient]="patient" [selected]="section['selected']" [section]="section" *ngIf="section['selected'] && section['externalEvent'] === 'BOOK_APPT'" (booked)="appointmentBooked($event)"></app-appointment-slot>
  <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
    <p>{{'form_field_mandatory' | translate}}</p>
  </div>
</div>