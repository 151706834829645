import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import * as moment from "moment";
import { TranslatePipe } from '../pipes/translate.pipe';
import { AppointmentService } from '../services/appointment.service';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { apptFlowType, visitType } from '@type';
import * as _ from 'lodash';


@Component({
  selector: 'app-appointment-review',
  templateUrl: './appointment-review.component.html',
  styleUrls: ['./appointment-review.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentReviewComponent implements OnInit {
  @Input() _input: any;
  @Input() visitType: visitType;
  @Input() flowType: apptFlowType;
  @Input() referralDetail: any;
  @Input() isReferral: boolean;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  public facility: any;
  public speciality: any;
  public practitioner: any;
  public patient: any;
  public slot: any;
  public currency_code: any;
  public facilityId: any;
  public retry: any;
  public paymentGatewayUrl: any;
  constructor(private translatePipe: TranslatePipe, private apptService: AppointmentService, private paymentService: PaymentService, private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.facility = this._input.in['facility'];
    this.speciality = this._input.in['speciality'];
    this.practitioner = this._input.in['practitioner'];
    this.patient = this._input.in['patient'];
    this.slot = this._input.in['slot'];
    this.retry = this._input.in['confirm']['retry'];
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];

    this.facilityId = this._input.in['facility'] ? this._input.in['facility']['id'] : this._input.in['speciality'] ? this._input.in['speciality']['facilityId'] : this._input.in['practitioner']['facilityId'];
    console.log(JSON.stringify(this._input));
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    });
    if(_.isEmpty(this._input['appointmentType']))
      this._input['appointmentType'] = this.visitType;
  }

  public payForAppt() {
    if (this.retry['payType'] == 'C' || this.retry['payType'] == 'X') {
      var payload = {
        linkedPersonId: this.patient['personid'],
        patientId: this.patient['id'],
        amount: this.retry['cost'],
        currency: this.currency_code,
        episodeType: 'R',
        encounterId: '0',
        facilityId: this.facilityId,
        requestType: 'APPTBK',
        resourceDetails: {
          billingGroup: this.retry['billingGroupCode'],
          insuranceFinDtls: this.retry['insuranceFinDtls']
        }
      };
      let request;
      if (this.retry['sourceRequest']) {
        request = this.retry['sourceRequest']
      } else {
        request = {
          ...this._input.in,
          resourceId: this.practitioner['id'],
          patientPersonId: this.patient['personid'],
          uhid: this.patient['id'],
          facilityId: this.facilityId,
          clinicCode: this.slot['clinicCode'],
          date: this.user.getMoment(this.slot['date']).format("YYYY-MM-DD"),
          time: this.slot['slot'],
          personName: this.patient['name'],
          dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
          gender: this.patient['gender'],
          practitionerName: this.translatePipe.transform(this.practitioner['name'], 'LOCALE', 'name'),
          specialityName: this.translatePipe.transform(this.speciality['name'], 'LOCALE', 'name'),
          patientName: this.patient['name'],
          discount: this.retry['discount'] ? this.retry['discount'] : null,
          visitType: this.visitType,
          billingGroup: this.retry['billingGroupCode'],
          insuranceFinDtls: this.retry['insuranceFinDtls'],
          trace: 'RETRY'
        }
        if (this.retry['freeFollowUpYn'] == 'Y') {
          request['freeFollowUpYn'] = this.retry['freeFollowUpYn']
        }
        if (this.retry['payType'] == 'X') {
          request['isInsurance'] = true;
        }
        if (this.flowType == 'RESCHEDULE') {
          request['apptRefNumber'] = this._input.in['exist']['apptRefNo'];
          request['appointmentNumber'] = this._input.in['exist']['apptRefNo'];
          payload['apptRefNumber'] = this._input.in['exist']['apptRefNo'];
          payload['appointmentNumber'] = this._input.in['exist']['apptRefNo'];
        }
      }

      payload['resourceDetails']['sourceRequest'] = _.cloneDeep(request)
      
      this.apptService.startTransaction(payload, request).subscribe((response: any) => {
        let appointment = {
          _orderId: response['transaction_id'],
          saved_card_token: response['saved_card_token'],
          saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
          saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
          uhid: this.patient['id'],
          email: this.patient['email'] || environment.DEFAULT_EMAIL,
          mobile_no: ''
        }
        let url = window['location'];
        let transaction_id = response['transaction_id'];
        let payment = true;
        let method = 'APPTBK';
        let date = this.user.getMoment(this.slot['date']).format("YYYYMMDD");
        let slot = this.user.getMoment(this.slot['slot'], 'HH:mm').format('HHmm')
        let retry = `pt=${this.retry['payType']}&cost=${this.retry['cost']}`;
        if (this.retry['freeFollowUpYn']) {
          retry += `&ff=${this.retry['freeFollowUpYn']}`;
        }
        if (this.retry['discount']) {
          retry += `&dis=${this.retry['discount']}`;
        }
        if (this.retry['billingGroupCode']) {
          retry += `&g=${this.retry['billingGroupCode']}`;
        }
        let visitType = this.visitType == 'VIDEO' ? 'V' : 'H';
        let flowType = this.flowType == 'BOOKING' ? 'B' : 'R';
        let retryJson = JSON.stringify(retry);
        let params = `orderid=${transaction_id}&f=${this.facilityId}&s=${this.speciality['id']}&p=${this.practitioner['id']}&d=${date}&t=${slot}&h=${this.patient['id']}&v=${visitType}&c=${flowType}&${retry}`
        let diff = (href, origin) => href.split(origin).join('');
        let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=APPTBK&p=${btoa(params)}`;
        let paymentInfo = {
          _returnURL: returnURL,
          currency: this.user.getCurrencyForPayment() ||  this.currency_code,
          charge: this.retry['cost'],
          paymentgatewayurl: this.paymentGatewayUrl
        }
        this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
          this.action.emit({ name: 'openPaymentCheckout' });
        })
      })
    }
  }

  public goToHome(){
    this.complete.emit('CLOSEMODAL');
  }
}
