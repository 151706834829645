import { Component, OnInit, Input } from '@angular/core';
import { ModalService} from '../services/modal.service';
import { HealthRecordsService } from '../services/health-records.service';
import { environment } from '@env/environment';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { BILLSTATUS_COLOR_MAPPING } from '@const';
// import * as moment from 'moment';

@Component({
  selector: 'app-hr-visit-bills',
  templateUrl: './hr-visit-bills.component.html',
  styleUrls: ['./hr-visit-bills.component.scss']
})
export class HrVisitBillsComponent implements OnInit {

  @Input() set selectedVisit(value: any) {
    this._selectedVisit = value;
    this.selectedFilter = { key: '', value: 'All' };
    this.unsettledBill = []
    this.unsettledBillToFilter = []
    this.settledBill = []
    this.settledBillToFilter = [];
    // this.getPendingBill(this._selectedPatient);
    // this.getSettledBill(this._selectedPatient);
    // this.getRefundPendingDtls()
    this.getAllBills(this._selectedPatient)
  }
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
    this.facilityId = this._selectedPatient.facilityId;
    this.selectedFilter = { key: '', value: 'All' };
    this.unsettledBill = []
    this.unsettledBillToFilter = []
    this.settledBill = []
    this.settledBillToFilter = [];
    // this.getPendingBill(this._selectedPatient);
    // this.getSettledBill(this._selectedPatient);
    // this.getRefundPendingDtls();
    this.getAllBills(this._selectedPatient)

  }

  public _selectedVisit: any;
  public _selectedPatient: any;
  public unsettledBill = [];
  public settledBill = [];
  public searchTerm: string = '';
  public viewBill = [];
  public viewBillDetail = false;
  public facilityId: string;
  public unsettledBillToFilter = [];
  public settledBillToFilter = [];
  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: any;
  public unsettledBillOP = [];
  public settledBillOP = [];
  public unsettledBillIP = [];
  public settledBillIP = [];
  public unsettledBillEY = [];
  public settledBillEY = [];
  public setBillDetail;
  public searchExpand: boolean;
  public currency_code;
  public depositList = [];
  public selectedReceipt: any;
  public allBills: Array<any> = [];
  public billToPreview;
  public billStatusColorMapping = BILLSTATUS_COLOR_MAPPING;
  public billDtl;
  public hrPatientBillingClaimSummary: boolean;
  public isMobileView;
  constructor(private modalService: ModalService, private hrService: HealthRecordsService, private user: UserService, private activatedRoute: ActivatedRoute) {
    this.user.getConfigurationInfo.subscribe(data => {
      this.hrPatientBillingClaimSummary = data['pxAppSettings'] && data['pxAppSettings']['hrPatientBillingClaimSummary'] == 'Y' ? true: false;
    })
   }
  ngOnInit(): void {
    this.filters = [
      { key: '', value: 'all' },
      { key: 'O', value: 'out_patient' },
      { key: 'I', value: 'in_patient' },
      { key: 'E', value: 'lbl_emer_query' }
    ];
    this.selectedFilter = this.filters[0];
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.isMobileView = document.body.clientWidth <=780;
    this.checkUrl();
  }

  openBill(data: any) {
    this.viewBill = [];
    this.viewBillDetail = false;
    this.selectedReceipt = null;
    this.getBillDetails(data);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  // private getAllBills(selectedPatient) {
  //   let pendingPromise = this.getPendingBill(selectedPatient);
  //   let settledPromise = this.getSettledBill(selectedPatient);
  //   let refundPromise = this.getRefundPendingDtls(selectedPatient);
  //   Promise.all([pendingPromise, settledPromise, refundPromise]).then((values) => {
  //     const pendingBills = values[0]
  //     const settledBills = values[1]
  //     const refunds = values[2]
  //     this.allBills = [...pendingBills, ...settledBills, ...refunds];
  //   })
  // }

  private getAllBills(selectedPatient) {
    if(this.hrPatientBillingClaimSummary) {
      this.hrService.getAllBillsForPatient(selectedPatient['uhid']).subscribe(data => {
        this.allBills = data;
      })
    }
    else {
      let pendingPromise = this.getPendingBill(selectedPatient);
      let settledPromise = this.getSettledBill(selectedPatient);
      // let refundPromise = this.getRefundPendingDtls();
      Promise.all([pendingPromise, settledPromise]).then((values) => {
        const pendingBills = values[0];
        pendingBills.forEach(t => t['_type'] = 'PEND');
        const settledBills = values[1];
        settledBills.forEach(t => t['_type'] = 'PAID')
        // const refunds = values[2].map(t => t['_type'] = 'REFUND')
        this.allBills = [...pendingBills, ...settledBills];
        if(!this.isMobileView && this.allBills.length)
          this.openBill(this.allBills[0]);
        console.log('allBills', this.allBills);
      })
    }
  }

  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
    this.filterItemsByKey(this.selectedFilter.key);
  }

  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterItemsByKey(searchTerm) {
    this.unsettledBill = this.unsettledBillToFilter;
    this.settledBill =  this.settledBillToFilter;
    if(searchTerm === 'O'){
      this.unsettledBill = this.unsettledBill.filter((item: any) => {
        return item.episodeType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.settledBill = this.settledBill.filter((item: any) => {
        return item.encounterType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.unsettledBillOP = this.unsettledBill;
      this.settledBillOP =  this.settledBill;
    }
    if(searchTerm === 'I'){
      this.unsettledBill = this.unsettledBill.filter((item: any) => {
        return item.episodeType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.settledBill = this.settledBill.filter((item: any) => {
        return item.encounterType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.unsettledBillIP = this.unsettledBill;
      this.settledBillIP =  this.settledBill;
    }
    if(searchTerm === 'E'){
      this.unsettledBill = this.unsettledBill.filter((item: any) => {
        return item.episodeType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.settledBill = this.settledBill.filter((item: any) => {
        return item.encounterType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ;
      });
      this.unsettledBillEY = this.unsettledBill;
      this.settledBillEY =  this.settledBill;
    }
  }

  searchFilter(ev) {
    this.searchTerm = ev.target.value;
    console.log("type of search",ev.target.value)
    this.filterItems(this.searchTerm);
  }

  filterItems(searchTerm) {
    if(this.selectedFilter.key === 'O'){
      this.unsettledBill = this.unsettledBillOP;
      this.settledBill =  this.settledBillOP;
    }
    else if(this.selectedFilter.key === 'I'){
      this.unsettledBill = this.unsettledBillIP;
      this.settledBill =  this.settledBillIP;
    }
    else if(this.selectedFilter.key === 'E'){
      this.unsettledBill = this.unsettledBillEY;
      this.settledBill =  this.settledBillEY;
    }
    else{
      this.unsettledBill = this.unsettledBillToFilter;
      this.settledBill =  this.settledBillToFilter;
    }
    this.unsettledBill = this.unsettledBill.filter((item: any) => {
      return item.episodeType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.practitionerName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.billDate.indexOf(searchTerm) > -1;
    });
    this.settledBill = this.settledBill.filter((item: any) => {
      return item.encounterType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.practitionerName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || item.billDate.indexOf(searchTerm) > -1;
    });
  }

  getPendingBill(data): Promise<Array<any>> {
    return new Promise(resolve => {
      if (!this._selectedPatient)
        return resolve([]);
      this.hrService.getPendingBill(data).subscribe(data => {
        if (!this._selectedVisit) {
          this.unsettledBill = data[0].unsettled
          this.unsettledBillToFilter = data[0].unsettled;
        } else {
          this.unsettledBill = data[0].unsettled.filter(us => {
            return us.encounterId == this._selectedVisit.encounterId
          })
          this.unsettledBillToFilter = data[0].unsettled.filter(us => {
            return us.encounterId == this._selectedVisit.encounterId
          })
        }
        return resolve(this.unsettledBill);
      }, err => {
        return resolve([]);
      })
    })
    
  }

  getSettledBill(data): Promise<Array<any>> {
    return new Promise(resolve => {
      if (!this._selectedPatient)
        return resolve([]);
      this.hrService.getSettledBill(data).subscribe(data => {
        if (!this._selectedVisit) {
          this.settledBill = data.bills
          this.settledBillToFilter = data.bills;
        } else {
          this.settledBill = data.bills.filter(us => {
            return us.encounterId == this._selectedVisit.encounterId
          })
          this.settledBillToFilter = data.bills.filter(us => {
            return us.encounterId == this._selectedVisit.encounterId
          })
        }
        return resolve(this.settledBill)
      }, err => {
        return resolve([]);
      })
    })
  }

  // private getRefundPendingDtls() {
  //   this.hrService.getRefunPendingDtls(this.facilityId, this._selectedPatient).subscribe(response=> {
  //     this.depositList = response;
  //   })
  // }
  private getRefundPendingDtls(): Promise<Array<any>> {
    return new Promise(resolve => {
      this.hrService.getRefunPendingDtls(this.facilityId, this._selectedPatient).subscribe(response => {
        this.depositList = response;
        return resolve(response)
      }, err => {
        return resolve([]);
      })
    })
  }

  getBillDetails(billDetail){
    this.hrService.getBillDetails(billDetail.facilityId, billDetail, this._selectedPatient||billDetail).subscribe(
      data =>{
        console.log("bill data",data)
        this.selectedReceipt = null
        this.viewBill = data
        this.setBillDetail = billDetail;
        this.viewBillDetail = true;
        // this.openModal(id);
      }
    )
  }

  public closeBillDetail() {
    this.selectedReceipt = null
    this.viewBill = []
    this.setBillDetail = null;
    this.viewBillDetail = false;
  }

  public openReceipt(id, data) {
    this.viewBill = []
    this.setBillDetail = null;
    this.viewBillDetail = false;
    this.selectedReceipt = data;
    this.openModal(id);
  }

  searchIcon(){
    if(this.searchExpand){
      this.searchExpand = false;
    }
    else{
      this.searchExpand = true;
    }
  }

  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      let ctx = params['ctx'];
      if (ctx) {
        let loginContext = this.user.getLoginContext();
        this.handleContext(loginContext);
      }
    })
  }
  private handleContext(context) {
    console.log(context);
    if (context && context.patientId && context.billNo) {
      context.uhid = context.patientId;
      this.getBillDetails(context)
      this.user.removeLoginContext()
    }
  }

  public openBillPreview( item , type) {
    this.billToPreview = item && item.billNo ? item.billNo: null
    this.billDtl = item
  }

  public closeBillPreview() {
    this.billToPreview = null;
  }

}


