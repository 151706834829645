<div id="crm-component">
  <!-- <div class="header-component" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header></app-header> 
  </div> -->
  <!-- <app-navigation [ngClass]="{'nav-hg': increaseIndent}"></app-navigation> -->
  <div class="app-crm-content" [ngClass]="{'full-width':isVideoConsultation, 'crm-hg': increaseIndent}">
    <router-outlet></router-outlet>
  </div>
  <!-- <app-footer [isCrm]="true" [appRemoveFeatureOff]="'SHOW_APP_FOOTER'"></app-footer> -->
</div>
