import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeFeedbackCardComponent } from './home-feedback-card.component';
import { PipesModule } from '../pipes/pipes.module';
import { ListItemModule } from '../list-item/list-item.module';



@NgModule({
  declarations: [HomeFeedbackCardComponent],
  imports: [
    CommonModule, PipesModule, ListItemModule
  ], exports: [
    HomeFeedbackCardComponent
  ]
})
export class HomeFeedbackCardModule { }
