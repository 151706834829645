import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import { OnspotConsultationService } from '../services/onspot-consultation.service';
import { onspotFlowType } from '../app.type';
import { SocketService } from '../services/socket.service';
import { DIRECTIVE_CODE } from '../app.const';
import * as moment from "moment";
import * as _ from 'lodash';
import { ModalService } from '../services/modal.service';
import { Subject } from "rxjs";
import * as AppConstant from '../app.string';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { environment } from '@env/environment';
import { PaymentService } from '../services/payment.service';
import { AppointmentService } from '../services/appointment.service';
import { GoogleTagsService } from '../services/google-tags.service';
import { CookieService } from '../services/cookie.service';
@Component({
  selector: 'app-onspot-consult-doctor',
  templateUrl: './onspot-consult-doctor.component.html',
  styleUrls: ['./onspot-consult-doctor.component.scss']
})
export class OnspotConsultDoctorComponent implements OnInit {

  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() apptInfo: any;
  @Output() action: EventEmitter<any>;
  @Output() complete: EventEmitter<any>;
  @Output() eventRequest: EventEmitter<any>;
  @Output() close: EventEmitter<any>;
  @Input() orderdetails: any;
  @Input() apptParams: any;
  @Input() ongoingAppts: any;
  countDown: Subject<boolean> = new Subject<boolean>();
  private readonly DIRECTIVE_CODE = DIRECTIVE_CODE;

  private directive: any;
  public selectedConcepts: any;
  public consultationCost: Object;
  public teamStatus;
  private teamStatusList;
  private currency_code;
  private cost;
  private payType;
  private facilityId;

  public linkedPatients: Array<any>;
  public state: onspotFlowType;
  public selectedPatient: any;
  public waitingTime;
  public eventInfo;
  public taskToComplete: Object;
  public orderId;
  public appointmentInfo;
  public practInfo;
  public specialityInfo = {};
  public flowType: string = '';
  public payParam: Object = {};
  public paymentGatewayUrl: string;
  public isFlip: boolean = false;
  public checkTaskInterval: any;
  public timeOut;
  public waitingSec;
  public confirmAppt: boolean = false;
  public chargeStr: string = "";
  public isPaying;
  public facilityList;
  public apptOverlapView: boolean = false;
  public apptOverlap: string = 'N';
  public onspotSelectedPatient;
  public selectedPatientWithAppt;
  public isRequesting: boolean = false;
  public recentConsultatedPatient: any;
  public specialityList: Array<any> = [];
  public noSpeciality: boolean = false;
  public isGettingSpeciality: boolean = false
  public showGroup: boolean = false
  public selectedSpeciality: any;
  public showCancelConfirmation: any;
  public restriction: any;
  constructor(
    private onspotService: OnspotConsultationService,
    private user: UserService,
    private linkPatService: LinkPatientService,
    private socket: SocketService,
    private modalService: ModalService,
    public loader: LoaderService,
    private paymentService: PaymentService,
    private apptService: AppointmentService,
    private gtmService: GoogleTagsService,
    private cookie: CookieService,
  ) {
    this.action = new EventEmitter<any>();
    this.complete = new EventEmitter<any>();
    this.close = new EventEmitter<any>();
    this.eventRequest = new EventEmitter<any>();
    this.consultationCost = {}
    this.linkedPatients = [];

  }

  ngOnInit(): void {
    if (this.apptInfo) {
      this.checKTransactionstatus(this.apptInfo, this.apptParams);
    }
    this.refresh.subscribe(response => {
      if (response) {
        this.setLinkedPatients(true);
      }
    })
    if (!this.apptInfo) {
      this.getSpecialityList()
      this.state = 'CHOOSE_SPECIALITY';
      this.gtmService.pushGTMEvent('Instant Consultation', 'select_person');
    }
    this.setLinkedPatients();
    if (this.user.canViewOption('ONSPOTWITHSPECIALITY')) {
      this.getConcepts(null).subscribe(data => {
        console.log("getConcepts", data)
      })
    }
    setTimeout(() => {
      console.log('timeout triggered on queue');
      this.getWatingTime();
    }, 500);
    this.countDown.subscribe(response => {
      console.log('timeout triggered on queue', this.getWatingTime());
      this.getWatingTime();
    })
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    })
    this.showGroup = this.user.canViewOption('SHOWONSPOTSPECGROUP')
  }

  ngOnDestroy(): void {
    if (this.timeOut)
      clearTimeout(this.timeOut)
  }

  private getConcepts(facilityId): Observable<any> {
    return new Observable(observer => {
      let patients = _.cloneDeep(this.linkedPatients);
      let inParam = {
        directiveCode: this.DIRECTIVE_CODE,
        facilityId: facilityId || this.facilityId,
        speciality: this.selectedSpeciality,
        checkGenderRestriction: true,
        patientIds: patients.map(p => p.uhid)
      }
      this.onspotService.getConceptDetailsForDirective(inParam).subscribe(response => {
        let data = response['data']
        let restriction = response['restriction']

        this.directive = data;
        this.facilityId = data['facilityId'];
        this.selectedConcepts = data.selectedConcepts;

        if (this.selectedConcepts.length >= 0) {
          let concept = this.selectedConcepts[0].concept_controls.controls
          console.log("concept", concept);
          this.consultationCost = {
            chargeCurrency: concept['chargeCurrency'] || this.user.getCurrency(),
            charge: concept['charge'],
            timeFrom: concept['timeFrom'],
            timeTo: concept['timeTo']
          }
        }
        if (facilityId) {
          this.state = 'CHOOSE_PATIENT'
          this.restriction = restriction || null
        }
        observer.next(this.consultationCost);
      })
    });
  }

  private setLinkedPatients(forceNew?) {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.recentConsultatedPatient = this.apptService.getRecentConsultatedPatientValue();
        this.linkPatService.getLinkedPatient(data, forceNew).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            if (this.ongoingAppts && this.ongoingAppts.length)
              this.parseAppts();
            this.setDefaultPatient()
          }
        })
      }
    })
  }

  public setDefaultPatient() {
    this.linkedPatients.forEach((p) => {
      if (p['uhid'] == this.recentConsultatedPatient['uhid']) {
        p['recentConsultated'] = true;
      }
    });
    this.linkedPatients.splice(0, 0, this.linkedPatients.splice(this.linkedPatients.findIndex(p => p['recentConsultated']), 1)[0]);
    console.log('this.linkedPatients', this.linkedPatients)
    this.selectedPatient = this.linkedPatients[0];
  }

  public goHome() {
    this.apptOverlapView = false;
  }

  public continue() {
    this.apptOverlapView = false;
    this.apptOverlap = 'Y';
    console.log('onspotSelectedPatient', this.onspotSelectedPatient)
    this.requestOnspot(this.onspotSelectedPatient)
  }

  public selectedOptForOverlap(ev) {
    console.log('opt', ev);
  }

  public generateApptOverlapParams(data) {
    let body = {
      ...data,
      patientId: this.selectedPatient['uhid'],
      patientids: [this.selectedPatient['uhid']],
      specialityId: this.selectedSpeciality.specialityId,
      apptType: 'ONSPOT',
      localeCode: this.user.getCurrentLanguage()
    }
    return body;
  }

  public checkApptOverlapForPatient(d) {
    let payload = this.generateApptOverlapParams(d)
    this.apptService.getcheckApptOverlapForPatient(payload).subscribe(async res => {
      console.log('getcheckApptOverlapForPatient', res)
      if (res.overlap == 'Y') {
        this.apptOverlapView = true;
      } else {
        this.requestOnspot(d)
      }
    }, err => {
      this.requestOnspot(d);
      console.log('Error:', err)
    }, () => {
      console.log('Completed')
    })
  }

  public requestConsultation(selectedPatient) {
    this.onspotSelectedPatient = selectedPatient
    if (this.isRequesting)
      return;
    if (this.user.canViewOption('ENABLECHECKAPPTOVERLAPFORPATIENT')) {
      this.checkApptOverlapForPatient(selectedPatient)
    } else {
      this.requestOnspot(selectedPatient)
    }

  }

  public requestOnspot(selectedPatient) {
    this.getConcepts(this.facilityId).subscribe(data => {
      this.selectedPatientWithAppt = null;
      this.gtmService.pushGTMEvent('Instant Consultation', 'step_option_select_person', { patient_type: 'Existing' });
      this.selectedPatient = selectedPatient;
      this.socket.onspotPatientEmit({ state: 'CHOOSE_PATIENT', patientId: this.selectedPatient['uhid'], selectedPatient: this.selectedPatient });
      console.log("selectedPatient", this.selectedPatient)
      const timeNow = this.user.getMoment(),
        timeFromSplit = this.consultationCost['timeFrom'].split(':'),
        timeFrom = this.user.getMoment().startOf('d').add(timeFromSplit[0], 'h').add(timeFromSplit[1], 'm'),
        timeToSplit = this.consultationCost['timeTo'].split(':'),
        timeTo = this.user.getMoment().startOf('d').add(timeToSplit[0], 'h').add(timeToSplit[1], 'm');
      if (timeNow.isBefore(timeFrom) || timeNow.isAfter(timeTo))
        return this.state = 'ON_UNAVAILABLE';
      if (this.selectedConcepts.length == 1) {
        this.getTeamStatusForConcept(this.selectedPatient);
        console.log("selectedConcepts", this.selectedConcepts)
      }
      else {
        this.state = 'CHOOSE_TEAM'
        this.isRequesting = false
      }
    })
  }

  public selectSpeciality(data) {
    this.selectedConcepts = data;
    this.getTeamStatusForConcept(this.selectedPatient);
  }

  public defaultRequestConsultation(selectedPatient) {
    if (this.isRequesting)
      return;
    this.isRequesting = true;
    this.selectedPatient = selectedPatient;

    let payload = {
      status: 'COMP',
      patientId: this.selectedPatient['uhid'],
      facilityId: this.facilityId,
      directiveCode: this.DIRECTIVE_CODE,
      directiveControls: this.directive['directiveControls'],
      conceptCode: this.selectedConcepts[0]['id'],
      form: {
        clinicalNote: null,
        topicOutcomes: null,
        outcomes: null
      },
      personId: this.user.getPersonId(),
      specialityCode: this.selectedSpeciality ? this.selectedSpeciality.specialityId : undefined,
      selectedConcept: this.selectedConcepts[0]
    };
    console.log("payload", payload);
    let fun = 'recordFormForOnspot'
    if (!payload.specialityCode) {
      fun = 'recordForm'
    }
    this.onspotService[fun](payload).subscribe(data => {
      console.log("recordForm response", data);
      this.eventInfo = data['data']['tasksList'][0];
      this.isRequesting = false;
      if (this.flowType == 'QUEUE') {
        this.state = 'ON_QUEUE'
        this.initSocket();
      }
      else {
        this.state = 'ON_AVAILABLE'
        this.initSocket();
        const dueDate = this.user.getMoment(this.eventInfo['task_due_date_time']).diff(this.user.getMoment(), 'millisecond');
        console.log('dueTime', dueDate);
        this.timeOut = setTimeout(() => {
          if (!this.isPaying) {
            this.cancelRequest(true);
            this.state = 'ON_BUSY';
          }
        }, dueDate);
      }
      this.timeOut = setTimeout(() => {
        this.cancelRequest(true);
        this.state = 'ON_BUSY';
      }, _.cloneDeep(this.waitingSec) * 1000);
      this.eventRequest.emit(this.eventInfo);
    }, err => {
      this.isRequesting = false;
    })
  }

  public initSocket() {
    let payload = {
      entityId: this.selectedPatient.entityid,
      uhid: [this.selectedPatient['uhid']]
    }
    this.socket.joinPatientEvent(payload)
    this.checkIfTaskCreated();
    this.checkTaskInterval = setInterval(() => { this.checkIfTaskCreated() }, 5000);
    this.socket.onTaskChange().subscribe(obj => {
      console.log('patient-task-change', obj);
      if (obj.event == 'ADD' && obj.data && obj.data["directiveCode"] == "ONSPOTCONS" && obj.data["conceptCode"] == this.selectedConcepts[0]['id'] && obj.data["taskCode"] == "TASK2") {
        clearInterval(this.checkTaskInterval);
        this.getTaskInfo(obj['data']['facilityId'], obj.data['taskGroupCode'], obj.data['taskId']);
      }
    })

    if (this.state == 'CHOOSE_PATIENT' || this.state == 'CHOOSE_TEAM') {
      const dueDate = this.user.getMoment(this.eventInfo['task_due_date_time']).diff(this.user.getMoment(), 'millisecond');
      console.log('dueTime', dueDate);
      this.timeOut = setTimeout(() => {
        if (!this.isPaying)
          this.closeModel();
      }, dueDate);
      return this.state = 'ON_AVAILABLE';
    }
  }

  public getTeamStatusForConcept(selectedPatient) {
    let payload = {
      facilityId: this.facilityId,
      directiveCode: this.DIRECTIVE_CODE,
      conceptCode: this.selectedConcepts[0]['id'],
      specialityCode: this.selectedSpeciality.specialityId
    }
    this.onspotService.getTeamStatusForConcept(payload).subscribe(data => {
      console.log("getTeamStatusForConcept response", data);
      this.teamStatusList = data.data
      this.getAvailabilityCount(selectedPatient, this.teamStatusList);
      let response = { ...data.data, patientInfo: selectedPatient, consultationCost: this.consultationCost, facilityId: this.facilityId }
      this.teamStatus = response;
    })
  }

  public openPayment(event) {
    console.log("openPayment event", event)
    this.gtmService.pushGTMEvent('Instant Consultation', 'step_option_request_accepted', { 'option': event.name == 'rejectRequest' ? 'Cancel Request' : 'Proceed to payment' });
    if (event.name == 'rejectRequest') {
      this.updateTask(this.taskToComplete, null, 'REJECTED', null, 'submit');
      this.gtmService.pushGTMEvent('Instant Consultation', 'cancel_request', { 'screen': 'Payment Screen' });
      this.selectedConcepts = null;
      this.state = 'CHOOSE_SPECIALITY';
    } else if (event.name == 'CONFIRM_APPT') {
      console.log("openPayment event", event)
      this.confirmAppt = true;
      this.state = 'REDIRECT'
      this.socket.onspotPatientEmit({ token: this.cookie.getStorage(AppConstant.ACCESS_TOKEN), state: 'PAYMENT_SUCCESS', paymentMethod: 'APPLEPAY', task: this.taskToComplete, facilityId: this.facilityId });
      this.getConfirmApptDetails(this.facilityId, event.reference)
    } else {
      this.state = 'PAYMENT_CHECKOUT';
    }
  }

  public chooseType(type) {
    this.complete.emit(type)
  }

  public getAvailabilityCount(selectedPatient, teams) {
    const available = teams.filter(t => t['status'] && t['status']['type'] == 'AVAILABLE');
    if (Array.isArray(available) && available.length && available.length > teams[0]['count']) {
      return this.defaultRequestConsultation(selectedPatient);
    }
    const busy = teams.filter(t => t['status'] && (t['status']['type'] == 'BUSY' || t['status']['type'] == 'INCONSULTATION'));
    const tot_busy = busy.length + (available.length * 2);
    if (tot_busy > teams[0]['count']) {
      let arr = busy.concat(available);
      arr = _.orderBy(arr, b => b['recorded_dttm'], 'desc');
      this.waitingTime = this.user.getMoment(arr[0]['recorded_dttm']).format();
      setInterval(() => { this.getWatingTime() }, 1000);
      this.state = 'ON_CONSULTATION'
    }
    else {
      this.state = 'ON_BUSY'
    }
  }

  public goToQueue(type) {
    this.flowType = type;
    this.defaultRequestConsultation(this.selectedPatient);
    this.timeOut = setTimeout(() => {
      this.close.emit();
    }, _.cloneDeep(this.waitingSec) * 1000);
    return this.state = 'ON_QUEUE';
  }

  public openModal(id: string) {
    this.gtmService.pushGTMEvent('Instant Consultation', 'step_option_select_person', { patient_type: 'New' });
    this.action.emit({
      name: id
    })
  }

  public getWatingTime() {
    const sec = Math.round(this.user.getMoment().diff(this.user.getMoment(this.waitingTime), 's')) % 900,
      diff = 900 - sec;
    this.waitingSec = 900 - sec;
    return this.user.getMoment().startOf('day').add(diff, 'second').format('mm:ss');
  }

  public getWatingTimeMin() {
    const sec = Math.round(this.user.getMoment().diff(this.user.getMoment(this.waitingTime), 's')) % 900;
    this.waitingSec = 900 - sec;
    return this.user.getMoment().startOf('day').add(this.waitingSec, 'second').format('m');
  }

  private getTaskInfo(facilityId, taskGroup, taskId) {
    const payload = {
      facilityId: facilityId,
      taskGroup: taskGroup,
      taskId: parseInt(taskId)
    }
    this.onspotService.getTaskInfo(payload).subscribe(data => {
      if (data['taskGroup'] && data['taskGroup']['tasks']) {
        let task1 = data['taskGroup']['tasks'].find(t => t['taskCode'] == 'TASK1');
        this.practInfo = {
          practId: task1['assignedTeamId'],
          practName: task1['assignedTo']
        }
        if (task1['specialtyDetails']) {
          this.specialityInfo = {
            specCode: task1['specialtyDetails']['specialtyCode'],
            specDesc: task1['specialtyDetails']['specialtyDesc']
          }
        }
        console.log('CHECK 2', task1)
        if (task1['noteContent'] && task1['noteContent']['topics']) {
          let topics = task1['noteContent']['topics'];
          topics.forEach(t => {
            t['sections'].forEach(s => {
              switch (s['domainCode']) {
                case 'OSFAC':
                  this.facilityId = s['selected'] ? s['selected'] : this.facilityId;
                  break;
                default:
                  break;
              }
            });
          });
        }
        // this.getHispractChargeForAppt(this.facilityId);
        this.taskToComplete = data['taskGroup']['tasks'].find(t => t['taskCode'] == 'TASK2');
        this.taskToComplete['taskId'] = taskId;
        console.log(this.taskToComplete);
        clearTimeout(this.timeOut);
        this.user.setStorage(AppConstant.TASK_LIST, JSON.stringify(this.taskToComplete));
        this.teamStatus['practInfo'] = this.practInfo;
        this.teamStatus['specialityInfo'] = this.specialityInfo;
        this.teamStatus['facilityId'] = this.facilityId;
        this.state = 'ON_ACCEPT'
        this.gtmService.pushGTMEvent('Instant Consultation', 'request_accepted');
      }
    })

  }

  public initCancelRequest() {
    this.showCancelConfirmation = true
  }

  public dismissCancelRequest(status) {
    if (status) {
      this.cancelRequest()
    }
    this.showCancelConfirmation = false
  }


  public cancelRequest (dontBackToOrigin?: boolean) {
    this.selectedPatient = false;
    clearInterval(this.checkTaskInterval);
    const payload = {
      eventId: this.eventInfo.event_ascn_no,
      conceptCode: this.eventInfo.concept_code,
      ioChart: false,
      note: false,
      patientId: this.eventInfo.patient_id
    }

    this.onspotService.cancelTask(payload).subscribe(data => {
      if (data.status == 'SUCCESS') {
        this.eventInfo = null;
      }
      if (this.timeOut)
        clearTimeout(this.timeOut);
      this.gtmService.pushGTMEvent('Instant Consultation', 'cancel_request', { 'screen': 'Waiting Screen' });
      this.socket.onspotPatientEmit({ token: this.cookie.getStorage(AppConstant.ACCESS_TOKEN), state: 'REQUEST_CANCELED', ...payload, facilityId: this.facilityId });
      this.isRequesting = false;
    })
    if (dontBackToOrigin)
      this.flowType = '';
    this.state = 'CHOOSE_SPECIALITY'
  }

  private completeTaskAndCheckIn() {
    let value = this.user.getStorage(AppConstant.TASK_LIST);
    this.taskToComplete = JSON.parse(value);
    console.log("this.taskToComplete", this.taskToComplete);
    if (this.taskToComplete?.['noteContent']) {
      this.taskToComplete['noteContent']['topics'].forEach(t => {
        t['sections'].forEach(s => {
          switch (s['domainCode']) {
            case 'APPTREFID':
              s['selected'] = this.appointmentInfo['appointmentno'];
              break;
            case 'APPRDATETIME':
              s['selected'] = this.appointmentInfo['appointmentdatetime'];
              break;
            case 'PRACTNAME':
              s['selected'] = this.appointmentInfo['practitioner_name'];
              break;
            case 'TRXID':
              s['selected'] = this.appointmentInfo['transaction_id'];
              break;
            case 'AMNTPAID':
              s['selected'] = this.appointmentInfo['paymentamount'];
              break;
            default:
              break;
          }
        });
      });
    }

    var form = {
      noteCode: this.taskToComplete['noteCode'],
      noteContent: this.taskToComplete['noteContent']
    }
    this.updateTask(this.taskToComplete, form, 'COMP', null, 'submit');
  }

  public updateTask(taskDetail, form, newStatus, outcome, type) {
    console.log("taskDetail", taskDetail, form);
    var outcomeData = null;
    if (outcome) {
      outcomeData = outcome;
    }

    let body = {
      "taskUpdates": [
        {
          "entityId": this.apptInfo ? this.apptInfo.entityId : this.selectedPatient.entityid,
          "facilityId": taskDetail?.facilityId,
          "patientId": this.apptInfo ? this.apptInfo['patientId'] : this.selectedPatient['uhid'],
          "taskId": taskDetail.taskId,
          "taskSeqNo": taskDetail.taskSeqNo,
          "currentStatus": taskDetail.currentStatus,
          "newStatus": '',
          "updatedBy": this.user.getPersonId(),
          "updatedDateTime": this.user.getMoment().format(),
          "linkedNoteId": form?.noteCode || null,
          "linkedNoteContent": form?.noteContent || null,
          "outcomes": outcomeData || null,
          "taskdtlascnno": taskDetail.taskdetailacsnno,
          "encounterSeq": taskDetail.encSeq
        }
      ]
    }
    if (type == 'submit') {
      body.taskUpdates[0].newStatus = newStatus;
    } else if (type == 'save') {
      body.taskUpdates[0].newStatus = taskDetail.currentStatus;
    }
    this.onspotService.updateTaskStatus(body).subscribe(data => {
      if (data.status == 'SUCCESS' && (this.apptInfo || this.confirmAppt)) {
        this.gtmService.pushGTMEvent('Instant Consultation', 'consultation_started', { 'booking_no': this.apptInfo && this.apptInfo['response'] ? this.apptInfo['response']['apptRefNumber'] : null });
        this.user.openLocation(this.appointmentInfo['joinUrl'] + "&source=px&lan=" + this.user.currentLanguage);
        if (this.timeOut)
          clearTimeout(this.timeOut);
        this.close.emit();
      }
    })
    console.log('body', body);

  }

  public getAppointmentDetails(apptInfo) {
    console.log("apptInfo", apptInfo)
    const payload = {
      facilityId: apptInfo['facilityId'],
      refNo: apptInfo['response']['apptRefNumber'],
    }

    this.onspotService.getAppointmentsByRefNo(payload).subscribe(data => {
      if (data['status'] == 'SUCCESS') {
        this.appointmentInfo = data['data'];
        this.completeTaskAndCheckIn();
      }
    });

  }

  public getConfirmApptDetails(facilityId, reference) {

    const payload = {
      facilityId: facilityId,
      refNo: reference,
    }

    this.onspotService.getAppointmentsByRefNo(payload).subscribe(data => {
      if (data['status'] == 'SUCCESS') {
        this.appointmentInfo = data['data'];
        if (this.timeOut)
          clearTimeout(this.timeOut);
        this.completeTaskAndCheckIn();
      }
    });

  }

  public checKTransactionstatus(data, params) {
    if (data.status == 'S') {
      if (data.requestType == 'APPTBK') {
        console.log(data);
        this.state = 'REDIRECT'
        this.getAppointmentDetails(data);
        this.gtmService.pushGTMEvent('Instant Consultation', 'payment', { payment_status: 'Payment Success', value: +params['cost'], booking_no: data['response']['apptRefNumber'] });
      }
    }
    else {
      let value = this.user.getStorage(AppConstant.TASK_LIST);
      this.taskToComplete = JSON.parse(value);
      var form = {
        noteCode: this.taskToComplete['noteCode'],
        noteContent: this.taskToComplete['noteContent']
      }
      this.updateTask(this.taskToComplete, form, 'REJECTED', null, 'submit');
      this.gtmService.pushGTMEvent('Instant Consultation', 'cancel_request', { 'screen': 'Waiting Screen' });
      this.state = 'CHOOSE_SPECIALITY';
      this.close.emit();
    }
  }

  public closeModel() {
    if (this.state == 'ON_ACCEPT' || this.state == 'PAYMENT_CHECKOUT') {
      let form = {
        noteCode: this.taskToComplete['noteCode'],
        noteContent: this.taskToComplete['noteContent']
      }
      this.updateTask(this.taskToComplete, form, 'REJECTED', null, 'submit');
      this.gtmService.pushGTMEvent('Instant Consultation', 'cancel_request', { 'screen': this.state == 'ON_ACCEPT' ? 'Accepted Screen' : 'Payment Screen' });
    }
    if (this.eventInfo && !['ON_ACCEPT', 'PAYMENT_CHECKOUT'].includes(this.state)) {
      this.cancelRequest();
    }
    clearInterval(this.checkTaskInterval);
    this.close.emit();
  }

  public payForAppt() {
    this.isPaying = true;
    this.payType = 'C'
    this.currency_code = this.apptInfo['currency'];
    this.cost = Number(this.apptInfo['amount']).toFixed(2)
    this.facilityId = this.apptInfo['facilityId']
    let practitioner = this.user.findPractitioner(this.apptParams['f'], this.apptParams['p']);
    let patient = this.user.findLinkedPatient(this.apptParams['h']);

    var payload = {
      linkedPersonId: this.apptInfo['linkedPersonId'],
      patientId: this.apptInfo['patientId'],
      amount: Number(this.apptInfo['amount']).toFixed(2),
      currency: this.apptInfo['currency'],
      episodeType: 'R',
      encounterId: '0',
      facilityId: this.apptInfo['facilityId'],
      requestType: 'APPTBK',
    };

    console.log("payForAppt payload", payload)
    let request = {
      resourceId: this.apptParams['p'],
      patientPersonId: this.apptInfo['linkedPersonId'],
      uhid: this.apptInfo['patientId'],
      facilityId: this.apptInfo['facilityId'],
      personName: patient.personname,
      dob: this.user.getMoment(patient.dob).format("YYYY-MM-DD"),
      gender: patient.gender,
      localeCode: this.user.currentLanguage,
      practitionerName: practitioner.resourceName[0].name,
      specialityName: this.specialityInfo['specDesc'],
      specialityCode: this.specialityInfo['specCode'],
      patientName: patient.personname,
      discount: null,
      visitType: 'VIDEO',
      autoApptFlagYn: 'Y',
      overlapYN: this.apptOverlap,
      chargeStr: this.chargeStr,
      taskId: this.eventInfo['task_ascn_no'],
      taskGroup: this.eventInfo['task_group_code'],
      eventId: this.eventInfo.event_ascn_no,
      conceptCode: this.eventInfo.concept_code,
    }

    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      console.log("environment.DEFAULT_EMAIL", environment.DEFAULT_EMAIL);
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.apptParams['h'],
        email: environment.DEFAULT_EMAIL || null,
        mobile_no: ''
      }

      this.socket.onspotPatientEmit({ token: this.cookie.getStorage(AppConstant.ACCESS_TOKEN), state: 'START_TRANSACTION' });

      let url = window['location'];
      let transaction_id = response['transaction_id'];
      let payment = true;
      let method = 'APPTBK';
      let retry = `pt=${this.payType}&cost=${this.cost}`;

      let visitType = 'VCN';
      let retryJson = JSON.stringify(retry);
      let params = `orderid=${transaction_id}&f=${this.facilityId}&p=${this.apptParams['p']}&h=${this.apptInfo['patientId']}&v=${visitType}&${retry}`

      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=APPTBK&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() || this.currency_code,
        charge: this.cost,
        paymentgatewayurl: this.paymentGatewayUrl
      }
      console.log("paymentInfo", paymentInfo)
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        let val = { name: 'openPaymentCheckout' }
        this.openPayment(val);

      })
    })


  }

  public flip() {
    this.isFlip = !this.isFlip;
    console.log("isFlip", this.isFlip)
  }

  public checkIfTaskCreated() {
    const payload = {
      facilityId: this.eventInfo['facility_id'],
      taskGroup: this.eventInfo['task_group_code'],
      taskId: parseInt(this.eventInfo['task_ascn_no'])
    }
    this.onspotService.getTaskStatusForVC(payload).subscribe(res => {
      if (!res || res['taskCode'] !== 'TASK2' || res['assignedTo'] !== this.selectedPatient['uhid'])
        return;
      clearInterval(this.checkTaskInterval);

      this.taskToComplete = null;
      this.onspotService.getTaskInfo(payload).subscribe(data => {
        console.clear();
        console.log(data);
        this.isRequesting = false;
        if (data['taskGroup'] && data['taskGroup']['tasks']) {
          this.taskToComplete = data['taskGroup']['tasks'].find(t => t['taskCode'] == 'TASK2' && t['taskState'] !== 'NOT_ASSIGNED');
          console.log(this.taskToComplete);
          if (!this.taskToComplete)
            return;
          let task1 = data['taskGroup']['tasks'].find(t => t['taskCode'] == 'TASK1');
          this.practInfo = {
            practId: task1['assignedTeamId'],
            practName: task1['assignedTo']
          }
          if (task1['specialtyDetails']) {
            this.specialityInfo = {
              specCode: task1['specialtyDetails']['specialtyCode'],
              specDesc: task1['specialtyDetails']['specialtyDesc']
            }
          }
          console.log('CHECK 1', task1)
          if (task1['noteContent'] && task1['noteContent']['topics']) {
            let topics = task1['noteContent']['topics'];
            topics.forEach(t => {
              t['sections'].forEach(s => {
                switch (s['domainCode']) {
                  case 'OSFAC':
                    console.log('OSFAC', s['selected'], this.facilityId)
                    this.facilityId = s['selected'] ? s['selected'] : this.facilityId;
                    console.log('OSFAC', s['selected'], this.facilityId)
                    break;
                  default:
                    break;
                }
              });
            });
          }
          // this.getHispractChargeForAppt(this.facilityId);
          this.taskToComplete['taskId'] = this.eventInfo['task_ascn_no'];
          console.log("checkIfTaskCreated", this.taskToComplete);
          this.taskToComplete = data['taskGroup']['tasks'].find(t => t['taskCode'] == 'TASK2');
          console.log(this.taskToComplete);
          clearTimeout(this.timeOut);
          this.user.setStorage(AppConstant.TASK_LIST, JSON.stringify(this.taskToComplete));
          this.teamStatus['practInfo'] = this.practInfo;
          this.teamStatus['specialityInfo'] = this.specialityInfo;
          this.teamStatus['facilityId'] = this.facilityId;
          this.state = 'ON_ACCEPT'
          this.gtmService.pushGTMEvent('Instant Consultation', 'request_accepted');
        }
      }, err => {
        this.isRequesting = false;
      });
    })

  }

  public getHispractChargeForAppt(facilityId) {
    let body = [{
      apptDate: this.user.getMoment().format('YYYY-MM-DD'),
      apptRefNo: null,
      facilityId: facilityId,
      practitionerId: this.practInfo['practId'],
      patientId: this.selectedPatient['uhid'],
      visitType: 'VIDEO',
      onSpotConsult: 'Y',
      clinicCode: null,
    }]
    console.log('getVisitCharge', body)
    this.apptService.getVisitCharge(body).subscribe(response => {
      let data = response[0]
      this.chargeStr = data['charge']
      this.consultationCost['charge'] = response[0]['patientPayableAmount']
      this.consultationCost['charge'] = this.user.fixDecimalPlaces(this.consultationCost['charge']);
      if (response[0]['discount']) {
        this.consultationCost['applyPromoDiscYn'] = response[0]['discount']['discountApplicable']
      }
    })
  }

  public parseAppts() {
    const appts = this.ongoingAppts.filter(a => a.isOnspot && (!['FINISH', 'CANCEL'].includes(a['consultationStatus']) && this.allowJoin(a))),
      grpedByPatients = _.values(_.groupBy(_.orderBy(appts, (a) => { moment(a['appointmentDateTime'], 'DD-MM-YYYY HH:mm').valueOf() }, 'desc'), 'patientId'));
    grpedByPatients.forEach(g => {
      let pat = this.linkedPatients.find(p => p['uhid'] == g[0]['patientId']);
      if (pat)
        pat['_appt'] = g[0];
    })
  }

  public chooseApptToProceed(patient) {
    this.selectedPatientWithAppt = patient;
  }
  public allowJoin(apt) {
    this.user.getUpdateInfo('facilities').subscribe(data => {
      this.facilityList = data.facilities;
    })
    this.facilityList = this.facilityList.filter(item => {
      return item['facilityId'] == apt['facilityId']
    })
    var policy = this.facilityList[0];
    if (policy && policy.facilityControls && policy.facilityControls.schedulingRules) {
      var rules = policy.facilityControls.schedulingRules;
      if (apt.isOnspot) {
        if (rules?.onspotConsult?.policy) {
          let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
          if (this.validateRule(dateTimeToCheck, rules.onspotConsult.policy, apt['facilityId'])) {
            return true;
          } else {
            return false;
          }
        }
      }
      else if (rules.videoConsult && rules.videoConsult.policy) {
        let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
        if (this.validateRule(dateTimeToCheck, rules.videoConsult.policy, apt['facilityId'])) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  public validateRule(dateTimeToCheck, txnType, facilityId) {

    var policy = txnType;
    let facilityTimezone = this.user.getFacilityTimezone(facilityId);
    var validate = true;
    var currDateTime = this.user.getMoment();

    var lowerRefDateTime = this.user.getMoment('1999-01-01');
    var upperRefDateTime = this.user.getMoment('2099-12-31');

    if (policy) {
      if (policy.minPeriod != "") {
        lowerRefDateTime = this.getRefDateTime(policy.minPeriod, policy.minPeriodUnit, policy.minPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
      if (policy.maxPeriod != "") {
        upperRefDateTime = this.getRefDateTime(policy.maxPeriod, policy.maxPeriodUnit, policy.maxPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
    }

    if ((currDateTime.isBefore(lowerRefDateTime)) || (currDateTime.isAfter(upperRefDateTime))) {
      validate = false;
    }

    return validate;
  }
  public getRefDateTime(period, unit, direction, dateTimeToCheck, timezone) {
    var refDateTime = this.user.getMoment(dateTimeToCheck, null, null, timezone);

    if (unit == "H") {
      if (direction == "BEFORE") {
        refDateTime.subtract(parseInt(period), 'hours');
      }
      else if (direction == "AFTER") {
        refDateTime.add(parseInt(period), 'hours');
      }
    }
    else if (unit == "M") {
      if (direction == "BEFORE") {
        refDateTime.subtract(parseInt(period), 'minutes');
      }
      else if (direction == "AFTER") {
        refDateTime.add(parseInt(period), 'minutes');
      }
    }
    else if (unit == "D") {
      if (direction == "BEFORE") {
        refDateTime.subtract(parseInt(period), 'days');
      }
      else if (direction == "AFTER") {
        refDateTime.add(parseInt(period), 'days');
      }
    }
    return refDateTime;

  }

  public proceedOngoingAppt() {
    const url = this.selectedPatientWithAppt?._appt?.joinUrl;
    this.user.openLocation(url + "&source=px&lan=" + this.user.currentLanguage);
  }

  public setSelectedPatient(pat) {
    this.selectedPatient = pat
    this.selectedPatientWithAppt = null;
  }

  public navigateToPreviousTopic() {
    switch (this.state) {
      case 'ON_AVAILABLE':
      case 'ON_ACCEPT':
        this.cancelRequest();
      case 'CHOOSE_PATIENT':
        this.state = 'CHOOSE_SPECIALITY'
        break;
      case 'PAYMENT_CHECKOUT':
        this.state = 'ON_ACCEPT';
        break;
      case 'ON_BUSY':
      case 'ON_UNAVAILABLE':
      case 'ON_CONSULTATION':
      case 'ON_QUEUE':
        this.cancelRequest();
        this.state = 'CHOOSE_SPECIALITY';
        break;
      case 'REDIRECT':
      case 'FAIL_PAY':
        this.state = 'CHOOSE_SPECIALITY';
        break;
    }
  }

  public choosePat(item) {
    item['_appt'] ? this.chooseApptToProceed(item) : this.setSelectedPatient(item)
  }

  public refreshSpeciality() {
    this.getSpecialityList()
  }

  private getSpecialityList(deepLinkParams: any = null) {
    this.isGettingSpeciality = true;
    this.apptService.getSpecialitiesForOnspot().subscribe(async (data) => {
      if (!data || data.length == 0) this.noSpeciality = true
      data = data.map(d => {
        if (!d.available) {
          d.isFetchingAvailability = true;
        }
        return d;
      })
      this.groupSpeciality(data)
      this.isGettingSpeciality = false;
      this.noSpeciality = false
      console.log('deepLinkParams', deepLinkParams)
      if (deepLinkParams && deepLinkParams.specialityId) {
        let item = data.find(d => d.specialityId == deepLinkParams.specialityId)
        if (item && item.available) {
          this.chooseSpeciality(item)
        } else {
          // let toast = await this.toastController.create({
          //   message: this.translatePipe.transform('DEEPLINK#SPEC#NOT#AVAILABLE', null, null, null),
          //   position: 'bottom',
          //   duration: 3000
          // });
          // toast.present()
        }
      }
      this.getAvailabilityForUnavailable(data)
    }, err => {
      this.specialityList = [];
      this.noSpeciality = true
      this.isGettingSpeciality = false;
    })
  }

  private groupSpeciality(data) {
    let generalOnspot = data.filter(s => s.isGeneralSpeciality == 'Y')
    let specOnspot = data.filter(s => s.isGeneralSpeciality != 'Y')
    specOnspot = specOnspot.sort((a,b) => {
      if(a.available === true && b.available === false) return -1;
      else if(a.available === false && b.available === true) return 1;
      else return 0;
    })
    this.specialityList = [
      {
        title: '',
        specialities: generalOnspot
      }, {
        title: 'CONSULTNOW#SPEC#SUBTITLE#OTHERSPEC',
        specialities: specOnspot
      }
    ]
    console.log('specialityList', this.specialityList);
  }

  private getAvailabilityForUnavailable(data) {
    let specialities = data.filter(d => !d.available).map(d => {
      return {
        specialityId: d.specialityId,
        facilityId: d.facilityId
      }
    })
    if (!specialities || specialities.length == 0) return
    this.apptService.getNextAvailablityForSpeciality(specialities).subscribe(availability => {
      availability.forEach(item => {
        let index = data.findIndex(s => s.specialityId == item.specialityId && s.facilityId == item.facilityId)
        if (index > -1) {
          if (item.unAvailable == 'Y') {
            data[index].unAvailable = 'Y'
          } else {
            data[index].nextAvailableDttm = item.nextAvailable
            data[index].lastAvailableDttm = item.endTime
            data[index].translation = {
              nextAvailable: moment(item.nextAvailable, 'YYYY-MM-DD HH:mm').format('DD MMM, hh:mm a'),
              lastAvailable: moment(item.endTime, 'YYYY-MM-DD HH:mm').format('DD MMM, hh:mm a')
            }
          }
          data[index].isFetchingAvailability = false
        }
      })
      this.groupSpeciality(data)
    })
  }

  public chooseSpeciality(speciality) {
    console.log('choosen speciality', speciality)
    if (!speciality.available) return;
    this.selectedSpeciality = speciality
    if (speciality.facilityId)
      this.facilityId = speciality.facilityId
    this.getConcepts(this.facilityId).subscribe();
  }
}


