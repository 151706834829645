import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkedPatientsComponent } from './linked-patients.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { PatientListModule } from '../patient-list/patient-list.module';

@NgModule({
  declarations: [LinkedPatientsComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, PersonAvatarModule, RegisterPatientModule, CustomModalModule,
    PractitionerAvatarModule,
    ManageIdentitiesModule, PatientListModule
  ],
  
  exports: [LinkedPatientsComponent]
})
export class LinkedPatientsModule { }
