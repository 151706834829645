import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnInit {
  public sample: any;
  

  ngOnInit() {
    this.sample = [
      {
        icon: 'assets/icons/form.svg',
        title: 'Complete bowel frequency chart',
        time_icon: 'assets/icons/calendar-timer.svg',
        time: 'Today, 4:45pm',
        action:'Mr James Williams'
      },
      {
        icon: 'assets/icons/form-group.svg',
        title: 'Cardiology consult questionnarie',
        time_icon:'assets/icons/doctor.svg',
        time: 'Dr Martin Abasto',
        action: 'Mr Gaspar Antunes'
      },
      {
        icon: 'assets/icons/star.svg',
        title: 'Complete bowel frequency chart',
        time_icon: 'assets/icons/doctor.svg',
        time: 'Dr Amish Shiravadakar',
        action: 'Mr Ray Cooper'
      }
    ]
  }

}
