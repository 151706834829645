<div id="register-patient" class="nav">
  <div (click)="close()" class="empty"></div>
  <div class="modal1">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">Map view</div>
      <div class="float-right closeicon pointer" (click)="close()"><img src="./assets/icons/close_black.svg"></div>
    </div>
    <div class="content">
      <div class="google-map">
        <google-map
          width="100%"
          height="100%"
          [zoom]="mapZoom"
          [options]="mapOptions"
          [center]="mapCenter"
          #map
          *ngIf="false"
        >
          <map-marker
            #markerElem
            *ngFor="let marker of markers"
            [position]="marker.position"
          >
          </map-marker>
        </google-map>
        <div class="fetching-location-overlay"
          *ngIf="!showDirection || showAlert">
          <div class="fetch-location-card">
            <ng-container *ngIf="locationError && !locationError['status']">
              <div class="loader"></div>
              {{routeItem['homecareActivityDetails'] && routeItem['homecareActivityDetails']['status']? ('hc_map_'+routeItem['homecareActivityDetails']['status'].toLowerCase()  | translate):'hc_map_unknown'}}
            </ng-container>
            <ng-container *ngIf="!locationError || (locationError && locationError['status'])">
              {{'hc_map_'+locationError['value'].toLowerCase()|translate }}
            </ng-container>
          </div>
        </div>
        <div id="map" style="width: 100%;height: 100%;"></div>
      </div>
      <div class="person-details">
        <div class="service-provider">
          <div class="image">
            <app-person-avatar [url]=""> </app-person-avatar>
          </div>
          <div class="name-detail">
            <div class="name">
              <ng-container *ngIf="routeItem['practitionerLocalName']">
                {{routeItem['practitionerLocalName'] | translate : 'name'}}
              </ng-container>
              <ng-container *ngIf="!routeItem['practitionerLocalName'] && routeItem['practitionerName']">
                {{routeItem['practitionerName']}}
              </ng-container>
              <ng-container *ngIf="!routeItem['practitionerLocalName'] && !routeItem['practitionerName']">
                {{(routeItem['resourceId']|partitionerDetails:routeItem['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || 'Doctor'}}
              </ng-container>
            </div>
            <div class="position">
              {{routeItem['resourceId']|partitionerDetails:routeItem['facilityId']:'specialityId' | specialityDetails:routeItem['facilityId']:'specialityName'| translate : 'LOCALE' : 'name'}}
            </div>
          </div>
        </div>
        <div class="time add-info">
          <img src="./assets/icons/homecare/clock.svg" alt="">
          {{routeItem['start']|moment}}
        </div>
        <div class="duration add-info" *ngIf="actualduration">
          <img src="./assets/icons/homecare/car.svg" alt="">
          {{actualduration}}
        </div>
        <div class="status add-info" *ngIf="routeItem['homecareActivityDetails']">
          <img src="./assets/icons/homecare/cursor.svg" alt="">
          <div>
            {{routeItem['homecareActivityDetails'] && routeItem['homecareActivityDetails']['status']?('hc_consult_'+routeItem['homecareActivityDetails']['status'].toLowerCase()  | translate):'hc_consult_unknown'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>