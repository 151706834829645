import * as moment from 'moment';
import { months } from './months';
import { hours } from './hours';
import { minutes } from './minutes';
import { meridian } from './meridian';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { Optional } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
export class Type {
    public readonly Array = Array;
    constructor(@Optional() private cfs: ClinicalformService) {
    }

    checkForMandatory(section: any) {
        if (section['mandatory'] === 'false' || section['completed'] === true) {
            section['completed'] = true;
        } else {
            section['completed'] = false;
        }
        this.changeInValues();
    }

    listenToInput(value, section) {
        if (value === '' && section['mandatory'] === 'true') {
            section['completed'] = false;
        } else if (value.length > 0) {
            section['selected'] = value;
            section['completed'] = true;
        }
        this.changeInValues();
    }

    decideCheckBoxType(section: any) {
        // switch (section['multiSelect']) {
        //     case 'false':
        //         section['trueType'] = 'radio';
        //         break;
        //     default:
        //         section['trueType'] = 'checkbox';
        //         break;

        // }
        switch (section['codify']) {
            case 'Y':
                if (section['domainControls']['multiSelect'] || section['multiSelect']) {
                    section['trueType'] = 'checkbox';
                }else{
                    section['trueType'] = 'radio';
                }
                break;
            default:
                if (section['multiSelect']) {
                    section['trueType'] = 'checkbox';
                }else{
                    section['trueType'] = 'radio';
                }
                break;
        }
        if (!section['checked']) {
            section['checked'] = [];
        }
    }

    complete(section) {
        section['completed'] = true;
        this.changeInValues();
    }

    incomplete(section) {
        section['selected'] = '';
        this.checkForMandatory(section);
    }

    getValuesforSection(section) {
        switch (section['codify']) {
            case 'Y':
                section['options'] = section['domainControls']['answer'];
                break;
            default:
                section['options'] = section['answer'];
                break;
        }
    }

    decideSelectSection(section) {
        switch (section['codify']) {
            case 'Y':
                section['options'] = section['domainControls']['answer'];
                if (section['domainControls']['multiSelect'] === true ||
                    section['domainControls']['multiSelect'] === 'true' ||
                    section['multiSelect'] === true ||
                    section['multiSelect'] === 'true') {
                    section['trueType'] = 'checkBox';
                }
                section['trueType'] = 'radio';

                section['options'] = section['domainControls']['answer'];
                break;
            default:
                if (section['multiSelect'] === true ||
                    section['multiSelect'] === 'true') {
                    section['trueType'] = 'checkBox';
                }
                section['trueType'] = 'radio';
                section['options'] = section['answer'];
                break;
        }
    }

    decideToggleSection(section) {
        switch (section['codify']) {
            case 'Y':
                section['toggle'] = section['domainName'] || section['domainCode'];
                break;
            default:
                section['toggle'] = section['question'][0]['text'];
                break;
        }
        section['selected'] = section['default'] || section['selected'];
        this.complete(section);
    }

    decideNumericSection(section) {
        switch (section.codify) {
            case 'Y':
                section['maxValue'] = section['domainControls']['maxValue'];
                section['minValue'] = section['domainControls']['minValue'] || null;
                section['stepCounter'] = section['domainControls']['stepCounter'] || 1;
                break;
            default:
                section['maxValue'] = section['maxValue'];
                section['minValue'] = section['minValue'] || null;
                section['stepCounter'] = section['stepCounter'] || 1;

                break;
        }
        section['selected'] = section['selected'];
        this.complete(section);
    }

    decideTimePeriod(section) {
        switch (section.codify) {
            case 'Y':
                section['maxValue'] = section['domainControls']['maxValue'] || 100;
                section['minValue'] = section['domainControls']['minValue'] || 1;
                section['stepCounter'] = section['domainControls']['stepCounter'] || 1;
                section['dropdownOptions'] = section['domainControls']['dropdownOptions'];
                break;
            default:
                section['maxValue'] = section['maxValue'] || 100;
                section['minValue'] = section['minValue'] || 1;
                section['stepCounter'] = section['stepCounter'] || 1;
                section['dropdownOptions'] = section['dropdownOptions'] || ['days', 'months'];
                break;
        }
        section['value'] = section['minValue'] || 1;
        let options = section['dropdownOptions'].filter(option => option['default'] == true);
        section['option'] = options.length > 0 ? options[0]['name'] : section['dropdownOptions'][0]['name'];

        if (section['selected']) {
            let option = section['selected'].toString().split(' ');
            section['value'] = option[0];
            section['option'] = option[1];
        }

        if (section['option'] && section['value']) {
            section['selected'] = section['value'] + ' ' + section['option'];
            this.complete(section);

        }
    }

    decideRating(section) {
        section['range'] = new Array(section['maxValue']);
        section['selected'] = section['selected'] || 0;
        this.complete(section);
    }

    decideDate(section) {
        this.getCalenderDate(section);
        this.getDays(section);
        this.getSelectedDate(section);
        this.complete(section);
    }

    getCalenderDate(section) {
        const date = this.getMoment();
        section['minYear'] = date.year();
        section['maxYear'] = section['minYear'] + 5;
        section['years'] = this.range(section['minYear'], section['maxYear']);
        section['months'] = months['default'];
        section['month'] = date.format('MMMM');
        section['year'] = date.year();
        section['day'] = date.date();
    }

    decideTime(section) {
        const date = this.getMoment();
        section['hours'] = hours;
        section['minutes'] = minutes;
        section['meridians'] = meridian;
        section['hour'] = date.hour() > 12 ? date.hour() - 12 : date.hour();
        section['minute'] = date.minutes();
        section['meridian'] = date.format('a').toUpperCase();

    }

    getDays(section) {
        console.log(section)
        const days = this.getMoment(section['year'] + '-' + section['month'], 'YYYY-MMMM').daysInMonth();
        section['days'] = this.range(1, days);
    }

    getSelectedDate(section) {
        section['selected'] = this.getMoment(section['day'] + '-' + section['month'] + '-' + section['year'], 'DD-MMMM-YYYY');

    }

    getSelectedDateTime(section) {
        section['selected'] = this.getMoment(section['day'] + '-' +
            section['month'] + '-' + section['year'] + ' ' +
            section['hour'] + ' ' + section['minute'] + ' ' +
            section['meridian'], 'DD-MMMM-YYYY hh mm a');
    }

    getSelectedTime(section) {
        section['selected'] = this.getMoment(section['hour'] + ' ' + section['minute'] + ' ' + section['meridian'], 'hh mm a');
    }

    selectTommorrow(section) {
        const date = this.getMoment().add(1, 'days');
        section['month'] = date.format('MMMM');
        section['year'] = date.year();
        section['day'] = date.date();
        this.getDays(section);
    }

    range(start, end) {
        const arr = [];
        for (let i = start; i <= end; i++) {
            arr.push(i);
        }
        return arr;
    }
    setDataForSelect(section){
        var selectTypeArray=[],typeObj;
        for(var i=0;i<section['options'].length;i++){
            typeObj={
                item_id:i+1,
                item_text:section['options'][i].localName[0].text
            }
            selectTypeArray.push(typeObj)
        }
        section['selectTypeArray']=selectTypeArray
    }

    fillDomainCodeValue(sections,domainValues){
        sections.map(s=>{
            if(s.domainCode && domainValues[s.domainCode]){
                s['selected'] = domainValues[s.domainCode]
            }
        })
    }

    clearDomainCodeValue(sections,domainValues){
        sections.map(s=>{
            if(s.domainCode && s.domainCode in domainValues){
                s['selected'] = domainValues[s.domainCode]
            }
        })
    }
    changeInValues() {
        this.cfs.changeInValues()
    }
    public getMoment(val?, format?) {
        if (format) {
            return moment(val, format).locale('en');
        } else if (val) {
            return moment(val).locale('en');
        } else {
            return moment().locale('en')
        }
    }
}
