<ng-container *ngIf="input == 'COUPON'">
  <div id="coupon-input" *ngIf="type == 'SELECT'">
    <div class="set-row">
      <label class="set-column col-size-12">
        <p class="title-label">{{"COUPON#LABEL#TITLE#COUPONCODE" | translate}}</p>
      </label>
    </div>
    <button class="choose-coupon-btn bold" *ngIf="!appliedCoupons || !appliedCoupons.appliedcouponcodedtls || appliedCoupons.appliedcouponcodedtls.length ==0" size="block" fill="outline" (click)="openCoupons()">
      {{"COUPON#LABEL#SELECT#COUPONCODE" | translate}}
    </button>
    <div *ngIf="appliedCoupons && appliedCoupons.appliedcouponcodedtls && appliedCoupons.appliedcouponcodedtls.length > 0" (click)="openCoupons()" class="set-card coupon-card">
      <div class="set-item">
        <label>
          <p class="coupon-amounts">
            <span *ngFor="let coupon of appliedCoupons.appliedcouponcodedtls; let last=last">
              <app-display-currency [amount]="coupon.cashDiscount" theme="black" [amountSize]="'small'"
                [suffix]="last? ('coupon_suffix_applied' | translate): ','"></app-display-currency>
            </span>
          </p>
        </label>
        <img src="./assets/icons/close_black.svg" (click)="$event.stopPropagation; removeAllCoupons(false)">
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="input == 'POINTS'">
  <div id="points-input" *ngIf="type == 'SELECT'">
    <button class="choose-coupon-btn" *ngIf="!appliedPoints || !appliedPoints.pointsDerivative || !appliedPoints.pointsDerivative.amount" size="block" fill="outline" (click)="openPointsModal()">
      <img src="./assets/icons/nudheek-icon.svg" class="nudheek-icon">
      {{"POINTS#LABEL#SELECT#POINTS" | translate}}
    </button>
    <div class="set-item choose-coupon-btn" *ngIf="appliedPoints && appliedPoints.pointsDerivative && appliedPoints.pointsDerivative.amount > 0" (click)="openPointsModal()">
      <div>
        <img src="./assets/icons/nudheek-icon.svg" class="nudheek-icon">
        <label>
          <p>{{"POINTS#LABEL#APPLIED#POINTS" | translate : 'LABELS' : null:
            null:appliedPoints.pointsDerivative}}</p>
        </label>
      </div>
      <img src="./assets/icons/close_black.svg" (click)="$event.stopPropagation; removeAllPoints(false)" alt="">
    </div>
  </div>
</ng-container>

<app-altpayment-modal *ngIf="showCouponModal" [couponProps]="couponProps" (complete)="completeCoupon($event)"></app-altpayment-modal>
<app-altpayment-modal *ngIf="showPointsModal" [pointsProps]="pointsProps" (complete)="completePoints($event)"></app-altpayment-modal>