<p class="section-title">{{'Selected Doctor' | translate}}</p>
<div class="doctor-card">
    <div class="doctor-card-main">
        <div class="image">
            <app-person-avatar [url]="selectedPract['photoUrl']" [name]="selectedPract['practitionerName']">
            </app-person-avatar>
        </div>
        <div class="doctor-card-content">
            <p class="doc-name">{{selectedPract['practitionerName']}}</p>
            <p class="doc-text">{{selectedPract['clinicName']}}</p>
            <ng-container *ngIf="isAvailable(selectedPract); else nextAvailableTemp">
                <p class="doc-text">{{'Currently_Available' | translate}}</p>
            </ng-container>
            <ng-template #nextAvailableTemp>
                <p class="doc-text" *ngIf="selectedPract['nextAvailableTime']">{{'Next_available_in' | translate}} {{getNextAvailableTime(selectedPract)}}</p>
                <p class="doc-text" *ngIf="!selectedPract['nextAvailableTime']">{{'Currently_Unavailable' | translate}}</p>
            </ng-template>
        </div>
    </div>
    <div class="doc-card-bottom">
        <p class="bottom-text">
            <img src="assets/icons/calendar/cash_blue.svg" alt="">
            {{visitCharges['currency']}} {{visitCharges?.patientPayable || 0}}
        </p>
        <p class="bottom-text">
            <img src="assets/icons/pin.svg" alt="">
            {{facility?.facilityName}}
        </p>
    </div>
</div>
<p class="section-title">{{'Selected_Patient' | translate}}</p>
<div class="doctor-card">
    <div class="doctor-card-main">
        <div class="image">
            <app-person-avatar [url]="selectedPatient['photoUrl']" [name]="selectedPatient['patientName']">
            </app-person-avatar>
        </div>
        <div class="doctor-card-content">
            <p class="doc-name">{{selectedPatient['patientName']}}</p>
            <p class="doc-text">{{selectedPatient['patientId']}}, {{selectedPatient['gender'] == 'F'? 'Female':'Male'}},
                {{selectedPatient['dob'] | age}}</p>
        </div>
    </div>
</div>
<ng-container *ngIf="visitCharges['isFreeVisit'] != 'Y'">
    <p class="section-title">{{'Payment due' | translate}}</p>
    <div class="section-card payment-card">
        <div class="section-item">
            <p class="section-label">{{'Consultation_Fee' | translate}} </p>
            <p class="section-value">{{visitCharges?.currency}} {{visitCharges?.patientPayable || 0}}</p>
        </div>
    </div>
</ng-container>
<div class="section-button-wrapper">
    <button class="section-action" *ngIf="visitCharges['isFreeVisit'] != 'Y'" (click)="initPayment()">{{'Proceed_to_Pay' | translate}}</button>
    <button class="section-action" *ngIf="visitCharges['isFreeVisit'] == 'Y'" (click)="createFollowupVisit()">{{'Proceed_to_Create_visit' | translate}}</button>
</div>