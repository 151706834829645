<div id="company-description">
  <div class="card">
    <ngb-carousel [showNavigationArrows]="false" [interval]="5000" [wrap]="true">
      <ng-template ngbSlide *ngFor="let item of slider;let i=index">
        <div (click)="openRoute(item, i)">
          <div class="wrapper" >
            <img class="type-image" [src]="item.imageUrl">
          </div>
          <div class="content">
            <div class="font-white-small">{{item.text}}</div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
