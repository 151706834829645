import { Component, OnInit, Input } from '@angular/core';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideToggleSection(this.section);
  }

  toggle() {
    this.section['selected'] = !this.section['selected'];
  }

}
