<div class="patient-list-wrapper">
  <div class="cards-wrapper">

  <div class="card row cursor-pointer" *ngFor="let item of linkedPatients;let index=index" 
    [ngClass]="{active: (selectedPatient && item.personid == selectedPatient.personid)}" (click)="selectPatient(item)">
    <div class="image">
      <app-person-avatar [url]="item.imageurl"> </app-person-avatar>
    </div>
    <div class="card-content">
      <div class="title bold">{{item.personname}}</div>
      <div class="subtitle"> 
        {{item.gender}},
        {{item.dob | age}},
        <ng-container *ngIf="!linkWithoutRelation">,
          {{item.relationshiptype | translate : 'RELATION'}},
        </ng-container>
        {{item.uhid}}
      </div>
    </div>
    <div class="more-icon" [id]="'linked-patients-'+index" >
      <span>
        <img class="type-image" src="./assets/icons/manage-account/More-icon.svg"
          (click)="triggerMoreOptions(item);openPopover(item, true, true, true, true);$event.stopPropagation()">
      </span>
    </div>
    <div class="more-user-details" *ngIf="item['_openOptions']"  (clickOutside)="item['_openOptions']=false" [triggerElementId]="'linked-patients-'+index">

      <div class="card1 row cursor-pointer" (click)="changePhoto();$event.stopPropagation()">
        <div class="image">
          <img src="./assets/icons/manage-account/photo.svg">
        </div>
        <div class="card-content">
          <div class="title">
            {{ "Change_photo" | translate}}
          </div>
        </div>
      </div>

      <div class="card1 row cursor-pointer" (click)="updatedRelationship(item);$event.stopPropagation()">
        <div class="image">
          <img src="./assets/icons/manage-account/relationship.svg">
        </div>
        <div class="card-content">
          <div class="title">
            {{ "Update_relationship" | translate}}
          </div>
        </div>
      </div>

      <!-- manage insurance is commented -->
      <!-- <div class="card1 row cursor-pointer">
      <div class="image">
        <img src="./assets/icons/manage-account/manage_insurance.svg">
      </div>
      <div class="card-content">
        <div class="title" >
          {{ "Manage_my_insurance" | translate}}
        </div>
      </div>
    </div> -->
      <div class="card1 row cursor-pointer" (click)="editPatient(item);$event.stopPropagation()">
        <div class="image">
          <img src="./assets/icons/health-package/edit.svg">
        </div>
        <div class="card-content">
          <div class="title">
            {{ "Edit_patient" | translate}}
          </div>
        </div>
      </div>

      <div class="card1 row cursor-pointer" (click)="chooseOption('UNLINK');$event.stopPropagation()">
        <div class="image">
          <img src="./assets/icons/manage-account/remove.svg">
        </div>
        <div class="card-content">
          <div class="remove-title">
            {{ "Remove_patient" | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="add-a-member-options" *ngIf="addMemberModal" 
id="choose-add-member-option" (clickOutside)="addMemberModal=false" [triggerElementId]="'add-member-manage-patients'">
  <div class="card1 row cursor-pointer">
    <div class="image">
      <img src="./assets/icons/manage-account/add_user.svg">
    </div>
    <div class="card-content" [appRemoveFeatureOff]="'FT_APP_REGISTER_FAMILY_MEMBER'" (click)="openModal('register-patient')">
      <div class="title">
        {{ "Register_a_patient" | translate}}
      </div>
    </div>
  </div>
  <div class="card1 row cursor-pointer" [appRemoveFeatureOff]="'FT_APP_FIND_FAMILY_MEMBER'" (click)="openModal('find-record');linkSameNumber = true">
    <div class="image">
      <img src="./assets/icons/manage-account/link.svg">
    </div>
    <div class="card-content">
      <div class="title">
        {{ "Link_patient(same_mobile_number)" | translate}}
      </div>
    </div>
  </div>
  <div class="card1 row cursor-pointer" [appRemoveFeatureOff]="'FT_APP_FIND_FAMILY_MEMBER'" (click)="openModal('find-record')">
    <div class="image">
      <img src="./assets/icons/manage-account/link.svg">
    </div>
    <div class="card-content">
      <div class="title">
        {{ "Link_patient(different_mobile_number)" | translate}}
      </div>
    </div>
  </div>
</div>

  <div class="add-member-button-wrapper">
    <!-- As confirmed by sakthivel commented this button <button class="button-secondary" (click)="addMember()" host="choose-add-member-option" id="add-member-manage-patients">
      <img class="button-prefix" src="./assets/icons/manage-account/add_plain.svg">
      {{'Add_a_member'|translate}}
    </button> -->
    <button class="button-secondary" (click)="manageFamily()">
      {{'Manage_my_family'|translate}}
    </button>
  </div>
</div>
<app-register-new-patient *ngIf=registerPatient [editDetails]="editDetails" [isEdit]="isEdit" (close)="closeModal('register-new-patient')"></app-register-new-patient>
<app-update-relation-modal *ngIf="isUpdateRelation" [patient]="isUpdateRelation" (close)="closeUpdateRelation($event)"></app-update-relation-modal>
<!-- <app-link-other-mobile *ngIf="findOtherPatient" (initLinkPatients)="initLinkPatients($event)"
  (initAddPatients)="initAddPatients($event)"
  (close)="closeModal('find-record')" [source]="'HOME'"
  [linkedPatients]="linkedPatients" [prefillNationalId]="prefillNationalId">
</app-link-other-mobile> -->

<app-link-other-mobile *ngIf="findOtherPatient" (initLinkPatients)="initLinkPatients($event)"
  (initAddPatients)="initAddPatients($event)"
  (close)="closeModal('find-record')" [linkSameNumber]="linkSameNumber" [source]="'HOME'"
  [linkedPatients]="linkedPatients" [prefillNationalId]="prefillNationalId">
</app-link-other-mobile>

<app-custom-modal-container id="manage-identity-modal" (complete)="closeManageIdentities()">
  <app-manage-identities *ngIf="isManageIdentities" (updateLinkedPatient)="setLinkedPatients()">
  </app-manage-identities>
</app-custom-modal-container>