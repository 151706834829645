import { Component, OnInit, Input } from '@angular/core';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
import { PhoneNumberUtil } from "google-libphonenumber";
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-numericinteger',
  templateUrl: './numericinteger.component.html',
  styleUrls: ['./numericinteger.component.scss'],
  providers:[TranslatePipe]
})
export class NumericintegerComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public route;
  @Input() public frequency;
  @Input() public tabindex;
  public minValue: number;
  constructor(cfs: ClinicalformService, private translate: TranslatePipe) {
    super(cfs);
  }

  ngOnInit() {
    if (this.section.codify === 'Y' && this.section.domainCode === 'PHNO' && !this.section['selected'])
      this.section['selected'] = '+91';
    super.checkForMandatory(this.section);
    super.decideNumericSection(this.section);
    if (this.section['minValue'])
      this.minValue = this.section['minValue'];
    else
      this.minValue = 0;

    if(this.section['_validationErr'] && this.section['selected']) {
      let hasValidationError = this.validator(this.section['selected'], this.minValue, this.section['maxValue'], this.section.stepCounter);
      if(hasValidationError)
        this.section['_validationErr'] = hasValidationError;
    }
  }
  change(value) {
    if (value <= this.minValue) {
      this.section['selected'] = '';
    } else if (value > this.section['maxValue']) {
      let str = value.toString();
      str = str.slice(0, -1);
      value = parseInt(str)
      this.section['selected'] = value;
    }
    if (value > this.minValue && value <= this.section['maxValue']) {
      super.complete(this.section);
    }
  }
  checkMobile(mobile) {
    console.log('check ph ---->', mobile)
    this.checkIfPhoneNumber(mobile)
  }
  checkIfPhoneNumber = (phone: string) => {
    var self = this;
    try {
      var phoneUtil = PhoneNumberUtil.getInstance();
      var number = phoneUtil.parse(phone);
      console.log(number, phoneUtil.isValidNumber(number));
      if (phoneUtil.isValidNumber(number)) {
        this.section['notValid'] = false;
      }
      else
        this.section['notValid'] = true;
    } catch (error) {
      console.log(error);
    }
  };

  validator(value, min, max, step){
    if(!this.section.selected) return null;
    let err;
    if(min && max)
        err =  ((min <= value ) &&  (max >= value)) ? null : this.validatorErrMessage(value, min, max, step, 'MIN_MAX');
    else if(min)
        err = min <= value ? null : this.validatorErrMessage(value, min, max, step, 'MIN');
    else if(max)
        err = max >= value ? null : this.validatorErrMessage(value, min, max, step, 'MAX');

    if(err) return err;

    return this.stepValidator(value, min, max, step);
}

stepValidator = (value, min, max, step) => (step && value % step !==0) ? this.validatorErrMessage(value, min, max, step, 'STEP') : null ;

validatorErrMessage(value, min, max, step, errType){
  // switch(errType){
  //     case 'MIN_MAX':
  //         return `Value should be between %min% and %max%`;
  //يجب أن تكون القيمة بين %min% و %max%

  //     case 'MIN':
  //         return `Value should be greater than %min%`;
  //         يجب أن تكون القيمة أكبر من %min%
  //     case 'MAX':
  //         return `Value should be less than %max%`;
  //         يجب أن تكون القيمة أقل من %max%
  //     case 'STEP':
  //         return `Value should be a multiplier of %step%`;
  //         يجب أن تكون القيمة مضاعفًا لـ %step%

  //     default:
  //         break;
  // }
  let label = ''
  switch(errType){
    case 'MIN_MAX':
      label = `Value_should_be_between`;
      break;
    case 'MIN':
      label = `Value_should_be_greater_than`;
      break;
    case 'MAX':
      label = `Value_should_be_less_than`;
      break;
    case 'STEP':
      label = `Value_should_be_a_multiplier_of`;
      break;
    default:
      break;
  }
  let replace = [
    { key: 'min', with: min},
    { key: 'max', with: max},
    { key: 'step', with: step },
    { key: 'value', with: value },
  ]
  let message = this.translate.transform(label, 'LABELS', null, null, replace)
  return message;
}

  changeVal(ev){
    console.log(ev);
    let min = +ev.min, max = +ev.max, value = +ev.value, step = +ev.step;
    let hasValidationError = this.validator(value, min, max, step);
    if(hasValidationError)
        this.section['_validationErr'] = hasValidationError;
    else
        delete this.section['_validationErr'];

    console.log(this.section['_validationErr']);
  }

}
