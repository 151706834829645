<div id="service-request" class="right-navigation showModal" *ngIf="isServiceRequest">
    <div class="component">
        <div class="header border-bottom" [ngClass]="{'show-header':showHeader}">
            <div class="font-primary-subtitle bold service-request-header">{{'Service_requests' | translate}}</div>
            <div (click)="closeModal()" class="float-right closeicon pointer">
                <img src="./assets/icons/close_grey.svg">
            </div>
        </div>

        <div class="content">
            <ng-container *ngIf="serviceRequestData?.length > 0; else noData">
                <div class="service-request-section">

                    <div class="options" [ngClass]="{'show-components':showComponents}">
                        <div class="content-details" *ngFor="let data of serviceRequestData">
                            <label class="content-label-name">{{ data.label ?? '-' | translate }}</label>
                            <div class="service-request">
                                <div class="service-request-wrapper" *ngFor="let item of data.list"
                                    [ngClass]="{active:item.reqId == selectedService.reqId }"
                                    (click)="selecteServiceRequest(item)">

                                    <div class="choose-more" id="choose-more-option" *ngIf="item['addMoreOptionModal']"
                                        (click)="cancelRequest(selectedService)">
                                        <div class="choose-more-card row cursor-pointer">
                                            <div class="image">
                                                <img src="./assets/icons/service-request/delete.svg">
                                            </div>
                                            <div class="choose-more-card-content">
                                                <div class="title">
                                                    {{ "Cancel_request" | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="service-request-card row cursor-pointer">
                                        <div class="card-content">
                                            <div class="image">
                                                <img *ngIf="item.reqType === 'ENQ'" class="icon"
                                                    src="./assets/icons/service-request/call.svg">
                                                <img *ngIf="item.reqType === 'APPT' || item.reqType ==='SERVICE'"
                                                    class="icon" src="./assets/icons/service-request/Calendar.svg">
                                            </div>
                                            <div class="title" [ngClass]="{active:item.reqId == selectedService.reqId }"
                                                *ngIf="item.reqType === 'ENQ'">
                                                {{ 'Enquiry' | translate}}
                                            </div>
                                            <div class="title" [ngClass]="{active:item.reqId == selectedService.reqId }"
                                                *ngIf="item.reqType === 'APPT'">
                                                {{ 'Appointment_request' | translate}}
                                            </div>
                                            <div class="title" [ngClass]="{active:item.reqId == selectedService.reqId }"
                                                *ngIf="item.reqType === 'SERVICE'">
                                                {{ 'Service' | translate}}
                                            </div>
                                        </div>
                                        <div class="image" (click)="moreActions(item);$event.stopPropagation()"
                                            host="choose-more-option" appPopover>
                                            <img class="icon" src="./assets/icons/service-request/More_icon.svg">
                                        </div>
                                    </div>

                                    <span class="doctor-name">
                                        {{ item.reqBody.practitionerName ?? '-' | translate}}</span>
                                    <div class="patient-date-card">
                                        <span class="for-patient-label">
                                            {{ 'For:' | translate }}
                                            <span class="patient-name">
                                                {{ item.reqBody.patientName ?? '-' | translate}}
                                            </span>
                                        </span>
                                        <span class="image">
                                            <img class="icon" src="./assets/icons/service-request/Calendar.svg">
                                            <span class="date">
                                                {{ item.dttm | moment: 'DD MMM YYYY, hh:mm A' ?? '-' | translate}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="components" *ngIf="selectedService">
                        <app-service-request-details [selectedServiceRequest]='selectedService'
                            [AllServiceRequest]='serviceRequestData' (close)="reset()"
                            (cancelServiceRequest)="getServiceReqList()"></app-service-request-details>
                    </div>

                </div>
            </ng-container>

        </div>


    </div>
    <ng-template #noData>
        <span class="no-data">
            {{'No_data_found' | translate}}
        </span>
    </ng-template>
</div>