import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { CookieService } from './cookie.service';
import { USER_TOKEN } from '../app.string';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginService {

  constructor(private router: Router, private cookie: CookieService, private route: ActivatedRoute, private user: UserService) { }

  canActivate() {
    let token = this.cookie.check(USER_TOKEN);
    let time = this.cookie.checkTime();
    if (token && time) {
      return false;
    }
    else
      return true;
  }
}