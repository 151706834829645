<div class="reports-view-modal-wrapper">
  <div class="hr-report-header md-hidden">{{'Report_details' | translate}} <img src="./assets/icons/close_black.svg" alt="" class="close-icon" (click)="closeModal()"></div>

  <div class="selected-patient-banner md-hidden" *ngIf="selectedPatient">
    <p class="selected-patient">{{selectedPatient['personname']}} <span class="selected-patient-info"> {{selectedPatient.gender}},
      {{selectedPatient.dob | age}},
      {{selectedPatient.relationshiptype|translate:'RELATION'}},
      {{selectedPatient.uhid}}</span></p>
  </div>
  <div class="report-info-wrapper">
    <img src="assets/icons/Home/up arrow.svg" class="arrow-image md-hidden" (click)="choosePrevious()"
      style="transform: rotate(-90deg);">
    <div class="report-info-context">
      <p class="report-info-title">{{selectedReport['eventDesc']}}</p>
      <p class="report-info-subtitle">{{selectedPatient['personname']}},
        {{selectedReport['facilityId']| facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name'}},
        {{selectedReport['reportDateTime'] | moment:'D MMM YYYY hh:mm A'}}
      </p>
    </div>
    <img src="assets/icons/Home/up arrow.svg" class="arrow-image md-hidden" (click)="chooseNext()"
      style="transform: rotate(90deg);">
    <div class="report-info-button"><button class="button-secondary" (click)="download()">{{'Download'}}</button></div>
  </div>
  <div [ngSwitch]="selectedReport['format']">
    <div *ngSwitchCase="'CLOBFORMAT'">
      <app-hr-report-clobformat [report]="selectedReport" [reportData]="selectedReport['data']"
        [selectedPatient]="selectedPatient" [isCommonView]="isCommonView" (close)="closeModal()" [isInline]="true"
        (initDownload)="download()"></app-hr-report-clobformat>
    </div>
    <div *ngSwitchCase="'FORMFORMAT'">
      <app-hr-report-formformat [report]="selectedReport" [reportData]="selectedReport['data']"
        [selectedPatient]="selectedPatient" [isCommonView]="isCommonView" (close)="closeModal()" [isInline]="true"
        (initDownload)="download()">
      </app-hr-report-formformat>
    </div>
    <div *ngSwitchCase="'JSONFORMAT'">
      <app-hr-report-jsonformat [report]="selectedReport" [reportData]="selectedReport['data']" [patientMetaData]="patientMetaData"
        [selectedPatient]="selectedPatient" [isInline]="true"
        [isCommonView]="isCommonView" (close)="closeModal()" (initDownload)="download()">
      </app-hr-report-jsonformat>
    </div>
  </div>

</div>