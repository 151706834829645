import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-timeperiod',
  templateUrl: './timeperiod.component.html',
  styleUrls: ['./timeperiod.component.scss']
})
export class TimeperiodComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  public inputList = [];
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideTimePeriod(this.section);
    this.inputList.push(this.section['minValue']);
    while (this.inputList[this.inputList.length - 1] + this.section['stepCounter'] < this.section['maxValue']) {
      this.inputList.push(this.inputList[this.inputList.length - 1] + this.section['stepCounter']);
    }
    // this.section['selected'] = this.section['value'] + ' ' + this.section['option'] ;
  }

  onChange(event) {
    this.section['selected'] = this.section['value'] + ' ' + this.section['option'] ;
    super.complete(this.section);
  }

}
