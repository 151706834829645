import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectiveLinkedPrintPreviewComponent } from './directive-linked-print-preview.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [DirectiveLinkedPrintPreviewComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [
    DirectiveLinkedPrintPreviewComponent
  ]
})
export class DirectiveLinkedPrintPreviewModule { }
