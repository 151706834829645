import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from 'src/app/custom-input-group/custom-input-group.module';
import { NodataModule } from 'src/app/nodata/nodata.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SkeletonLoaderModule } from 'src/app/skeleton-loader/skeleton-loader.module';
import { DisplayCurrencyModule } from '../display-currency/display-currency.module';
import { ConsentModalComponent } from './consent-modal.component';



@NgModule({
  declarations: [ConsentModalComponent],
  imports: [
    CommonModule, PipesModule, DisplayCurrencyModule, NodataModule, CustomInputGroupModule, FormsModule, ReactiveFormsModule, SkeletonLoaderModule
  ], exports: [
    ConsentModalComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsentModalModule { }
