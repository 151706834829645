import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BILLSTATUS_COLOR_MAPPING } from '@const';
import { HealthRecordsService } from '../services/health-records.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-hr-visit-bill-preview',
  templateUrl: './hr-visit-bill-preview.component.html',
  styleUrls: ['./hr-visit-bill-preview.component.scss']
})
export class HrVisitBillPreviewComponent implements OnInit {
  @Input() billDetails;
  @Input() billNo;
  @Input() billDtl;
  @Input() patient;
  @Output() close: EventEmitter<any> = new EventEmitter<any>()
  public currency_code;
  public billStatusColorMapping = BILLSTATUS_COLOR_MAPPING;
  constructor(private user: UserService, private hrService:HealthRecordsService) { 
    this.currency_code = this.user.getCurrency()
  }

  ngOnInit(): void {
    console.log(this.patient);
    // this.hrService.getBillItemDetails(this.billNo, this.patient?.uhid).subscribe(data => {
    //   console.log(data);
    //   this.billDetails = data;
    // })

    this.billDetails = this.billDtl; 
  }

  public closeModal() {
    this.close.emit();
  }

  public getSumOfItems(attr) {
   return this.billDetails?.items.reduce((a, b) => a += b[attr], 0);
  }

  public concatDiag(billDetail) {
    var string = billDetail?.diagnosis.reduce((a, b) => a += b['desc']+", ","");
    string = string.substring(0, string.length-2);
    return string
   }
}
