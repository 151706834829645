<div class="ui">
<p class="rating-label">
  <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
   <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup> - <span class="rating-val">{{section['selected']}}</span></p>
<div style="display: -webkit-inline-box;color: teal"  *ngFor="let r of section['range'];let i =index">
  <div (click)="rate(i)">
    <i [ngClass]="i<section['selected']?'fas-star':'far-star'"></i>
  </div>
</div>
<div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
  <p>{{'form_field_mandatory' | translate}}</p>
</div>
</div>