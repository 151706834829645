import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
@Pipe({
  name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

  constructor(private user: UserService) { }

  transform(value: Array<any>, searchString: string, searchkey: string, type: 'LANGARRAY' | 'STRING' | 'GROUP' | 'LOCAL' | 'FACILITY', localKey: string, listKey: string = 'list', showToday: boolean = false): any {
    if (!value) return [];
    if (searchString && searchString != '') {
      if (type == 'GROUP') {
        value = _.cloneDeep(value).map(v => {
          v[listKey] = (v[listKey] || []).filter(k => {
            return this.compare(k[searchkey], searchString)
          })
          return v;
        }).filter(v=> v[listKey].length > 0)
        return value;
      }else if(type == 'LOCAL'){
        value = _.cloneDeep(value).filter(v => {
            if(this.compare(v[searchkey], searchString))
              return v;
        });
        return value;
      }else if(type == 'FACILITY'){
        var unique= value.filter((v) => {
          try {
            let obj = v[searchkey].find(k => { return k['locale'] == this.user.currentLanguage })
            if (!obj) obj = v[searchkey][0];
            // console.log('facility obj',obj)
            return this.compare(obj[localKey], searchString)
          } catch (e) {
            console.error(e)
            return true;
          }
        })
        return unique;
        return value;
      } else {
        console.log(value);
        var unique= value.filter((v) => {
          try {
            if (type == 'LANGARRAY') {
              let obj = v[searchkey].find(k => { return k['locale'] == this.user.currentLanguage })
              if (!obj) obj = v[searchkey][0];
              return this.compare(obj[localKey], searchString)
            } else {
              return this.compare(v[searchkey], searchString)
            }
          } catch (e) {
            console.error(e)
            return true;
          }
        })
        if(searchkey == "resourceName") {
          if(!showToday)
          return unique.filter((v,i,a)=>a.findIndex(v2=>(v2.resourceId===v.resourceId))===i);
          else {
            let tmp=unique.filter((v,i,a)=>a.findIndex(v2=>(v2.resourceId===v.resourceId))===i);
            let date=moment().format("YYYY-MM-DD");
            console.log('in toggle enable and with searchstring---',showToday,date, tmp)
            tmp=tmp.filter(t=>{
              if (t._availableDates && t._availableDates[date] && t._availableDates[date] > 0) {
                return t;
              }
            })
            return tmp;
          }
        }
        else
          return unique;

      }
    } else {
      if(!showToday)
      return value;
      else{
        let tmp=value,date=moment().format("YYYY-MM-DD");
        console.log('in toggle enable---',showToday,date,tmp)
        tmp=tmp.filter(t=>{
          if (t._availableDates && t._availableDates[date] && t._availableDates[date] > 0) {
            return t;
          }
        })
        return tmp;
      }
    }
  }

  private compare(a: string, b: string) {
    const check1 = this.compareResult(a, b);
    const check2 = this.compareResult(this.normalizeArabicChars(a), b);
    const check3 = this.compareResult(a, this.normalizeArabicChars(b));
    const check4 = this.compareResult(this.normalizeArabicChars(a), this.normalizeArabicChars(b));
    return check1 || check2 || check3 || check4;
  }

  private compareResult(a, b) {
    const removeDiacritics = str => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    const contains = (value, charToCheck) => {
      return value.split('').some(char => char.localeCompare(charToCheck, this.user.currentLanguage, { sensitivity: 'accent' }) === 0)
    }
    let stringCompare = a.toLocaleLowerCase().includes(b.toLocaleLowerCase())
    let normalizedCompare = removeDiacritics(a).indexOf(removeDiacritics(b)) > -1
    let localeCompare = contains(a, b)
    return stringCompare || normalizedCompare || localeCompare;
  }

  private normalizeArabicChars(s) {
    var filter = (c) => {
      switch (c) {
        // ALEF Chars
        case '\u0625':
        case '\u0623':
        case '\u0622':
        case '\u0675':
        case '\u0673':
        case '\u0672':
        case '\u0671':
        case '\u0670':
        case '\u0674':
          return '\u0627';
        // TAAA MARBOTA Chars
        case '\u06c3':
        case '\u06c2':
        case '\u06c0':
        case '\u06c1':
        case '\u0647':
          return '\u0629';
        // YAAA Chars
        case '\u0649':
        case '\u064a':
        case '\u0678':
        case '\u064a':
          return '\u0620';

        case '\u0626':
          return '\u0620\u0621';

        default:
          return c;
      }
    }

    var normalized = '', i, l;
    for (i = 0, l = s.length; i < l; i = i + 1) {
      normalized = normalized + filter(s.charAt(i));
    }
    return normalized;
  };

}
