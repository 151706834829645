import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { NavigationMenuItem } from '../app.type';
import { MENU } from '../app.const';
import { cloneDeep as _cloneDeep} from 'lodash';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() selectedPath: any;
  @Input() viewType: 'NEW' | 'OLD' = 'NEW';
  public menu: Array<NavigationMenuItem>;
  public state: Object;
  constructor(private router: Router, private route: ActivatedRoute, private featureFlagService: FeatureFlagsService, private user: UserService) { 
    this.menu = new Array<NavigationMenuItem>();
    this.state = {
      route_name: '',
      route_url: '',
    }
  }

  ngOnInit() {
    let menu = _cloneDeep(MENU);

    this.menu = menu.filter(m => {
      return m.permission == 'DEFAULT'
    });
    this.featureFlagService.getInitStatus().subscribe(_ => {
      this.menu = menu.filter(m => {
        if (m.permission == 'DEFAULT') {
          return true
        } else {
          if (this.featureFlagService.featureOn(m.permission)) {
            return true;
          }
        }
      })
    })
    if (this.route.snapshot.firstChild != null) {
      this.state = {
        route_name: this.route.snapshot.firstChild.data['name'],
        route_url: this.router.url
      }
    }
    else {
      this.state = {
        route_name: this.selectedPath.snapshot.data['name'],
        route_url: this.router.url
      }
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if(this.route.snapshot.firstChild != null){
          this.state = {
            route_name: this.route.snapshot.firstChild.data['name'],
            route_url: this.router.url
          }
        }
      }
    });
  }

  setRoute(item){
    this.router.navigate([`${this.user.getPortalBasePath()}/`+item.routeTo])
  }

}
