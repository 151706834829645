import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentVisitTypeComponent } from './appointment-visit-type.component';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';



@NgModule({
  declarations: [AppointmentVisitTypeComponent],
  imports: [
    CommonModule, PipesModule, DirectiveModule
  ],
  exports: [
    AppointmentVisitTypeComponent
  ]
})
export class AppointmentVisitTypeModule { }
