<div id="LoginComponent">
  <div class="login-header" (click)="openInfo()">
    <div class="flex-container"> <img class="type-image" src="assets/images/signin/nahdi_clinic.png" /></div>
  </div>
  <div class="login-container">
    <div class="image-placeholder">
      <div class="logo" *ngIf="false">
        <img class="type-image" src="assets/images/signin/logo_minimal_white.png" />
      </div>
      <div class="overlay"></div>
    </div>
    <div class="user-section">
      <div class="settings">
        <div class="back-button">
          <div class="languages">
            <div class="cursor-pointer"
              [ngClass]="{active: (item.localeCode === selectedLanguage), bold:(item.localeCode === selectedLanguage)}"
              *ngFor="let item of languageList" (click)="selectLanguge(item)">
              {{item.localName}}
            </div>
          </div>
        </div>
        <div class="company-details">
          <h3 class="bold default-header-lbl">
            {{'Welcome_to'|translate:'TASK_LABELS'}}
            <!-- Welcome To -->
          </h3>
          <img class="type-image hide-logo" src="assets/images/signin/logo_grey.png" />
        </div>
        
        <div class="component-container" [ngSwitch]="currentState">
          <div class="component-option" *ngSwitchCase="'GENOTP'">
            <div class="labels">
              <div class="label-title">{{'New_Report'|translate:'TASK_LABELS'}}</div>
              <div class="label-message">{{'Generate_otp_to'|translate:'TASK_LABELS'}}
                {{selectedPatient?selectedPatient.maskedMobile:''}}
              </div>
            </div>
            <div class="generate-otp">
              <button type="submit" (click)="generateOtp()" type="button" class="primary-button">
                {{'Generate_OTP'|translate:'TASK_LABELS'}} </button>
            </div>
          </div>
          <div class="component-option" *ngSwitchCase="'ENTOTP'">
            <div class="labels">
              <div class="label-title">{{'New_Report'|translate:'TASK_LABELS'}}</div>
              <div class="enter-otp">
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'Enter_otp'|translate:'TASK_LABELS'}}</label>
                    <div class="input-group">
                      <input [(ngModel)]="otp" placeholder="000000" type="password" required>
                    </div>
                  </div>
                </div>
                <div class="otp-reference">
                  {{'Enter_otp_sent_to'|translate:'TASK_LABELS'}} {{selectedPatient?selectedPatient.maskedMobile:''}}
                </div>
                <div class="font-danger-small" *ngIf="invalidOtp">
                  {{'Invalid_OTP'|translate:'TASK_LABELS'}}
                </div>
              </div>
            </div>
            <div class="generate-otp" >
              <button type="submit" (click)="validateOTP()" type="button" class="primary-button">
                {{'Verify_OTP'|translate:'TASK_LABELS'}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer [isLogin]="true"></app-footer>
