import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { OnspotConsultDoctorComponent } from './onspot-consult-doctor.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { OnspotRequestAcceptModule } from '../onspot-request-accept/onspot-request-accept.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { CouponModule } from '../coupon/coupon.module';
import { PatientListModule } from '../patient-list/patient-list.module';
import { AppointmentOverlapModalModule } from '../appointment-overlap-modal/appointment-overlap-modal.module';
import { LoaderModule } from '../loader/loader.module';
@NgModule({
  declarations: [OnspotConsultDoctorComponent],
  imports: [
    CommonModule, PipesModule,
    CustomModalModule,
    PersonAvatarModule,
    OnspotRequestAcceptModule,
    PaymentCheckoutModule,
    SpinnerModule,
    CouponModule,
    PatientListModule,
    AppointmentOverlapModalModule,
    LoaderModule
  ],

  exports: [
    OnspotConsultDoctorComponent
  ]
})
export class OnspotConsultDoctorModule { }
