import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  source = new BehaviorSubject<boolean>(false);
  ob = this.source.asObservable();
  

  changeState(state) {
    this.source.next(state);
  }
}
