import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Output() close: EventEmitter<any>;

  public changePasswordForm: UntypedFormGroup;
  public extAuth: boolean;
  private additionalPinValidation: Array<any> = [];


  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private user: UserService) { 
    this.close = new EventEmitter<any>();
    this.user.getExternalInterface().subscribe(data => {
      this.extAuth = data;
    })
    this.additionalPinValidation = this.user.getPasswordValidation()
  }

  ngOnInit(): void {
    this.buildForm();
  }

  public closeModal() {
    this.close.emit();
  }
  private buildForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, ...this.additionalPinValidation]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: this.matchPassword });
  }
  matchPassword = (control: AbstractControl): { invalid: boolean } => {
    if (control.get('newPassword').value !== control.get('confirmPassword').value || control.get('newPassword').value == control.get('currentPassword').value) {
      return { invalid: true };
    }
  };
  public isRequiredField(field: string) {
    let form_field = this.changePasswordForm.get(field);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  public changePassword() {
    this.authService.changePassword(this.changePasswordForm.get('currentPassword').value, this.changePasswordForm.get('newPassword').value).subscribe(_ => {
      this.closeModal();
      this.changePasswordForm.reset();
    })
  }
  public changePin() {
    this.user.getUserInfo('personDetails').subscribe(data => { 
      let countryCode = data['countryCode']?`+${data['countryCode']}`:null;
      let mobile = data['mobile'];
      this.authService.changePin(this.changePasswordForm.get('currentPassword').value, this.changePasswordForm.get('newPassword').value, mobile, countryCode).subscribe(_ => {
        this.closeModal();
        this.changePasswordForm.reset();
      })
    })
    
  }
  public resetForm() {
    this.closeModal();
    this.changePasswordForm.reset();
  }

  public isSameAsOldPin() {
    return this.changePasswordForm.get('currentPassword').value && this.changePasswordForm.get('newPassword').value && this.changePasswordForm.get('currentPassword').value === this.changePasswordForm.get('newPassword').value;
  }
}
