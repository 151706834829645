<div class="ui form" id="radio-list">
  <div class="grouped fields">
    <p class="check-label">
      <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
      <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
    </p>
    <div class="check-list">
      <div class="field" *ngFor="let a of section['options']">
        <div class="ui radio checkbox">
          <label class="check-opt" [for]="'radio_'+UUID+section['domainCode']+'_'+nospace(a.localName[0].text)">
            <input 
              (click)="setValue(a.localName[0].text)" 
              type="radio" 
              name="{{'radio_'+UUID+section['domainCode']+'_'+nospace(a.localName[0].text) }}"
              value="{{a.localName[0].text}}"
              [checked]="section['selected'] === a.localName[0].text"
              [tabindex]="tabindex"
              [id]="'radio_'+UUID+section['domainCode']+'_'+nospace(a.localName[0].text)"
            />
            {{a.localName | translate:'LOCALE':'text'}}
          </label>
              <!-- name="{{ section['domainCode'] }}" -->

        </div>
      </div>
    </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
      <p>{{'form_field_mandatory' | translate}}</p>
    </div>
  </div>
</div>
