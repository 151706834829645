import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { PhoneNumberUtil } from "google-libphonenumber";
import { LinkPatientService } from '../services/link-patient.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { ToastServiceService } from '../services/toast-service.service';

@Component({
  selector: 'app-link-other-mobile',
  templateUrl: './link-other-mobile.component.html',
  styleUrls: ['./link-other-mobile.component.scss']
})
export class LinkOtherMobileComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  @Output() initLinkPatients: EventEmitter<any>;
  @Output() initAddPatients: EventEmitter<any>;
  @Input() linkedPatients: any;
  @Input() prefillNationalId: any;
  @Input() linkSameNumber: any;
  @Input() source: any;
  public recordsToLink: any;
  public linkedId: any;
  public popoverpatient: any;
  public isOTPInput: boolean = false;
  public isRecordToLink: boolean = false;
  public isExtIdLogin: boolean = false;
  public isRelationModal: boolean = false;
  public linkpatientForm: UntypedFormGroup;
  public otplength: number = 6;
  public resendSeconds: number = 0;
  public maxOTPSendTime: number = 30;
  public relationChoosedPatient: any = null;

  constructor(private user: UserService, private auth: AuthService, private linkPatient: LinkPatientService, private fb: UntypedFormBuilder, private featureService: FeatureFlagsService, private toast:ToastServiceService) {
    this.close = new EventEmitter<any>();
    this.initLinkPatients = new EventEmitter<any>();
    this.initAddPatients = new EventEmitter<any>();
    this.isExtIdLogin = this.featureService.featureOn('FT_APP_EXTIDLOGIN');

  }

  ngOnInit(): void {
    this.linkpatientForm = this.fb.group({
      mobile: this.isExtIdLogin ? undefined : new UntypedFormControl('', [Validators.required, this.validatePhone]),
      natid: this.isExtIdLogin ? new UntypedFormControl('', [Validators.required,]) : null
    });
    this.initPage()
    
  }

  initPage() {
    if (this.linkSameNumber)
      return this.getPatientsForSameNumber();
    if (this.prefillNationalId) {
      this.linkpatientForm.patchValue({ 'natid': this.prefillNationalId })
      this.verifymobile()
    }
  }

  closeModal() {
    this.close.emit()
  }

  public verifymobile(isResendOtp?) {
    let value, countryCode, mobile, natid;
    if (!this.isExtIdLogin) {
      value = this.linkpatientForm.get('mobile').value;
      countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
      mobile = this.phoneExtractor(value, 'MOBILE');
    } else {
      natid = this.linkpatientForm.get('natid').value;
    }
    this.auth.sendOTP(countryCode, mobile, natid).subscribe(_ => {
      this.linkpatientForm.addControl('otp', new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('(|^)\\d{6}')
      ]))
      this.resendSeconds = this.maxOTPSendTime;
      this.initMaxOTPSendTime();
      this.isOTPInput = true;
      if(isResendOtp)
      this.toast.showToast(true, "OTP has been resent to mobile number", 'success');

    })
  }

  public verifyotp() {
    let value, countryCode, mobile;
    value = this.linkpatientForm.get('mobile').value;
    countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    mobile = this.phoneExtractor(value, 'MOBILE');    
    let payload = {
      personDetails: {
        mobile: mobile,
        countryCode: countryCode
      },
      otp: this.linkpatientForm.get('otp').value
    }
    this.linkedId = this.linkedPatients.map(item => {
      return item.uhid;
    })
    this.linkPatient.getPatientsToLink(payload).subscribe(result => {
      if (result.status === 'SUCCESS') {
        this.recordsToLink = result.data.filter((item) => {
          return this.linkedId.indexOf(item.patientUhid) === -1
        })
        this.isRecordToLink = true;
      }
    })
  }

  phoneExtractor = (value: string, type: string): string => {
    let countryCode: string | number = '';
    if (value.match(/[a-z]/i))
      return '' + countryCode;
    try {
      var phoneUtil = PhoneNumberUtil.getInstance();
      var number = phoneUtil.parse(value);
      switch (type) {
        case 'MOBILE':
          countryCode = number.getNationalNumber();
          break;
        case 'COUNTRY_CODE':
          countryCode = number.getCountryCode();
          break;
      }
    } catch (error) {
      console.error(error);
    }
    return '' + countryCode;
  };
  validatePhone = (control: AbstractControl): { mobile: boolean } => {
    let phone = control.value;
    if (this.checkIfPhoneNumber(phone))
      return null;
    return { mobile: true };
  };
  checkIfPhoneNumber = (phone: string) => {
    let validation: boolean = false;
    if (null == phone || typeof phone == undefined || phone.length < 5 || phone.match(/[a-z]/i)) {
      return validation;
    } else {
      try {
        var phoneUtil = PhoneNumberUtil.getInstance();
        var number = phoneUtil.parse(phone);
        validation = phoneUtil.isValidNumber(number);
        return validation;
      } catch (error) {
        console.error(error);
        return validation;
      }
    }
  };

  public openRelationModal(patient) {
    this.relationChoosedPatient = patient;
    this.isRelationModal = true;
  }

  public openPopover(patient, enablePhoto, enableUnlink, enableLink) {
    this.popoverpatient = { patient, enablePhoto, enableUnlink, enableLink }
  }
  public chooseOption(option: 'LINK' | 'ADD', patient?) {
    switch (option) {
      case 'LINK':
        this.initLinkPatients.emit(this.popoverpatient && this.popoverpatient.patient ? this.popoverpatient.patient: patient)
        break;
      case 'ADD':
        let natid = this.linkpatientForm.get('natid').value;
        let otp = this.linkpatientForm.get('otp').value;
        this.initAddPatients.emit({natid, otp})
        break;
    }
  }
  public initMaxOTPSendTime() {
    setTimeout(_ => {
      this.resendSeconds -= 1;
      if (this.resendSeconds > 0) {
        this.initMaxOTPSendTime();
      }
    }, 1000)
  }

  public handlePrecheckNatIdClick() {
    
  }

  public getPatientsForSameNumber() {
    if (this.linkedPatients) {
      this.linkedId = this.linkedPatients.map(item => {
        return item.uhid;
      })
    }
    this.user.getUserCredential().subscribe(data => {
      if (data) {
        this.linkPatient.getPatientsToLink(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.recordsToLink = result.data.filter((item) => {
              return this.linkedId.indexOf(item.patientUhid) === -1
            })
            this.isRecordToLink = true;
          }
        })
      }
    })
  }

  public closeUpdateRelation() {
    this.isRelationModal = false;
    this.relationChoosedPatient = null;
    this.close.emit()
  }
  
}
