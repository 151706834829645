<div class="section" [ngClass]="{'section-editable': editable}">
    <ng-container *ngIf="section.type !== 'directiveLinkedField'">
      <div *ngIf="codify(section.codify)" class="codify-div">
        <div *ngIf="type(section.type)">
          <div *ngFor="let ques of section.question">
            <div class="no-padding no-margin secondary-text preview-p p-s-1">
              <div class="que-txt">{{ ques['text'] }}</div>
              <div style="font-weight: 500" class="ans-txt"> {{ section['value'] }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="!type(section.type)">
          <div *ngFor="let ques of section.question">
            <div class="no-padding no-margin secondary-text preview-p p-s-2" *ngIf="section.type !== 'addendum'">
              <div
                class="que-txt">{{ ques['text'] }}</div>
              <div class="spanData ans-txt"
                *ngIf="!((section.type == 'photo') || (section['type'] == 'draw') || (section['type'] == 'schematics'))">{{ section['value'] }}</div>
            </div>
            <div *ngIf="section.type === 'addendum'" class="no-padding no-margin secondary-text">
              <!-- <div class="addendum-title" *ngIf="section.addendum && topics.length > 1">
                Added by <span>{{ section.authorName || 'N/A' }}</span> on <span>{{ section.authoredDate | moment: 'MMM DD, YYYY h:mm a' }}</span>
            </div> -->
              <div class="addendum-note">
                {{ section.value }}
              </div>
            </div>
            <ng-container *ngIf="section['type'] === 'draw'">
              <img class="rpt-img" [ngClass]="{'rpt-img-large':section._zoom}"
                (click)="section._zoom = !section._zoom" src="{{section.selected}}">
            </ng-container>
            <ng-container *ngIf="(section['type'] === 'photo' || section['type'] === 'schematics') && section.selected.length>0">
              <ng-container *ngFor="let s of section.selected">
                <img class="rpt-img" *ngIf="s.url" [ngClass]="{'rpt-img-large':s._zoom}" (click)="s._zoom = !s._zoom"
                  [src]="s.url" style="margin: 0.5em;">
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="!codify(section.codify)" class="codify-div">
        <div>
          <div class="no-padding no-margin secondary-text preview-p p-s-3">
            <div class="que-txt">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}
            </div>
            <div *ngIf="section['type'] !== 'caption'"
              class="ans-txt">
                <ng-container *ngIf="isFromReadOnlySection && !section['value']">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="section['value']">
                  {{ section['value'].toString().includes('#') ? section['value'].toString().split('#')[1] : section['value']  }}
                </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="edit-button cursor-pointer" (click)="editSection()" *ngIf="section.isReadOnlyYn != 'Y'">
      <img src="assets/icons/signin/pen.svg" />
    </div>
  </div>