import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicAppointmentReviewComponent } from './express-clinic-appointment-review.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ApptDtlCardModule } from '../appt-dtl-card/appt-dtl-card.module';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';


@NgModule({
  declarations: [ExpressClinicAppointmentReviewComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule,
    PractitionerAvatarModule,
    ApptDtlCardModule, ApptTimeModule
  ]
})
export class ExpressClinicAppointmentReviewModule { }
