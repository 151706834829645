import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FormGroup, Validators } from '@angular/forms';
import { DEFAULT_DATE_FORMAT, RATINGARRAY } from '@const';
import { FormControlGenerateService } from '../services/form-control-generate.service';
import { combineLatestWith } from 'rxjs';
import * as moment from 'moment';
import { ToastServiceService } from '../services/toast-service.service';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.scss']
})
export class InsuranceDetailsComponent implements OnInit {
  @Input() patient: any;
  @Input() payGroupList: Array<any>;
  public payGroup: Array<any>;
  public selectOption: any = { name: 'desc', value: 'BLNGGRPID', choose: 'desc' };
  public standalone: boolean;
  public userId: any;
  public modalOpen: boolean = false;
  public insurance: any
  public insuranceForm: FormGroup;
  public removeInsurance: boolean = false;
  public selectedInsuranceCompany: any = {
    desc: '',
    BLNGGRPID: '',
    code: ''
  };
  public insuranceDetails: any;
  public billingGrpType: any;
  public viewType: any;
  public policyDataObject: any;
  public policyInfo: any;
  public noInsurance: boolean = false;
  public hidePolicyLink: boolean = true;
  public callno: any;
  public updateView: boolean = false;
  public boarderId: boolean = false;
  public selectedBillingGroupId: any;
  public selectedBillingGroupCode: any;
  public toUpdatePolicy: boolean = false;
  public policyError: any;
  public random: any;
  public showOptions: any;
  public config: any;
  public defaultFacilityIdForReg: any;
  public allowUser: any;
  public passport: any;
  private mobileNumber: any;
  private regForm = {};
  @Output() close: EventEmitter<any>;
  @Output() refresh: EventEmitter<any>;
  constructor(private user: UserService, private translatePipe: TranslatePipe, private formService: FormControlGenerateService, public toast: ToastServiceService,) {
    this.close = new EventEmitter<any>();
    this.refresh = new EventEmitter<any>();
  }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.callno = this.translatePipe.transform('REGISTER#CALL#NUMBER', 'LABELS');
    let editPatientObj = this.patient.actualData
    this.editPatientInfo(editPatientObj)
    this.getPatient(editPatientObj)
    console.log('patient', this.patient);
    this.defaultFacilityIdForReg = this.user.getDefaultFacilityForRegistration()
    this.noInsurance = false;
    this.random = (Math.floor(Math.random() * (999999 - 100000)) + 100000).toString();
    this.listPayerGroup();
    this.getUserIdForPatient();
    if (this.updateView) {
      this.updateView = false;
      this.toUpdatePolicy = false;
    }
    // if (this.userId && this.userId.startsWith("3"))
    //   this.boarderId = true;
    this.patient.insuranceDetails = this.patient.insuranceDetails.filter(t => t.settlememtInd == 'X')
    if (this.patient.insuranceDetails && this.patient.insuranceDetails.length > 0) {
      this.insuranceDetails = this.patient.insuranceDetails[0]
      this.billingGrpType = 'INS';
      this.selectedBillingGroupId = this.patient.insuranceDetails[0]['billingGrpId'];
      this.selectedBillingGroupCode = this.patient.insuranceDetails[0]['custGroupCode'];
      // this.selectedInsuranceCompany=this.patient.insuranceDetails[0]['billingGrpId']!=='CASH' ? this.patient.insuranceDetails[0]['billingGrpId'] : null;
      this.policyInfo = {
        custGroupName: this.insuranceDetails['custGroupName'],
        policyName: this.insuranceDetails['policyName'] || this.insuranceDetails['policyTypeCode'],
        payerName: this.insuranceDetails['custName'],
        policyNumber: this.insuranceDetails['policyNumber'],
        membershipNo: this.insuranceDetails['membershipNo'],
        policyStartDate: this.insuranceDetails['policyStartDate'],
        policyExpiryDate: this.insuranceDetails['policyExpiryDate'],
      }
    } else {
      this.billingGrpType = 'CASH'
      this.viewType = 'ADD';
    }
    if (this.billingGrpType == 'CASH' && !this.hidePolicyLink)
      this.hidePolicyLink = true;
    this.initForm();
  }

  private editPatientInfo(editPatientObj) {
    console.debug('editPatientObj', editPatientObj)
    editPatientObj.momentDOB = moment(editPatientObj?.dob?.split("+")[0]);
    if (editPatientObj.gender && ['M', 'F'].includes(editPatientObj.gender)) {
      this.regForm['gender'] = editPatientObj.gender
    }
    if (editPatientObj.dob) {
      let dob = moment(editPatientObj.dob.split("+")[0]);
      this.regForm['dob'] = dob;
    }
    this.regForm['payerGroup'] = this.insuranceDetails?.custGroupCode
  }

  public getPatient(editPatientObj) {
    this.user.getPatient(editPatientObj['uhid']).subscribe(data => {
      if (data['familyName'])
        data['lastName'] = data['familyName']
      this.regForm['fName'] = data.firstName
      this.regForm['middlename'] = data.secondName
      this.regForm['lName'] = data.lastName

      if (!editPatientObj.gender && data.gender && ['M', 'F'].includes(data.gender)) {
        this.regForm['gender'] = data.gender
      }
      let mobileNumber, passportNo, iqamaId, nationalId;
      if (data['altIds'] && data['altIds'].length > 0) {
        data['altIds'].forEach((a) => {
          if (a['altIdType'] == 'ALT3')
            mobileNumber = a['altId']
          if (a['altIdType'] == 'ALT2')
            passportNo = a['altId']
          if (a['altIdType'] == 'ALT1')
            iqamaId = a['altId']
          if (a['altIdType'] == 'NATID')
            nationalId = a['altId']
        })
        mobileNumber = parseInt(mobileNumber)
        var cc = this.user.getCoutryCode('+' + mobileNumber.toString());
        if (cc) {
          this.mobileNumber = cc[2].toString();
          var ccObj = this.user.getCountryObj(cc[1])
          if (ccObj && ccObj[0])
            this.regForm['country'] = ccObj[0]
        }
        if (passportNo) {
          this.regForm['passport'] = passportNo
        }
        if (iqamaId || nationalId) {
          this.regForm['nationalid'] = nationalId || iqamaId;
        } else {
          this.regForm['nationalid'] = editPatientObj.national_id || editPatientObj.iqama_id;
        }
        if (!(this.regForm['nationalid'].startsWith('1') || this.regForm['nationalid'].startsWith('2'))) {
          this.regForm['visitor'] = true
        }
        this.userId = this.regForm['nationalid'];
        console.log('regForm', this.regForm);
      }
    }, err => {
    })
  }

  public initForm() {
    this.insurance = [
      {
        type: 'group',
        name: 'basic',
        title: '',
        child: [
          { type: 'control', name: 'nationalid', value: '', validators: null, input: 'number', title: 'PAT_REGISTRATION#FORM#LABEL#NATIONALID', readonly: true },
          { type: 'control', name: 'payerGroup', value: '', validators: null, input: 'search_select', title: 'PAT_REGISTRATION#FORM#LABEL#INSURANCECOMPANY', selectList: this.payGroupList, selectOptions: { name: 'desc', value: 'code', choose: 'desc', first: 'SEARCHSELECT#CASH#SELECT' }, order: 5 },

        ]
      }
    ]
    this.insuranceForm = this.formService.generateForm(this.insurance);
  }
  public listPayerGroup() {
    this.user.listPayerGroup(this.defaultFacilityIdForReg, false).subscribe(data => {
      this.payGroupList = data;
      data.unshift({
        code: '0001',
        desc: this.translatePipe.transform('SEARCHSELECT#CASH#SELECT'),
        BLNGGRPID: 'CASH'
      })
      this.payGroupList = data;
      if (this.billingGrpType !== 'CASH' && this.insuranceDetails) {
        this.payGroupList.forEach(l => {
          if (l['BLNGGRPID'] == this.insuranceDetails['billingGrpId'])
            this.selectedInsuranceCompany = {
              desc: this.insuranceDetails['billingGrpName'],
              code: ['custGroupCode'],
              BLNGGRPID: ['billingGrpId']
            }
        })
      }
    });
  }

  public getUserIdForPatient() {
    this.user.getUserIdForPatient({ patientId: this.patient['uhid'] }).subscribe(data => {
      this.userId = data['nationalId'] ? data['nationalId'] : data['iqamaId'];
      console.log('user id from EM --->', data, this.userId);
      this.insuranceForm.get(['basic', 'nationalid']).setValue(this.userId);
      if (this.userId && this.userId.startsWith("3"))
        this.boarderId = true;
    })
  }

  public getEffectedDate(date) {
    return this.user.getMoment(date).format('DD MMM YYYY')
  }

  public checkIsExpired(date) {
    return this.user.checkTimeExpiry(date)
  }

  public updateInsurance() {
    let inparam = {
      "facilityId": this.patient.facilityId,
      "patientId": this.patient.uhid,
      "billingGroupCode": this.selectedBillingGroupId,
      "locale": "en"
    }
    if (this.policyDataObject) {
      inparam['policyDataObject'] = this.policyDataObject;
      inparam['financialDetails'] = [
        {
          "payerGroupCode": this.policyDataObject['financialDetails'][0]['payerGroupCode'],
          "payer": this.policyDataObject['financialDetails'][0]['payer'],
          "policy": this.policyDataObject['financialDetails'][0]['policy'],
          "policyNo": this.policyDataObject['financialDetails'][0]['policyNo'],
          "policyStartDtm": this.policyDataObject['insDetails'].InsurancePolicy_StartDate ? moment(this.policyDataObject['insDetails'].InsurancePolicy_StartDate.split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
          "policyEndDtm": this.policyDataObject['insDetails'].InsurancePolicy_ExpiryDate ? moment(this.policyDataObject['insDetails'].InsurancePolicy_ExpiryDate.split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
          "approvedAmount": "",
          "approvedDays": "",
          "authRef": "",
          "authDate": "",
          "nonBillingGrp": "CASH",
          "membershipNo": this.policyDataObject['financialDetails'][0]['membershipId']
        }
      ]
    } else if (this.insuranceDetails) {
      inparam['financialDetails'] = [
        {
          "payerGroupCode": this.insuranceDetails['custGroupCode'],
          "payer": this.insuranceDetails['custCode'],
          "policy": this.insuranceDetails.policyName || this.insuranceDetails.policyTypeCode,
          "policyNo": this.insuranceDetails.policyNumber,
          "policyStartDtm": this.insuranceDetails.policyStartDate ? moment(this.insuranceDetails.policyStartDate).format(DEFAULT_DATE_FORMAT) : null,
          "policyEndDtm": this.insuranceDetails.policyExpiryDate ? moment(this.insuranceDetails.policyExpiryDate).format(DEFAULT_DATE_FORMAT) : null,
          "approvedAmount": "",
          "approvedDays": "",
          "authRef": "",
          "authDate": "",
          "nonBillingGrp": "CASH",
          "membershipNo": this.insuranceDetails['membershipNo']
        }
      ]
    }
    console.log(inparam)
    this.user.updateFinancialStatus(inparam).subscribe(data => {
      console.log('data---', data)
      if (data['status'] == 'SUCCESS') {
        this.user.setFinancialStatusUpdated(true);
        this.refresh.emit()
        let msg = this.translatePipe.transform('UPDATEPOLIY#MSG#SUCCESS#POLICYUPDATE', 'LABELS', null, null);
        this.toast.showToast(true, msg, "success")
        // this.navCtrl.pop()
      }
    }, err => {
      console.log('err--', err)
      this.user.setFinancialStatusUpdated(false)
    })
  }

  public refreshInsurance() {
    let inparam = {
      "facilityId": this.patient.facilityId,
      "policyGroup": this.selectedBillingGroupId, // "MGUL" || ,
      "idNumber": this.userId, // "1082366921" || "1024588896" || "2197448612" ||
      "custGroupCode": this.selectedBillingGroupCode,
      "firstName": this.regForm['fName'], 
      "middleName": this.regForm['middlename'],
      "lastName": this.regForm['lName'],
      "visitor": this.regForm['visitor'], 
      "dob": this.regForm['dob'],
      "gender": this.regForm['gender'],
      "passport": this.regForm['passport'],
      "country": this.regForm['country'][1],
      "patientId": this.patient.uhid,
      "fromManageInsurance": true
    }
    this.user.getPatientPolicy(inparam).subscribe(data => {
      console.log('policy info---', data);
      this.toUpdatePolicy = true;
      this.policyDataObject = data;
      if (data) {
        this.billingGrpType = 'INS';
        this.updateView = true;
        this.noInsurance = false;
        this.policyError = null
        this.policyInfo = {
          custGroupName: this.selectedInsuranceCompany['desc'],
          policyName: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policyName'] : null,
          payerName: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['payerName'] : null,
          policyNumber: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policyNo'] : null,
          membershipNo: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['membershipId'] : null,
          policyStartDate: data['insDetails'] && data['insDetails']['InsurancePolicy_StartDate'] ? moment(data['insDetails']['InsurancePolicy_StartDate'].split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
          policyExpiryDate: data['insDetails'] && data['insDetails']['InsurancePolicy_ExpiryDate'] ? moment(data['insDetails']['InsurancePolicy_ExpiryDate'].split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
          policy: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policy'] : null,
        }
      }
    }, err => {
      console.log('policy fetching error', err)
      this.policyInfo = null;
      this.noInsurance = true;
      this.policyError = err.error;
    })
  }

  public openInsurancePop() {
    this.removeInsurance = true;
  }

  public closeInsurancePop() {
    this.removeInsurance = false;
  }

  public updateBillingGroup(data) {
    console.log('updateBillingGroup', data)
    if (data == 'CASH') {
      let inparam = {
        "facilityId": this.patient.facilityId,
        "patientId": this.patient.uhid,
        "billingGroupCode": 'CASH',
        "locale": "en",
        "financialDetails": null
      }
      this.user.updateFinancialStatus(inparam).subscribe(data => {
        console.log('data---', data)
        if (data['status'] == 'SUCCESS') {
          this.policyInfo = null;
          this.billingGrpType = 'CASH';
          this.noInsurance = true;
          this.removeInsurance = false;
          this.user.setFinancialStatusUpdated(true);
          this.refresh.emit();
          let msg = this.translatePipe.transform('UPDATEPOLIY#MSG#SUCCESS#REMOVED', 'LABELS', null, null);
          this.toast.showToast(true, msg, "success");
        }
      }, err => {
        console.log('err--', err)
        this.removeInsurance = false;
        this.user.setFinancialStatusUpdated(false)
      })
    }
  }

  public chooseValue(data) {
    console.log('choose', data);
    if (data) {
      this.selectedBillingGroupId = data.BLNGGRPID;
      this.selectedInsuranceCompany = data;
      this.toUpdatePolicy = true;
      this.hidePolicyLink = false;
      let inparam = {
        "facilityId": this.patient.facilityId,
        "policyGroup": data.BLNGGRPID, // "MGUL" || ,
        "idNumber": this.userId, // "1082366921" || "1024588896" || "2197448612" ||
        "custGroupCode": data.code,
        "firstName": this.regForm['fName'], 
        "middleName": this.regForm['middlename'],
        "lastName": this.regForm['lName'], 
        "visitor": this.regForm['visitor'],
        "dob": this.regForm['dob'],
        "gender": this.regForm['gender'],
        "passport": this.regForm['passport'],
        "country": this.regForm['country'][1],
        "patientId": this.patient.uhid,
        "fromManageInsurance": true
      }
      this.user.getPatientPolicy(inparam).subscribe(data => {
        console.log('policy info---', data);
        this.policyDataObject = data;
        if (data) {
          this.billingGrpType = 'INS';
          this.updateView = true;
          this.noInsurance = false;
          this.policyError = null;
          this.policyInfo = {
            custGroupName: this.selectedInsuranceCompany['desc'],
            policyName: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policyName'] : null,
            payerName: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['payerName'] : null,
            policyNumber: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policyNo'] : null,
            membershipNo: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['membershipId'] : null,
            policyStartDate: data['insDetails'] && data['insDetails']['InsurancePolicy_StartDate'] ? moment(data['insDetails']['InsurancePolicy_StartDate'].split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
            policyExpiryDate: data['insDetails'] && data['insDetails']['InsurancePolicy_ExpiryDate'] ? moment(data['insDetails']['InsurancePolicy_ExpiryDate'].split("+")[0]).format(DEFAULT_DATE_FORMAT) : null,
            policy: data['financialDetails'] && data['financialDetails'].length > 0 ? data['financialDetails'][0]['policy'] : null,
          }
        }
      }, err => {
        console.log('policy fetching error', err)
        this.policyInfo = null;
        this.noInsurance = true;
        this.policyError = err.error;
      })
    }
  }

  public closeModal() {
    this.close.emit();
  }

}
