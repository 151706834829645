<div id="input-section" class="{{addclass}}">
  <ng-container *ngIf="type=='text'||type=='phonenumber' || type == 'tel'">
    <div class="input-group text-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'text-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}">
        <div *ngIf="prefix" class="input-prefix bold">{{prefix}}</div>
        <input name="inputname" [(ngModel)]="userInput" [type]="type" [placeholder]="'plhd_input' | translate :'LABELS' : null:null : placeholderPayload" [id]="random+'text-inputId'"
          (input)="onTextInput($event)" (keypress)="validateKey(); charType($event, characterType)"
          [readonly]="readonly" autocomplete="off" autocapitalize="true"
          [maxlength]="maxLength>0?maxLength:null" [minlength]="minLength>0?minLength:null" (blur)="inputBlur()"/>
        <ng-container *ngIf="icon">
          <img class="suffix-icon" [src]="icon" style="height: 1rem;margin: -1.5rem;"/>
        </ng-container>
      </div>
    </div>
  </ng-container>
    <ng-container *ngIf="type=='incrementnumber'">
      <div class="input-group text-input increment-input" [ngClass]="{valid: userInput}">
        <div class="label" *ngIf="label">
          <label [for]="random+'text-inputId'" class="input-label">
            {{label | translate}}
            <span class="mandatory font-danger-small" *ngIf="required">*</span>
          </label>
        </div>
        <div class="value increment-container"
          [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}">
          <div class="set-row">
            <div class="set-col col-size-3">
              <button color="customer-light" (click)="decrementCounter()"
                [disabled]="(minIncrement||minIncrement==0) && (userInput <= minIncrement)">
                <span class="icon-char"> - </span>
              </button>
            </div>
            <div class="set-col col-size-6">
              <input name="inputname" [(ngModel)]="userInput" [ngModel]="userInput === 0 ? '' : userInput" inputmode="numeric" type="text" [id]="random+'text-inputId'"
                (input)="onTextInput($event)" [readonly]="incrementonly || readonly" autocomplete="off" /></div>
            <div class="set-col col-size-3">
              <button color="customer-light" (click)="incrementCounter()"
                [disabled]="(maxIncrement || maxIncrement == 0 ) && userInput >= maxIncrement">
                <span class="icon-char"> + </span>
              </button>
            </div>
          </div>
    
        </div>
      </div>
    </ng-container>
  <ng-container *ngIf="type=='number'">
    <div class="input-group text-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'text-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}">
        <input name="inputname" [(ngModel)]="userInput" [placeholder]="'plhd_input' | translate :'LABELS' : null:null : placeholderPayload" [type]="'text'" [id]="random+'text-inputId'"
          (input)="onTextInput($event)" [readonly]="readonly" autocomplete="off" autocapitalize="true"
          [maxlength]="maxLength>0?maxLength:null"  autocomplete="off" [minlength]="minLength>0?minLength:null" (blur)="inputBlur()"/>
        <ng-container *ngIf="icon">
          <img class="suffix-icon" [src]="icon" />
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='name'">
    <div class="input-group text-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'text-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}">
        <input name="inputname" [(ngModel)]="userInput" [placeholder]="'plhd_input' | translate :'LABELS' : null:null : placeholderPayload" type="text" [id]="random+'text-inputId'"
          (input)="onNameInput($event)" [readonly]="readonly" autocomplete="off" autocapitalize="true"
          [maxlength]="maxLength>0?maxLength:null" [minlength]="minLength>0?minLength:null" (blur)="inputBlur()"/>
        <!-- [pattern]="nameRegex" -->
        <ng-container *ngIf="icon">
          <img class="suffix-icon" [src]="icon" />
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='password'">
    <div class="input-group text-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'pass-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError}">
        <input name="inputname" [(ngModel)]="userInput" [placeholder]="'plhd_input' | translate :'LABELS' : null:null : placeholderPayload" type="password" [id]="random+'pass-inputId'"
          (input)="onTextInput($event)" />
        <!-- <app-form-input-error [control]="registerForm.controls.guardianName"></app-form-input-error> -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='date'">
    <div class="input-group date-input" [ngClass]="{valid: selectedDate}">
      <div class="label">
        <label [for]="random+'date-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value datepicker-overlay" [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}" style="max-height: 2.8em;" *ngIf="!preferWheel || true">
        <vaadin-date-picker [value]="formDate" [placeholder]="'plhd_date' | translate :'LABELS' : null:null : placeholderPayload" class="vaadin-date"
          [id]="random+'date-inputId'" [max]="dateMax" (change)="vaadinChange($event)"
          [ngModelOptions]="{standalone: true}" #datePicker [disabled]="readonly">
        </vaadin-date-picker>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='select'">
    <div class="input-group select-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'sel-inputId'" class="input-label">
          {{label}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError}">
        <select (input)="onTextInput($event)" [(ngModel)]="userInput"
          (change)="selectOptionChange($event)" autocomplete="off" (blur)="onBlur()" (focus)="onFocus()" [ngClass]="{'cursor-pointer': !search}">
          <option value="" disabled selected>{{'plhd_select' | translate :'LABELS' : null:null : placeholderPayload}}</option>
          <option *ngFor="let x of selectList">{{( x['desc'] || x['countryDesc'] || x['value']| translate : 'LOCALE' :
            'name' ||
            joinString([x['townDesc'], x['cityDesc'], x['stateDesc']]))}}</option>
        </select>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='label'">
    <div class="input-group select-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'cus-sel-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='select_dobCal'">
    <div class="input-group default_radio" [ngClass]="{valid: userInput}">
      <div class="label">
        <label class="group-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value">
        <div class="radio-group">
          <div class="radio" [ngClass]="{'selected': item[radioOptions.value]==userInput}"
            *ngFor="let item of radioList">
            <input type="radio" [id]="random+item[radioOptions.name]" (change)="selectValue(userInput)"
              [name]="random+item[radioOptions.name]" [(ngModel)]="userInput" [value]="item[radioOptions.value]"
              (change)="onTextInput($event)" />
            <label [ngClass]="{bold: item[radioOptions.value]==userInput}"
              (click)="userInput = item[radioOptions.value]; onTextInput();">
              {{item[radioOptions.name] | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='select-unit'">
    <div class="input-group select-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'sel-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value" [ngClass]="{'custom-error-input-css':hasError}">
        <input name="inputname" type="text" [id]="random+'sel-inputId'" (input)="onTextInput($event)" autocomplete="off"
          (blur)="onBlur()" (focus)="onFocus()" [readonly]="!search" [ngClass]="{'cursor-pointer': !search}"
          [ngModel]="selectedValue || value1[selectOptions.name]| translate"
          (ngModelChange)="value1[selectOptions.name]=$event" />
        <i class="dp-down-icon" name="caret-down-sharp" color="dark"></i>
      </div>
      <div class=" dropdown dropdown-unit" *ngIf="showOptions">
        <div class="dropdown-header">
          {{label | translate}}
        </div>
        <div class="dropdown-options" #content>
          <a class="dropdown-item" *ngFor="let item of selectList" (click)="selectOption(item)">
            <input type="radio" [id]="random+item[selectOptions.name]" [name]="random+item[selectOptions.name]"
              [value]="item[selectOptions.value]" [(ngModel)]="value1[selectOptions.value]"
              [checked]="item[selectOptions.value]==selectedValue" />
            <label [for]="random+item[selectOptions.name]">{{item[selectOptions.name] | translate }}</label>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='custom_radio'">
    <div class="input-group {{type}}" [ngClass]="{valid: userInput}">
      <div class="label">
        <label class="group-label">
          {{label}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value">
        <div class="radio-group">
          <div class="radio" [ngClass]="{'selected': item[radioOptions.value]==userInput}"
            *ngFor="let item of radioList">
            <input type="radio" [id]="random+item[radioOptions.name]" [name]="random+item[radioOptions.name]"
              [(ngModel)]="userInput" [value]="item[radioOptions.value]" (change)="onTextInput($event)" />
            <label [ngClass]="{bold: item[radioOptions.value]==userInput}"
              (click)="userInput = item[radioOptions.value]; onTextInput();">
              {{item[radioOptions.name]}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='default_radio'">
    <div class="input-group default_radio" [ngClass]="{valid: userInput}">
      <div class="label">
        <label class="group-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value">
        <div class="radio-group">
          <div class="radio" [ngClass]="{'selected': item[radioOptions.value]==userInput}"
            *ngFor="let item of radioList">
            <input type="radio" [id]="random+item[radioOptions.name]" (change)="selectValue(userInput)"
              [name]="random+item[radioOptions.name]" [(ngModel)]="userInput" [value]="item[radioOptions.value]"
              (change)="onTextInput($event)" />
            <label class="radio-label" [ngClass]="{bold: item[radioOptions.value]==userInput}"
              (click)="userInput = item[radioOptions.value]; onTextInput();">
              {{item[radioOptions.name] | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type == 'checkbox'">
    <div class="input-group {{type}}" [ngClass]="{valid: userInput}">
      <div class="label">
        <label class="group-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div >
        <div class="radio-group check-grp">
          <div class="radio grp-con"
            *ngFor="let item of radioList">
            <input [id]="random+'text-inputId'" type="checkbox" [(ngModel)]="item['checked']" [disabled]="readonly" (ngModelChange)="onCheckChange($event)">
            <label  [for]="random+'text-inputId'" class="check-label label">{{item[radioOptions.name] | translate}}</label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container  *ngIf="type=='country_select_img'">
    <div class="input-group select-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'cus-sel-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value">
        <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" [placeholder]="'plhd_select' | translate :'LABELS' : null:null : placeholderPayload" [disabled]="readonly" (ngModelChange)="onDpChange(selectedCountry)">
            <ng-template pTemplate="selectedItem" >
                <div class="flex align-items-center gap-2 display-label" *ngIf="selectedCountry && selectedCountry['code']">
                  <div class="country-flag {{selectedCountry['code']}}"></div> {{selectedCountry['name']}}
                </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2 display-label">
                    <div class="country-flag {{country['code']}}"></div> {{country['name']}}
                </div>
            </ng-template>
        </p-dropdown>
      </div>
    </div>
  </ng-container>
  <ng-container  *ngIf="type=='search_select'">
    <div class="input-group select-input" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'cus-sel-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value">
        <p-dropdown [options]="selectList" [(ngModel)]="userInput" [optionLabel]="selectOptions.name" [filter]="!selectOptions.hidesearch" [filterBy]="!selectOptions.hidesearch ? selectOptions.name: null" [showClear]="false" [placeholder]="'plhd_select' | translate :'LABELS' : null:null : placeholderPayload" [disabled]="readonly" (ngModelChange)="onDpChange(userInput)" appendTo="body">
            <ng-template pTemplate="selectedItem" >
                <div class="flex align-items-center gap-2 display-label" *ngIf="userInput && userInput[selectOptions.value]"> 
                  <ng-container *ngIf="selectOptions.translate">{{userInput[selectOptions.name] | translate}}</ng-container>
                  <ng-container *ngIf="selectOptions.locale">
                    {{userInput[selectOptions.name] | translate:'LOCALE': selectOptions.key }}
                  </ng-container>
                  <ng-container *ngIf="!selectOptions.translate && !selectOptions.locale">{{userInput[selectOptions.name]}}</ng-container>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div class="flex align-items-center gap-2 display-label">
                  <ng-container *ngIf="selectOptions.translate">{{item[selectOptions.name] | translate}}</ng-container>
                  <ng-container *ngIf="selectOptions.locale">
                    {{item[selectOptions.name] | translate:'LOCALE': selectOptions.key }}
                  </ng-container>
                  <ng-container *ngIf="!selectOptions.translate && !selectOptions.locale">{{item[selectOptions.name]}}</ng-container>
                </div>
            </ng-template>
        </p-dropdown>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='textarea'">
    <div class="input-group textarea" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'text-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value allowextendedheight"
        [ngClass]="{'custom-error-input-css':hasError,'input-field-disabled':readonly}">
        <div *ngIf="prefix" class="input-prefix bold">{{prefix}}</div>
        <textarea name="inputname" [(ngModel)]="userInput" [id]="random+'text-inputId'"
          (input)="onTextInput($event)" [readonly]="readonly" autocomplete="off" autocapitalize="true"
          [maxlength]="maxLength>0?maxLength:null" [minlength]="minLength>0?minLength:null" [ngClass]="{stopResize: !resize}"></textarea>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type=='rating'">
    <div class="input-group rating ignore-border" [ngClass]="{valid: userInput}">
      <div class="label">
        <label [for]="random+'text-inputId'" class="input-label">
          {{label | translate}}
          <span class="mandatory font-danger-small" *ngIf="required">*</span>
        </label>
      </div>
      <div class="value allowextendedheight">
        <div class="radio-group rating-group">
          <div class="radio" [ngClass]="{'selected': item[radioOptions.value]==userInput}" *ngFor="let item of radioList">
            <input type="radio" [id]="random+item[radioOptions.name]" [name]="random+item[radioOptions.name]"
              [(ngModel)]="userInput" [value]="item[radioOptions.value]" (change)="onTextInput($event)" />
            <label [ngClass]="{bold: item[radioOptions.value]==userInput}"
              (click)="userInput = item[radioOptions.value]; onTextInput();">
              <img class="smiley-image" [ngClass]="{selectedoption: item[radioOptions.value]==userInput}" [src]="item[radioOptions.value]==userInput ? item[radioOptions.selectedicon]:item[radioOptions.unselectedicon]"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
