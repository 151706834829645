import { Component, OnInit, HostListener } from '@angular/core';
import { Validators, AbstractControl, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ROUTE_CRM_HEALTH_RECORDS, ROUTE_CRM_CALENDAR, APP_VERSION, ROUTE_TASK, TOAST_SUCCESS, PATH_MATCH_FULL, ROUTE_LOGIN, ROUTE_CRM, ROUTE_CRM_HOME } from '../app.string';
import * as  Constant from '../app.const';
import * as AppConstantString from '../app.string';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PhoneNumberUtil } from "google-libphonenumber";
import { environment } from '@env/environment';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import { AutoLoginService } from '../services/auto-login.service';
import { UtilitiesService } from '../services/utilities.service';
import { GoogleTagsService } from '../services/google-tags.service';
import { loginType } from '@type';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ToastServiceService } from '../services/toast-service.service';
import * as _ from 'lodash';
import { ReCaptchaV3Service, OnExecuteData } from 'ng-recaptcha';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'app-login',
  templateUrl: environment['LOGIN_VERSION'] != 'TASK' ? './login.component.html' :'./task-login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [EventsService]
})
export class LoginComponent implements OnInit {

  public precheckForm: FormGroup;
  public mobileFormControl: FormControl;
  public passwordFormControl: FormControl;
  public pinFormControl: FormControl;
  public confirmPinFormControl: FormControl;
  public otpFormControl: FormControl;
  public loginForm: FormGroup;
  public ssoLoginForm: FormGroup;
  public signupForm: FormGroup;
  public signupContinueForm: FormGroup;
  public ssoSignUpForm: FormGroup;
  public prefilledSignupForm: FormGroup;
  public ssoVerifyOtpForm: FormGroup;
  public ssoForgotPwdForm: FormGroup;
  public ssoResetPwdForm: FormGroup;
  public remaindmeControl: FormControl;

  public showPin: boolean;
  public remaindme: boolean;
  public isPreCheck: boolean;
  public isLogin: boolean;
  public isRegister1: boolean;
  public isRegister2: boolean;
  public isSSOLogin: boolean;
  public isSSORegister: boolean;
  public externalInterface: boolean;
  public isSSOVerifyOtp: boolean;
  public isSSOForgotPassword: boolean;
  public isSSOResetPassword: boolean;

  public setMobile: string;
  public labelList: any;
  public languageList: any;
  public selectedLanguage: string;
  public isResetPin: boolean;
  private txId: string;
  public timerOn : boolean= true;
  public timeLeft = 60;
  public resendOTP: boolean = false;
  public isAuthorizeState: boolean = false;
  // public setLangExternally:any;
  public action : string;
  public aptrefno : string;
  public resource : string;
  public speciality : string;
  public apptType: string = environment['DEFAULT_VISIT_TYPE'];
  public facility: string = environment['DEFAULT_FACILITY']
  public locale: string = ''
  public curDate: string;
  public mobilevalidationError: any;
  public labelsReplace: any = {};

  public loginWithOTP: boolean;
  public loginWithContext: boolean = false;
  public loginType: loginType;
  public loginContext: any=null;
  public contextKey: string;
  public contextThroughSignIn: boolean = true;
  public validateError: boolean = false;
  public isValidating: boolean = false;
  public enableOnlyCommonView: boolean = false;
  public isSignOut: boolean = false;
  public switchLanguage: boolean = false;
  public regPatientOnSignup: boolean = false;
  public readonly versionNumber = APP_VERSION;
  public mobileJson = []
  public task_guidelines_label = null;
  public generateOTPLabel = [];
  public AppConstant = Constant;
  public signupPayload;
  public checkingAutoSignon: boolean = false;
  public isAuthenticate: boolean = false;
  public webViewState: false | 'NO_REFRESH_TOKEN' | 'ERR_REFRESH_TOKEN' | 'LOADING' | 'SUCCESS' = false;
  public isExtIdLogin: boolean = false;
  public isUnregisteredPatient: boolean = false;
  public isVerifyOtpForSignup: boolean = false;
  public prefillPersonDetails: any = {};
  public isRegisterWithPrefill: boolean = false;
  public showprefilldetails: boolean = true;
  public signInWithoutSignup: boolean = false;
  public isVerifyMobilenumber: boolean = false;
  public isPreCheckError: boolean = false;
  public resendSeconds: number = 0;
  public reportId: any;
  public patientId: any;

  //static conditions
  public extIdOtpAutoGen: boolean = true;
  public otpLoginWithoutRegisterForHOF: boolean = false;
  public otpLoginWithoutRegisterForMem: boolean = true;
  public verifyMobileNumberForSignin: boolean = true;
  public verifyMobileNumberForSignup: boolean = true;
  public otplength: number = 6;
  public maxOTPSendTime: number = 30;
  public captchaKey: string = null;
  public captchaVersion: 2 | 3 = null;
  public isCaptchaVerifyLogin: boolean = false;
  public isCaptchaVerifySignup: boolean = false;
  private captchaSubscription: any;
  slot: string;
  alias;
  constructor(private router: Router, private route: ActivatedRoute, private user: UserService, private auth: AuthService, private fb: FormBuilder, private autoLogin: AutoLoginService, private utils: UtilitiesService, private gtmService: GoogleTagsService, private featureService: FeatureFlagsService, private translate: TranslatePipe, private toast: ToastServiceService, private recaptchaV3Service: ReCaptchaV3Service,public events:EventsService) {
    this.showPin = false;
    this.remaindme = true;
    this.isPreCheck = false;
    this.isLogin = false;
    this.isSSOLogin = false;
    this.isRegister1 = false;
    this.isRegister2 = false;
    this.isSSORegister = false;
    this.isSSOVerifyOtp = false;
    this.languageList = new Array<any>();
    this.precheckForm = new FormGroup({});
    this.ssoForgotPwdForm = new FormGroup({});
    this.curDate = this.user.getMoment().format('YYYY-MM-DD');
    this.loginWithOTP = this.featureService.featureOn('FT_APP_LOGWITH_OTP');
    this.contextThroughSignIn = this.featureService.featureOn('FT_APP_CXTWITH_PRECHECK');
    this.enableOnlyCommonView = this.featureService.featureOn(this.AppConstant.FT_APP_OLY_CMN_VIEW);
    this.regPatientOnSignup = this.featureService.featureOn(this.AppConstant.FT_SSO_REGPAT_ON_SIGNUP);
    this.isExtIdLogin = this.featureService.featureOn('FT_APP_EXTIDLOGIN');
    this.isCaptchaVerifyLogin = this.featureService.featureOn('FT_APP_LOGINCAPTCHA');
    this.isCaptchaVerifySignup = this.featureService.featureOn('FT_APP_SIGNUPCAPTCHA');
    this.captchaVersion = this.user.getCaptchaVersion();
    this.captchaKey = this.user.getCaptchaKey(this.captchaVersion);
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isValidating) return;
    if (event['keyCode'] == 13) {
      if (this.isPreCheck && this.precheckForm.valid) {
        if (!this.isUnregisteredPatient && !this.isVerifyMobilenumber)
          this.precheck();
        if ((this.isUnregisteredPatient || this.isVerifyMobilenumber) && !this.isVerifyOtpForSignup)
          this.checkCaptcha('PRECHECK', this.isVerifyMobilenumber?'SIGNUP':null);
        if (this.isUnregisteredPatient && this.isVerifyOtpForSignup)
          this.handleVerifyOTP('PRECHECK', 'SIGNUP')
      }
      if (this.isLogin && this.loginForm.valid) {
        this.login();
      }
      if (this.isRegister1 && this.signupForm.valid && !this.isResetPin) {
        this.continueCreateAccount();
      }
      if (this.isRegister1 && this.signupForm.valid && this.isResetPin) {
        this.resetPin();
      }
      if (this.isRegister2 && this.signupForm.valid && this.signupContinueForm.valid) {
        this.createAccount();
      }
      if (this.isSSOLogin && this.ssoLoginForm.valid) {
        this.ssoLogin();
      }
      if (this.isSSORegister && this.ssoSignUpForm.valid) {
        this.ssoCreateAccount();
      }
      if(this.isSSOVerifyOtp && this.ssoVerifyOtpForm.valid){
        this.ssoAccountVerification();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (targetElement && document.getElementById('language-actions') && !document.getElementById('language-actions').contains(targetElement) && document.getElementById('language-action-options') && !document.getElementById('language-action-options').contains(targetElement)) {
      this.switchLanguageClose();
    }
  }

  private checkAutoSignon(params) {
    this.checkingAutoSignon = true
    this.user.getAutoSignon().subscribe(autoSignon => {
      if (autoSignon) {
        this.ssoRedirect(params.params)
      } else {
        this.checkingAutoSignon = false
      }
    }, err => {
      this.checkingAutoSignon = false
    })
  }

  ngOnInit() {
    let canActivate = this.autoLogin.canActivate();
    console.log('router', this.router)
    let iswebview = false;
    if (this.router.url.includes(AppConstantString.ROUTE_WEBVIEW)) {
      this.route.queryParams.subscribe(params => {
        const hash = params['hash'];
        const restParams = _.cloneDeep(params);
        delete restParams.hash;
        if (!hash) {
          this.webViewState = 'NO_REFRESH_TOKEN';
        } else {
          this.webViewState = 'LOADING';
          this.validateWebView(hash, restParams);
        }
      });
      iswebview = true;
    }
    this.route.queryParamMap.subscribe(params => {
      console.info('login queryParamMap', params);
      this.validateError = false;
      this.toggleScreen('PRECHECK')
      let sso = params.get('sso');
      this.action = params.get('action');
      this.reportId = params.get('reportid');
      this.patientId = params.get('patientid');
       this.aptrefno = params.get('aptrefno');
      let context = params.get('ctx');
      this.isSignOut = params.get('signout') == '1';
      this.loginWithContext = !!context;
      this.facility = params.get('facility') != null ? params.get('facility').toUpperCase() : this.facility
      this.resource = params.get('resource')
      this.speciality = params.get('speciality')
      this.slot = params.get('slot')
      this.apptType = params.get('type') != null ? params.get('type').toUpperCase() : this.apptType
      this.locale = params.get("locale") != null ? params.get('locale').toLowerCase() : null;
      this.alias = params.get('alias');
      let webview = params.get('appaccess') == 'Y';
      if (webview) {
        this.user.setAppType('webview')
      } else {
        this.user.setAppType('portal');
      }
      if (sso == 'Y') {
        let code = params.get('code');
        let state = params.get('state');
        this.validateSSORedirection(code, state);
        this.isAuthenticate = true;
        return;
      } else if (!this.isAuthenticate && !iswebview) {
        this.checkAutoSignon({ ...params })
      }
      if(!this.locale)
        this.locale = params.get("lang") != null ? params.get('lang').toLowerCase() : 'en';
      if (!this.isSSOLogin) {
        this.toggleScreen('PRECHECK')
        if (this.loginWithContext) {
          this.contextKey = params.get('key');
          this.getLoginContext(canActivate);
          // Set default language user preference(local storage), locale in URL, isDefault lang
          this.user.getLanguages().subscribe(data => {
            this.languageList = data;
            let userPreference = this.user.getStorage(AppConstantString.USER_LANGUAGE);
            userPreference = JSON.parse(userPreference);
            console.log("USER PREFERENCEEE--->", userPreference, this.user.currentLanguage);
            if (userPreference && userPreference['localeCode'] === this.user.currentLanguage) {
              // Nothing TO DO
            }
            else if (userPreference && userPreference['localeCode'] && userPreference['localeCode'] !== this.user.currentLanguage) {
              this.user.setDefaultLanguage(this.languageList, userPreference['localeCode']);
            } else if (!userPreference && this.locale && this.locale !== this.user.currentLanguage) {
              this.user.setDefaultLanguage(this.languageList, this.locale);
            } else if (!userPreference) {
              let lang = this.languageList.find(lan => lan.isDefault);
              this.user.setDefaultLanguage(this.languageList, lang['localeCode']);
            }
          });
        }
      } else if (params.get('locale') != null) {
        this.user.getLanguages().subscribe(data => {
          this.languageList = data;
        });
        if (this.locale != this.user.currentLanguage) {
          let lan = this.languageList.filter(item => {
            return item.localeCode == this.locale
          })
          this.user.setDirection(lan[0]);
        } else if (!canActivate) {
          this.initHomeNavigation()
        }
      }
      else if (!canActivate) {
        this.initHomeNavigation()
      }

    })
    this.user.getExternalInterface().subscribe(data => {
      this.externalInterface = data;
      if (this.externalInterface === true) {
        this.toggleScreen('SSOLOGIN')
      }
      else {
        this.toggleScreen('PRECHECK')
      }
    })
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });

    this.user.getUserInfo('personDetails').subscribe(data => {
      if (data && data['mobile'] && data['countryCode']) {
        this.buildForm(`+${data['countryCode']}`, data['mobile']);
      } else {
        this.buildForm();
      }
    });
    this.selectedLanguage = this.user.currentLanguage;
  }

  changeExternalLanguage(){
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });
    if(this.locale != this.user.currentLanguage ){
      let lan = this.languageList.filter( item =>{
        return item.localeCode == this.locale
      })
      this.user.setDirection(lan[0]);
    }
  }
  //Helpers
  validatePhone = (control: AbstractControl): { mobile: boolean } => {
    let phone = control.value;
    if (this.checkIfPhoneNumber(phone) || true)
      return null;
    return { mobile: true };
  };
  checkIfPhoneNumber = (phone: string) => {
    let validation: boolean = false;
    if (null == phone || typeof phone == undefined || phone.length < 5 || phone.match(/[a-z]/i)) {
      return validation;
    } else {
      try {
        var phoneUtil = PhoneNumberUtil.getInstance();
        var number = phoneUtil.parse(phone);
        validation = phoneUtil.isValidNumber(number);
        return validation;
      } catch (error) {
        console.error(error);
        this.mobilevalidationError = error;
        return validation;
      }
    }
  };
  matchPassword = (control: AbstractControl): { invalid: boolean } => {
    if (control.get('pin').value !== control.get('confirmPin').value) {
      return { invalid: true };
    }
  };
  phoneExtractor = (value: string, type: string): string => {
    let countryCode: string | number = '';
    if (value.match(/[a-z]/i))
      return '' + countryCode;
    try {
      var phoneUtil = PhoneNumberUtil.getInstance();
      var number = phoneUtil.parse(value);
      switch (type) {
        case 'MOBILE':
          countryCode = number.getNationalNumber();
          break;
        case 'COUNTRY_CODE':
          countryCode = number.getCountryCode();
          break;
      }
    } catch (error) {
      console.error(error);
    }
    console.log(countryCode)
    let retVal = countryCode.toString();
    if (type == 'MOBILE') {
      let numberLength = (value.length) - (number.getCountryCode().toString().length + 1) ;
      if (retVal.length != numberLength) {
        let prepend = new Array(numberLength - retVal.length).fill('0').join('')
        retVal = prepend+retVal
      }
    }
    return retVal;
  };
  private ageRangeValidator = (control: AbstractControl): { futureDate: boolean } => {
    if ( this.user.getMoment(control.value).isAfter(this.user.getMoment().format()) ) {
            return { futureDate : true };
        }
        return null;
  };
  //Methods
  public buildForm(countryCode: string = environment['COUNTRY_CODE'], mobile: string = '') {
    let defaultIdValidator:any = {
      mobile: new FormControl('', [
        Validators.required,
        this.validatePhone
      ])
    }
    if (this.isExtIdLogin) {
      defaultIdValidator = {
        natid: new FormControl('', [
          Validators.required,
        ])
      }
    }
    this.precheckForm = this.fb.group({
      mobile: this.isExtIdLogin ? undefined : new FormControl('', [Validators.required, this.validatePhone]),
      natid: this.isExtIdLogin ? new FormControl('', [Validators.required,]) : null
    });
    this.precheckForm.get('mobile').valueChanges.subscribe(data=>{
      console.log('MOBILE CHANGE', data)
    })
    this.loginForm = this.fb.group({
      mobile: this.isExtIdLogin ? undefined : new FormControl('', [Validators.required, this.validatePhone]),
      natid: this.isExtIdLogin ? new FormControl('', [Validators.required,]) : null,
      pin: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ])
    });
    this.signupForm = this.fb.group({
      mobile: this.isExtIdLogin ? undefined : new FormControl('', [Validators.required, this.validatePhone]),
      natid: this.isExtIdLogin ? new FormControl('', [Validators.required,]) : null,
      pin: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ]),
      otp: new FormControl('', [
        Validators.required,
        Validators.pattern('(|^)\\d{' + this.otplength+'}')
      ])
    }, { validators: this.matchPassword });
    this.signupContinueForm = this.fb.group({
      fname: new FormControl('', [
        Validators.required
      ]),
      lname: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      ]),
      accept: new FormControl('', [
        Validators.required,
      ])
    });

    //SSO Flow

    //SSO sign in existing user
    this.ssoLoginForm = this.fb.group({
      mobile: new FormControl('', [
        Validators.required,
        this.validatePhone
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ])
    });
    //SSO sign up new user to verify
    this.ssoSignUpForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      ]),
      mobile: new FormControl('', [
        Validators.required,
        this.validatePhone
      ]),
      fname: new FormControl('', [
        Validators.required
      ]),
      lname: new FormControl('', [
        Validators.required
      ]),
      guardianName: new FormControl('', [
        Validators.required
      ]),
      pin: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ]),
      gender: new FormControl('male', Validators.required),
      language: new FormControl('en', Validators.required),
    dob:  new FormControl('', [Validators.required, this.ageRangeValidator ]),
    }, { validators: this.matchPassword });

    //SSO sign up verifying new user based on otp
    this.ssoVerifyOtpForm = this.fb.group({
      otp: new FormControl('', [
        Validators.required,
        Validators.pattern('(|^)\\d{' + this.otplength +'}')
      ])
    });
    //SSO forgot password flow
    // a1.Forgot password request based on entered mobile number
    this.ssoForgotPwdForm = this.fb.group({
      mobile: new FormControl('', [
        Validators.required,
        this.validatePhone
      ])
    });
     // a2.SSO reset password based on otp verification from above step a1
     this.ssoResetPwdForm = this.fb.group({
      pin: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ]),
      otp: new FormControl('', [
        Validators.required,
        Validators.pattern('(|^)\\d{' + this.otplength +'}')
      ])
      }, { validators: this.matchPassword 
     });
    const passpolicy = this.user.getPasswordPolicy()
    this.prefilledSignupForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      ]),
      otp: new FormControl('', [
        Validators.required
      ]),
      pin: new FormControl('', [
        Validators.required,
        this.user.checkPassword(passpolicy)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ]),
    },{
      validators: this.matchPassword
    })
    


    let prefillMobile = `${countryCode}${mobile}`;
    this.ssoSignUpForm.patchValue({ mobile: prefillMobile });
    this.precheckForm.patchValue({ mobile: prefillMobile });
    this.ssoLoginForm.patchValue({ mobile: prefillMobile });
    this.ssoForgotPwdForm.patchValue({ mobile: prefillMobile });
    if (mobile) {
      this.loginForm.patchValue({ mobile: prefillMobile });
      this.signupForm.patchValue({ mobile: prefillMobile });
      this.ssoLoginForm.patchValue({ mobile: prefillMobile });
      // this.toggleScreen('LOGIN');
    }
  }

  public precheck() {
    // console.log(this.precheckForm);return
    let countryCode = null, mobile = null, natid = null, value = null;
    if (!this.isExtIdLogin) {
      value = this.precheckForm['value']['mobile'];
      countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
      mobile = this.phoneExtractor(value, 'MOBILE');
    } else {
      natid = this.precheckForm['value']['natid']
    }
    this.auth.preCheck(countryCode, mobile, natid).subscribe(data => {
      let isRegistered = data['registered'];
      let isHOF = data['isHof'] =='Y'
      this.labelsReplace.maskedMobileNumber = { mobile: data['maskedMobile'] }
      if (!this.isExtIdLogin) {
        this.loginForm.patchValue({ mobile: value });
        this.signupForm.patchValue({ mobile: value });
      } else {
        this.loginForm.patchValue({ natid: natid })
        this.signupForm.patchValue({ natid: natid })
      }
      if (isRegistered) {
        this.switchTo('PIN');
        this.toggleScreen('LOGIN');
      } else {
        if (!this.isExtIdLogin) {
          this.toggleScreen('REGISTER1');
        } else {
          if (this.verifyMobileNumberForSignup) {
            this.enableVerigyWIthMobile(isHOF)
          } else {
            this.enableOTPContext(isHOF);
          }
        }
      }
    }, err => {
      this.toggleScreen('PRECHECKERROR');
    });
  }

  public enableVerigyWIthMobile(isHOF) {
    this.isVerifyMobilenumber = true
    let cc = environment['COUNTRY_CODE'];
    this.precheckForm.addControl('verifymobile', new FormControl(cc, [Validators.required, this.validatePhone]))
    if (this.isCaptchaVerifySignup && this.captchaVersion == 2) {
      this.precheckForm.addControl('captcha', new FormControl(null, [Validators.required]))
    }
    this.signInWithoutSignup = (isHOF && this.otpLoginWithoutRegisterForHOF) || (!isHOF && this.otpLoginWithoutRegisterForMem)
  }

  public enableOTPContext(isHOF) {
    if (this.extIdOtpAutoGen) {
      this.sendOTP('PRECHECK');
      this.signInWithoutSignup = (isHOF && this.otpLoginWithoutRegisterForHOF) || (!isHOF && this.otpLoginWithoutRegisterForMem)
    } else {
      this.isUnregisteredPatient = true;
    }
  }

  public handlePrecheckNatIdClick() {
    this.toggleScreen('PRECHECK');
  }

  public forgetPin() {
    this.isResetPin = true;
    let value, countryCode, mobile, natid
    if (!this.isExtIdLogin) {
      value = this.loginForm['value']['mobile'];
      countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
      mobile = this.phoneExtractor(value, 'MOBILE');
    } else {
      natid = this.precheckForm['value']['natid']
    }
    this.auth.sendOTP(countryCode, mobile, natid).subscribe(data => {
      this.toggleScreen('REGISTER1');
    })
  }
  public continueCreateAccount() {
    this.toggleScreen('REGISTER2');
  }
  public createAccount() {
    let mobile, countryCode, patientId, value, natid;
    if (!this.loginWithContext) {
      if (!this.isExtIdLogin) {
        value = this.precheckForm['value']['mobile'];
        countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
        mobile = this.phoneExtractor(value, 'MOBILE');
      } else {
        natid = this.precheckForm['value']['natid']
      }
    } else {
      patientId = this.loginContext['patientId'];
    }
    let pin = this.signupForm['value']['pin'];
    let otp = this.signupForm['value']['otp'];
    let fname = this.signupContinueForm['value']['fname'];
    let lname = this.signupContinueForm['value']['lname'];
    let email = this.signupContinueForm['value']['email'];
    this.auth.signUp(countryCode, mobile, pin, otp, fname, lname, email, null, patientId, natid).subscribe(data => {
      data = { ...data, countryCode: countryCode, mobile: mobile, pin: null, natid: natid }
      this.gtmService.pushGTMEvent(null, 'signup', { user_id: data['personId'] });
      if (this.loginContext && this.loginContext.personName) {
        data.contextPersonName = this.loginContext.personName
      }
      console.log(data, this.loginContext)
      this.user.setLoginInfo(data, this.remaindme)
      if (!this.loginWithContext) {
        this.router.navigate([`../${this.user.getPortalBasePath()}`], { relativeTo: this.route });
      } else {
        this.navigateWithContext()
      }
    })
  }
  public login(source?: 'PRECHECK') {
    let pin, otp, mobile, countryCode, patientId, natid, value;
    if (!this.loginWithContext) {
      if (!this.isExtIdLogin) {
        value = this.precheckForm['value']['mobile'];
        countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
        mobile = this.phoneExtractor(value, 'MOBILE');
      } else {
        natid = this.precheckForm['value']['natid']
      }
    } else {
      patientId = this.loginContext['patientId'];
    }
    if (source != 'PRECHECK') {
      if (this.loginType == 'PIN') {
        pin = this.loginForm['controls']['pin']['value'];
        if (this.isAuthorizeState) {
          otp = this.loginForm['controls']['authOTP']['value'];
        }
      }
      if (this.loginType == 'OTP') {
        otp = this.loginForm['controls']['otp']['value']
      }
    } else if (source == 'PRECHECK') {
      otp = this.precheckForm['value']['otp']
    }
    this.auth.login(countryCode, mobile, pin, otp, patientId, natid).subscribe(data => {
      if (data.status == 'AUTHORIZE') {
        this.loginForm.addControl('authOTP', new FormControl('', [Validators.required, Validators.minLength(this.otplength), Validators.maxLength(this.otplength)]))
        this.isAuthorizeState = true;
      } else if (data.status == 'SIGNUP') {
        this.signupForm.patchValue({
          otp: otp
        });
        this.continueCreateAccount();
      } else {
        data = { ...data, countryCode: countryCode, mobile: mobile, pin: null, natid: natid }
        this.gtmService.pushGTMEvent(null, 'login', { user_id: data['personId'] });
        if (this.loginContext && this.loginContext.personName) {
          data.contextPersonName = this.loginContext.personName
        }
        console.log(data, this.loginContext)
        this.user.setLoginInfo(data, this.remaindme)
        if (!this.loginWithContext) {
          this.initHomeNavigation()
        } else {
          this.navigateWithContext()
        }
      }
    });
  }
  public toggleScreen(val) {
    this.isPreCheck = (val === 'PRECHECK');
    this.isLogin = (val === 'LOGIN');
    this.isRegister1 = (val === 'REGISTER1');
    this.isRegister2 = (val === 'REGISTER2');
    this.isSSOLogin = (val === 'SSOLOGIN');
    this.isSSORegister = (val === 'SSOREGISTER');
    this.isSSOVerifyOtp = (val === 'SSOVERIFYOTP');
    this.isSSOForgotPassword =( val === 'SSOFORGOTPASSWORD');
    this.isSSOResetPassword = (val === 'SSORESETPASSWORD');
    this.isRegisterWithPrefill = (val === 'REGISTERWITHPREFILL');
    this.isPreCheckError = (val === 'PRECHECKERROR');
    if (val == 'SSOFORGOTPASSWORD' && this.ssoLoginForm.get('mobile').value) {
      this.ssoForgotPwdForm.setValue({ mobile: this.ssoLoginForm.get('mobile').value })
    }
    if (val == 'PRECHECK') {
      if (this.isUnregisteredPatient) {
        this.isUnregisteredPatient = false;
        this.isVerifyOtpForSignup = false;
        this.precheckForm.removeControl('otp')
      }
      if (this.isVerifyMobilenumber) {
        this.isVerifyMobilenumber = false;
        this.precheckForm.removeControl('verifymobile')
        if (this.precheckForm.get('captcha'))
          this.precheckForm.removeControl('captcha')
      }
    }
  }
  public resetPin() {
    let value = this.signupForm['value']['mobile'];
    let mobile = this.phoneExtractor(value, 'MOBILE');
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let pin = this.signupForm['value']['pin'];
    let otp = this.signupForm['value']['otp'];
    this.auth.resetPin(countryCode, mobile, pin, otp).subscribe(_ => {
      this.toggleScreen('LOGIN');
      this.isResetPin = false;
    })
  }
  public openLink(type) {
    if(type == 'pp'){
      let base = 'https://healthp.nahdi.sa/static/';
      let link = `${base}${type}/${this.selectedLanguage}.html`;
      this.user.openLocation(link, "_blank");
    }
    if(type == 'cc'){
      let base = 'https://nahdicareclinics.sa/index.php';
      let link = `${base}/${this.selectedLanguage}/contact`;
      this.user.openLocation(link, "_blank");
    }
  }
  public selectLanguge(data){
    // this.user.setLanguage(data);
      this.user.setDirection(data);
  }
  public isNumber(evt:any) {
    let charCode = evt['which'] ? event['which'] : event['keyCode'];
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  public vaadinChange(event, key) {
    if(key == 'dob'){
        this.ssoSignUpForm.get('dob').setValidators(this.ageRangeValidator);
        this.ssoSignUpForm.get('dob').markAsDirty();

    }
    this.ssoSignUpForm.patchValue({
      [key]: this.user.getMoment(event['target']['__data'].value).format('YYYY-MM-DD')
    });
  }

  public mobileChange(event){
    this.ssoSignUpForm.get('mobile').setValidators(this.validatePhone);
    this.ssoSignUpForm.get('mobile').markAsDirty();
  }

  public emailChange(event){
    // this.ssoSignUpForm.get('email').setValidators(this.validatePhone);
    this.ssoSignUpForm.get('email').markAsDirty();
  }
  //sso methods
  public ssoLogin(refresh_token?) {
    let value, countryCode, mobile, password;
    if (!refresh_token) {
      value = this.ssoLoginForm['controls']['mobile']['value'];
      countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
      mobile = this.phoneExtractor(value, 'MOBILE');
      password = this.ssoLoginForm['controls']['password']['value'];
    }
    this.auth.ssoLogin(countryCode, mobile, password, refresh_token).subscribe(data => {
      this.user.setSSOCredentials(data, this.remaindme);
      this.gtmService.pushGTMEvent(null, 'login', { user_id: data['payload']['personId'] });
      let userMobile = data.userInfo.phone;
      countryCode = this.phoneExtractor(userMobile, 'COUNTRY_CODE');
      mobile = this.phoneExtractor(userMobile, 'MOBILE');
      this.user.setMandatoryConsent( data.userInfo.mandatoryConsent);
      this.user.setMarketingConsent(data.userInfo.marketingConsent);
      data = { ...data.payload, countryCode: countryCode, mobile: mobile, pin: null }
      if (this.loginContext && this.loginContext.personName) {
        data.contextPersonName = this.loginContext.personName
      }
      console.log(data, this.loginContext)
      this.user.setLoginInfo(data, this.remaindme);
      let needToRegisterPatient = this.user.setLoginInfo(data, this.remaindme);
      this.initHomeNavigation(needToRegisterPatient)
      this.webViewState = 'SUCCESS';
    }, err => {
      if (this.webViewState == 'LOADING') {
        this.webViewState = 'ERR_REFRESH_TOKEN'
      }
    });
  }

  public initSignup(payload) {
    this.signupPayload = payload
    let value = payload['mobileNum'];
    let mobile = this.phoneExtractor(value, 'MOBILE');
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    this.signupPayload.mobile = mobile;
    this.signupPayload.countryCode = countryCode;
    console.log(this.signupPayload)
    this.auth.ssoSignUp(this.signupPayload).subscribe(data => {
      this.txId = data.txId
      this.timeLeft = 60;
      this.resendOTP = false;
      this.startTimer()
      this.toggleScreen('SSOVERIFYOTP');
    })
  }
  public ssoCreateAccount() {
    let value = this.ssoSignUpForm['value']['mobile'];
    let mobile = this.phoneExtractor(value, 'MOBILE');
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let pin = this.ssoSignUpForm['value']['pin'];
    let fname = this.ssoSignUpForm['value']['fname'];
    let lname = this.ssoSignUpForm['value']['lname'];
    let email = this.ssoSignUpForm['value']['email'];
    let gender = this.ssoSignUpForm['value']['gender'];
    let locale = this.ssoSignUpForm['value']['language'];
    let dob = this.ssoSignUpForm['value']['dob'];
    this.setMobile = value;
    let payload = {
      mobile: mobile,
      countryCode: countryCode,
      password: pin,
      firstName: fname,
      lastName: lname,
      email: email,
      gender: gender,
      dob: dob,
      locale: locale,
      source: AppConstantString.PORTAL_SOURCE
    };
    this.auth.ssoSignUp(payload ).subscribe(data => {
        this.txId =data.txId
      data = { ...data, countryCode: countryCode, mobile: mobile, pin: null }
        this.timeLeft = 60;
        this.resendOTP = false;
        this.startTimer()
        this.toggleScreen('SSOVERIFYOTP');
    })
  }

  ssoAccountVerification(){
    let value =  this.ssoSignUpForm['value']['mobile'];
    let mobile = this.signupPayload ? this.signupPayload['mobile'] : this.phoneExtractor(value, 'MOBILE');
    let countryCode = this.signupPayload ? this.signupPayload['countryCode'] : this.phoneExtractor(value, 'COUNTRY_CODE');
    let pin = this.signupPayload ? this.signupPayload['password'] : this.ssoSignUpForm['value']['pin'];
    let otp = this.ssoVerifyOtpForm['value']['otp'];

    this.auth.activateAccount(this.txId, otp, countryCode, mobile, pin, this.signupPayload).subscribe(data => {
      this.user.setSSOCredentials(data, this.remaindme);
      data = { ...data.payload, countryCode: countryCode, mobile: mobile, pin: null }
      if (this.loginContext && this.loginContext.personName) {
        data.contextPersonName = this.loginContext.personName
      }
      console.log(data, this.loginContext)
      this.gtmService.pushGTMEvent(null, 'signup', { user_id: data['personId'] });
      this.user.setLoginInfo(data, this.remaindme);
      this.router.navigate([`../${this.user.getPortalBasePath()}`], { relativeTo: this.route });
    })
  }

  changeNumber(){
    this.ssoSignUpForm.patchValue({
      mobile: this.ssoSignUpForm['value']['mobile'],
      pin:this.ssoSignUpForm['value']['pin'],
      fname: this.ssoSignUpForm['value']['fname'],
      lname : this.ssoSignUpForm['value']['lname'],
      email : this.ssoSignUpForm['value']['email'],
      gender : this.ssoSignUpForm['value']['gender'],
      locale : this.ssoSignUpForm['value']['language'],
      dob: this.ssoSignUpForm['value']['dob']
    });
    this.ssoVerifyOtpForm.patchValue({
      otp: this.ssoVerifyOtpForm['value']['otp']
    })
    this.toggleScreen('SSOREGISTER')
  }

  public ssoForgotPassword() {
    // this.isResetPin = true;
    let value = this.ssoForgotPwdForm['value']['mobile'];
    this.setMobile = value;
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let mobile = this.phoneExtractor(value, 'MOBILE');
    this.auth.resetPasswordRequest(countryCode, mobile).subscribe(data => {
      this.txId = data.txId
      if(data.status === 'SUCCESS'){
        this.toggleScreen('SSORESETPASSWORD');
        this.timeLeft = 60;
        this.resendOTP = false;
        this.startTimer()
      }
    })
    // this.toggleScreen('SSORESETPASSWORD');
  }

  public ssoResetPassword() {
    let pin = this.ssoResetPwdForm['value']['pin']
    let otp = this.ssoResetPwdForm['value']['otp']
    this.auth.resetPassword(this.txId, otp, pin).subscribe(data => {
      if(data.status === 'SUCCESS'){
        this.toggleScreen('SSOLOGIN');
      }
    })
  }

  public ssoResendOTP() {
    this.auth.resendOTP(this.txId).subscribe(data => {
      if(data.status === 'SUCCESS'){
        this.txId = data.txId;
        this.timeLeft = 60;
        this.resendOTP = false;
        this.startTimer();
        // this.toggleScreen('SSOLOGIN');
      }
    })
  }

public startTimer() {

    setInterval(() => {
    if(this.timeLeft > 0) {
    this.timeLeft--;
    } else {
    // this.timeLeft = '';
      this.resendOTP = true;
    }
    },1000)
  }

  public openInfo() {
    // let base = 'https://nahdicareclinics.sa';
    // this.user.openLocation(base, "_blank");
    let link = environment['urlInfo']['index'];
    if(environment['urlInfo']['specifyLanguage'])
      link = this.utils.replaceLanguage(link, this.user.currentLanguage);
    this.user.openLocation(link, "_blank");
  }
  public switchTo(type: loginType) {
    if (type == 'OTP') {
      this.loginForm.addControl('otp', new FormControl(null, [Validators.minLength(this.otplength), Validators.maxLength(this.otplength), Validators.required]))
      this.loginType = type;
      this.loginForm.removeControl('pin');
      this.sendOTP();
    }
    if (type == 'PIN') {
      this.loginForm.addControl('pin', new FormControl(null,[Validators.minLength(4), Validators.required]))
      this.loginType = type;
      this.loginForm.removeControl('otp')
    }
  }

  // public sendOTP(isUserAction?) {
  //   if (!this.loginWithContext) {
  //     let value = this.loginForm['controls']['mobile']['value'];
  //     let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
  //     let mobile = this.phoneExtractor(value, 'MOBILE');
  //     this.auth.sendOTP(countryCode, mobile).subscribe()
  //   } else {
  //     this.auth.generateOTPforPatientId(this.loginContext['patientId'], this.loginContext['registered']).subscribe(data => {
  //       if (this.isPreCheck) {
  //         this.signupForm.get('mobile').setValidators(null);
  //         this.handleContext('OTP', true);
  //       }
  //       if (isUserAction)
  //         this.toast.showToast(null, data, TOAST_SUCCESS);
  //     })
  //   }
  // }
  public ngOnDestroy() {
    if (this.captchaSubscription) {
      this.captchaSubscription.unsubscribe();
    }
  }


  public checkCaptcha(context, captchacase:'SIGNUP'|'SIGNIN') {
    if (this.captchaVersion == 3 && ((captchacase == 'SIGNIN' && this.verifyMobileNumberForSignin) || (captchacase == 'SIGNUP' && this.verifyMobileNumberForSignup))) {
      this.isValidating = true;
      if (this.captchaSubscription) {
        this.captchaSubscription.unsubscribe();
      }
      this.captchaSubscription = this.recaptchaV3Service.execute(captchacase).subscribe((token) => {
        this.isValidating = false;
        switch (captchacase) {
          case 'SIGNUP':
            this.sendOTP(context, token)
            break;
        }
      }, err => {
        console.log(err);
      });
    } else {
      switch (captchacase) {
        case 'SIGNUP':
          this.sendOTP(context, null)
          break;
      }
    }
  }

  public sendOTP(context?:string, captcha?:string, isUserAction?) {
    if (!this.loginWithContext) {
      let value, countryCode, mobile, natid, isVerifyMobile: boolean;
      if (!this.isExtIdLogin) {
        value = this.loginForm['controls']['mobile']['value'];
        countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
        mobile = this.phoneExtractor(value, 'MOBILE');
      } else {
        natid = this.precheckForm['value']['natid']
      }
      if (this.isVerifyMobilenumber) {
        value = this.precheckForm['controls']['verifymobile']['value'];
        countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
        mobile = this.phoneExtractor(value, 'MOBILE');
        isVerifyMobile = true
        if (this.isCaptchaVerifySignup) {
          if (this.captchaVersion == 2) {
            captcha = this.precheckForm['controls']['captcha']['value']
          }
          else if (this.captchaVersion == 3) {
            
          }
            
        }
      }
      this.auth.sendOTP(countryCode, mobile, natid, isVerifyMobile, captcha).subscribe(_ => {
        if (context == 'PRECHECK') {
          this.precheckForm.addControl('otp', new FormControl('', [
            Validators.required,
            Validators.pattern('(|^)\\d{6}')
          ]))
          this.isUnregisteredPatient = true;
          this.isVerifyOtpForSignup = true;
        }
        this.resendSeconds = this.maxOTPSendTime;
        this.initMaxOTPSendTime()
      }, err => {
        this.toggleScreen('PRECHECKERROR');
      })
    } else {
      this.auth.generateOTPforPatientId(this.loginContext['patientId'], this.loginContext['registered']).subscribe(data => {
        if (this.isPreCheck) {
          this.signupForm.get('mobile').setValidators(null);
          this.handleContext('OTP', true);
        }
        if(isUserAction)
          this.toast.showToast(null, data, TOAST_SUCCESS);
      })
    }
  }

  public getLoginContext(canActivate) {
    this.isValidating = true;
    this.user.validatePatientView(this.contextKey).subscribe(response => {
      this.isValidating = false;
      let countryCode =  (`+${response.countryCode} ${response.maskedNumber}`).replace('++','+');
      let taskDesc = response.task_desc, contextGuidelines = null;
      if (response.task_desc) {
        let simplified = response.task_desc.replace(/\s/g, '_').toLowerCase()
        this.task_guidelines_label = `task_guidelines_${simplified}`
      }
      if (response.task_desc_locale) {
        taskDesc = this.translate.transform(response.task_desc_locale, 'LOCALE', 'text')
      } else {
        let taskNameLabel = response.task_desc.replace(/\s/g, '_');
        taskDesc = this.translate.transform(taskNameLabel, 'LABELS', 'text')
      }
      if (response.task_guidelines) {
        contextGuidelines = this.translate.transform(response.task_guidelines, 'LOCALE', 'text')
      }
      this.loginContext = {
        type: response.type,
        facilityId: response.facilityId,
        patientId: response.patientId,
        reportId: response.reportId,
        billNo: response.billNo,
        apptRefNo: response.apptRefNo,
        countryCode: (response.countryCode).replace('+',''),
        maskedNumber: response.maskedNumber,
        registered: response.registered,
        displayMaskedNumber:countryCode,
        enableOnlyCommonView: this.enableOnlyCommonView,
        taskId: response.taskId,
        taskKey: response.taskKey,
        personName: response.person_name,
        contextDesc: taskDesc,
        contextGuidelines: contextGuidelines,
        taskSettings: response.taskSettings
      }
      this.user.setTaskViewSettings(response.taskSettings)
      this.mobileJson = [{ key: 'mobile', with: (this.loginContext ? this.loginContext['displayMaskedNumber'] : '') }]
      this.generateOTPLabel = this.contrctMobileNumberLabels('Generate_otp_to');
      console.log("MOBILE JSON-->",this.mobileJson);
      this.handleContext('PIN', false);
    }, err => {
      this.isValidating = false;
      this.validateError = true;
    })
  }

  public handleContext(type:loginType = 'PIN', force:boolean = false) {
    if (!this.contextThroughSignIn || force) {
      let isRegistered = this.loginContext['registered'];
      if (isRegistered) {
        this.switchTo(type);
        this.toggleScreen('LOGIN');
        this.loginForm.get('mobile').setValidators(null);
      } else {
        this.auth.generateOTPforPatientId(this.loginContext['patientId'], isRegistered).subscribe(data => {
          if (environment['LOGIN_VERSION'] == 'TASK') {
            this.switchTo(type);
            this.loginForm.get('mobile').setValidators(null);
          }
          this.toggleScreen('REGISTER1');
        })
      }
    } else {

    }
  }

  public handleMobileEdit() {
    !this.loginWithContext ? this.toggleScreen('PRECHECK') : null;
    this.isUnregisteredPatient = false;
    this.prefillPersonDetails = {};
  }

  private navigateWithContext() {
    switch (this.loginContext.type.toUpperCase()) {
      case this.AppConstant.LOGIN_CONTEXT['REPORT']:
      case this.AppConstant.LOGIN_CONTEXT['BILL']:
        this.user.setLoginContext(this.loginContext, this.enableOnlyCommonView)
        this.router.navigate([`../${this.user.getPortalBasePath()}/${ROUTE_CRM_HEALTH_RECORDS}`], { queryParams: { ctx: true, key: this.contextKey }});
        break;
      case this.AppConstant.LOGIN_CONTEXT['APPOINTMENT']:
        this.user.setLoginContext(this.loginContext, this.enableOnlyCommonView)
        this.router.navigate([`../${this.user.getPortalBasePath()}/${ROUTE_CRM_CALENDAR}`], { queryParams: { ctx: true, key: this.contextKey } });
        break;
      case this.AppConstant.LOGIN_CONTEXT['TASK']:
        this.user.setLoginContext(this.loginContext, this.enableOnlyCommonView)
        this.router.navigate([`../${ROUTE_TASK}`], { queryParams: { ctx: true, key: this.contextKey } });
        break;
      default:
        this.router.navigate([`../${this.user.getPortalBasePath()}`], { queryParams: { } })
        break;
    }
  }

  public closeWindow() {
    this.user.closeWindow()
  }

  public switchLanguageAction() {
    if (this.switchLanguage) {
      this.switchLanguage = false;
    }
    else {
      this.switchLanguage = true;
    }
  }

  public switchLanguageClose() {
    this.switchLanguage = false;
  }

  private contrctMobileNumberLabels(key) {
    let result = [];
    let generateLabel = this.translate.transform(key, 'LABELS');
    let generateOTPLabelSplit = generateLabel.split('%');
    generateOTPLabelSplit.forEach(data => {
      if(!data)
        return;
      if(data === 'mobile')
        result.push({type: 'BDI', text: this.mobileJson[0]['with']});
      else
        result.push({type: 'SPAN', text: data});
    });
    return result;
  }

  private getCookie(cname) {
    // return document.cookie;
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  private validateWebView(hash, params) {
    this.auth.validateWebView(hash, params).subscribe(data => {
      console.log(data)
      this.webViewState = 'SUCCESS';
      this.gtmService.pushGTMEvent(null, 'login', { user_id: data['personId'] });
      this.user.setLoginInfo(data, this.remaindme)
      this.router.navigate([`../${this.user.getPortalBasePath()}`], { queryParams: data.additionalParams, relativeTo: this.route });
    }, err => {
      this.webViewState = 'ERR_REFRESH_TOKEN';
    })
  }

  public ssoRedirect(params) {
    this.auth.initSSORedirection(params);
  }

  private validateSSORedirection(code, state) {
    console.info('validateSSORedirection', code, state);
    this.checkingAutoSignon = true;
    this.auth.validateSSORedirection(code, state).subscribe(data => {
      this.user.setSSOCredentials(data, this.remaindme);
      this.gtmService.pushGTMEvent(null, 'login', { user_id: data['payload']['personId'] });
      if (this.loginContext && this.loginContext.personName) {
        data.contextPersonName = this.loginContext.personName
      }
      console.info('validateSSORedirection 12', data, this.loginContext)
      this.user.setLoginInfo(data.payload, this.remaindme, true);
      this.initHomeNavigation()
    })
  }
  public handleVerifyOTP(source: 'PRECHECK', selection: 'SIGNIN' | 'SIGNUP') {
    if (selection == 'SIGNUP') {
      this.verifyOTP(source)
    } else {
      this.login(source)
    }
  }

  public verifyOTP(source) {
    let natid = this.precheckForm['value']['natid'];
    let otp = this.precheckForm['value']['otp'];

    this.auth.verifyOTP(natid, otp).subscribe(data => {
      this.toggleScreen('REGISTERWITHPREFILL');
      this.signupForm.patchValue({ 'otp': otp })
      this.prefilledSignupForm.patchValue({ 'otp': otp })
      this.prefillPersonDetails = data
    })
  }
  public signupPrefilledForm() {
    let mobile, countryCode, patientId, value, natid;  
    natid = this.precheckForm['value']['natid']
    let pin = this.prefilledSignupForm['value']['pin'];
    let otp = this.prefilledSignupForm['value']['otp'];
    let email = this.prefilledSignupForm['value']['email'];
    this.auth.signUp(null, null, pin, otp, null, null, email, null, null, natid).subscribe(data => {
      data = { ...data, countryCode: countryCode, mobile: mobile, pin: null, natid: natid }
      this.gtmService.pushGTMEvent(null, 'signup', { user_id: data['personId'] });
      this.user.setLoginInfo(data, this.remaindme)
      if (!this.loginWithContext) {
        this.router.navigate([`../${ROUTE_CRM}`], { relativeTo: this.route });
      } else {
        this.navigateWithContext()
      }
    })
  }

  public handleBack() {
    if (this.isPreCheck) {
      if (this.isVerifyOtpForSignup) {
        this.precheckForm.removeControl('otp')
        this.isUnregisteredPatient = false;
        this.isVerifyOtpForSignup = false;
      } else {
        this.toggleScreen('PRECHECK')
      }
    } else {
      this.handleHome()
    }
  }

  public handleHome() {
    this.toggleScreen('PRECHECK')
  }

  public initMaxOTPSendTime() {
    setTimeout(_ => {
      this.resendSeconds -= 1;
      if (this.resendSeconds > 0) {
        this.initMaxOTPSendTime();
      }
    }, 1000)
  }

  private initHomeNavigation(needToRegisterPatient=false) {
    this.router.navigate([`../${this.user.getPortalBasePath()}`], { queryParams: { action: this.action, resource: this.resource, facility: this.facility, type: this.apptType, speciality: this.speciality,slot:this.slot, regPat: needToRegisterPatient ? 'Y' : 'N', aptrefno: this.aptrefno, alias:this.alias, patientid: this.patientId, reportid: this.reportId }, relativeTo: this.route });
  }
}
