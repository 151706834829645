<div class="topic-outcomes">
  <div class="topic-row main">
    <div class="score border-right">
      <p class="no-margin title">{{'Score'| translate}} </p>
      <p class="value bold">{{formService.calculateInterpretationScore(topic).score}}</p>
    </div>
    <div class="interpretation border-right">
      <p class="no-margin title">{{'Interpretation'| translate}}</p>
      <p class="no-margin value bold">{{formService.calculateInterpretationScore(topic).value}}</p>
    </div>
  </div>
  <ng-container *ngIf="topic && topic['results']">
    <div class="topic-row">
      <div class="results" *ngFor="let r of topic['results']">
        <p *ngIf="r['from'] && r['to']" class="no-margin result-title">{{r['from']}} - {{r['to']}}</p>
        <p *ngIf="!r['from'] && r['to']" class="no-margin result-title">{{r['to']}}</p>
        <p *ngIf="r['from'] && !r['to']" class="no-margin result-title">{{r['from']}}</p>
        <p class="no-margin result-value">{{r['name'] || r['value']}}</p>
      </div>
    </div>
  </ng-container>
</div>