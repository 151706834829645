import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicHomeComponent } from './express-clinic-home.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [ExpressClinicHomeComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, BrowserModule
  ],
  
  exports:[ExpressClinicHomeComponent]
})
export class ExpressClinicHomeModule { }
