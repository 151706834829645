import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageSavedCardsComponent } from './manage-saved-cards.component';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { PipesModule } from '../pipes/pipes.module';
import { NodataModule } from '../nodata/nodata.module';



@NgModule({
  declarations: [
    ManageSavedCardsComponent
  ],
  imports: [
    CommonModule, SkeletonLoaderModule, PipesModule, NodataModule
  ],
  exports: [
    ManageSavedCardsComponent
  ]
})
export class ManageSavedCardsModule { }
