<!-- <div id="hr-visit-history">
  <div class="card-layout">
    <div class="card">
      <div class="card-head bold border-bottom">
        <span class="bold">{{'ttl_visit_history'|translate:'LABELS'}}</span>
      </div>
      <div class="card-content">
        <div class="no-data" *ngIf="!visitHistory || (visitHistory| keyvalue)['length']==0">
          <app-nodata></app-nodata>
        </div>
        <ng-container *ngIf="visitHistory && (visitHistory| keyvalue)['length']>0">
          <div class="visit-group" *ngFor="let item of visitHistory | keyvalue">
            <div class="visit-content">
              <div class="visit-title">
                <span class="font-primary-small">
                  {{item.value[0].admnDate | moment : 'dddd, DD MMM YYYY'}}</span>
              </div>
              <div class="visit-cards">
                <div class="card-layout" *ngFor="let visit of item.value">
                  <div class="card cursor-pointer" (click)="updateSelectedVisit(visit)"
                    [ngClass]="{active: (selectedVisit && visit.encounterId==selectedVisit.encounterId)}">
                    <div class="head-row">
                      <div class="visit-type" *ngIf="visit['patientClass'] == 'IP'">
                        <img class="inactive-icon type-image" src="assets/icons/bed.svg" />
                        <img class="active-icon type-image" src="assets/icons/bed_white.svg" />
                        <span class="type-name font-primary-small">{{'in_patient'|translate:'LABELS'}}</span>
                      </div>
                      <div class="visit-type" *ngIf="visit['patientClass'] == 'OP'">
                        <img class="inactive-icon type-image" src="assets/icons/facility.svg" />
                        <img class="active-icon type-image" src="assets/icons/walk_white.svg" />
                        <span class="type-name font-primary-small">{{'out_patient_visit'|translate:'LABELS'}}</span>
                      </div>
                      <div class="visit-type" *ngIf="visit['patientClass'] == 'EM'">
                        <img class="inactive-icon type-image" src="assets/icons/ambulance.svg" />
                        <img class="active-icon type-image" src="assets/icons/ambulance_white.svg" />
                        <span class="type-name font-primary-small">{{'emergency'|translate:'LABELS'}}</span>
                      </div>
                      <div class="visit-time font-light-small">
                        {{visit.admnDate | moment : 'hh:mm a'}}
                      </div>
                    </div>
                    <div class="practitoner-detail">
                      <div class="image">
                        <app-person-avatar url=""> </app-person-avatar>
                      </div>
                      <div class="practitoner-content">
                        <div class="title bold"  *ngIf="visit && visit['practitionerId'] ">
                          {{(visit['practitionerId'].split('#').shift() | partitionerDetails:visit['facilityId']:'resourceName'| translate : 'LOCALE' : 'name')||(visit['practitionerId'].split('#').pop())}}
                        </div>
                        <div class="subtitle"  *ngIf="visit && visit['specialtyCode'] ">
                          {{(visit['specialtyCode'].split('#').shift() | specialityDetails:visit['facilityId']:'specialityName'| translate : 'LOCALE' : 'name')||visit['specialtyCode'].split('#').pop()}}
                        </div>
                      </div>
                    </div>
                    <div class="visit-footer" *ngIf="visit.LBIN || visit.RDIN || visit.CLNT">
                      <div class="actions">
                        <div class="option" *ngIf="visit.LBIN">
                          <img class="inactive-icon type-image" src="assets/icons/lab.svg" />
                          <img class="active-icon type-image" src="assets/icons/lab_white.svg" />
                        </div>
                        <div class="option" *ngIf="visit.RDIN">
                          <img class="inactive-icon type-image" src="assets/icons/xray.svg" />
                          <img class="active-icon type-image" src="assets/icons/xray_white.svg" />
                        </div>
                        <div class="option" *ngIf="visit.CLNT">
                          <img class="inactive-icon type-image" src="assets/icons/report.svg" />
                          <img class="active-icon type-image" src="assets/icons/report_white.svg" />
                        </div>
                      </div>
                      <div class="actions-right">

                      <div class="feedback font-default-small cursor-pointer" (click)="openFeedback(visit)"
                        [appRemoveFeatureOff]="'FT_APP_HR_VISIT_FEEDBACK'">
                        <span>
                          <img class="inactive-icon type-image" src="assets/icons/star.svg" />
                          <img class="active-icon type-image" src="assets/icons/star_white.svg" />
                          {{'leave_feedback'|translate}}
                        </span>
                      </div>
                      <div class="transcript-img" (click)="openTranscript(visit)">
                        <img src="assets/icons/chat/{{(selectedVisit && visit.encounterId==selectedVisit.encounterId) ? 'transcript_white.svg' : 'transcript.svg'}}" alt="">
                      </div>
                    </div>

                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<div class="hr-visit-list-wrapper sm-hidden">
  <div class="hr-visit-list-header">
    <div class="practitioner-name header-item">{{'Practitioner_name' | translate}}</div>
    <div class="dttm-item header-item">{{'Date_and_time' | translate}}</div>
    <div class="constype-item header-item">{{'Consultation_type' | translate}}</div>
    <div class="reports-item header-item">{{'Reports'}}</div>
  </div>
  <div class="hr-visit-list-content">
      <div class="hr-visit-list-item" *ngFor="let visit of allVisits" (click)="updateSelectedVisit(visit, null)">
        <div class="practitioner-name">
          <div class="image">
            <app-person-avatar url=""> </app-person-avatar>
          </div>
          <div class="practitoner-content">
            <div class="title"  *ngIf="visit && visit['practitionerId'] ">
              {{(visit['practitionerId'].split('#').shift() | partitionerDetails:visit['facilityId']:'resourceName'| translate : 'LOCALE' : 'name')||(visit['practitionerId'].split('#').pop())}}
            </div>
            <div class="subtitle"  *ngIf="visit && visit['specialtyCode'] ">
              {{(visit['specialtyCode'].split('#').shift() | specialityDetails:visit['facilityId']:'specialityName'| translate : 'LOCALE' : 'name')||visit['specialtyCode'].split('#').pop()}}
            </div>
          </div>
        </div>
        <div class="dttm-item">{{visit['admnDate'] | moment : 'D MMM YYYY, HH:mm A'}}</div>
        <div class="constype-item">
            <span *ngIf="visit['patientClass'] == 'IP'">{{'in_patient'|translate:'LABELS'}}</span>
            <span *ngIf="visit['patientClass'] == 'OP'">{{'out_patient_visit'|translate:'LABELS'}}</span>
            <span *ngIf="visit['patientClass'] == 'EM'">{{'emergency'|translate:'LABELS'}}</span>
        </div>
        <div class="reports-item">
            <img class="report-option" *ngIf="visit.LBIN" src="assets/icons/lab.svg" />
            <img class="report-option" *ngIf="visit.RDIN" src="assets/icons/xray.svg" />
            <img class="report-option" *ngIf="visit.CLNT" src="assets/icons/report.svg" />
        </div>
        <div class="hr-actions-bar">
            <div class="report-button" (click)="updateSelectedVisit(visit,'REPORTS');$event.stopPropagation()"><img src="./assets/icons/Health_records/reports.svg" alt="">{{'Reports' | translate}}</div>
            <div class="pres-button" (click)="updateSelectedVisit(visit,'PRES');$event.stopPropagation()"><img src="./assets/icons/Health_records/prescriptions.svg" alt="">{{'Prescriptions' | translate}}</div>
            <div class="bill-button" (click)="updateSelectedVisit(visit,'BILLS');$event.stopPropagation()"><img src="./assets/icons/Health_records/bills_pink.svg" alt="">{{'Bills' | translate}}</div>
        </div>
      </div>
  </div>
</div>

<div class="hr-visit-mobile-view">
  <div class="appt-list-view">
    <ng-container  *ngFor="let grp of visitHistory | keyvalue">
    <p class="month-date">
      {{grp.value[0].admnDate | moment : 'MMM YYYY'}}
    </p>
    <div class="appt-list-card" *ngFor="let visit of grp.value" (click)="updateSelectedVisit(visit, null)">
      <div  class="appt-card-hdr" 
      [ngClass]="{'video-header': visit['admissionType'].toLowerCase().includes('video'),  'hc-header': visit['patientClass'] == 'EM', 'clinic-header': (!visit['admissionType'].toLowerCase().includes('video') && (visit['patientClass'] == 'OP' || visit['patientClass'] == 'IP')) }">
        <span *ngIf="visit['admissionType'].toLowerCase().includes('video')" class="appt-img"> <img class="type-image" src="assets/icons/Appointments/video.svg" alt=""></span>
        <span *ngIf="visit['admissionType'].toLowerCase().includes('visit') && visit['patientClass'] == 'IP'" class="appt-img"> <img class="type-image" src="assets/icons/Appointments/clinic.svg" alt=""></span>
        <span *ngIf="visit['admissionType'].toLowerCase().includes('visit') && visit['patientClass'] == 'OP'" class="appt-img"> <img class="type-image" src="assets/icons/Appointments/walkin.svg" alt=""></span>
        <span *ngIf="visit['patientClass'] == 'EM'" class="appt-img"> <img class="type-image" src="assets/icons/ambulance.svg" alt=""></span>


        <span *ngIf="visit['patientClass'] == 'IP'">{{'in_patient'|translate:'LABELS'}}</span>
        <span *ngIf="visit['patientClass'] == 'OP'">{{'out_patient_visit'|translate:'LABELS'}}</span>
        <span *ngIf="visit['patientClass'] == 'EM'">{{'emergency'|translate:'LABELS'}}</span>
      </div>
      <div class="appointment-content">
        <div class="person-avatar-wrapper">
          <app-person-avatar [url]="visit.imageUrl"> </app-person-avatar>
        </div>
        <div class="appt-context">
          <div class="appt-title">{{(visit['practitionerId'].split('#').shift() | partitionerDetails:visit['facilityId']:'resourceName'| translate : 'LOCALE' : 'name')||(visit['practitionerId'].split('#').pop())}}</div>
          <div class="appt-subtitle"> {{(visit['specialtyCode'].split('#').shift() | specialityDetails:visit['facilityId']:'specialityName'| translate : 'LOCALE' : 'name')||visit['specialtyCode'].split('#').pop()}}</div>
        </div>
      </div>
      <div class="appointment-for-content">
        <p class="appt-for" *ngIf="false">
          <span *ngIf="visit['patientClass'] == 'IP'">{{'in_patient'|translate:'LABELS'}}</span>
          <span *ngIf="visit['patientClass'] == 'OP'">{{'out_patient_visit'|translate:'LABELS'}}</span>
          <span *ngIf="visit['patientClass'] == 'EM'">{{'emergency'|translate:'LABELS'}}</span>

          <span style="float:right"> 
            <img src="./assets/icons/Appointments/calendar.svg" alt=""> {{visit['admnDate'] | moment : 'D MMM YYYY, HH:mm A'}}
          </span>
        </p>
        <p class="appt-for">
          <span> 
            <img src="./assets/icons/Appointments/calendar.svg" alt=""> {{visit['admnDate'] | moment : 'D MMM YYYY, HH:mm A'}}
          </span>
        </p>
          <!-- <span class="bold">{{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name')}}</span> -->
      </div>
      <div class="appt-date-context" *ngIf="visit.LBIN || visit.RDIN || visit.CLNT">
        <div class="appt-date-section">
           {{'Reports'}} 
           <img class="report-option" *ngIf="visit.LBIN" src="assets/icons/lab.svg" />
           <img class="report-option" *ngIf="visit.RDIN" src="assets/icons/xray.svg" />
           <img class="report-option" *ngIf="visit.CLNT" src="assets/icons/report.svg" />
        </div>
      </div>
    </div>
    </ng-container>
    </div>
</div>
<app-patient-chat *ngIf="selectedForTranscript" [encounter]="selectedForTranscript" (close)="selectedForTranscript = null"></app-patient-chat>