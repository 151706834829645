<div id="todo">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img src="assets/icons/task.svg" />
        </div>
        <div class="title">{{'sub_todo' | translate}}</div>
      </div>
    </div>
    <div class="list">
      <app-list-item *ngFor="let item of sample">
        <img class="thumbnail" src="{{item.icon}}" />
        <span class="title">
          <img src="{{item.icon}}" />
          {{item.title}}</span>
        <span class="primary-content">
          <img [src]="item.time_icon">
          <span>{{item.time}}</span>
        </span>
        <span class='list-action'>
          {{item.action}}
        </span>
      </app-list-item>
    </div>
  </div>
</div>
