import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaskPipe } from 'src/app/pipes/mask.pipe';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthService } from 'src/app/services/auth.service';
import { CouponService } from 'src/app/services/coupon.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-promo-input',
  templateUrl: './promo-input.component.html',
  styleUrls: ['./promo-input.component.scss'],
  providers: [MaskPipe]
})
export class PromoInputComponent {
  public promocode: string = ''
  public maskedPromoCode: string = ''
  public promocodeApplied: boolean = false;
  public pointsApplied: boolean = false;
  public promocodeChecked: boolean = false;
  public pointsChecked: boolean = false;
  public promocodeChecking: boolean = false;
  public promocodeStatus: 'NONE' | 'SUCCESS' | 'FAILURE' = 'NONE';
  public pointsStatus: 'NONE' | 'SUCCESS' | 'FAILURE' = 'NONE';

  private applicablePromoCode: any = [];
  private promoInfo: any = [];
  private appliedPromoDtls: any = [];
  public appliedPoints: any = {}
  private isModalOpen: boolean = false;
  public pointsProps: any
  public showPointsModal: boolean = false;
  public showConsentModal: boolean = false;
  public consentDetails; any;
  public isConsent: boolean;
  public userInfo: any;

  @Output() appliedDiscount: EventEmitter<any>;
  @Output() applyingDiscount: EventEmitter<any>;
  @Input() patientPayable: string;
  @Input() selections: any;
  @Input() selectedServices: any;
  @Input() discountDetails: any;
  @Input() isEnablePromo: boolean;
  @Input() isEnablePoints: boolean;
  @Input() parsedChargeInfo:any;
  constructor(private appointmentService: AppointmentService, private user: UserService, private mask: MaskPipe, private couponService: CouponService, private authService: AuthService) {
    this.appliedDiscount = new EventEmitter<any>()
    this.applyingDiscount = new EventEmitter<any>()
    this.user.getConfigurationInfo.subscribe(data => {
      console.log('profile', data)
      this.consentDetails = data.profile.manageconcent;
    })
  }
  ngOnInit() {
    this.resetPromo()
    this.getUserProfile()
  }

  private getAvailablePromos() {
    let payLoad: any = {
      applyDiscountYn: "N",
      chargeStr: this.discountDetails && this.discountDetails.charge ? this.discountDetails.charge : '',
      facilityId: this.selections?.facility?.id || "",
      patientId: this.selections?.patient?.id || "",
      practitionerId: this.selections?.practitioner?.id || "",
      specialityId: this.selections?.speciality?.id || "",
      selectedServices: this.selectedServices ? this.selectedServices : null,
      isBackgroundProcess: true
    }
    this.couponService.getPromo(payLoad).subscribe(res => {
      this.promocodeChecking = false;
      if (res['status'] == "SUCCESS") {
        this.applicablePromoCode = res['data']['applicablepromocodedtls'];
        this.promoInfo = res['data']['promoInfo'];
        this.appliedPromoDtls = res['data']['appliedpromocodedtls']
      }
    }, err => {
      this.promocodeChecking = false;
    });
  }

  public onInput(event) {
    this.promocodeChecked = false;
  }

  private resetPromo() {
    this.promocode = ''
    this.maskedPromoCode = ''
    this.promocodeApplied = false
    this.promocodeChecked = false
    this.promocodeChecking = true
    this.promocodeStatus = 'NONE';
    this.applicablePromoCode = [];
    this.promoInfo = [];
    this.appliedPromoDtls = [];
    this.getAvailablePromos();
  }

  public checkPromoCode() {
    this.maskedPromoCode = this.mask.transform(this.promocode, 2, 1)
    this.promocodeStatus = 'NONE'
    this.promocodeChecking = true;
    this.applyingDiscount.emit({ status: true })
    this.recalculateVat()
  }

  public openPointsModal() {
    console.log('openPointsModal', this.isConsent, this.consentDetails.enableConcent)
    if(!this.isConsent && (this.consentDetails && this.consentDetails.enableConcent ==  "Y"))
      this.showConsentModal = true;
      else
      this.initPointsModal()
  }
  public async initPointsModal() {
    if (this.isModalOpen) return
    this.isModalOpen = true;
    this.applyingDiscount.emit({ status: true })
    this.pointsProps = {
      promoDetails: null,
      type: 'POINTS',
      patientPayable: this.patientPayable,
      appliedPoints: this.appliedPoints,
    }
    this.showPointsModal = true;
  }
  public completePoints(event) {
    this.showPointsModal = false;
    this.isModalOpen = false;
    console.log('event completePoints', event)
    if (event && event.type == 'APPLYPOINTS') {
      let data = event.payload;
      this.appliedPoints = data?.data || {};
      this.recalculateVat()
    } else {
      this.applyingDiscount.emit({ status: false })
    }
  }

  completeConsent(event) {
    console.log('event', event.type)
    if(event.type == "CLOSE") {
      this.showConsentModal = false;
    } else if(event.type == "CONSENT") {
      this.showConsentModal = false;
      this.isConsent = true;
      this.initPointsModal();
      this.updateProfile()
    }
  }

  public getUserProfile(val?) {
    if(!this.user.isLoggedIn()) return
    this.authService.getUserProfile().subscribe(data => {
      console.log('getUserProfile', data)
       this.userInfo = data
       this.isConsent = data.marketingConsent;
       this.user.setSSOObjectOnUpdate(data);
       console.log("updateUserProfile", data)
       if(val)
        this.user.setMarketingConsent(data.marketingConsent)
    })
  }

  public updateProfile() {
      let payload = {
        "personId": this.user.getPersonId(),
        "username": this.userInfo.username ? this.userInfo.username : this.userInfo.userName,
        "firstName":  this.userInfo.firstname ? this.userInfo.firstname : this.userInfo.firstName,
        "lastName": this.userInfo.lastname ? this.userInfo.lastname : this.userInfo.lastName,
        "gender": this.userInfo.gender,
        "locale": this.userInfo.language,
        "dob": this.userInfo.birth_date,
        "mandatoryConsent": this.userInfo.mandatoryConsent,
        "marketingConsent": this.isConsent,
       
      }
      this.authService.updateUserProfile(payload, 'CONSENT').subscribe(_ => {
        this.user.updateUserInfo({ personName: payload['firstName'] + " "+ payload['lastName']})
          this.showConsentModal = false;
          this.getUserProfile(true)
      })
    } 

  public removePromo() {
    this.applyingDiscount.emit({ status: true })
    this.resetPromo();
    this.recalculateVat()
  }
  public removeAllPoints() {
    this.applyingDiscount.emit({ status: true })
    this.pointsApplied = false;
    this.appliedPoints = {};
    this.recalculateVat()
  }

  private recalculateVat() {
    if (!this.promocode && !this.appliedPoints?.pointsDerivative?.amount) {
      this.appliedDiscount.emit({ type: 'REMOVE' })
      return;
    }
    const payload = {
      //unused
      applicablePromoCodeDtls: this.applicablePromoCode,
      practitionerId: this.selections?.practitioner?.id || "",
      //for validating and recalculating VAT
      facilityId: this.selections?.facility?.id || "",
      serviceCharge: this.patientPayable,
      netServiceCharge: this.patientPayable,
      cartValue: this.patientPayable,
      promoInfo: this.promoInfo,
      appliedPromoCodeDtls: this.appliedPromoDtls,
      applyDiscountYn: "Y",
      selectedPromoCodes: this.promocode,
      patientId: this.selections?.patient?.id || "",
      selectedServices: this.selectedServices ? this.selectedServices : null,
      chargeStr: this.discountDetails ? this.discountDetails.charge : "",
      specialityId: this.selections?.speciality?.id || "",
      //new attributes
      appliedPoints: this.appliedPoints,
    }
    this.couponService.validatePromoCode(payload).subscribe(res => {
      if (res['STATUS'] == "SUCCESS") {
        if (this.promocode) {
          this.promocodeChecked = true;
          if (res.data?.appliedpromocodedtls?.length > 0) {
            this.promocodeApplied = true;
            this.promocodeStatus = 'SUCCESS'
          } else {
            this.promocodeApplied = false;
            this.promocodeStatus = 'FAILURE'
          }
        }
        if (this.appliedPoints?.pointsDerivative?.amount>0) {
          this.pointsChecked = true;
          if (res.data?.pointsApplied?.amount > 0) {
            this.pointsApplied = true;
            this.pointsStatus = 'SUCCESS'
          } else {
            this.pointsApplied = false;
            this.pointsStatus = 'FAILURE'
            this.removeAllPoints()
          }
        }

        if (res && res.data.netservicecharge != null) {
          this.appliedDiscount.emit({ type: 'APPLY', data: res.data })
        } else {
          this.applyingDiscount.emit({ status: false })
        }

        this.promocodeChecking = false
      }
    }, (err) => {
      this.promocodeChecking = false;
    })
  }

  

  
}
