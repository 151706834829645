<div id="nodata-component" *ngIf="source !== 'COUPONS'">
  <span class="font-primary-title">{{'no_data_found'|translate:'LABELS'}}</span>
  <span class="font-light-default">{{ 'pls_try_again_later' |translate:'LABELS'}}</span>
</div>
<div class="no-data-wrap" [ngClass]="{'no-data-point-avail' : pointsView}" *ngIf="source == 'COUPONS'">
  <div class="no-content-data">
    <div class="center onspot-img-con">
      <img src="./assets/icons/Emptywallet.svg" alt="">
    </div>
    <div class="no-data-title">{{'NO_DATA_FOUND_TITLE' | translate}}</div>
    <ng-container *ngIf="availablePoints >= minimumExchangeEligibleNuhdeekPoints">
      <div class="exchange-points">
        <p class="exchange-points-label">
        {{'NO#DATA#EXCHANGE#NUHDEEK#POINTS#NAHDI#PHARMACY#APP' | translate}}
        </p>
      </div>
      <div class="button-bar">
        <button *ngIf="exchangeCouponsUrl" class="exchange-points-btn" size="block" fill="outline" (click)="exchangeCoupons(exchangeCouponsUrl)">
          <img src="assets/icons/nuhdeek/nuhdeekicon.svg" class="nudheek-icon">
          {{"NO#DATA#EXCHANGE#COUPONS" | translate}}
        </button>
      </div>
    </ng-container>
  </div>
</div>