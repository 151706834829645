import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home-feedback-card',
  templateUrl: './home-feedback-card.component.html',
  styleUrls: ['./home-feedback-card.component.scss']
})
export class HomeFeedbackCardComponent {
  @Output() openFeedbackForm: EventEmitter<any>;
  constructor() { 
    this.openFeedbackForm = new EventEmitter<any>();
  }
  openFeedback() {
    this.openFeedbackForm.emit();
  }
}
