import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewProfileComponent } from './view-profile.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [ViewProfileComponent],
  imports: [
    CommonModule, PersonAvatarModule, PipesModule
  ],
  exports:[
    ViewProfileComponent
  ]
})
export class ViewProfileModule { }
