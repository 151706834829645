<div class="longtext">
  <div class="rich-text-editor">
    <div class="rich-text-title-bar">
      <div class="rich-text-title-desc">
        <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
      </div>
      <div class="rich-text-title-lan" *ngIf="section.template && section.template.length">
          <span *ngFor="let template of section.template" [ngClass]="{'rich-text-sel-lan':template.templatelanguage===section._selectedTemplateLanguage}" (click)="lanSwitch(template)">{{template.templatelanguage}}</span>
      </div>
    </div>
    <div class="rich-text-content" #richTextContent></div>
  </div>
  <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
      <p>{{'form_field_mandatory' | translate}}</p>
  </div>
</div>