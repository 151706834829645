<div id="health-pkg-flow" class="right-nav showModal">
    <div class="component">
          <ng-container *ngIf="!isRegisterPatient">
            <ng-container [ngSwitch]="index">
                <ng-container *ngSwitchCase="0">
                    <app-health-package [pkgdetails]="pkgdetails" (close)="checkAction('CLOSE')" (next)="toggleState($event)"></app-health-package> 
                </ng-container>
                <ng-container *ngSwitchCase="1">
                <app-health-package-details [selectedPkg]="input['selectedPkg']" (next)="toggleState($event)" (action)="checkAction($event)"></app-health-package-details>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                <app-list-facility [facilityList]="input['facilityList']" (next)="toggleState($event)" (action)="checkAction($event)"></app-list-facility>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <div id="list-patient-component">
                        <div class="header border-bottom spac-btw">
                            <div class="flex">
                                <div (click)="checkAction('BACK')" class="float-left closeicon pointer">
                                    <img src="./assets/icons/arrow.svg">
                                </div>
                                <div class="font-primary-title bold">{{ 'title_select_patient'|translate:'LABELS'}} </div>
                            </div>
                            <div (click)="checkAction('CLOSE')" class="float-right closeicon pointer">
                                <img src="./assets/icons/close_black.svg">
                            </div>
                        </div>
                        <div class="patient-modal-content has-footer">
                            <app-patient-list [linkedPatients]="linkedPatients" [selectedPkg]="input['selectedPkg']" [source]="'PACKAGE'" [packageFacilityId]="input['selectedFacility'] ? input['selectedFacility']['facilityId'] : null" (selcetedPatient)="choosePat($event)" [_selectedPatient]="selectedPatient" ></app-patient-list>
                        </div>
                        <div class="modal-footer">
                            <div class="btn-container">
                                <ng-container  *ngIf="!selectedPatientWithAppt">
                                    <button class="primary-button-outline" (click)="openModal('isRegisterPatient')">{{'hlthpkg_reg_new_pat' | translate}}</button>
                                    <button class="primary-button" [disabled]="!selectedPatient" (click)="setSelectedPatient(selectedPatient)">{{'hlthpkg_continue' | translate}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="4">
                    <div id="list-patient-component">
                        <div class="header border-bottom spac-btw">
                            <div class="flex">
                                <div (click)="checkAction('BACK')" class="float-left closeicon pointer">
                                    <img src="./assets/icons/arrow.svg">
                                </div>
                                <div class="font-primary-title bold">{{ 'title_select_time_slot'|translate:'LABELS'}} </div>
                            </div>
                            <div (click)="checkAction('CLOSE')" class="float-right closeicon pointer">
                                <img src="./assets/icons/close_black.svg">
                            </div>
                        </div>
                        <div class="patient-modal-content">
                            <app-choose-slot [_input]="input" [visitType]="'PACKAGE'" 
                            (complete)="setSlot($event)" [isStandalone]="true"></app-choose-slot>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="5">
                    <app-health-package-confirm-request [requestInfo]="requestInfo" [reqConfirm]="reqConfirm" (action)="checkAction($event)" (switchAction)="editAction($event)"></app-health-package-confirm-request>
                </ng-container>
            </ng-container>
            </ng-container>
          <ng-container *ngIf="isRegisterPatient">
            <app-register-new-patient *ngIf="isRegisterPatient" (close)="closeRegPatient($event)"></app-register-new-patient>
          </ng-container>
    </div>
</div>
