<div class="ui form">
  <div class="grouped fields">
    <p class="check-label"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span> <sup *ngIf="section['mandatory']=='true'"
        style="color: red">*</sup></p>
    <div>

      <div class="center">
        <!-- <file-drop [showBrowseBtn]="true" browseBtnLabel="Browse Files" dropZoneLabel="Drop files here"
          (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        </file-drop> -->
        <file-drop dropZoneClassName="drop-zone" browseBtnClassName="browser-button"
          contentClassName="drop-zone-content" [showBrowseBtn]="true" (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
          <div style="align-items:center;">
            <span style="float:left;">
              <img class="type-image" src="assets/icon/upload.svg">
            </span>
            <div style="padding-left:30px; display:inline-block;">
              <div>{{'drag_and_drop_to_upload' | translate}}</div>
              <div>{{'or' | translate}} <a (click)="openBrowse()" class="browse-link cursor-pointer">{{'lbl_browse' | translate}}</a> {{'to_choose_the_files' | translate}}</div>
            </div>
          </div>
        </file-drop>
        <div class="upload-items cursor-pointer" *ngFor="let item of files; let i=index" (click)="openPreview(item)">
          <span>{{i+1}}. {{ item.relativePath }}</span>
          <button *ngIf="!item['uploaded']" (click)="removeFile(i)" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <button *ngIf="item['uploaded']" type="button" class="close" aria-label="Close">
            <img class="type-image" src="assets/icon/doneIcon.svg">
          </button>

        </div>
      </div>
      <div class="mandatory right"
        *ngIf="(section['mandatory']=='true' && section['selectedFile'] && section['selectedFile'].length === 0)">
        <p>{{'form_field_mandatory' | translate}}</p>
      </div>
    </div>
  </div>