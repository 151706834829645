import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-directive-linked-print-preview',
  templateUrl: './directive-linked-print-preview.component.html',
  styleUrls: ['./directive-linked-print-preview.component.scss']
})
export class DirectiveLinkedPrintPreviewComponent implements OnInit {
  @Input() public s: any;
  public presHeaders: any;
  public presValues: any;
  public vitalHeaders: any;
  public orderData: any = [];
  public codify: boolean;
  public customAnswer: string;
  constructor(private utils: UtilitiesService) { }

  ngOnInit() {
    this.initData();
  }

  private initData() {
    this.codify = this.s.codify === 'Y';
    this.customAnswer = this.getAnswerType();
    if (this.s._isPrescription) {
      let domainCodes = [];
      let values: any = {};
      this.s.selectedValues.forEach(sv => {
        sv.values.forEach(d => {
          domainCodes.push({ name: d.name, code: d.code });
          if (values[sv.id]) {
            values[sv.id][d.code] = d.parsedValue || d.displayTxt || d.values;
          } else {
            values[sv.id] = { [d.code]: d.parsedValue || d.displayTxt || d.values };
          }
        });
      });
      this.presHeaders = _.uniqBy(domainCodes, 'code');
      // this.presHeaders = domainCodes.filter((item, pos) => {
      //   return (domainCodes.indexOf(codes => {
      //     codes['code'] == item['code'];
      //   }) == pos);
      // })
      this.presValues = values;
    }
    if (this.s._isVital) {
      if (!this.s._isVitalDisplayType) this.s._isVitalDisplayType = 'INLINE';
      if (this.s._isVitalDisplayType === 'TABLE') {
        this.vitalHeaders = [
          { name: "Title", code: "eventDesc" },
          { name: "Reading", code: "resultNum" },
          { name: "Unit", code: "uom" },
          { name: "Stage", code: "normalcyInd" }
        ];
      }
    }
    if (this.s._isOrder && !this.s._isPrescription) {
      if (Array.isArray(this.s.selectedValues) && this.s.domainControls && this.s.domainControls.directives) {
        let groupedOrders = this.utils.groupData(this.s.selectedValues, i => i['directiveCode']);
        let directiveNames = {};
        this.s.domainControls.directives.forEach(d => {
          directiveNames[d['directiveCode']] = d['directiveName'];
        });
        groupedOrders.map(gd => {
          gd[0]['directiveName'] = directiveNames[gd[0]['directiveCode']]
        });
        this.orderData = groupedOrders;
      }
    }
  }
  private getAnswerType() {
    if (this.s._isPrescription) return 'PRES';
    if (this.s._isVital) return 'VITAL';
    if (this.s._isOrder) return 'ORDER';
    if (this.s._isSingleInstance) return 'SINGLE';
    return null;
  }
}
