<div id="onspot-parent">
  <div class="hdr-component" *ngIf="state != 'ON_ACCEPT'">
    <div class="header border-bottom">
      <div class="header-row">
        <div (click)="navigateToPreviousTopic()" *ngIf="state != 'CHOOSE_SPECIALITY'" class="closeicon pointer hide">
          <img src="./assets/icons/calendar/arrow.svg" alt="">
        </div>
        <div class="header-font bold">{{'sub_schedule_consult_doctor_now'|translate}}
        </div>
      </div>
      <div (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
  </div>
  <div id="onspot-consult-doctor"
    [ngClass]="{'accept-container': state == 'ON_ACCEPT', 'pract-container': state != 'ON_ACCEPT' && state != 'CHOOSE_SPECIALITY', 'adjust-height':state == 'PAYMENT_CHECKOUT' }">
    <div class="component" [ngSwitch]="state">
      <ng-container *ngSwitchCase="'CHOOSE_SPECIALITY'">
        <ng-container [ngTemplateOutlet]="CHOOSE_SPECIALITY"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CHOOSE_PATIENT'">
        <ng-container [ngTemplateOutlet]="CHOOSEPATIENT"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CHOOSE_TEAM'">
        <ng-container [ngTemplateOutlet]="CHOOSE_TEAM"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_AVAILABLE'">
        <ng-container [ngTemplateOutlet]="ON_AVAILABLE"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_BUSY'">
        <ng-container [ngTemplateOutlet]="ON_BUSY"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_UNAVAILABLE'">
        <ng-container [ngTemplateOutlet]="ON_UNAVAILABLE"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_CONSULTATION'">
        <ng-container [ngTemplateOutlet]="ON_CONSULTATION"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_QUEUE'">
        <ng-container [ngTemplateOutlet]="ON_QUEUE"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_ACCEPT'">
        <ng-container [ngTemplateOutlet]="ON_ACCEPT"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'PAYMENT_CHECKOUT'">
        <app-payment-checkout></app-payment-checkout>
      </ng-container>
      <ng-container *ngSwitchCase="'REDIRECT'">
        <div class="margin-auto">
          {{ 'onspot_redirect_msg' | translate }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'FAIL_PAY'">
        <ng-container [ngTemplateOutlet]="FAIL_PAY"></ng-container>
      </ng-container>
    </div>
  </div>
  <div class="footer" [ngSwitch]="state" *ngIf="state != 'ON_ACCEPT' && state != 'CHOOSE_SPECIALITY'"
    [ngClass]="{ 'hide-footer':state == 'PAYMENT_CHECKOUT'}">
    <div class="button-bar" [ngClass]="{ 'clm-rev': ['ON_UNAVAILABLE', 'ON_BUSY'].includes(state) ? state : !state}">
      <ng-container *ngSwitchCase="'CHOOSE_PATIENT'">
        <ng-container *ngIf="!selectedPatientWithAppt">
          <button class="primary-button-outline"
            (click)="openModal('isRegisterPatient')">{{'onspot_consultation_reg_new_pat' | translate :
            'LABELS'}}</button>
          <button class="primary-button" [disabled]="!selectedPatient"
            (click)="requestConsultation(selectedPatient)">{{'onspot_consultation_continue' | translate:
            'LABELS'}}</button>
        </ng-container>
        <ng-container *ngIf="selectedPatientWithAppt">
          <button class="primary-button" (click)="proceedOngoingAppt()">{{'resume_consultation' | translate}}</button>
          <button class="primary-button-outline"
            (click)="requestConsultation(selectedPatientWithAppt)">{{'request_new_consultation' | translate}}</button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_AVAILABLE'">
        <button class="primary-button-outline"
          (click)="initCancelRequest()">{{'onspot_button_cancel_request'|translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_QUEUE'">
        <button class="primary-button-outline"
          (click)="cancelRequest()">{{'onspot_button_exit_queue'|translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="['ON_UNAVAILABLE', 'ON_BUSY'].includes(state) ? state : !state">
        <button class="primary-button-outline" (click)="closeModel()">{{'onspot_button_back_to_home' |
          translate}}</button>
        <button class="primary-button" (click)="chooseType('VIDEO')">{{'onspot_button_schedule_video_consulation' |
          translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'ON_CONSULTATION'">
        <div class="subtitle show aln-center">{{'onspot_lbl_inconsult_schedule_options'|translate:'LABELS'}}</div>
        <button class="primary-button-outline"
          (click)="chooseType('VIDEO')">{{'onspot_button_schedule_video_consulation' | translate}}</button>
        <button class="primary-button hide" (click)="goToQueue('QUEUE')">
          {{'onspot_button_join_queue'|translate}}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<app-appointment-overlap-modal *ngIf="apptOverlapView" (goBack)="goHome()"
  (confirm)="continue()"></app-appointment-overlap-modal>


<ng-template #CHOOSE_SPECIALITY>
  <div class="center-align" *ngIf="false">
    <div class="header-font bold padding">
      {{'onspot_our_doctors_online_now'|translate}}
    </div>
    <span class="img-bg"><img class="" src="assets/icons/onspot/onspot copy 6.svg"></span>
    <p><span class="subtitle"> {{'onspot_consultation_fee'|translate}}</span>
      <span class="primary-large bold"> {{ consultationCost['charge'] }} {{
        consultationCost['chargeCurrency']}}</span>
    </p>
  </div>
  <div class="banner">
    <div class="primary-banner">
      <div class="font-white-title bold banner-title">{{'CONSULTNOW#SPEC#BANNER#TITLE' | translate}}</div>
      <div class="font-white-default">{{'CONSULTNOW#SPEC#BANNER#SUBTITLE' | translate}}</div>
    </div>
    <div class="secondary-banner">
      <img class="start-icon" src="assets/icons/info.svg">
      <div class="banner-subtitle">{{'Waiting_time_is_indicative' | translate}}</div>
      <button (click)="refreshSpeciality()" class="refresh-button">{{'Refresh' | translate}}</button>
    </div>
  </div>
  <div class="patient-container">
    <div class="speciality-list">
      <ng-container *ngIf="!isGettingSpeciality; else LOADSPECIALITY">
        <ng-container *ngIf="!noSpeciality">
          <ng-container *ngFor="let group of specialityList">
            <ng-container *ngIf="group.title && showGroup">
              <p class="subheader-font bold">{{group.title | translate}}</p>
            </ng-container>
            <div class="spec-group">
              <ng-container *ngFor="let item of group.specialities">
                <div class="spec-card-container" [ngClass]="{'spec-unavailable': !item.available}">
                  <div class="spec-card" (click)="chooseSpeciality(item)">
                    <div class="spec-image">
                      <img src="./assets/icons/Book_new/{{item.icon || '7'}}.svg" alt="">
                    </div>
                    <div class="spec-desc">
                      <div class="spec-title">{{item.specialityName | translate : 'LANGARRAY' : 'name'}}</div>
                      <div class="spec-waiting-time" *ngIf="item.available">
                        <span class="grey-text">{{'Estimated_waiting_time' | translate}}:</span> <span class="primary-text"> {{item.minWaitingTime}} <ng-container *ngIf="item.maxWaitingTime"> - {{item.maxWaitingTime}} </ng-container></span>
                      </div> 
                      <div class="spec-waiting-time" *ngIf="!item.available">
                        <ng-container *ngIf="!item.isFetchingAvailability">
                          <p class="danger-text" *ngIf="item.unAvailable !='Y'">{{'CONSULTNOW#SPEC#MESSAGE#NXTAVAIL' | translate : 'LABELS':null:null:item.translation }}</p>
                          <p class="danger-text" *ngIf="item.unAvailable =='Y'">{{'CONSULTNOW#SPEC#MESSAGE#UNAVAIL' | translate : 'LABELS' }}</p>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="noSpeciality">
          No speciality
        </ng-container>
      </ng-container>
      <ng-template #LOADSPECIALITY>
        <div class="loading-container">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-template #CHOOSEPATIENT>
  <div class="center-align" *ngIf="false">
    <div class="header-font bold padding">
      {{'onspot_our_doctors_online_now'|translate}}
    </div>
    <span class="img-bg"><img class="" src="assets/icons/onspot/onspot copy 6.svg"></span>
    <p><span class="subtitle"> {{'onspot_consultation_fee'|translate}}</span>
      <span class="primary-large bold"> {{ consultationCost['charge'] }} {{
        consultationCost['chargeCurrency']}}</span>
    </p>
  </div>
  <div class="patient-container">
    <div class="rl-padding">
      <p class="subheader-font bold"> {{ "onspot_title_select_a_patient" | translate }} </p>
    </div>
    <app-patient-list [linkedPatients]="linkedPatients" [ageGenderRestrictions]="restriction" (selcetedPatient)="choosePat($event)" [source]="'ONSPOT'"></app-patient-list>
  </div>
</ng-template>

<ng-template #CHOOSE_TEAM>
  <div class="patient-container">
    <div>
      <p class="title bold"> {{ "onspot_title_select_team" | translate }} </p>
      <p class="subtitle"> {{ "onspot_title_select_team_msg" | translate }} </p>
    </div>
    <div class="cards">
      <div class="card-layout" *ngFor="let item of selectedConcepts">
        <div class="card row cursor-pointer" (click)="selectSpeciality(item)">
          <div class="image">
            <img class="type-image new-pat" src="assets/icons/group.svg" alt="">
          </div>
          <div class="card-content">
            <div class="font-primary-subtitle bold">{{ item.careElementType}}</div>
            <div class="subtitle">
              <span class="default-subtitle bold">{{ item.concept_controls.controls.chargeCurrency }} {{
                item.concept_controls.controls.charge }} </span>
              {{ 'onspot_lbl_will_be_applicable' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ON_AVAILABLE>
  <div class="center-align padding">
    <div class="header-font bold padding">
      {{ 'onspot_title_connecting' |translate }}
      <p class="subtitle">
        {{'onspot_title_connect_next_available_doctor'|translate}}
      </p>
      <p *ngIf="selectedSpeciality && selectedSpeciality.available" class="subtitle center adjust-margin"><span class="font-medium-small">{{'CONSULTNOW#SPEC#MESSAGE#TIME' | translate}}: </span><span ><strong>{{selectedSpeciality.minWaitingTime}} <ng-container *ngIf="selectedSpeciality.maxWaitingTime"> - {{selectedSpeciality.maxWaitingTime}}</ng-container></strong></span></p>
    </div>
    <div class="animate-container">
      <div class="circle">
        <div class="image">
          <app-person-avatar [url]="selectedPatient.imageurl"> </app-person-avatar>
        </div>
      </div>
    </div>
    <p class="subtitle"> {{'onspot_msg_connect_next_available_doctor'|translate}}</p>
  </div>
</ng-template>

<ng-template #ON_BUSY>
  <div class="center-align a-center">
    <div class="padding">
      <span class="img-bg"><img class="" src="assets/icons/onspot/busy.svg"></span>
    </div>
    <div class="header-font bold p-btm">
      {{'onspot_title_service_busy'|translate}}
    </div>
    <p class="subtitle"> {{'onspot_msg_service_busy'|translate}} {{ '30_minutes' | translate }}
    </p>
    <div class="card-head">
      <span class="subtitle hide">{{'onspot_lbl_schedule_options'|translate:'LABELS'}}</span>
      <span class="subtitle show">{{'onspot_req_lbl_busy_sch_video_cons_msg'|translate:'LABELS'}}</span>
    </div>
  </div>
</ng-template>

<ng-template #ON_UNAVAILABLE>
  <div class="center-align a-center">
    <div class="padding">
      <span class="img-bg"><img class="" src="assets/icons/onspot/Unavailable.svg"></span>
    </div>
    <div class="header-font bold p-btm">
      {{'onspot_title_service_unavailable'|translate}}
    </div>
    <p class="subtitle"> {{'onspot_msg_service_unavailable'|translate}}
      {{ consultationCost['timeFrom'] | moment:'hh:mm a':'HH:mm' }} - {{ consultationCost['timeTo'] |
      moment:'hh:mm a':'HH:mm'}}</p>
    <div class="card-head ">
      <span class="subtitle hide">{{'onspot_lbl_schedule_options'|translate:'LABELS'}}</span>
      <span class="subtitle show">{{'onspot_req_lbl_busy_sch_video_cons_msg'|translate:'LABELS'}}</span>
    </div>
  </div>
</ng-template>

<ng-template #ON_CONSULTATION>
  <div class="center-align a-center">
    <div class="padding">
      <span class="img-bg"><img class="" src="assets/icons/onspot/busy.svg"></span>
    </div>
    <div class="header-font bold">
      {{'onspot_title_service_in_consultation'|translate}}
    </div>
    <div class="padding subtitle">{{'onspot_msg_service_in_consultation'|translate}}
      {{ getWatingTimeMin() }} {{ 'label_minutes' | translate }}
    </div>
    <div class="card-head hide">
      <span class="subtitle">{{'onspot_lbl_join_queue_or_schedule_consult'|translate}}</span>
    </div>
  
    <div class="schedule-content show">
      <div class="subtitle">{{'onspot_lbl_join_queue'|translate:'LABELS'}}</div>
      <div class="font-default-small">
        <button class="primary-button" (click)="goToQueue('QUEUE')">
          {{'onspot_button_join_queue'|translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ON_QUEUE>
  <div class="center-align padding a-center">
    <div class="padding">
      <span class="img-bg"><img class="" src="assets/icons/onspot/Queue.svg"></span>
    </div>
    <div class="header-font bold p-btm">
      {{'onspot_title_queue'|translate}}
    </div>
    <p class="subtitle"> {{'onspot_msg_queue'|translate}} </p>
    <p class="subtitle">
      {{ getWatingTime() }} {{ 'label_minutes' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #ON_ACCEPT>
  <app-onspot-request-accept [consultData]="teamStatus" [overlapAppt]="apptOverlap" [eventInfo]="eventInfo"
  (payment)="openPayment($event)" (closeConsult)="closeModel()" [selectedSpeciality]="selectedSpeciality"
  (navigateTopic)="navigateToPreviousTopic()"></app-onspot-request-accept>
</ng-template>

<ng-template #FAIL_PAY>
  <div class="payment-details">
    <div class="card">
      <div class="card-head border-bottom">
        <span class="font-default-small bold">{{'appt_dtl_pmt_failed'|translate:'LABELS'}}</span>
      </div>
      <div class="card-content">
        <div class="payment-group payment-error">
          <img class="type-image" src="assets/icons/alert_red.svg" />
          <span class="font-danger-small">{{'msg_payment_err'|translate}} <span class="click_here cursor-pointer"
              (click)="payForAppt()">{{'appt_click_here'|translate}}</span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="transaction-details">
    <div class="card">
      <div class="card-head border-bottom">
        <span class="font-default-small bold">{{'transaction_details'|translate:'LABELS'}}</span>
      </div>
      <div class="card-content">
        <div class="transaction-group">
          <p class="font-default-small">{{'transaction_note'|translate}}</p>
          <div class="transaction-content">
            <div class="font-light-small">{{'transaction_id'|translate:'LABELS'}}</div>
            <div>{{ payParam['transactionId']||'-'}}</div>
          </div>
          <div class="transaction-content">
            <div class="font-light-small">{{'gateway_ref_no'|translate:'LABELS'}}</div>
            <div>{{ payParam['gatewayRefId']||'-'}}</div>
          </div>
          <div class="transaction-content">
            <div class="font-light-small">{{'bank_ref_no'|translate:'LABELS'}}</div>
            <div>{{ payParam['bankRefId']||'-'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<div *ngIf="showCancelConfirmation" id="cancel-prompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-title bold">{{'ttl_cancel_osrequest' | translate}}</span>
        <div class="close float-right closeicon pointer" (click)="dismissCancelRequest(false)">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <img class="type-image" src="./assets/icons/appt-preview/alert.svg" />
        <div class="font-light-small">{{'info_cancel_osrequest' | translate}}</div>
      </div>
      <div class="card-footer">
        <div class="btn-row">
          <button class="footer-button-small white-font-primary-button border"
            (click)="dismissCancelRequest(false)">{{'btn_cancelos_no' | translate}}</button>
          <button class="footer-button-small primary-button" (click)="dismissCancelRequest(true)">{{'btn_cancelos_yes' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
