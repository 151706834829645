import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'directiveLinkedPreviewText'
})
export class DirectiveLinkedPreviewTextPipe implements PipeTransform {

  transform(value) {
    console.log('value', value);
    if (!value || !value.displayTxt) return '';
    if (typeof value.displayTxt === 'string')
      return value.displayTxt.includes(',') ? value.displayTxt.split(',').join(', ') : value.displayTxt;
    return value.displayTxt;
  }

}
