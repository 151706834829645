import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { ModalService } from '../services/modal.service';
import { LinkPatientService } from '../services/link-patient.service';
import { GoogleTagsService } from '../services/google-tags.service';
import { AppointmentService } from '../services/appointment.service';
import { FeatureFlagsService } from '../services/feature-flags.service';

@Component({
  selector: 'app-linked-patients',
  templateUrl: './linked-patients.component.html',
  styleUrls: ['./linked-patients.component.scss']
})
export class LinkedPatientsComponent implements OnInit {

  @Input() _input: any;
  @Input() settings: any;
  @Input() msg: any;
  @Input() patientBasedApptbooking: any;
  @Input() flowType: any;
  @Input() isReferral: boolean;
  @Input() referralDetail: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;

  public linkWithoutRelation: boolean = false;

  public isManageIdentities:any;
  public facilityId: string;
  public searchkey: string;
  public translateType = 'LOCALE';
  public linkedPatients: Array<any>;
  public relationList: Array<any>;
  public visitType: string;
  public delayRunning: boolean = false;
  selectedPatient;
  public recentConsultatedPatient: any;
  constructor(private user: UserService, private modalService: ModalService, private linkPatient: LinkPatientService, private gtmService: GoogleTagsService, private appointment: AppointmentService, private featureService: FeatureFlagsService, private changeDetectorRef: ChangeDetectorRef) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.searchkey = '';
    this.linkedPatients = [];
    this.relationList = [];
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
  }

  ngOnInit() {
    try {
      this.delayRunning = true;
      this.visitType = this._input.in['home']['visitType']
      if (!this.patientBasedApptbooking) {
        this.facilityId = this._input.in['facility'] ? this._input.in['facility']['id'] : this._input.in['speciality'] ? this._input.in['speciality']['facilityId'] : this._input.in['practitioner']['facilityId'];
      }
      this.setLinkedPatients();
    } catch (e) {
      this.hideDelayRunning()
    }
  }
  private setLinkedPatients() {
    try {
      this.recentConsultatedPatient = this.appointment.getRecentConsultatedPatientValue();
      this.user.getUserId().subscribe(data => {
        if (data) {
          console.log('getLinkedPatient', data);
          this.linkPatient.getLinkedPatient(data, true).subscribe(result => {
            if (result.status === 'SUCCESS') {
              if(this._input.in['home']['visitType']=='HOSPITAL' || this._input.in['home']['visitType']=='VIDEO'){
                let payload = {
                  facilityId: this.facilityId,
                  clinicCode: this._input.in['slot']['clinicCode'],
                  patientIds:  result.data.map(item => item.uhid)
                }
                this.user.getRestrictionForPatients(payload).subscribe((data) => {
                  let restrictedList = data;
                  let patients=result.data;
                  let patient = patients.filter(o1 => {
                    return restrictedList.some(o2 => {
                        if(o1.uhid === o2.patientId) {
                          o1.ageRestricted = o2.ageRestricted ? true : false;
                          o1.genderRestricted = o2.genderRestricted ? true : false;
                          o1.ageGenderRestricted = (o2.ageRestricted && o2.genderRestricted) ? true : false;
                          o1.disablePatient = o2.ageRestricted || o2.genderRestricted;
                          return true;
                        } else {
                          o1.disablePatient = false;
                          return false;
                        }
                    });
                  });
                  if(patient.length > 0) {
                    patients.sort((a, b) => Number(a.disablePatient) - Number(b.disablePatient)); 
                    this.linkedPatients= patients;
                    this.selectedPatient = this.linkedPatients[0];
                  }else{
                    this.linkedPatients = result.data;
                  }
                  this.setDefaultPatient()
                  // this.linkedPatients = result.data;
                  if (this.linkedPatients.length == 1) {
                    this.completeSelection(this.linkedPatients[0])
                  } else {
                    this.hideDelayRunning()
                  }
                },error => {
                  console.log("error", error)
                  this.linkedPatients = result.data;
                  this.setDefaultPatient()
                  if (this.linkedPatients.length == 1) {
                    this.completeSelection(this.linkedPatients[0])
                  } else {
                    this.hideDelayRunning()
                  }
                })
              }else{
                this.linkedPatients = result.data;
                this.setDefaultPatient()
                if (this.linkedPatients.length == 1) {
                  this.completeSelection(this.linkedPatients[0])
                } else {
                  this.hideDelayRunning()
                }
              }
            }
          }, err => {
            console.log(err);
            this.hideDelayRunning()
          })
        }
      }, err => {
        console.log(err);
        this.hideDelayRunning()
      })
    } catch (e) {
      this.hideDelayRunning()

    }
  }
  public setDefaultPatient() {
    this.linkedPatients.forEach((p) => {
      if(p['uhid'] == this.recentConsultatedPatient?.['uhid']) {
        p['recentConsultated'] = true;
      }
    });
    this.linkedPatients.splice(0, 0, this.linkedPatients.splice(this.linkedPatients.findIndex(p => p['recentConsultated']), 1)[0]);
    console.log('this.linkedPatients', this.linkedPatients)
    this.selectedPatient = this.linkedPatients[0];
  }

  public completeSelection(event: any) {
    this.appointment.getBookingRestrictions(event.uhid, this.visitType, this.flowType).subscribe(bookingRestrictions => {
      console.log(this.visitType);
      this.hideDelayRunning()
      if (this.visitType) {
        let obj = { 'patient_type': 'Existing' };
        let eevent = 'step_option_select_person',
          funnel_name = 'Clinic Appointments';
        if (this.visitType == 'VIDEO')
          funnel_name = 'Online Consultation';
        if (this.visitType == 'VIDEO-CONSULT-NOW')
          funnel_name = 'Instant Consultation';
        this.gtmService.pushGTMEvent(funnel_name, eevent, obj);
      }
      let obj = {
        id: event.uhid,
        name: event.personname,
        personid: event.personid,
        relation: event.relationshiptype,
        image: event.imageurl,
        gender: event.gender,
        dob: event.dob
      }
      this.complete.emit({ patient: obj, bookingRestrictions: bookingRestrictions });
    }, err => {
      this.hideDelayRunning()
    })
  }
  openModal(id: string) {
    if (this.visitType) {
      let obj = {'patient_type': 'New' },
        event = 'step_option_select_person',
        funnel_name = 'Clinic Appointments';
      if (this.visitType == 'VIDEO')
        funnel_name = 'Online Consultation';
      if (this.visitType == 'VIDEO-CONSULT-NOW')
        funnel_name = 'Instant Consultation';
      this.gtmService.pushGTMEvent(funnel_name, event, obj);
    }
    this.action.emit({
      name: 'registerpatient'
    })
  }
  public openUrl(link) {
    this.user.openFacilityLocation(this.facilityId)
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }
  public hideDelayRunning() {
    this.delayRunning = false;
    this.changeDetectorRef.detectChanges();
  }

  choosePatient(data) {
    this.selectedPatient = data;
  }
}
