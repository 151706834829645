<div id="phone-input">
  <div class="input-group" [ngClass]="{'direction-row': direction == 'row'}">
    <div class="prefix cursor-pointer" (click)="toggleDropDown()">
      <div class="country-flag {{country}}" [ngClass]="{hideCountry: hideCountry}">
        <span class="dis-country-name">{{countryName}}</span>
      </div>
      <div class="country-select" *ngIf="showDropdown">
        <div class="country-list">
          <div class="list-item" *ngFor="let item of newCountry" (click)="toggleCountry(item)">
            <div class="country-flag {{item.iso}}"></div>
            <div class="country-name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="input" [ngClass]="{'cursor-pointer': readonly}">
      <input type="text" name="tel" (keypress)="isPhoneNumber($event)" (input)="getItems()" [(ngModel)]="phonenumber" [readonly]="readonly" autofocus *ngIf="!masked" />
      <input type="text" name="telmasked" [readonly]="true" [(ngModel)]="maskedNumber" *ngIf="masked" />
    </div>
  </div>
</div>
