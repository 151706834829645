import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent } from './alert-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';

@NgModule({
  declarations: [
    AlertModalComponent
  ],
  imports: [
    CommonModule,PipesModule,DirectiveModule
  ],
  exports: [AlertModalComponent]
})
export class AlertModalModule { }
