<div  class="right-navigation showModal" *ngIf="isErrorModal">
    <div class="component">
        <div class="content">

            <div class="modal-type" *ngIf="modalType=='Session_Expired'">
            <div class="session-expired-wrapper">
                <div class="image">
                    <img src="assets/icons/Home/OOPS.svg">
                </div>
                <div class="session-expired-text">
                    <span class="text">{{'Session_expired' | translate }}</span>
                </div>
                <div class="session-expired-message">
                    <span class="text">{{'Unable_to_proceed._please_login_again.' | translate }}</span>
                </div>
                <div class="session-expired-login">
                    <button class="login_btn" (click)="login()">
                        {{'Log_in' | translate }}
                    </button>
                </div>
            </div>
            </div>

        </div>
    </div>
</div>