<div id="hr-visit-bill-preview">
  <div class="overlay"></div>
  <div class="modal-card">
    <div class="modal-header">
      <div class="title font-primary-default bold">{{'Billing' | translate}}</div>
      <div class="close-button" (click)="closeModal()">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="modal-content" *ngIf="billDetails">
      <div class="content-header">
        <div class="patient-details">
          <div class="patient-name font-primary-default bold">{{patient['personname']}}</div>
          <div class="patient-name font-light-small1" *ngIf="billDetails && billDetails['diagnosis'] && billDetails['diagnosis'].length > 0 ">{{'Diagnosis' | translate}}: {{ concatDiag(billDetails) }}</div>
        </div>
        <div class="insurance-details" *ngIf="billDetails && billDetails['insuranceDetails'] && billDetails['insuranceDetails'].length > 0">
          <div class="insurance-name" *ngIf="billDetails?.insuranceDetails[0]['insuranceName']"> <img class="thumbnail-image type-image"
              src="assets/icons/insurance.svg" />{{ billDetails?.insuranceDetails[0]['insuranceName'] }}</div>
          <div class="insurance-coverage font-light-small1" *ngIf="billDetails?.insuranceDetails[0]['insuranceCoverage']">{{'Coverage' | translate}}: {{ billDetails?.insuranceDetails[0]['insuranceCoverage'] }}</div>
        </div>
      </div>
      <div class="content-subheader border-bottom">
        <div class="bill-details font-light-small1">
          {{'Bill_number' | translate}} <span class="font-light-small bold">{{billDetails?.billNo}}</span> {{billDetails['billDate'] | moment:'DD-MM-YYYY hh:mm a'}}
        </div>
        <div class="approval-status">
          <button class="{{billStatusColorMapping?.[billDetails['billStatus']]?.class }} bold btn-flat btn-small font-danger-small">{{billDetails?.billStatus | translate}}</button>
        </div>
      </div>
      <div class="content-details">
        <div class="font-primary-default service-title">{{'Service_details' | translate}}</div>
        <div class="service-list">
          <div class="billing-card-table">
            <div class="bill-table">
              <div class="bill-table-row table-header">
                <div class="bill-table-cell font-light-small1 bold" style="grid-area: a;">{{'Service_name_/_category' | translate}}
                </div>
                <div class="bill-table-cell font-light-small1 bold" style="grid-area: b;">{{'Quantity' | translate}}</div>
                <div class="bill-table-cell font-light-small1 bold" style="grid-area: c;">{{'Discount'|translate}}</div>
                <div class="bill-table-cell font-light-small1 bold" style="grid-area: d;">{{'Net_amount'|translate}}</div>
                <div class="bill-table-cell font-light-small1 bold" style="grid-area: e;">{{'Copay_amount'|translate}}</div>
              </div>
              <div class="bill-table-row table-content" *ngFor="let item of billDetails['items']">
                <div class="bill-table-cell" style="grid-area: a;">
                  <div class="view-type-2">
                    <div class="primary-section">{{item['serviceDisplay']}}</div>
                    <div class="secondary-section font-light-small">{{item['itemCategory']}}</div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: b;">
                  <div class="view-type-2 billdetails">
                    <div class="primary-section font-light-small">
                      <span class="mobile-view-title">{{'Qty' | translate}}:</span> {{item['itemQuantity']}}
                    </div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: c;">
                  <div class="view-type-2">
                    <div class="primary-section font-light-small">
                      <span class="mobile-view-title">{{'Discount' | translate}}:</span> {{item['Discount']}}
                    </div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: d;">
                  <div class="view-type-2 netamount">
                    <div class="primary-section font-light-small">
                      <span class="mobile-view-title">{{'Net' | translate}}:</span> {{currency_code}} {{item['net']}}
                    </div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: e;">
                  <div class="view-type-2 copayamount font-primary-default">
                    <span class="mobile-view-title">{{'Copay' | translate}}:</span> {{currency_code}} {{item['coPay']}}
                  </div>
                </div>
              </div>
              <div class="bill-table-row table-content no-border">
                <div class="bill-table-cell" style="grid-area: a;">
                  <div class="view-type-2">
                    <div class="primary-section font-primary-default bold">Total</div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: b;">
                  <div class="view-type-2 billdetails">
                    
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: c;">
                  <div class="view-type-2">
                    
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: d;">
                  <div class="view-type-2 netamount totalamount">
                    <div class="primary-section font-light-small bold">
                      <span class="mobile-view-title">{{'Net' | translate}}:</span>{{currency_code}} {{getSumOfItems('net')}}
                    </div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: e;">
                  <div class="view-type-2 copayamount totalamount font-primary-default bold">
                    <span class="mobile-view-title">{{'Copay' | translate}}:</span> {{currency_code}} {{getSumOfItems('coPay')}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>