import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionPreviewComponent } from './section-preview.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [SectionPreviewComponent],
  imports: [
    CommonModule, PipesModule
  ], 
  exports: [SectionPreviewComponent],
  
})
export class SectionPreviewModule { }
