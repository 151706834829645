import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PractitionerAvatarComponent } from './practitioner-avatar.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [PractitionerAvatarComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [PractitionerAvatarComponent]
})
export class PractitionerAvatarModule { }
