import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Platform } from '@angular/cdk/platform';
import { environment } from '@env/environment';
import { TranslatePipe } from '../pipes/translate.pipe';
import { CookieService } from '../services/cookie.service';

@Component({
  selector: 'app-promotion-popup',
  templateUrl: './promotion-popup.component.html',
  styleUrls: ['./promotion-popup.component.scss']
})
export class PromotionPopupComponent implements OnInit {
  public showPopup: boolean = false;
  public popupType: string = null;
  public appOpenOptions: any = null;
  public expireDays: any = null;
  constructor(private user: UserService, public platform: Platform, private translate: TranslatePipe, private cookie: CookieService) {
    
  }

  ngOnInit(): void {
    this.user.appPromoSubject.subscribe(data => {
      this.popupType = data.type;
      this.expireDays = data.expireDays;
      if (data.show) {
        this.openPopup(data);
      }
    })
  }

  private openPopup({ show, action, repeatAppPromo }) {
    if (!!action && !repeatAppPromo) {
      this.promoAction(null);
    } else if (this.popupType == 'APPPROMO') {
      console.log('PLATFORM=>', this.platform)
      let appUrl = null;
      let appNameLabel = ''
      if (this.platform.IOS) {
        appUrl = environment['urlInfo']['iOS'];
        appNameLabel = 'app_name_appstore'
      } else if (this.platform.ANDROID) {
        appUrl = environment['urlInfo']['android'];
        appNameLabel = 'app_name_playstore'
      }
      let icons = {
        BLINK:'https://img.icons8.com/color/48/000000/chrome--v1.png',
        EDGE:'https://img.icons8.com/color/50/000000/ms-edge-new.png',
        FIREFOX:'https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo-sm.f2523d97cbe0.png',
        SAFARI:'https://img.icons8.com/color/48/000000/safari--v1.png',
        TRIDENT:'https://img.icons8.com/fluency/48/000000/internet.png',
        WEBKIT: 'https://img.icons8.com/fluency/48/000000/internet.png',
        DEFAULT: 'https://img.icons8.com/fluency/48/000000/internet.png'
      }
      let currentBrowser = Object.keys(icons).find(key => { return this.platform[key] })
      if (!currentBrowser) currentBrowser = 'DEFAULT';
      if (appUrl) {
        this.appOpenOptions = [
          {
            icon: 'assets/images/app-promotion/app-icon.png',
            name: appNameLabel,
            button: 'open_app_action_open',
            action: 'primary',
            payload: {type: 'open', url: appUrl}
          },
          {
            icon: icons[currentBrowser],
            name: 'app_name_browser',
            button: 'open_app_action_close',
            action: 'secondary',
            payload: { type: 'close' }
          }
        ]
        this.showPopup = show;
        console.log(this.appOpenOptions)
      }
    }
  }

  public promoAction(payload) {
    if (payload && payload.type == 'open') {
      this.user.openLocation(payload.url, "_blank");
    }
    const d = new Date();
    let expDays = Number(this.expireDays) || 1
    d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    this.cookie.setCookie(`APPPROMOCLOSED=${true};${expires}`);
    sessionStorage.setItem('APPPROMOCLOSED', 'true');
    this.showPopup = false;
  }

}
