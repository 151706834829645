import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import {EditProfileModule} from '../edit-profile/edit-profile.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { NavigationModule } from '../navigation/navigation.module';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { ManageAccountModule } from '../manage-account/manage-account.module';
import { notificationDtlModule } from '../notification-dtl/notification-dtl.module';
@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    CustomModalModule,
    EditProfileModule,
    PersonAvatarModule, 
    BookAppointmentModule, 
    PipesModule,
    ManageIdentitiesModule,
    NavigationModule,
    ManageAccountModule,
    notificationDtlModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
