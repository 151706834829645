<ng-container *ngIf="s">
  <div>
    <p class="no-padding no-margin secondary-text"><span
        class="que-txt font-light-small">{{ codify ? s['domainName'] :  s['question'][0]['txt']}}</span>
      <span style="font-weight: 500" class="ans-txt font-default-small" *ngIf="!customAnswer">
        <ng-container *ngIf="!s._isReferral">{{ s['value'] }}</ng-container>
        <ng-container *ngIf="s._isReferral">
          <ng-container *ngIf="s._isCrossReferral">
            <ng-container *ngFor="let sv of s.selectedValues;let last=last;">
              {{sv.team}} 
              <ng-container *ngIf="sv.parsedValue">({{sv.parsedValue}})</ng-container>
              <ng-container *ngIf="sv._isCrossReferral && sv._isCrossReferral.facility">
                <div>{{sv._isCrossReferral.facility.longName}}
                  <ng-container *ngIf="sv._isCrossReferral.apptData"> 
                    - {{sv._isCrossReferral.apptData.practitionerName}} -
                    {{sv._isCrossReferral.apptData.apptDate | moment:'DD MMM YYYY':'YYYY-MM-DD'}}
                    {{sv._isCrossReferral.apptData.apptTime | moment:'h:mm a':'HH:mm'}}</ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!s._isCrossReferral">
            <ng-container *ngFor="let sv of s.selectedValues;let last=last;">
              {{sv.team}} 
              <ng-container *ngIf="sv.parsedValue">({{sv.parsedValue}})</ng-container>
              <ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </span>
    </p>
  </div>

  <!-- single instance form -->
  <ng-template #singleInstance>
    <div *ngIf="s.selectedValues && s.selectedValues.length>0" class="dle-selected-values">
      <ng-container *ngFor="let sv of s.selectedValues">
        <ng-container *ngIf="sv.values && sv.values.length>0">
          <div *ngFor="let val of sv.values" class="dle-single-selected-values">
            <span class="dle-single-selected-values-topic font-light-subtitle">{{val.name}}</span>
            <span *ngIf="val._format && val._format === 'HTML' else normalData" [innerHTML]="val.values"
              class="dle-single-selected-values-val"></span>
            <ng-template #normalData>
              <span class="dle-single-selected-values-val">{{val | directiveLinkedPreviewText}}</span>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <!-- order form -->
  <ng-template #order>
    <div class="order-data">
      <div class="order-data-row" *ngFor="let go of orderData">
        <span class="order-data-col-head font-light-subtitle">{{go[0].directiveName}}</span>
        <span class="order-data-col-val font-default-small">
          <ng-container *ngFor="let o of go;let last=last;">
            {{o['conceptName']}}
            <span class="order-data-parsed-val" *ngIf="o['parsedValue']"> ({{o['parsedValue']}})</span>
            <ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #prescription>
    <div class="pres-data">
      <div class="rw rw-header">
        <div class="colm font-default-small">
          Medicine name
        </div>
        <div class="colm font-default-small" *ngFor="let c of presHeaders">
          {{c.name}}
        </div>
      </div>
      <div class="rw" *ngFor="let sv of s.selectedValues">
        <div class="colm font-default-small">
          {{sv.conceptName}}
        </div>
        <div class="colm font-default-small" *ngFor="let c of presHeaders">
          <ng-container *ngIf="presValues && presValues[sv.id] && presValues[sv.id][c.code]">
            {{presValues[sv.id][c.code]}}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #referral>
    <div *ngIf="s.selectedValues && s.selectedValues.length>0" class="dle-selected-values">
      <div class="dle-single-selected-values" *ngFor="let sv of s.selectedValues">
        <span class="dle-single-selected-values-topic font-light-subtitle">{{sv.team}}</span>
        <span class="dle-single-selected-values-val font-default-small"
          *ngIf="sv.parsedValue">({{sv.parsedValue}})</span>
      </div>
    </div>
  </ng-template>

  <ng-template #vital>
    <ng-container *ngIf="s._isVitalDisplayType === 'INLINE'">
      <span class="vital-inline-data">
        <div *ngFor="let v of s._vitalsData;let last = last;" class="vital-inline-div">
          <bdi><span class="vital-inline-data-desc">{{v.eventDesc}} : </span></bdi>
          <span class="vital-inline-data-val">{{v.resultNum}} {{v.uom}}<ng-container *ngIf="!last">, </ng-container>
            </span>
        </div>
      </span>
    </ng-container>
    <ng-container *ngIf="s._isVitalDisplayType === 'TABLE'">
      <span class="vital-data">
        <table class="vital-table">
          <thead>
            <tr class="border-bottom">
              <th class="bold" *ngFor="let h of vitalHeaders">
                {{h.name}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-bottom" *ngFor="let v of s._vitalsData">
              <th class="font-light-default" *ngFor="let h of vitalHeaders">
                {{h.code === 'normalcyInd' ? (v[h.code] ? 'Abnormal': 'Normal') : v[h.code] }}
              </th>
            </tr>
          </tbody>
        </table>
      </span>
    </ng-container>
  </ng-template>



  <div class="custom-answer" *ngIf="customAnswer">
    <div [ngSwitch]="customAnswer">
      <div *ngSwitchCase="'SINGLE'">
        <ng-container *ngTemplateOutlet="singleInstance"></ng-container>
      </div>
      <div *ngSwitchCase="'ORDER'">
        <ng-container *ngTemplateOutlet="order"></ng-container>
      </div>
      <div *ngSwitchCase="'PRES'" class="allow-overflow">
        <ng-container *ngTemplateOutlet="prescription"></ng-container>
      </div>
      <div *ngSwitchCase="'REFERRAL'">
        <ng-container *ngTemplateOutlet="referral"></ng-container>
      </div>
      <div *ngSwitchCase="'VITAL'">
        <ng-container *ngTemplateOutlet="vital"></ng-container>
      </div>
      <div *ngSwitchDefault></div>
    </div>
  </div>
</ng-container>
