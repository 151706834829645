import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicServiceComponent } from './express-clinic-service.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';

@NgModule({
  declarations: [ExpressClinicServiceComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, PractitionerAvatarModule, PersonAvatarModule
  ],
  
  exports:[ExpressClinicServiceComponent]
})
export class ExpressClinicServiceModule { }
