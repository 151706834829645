import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitDetailsComponent } from './hr-visit-details.component';
import { PipesModule } from '../pipes/pipes.module';
import { HrVisitReportModule } from '../hr-visit-report/hr-visit-report.module';
import { HrVisitPrescriptionModule } from '../hr-visit-prescription/hr-visit-prescription.module';
import { HrVisitBillsModule } from '../hr-visit-bills/hr-visit-bills.module';
import { HrVisitHistoryModule } from '../hr-visit-history/hr-visit-history.module';
import { DirectiveModule } from '../directive/directive.module';
import { HrVisitSummaryModule } from '../hr-visit-summary/hr-visit-summary.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PatientChatModule } from '../patient-chat/patient-chat.module';


@NgModule({
  declarations: [HrVisitDetailsComponent],
  imports: [
    CommonModule, PipesModule, HrVisitReportModule, HrVisitPrescriptionModule, HrVisitBillsModule, HrVisitHistoryModule, DirectiveModule, HrVisitSummaryModule,
    PersonAvatarModule, PatientChatModule
  ],
  exports: [
    HrVisitDetailsComponent
  ]
})
export class HrVisitDetailsModule { }
