<div class="update-relationship-wrapper">
    <div class="update-relationship-modal">

      <div class="header-modal border-bottom">
    <div class="title bold">{{'Select_relationship'|translate:'LABELS'}}</div>
    <div (click)="closeModal()" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close_black.svg">
    </div>
  </div>

  <div class="content-label">
    <span>Please select the relationship with </span> 
    <span class="relation-with-name">{{patient['personname']}}</span>
  </div>

  <div class="relation-types-wrapper">
  <div *ngFor="let r of  relations" class="relation-types-repeat" (click)="selectedRelation = r['code']">
      <input
        id="relation-type-id-{{r['code']}}"
        value="{{r['code']}}"
        type="radio"
        name="enums"
        [checked]="r['code'] == selectedRelation" />
      <label for="relation-type-id-{{r['code']}}">  {{r['value']|translate:'LOCALE':'name'}} </label>
  </div>
  </div>

  <div class="update-button-wrapper">
      <button class="button-primary" (click)="toLinkPatients(patient)">{{'Update_relation' | translate }}</button>
    </div>
</div>

</div>