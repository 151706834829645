import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from "google-libphonenumber";
import { AuthService } from '../services/auth.service';
import { environment } from '@env/environment';
import { UserService } from '../services/user.service';
import { ToastServiceService } from '../services/toast-service.service';
@Component({
  selector: 'app-change-mobile-number',
  templateUrl: './change-mobile-number.component.html',
  styleUrls: ['./change-mobile-number.component.scss']
})
export class ChangeMobileNumberComponent implements OnInit {

  @Output() close: EventEmitter<any>;

  public changeMobileNoForm: UntypedFormGroup;
  public precheckForm: UntypedFormGroup;
  public isPreCheck: boolean = true;
  public isVerifyMobile:boolean = false;
  public txId: string = '';
  public mobile: string;
  public extAuth: boolean;
  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private user: UserService, private toast: ToastServiceService) { 
    this.close = new EventEmitter<any>();
    this.precheckForm = new UntypedFormGroup({});
    this.user.getExternalInterface().subscribe(data => {
      this.extAuth = data;
    })
  }

  ngOnInit(): void {
    this.user.getUserInfo('personDetails').subscribe(data => {
      if (data && data['mobile'] && data['countryCode']) {
        this.buildForm(`+${data['countryCode']}`, data['mobile']);
      } else {
        this.buildForm();
      }
    })
    // this.buildForm();
  }

  public closeModal() {
    this.close.emit();
  }
  public buildForm(countryCode:string=environment['COUNTRY_CODE'], mobile:string='') {
    this.precheckForm = this.formBuilder.group({
      oldMobile: new UntypedFormControl('', [
        Validators.required,
        this.validatePhone
      ]),
      mobile: new UntypedFormControl('', [
        Validators.required,
        this.validatePhone
      ])
    });
    this.changeMobileNoForm = this.formBuilder.group({
      otp: new UntypedFormControl('', [Validators.required]),
      mobile: new UntypedFormControl('', [Validators.required])
    });
    let prefillMobile = `${countryCode}${mobile}`;
    this.precheckForm.patchValue({ 
      oldMobile: prefillMobile,
      mobile: `${countryCode}` });
    // this.changeMobileNoForm.patchValue({ mobile: prefillMobile });
  }

  validatePhone = (control: AbstractControl): { invalid: boolean } => {
    let phone = control.value;
    if (this.checkIfPhoneNumber(phone))
      return null;
    return { invalid: true };
  };
  checkIfPhoneNumber = (phone: string) => {
    let validation: boolean = false;
    if (null == phone || typeof phone == undefined || phone.length < 5 || phone.match(/[a-z]/i)) {
      return validation;
    } else {
      try {
        var phoneUtil = PhoneNumberUtil.getInstance();
        var number = phoneUtil.parse(phone);
        validation = phoneUtil.isValidNumber(number);
        return validation;
      } catch (error) {
        console.error(error);
        return validation;
      } 
    }
  };

  phoneExtractor = (value: string, type: string): string => {
    let countryCode: string | number = '';
    if (value.match(/[a-z]/i))
      return '' + countryCode;
    try {
      var phoneUtil = PhoneNumberUtil.getInstance();
      var number = phoneUtil.parse(value);
      switch (type) {
        case 'MOBILE':
          countryCode = number.getNationalNumber();
          break;
        case 'COUNTRY_CODE':
          countryCode = number.getCountryCode();
          break;
      }
    } catch (error) {
      console.error(error);
    }
    return '' + countryCode;
  };

  public isRequiredField(field: string) {
    let form_field = this.changeMobileNoForm.get(field);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  public changeNumber() {
    this.isVerifyMobile = false;
    this.isPreCheck = true;
  }

  public sendOTP(isResend) {
    let value = this.precheckForm['value']['mobile'];
    this.mobile = value;
    this.changeMobileNoForm.patchValue({ mobile: value });
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let mobile = this.phoneExtractor(value, 'MOBILE');
    this.authService.updateMobile(countryCode, mobile).subscribe( data => {
      this.txId = data.txId;
      if(data.status === 'SUCCESS'){
        this.isPreCheck = false;
        this.toggleScreen('VERIFYMOBILE')
        if(isResend)
        this.toast.showToast(true, "OTP has been resent to mobile number", 'success');

      }
    })
  }
  public sendDOTP(){
    let value = this.precheckForm['value']['mobile'];
    this.mobile = value;
    this.changeMobileNoForm.patchValue({ mobile: value });
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let mobile = this.phoneExtractor(value, 'MOBILE');
    this.authService.precheckMobile(countryCode, mobile).subscribe( data => {
      if (!data.registered){
        this.isPreCheck = false;
        this.toggleScreen('VERIFYMOBILE')
      } else {
        this.toast.showToast(true, "Mobile number already registered", "error");
      }
    })
  }

  public verifyOTP(){
    let value = this.changeMobileNoForm['value']['mobile'];
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let mobile = this.phoneExtractor(value, 'MOBILE');
    let otp = this.changeMobileNoForm['value']['otp'];
    this.user.getUserId().subscribe(data => {
    this.authService.verifyUpdateMobile(this.txId,otp,countryCode,mobile,data).subscribe( data => {
      this.txId = data.txId;
      if(data.status === 'SUCCESS'){
        this.toggleScreen('PRECHECK')
        this.precheckForm.patchValue({ 
          oldMobile: value
        });
        this.user.updateUserInfo({ mobile: mobile, countryCode: countryCode })
        this.closeModal();
      }
    })
  })
  }
  public verifyDOTP(){
    let value = this.changeMobileNoForm['value']['mobile'];
    let countryCode = this.phoneExtractor(value, 'COUNTRY_CODE');
    let mobile = this.phoneExtractor(value, 'MOBILE');
    let otp = this.changeMobileNoForm['value']['otp'];
    this.user.getUserId().subscribe(data => {
      this.authService.changeLoginId(otp,countryCode,mobile,data).subscribe( data => {
      this.txId = data.txId;
      if(data.status === 'SUCCESS'){
        this.toggleScreen('PRECHECK')
        this.precheckForm.patchValue({ 
          oldMobile: value
        });
        this.user.updateUserInfo({ mobile: mobile, countryCode: countryCode })
        this.closeModal();
      }
    })
  })
  }
  public toggleScreen(val) {
    try{
      this.isPreCheck = (val === 'PRECHECK');
      this.isVerifyMobile = (val === 'VERIFYMOBILE');
    } catch(e){
      console.error(e);
    }
  }


}
