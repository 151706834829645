import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { HealthRecordsService } from '../services/health-records.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-home-feedback',
  templateUrl: './home-feedback.component.html',
  styleUrls: ['./home-feedback.component.scss']
})
export class HomeFeedbackComponent implements OnInit {

  // @Input() selectedAppt: any;
  @Output() close: EventEmitter<any>;
  @ViewChild('clinicalForm') clinicalForm: ElementRef;
  public params: any;
  public showPreview: boolean;
  public _topicsToPreview: Array<any>;
  public formContent: Array<any>;
  public isComplete: boolean;
  public directiveGrp: any;
  public conceptGrp: any;
  constructor(private apptService: AppointmentService, private userService: UserService) {
    this.close = new EventEmitter<any>();
    this.params = {
      patient: null,
      directiveCode: null,
      preview: false,
      enableDraft: false,
      taskApplicableStatus: null,
      editNote: null,
      addToNote: null,
      concept: {
        noteContent: {}
      }
    }
  }

  ngOnInit(): void {
    this.getQuestionnarieForm();
  }
  public closeModal() {
    this.close.emit();
  }
  private getQuestionnarieForm() {
    this.userService.getUpdateInfo('directives').subscribe(data => {
      let directive = data.userDefined.find(def => {
        return def.directiveGroup == 'FEEDBACK';
      });
      if (directive) {
        this.directiveGrp = directive.code;
        this.conceptGrp = directive.concepts[0].code;
      }
    });
    this.userService.getGenFeedbackData(this.directiveGrp || 'FEEDBACK', this.conceptGrp || 'GENFB').subscribe(data => {
      this.params['concept']['noteContent'] = {
        topics: data.feedbackForm.topics
      }
      this.isComplete = data.isComplete;
      this.formContent = data;
    })
  }
  public togglePreview() {
    this.showPreview = !this.showPreview;
  }
  public saveResponse(event) {
    console.log(event);
    let d = event['response'];
    let body = {
      'name': d.name||this.userService.getPersonName(),
      'mobileNo': d.mobile||this.userService.getPersonMobile(),
      'email': d.email||this.userService.getPersonEmail(),
      'directiveCode': 'FEEDBACK',
      'conceptCode': 'GENFB',
      'templateType': 'FEEDBACK',
      'personId': this.userService.getPersonId(),
      'facilityId': this.userService.getDefaultFacility(),
      form: {
        clinicalNote: (Array.isArray(d.data) && d.data.length >= 1 && d.data[0].noteContent) ? d.data[0].noteContent : null,
        topicOutcomes: (Array.isArray(d.topicOutcomes) && d.topicOutcomes.length > 0) ? d.topicOutcomes : null,
        outcomes: (Array.isArray(d.outcome) && d.outcome.length > 0) ? d.outcome : null
      }
    };
    // console.log(body); return;
    this.userService.recordGeneralFeedback(body).subscribe(_ => {
      this.closeModal();
    })
  }

}
