import { Component, Input, OnInit } from '@angular/core';
import { ClinicalformService } from '../services/clinicalform.service';

@Component({
  selector: 'app-topic-outcomes',
  templateUrl: './topic-outcomes.component.html',
  styleUrls: ['./topic-outcomes.component.scss']
})
export class TopicOutcomesComponent {
  @Input() public topic: any;
  constructor(public formService: ClinicalformService) { }

}
