import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CouponModalComponent } from 'src/app/coupon-modal/coupon-modal.component';

@Component({
  selector: 'app-altpayment-input',
  templateUrl: './altpayment-input.component.html',
  styleUrls: ['./altpayment-input.component.scss']
})
export class AltpaymentInputComponent {
  @Input() input: 'COUPON' | 'POINTS';

  @Input() type: 'VIEWONLY' | 'SELECT' = 'SELECT';
  @Input() promoCodeDetails: any;
  @Input() netPayable: any;
  @Input() totalAmount: any;

  @Output() couponApplied: EventEmitter<any>
  @Output() applyingCoupon: EventEmitter<any>

  @Output() applyingPoints: EventEmitter<any>
  @Output() pointsApplied: EventEmitter<any>


  private isModalOpen: boolean = false;

  public appliedCoupons: any = []
  public appliedPoints: any = {}

  //Modal controllers
  public showCouponModal: boolean = false;
  public couponProps: any;
  public showPointsModal: boolean = false;
  public pointsProps: any;
  constructor() {
    this.couponApplied = new EventEmitter<any>();
    this.applyingCoupon = new EventEmitter<any>();
    this.applyingPoints = new EventEmitter<any>();
    this.pointsApplied = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  public removeAllCoupons(fromPromo) {
    this.appliedCoupons = [];
    this.couponApplied.emit({ type: 'REMOVE', data: null })
    if (fromPromo) {
      this.showReselectCouponModal()
    }
  }

  public removeAllPoints(fromPromo) {
    this.appliedPoints = {};
    this.pointsApplied.emit({ type: 'REMOVE', data: null })
    if (fromPromo) {
      this.showReselectCouponModal()
    }
  }

  public async openCoupons() {
    if (this.isModalOpen) return
    this.isModalOpen = true;
    this.applyingCoupon.emit({ status: true })
    this.couponProps = {
      promoDetails: this.promoCodeDetails,
      type: this.type == 'VIEWONLY' ? 'VIEWONLY' : 'COUPONNEW',
      patientPayable: this.netPayable,
      serviceAmount: this.totalAmount,
      appliedCoupons: this.appliedCoupons,
    }
    this.showCouponModal = true;
  }

  public completeCoupon(event) {
    this.showCouponModal = false;
    this.isModalOpen = false;
    if (event && event.type == 'APPLYCOUPON') {
      let data = event.payload;
      this.processAppliedCoupon(data)
    } else {
      this.applyingCoupon.emit({ status: false })
    }
  }

  private processAppliedCoupon(response) {
    if (response && response.data) {
      this.couponApplied.emit({ type: 'APPLY', data: response.data })
      this.appliedCoupons = response.data || [];
      console.log('appliedCoupons', this.appliedCoupons)
    } else {
      this.applyingCoupon.emit({ status: false })
    }
  }

  public removeCoupon(coupon) {
    this.applyingCoupon.emit({ status: true })
    this.appliedCoupons.appliedcouponcodedtls = this.appliedCoupons.appliedcouponcodedtls.filter(c => c.couponSequenceNo != coupon.couponSequenceNo)
    let calculatedDiscount = this.appliedCoupons.appliedcouponcodedtls.reduce((discount, data, index) => {
      if (data.selected) {
        let couponDiscount = Number(data.cashDiscount)
        let balancePayable = Number(this.netPayable) - discount
        let couponBurnt = 0;
        if (balancePayable > 0) {
          couponBurnt = Math.min(couponDiscount, balancePayable);
        }
        this.appliedCoupons.appliedcouponcodedtls[index]['couponBurnt'] = couponBurnt;
        discount += couponBurnt
      }
      return discount
    }, 0)
    this.appliedCoupons.value = calculatedDiscount
    this.couponApplied.emit({ type: 'APPLY', data: this.appliedCoupons })
  }

  private async showReselectCouponModal() {
    // try {
    //   const modal = await this.modalController.create({
    //     component: CouponModalComponent,
    //     swipeToClose: true,
    //     showBackdrop: true,
    //     backdropDismiss: true,
    //     mode: 'ios',
    //     cssClass: 'modal-class',
    //     componentProps: {
    //       type: 'RESELECT'
    //     }
    //   });
    //   modal.present()
    // } catch (e) {

    // }
  }

  public async openPointsModal() {
    if (this.isModalOpen) return
    this.isModalOpen = true;
    this.applyingPoints.emit({ status: true })
    this.pointsProps= {
      promoDetails: this.promoCodeDetails,
      type: this.type == 'VIEWONLY' ? 'VIEWPOINTS' : 'POINTS',
      patientPayable: this.netPayable,
      serviceAmount: this.totalAmount,
      appliedPoints: this.appliedPoints,
    }
    this.showPointsModal = true;
  }
  public completePoints(event) {
    this.showPointsModal = false;
    this.isModalOpen = false;
    if (event && event.type == 'APPLYPOINTS') {
      let data = event.payload;
      this.processAppliedPoints(data)
    } else {
      this.applyingPoints.emit({ status: false })
    }
  }

  private processAppliedPoints(response) {
    if (response && response.data) {
      this.pointsApplied.emit({ type: 'APPLY', data: response.data })
      this.appliedPoints = response.data || {};
      console.log('appliedPoints', this.appliedPoints)
    } else {
      this.applyingPoints.emit({ status: false })
    }
  }
}
