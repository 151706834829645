import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { HealthPackageDetailsComponent } from '../health-package-details/health-package-details.component';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
@NgModule({
  declarations: [HealthPackageDetailsComponent],
  imports: [
    CommonModule,
    PersonAvatarModule, 
    PipesModule,
    ManageIdentitiesModule,
    FormInputErrorModule,
    CustomInputGroupModule
  ],
  exports: [
    HealthPackageDetailsComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class HealthPackageDetailsModule { }