import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() public show: boolean;
  constructor(public loader: LoaderService) {

  }

  ngOnInit() {
    this.show = false;
    this.loader.source.subscribe(state => {
      this.show = state;
    })
  }

}
