<div id="manage-payment-card" class="right-nav">
    <div class="content">
    <div class="form">
        <div class="title bold hide">
          {{'title_my_saved_cards'|translate}}
        </div>
        <div *ngIf="cardDetail && cardDetail.length" class="card-wrapper">
            <div class="card-dtls">
              <div *ngFor="let c of cardDetail" class="card-item cursor-pointer" >
                <div class="card-no">
                    {{c['cardsInfo']['cardNumber']}}
                </div>
                <div class="flex-column ">
                  <p class="card-name " [ngClass]="{'expired-card-name': !checkCardExpiry(c['cardsInfo']['expiryDate'])}">
                      {{c['cardsInfo']['cardHolderName']}}</p>
                  <p class="card-date " [ngClass]="{'expired-card-name': !checkCardExpiry(c['cardsInfo']['expiryDate'])}">
                    {{ 'card_expire' | translate }}
                    {{c['cardsInfo']['expiryDate'] | moment : 'MM YYYY' : 'YYMM'}}</p>
                </div>
                <img  src="./assets/icons/delete.svg" alt="" (click)="deleteCard(c['cardsInfo'])">
              </div>
            </div>
          </div>
    </div>
    </div>
</div>


  <!-- <ng-container *ngIf="showModal">
    <div class="tc-modal " *ngIf="showModal">
      <div class="content">
        <div class="modal-header border-bottom">
          <div class="font-primary-subtitle bold">{{'remove_card'|translate}}</div>
          <div (click)="exit()" class="float-right closeicon pointer">
            <img src="./assets/icons/close.svg">
          </div>
        </div>
        <div class="modal-content font-default-small">
           <div>
            <img src="./assets/icons/profile/card.svg">
           </div>
            <p>
                {{'remove_card_msg'|translate}}
            </p>
        </div>
        <div class="modal-footer1">
          <div class="segment cursor-pointer border-right" (click)="removeCard()">
            {{'btn_yes'|translate}}
          </div>
          <div class="segment cursor-pointer" (click)="exit()">
            {{'btn_no'|translate}}
          </div>
        </div>
      </div>
      
    </div>
  </ng-container> -->