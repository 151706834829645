import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentConfirmComponent } from './appointment-confirm.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { CouponModule } from '../coupon/coupon.module';
import { ApptDtlCardModule } from '../appt-dtl-card/appt-dtl-card.module';
import { CouponModalModule } from '../coupon-modal/coupon-modal.module';
import { ManageInsuranceModule } from '../manage-insurance/manage-insurance.module';
import { DisplayCurrencyModule } from '../components/display-currency/display-currency.module';
import { PromoInputModule } from '../components/promo-input/promo-input.module';
import { AltpaymentInputModule } from '../components/altpayment-input/altpayment-input.module';
import { VideoConsultTCModule } from '../video-consult-tc/video-consult-tc.module';

@NgModule({
  declarations: [AppointmentConfirmComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, PersonAvatarModule,
    PractitionerAvatarModule,
    CouponModule,
    ApptDtlCardModule, CouponModalModule, ManageInsuranceModule, DisplayCurrencyModule, PromoInputModule, AltpaymentInputModule, VideoConsultTCModule
  ],

  exports: [AppointmentConfirmComponent]
})
export class AppointmentConfirmModule { }
