import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from './moment.pipe';
import { TranslatePipe } from './translate.pipe';
import { GroupByPipe } from './group-by.pipe';
import { LocalefilterPipe } from './localefilter.pipe';
import { ArraySlicePipe } from './array-slice.pipe';
import { PersoninitialPipe } from './personinitial.pipe';
import { GeodistancePipe } from './geodistance.pipe';
import { PartitionerDetailsPipe } from './partitioner-details.pipe';
import { SpecialityDetailsPipe } from './speciality-details.pipe';
import { FacilityDetailsPipe } from './facility-details.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { PractitionerContentPipe } from './practitioner-content.pipe';
import { FacilityImagePipe } from './facility-image.pipe';
import { HrReportFilterPipe } from './hr-report-filter.pipe';
import { ConditionaltopicPipe } from './conditionaltopic.pipe';
import { SectionfilterPipe } from './sectionfilter.pipe';
import { DirectiveLinkedPreviewTextPipe } from './directive-linked-preview-text.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ClinicalformMandatoryFilterPipe } from './clinicalform-mandatory-filter.pipe';
import { RepeatableTopicsPipe } from './repeatable-topics.pipe';
import { SexPipe } from './sex.pipe';
import { AgePipe } from './age.pipe';
import { EnglishToLangPipe } from './english-to-lang.pipe';
import { SortPipe } from './sort.pipe';
import { IdentityPipe } from './identity.pipe';
import { HrSummaryFilterPipe } from './hr-summary-filter.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { HealthpackPipe } from './healthpack.pipe';
import { FilterSearchPipe } from './filter-search.pipe';
import { MyAlertsPipe } from './my-alerts.pipe';
import { FromnowPipe } from './fromnow.pipe';
import { ApptFilterPipe } from './appt-filter.pipe';
import { NormalcyindPipe } from './normalcyind.pipe';
import { ApptTimePipe } from './appt-time.pipe';
import { MaskPipe } from './mask.pipe';

@NgModule({
  declarations: [MomentPipe, TranslatePipe,
     LocalefilterPipe, ArraySlicePipe, PersoninitialPipe, GeodistancePipe, PartitionerDetailsPipe, SpecialityDetailsPipe, FacilityDetailsPipe,
     GroupByPipe,
     SearchFilterPipe,
     PractitionerContentPipe,
     FacilityImagePipe,
     HrReportFilterPipe,
     ConditionaltopicPipe,
     SectionfilterPipe,
     DirectiveLinkedPreviewTextPipe,
     SafeHtmlPipe,
     ClinicalformMandatoryFilterPipe,
     RepeatableTopicsPipe,
     SexPipe,
     AgePipe,
     EnglishToLangPipe,
     SortPipe,
     IdentityPipe,
     HrSummaryFilterPipe,
     DateAgoPipe,
     HealthpackPipe,
     FilterSearchPipe,
     MyAlertsPipe,
     FromnowPipe,
     ApptFilterPipe,
     NormalcyindPipe,
     ApptTimePipe,
     MaskPipe],
  imports: [
    CommonModule
  ],
  exports: [MomentPipe, TranslatePipe, LocalefilterPipe, ArraySlicePipe, PersoninitialPipe, GeodistancePipe, PartitionerDetailsPipe, SpecialityDetailsPipe, FacilityDetailsPipe,
    GroupByPipe, SearchFilterPipe, PractitionerContentPipe, FacilityImagePipe, HrReportFilterPipe, ConditionaltopicPipe, SectionfilterPipe, DirectiveLinkedPreviewTextPipe,
    SafeHtmlPipe, ClinicalformMandatoryFilterPipe,
    RepeatableTopicsPipe, SexPipe, AgePipe, EnglishToLangPipe, SortPipe, IdentityPipe, HrSummaryFilterPipe, DateAgoPipe, HealthpackPipe, FilterSearchPipe,
    MyAlertsPipe, FromnowPipe, ApptFilterPipe, NormalcyindPipe, ApptTimePipe, MaskPipe]
})
export class PipesModule { }
