import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoInputComponent } from './promo-input.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AltpaymentModalModule } from '../altpayment-modal/altpayment-modal.module';
import { ConsentModalModule } from '../consent-modal/consent-modal.module';



@NgModule({
  declarations: [
    PromoInputComponent
  ],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, AltpaymentModalModule, ConsentModalModule,
  ],
  exports: [PromoInputComponent]
})
export class PromoInputModule { }
