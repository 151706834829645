<div class="search-f-s-p-wrapper">
  <div class="selected-facility-wrapper" *ngIf="selectedFacility && visitType !== 'VIDEO'">
    <img src="./assets/icons/Book_new/clinic.svg" alt=""> {{selectedFacility['name'] | translate : translateType : 'name'}}
    <span class="change-button bold" (click)="clearSearch(true)">{{'Change_facility' | translate}}</span>
  </div>
  <div class="selected-facility-wrapper" *ngIf="!selectedFacility && selectedSpeciality && view !== 'AVAILABLE' && selectedType =='PRACT' && practitionerList.length > 0">
    {{selectedSpeciality['id'] | specialityDetails: selectedSpeciality['facilityId']:'specialityName'| translate : translateType : 'name'}}
    <span class="change-button bold" (click)="changeView('SPEC')">{{'Change_speciality' | translate}}</span>
  </div>
  <div class="selected-facility-wrapper" *ngIf="!selectedFacility && !selectedSpeciality && view !== 'AVAILABLE' && selectedType =='PRACT' && practitionerList.length > 0 && visitType !== 'VIDEO'">
    <span class="change-button bold" (click)="changeView('SPEC')">{{'Select_speciality' | translate}}</span>
  </div>
  <div class="selected-facility-wrapper" *ngIf="!selectedFacility && !selectedSpeciality && selectedType =='SPEC' && backToFac && visitType !== 'VIDEO'">
    <span class="change-button bold" (click)="changeView('FAC')">{{'Select_facility' | translate}}</span>
  </div>
  <div class="selected-facility-wrapper" *ngIf="view == 'AVAILABLE' && selectedSpeciality && changePract">
    <span *ngIf="selectedPract && selectedPract.name">{{selectedPract.name | translate : translateType : 'name'}}</span>
    <span class="change-button bold" (click)="changeView('PRACLIST')">{{'Change_practitioner' | translate}}</span>
  </div>

  <div class="select-tab">
    <ng-container *ngIf="view == 'SP'">
      <div class="header-container" >
        <div class="toggle-option">
          <div class="option" [ngClass]="{selected: 'SPEC' == selectedType}" (click)="selectedType = 'SPEC';selectedSpeciality = '';showTodayPractOnly = false;goBackToSymptomSearch();refreshPractitioner(); clearPractView()">
            {{'Speciality' | translate}}
          </div>
          <div class="option" [ngClass]="{selected: 'PRACT' == selectedType}" (focus)="refreshPractitioner()"
            (click)="selectedType = 'PRACT';refreshPractitioner();searchStr = ''">
            {{'Practitioner' | translate}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="toolbars-wrapper" *ngIf="view !== 'AVAILABLE'">
    <div class="search-wrapper"  *ngIf="view == 'FACILITY'" [ngClass]="{'primary-border': primaryBorder}">
      <img src="./assets/icons/Book_new/search.svg" alt="">
      <div class="selected-spec" *ngIf="selectedSymptom">
        {{getSymptomName(selectedSymptom['names'])}}
      </div>
      <input type="text" placeholder="{{'Search_symptoms_specialities_practitioner' | translate}}"
        [(ngModel)]="filterSearch" (focus)="focusView=true;primaryBorder=true;"
        (blur)="primaryBorder=false;checkFocusView(filterSearch)"
        (input)="searchString = $event.target.value;handleInput()">
      <img src="./assets/icons/Book_new/textbox clear.svg" alt="" *ngIf="filterSearch" (click)="clearSearch()">
    </div>
    <div class="search-wrapper" *ngIf="(view == 'SP' && selectedType !=='PRACT') ||  (specSymptomDetailView && symptomSpecListview && selectedSymptom)" [ngClass]="{'primary-border': primaryBorder}">
      <img src="./assets/icons/Book_new/search.svg" alt="">
      <div class="selected-spec" *ngIf="selectedSymptom">
        {{getSymptomName(selectedSymptom['names'])}}
      </div>
      <input type="text" placeholder="{{'Search_symptoms_specialities' | translate}}"
        [(ngModel)]="searchStr" (focus)="primaryBorder=true;specfocusView=true"
        (blur)="primaryBorder=false;checkSpecFocusView(searchStr)" show-clear-button="always"
        (input)="searchString = $event.target.value;handleInput()">
      <img src="./assets/icons/Book_new/textbox clear.svg" alt="" *ngIf="searchStr" (click)="clearSearch()">
    </div>
    <div class="search-wrapper" *ngIf="view == 'SP' && selectedType =='PRACT'" [ngClass]="{'primary-border': primaryBorder}">
      <img src="./assets/icons/Book_new/search.svg">
      <div class="selected-spec" *ngIf="selectedSpeciality">
        {{ selectedSpeciality['id'] | specialityDetails: selectedSpeciality['facilityId']:'specialityName'| translate : translateType : 'name'}}
      </div>
      <input type="text" placeholder="{{('Search_practitioner') | translate}}" [(ngModel)]="practSrch" (focus)="primaryBorder=true;practfocusView=true"
      (blur)="primaryBorder=false;checkPractFocusView(practSrch)" show-clear-button="always">
      <img src="./assets/icons/Book_new/textbox clear.svg" alt="" *ngIf="practSrch" (click)="clearPractSearch()">
    </div>
    <div class="show-available-dr" *ngIf="view == 'SP' && selectedType =='PRACT' && selectedFacility">
      <p>{{ 'show_available_today_now' | translate}}</p>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="showTodayPractOnly" (input)="showAvailableDr($event, availableDr)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="select-location" *ngIf="view == 'FACILITY'" (click)="getCurrentLatLon(true)">
      <div class="select-location__icon">
        <img src="assets/icons/bookappt/locate_icon.svg" alt="locate-icon" class="select-location__img">
      </div>
      <span class="location-label">{{'Use_current_location' | translate}}</span>
    </div>
  </div>


  <div *ngIf="focusView && ((filterSearch && filterSearch.length>0) || (recentlyConsultedPracts && recentlyConsultedPracts.length>0) || (symptomSpecListview && symptomBasedSpecList && symptomBasedSpecList.length>0))" class="search-quick-con fv">
    <ng-container *ngIf="symptoms && symptoms.length>0 && !symptomSpecListview">
      <p class="quick-filter-type-header" *ngIf="symptoms && symptoms.length>0">{{'symptoms' | translate}}</p>
      <ng-container *ngFor="let s of symptoms;let i = index">
        <div class="fac-fv-symptoms" *ngIf="canShowSymptomSpec(s)" (click)="selectsymptomsFromSearch(s)" lines="full">
          <div class="small">{{getSymptomName(s['names'])}}</div>
          <div><img src="assets/icons/symptoms/right_arrow.svg" alt="" class="select-location__img"></div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="symptomSpecListview">
      <div class="link-spec-view">
        <img filpableicon src="assets/icons/symptoms/left-back-arrow.svg" (click)="goBackToSymptomSearch()">
        <p class="linked-spec-title" *ngIf="symptoms && symptoms.length>0">{{'Linked_specialities' | translate}}</p>
      </div>

      <ng-container *ngFor="let s of symptomBasedSpecList;let i = index" class="speciality">
        <div class="fac-fv-symptoms" (click)="completeSpecialtiySelection(s)" lines="full">
          <div class="small"> {{s['specialityName'] | translate : 'LANGARRAY' : 'name'}}</div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!symptomSpecListview && (recentlyConsultedPracts && recentlyConsultedPracts.length>0) && (recentlyConsultedPracts | filterSearch : filterSearch : 'resourceName':'LANGARRAY' : 'name' : 'list') as searchedDoctors">
      <p class="quick-filter-type-header" *ngIf="searchedDoctors && searchedDoctors.length>0">
        {{'Recently_consulted' | translate}}
      </p>
      <div class="recent-pract" *ngIf="searchedDoctors && searchedDoctors.length>0">
        <div class="clinic-item-card" *ngFor="let d of searchedDoctors">
          <div class="card-main" (click)="completePractSelection(d)">
            <!-- <div class="image">
              <app-practitioner-avatar [url]="d['resourceAvatarURL']"></app-practitioner-avatar>
            </div> -->
            <div class="card-content">
              <div class="container">
                <div class="title">{{ 'dr_label' |translate: 'LABELS'}} {{ d['resourceName'] | translate : 'LOCALE' :
                  'name' || 'Doctor'}}</div>
                <div class="font-light-small">
                  <span>
                    {{ d['specialityDesc'] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div id="search-sort-container" *ngIf="!symptomSpecListview">
      <ng-container  *ngIf="(filterSearch && filterSearch.length>0) && (allPractitioner| localefilter:filterSearch:'resourceName':'name').length">
        <p class="quick-filter-type-header" *ngIf="allPractitioner && allPractitioner.length>0">
          {{'Practitioners' | translate}}
        </p>
        <div class="practitioner-view" *ngIf="allPractitioner && allPractitioner.length>0">
          <div class="clinic-item-card" *ngFor="let d of (allPractitioner| localefilter:filterSearch:'resourceName':'name')">
            <div class="card-main" (click)="completePractSelection(d)">
              <!-- <div class="image">
                <app-practitioner-avatar [url]="d['resourceAvatarURL']"></app-practitioner-avatar>
              </div> -->
              <div class="card-content">
                <div class="container">
                  <div class="title">{{ d['resourceName'] | translate : 'LOCALE' : 'name' || 'Doctor'}}</div>
                  <div class="font-light-small">
                    <span>
                      {{ d['specialityDesc'] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container  *ngIf="(filterSearch && filterSearch.length>0) && (specialityList | filterSearch : filterSearch : 'specialityName':'LANGARRAY' : 'name' : 'list') as searchedSpeciality">
        <p class="quick-filter-type-header" *ngIf="searchedSpeciality && searchedSpeciality.length>0">
          {{'specialities' | translate}}
        </p>
        <ng-container *ngFor="let s of searchedSpeciality;let i = index" class="speciality">
          <div class="fac-fv-symptoms" (click)="completeSpecialtiySelection(s)" lines="full">
            <div class="small"> {{s['specialityDesc']}}</div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(filterSearch && filterSearch.length>0) && (facilityList | filterSearch : filterSearch : 'facilityName':'LANGARRAY' : 'name' : 'list' ) as searchedFacilities">
        <p class="quick-filter-type-header" *ngIf="searchedFacilities && searchedFacilities.length>0">
          {{'facilities' | translate}}
        </p>
        <ng-container *ngFor="let s of searchedFacilities;let i = index" class="speciality">
          <div class="fac-fv-symptoms" (click)="completeFacilitySelection(s)" lines="full">
            <div class="small"> {{s['facilityName'] | translate : 'LANGARRAY' : 'name'}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>


    <div class="no-data-state no-data-con-margin" *ngIf="filterSearch && filterSearch.length>0 && checkNoData((allPractitioner | filterSearch : filterSearch : 'resourceName':'LANGARRAY' : 'name' : 'list'),(specialityList | filterSearch : filterSearch : 'specialityName': 'LANGARRAY' : 'name' : 'list'),(facilityList | filterSearch : filterSearch : 'facilityName' : 'FACILITY' : 'name' : 'list'))">
      <p class="text-center">
        <strong>
          <label>
            {{'no_data_found' | translate}}
          </label>
        </strong>
      </p>
    </div>
  </div>

  <div *ngIf="specfocusView && ((searchStr && searchStr.length>0) || (symptoms && symptoms.length>0))" class="search-quick-con sfv" [ngClass]="{'vc-sfv': visitType == 'VIDEO' && !selectedFacility}">
    <ng-container *ngIf="view == 'SP' && symptoms && symptoms.length>0 && !symptomSpecListview">
      <p class="quick-filter-type-header" *ngIf="symptoms && symptoms.length>0">{{'symptoms' | translate}}</p>
      <ng-container *ngFor="let s of symptoms;let i = index">
        <div class="fac-fv-symptoms" *ngIf="canShowSymptomSpec(s)" (click)="selectsymptomsFromSearch(s)" lines="full">
          <div class="small">{{getSymptomName(s['names'])}}</div>
          <div><img src="assets/icons/symptoms/right_arrow.svg" alt="" class="select-location__img"></div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="symptomSpecListview">
      <div class="link-spec-view">
        <img filpableicon src="assets/icons/symptoms/left-back-arrow.svg" (click)="goBackToSymptomSearch()">
        <p class="linked-spec-title" *ngIf="symptoms && symptoms.length>0">{{'Linked_specialities' | translate}}</p>
      </div>

      <ng-container *ngFor="let s of symptomBasedSpecList;let i = index" class="speciality">
        <div class="fac-fv-symptoms" (click)="completeSpecialtiySelection(s)" lines="full">
          <div class="small"> {{s['specialityName'] | translate : 'LANGARRAY' : 'name'}}</div>
        </div>
      </ng-container>
    </ng-container>
    <div id="search-sort-container" *ngIf="!symptomSpecListview">
      <!-- <ng-container
        *ngIf="(searchStr && searchStr.length>0) && (facPractitionerList| localefilter:searchStr:'resourceName':'name').length">
        <p class="quick-filter-type-header" *ngIf="facPractitionerList && facPractitionerList.length>0">
          {{'Practitioners' | translate}}
        </p>
        <div class="practitioner-view" *ngIf="facPractitionerList && facPractitionerList.length>0">
          <div class="clinic-item-card"
            *ngFor="let d of (facPractitionerList| localefilter:searchStr:'resourceName':'name')">
            <div class="card-main" (click)="completePractSelection(d)">
              <div class="image">
                <app-practitioner-avatar [url]="d['resourceAvatarURL']"></app-practitioner-avatar>
              </div>
              <div class="card-content">
                <div class="container">
                  <div class="title"> {{ d['resourceName'] | translate : 'LOCALE' : 'name' || 'Doctor'}}</div>
                  <div class="font-light-small">
                    <span>
                      {{ d['specialityDesc'] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container> -->
      <ng-container *ngIf="(searchStr && searchStr.length>0) && (specialityList | filterSearch : searchStr : 'specialityName':'LANGARRAY' : 'name' : 'list') as searchedSpeciality">
        <p class="quick-filter-type-header" *ngIf="searchedSpeciality && searchedSpeciality.length>0">
          {{'specialities' | translate}}
        </p>
        <ng-container *ngFor="let s of searchedSpeciality;let i = index" class="speciality">
          <div class="fac-fv-symptoms" (click)="completeSpecialtiySelection(s)" lines="full">
            <div class="small"> {{s['specialityDesc']}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="no-data-state no-data-con-margin" *ngIf="searchStr && searchStr.length > 0 && symptoms && symptoms.length == 0 && checkSpecNoData(specialityList | filterSearch : searchStr : 'specialityName': 'LANGARRAY' : 'name' : 'list')">
      <p class="text-center">
        <strong>
          <label>
            {{'no_data_found' | translate}}
          </label>
        </strong>
      </p>
    </div>
  </div>

  <div *ngIf="practfocusView && (practSrch && practSrch.length>0)" class="search-quick-con sfv" [ngClass]="{'vc-sfv': visitType == 'VIDEO'}">
    <div id="search-sort-container">
      <ng-container *ngIf="(practSrch && practSrch.length>0) && (practitionerList| filterSearch : practSrch : 'resourceName':'LANGARRAY' : 'name').length">
      <p class="quick-filter-type-header" *ngIf="practitionerList && practitionerList.length>0">
        {{'Practitioners' | translate}}
      </p>
      <div class="practitioner-view" *ngIf="practitionerList && practitionerList.length>0">
        <div class="clinic-item-card" *ngFor="let d of (practitionerList| filterSearch : practSrch : 'resourceName':'LANGARRAY' : 'name')">
          <div class="card-main" (click)="completePractSelection(d)">
            <!-- <div class="image">
              <app-practitioner-avatar [url]="d['resourceAvatarURL']"></app-practitioner-avatar>
            </div> -->
            <div class="card-content">
              <div class="container">
                <div class="title"> {{ d['resourceName'] | translate : 'LOCALE' : 'name' || 'Doctor'}}</div>
                <div class="font-light-small">
                  <span>
                    {{ d['specialityDesc'] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
    <div class="no-data-state no-data-con-margin" *ngIf="practSrch && practSrch.length>0 && checkSpecNoData(practitionerList | filterSearch : practSrch : 'resourceName':'LANGARRAY' : 'name' : 'list')">
      <p class="text-center">
        <strong>
          <label>
            {{'no_data_found' | translate}}
          </label>
        </strong>
      </p>
    </div>
  </div>


  <div class="search-result">
    <ng-container *ngIf="practSrch">
      <ng-container *ngIf="!selectedPract && (practitionerList| localefilter:practSrch:'resourceName':'name').length">
        <p class="search-f-s-p-title">{{'Practitioners' | translate}}</p>
        <div class="clinics-list pract-list">
          <div class="clinic-block">
            <div class="clinic-item-card practitioner-card"
              *ngFor="let item of (practitionerList| localefilter:practSrch:'resourceName':'name')"
              (click)="completePractSelection(item)">
              <div class="card-main">
                <div class="image">
                  <app-practitioner-avatar [url]="item.resourceAvatarURL"></app-practitioner-avatar>
                </div>
                <div class="card-content">
                  <div class="container">
                    <div class="title">{{item.resourceName | translate : translateType : 'name'}}</div>
                    <div class="font-light-small">
                      <span *ngIf="item.additionalInfo != null">{{item.additionalInfo.info | translate : translateType :
                        'name'}}
                      </span>
                      <span *ngIf="item.additionalInfo.info[0].name == null || item.additionalInfo.info[0].name.trim() == '' ">
                        {{item['specialityId'] | specialityDetails: item['tfacilityId']:'specialityName'| translate : translateType : 'name'}}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="btn_container" *ngIf="selectedFacility">
                  <div class="cost strike-text" *ngIf="item.preview.hasDiscount">
                    {{item.preview.originalCharge}} {{currency_code}}
                  </div>
                  <div class="cost" *ngIf="item.preview.payableCharge">
                    {{item.preview.payableCharge}} {{currency_code}}
                  </div>
                </div>
              </div>
              <div class="card-footer" *ngIf="selectedFacility">
                <div class="days">
                  <span class="available-label">{{'Availability' | translate}}</span>
                  <div class="day" *ngFor="let days of slotDates">
                    <div class="text" [ngClass]="{available: item['_availableDates'] && item['_availableDates'][days]}">
                      {{days | moment : 'SD' : 'YYYY-MM-DD'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- <ng-container
        *ngIf="!selectedSpeciality && (specialityList |  localefilter:searchStr:'specialityName':'name': 'specialityDesc').length">
        <p class="search-f-s-p-title">{{'Clinics' | translate}}</p>
        <div class="clinics-list">
          <div class="clinic-block">
            <div class="clinic-item-card speciality-card"
              *ngFor="let item of (specialityList |  localefilter:searchStr:'specialityName':'name': 'specialityDesc')"
              (click)="completeSpecialtiySelection(item)">
              <div class="card-content">
                <div class="title">{{item['specialityDesc'] | translate : translateType : 'name'}}</div>
                <div class="font-light-small">{{item['pracCount'] }} {{'Practitioners_available' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container> -->

      <!-- <ng-container *ngIf="!selectedFacility &&  (facilityList | localefilter:searchStr:'facilityName':'name').length">
        <p class="search-f-s-p-title">{{'Clinics' | translate}}</p>
        <div class="clinics-list">
          <div class="clinic-block">
            <div class="clinic-item-list-card"
              *ngFor="let item of (facilityList | localefilter:searchStr:'facilityName':'name')"
              (click)="completeFacilitySelection(item)">
              <div class="card-content">
                <div class="title">{{item['facilityName'] | translate : translateType : 'name'}}</div>
                <div class="font-light-small" *ngIf="item['facilityAddress']">{{item['facilityAddress'] | translate :
                  translateType :'text'}}</div>
              </div>
              <div class="card-action">
                <div class="action-item">
                  <img class="nav-icon type-image" src="./assets/icons/Book_new/location.svg"
                    (click)="navigateUser(item['facilityGeoLocation'])" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container> -->

    </ng-container>
    <ng-container>
      <ng-container *ngIf="view == 'FACILITY'">
        <div class="clinics-label-with-filter-wrapper">
          <p class="search-f-s-p-title">{{'Our_clinics' | translate}}</p>
          <div class="appointments-segment hp-segment">
            <div class="hp-segment-item" [ngClass]="{'selected': selectedFacilityTab == 'LIST'}"
              (click)="changeFacilityTab('LIST')">
              <img
                [src]="selectedFacilityTab == 'LIST' ? './assets/icons/Book_new/List_icon_selected.svg' :'./assets/icons/Book_new/List_icon.svg'"
                alt="">
            </div>
            <div class="hp-segment-item" [ngClass]="{'selected': selectedFacilityTab == 'MAP'}"
              (click)="changeFacilityTab('MAP')">
              <img
                [src]="selectedFacilityTab == 'MAP' ? './assets/icons/Book_new/location_selected.svg' :'./assets/icons/Book_new/location.svg'"
                alt="">
            </div>
          </div>
        </div>
        <div class="clinics-list" *ngIf="selectedFacilityTab =='LIST'">
          <div class="clinic-block">
            <div class="clinic-item-list-card" *ngFor="let item of facilityList"
              (click)="completeFacilitySelection(item)">
              <div class="card-wrapper">
                <div class="title">{{item['facilityName'] | translate : translateType : 'name'}}</div>
                <div class="card-details">
                  <div class="card-content">
                    <div class="font-light-small" *ngIf="item['facilityAddress']">{{item['facilityAddress'] | translate
                      :
                      translateType :'text'}}</div>
                  </div>
                  <div class="card-action">
                    <div class="action-item">
                      <div class="call-image">
                        <img class="nav-icon type-image" src="./assets/icons/Book_new/call.svg"
                          (click)="navigateUser(item['facilityGeoLocation'])" />
                      </div>
                      <div class="navigation-image">
                        <img class="nav-icon type-image" src="./assets/icons/Book_new/location.svg"
                          (click)="navigateUser(item['facilityGeoLocation'])" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="map-wrapper" *ngIf="selectedFacilityTab =='MAP'">
          <div #gmap id="gmap" class="gmap" [ngClass]="{'rotate' : htmlDirection=='rtl'}"></div>
          <div class="facility-map-popover facility-content" id="map-popover" [ngClass]="{'hidden': !showMapPopover}">
            <div class="facility-details" *ngIf="mapSelectedFacility">
              <div class="facility-content-panel-text">
                <div class="facility-name">{{ mapSelectedFacility['facilityName'] | translate : translateType : 'name'
                  }}</div>
                <div class="facility-close" (click)="showMapPopover = false;">
                  <img src="assets/icons/close_black.svg" alt="close" class="close-icon">
                </div>
              </div>
              <div class="facility-address">{{mapSelectedFacility['facilityAddress'] | translate :translateType :'text'
                }}</div>
              <div class="select-button f-width float-right sm-hidden">
                <button class="button-primary" (click)="completeFacilitySelection(mapSelectedFacility)">
                  {{'Select_&_Continue' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="facility-selection-button f-width float-right md-hidden"
            *ngIf="showMapPopover && mapSelectedFacility">
            <button class="button-primary" (click)="completeFacilitySelection(mapSelectedFacility)">
              {{'Select_&_Continue' | translate}}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="view == 'SP'">
        <div class="clinics-list" *ngIf="selectedType =='SPEC'">
          <div class="clinic-block">
            <div class="clinic-item-card speciality-card" *ngFor="let item of specialityList"
              (click)="completeSpecialtiySelection(item)">
              <div class="image-content-wrapper">
                <div class="image">
                  <img [src]="item.icon">
                </div>
                <div class="card-content">
                  <div class="title">{{item['specialityDesc'] | translate : translateType : 'name'}}</div>
                  <div class="font-light-small">{{item['pracCount'] }} {{'Practitioners_available' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clinics-list pract-list scroll-hide" *ngIf="selectedType =='PRACT' && (practitionerList | filterSearch : practSrch : 'resourceName':'LANGARRAY' : 'name' : 'list' : showTodayPractOnly) as practitioners">
          <div class="clinic-block one" infiniteScroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
            <div class="clinic-item-card practitioner-card" *ngFor="let item of practitioners" (click)="completePractSelection(item)">
              <div class="card-main">
                <div class="image">
                  <app-practitioner-avatar [url]="item.resourceAvatarURL"></app-practitioner-avatar>
                </div>
                <div class="card-content">
                  <div class="container">
                    <div class="title">{{item.resourceName | translate : translateType : 'name'}}</div>
                    <div class="font-light-small">
                      <span *ngIf="item.additionalInfo != null">{{item.additionalInfo.info | translate : translateType : 'name'}}</span>
                      <span *ngIf="item.additionalInfo.info[0].name == null || item.additionalInfo.info[0].name.trim() == '' ">
                        {{ item['specialityId'] | specialityDetails: item['tfacilityId']:'specialityName'| translate : translateType : 'name'}}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="btn_container" *ngIf="selectedFacility">
                  <div class="cost strike-text" *ngIf="item && item.preview && item.preview.hasDiscount">
                    {{item.preview.originalCharge}} {{currency_code}}
                  </div>
                  <div class="cost" *ngIf="item && item.preview && item.preview.payableCharge">
                    {{item.preview.payableCharge}} {{currency_code}}
                  </div>
                </div>
              </div>
              <div class="card-footer" *ngIf="selectedFacility">
                <div class="days">
                  <span class="available-label">{{'Availability' | translate}}</span>
                  <div class="day" *ngFor="let days of slotDates">

                    <div class="text" *ngIf="!isSlotsLoading" [ngClass]="{available: (item['_availableDates'] && item['_availableDates'][days])}">
                      {{days | moment : 'SD' : 'YYYY-MM-DD'}}
                    </div>
                    <div class="day-slot-loading" *ngIf="isSlotsLoading"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="view == 'AVAILABLE'">
        <div class="clinic-item-card practitioner-card">
          <div class="card-main">
            <div class="image">
              <app-practitioner-avatar [url]="selectedPract.resourceAvatarURL"></app-practitioner-avatar>
            </div>
            <div class="card-content">
              <div class="container">
                <div class="title">{{selectedPract.name | translate : translateType : 'name'}}</div>
                <div class="font-light-small">
                  <span *ngIf="selectedPract.info != null">{{selectedPract.info | translate : translateType : 'name'}}</span>
                  <span *ngIf="selectedPract.info[0].name == null || selectedPract.info[0].name.trim() == '' ">
                    {{ selectedSpeciality['desc'] | translate : translateType : 'name'}}
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <p class="search-f-s-p-title">{{'Available_in' | translate}}</p>
        <div class="clinics-list">
          <div class="clinic-block">
            <div class="clinic-item-card" *ngFor="let item of selectedPract['_facilities']" (click)="completeFacilitySelection(item)">
              <div class="clinic-item-card-main">
                <div class="card-content">
                  <div class="title">{{item['facilityName'] | translate : translateType : 'name'}}</div>
                  <div class="font-light-small" *ngIf="item['facilityAddress']">{{item['facilityAddress'] | translate :
                    translateType :'text'}}</div>
                </div>
                <div class="card-action">
                  <div class="action-item">
                    <img class="nav-icon type-image" src="./assets/icons/Book_new/location.svg"
                      (click)="navigateUser(item['facilityGeoLocation'])" />
                  </div>
                </div>
              </div>
              <div class="card-footer" *ngIf="item && item.daysAvailable">
                <div class="days">
                  <span class="available-label">{{'Availability' | translate}}</span>
                  <div class="day" *ngFor="let days of slotDates">
                    <div class="text" [ngClass]="{available: item['daysAvailable'] && item['daysAvailable'][days]}">
                      {{days | moment : 'SD' : 'YYYY-MM-DD'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
