import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hrSummaryFilter'
})
export class HrSummaryFilterPipe implements PipeTransform {

  transform(value: Array<any>, filterKey: string, filterValue: any, search: string): any {
    if (!value)
      return value;
    let unordered = value.filter(val => {
      let keyFilter = true;
      let searchFilter = true;
      if (filterValue.key) {
        keyFilter = val[filterKey].toLowerCase() == filterValue.key.toLowerCase();
      } 
      if (search && search.trim()) {
        searchFilter =  val && val['problemDesc'] ? val['problemDesc'].toLowerCase().includes(search.toLowerCase().trim()) : val['recordedByName'].toLowerCase().includes(search.toLowerCase().trim());
      }
      return keyFilter && searchFilter;
    });
    return unordered
  }

}
