<div id="upload-questionnarie" class="">
  <div (click)="closeModal()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">{{'sub_preconsult_questionnarie' | translate}}</div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content">
      <div class="card-review">
        <div class="card">
          <div class="card-head border-bottom">
            <span class="font-default-small bold">{{'ttl_appointment_details'|translate:'LABELS'}}</span>
          </div>
          <div class="card-content">
            <div class="partitioner-details">
              <div class="image">
                <!-- <app-person-avatar
                  [url]="(selectedAppt['resourceId']|partitionerDetails: selectedAppt['facilityId']:'resourceAvatarURL')">
                </app-person-avatar> -->
                <app-practitioner-avatar  [url]="(selectedAppt['resourceId']|partitionerDetails: selectedAppt['facilityId']:'resourceAvatarURL')"></app-practitioner-avatar>
              </div>
              <div class="partitioner-content">
                <div class="title">
                  {{(selectedAppt['resourceId']|partitionerDetails: selectedAppt['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || 'Doctor'}}
                </div>
                <div class="font-light-small">
                  <!-- {{_input.in['practitioner']['additionalInfo']['info'] | translate : 'LOCALE' : 'name'}} -->
                </div>
                <div class="title font-light-small">
                  {{selectedAppt['resourceId']|partitionerDetails: selectedAppt['facilityId']:'specialityId' | specialityDetails: selectedAppt['facilityId']:'specialityName'| translate : 'LOCALE' : 'name'}}
                </div>
              </div>
            </div>
            <div class="vertical-divider">
              <div class="divider"></div>
            </div>
            <div class="patient-details">
              <div class="image">
                <app-person-avatar [url]="selectedAppt['imageurl']"> </app-person-avatar>
              </div>
              <div class="partitioner-content">
                <div class="title">
                  {{selectedAppt['personName']}}
                </div>
                <div class="font-light-small">
                  <!-- {{_input.in['patient']['relation'] | translate : 'RELATION'}} -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-foot border-top">
            <div class="slot-detail">
              <img class="icon type-image" src="assets/icons/calendar-timer.svg" alt="">
              <span class="speciality-name">
                {{selectedAppt['appointmentDateTime']| moment : 'ddd DD MMM YYYY, hh:mm a':'YYYY-MM-DD HH:mm'}}
              </span>
            </div>
            <div class="vertical-divider">
              <div class="divider"></div>
            </div>
            <div class="speciality-detail">
              <img class="icon type-image" src="assets/icons/pin.svg" alt="">
              <span class="speciality-name">
                {{(selectedAppt['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
              </span>
              <span class="speciality-address font-light-small">
                {{(selectedAppt['facilityId']|facilityDetails:'facilityAddress')| translate : 'LOCALE' : 'text'}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <app-clinicalform [params]="params" *ngIf="params['concept']['noteContent']['topics']"
        (response)="saveResponse($event)" [showSaveDraftBtn]="true" #clinicalForm></app-clinicalform>
    </div>
  </div>
</div>