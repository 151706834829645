import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService} from '../services/modal.service';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { profile } from '@type';
import { GENDER } from '@const';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  @Output() close: EventEmitter<any>;

  public editProfileForm: UntypedFormGroup;
  public genderList: Array<any>;
  public languageList: any;
  public extInterface: boolean;
  profileDetail:any;
  public genderSelected:any;
  public languageSelected:any;
  maxDate: string;

  constructor(public modalService: ModalService, private formBuilder: UntypedFormBuilder, private user: UserService, private authService: AuthService)  { 
    this.genderList = GENDER;
    this.profileDetail = {};
    this.close = new EventEmitter<any>();
  

    this.user.getExternalInterface().subscribe(data => {
      this.extInterface = data;
      if (data) {
        this.editProfileForm = this.formBuilder.group({
          userName: new UntypedFormControl('', [Validators.required]),
          firstName: new UntypedFormControl('', [
            Validators.required
          ]),
          lastName: new UntypedFormControl('', [
            Validators.required,
            // this.validatePhone
          ]),
          gender: new UntypedFormControl('', [
            Validators.required,
            // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
          ]),
          language: new UntypedFormControl('', [
            Validators.required,
          ]),
          dob: new UntypedFormControl('', [
            Validators.required,
            this.ageRangeValidator
          ]),
        });
      } else {
        this.editProfileForm = this.formBuilder.group({
          userName: new UntypedFormControl('', [Validators.required]),
          email:new UntypedFormControl('', [Validators.email])
        });
      }
    });

    this.maxDate = this.user.getMoment().format('YYYY-MM-DD');

  }

  ngOnInit() {
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });
    this.getUserProfile()
  }

  private ageRangeValidator = (control: AbstractControl): { futureDate: boolean } => {
    if ( this.user.getMoment(control.value).isAfter(this.user.getMoment().format()) ) {
            return { futureDate : true };
        }
        return null; 
  };
  closeModal() {
    this.close.emit();
  }

  editProfile() {
    if (this.extInterface) {
      let payload = {
        "personId": this.user.getPersonId(),
        "username": this.editProfileForm.get('userName').value,
        "firstName": this.editProfileForm.get('firstName').value,
        "lastName": this.editProfileForm.get('lastName').value,
        "gender": this.editProfileForm.get('gender').value == 'M' ? 'male' : 'female',
        "locale": this.editProfileForm.get('language').value,
        "dob": (moment(this.editProfileForm.get('dob').value,'DD-MM-YYYY')).format('YYYY-MM-DD')
       
      }
      this.authService.updateUserProfile(payload, 'UPDATEPROFILE').subscribe(_ => {
        this.user.updateUserInfo({ personName: payload['firstName'] + " "+ payload['lastName']})
        this.authService.getUserProfile().subscribe(data => {
          this.user.setSSOObjectOnUpdate(data);
          console.log("updateUserProfile", data)
          this.closeModal();
        })
      })
    } else {
      let payload = {
        "personId": this.user.getPersonId(),
        "personName": this.editProfileForm.get('userName').value,
        "email": this.editProfileForm.get('email').value,
      }
      this.authService.updateProfile(payload).subscribe(_ => {
        this.user.updateUserInfo({ email: this.editProfileForm.get('email').value, personName: this.editProfileForm.get('userName').value})
        this.closeModal();
      })
    }
    
  }
  public getUserProfile() {
    if(!this.user.isLoggedIn()) return
    this.authService.getUserProfile().subscribe(data => {
      this.user.setMarketingConsent(data.marketingConsent);
      if (this.extInterface) {
        let gender = data.gender ? data.gender : data.Gender;
        let language = data.language ? data.language : data.Language;
        let birth_date = data.birth_date ? data.birth_date : data['Birth Date'];
        this.editProfileForm.patchValue({
          userName: data.username,
          firstName: data.firstname,
          lastName: data.lastname,
          gender: gender == 'male' ? 'M' : 'F',
          language: language,
          dob: birth_date,
          // dob: this.user.getMoment(birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        })
        this.genderSelected =  this.editProfileForm.value.gender;
        this.languageSelected = this.editProfileForm.value.language;
      } else {
        this.editProfileForm.patchValue({
          userName: data.personName,
          email: data.email
        })
      }
    })
  }
  public dobDateChange(event) {
    this.editProfileForm.get('dob').setValidators(this.ageRangeValidator);
    this.editProfileForm.get('dob').markAsDirty();
    this.editProfileForm.patchValue({ dob: this.user.getMoment(event['target']['__data'].value).format('YYYY-MM-DD') })
  };
  public isRequiredField(field: string) {
    let form_field = this.editProfileForm.get(field);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  public resetForm() {
    this.closeModal();
  }
  public selectGender(gender) {
     this.genderSelected = gender;
  }
  public selectLanguage(language) {
    this.languageSelected = language;
  }
}
