<div class="font-danger-small" *ngIf="control && control.errors && (control.dirty || control.touched)">
  <div *ngIf="control.errors.required"><small>{{'This_field_is_required' | translate }}</small></div>
  <div *ngIf="control.errors.unique"><small>{{control.errors.unique}}</small></div>
  <div *ngIf="control.errors.lessThen"><small>{{control.errors.lessThen}}</small></div>
  <div *ngIf="control.errors.greaterThan"><small>{{control.errors.greaterThan}}</small></div>
  <div *ngIf="control.errors.email"><small>{{'Enter_valid_email' | translate}}</small></div>
  <div *ngIf="control.errors.mobile"><small>{{'Invalid_country_code_or_mobile_number' | translate}}</small></div>
  <div *ngIf="control.errors.confirmPassword"><small>{{control.errors.confirmPassword}}</small></div>
  <div *ngIf="control.errors.minlength"> <small>{{'Length_must_be' | translate }} {{length}} {{'characters' | translate }}</small></div>
  <div *ngIf="control.errors.futureDate"> <small>{{'Future_date_is_not_accepted' | translate}}</small></div>
  <div *ngIf="control.errors.pastDate"> <small>{{'Please_select_future_date' | translate}}</small></div>
  <div *ngIf="!control.errors.minlength && control.errors.pattern"> <small>{{'Enter_Valid_ID' | translate}}</small></div>
  <div *ngIf="control.errors.validField"> <small>{{ "ERRLBL#VALID_FIELD" | translate }}</small></div>
  <div *ngIf="control.errors.validName"> <small>{{ "ERRLBL#ENTER_NAME_IN_ENGLISH" | translate }}</small></div>
</div>
