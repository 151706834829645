/// <reference types="@types/googlemaps" />
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { visitType } from '@type';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ToastServiceService } from '../services/toast-service.service';
import { TOAST_SUCCESS } from '@string';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Platform } from '@angular/cdk/platform';
import * as moment from 'moment';
import { AppointmentService } from '../services/appointment.service';
@Component({
  selector: 'app-search-f-s-p',
  templateUrl: './search-f-s-p.component.html',
  styleUrls: ['./search-f-s-p.component.scss']
})
export class SearchFSPComponent implements OnInit {
  @Input() _input: any = {};
  @Input() visitType: visitType;
  @Output() complete: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  facilityList: any = [];
  delayRunning: boolean;
  selectedFacility;
  specialityList: any = [];
  public selectedSpeciality: any;
  restrictFaciltiesWithoutPract: any;
  searchStr = '';
  latlon: { lat: number; lon: number; };
  public translateType = 'LOCALE';
  view: 'FACILITY' | 'SP' | 'SEARCH' | 'AVAILABLE' | 'PRACTITIONERALLCLINIC' = 'FACILITY';
  selectedType: 'SPEC' | 'PRACT' = 'SPEC';
  dayList: { text: string; value: string; name: string; }[];
  selectedPract: any;
  // { id: any; personId: any; name: any; resourceAvatarURL: any; info: any; availDays: any; schedulerControls: any; additionalInfo: any; };
  practitionerList: any = [];
  currency_code: any;
  public htmlDirection = document.getElementsByTagName("html")[0].getAttribute("dir");
  public showMapPopover: boolean = false;
  public mapSelectedFacility: any;
  public map: google.maps.Map;
  public position: any;
  public curMarker: any;
  @ViewChild('gmap', { static: false }) gmapElement: ElementRef;
  @ViewChild('mapaddresstext', { static: true }) mapaddresstext: ElementRef;
  locationAddress: string;
  geoErrorMessage: string;
  isEditLocation: boolean;
  selectedFacilityTab = 'LIST';
  slotDates = [];
  isSlotsLoading: boolean;
  clonedPracts: any;
  public practSrch: any;
  public SCount: any;
  private ECount: any;
  public allPractitioner: any;
  public facPractitionerList: any;
  private facilityId;
  public filterSearch: string;
  public focusView: boolean = false;
  public practfocusView: boolean = false;
  public recentlyConsultedPracts: any;
  public recentView: boolean;
  public symptoms: any;
  public symptomList: any;
  public searchString: any;
  public showSymLoader: boolean = false;
  public enableSymptomSearch: boolean = true;
  public symptomSpecListview: boolean = false;
  public allSpecsForAllFacility: any;
  public symptomBasedSpecList: any;
  public selectedSymptom: any;
  public specfocusView: boolean = false;
  public specSymptomDetailView: boolean = false;
  public allPractInSearchedSpecialities: any;
  public primaryBorder
  public backToFac: boolean = false;
  public changePract: boolean = false;
  public selectedSpecialityEvent: any;
  public selectedPractitionerEvent: any;
  public inClinicSelection: any;
  public inClinicSrchSelection: any;
  public showTodayPractOnly: boolean = false;
  public availableDr: any;
  constructor(private user: UserService, private translatePipe: TranslatePipe, private toast: ToastServiceService, private changeDetectorRef: ChangeDetectorRef, public platform: Platform, private apptService: AppointmentService) {
    this.latlon = { lat: 0, lon: 0 };
    this.getCurrentLatLon();
    this.user.getUpdateInfo('configuration').subscribe(data => {
      this.restrictFaciltiesWithoutPract = data['pxAppSettings'] && data['pxAppSettings']['restrictFaciltiesWithoutPract'];
    })
    this.dayList = [
      { text: 'M', value: 'mon', name: 'Mon' },
      { text: 'T', value: 'tue', name: 'Tue' },
      { text: 'W', value: 'wed', name: 'Wed' },
      { text: 'T', value: 'thu', name: 'Thu' },
      { text: 'F', value: 'fri', name: 'Fri' },
      { text: 'S', value: 'sat', name: 'Sat' },
      { text: 'S', value: 'sun', name: 'Sun' },
    ];
  }

  ngOnInit(): void {
    console.log('visit type', this.visitType, '_input', this._input);
    this.getRecentPracts();
    this.getFacilityList();
    this.getSpecialities();
    this.getPractitioner();
    this.allPractitioner = this.user.getPractitioners(null, this.visitType, null, true);
    if (this.visitType == 'VIDEO') {
      this.view = 'SP';
      this.selectedFacility = null;
    }
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];

  }

  ngAfterViewInit(): void {
    this.inClinicSelection = this.user.getInclinicSelection();
    console.log('inClinicSelection', this.inClinicSelection);
    if (this.inClinicSelection && !this.inClinicSelection.searchFlow && this.inClinicSelection.facility && this.inClinicSelection.speciality) {
      this.selectedFacility = this.inClinicSelection.facility;
      this.completeSpecialtiySelection(this.inClinicSelection.selectedSpecialityEvent)
    } else if (this.inClinicSelection && this.inClinicSelection.searchFlow && this.inClinicSelection.speciality && this.inClinicSelection.practitioner) {
      this.selectedPract = this.inClinicSelection.practitioner;
      this.completePractSelection(this.inClinicSelection.selectedPractitionerEvent);
    }
  }

  public getRecentPracts() {
    let practitioners = []
    practitioners = this.user.getPractitioners(null, this.visitType, null, true);
    // this.practitionerList = practitioners;
    console.log('practitioners ---->', practitioners)
    let recentPractitioners = practitioners.filter(p => p['lastVisitDate']).sort((a, b) => new Date(b['lastVisitDate']).getTime() - new Date(a['lastVisitDate']).getTime()).slice(0, 5);
    console.log('recent practs--', recentPractitioners, practitioners)

    let limit = this.user.canViewOption('CLINICAPPTRECENTCONSULTRESPONSELIMIT') ? this.user.canViewOption('CLINICAPPTRECENTCONSULTRESPONSELIMIT') : null;

    this.user.getUserId().subscribe(id => {
      if (id) {
        this.apptService.getRecentlyConsultedAppts(id, limit).subscribe((res) => {
          console.log('recently consult data', res);
          let tmpRecent = [];
          if (res.length > 0) {
            res.forEach((p) => {
              practitioners.forEach((ap) => {
                if (ap['resourceId'] == p['resourceId'])
                  tmpRecent.push(ap)
              })
            })
            tmpRecent = _.uniqBy(tmpRecent, 'resourceId')
            this.recentlyConsultedPracts = tmpRecent;
            console.log('recent practs api call--', id, res, tmpRecent)
          }
        })
      }
    });
  }

  public refreshPractitioner() {
    console.log('refreshPractitioner', this.visitType, this.selectedFacility);
    if (this.visitType == 'VIDEO') {
      this.selectedFacility = null;
    }
    this.getPractitioner();
    if (!this.selectedSpeciality)
      this.goBackToSymptomSearch();
    if (!this.selectedFacility && !this.backToFac) {
      this.selectedSpeciality = null;
      this.selectedType = 'SPEC';
      this.backToFac = true;
    }
  }

  getFacilityList() {
    this.delayRunning = true;
    this.user.getUpdateInfo('facilities').subscribe(data => {
      let facilities = data.facilities;
      this.user.getUpdateInfo('facilityServices').subscribe(services => {
        let facilityServices = services.facilities;
        facilities = facilities.filter(facility => {
          let facSer = facilityServices.findIndex(service => facility.facilityId == service.facilityId)
          return facSer > -1 && facilityServices[facSer].practitioners && facilityServices[facSer].practitioners.length > 0
        })
        if (this.visitType == 'HOSPITAL') {
          facilities = facilities.filter(data => {
            if (data['facilityControls'] && data['facilityControls']['schedulingRules'] && data['facilityControls']['schedulingRules']['pxAppSettings'] && data['facilityControls']['schedulingRules']['pxAppSettings']['hideFacilityForNormalVisit'])
              return false;
            return true;
          });
        }
        console.log('this._input.bookingRestrictions', this._input.in.bookingRestrictions)
        if (this._input.in.bookingRestrictions && this._input.in.bookingRestrictions.allowedFacilities) {
          let isAllowedForAllSpecialities = false;
          let isAllowedForAllPractitioners = false;
          let facilityArray = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).map(facility => {
            if (facility.facilityId && facility.facilityId != '*') {
              return [facility.facilityId]
            } else if (facility.facilityId == '*') {
              let retFacilities = [];

              if (facility.allowedSpecialities) {
                let specialities = facility.allowedSpecialities.map(speciality => speciality.specialityId).filter(specialityId => specialityId != '*');
                isAllowedForAllSpecialities = facility.allowedSpecialities.map(speciality => speciality.specialityId).filter(specialityId => specialityId != '*');
                if (specialities && specialities.length > 0) {
                  let specialityAvailFacilities = facilityServices.filter(facility => {
                    return facility.specialities.find(speciality => specialities.include(speciality.specialityId));
                  })
                  retFacilities = [...retFacilities, ...specialityAvailFacilities];
                }
              }
              if (facility.allowedPractitioners) {
                let practitioners = facility.allowedPractitioners.map(practitioner => practitioner.practitionerId).filter(practitionerId => practitionerId != '*');
                isAllowedForAllPractitioners = facility.allowedPractitioners.map(practitioner => practitioner.practitionerId).filter(practitionerId => practitionerId == '*');
                if (practitioners && practitioners.length > 0) {
                  let practitionerAvailFacilities = facilityServices.filter(facility => {
                    return facility.practitioners.find(practitioner => practitioners.include(practitioner.resourceId));
                  })
                  retFacilities = [...retFacilities, ...practitionerAvailFacilities];
                }
              }
              retFacilities = [].concat.apply([], retFacilities).filter((value, index, self) => {
                return self.indexOf(value) === index;
              })
              return retFacilities;
            } else {
              return []
            }
          });
          let allowedFacilities: Array<any> = [].concat.apply([], facilityArray).filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
          if (!(isAllowedForAllPractitioners && isAllowedForAllSpecialities)) {
            facilities = facilities.filter(facility => {
              return allowedFacilities.includes(facility.facilityId);
            })
          }
        }
        this.facilityList = facilities;
        console.log('facilities', this.facilityList);
        if (this.facilityList.length == 1) {
          this.completeFacilitySelection(this.facilityList[0]);
        } else {
          this.hideDelayRunning()
        }
      }, err => {
        this.hideDelayRunning()
      })
    })
  }

  getSpecialities() {
    let facilityId = this.selectedFacility ? this.selectedFacility['id'] : null;
    let specialityList = this.user.getSpecialities(facilityId, this.visitType, true);
    if (this._input.in.bookingRestrictions && this._input.in.bookingRestrictions.allowedFacilities) {
      let allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == facilityId);
      if (!allowedFacility) {
        allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == '*');
      }
      if (allowedFacility && allowedFacility.allowedSpecialities) {
        let specialityIdArray = allowedFacility.allowedSpecialities.map(speciality => speciality.specialityId)
        let practitionerArray = allowedFacility.allowedPractitioners.map(practitioner => practitioner.practitionerId)
        let allowedSpeciality = specialityIdArray.filter(specialityId => specialityId != '*')
        let allowedPractitioner = practitionerArray.filter(resourceId => resourceId != '*')
        let isAllowedForAllSpeciality = specialityIdArray.filter(specialityId => specialityId == '*').length > 0
        let isAllowedForAllPractitioner = practitionerArray.filter(resourceId => resourceId == '*').length > 0
        if (allowedSpeciality.length > 0) {
          specialityList = specialityList.filter(speciality => {
            return allowedSpeciality.includes(speciality.specialityId)
          })
        } else if (allowedPractitioner.length > 0) {
          let practitionerList = this.user.getPractitioners(null, this.visitType, facilityId);
          if (practitionerList) {
            allowedSpeciality = practitionerList.filter(practitioner => {
              return allowedPractitioner.includes(practitioner.resourceId)
            }).map(practitioner => practitioner.specialityId);
            if (allowedSpeciality.length > 0) {
              specialityList = specialityList.filter(speciality => {
                return allowedSpeciality.includes(speciality.specialityId)
              })
            }
          }
        } else if (!isAllowedForAllSpeciality) {
          specialityList = [];
        }
      }
    }
    this.specialityList = specialityList.filter(cs => cs.pracCount > 0);
    this.specialityList.forEach((element) =>
      element.icon = "./assets/icons/Book_new/" + Number(element.icon) + ".svg"
    )
    console.log('specialities', this.specialityList);
    if (this.specialityList.length == 1) {
      this.completeSpecialtiySelection(this.specialityList[0]);
    } else {
      this.hideDelayRunning()
    }
  }

  getPractitioner() {
    this.practitionerList = []
    let facilityId = this.selectedFacility ? this.selectedFacility['id'] : null;
    let specialityId = this.selectedSpeciality ? this.selectedSpeciality['id'] : null;

    let practitionerList = this.user.getPractitioners(specialityId, this.visitType, facilityId, true);
    if (this._input.in.bookingRestrictions && this._input.in.bookingRestrictions.allowedFacilities) {
      let allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == facilityId);
      if (!allowedFacility) {
        allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == '*');
      }
      if (allowedFacility && allowedFacility.allowedPractitioners) {
        let practitionerArray = allowedFacility.allowedPractitioners.map(practitioner => practitioner.practitionerId)
        let allowedPractitioner = practitionerArray.filter(resourceId => resourceId != '*')
        let isAllowedForAll = practitionerArray.filter(resourceId => resourceId == '*').length > 0
        if (allowedPractitioner.length > 0) {
          practitionerList = practitionerList.filter(practitioner => {
            return allowedPractitioner.includes(practitioner.resourceId)
          })
        } else if (!isAllowedForAll) {
          practitionerList = [];
        }
      }
    }
    this.facilityId = facilityId;
    console.log('get practitionerList', practitionerList);
    this.clonedPracts = _.cloneDeep(practitionerList)
    this.getAvailablePractList(practitionerList)
    this.getpractBasedOnInfinite(0, 7, this.clonedPracts, facilityId)
  }

  private getpractBasedOnInfinite(sCount, eCount, practitioners, facilityId) {
    var tmpPractArr = [];
    // (sCount==0? sCount : sCount+1)
    for (let i = sCount; i < eCount; i++) {
      if (i < practitioners.length) {
        tmpPractArr.push(practitioners[i])
      } else {
        this.SCount = 0;
        this.ECount = 0
      }
    }
    this.SCount = eCount;
    this.ECount = eCount + 7;

    this.practitionerList = this.practitionerList.concat(tmpPractArr.map(list => {
      list.preview = { hasDiscount: false };
      if (this.visitType != 'VIDEO') {
        let discountCharge = 0;
        let charge = 0;

        if (list && list.schedulerControls && Array.isArray(list.schedulerControls.locationCharges) && list.schedulerControls.locationCharges.length > 0)
          charge = list.schedulerControls.locationCharges[0].charges
        else
          charge = list.schedulerControls.charge

        if (list && list.schedulerControls && Array.isArray(list.schedulerControls.locationCharges) && list.schedulerControls.locationCharges.length > 0)
          discountCharge = list.schedulerControls.locationCharges[0]['discountCharge']
        else if (list.schedulerControls && list.schedulerControls['discountCharge'])
          discountCharge = list.schedulerControls['discountCharge']


        if (discountCharge) {
          list.preview = { hasDiscount: true, originalCharge: charge, payableCharge: discountCharge }
        } else {
          list.preview = { hasDiscount: false, originalCharge: charge, payableCharge: charge }
        }
        return list;
      } else {
        let discountCharge = 0;
        let charge = 0;

        if (list && list.schedulerControls && list.schedulerControls.videoConsultCharge)
          charge = list.schedulerControls.videoConsultCharge;

        if (list && list.schedulerControls && list.schedulerControls.videoConsultDiscountCharge)
          discountCharge = list.schedulerControls.videoConsultDiscountCharge;

        if (discountCharge) {
          list.preview = { hasDiscount: true, originalCharge: charge, payableCharge: discountCharge }
        } else {
          list.preview = { hasDiscount: false, originalCharge: charge, payableCharge: charge }
        }
        return list;
      }
    }))
    const dates = this.getNext7Days()
    const offset = this.selectedFacility?.facilityControls?.['schedulingRules']?.['offsetTimeForDirectBooking'];
    this.isSlotsLoading = true;
    if (tmpPractArr.length > 0) {
      let payload = {
        practitioners: tmpPractArr.map(p => p['resourceId']),
        appointmentType: this.visitType,
        facilities: [facilityId],
        dates: dates,
        offset: { [facilityId]: offset }
      };

      if (this.visitType == 'VIDEO') {
        this.selectedFacility = this.facilityList;
        const offset = this.facilityList.map(f => f['facilityId']).reduce((r, a) => {
          let facility = this.facilityList.filter(f => a == f['facilityId']);
          r[a] = facility[0]?.facilityControls?.['schedulingRules']?.['offsetTimeForDirectBooking'];
          return r;
        }, {})
        payload.facilities = this.facilityList.map(f => f['facilityId']);
        payload.offset = offset;
      }
      this.slotDates = dates;
      this.user.getAvailableDaysForPract(payload).subscribe(data => {
        console.log(data)
        this.practitionerList = this.practitionerList.map(list => {
          data.map(parc => {
            if (list.resourceId == parc.practitioner)
              list['_availableDates'] = parc.daysAvailable;
          })
          return list;
        })
        this.isSlotsLoading = false;
      })
    }

    console.log("this.practitionerList", this.practitionerList)
    // if (this.practitionerList.length == 1)
    //   this.completePractSelection(this.practitionerList[0]);
  }

  public completeFacilitySelection(facility: any) {
    console.log('completeFacilitySelection', facility);
    this.focusView = false;
    this.filterSearch = '';
    this.checkFocusView(this.filterSearch, 'SPEC')
    let isUpcoming = facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['pxAppSettings'] && facility['facilityControls']['schedulingRules']['pxAppSettings']['isUpcomingFacility']
    if (facility && isUpcoming) {
      let message = this.translatePipe.transform('upcoming_facility_message', 'LABELS', null, null, null, true)
      if (message) {
        this.toast.showToast(null, message, TOAST_SUCCESS)
      }
      return;
    }
    if ((!facility.practitioners || facility.practitioners.length == 0) && this.restrictFaciltiesWithoutPract) {
      let message = this.translatePipe.transform('facility_no_practitioners', 'LABELS', null, null, null, true)
      if (message) {
        this.toast.showToast(null, message, TOAST_SUCCESS)
      }
      return;
    }
    let obj = {
      id: facility['facilityId'],
      name: facility['facilityName'],
      address: facility['facilityAddress'],
      facilityControls: facility['facilityControls']
    }
    this.selectedFacility = obj;
    if (this.selectedSpeciality && this.selectedPract)
      return this.complete.emit({ facility: this.selectedFacility, speciality: this.selectedSpeciality, practitioner: this.selectedPract });

    this.view = 'SP';
    this.searchStr = '';
    this.getSpecialities();
    this.getPractitioner();
    this.hideDelayRunning()
    // this.complete.emit({ facility: obj });
  }

  public completeSpecialtiySelection(event: any) {
    console.log('completeSpecialtiySelection', event)
    this.selectedSpecialityEvent = event;
    this.filterSearch = '';
    this.checkFocusView(this.filterSearch, 'SPEC')
    let specNameArray = event.specialityName
    if (!event.specialityName) {
      specNameArray = [{ locale: 'en', name: event['specialityDesc'] }]
    }
    let obj = {
      id: event.specialityId,
      code: event.sourceSpecialityCode,
      desc: event.specialityDesc,
      name: specNameArray,
      facilityId: event.facilityId
    }
    this.selectedSpeciality = obj;
    this.searchStr = '';
    this.checkSpecFocusView(this.searchStr)
    // this.complete.emit({ speciality: obj });
    this.getPractitioner();
    this.view = 'SP';
    this.selectedType = 'PRACT';
    this.hideDelayRunning()
  }

  private getNext7Days() {
    let count = 0;
    const days = []
    while (count < 7) {
      days.push(moment().add(count, 'day').format('YYYY-MM-DD'));
      count++;
    }
    return days;
  }
  public clearPractView() {
    this.practfocusView = false;
    this.practSrch = '';
  }


  public completePractSelection(event: any) {
    console.log('completePractSelection', event);
    this.selectedPractitionerEvent = event;
    this.clearPractView();
    let obj = {
      id: event.resourceId,
      personId: event.personId,
      name: event.resourceName,
      resourceAvatarURL: event.resourceAvatarURL,
      info: event.additionalInfo['info'],
      availDays: event['schedulerControls']['daysAvailable'],
      schedulerControls: event['schedulerControls'],
      additionalInfo: event['additionalInfo'],
      specialityId: event['specialityId'],
      _facilities: event['facList'],
      daysAvailable: []
    }
    if (this.visitType == 'VIDEO') {
      obj['facList'] = event['facList'];
    }
    if (!this.selectedSpeciality) {
      let spec = this.specialityList.find(s => s['specialityId'] == event['specialityId']);
      if (spec) {
        this.completeSpecialtiySelection(spec);
      }
    }
    if (!this.selectedFacility && this.visitType !== 'VIDEO')
      this.changePract = true;
    let facilities;
    if (event['facList'].length > 0) {
      facilities = event['facList'].map(a => a.facilityId);
      console.log('facilities', facilities)
    }
    this.selectedPract = obj;
    facilities.forEach(a => {
      this.fetchPractitionerSlots(event, a)
    })
    if (!this.selectedFacility && this.selectedSpeciality && this.selectedPract && this.visitType !== 'VIDEO') {
      let srchSelection = {
        searchFlow: true,
        speciality: this.selectedSpeciality,
        practitioner: this.selectedPract,
        selectedSpecialityEvent: this.selectedSpecialityEvent,
        selectedPractitionerEvent: this.selectedPractitionerEvent
      }
      this.user.setInclinicSelection(srchSelection);
    }
    if (this.selectedFacility && this.selectedSpeciality && this.selectedPract && this.visitType !== 'VIDEO') {
      let selection = {
        searchFlow: false,
        facility: this.selectedFacility,
        speciality: this.selectedSpeciality,
        practitioner: this.selectedPract,
        selectedSpecialityEvent: this.selectedSpecialityEvent
      }
      this.user.setInclinicSelection(selection)
    }
    console.log('selectedPract', this.selectedPract);
    if (!this.selectedFacility && this.visitType !== 'VIDEO')
      return this.view = 'AVAILABLE';
    this.complete.emit({ facility: this.selectedFacility, speciality: this.selectedSpeciality, practitioner: this.selectedPract })
    // this.complete.emit({ practitioner: obj });
  }

  // private fetchPractitionerSlotsForFacility(practitioners) {
  //   console.log('fetchPractitionerSlotsForFacility', practitioners)
  //   practitioners.forEach(pract => {
  //     const dates = this.getNext7Days()
  //     const offset = this.user.getDirectOffset(pract.facilityId);
  //     pract['slotDates'] = dates
  //     const payload = {
  //       practitioners: [pract.resourceId],
  //       appointmentType: this.visitType,
  //       facilities: [pract.facilityId],
  //       dates: dates,
  //       offset: { [pract.facilityId]: offset}
  //     }
  //     this.user.getAvailableDaysForPract(payload).subscribe(data => {
  //       console.log(data)
  //       // this.mapDays(data, [pract.facilityId], true)
  //     })
  //   })

  // }

  public fetchPractitionerSlots(practitioner, facilityId) {
    const dates = this.getNext7Days()
    const offset = this.user.getDirectOffset(facilityId)
    const payload = {
      practitioners: [practitioner.resourceId],
      appointmentType: this.visitType,
      facilities: [facilityId],
      dates: dates,
      offset: offset
    }
    this.slotDates = dates;
    console.log(payload)

    this.user.getAvailableDaysForPract(payload).subscribe(data => {
      console.log('getAvailableDaysForPract', data)
      this.mapDays(data, facilityId)
    })
  }

  public mapDays(data, facId) {
    let resourceId = this.selectedPract.id;
    this.selectedPract._facilities = this.selectedPract._facilities.map(a => {
      if (a.facilityId != facId) return a;
      const pract = data.find(dat => dat.practitioner == resourceId);
      console.log('praccccc', pract);
      if (pract && pract.daysAvailable) {
        a.daysAvailable = pract.daysAvailable;
      }
      return a;
    })
    console.log('selected prac', this.selectedPract);
  }

  public getFacilitiesForPract(practitionerId) {
    let selectedPractitioner = this.allPractitioner.filter(pract => pract.resourceId == practitionerId)
    return selectedPractitioner.map(pract => pract.facilityId);
  }

  public hideDelayRunning() {
    this.delayRunning = false;
    this.changeDetectorRef.detectChanges();
  }

  public checkFocusView(searchStr, source?) {
    setTimeout(() => {
      ((searchStr && searchStr.length == 0 || !searchStr) ? this.focusView = false : this.focusView = true)
      console.log('check focus view--', this.focusView, searchStr)
    }, 100)
  }

  public checkSpecFocusView(searchStr, source?) {
    setTimeout(() => {
      ((searchStr && searchStr.length == 0 || !searchStr) ? this.specfocusView = false : this.specfocusView = true)
      console.log('check focus view--', this.specfocusView, searchStr)
    }, 100)
  }

  public checkPractFocusView(searchStr) {
    setTimeout(() => {
      ((searchStr && searchStr.length == 0 || !searchStr) ? this.practfocusView = false : this.practfocusView = true)
      console.log('check focus view--', this.specfocusView, searchStr)
    }, 100)
  }

  public clearSearch(isFacility?) {
    this.filterSearch = '';
    this.searchStr = '';
    this.selectedSymptom = '';
    this.checkFocusView(this.filterSearch);
    this.checkSpecFocusView(this.searchStr);
    this.selectedSpeciality = null;
    this.getPractitioner();
    this.goBackToSymptomSearch();
    this.clearPractView();
    // this.getRecentPracts();
    this.selectedType = 'SPEC';
    console.log('clear--')
    if (isFacility) {
      this.selectedFacility = null;
      this.view = 'FACILITY';
    }
  }

  changeView(view) {
    if (view == 'SPEC') {
      this.selectedSymptom = '';
      this.goBackToSymptomSearch();
      this.view = 'SP';
      this.selectedType = 'SPEC';
      this.selectedSpeciality = null;
      this.backToFac = true;
      this.clearPractView()
    } else if (view == 'FAC') {
      this.selectedFacility = null;
      this.selectedSpeciality = null;
      this.view = 'FACILITY';
      this.backToFac = false;
      this.clearPractView();
      this.goBackToSymptomSearch();
      this.checkFocusView(this.filterSearch);
      this.checkSpecFocusView(this.searchStr);
      this.checkPractFocusView(this.practSrch);
      this.specfocusView = false;
    } else if (view == 'PRACLIST') {
      this.changePract = false;
      this.completeSpecialtiySelection(this.selectedSpecialityEvent);
    }
  }

  backToSpec(spec?) {
    if (spec) {
      this.selectedSpeciality = null;
      this.selectedType = 'SPEC';
    }
  }

  clearPractSearch() {
    this.practSrch = '';
    this.getPractitioner();
    this.getRecentPracts();
  }


  public loadMap() {
    var mapProp = {
      zoom: 20,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    var latLong = new google.maps.LatLng(this.position?.['lat'] || 0, this.position?.['lng'] || 0);
    console.log("current location->", latLong);
    this.curMarker = null;
    this.curMarker = new google.maps.Marker({
      position: latLong,
      icon: 'assets/icons/locate.svg',
      draggable: true,
      map: this.map
    });
    google.maps.event.addListener(this.curMarker, 'dragend', () => {
      this.position = {
        lat: this.curMarker.position.lat(),
        lng: this.curMarker.position.lng(),
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.position }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.locationAddress = results[0].formatted_address;
            // if (this.isEditLocation) {
            //   this.searchText = results[0].formatted_address;
            // }
          }
        }
      })
      this.getFacilityList();
    });
    this.map.setZoom(12);
    this.map.setCenter(this.curMarker.getPosition());
    this.map.addListener('click', (e) => {
      this.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      }
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.position }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.locationAddress = results[0].formatted_address;
            // this.searchText = results[0].formatted_address;
          }
        }
      })
      this.getFacilityList();
    });
    let bounds = new google.maps.LatLngBounds();
    bounds.extend(this.curMarker.getPosition());
    if (Array.isArray(this.facilityList))
      this.addMarker(bounds);
  }

  public addMarker(bounds) {
    this.facilityList = _.cloneDeep(this.facilityList).map(facility => {
      facility['latitude'] = facility['latitude'] || 0;
      facility['longitude'] = facility['longitude'] || 0;
      facility['distance'] = this.getDistanceFromLatLonInKm(this.position?.['lat'] || 0, this.position?.['lng'] || 0, facility['latitude'], facility['longitude']);
      return facility;
    }).sort((a, b) => (Number(a.distance) > Number(b.distance)) ? 1 : ((Number(b.distance) > Number(a.distance)) ? -1 : 0))
    console.log('facilityList', this.facilityList)
    let allMarkers = this.facilityList.map(facility => {
      return new Promise((resolve) => {
        var latLong = new google.maps.LatLng(facility['latitude'], facility['longitude']);
        var marker = new google.maps.Marker({
          position: latLong,
          icon: 'assets/icons/gmap_marker_notify_icon.svg'
        });
        marker.setMap(this.map);
        facility['_marker'] = marker;
        marker.addListener('click', (event) => {
          console.log(event);
          this.facilityList.map(facility => facility['selected'] = false);
          facility['selected'] = true;
          this.showMapPopover = true;
          this.mapSelectedFacility = facility;
          let popover = document.getElementById('map-popover');
          popover.style.display = 'block';
          popover.style.top = '50%';
          popover.style.left = '50%';
          popover.style.transform = 'translate(-50%, -50%)';
        });
        resolve(true);
      })
    });
    console.log("facility list->", this.facilityList);
    Promise.all(allMarkers).then(() => {
      let f = this.facilityList.slice(0, 3);
      console.log(f);
      f.forEach(({ _marker }) => {
        bounds.extend(_marker.getPosition());
      })
      this.map.fitBounds(bounds)
    });;
  }


  public getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(2);
  }

  public deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  public getCurrentLatLon(map?: boolean) {
    if (map) {
      this.changeFacilityTab('MAP')
    }
    if (this.platform.IOS) {
      var self = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log("success geolocation", position)
          if (position) {
            let lat = position.coords.latitude;
            let lon = position.coords.longitude;
            self.geoErrorMessage = null;
            self.position = {
              lat: lat,
              lng: lon
            }
            self.getAddress();
            self.getFacilityList();
          } else {
            self.geoErrorMessage = 'error_getting_location';
            self.isEditLocation = true;
            this.focusMapAddress()
          }
        },
        function () {
          console.log("error geo location")
          self.geoErrorMessage = 'error_getting_location';
          self.isEditLocation = true;
          this.focusMapAddress()
        }
      );
    } else {
      this.user.getGeoLocation().subscribe(data => {
        console.log('Express clinic choose-----', data)
        if (data.status) {
          this.geoErrorMessage = null;
          this.position = {
            lat: data['data']['lat'],
            lng: data['data']['lon']
          }
          this.getAddress();
          this.getFacilityList();
        } else {
          switch (data.message) {
            case 'DECLINED':
              this.geoErrorMessage = 'location_declined';
              break;
            case 'NOOPTION':
              this.geoErrorMessage = 'no_location_service';
              break;
            default:
              this.geoErrorMessage = 'error_getting_location';
              break;
          }
          this.isEditLocation = true;
          this.focusMapAddress()
        }
      })
    }
  }

  private focusMapAddress() {
    const el = document.getElementById('mapaddresstext')
    if (el) {
      document.getElementById('mapaddresstext').focus();
    }
  }
  public getAddress() {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: this.position }, (results, status) => {
      console.log('expres-----geocoder ---', status)
      if (status === 'OK') {
        if (results[0]) {
          this.locationAddress = results[0].formatted_address;
        }
      }
    })
  }

  public changeFacilityTab(tab) {
    this.selectedFacilityTab = tab;
    if (tab == 'MAP') {
      setTimeout(this.loadMap.bind(this), 200)
    }
  }

  public handleInput() {
    console.log('searchStr', this.searchStr.length);
    if (this.enableSymptomSearch) {
      if (this.searchString && this.searchString.length > 2) {
        let length = this.searchString.length;
        setTimeout(() => {
          if (length === this.searchString.length) {
            this.showSymLoader = true;
            this.apptService.getSymptomsBySearch(this.searchString).subscribe((data) => {
              console.log('search data--', data)
              // this.symptoms=data['symptomes'];

              let tmp = []
              if (data['symptomes'] && data['symptomes'].length > 0) {
                data['symptomes'].forEach(sym => {
                  if (this.canShowSymptomSpec(sym)) {
                    sym['applicable'] = true;
                    tmp.push(sym)
                  }
                })
                this.symptoms = tmp;
                tmp = [];
              } else this.symptoms = undefined;

              this.showSymLoader = false;
            }, error => {
              this.showSymLoader = false;
            })
          }
        }, 1000)
      }
      else {
        this.symptoms = undefined;
      }
    }

    if (this.searchString && this.searchString.length > 0) {
      setTimeout(() => {
        const elements = document.querySelectorAll('.sortable');
        const elementsArray = Array.from(elements);

        const sortByDataValue = (a, b) => {
          const aDataValue = a.getAttribute('data-value');
          const bDataValue = b.getAttribute('data-value');
          return aDataValue - bDataValue
        }
        elementsArray.sort(sortByDataValue);
        const container = document.getElementById('search-sort-container');
        console.log('container--', container, elementsArray)
        if (elementsArray && elementsArray.length > 0) {
          // container.innerHTML = '';
          elementsArray.forEach(element => {
            container.appendChild(element);
          })
        }
      }, 1500)
    }
  }



  public getSymptomName(names) {
    // console.log('get symptom names--',names,this.user.getCurrentLanguage())
    if (names && names.length > 0) {
      let name = names.find(n => n.locale == this.user.getCurrentLanguage())
      if (name && name['name'])
        return name['name']
      else return names[0].name;
      // for(let i=0;i<names.length;i++){
      //   if(names[i].locale==this.user.getCurrentLanguage())
      //     return names[i].name;
      //   return names[0].name;
      // }
    }
  }

  public canShowSymptomSpec(symptom) {
    console.log('symptom--', symptom, this.specialityList);
    if (this.specialityList && this.specialityList.length > 0) {
      if (symptom.conceptControls && symptom.conceptControls.controls && symptom.conceptControls.controls.applicableSpecialities && symptom.conceptControls.controls.applicableSpecialities.length > 0) {
        let spec = this.specialityList.find(s => symptom.conceptControls.controls.applicableSpecialities.includes(s.specialityId))
        console.log('find spec--', spec)
        if (spec)
          return true;
      }
      return false;
    }
    return false;
  }

  public selectsymptomsFromSearch(symptom, from?) {
    if (symptom.conceptControls && symptom.conceptControls.controls && symptom.conceptControls.controls.applicableSpecialities && symptom.conceptControls.controls.applicableSpecialities.length > 0) {
      let linkedSpecs = []
      // this.allSpecsForAllFacility.forEach(s=>{
      //   if(symptom.conceptControls.controls.applicableSpecialities.includes(s.specialityId))
      //     linkedSpecs.push(s)
      // })
      if (from == 'FROMSPEC') {
        this.specSymptomDetailView = true;
        this.focusView = false;
        this.specfocusView = true;
        this.specialityList.forEach(s => {
          if (symptom.conceptControls.controls.applicableSpecialities.includes(s.specialityId))
            linkedSpecs.push(s)
        })
      } else {
        this.specialityList.forEach(s => {
          if (symptom.conceptControls.controls.applicableSpecialities.includes(s.specialityId))
            linkedSpecs.push(s)
        })
      }
      linkedSpecs = _.uniqBy(linkedSpecs, 'specialityId')
      this.symptomBasedSpecList = linkedSpecs;
      this.selectedSymptom = symptom;
      console.log('find spec--', linkedSpecs)
    }
    this.filterSearch = '';
    this.searchStr = '';
    this.symptomSpecListview = true;
  }

  public selectSpecialityFromSearch(spec) {
    console.log('selectSpecialityFromSearch', spec);
    this.searchStr = '';
    this.specfocusView = false;
    this.symptomSpecListview = false;
    this.filterSearch = '';
    this.checkFocusView(this.filterSearch, 'SPEC')
    let facilities = this.user.getAttributeForListingType('facilityServices', true)['facilities'];
    console.log('facilities', facilities);
    let tmp = [], tmpPrac = [];
    // this.focusView = false;
    let practitionerFilter = this.visitType == "VIDEO" ? ['VIDEO', 'HOSPVIDEO'] : ['HOSPITAL', 'HOSPVIDEO']
    facilities.forEach((f) => {
      if (f['specialities'] && f['specialities'].length > 0) {
        f['specialities'].forEach((s) => {
          if (s['specialityId'] == spec['specialityId']) {
            tmp.push(f)
            if (f['practitioners'] && f['practitioners'].length > 0) {
              f['practitioners'].forEach(p => {
                if (p['specialityId'] == spec['specialityId'] && (p['schedulerControls'] && p['schedulerControls']['consultType'] && practitionerFilter.includes(p['schedulerControls']['consultType'])))
                  tmpPrac.push(p)
              })
            }
          }
        })
      }
    })
    spec['_multipleSpecialityInfo'] = tmp;
    // this.allPractInSearchedSpecialities=tmpPrac;
    let tmpGrp = _.values(_.groupBy(tmpPrac, (p) => p['resourceId']))
    tmpPrac.forEach((p) => {
      tmpGrp.forEach(g => {
        g.forEach(pg => {
          if (p['resourceId'] == pg['resourceId'])
            p['_multiple'] = g
        })
      })
    })
    let tmpuniq = _.uniqBy(tmpPrac, 'resourceId')
    this.allPractInSearchedSpecialities = tmpuniq
    console.log('selected spec', spec, tmpPrac, tmpGrp, tmpuniq)

  }

  public checkNoData(drs, specs, clinics?) {
    console.log('drss--', drs, specs, clinics, this.enableSymptomSearch, this.symptoms)
    if (this.enableSymptomSearch && this.symptoms) {
      if (drs.length == 0 && specs.length == 0 && clinics.length == 0 && this.symptoms.length == 0)
        return true;
    } else {
      if (drs.length == 0 && specs.length == 0 && clinics.length == 0)
        return true;
    }
    return false;
  }

  public checkSpecNoData(specs) {
    if (specs.length == 0)
      return true;
    return false;
  }

  public goBackToSymptomSearch() {
    this.symptomSpecListview = false;
    this.selectedSymptom = '';
    this.symptoms = undefined;
  }

  public getAvailablePractList(pract) {
    let practitionerList = pract.map(list => {
      list.preview = { hasDiscount: false };
      if (this.visitType != 'VIDEO') {
        let discountCharge = 0;
        let charge = 0;

        if (list && list.schedulerControls && Array.isArray(list.schedulerControls.locationCharges) && list.schedulerControls.locationCharges.length > 0)
          charge = list.schedulerControls.locationCharges[0].charges
        else
          charge = list.schedulerControls.charge

        if (list && list.schedulerControls && Array.isArray(list.schedulerControls.locationCharges) && list.schedulerControls.locationCharges.length > 0)
          discountCharge = list.schedulerControls.locationCharges[0]['discountCharge']
        else if (list.schedulerControls && list.schedulerControls['discountCharge'])
          discountCharge = list.schedulerControls['discountCharge']


        if (discountCharge) {
          list.preview = { hasDiscount: true, originalCharge: charge, payableCharge: discountCharge }
        } else {
          list.preview = { hasDiscount: false, originalCharge: charge, payableCharge: charge }
        }
        return list;
      } else {
        let discountCharge = 0;
        let charge = 0;

        if (list && list.schedulerControls && list.schedulerControls.videoConsultCharge)
          charge = list.schedulerControls.videoConsultCharge;

        if (list && list.schedulerControls && list.schedulerControls.videoConsultDiscountCharge)
          discountCharge = list.schedulerControls.videoConsultDiscountCharge;

        if (discountCharge) {
          list.preview = { hasDiscount: true, originalCharge: charge, payableCharge: discountCharge }
        } else {
          list.preview = { hasDiscount: false, originalCharge: charge, payableCharge: charge }
        }
        return list;
      }
    })
    const dates = this.getNext7Days()
    const offset = this.selectedFacility?.facilityControls?.['schedulingRules']?.['offsetTimeForDirectBooking'];
    this.isSlotsLoading = true;
    if (practitionerList.length > 0) {
      let payload = {
        practitioners: practitionerList.map(p => p['resourceId']),
        appointmentType: this.visitType,
        facilities: [this.facilityId],
        dates: dates,
        offset: { [this.facilityId]: offset }
      };

      if (this.visitType == 'VIDEO') {
        this.selectedFacility = this.facilityList;
        const offset = this.facilityList.map(f => f['facilityId']).reduce((r, a) => {
          let facility = this.facilityList.filter(f => a == f['facilityId']);
          r[a] = facility[0]?.facilityControls?.['schedulingRules']?.['offsetTimeForDirectBooking'];
          return r;
        }, {})
        payload.facilities = this.facilityList.map(f => f['facilityId']);
        payload.offset = offset;
      }
      this.slotDates = dates;
      this.user.getAvailableDaysForPract(payload).subscribe(data => {
        console.log(data)
       let practitioners = practitionerList.map(list => {
          data.map(parc => {
            if (list.resourceId == parc.practitioner)
              list['_availableDates'] = parc.daysAvailable;
          })
          return list;
        })
        if(practitioners && practitioners.length > 0) {
          let tmp=practitionerList,date=moment().format("YYYY-MM-DD");
          tmp=tmp.filter(t=>{
          if (t._availableDates && t._availableDates[date] && t._availableDates[date] > 0) {
            return t;
          }
        })
          this.availableDr = _.cloneDeep(tmp);
          console.log('availble dr', this.availableDr);
        }
      })
    }
  }

  public async showAvailableDr(val, practs) {
    // console.log('showAvailableDr val', val.srcElement.checked, practs, this.availableDr);
    if (val.srcElement.checked && practs && practs.length > 0) {
      this.clonedPracts = _.cloneDeep(practs)
      this.practitionerList = practs;
    } else {
      this.getPractitioner();
    }
  }

  public onScroll() {
    // console.log('scrolled---', this.clonedPracts.length, this.practitionerList.length);
    if(this.practitionerList.length < this.clonedPracts.length)
    this.getpractBasedOnInfinite(this.SCount, this.ECount, this.clonedPracts, this.facilityId)
  }

  //undefined functions
  public navigateUser(arg) {

  }
}
