<div id="book-appointment" class="right-nav">
    <div class="component">
      <ng-container *ngIf="!isShowExternalFilter">
      <ng-container *ngIf="!hideHealthPackages && !openPkgReq"> 
        <div class="header border-bottom">
            <div class="font-primary-title bold">{{ 'title_health_chkup_pkgs'|translate:'LABELS'}} </div>
            <div (click)="closeModal()" class="float-right closeicon pointer">
              <img src="./assets/icons/close_black.svg">
            </div>
        </div>
        <div class="modal-pkg-content">
            <div class="filter-section">
                <div class="search" id="search-static">
                    <img class="type-image" src="./assets/icons/Book_new/search.svg">
                    <input type="text" [(ngModel)]="searchkey" placeholder="{{'plhd_search_package'|translate}}" >
                    <img class="close-icon cursor-pointer" src="assets/icons/close_grey.svg" *ngIf="searchkey" (click)="searchkey=''">
                </div>
                <div class="filter-template">
                  <div class="filter-icon show" (click)="openFilterModal($event)">
                    <img class="type-image" src="assets/icons/health-package/Filter.svg">
                  </div>
                  <div class="filter-content">
                    <app-filter [filterInput]="applicableFilter" (complete)="filterPackages($event)" (showExternalFilter)="openFilterModal($event)"></app-filter>
                  </div>
                </div>

                <div class="flex-container" *ngIf="false">
                  <div class="group">
                    <div class="label">
                      <label class="font-light-xssmall">{{'lbl_choose_facility' | translate }}</label>
                    </div>
                      <div class="inputs ">
                        <div class="input-group select-input">
                          <div class="location-input" (click)="searchFacility.show = !searchFacility.show">
                            <div class="image hide">
                              <img  class="type-image" src="assets/icons/current.svg" alt="">
                            </div>
                            <div class="value  font-default-small">
                              <!-- {{searchFacility.selected['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                              {{(searchFacility.selected['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                            </div>
                            <div class="image show">
                              <img  src="assets/icons/calendar/drop.svg" alt="">
                            </div>
                          </div>
                          <div class="dropdown" *ngIf="searchFacility.show == true">
                            <div class="dropdown-options">
                              <a class="dropdown-item" *ngFor="let x of facilityList" (click)="selectFacility(x)">
                                <!-- {{x['facilityName'] | translate : 'LOCALE' : 'name'}} -->
                                {{(x['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="group" *ngIf=gender.show>
                      <div class="label">
                        <label class="font-light-xssmall">{{ 'lbl_select_gender' | translate }}</label>
                      </div>
                      <div class="flex hide">
                        <div class="radio-option" *ngFor="let item of genderList">
                          <input type="radio" name="label" [checked]="isSelectedGender === item.code ? true: false"
                            (change)="selectGender(item)" [id]="'lang'+item.code">
                          <label class="label" [for]="'lang'+item.code" class="font-default-small">{{item['desc'] | translate}} </label>
                        </div>
                      </div>
                      <div class="inputs show">
                        <div class="input-group select-input">
                          <div class="location-input" (click)="searchGender = !searchGender">
                            <div class="value  font-default-small">
                              {{ selectedGenderValue | translate}}
                            </div>
                            <div class="image">
                              <img  src="assets/icons/calendar/drop.svg" alt="">
                            </div>
                          </div>
                          <div class="dropdown" *ngIf="searchGender == true">
                            <div class="dropdown-options">
                              <a class="dropdown-item" *ngFor="let item of genderList" (click)="selectGender(item)">
                                {{item['desc'] | translate}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="package-section">
                <div class="cards">
                  <div class="card-layout" *ngFor="let c of (packs.concepts  | filterSearch : searchkey : 'name': 'LANGARRAY' : 'name' | healthpack : applicableFilter : true);" >
                    <!-- <div  class="card-layout"  *ngFor="let c of packs.concepts;"> -->
                      <div class="card row cursor-pointer" (click)="getSelectedPackageDetail(c)" [ngClass]="{'expired-package': !checkExpire(c.facilities)}">
                        <div class="image">
                          <ng-container *ngIf="c.detail | translate : 'LANGARRAY' : 'imageURL'; else DFLTIMG">
                            <img class="type-image" [src]="c.detail | translate : 'LANGARRAY' : 'imageURL'" alt="">
                          </ng-container>
                          <ng-template #DFLTIMG>
                            <img class="type-image" src="assets/icons/health-package/healthpack_dflt.png" alt="">
                          </ng-template>
                        </div>
                        <div class="card-content font-default-subtitle">
                          <div>
                            <p class="font-default-subtitle bold">{{c.name | translate : 'LANGARRAY' : 'name'}}</p>
                            <!-- <span class="font-ternary-subtitle">{{ c.detail | translate : 'LANGARRAY' : 'description'}}</span> -->
                          </div>
                          <div class="font-primary-subtitle bold clmn">
                            <span *ngIf="getDiscountChargeByFacility(c)">{{getDiscountChargeByFacility(c)}} {{currency_code}} </span>
                            <span *ngIf="getChargeByFacility(c)" [ngClass]="{ 'lstrike' : getDiscountChargeByFacility(c) }">{{getChargeByFacility(c)}} {{currency_code}} </span>
         
                            <!-- <span [ngClass]="{ 'lstrike' : c.facilities[0].controls.discountCharge}">{{currency_code}} {{c.facilities[0].controls.charge}}</span>
                            <span *ngIf="c.facilities[0].controls.discountCharge">{{currency_code}} {{c.facilities[0].controls.discountCharge}}</span>    -->
                            <!-- {{currency_code}} {{c.facilities[0].controls.charge}} -->
                          </div>
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->
                </div>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hideHealthPackages && !openPkgReq && false">
        <app-health-package-details [selectedPkg]="selectedPkg" (closePkg)="closePkgDetail()" (openRequest)="openConfirm($event)" (action)="checkAction($event)"></app-health-package-details>
      </ng-container>
      <ng-container *ngIf="openPkgReq && false">
        <app-health-package-confirm-request [pkgPrefer]="userPkgPeferrence" [reqConfirm]="reqConfirm" (close)="closeModal()"></app-health-package-confirm-request>
      </ng-container>
    </ng-container>
      <div *ngIf="isShowExternalFilter">
        <app-filter [filterInput]="applicableFilter" (close)="filterPackages($event)" [isShowExternalFilter]="isShowExternalFilter" (showExternalFilter)="openFilterModal($event)"></app-filter>
      </div>
    </div>
</div>