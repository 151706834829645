<div id="appointment-queue-preview">
    <div class="card-container">
      <div class="card">
        <div class="checkin-receipt-container" *ngIf="appointment && viewType=='CHECKIN'">
            <div class="top-box">
              <div class="success-label-checkin">
                <!-- <ion-icon class="tick-icon" src="assets/icon/tick_white.svg"></ion-icon> -->
                <img  class="tick-icon" src="assets/icons/tick_white.svg">
                <div class="msg-con">
                  <p>{{'checkin_queue_confirm_success_msg' | translate : 'LABELS' : null : curLang}}</p>
                </div>
              </div>
              <div class="success-map-label" *ngIf="(mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show))">
                <p>{{ 'CHECKIN_QUEUE_CONFIRMATION#SUCCESS#MAPMESSAGE' | translate}} <span *ngIf="mappingFloorControls.floor.show && (mappingFloorInfo && mappingFloorInfo.floor)">{{getFloorName(mappingFloorInfo.floor)+ ', '}}</span>  <span *ngIf="mappingFloorControls.waitingArea.show && (mappingFloorInfo && mappingFloorInfo.waitingArea)"> {{'CHECKIN_QUEUE_CONFIRMATION#LABEL#MAPPINGINFO#WAITINGAREA' | translate}} {{mappingFloorInfo.waitingArea+ ','}}</span><span  *ngIf="mappingFloorControls.room.show && (mappingFloorInfo && mappingFloorInfo.room)">{{'CHECKIN_QUEUE_CONFIRMATION#LABEL#MAPPINGINFO#ROOM' | translate}}  {{mappingFloorInfo.room}}</span></p>
              </div>
              <div class="queue-container">
                <p class="queue-info-label" *ngIf="queueNo">{{ 'checkin_queue_confirm_success_your_no' | translate : 'LABELS' : null : curLang}}</p>
                <!-- <p class="queue-info-label"  *ngIf="!queueNo && loadingQueue" >{{ 'CHECKIN_QUEUE_CONFIRMATION#LABEL#RETRIEVINGYOURQUEUE' | translate : 'LABELS' : null : curLang}}</p>
                <p class="queue-info-label"  *ngIf="!queueNo && !loadingQueue" >{{ 'CHECKIN_QUEUE_CONFIRMATION#LABEL#YOUAREINQUEUE' | translate : 'LABELS' : null : curLang}}</p> -->
                <div class="queue-con" *ngIf="queueNo">
                  <p>
                    {{queueNo}}
                  </p>
                </div>
                <!-- <div class="queue-error-con" *ngIf="!queueNo && !loadingQueue">
                  <p>{{ 'CHECKIN_QUEUE_CONFIRMATION#ERROR#LABEL#QUEUECOULDNOTBERETRIEVED' | translate : 'LABELS' : null : curLang}}</p>
                  <div class="retry-but-con" (click)="getQueueNo()">
                    <p>{{ 'CHECKIN_QUEUE_CONFIRMATION#ERROR#BUTTON#TRYAGAIN' | translate : 'LABELS' : null : curLang}}</p>
                  </div>
                </div>
                <div class="queue-retrieve-con" *ngIf="!queueNo && loadingQueue" >
                  <div class="loader-2 center"><span></span></div>
                </div> -->
              </div>
            </div>
            <div class="patient-info-container">
              <div class="avatar" [ngClass]="{'avatar-with-mapFloor':(mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show))}">
                <app-person-avatar [url]="(appointment['resourceId']|partitionerDetails:
                appointment['facilityId']:'resourceAvatarURL')"> </app-person-avatar>
              </div>
              <p class="dr-name">
                <ng-container *ngIf="appointment.practitionerLocalName">
                    {{appointment.practitionerLocalName | translate : 'LOCALE' : 'name' : curLang}}
                  </ng-container>
                  <ng-container *ngIf="!appointment.practitionerLocalName && appointment.practitionerName">
                    {{appointment.practitionerName}}
                  </ng-container>
                  <ng-container *ngIf="!appointment.practitionerLocalName && !appointment.practitionerName">
                    {{(appointment['resourceId']|partitionerDetails:appointment['facilityId']:'resourceName'| translate : 'LOCALE' : 'name': curLang) || 'Doctor'}}
                  </ng-container>
              </p>
              <p class="dr-class">{{(appointment['resourceId']|partitionerDetails: appointment['facilityId']:'specialityId') | specialityDetails: appointment['facilityId']:'specialityName'| translate : 'LOCALE' : 'name': curLang}}</p>
              <div class="patient-name-con borders-css">
                <p class="patient-label">{{ 'checkin_queue_confirm_success_patientname' | translate : 'LABELS' : null : curLang}}</p>
                <p class="patient-value"> {{appointment['personName'] ? appointment['personName']: ''}}
                  <span>{{ appointment['patientId'] }}</span>
                </p>
              </div>
              <div class="patient-name-con">
                <p class="patient-label">{{ 'checkin_queue_confirm_success_datetime' | translate : 'LABELS' : null : curLang}}</p>
                <p class="patient-value">{{ appointment['appointmentDateTime'] | moment : 'DD MMM YYYY, hh:mm a' : 'YYYY-MM-DDHH:mm'}}</p>
              </div>
            </div>
            <div class="triangel-con">
              <div *ngFor="let t of triangleArr"  class="triangle-css">
              </div>
            </div>
        </div>
        <div class="header" *ngIf="viewType=='SPEAK'">
          <div class="logo">
            <img class="logo type-image" src="assets/images/signin/logo_grey.png" alt="">
          </div>
        </div>
        <p *ngIf="viewType=='SPEAK'" class="apeak-queue-msg">
          {{ 'speak_queue_message' | translate : 'LABELS' : null : curLang}}
        </p>
        <div class="ticket-con" *ngIf="viewType=='SPEAK' && queueNo">
          <p>{{queueNo}}</p>
        </div>
      </div>
    </div>
  </div>
