import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @Input() src: string;

  public svg: any;
  constructor(private sanitizer: DomSanitizer) { }
  
  ngOnInit() {
    const url = this.getUrl();
    if (url) { 
      fetch(url)
        .then(response => response.text())
        .then(data => {
          this.svg = this.sanitizer.bypassSecurityTrustHtml(data);
        });
    }
  }

  private getUrl = () => {
    let url = this.getSrc(this.src);
    if (url) {
      return url;
    }
    return null;
  };
  
  private getSrc = (src) => {
    const isSrc = (str) => str.length > 0 && /(\/|\.)/.test(str);
    const isStr = (val) => typeof val === 'string';
    if (isStr(src)) {
      src = src.trim();
      if (isSrc(src)) {
        return src;
      }
    }
    return null;
  };
}
