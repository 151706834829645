import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AltpaymentInputModule } from "../components/altpayment-input/altpayment-input.module";
import { DisplayCurrencyModule } from "../components/display-currency/display-currency.module";
import { PromoInputModule } from "../components/promo-input/promo-input.module";
import { CouponModalModule } from "../coupon-modal/coupon-modal.module";
import { CouponModule } from "../coupon/coupon.module";
import { CustomModalModule } from "../custom-modal-container/custom-modal-container.module";
import { PersonAvatarModule } from "../person-avatar/person-avatar.module";
import { PipesModule } from "../pipes/pipes.module";
import { PractitionerAvatarModule } from "../practitioner-avatar/practitioner-avatar.module";
import { VideoConsultTCComponent } from "./video-consult-tc.component";

@NgModule({
  declarations: [VideoConsultTCComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    PipesModule,
    CustomModalModule,
    PersonAvatarModule,
    PractitionerAvatarModule,
    FormsModule,
    CouponModule,
    CouponModalModule,
    AltpaymentInputModule,
    PromoInputModule,
    DisplayCurrencyModule,
  ],
  exports: [VideoConsultTCComponent],
})
export class VideoConsultTCModule {}
