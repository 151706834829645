<div id="appointments">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img class="type-image" src="assets/icons/calendar_app.svg" />
        </div>
        <div class="font-default-title title">{{'hme_lbl_referrals'|translate:'LABELS'}}</div>
      </div>
    </div>
    <div class="list">
      <ng-container *ngIf="openReferrals && openReferrals.length>0">
        <app-list-item *ngFor="let item of openReferrals" (click)="openReferralDetails(item)">
          <span class="title">
            {{item.specialityDesc}}
          </span>
          <span class="sub-title">{{item.facilityId | facilityDetails : 'facilityName'| translate : translateType : 'name'}}</span>

          <span class="primary-content">
            <img class="type-image" src="assets/icons/user.svg">
            <span>
              {{"referred_by" | translate}}
              <ng-container *ngIf="item.referredPractitionerLocalName">
                {{item.referredPractitionerLocalName | translate : translateType : 'name'}}
              </ng-container>
              <ng-container *ngIf="!item.referredPractitionerLocalName && item.referredPractitionerName">
                {{item.practitionerName}}
              </ng-container>
              <ng-container *ngIf="!item.referredPractitionerLocalName && !item.referredPractitionerName">
                {{(item['referredResourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name') || 'Doctor'}}
              </ng-container>
            </span>
          </span>
          <span class="secondary-content">
            <img class="type-image" src="assets/icons/bookappt/calendar_grey.svg">
            <span>{{'valid_till'|translate}} {{item.referralEndDate | moment : 'DD MMM YYYY': 'YYYY-MM-DD'}}</span>
          </span>
          <span class='list-action'>
            {{item.personName}}
          </span>
        </app-list-item>
      </ng-container>
    </div>
  </div>
</div>