<div id="filter-container" (clickOutside)="closeFilter();" *ngIf="!isShowExternalFilter" >
    <ng-container *ngFor="let group of filterInput; let i = index">
        <div class="filter">
            <div class="dp-down cursor-pointer">
                <div class="dp-select" [ngClass]="{'bg-secondry': group['count'] > 0 , 'hide-dp-select': !isHideMobileView }" (click)="openCloseFilter(group)">
                    <span class="font-primary-default">
                        <span class="label" *ngIf="group['label']">{{ group['label'] | translate }}</span>
                        <span *ngIf="group['placeholderLbl']">{{ group['placeholderLbl'] | translate }}</span>
                        <span [ngClass]="{'circle-css': group['count']  != 'All' }">{{ group['count'] |  translate:'LABELS'}}</span>
                    </span>
                </div>
                <div class="dp-option " *ngIf="group.openFilter" [ngClass]="{'hide': !isHideMobileView}">
                    <div class="search" id="search-statics" *ngIf="group.searchApplicable">
                        <img class="type-image" src="./assets/icons/Book_new/search.svg">
                        <input type="text" [(ngModel)]="searchkey" placeholder="{{ group['searchLbl'] |translate }}">
                    </div>
                    <ng-container *ngIf="group.multiSelect">
                        <ng-container *ngIf="group.options && (group.options | searchFilter : searchkey: 'label' ).length>0">
                            <div class="input-group " *ngFor="let item of (group.options | searchFilter : searchkey: 'label' )" >
                                <ng-container>
                                    <input type="checkbox" id="{{item.key}}" (click)="chooseFilter($event, item, group)" [checked]="item.checked" />
                                    <label for="{{item.key}}">{{item.label |translate:'LABELS'}}</label>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="false">
                            <div class="input-group " *ngFor="let item of (group.options | localefilter : searchkey: 'value' :'name')" >
                                <ng-container>
                                    <input type="checkbox" id="{{item.key}}" (click)="chooseFilter($event, item, group)" />
                                    <label for="{{item.key}}">{{item.label |translate:'LABELS'}}</label>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!group.multiSelect">
                        <div class="input-group " *ngFor="let item of group.options" >
                            <input type="radio" id="{{item.key}}" (click)="chooseFilter($event, item, group)" name="{{group.type}}" value="{{item['key']}}" [checked]="item.checked" />

                            <label class="custom-radio-btn" for="{{item.key}}">{{item.label |translate:'LABELS'}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="filter-modal-content" *ngIf="isShowExternalFilter" >
    <div class="modal-header border-bottom">
      <div class="font-primary-subtitle bold">{{ 'Filters' |translate}}</div>
      <div (click)="closeFilterModal()" class="float-right closeicon pointer">
          <img class="type-image" src="assets/icons/close.svg" />
      </div>
    </div>
    <div class="modal-content">
        <div class="ex-container">
            <div class="ex-filter" >
                <div *ngFor="let group of filterInput; let i = index">
                    <div class="ex-dp-down cursor-pointer">
                        <div class="ex-dp-select" [ngClass]="{'bg-primary-clr': i ==  selectedIndex }" (click)="openCloseExternalFilter(group, i)">
                            <span class="">{{ group['label'] | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ex-dp-option" *ngIf="selectedOption.openFilter">
                <div class="search" id="search-statics" *ngIf="selectedOption.searchApplicable && false">
                    <img class="type-image" src="./assets/icons/Book_new/search.svg">
                    <input type="text" [(ngModel)]="searchkey" placeholder="{{ selectedOption['searchLbl'] |translate }}">
                </div>
                <ng-container *ngIf="selectedOption.multiSelect">
                    <div class="input-group border-bottom font-primary-subtitle " *ngIf="selectedOption.enableSelectAll" (click)="onClick($event, selectedOption, null)">{{ 'HEALTHPACKAGE#MODAL#FACILITY#SELECT/DESELECT_ALL' |translate:'LABELS'}}</div>
                    <ng-container *ngIf="selectedOption.options && (selectedOption.options | searchFilter : searchkey: 'label' ).length>0">
                        <div class="input-group " *ngFor="let item of (selectedOption.options | searchFilter : searchkey: 'label' )" >
                            <ng-container>
                                <input type="checkbox" id="{{item.key}}" (click)="chooseFilter($event, item, selectedOption)" [checked]="item.checked" />
                                <label for="{{item.key}}">{{item.label |translate:'LABELS'}}</label>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!selectedOption.multiSelect">
                    <div class="input-group " *ngFor="let item of selectedOption.options" >
                        <input type="radio" id="{{item.key}}" (click)="chooseFilter($event, item, selectedOption)" name="{{selectedOption.type}}" value="{{item['key']}}" [checked]="item.checked" />
                        <label class="custom-radio-btn" for="{{item.key}}">{{item.label |translate:'LABELS'}}</label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="button-bar">
        <div class="font-primary-subtitle" (click)="clearFilter()">
            {{ 'Clear_filters' |translate}}
        </div>
        <div>
            <button class="primary-button" (click)="apply()" >{{'btn_apply' | translate}}</button>
        </div>
    </div>
</div>
