import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import * as _ from 'lodash'
import { ToastServiceService } from '../services/toast-service.service';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnChanges {
  @Input() reportEvents: any;
  @Input() patientMetaData: any;
  @Input() hasGraph: boolean;
  @Input() query: boolean;

  public eventHistory={}

  constructor(private user: UserService, private toast: ToastServiceService) {
    
  }

  ngOnChanges(changes:SimpleChanges): void {
    if(changes['reportEvents'])
    this.getReportHistory(this.reportEvents[0], true);
  }

  getReportHistory(event, isOpen = false) {
    if (!event || !event.Result_num) return;
    if (!this.eventHistory[event['Code']]) {
      this.eventHistory[event['Code']] = {
        fetching: false,
        history: null,
        open: false
      }
    }
    if (!this.eventHistory[event['Code']].open) {
      if (this.eventHistory[event['Code']] && this.eventHistory[event['Code']]['history']) {
        this.eventHistory[event['Code']].open = true;
      } else {
        this.eventHistory[event['Code']].fetching = true;
        this.openHistory(event)
      }
    } else {
      this.eventHistory[event['Code']].open = isOpen
    }
    
  }

  public openHistory(event) {
    this.user.getPatientReportHistory(this.patientMetaData['patientId'] ? this.patientMetaData['patientId'] : this.patientMetaData['id'], event['Code']).subscribe(history => {
      let data = this.parseEventsStructure(history, event);
      this.eventHistory[event['Code']].history = data
      this.eventHistory[event['Code']].fetching = false;
      this.eventHistory[event['Code']].open = true;
      console.log('event', this.eventHistory);
    });
  }

  private parseEventsStructure(historyData, sourceEvent) {
    if (!historyData || historyData.length == 0) return null;
    let pastHistory = [];
    let currentHistory = {};
    historyData = _.orderBy(historyData, [(obj) => moment(obj.reportDateTime)], 'desc');
    currentHistory = {
      code: historyData[0].eventGroup,
      Test: historyData[0].eventDesc,
      Result_num: historyData[0].resultNum,
      'Normalcy Ind': historyData[0].normalcyInd,
      Range: historyData[0].range,
      Uom: historyData[0].uom,
      sourceEvent: sourceEvent
    }
    historyData.forEach((history, index) => {
      if (index === 0)
        return;
      let val = {
        test: history.eventDesc,
        'Event Date': moment(history.reportDateTime).format('YYYY-MM-DD hh:mm'),
        Result_num: history.resultNum,
        'Normalcy Ind': history.normalcyInd,
      }
      pastHistory.push(val);
    });
    const curVal = {
      test: historyData[0].eventDesc,
      'Event Date': moment(historyData[0].reportDateTime).format('YYYY-MM-DD hh:mm'),
      Result_num: historyData[0].resultNum,
      'Normalcy Ind': historyData[0].normalcyInd,
    }
    pastHistory.push(curVal);
    currentHistory['Event History'] = _.orderBy(pastHistory, 'Event Date');
    if (pastHistory.length === 0) {
      this.toast.showToast(true, 'Event history not found', 'info');
      return null;
    }
    return currentHistory;
  }

}

