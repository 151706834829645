<!-- <div class="jw-modal">
  <div class="jw-modal-body" *ngIf="!isClosed" >
    <ng-content></ng-content>
  </div>
</div>
<div class="jw-modal-background"></div> -->

<div class="right-nav showModal" [ngClass]="{'common-view' :isCommonView}">
  <div class="empty" (click)="close()" *ngIf="isModalLeftAlign"></div>
    <div class="component jw-modal-body" [ngClass]="{'left-view' :isModalLeftAlign}">
      <ng-content></ng-content>
    </div>
  </div>