import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-display-currency',
  templateUrl: './display-currency.component.html',
  styleUrls: ['./display-currency.component.scss']
})
export class DisplayCurrencyComponent {
  @Input() prefix: string;;
  @Input() amount: string;
  @Input() actualAmount: string;
  @Input() isOff: boolean = false;
  @Input() suffix: string = null;
  @Input() amountSize: 'small' | 'medium' | 'large' | 'xxsmall' | 'xsmall' | 'xlarge' = 'small';
  @Input() actAmountSize: 'small' | 'medium' | 'large' | 'xxsmall' | 'xsmall' | 'xlarge' = 'small';
  @Input() theme: 'app' | string = 'app';
  @Input() displayDirection: 'row' | 'column' | 'inline' = 'column'

  public currency: string;
  constructor(private user: UserService) {
    this.currency = this.user.getCurrency();
  }

  ngOnInit() { }
}
