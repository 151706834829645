import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import { visitType, apptFlowType } from '@type';
import { TranslatePipe } from '../pipes/translate.pipe';
import * as _ from 'lodash';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { ToastServiceService } from '../services/toast-service.service';
import { TOAST_ERROR } from '@string';
@Component({
  selector: 'app-choose-slot',
  templateUrl: './choose-slot.component.html',
  styleUrls: ['./choose-slot.component.scss'],
  providers: [TranslatePipe]
})
export class ChooseSlotComponent implements OnInit {
  @Input() _input: any;
  @Input() isStandalone: boolean = false;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Input() visitType: visitType;
  @Input() flowType: apptFlowType;
  @ViewChild('vaadinDp') dateRange: ElementRef;
  public slotList: any;
  public availDateList: any;
  public availDays: any;
  public facilityId: string;
  public specialityId: string;
  public practitionerId: string;
  public searchkey: string;
  public translateType = 'LOCALE';
  public startIndex: number;
  public endIndex: number;
  public _startIndex: number;
  public _endIndex: number;
  public selectedClinicIndex: number;
  public selectedClinic: string;
  public curDate: string;
  public selectedFacility: any;
  public availDaysBrief;
  public availableDays = [];
  public hideFacilityList: boolean;
  public directoffset: number = 0;
  private toRestrictDays: Array<any> = [];
  private allowOnlyDays: Array<any> = null;
  public linkWithoutRelation: boolean = false;
  public selectedDate: any = '';
  public selectedSlot: any = {};
  public selectedSlots: any = [];
  public consultAgainVideoAppt: boolean = false;
  public dir: any;
  public noslot:any ;
  public facilityContactno:any;
  constructor(private user: UserService, private translatePipe: TranslatePipe, private featureService: FeatureFlagsService, private toast: ToastServiceService) {
    this.dir = this.user.getAppDirection()
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.slotList = {
      dates: [],
      clinics: [],
      slots:{}
    };
    this.availDateList = [];
    this.searchkey = '';
    this.availDays = [];
    this.startIndex = 0;
    this._startIndex = 0;
    this.endIndex = 3;
    this._endIndex = 6;
    this.curDate = this.user.getMoment().format('YYYY-MM-DD HH:mm');
    // this.hideFacilityList = this.user.hideFacilityCategory();
    this.user.getConfigurationInfo.subscribe(data => {
      if (data.pxAppSettings) {
        if (data.pxAppSettings.hideFacilityCategoryYn == 'Y') {
          this.hideFacilityList = true;
        }
        else {
          this.hideFacilityList = false;
        }
      }
    });
    this.linkWithoutRelation = this.featureService.featureOn('FT_APP_LINKWITHOUTRELATION');
  }

  ngOnInit() {
    console.log("_input", this._input)
    this.consultAgainVideoAppt = this._input.in['consultAgain'];
    if (this.visitType == 'PACKAGE') {
      this.facilityId = this._input.in['facility']['id'];
    }
    else if (this.visitType == 'HOSPITAL'&&this.flowType!='RESCHEDULE') {
      this.facilityId = this._input.in['facility']['id'];
    } else {
      this.facilityId = this._input.in['exist'] && this._input.in['exist']['slot'] ? this._input.in['exist']['slot']['facilityId'] : this._input.in['practitioner']['facList'][0]['facilityId'];
      if (this._input.in['practitioner']['facList'] && Array.isArray(this._input.in['practitioner']['facList'])) {
        this.selectedFacility = this._input.in['practitioner']['facList'].find(fac => {
          return fac.facilityId == this.facilityId;
        });
      } else {
        this.selectedFacility = this.user.findFacility(this.facilityId);
      }
    }
    this.specialityId = this._input.in['speciality']['id'];
    this.practitionerId = this._input.in['practitioner']['id'];
    if (this._input.in.bookingRestrictions && this._input.in.bookingRestrictions.allowedFacilities) {
      let allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == this.facilityId);
      if (!allowedFacility) {
        allowedFacility = _.cloneDeep(this._input.in.bookingRestrictions.allowedFacilities).find(facility => facility.facilityId == '*')
      }
      if (allowedFacility) {
        if (allowedFacility.restrictedDates && allowedFacility.restrictedDates != '*' && Array.isArray(allowedFacility.restrictedDates) && allowedFacility.restrictedDates.length > 0) {
          this.toRestrictDays = allowedFacility.restrictedDates
        }
        if (allowedFacility.allowedDates && allowedFacility.allowedDates != '*' && Array.isArray(allowedFacility.allowedDates) && allowedFacility.allowedDates.length > 0) {
          this.allowOnlyDays = allowedFacility.allowedDates
        }
      }
    }
    this.availDays = Object.keys(this._input.in['practitioner']['availDays']).filter(d => {
      return (this._input.in['practitioner']['availDays'][d] === 'true' || this._input.in['practitioner']['availDays'][d] === true);
    });
  
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    
    this.availDaysBrief = this.availDays
    this.availDaysBrief.map(data => {
      this.availableDays.push(this.translatePipe.transform(capitalize(data) ))
    })
    
    this.selectedClinicIndex = 0;
    this.initAvailDates(this.user.getMoment().format('YYYY-MM-DD'));
    if (this._input.in['facility'] && this._input.in['facility']['id']) {
      let facDetail = this.user.findFacility(this._input.in['facility']['id'])
      if (facDetail && facDetail.directory && facDetail.directory[0]) {
        this.facilityContactno = facDetail.directory[0].number;
      }
    }
    
  }

  private initAvailDates(date) {
    if (!date || date == '') return;
    if (Object.values(this.availDays).every(day => !day)) {
      this.toast.showToast(null, 'No_dates_are_configured_for_selected_practitioner.', TOAST_ERROR, true);
      return;
    }
    if (this.allowOnlyDays) {
      this.availDateList = this.allowOnlyDays;
    } else {
      let initDate = moment(date, 'YYYY-MM-DD');
      this.availDateList = [];
      while (this.availDateList.length !== 10) {
        if (this.availDays.indexOf(initDate.format('ddd').toLowerCase()) !== -1) {
          if (!this.toRestrictDays || !this.toRestrictDays.includes(initDate.clone().format('YYYY-MM-DD')))
            this.availDateList.push(initDate.format('YYYY-MM-DD'));
        }
        initDate = moment(initDate).add(1, 'day');
      }
    }
    if(this.visitType == 'PACKAGE'){
      this.getSlotsForSingleFacility(this.availDateList,  [{ facilityId: this.facilityId }]);
    }
    else if (this.visitType == 'VIDEO' && this.hideFacilityList && this.flowType!='RESCHEDULE' && !this.consultAgainVideoAppt) {
      this.getSlotList(this.availDateList, this._input.in['practitioner']['facList']);
    } else {
      this.getSlotList(this.availDateList, [{ facilityId: this.facilityId }]);
    }
    // this._input.in['practitioner']['facList']
  }
  private getSlotList(dates: Array<string>, facilityList) {
    let promiseObj = facilityList.map(facility => {
      return new Promise((resolve, reject) => {
        this.user.getSlotList(this.practitionerId, facility.facilityId, dates, this.visitType).subscribe(data => { 
          let resolveObj = { facility: facility.facilityId, slots: data.slots };
          resolve(resolveObj);
        }, _ => {
            let resolveObj = { facility: facility.facilityId, slots: {} };
            resolve(resolveObj)
        })
      })
    })
    Promise.all(promiseObj).then(slotList => {
      // console.log(slotList);
      this.setSlotList(dates, slotList);
    })
  }

  private getSlotsForSingleFacility(dates, facilityList){
    let promiseObj = facilityList.map(facility => {
      return new Promise((resolve, reject) => {
        this.user.getSlotList(this.practitionerId, facility.facilityId, dates, this.visitType, this.specialityId).subscribe(data => { 
          let resolveObj = { facility: facility.facilityId, slots: data.slots };
          resolve(resolveObj);
        }, _ => {
            let resolveObj = { facility: facility.facilityId, slots: {} };
            resolve(resolveObj)
        })
      })
    })
    Promise.all(promiseObj).then(slotList => {
      this.setSlotList(dates, slotList);
    })
  }

  private setSlotList(dates, slotList) {

    let combinedList = slotList.reduce((comb, obj) => {
      Object.entries(obj['slots']).forEach(([dateKey, dateValue]) => {
        if (!comb[dateKey]) {
          comb[dateKey] = {}
        }
        Object.entries(dateValue).forEach(([clinicKey, clinicValue]) => {
          if (!comb[dateKey][clinicKey]) {
            comb[dateKey][clinicKey] = {};
          }
          comb[dateKey][clinicKey][obj['facility']] = clinicValue
        })
      })
      return comb;
    }, {})
    let clinicObj = {};
    let clinicList = [];
    let dateList = [];
    dates.forEach(d => {
      clinicObj = {
        ...clinicObj,
        [d]: {}
      }
    })
    this.startIndex = 0;
    this._startIndex = 0;
    this.endIndex = 3;
    this._endIndex = 6;
    Object.entries(combinedList).map(slot => {
      let date = slot[0];
      if (dateList.indexOf(date) == -1) {
        dateList.push(date);
      }
      let clinics = slot[1];
      Object.entries(clinics).map((clinic: any) => {
        let c = clinic[0];
        clinicList.push(c);
        Object.entries(clinic[1]).forEach(([facilityId, times]: any) => {
          let s = times.split(',');
          let noonstarttime = date + ' 13:00';
          let evestarttime = date + '16:30';
          let mrng = [];
          let noon = [];
          let eve = [];
          let facilityTimezone = this.user.getFacilityTimezone(facilityId);
          const directoffset = this.user.getDirectOffset(facilityId);
          let curDate = moment().tz(facilityTimezone).format('YYYY-MM-DD HH:mm');
          s.forEach(x => {
            let checkTime = date + ' ' + x;
            let checkTimeMoment = moment(checkTime, 'YYYY-MM-DD HH:mm').tz(facilityTimezone, true)
            if (date == moment().tz(facilityTimezone).format("YYYY-MM-DD")) {
              let momentVar = moment().tz(facilityTimezone).add(directoffset, 'hours');
              if (checkTimeMoment.isSameOrAfter(momentVar)) {
                // momentVar = this.user.getMoment().add(this.directoffset,'hours').format("HH:mm");
                let noondifftime = checkTimeMoment.diff(this.user.getMoment(noonstarttime, 'YYYY-MM-DD HH:mm'));
                let evedifftime = checkTimeMoment.diff(this.user.getMoment(evestarttime, 'YYYY-MM-DD HH:mm'));
                if (noondifftime < 0) {
                  mrng.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/morning.svg' });
                } else if (evedifftime < 0) {
                  noon.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/noon_.svg' });
                } else {
                  eve.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/evening.svg' })
                }
              }
            }
            else if (checkTimeMoment.isSameOrAfter(this.user.getMoment(this.curDate, 'YYYY-MM-DD HH:mm'))) {
              let noondifftime = checkTimeMoment.diff(this.user.getMoment(noonstarttime, 'YYYY-MM-DD HH:mm'));
              let evedifftime = checkTimeMoment.diff(this.user.getMoment(evestarttime, 'YYYY-MM-DD HH:mm'));
              if (noondifftime < 0) {
                mrng.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/morning.svg' });
              } else if (evedifftime < 0) {
                noon.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/noon_.svg' });
              } else {
                eve.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/evening.svg' })
              }
            }
          });
          if (clinicObj[date]) {
            mrng = mrng.sort(this.sortTime);
            noon = noon.sort(this.sortTime);
            eve = eve.sort(this.sortTime);
            clinicObj[date] = { mrng: mrng, noon: noon, eve: eve };
          }
        })
      })
    });
    this.changeActiveClinic(clinicList[0]);
    this.slotList = {
      dates: dates,
      clinics: clinicList,
      slots: clinicObj
    }
    console.log(this.slotList);
    this.selectedDate = this.slotList.dates[0];
    this.slotList.dates.forEach(element => {
      if ((_.isEmpty(this.slotList['slots'][element])) || (this.slotList['slots'][element]['mrng'].length == 0 &&
        this.slotList['slots'][element]['noon'].length == 0 && this.slotList['slots'][element]['eve'].length == 0)) {
        this.noslot = true;
        this.slotList['slots'][element] = {};
      }
    });
    this.selectedSlots = [
      ...this.slotList['slots'][this.selectedDate]['mrng'],
      ...this.slotList['slots'][this.selectedDate]['noon'],
      ...this.slotList['slots'][this.selectedDate]['eve']
    ];
    // this.selectedSlot = this.selectedSlots[0];
  }
  public changeStart(count) {
    if ((this.startIndex <= 0 && count < 0) || (this.endIndex >= this.slotList.dates.length && count > 0)) {
      return;
    } else {
      this.startIndex += count;
      this.endIndex += count;
    }
  }

  public _changeStart(count) {
    if ((this._startIndex <= 0 && count < 0) || (this._endIndex >= this.slotList.dates.length && count > 0)) {
      return;
    } else {
      this._startIndex += count;
      this._endIndex += count;
    }
  }
  public changeActiveClinic(clinicCode) {
    this.selectedClinic = clinicCode;
  }
  public completeSelection(date: any, slot: any) {
    this.selectedDate = date;
    this.selectedSlot = slot
    let obj = {
      name: this.user.getMoment(date).format('DD MMM YYYY,') + this.user.getMoment(slot['time'], 'HH:mm').format('hh:mm a'),
      date: date,
      slot: slot['time'],
      clinicCode: slot['clinic']
    }
    let selectedFacility= this.user.findFacility(slot['facility']);
    let fobj = {
      id: selectedFacility['facilityId'],
      name: selectedFacility['facilityName'],
      address: selectedFacility['facilityAddress']
    }
    this.complete.emit({ slot: obj, facility: fobj });
    // }
  }
  public openPicker() {
    this.dateRange.nativeElement.click();
  }
  public vaadinChange(event) {
    if (!event || !event['target'] || !event['target']['__data'] || !event['target']['__data'].value) return;
    this.initAvailDates(event['target']['__data'].value);
  }
  public changeActiveFacility(facility) {
    this.selectedFacility = facility;
    this.slotList = {
      dates: [],
      clinics: [],
      slots: {}
    };
    this.facilityId = facility['facilityId'];
    this.initAvailDates(this.user.getMoment().format('YYYY-MM-DD'));
  }
  private sortTime = (a, b) => {
    if (this.user.getMoment(a.time, 'HH:mm').isAfter(this.user.getMoment(b.time, 'HH:mm'))) {
      return 1;
    } else return -1
  }

  public onDateSelect(date) {
    this.selectedDate = date;
    if(!(_.isEmpty(this.slotList['slots'][this.selectedDate]))) {
    this.selectedSlots = [
      ...this.slotList['slots'][this.selectedDate]['mrng'],
      ...this.slotList['slots'][this.selectedDate]['noon'],
      ...this.slotList['slots'][this.selectedDate]['eve']
    ];
  } else {
    this.selectedSlots = [];
  }
    const selectedIndex = this.availDateList.indexOf(this.selectedDate);
    let diff = 0;
    diff = this._endIndex - selectedIndex;
    if (diff < 2 &&
      (this._endIndex < this.availDateList.length) &&
      (this._startIndex < this.availDateList.length - 6)
    ) {
      this._startIndex++;
      this._endIndex++;
    } else if (diff > 2 &&
      (this._endIndex > 6) &&
      (this._startIndex > 0)
    ) {
      this._startIndex--;
      this._endIndex--;
    }
  }
}
