import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpcomingApptPreviewComponent } from './upcoming-appt-preview.component'; 
import {PipesModule} from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';

@NgModule({
  declarations: [UpcomingApptPreviewComponent],
  imports: [
    CommonModule,PipesModule, PersonAvatarModule, 
    PractitionerAvatarModule,
    FormsModule, ReactiveFormsModule, UpcomingAppointmentDetailModule, CustomModalModule
  ],
  exports:[UpcomingApptPreviewComponent]
})
export class UpcomingApptPreviewModule { }