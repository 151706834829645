<div id="appt-confirm" *ngIf="!isFlip">
  <div class="new-card-review">
    <div class="col">
      <app-appt-dtl-card [apptInfo]="_input" [type]="'DETAILS'" [isEditApplicable]="true" [refControl]="referralControl" [isReferralApplicable]="enableReferralCode" (complete)="setReferralCode($event)"  (editAction)="switchActions($event)" [fromSlotBlockPrompt]="fromSlotBlockPrompt" [flowType]="flowType" [showpolicyInfo]="(flowType=='RESCHEDULE' && policyInfo && !showPolicyExpirePrompt) ? true : false" (polciyExpireAction)="openPolicyExpirePrompt(policyInfo)"></app-appt-dtl-card>
    </div>
    <div class="col payment-detail-col">
      <div *ngIf="isEnablePoints && pointsType == 'PAYMENT' && chargeDetails && ((canPayToPract && canPay) || chargeDetails.isPointsApplied)">
        <app-altpayment-input [input]="'POINTS'" [totalAmount]="chargeDetails.totalAmount"
          [netPayable]="chargeDetails._payableWithoutPoints"
          [promoCodeDetails]="chargeDetails['promoCodeDetails']" (pointsApplied)="pointsApplied($event)" #points
          (applyingPoints)="applyingPoints($event)"></app-altpayment-input>
      </div>
      <div *ngIf="state == 'COMPLETE' && (isEnablePromo || (isEnablePoints && pointsType == 'DISCOUNT'))"
        id="finddocpromodiv">
        <ng-container
          *ngIf="(canPay && canPayToPract && chargeDetails && chargeDetails.discountDetails && chargeDetails.discountDetails.applicable && !chargeDetails.isFreeFollowUp) || (chargeDetails.isPromoApplied || (pointsType == 'DISCOUNT' && chargeDetails.isPointsApplied))">
          <app-promo-input [patientPayable]="chargeDetails.totalAmount" [selections]="_input.in"
            [discountDetails]="chargeDetails.discountDetails" (applyingDiscount)="applyingPromoCode($event)"
            (appliedDiscount)="appliedPromocode($event)" [isEnablePoints]="isEnablePoints && pointsType == 'DISCOUNT'"
            [isEnablePromo]="isEnablePromo" [parsedChargeInfo]="chargeVatDetails ? chargeVatDetails: null">
          </app-promo-input>
        </ng-container>
      </div>
      <div *ngIf="isEnableCoupon && chargeDetails && ((canPayToPract && canPay) || chargeDetails.isCouponApplied)">
        <app-altpayment-input [input]="'COUPON'" [totalAmount]="chargeDetails.totalAmount"
          [netPayable]="chargeDetails._payableWithoutCoupon"
          [promoCodeDetails]="chargeDetails['promoCodeDetails']" (couponApplied)="couponApplied($event)" #coupon
          (applyingCoupon)="applyingCouponCode($event)"></app-altpayment-input>
      </div>
      <ng-container *ngIf="!fetchingCharge">
        <p class="font-default-small bold side-hdr">{{'onspot_req_lbl_summary_of_charges'|translate:'LABELS'}}</p>
        <div class="payment-details">
          <div class="card">
            <div class="card-content" *ngIf="flowType == 'BOOKING' || (flowType == 'RESCHEDULE' && prevPayment && prevPayment['status']!='PAID')">
              <div class="payment-group">
                <div class="payment-content border-bottom" *ngIf="!chargeVatDetails">
                  <div class="subheader-font">{{'onspot_req_lbl_consult_charges'|translate:'LABELS'}}
                  </div>
                  <div class="prim-ttl-txt bold">
                    <app-display-currency [amount]="chargeDetails.payableAmount"></app-display-currency>
                  </div>
                </div>
                <div class="payment-content border-bottom" *ngIf="chargeVatDetails && chargeVatDetails.baseServiceAmount">
                  <div class="subheader-font">{{'onspot_req_lbl_consult_charges'|translate:'LABELS'}}
                  </div>
                  <div class="prim-ttl-txt bold">
                    <app-display-currency [amount]="chargeVatDetails.baseServiceAmount"></app-display-currency>
                  </div>
                </div>
                <div class="payment-content border-bottom">
                  <div class="subheader-font">{{'appt_dtl_pmt_details_category'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle">
                    {{(billingGroup ? billingGroup : 'CASH') | translate}}
                  </div>
                </div>
                <div class="payment-content border-bottom" *ngIf="chargeDetails.isPromoApplied && chargeDetails.promoDiscount > 0">
                  <div class="subheader-font">{{'lbl_Promocode'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle">
                    <app-display-currency [amount]="chargeDetails.promoDiscount"></app-display-currency>
                  </div>
                </div>
                <div class="payment-content border-bottom" *ngIf="chargeDetails.isCouponApplied && chargeDetails.couponDiscount > 0">
                  <div class="subheader-font">{{'lbl_coupon'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle">
                    <app-display-currency [amount]="chargeDetails.couponDiscount"></app-display-currency>
                  </div>
                </div>
                <div class="payment-content border-bottom" *ngIf="chargeDetails.isPointsApplied && chargeDetails.pointsDiscount > 0">
                  <div class="subheader-font">{{'lbl_points'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle">
                    <app-display-currency [amount]="chargeDetails.pointsDiscount" [prefix]="'-'"></app-display-currency>
                  </div>
                </div>

                <div class="payment-content border-bottom" *ngIf="chargeVatDetails && chargeVatDetails.vatDisplayAmount">
                  <div class="subheader-font">{{'appt_req_lbl_vat_amount'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle">
                    <app-display-currency [amount]="chargeVatDetails.vatDisplayAmount"></app-display-currency>
                  </div>
                </div>

                <div class="payment-content">
                  <div class="subheader-font bold">{{'onspot_req_lbl_total_to_be_paid'|translate:'LABELS'}}
                  </div>
                  <div class="def-subtitle bold">
                    <app-display-currency [amount]="chargeDetails.netPayable"></app-display-currency>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-content"
              *ngIf="(flowType == 'RESCHEDULE' && prevPayment && prevPayment['status']=='PAID')">
              <div class="payment-group">
                <div class="payment-content border-bottom">
                  <div class="subheader-font">{{'prev_amt_paid'|translate:'LABELS'}}
                  </div>
                  <div class="prim-ttl-txt bold">
                    <app-display-currency [amount]="prevPaymentCost"></app-display-currency>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-methods payment-details" *ngIf="payType=='X'">
          <div class="card">
            <div class="card-head def-subtitle border-bottom">
              <span class="font-default-small bold">{{'policy_details'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content">
              <div class="payment-group">
                <div class="payment-content border-bottom">
                  <div class="font-default-small">{{'appt_confirm_lbl_policy_name'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">{{patientInsurance['policyTypeDesc']}}</div>
                </div>
                <div class="payment-content border-bottom">
                  <div class="font-default-small">{{'appt_confirm_lbl_policy_no'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">{{patientInsurance['policyno']}}</div>
                </div>
                <div class="payment-content ">
                  <div class="font-default-small">{{'appt_confirm_lbl_expire_date'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">
                    {{patientInsurance['policyExpireDate']|moment:'DD MMM YYYY':'DD/MM/YYYY'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-methods payment-details" *ngIf="actualInsuranceDetails">
          <div class="card">
            <div class="card-head def-subtitle border-bottom">
              <span class="font-default-small bold">{{'policy_details'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content">
              <div class="payment-group">
                <div class="payment-content border-bottom">
                  <div class="font-default-small">{{'appt_confirm_lbl_policy_name'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">{{actualInsuranceDetails['policyTypeDesc']}}</div>
                </div>
                <div class="payment-content border-bottom">
                  <div class="font-default-small">{{'appt_confirm_lbl_policy_no'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">{{actualInsuranceDetails['policyno']}}</div>
                </div>
                <div class="payment-content">
                  <div class="font-default-small">{{'appt_confirm_lbl_expire_date'|translate:'LABELS'}}</div>
                  <div class="font-default-small bold">
                    {{actualInsuranceDetails['policyExpireDate']|moment:'DD MMM YYYY':'DD/MM/YYYY'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="followup-text prim-ttl-txt" *ngIf="freeFollowUpYn">
          <img class="type-image" src="assets/icons/info.svg" />
          {{'you_are_eligible_for_a_followup_day'|translate}}
        </div>
        <div class="policy-validation-card-container" *ngIf="policyValidation">
          <div class="policy-validation-card card">
            <div class="card-head def-subtitle bold border-bottom">
              {{'lbl_head_policy_details' | translate}}
            </div>
            <div class="card-content">
              <div class="success-failure-img">
                <img src="./assets/icons/insurance_icons/success.svg" *ngIf="!policyValidation.promptCashAppt">
                <img src="./assets/icons/insurance_icons/alert.svg" *ngIf="policyValidation.promptCashAppt">
              </div>
              <div class="validation-text">
                <div class="policy-name bold">{{policyValidation.promptLabels.title | translate}}</div>
                <div class="policy-status font-light-small" *ngIf="!policyValidation.promptCashAppt">
                  {{'lbl_insurance_applied_success' | translate}}</div>
                <div class="policy-status font-light-small" *ngIf="policyValidation.promptCashAppt">
                  {{'lbl_insurance_applied_failure' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="fetchingCharge">
        <div class="page-loader-container">
          <div class="pageloader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-container" *ngIf="state == 'COMPLETE' && !fetchingCharge">
      <ng-container *ngIf="flowType=='BOOKING'">
        <div class="action-type" *ngIf="(visitType == 'VIDEO' && payType=='X') || (enableCallCenter)">
          <span class="font-light-small">{{'for_insurance_assistance'|translate}}</span>
          <button class="success-button" (click)="call()" [disabled]="!accept">
            <img class="type-image" src="assets/icons/call.svg" />
            {{'call_us'|translate}}
          </button>
        </div>
        <div class="action-type" *ngIf="canPayToPract && canPay">
          <button class="primary-button"  (click)="initBookAppointment('PAY')" [disabled]="!accept">
            <strong>{{ 'appt_but_pay' | translate }}</strong> &nbsp; <app-display-currency [amount]="chargeDetails.netPayable"></app-display-currency>
          </button>
        </div>
        <div class="action-type" *ngIf="canPayToPract && canPay && canPayOptional">
          <button class="pay-later-btn inverted" (click)="initBookAppointment('PAYLATER')" [disabled]="!accept || policyInfo">
            {{'pay_later'|translate}}
          </button>
        </div>
        <div class="action-type" *ngIf="!canPayToPract || !canPay">
          <button class="primary-button" (click)="initBookAppointment('BOOK')" [disabled]="!accept">
            <ng-container *ngIf="payType=='C'">
              {{freeFollowUpYn?('btn_followup_confirm'|translate):('btn_confirm_appt'|translate)}}
            </ng-container>
            <ng-container *ngIf="payType=='X'">
              {{'btn_insurance_proceed'|translate}}
            </ng-container>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="flowType=='RESCHEDULE'">
        <div class="action-type">
          <button class="primary-button" (click)="initRescheduleAppointment()" [disabled]="!accept || policyInfo">
            {{'btn_confirm'|translate}}
          </button>
        </div>
      </ng-container>
    </div>
    <div class="btn-container" *ngIf="state == 'COMPLETERESCHEDULEERROR'">
      <div class="action-type">
        <button class="cancel-button" (click)="gotoMainRoute()">
          {{'APPT#INSERROR#POLICY#NOTAPPLICABLE#BUTTON#CANCELAPPT'|translate:'LABELS'}}
        </button>
      </div>
      <div class="action-type">
        <button class="primary-button" (click)="goBackToSlotView()">
          {{'APPT#INSERROR#POLICY#NOTAPPLICABLE#BUTTON#EARLIERDATE'|translate:'LABELS'}}
        </button>
      </div>
    </div>
  </div>
</div>

<div id="policy-expire-prompt" *ngIf="showPolicyExpirePrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span
          class="font-primary-subtitle bold">{{'APPT#INSERROR#POLICY#NOTAPPLICABLE#HEADER'|translate:'LABELS'}}</span>
        <div class="close float-right closeicon pointer" (click)="goBackPolicyPrompt()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <div class="card-image block-slot-img">
          <img src="./assets/icons/error-info.svg">
        </div>
        <div class="ref-subtitle font-light-small ins-subtitle">
          {{'APPT#INSERROR#POLICY#NOTAPPLICABLE#MESSAGE'|translate:'LABELS'}}
        </div>
        <div class="policy-expire-insurance-details" *ngIf="policyInfo">
          <div class="ins-name">{{policyInfo.payerGroupDesc}}</div>
          <div class="custom-row">
            <span class="text-grey">{{"frm_policy_no" | translate}}</span>
            <span class="text-primary">{{policyInfo.policyno}}</span>
          </div>
          <div class="custom-row">
            <span class="text-grey">{{"frm_ins_summary_expiry_date" | translate}}</span>
            <span class="text-primary warning-css">{{policyInfo.policyExpireDate}}</span>
          </div>
          <div class="policy-edit-con" (click)="openInsuranceModal()">
            <img src="./assets/icons/edit-icon.svg" alt="">
          </div>
        </div>
        <div class="policy-expire-button-con">
          <button class="cancel-button" (click)="gotoMainRoute()">
            {{'APPT#INSERROR#POLICY#NOTAPPLICABLE#BUTTON#CANCELAPPT'|translate:'LABELS'}}
          </button>
          <button class="primary-button" (click)="goBackToSlotView()">
            {{'APPT#INSERROR#POLICY#NOTAPPLICABLE#BUTTON#EARLIERDATE'|translate:'LABELS'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="block-slot-prompt" *ngIf="showBlockSlotPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-subtitle bold">{{'APPT#SLOTBLOCK#TIME#UNAVIL#HEADER'|translate:'LABELS'}}</span>
        <div class="close float-right closeicon pointer" (click)="goBackSlotBlockPrompt()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <div class="card-image block-slot-img">
          <img src="./assets/icons/error.svg">
        </div>
        <!-- <div class="ref-title bold">
          {{'insurance_not_eligible' | translate}}
        </div> -->
        <div class="ref-subtitle font-light-small ins-subtitle">
          {{'APPT#SLOTBLOCK#TIME#UNAVIL#MESSAGE'|translate:'LABELS'}}</div>
        <button class="primary-button"
          (click)="goBackToSlotView()">{{'APPT#SLOTBLOCK#TIME#UNAVIL#BUTTON'|translate:'LABELS'}}</button>
      </div>
    </div>
  </div>
</div>
<div id="cash-prompt" *ngIf="showCashPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-subtitle bold">{{(promptLabels.header || 'insurance_not_eligible') | translate}}</span>
        <div class="close float-right closeicon pointer" (click)="goBack()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <div class="card-image">
          <img src="./assets/icons/insurance_icons/insurance_no.svg">
        </div>
        <div class="ref-title bold">
          {{promptLabels.title | translate}}
        </div>
        <div class="ref-subtitle font-light-small ins-subtitle">{{promptLabels.subtitle | translate}}</div>
        <!-- <div class="ref-alert" *ngIf="promptLabels.alert">
          <img src="./assets/icons/insurance_icons/info.svg">
          {{promptLabels.alert | translate}}
        </div> -->
        <button class="primary-button" (click)="proceedWithCash()">{{(promptLabels.buttonLabel || 'btn_continue_with_cash') | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div id="ins-prompt" *ngIf="showInsPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span class="font-primary-title bold">{{promptLabels.title | translate}}</span>
        <div class="close float-right closeicon pointer" (click)="goBack()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <div class="card-image">
          <img src="./assets/icons/insurance_icons/insurance_yes.svg">
        </div>
        <div class="ref-title bold">
          {{'insurance_eligible' | translate}}
        </div>
        <div class="ref-subtitle font-light-small ins-subtitle">{{promptLabels.subtitle | translate}}</div>
        <!-- <div class="ref-alert" *ngIf="promptLabels.alert">
          <img src="./assets/icons/insurance_icons/info.svg">
          {{promptLabels.alert | translate}}
        </div> -->
        <!-- <div class="ref-subtitle">{{'lbs_proceed_with_ins' | translate}}</div> -->
        <div class="btn-row">
          <button class="footer-button-small white-font-primary-button border"
            (click)="retrievePaymentWithCash()">{{'btn_proceed_with_cash_yes' | translate}}</button>
          <button class="footer-button-small primary-button continue-btn"
            (click)="proceedWithIns()">{{'btn_proceed_with_ins_yes' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showInsuranceModal">
  <app-manage-insurance (completeCoupon)="closeInsuranceModal()"></app-manage-insurance>
</div>

<div *ngIf="videoConsultationTC">
  <app-video-consult-tc (complete)="continuePay()" (close)="closeVcModal()"></app-video-consult-tc>
</div>