import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { HealthPackageConfirmRequestComponent } from '../health-package-confirm-request/health-package-confirm-request.component';
import { HealthPackageDetailsModule } from '../health-package-details/health-package-details.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { ApptDtlCardModule } from '../appt-dtl-card/appt-dtl-card.module';
@NgModule({
  declarations: [HealthPackageConfirmRequestComponent],
  imports: [
    CommonModule,
    PersonAvatarModule, 
    PipesModule,
    ManageIdentitiesModule,
    HealthPackageDetailsModule,
    PaymentCheckoutModule,
    ApptDtlCardModule
  ],
  exports: [
    HealthPackageConfirmRequestComponent
  ]
})
export class HealthPackageConfirmRequestModule { }
