import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagsService } from '../services/google-tags.service';
import { UserService } from '../services/user.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {
  public isVideoConsultation: boolean;
  public selectedLanguage: string;
  public increaseIndent: boolean;
  constructor(private router:Router, public user:UserService, private gtmService:GoogleTagsService, private featureFlag: FeatureFlagsService) { }

  ngOnInit() {
    this.isVideoConsultation = this.router.url.includes('/crm/videoconsultation')
    this.selectedLanguage = this.user.currentLanguage;
    if(!this.featureFlag.featureOn('SHOW_APP_FOOTER')){
      this.increaseIndent = true;
    }

// this.router.events.forEach(item => {
//   if (item instanceof NavigationEnd) {
//   }
// });
  }
}
