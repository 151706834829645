import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrReportClobformatComponent } from './hr-report-clobformat.component';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfxviewerModule } from './../pdfxviewer/pdfxviewer.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';



@NgModule({
  declarations: [HrReportClobformatComponent],
  imports: [
    CommonModule, PipesModule, DirectiveModule, PdfViewerModule, PdfxviewerModule, NgxQRCodeModule
  ],
  exports: [
    HrReportClobformatComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HrReportClobformatModule { }
