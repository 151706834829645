import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentCheckoutComponent } from './payment-checkout.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PaymentCheckoutComponent],
  imports: [
    CommonModule, PipesModule, FormsModule
  ],
  exports: [
    PaymentCheckoutComponent
  ]
})
export class PaymentCheckoutModule { }
