import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import * as _ from 'lodash';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.css']
})
export class FormulaComponent extends Type implements OnInit, OnChanges, SectionInterface {

  @Input() public section;
  @Input() public sections;
  constructor(private form: ClinicalformService) {
    super(form);

  }

  ngOnInit() {
    this.section.formulaVariables = this.section.formulaVariables.sort(this.sortFormaulaFeilds);
  }

  sortFormaulaFeilds(a, b) {
    return a.length < b.length ? 1 : 0;
  }

  ngOnChanges() {
  }


  getScore() {
    try{
      let score = '0';
    let formula: string = _.cloneDeep(this.section['formula']);
    this.section.formulaVariables.forEach(element => {
      let value = this.sections.filter(item => item['domainCode'] == element).length > 0 ? this.sections.filter(item => item['domainCode'] == element)[0] : null;
      if (value) {
        let selectedValue = this.getValueForCode(value);
        formula = formula.split(element).join(selectedValue ? selectedValue : '0');
      }
    });
    score = this.form.commonEvalMethod(formula);
    console.log(formula,score)
    this.section.selected = score;
    if(this.section.results)
        this.section.selectedInterpretation = this.calculateInterpretationForFormula(this.section,score);
    return score;
    }catch(err){
      console.log('err in calculating formulae',err);
    }
  }

  getValueForCode(s){
    if(s.type=='numericInteger' || s.type=='numericDecimal' || s.type=='numericIntegerRange' || s.type=='numericDecimalRange' || s.type=='dateAndTime' || s.type==='date' || s.type=='time'){
        return s.selected;
    }else if(s.type=='resultLinked'){
        return Array.isArray(s.selected) && s.selected.length>0 && s.selected[0].hasOwnProperty('resultNum')?s.selected[0].resultNum:null;
    }
    else{
      if(s.selected){
        if(s.domainControls.multiSelect){
          let tScore = s.domainControls.answer.reduce((initialValue,a)=>{
            initialValue += s.selected.split(',').includes(a.localName[0].text) ? parseInt(a.score) : 0;
            return initialValue;
          },0);
          return !_.isEmpty(tScore) ? tScore : s.selected;
        }
        const score = s.domainControls.answer.filter( a => a.localName[0].text === s.selected)[0].score;
        return  !_.isEmpty(score) ? score : s.selected;
      }
      return null;
    }
  }

  calculateInterpretationForFormula(t,totalScore){
    console.log('calInterp',t,totalScore);
    totalScore=Number(totalScore);
    var result = "";
    for(let s=0; s<t.results.length; s++){
        if(t.results[s].from!='' && t.results[s].to!=''){
            if(t.results[s].from<=totalScore && totalScore<=t.results[s].to){
                result = t.results[s].name || t.results[s].value;
            }
        }
        if(t.results[s].from!='' && t.results[s].to==''){
            if(t.results[s].from<=totalScore){
                result = t.results[s].name || t.results[s].value;
            }
        }
        if(t.results[s].from=='' && t.results[s].to!=''){
            if(totalScore<=t.results[s].to){
                result = t.results[s].name || t.results[s].value;
            }
        }
    }
    console.log('result for interp',result);
    return result;
  }

}
