import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-hr-report-jsonformat',
  templateUrl: './hr-report-jsonformat.component.html',
  styleUrls: ['./hr-report-jsonformat.component.scss']
})
export class HrReportJsonformatComponent implements OnInit {
  @Input() report: any;
  @Input() reportData: any;
  @Input() selectedPatient: any;
  @Input() isCommonView: boolean;
  @Input() patientMetaData;
  @Input() isInline: boolean;
  @Output() close: EventEmitter<any>;
  @Output() initDownload: EventEmitter<any>;
  @Output() toggleQrCode: EventEmitter<any>;
  @Input() set qrcodeUrl(value) {
    this._qrcodeUrl = value;
  }
  get qrcodeUrl() {
    return this._qrcodeUrl;
  }
  public canDownload: boolean;
  public isPreviewImage: boolean;
  public isPreviewVideo: boolean;
  public isPreviewPdf: boolean;
  public hasGraph: boolean;
  public previewUrl: string;
  public view: 'table'|'graph';
  public _qrcodeUrl: any;
  public eventHistory: any;
  public query: boolean
  @Input() expandQRCode: boolean;
  constructor(private user: UserService) {
    this.close = new EventEmitter<any>();
    this.initDownload = new EventEmitter<any>();
    this.toggleQrCode = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getConfigurationInfo.subscribe(data => {
      this.canDownload = data.features.documents.download;
    });
    this.view = 'table';
    this.hasGraph = (this.reportData.Events.filter(d => { return d['Event History'] ? true : false }).length > 0)
    this.query = this.patientMetaData['queryForHistory'];
  }
  public startDownload() {
    this.initDownload.emit({ report: this.report, data: this.reportData });
  }
  public closeModel() {
    this.close.emit();
  }
  public openPreviewImage(url: string) {
    this.isPreviewImage = true;
    this.previewUrl = url;
  }
  public closePreviewImage() {
    this.isPreviewImage = false;
    this.previewUrl = '';
  }
  public openPreviewVideo(url: string) {
    this.isPreviewVideo = true;
    this.previewUrl = url;
  }
  public closePreviewVideo() {
    this.isPreviewVideo = false;
    this.previewUrl = '';
  }
  public openPreviewPdf(url: string) {
    this.isPreviewPdf = true;
    this.previewUrl = url;
  }
  public closePreviewPdf() {
    this.isPreviewPdf = false;
    this.previewUrl = '';
  }
  public changeView(view: 'table' | 'graph') {
    this.view = view;
  }
  public expandQR() {
    this.toggleQrCode.emit()
  }

  // undefinedFun
  public closeEventHistory() { }
}
