import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-us-details',
  templateUrl: './contact-us-details.component.html',
  styleUrls: ['./contact-us-details.component.scss'],
  providers: [NgbCarouselConfig]
})
export class ContactUsDetailsComponent implements OnInit, OnChanges {
  @Input() selectedHospital: any;
  @Input() currentLang: string;
  @Output() close: EventEmitter<any>;

  public showNavigationArrows = false;
  public showNavigationIndicators = true;
  public hospitalProfile: any = {};
  public mobileNo: string = '';

  constructor(config: NgbCarouselConfig, private user: UserService) {
    this.close = new EventEmitter<any>();
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true;
    config.interval = 3000;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.selectedHospital['directory'].forEach(d=>{
      if(d['type'] === 'EMR')
        this.mobileNo = `${this.user.getPersonCountryCode()} ${d['number']}`;
    })
    this.user.getUpdateInfo('facilityProfiles').subscribe(data => {
      const myFacilityProfile = data['profiles'].find(p => p['facilityId'] === this.selectedHospital['facilityId']);
      const localeProfile = myFacilityProfile?.['profile'].find(p => p.locale === this.currentLang);
      if (_.isEmpty(localeProfile))
        this.hospitalProfile = myFacilityProfile?.['profile'][0];
      else
        this.hospitalProfile = localeProfile;
      console.log(this.hospitalProfile);
    })
  }

  public closeModal() {
    this.close.emit();
  }

  public openMap() {
    window.open(this.selectedHospital['facilityMapsURL'], '_blank');
  }
}
