import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicalformComponent } from './clinicalform.component';
import { FormpreviewModule } from '../formpreview/formpreview.module';
import { SectionModule } from '../section/section.module';
import { PipesModule } from '../pipes/pipes.module';
import { ClinicalFormUtils } from './clinicalform.utils';
import { ClinicalformService } from '../services/clinicalform.service';
import { TopicOutcomesModule } from '../topic-outcomes/topic-outcomes.module';



@NgModule({
  declarations: [ClinicalformComponent],
  imports: [
    CommonModule, FormpreviewModule, SectionModule, PipesModule, TopicOutcomesModule
  ],
  exports: [
    ClinicalformComponent
  ],
  
  providers: [ClinicalFormUtils, ClinicalformService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
})
export class ClinicalformModule { }
