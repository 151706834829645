import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
import * as moment from 'moment';
import {cloneDeep as _cloneDeep, uniq as _uniq} from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './richTextEditor.component.html',
  styleUrls: ['./richTextEditor.component.scss']
})
export class RichTextEditor extends Type implements OnInit, SectionInterface, OnChanges {

  @Input() public section;
  @Input() public sections;
  @Input() public patient;
  @Input() public topics;
  @Input() public appointmentsList;
  public f:any;
  @ViewChild('richTextContent') public richTextContent:any;
  private delimiter: any = ',';
    constructor(public user: UserService, public form: ClinicalformService) {
        super(form);
  }

  ngOnInit() {
      console.log(this.appointmentsList, this.patient);
    if(this.section){
      if(!this.section._selectedTemplateLanguage){
          if(Array.isArray(this.section.template) && this.section.template.length){
              this.section._selectedTemplateLanguage = this.user.currentLanguage || this.section.template[0]['templatelanguage'];
          }
      }
      this.section['templateVaraibleValues']={};
      this.section['templateVaraibleValues']['_SINGLE_PAT_CONTRACT'] = this.patient && this.patient['externalInfo'] && this.patient['externalInfo']['singlePatientContract'] ? 'Y' : 'N';
      this.checkForTemplate();
    }
  }

  ngOnChanges() {

  }

  lanSwitch(template){

    if(this.section._selectedTemplateLanguage === template['templatelanguage']) return;

    this.section._selectedTemplateLanguage = template['templatelanguage'];
    this.checkForTemplate();
  }


  checkForTemplate(){
    if(!this.section._selectedTemplateLanguage) return;
      let template = this.section.template.find(t => t['templatelanguage'] === this.section._selectedTemplateLanguage);
      if (!template) template = this.section.template[0]
    if(!template || !template.templateContent) return;
    this.section.selected = this.parseTemplateContent(template);
    this.displaySelectedValue();
  }
  parseTemplateContent(template){
    if(!template.templateControls || !Array.isArray(template.templateControls.templateVariable) || !template.templateControls.templateVariable.length) return template.templateContent;
    let templateContent = template.templateContent;
    const tempVariables = template.templateControls.templateVariable.filter(this.identifyVariables);
    const tempConditions = template.templateControls.templateVariable.filter(this.identifyConditions);
    tempConditions.forEach(c=>{
        templateContent = this.parseTemplateCondition(templateContent, c, tempVariables);
    });
    tempVariables.forEach(v=>{
        templateContent = this.parseTemplateVariable(templateContent, v);
    });
    console.log(this.section.templateVaraibleValues);
    return templateContent;
}

identifyVariables = t=>t.type!=='CONDITION';
identifyConditions = t=>t.type==='CONDITION';


parseTemplateVariable(templateContent, templateVariable){
    const varName = '$'+templateVariable.id+'$';
    let templateContentSplit = templateContent.split(varName);
    if(Array.isArray(templateContentSplit) && templateContentSplit.length<2) return templateContent;
    const val = this.evaluateTemplateVaraible(templateVariable);
    this.section.templateVaraibleValues[templateVariable.id]=val;
    return templateContentSplit.join(val);
}

parseTemplateCondition(templateContent, templateCondition, tempVariables){
    const conditionName = '#'+templateCondition.id+'#';
    let templateContentSplit = templateContent.split(conditionName);
    if(Array.isArray(templateContentSplit) && templateContentSplit.length<3) return templateContent;
    if(!this.evaluateTemplateCondition(templateCondition, tempVariables)) //if condition fails
        templateContentSplit = templateContentSplit.filter((f,i)=>i% 2 === 0); //remove condition value in the content
    return templateContentSplit.join('');
}

evaluateTemplateVaraible(templateVariable){
    let finalValue;
    switch(templateVariable.type){
        case 'DOMAINCODE':
            finalValue = this.findSystemDomainCodes(templateVariable);
            break;
        case 'ENCOUNTER':
            let vars = templateVariable.code.split('|');
            if(vars.length){
                let value = this.patient;
                vars.forEach(v=>{
                    if(value)
                        value = value[v];
                });
                if(typeof value == 'string')
                    finalValue = value;
            }
            break;
        case 'APPOINTMENT':
            let variables = templateVariable.code.split('|');
            finalValue = [];
            if(Array.isArray(this.appointmentsList) && this.appointmentsList.length){
                this.appointmentsList.forEach(a=>{
                    if(variables.length){
                        let value = a;
                        variables.forEach(v=>{
                            if(v)
                                value = value[v];    
                        });
                        if(typeof value == 'string')
                            finalValue.push(this.formatTemplateVariable(templateVariable, value));
                    }
                })
            }
            break;    
        default :
            break;
    }
    if(templateVariable.type === 'APPOINTMENT')
        return finalValue.join(', ');
    return this.formatTemplateVariable(templateVariable, finalValue);
}

findSystemDomainCodes(templateVariable){
    const { patientInfo } = this.patient;
    switch (templateVariable.code){
        case 'SYS_LOG_IN_PERSON':
            return //this.resource.personName;
        case 'SYS_LOG_IN_PRACT_ID':
            return //this.resource.resourceId;
        case 'SYS_LOG_IN_DATE':
            return this.user.getMoment().format('DD-MMM-YYYY h:mm a');
        case 'APPT_IS_SINGLE':
            return  this.isRecurringAppointment() ? 'NO' :  'YES';
        case 'APPT_IS_RECURRING':
            return this.isRecurringAppointment() ? 'YES' : 'NO';
        case 'APPT_RENEW_YES':
            return this.patient && this.patient['isNewPatient'] ? 'NO' : 'YES'
        case 'APPT_RENEW_NO':
            return this.patient && this.patient['isNewPatient'] ? 'YES' : 'NO'
        case 'APPT_NEW_YES':
            return 'YES'; 
        case 'APPT_NEW_NO':
            return 'NO';
        case 'APPT_CURR_DATE':
            return this.user.getMoment().format('DD/MMM/YYYY');
        case 'APPT_SERVICE_SDATE':
            if(this.hasAppointments()){
                // let apptList = _cloneDeep(this.appointmentsList);
                // apptList.sort((a,b)=> new Date(a['appointmentdatetime']).getTime() - new Date(b['appointmentdatetime']).getTime())
                // return this.user.getMoment(apptList[0]['appointmentdatetime']).format('DD/MMM/YYYY');
                let apptList = _cloneDeep(this.appointmentsList);
                apptList.sort((a,b)=> new Date(a['schedule_from_datetime']).getTime() - new Date(b['schedule_from_datetime']).getTime())
                return this.user.getMoment(apptList[0]['schedule_from_datetime']).format('DD/MMM/YYYY');
            }
            return ;    
        case 'APPT_SERVICE_EDATE':
            if(this.hasAppointments()){
                // let apptList = _cloneDeep(this.appointmentsList);
                // apptList.sort((a,b)=>new Date(b['appointmentdatetime']).getTime() - new Date(a['appointmentdatetime']).getTime())
                let apptList = _cloneDeep(this.appointmentsList);
                apptList.sort((a,b)=>new Date(b['schedule_to_datetime']).getTime() - new Date(a['schedule_to_datetime']).getTime())
                console.log(apptList);
                return this.user.getMoment(apptList[0]['schedule_to_datetime']).format('DD/MMM/YYYY');
            }
            return ;
        case 'APPT_LOCATION':
            if(this.hasAppointments()){
                let apptData = this.appointmentsList[0];
                if(apptData.home_care_services && apptData.home_care_services.location && apptData.home_care_services.location.address){
                    return apptData.home_care_services.location.address;
                }
            }    
            return ;
        case 'APPT_DURATION':
            if(this.hasAppointments()){
                let appt_duration = 0;
                if(this.hasAppointments()) 
                    appt_duration = this.appointmentsList.reduce((total, value) => { total = total +  (value['appt_duration'] ? value['appt_duration'] : 0); return total }, 0);
                else 
                    appt_duration = this.appointmentsList[0]['appt_duration'];
                if(appt_duration){
                    const duration = moment.duration(appt_duration, 'minutes')
                    const hours = Math.floor(duration.asHours());
                    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
                    const mindesc = minutes ? `and ${minutes}mins` : '';
                    return `${hours}Hr ${mindesc}`;
                }
            }  
            return;    
        case 'APPT_SCHEDULED_VISIT':
            if(this.hasAppointments()){
                let isMultiple = this.isRecurringAppointment();
                if(isMultiple){
                    let apptDates = this.appointmentsList.map(a=> this.user.getMoment(a['appointmentdatetime']).format('d'));
                    apptDates.sort((a,b)=>a-b);
                    apptDates = _uniq(apptDates);
                    apptDates = apptDates.map(a=>this.user.getMoment(a,'d').format('ddd'));
                    return apptDates.join(' ');
                }else{
                    let apptData = this.appointmentsList[0];
                    return this.user.getMoment(apptData['appointmentdatetime']).format('dddd');
                } 
            }
            return;
        case 'APPT_CARE_ELE_NAME':
            if(this.hasAppointments()){
                if(this.hasGroupAppointments()) {
                    let groupAppts = this.groupAppointments();
                    return groupAppts.map(appts => {
                        let apptData = appts[0];
                        if(apptData.home_care_services && Array.isArray(apptData.home_care_services.homeCareServices) && apptData.home_care_services.homeCareServices.length){
                            return apptData.home_care_services.homeCareServices.map(s=>s['name']).join(', ');
                        } else return '';
                    }).filter(a=> a).join(this.delimiter);
                }
                let apptData = this.appointmentsList[0];
                if(apptData.home_care_services && Array.isArray(apptData.home_care_services.homeCareServices) && apptData.home_care_services.homeCareServices.length){
                    return apptData.home_care_services.homeCareServices.map(s=>s['name']).join(', ');
                }
            }
            return;  
        case 'APPT_VISITS_PER_WEEK':
            if (this.hasAppointments()) {
                if(this.hasGroupAppointments()) {
                    let groupAppts = this.groupAppointments();
                    return groupAppts.map(appts => {
                        let apptDates = appts.map(a => this.user.getMoment(a['appointmentdatetime']).format('d'));
                        apptDates.sort((a, b) => a - b);
                        apptDates = _uniq(apptDates);
                        return apptDates.length;
                    }).join(this.delimiter)
                }
                let apptDates = this.appointmentsList.map(a => this.user.getMoment(a['appointmentdatetime']).format('d'));
                apptDates.sort((a, b) => a - b);
                apptDates = _uniq(apptDates);
                return apptDates.length;
            }
            return;
        case 'APPT_CARE_SERVI_NAME':
            if(this.hasAppointments()){
                if(this.hasGroupAppointments()) {
                    let groupAppts = this.groupAppointments();
                    return groupAppts.map(appts => {
                        let apptData = appts[0];
                        if(apptData.home_care_services && apptData.home_care_services.serviceGroup){
                            return apptData.home_care_services.serviceGroup['name'];
                        } else return '';
                    }).filter(a=> a).join(this.delimiter);
                }
                let apptData = this.appointmentsList[0];
                if(apptData.home_care_services && apptData.home_care_services.serviceGroup){
                    return apptData.home_care_services.serviceGroup['name'];
                }
            }
            return;  
        case 'APPT_RESOURCE_NAME':
            if(this.hasAppointments()){
                let apptData = this.appointmentsList;
                let resources=[];
                apptData.forEach(a=>{
                    if(a.home_care_services && Array.isArray(a.home_care_services.resourceList)){
                        a.home_care_services.resourceList.forEach(r=>{
                            resources.push(r['name'])
                        })
                    }
                })
                return _uniq(resources).join(', ');
            }
            return;    
        case 'PAT_NATIONALITY' :
                const response = this.user.getSavedCountryList();
                const nationality = response.find(r=> r['countryCode'] == patientInfo['nationality'])
                if(nationality)
                    return nationality['countryDesc'];
                return;
        case 'PAT_ADDRESS' :
            let address = [];
            address.push(patientInfo['addrLine1']);
            if(patientInfo['addrLine2']) address.push(patientInfo['addrLine2']);
            if(patientInfo['addrLine3']) address.push(patientInfo['addrLine3']);
            if(patientInfo['addrLine4']) address.push(patientInfo['addrLine4']);
            if(patientInfo['cityDesc']) address.push(patientInfo['cityDesc']);
            if(patientInfo['stateDesc']) address.push(patientInfo['stateDesc']);
            return address.join(',');
        case 'PAT_NAT_ID':
            const natId = patientInfo['altIds'] && patientInfo['altIds'].length ?  patientInfo['altIds'].find(a=> a['altIdType'] == 'NATID') : null;
            if(natId) return natId['altId']
            return;
        case 'APPT_BOOK_DAY' :
            if(this.hasAppointments()){
                let apptData = this.appointmentsList[0];
                return this.user.getMoment(apptData['bookingdatetime']).format('dddd');
            }
            return;
        case 'CONTRACT_SIGN_DATE' :
            if(this.hasAppointments()){
                let apptData = this.appointmentsList[0];
                return this.user.getMoment(apptData['bookingdatetime']).format('DD MMM YYYY');
            }
            return;
        case 'APPT_TOTAL_AMOUNT' :
            if(this.hasAppointments()) {
                return this.appointmentsList.reduce((total, value) => { total = total +  (value['totalAmount'] ? (value['totalAmount'] - (value['discountAmount'] ? Number(value['discountAmount']) : 0)) : 0); return total }, 0);
            }
            return ;
        case 'APPT_IN_PAY_AMOUNT' :
            if(this.hasAppointments()) {
                return this.appointmentsList.reduce((total, value) => { total = total +  (value['paymentamount'] ? Number(value['paymentamount']) : 0); return total }, 0);
            }
            return ;
        default:
            let domainSection = this.findValueForDomainCode(templateVariable.code);
            return  domainSection ?  domainSection.selected : null;
    }
}

hasAppointments(){
    return Array.isArray(this.appointmentsList) && this.appointmentsList.length;
}

isRecurringAppointment(){
    if(this.hasGroupAppointments()) {
        return this.patient['externalInfo']['apptDtls'].some(a=> a['isSingle'] != 'Y')
    }
   return this.hasAppointments() && this.appointmentsList.length>1
}

groupAppointments() {
    if(this.patient && this.patient['externalInfo'] && this.patient['externalInfo']['singlePatientContract'] && this.patient['externalInfo']['apptDtls'] && this.patient['externalInfo']['apptDtls'].length) {
        let group = []
        this.patient['externalInfo']['apptDtls'].forEach(appt => {
            const filterAppts = this.appointmentsList.filter(a=> appt['apptRefNums'].includes(a['appointmentno']));
            group.push(filterAppts);
        });
        return group;
    }
}

hasGroupAppointments() {
    return this.patient && this.patient['externalInfo'] && this.patient['externalInfo']['singlePatientContract'] && this.patient['externalInfo']['apptDtls'] && this.patient['externalInfo']['apptDtls'].length;
}

formatTemplateVariable(templateVariable,value){
    if(!value || (Array.isArray(value) && !value.length)) return templateVariable.default;
    if(!templateVariable.output) return value;
    switch(templateVariable.datatype){
        case 'string':
            if(Array.isArray(value)){
                let finalValue=[];
                value.forEach(v=> finalValue.push(this.formatStringValue(v, templateVariable.output, templateVariable.input)));
                return finalValue.join(', ');
            }
            return this.formatStringValue(value, templateVariable.output, templateVariable.input);
        case 'date':
            return this.formatDateValue(value, templateVariable.output, templateVariable.input);
        case 'number':
            return this.formatNumberValue(value, templateVariable.output, templateVariable.input);
        default:
            return value;
    }
}

formatStringValue(value, output, input){
    switch(output){
        case 'UPPER':
            return value.toUpperCase();
        case 'LOWER':
            return value.toLowerCase();
        case 'INIT':
            return value[0].toUpperCase() + value.slice(1);
        default:
            return value;
    }
}

formatDateValue(value, output, input?){
    let dateMnt = input ? this.user.getMoment(value,input) : this.user.getMoment(value);
    switch(output){
        case 'AGE_IN_YEARS':
            return this.user.getMoment().diff(dateMnt, 'y')+' Y';
        case 'AGE':
            return this.getAgeDtm(dateMnt.format('YYYY-MM-DD'));
        default:
            return dateMnt.format(output);
    }
}

formatNumberValue(value, output, input){
    //TODO implement function like toFixed,...
    return value;
}

findValueForDomainCode(domainCode){
    this.f = {noteContent:{topics:this.topics}};
    if(this.f.noteContent && Array.isArray(this.f.noteContent.topics) && this.f.noteContent.topics.length){
        this.f.noteContent.topics.find(t=>t['sections'].find(s=>s['domainCode']===domainCode));
        for(let i=0; i< this.f.noteContent.topics.length; i++)
            for(let j=0; j< this.f.noteContent.topics[i].sections.length; j++)
                if(this.f.noteContent.topics[i].sections[j].domainCode === domainCode )
                    return this.f.noteContent.topics[i].sections[j];
    }
    return null;
}


displaySelectedValue(){
    console.log(this.richTextContent);
    setTimeout(_=>{
      if(this.section.selected && this.richTextContent && this.richTextContent.nativeElement)
        this.richTextContent.nativeElement.innerHTML = this.section.selected;
    },500)
    
}


evaluateTemplateCondition(templateCondition, tempVariables){
    if(!templateCondition.condition) return true;
    let templateContent = templateCondition.condition;
    tempVariables.forEach(v=>{
        templateContent = this.parseTemplateVariable(templateContent, v);
    });
    console.log('final condition', templateContent);
    let val;
    try{
        val = this.form.commonEvalMethod(templateContent);
    }catch (err){
        console.log('err in condition');
    }
    return !!val;
}

getAgeDtm(dtm) {
    if (dtm) {
        var temp = this.user.getMoment().diff(this.user.getMoment(dtm, 'YYYY-MM-DD'), 'y');
        if (temp <= 0) {
            temp = this.user.getMoment().diff(this.user.getMoment(dtm, 'YYYY-MM-DD'), 'months');
            if (temp <= 0) {
                temp = this.user.getMoment().diff(this.user.getMoment(dtm, 'YYYY-MM-DD'), 'days');
                return temp + 'D';
            }
            return temp + 'M';
        }
        return temp + 'Y';
    } else
        return '';
}

    public checkTemplateFromChildClass({section, sections, topics, patient, appointmentsList}) {
        this.section = section;
        this.sections = sections;
        this.topics = topics;
        this.patient = patient;
        this.appointmentsList = appointmentsList;
        if(!this.section._selectedTemplateLanguage){
            if(Array.isArray(this.section.template) && this.section.template.length){
                this.section._selectedTemplateLanguage = this.user.currentLanguage || this.section.template[0]['templatelanguage'];
            }
        }
        this.section['templateVaraibleValues']={};
        this.section['templateVaraibleValues']['_SINGLE_PAT_CONTRACT'] = this.patient && this.patient['externalInfo'] && this.patient['externalInfo']['singlePatientContract'] ? 'Y' : 'N';
        this.checkForTemplate();
        console.log(this.section);
    };
}
