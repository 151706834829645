<div id="change-password" class="right-nav showModal" *ngIf="isMangeAccount;else manageAddressForm">
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold my-account">{{'My account' | translate}}</div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_grey.svg">
      </div>
    </div>
    <div class="content">
      <div class="profile-section">
        <div class="options" [ngClass]="{'no-display-options': curAction}">
          <div class="profile-card row cursor-pointer" [ngClass]="{active: 'edit_profile' == curAction}"
            (click)="changeCurAction({action: 'edit_profile',visible: false})">
            <div class="image">
              <img src="./assets/icons/manage-account/photo_round.svg">
            </div>
            <div class="card-content">
              <div class="title">{{personDetails.personName}}
              </div>
              <div class="edit-account">{{'Edit_account' | translate}}
              </div>
            </div>
          </div>
          <div *ngFor="let groups of options" class="options-group">
            <div *ngFor="let item of groups" class="options-item">
              <ng-container *ngIf="!item.hide">
                <div class="card1 row cursor-pointer" [ngClass]="{active: item.action == curAction}"
                  (click)="changeCurAction(item)">
                  <div class="image" [ngClass]="{'red-dot': !isConsent && item.action == 'manage_consent'}">
                    <img [src]="item.icon">
                  </div>
                  <div class="card-content">
                    <div class="title" [ngClass]="{active: item.action == curAction}">
                      {{item.name | translate}}
                    </div>
                  </div>
                  <div class="image arrow">
                    <img *ngIf="item.action !== curAction" class="arrow-icon"
                      src="./assets/icons/manage-account/arrow.svg">
                    <img *ngIf="item.action == curAction" class="arrow-icon"
                      src="./assets/icons/manage-account/arrow_green.svg">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="delete-account-con" *ngIf="false">
            <p class="delete-label">If you wish to remove your nahdi account</p>
            <div class="delete-but-con" (click)="deleteAccount()">
              <img class="type-image" src="assets/icons/profile/sign_out.svg" />
              <p class="delete-but">Delete account</p>
            </div>
          </div>
        </div>
        <div class="components" [ngClass]="{'show-only-components': curAction}">
          <ng-container *ngTemplateOutlet="currentComponents"></ng-container>
        </div>
      </div>
    </div>
    <app-manage-saved-cards *ngIf="showSavedCardsModal" (close)="closeSavedCards()"></app-manage-saved-cards>
  </div>
</div>

<ng-template #currentComponents>
  <div [ngSwitch]="curAction" class="manage-address">
    <div *ngSwitchCase="'change_password'">
      <app-change-password (close)="reset()"></app-change-password>
    </div>
    <div *ngSwitchCase="'switch_lang'">
      <div class="language-selection">
        <div class="lang-title">
          <p class="bold">{{'switch_language'|translate}}</p>
        </div>
        <div class="list">
          <div class="list-item language-list" *ngFor="let item of languageList" (click)="onItemChange(item)">
            <input type="radio" name="language-label" [(ngModel)]="isSelected" [value]="item.localeCode"
              [id]="'lang_23'+item.localeCode">
            <label class="label" [for]="'lang_23'+item.localeCode">{{item.localName}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'manage_consent'">
      <app-manage-my-consent (close)="reset()" [isConsented]="isConsent" (completeConsent)="manageConsent($event)"></app-manage-my-consent>
    </div>
    <div *ngSwitchCase="'edit_profile'">
      <app-edit-profile (close)="reset()"></app-edit-profile>
    </div>
    <div *ngSwitchCase="'manage_address'" class="manage-address">
      <app-manage-address (editOrAddAddress)="openManageAddressForm($event)"></app-manage-address>
    </div>
    <div *ngSwitchDefault>
      <app-view-profile [refresh]="refresh.asObservable()"></app-view-profile>
    </div>
    <div *ngSwitchCase="'change_number'">
      <app-change-mobile-number (close)="reset()"></app-change-mobile-number>
    </div>
    <div *ngSwitchCase="'change_email'">
      <app-change-email (close)="reset()"></app-change-email>
    </div>
    <div *ngSwitchCase="'payment_detail'">
      <app-manage-payment-card (close)="reset($event)" [refresh]="refresh.asObservable()"></app-manage-payment-card>
    </div>
    <div *ngSwitchCase="'about_us'">
      <app-contact-us-modal (close)="reset()" [contactUs]="false">
      </app-contact-us-modal>
    </div>
    <div *ngSwitchCase="'contact_us'">
      <app-contact-us-modal (close)="reset()" [contactUs]="true">
      </app-contact-us-modal>
    </div>
  </div>
</ng-template>
<ng-template #currentComponents1>
  <div [ngSwitch]="curAction">
    <div *ngSwitchCase="'change_password'">
      <app-change-password (close)="reset()"></app-change-password>
    </div>
    <div *ngSwitchCase="'switch_lang'">
      <div class="language-selection">
        <div class="lang-title">
          <p class="bold">{{'switch_language'|translate}}</p>
        </div>
        <div class="list">
          <div class="list-item language-list" *ngFor="let item of languageList" (click)="onItemChange(item)">
            <input type="radio" name="language-label1" [(ngModel)]="isSelected" [value]="item.localeCode"
              [id]="'lang_23'+item.localeCode">
            <label class="label" [for]="'lang_23'+item.localeCode">{{item.localName}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'edit_profile'">
      <app-edit-profile (close)="reset()"></app-edit-profile>
    </div>
    <div *ngSwitchCase="'manage_address'">
      <app-manage-address (editOrAddAddress)="openManageAddressForm($event)"></app-manage-address>
    </div>
    <div *ngSwitchDefault>
      <app-view-profile [refresh]="refresh.asObservable()"></app-view-profile>
    </div>
    <div *ngSwitchCase="'change_number'">
      <app-change-mobile-number (close)="reset()"></app-change-mobile-number>
    </div>
    <div *ngSwitchCase="'change_email'">
      <app-change-email (close)="reset()"></app-change-email>
    </div>
    <div *ngSwitchCase="'payment_detail'">
      <app-manage-payment-card (close)="reset($event)" [refresh]="refresh.asObservable()"></app-manage-payment-card>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="showModal">
  <div class="tc-modal " *ngIf="showModal">
    <div class="content">
      <div class="modal-header border-bottom">
        <div class="font-primary-subtitle bold">{{'remove_card'|translate}}</div>
        <div (click)="exit()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="modal-content font-default-small">
        <div>
          <img src="./assets/icons/profile/card.svg">
        </div>
        <p>{{'remove_card_msg'|translate}}</p>
      </div>
      <div class="modal-footer1">
        <div class="segment cursor-pointer border-right" (click)="removeCard()">
          {{'btn_yes'|translate}}
        </div>
        <div class="segment cursor-pointer" (click)="exit()">
          {{'btn_no'|translate}}
        </div>
      </div>
    </div>

  </div>
</ng-container>
<ng-template #manageAddressForm>
  <app-manage-address-form (close)="closeManageAddressForm()"
    [addressDetails]="manageAddressFormDetails['addressDetails']"
    [addressIndex]="manageAddressFormDetails['addressIndex']"
    [configDetails]="manageAddressFormDetails['configDetails']"
    [isEditPatient]="manageAddressFormDetails['isEditPatient']"
    [patientDetails]="manageAddressFormDetails['patientDetails']"></app-manage-address-form>
</ng-template>


<app-custom-modal-container id="manage-identifier-modal" (complete)="closeManageIdentities()">
  <app-manage-identities *ngIf="isManageIdentities" [modalId]="'manage-identifier-modal'"></app-manage-identities>
</app-custom-modal-container>


<div *ngIf="showCoupon" class="show-coupon-list">
  <app-coupon-modal (completeCoupon)="applyCoupon($event.data)" [refresh]="refreshInternalCoupon.asObservable()"
  [type]="'VIEWCOUPON'"></app-coupon-modal>
</div>

<div *ngIf="showInsuranceModal">
  <app-manage-insurance (completeCoupon)="closeInsuranceModal()"></app-manage-insurance>
</div>
