import { Component, OnInit, Input, NgModule, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import * as moment from 'moment';
import { months } from '../months';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { UserService } from 'src/app/services/user.service';



export const MY_CUSTOM_FORMATS = {
  parseInput: 'ddd, DD MMM',
  fullPickerInput: 'ddd, DD MMM h:mm a',
  datePickerInput: 'ddd, DD MMM',
  timePickerInput: 'hh:mm a',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
};

@Component({
  selector: 'app-dateandtime',
  templateUrl: './dateandtime.component.html',
  styleUrls: ['./dateandtime.component.scss'],
  providers: [
    // { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})

export class DateandtimeComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  @ViewChild('dt1') owlDateTime;
  @Output() valueChangeComplete: EventEmitter<any>
  public isFocus: boolean = false;
  public _min: string;
  public _max: string;
  public restrictOldDates: boolean = false;
  constructor(private toast: ToastServiceService, cfs: ClinicalformService, private user: UserService) {
    super(cfs);
    this.valueChangeComplete = new EventEmitter<any>()
    this._min = this.user.getMoment().subtract(120, 'years').format('YYYY-MM-DD');
    this._max = this.user.getMoment().add(1, 'years').format('YYYY-MM-DD');
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyup(event: KeyboardEvent) {
    let activeElement = document['_activeElement'];
    if (!activeElement.closest('app-section'))
      return;
    if (event.keyCode === 32 && this.section['domainCode'] === activeElement.closest('app-section')['id'].split('#')[1])
      this.owlDateTime.open();
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    this.restrictOldDates = this.section.codify === 'Y' ? (this.section.domainControls.restrictPastDateEntry === 'Y') : (this.section.restrictPastDateEntry === 'Y');
    this.setMinDate();
  }

  setMinDate() {
    if (this.restrictOldDates)
      this._min = this.user.getMoment().format();
  }

  onDataTimeInput(ev) {
    console.log(ev.value);
    console.log(this.user.getMoment(ev.value).format());
    this.section['selected'] = this.user.getMoment(this.section['selected']).format();
    setTimeout(() => {
      if (this.user.getMoment(ev.value).isValid()) {
        if (this.restrictOldDates) {
          let currentValue = this.user.getMoment(this.user.getMoment().format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a');
          let selValue = this.user.getMoment(this.user.getMoment(ev.value).format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a');
          if (selValue.isBefore(currentValue)) {
            this.toast.showToast(true, "current date/time is set, as previous date/time is selected", "error");
            this.section.selected = this.user.getMoment().format();
            this.section['_error'] = '';
          }
        }
      } else {
        this.section.selected = '';
      }
      if (this.section.selected) {
        this.valueChangeComplete.emit(true);
      }
    }, 100);
  }
}
