import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  constructor(private user: UserService) { }

  transform(value: any): any {
    return this.user.getMoment(value).format('dddd, DD-MMMM-YYYY');
  }

}
