import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
@Component({
  selector: 'app-longtext',
  templateUrl: './longtext.component.html',
  styleUrls: ['./longtext.component.scss']
})
export class LongtextComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
  }

  changeInText(value) {
    if(value === '') {
      super.listenToInput(value, this.section);
    }
    super.complete(this.section);
  }

}
