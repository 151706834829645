import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarePlanComponent } from './care-plan.component';
import { ListItemModule } from '../list-item/list-item.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [CarePlanComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule
  ],
  exports:[CarePlanComponent]
})
export class CarePlanModule { }
