import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [ToastComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [
    ToastComponent
  ]
})
export class ToastModule { }
