import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TaskLoginComponent } from './task-login.component';
import { FooterModule } from '../footer/footer.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
    declarations: [TaskLoginComponent],
    imports: [
        CommonModule, FooterModule, PipesModule, FormsModule, ReactiveFormsModule
    ], exports: [
        TaskLoginComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class TaskLoginModule { }
