<div class="directive-linked">
  <div class="directive-linked-title" (click)="openEditMode()">
    <img
    [src]="section.domainControls.directiveIconIndex ? 'assets/icons/opd/'+section.domainControls.directiveIconIndex+'.svg' : 'assets/icons/opd/custom.svg' "
    *ngIf="section.domainControls">
    <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
    <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
  </div>

  <ng-template #empty>
    <div class="direcive-linked-content" (click)="openEditMode()">
      <input class="empty-state" placeholder="{{'Enter ' +  section.domainName }}" disabled>
      <p></p>
    </div>
  </ng-template>
  
  <ng-container *ngIf="!controls['isVital'] && section">
    <ng-container *ngIf="section._isSingleInstance || section._isCrossReferral || (section._controls && section._controls.isReferral)">
      <ng-container *ngIf="section._isCrossReferral || (section._controls && section._controls.isReferral)">
        <div *ngIf="section.selectedValues && section.selectedValues.length>0  else empty;" class="dle-selected-values dle-selected-cross-referral" [ngClass]="{'dle-selected-single-referral':section._controls.isReferral && !section._isCrossReferral}">
          <div *ngFor="let sv of section.selectedValues" class="dle-single-selected-values-item">
              <div class="dle-single-selected-values"><span class="dle-single-selected-values-val"><span class="highlight-blue">{{ sv.team || sv.teamPractName }}</span> ({{sv.parsedValue}})</span></div>
              <ng-container *ngIf="sv._isCrossReferral">
                <div class="dle-single-selected-values" *ngIf="sv._isCrossReferral.facility"><span class="dle-single-selected-values-topic">{{'referred_facility'| translate}}</span><span class="dle-single-selected-values-val">{{sv._isCrossReferral.facility.longName}}</span></div>
                <div class="dle-single-selected-values" *ngIf="sv._isCrossReferral.apptRefNumber && sv._isCrossReferral._parsedTxt"><span class="dle-single-selected-values-topic">{{'appointment_booked' | translate}}</span><span class="dle-single-selected-values-val">{{sv._isCrossReferral._parsedTxt}}</span></div>  
              </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!section._isCrossReferral && !(section._controls && section._controls.isReferral)">
        <div *ngIf="section.selectedValues && section.selectedValues.length>0  else empty;" class="dle-selected-values">
          <ng-container *ngFor="let sv of section.selectedValues" >
              <ng-container *ngIf="sv.values && sv.values.length>0">
                  <div *ngFor="let val of sv.values" class="dle-single-selected-values" (click)="openEditMode()">
                      <span class="dle-single-selected-values-topic">{{val.name}} : </span>
                      <span *ngIf="val._format && val._format === 'HTML' else normalData" class="dle-single-selected-values-val" [innerHTML]="val.values"></span>
                      <ng-template #normalData>
                          <span class="dle-single-selected-values-val">{{val | directiveLinkedPreviewText}}</span>
                      </ng-template>
                  </div>
              </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!section._isSingleInstance && !section._isCrossReferral && !(section._controls && section._controls.isReferral)">
      <div *ngIf="section['selectedValues'] && section['selectedValues'].length else empty;" class="multi-concept-values">
        <div class="multi-concept-value-item" *ngFor="let sv of section['selectedValues'];let last = last;let i = index" [ngClass]="{'copied-concept-name': sv._copied && !sv.copiedToForm}">
          <span (click)="editSelectedValues(i)">{{sv['team'] || sv['conceptName']}}</span> <ng-container *ngIf="sv.parsedValue">({{sv.parsedValue}}) </ng-container><ng-container *ngIf="!last">, </ng-container>
          <div class="concept-actions">
            <div class="concept-action" *ngIf="sv._copied && !sv.copiedToForm" (click)="addToForm(i)">
              <img class="type-image" src="assets/icons/opd/copy_plain.svg"> {{'lbl_copy' | translate}}
            </div>
            <ng-container *ngIf="!sv._copied || (sv._copied && sv.copiedToForm)">
              <div class="concept-action" (click)="editSelectedValues(i)">
                <img class="type-image" src="assets/icons/opd/edit.svg" alt="">
              </div>
              <div class="concept-action" (click)="deleteSelectedValues(i)">
                <img class="type-image" src="assets/icons/opd/del.svg" alt="">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>


  <div class="vitals" *ngIf="controls['isVital']">
    <app-patient-vitals [patientId]="patient['patientId']" [encounter]="encounter"></app-patient-vitals>
  </div>


  <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
      <p>{{'form_field_mandatory' | translate}}</p>
  </div>
</div>