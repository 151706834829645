import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsDetailsComponent } from './contact-us-details.component';
import { PipesModule } from '../pipes/pipes.module';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ContactUsDetailsComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    NgbCarouselModule
  ],
  exports:[
    ContactUsDetailsComponent
  ]
})
export class ContactUsDetailsModule { }
