import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import * as AppConstant from '../app.string';
import { LinkPatientService } from '../services/link-patient.service';
import * as moment from 'moment';
@Component({
  selector: 'app-health-package-details',
  templateUrl: './health-package-details.component.html',
  styleUrls: ['./health-package-details.component.scss']
})
export class HealthPackageDetailsComponent implements OnInit {

  @Output() closePkg: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Output() openRequest: EventEmitter<any>;
  @Output() next: EventEmitter<any>;
  @Input() selectedPkg;
  public searchFacility: any;
  public searchPatient: any;
  public facilityList: Array<any>;
  public currency_code: string;
  public linkedPatients: Array<any>;
  public isManageIdentities: boolean;
  public uploadForm: UntypedFormGroup;
  public curDate: string;
  public paymentGatewayUrl: string;
  public appConfig: any;
  dates:any=[];
  dateId:any;
  public chosenFacility: any;
  public choosePreference: boolean;
  //undefined
  public date
  constructor(private user: UserService,  private linkPatient: LinkPatientService, private formBuilder: UntypedFormBuilder, ) {
    this.closePkg = new EventEmitter<any>();
    this.openRequest = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.next = new EventEmitter<any>();
    this.uploadForm = new UntypedFormGroup({});
    this.curDate = this.user.getMoment().format('YYYY-MM-DD');
    this.searchFacility = {
      show: false,
      search: '',
      selected: {},
      prevent:false
    };
    this.searchPatient = {
      show: false,
      search: '',
      selected: {},
      prevent:false
    };
  }

  private pastDateValidator = (control: AbstractControl): { pastDate: boolean } => {
    let today = this.user.getMoment().format('YYYY-MM-DD');
    let selectedDate = this.user.getMoment(control.value, 'YYYY-MM-DD')
    if ( selectedDate.isBefore(this.user.getMoment(today,'YYYY-MM-DD')) ) {
        return { pastDate : true };
    }
    return null; 
  };

  ngOnInit(): void {
    this.currency_code = this.user.getCurrency();
    // this.user.getUpdateInfo('facilities').subscribe(data => {
    //   this.facilityList = data.facilities;
    //   let facility =  data.facilities;
    //   facility = facility.filter(item => {
    //     return item.facilityId == this.selectedPkg.selectedFacilityId;
    //   })
    //   this.selectFacility(facility[0]);
    // });
    this.facilityList = this.selectedPkg.facilities
    this.selectedPkg.facilities.find( item => {
      if(item.facilityId == this.selectedPkg['selectedFacilityId']){
        this.chosenFacility = item;
        this.selectFacility(this.chosenFacility);
      }
      console.log("ccccc", this.chosenFacility)
    })
    // this.setLinkedPatients();
    this.uploadForm = this.formBuilder.group({
      // name: new FormControl('', [Validators.required]),
      // type: new FormControl('', [Validators.required]),
      date: new UntypedFormControl('', [Validators.required, this.pastDateValidator])
    });
    this.updateForm(this.user.getMoment().format('DD-MM-YYYY'), 'date')
    this.getDates();
  }

  public closePkgModal() {
    this.closePkg.emit('CLOSE');
  }

  public selectFacility(value: any) {
    console.log("selectFacility", value.facilityId)
    if (value) {
      this.searchFacility = {
        show: false,
        search: '',
        selected: value
      }
    } 
    this.chosenFacility = value
    this.selectedPkg['selectedFacilityId'] = value.facilityId;
  }
  public selectPatient(value: any) {
    console.log("searchPatient", value)
    if (value) {
      this.searchPatient = {
        show: false,
        search: '',
        selected: value
      }
    } 
  }

  getSubTitle(names){
    for (let l in names){
      if(names[l].locale==this.user.currentLanguage){
        return names[l].description;
      }
      else
      {
        return names[l].description;
      }
    }
  }

  getTitle(names){
    if (names.length == 0) {
        return '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

  getPackage(detail){
    for(let l in detail){
        if(detail[l].locale == this.user.currentLanguage){
            if(detail[l].packageContent == "" || detail[l].packageContent== "undefined" || detail[l].packageContent=='<html></html>'){
                return false;
            }
            else{
              return detail[l].packageContent
            }
        }
        else{
          return detail[l].packageContent
        }
    }
  } 

  getPackageContent1(detail){
    if (detail.length == 0) {
        return '';
    } else {
        let t = detail.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.packageContent;
        } else {
            return detail[0].packageContent;
        }
    }
  }

  getPackageContent(detail){
    console.log("getPackageContent detail", detail)
    let t = detail.find(name => {
      return name.locale == this.user.currentLanguage
    })
    if (t) {
        return t.packageContent;
    } else {
        return detail[0].packageContent;
    }
  }

  private setLinkedPatients() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            console.log(this.linkedPatients);
            this.searchPatient.selected = this.linkedPatients[0];
          }
        })
      }
    })
  }

  public completeSelection(event: any) {
    let obj = {
      id: event.uhid,
      name: event.personname,
      personid: event.personid,
      relation: event.relationshiptype,
      image: event.imageurl,
      gender: event.gender,
      dob: event.dob
    }
    // this.complete.emit({ patient: obj });
  }

  openModal(id: string) {
    this.isManageIdentities = true;
  }
  closeManageIdentity(){
    this.setLinkedPatients();
    this.isManageIdentities = false;
  }
  dpDown(){
    if(this.searchPatient.show == true){
      this.searchPatient.show = false
      console.log("false", this.searchPatient.show)
    }
    else{
      this.searchPatient.show = true;
      this.searchFacility.show = false;
      console.log("true", this.searchPatient.show)
    }
  }

  public updateForm(value, key) {
    this.uploadForm.patchValue({
      [key]: value
    });
  }
  public vaadinChange(event, key){
    this.uploadForm.get('date').setValidators(this.pastDateValidator);
    this.uploadForm.get('date').markAsDirty();
    this.uploadForm.patchValue({
      [key]: this.user.getMoment(event['target']['__data'].value).format('YYYY-MM-DD')
    });
  }
  public choosePackage(){
    let payload = {
      package: this.getTitle(this.selectedPkg.name),
      pkgDtl: this.getSubTitle(this.selectedPkg.detail),
      patient: this.searchPatient.selected,
      facility: this.searchFacility.selected,
      preferredDate: this.uploadForm.get('date').value,
      pkgCost: this.selectedPkg.facilities[0].controls.charge,
      pkgConcept: this.selectedPkg
    }
    let actualPackCharge = this.getChargeByFacility()
    let discountPackCharge = this.getDiscountChargeByFacility()
    payload['pkgCost'] = discountPackCharge ? discountPackCharge: actualPackCharge
    console.log("payload", payload, this.selectedPkg.facilities)
    // this.openRequest.emit(payload)
    this.next.emit({
       data: this.selectedPkg.facilities, state: 'CHOOSE_FACILITY', key: 'facilityList'
    })
  }

  getDates(){
    this.dates=this.getActiveDates(this.chosenFacility.controls.daysAvailable,
        this.chosenFacility.controls.maxRequestDaysAhead,
        this.chosenFacility.starts,
        this.chosenFacility.expires
    );
    this.dateId=this.dates[0];
    this.uploadForm.patchValue({
      date:this.dateId
    })
  }
getActiveDates(schedule, noOfDays,starts,expires) {
    console.log(schedule,noOfDays);
    var counter = 0;
    var dateArray = [];
    var dateIndex: any = new Date();
    console.log('dateindex',dateIndex);
    while (counter < noOfDays) {
        var dayOfWeek = dateIndex.getDay();
        if (this.checkActive(schedule, dayOfWeek) == true) {
            //if(this.user.getMoment(new Date(dateIndex)).format("YYYY-MM-DD") > this.user.getMoment(starts).format("YYYY-MM-DD")
            //&& this.user.getMoment(new Date(dateIndex)).format("YYYY-MM-DD") < this.user.getMoment(expires).format("YYYY-MM-DD"))
            if(((this.user.getMoment(new Date(dateIndex)).isAfter(starts))) && (this.user.getMoment(new Date(dateIndex)).isBefore(expires)))
            {
                //console.log("New Date is "new Date(dateIndex));
                console.log("the date index is ",dateIndex);
                dateArray.push(this.user.getMoment(new Date(dateIndex)).format("YYYY-MM-DD"));
            }


        }
        dateIndex.setDate(dateIndex.getDate() + 1);
        counter++;
    }
    console.log('dates',dateArray);
    return dateArray;
}
getPreferDate(dtm){
    if(dtm){
        return this.user.getMoment(dtm).format('ddd, DD-MM-YYYY');
    }
}

checkActive(schedule, dayOfWeek) {
    //0-sunday,1-monday,2-tuesday,3-wednesday,4-thursday,5-friday,6-saturday
    var day;
    var active = false;
    switch (dayOfWeek) {
        case 0:
            day = "sun";
            break;
        case 1:
            day = "mon";
            break;
        case 2:
            day = "tue";
            break;
        case 3:
            day = "wed";
            break;
        case 4:
            day = "thu";
            break;
        case 5:
            day = "fri";
            break;
        case 6:
            day = "sat";
            break;
        default:
            active = false;
    }

    if (schedule[day] == true || schedule[day] == 'true'){
        active = true;
    }
    return active;
}

  public showFacility(){
    if(this.searchFacility.show == true){
      this.searchFacility.show = false
      console.log("false", this.searchFacility.show)
    }
    else{
      this.searchFacility.show = true;
      this.searchPatient.show = false;
      console.log("true", this.searchFacility.show)
    }
  }

  public selectPackage(){
    this.choosePreference = true;
  }
  public goToPkgDtl(){
    this.choosePreference = false;
  }
  public getChargeByFacility(){
    let charge;
    if(this.chosenFacility){
      charge = this.chosenFacility.controls.charge
    }
    return charge;
  }
  public getDiscountChargeByFacility(){
    let discountCharge;
    if(this.chosenFacility){
      discountCharge = this.chosenFacility.controls.discountCharge
    }
    return discountCharge
  }
  public getPackageImg(detail){
    let t = detail.find(name => {
      return name.locale == this.user.currentLanguage
    })
    if (t) {
        return t.imageURL;
    } else {
        return detail[0].imageURL;
    }
  }

  public closeModal(type) {
      this.action.emit(type);
  }
}
