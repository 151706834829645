import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(public http: HttpClient, private intercepter: InterceptorService, private user: UserService) { }

  public getTopicsForReports(requestURL) {
    return Observable.create(oberserver => {
      this.http.get(requestURL, { reportProgress: false }).subscribe(data => {
        oberserver.next(data);
      });
    });
  }

  public getJSONForReports(requestURL) {
    return Observable.create(oberserver => {
      this.http.get(requestURL, { reportProgress: false, responseType: 'text' }).subscribe(result => {
        try {
          if (typeof result == 'string')
            result = result.replace(/(\r\n|\n|\r|\t)/gm, "<br/>");
          result = JSON.parse(result);
        } catch (err) {
          console.error('Error in paring JSON', err);
        }
        oberserver.next(result['Events']);
      }, error => {
        console.error(error);
        oberserver.next([]);
      });
    });
  }


  public resolveFileURL(payload) {
    return Observable.create(oberserver => {
      this.http.post(environment['API_URL'] + 'api/events/resolveFileUrl', payload, { reportProgress: true }).subscribe(data => {
        oberserver.next(data);
      });
    });
  }


  public getClobData(requestURL) {
    return Observable.create(oberserver => {
      let header = new HttpHeaders();
      header = header.set('Content-Type', 'text/html');
      this.http.get(requestURL, { headers: header, reportProgress: false, responseType: 'text' }).subscribe(data => {
        oberserver.next(data);
      });
    });
  }
  public b64toBlob(file) {
    var block = file.split(";");
    let contentType = block[0].split(":")[1];
    var b64Data = block[1].split(",")[1];
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public uploadDocs(file, params) {
    return Observable.create(oberserver => {
      let blob = this.b64toBlob(file)
      let formData = new FormData();
      formData.append('upload', blob, params.filename);
      Object.entries(params).forEach((p:Array<any>) => {
        formData.append(p[0], p[1]);
      })
      let options = {
        reportProgress: true,
        body: {
          fileName: 'random.pdf',
          fileKey: 'upload',
          mimeType: 'application/pdf',
          chunkedMode: false,
          params: {
            patientId: this.user.getPersonId(),
            ...params
          }
        }
      }
      this.http.post(environment['API_URL'] + 'api/persons/document', formData, options).subscribe(data => {
        oberserver.next(data);
      });
    });
  }
}
