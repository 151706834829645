import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginModalComponent } from './pre-login-modal.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    PreLoginModalComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports:[PreLoginModalComponent]
})
export class PreLoginModalModule { }
