<div id="appointment-confirm">
  <div class="appt-review-details">
    <div class="appt-card-wrapper">
      <div class="appt-container">
        <div class="appt-card">
          <div class="appt-header border-bottom">
            <div class="ternary-text">{{'ttl_appointment_details'|translate:'LABELS'}}</div>
            <div class="title-text">{{'Clinic_consultation_-_Care_Express'|translate:'LABELS'}}</div>
          </div>
          <div class="appt-header border-bottom">
            <div class="title ternary-text">{{'Appointment_for' | translate}}</div>
            <div class="appt-facility-pract">
              <div class="image">
                <app-person-avatar [url]="_input['in']['patient']['image']" [additionalClass]="'circular'">
                </app-person-avatar>
              </div>
              <div class="appt-facility-pract-details">
                <span class="secondary-text bold">{{_input['in']['patient']['name']}}</span>
                <span class="ternary-text">{{_input['in']['patient']['relation'] | translate : 'RELATION'}}</span>
              </div>
              <div class="switch-wrapper" (click)="switchAction('select_patient')">
                <img src="./assets/icons/health-package/Switch.svg" alt="switch" class="switch-user">
              </div>
            </div>
          </div>
          <div class="appt-header border-bottom" *ngIf="practDetails">
            <div class="title ternary-text">{{'Consulting_doctor' | translate}}</div>
            <div class="appt-facility-pract">
              <div class="image">
                <app-practitioner-avatar [url]="practDetails['photoUrl']"
                  [additionalClass]="'circular'"></app-practitioner-avatar>
              </div>
              <div class="appt-facility-pract-details">
                <span class="secondary-text bold">{{ 'dr_label' |translate: 'LABELS'}} {{
                  practDetails['practitionerName'] | translate : 'LOCALE' : 'name' }}</span>
                <span class="ternary-text">
                  {{ practDetails['specialityName'] | translate : 'LOCALE' : 'name'
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="appt-service">
            <span class="title ternary-text service-title">{{'Services'|translate}}: </span>
            <div class="services" *ngIf="selectedServices">
              <div class="service" *ngFor="let service of selectedServices;let indexOfService=index">
                <span class="service__text">{{ getServiceName(service['details'],service['conceptName'])}}</span>
                <span *ngIf="service['details']['servicecost']"
                  class="bold service__price">{{service['details']['servicecost']}}
                  {{currency_code}}
                  <!-- <img *ngIf="selectedServices.length > 1" src="./assets/icons/input/delete.svg" alt="delete"
                    class="service__delete-icon cursor-pointer" (click)="removeService(indexOfService)"> -->
                </span>
              </div>
            </div>
          </div>
          <div class="appt-header">
            <div class="title ternary-text">{{'Date_&_location' | translate}}</div>
            <div class="appt-slot-content" *ngIf="!fromSlotBlockPrompt">
              <div class="title-text no-bold">
                <app-appt-time [type]="'DETAILEXPRESS'" [time]="_input.in['slot']['fromDate']"
                  [toTime]="_input.in['slot']['toDate']" [sourceFormat]="'YYYY-MM-DD hh:mm a'"
                  [destinationFormat]="'D MMM YY, hh:mm a'" [toFormat]="'hh:mm a'"
                  [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: _input.in['facility'])"
                  [facilityId]="_input.in['facility']"></app-appt-time>
              </div>
              <div class="title-text no-bold">{{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}
              </div>
            </div>
            <div class="appt-slot-content slot-with-block-err-con" *ngIf="fromSlotBlockPrompt">
              <div>
                <div class="title-text no-bold">{{ slotDate }}, {{ _input['in']['slot']['name'] }}</div>
                <div class="title-text no-bold">{{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}
                </div>
              </div>
              <div *ngIf="fromSlotBlockPrompt" class="error-img-con">
                <img src="./assets/icons/error.svg" style="width: 1.3em;">
              </div>
            </div>
          </div>
          <div [ngClass]="{ 'default-bg':  !enterReferral , 'referral-card': enterReferral}"
            *ngIf="isReferralApplicable">
            <p class="prim-ttl-txt " (click)="enterReferral = !enterReferral" *ngIf="!enterReferral">
              {{'appt_dtl_ttl_enter_referral_code'| translate:'LABELS'}}</p>
            <ng-container *ngIf="enterReferral">
              <p class="subtitle lbl">{{'appt_dtl_ttl_referral_code'|translate:'LABELS'}}</p>
              <div class="padding">
                <input class="ref-input" [type]="referralControl?referralControl.type:'text'"
                  (input)="onTextInput($event)" placeholder="{{ 'appt_dtl_placeholder_enter' | translate:'LABELS'}}"
                  [(ngModel)]="referralCode" [minLength]="referralControl?referralControl.minLength:null"
                  [maxLength]="referralControl?referralControl.maxLength:null" />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="appt-card-side">
        <div *ngIf="(serviceInfo.totalCost > 0 || chargeDetails.isPointsApplied) && isEnablePoints && pointsType == 'PAYMENT'">
          <app-altpayment-input [input]="'POINTS'" [totalAmount]="serviceInfo.totalCost"
            [netPayable]="chargeDetails._payableWithoutPoints"
            [promoCodeDetails]="chargeDetails['promoCodeDetails']" (pointsApplied)="pointsApplied($event)" #points
            (applyingPoints)="applyingPoints($event)"></app-altpayment-input>
        </div>
        <div  *ngIf="(isEnablePromo || (isEnablePoints && pointsType == 'DISCOUNT'))" id="finddocpromodiv">
          <ng-container *ngIf="canPay && (isEnablePromo && serviceInfo.totalCost > 0) ||  chargeDetails.isPromoApplied">
            <app-promo-input [patientPayable]="chargeDetails.consultationCharge" [selections]="selections"
              [discountDetails]="chargeDetails['discountDetails']" [selectedServices]="selectedServices"
              (applyingDiscount)="applyingPromoCode($event)" (appliedDiscount)="appliedPromocode($event)"
              [isEnablePoints]="isEnablePoints && pointsType == 'DISCOUNT'"
              [isEnablePromo]="isEnablePromo" [parsedChargeInfo]="chargeVatDetails ? chargeVatDetails: null"></app-promo-input>
          </ng-container>
        </div>

        <div *ngIf="(serviceInfo.totalCost > 0 || chargeDetails.isCouponApplied) && isEnableCoupon">
          <app-altpayment-input [input]="'COUPON'" [totalAmount]="serviceInfo.totalCost"
            [netPayable]="chargeDetails._payableWithoutCoupon"
            [promoCodeDetails]="chargeDetails['promoCodeDetails']" (couponApplied)="couponApplied($event)" #coupon
            (applyingCoupon)="applyingCouponCode($event)"></app-altpayment-input>
        </div>

        <div class="appt-confirm-wrapper" *ngIf="flowType != 'RESCHEDULE'">
          <div class="appt-confirm-card">
            <div class="appt-confirm-header">
              <span class="bold title secondary-text">{{'appt_dtl_pmt_details'|translate:'LABELS'}}</span>
            </div>
            <div class="appt-confirm-content-main">
              <div class="appt-confirm-content border-bottom" *ngIf="!chargeVatDetails">
                <span class="secondary-text ternary-color">{{'Service_charges'|translate:'LABELS'}}</span>
                <div class="appt-confirm-content-amount amount--bright">
                  <app-display-currency [amount]="chargeDetails.consultationCharge"></app-display-currency>
                </div>
              </div>
              <div class="appt-confirm-content border-bottom" *ngIf="chargeVatDetails && chargeVatDetails.baseServiceAmount">
                <span class="secondary-text ternary-color">{{'Service_charges'|translate:'LABELS'}}</span>
                <div class="appt-confirm-content-amount amount--bright">
                  <app-display-currency [amount]="chargeVatDetails.baseServiceAmount"></app-display-currency>
                </div>
              </div>
              <div class="coupon-applied-wrapper">
                <div class="coupon-applied-details"  *ngIf="chargeDetails.isPromoApplied && chargeDetails.promoDiscount > 0">
                  <span class="secondary-text ternary-color">{{'lbl_Promocode'|translate}}</span>
                  <span class="align-right secondary-text"><app-display-currency [amount]="chargeDetails.promoDiscount"></app-display-currency></span>
                </div>
                <div class="coupon-applied-details" *ngIf="chargeDetails.isPointsApplied && chargeDetails.pointsDiscount > 0">
                  <span class="secondary-text ternary-color">{{'lbl_points'|translate}}</span>
                  <span class="align-right secondary-text"><app-display-currency [amount]="chargeDetails.pointsDiscount" [prefix]="'-'"></app-display-currency></span>
                </div>
                <div class="coupon-applied-details" *ngIf="chargeDetails.isCouponApplied && chargeDetails.couponDiscount > 0">
                  <span class="secondary-text ternary-color">{{'lbl_coupon'|translate}}</span>
                  <span class="align-right secondary-text"><app-display-currency [amount]="chargeDetails.couponDiscount"></app-display-currency></span>
                </div>
                <div class="coupon-applied-details" *ngIf="chargeVatDetails && chargeVatDetails.vatDisplayAmount">
                  <span class="secondary-text ternary-color">{{'express_lbl_Vat_amount'|translate}}</span>
                  <span class="align-right secondary-text"><app-display-currency [amount]="chargeVatDetails.vatDisplayAmount"></app-display-currency></span>
                </div>
                <div class="coupon-applied-details">
                  <span class="secondary-text bold">{{'total'|translate}}</span>
                  <span class="align-right bold secondary-text"><app-display-currency [amount]="serviceInfo['totalCost']"></app-display-currency></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="appt-confirm-wrapper">
          <div class="appt-confirm-card">
            <div class="appt-confirm-header">
              <span class="bold title secondary-text">{{'conditions_and_policy'|translate}}</span>
            </div>
            <div class="content-pad">
              <input type="checkbox" [(ngModel)]="isTermsAndConditionAccepted" />
              <label class="secondary-text">{{'msg_agree'|translate:'LABELS'}} <span
                  class="font-primary secondary-text cursor-pointer"
                  (click)="opentc()">{{'msg_terms_conditions'|translate:'LABELS'}}</span>
                {{'of_policy'|translate:'LABELS'}}</label>
            </div>
          </div>
        </div>
        <div class="appt-confirm-wrapper" *ngIf="!hideRefundPolicyInExpress">
          <div class="appt-confirm-card">
            <div class="appt-confirm-header border-bottom">
              <span class="bold title">{{'other_queries'|translate}}</span>
            </div>
            <div class="content-pad">
              <button class="btn btn-secondary btn-customize btn-white" *ngIf="isHelpEnabled"
                (click)="openFaq()">{{'frequently_asked'|translate}}</button>
              <a href="{{apiUrl}}static/rc/{{lang}}.html" target="_blank" rel="noopener"><button
                  class="btn btn-secondary btn-customize btn-white btn-margin">{{'our_refund_policy'|translate}}</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="footer">
  <ng-container *ngIf="flowType != 'RESCHEDULE'; else rescheduleButton">
    <ng-container>
      <button class="primary-button btn-customize" *ngIf="canPay && serviceInfo['totalCost'] >0" [disabled]="!isTermsAndConditionAccepted" (click)="payForAppt()">
        <span>{{'appt_but_pay'|translate:'LABELS'}}</span>
        <app-display-currency class="paybtn-margin" [amount]="serviceInfo['totalCost']" theme="white"></app-display-currency>
      </button>
      <button class="primary-button btn-customize" [disabled]="!isTermsAndConditionAccepted" (click)="confirmAppt()" *ngIf="!canPay || serviceInfo['totalCost'] <= 0">
        {{'Confirm_appointment' | translate}}
      </button>
    </ng-container>
  </ng-container>
  <ng-template #rescheduleButton>
    <button class="primary-button btn-customize" [disabled]="!isTermsAndConditionAccepted" (click)="rescheduleAppt()"> {{'Reschedule_appt' | translate}} </button>
  </ng-template>
</div>

<div id="block-slot-prompt" *ngIf="showBlockSlotPrompt">
  <div class="card-container">
    <div class="card">
      <div class="card-header">
        <span
          class="font-primary-subtitle bold">{{'EXPRESS#APPT#SLOTBLOCK#TIME#UNAVIL#HEADER'|translate:'LABELS'}}</span>
        <div class="close float-right closeicon pointer" (click)="goBackSlotBlockPrompt()">
          <img src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="card-content">
        <div class="card-image block-slot-img">
          <img src="./assets/icons/error.svg">
        </div>
        <div class="ref-subtitle font-light-small ins-subtitle">
          {{'EXPRESS#APPT#SLOTBLOCK#TIME#UNAVIL#MESSAGE'|translate:'LABELS'}}</div>
        <button class="primary-button"
          (click)="goBackToSlotView()">{{'EXPRESS#APPT#SLOTBLOCK#TIME#UNAVIL#BUTTON'|translate:'LABELS'}}</button>
      </div>
    </div>
  </div>
</div>