<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'DETAIL1'">
    {{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'FACILITY' : facilityId}} 
    <span class="timezone-facility" *ngIf="showLocalTime">{{'timezone_facility' | translate: 'FACTIMEZONE': facilityId}}</span>
    <div class="desc-local-time" lines="none" *ngIf="showLocalTime">
      <img slot="start" src="assets/icons/info.svg">
      <label>
        <p>{{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'LOCAL' : facilityId}} {{'timezone_local' | translate}}</p>
      </label>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'DETAILEXPRESS'">
    {{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'FACILITY' : facilityId}}  - {{toTime | apptTime: 'FORMAT':(toFormat || 'hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'FACILITY' : facilityId}}
    <span class="timezone-facility" *ngIf="showLocalTime">{{'timezone_facility' | translate: 'FACTIMEZONE': facilityId}}</span>
    <div class="desc-local-time" lines="none" *ngIf="showLocalTime">
      <img slot="start" src="assets/icons/info.svg">
      <label>
        <p>{{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'LOCAL' : facilityId}} - {{toTime | apptTime: 'FORMAT':(toFormat || 'hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'LOCAL' : facilityId}} {{'timezone_local' | translate}}</p>
      </label>
    </div>
  </ng-container>
  <div *ngSwitchCase="'CARD1'" class="scroll-text">
    <p><strong>{{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'LOCAL' : facilityId}} 
    <span class="timezone-facility" *ngIf="showLocalTime">{{'timezone_local' | translate}}</span></strong></p>
  </div>
  <div *ngSwitchCase="'CARD2'">
    <p><strong>{{time | apptTime: 'FORMAT':(destinationFormat || 'D MMM YYYY, hh:mm a'):(sourceFormat || 'YYYY-MM-DD HH:mm') : 'FACILITY' : facilityId}}</strong></p>
  </div>
</ng-container>