import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { HealthRecordsService } from '../services/health-records.service';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
import { UtilitiesService } from '../services/utilities.service';
@Component({
  selector: 'app-hr-visit-prescription',
  templateUrl: './hr-visit-prescription.component.html',
  styleUrls: ['./hr-visit-prescription.component.scss']
})
export class HrVisitPrescriptionComponent implements OnInit {
  viewUploads: boolean;
  selectedTab: any;

  @Input() set selectedVisit(value: any) {
    this._selectedVisit = value;
    this.prescriptions = []
    this.prescriptionToFilter = []
    this.getVisitDetails();
    this.getPrescriptions(this._selectedPatient)
  }
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
    this.prescriptions = []
    this.prescriptionToFilter = []
    this.getVisitDetails();
    this.getPrescriptions(this._selectedPatient)
  }
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() hideFilters: boolean;
  @Output() selectReport: EventEmitter<any>;
  @Output() openDocsUpload: EventEmitter<any>;
  public _selectedVisit: any;
  public _selectedPatient: any;
  public prescriptions = [];
  public searchTerm: string = '';
  public prescriptionToFilter = [];
  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: any;
  public maxDate: any;
  public pastPrescribeFilter;
  public currentPrescribeFilter;
  public searchExpand : boolean;
  public uploadedDocs : Array<any>=[];
  public reportToken: string;
  public search: string;
  public enablePrintPrescriptionYn: boolean;
  public enableOrderPharmacy:boolean;
  public uploadedPresBfrFilter = [];
  public viewTabs = [{code:'CM', label:'Current_medications'},{code:'PM', label:'Past_medications'}, {code:'UP', label:'Uploaded'}]
  public showBrandName:boolean;
  constructor( private hrService: HealthRecordsService, private user: UserService, private utility: UtilitiesService) { 
    this.selectReport = new EventEmitter<any>();
    this.openDocsUpload = new EventEmitter<any>();
    this.user.getConfigurationInfo.subscribe(data => {
      this.enablePrintPrescriptionYn = data['pxAppSettings'] && data['pxAppSettings']['enablePrintPrescriptionYn'] == 'Y' ? true: false;
      this.enableOrderPharmacy = data['pxAppSettings'] && data['pxAppSettings']['orderFromPharmacy'] == 'Y' ? true: false;
      this.showBrandName = data['pxAppSettings'] && data['pxAppSettings']['showBrandName'] == 'Y' ? true: false;
    })
  }

  ngOnInit(): void { 
    // this.prescriptions = prescription;
    // this.prescriptionToFilter = prescription;
    this.filters = [
      { key: '', value: 'all' },
      { key: 'CM', value: 'current_medication' },
      { key: 'PM', value: 'past_medication' }
    ];
    this.selectedFilter = this.filters[0];
    this.search = '';
    this.refresh.subscribe(response => {
      if (response) {
        this.getVisitDetails();
      }
    })
    // this.getVisitDetails();
    if (this._selectedVisit || this._selectedPatient){
      this.getUploadedReport(); 
    } 
  }
  public getUploadedReport() {
    this.user.getUploadedReport(this._selectedPatient.uhid, null, null).subscribe(data => {
      this.uploadedDocs = data.map(d => {
        d.note = d.header.note
        // d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY')
        var aDate   = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY', true);
        // var aDate   = this.user.getMoment(d.header.reportDate, 'MM-DD-YYYY', true);
        var isValid = aDate.isValid();  
        if(isValid){
          d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY')
        }
        else{
          d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MMM-YYYY')
        }
        return d;
      });
      this.uploadedDocs = this.uploadedDocs.filter( item =>{
        return item.docType == "PRE";
      })
      this.uploadedPresBfrFilter = this.uploadedDocs;
    })
  }
  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
    this.filterItemsByDate(this.selectedFilter.key);
    // this.maxDate = this.max_date();
    // this.filterItemsByDate(this.selectedFilter.key, this.maxDate);
  }
  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterItemsByDate(key) {
    this.prescriptions = this.prescriptionToFilter;
    if(key === 'CM'){
      this.prescriptions = this.prescriptions.filter(item => {
        // return item.startDateTime.indexOf(searchTerm) > -1;
        return item.endDateTime >= this.user.getMoment(new Date()).format("YYYY-MM-DD");
      });
      this.currentPrescribeFilter = this.prescriptions;
    }
    if(key === 'PM'){
      this.prescriptions = this.prescriptions.filter(item => {
        // return item.startDateTime.indexOf(searchTerm) === -1;
        return item.endDateTime < this.user.getMoment(new Date()).format("YYYY-MM-DD");
      });
      this.pastPrescribeFilter = this.prescriptions;
    }
  }

  max_date() {
    var max_dt;
    var max_dtObj = new Date(this.prescriptionToFilter[0].startDateTime);
      this.prescriptions.map((dt) => {
        if ( new Date( dt.startDateTime ) > max_dtObj)
        {
        max_dt = dt.startDateTime ;
        max_dtObj = new Date(dt.startDateTime );
        } 
      });
     return this.user.getMoment(max_dtObj).format("YYYY-MM-DD");
  }

  searchFilter(ev) {
      this.searchTerm = ev.target.value;
      this.filterItems(this.searchTerm,this.selectedFilter.key);
  }

  filterItems(searchTerm,key) {
    if(key === 'CM'){
      this.prescriptions = this.currentPrescribeFilter;
    }
    else if(key === 'PM'){
      this.prescriptions = this.pastPrescribeFilter;
    }
    else{
      this.prescriptions = this.prescriptionToFilter;
    }
    this.prescriptions = this.prescriptions.filter(item => {
      return item.genericText.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
    // this.prescriptions = this.prescriptions.filter(item => {
    //   return item.drugText.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    // });
    this.uploadedDocs = this.uploadedPresBfrFilter.filter(item => {
      return item.header.note.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  getPrescriptions(data){
    console.log("this._selectedPatient", this._selectedPatient)
    console.log("this._selectedVisit", this._selectedVisit)
    this.hrService.getPrescriptions(data.uhid, this._selectedVisit).subscribe(
      data => {
        this.prescriptions = data.prescriptions;
        this.prescriptionToFilter = data.prescriptions;
        if (!this.hideFilters)
          this.chooseTab(this.viewTabs[0]);
      }
    )
  }
  
  searchIcon(){
    if(this.searchExpand){
      this.searchExpand = false;
    }
    else{
      this.searchExpand = true;
    }
  }
  
  public openDocUpload() {
    this.openDocsUpload.emit();
  }

  public initViewPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'VP' });
  }
  public initDownloadPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'DP' });
  }
  public removeDownloadPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'RP' });
  }
  private getVisitDetails() {
    if (this._selectedVisit) {
      this.user.getVisitReport(this._selectedPatient.uhid, this._selectedVisit.encounterId, null).subscribe(data => {
        // this.clinicalHistory = data.reports;
        this.reportToken = data.accessToken;
      })
      this.getUploadedReport();
    } else if (this._selectedPatient) {
      this.user.getVisitReport(this._selectedPatient.uhid, null, null).subscribe(data => {
        // this.clinicalHistory = data.reports;
        this.reportToken = data.accessToken;
      })
      this.getUploadedReport();
    }
  }

  public printPrescription(pres){
    let payload = {
      facilityId: this._selectedPatient.facilityid || this._selectedPatient.facilityId,
      patientId:this._selectedPatient.uhid,
      encounterId: pres.encounterId,
      locale: this.user.currentLanguage,
      orderId: pres.orderId
    }
    this.hrService.generatePrescriptionHtml(payload).subscribe(data=> {
        this.utility.print(data, 'text/html')
    })
  }

  public openPharmacy(pg){
    let payload={
      orderId:pg[0].orderId
    }
    this.hrService.generateEPrescribeUrl(payload).subscribe(data=>{
      console.log('data--',data)
      if(data){
        window.open(data,'_blank')
      }
    })
  }

  public chooseTab(tab) {
    this.selectedTab = tab['code'];
    if(tab['code'] == 'UP') {
      this.viewUploads = true;
    } else {
      this.viewUploads = false;
      this.filterItemsByDate(tab['code']);
    }
  }
}


