import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { ManageIdentitiesComponent } from './manage-identities.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { DirectiveModule } from '../directive/directive.module';
import { LinkOtherMobileModule } from '../link-other-mobile/link-other-mobile.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
import { UpdateRelationModalModule } from '../update-relation-modal/update-relation-modal.module';
@NgModule({
  declarations: [ManageIdentitiesComponent],
  imports: [
    CommonModule, PipesModule,
    CustomModalModule,
    RegisterPatientModule,
    RegisterNewPatientModule,
    PersonAvatarModule, DirectiveModule, LinkOtherMobileModule,
    UpdateRelationModalModule
  ],
  exports: [
    ManageIdentitiesComponent
  ]
})
export class ManageIdentitiesModule { }
