import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeCareActivityComponent } from './home-care-activity.component';
import { NodataModule } from '../nodata/nodata.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [HomeCareActivityComponent],
  imports: [
    CommonModule, NodataModule, PipesModule, FormsModule, ReactiveFormsModule
  ],
  exports: [HomeCareActivityComponent]
})
export class HomeCareActivityModule { }
