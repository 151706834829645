import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss']
})
export class CaptionComponent extends Type implements OnInit, SectionInterface {

  @Input() public section;
  @Input() public sections;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
  }

}
