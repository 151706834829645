import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateRelationModalComponent } from './update-relation-modal.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    UpdateRelationModalComponent
  ],
  imports: [
    CommonModule, PipesModule
  ],
  exports:[UpdateRelationModalComponent]
})
export class UpdateRelationModalModule { }
