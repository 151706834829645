import { Pipe, PipeTransform } from '@angular/core';
// import { MasterDataService } from '@service/master-data.service';
import { TranslatePipe } from './translate.pipe';
import { TitleCasePipe } from '@angular/common';
import { UserService } from '../services/user.service';
@Pipe({
  name: 'healthpack',
  pure: false 
})
export class HealthpackPipe implements PipeTransform {

  constructor(private translate: TranslatePipe, private titleCasePipe: TitleCasePipe, private user: UserService){}

  transform(packs: any, applicableFilter: any,isModal?): any {
    if(isModal){
      if(applicableFilter[2] && applicableFilter[2].options.length === 0) {
        let facList = this.user.getAttributeForListingType('facilities', true)['facilities'];
        console.log(facList,"--908908----", this.user.getAttributeForListingType('facilities', true));

      let facilityList = facList.map(a => ({'key': a.facilityId, 'label': this.titleCasePipe.transform(this.translate.transform(a.facilityName, 'LANGARRAY', 'name', null)), 'checked': false}))
      let val = applicableFilter[2].options.push(...facilityList)
      }
      let selectedFitlers = applicableFilter.reduce((filter, checked) => {
        filter[checked['type']] = checked.options.filter(opt => opt.checked);
        return filter;
      }, {})
      let filteredPacks = packs.filter(concept => {
        let facFilter = true, gender = true, age = true;
        if(concept.facilities && selectedFitlers && selectedFitlers.facility && selectedFitlers.facility.length > 0) {
          facFilter = concept.facilities.find(a => selectedFitlers.facility.some(b => b.key === a.facilityId))
          // return facFilter;
        }
        if (!concept.filters)
          return true;
        let canAgeFilter = (concept.filters.hasOwnProperty('minAge') && concept.filters.hasOwnProperty('maxAge'))
        if (concept.filters.hasOwnProperty('gender') && selectedFitlers.gender && selectedFitlers.gender.length > 0) {
          gender = concept.filters.gender == 'A' || selectedFitlers.gender.find(g => g.key == concept.filters.gender)
        }
        if (canAgeFilter && selectedFitlers.age && selectedFitlers.age.length > 0) {
          age = selectedFitlers.age.find(a => concept.filters.minAge <= a.range.max && concept.filters.maxAge >= a.range.min)
        }
        return gender && age && facFilter
      })
      return filteredPacks;
    }else{
      let selectedFitlers = applicableFilter.reduce((filter, checked) => {
        filter[checked['type']] = checked.options.filter(opt => opt.checked);
        return filter;
      }, {})
      let filteredPacks = packs.filter(concept => {
        if (!concept.filters) return true;
        let canAgeFilter = (concept.filters.hasOwnProperty('minAge') && concept.filters.hasOwnProperty('maxAge'))
        let gender = true, age = true;
        if (concept.filters.hasOwnProperty('gender') && selectedFitlers.gender && selectedFitlers.gender.length > 0) {
          gender = concept.filters.gender == 'A' || selectedFitlers.gender.find(g => g.key == concept.filters.gender)
        }
        if (canAgeFilter && selectedFitlers.age && selectedFitlers.age.length > 0) {
          age = selectedFitlers.age.find(a => concept.filters.minAge <= a.range.max && concept.filters.maxAge >= a.range.min)
        }
        return gender && age
      })
      return filteredPacks;
    }
  }

}
