import { Component, OnInit, EventEmitter, ViewChild, ComponentFactoryResolver, Input, Output } from '@angular/core';
import { BookappointmentDirective } from '../directive/bookappointment.directive';
import { inOutData, flowType } from '../app.type';
import { visitType, apptFlowType } from '../app.type';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { ExpressClinicFacilityComponent } from '../express-clinic-facility/express-clinic-facility.component';
import { ExpressClinicServiceComponent } from '../express-clinic-service/express-clinic-service.component';
import { ExpressClinicHomeComponent } from '../express-clinic-home/express-clinic-home.component';
import { ExpressClinicPatientComponent } from '../express-clinic-patient/express-clinic-patient.component';
import { ExpressClinicChooseSlotComponent } from '../express-clinic-choose-slot/express-clinic-choose-slot.component';
import { ExpressClinicAppointmentConfirmComponent } from '../express-clinic-appointment-confirm/express-clinic-appointment-confirm.component';
import { PaymentService } from '../services/payment.service';
import { ExpressClinicAppointmentReviewComponent } from '../express-clinic-appointment-review/express-clinic-appointment-review.component';
import { ExpressClinicPractitionerComponent } from '../express-clinic-practitioner/express-clinic-practitioner.component';
import { COUPON_PARAMS , PROMO_PARAMS } from '../app.const';
import { Subject } from "rxjs";
import { GoogleTagsService } from '../services/google-tags.service';
import * as _ from 'lodash';
import { EXPRESS_CARE_TERMS_COND } from '@string';
import { EventsService } from '../services/events.service';
@Component({
  selector: 'app-express-clinic-modal',
  templateUrl: './express-clinic-modal.component.html',
  styleUrls: ['./express-clinic-modal.component.scss'],
  providers: [PartitionerDetailsPipe, EventsService]
})
export class ExpressClinicModalComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  @Output() completeAppt: EventEmitter<any>;
  @Input() orderdetails: any;
  @Input() rescheduleData: any;
  @Input() patient: any;
  @Input() isPatientTask: boolean;
  refresh: Subject<boolean> = new Subject<boolean>();
  refreshFlip: Subject<Object> = new Subject<Object>();
  @ViewChild(BookappointmentDirective, { static: true }) bookAppoinment: BookappointmentDirective;
  public _index: number;
  public currentTimeline: Array<any>;
  public bookingAttributes: Object;
  public isManageIdentities: boolean;
  public isCouponCode: boolean = false;
  public couponList: Array<any>;
  public couponCode: string;
  public practDetails: Array<any>;
  public totalServiceTime: any;
  public visitType: visitType;
  public paymentCheckout: boolean;
  public tandc: any;
  public showTC: boolean;
  public showFaq: boolean;
  public faq: any;
  public flowType: apptFlowType;
  public currency_code: any;
  public isMultiplePractitionerAvailable: boolean;
  public maxServiceTime: number;
  public multiplePractDetails: Array<any>;

  public couponData: any;
  public promoData: any;
  public couponParams :any = COUPON_PARAMS;
  public promoParam :any;
  public isCouponType : any;
  public promoInputs: any;
  public couponInputs: any;
  public isRegisterPatient: boolean = false;
  public flow: Array<flowType> = [
    {
      name: "Home",
      code: "HOME",
      component: ExpressClinicHomeComponent,
      fullfillment: (event: any) => {
        return event['home'] ? 1 : 0
      },
      attribute: 'home',
      timeLineView: false,
      header: 'express_clinic_head_home'
    },
    {
      name: "Select Patient",
      code: "PATSEL",
      component: ExpressClinicPatientComponent,
      gtmEvent:'select_person',
      fullfillment: (event: any) => {
        return event['patient']['name'] ? 2 : 1
      },
      attribute: 'patient',
      timeLineView: true,
      header: 'express_clinic_head_patient'
    }, {
      name: "Search Facility",
      code: "FACSEARCH",
      component: ExpressClinicFacilityComponent,
      gtmEvent:'select_facility',
      fullfillment: (event: any) => {
        return event['facility']['name'] ? 3 : 2
      },
      attribute: 'facility',
      timeLineView: true,
      header: 'express_clinic_head_clinic'
    }, {
      name: "Search Practitioner",
      code: "PARTSEARCH",
      component: ExpressClinicPractitionerComponent,
      gtmEvent:'choose_practitioner',
      fullfillment: (event: any) => {
        return event['practitioner']['name'] ? 4 : 3
      },
      attribute: 'practitioner',
      timeLineView: true,
      header: 'express_clinic_head_practitioner'
    },
    {
      name: "search service",
      code: "SERVSEARCH",
      component: ExpressClinicServiceComponent,
      gtmEvent:'choose_services',
      fullfillment: (event: any) => {
        this.totalServiceTime = moment.duration(event['service']['totalTime'], 'minutes');
        return event['service']['name'] ? 5 : 4
      },
      attribute: 'service',
      timeLineView: true,
      header: 'express_clinic_head_service'
    },
    {
      name: "Choose Slot",
      code: "SLOTCHOSE",
      component: ExpressClinicChooseSlotComponent,
      gtmEvent:'select_date_time',
      fullfillment: (event: any) => {
        return event['slot']['name'] ? 6 : 5
      },
      attribute: 'slot',
      timeLineView: true,
      header: 'express_clinic_head_slot'
    },
    {
      name: "Appointment Confirm",
      code: "CONFAPPT",
      component: ExpressClinicAppointmentConfirmComponent,
      gtmEvent:'payment',
      isEditable: () => {
        return false;
      },
      fullfillment: (event: any) => {
        return event['confirm'] ? 7 : 6
      },
      attribute: 'confirm',
      timeLineView: true,
      header: 'express_clinic_head_confirm'
    }, {
      name: "Appointment Review",
      code: "APPTRVW",
      component: ExpressClinicAppointmentReviewComponent,
      fullfillment: (event: any) => {
        return -1
      },
      timeLineView: false,
      header: 'express_clinic_head_review'
    }
  ]
  public timeline = [
    {
      name: "Select Patient",
      attribute: 'patient',
      index: 1,
      translate: false,
      placeholder: "select_patient",
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/user_white.svg',
        inactive: 'assets/icons/bookappt/user_grey.svg',
        current: 'assets/icons/bookappt/user_grey.svg'
      }
    },
    {
      name: "Search Facility",
      attribute: 'facility',
      index: 2,
      translate: 'LOCALE',
      placeholder: 'select_facility',
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/pin_white.svg',
        inactive: 'assets/icons/bookappt/pin_grey.svg',
        current: 'assets/icons/bookappt/pin_grey.svg'
      }
    }, {
      name: "Search Practitioner",
      attribute: 'practitioner',
      translate: 'LOCALE',
      index: 3,
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/doctor_white.svg',
        inactive: 'assets/icons/bookappt/doctor_grey.svg',
        current: 'assets/icons/bookappt/doctor_grey.svg'
      }
    }, {
      name: "search service",
      attribute: 'service',
      index: 4,
      translate: false,
      placeholder: 'choose_services',
      timeLineView: () => {
        if (this.flowType == 'RESCHEDULE') {
          return false;
        } else {
          return true
        }
      },
      icon: {
        active: 'assets/icons/bookappt/doctor_white.svg',
        inactive: 'assets/icons/bookappt/doctor_grey.svg',
        current: 'assets/icons/bookappt/doctor_grey.svg'
      }
    },
    {
      name: "Choose Slot",
      attribute: 'slot',
      translate: false,
      placeholder: 'select_slot',
      index: 5,
      timeLineView: () => {
        return true;
      },
      icon: {
        active: 'assets/icons/bookappt/clock_white.svg',
        inactive: 'assets/icons/bookappt/clock_grey.svg',
        current: 'assets/icons/bookappt/clock_grey.svg'
      }
    },
    {
      name: "Appointment Confirm",
      attribute: 'confirm',
      index: 6,
      translate: "LABELS",
      placeholder: "Confirm appointment",
      timeLineView: () => {
        return true;
      },
      icon: {
        active: 'assets/icons/bookappt/tick_white.svg',
        inactive: 'assets/icons/bookappt/tick_grey.svg',
        current: 'assets/icons/bookappt/tick_grey.svg'
      }
    }
  ]
  public isPatientSwap: number = -1;

  constructor(private cfr: ComponentFactoryResolver, private user: UserService, private paymentService: PaymentService, private gtmService:GoogleTagsService, private events:EventsService) {
    this.close = new EventEmitter<any>();
    this.completeAppt = new EventEmitter<any>();
    this._index = 0;
    this.currentTimeline = this.timeline;
    this.bookingAttributes = {};
    this.isCouponCode = false;
    this.visitType = 'CLINIC';
    this.paymentCheckout = false;
    this.isMultiplePractitionerAvailable = false;
    console.log("currentTimeLine", this.currentTimeline);
    this.user.getConfigurationInfo.subscribe(data => {
      if(data['pxAppSettings']) {
       this.maxServiceTime = data['pxAppSettings']['expressClinicMaxApptDur'];
      }
    })
  }

  ngOnInit() {
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.constructResponseObject();
    this.precheckComponent();
    this.user.getUpdateInfo('directives').subscribe(data => {
      if(data && data['userDefined']) {
        this.tandc = data.userDefined.find(def => {
          return def.code == 'APPTTS';
        }).concepts[0].detail;
        if (data.userDefined.find(def => { return def.code == 'help' })) {
          this.faq = data.userDefined.find(def => {
            return def.code == 'help';
          }).concepts[0].detail;
        }
      }
    });
  }

  private constructResponseObject() {
    this.flow.forEach((item, index) => {
      this.bookingAttributes[item['attribute']] = null;
    });
  }

  private precheckComponent() {
    if (this.orderdetails) {
      this.checkOrderDetails();
    }
    else if (this.rescheduleData) {
      this.flowType = 'RESCHEDULE';
      this.initRescheduleData();
      this._index = 5;
      this.loadComponent(this._index);
    } else if(this.isPatientTask) {
      this.bookingAttributes['home'] = {};
      this.bookingAttributes['patient'] = this.patient;
      this._index = 2;
      this.loadComponent(this._index);
    }
    else
      this.loadComponent(this._index);
  }

  public calculateAmount(services) {
    if (services.length)
      return services.map(service => service.details.servicecost).reduce((prev, next) => parseFloat(prev) + parseFloat(next));
    return 0;
  }

  public calculateTotalTime(services) {
    let totalTime = 0;
    services.forEach(service => {
      if (service['details']['servicetimehm'] == 'H')
        totalTime = totalTime + (parseInt(service['details']['servicetime']) * 60);
      else if (service['details']['servicetimehm'] == 'M')
        totalTime = totalTime + parseInt(service['details']['servicetime']);
    })
    if(totalTime < this.maxServiceTime)
      return totalTime;
    else
      return this.maxServiceTime;
  }

  public initRescheduleData() {
    let practitionerId = this.rescheduleData.resourceId;
    let facilityId = this.rescheduleData.facilityId;
    let patientUhid = this.rescheduleData.uhid;
    let patient = this.user.findLinkedPatient(patientUhid);
    let servicesToOrder = this.rescheduleData.resourceDetail.servicesToOrder;
    this.bookingAttributes['patient'] = {
      id: patient.uhid,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    let practitioner = this.user.findPractitioner(facilityId, practitionerId);
    this.bookingAttributes['practitioner'] = {
      practitionerId: practitioner.resourceId,
      name: practitioner.resourceName
    }
    let speciality = this.user.findSpeciality(facilityId, practitioner.specialityId);
    this.bookingAttributes['inputService'] = {
      practDetails: [{
        practitionerId: practitioner.resourceId,
        practitionerName: practitioner.resourceName,
        photoUrl: practitioner.resourceAvatarURL,
        speciality_details: [{
          code: practitioner.specialityId,
          desc: practitioner.specialityDesc
        }],
        specialityName: speciality ? speciality['specialityName'] : ''
      }]
    }
    let services = [];
    servicesToOrder.forEach(service => {
      if (service['concepts'].length) {
        service['concepts'].forEach(concept => {
          let obj = {
            directiveCode: service['directiveCode'],
            conceptCode: concept['code'],
            conceptName: concept['desc'],
            directiveName: service['directiveDesc'],
            details: {
              servicetime: concept['serviceTime'],
              servicetimehm: concept['serviceHm'],
              servicecost: concept['serviceCost']
            }
          }
          services.push(obj);
        })
      }
    })
    this.bookingAttributes['service'] = {
      services: services,
      name: services.length + ' Services',
      totalTime: this.calculateTotalTime(services),
      totalAmount: this.calculateAmount(services)
    }
    this.bookingAttributes['facility'] = {
      address: '',
      clinicCode: this.rescheduleData.clinicCode,
      distance: null,
      facilityId: facilityId,
      facilityLocation: null,
      name: '',
      position: null
    }
    this.bookingAttributes['exist'] = {
      slot: {
        date: this.user.getMoment(this.rescheduleData.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
        slot: this.user.getMoment(this.rescheduleData.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        facilityId: facilityId
      },
      payment: {
        ...this.rescheduleData.paymentDetail
      },
      apptRefNo: this.rescheduleData.appointmentId
    }
    this.currentTimeline = this.timeline.filter(t => {
      return t.timeLineView();
    })
    console.log(this.rescheduleData);
  }

  private checkOrderDetails() {
    let orderData = atob(this.orderdetails);
    let arr = orderData.split('&');
    let params = {};
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    this.paymentService.getTransactionDetail(params['orderid']).subscribe(data => {
      console.log("data return from payemnt->", data);
      if (data.status != 'S') {
        if(data && data.transactionRequest && data.transactionRequest.blockId) {
          let payload = {
            facilityId: data.facilityId,
            blockId: data.transactionRequest.blockId,
          }
          this.initUnBlockSlot(payload);
        }
      }
      this.generateData(params, data);
    })
  }

  private generateData(params, transaction) {
    console.log("params resent->", params);
    let resourceDetails = transaction['resourceDtls'];
    this.bookingAttributes = {};
    this.bookingAttributes['facility'] = {
      address: resourceDetails['facility']['address'],
      clinicCode: resourceDetails['facility']['clinicCode'],
      distance: resourceDetails['facility']['distance'],
      facilityId: resourceDetails['facility']['facilityId'],
      facilityLocation: { lat: resourceDetails['facility']['facilityLocation'].split(',')[0], lng: resourceDetails['facility']['facilityLocation'].split(',')[1] },
      name: resourceDetails['facility']['name'],
      position: { lat: resourceDetails['facility']['position'].split(',')[0], lng: resourceDetails['facility']['position'].split(',')[1] }
    }
    //Service Details
    let totalTime = this.calculateTotalTime(resourceDetails['service'])
    this.bookingAttributes['service'] = {
      services: resourceDetails['service'],
      name: resourceDetails['service'].length + ' Services',
      totalTime: totalTime,
      totalAmount: this.calculateAmount(resourceDetails['service'])
    }
    //Slot Details
    let time1 = resourceDetails['slot']['name'].split(' ')[0].trim();
    let fromTime = this.user.getMoment(time1, 'LT').locale(this.user.currentLocale).format('LT').toLowerCase();
    let toTime = this.user.getMoment(time1, 'LT').add(totalTime, 'm').locale(this.user.currentLocale).format('LT').toLowerCase();
    let date = this.user.getMoment(resourceDetails['slot']['date'], 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    this.bookingAttributes['slot'] = {
      name: resourceDetails['slot']['name'],
      date: date,
      slot: {
        slot: this.user.getMoment(resourceDetails['slot']['date'], 'YYYY-MM-DD HH:mm').format('HH:mm')
      },
      fromTime: fromTime,
      toTime: toTime,
      fromDate: `${date} ${fromTime}`,
      toDate: `${date} ${toTime}`,
      clinicCode: resourceDetails['facility']['clinicCode'],
      slotsNeeded: resourceDetails['slot']['slotsNeeded'],
    }
    //pract details
    let practitioner = this.user.findPractitioner(resourceDetails['facility']['facilityId'], resourceDetails['practId']);
    this.bookingAttributes['practitioner'] = {
      practitionerId: practitioner.resourceId,
      name: practitioner.resourceName
    }
    let speciality = this.user.findSpeciality(resourceDetails['facility']['facilityId'], practitioner.specialityId);
    console.log('resourceDetails details pract->', practitioner);
    this.bookingAttributes['inputService'] = {
      practDetails: [{
        practitionerId: practitioner.resourceId,
        practitionerName: practitioner.resourceName,
        photoUrl: practitioner.resourceAvatarURL,
        speciality_details: [{
          code: practitioner.specialityId,
          desc: practitioner.specialityDesc
        }],
        specialityName: speciality?speciality['specialityName']:null
      }]
    }
    let patient = this.user.findLinkedPatient(resourceDetails['patientId']);
    this.bookingAttributes['patient'] = {
      id: patient.uhid,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    if (transaction.status == 'S') {
      this.bookingAttributes['confirm'] = {
        name: 'pay_success',
        status: 'S',
        amount: transaction['response']['amount'],
        currency: transaction['currency'],
        category: 'CASH',
        medium: 'ONLINE',
        gatewayRefId: transaction['gatewayRefId'],
        transactionId: transaction['transactionId'],
        bankRefId: transaction['bankRefId'],
        apptRefNo: transaction['response']['apptRefNumber'],
      }
    } else {
      this.bookingAttributes['confirm'] = {
        name: 'pay_failed',
        status: 'F',
        amount: null,
        currency: null,
        category: 'CASH',
        medium: 'ONLINE',
        gatewayRefId: transaction['gatewayRefId'],
        transactionId: transaction['transactionId'],
        bankRefId: transaction['bankRefId'],
        retry: {
          flowType: params['c'],
          freeFollowUpYn: params['ff'] ? params['ff'] : null,
          payType: params['pt'],
          discount: params['dis'] ? params['dis'] : null,
          cost: params['cost'],
          sourceRequest: resourceDetails['sourceRequest']
        },
      }
    }
    this.flowType = !params['c'] || params['c'] == 'B' ? 'BOOKING' : 'RESCHEDULE';
    if (transaction.status == 'S') {
      let obj = {
        value: +transaction['response']['amount'],
        booking_no: transaction['response']['apptRefNumber'],
        
      };
      let eev = 'appointment_confirmation',
        funnel_name = 'Express Clinics';
      this.gtmService.pushGTMEvent(funnel_name, eev, obj);
    }
    this._index = 7;
    this.loadComponent(this._index);
    console.log('transaction details->', this.bookingAttributes);
  }

  public navBack() {
    if(this._index == 0) return
    if(this.isPatientSwap != -1) {
      this.loadComponent(this.isPatientSwap);
      this._index = _.cloneDeep(this.isPatientSwap);
      this.isPatientSwap = -1;
      return;
    }
    this._index -= 1;
    if (this._index == 3) {
      if (!this.isMultiplePractitionerAvailable) {
        this._index -= 1;
      } else {
        this.bookingAttributes['inputService']['practDetails'] = this.multiplePractDetails;
      }
      
    }
    this.loadComponent(this._index)
  }

  public loadComponent(index: number, msg?: any) {
    let componentFactory = this.cfr.resolveComponentFactory(this.flow[index]['component']);
    let viewContainerRef = this.bookAppoinment.vcr;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<ExBookApptComponent>componentRef.instance)._input = <inOutData>{ in: this.bookingAttributes };
    (<ExBookApptComponent>componentRef.instance).visitType = this.visitType;
    (<ExBookApptComponent>componentRef.instance).flowType = this.flowType;
    (<ExBookApptComponent>componentRef.instance).msg = msg;
    (<ExBookApptComponent>componentRef.instance).couponData = this.couponData;
    (<ExBookApptComponent>componentRef.instance).promoData = this.promoData;
    (<ExBookApptComponent>componentRef.instance).complete.subscribe((event: inOutData['out']) => {
      Object.assign(this.bookingAttributes, event);
      console.log('complete session->', this.bookingAttributes);
      this._index = this.flow[index]['fullfillment'](event);
      console.log(" after complete index->", this._index);
      if(this.isPatientSwap != -1) {
        this.loadComponent(this.isPatientSwap);
        this._index = _.cloneDeep(this.isPatientSwap);
        this.isPatientSwap = -1;
        return;
      }
      if (this._index == 3) {
        this.user.getClinicInfo(event['facility']['facilityId'], event['facility']['clinicCode'], this.bookingAttributes['home']).subscribe(data => {
          this.bookingAttributes['inputService'] = data['data'];
          this.practDetails = data['data']['practDetails'];
          this.practDetails.map( (pract,index) => {
            let practitioner = this.user.findPractitioner(this.bookingAttributes['facility']['facilityId'], pract['practitionerId']);
            let speciality = this.user.findSpeciality(this.bookingAttributes['facility']['facilityId'], pract['speciality_details'][0]['code']);
            console.log("pract and speciality Input Details->",this.bookingAttributes['facility']['facilityId'], pract['practitionerId'],pract['speciality_details'][0]['code']);
            console.log("pract and speciality Details->",practitioner,speciality);
            this.bookingAttributes['inputService']['practDetails'][index]['practitionerName'] = practitioner ? practitioner['resourceName'] : pract['practitionerName'];
            this.bookingAttributes['inputService']['practDetails'][index]['specialityName'] = speciality ? speciality['specialityName'] : pract['speciality_details'][0]['desc'];
          });
          this.gtmService.pushGTMEvent('Express Clinics', 'choose_practitioner', {'practitioners':this.practDetails.length})
          if(this.practDetails.length == 1) {
            this.bookingAttributes['practitioner'] = {
              practitionerId: this.bookingAttributes['inputService']['practDetails'][0]['practitionerId'],
              name: this.bookingAttributes['inputService']['practDetails'][0]['practitionerName']
            }
            this.isMultiplePractitionerAvailable = false;
            this._index = this._index + 1;
            this.loadComponent(this._index)
            this.gtmService.pushGTMEvent('Express Clinics', 'step_option_choose_practitioner', {'practitioner':this.bookingAttributes['practitioner']['name'][0]['name']});
          } else {
            this.isMultiplePractitionerAvailable = true;
            this.multiplePractDetails = this.bookingAttributes['inputService']['practDetails'];
            this.loadComponent(this._index);
          }
        })
        console.log('complete session with practDetails->', this.bookingAttributes);
      }
      if(this._index == 4 && this.isMultiplePractitionerAvailable) {
        let practId = event['practitioner']['practitionerId'];
        this.bookingAttributes['inputService']['practDetails'] = this.bookingAttributes['inputService']['practDetails']
        .filter(pract=>{
          return pract['practitionerId'] == practId;
        })
      }

      if (this.flow[index]['fullfillment'](event) != index && this.flowType !=='RESCHEDULE')
        this.updateGTMEvents(index, event);
      
      if (this._index == -1) {
        this.closeModal();
      } else if(this.isPatientTask && this._index === 7){
        this.completeAppt.emit(event['confirm']);
      } else if (this._index != 3) {
        this.loadComponent(this._index);
      }
    });
    (<ExBookApptComponent>componentRef.instance).action.subscribe((event: any) => {
      if (event.name == 'isManageIdentities') {
        this.isManageIdentities = true;
      }
      else if(event.name == 'registerpatient')
        this.isRegisterPatient = true;
      else if(event.name == 'change-facility') {
        this._index-=1;
        this.loadComponent(this._index);
      }
      else if (event.name == 'openCoupon') {
        this.promoData = event.promoData;
        this.couponData = event.couponData;
        this.couponParams = COUPON_PARAMS;
        this.couponInputs = event.couponInput;
        this.isCouponType = true;
        this.isCouponCode = true;
        // this.refresh.next(true);
        this.refreshFlip.next({ 
          'enable': true,
          'type': 'COUPON',
          "appliedcouponcodedtls": this.couponData.appliedcouponcodedtls
        });
      }
      else if (event.name == 'openPromo') {
        this.couponData = event.couponData;
        this.promoData = event.promoData;
        this.couponParams = PROMO_PARAMS;
        this.promoInputs = event.promoInput;
        this.couponInputs = event.couponInput;
        this.isCouponType = false;
        this.isCouponCode = true;
        // this.refresh.next(true);
        this.refreshFlip.next({ 
          'enable': true,
          'type': 'PROMO',
        });
      }
      
      else if (event.name == 'openPaymentCheckout') {
        this.paymentCheckout = true;
        this.removeComponent();
      } else if (event.name == 'opentc') {
        this.showTC = true;
      } else if (event.name == 'openfaq') {
        this.showFaq = true;
      } else if (event.name == 'select_patient') {
        this.isPatientSwap = _.cloneDeep(this._index);
        this._index = 1;
        this.loadComponent(this._index)
      }
      else if(event.name == 'choose_slot') {
        this._index = 5;
        this.loadComponent(this._index)
      }
    })

    if (this.flow[index]['gtmEvent'] && this.flowType !=='RESCHEDULE')
      this.gtmService.pushGTMEvent('Express Clinics', this.flow[index]['gtmEvent']);
  }

  public getCouponList() {
    this.couponList = [{
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }, {
      code: 'NFJHSF1',
      cost: 30,
      valid: 'DEC 2020'
    }]
  }

  public closetc(status: boolean) {
    if (status) {
      this.events.broadcast(EXPRESS_CARE_TERMS_COND);
    }
    this.events.off(EXPRESS_CARE_TERMS_COND, null, true);
    this.showTC = false;
  }

  public closeFaq() {
    this.showFaq = false;
  }

  public removeComponent() {
    let viewContainerRef = this.bookAppoinment.vcr;
    viewContainerRef.clear();
  }

  public closeModal() {
    this.user.clearCoupons();
    this.close.emit('CLOSE');
  }

  public changeSelection(index) {
    if (this._index > 6 || this._index < index || (this.isPatientTask && index === 1)) {
      return;
    } else if(index == 3) {
      if(this.isMultiplePractitionerAvailable) {
        this.bookingAttributes['inputService']['practDetails'] = this.multiplePractDetails;
        this._index = index;
        this.loadComponent(this._index);
      }
    } else {
      this._index = index;
      this.loadComponent(this._index);
    }
  }

  public applyCoupon() {
    this.bookingAttributes['coupon'] = this.couponCode;
    this.loadComponent(this._index);
    this.isCouponCode = false;
  }
  public closeManageIdentity() {
    this._index = this._index;
    this.loadComponent(this._index);
    this.isManageIdentities = false;
  }

  public closeCouponCode() {
    this._index = this._index;
    this.loadComponent(this._index);
    this.isCouponCode = false;
  }
  public closePayemntCheckout() {
    this.paymentCheckout = false;
    console.log("closepaymentModal");
    this.initUnBlockSlot();
    this.closeModal();
  }
  public closeCouponModal(event: any) {
    console.log("closeCoupon",  event)
    // if(event.data){
    //   this.couponData = event.data;
    // }
    if(event){
      if(event.type == 'COUPON' && event.data){
        this.couponData = event.data;
      }
      else  if(event.type == 'PROMO' && event.data){
        this.promoData = event.data;
      }
    }else{
      this.couponData = null;
    }
    this._index = this._index;
    this.loadComponent(this._index);
    this.isCouponCode = false;
  }

  public updateGTMEvents(index, event) {
    console.log(index);
    console.table(event);
    if (this.flowType == 'RESCHEDULE')
      return;
    let obj = {},
      funnel_name ='Express Clinics',
      gtmevent = '';

    switch (index) {
      case 1:
        if (event['patient'] && event['patient']['id']) {          
          gtmevent = 'step_option_select_person';
          obj['patientId'] = event['patient']['id'];
          obj['patient_type'] = 'Existing';
        }
        break;
      case 3:
        if (event['practitioner'] && event['practitioner']['name']) {          
          gtmevent = 'step_option_choose_practitioner';
          obj['practitioner'] = event['practitioner']['name'][0]['name'];
        }
        break;
      case 7:
        if (event['confirm']) {          
          gtmevent = 'appointment_confirmation';
          obj['practitioner'] = event['practitioner']['name'][0]['name'];
        }
        break;
      default:
        break;
    }
    console.log(funnel_name, gtmevent, obj);
    if (gtmevent)
      this.gtmService.pushGTMEvent(funnel_name, gtmevent, obj);
  }
  public closeRegPatient(event) {
    this.isRegisterPatient = false
    if(event?.patientId)
      this.loadComponent(this._index);
  }

  public onAgree() {
    this.closetc(true);
  }

  public initUnBlockSlot(data?) {
    let appointment = this.paymentService.getAppointmentInfo()
    let payload;
    if(data) {
      payload = data;
    } else {
       payload = {
        facilityId: appointment.facilityId,
        blockId: appointment.blockId,
      }
    }
    if (payload && payload.blockId) {
      this.paymentService.unBlockSlot(payload).subscribe(data => {
        console.log('unblock slot', data)
      })
    }
  }
}

export interface ExBookApptComponent {
  _input?: inOutData;
  complete?: EventEmitter<Object>;
  action?: EventEmitter<Object>;
  visitType?: visitType;
  msg?: any;
  flowType?: apptFlowType;
  couponData?:inOutData;
  promoData?:inOutData;
}
