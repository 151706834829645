import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpcomingAppointmentComponent } from './upcoming-appointment.component';
import {PipesModule} from '../pipes/pipes.module';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import {TimelineModule} from '../timeline/timeline.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { NodataModule } from '../nodata/nodata.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { AppointmentUploadQuestionnarieModule } from '../appointment-upload-questionnarie/appointment-upload-questionnarie.module';
import { HrDocumentUploadModule } from '../hr-document-upload/hr-document-upload.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module';
import { HomeCareServicesModule } from '../home-care-services/home-care-services.module';
import { DirectiveModule } from '../directive/directive.module';
import { MapModule } from '../map/map.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';
@NgModule({
  declarations: [UpcomingAppointmentComponent],
  imports: [
    CommonModule,PipesModule, UpcomingAppointmentDetailModule,
    TimelineModule,
    CustomModalModule, BookAppointmentModule,
    PersonAvatarModule,
    NodataModule, RegisterPatientModule, AppointmentUploadQuestionnarieModule, HrDocumentUploadModule,
    PractitionerAvatarModule, ExpressClinicModalModule, DirectiveModule, MapModule, FullCalendarModule, HomeCareServicesModule, RegisterNewPatientModule, CalendarModule, FormsModule, ApptTimeModule
  ],
  exports: [UpcomingAppointmentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class UpcomingAppointmentModule { }
