import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import {OnspotRequestAcceptComponent} from './onspot-request-accept.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import {PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { FormsModule } from '@angular/forms';
import {CouponModule} from '../coupon/coupon.module';
import { CouponModalModule } from '../coupon-modal/coupon-modal.module';
import { AltpaymentInputModule } from '../components/altpayment-input/altpayment-input.module';
import { PromoInputModule } from '../components/promo-input/promo-input.module';
import { DisplayCurrencyModule } from '../components/display-currency/display-currency.module';
import { VideoConsultTCModule } from '../video-consult-tc/video-consult-tc.module';
@NgModule({
  declarations: [OnspotRequestAcceptComponent],
  imports: [
    CommonModule, PipesModule,
     CustomModalModule,
     PersonAvatarModule,
     PractitionerAvatarModule,
     FormsModule,
     CouponModule, CouponModalModule, AltpaymentInputModule, PromoInputModule, DisplayCurrencyModule, VideoConsultTCModule
  ],
  exports: [
    OnspotRequestAcceptComponent
  ]
})
export class OnspotRequestAcceptModule { }
