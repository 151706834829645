import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, start: number = 2, end: number = 1): string {
    const first = value.substring(0, start);
    const last = value.substring(value.length - end);

    const mask = value.substring(start, value.length - end).replace(/./g, "*");
    return first + mask + last;
  }

}
