<div id="graph-component">
  <ng-container *ngFor="let event of reportEvents">
    <div class="card-layout" *ngIf="event['Event History']">
      <div class="card">
        <div class="header">
          <div class="title">{{event.Test}}</div>
          <div class="stats">
            <div>
              <span class="font-danger-default" *ngIf="event['Normalcy Ind']=='H'">{{'high'|translate:'LABELS'}}</span>
              <span class="font-danger-default" *ngIf="event['Normalcy Ind']=='L'">{{'low'|translate:'LABELS'}}</span>
              <span class="font-success-default"
                *ngIf="event['Normalcy Ind']!='L'&&event['Normalcy Ind']!='H'">{{'normal'|translate:'LABELS'}}</span>
            </div>
            <div class="font-light-small">{{event['Range']}}</div>
          </div>
        </div>
        <div class="graph">
          <app-graph [eventhistory]="event['Event History']" [event]="event"></app-graph>
        </div>
      </div>
    </div>
  </ng-container>
</div>

