import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { FooterModule } from '../footer/footer.module';
import { DirectiveModule } from '../directive/directive.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PartitionInputModule } from '../partition-input/partition-input.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { UnauthorizedPageModule } from '../unauthorized-page/unauthorized-page.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, PipesModule, PhoneInputModule,
    FormInputErrorModule, FooterModule, DirectiveModule, PartitionInputModule, RegisterPatientModule, UnauthorizedPageModule, PersonAvatarModule, RecaptchaModule, RecaptchaFormsModule, RegisterNewPatientModule
  ],
  exports: [LoginComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginModule { }
