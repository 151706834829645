<div id="linked-patient">
  <!-- <div class="patient-title bold">{{'select_a_patient' | translate:'LABELS'}}</div> -->
  <div class="cards">
    <div class="card-layout" *ngFor="let item of linkedPatients" (click)="selectCard(item, $event);">
      <div class="card row cursor-pointer" [ngClass]="{'selected': selectedPatient === item}">
        <div class="custom-radio__group">
          <input type="radio" name="selected-patient" class="custom-radio__input custom-radio__radio-input"
            [checked]="selectedPatient === item">
          <span class="custom-radio__button"></span>
        </div>
        <div class="image">
          <app-person-avatar [url]="item.imageurl" [additionalClass]="'circular'"> </app-person-avatar>
        </div>
        <div class="card-content">
          <div class="main-title bold">{{item.personname}}</div>
          <div class="subtitle">
            <span class="gender">{{(item.gender === 'F' ? 'Female, ' : 'Male, ') | translate}}</span>
            <span class="age">{{item.age}}{{' Y, ' | translate}}</span>
            <span class="relationship">{{item.relationshiptype | translate : 'RELATION'}}{{', ' | translate}}</span>
            <span class="uhid">{{item.uhid}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-layout">
      <div class="card row cursor-pointer no-padding" (click)="openModal('isManageIdentities')">
        <div class="image manage-img">
          <app-person-avatar [url]="'assets/icons/group_notify.svg'" [additionalClass]="'circular'"></app-person-avatar>
        </div>
        <div class="card-content">
          <div class="title main-title bold">{{'manage_identities' | translate:'LABELS'}}</div>
          <div class="subtitle">{{'onspot_link_or_reg_patient' | translate:'LABELS'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <button class="notify-button-inverted btn-customize" (click)="openModal('register-patient')">
    {{'Register_new_patient'|translate}}
  </button>
  <button class="notify-button btn-customize" [disabled]="!selectedPatient"
    (click)="completeSelection(selectedPatient)">
    {{'Continue'|translate}}
  </button>
</div>
