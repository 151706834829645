import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './section.component';
import { SectionDirective } from './section.directive';
import { TypeModule } from './type/type.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SectionPreviewModule } from '../section-preview/section-preview.module';


@NgModule({
  declarations: [SectionComponent, SectionDirective],
  imports: [
    CommonModule, TypeModule, SignaturePadModule, SectionPreviewModule
  ], exports: [
    SectionComponent
  ],schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
})
export class SectionModule { }
