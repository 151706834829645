<div id="search-clinic">
  <div class="search-section">
    <div class="search-bar">
      <div class="search" id="search-static">
        <img class="type-image" src="./assets/icons/Book_new/search.svg">
        <input type="text" [(ngModel)]="searchKey" placeholder="{{'plhd_search_facility'|translate}}">
        <div class="clear-search cursor-pointer" (click)="searchKey=''" *ngIf="searchKey">
          <img src="assets/icons/close_grey.svg" alt="clear-icon" class="clear-icon">
        </div>
        <img src="assets/icons/bookappt/locate_icon.svg" alt="locate-icon" class="my-location" (click)="getCurrentLatLon()">
      </div>
      <div class="select-location" (click)="getCurrentLatLon()">
        <div class="select-location__icon">
          <img src="assets/icons/bookappt/locate_icon.svg" alt="locate-icon" class="select-location__img">
        </div>
        <span class="location-label">{{'Use_current_location' | translate}}</span>
      </div>
    </div>
  </div>
  <div class="title-section">
    <div class="title-desc bold">
      {{'lbl_our_clinics' | translate}}
    </div>
    <div class="list-map-icons">
      <div class="left-icon" [ngClass]="{'selected': isMapOrList == 'LIST'}" (click)="toggle()">
        <img *ngIf="isMapOrList != 'LIST'" class="list" src="assets/icons/List_icon.svg" alt="location-icon">
        <img *ngIf="isMapOrList == 'LIST'" class="list inverted" src="assets/icons/List_icon_white.svg" alt="location-selected-icon">
      </div>
      <div class="right-icon" [ngClass]="{'selected': isMapOrList == 'MAP'}" (click)="toggle()">
        <img *ngIf="isMapOrList != 'MAP'" class="location" src="assets/icons/location_prim.svg" alt="location-icon">
        <img *ngIf="isMapOrList == 'MAP'" class="location inverted" src="assets/icons/location_white.svg" alt="location-selected-icon">
      </div>
    </div>
  </div>
  <div class="map-wrapper" [ngClass]="{hide: isMapOrList != 'MAP'}">
    <div #gmap id="gmap" class="gmap" [ngClass]="{'rotate' : htmlDirection=='rtl'}"></div>
    <div class="facility-map-popover facility-content" id="map-popover" [ngClass]="{hide: !showMapPopover}">
      <div class="facility-details" *ngIf="mapSelectedFacility">
        <div class="facility-content-panel-text">
          <div class="facility-name">{{ mapSelectedFacility['desc'] }}</div>
          <div class="facility-close">
            <img (click)="$event.stopPropagation();showMapPopover = false" src="assets/icons/close_black.svg" alt="close" class="close-icon cursor-pointer">
          </div>
        </div>
        <div class="facility-address">{{ mapSelectedFacility['address'] }}</div>
        <div class="facility-mobile" *ngIf="(mapSelectedFacility && mapSelectedFacility.mobileNo) || careExpressContact">
          <img class="nav-icon type-image" src="assets/icons/clinic/call.svg"
            *ngIf="(mapSelectedFacility && mapSelectedFacility.mobileNo) || careExpressContact">
          <span class="facility-mobile-text">{{(mapSelectedFacility && mapSelectedFacility['mobileNo']) || careExpressContact }}</span>
        </div>
        <div class="facility-time" *ngIf="mapSelectedFacility && mapSelectedFacility.time">
          <img class="nav-icon type-image" src="assets/icons/clinic/clock_prim.svg"
            *ngIf="mapSelectedFacility && mapSelectedFacility.time">
          <span class="facility-time-text">{{mapSelectedFacility['time'] }}</span>
        </div>
        <div class="select-button f-width float-right">
          <button class="primary-button" (click)="completeSelection(mapSelectedFacility)">
            {{'Select_&_Continue' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="facility-wrapper responsive-wrapper" [ngClass]="{hide: isMapOrList != 'LIST'}">
    <div class="facility-details-wrapper">
      <ng-container *ngFor="let facility of facilityList | searchFilter: searchKey: 'desc'; let i=index">
        <div class="facility-content cursor-pointer" id="{{facility['code']}}" 
          [ngClass]="{'last-col': ((i + 1) % 3 === 0), 'selected': facility['selected']}"
          (click)="completeSelection(facility)">
          <div class="facility-details"
            [ngClass]="{'without-mobile': !(facility && facility.mobileNo) && !careExpressContact}">
            <!-- <img class="logo-img type-image" src="assets/images/signin/logo_minimal.png" /> -->
            <div class="facility-content-panel-text">{{ facility['desc'] }}</div>
            <div class="facility-address">{{ facility['address'] }}</div>
            <div class="facility-mobile" *ngIf="(facility && facility.mobileNo) || careExpressContact">{{ (facility && facility['mobileNo']) ||  careExpressContact}}</div>
            <!-- <div class="secondary-color">{{facility['distance']}} {{'lbl_kms'|translate}}</div> -->
          </div>
          <div class="facility-actions"
            [ngClass]="{'without-mobile': !(facility && facility.mobileNo) && !careExpressContact}">
            <div class="icons">
              <img class="nav-icon type-image call-icon" src="assets/icons/bookappt/call_prim_sec.svg"
                *ngIf="(facility && facility.mobileNo) || careExpressContact" (click)="$event.stopPropagation();call(facility && facility['mobileNo'] ? facility['mobileNo']:careExpressContact)">
              <img class="nav-icon type-image last"
                src="assets/icons/bookappt/locate_prim_sec.svg" 
                (click)="$event.stopPropagation();navigateUser(facility['latitude'],facility['longitude'])"/>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="no-data" *ngIf="!facilityList.length">
        <app-nodata></app-nodata>
      </div>
    </div>
  </div>
</div>