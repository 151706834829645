import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  // transform(value: any): any {
  //   return this.user.getMoment().diff(value, 'years', false) + 'Y';
  // }
  constructor(private user: UserService) { }

  transform(value: any): any {
    if (this.user.getMoment().diff(value, 'years', false) == 0) {
      if (this.user.getMoment().diff(value, 'months', false) == 0) {
        return this.user.getMoment().diff(value, 'days', false) + 'D';
        // if (this.user.getMoment().diff(value, 'weeks', false) == 0) {
        //   return this.user.getMoment().diff(value, 'days', false) + 'D';
        // } else return this.user.getMoment().diff(value, 'weeks', false) + 'W';
      } else return this.user.getMoment().diff(value, 'months', false) + 'M';
    } else return this.user.getMoment().diff(value, 'years', false) + ' Y';
  }

}
