import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { GoogleTagsService } from 'src/app/services/google-tags.service';
import { PartitionerDetailsPipe } from '../../pipes/partitioner-details.pipe';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss'],
  providers: [PartitionerDetailsPipe]
})
export class RescheduleComponent implements OnInit {
  public bookingAttributes: any = {};
  public visitType
  public _input
  @Input() appointment: any;
  @Input() patient: any;
  @Output() close: EventEmitter<any>
  public accept: boolean = false;
  public selectedSlot: any;
  public tandc: any;
  public showTC: boolean = false;
  constructor(private user: UserService, private pracDetailsPipe: PartitionerDetailsPipe, private apptService: AppointmentService, private gtmService: GoogleTagsService) { 
    this.close = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.initRescheduleData()
    this.user.getUpdateInfo('directives').subscribe(data => {
      this.tandc = data.userDefined.find(def => {
        return def.code == 'APPTTS';
      }).concepts[0].detail;
    });
  }

  public initRescheduleData() {
    let practitionerId = this.appointment.resourceId;
    let facilityId = this.appointment.facilityId;
    let specialityId = this.pracDetailsPipe.transform(practitionerId, facilityId, 'specialityId');
    let patientUhid = this.appointment.uhid;

    let practitioner = this.user.findPractitioner(facilityId, practitionerId);
    this.bookingAttributes['practitioner'] = {
      id: practitioner.resourceId,
      personId: practitioner.personId,
      name: practitioner.resourceName,
      resourceAvatarURL: practitioner.resourceAvatarURL,
      info: practitioner.additionalInfo['info'],
      availDays: practitioner['schedulerControls']['daysAvailable'],
      schedulerControls: practitioner['schedulerControls'],
      additionalInfo: practitioner['additionalInfo'],
      facList: practitioner['facList']
    }
    let speciality = this.user.findSpeciality(facilityId, specialityId);
    this.bookingAttributes['speciality'] = {
      id: speciality.specialityId,
      code: speciality.sourceSpecialityCode,
      desc: speciality.specialityDesc,
      name: speciality.specialityName,
    }
    this.visitType = (this.appointment.appointmentType == 'VIDEO' || this.appointment.appointmentType == 'video') ? 'VIDEO' : 'HOSPITAL';
    console.log(this.appointment);
    this.bookingAttributes['home'] = { visitType: this.visitType };
    this.bookingAttributes['exist'] = {
      slot: {
        date: this.user.getMoment(this.appointment.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
        slot: this.user.getMoment(this.appointment.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        facilityId: facilityId
      },
      payment: {
        ...this.appointment.paymentDetail
      },
      apptRefNo: this.appointment.appointmentId
    }
    // let patient = this.user.findLinkedPatient(patientUhid);
    this.bookingAttributes['patient'] = {
      id: this.patient.patient_id,
      name: this.patient.patient_name,
      personid: this.appointment.personId,
      gender: this.patient.gender,
      dob: this.patient.dob
    }
    console.log(this.appointment);
    this._input = { in: this.bookingAttributes }
    console.log(this._input)
  }

  public complete(event) {
    console.log(event);
    if (event) {
      this.selectedSlot = event;
    }
  }

  confirmClose(event?) {
    this.close.emit(event)
  }

  public confirmReschedule() {
    const patient = this.bookingAttributes['patient'];
    const practitioner = this.bookingAttributes['practitioner'];
    const speciality = this.bookingAttributes['speciality'];
    const slot = this.selectedSlot['slot'];
    console.log(this._input.in['patient']['personid'], "get reschedule", patient);

    let data = {
      patientPersonId: patient['personid'],
      uhid: patient['id'],
      facilityId: this.appointment.facilityId,
      clinicCode: slot['clinicCode'],
      resourceId: practitioner['id'],
      date: this.user.getMoment(slot['date']).format("YYYY-MM-DD"),
      time: slot['slot'],
      personName: patient['name'],
      dob: this.user.getMoment(patient['dob']).format("YYYY-MM-DD"),
      gender: patient['gender'],
      practitionerName: practitioner['name'],
      specialityName: speciality['name'][0]['name'],
      patientName: patient['name'],
      visitType: this.visitType,
      appointmentNumber: this.appointment.appointmentId,
    }
    this.apptService.rescheduleAppointment(data).subscribe(response => {
      this.gtmService.pushGTMEvent(null, 'appointment_reschedule', { booking_no: this._input.in['exist']['apptRefNo'] });
      this.close.emit(true);
    })
  }
  public opentc() {
    this.showTC = true;
  }

  public closetc() {
    this.showTC = false;
  }
}
