import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from 'src/app/custom-input-group/custom-input-group.module';
import { FormInputErrorModule } from 'src/app/form-input-error/form-input-error.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ManageMyConsentComponent } from './manage-my-consent.component';



@NgModule({
  declarations: [ManageMyConsentComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule
  ],
  exports: [
    ManageMyConsentComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ManageMyConsentModule { }
