import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CouponService } from '../services/coupon.service';
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {

  @Input() set promoInput(value: any) {
    this._promoInput = value;
    this.promoCode = this._promoInput && this._promoInput.promoCode ? this._promoInput.promoCode : "" ;
    if(this.promoCode) this.isPromoApplied = true
    else this.removePromo(); //this.isPromoApplied = false
    // this.getPromo();
  }
  @Input() set couponInput(value: any) {
    this._couponInput = parseFloat(value);
    this.couponBalPatientPayable = this._couponInput;
    // this.getCoupons();
  }
  // @Input() set _couponList(value: any){
  //   this.couponList = value;
  // }
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() couponParam : any; 
  @Input() type : any; 
  @Input() listType: any;
  // @Input() promoInput: any;
  @Output() completeCoupon: EventEmitter<any>;
  @Output() clearPromo: EventEmitter<any>;
  @Output() showExternalCoupon: EventEmitter<any>;
  public couponCode:  string = '';
  public promoCode:  string = '';
  public validatedResponse: any;
  public selectedPromo: any;
  public currency_code:any;
  public isInValidCoupon: boolean = false;
  public isInValidPromo: boolean = false;
  public list;
  public promoList;
  public couponList;
  public selectedCode: any;
  public appliedPromoDtls: any;
  public lang: any;
  public selectedCoupons: Array<any> = [];
  public couponDiscount: any = 0;
  public loyaltyId : string;
  public _promoInput: any;
  public promoInfo;
  public _couponInput: number;
  public couponBalPatientPayable: number;
  public showAvailPromoList: boolean;
  public showNoCoupon: boolean;

  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: string = '';
  public isPromoApplied : boolean;
  public alreadyAppliedCoupons: Array<any> = [];

  constructor(public couponService:CouponService, private user:UserService) { 
    this.completeCoupon = new EventEmitter<any>();
    this.clearPromo = new EventEmitter<any>();
    this.showExternalCoupon = new EventEmitter<any>();
    this.validatedResponse = {}
    this.user.getConfigurationInfo.subscribe(data => {
      this.showAvailPromoList = data['pxAppSettings'] && data['pxAppSettings']['showAvailPromoYn'] == 'Y' ? true: false;
    })
  }

  ngOnInit(): void {
    this.currency_code = this.user.getCurrency();
    this.lang = this.user.currentLanguage;
    if(this.type == 'PROMO' && this.showAvailPromoList){
        this.getPromo();
    }
    if(this.type == 'COUPON'|| this.listType == 'COUPONLIST' ){
      // this.couponList = COUPON //mock data
      // this._couponInput = this.couponService.getPatPayable();
      if(!this.couponList) this.getCoupons(); // commented due to Nahdi issue
    }
    this.refresh.subscribe(response => {
      console.log("response", response, this.type, this.couponList, "-== isFilterOpen===", this.isFilterOpen, this.selectedCoupons)
      if (response['enable']) {
        if(this.type == 'COUPON'|| this.listType == 'COUPONLIST'){
          if(response['appliedcouponcodedtls'] && response['appliedcouponcodedtls'].length > 0){
            response['appliedcouponcodedtls'] = response['appliedcouponcodedtls'].filter(item => {
              return item.checked && item.isApplied
            })
            // this.couponList = response['couponList']
            this.selectedCoupons = response['appliedcouponcodedtls']
            this.alreadyAppliedCoupons = response['appliedcouponcodedtls']
            this.getCoupons(this.selectedCoupons);
          }
          else{
            this.getCoupons();
          }
          // this.getCoupons();

        }
        if(this._couponInput)
          this.couponBalPatientPayable = this._couponInput;
        if(response['payableAmt']){
          this._couponInput = response['payableAmt']
          this.couponBalPatientPayable = response['payableAmt']
        }
        // this.couponList = COUPON //mock data
        // this.getCoupons(); // commented due to Nahdi issue
        // if(response['type'] != 'COUPON' ||  this.listType != 'COUPONLIST'){
        //   this.promoCode = ''
        //   if(this._promoInput)
        //     this.getPromo();
        // }
      }
    })
    this.isEmpty();
  }

  public isEmpty() {
    if (this.couponCode == '') return true ;
             return false ;
  }
  protected onchange(evt) {
    console.log(" event item",evt)
    this.couponCode = evt.srcElement.value;
    this.isEmpty();
  }
  public isEmptyPromo() {
    if (this.promoCode == '') return true ;
             return false ;
  }
  protected onchangePromo(evt) {
    // console.log(" event item",evt)
    this.promoCode = evt.srcElement.value;
    if(this.isEmptyPromo())
      this.removePromo();
  }
  public exit(){
    console.log(" this.couponParam.type,", this.couponParam.type,)
    this.validatedResponse['code'] = this.selectedCode;
    this.completeCoupon.emit({
      data : this.validatedResponse,
      type: this.couponParam.type,
    });
  }
  public closeModal(){
    this.completeCoupon.emit(false)
  }
  public dismiss(action?){
    if(this.type == 'COUPON'){
      this.isFilterOpen = false;
      this.couponDiscount = 0
      let selectedCouponSeq = []

      if(action && action == 'APPLY'){
        this.selectedCoupons.map(ele => {
          ele.isApplied = true
        })
      }else{
        this.selectedCoupons = this.alreadyAppliedCoupons ? this.alreadyAppliedCoupons : []
      }

      if(this.selectedCoupons && this.selectedCoupons.length > 0){
        this.selectedCoupons = this.selectedCoupons.filter(item =>{
          if(item.checked && item.isApplied) {
            selectedCouponSeq.push(item.couponSequenceNo);
            return item.checked && item.isApplied
          }
        })

        if(selectedCouponSeq && selectedCouponSeq.length > 0)
          this.couponList = this.couponList.map(item =>{
            if(selectedCouponSeq && selectedCouponSeq.length > 0 && selectedCouponSeq.includes(item.couponSequenceNo)){
              item['checked'] = true
            }else{
              item['checked'] = false
            }
            return item
          })

        console.log("==== this.couponList on dismiss ====", this.couponList)


        // this.selectedCoupons.map(item =>{
        //   this.couponDiscount = this.couponDiscount + parseInt(item.cashDiscount)
        // })

        this.couponDiscount = this.selectedCoupons.reduce((total, item) => {
          total += parseInt(item.cashDiscount)
          return total;
        }, 0)
      }
      console.log("==== this.selectedCoupons on dismiss ====", this.selectedCoupons, this.couponDiscount)
      this.validatedResponse['value'] = this.couponDiscount
      this.validatedResponse['appliedcouponcodedtls'] = this.selectedCoupons 
      this.validatedResponse['couponCode'] = "COUPON"
      // this.validatedResponse['couponList'] =  this.couponList
      this.completeCoupon.emit({
        data : this.validatedResponse,
        type: this.type,
      });
    }
    else{
      this.completeCoupon.emit(false);
    }
  }
  public applyCode(){
    if(this.type == 'COUPON'){
      this.selectedCode = this.couponCode
      this.validateCoupons();
    }
    else{
      this.selectedCode = this.promoCode
      this.validatePromo();
    }
  }
  public chooseCoupon(data){
    if(this.type == 'COUPON'){
      this.couponCode = data.couponSequenceNo;
      this.selectedCode = this.couponCode
      console.log("chooseCoupon",this.couponBalPatientPayable)
      if(this.couponBalPatientPayable > 0){
        this.redeemCoupon(data);
      }
      // this.validateCoupons();
    }
    else{
      this.promoCode = data.promocode;
      this.selectedCode = this.promoCode
      this.validatePromo();
    }
  }

  //coupon methods
  public getCoupons(selCoupon?){
    this.user.getCoupons().subscribe(res => {
      if(res){
        this.couponList = res;

        let selSeq = []
        if(selCoupon && selCoupon.length > 0){
          selCoupon.map(e => {
            if(e.checked && e.isApplied) selSeq.push(e.couponSequenceNo)
          })
          this.couponList.map(ele => {
            ele.checked = selSeq.includes(ele.couponSequenceNo)
          })
        }

      }
      else{
        this.getUserCoupons();
      }
    })
  }

  public getUserCoupons(){
    this.user.getSSOUserObject().subscribe( data => {
      let payload = {
        "loyaltyId": data.username,
        "locale": this.user.currentLanguage
        // "loyaltyId": 100212045
      }
      this.loyaltyId = data.username;
      this.couponService.getCoupons(payload).subscribe( response => {
        console.log("getCoupons", response)
        this.couponList = response.data;
        if(this.couponList && this.couponList.length == 0){
          this.showNoCoupon = true;
        }
        this.couponList.map( item => {
          // return item['loyaltyId'] = 100212045
           return item['loyaltyId'] = this.loyaltyId
        })
        console.log("loyaltyId", this.couponList)

        this.user.setCoupons(this.couponList);
      })
    })
  }

  public validateCoupons(){
    this.isInValidCoupon = false;
    this.user.getSSOUserObject().subscribe( data => {
      let payload = {
        "loyaltyAccId": data.username,
        "couponCode ": this.couponCode
      }
      this.couponService.validateCoupon(payload).subscribe( response => {
        console.log("validateCoupons",data);
        if(response.data.valid == 'Y'){
          this.validatedResponse = response.data;
          this.exit();
          // this.dismiss();
        }
        else{
          this.isInValidCoupon = true;
        }
      })
    })
  }

  public redeemCoupon(data){
    data['couponBurnt'] = (parseInt(data.cashDiscount) > this.couponBalPatientPayable) ? Number(this.couponBalPatientPayable) : parseInt(data.cashDiscount)
    console.log("data.cashDiscount > this.couponBalPatientPayable", data.cashDiscount > this.couponBalPatientPayable ,  data['couponBurnt']) 
    this.selectedCoupons.push(data);
    this.calculatePatientPayable()
    console.log("redeemCoupon couponBalPatientPayable =>",this.couponBalPatientPayable)
    this.isInValidCoupon = false;
    this.validatedResponse = {
      "couponCode" : data.couponSequenceNo,
      // "value" : data.cashDiscount,
      "value": this.couponDiscount,
      // "appliedcouponcodedtls": this.selectedCoupons
    }

    // commented this code as removal of checked coupon is not required in new UX revamp
      // this.couponList = this.couponList.filter(item => {
      //   return item.couponSequenceNo != data.couponSequenceNo
      // })
    //
  }

  public removeCoupon(data, action?){
    console.log("remove",data)
    this.selectedCoupons = this.selectedCoupons.filter( item => {
      return item.couponSequenceNo != data.couponSequenceNo
    })
    
    this.selectedCoupons.map(item =>{
      item.couponBurnt =  Number(item.cashDiscount)
    })
    // this.validatedResponse['appliedcouponcodedtls'] = this.selectedCoupons
    // commented this code as this logic is not required in new UX revamp
      // this.couponList.push(data);
    //
    this.calculatePatientPayable()
    console.log("removeCoupon ===> ", this.couponDiscount, this.selectedCoupons);
    if(action == 'DISMISS'){
      // setTimeout(()=>{
        this.validatedResponse['value'] = this.couponDiscount
        this.validatedResponse['appliedcouponcodedtls'] = this.selectedCoupons 
        this.validatedResponse['couponCode'] = "COUPON"
        this.completeCoupon.emit({
          data : this.validatedResponse,
          type: this.couponParam.type,
        });
      // },1000)

    console.log("removeCoupon validatedResponse ===> ", this.validatedResponse);

    }
  }

  //promo methods
  public getPromo(){
    console.log("getPromo promoInput", this._promoInput)
    if(this._promoInput){
      let serviceArr = this._promoInput ? this._promoInput['selectedServices'] : null
      let chargeStr =  this._promoInput ? this._promoInput['chargeStr'] : ""
        this.couponService.getPromoCodes(this._promoInput, serviceArr, chargeStr).subscribe( response => {
          console.log("getAvailablePromo", response)
          // this.promoList = response.data;
          this.promoList = response.data.applicablepromocodedtls;
          this.appliedPromoDtls =  response.data.appliedpromocodedtls;
          this.promoInfo =  response.data.promoInfo;
        })
    }
  }

  public validatePromo(){
    console.log("promoInput", this._promoInput)
    this.isInValidPromo = false;
      // let payload = {
      //   "promoCode": this.selectedCode,
      //   "personId": this.promoInput.personId,
      //   "currency": this.promoInput.currency,
      //   "billingGroup": this.promoInput.billingGroup,
      //   services :[{
      //       "serviceCode": this.promoInput.services[0].serviceCode,
      //       "serviceAmount": this.promoInput.services[0].serviceAmount
      //   }]
      // }
      let payload = {
        "serviceCharge": this._promoInput.services[0].serviceAmount,
        "netServiceCharge": this._promoInput.services[0].serviceAmount,
        "cartValue": this._promoInput.services[0].serviceAmount, 
        "appliedPromoCodeDtls": this.appliedPromoDtls, 
        "applyDiscountYn": "Y", 
        "selectedPromoCodes": this.selectedCode,
        "applicablePromoCodeDtls": this.promoList,
        "selectedServices": this._promoInput.services[0].selectedServices ? this._promoInput.services[0].selectedServices : null,
        "promoInfo": this.promoInfo,
        "chargeStr": this._promoInput ? this._promoInput['chargeStr'] : "",
        patientId: this._promoInput['patientId'],
        personId: this.user.getPersonId(),
        facilityId: this._promoInput['facilityId'],
        specialityId: this._promoInput['specialityId'],
        practitionerId: this._promoInput['practitionerId']
      }
    
      this.couponService.validatePromoCode(payload).subscribe( response => {
        console.log(this._promoInput,"validatePromoCode",response);
        if(response && response.data.netservicecharge != null){
          this.validatedResponse = response.data;
          this.isPromoApplied = true
          this.exit();
        }
        else{
          this.isInValidPromo = true;
          this.isPromoApplied = false;
        }
      })

      // this.validatedResponse = PROMO_RES;
      // this.exit();
  }

  getPromoDesc(names){
    if (names.length == 0) {
        return '';
    } else {
        let t = names.find(i => {
            return i.name == this.user.currentLanguage
        })
        if (t) {
            return t.value;
        } else {
            return names[0].value;
        }
    }
  }

  calculatePatientPayable(){
    if(this.selectedCoupons.length > 0){
      this.couponDiscount = this.selectedCoupons.reduce((total, item) => {
        total += parseInt(item.cashDiscount)
        return total;
      }, 0)
      // this.couponDiscount = this.selectedCoupons.map(item => item.cashDiscount).reduce((prev, next) => parseInt(prev) + parseInt(next));
    }
    else{
      this.couponDiscount = 0
    }
   let balance: any = this._couponInput - this.couponDiscount
    balance = parseFloat(balance).toFixed(2) 
    if(balance <= 0){
      this.couponBalPatientPayable = 0
    }else {
      this.couponBalPatientPayable = balance
    }
  }

  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
    this.showMobileView();
  }

  public showMobileView(){
    const query = window.matchMedia("(max-width: 780px)")
    if (query.matches) { 
      this.isFilterOpen = false;
      this.showExternalCoupon.emit();
    }else{
      if(this.isFilterOpen &&  this.couponList &&  this.couponList.length > 0 ){
        this.getCoupons(this.selectedCoupons);
      }
    }
  }

  public chooseFilter(ev, item){
    console.log("==== ev ===", ev, ev.target.checked)
    if(!ev.target.checked){
      this.removeCoupon(item);
    }else{

      item['checked'] = ev.target.checked;
      if(item.checked)
        this.redeemCoupon(item);
      else 
        this.removeCoupon(item);

      // if(this.couponBalPatientPayable > 0){
      //   item['checked'] = ev.target.checked;
      //   if(item.checked)
      //     this.redeemCoupon(item);
      //   else 
      //     this.removeCoupon(item);
      // }else{
      //   if(!item.checked) { ev.target.checked = false; this.removeCoupon(item);}
      // }
    }
    // item.isApplied = false;
    // item['checked'] = ev.target.checked
    // if(item.checked)
    //   this.redeemCoupon(item); 
    // else this.removeCoupon(item);
    // this.initFilter();

  }

  public initFilter(){
    let count = this.couponList.map(ele => {
      return ele.checked
    })
    this.selectedFilter = count
    console.log("==== selectedFilter ===", count, count.length, "===>", this.selectedFilter)
  }

  public removePromo(){
    this.promoCode = ""
    this.isPromoApplied = false;
    this.isInValidPromo = false;
    this.clearPromo.emit();
  }

  public removeInternalCoupon(coupon){
    coupon.checked = false;
    coupon.isApplied = false;
    this.removeCoupon(coupon, 'DISMISS');
    // this.dismiss();
  }

  public closeFilter(){
    this.isFilterOpen = false;
  }
}

export const COUPON = [
  {
    "couponTitle":"#234",
    "expires":"10/30",
    "loyaltyAccId":"",
    "cashDiscount":"50",
    "currency":"",
    "status":"",
    "valid":"",
    couponSequenceNo: "#23401"
  },
  { 
    couponTitle: '#123',
    cashDiscount: '20',
    expires: '11/20' ,
    couponSequenceNo: "#23402"
  },
  { 
    couponTitle: '#2323',
    cashDiscount: '20',
    expires: '11/20' ,
    couponSequenceNo: "#23403"
  },
  { 
    couponTitle: '#456',
    cashDiscount: '30',
    expires: '12/20' ,
    couponSequenceNo: "#23404"
  },
  { 
    couponTitle: '#129',
    cashDiscount: '20',
    expires: '11/20' ,
    couponSequenceNo: "#23405"
  },
  { 
    couponTitle: '#238',
    cashDiscount: '20',
    expires: '11/20' ,
    couponSequenceNo: "#23406"
  },
  { 
    couponTitle: '#457',
    cashDiscount: '30',
    expires: '12/20' ,
    couponSequenceNo: "#23407"
  },
]
export const PROMO = [
  {
    "promoCode":"NAHDIEXP50",
    "promoText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "minimumValue": "Get 50% discount on express clinic consultation"
  },
  {
    "promoCode":"NAHDIEXP10",
    "promoText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "minimumValue": "Get 10% discount on express clinic consultation"
  },
]

export const PROMO_RES = {
  "STATUS": "SUCCESS",
  "data": {
 
      "appliedpromocodedtls": [
          {
              "longdesc": "N",
              "promocode": "DIWALI2020",
              "promodesc": "Diwali 2020",
              "shortdesc": "N",
              "mincartvalue": 200,
              "discountpercentage": 10,
              "maxdiscountpertrans": 500,
              "discountonbillamountyn": "N",
              "maxdiscountacrosstrans": 1000,
              "noofdiscountsperaccount": 5,
              "applicabletoonlineonlyyn": "Y",
              "calculateddiscountamount": 50,
              "restorelimitincaseofcancelyn": "Y"
          }
      ],
      "applicablepromocodedtls": [
          {
              "longdesc": "N",
              "promocode": "DIWALI2020",
              "promodesc": "Diwali 2020",
              "shortdesc": "N",
              "mincartvalue": 200,
              "discountpercentage": 10,
              "maxdiscountpertrans": 500,
              "discountonbillamountyn": "N",
              "maxdiscountacrosstrans": 1000,
              "noofdiscountsperaccount": 5,
              "applicabletoonlineonlyyn": "Y",
              "calculateddiscountamount": null,
              "restorelimitincaseofcancelyn": "Y"
          },
          {
              "longdesc": "N",
              "promocode": "NEWYEAR2021",
              "promodesc": "New Year 2021",
              "shortdesc": "N",
              "mincartvalue": 200,
              "discountpercentage": 10,
              "maxdiscountpertrans": 500,
              "discountonbillamountyn": "N",
              "maxdiscountacrosstrans": 1000,
              "noofdiscountsperaccount": 5,
              "applicabletoonlineonlyyn": "Y",
              "calculateddiscountamount": null,
              "restorelimitincaseofcancelyn": "Y"
          }
      ],
      "netservicecharge": 450,
      "calculatedDiscountAmt": 100
  }
}
