import { Component, OnInit,Output,EventEmitter,HostListener } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute,Router } from '@angular/router';
import { Location } from '@angular/common';
import { LinkPatientService } from '../services/link-patient.service';
import { ModalService} from '../services/modal.service';
import { concat as _concat } from 'lodash';
import * as moment from 'moment';
import {AppointmentService} from '../services/appointment.service';
import { SocketService } from '../services/socket.service';

import { ROUTE_CRM_CALENDAR, ROUTE_LOGIN } from '../app.string';
import { UtilitiesService } from '../services/utilities.service';
import { Subject } from "rxjs";
import { GoogleTagsService } from '../services/google-tags.service';
import { environment } from '@env/environment';
import { AlertService } from '../services/alert.service';
import { EventsService } from '../services/events.service';
import { SESSION_EXPIRED } from '@string';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  refresh: Subject<boolean> = new Subject<boolean>();
  private appConfig: any;
  public hasLinkedPatients: boolean;
  public orderdetails: any;
  public isBookAppointment: boolean;
  public isRegisterPatient: boolean;
  public bookingAttributes: any;
  public upcomingAppointments: any;
  public ongoingAppointments: any;
  public consultAgainAppts: any;

  public relationType: any;
  public relations: any;
  public entity: any;
  public linkedId: Array<any>;
  public recordsToLink: Array<any> = [];
  public linkedPatients: Array<any>;
  public isFeedback: boolean;
  public action: string;
  public resource: string;
  public appointmentType: string;
  public isExternalBookAppointment: any;
  public chooseSlot:any;
  public prefillData: Object;
  public facilityList:any;
  public isExpressClinicEnabled: boolean;


  public openUserProfileActions: boolean;
  public isManageIdentities:boolean;
  public selectedLanguage: string;

  public isConsultNow: boolean;
  public isOnspot: boolean = false;
  public isExpressClinic: boolean;
  public expressdetails: any;

  public openHealthPkgs: boolean;
  public pkgdetails: any;
  public selectedPkg: any;
  public showClinicOpt: boolean;
  public showVideoOpt: boolean;
  public showPersonalOpt: boolean;
  public showHealthOpt: boolean;
  public version1: boolean;
  public version2: boolean;
  public homeServices: boolean;
  public isPatReg: boolean;
  allAppointments: any;
  public openReferrals: Array<any> = [] //referral; API integration yet to done
  public isReferral: boolean = false;
  public referralDetail: any = null;
  public newUI: boolean;
  public enableAlertOnServiceUpdateNotification:boolean=false;
  public unreadAlertCount: number = 0;
  public isMyAlerts: boolean;
  public updateAlertInfo:any;
  public openServiceRequests;
  public isLoggedIn: boolean;
  public patientIdForHealthRecord: any;
  public reportIdForHealthRecord: any;
  showPreLoginModal: boolean;
  preloginContext: any;
  openMyPackages:boolean;
  showContactusModal;
  openAlertModal;
  constructor(private user: UserService, private activatedRoute: ActivatedRoute, private location: Location, private linkPatient: LinkPatientService,
    public modalService: ModalService, private appointmentService: AppointmentService, private router: Router, private socket: SocketService, private utils: UtilitiesService, private gtmService: GoogleTagsService,
    private alertService: AlertService,public events:EventsService) {
    this.version1 = false;
    this.version2 = true;

      this.user.setPackages();
      this.selectedLanguage = this.user.currentLanguage;
      this.user.getConfigurationInfo.subscribe(data => {
        this.appConfig = data;
        this.isExpressClinicEnabled = data['pxAppSettings']['enableExpressClinicYn'] == 'Y';
        this.isMyAlerts = data['pxAppSettings']['disableAlertsYn'] != 'Y';
      })
      this.user.getUserInfo('linkedPatients').subscribe(data => {
        this.linkedPatients = data
      });
      if (Array.isArray(this.linkedPatients) && this.linkedPatients.length){
        this.linkedPatients.map(p => {
          let payload = {
            entityId: p.entityid,
            uhid: [p['uhid']]
          }
          this.socket.joinPatientEvent(payload);
        });
      }
      this.socket.onAppointmentChange().subscribe( obj => {
        this.fetchUpcomingAppointment();
      })
  }

  ngOnInit() {
    this.newUI = (environment['ENTITY'] && environment['ENTITY'] == 'MOHEGY') ? true : false;
    this.isLoggedIn = this.user.isLoggedIn();
    this.user.getConfigurationInfo.subscribe(data => {
      if(data.pxAppSettings){
        if(data.pxAppSettings.onSpotConsultationYn == 'Y'){
          this.isOnspot = true;
        }
        else{
          this.isOnspot = false;
        }
      }
    });
    this.checkUrl();
    if (this.isLoggedIn) {
      this.user.getUserInfo('linkedPatients').subscribe(data => {
        this.hasLinkedPatients = data.length > 0 ? true : false;
      });
      this.fetchUpcomingAppointment();
      if(this.user.canViewOption('ENABLERECENTLYCONSULTEDVIEW')) {
        this.getConsultAgain();
      }
      this.initLinkedRecords();
      this.getPatientsToLink();
    }
    this.showErrorModal();
    
  }

  checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.info('activatedRoute checkUrl', params)
      let payment = params['s'];
      let action = params['action'];
      let resource = params['resource'];
      let speciality = params['speciality'];
      let facility = params['facility'];
      let slot = params['slot'];
      let isPatReg = params['regPat'] == 'Y'
      let type = params['type'];
      let alias = params['alias'];
      let patientId = params['patientid']
      let reportId = params['reportid']
      if (payment == 1) {
        let method = params['method'];
        let orderdetails = params['p'];
        switch (method) {
          case 'APPTBK':
            this.orderdetails = orderdetails;
            this.openBookAppointment();
            this.location.replaceState('/portal/home')
            break;
          case 'EXPRESS':
            this.orderdetails = orderdetails;
            this.openExpressClinic(true);
            this.location.replaceState('/portal/home')
            break;
          case 'SRVREQ':
            this.selectedPkg = null;
            this.pkgdetails = orderdetails;
            this.openHealthPkgs = true
            this.location.replaceState('/portal/home')
            break;
          case 'PATREGN':
            this.openModal('manage-identity-modal');
            this.location.replaceState('/portal/home')
            break;
        }
      }
      else if (action == 'schedule') {
        //example https://healtht.nahdi.sa/at/patientportal/#/login?action=schedule&type=express
        console.log("tyoe", params)
        if (type && type.toLowerCase() == 'onspot') {
          this.openAppointment('VIDEO-CONSULT-NOW')
          this.location.replaceState('/portal/home')
        } else if (type && type.toLowerCase() == 'express') {
          this.openExpressClinic();
          this.location.replaceState('/portal/home')
        } else if (type && type.toLowerCase() == 'healthpackages') {
          this.openHealthPkgs = true
          this.location.replaceState('/portal/home')
        } else if (type && type.toLowerCase() == 'homecare') {
          this.homeServices = true;
          this.location.replaceState('/portal/home')
        } else {
          this.chooseSlot = {
            resourceId: resource,
            facilityId: facility,
            appointmentType: type,
            specialityId: speciality,
            slot:slot,
            alias:alias
          }
          this.openBookAppointment();
          this.location.replaceState('/portal/home');
        }
      } else if (action == 'registerpatient') {
        this.openRegisterPatient();
        this.location.replaceState('/portal/home')
      } else if (action == 'home') {
        this.location.replaceState('/portal/home')
      } else if(action == 'reports') {
        this.patientIdForHealthRecord = patientId;
        this.reportIdForHealthRecord = reportId;
        this.openHealthRecords()
        this.location.replaceState('/portal/health-records')
      } else if(action == 'healthrecords') {
        this.patientIdForHealthRecord = patientId;
        this.openHealthRecords()
        this.location.replaceState('/portal/health-records')
      }
      else if(resource){ 
        this.chooseSlot = {
          resourceId: resource,
          facilityId: facility,
          appointmentType: type
        }
      }

      if (isPatReg) {
        setTimeout(_ => {
          this.isPatReg = true
          this.openModal('manage-identity-modal');
          this.location.replaceState('/portal/home')
        }, 500)
      }
    });
  }
  
  public openAppointment( appointmentType){
    if(appointmentType == 'VIDEO-CONSULT-NOW' && !this.isLoggedIn)
      return this.showLoginAlert({action:'schedule', type:'onspot'});
    this.prefillData = { type: appointmentType };
    this.openBookAppointment();

    let obj = {
      'event': 'clinic_appointment_button',
      'funnel_name': 'Clinic Appointments',
    };
    if (appointmentType == 'VIDEO') {
      obj['event'] = 'online_consultation_button';
      obj['funnel_name'] = 'Online Consultation';
    }
    if (appointmentType == 'VIDEO-CONSULT-NOW') {
      obj['event'] = 'consult_doctor_now_button';
      obj['funnel_name'] = 'Instant Consultation';
    }
    this.gtmService.pushGTMEvent(obj['funnel_name'], obj['event']);
  }

  public openHealthRecords() {
    console.info('openHealthRecords',this.patientIdForHealthRecord, this.reportIdForHealthRecord)
    if(this.patientIdForHealthRecord && this.reportIdForHealthRecord) {
      this.router.navigate([`${this.user.getPortalBasePath()}/`+ 'health-records', {action: 'reports', id: this.patientIdForHealthRecord, reportId: this.reportIdForHealthRecord}])
    } else if(this.patientIdForHealthRecord) {
      this.router.navigate([`${this.user.getPortalBasePath()}/`+ 'health-records', {action: 'healthrecordreport', id: this.patientIdForHealthRecord}])
    } else {
      this.router.navigate([`${this.user.getPortalBasePath()}/`+ 'health-records', {action: 'healthrecords'}])
    }
  }

  closeApptDetail() {
    this.isBookAppointment = false;
  }
  public openBookAppointment() {
    this.isBookAppointment = true;
  }
  public openExpressClinic(isFromRoute?) {
    if (!this.isLoggedIn)
    return this.showLoginAlert({action:'schedule', type:'express'});
    this.isExpressClinic = true;
    if (!isFromRoute)
      this.gtmService.pushGTMEvent('Express Clinics', 'express_clinics_button');
  }
  public closeExpressClinic(){
    this.isExpressClinic = false;
    this.orderdetails = null;
    this.bookingAttributes = null;
  }
  public closeBookAppointment(event) {
    this.isBookAppointment = false;
    this.orderdetails = null;
    this.chooseSlot = null;
    this.prefillData = null;
    if(!event){
      return
    }
    if (event.bookingAttributes) {
      this.bookingAttributes = event.bookingAttributes;
      this.openRegisterPatient();
    } else if(event == 'EXPRESS') {
      this.isExpressClinic = true;
    } else if(event == 'HOME-CARE') {
      this.homeServices = true;
    } else {
      this.fetchUpcomingAppointment();
      this.getConsultAgain();
      this.bookingAttributes = null;
      this.chooseSlot = null;
      this.isReferral = false;
      this.referralDetail = null;
      this.router.navigate([`../${ROUTE_CRM_CALENDAR}`], { relativeTo: this.activatedRoute });
    }
  }
  public openRegisterPatient() {
    if (!this.isLoggedIn)
      return this.showLoginAlert({ });
    this.isRegisterPatient = true;
  }
  public closeRegisterPatient() {
    this.isRegisterPatient = false;
    if (this.bookingAttributes) {
      this.openBookAppointment()
    }
  }

  public navigateToCalendar() {
    if(!this.isLoggedIn)
    return this.showLoginAlert();
    this.router.navigate([`../${ROUTE_CRM_CALENDAR}`], { relativeTo: this.activatedRoute });
  }
  public getAppointments() {
    this.user.getUpcomingAppointments().subscribe(data => {
      this.upcomingAppointments = data.data['appointments'];
      this.ongoingAppointments = data.data['appointments'];
    })
  }

  private getRelationList() {
    this.user.getMasterConfig.subscribe(data => {
      this.relationType = data.list;
      this.relationType = this.relationType.filter(arr => {
        return arr.domain === 'RELATIONSHIP_TYPE'
      })
      this.relations = this.relationType[0].codes
    });
  }

  private getEntityId() {
    this.user.getConfigurationInfo.subscribe(data => {
      this.entity = data.entityId;
    })
  }

  private getLinkedPatient() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            this.getPatientsToLink();
          }
        })
      }
    })
  }

  private getPatientsToLink() {
    if(this.linkedPatients){
      this.linkedId = this.linkedPatients.map(item => {
        return item.uhid;
      })
    }
    this.user.getUserCredential().subscribe(data => {
      if (data) {
        this.linkPatient.getPatientsToLink(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.recordsToLink = result.data.filter((item) => {
              return this.linkedId.indexOf(item.patientUhid) === -1
            });
          }
        })
      }
    })
  }
  public initLinkedRecords() {
    this.getLinkedPatient();
    this.getEntityId();
    this.getRelationList();
  }
  public openFeedbackForm() {
    this.isFeedback = true;
  }
  public closeFeedbackForm() {
    this.isFeedback = false;

  }

  openModal(id: string) {
    if (id == 'manage-identity-modal') {
      if(!this.isLoggedIn)
      return this.showLoginAlert();
      this.isManageIdentities = true;
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }

  public openUserActions() {
    if(!this.isLoggedIn)
    return this.showLoginAlert();
    this.openUserProfileActions = true;
  }
  
  public closeUserActions() {
    this.openUserProfileActions = false;
    this.refresh.next(true);
  }

  public openHealthPkg(packag?) {
    this.selectedPkg = packag;
    this.pkgdetails = null;
    this.openHealthPkgs = true;
    this.gtmService.pushGTMEvent('Health Packages', 'health_packages_button');

    // this.switchLanguage = false;
  }
  public closeHealthPkg() {
    this.openHealthPkgs = false;
  }
  public closeManageIdentities() {
    this.isManageIdentities = false;
  }

  fetchUpcomingAppointment() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.appointmentService.getUpcomingAppointment(data).subscribe(result => {
          this.upcomingAppointments = this.allAppointments = result.data.appointments;
          let today= this.user.getMoment().format('DD/MM/YYYY');
          let nextAppointments = this.upcomingAppointments.filter(a => {
            if(this.user.getMoment(a.appointmentDateTime,'YYYY-MM-DD HH:mm').isAfter(this.user.getMoment(today,'DD/MM/YYYY'),'day')) return a;
          }).splice(0,4)
          this.upcomingAppointments = this.upcomingAppointments.filter((a)=>{//2020-04-23 08:00
              if(this.user.getMoment(today,'DD/MM/YYYY').isSame(this.user.getMoment(a.appointmentDateTime,'YYYY-MM-DD HH:mm'),'day')) return a;
          })
          this.ongoingAppointments = this.upcomingAppointments.filter((a)=>{
            if(a.consultationStatus && a.consultationStatus =='PAUSE' || a.consultationStatus =='STARTED') {
              return a;
            }
          })
          this.upcomingAppointments = this.upcomingAppointments.filter((a)=>{
              if(!a['consultationStatus'] || (a.consultationStatus && a.consultationStatus !='FINISH' && a.consultationStatus !='CANCEL' && a.consultationStatus !='PAUSE' && a.consultationStatus !='STARTED' )) {
                return a;
              }
          })
          this.upcomingAppointments = this.upcomingAppointments.filter((a)=>{
              var apptDate = new Date(this.user.getMoment(a.appointmentDateTime, 'YYYY-MM-DD HH:mm').toDate())
              if(this.user.getMoment(apptDate).isAfter(this.user.getMoment().format())) {
                return a;
              }
              else {
                if(a['appointmentType']=='VIDEO'){
                   return this.allowJoin(a)
                }else{
                  return this.showCheckIn(a);
                }
              }

          })
          this.upcomingAppointments = _concat(this.upcomingAppointments, nextAppointments);
          console.log('--p-=-=-=-=->>>', this.upcomingAppointments, this.ongoingAppointments);
        })
      }
    })
  }

  getConsultAgain() {
    this.user.getUserId().subscribe(data => {
      console.log('getConsultAgain', data)
      if (data) {
        this.appointmentService.getRecentlyConsultedAppts(data).subscribe((res) => {
          console.log('consult again data', res);
          this.consultAgainAppts = res;
        })
      }
    });
  }

  public openRCAppointment() {
    console.log('openingggg')
  }


      showCheckIn(apt){
        this.user.getUpdateInfo('facilities').subscribe(data => {
          this.facilityList = data.facilities;
        })
        if (this.appConfig.features.appointmentCheckIn) {
          if (apt.checkedIn == 'false' && apt.checkInAllowed == 'true') {
            for (let f in this.facilityList) {
              if (this.facilityList[f].facilityId == apt.facilityId) {
                if (this.validateRule(apt.appointmentDateTime, this.facilityList[f].facilityControls.schedulingRules.checkInPolicy, apt['facilityId'])) {
                  return true;
                } else {
                  return false;
                }
              }
            }

          } else {
            return false;
          }
        } else {
          return false;
        }
    }

      allowJoin(apt){
        this.user.getUpdateInfo('facilities').subscribe(data => {
          this.facilityList = data.facilities;
        })
        this.facilityList = this.facilityList.filter( item =>{
          return item['facilityId'] == apt['facilityId']
        })
        var policy=this.facilityList[0];
        if(policy && policy.facilityControls && policy.facilityControls.schedulingRules){
          var rules = policy.facilityControls.schedulingRules;
          if (apt.isOnspot) {
            if (rules?.onspotConsult?.policy) {
              let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
              if (this.validateRule(dateTimeToCheck, rules.onspotConsult.policy, apt['facilityId'])) {
                return true;
              } else {
                return false;
              }
            }
          }
            else if (rules.videoConsult&&rules.videoConsult.policy){
              let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
            if (this.validateRule(dateTimeToCheck, rules.videoConsult.policy, apt['facilityId'])) {
                   return true;
               }else{
                   return false;
               }
            }
        }
    }


  validateRule(dateTimeToCheck, txnType, facilityId) {

        var policy = txnType;
      let facilityTimezone = this.user.getFacilityTimezone(facilityId);
        var validate = true;
        var currDateTime = this.user.getMoment();

        var lowerRefDateTime = this.user.getMoment('1999-01-01');
        var upperRefDateTime = this.user.getMoment('2099-12-31');

        if (policy) {
            if (policy.minPeriod != "") {
              lowerRefDateTime = this.getRefDateTime(policy.minPeriod, policy.minPeriodUnit, policy.minPeriodFactor, dateTimeToCheck, facilityTimezone);
            }
            if (policy.maxPeriod != "") {
              upperRefDateTime = this.getRefDateTime(policy.maxPeriod, policy.maxPeriodUnit, policy.maxPeriodFactor, dateTimeToCheck, facilityTimezone);
            }
        }

        if ((currDateTime.isBefore(lowerRefDateTime)) || (currDateTime.isAfter(upperRefDateTime))) {
            validate = false;
        }

        return validate;
    }
    getRefDateTime(period, unit, direction, dateTimeToCheck, timezone) {
        var refDateTime = this.user.getMoment(dateTimeToCheck, null, null, timezone);

        if (unit == "H") {
            if (direction == "BEFORE") {
                refDateTime.subtract(parseInt(period), 'hours');
            }
            else if (direction == "AFTER") {
                refDateTime.add(parseInt(period), 'hours');
            }
        }
        else if (unit == "M") {
            if (direction == "BEFORE") {
                refDateTime.subtract(parseInt(period), 'minutes');
            }
            else if (direction == "AFTER") {
                refDateTime.add(parseInt(period), 'minutes');
            }
        }
        else if (unit == "D") {
            if (direction == "BEFORE") {
                refDateTime.subtract(parseInt(period), 'days');
            }
            else if (direction == "AFTER") {
                refDateTime.add(parseInt(period), 'days');
            }
        }
        return refDateTime;

    }

  public openOnSpotConsult() {
    this.isConsultNow = true;
  }

  public openPkg(ev: any) {
    this.pkgdetails = null;
    if(ev.toFilterPackage){
      ev.directive['concepts'].find(item => {
        if(item.code == ev.toFilterPackage){
          this.selectedPkg = item;
          this.selectedPkg['selectedFacilityId'] = 'JA'
        }
      })
      this.openHealthPkgs = true;
    }
    else{
      this.openHealthPkgs = true;
    }
  }
  public openHomeCareServices() {
    if (!this.isLoggedIn)
    return this.showLoginAlert();
    this.homeServices = true 
    this.gtmService.pushGTMEvent('Home Health Care', 'care_at_home_button');
  }
  public closehomeServices(value){
    this.homeServices = false
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event['keyCode'] == 27 ) {
      this.homeServices = false
  }
  }
  
  public openReferralAppointment(referral) {
    this.isReferral = true;
    this.referralDetail = referral;
    this.openBookAppointment()
  }

    //Notification block - For future integration purpose

    public getUnReadAlertsCount() {
      this.unreadAlertCount= 0
      this.alertService.getAlerts(true).subscribe(data => {
        // if (data['status'] == "SUCCESS") {
          var events = [];
          var myalerts = [];
          if (data['events']) {
            events = data['events'].filter(function (value) {
              if (value.readStatus != 'yes')
                return value;
            });
          }
          if (data['alerts']) {
            myalerts = data['alerts'].filter(function (value) {
              if (value.readStatus != 'yes')
                return value;
            });
            let tmpUpdate;
            tmpUpdate=data['alerts'].map(a=>{
              if (a.category == 'UPDATES' && (a.updateCategory == 'SERVICEREQ' || a.updateCategory == 'PATLINK'))
                return a;
            });
            if(tmpUpdate && tmpUpdate.length>0)
            tmpUpdate=tmpUpdate.sort((a, b) => {
              return new Date(moment(b.startDate).format()).getTime() - new Date(moment(a.startDate).format()).getTime();
            })
            // tmpUpdate.sort((a,b)=>a.startDate-b.startDate);
            if(tmpUpdate.length>0 && tmpUpdate[0] && tmpUpdate[0].readStatus===''){
              this.enableAlertOnServiceUpdateNotification=true;
              this.updateAlertInfo=tmpUpdate[0];
            }
            console.log('tmp update home--',tmpUpdate,this.updateAlertInfo)
          }
          console.log('get alert count---',data,events,myalerts)
          this.unreadAlertCount = events.length + myalerts.length;
        // }
      })
    }

    public clearAlertInfo(alertInfo){
      console.log('clear')
      this.updateAlertInfo={}
      this.enableAlertOnServiceUpdateNotification = false;
      switch (alertInfo.updateCategory) {
        case 'SERVICEREQ':
          // this.navCtrl.navigateForward('/notifications');
          break;
        case 'PATLINK':
          // this.navigate('LINKEDPATIENTS', { source: 'notification', info: alertInfo })
          // this.updateAlertStatus(alertInfo, )
          break;
      }
    }

  public showLoginAlert(context?) {
    this.showPreLoginModal = true;
    this.preloginContext = context;
  }

  public showServiceRequests() {
    if (!this.isLoggedIn)
      return this.showLoginAlert();
    this.openServiceRequests = true;
  }

  public closeMyPackages() {
    this.openMyPackages = false;
  }

  public toggleMyPackages() {
    if(!this.isLoggedIn)
      return this.showLoginAlert();
    this.openMyPackages = true
  }

  public openContactUsModal() {
    this.showContactusModal = true
  }
  public showErrorModal() {
    this.events.on(SESSION_EXPIRED, _ => {
      console.log("home SESSION_EXPIRED called")
      this.openAlertModal = true;
    });
  }
}

const referral = [{
  "personId": "031728",
  "personName": "Vidhya Palani",
  "uhid": "A200000357",
  "facilityId": "A2",
  "facilityName": "El Zohour Hospital",
  "referralEndDate": "2021-08-10",
  "referredResourceId": "DOCOPD02",
  "referredPractitionerName": "Doctor El Zohour Outpatient",
  "referredPractitionerLocalName": [{
    "locale": "en",
    "name": "Doctor El Zohour Outpatient"
  }],
  "specialityDesc": "Internal Medicine Splty",
  "specialityId": "MED"
}]
