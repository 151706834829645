import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import * as AppConstant from '../app.string';
import { UserService } from './user.service';
import { ReportService } from './report.service';
import { FeatureFlagsService } from './feature-flags.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class LinkPatientService {
  linkedPatients: any;

  constructor(private http: HttpClient, private user: UserService, private cookie: CookieService, private report: ReportService, private featureService: FeatureFlagsService) {
   }

  public getLinkedPatient(_id: string, forceNewData?): Observable<any> {
    let payload = {
      personId: _id
    }
    if(this.linkedPatients && !forceNewData)
      return new Observable(o => o.next(this.linkedPatients));
    return Observable.create(observer => {
      this.http.post( `${environment['API_URL']}${AppConstant.API_PERSONS}/getLinked`, payload, {reportProgress:true} ).subscribe(data => {
        this.linkedPatients = data;
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public removeLinkedPatient(_id: string, linkedPatient: string): Observable<any> {
    let payload = {
      personId: _id,
      linkedPersonId: linkedPatient
    }
    return Observable.create(observer => {
      this.http.post( `${environment['API_URL']}${AppConstant.API_PERSONS}/remove`, payload, {reportProgress:true} ).subscribe(data => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public removeMyLinked(_id: string, linkedPatient: string): Observable<any> {
    // Developed For MOH - To remove access of my records in some other login - If you want to remove linked patient don't change the logic, instead use removeLinkedPatient fn above
    let payload = {
      personId: this.user.getPersonId(),
      linkedWithPersonId: _id,
      linkedPersonId: linkedPatient
    }
    return Observable.create(observer => {
      this.http.post( `${environment['API_URL']}${AppConstant.API_PERSONS}/removeSelf`, payload, {reportProgress:true} ).subscribe(data => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getPatientsToLink(data): Observable<any> {
    let idValue = data.personDetails.mobile;
    let defCountryCode = environment['COUNTRY_CODE'].replace(/\D/g, '');
    if (data.personDetails.countryCode != defCountryCode) {
      idValue = data.personDetails.countryCode + idValue;
    }
    let payload = {
      idType: this.featureService.featureOn('FT_APP_EXTIDLOGIN')?"N":"M",
      idValue: idValue,
      mobile: data.personDetails.mobile,
      countryCode: data.personDetails.countryCode,
      pin: data.credential,
      otp: data.otp
    }
    return Observable.create(observer => {
      this.http.post( `${environment['API_URL']}${AppConstant.API_PERSONS}/getHisPatients`, payload, {reportProgress:true} ).subscribe(data => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public toLinkPatients(payload): Observable<any> {
    return new Observable(observer => { 
      this.user.getExternalInterface().subscribe(extInterface => {
        if (extInterface) {
          const SSO_TOKEN = this.cookie.getStorage(AppConstant.ACCESS_TOKEN) ? this.cookie.getStorage(AppConstant.ACCESS_TOKEN) : ''
          this.user.getSSOUserObject().subscribe(userObj => {
            payload['ssoDetails'] = {
              token: SSO_TOKEN,
              mobile: this.user.getPersonMobile(),
              countryCode: this.user.getPersonCountryCode(),
              username: userObj.username,
              locale: this.user.currentLanguage
            }
            this.initToLinkPatients(payload).subscribe(response => {
              observer.next(response);
            })
          }, err => {
            this.initToLinkPatients(payload).subscribe(response => {
              observer.next(response);
            })
          })
        } else {
          this.initToLinkPatients(payload).subscribe(response => {
            observer.next(response);
          })
        }
      }, err => {
        this.initToLinkPatients(payload).subscribe(response => {
          observer.next(response);
        })
      })
    })
  }

  public initToLinkPatients(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post( `${environment['API_URL']}${AppConstant.API_PERSONS}/link`, payload, {reportProgress:true} ).subscribe(data => {
        observer.next(data);
        this.user.syncPatientProfile()
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    return 'Something went wrong';
  }

  public getPatientDetails(patient): Observable<any>  {
    let payload = {
      locale: this.user.currentLanguage,
      patientId: patient['uhid'] || patient['patientId']
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/mpi/find`, payload, { reportProgress: true }).subscribe(data => {
        let patientDetails = data['data'][0];
        if (patientDetails.patientAddress) {
          patientDetails.patientAddress = JSON.parse(patientDetails.patientAddress);
        }
        patientDetails.hisDetails = patient;
        patientDetails.relationshiptype = patient.relationshiptype;
        observer.next(patientDetails);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public addPatient(natid, otp) {
    let payload = {externalId: natid, otp: otp}
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/addpatient`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public uploadPatientPhoto(file:File, patient): Observable<any>{
    return new Observable(observer => {
      let params = {
        personId: patient.personid,
        filename: 'random.png'
      }
      // let blob = this.report.b64toBlob(file)
      let formData = new FormData();
      formData.append('upload', file, 'random.png');
      Object.entries(params).forEach((p: Array<any>) => {
        formData.append(p[0], p[1]);
      })
      let options = {
        reportProgress: true,
        body: {
          fileName: 'random.png',
          fileKey: 'upload',
          mimeType: 'image/png',
          chunkedMode: false,
          params: {
            personId: patient.personid,
            filename: 'random.png'
          }
        }
      }
      this.http.post(environment['API_URL'] + 'api/persons/photo', formData, options).subscribe(data => {
        observer.next(data);
      });
    })
  }
}
