<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
    <div class="component">
      <div class="header border-bottom">
        <div class="font-primary-subtitle bold">{{'NUHDEEKCONSENT#MODAL#HEADER' | translate}}</div>
        <div (click)="close()" class="float-right closeicon pointer">
          <img class="type-image" src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div class="content" style="overflow-y: scroll;">
        <div class="nuhdeek-content">
            <div class="content-enclosure">
                <div class="image-wrapper">
                    <img src="./assets/icons/nudheek-icon.svg" alt="">
                </div>
                <div class="nuhdeek-msg subheader-font">
                    {{'NUHDEEKCONSENT#MODAL#SUBHEADER' | translate}}
                </div>
            </div>
            <div class="footer-enclosure">
                <button class="primary-button" (click)="continue()">{{'NUHDEEKCONSENT#MODAL#BTN#AGREE#CONTINUE' | translate}}</button>
            </div>
        </div>
      </div>
    </div>
</div>
