import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-openreferrals',
  templateUrl: './openreferrals.component.html',
  styleUrls: ['./openreferrals.component.scss']
})
export class OpenreferralsComponent implements OnInit {
  @Input() openReferrals: Array<any>
  @Output() openReferralAppt: EventEmitter<any>
  
  public translateType = "LOCALE"
  constructor() {
    this.openReferralAppt = new EventEmitter<any>();
  }

  ngOnInit(): void {
  }

  public openReferralDetails(referral) {
    this.openReferralAppt.emit(referral);
  }

}
