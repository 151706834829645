import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from "rxjs";
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/user.service';
import { PhoneNumberUtil } from "google-libphonenumber";
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  @Input() contactUs: boolean = true;
  refresh: Subject<boolean> = new Subject<boolean>();
  public options: Array<any>;
  public curAction: string;
  public externalInterface: boolean;
  public isContactUs: boolean = true;
  public showModal: boolean = false;
  public selectedCard: any;
  public GetInTouchWithOptions: Array<any>;
  public submitEnquiryForm: Array<any>;
  public optionWithsubOption: Array<any>;
  public showClinicOpt: boolean;
  public hospitals: any[] = [];
  public currentLang: string = '';
  public selectedHospital: any = null;
  public showEnquiry: boolean = false;
  public selectedAboutNahdi: boolean = true;
  public userMobile: string = '';
  public userMail: string = '';
  public userQuery: string = '';
  public mobileNo: any;
  public mailId: string;
  public whatsAppLink: string;
  public emailvalidErrorMsg: boolean = false;
  public hasPhError: boolean = false;

  constructor(public modalService: ModalService, private user: UserService) {
    this.close = new EventEmitter<any>();
    this.options = options;
    console.info("opt", this.options)
    this.GetInTouchWithOptions = GetInTouchWithOptions;
    this.submitEnquiryForm = submitEnquiryForm;
    this.optionWithsubOption = optionWithsubOption;
    console.info("this.curAction", this.curAction);
    this.currentLang = this.user.getCurrentLanguage()
  }

  ngOnInit(): void {
    this.user.getUpdateInfo('facilities').subscribe(data => {
      let facility = data.facilities.filter(f => {
        const filter = !f?.['facilityControls']?.['schedulingRules']?.['pxAppSettings']?.['hideFaciltyUnderContactUs'];
        return filter;
      });
      this.hospitals = facility.map(f => {
        if (!_.isEmpty(f['facilityMapsURL']) && !f['facilityMapsURL'].includes('staticmap'))
          f['showDirection'] = true;
        return f;
      });
      // this.selectedHospital = this.hospitals[0];
      console.log('this.hospitals', this.hospitals)
    })
    this.user.getConfigurationInfo.subscribe(data => {
      let profile = data.profile;
      this.whatsAppLink = profile.whatsappLink;
    })
    this.userMobile = this.user.getPersonMobile();
    this.userMail = this.user.getPersonEmail();
    this.mobileNo = this.user.getLabelsBasedOnLang("common_helpline_mobile_no", this.user.getCurrentLanguage());
    this.mailId = this.user.getLabelsBasedOnLang("common_helpline_email_id", this.user.getCurrentLanguage());
    console.log('dataaaaa', this.mobileNo, this.mailId);
  }

  mailto(emailAddress: string) {
    console.log('mail', emailAddress)
    let mailText = "mailto:" + emailAddress;
    window.location.href = mailText;
  }

  callUs(num) {
    window.open("tel:" + this.mobileNo);
  }

  openWhatsApp(link) {
    window.open(link)
  }

  public emailValidation(mail) {
    console.log('mail', mail)
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.length > 0) {
      if (mail.match(mailformat)) {
        this.emailvalidErrorMsg = false;
      }
      else {
        this.emailvalidErrorMsg = true;
      }
    } else {
      this.emailvalidErrorMsg = false;
    }
  }

  checkIfPhoneNumber = (phone: string) => {
    let countryCode = this.user.getPersonCountryCode();
    const phoneWithCC = '+' + countryCode + ' ' + this.userMobile;
    console.log('value num', phone);
    let validation: boolean = false;
    if (null == phoneWithCC || typeof phoneWithCC == undefined || phoneWithCC.length < 5 || phoneWithCC.match(/[a-z]/i)) {
      return validation;
    } else {
      try {
        var phoneUtil = PhoneNumberUtil.getInstance();
        var number = phoneUtil.parse(phoneWithCC);
        validation = phoneUtil.isValidNumber(number);
        this.hasPhError = !validation
        return validation;
      } catch (error) {
        console.error(error);
        this.hasPhError = !validation
        return validation;
      }
    }
  };


  public closeModal() {
    if (this.showEnquiry)
      this.showEnquiry = !this.showEnquiry;
    else
      this.close.emit();
  }
  public changeCurAction(item) {
    if (item.action != this.curAction) {
      this.options = this.options.map(opt => {
        opt.visible = false;
        return opt;
      });
    }
    this.curAction = item.action;
    this.options = this.options.map(opt => {
      if (opt.action == item.action) {
        opt.visible = !opt.visible
      }
      return opt;
    });
    console.log("current action->", this.curAction);

  }

  // public changeChildCurAction(item) {
  //   this.curAction = item.action;
  // }
  public reset() {
    this.curAction = "";
    this.selectedHospital = null;
    this.refresh.next(true);
  }

  public onSelectHospital(item) {
    this.selectedHospital = item;
    this.selectedAboutNahdi = false;
  }

  public aboutNahdi() {
    this.selectedHospital = '';
    this.selectedAboutNahdi = true;
  }

  public submitEnquiry() {
    console.log('Submitted enquiry form');
    const payload = {
      'mcontent': {
        mobile: this.userMobile,
        email: this.userMail,
        enquiry: this.userQuery,
      },
      'personName': this.user.getPersonName(),
      'facilityId': this.user.getFacility(),
      'personId': this.user.getPersonId()
    }
    this.user.sendEnquiry(payload)
      .subscribe({
        next: () => {
          this.showEnquiry = false;
          this.userQuery = '';
        }
      })
  }

}
// const options = [
//   {
//     name: 'header_opt_profile_edit',
//     action: 'edit_profile',
//     icon: 'assets/icons/profile/user_clr.svg',
//     visible: false,
//   },
//   {
//     name: 'change_password',
//     action: 'change_password',
//     icon: 'assets/icons/profile/lock.svg',
//     visible: false,
//   },
//   {
//     name: 'change_number',
//     action: 'change_number',
//     icon: 'assets/icons/profile/mobile.svg',
//     visible: false,
//   },
//   {
//     name: 'change_email',
//     action: 'change_email',
//     icon: 'assets/icons/profile/gmail.svg',
//     visible: false,
//   },
//   {
//     name: 'lst_change_lang',
//     action: 'switch_lang',
//     icon: 'assets/icons/profile/translate.svg',
//     visible: false,
//   },
//   {
//     name: 'manage_payment_details',
//     action: 'payment_detail',
//     icon: 'assets/icons/profile/card.svg',
//     visible: false,
//   },{
//     name: 'lbl_manage_address_title',
//     action: 'manage_address',
//     icon: 'assets/icons/profile/address.svg',
//     visible: false,
//   },
//   {
//     name: 'logout',
//     action: 'logout',
//     icon: 'assets/icons/profile/sign_out.svg',
//     visible: false,
//   }
// ]

const options = [
  {
    name: 'AI Marwah Clinic',
    subname: 'Jeddah',
    action: 'first',
    visible: false,
    address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  },
  {
    name: 'AI Tahlia Clinic',
    subname: 'Mecca',
    action: 'second',
    visible: false,
    address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  },
  {
    name: 'AI Marwah Clinic',
    subname: 'Jeddah',
    action: 'third',
    visible: false,
    address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  },
  {
    name: 'AI Samer Clinic',
    subname: 'Jeddah',
    action: 'fourth',
    visible: false,
    address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  },

];

const GetInTouchWithOptions = [
  {
    name: 'Customercare@nahdi.sa',
    icon: 'assets/icons/contact-us/mail_outline.svg',
    action: 'customercare',
    visible: false,
    isMail: true
  },
  {
    name: '920024673',
    icon: 'assets/icons/contact-us/call.svg',
    action: 'mobile-no',
    visible: false,
    isMail: false
  }
];

const submitEnquiryForm = [
  {
    name: 'Enquiry form',
    icon: 'assets/icons/contact-us/enquiry-forms.svg',
    action: 'Enquiryform',
    visible: false,
  },



];

const optionWithsubOption = [
  {
    name: 'Riyadh',
    subname: [{ name: 'AI Marwah Clinic', action: "first", address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia' },
    { name: 'AI Tahlia Clinic', action: "first1", address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia' },
    { name: 'AI Tahlia Clinic', action: "first2", address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia' },
    { name: 'AI Samer Clinic', action: "first3", address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia' }],
    action: 'first',
    visible: false,
    address: 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  },
  // {
  //   name: 'Karnataka',
  //   subname: [ {name:'Kar1',action:"kar1"},
  //   {name:'Kar2',action:"kar2"},
  //   {name:'Kar3',action:"kar3"},
  //   {name:'Kar4',action:"kar4"}],
  //   action: 'second',
  //   visible: false,
  //   address : 'Jeddah,Abdullah Bin Khayat 23545,Saudi Arabia'
  // }

];
