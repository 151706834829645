<div id="contact-us" class="right-navigation showModal">
    <div class="component">
      <div class="header border-bottom">
        <div class="font-primary-subtitle bold contact-us-header">
          {{'VIDEO#CONSULTATION#LABEL#VC#TC' | translate}}
        </div>
        <div (click)="closeModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_grey.svg">
        </div>
      </div>
  
      <div class="content">
        <div class="refr-body">
          <div class="modal-content font-default-small" [innerHTML]="tandc|translate:'LOCALE':'packageContent'">
        </div>
      </div>
      <div class="footer">
        <button class="consent-btn" (click)="proceed();$event.stopPropagation()">
            {{'VIDEO#CONSULTATION#TC#BTN#AGREE#CONTINUE' | translate}}
        </button>
    </div>
    </div>
</div>
