import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { AppointmentService } from '../services/appointment.service';
import { ROUTE_LOGIN } from '../app.string';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from "rxjs";
import { FeatureFlagsService } from '../services/feature-flags.service';
import { ModalService } from '../services/modal.service';
import { LinkPatientService } from '../services/link-patient.service';
import { CouponService } from '../services/coupon.service';
import { UtilitiesService } from '../services/utilities.service';
import { environment } from '@env/environment';
@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  refresh: Subject<boolean> = new Subject<boolean>();
  refreshInternalCoupon: Subject<Object> = new Subject<Object>();
  public options: Array<any>
  public curAction: string;
  public isSelected: string;
  public languageList: Array<any>;
  public personDetails: any;
  public externalInterface: boolean;
  public showModal: boolean = false;
  public selectedCard: any;
  public isMangeAccount: boolean = true;
  public manageAddressFormDetails: object;
  public isExtIdLogin: boolean = false;
  public isManageIdentities: boolean;
  public showSavedCardsModal: boolean = false;
  public entity;
  public relations;
  public relationType;
  public linkedPatients;
  public linkedId;
  public recordsToLink;
  public showCoupon: boolean = false;
  public couponList: any;
  public showInsuranceModal: boolean = false;
  public showConsentModal: boolean = false;
  public consentDetails; any;
  public isConsent: boolean;
  public userInfo: any;
  constructor(private user: UserService, private router: Router, private route: ActivatedRoute, private auth: AuthService, private apptService: AppointmentService, private featureService: FeatureFlagsService, public modalService: ModalService, private linkPatient: LinkPatientService, private couponService: CouponService, private utils: UtilitiesService) {
    this.close = new EventEmitter<any>();
    this.options = options;
    this.isConsent = this.user.getMarketingConsent();
    this.getUserProfile()
    console.info("opt", this.options)
    this.isSelected = this.user.currentLanguage;
    // this.curAction = this.options[0].action;
    console.info("this.curAction", this.curAction)
  }

  ngOnInit(): void {
    this.user.getUserInfo('personDetails').subscribe(data => {
      this.personDetails = data;
    })
    this.user.getExternalInterface().subscribe(data => {
      this.externalInterface = data;
      if (!data) {
        this.options.splice(3, 1);
      }
      this.setName();
    })
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });
    this.user.getConfigurationInfo.subscribe(data => {
      console.log('profile', data)
      this.consentDetails = data.profile.manageconcent;
      if(this.consentDetails && this.consentDetails.enableConcent ==  "N") {
      this.options.filter((o) => {
        o.forEach((i) => {
          if (i['action'] == 'manage_consent') {
            i['hide'] = true;
          }
        })
      })
    }
  })
    this.user.getConfigurationInfo.subscribe(data => {
      if (data.pxAppSettings) {
        if (data.pxAppSettings.showPaymentDetail == 'Y') {
          this.options = options;
        }
        else {
          this.options = this.options.filter(item => {
            return item.action != 'payment_detail'
          })
        }
        if (data.pxAppSettings.manageMultipleAdderss) {
          this.options = options;
        }
        else {
          this.options = this.options.filter(item => {
            return item.action != 'manage_address'
          })
        }

        if (data.pxAppSettings.hideChangeNumber == 'Y') {
          this.options.filter((o) => {
            o.forEach((i) => {
              if (i['action'] == 'change_number') {
                i['hide'] = true;
              }
            })
          })
        }
      }
    });
    this.isExtIdLogin = this.featureService.featureOn('FT_APP_EXTIDLOGIN');
    if (this.isExtIdLogin) {
      this.options = this.options.filter(item => {
        return item.action != 'change_number'
      })
    }
    this.initLinkedRecords();
  }

  public getUserCoupons() {
    this.user.getSSOUserObject().subscribe(data => {
      let payload = {
        "loyaltyId": data.username,
        "locale": this.user.currentLanguage
      }
      let loyaltyId = data.username;
      this.couponService.getCoupons(payload).subscribe(response => {
        console.log("getCoupons", response)
        this.couponList = response.data;
        this.couponList.map(item => {
          item['checked'] = false
          return item['loyaltyId'] = loyaltyId
        })
        console.log("loyaltyId", this.couponList)
        this.user.setCoupons(this.couponList);
      })
    })
  }

  applyCoupon(ev) {
    this.showCoupon = false;
  }


  public closeModal() {
    this.close.emit();
  }
  public changeCurAction(item) {

    switch (item.action) {
      case 'logout':
        this.logout();
        break;
      case 'my_family':
        this.openModal('manage-identifier-modal')
        break;
      case 'my_saved_cards':
        this.openSavedCardsModal()
        break;
      case 'my_coupons':
        this.openCouponModal();
        break;
      case 'manage_insurance_policy':
        this.openInsuranceModal();
        break;
      case 'terms_cond':
        this.openLink('tos');
      default:
        this.curAction = item.action;
        break;
    }
  }

  private openSavedCardsModal() {
    this.showSavedCardsModal = true;
  }

  public closeSavedCards() {
    this.showSavedCardsModal = false;
  }

  public openCouponModal() {
    this.showCoupon = true;
    this.getUserCoupons();
  }


  closeCouponModal(event) {
    console.log('coupon', event);
    this.showCoupon = false;
  }

  public openInsuranceModal() {
    this.showInsuranceModal = true;
  }

  public closeInsuranceModal() {
    this.showInsuranceModal = false
  }

  public logout() {
    // this.auth.signOut();
    if (this.externalInterface === true) {
      this.auth.ssoSignOut().subscribe(_ => {
        this.user.clearStorage();
        this.router.navigate([`/`]);
        location.reload()

      })
    }
    else {
      this.user.clearStorage();
      this.router.navigate([`/`]);
      location.reload();
    }
  }

  public onItemChange(item) {
    if (item.localeCode == this.isSelected) {
      this.isSelected = item.localeCode;
      return;
    } else {
      this.user.setDirection(item);
      this.isSelected = item.localeCode;
    }
  }
  public reset(event?: any) {
    this.setName();
    if (event && event.name == 'removeCard') {
      this.showModal = true;
      this.selectedCard = event.item
    }
    else {
      this.curAction = "";
    }
    this.refresh.next(true);
  }

  public exit() {
    this.showModal = false;
  }

  public removeCard() {
    this.deleteCard();
  }

  public deleteCard() {
    this.showModal = false;
    let payload = {
      "paymentMode": this.selectedCard.paymentMode,
      "cardNumber": this.selectedCard.cardNumber,
      "savedCardToken": this.selectedCard.savedCardToken,
      "expiryDate": this.selectedCard.expiryDate,
      "transactionId": this.selectedCard.transactionId
    }
    this.apptService.removeSavedCard(payload).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.refresh.next(true);
        this.showModal = false;
      }
    })
  }

  manageConsent(val) {
    console.log('manage consent', val);
    this.isConsent = val;
    this.updateProfile()
  }

  public getUserProfile(val?) {
    if(!this.user.isLoggedIn()) return
    this.auth.getUserProfile().subscribe(data => {
       this.userInfo = data
       this.isConsent = data.marketingConsent;
       this.user.setSSOObjectOnUpdate(data);
       console.log("updateUserProfile", data)
       if(val)
        this.user.setMarketingConsent(data.marketingConsent)
    })
  }

  public updateProfile() {
      let payload = {
        "personId": this.user.getPersonId(),
        "username": this.userInfo.username ? this.userInfo.username : this.userInfo.userName,
        "firstName":  this.userInfo.firstname ? this.userInfo.firstname : this.userInfo.firstName,
        "lastName": this.userInfo.lastname ? this.userInfo.lastname : this.userInfo.lastName,
        "gender": this.userInfo.gender,
        "locale": this.userInfo.language,
        "dob": this.userInfo.birth_date,
        "mandatoryConsent": this.userInfo.mandatoryConsent,
        "marketingConsent": this.isConsent,
       
      }
      this.auth.updateUserProfile(payload, 'CONSENT').subscribe(_ => {
        this.user.updateUserInfo({ personName: payload['firstName'] + " "+ payload['lastName']})
          this.showConsentModal = false;
          this.getUserProfile(true)
      })
    } 

    public openLink(type) {
      console.log('type', environment);
      if (type == 'pp') {
        let link = environment['urlInfo']['privacypolicy'];
        if (environment['urlInfo']['specifyLanguage'])
          link = this.utils.replaceLanguage(link, this.user.currentLanguage);
        this.user.openLocation(link, "_blank");
      }
      if (type == 'tos') {
        let link = environment['urlInfo']['termscondition'];
        if (environment['urlInfo']['specifyLanguage'])
          link = this.utils.replaceLanguage(link, this.user.currentLanguage);
        this.user.openLocation(link, "_blank");
      }
    }

  public setName() {
    if (this.externalInterface) {
      this.user.getSSOUserObject().subscribe(data => {
        this.personDetails = {
          personName: data.firstname + " " + data.lastname
        }
      })
    } else {
      this.user.getUserInfo('personDetails').subscribe(data => {
        this.personDetails = data;
      })
    }
  }

  public openManageAddressForm(data) {
    this.isMangeAccount = false;
    this.manageAddressFormDetails = data;
    console.log("data to send manageaddress", data);
  }

  public closeManageAddressForm() {
    this.isMangeAccount = true;
    this.manageAddressFormDetails = null;
  }

  public deleteAccount() {
    console.log('clickec')
    let url = "https://healtht.nahdi.sa/dev/static/tos/en.html"
    window.open(url, '_blank');
  }
  payment = function (win) {

  }

  public closeManageIdentities() {
    this.isManageIdentities = false;
  }
  openModal(id: string) {
    if (id == 'manage-identifier-modal') {
      this.isManageIdentities = true;
    }
    this.modalService.open(id);
  }

  public initLinkedRecords() {
    this.getLinkedPatient();
    this.getEntityId();
    this.getRelationList();
  }

  private getLinkedPatient() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.linkPatient.getLinkedPatient(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            this.getPatientsToLink();
          }
        })
      }
    })
  }

  private getEntityId() {
    this.user.getConfigurationInfo.subscribe(data => {
      this.entity = data.entityId;
    })
  }
  private getRelationList() {
    this.user.getMasterConfig.subscribe(data => {
      this.relationType = data.list;
      this.relationType = this.relationType.filter(arr => {
        return arr.domain === 'RELATIONSHIP_TYPE'
      })
      this.relations = this.relationType[0].codes
    });
  }
  private getPatientsToLink() {
    if (this.linkedPatients) {
      this.linkedId = this.linkedPatients.map(item => {
        return item.uhid;
      })
    }
    this.user.getUserCredential().subscribe(data => {
      if (data) {
        this.linkPatient.getPatientsToLink(data).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.recordsToLink = result.data.filter((item) => {
              return this.linkedId.indexOf(item.patientUhid) === -1
            });
          }
        })
      }
    })
  }
}

const options = [
  [
    {
      name: 'Manage_my_family',
      action: 'my_family',
      icon: 'assets/icons/manage-account/manage_family.svg',
      visible: false,
    },
    {
      name: 'Manage_insurance_policy',
      action: 'manage_insurance_policy',
      icon: 'assets/icons/manage-insurance/insurance_prim.svg',
      visible: false,
    },
    {
      name: 'Manage_saved_cards',
      action: 'my_saved_cards',
      icon: 'assets/icons/payment/card_primary_icon.svg',
      visible: false,
    },
    {
      name: 'Nuhdeek',
      action: 'my_coupons',
      icon: 'assets/icons/nuhdeek/nuhdeekaccount.svg',
      visible: false,
    }
  ], [
    {
      name: 'Change_password',
      action: 'change_password',
      icon: 'assets/icons/manage-account/change_password.svg',
      visible: false,
    },
    {
      name: 'Change_mobile_number',
      action: 'change_number',
      icon: 'assets/icons/manage-account/change_mobile_number.svg',
      visible: false,
    },
    {
      name: 'Change_E-Mail_ID',
      action: 'change_email',
      icon: 'assets/icons/manage-account/mail_outline.svg',
      visible: false,
    }
  ], [
    {
      name: 'About_us',
      action: 'about_us',
      icon: 'assets/icons/manage-account/aboutus.svg',
      visible: false,
    },
    {
      name: 'Contact_us',
      action: 'contact_us',
      icon: 'assets/icons/manage-account/contact_us.svg',
      visible: false,
    },
    {
      name: 'Terms_&_Conditions',
      action: 'terms_cond',
      icon: 'assets/icons/manage-account/terms_conditions.svg',
      visible: false,
    },
    {
      name: 'Manage_my_data_privacy_and_consent',
      action: 'manage_consent',
      icon: 'assets/icons/manage-account/privacy.svg',
      visible: false,
    }
  ]
]
