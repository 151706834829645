import { Directive, ElementRef, Input } from '@angular/core';
import { FeatureFlagsService } from '../services/feature-flags.service';

@Directive({
  selector: '[appRemoveFeatureOff]'
})
export class RemoveFeatureOffDirective {
  @Input('appRemoveFeatureOff') featureName: string;

  constructor(private el: ElementRef, private featureFlagService: FeatureFlagsService) { }

  ngOnInit() {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
