<div id="appointments">
  <p class="home-section-subtitle">
    <span class="title-label">
      {{'Upcoming_appointments' | translate}} 
    </span>
    <span class="see-all"
      (click)="onSeeAll()">
      {{'See_all' | translate}}
      <img class="title-icon" src="assets/icons/slot/right_arrow.svg" alt="arrow">
    </span>
  </p>
  <div class="appt-list-view" *ngIf="!showAsSlide">
    <div class="appt-list-card" *ngFor="let item of upcomingAppointments | sort : 'appointmentDateTime' : 'moment'">
      <ng-container [ngTemplateOutlet]="APPTCARD" [ngTemplateOutletContext]="{item:item}"></ng-container>
    </div>
  </div>
  <swiper-container pagination="true" class="appt-list-view" *ngIf="showAsSlide" #apptSwiper>
    <swiper-slide class="appt-list-card-slider" *ngFor="let item of upcomingAppointments | sort : 'appointmentDateTime' : 'moment'">
      <ng-container [ngTemplateOutlet]="APPTCARD" [ngTemplateOutletContext]="{item:item}"></ng-container>
    </swiper-slide>
  </swiper-container>
</div>

<ng-template #APPTCARD let-item="item">
  <div class="appt-list-card-for-slider" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')">
    <div class="appt-card-header video-header" *ngIf="item['appointmentType'] == 'VIDEO'">
      <img class="type-image" src="assets/icons/Appointments/wvc.svg" alt=""> {{'Video_consultation' | translate}}
      <span *ngIf="item['isOnspot']"> - {{'Onspot' | translate}}</span>
    </div>
    <div class="appt-card-header clinic-header"
      *ngIf="item['appointmentType'] != 'VIDEO' && item['appointmentType'] != 'HC'">
      <img class="type-image" src="assets/icons/Appointments/wclinic.svg" alt=""> {{'Clinic_consultation' | translate}}
    </div>
    <div class="appt-card-header hc-header" *ngIf="item['appointmentType'] == 'HC'">
      <img class="type-image" src="assets/icons/Appointments/whc.svg" alt=""> {{'Home_health_care' | translate}}
    </div>
    <div class="appointment-content">
      <div class="person-avatar-wrapper">
        <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
      </div>
      <div class="appt-context">
        <div class="appt-title">{{item['practitionerName']}}</div>
        <div class="appt-subtitle"> {{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' |
          specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</div>
      </div>
      <div class="item-detail">
        <div class="timeline-button" *ngIf="item['appointmentType']">
            <ng-container *ngIf="(item['appointmentType'] == 'VIDEO' ?  item['_actions']?.['canCheckInVC']: item['_actions']?.['canCheckInIP']) && item['_actions']?.['actionType'] == 'CHECKIN'">
              <button class="button-primary" (click)="handleActions(item, item['_actions']?.['actionType'], null); $event.stopPropagation();">
                {{'cal_modal_btn_checkin'|translate}}
              </button>
            </ng-container>
            <ng-container *ngIf="item['_actions']?.['checkinStatus'] == 'PAUSE' && item['_actions']?.canRejoin">
               <button class="button-primary" (click)="handleActions(item, item['_actions']?.['actionType'], null); $event.stopPropagation();">
                {{'cal_modal_btn_join'|translate}}
              </button>
            </ng-container>
            <ng-container *ngIf="(item['appointmentType'] == 'VIDEO' ?  item['_actions']?.['canCheckInVC']: item['_actions']?.['canCheckInIP']) && item['_actions']?.['actionType'] == 'PAYCHECKIN'">
              <button class="button-primary" (click)="handleActions(item, item['_actions']?.['actionType'], null); $event.stopPropagation();">
                {{'cal_modal_btn_pay_and_checkin'|translate}}
              </button>
            </ng-container>
            <ng-container *ngIf="item['_actions']?.['actionType'] == 'PAY'">
              <button class="button-primary" (click)="handleActions(item, item['_actions']?.['actionType'], null); $event.stopPropagation();">
                {{'appt_but_pay'|translate}}
              </button>
            </ng-container>
        </div>
      </div>
    </div>
    <div class="appointment-for-content">
      <p class="appt-for"> {{'For:' | translate}}
        <span class="bold">{{item['personName']}}</span>
      <p class="appt-for" *ngIf="!(item['paymentDetail']['status'] != 'PAID' && item['checkInAllowed'] !='true')">
        {{'Status:' | translate}}
        <span class="bold appt-status__desc">{{item['consultationStatus'] || item['visitStatus']?.['status'] ||
          item['paymentDetail']?.['status'] || '-'}}</span>
    </div>
    <div class="appt-date-context">
      <div class="appt-date-section">
        <img src="./assets/icons/Appointments/calendar.svg" alt="">

        <app-appt-time type="CARD1" [time]="item['appointmentDateTime']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facilityId'])" [facilityId]="item['facilityId']"></app-appt-time>
      </div>
      <div class="appt-date-section">
        <img src="./assets/icons/Book_new/location.svg" alt="">
        {{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}
      </div>
    </div>
  </div>
</ng-template>

<app-custom-modal-container id="calendar-appointment-details-modal" (complete)="closeApptDetail()">
  <app-upcoming-appointment-detail *ngIf="appointmentDetail" [viewAppointment]="appointmentData"
    (reschedule)="openApptView($event)" (expressReschedule)="openExpressView($event)"
    (uploadQuestionnarie)="openuploadQuestionnarie($event)" (uploadDocs)="openuploadDocs($event)"
    [initCheckin]="initCheckin">
  </app-upcoming-appointment-detail>
</app-custom-modal-container>
<app-register-new-patient *ngIf="isRegisterPatient" (close)="closeRegisterPatient()"></app-register-new-patient>
<app-custom-modal-container id="preconsult-questionnaire-modal" (close)="closeuploadQuestionnarie()">
  <app-appointment-upload-questionnarie [selectedAppt]="selectedAppt" *ngIf="isUploadQuestionnarie"
    (close)="closeuploadQuestionnarie()"></app-appointment-upload-questionnarie>
</app-custom-modal-container>

<app-custom-modal-container id="upload-document-modal" (close)="closeuploadDocs()">
  <app-hr-document-upload *ngIf="isUploadDocs" [appointment]="selectedAppt" [selectedPatient]="selectedAppt"
    (close)="closeuploadDocs()">
  </app-hr-document-upload>
</app-custom-modal-container>

<app-custom-modal-container id="reschedule-appt-modal" (close)="closeApptView($event)">
  <app-book-appointment *ngIf="isApptView" (close)="closeApptView($event)" [rescheduleData]="rescheduleData"
    [orderdetails]="orderdetails" [prefillData]="prefillData"
    [ongoingAppts]="appointment_history"></app-book-appointment>
</app-custom-modal-container>
<app-express-clinic-modal *ngIf="isExpress" (close)="closeExpressClinic()"
  [rescheduleData]="rescheduleData"></app-express-clinic-modal>
<app-map [routeItem]="routeItem" (complete)="closeMap()" *ngIf="openRoute"></app-map>