<div class="flip-cards">
  <div class="flip-cards-inner">
    <div class="flip-cards-front">
      <div class="modal-header header border-bottom">
        <div class="header-row">
          <div class="back-btn" *ngIf="visitInfo" (click)="closeModal('calendar-appointment-details-modal')">
            <img class="type-image" src="./assets/icons/appt-preview/arrow.svg">
          </div>
          <div class="title bold" *ngIf="!visitInfo">{{'cal_modal_header_appt'|translate:'LABELS'}}
          </div>
          <div class="title bold" *ngIf="visitInfo">{{'Check-in_successful'|translate:'LABELS'}}
          </div>
        </div>
        <div (click)="closeModal('calendar-appointment-details-modal')" class="float-right closeicon pointer">
          <img class="type-image" src="./assets/icons/close_black.svg">
        </div>
      </div>
      <div id=upcoming-appointment-detail class="modal-content" [ngClass]="{'adjust-height':  isCheckout || (primaryActions && primaryActions.length == 0 ), 'modal-height':  (!isCheckout && primaryActions && primaryActions.length > 0 ), 'checkin-height': visitInfo}">
        <div class="new-card-review" *ngIf="!visitInfo">
          <div class="col">
            <ng-container>
              <app-appt-dtl-card [apptInfo]="viewAppointment"></app-appt-dtl-card>
            </ng-container>
            <ng-container *ngIf="serviceDetails.length">
              <p class="font-default-small bold">{{"APPTPREVIEW#CONFIRM#LABEL#BOOKED_SERVICES" | translate}}</p>
              <div class="payment-item card" *ngFor="let service of serviceDetails">
                <div class="payment-item-content">
                  <div class="subheader-font">{{ getServiceName(service) }}</div>
                  <div class="def-subtitle bold">
                    <span>{{service['serviceCost']}} {{currency_code}} </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="homecareService.length">
              <p class="font-default-small bold">{{"APPTPREVIEW#CONFIRM#LABEL#BOOKED_SERVICES" | translate}}</p>
              <div class="payment-item card" *ngFor="let service of homecareService">
                <div class="payment-item-content">
                  <div class="subheader-font">{{ service['name'] }}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col">
            <div class="payment-details-list" *ngIf="actionType == 'PAYCHECKIN' || actionType == 'PAY'">
              <div *ngIf="isEnablePoints && pointsType == 'PAYMENT'" class="promo-container-div coupon-input">
                <app-altpayment-input [input]="'POINTS'"
                  [promoCodeDetails]="viewAppointment.chargeDetails.promoCodeDetails"
                  [totalAmount]="viewAppointment.chargeDetails.totalAmount"
                  [netPayable]="viewAppointment.chargeDetails._payableWithoutPoints" (pointsApplied)="pointsApplied($event)"
                  #points (applyingPoints)="applyingPoints($event)"></app-altpayment-input>
              </div>
              <div
                *ngIf=" (isEnablePromo || (isEnablePoints && pointsType == 'DISCOUNT')) && viewAppointment.chargeDetails.discountDetails && viewAppointment.chargeDetails.discountDetails.applicable"
                class="promo-container-div ">
                  <app-promo-input [selections]="promoCodeParam"
                    [discountDetails]="viewAppointment.chargeDetails.discountDetails"
                    [patientPayable]="viewAppointment.chargeDetails.totalAmount" (applyingDiscount)="applyingPromoCode($event)"
                    (appliedDiscount)="appliedPromocode($event)" [isEnablePoints]="isEnablePoints && pointsType == 'DISCOUNT'"
                    [isEnablePromo]="isEnablePromo" [parsedChargeInfo]="chargeVatDetails ? chargeVatDetails: null"></app-promo-input>
              </div>
              <div *ngIf="isEnableCoupon" class="promo-container-div coupon-input">
                <app-altpayment-input [input]="'COUPON'" [promoCodeDetails]="viewAppointment.chargeDetails.promoCodeDetails" [totalAmount]="viewAppointment.chargeDetails.totalAmount" [netPayable]="viewAppointment.chargeDetails._payableWithoutCoupon" (couponApplied)="couponApplied($event)" #coupon (applyingCoupon)="applyingCouponCode($event)"></app-altpayment-input>
              </div>
            </div>
            <p class="font-default-small bold">{{'cal_modal_payment_details'|translate:'LABELS'}}</p>
            <div class="payment-item card">
              <div class="payment-item-content" *ngIf="viewAppointment.chargeDetails && viewAppointment.chargeDetails.totalAmount">
                <div class="subheader-font">{{'Total_payable'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <app-display-currency [amount]="viewAppointment.chargeDetails.totalAmount"></app-display-currency>
                </div>
              </div>
              <div class="payment-item-content" *ngIf="viewAppointment._state == 'UPCOMING'">
                <div class="subheader-font">{{'cal_modal_label_payment_cat'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <span *ngIf="!viewAppointment.chargeDetails.billingGroupCode || viewAppointment.chargeDetails.billingGroupCode == 'CASH'">
                    {{"APPTPREVIEW#CONFIRM#LABEL#PAYMENT_MODE_CASH" | translate}}
                  </span>
                  <span *ngIf="viewAppointment.chargeDetails.billingGroupCode && viewAppointment.chargeDetails.billingGroupCode !== 'CASH'">
                    {{"APPTPREVIEW#CONFIRM#LABEL#INSURANCE" | translate}}
                  </span>
                </div>
              </div>
              <div class="payment-item-content" *ngIf="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.isPromoApplied) || promoAmtForPaidAppt">
                <div class="subheader-font">{{'lbl_Promocode'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <app-display-currency [amount]="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.promoDiscount) ? viewAppointment.chargeDetails.promoDiscount : promoAmtForPaidAppt"></app-display-currency>
                </div>
              </div>
              <div class="payment-item-content" *ngIf="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.couponDiscount) || totalCouponBurntAmt">
                <div class="subheader-font">{{'lbl_coupon'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <app-display-currency [amount]="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.couponDiscount) ? viewAppointment.chargeDetails.couponDiscount : totalCouponBurntAmt"></app-display-currency>
                </div>
              </div>
              <div class="payment-item-content" *ngIf="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.pointsDiscount) || totalPointsPaid">
                <div class="subheader-font">{{'lbl_points'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <app-display-currency [amount]="(viewAppointment.chargeDetails && viewAppointment.chargeDetails.pointsDiscount) ? viewAppointment.chargeDetails.pointsDiscount : totalPointsPaid" [prefix]="'-'"></app-display-currency>
                </div>
              </div>

              <div class="payment-item-content" *ngIf="chargeVatDetails && chargeVatDetails.vatDisplayAmount">
                <div class="subheader-font">{{'appt_req_lbl_vat_amount'|translate:'LABELS'}}</div>
                <div class="def-subtitle bold">
                  <app-display-currency [amount]="chargeVatDetails.vatDisplayAmount"></app-display-currency>
                </div>
              </div>

              <div class="payment-item-content">
                <div class="subheader-font">{{(alreadyPaid ? 'lbl_amount_paid_via_online' : 'cal_modal_label_amount_to_be_paid') |translate:'LABELS'}}</div>
                <div class="prim-ttl-txt bold">
                  <app-display-currency displayDirection="row" [amount]="viewAppointment.paymentDetail.status == 'PAID'?viewAppointment.paymentDetail.amount : (viewAppointment.chargeDetails ? viewAppointment.chargeDetails.netPayable : '')">
                  </app-display-currency>
                </div>
              </div>
            </div>

            <ng-container
              *ngIf="viewAppointment['appointmentType'] != 'CLINIC' && viewAppointment['appointmentType'] != HOMECARE">
              <p class="font-default-small bold">{{'cal_modal_appt_guidelines'|translate:'LABELS'}}</p>
              <div class="card payment-item ">
                <p class="font-default-small padding">{{'cal_modal_appt_guidelines_brief' |translate:'LABELS'}}</p>
              </div>
            </ng-container>

            <ng-container
              *ngIf="viewAppointment['appointmentType'] != 'VIDEO' && viewAppointment['appointmentType'] != HOMECARE">
              <p class="font-default-small bold">{{'cal_modal_vst_reg'|translate:'LABELS'}}</p>
              <div class="card payment-item ">
                <p class="font-default-small padding">{{'cal_modal_vst_reg_brief' |translate:'LABELS'}}</p>
              </div>
            </ng-container>

          </div>
        </div>
        <ng-container *ngIf="isCheckout">
          <div class="checkout" [ngClass]="{hidden: !isCheckout}">
            <div class="modal-header header">
              <div class="title bold">{{'cal_modal_header_appt'|translate:'LABELS'}}</div>
              <div (click)="closeModal('calendar-appointment-details-modal')" class="float-right closeicon pointer">
                <img class="type-image" src="./assets/icons/close_black.svg">
              </div>
            </div>
            <div class="content">
              <app-payment-checkout></app-payment-checkout>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="visitInfo">
          <div id="appointment-queue-preview">
            <div class="card-container">
              <div class="card">
                <div class="checkin-receipt-container">
                  <div class="top-box">
                    <div class="success-label-checkin"
                      [ngClass]="{'success-checkin': (!checkinResponseData || !checkinResponseData.queueNo)}">
                      <img class="tick-icon" src="assets/icons/tick_white.svg">
                      <div class="msg-con">
                        <p>{{'checkin_queue_confirm_success_msg' | translate : 'LABELS'}}</p>
                      </div>
                    </div>
                    <div class="success-map-label"
                      *ngIf="(mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show))">
                      <p>{{ 'CHECKIN_QUEUE_CONFIRMATION#SUCCESS#MAPMESSAGE' | translate}} <span
                          *ngIf="mappingFloorControls.floor.show && (mappingFloorInfo && mappingFloorInfo.floor)">{{getFloorName(mappingFloorInfo.floor)+
                          ', '}}</span> <span
                          *ngIf="mappingFloorControls.waitingArea.show && (mappingFloorInfo && mappingFloorInfo.waitingArea)">
                          {{'CHECKIN_QUEUE_CONFIRMATION#LABEL#MAPPINGINFO#WAITINGAREA' | translate}}
                          {{mappingFloorInfo.waitingArea+ ','}}</span><span
                          *ngIf="mappingFloorControls.room.show && (mappingFloorInfo && mappingFloorInfo.room)">{{'CHECKIN_QUEUE_CONFIRMATION#LABEL#MAPPINGINFO#ROOM'
                          | translate}} {{mappingFloorInfo.room}}</span></p>
                    </div>
                    <div class="queue-container" *ngIf="checkinResponseData.queueNo">
                      <p class="queue-info-label">{{
                        'checkin_queue_confirm_success_your_no' | translate : 'LABELS'}}</p>
                      <div class="queue-con" *ngIf="checkinResponseData.queueNo">
                        <p>
                          {{checkinResponseData.queueNo}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="patient-info-container"
                    [ngClass]="{'pat-info-con': (mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show)) || (checkinResponseData && checkinResponseData.queueNo)}">
                    <div class="avatar"
                      [ngClass]="{'avatar-with-mapFloor':(mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show)), 'avatar-with-queueno': (checkinResponseData && checkinResponseData.queueNo), 'waitingarea-queue': ((mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show)) && (checkinResponseData && checkinResponseData.queueNo))}">
                      <app-person-avatar [url]="(viewAppointment['resourceId']|partitionerDetails:
                      viewAppointment['facilityId']:'resourceAvatarURL')" [default]="true"> </app-person-avatar>
                    </div>
                    <p class="dr-name">
                      <ng-container *ngIf="viewAppointment.practitionerLocalName">
                        {{viewAppointment.practitionerLocalName | translate : 'LOCALE' : 'name'}}
                      </ng-container>
                      <ng-container *ngIf="!viewAppointment.practitionerLocalName && viewAppointment.practitionerName">
                        {{viewAppointment.practitionerName}}
                      </ng-container>
                      <ng-container *ngIf="!viewAppointment.practitionerLocalName && !viewAppointment.practitionerName">
                        {{(viewAppointment['resourceId']|partitionerDetails:viewAppointment['facilityId']:'resourceName'|
                        translate : 'LOCALE' : 'name') || 'Doctor'}}
                      </ng-container>
                    </p>
                    <p class="dr-class">{{(viewAppointment['resourceId']|partitionerDetails:
                      viewAppointment['facilityId']:'specialityId') | specialityDetails:
                      viewAppointment['facilityId']:'specialityName'| translate : 'LOCALE' : 'name'}}</p>
                    <div class="patient-name-con borders-css"
                      [ngClass]="{'pat-dtl-margin': (mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show)) || (checkinResponseData && checkinResponseData.queueNo)}">
                      <p class="patient-label">{{ 'checkin_queue_confirm_success_patientname' | translate : 'LABELS'}}
                      </p>
                      <p class="patient-value"> {{viewAppointment['personName'] ? viewAppointment['personName']: ''}}
                        <span>{{ viewAppointment['patientId'] }}</span>
                      </p>
                    </div>
                    <div class="patient-name-con"
                      [ngClass]="{'pat-dtl-margin': (mappingFloorControls && (mappingFloorControls.floor.show || mappingFloorControls.room.show || mappingFloorControls.waitingArea.show)) || (checkinResponseData && checkinResponseData.queueNo)}">
                      <p class="patient-label">{{ 'checkin_queue_confirm_success_datetime' | translate : 'LABELS'}}</p>
                      <p class="patient-value">{{ viewAppointment['appointmentDateTime'] | moment : 'DD MMM YYYY, hh:mm'
                        : 'YYYY-MM-DDHH:mm'}}</p>
                    </div>
                  </div>
                  <div class="triangel-con">
                    <div *ngFor="let t of triangleArr" class="triangle-css">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
      </div>
      <div class="footer" [ngClass]="{'hide-footer':  isCheckout || (primaryActions && primaryActions.length == 0)}" *ngIf="!visitInfo">
        <ng-container>
          <div class="show-actions">
            <div class="dp-select">
              <button class="primary-button-outline" (click)="showAction()">{{'cal_modal_actions' | translate}}</button>
            </div>
            <div class="dp-option card" *ngIf="isActionOpen">
              <div class="dp-list">
                <div class="input-group" *ngFor="let item of primaryActions" (click)="item.handler()">
                  <img src="{{item.icon}}">
                  <span>{{ item.text }} </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="button-bar hide-actions">
            <div *ngFor="let item of primaryActions">
              <ng-container *ngIf="!item['isRight']">
                <button [ngClass]="{ 'primary-button' : item.cssClass , 'primary-button-outline' : !item.cssClass }"
                  (click)="item.handler()">{{ item.text }}</button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="button-bar hide-actions right-button">
          <div *ngFor="let item of primaryActions">
            <ng-container *ngIf="item['isRight']">
              <button [ngClass]="{ 'primary-button' : item.cssClass , 'primary-button-outline' : !item.cssClass }"
                (click)="item.handler()">{{ item.text }}</button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="flip-cards-back">
      
    </div>
  </div>
</div>