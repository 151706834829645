<div class="header-container" [ngClass]="headerClass">
    <div class="user-welcome logo" (click)="openInfo()">
      <img class="type-image"  src="assets/images/signin/nahdi_logo.png"/>
      <img *ngIf="isTaskView" class="type-image-light" src="assets/images/signin/logo_color_bg.svg" />
    </div>
    <div class="welcome-text" [ngClass]="{'sm-hidden': !showWelcome, 'full-space': !isLoggedIn}">
      <p class="welcome-title">{{'lbl_welcome_to' | translate}}</p>
      <p class="welcome-subtitle" *ngIf="!isLoggedIn">{{'lbl_login_for_benefits' | translate}}</p>
      <p class="welcome-subtitle" *ngIf="personDetails">{{personDetails? personDetails.personName: ''}}</p>
    </div>
    <div class="nav-container" *ngIf="isLoggedIn">
      <app-navigation [selectedPath]="path"></app-navigation>
    </div>
    <div class="actions-container">
      <div class="chat cursor-pointer lang-icon type-image" id="language-actions" (click)="switchLanguageAction()">
        <label class="locale-name" (click)="onItemChange(isNotSelected); $event.stopPropagation()">
          {{isNotSelected['localName']}}
        </label>
        <img src="assets/icons/header/world.svg"/>
      </div>
      <button class="button-primary" *ngIf="!isLoggedIn" (click)="openLogin.emit()">{{'Login_/_Sign_up' | translate}}</button>
      <div class="notifications cursor-pointer"  *ngIf="isLoggedIn" (click)="openModal('notification-modal')">
        <img src="assets/icons/Home/notification.svg"/>
        <div class="notify-badge">.</div>
      </div>
      <div class="chat cursor-pointer type-image" id="user-account-button-wrapper" *ngIf="isLoggedIn" (click)="showMyAccountOptions = !showMyAccountOptions" >
        <img class="type-image" src="assets/icons/Home/Account.svg"/>
      </div>
    </div>
  <div class="custom-home-msg-container" *ngIf="enableAlertOnServiceUpdateNotification">
    <div class="custom-home-msg-item">
      <span class="">
        <img class="type-image" src="assets/icons/Update.svg" />
        <label>
          {{updateAlertInfo['title'] || 'HOME#NOTIFY#SERVICEREQUPDATED' | translate}}
        </label>
      </span>
      <div class="close-action-icon pointer">
        <img class="type-image"  src="assets/icons/close_grey.svg" (click)="enableAlertOnServiceUpdateNotification=false;updateAlertInfo={}" />
      </div>
    </div>
    <p class="custom-home-msg-sub-header" *ngIf="updateAlertInfo && updateAlertInfo.body">
      <span>
        {{updateAlertInfo.body}}
      </span>
    </p>
    <p class="custom-home-msg-action" (click)="clearAlertInfo(updateAlertInfo)">
      <span >{{'HOME#NOTIFY#VIEW#DETAIL' | translate}}</span>
      <img class="type-image" src="assets/icons/next arrow.svg" />
    </p>
  </div>
</div>
<div class="actions" id="language-action-options" *ngIf="switchLanguage">
  <ul class="list-group">
    <li class="list-group-item cursor-pointer"  *ngFor="let item of languageList">
      <input type="radio" name="label" [checked]="isSelected === item.localeCode ? true: false"
        (change)="onItemChange(item)" [id]="'lang'+item.localeCode">
      <label class="label" [for]="'lang'+item.localeCode">{{item.localName}} </label>
    </li>
  </ul>
</div>
<div class="user-options-wrapper" id="user-account-options-wrapper" *ngIf="showMyAccountOptions">

  <div class="user-actions-my-account" (click)="openUserOptions()">
    <div class="image">
      <img src="assets/icons/Home/Account.svg">
    </div>
    <div class="card-content">
      <div class="title">
         {{'Manage_my_account' | translate}}
      </div>
    </div>
  </div>
  <div class="user-actions-logout" (click)="logout()">
    <div class="image">
      <img src="assets/icons/header/logout.svg">
    </div>
    <div class="card-content">
      <div class="title">
         {{'Log_out' | translate}}
      </div>
    </div>
  </div>
</div>

<app-custom-modal-container id="edit-profile-modal">
  <app-edit-profile></app-edit-profile>
</app-custom-modal-container>

<app-custom-modal-container id="hdr-manage-identity-modal" (complete)="closeManageIdentities()">
  <app-manage-identities  *ngIf="isManageIdentities" [modalId]="'hdr-manage-identity-modal'" ></app-manage-identities>
</app-custom-modal-container>
<app-manage-account *ngIf="openUserProfileActions" (close)="closeUserActions()"></app-manage-account>

<app-custom-modal-container  id="notification-modal" [isModalLeftAlign]="true" (complete)="closeNotification()">
  <app-notification-dtl *ngIf="isOpenNotification" ></app-notification-dtl>
</app-custom-modal-container>
