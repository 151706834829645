import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value, sortBy, type): unknown {
    if (type == 'moment' && (Array.isArray(value) && value.length > 1))
      value.sort((a, b) => a[sortBy].valueOf() - b[sortBy].valueOf());
    return value;
  }

}
