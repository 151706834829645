import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableComponent } from './report-table.component';
import { PipesModule } from '../pipes/pipes.module';
import { ReportGraphModule } from '../report-graph/report-graph.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';



@NgModule({
  declarations: [ReportTableComponent],
  imports: [
    CommonModule, PipesModule, ReportGraphModule, SvgIconModule
  ],
  exports: [
    ReportTableComponent
  ]
})
export class ReportTableModule { }
