import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-hr-visit-report',
  templateUrl: './hr-visit-report.component.html',
  styleUrls: ['./hr-visit-report.component.scss']
})
export class HrVisitReportComponent implements OnInit {
  @Input() set selectedVisit(value: any) {
    this._selectedVisit = value;
    this.clinicalHistory = [];
    this.getVisitDetails();
  }
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
    this.clinicalHistory = [];
    this.getVisitDetails();
  }
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() type:'UPLOADS' | 'REPORTS' = 'UPLOADS';
  @Input() isCommonView;
  @Input() qrCodeUrl;
  @Input() reportId: any;
  @Output() selectReport: EventEmitter<any>;
  @Output() openDocsUpload: EventEmitter<any>;
  @ViewChildren('clinicalHistoryLoop') clinicalHistoryLoop;
  public _selectedVisit: any;
  public _selectedPatient: any;
  public clinicalHistory: any;
  public canDownload: boolean;
  public isFilterOpen: boolean;
  public reportToken: string;
  public filters: Array<any>;
  public selectedFilter: any;
  public patientMetaData: any;
  public search: string;
  public searchExpand : boolean;
  public uploadedDocs: Array<any> = [];
  public searchTerm: any
  isMobileView = false;
  selectedReportToView;
  constructor(private user: UserService, private activatedRoute: ActivatedRoute) {
    this.selectReport = new EventEmitter<any>();
    this.openDocsUpload = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.isMobileView = document.body.clientWidth <=780;
    this.user.getConfigurationInfo.subscribe(data => {
      this.canDownload = data.features.documents?data.features.documents.download:false;
    })
    this.filters = [
      { key: '', value: 'all' },
      { key: 'RDIN', value: 'ttl_radiology' },
      { key: 'LBIN', value: 'ttl_laboratory' },
      { key: 'CLNT', value: 'ttl_notes' }
    ];
    this.selectedFilter = this.filters[0];
    this.search = '';
    this.refresh.subscribe(response => {
      if (response) {
        this.getVisitDetails();
      }
    })
    // if (this._selectedVisit || this._selectedPatient){
    //   this.getUploadedReport(); 
    // } 
    // this.getUploadedReport(); 
    this.checkUrl();
  }

  private getVisitDetails() {
    if (this._selectedVisit && this._selectedPatient) {
      this.user.getVisitReport(this._selectedPatient['uhid'], this._selectedVisit['encounterId'], null).subscribe(data => {
        this.clinicalHistory = data.reports;
        this.patientMetaData = data.patient
        this.reportToken = data.accessToken;
        if(this.clinicalHistory?.length && !this.isMobileView)
          this.openReports(this.clinicalHistory[0]);
      })
      this.getUploadedReport();
    } else if (this._selectedPatient) {
      this.user.getVisitReport(this._selectedPatient['uhid'], null, null).subscribe(data => {
        this.clinicalHistory = data.reports;
        this.patientMetaData = data.patient
        this.reportToken = data.accessToken;
        if (this.clinicalHistory?.length && !this.isMobileView && !this.reportId) {
          this.openReports(this.clinicalHistory[0]);
        } else if(this.reportId) {
          let repDtl = this.clinicalHistory.find(a => a.reportId == this.reportId);
          this.openReports(repDtl);
        }
      })
      this.getUploadedReport();
    }
  }
  public initDownload(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'D' });
  }
  public initDownloadPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'DP' });
  }
  public removeDownloadPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'RP' });
  }
  public chooseReport(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'V', patientMetaData: this.patientMetaData });
  }

  openReports(r) {
    this.user.getDownloadContent(r.url, this.reportToken, this.isCommonView).subscribe(data => {
      if (r['format'] && ['FORMFORMAT', 'JSONFORMAT'].includes(r['format'])) {
        data = JSON.parse(data);
      }
      r['data'] = data;
    this.selectedReportToView = r;
    console.log('reportView', r);
    })
  }
  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
  }
  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }
 
  searchIcon(){
    if(this.searchExpand){
      this.searchExpand = false;
    }
    else{
      this.searchExpand = true;
    }
  }
  public openDocUpload() {
    this.openDocsUpload.emit();
  }
  public getUploadedReport() {
    this.user.getUploadedReport(this._selectedPatient.uhid, null, null).subscribe(data => {
      this.uploadedDocs = data.map(d => {
        d.note = d.header.note
        // d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY')
        // var aDate   = this.user.getMoment(d.header.reportDate, 'MM-DD-YYYY', true);
        var aDate   = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY', true);
        var isValid = aDate.isValid();  
        if(isValid){
          d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MM-YYYY')
          // d.header.reportDate = this.user.getMoment(d.header.reportDate, 'MM-DD-YYYY')
        }
        else{
          d.header.reportDate = this.user.getMoment(d.header.reportDate, 'DD-MMM-YYYY')
        }
        return d;
      });
      this.uploadedDocs = this.uploadedDocs.filter( item =>{
        // return item.docType == "OTH";
        return item.docType != "PRE";
      })
    })
  }
  public initViewPersonal(selectedReport) {
    this.selectReport.emit({ report: selectedReport, token: this.reportToken, type: 'VP' });
  }
  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      let ctx = params['ctx'];
      if (ctx) {
        let loginContext = this.user.getLoginContext();
        this.handleContext(loginContext);
      }
    })
  }
  private handleContext(context) {
    console.log(context);
    if (context && context.patientId && context.reportId) {
      this.user.getVisitReport(context.patientId, null, null, context.reportId).subscribe(data => {
        if (data.reports && data.reports.length > 0 && data.accessToken) {
          let clinicalHistory = data.reports;
          let reportToken = data.accessToken;
          let report = null;
          if (clinicalHistory && clinicalHistory.length > 1) {
            report = clinicalHistory.find(rep=>rep.reportId == context.reportId)
          } else {
            report = clinicalHistory[0]
          }
          if (report) {
            this.selectedReportToView;
          }
        }
        this.user.removeLoginContext()
      })
    }
  }
}
