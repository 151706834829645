import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input() type: 'text' | 'image';
  @Input() imgWidth: string = '3rem';
  @Input() imgHeight: string = '3rem';
  @Input() txtWidth: string = '100%';
  @Input() txtHeight: string = '0.5rem';
  @Input() imgShape: 'round' | 'square' = 'square';
}
