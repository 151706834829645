import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent{
  @Input() className: any;
  @Output() clicked: EventEmitter<any>;
  constructor() { 
    this.clicked = new EventEmitter<any>();
  }

  public listItemClick() {
    this.clicked.emit();
  }

}
