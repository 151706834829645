import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslatePipe } from './translate.pipe';

@Pipe({
  name: 'englishToLang'
})
export class EnglishToLangPipe implements PipeTransform {
  constructor(private user: UserService, private translate: TranslatePipe) { }
  
  transform(selected: string, answers: Object[]) {
    if (!selected || (typeof selected !== 'string' && typeof selected !== 'number') || !answers || answers.length == 0)
      return selected;
    let lang = answers.find(answer => selected == answer['localName'][0]['text']);
    if (!lang || !lang['localName'] && !Array.isArray(lang['localName']) )
      return selected;
    return this.translate.transform(lang['localName'], 'LOCALE', 'text');
  }

}
