<ng-container *ngIf="visitDetails && visitDetails['status'] == 'S' && successState != 'REGPAT'">
  <div class="section-card success-card">
    <img class="success-img" src="assets/icons/tick.png" alt="">
    <p class="success-text">{{'SUCCESS' | translate}}</p>
    <p class="section-secondary">{{'You_have_successfully_checked_in_for_your_visit' | translate}}</p>
  </div>
  <p class="section-title">{{'Visit_Details' | translate}}</p>
  <div class="section-card payment-due">
    <div class="section-item">
      <p class="section-label">{{'Doctor' | translate}} </p>
      <p class="section-value">{{selectedPract['practitionerName']}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Clinic' | translate}} </p>
      <p class="section-value">{{selectedPract['clinicName']}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Patient' | translate}}</p>
      <div class="section-value">
        {{selectedPatient['patientName']}}
        <p class="section-secondary">{{selectedPatient['patientId']}}</p>
      </div>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Visit_Date_Time' | translate}} </p>
      <p class="section-value">{{visitDetails['visitDateTime'] | moment : 'DD MMM YYYY hh:mm a'}}</p>
    </div>
  </div>
  <ng-container  *ngIf="visitDetails['freefollowUpYn'] !== 'Y'">

  
  <p class="section-title">{{'Bill Details' | translate}}</p>
  <div class="section-card payment-due">
    <div class="section-item">
      <p class="section-label">{{'Amount_Paid' | translate}} </p>
      <p class="section-value">{{visitDetails.visitRequest.currency}} {{visitDetails['paidAmount'] || visitCharges['patientPayable']}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Receipt_Number' | translate}} </p>
      <p class="section-value">{{visitDetails['billNo']}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Paid_Via' | translate}}</p>
      <div class="section-value">{{visitDetails['paidvia'] || 'Card'}}</div>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Payment_mode' | translate}} </p>
      <p class="section-value">{{visitDetails['paidmode'] || 'Online'}}</p>
    </div>
  </div>
  <div class="section-button-wrapper">
    <button class="section-action" (click)="printBill()">{{'Print_download_receipt' | translate}}</button>
  </div>
</ng-container>

</ng-container>
<ng-container *ngIf="visitDetails && visitDetails['status'] == 'S' && successState == 'REGPAT'">
  <div class="section-card success-card">
    <img class="success-img" src="assets/icons/tick.png" alt="">
    <p class="success-text">{{'SUCCESS' | translate}}</p>
    <p class="section-secondary">{{'Patient_registered_successfully' | translate}}</p>
  </div>
  <div class="section-card patient-card">
    <div class="patient-header">
      <p class="section-label">{{'Patient_id:' | translate}} </p>
      <p class="section-value">{{visitDetails['patientId']}}</p>
    </div>
    <div class="patient-context" *ngIf="visitDetails['visitRequest']">
      <p class="section-label">{{'Patient_details:' | translate}} </p>
      <p class="section-value">{{visitDetails['visitRequest']['prefix']}} {{visitDetails['visitRequest']['firstName']}} {{visitDetails['visitRequest']['fatherName']}} {{visitDetails['visitRequest']['familyName']}}</p>
      <p class="section-label"> {{visitDetails['visitRequest']['gender'] == 'M' ? 'Male' : 'Female'}} {{visitDetails['visitRequest']['dob'] | age}} {{visitDetails['visitRequest']['dob'] | moment:'D MMM YYYY':'YYYY-MM-DD'}} </p>
    </div>
  </div>
  <p class="section-title">{{'Bill_details' | translate}}</p>
  <div class="section-card payment-due">
    <div class="section-item">
      <p class="section-label">{{'Amount_paid' | translate}} </p>
      <p class="section-value">{{visitDetails?.currency}}{{visitDetails?.paidAmount}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Receipt_no' | translate}} </p>
      <p class="section-value">{{visitDetails?.receiptNo}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Paid Via' | translate}}</p>
      <p class="section-value">{{visitDetails['paidvia'] || 'Card'}}</p>
    </div>
    <div class="section-item">
      <p class="section-label">{{'Payment_mode' | translate}} </p>
      <p class="section-value">{{visitDetails['paidmode'] || 'Online'}}</p>
    </div>
  </div>
  <div class="section-button-wrapper">
    <button class="section-action" (click)="printBill()">{{'Print_download_receipt' | translate}}</button>
    <button class="primary-button-outline" (click)="proceedToCreateVisit()">{{'Proceed_to_create_visit' | translate}}</button>

  </div>
</ng-container>

<ng-container *ngIf="visitDetails && visitDetails['status'] != 'S'">
    <div class="section-card success-card">
      <img class="success-img" src="assets/icons/task/error.svg" alt="">
      <p class="font-danger-default">{{'Payment_failed' | translate}}</p>
      <p class="section-secondary">{{'Payment_failed_due_to_unexpected_reason' | translate}}</p>
    </div>
    <p class="section-title" *ngIf="false">{{'Visit_Details' | translate}}</p>
    <div class="section-card payment-due" *ngIf="false">
      <div class="section-item">
        <p class="section-label">{{'Doctor' | translate}} </p>
        <p class="section-value">{{selectedPract['practitionerName']}}</p>
      </div>
      <div class="section-item">
        <p class="section-label">{{'Clinic' | translate}} </p>
        <p class="section-value">{{selectedPract['clinicName']}}</p>
      </div>
      <div class="section-item">
        <p class="section-label">{{'Patient' | translate}}</p>
        <div class="section-value">
          {{selectedPatient['patientName']}}
          <p class="section-secondary">{{selectedPatient['patientId']}}</p>
        </div>
      </div>
      <div class="section-item">
        <p class="section-label">{{'Payable_amount' | translate}} </p>
        <p class="section-value">{{visitDetails.visitRequest.currency}} {{visitDetails.visitRequest.settlementAmount}}
        </p>
      </div>
    </div>
     <div class="section-button-wrapper">
       <button class="primary-button" (click)="retry()">{{'Retry_payment' | translate}}</button>
       <button class="primary-button-outline" (click)="close()">{{'Close' | translate}}</button>
     </div>
</ng-container>