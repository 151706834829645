<div id="home-care-services">
  <div class="modal-container">
    <div id="home-care">
      <div class="home-care-header border-bottom">
        <div class="font-primary-subtitle bold">{{'home_care_homepage_title' | translate}}
        </div>
          <div class="float-right closeicon pointer" (click)="closeModal()"> <img src="./assets/icons/close_black.svg"> </div>
        </div>
      <div  class="home-care-content">
        <div class="home-title-wrapper">
          <span class="home-title bold">{{'home_care_homepage_title' | translate}}</span>
          <img [src]="getIcon(home.iconLocale)" class="high-res type-image" alt="" *ngIf="home.iconLocale">
          <img [src]="home.icon" alt="" *ngIf="home.icon && !home.iconLocale" class="high-res">
          <img src="assets/icons/clinic_home.svg" *ngIf="!home.icon && !home.iconLocale" />
          <span class="secondart-text">{{'home_care_more'|translate}}</span>
        </div>
        <div class="home-wrapper">
          <div class="home-card">
            <div class="home-header border-bottom">
              <span class="bold">{{'homecare_about_title'|translate}}</span>
            </div>
            <div class="home-text">
              {{'homecare_about_content' | translate}}
            </div>
          </div>
        </div>
        <div class="available-services">
          <div class="slot-header border-bottom">
            <span class="bold">{{'home_care_avail_services' | translate}}</span>
          </div>
          <div class="slot-content" >
            <ng-container *ngFor="let service of availableServices">
              <div class="card-layout" >
                <div class="card cursor-pointer" (click)="activityfun(service.title, service)">
                  <div class="card-main row">
                    <div class="image">
                      <img src="{{service.iconLocale|translate:'LOCALE':'src'}}" *ngIf="service.iconLocale">
                      <img [src]="service.icon" *ngIf="service.icon && !service.iconLocale">
                      <img src="./assets/icons/profile/gender_female.svg" *ngIf="!service.icon && !service.iconLocale">
                    </div>
                    <div class="card-content">
                      <div class="container">
                        <div class="title" appCssEllipsis>{{ service.title | translate : 'LOCALE' : 'label'}}</div>
                        <div class="font-light-small" appCssEllipsis>
                          <span>{{ service.sub_title | translate : 'LOCALE' : 'label'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="availableServices.length == 0">
              <div class="no-services">{{'No services configured'|translate}}</div>
            </ng-container>
            <!-- <app-home-care-activity *ngIf="Activity"></app-home-care-activity> -->
          </div>
        </div>
      </div>
      <div class="home-care-callus-section">
        <div class="mob-number bold" *ngIf="call_option.enable">
          {{'to_book_home_care_visit' | translate}} 
          <span class="cursor-pointer" (click)="call()">
            <img class="mob-whatsapp" src="./assets/icons/calendar-color.svg" alt="whats-app-img"> 
          </span>
          <span class="cursor-pointer blink_me" (click)="call()">{{call_option.number}}</span>
        </div>
        <div class="buttons-customize">
          <button class="btn-customize" *ngIf="location.enable"
          (click)="activityfun('locations', location)">{{'available_locations' | translate}}</button>
          <button class="btn-customize btn-customize-primary" *ngIf="call_option.enable"
          (click)="call()">{{'call_us' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>

<app-home-care-activity *ngIf="Activity" (close)="closeactivity()" (call)="call()" [activity]="activityEvent"
  [activityInput]="activityInput" [disableCall]="!call_option.enable"></app-home-care-activity>