import { Component, OnInit, Input } from '@angular/core';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { EventsService } from 'src/app/services/events.service';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
@Component({
  selector: 'app-composite-field',
  templateUrl: './composite-field.component.html',
  styleUrls: ['./composite-field.component.scss'],
  providers: [EventsService]
})
export class CompositeFieldComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  @Input() topic;
  @Input() prescriptionRules;
  @Input() patient;
  @Input() tabindex;
  public hideQuickSelect:boolean;
  public apptBooked:boolean;
  constructor(public events: EventsService, cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    this.section['multipleFields'].forEach(field => field['options'] = field['codify'] == 'Y' ? field['domainControls']['answer']: field['answer']);
    if(this.section['quickSelection']) {
      this.section['quickSelection'].forEach(q => {
      const dCodes = Object.keys(q).filter(i => i != 'displayValue' && i != '_value' && i != 'selected');
      q['_value'] = dCodes.reduce((s,i) => s ? s+'#_#' + q[i]:s+q[i],'');
      })
    }
    if(this.section['multipleFields'].some(d=> d['domainCode'] == 'DOSE2') && this.prescriptionRules) {
      this.loadOptions();
      this.events.on('presc_' +'PRESDOSAGETYPE', () => {
        this.loadOptions();
      })
    }
    this.changeInValues();
  }

  quickSelect(item) {
    if(this.apptBooked)
      return;
    const dCodes = Object.keys(item).filter(i => i != 'displayValue' && i != '_value');
    dCodes.forEach(c => {
      const field = this.section['multipleFields'].find(f => f['domainCode'] == c);
      if (field) {
        field['selected'] = item[c];
      }
    })
    this.section['quickSelection'].map(e => e['selected'] = false);
    item['selected'] = true;
    this.section['selected'] = item['_value'];
  }

  public changeInValues() {
    const self = this;
      const val = self.section['multipleFields'].reduce((res, i) => { 
        if(i['selected']) {
          return res += res ? "#_#" + i['selected'] : i['selected']
        }
      else return res = '' }, '');
      if(self.section['quickSelection']) {
        self.section['quickSelection'].forEach(s => {
        s['selected'] = s['_value'] == val;
        })
      }
      
      self.section['selected'] = val;
  }
  loadOptions() {
    const PRESDOSAGETYPE = this.sections.find(g => g['domainCode'] =='PRESDOSAGETYPE');
    const dose2 = this.section['multipleFields'].find(d => d['domainCode'] == 'DOSE2');
    const dose1 = this.section['multipleFields'].find(d => d['domainCode'] == 'DOSE1');
    const uom = this.prescriptionRules[PRESDOSAGETYPE['selected'].toLowerCase()+ 'UOM'];
    console.log(this.section['multipleFields'],PRESDOSAGETYPE, this.prescriptionRules);
    if(uom) {
      dose2['options'] =[];
      this.hideQuickSelect = true;
      uom.forEach(u => {
        dose2['options'].push({localName:[{text:u['name']}],code:u['code']});
        if(u['default']) {
          dose2['selected'] = u['code'];
          if(u['defaultValue'])
            dose1['selected'] =  typeof u['defaultValue'] == 'number' ? u['defaultValue'] : parseInt(u['defaultValue']);
        }
      })
    }
  }

  public appointmentBooked(bool) {
      this.apptBooked = bool;
  }
}
