import { Pipe, PipeTransform } from '@angular/core';
import {values as _values, groupBy as _groupBy} from 'lodash';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  constructor(private user: UserService) { }

  transform(arr: Array<any>, key:string,  type?:string): any {
    
    
    if (!(key || type)|| !arr)
      return arr;
    if (type) {
        switch (type) {
          case 'YEAR':
            return _values(_groupBy(arr, d => this.user.getMoment(d[key]).format('YYYY')));
        
          case 'DATE':
            return _values(_groupBy(arr, d => this.user.getMoment(d[key]).format('YYYYMMDD')));

          default:
            break;
        }
      }
      return _values(_groupBy(arr, key));
  }
}
