<div id="LoginComponent" *ngIf="!webViewState && !checkingAutoSignon">
  <div class="login-header" (click)="openInfo()">
    <div class="flex-container"> <img alt="nahdi_clinic" class="type-image"
        src="assets/images/signin/nahdi_clinic.png" />
    </div>
  </div>
  <div class="login-container">
    <div class="image-placeholder" [ngClass]="{prefilledview: isRegisterWithPrefill}">
      <div class="logo" *ngIf="false">
        <img alt="logo" class="type-image" src="assets/images/signin/logo_minimal_white.png" />
      </div>
      <div class="overlay"></div>
      <div class="prefilled-data" *ngIf="isRegisterWithPrefill">
        <div class="logo">
          <img class="type-image" src="assets/images/signin/logo_grey.png" />
        </div>
        <div class="person-details">
          <div class="person-name">
            <app-person-avatar [name]="prefillPersonDetails['name']" [additionalClass]="'initial'"></app-person-avatar>
            <div class="name">
              <div>{{'Welcome' | translate}}</div>
              <div>{{prefillPersonDetails['name']}}</div>
            </div>
            <div class="toggle-detais-button" [ngClass]="{open: showprefilldetails}"
              (click)="showprefilldetails = !showprefilldetails">
              <img src="assets/icons/calendar/arrow.svg" alt="">
            </div>
          </div>
          <div class="details">
            <form [formGroup]="prefilledSignupForm" class="signup-form" [ngClass]="{crumbled: !showprefilldetails}">
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_uhid' | translate}}</label>
                  <div class="input-group">
                    {{prefillPersonDetails['uhid']}}
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_dob' | translate}}</label>
                  <div class="input-group">
                    {{prefillPersonDetails['dob'] | moment : 'DD MMM YYYY' : 'YYYY-MM-DD'}}
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_mobile_number' | translate}}</label>
                  <div class="input-group">
                    {{prefillPersonDetails['maskedMobileNumber']}}
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'frm_email' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="email" placeholder="{{'enter_mail_id'|translate}}" type="text" required>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="user-section">
      <div class="settings">
        <ng-container *ngIf="!isExtIdLogin">
          <div class="back-button">
            <div class="languages">
              <div
                class="cursor-pointer"
                [ngClass]="{active: (item.localeCode === selectedLanguage), bold:(item.localeCode === selectedLanguage)}"
                *ngFor="let item of languageList" (click)="selectLanguge(item)">
                {{item.localName}}
              </div>
            </div>
            <div class="padding-top float-right input-label" (click)="toggleScreen('SSOLOGIN')"  *ngIf="isSSORegister || isSSOResetPassword">
              <label >{{'btn_top_back' | translate}}</label>
            </div>
          </div>
          <div class="company-details" [ngClass]="{hide : isSSOForgotPassword || isSSOResetPassword || isRegisterWithPrefill}" >
            <h3 class="bold default-header-lbl">
              <ng-container *ngIf="!loginWithContext || !loginContext || !loginContext.personName">
                {{'lbl_welcome_to'|translate}}</ng-container>
              <ng-container *ngIf="loginWithContext">
                <ng-container *ngIf="(loginContext && loginContext.personName) && isPreCheck">{{'welcome'|translate}}
                  {{loginContext.personName}}</ng-container>
                <ng-container *ngIf="!isPreCheck">
                  {{'Confirm_your_identity'|translate}}
                </ng-container>
              </ng-container>
            </h3>
            <img alt="logo_grey" class="type-image hide-logo" src="assets/images/signin/logo_grey.png" />
          </div>
          <div class="context-title" *ngIf="loginWithContext && loginContext">
            <ng-container *ngIf="loginContext.contextDesc">{{loginContext.contextDesc}}</ng-container>
            <ng-container *ngIf="!loginContext.contextDesc">
              <ng-container *ngIf="AppConstant.LOGIN_CONTEXT['REPORT'] == loginContext.type">
                {{'New_report' | translate}}</ng-container>
              <ng-container *ngIf="AppConstant.LOGIN_CONTEXT['BILL'] == loginContext.type">
                {{'Bill_details' | translate}}
              </ng-container>
              <ng-container *ngIf="AppConstant.LOGIN_CONTEXT['APPOINTMENT'] == loginContext.type">
                {{'Appointment_details' | translate}}</ng-container>
              <ng-container *ngIf="AppConstant.LOGIN_CONTEXT['TASK'] == loginContext.type">
                {{'New_task' | translate}}</ng-container>
            </ng-container>
          </div>
          <div class="context-subtitle" *ngIf="loginWithContext && loginContext">
            {{(loginContext.context_subtitle || 'context_subtitle')|translate}}
          </div>
        </ng-container>
        <ng-container *ngIf="isExtIdLogin && !isPreCheckError">
          <div class="back-button" *ngIf="isRegisterWithPrefill || isVerifyOtpForSignup || isVerifyMobilenumber">
            <div class="padding-top float-right input-label" (click)="handleBack()" *ngIf="!isRegisterWithPrefill">
              <img src="assets/icons/arrow.svg">
              <label>{{'btn_top_back' | translate}}</label>
            </div>
            <div class="padding-top float-right input-label" (click)="handleHome()" *ngIf="isVerifyOtpForSignup">
              <label>{{'btn_top_home' | translate}}</label>
              <img src="assets/icons/homecare/home.svg"/>
            </div>
          </div>
          <div class="back-button" *ngIf="!(isRegisterWithPrefill || isVerifyOtpForSignup || isVerifyMobilenumber)">
            <div class="languages">
              <div class="cursor-pointer"
                [ngClass]="{active: (item.localeCode === selectedLanguage), bold:(item.localeCode === selectedLanguage)}"
                *ngFor="let item of languageList" (click)="selectLanguge(item)">
                {{item.localName}}
              </div>
            </div>
          </div>
          <div class="company-details" [ngClass]="{hide : isRegisterWithPrefill || isVerifyOtpForSignup || isVerifyMobilenumber}">
            <h3 class="bold default-header-lbl">
              {{'lbl_welcome_to'|translate}}
            </h3>
            <img class="type-image" src="assets/images/signin/logo_grey.png" />
          </div>
          <div class="new-user-details" *ngIf="isVerifyMobilenumber && !isVerifyOtpForSignup && !isRegisterWithPrefill">
            <div class="not-registered-title">
              {{'Not_registered_validate_mobile' | translate}}
            </div>
            <div class="not-registered-message">
              {{'not_registered_message_validate_mobile' | translate}}
            </div>
          </div>
          <div class="new-user-details" *ngIf="isVerifyOtpForSignup && !isRegisterWithPrefill">
            <div class="not-registered-title">
              {{'Not_registered_verifyotp' | translate}}
            </div>
            <div class="not-registered-message">
              {{'not_registered_message_validate_otp' | translate}}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isExtIdLogin && isPreCheckError">
          <div class="new-user-details invalid-details">
            <div class="not-registered-title">
              {{'Not_registered_invalid_mobile' | translate}}
            </div>
            <div class="not-registered-message">
              <span class="font-danger-small">{{'not_registered_message_invalid_mobile' | translate}}</span>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="pre-check-container" *ngIf="isPreCheck">
            <form [formGroup]="precheckForm" class="precheck-form">
              <ng-container *ngIf="!isVerifyOtpForSignup">
                <div class="group" (click)="handlePrecheckNatIdClick()">
                  <div [ngClass]="{'mobile-no-country-code':!isExtIdLogin, 'input-container text-input': isExtIdLogin}">
                    <ng-container *ngIf="!loginWithContext">
                      <ng-container *ngIf="!isExtIdLogin">
                        <label>{{'prtl_auth_lbl_mobile' | translate}}</label>
                        <div class="phoneinput">
                          <app-phone-input formControlName="mobile"></app-phone-input>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isExtIdLogin">
                        <label>{{'prtl_auth_lbl_natid' | translate}}</label>
                        <div class="input-group">
                          <input formControlName="natid" placeholder="{{'prtl_auth_plh_natid' | translate}}"
                            [attr.type]="'text'" [readonly]="isAuthorizeState || isUnregisteredPatient">
                          <img class="type-image" src="assets/icons/signin/pen.svg" *ngIf="isVerifyMobilenumber">
                        </div>
                      </ng-container>
                    </ng-container>
                    
                    <div class="phoneinput" *ngIf="loginWithContext">
                      <app-phone-input [masked]="true" [maskedNumber]="loginContext?loginContext['displayMaskedNumber']:''" [readonly]="true" *ngIf="false"></app-phone-input>
                      <div class="gen-otp-message">{{'Generate_otp_to'|translate}}
                        {{loginContext?loginContext['displayMaskedNumber']:''}}
                      </div>

                    </div>
                  </div>
                  <p class="error-p" [ngClass]="{show: precheckForm['controls']['mobile']['value'] && precheckForm['controls']['mobile']['dirty'] && precheckForm['controls']['mobile']['touched'] && !precheckForm['controls']['mobile']['pending'] && !precheckForm['controls']['mobile']['valid']}" *ngIf="!loginWithContext && !isExtIdLogin">
                    * Invalid mobile number *
                  </p>
                  <p class="error-p" [ngClass]="{show: precheckForm['controls']['natid']['value'] && precheckForm['controls']['natid']['dirty'] && precheckForm['controls']['natid']['touched'] && !precheckForm['controls']['natid']['pending'] && !precheckForm['controls']['natid']['valid']}" *ngIf="!loginWithContext && isExtIdLogin">
                    * Invalid mobile number *
                  </p>
                </div>
                <div class="group" *ngIf="isVerifyMobilenumber">
                  <div class="mobile-no-country-code">
                    <label>{{'prtl_auth_lbl_verifymobile' | translate : 'LABELS': null : null : labelsReplace.maskedMobileNumber}}</label>
                    <div class="phoneinput">
                      <app-phone-input direction="row" formControlName="verifymobile" [readonly]="isVerifyOtpForSignup">
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <re-captcha *ngIf="isVerifyMobilenumber && isCaptchaVerifySignup && captchaKey && captchaVersion == 2"
                  formControlName="captcha" name="captcha" [siteKey]="captchaKey"></re-captcha>
              </ng-container>
              <ng-container *ngIf="isVerifyOtpForSignup">
                <div class="entered-details">
                  <div class="group">
                    <div class="input-container text-input">
                      <label>{{'prtl_auth_lbl_natid_entered' | translate}}</label>
                      <input formControlName="natid" [attr.type]="'text'" [readonly]="true">
                    </div>
                  </div>
                  <div class="group">
                    <div class="input-container text-input">
                      <label>{{'prtl_auth_lbl_mobile_entered' | translate}}</label>
                      <input formControlName="verifymobile" [attr.type]="'text'" [readonly]="true">
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="group" *ngIf="isVerifyOtpForSignup">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_lgotp' | translate}}</label>
                  <div class="input-group password-direc-change max-otp" [ngClass]="{otpinput: true}">
                    <ng-container *ngIf="false">
                      <input formControlName="otp" placeholder="{{'otp_placeholder'|translate}}"
                        [attr.type]="showPin ? 'text' : 'password'"
                        [readonly]="isAuthorizeState">
                      <img [src]="showPin ? 'assets/icons/signin/eye_active.svg' :'assets/icons/signin/eye_inactive.svg'"
                        (mouseenter)="showPin = true;" (mouseleave)="showPin = false" />
                    </ng-container>
                    <ng-container>
                      <app-partition-input type="number" [readonly]="false" [length]="otplength" formControlName="otp">
                      </app-partition-input>
                    </ng-container>
                  </div>
                  <div class="help-link cursor-pointer" *ngIf="!isAuthorizeState">
                    <div class="padding-top float-right" *ngIf="resendSeconds>0">
                      {{'resendotpin' | translate}} ({{resendSeconds | moment : 'mm:ss':null:true}})
                    </div>
                    <div class="padding-top float-right" *ngIf="resendSeconds == 0" (click)="sendOTP()">
                      {{'prtl_auth_otpresend' | translate}}
                    </div>
                  </div>
                  <div class="otp-reference" *ngIf="loginWithContext">
                    {{'Enter_otp_sent_to'|translate}} {{loginWithContext?loginContext['displayMaskedNumber']:''}}
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!loginWithContext">
                <button type="submit" (click)="precheck()" [disabled]="!precheckForm['valid']" type="button"
                  class="primary-button" *ngIf="!isUnregisteredPatient && !isVerifyMobilenumber"> {{'btn_continue'|translate}}
                </button>
                <button type="submit" (click)="checkCaptcha('PRECHECK', isVerifyMobilenumber?'SIGNUP':null)"
                  [disabled]="isValidating || (isVerifyMobilenumber && !precheckForm['valid'])" type="button"
                  class="primary-button"
                  *ngIf="(isUnregisteredPatient || isVerifyMobilenumber) && !isVerifyOtpForSignup">
                  {{(isVerifyMobilenumber?'Verify_mobile':'Generate_OTP')|translate}} </button>
                <div class="signup-signin-otp" *ngIf="isUnregisteredPatient && isVerifyOtpForSignup">
                  <button type="submit" (click)="handleVerifyOTP('PRECHECK', 'SIGNIN')" [disabled]="!precheckForm['valid']" type="button" class="primary-button-outline" *ngIf="signInWithoutSignup">
                    {{'btn_signin'|translate}}
                  </button>
                  <button type="submit" (click)="handleVerifyOTP('PRECHECK', 'SIGNUP')" [disabled]="!precheckForm['valid']" type="button" class="primary-button">
                    {{'Verify_OTP'|translate}}
                  </button>
                </div>
              </ng-container>
              <div class="login-with-otp" *ngIf="loginWithContext && !validateError">
                <button type="submit" (click)="handleContext('PIN', true);" type="button"
                  class="primary-button-outline" [disabled]="isValidating"
                  [appRemoveFeatureOff]="'FT_APP_CXTWITH_PIN'"> {{'btn_signin_pin'|translate}} </button>
                <button type="submit" (click)="sendOTP()" [disabled]="isValidating" type="button" class="primary-button"> {{'Generate_OTP'|translate}} </button>
              </div>
              <p class="font-danger-small" *ngIf="loginWithContext && validateError">{{'error_validation_document' | translate}}</p>
            </form>
          </div>
          <div class="sign-in-container" *ngIf="isLogin">
            <form [formGroup]="loginForm" class="signin-form">
              <div class="group" *ngIf="!loginWithContext"> <!-- Remove NgIf if mobile number need to show-->
                <div [ngClass]="{'mobile-no-country-code':!isExtIdLogin, 'input-container text-input': isExtIdLogin}"
                  (click)="handleMobileEdit()">
                  <ng-container *ngIf="!loginWithContext">
                    <ng-container *ngIf="!isExtIdLogin">
                      <label>{{'prtl_auth_lbl_mobile' | translate}}</label>
                      <div class="phoneinput">
                        <app-phone-input formControlName="mobile" [readonly]="true"></app-phone-input>
                        <img class="type-image" src="assets/icons/signin/pen.svg">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isExtIdLogin">
                      <label>{{'prtl_auth_lbl_natid' | translate}}</label>
                      <div class="input-group">
                        <input formControlName="natid" placeholder="{{'prtl_auth_plh_natid' | translate}}"
                          [attr.type]="'text'" [readonly]="isAuthorizeState">
                      </div>
                    </ng-container>
                  </ng-container>
                  <div class="phoneinput" *ngIf="loginWithContext">
                    <app-phone-input [masked]="true" [maskedNumber]="loginContext?loginContext['displayMaskedNumber']:''" [readonly]="true"></app-phone-input>
                  </div>
                </div>
                <p class="error-p" [ngClass]="{show: precheckForm['controls']['mobile']['value'] &&
                  precheckForm['controls']['mobile']['dirty'] && precheckForm['controls']['mobile']['touched'] &&
                  !precheckForm['controls']['mobile']['pending'] && !precheckForm['controls']['mobile']['valid']}"
                  *ngIf="!loginWithContext">
                  * Invalid mobile number *
                </p>
              </div>
              <div class="group" *ngIf="!loginWithOTP || loginType == 'PIN'">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_lgpin' | translate}}</label>
                  <div class="input-group password-direc-change">
                    <input formControlName="pin" placeholder="{{'pin_placeholder'|translate}}"
                      [attr.type]="showPin ? 'text' : 'password'" [readonly]="isAuthorizeState">
                    <img [src]="showPin ? 'assets/icons/signin/eye_active.svg' :'assets/icons/signin/eye_inactive.svg'" (mouseenter)="showPin = true;" (mouseleave)="showPin = false" />
                  </div>
                  <div class="help-link cursor-pointer" *ngIf="!isAuthorizeState">
                    <div class="input-group keep_signin" [appRemoveFeatureOff]="'FT_APP_KEEP_SIGNIN'">
                      <input id="remaindme" [(ngModel)]="remaindme" [ngModelOptions]="{standalone: true}" type="checkbox">
                      <label for="remaindme" class="input-label">{{'prtl_auth_kpsgin' | translate}}</label>
                    </div>
                    <div class="padding-top float-right" (click)="forgetPin()">{{'prtl_auth_fgpin' | translate}}</div>
                  </div>
                  <div class="otp-reference" *ngIf="loginWithContext">
                    {{'Enter_pin_for_the_mobile_number'|translate}} {{loginContext?loginContext['displayMaskedNumber']:''}}
                  </div>
                </div>
              </div>
              <div class="group" *ngIf="loginType == 'OTP'">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_lgotp' | translate}}</label>
                  <div class="input-group password-direc-change">
                    <input formControlName="otp" placeholder="{{'otp_placeholder'|translate}}"
                      [attr.type]="showPin ? 'text' : 'password'" [readonly]="isAuthorizeState">
                    <img [src]="showPin ? 'assets/icons/signin/eye_active.svg' :'assets/icons/signin/eye_inactive.svg'" (mouseenter)="showPin = true;" (mouseleave)="showPin = false" />
                  </div>
                  <div class="help-link cursor-pointer" *ngIf="!isAuthorizeState">
                    <div class="input-group keep_signin" [appRemoveFeatureOff]="'FT_APP_KEEP_SIGNIN'">
                      <input id="remaindme" [(ngModel)]="remaindme" [ngModelOptions]="{standalone: true}" type="checkbox">
                      <label for="remaindme" class="input-label">{{'prtl_auth_kpsgin' | translate}}</label>
                    </div>
                    <div class="padding-top float-right" (click)="sendOTP()">{{'prtl_auth_otpresend' | translate}}
                    </div>
                  </div>
                  <div class="otp-reference" *ngIf="loginWithContext">
                    {{'Enter_otp_sent_to'|translate}} {{loginWithContext?loginContext['displayMaskedNumber']:''}}
                  </div>
                </div>
              </div>
              <div class="group" *ngIf="isAuthorizeState">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_lgotp' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="authOTP" placeholder="{{'otp_placeholder'|translate}}" type="password"
                      required />
                  </div>
                  <div class="help-link cursor-pointer">
                    <div class="input-group keep_signin" [appRemoveFeatureOff]="'FT_APP_KEEP_SIGNIN'">
                      <input id="remaindme" [(ngModel)]="remaindme" [ngModelOptions]="{standalone: true}" type="checkbox">
                      <label for="remaindme" class="input-label">{{'prtl_auth_kpsgin' | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!loginWithOTP || isAuthorizeState">
                <button type="submit" *ngIf="!isAuthorizeState" (click)="login()" [disabled]="!loginForm.valid" type="button" class="primary-button"> {{'btn_signin' | translate}} </button>
                <button type="submit" *ngIf="isAuthorizeState" (click)="login()" [disabled]="!loginForm.valid" type="button" class="primary-button"> {{'btn_authorize' | translate}} </button>
              </ng-container>
              <ng-container *ngIf="loginWithOTP && !isAuthorizeState">
                <div class="login-with-otp">
                  <button type="submit" *ngIf="loginType == 'PIN'" (click)="switchTo('OTP')" type="button" class="primary-button-outline"> {{'btn_signin_otp' | translate}} </button>
                  <button type="submit" *ngIf="loginType == 'OTP'" (click)="switchTo('PIN')" type="button" class="primary-button-outline" [appRemoveFeatureOff]="'FT_APP_CXTWITH_PIN'"> {{'btn_signin_pin' | translate}} </button>
                  <button type="submit" (click)="login()" [disabled]="!loginForm.valid"
                    type="button" class="primary-button"> {{'btn_signin' | translate}} </button>
                </div>
              </ng-container>
            </form>
          </div>
          <div class="sign-up-container" *ngIf="isRegister1">
            <form [formGroup]="signupForm" class="signup-form">
              <div class="group">
                <div class="mobile-no-country-code" (click)="toggleScreen('PRECHECK')">

                  <ng-container *ngIf="!isExtIdLogin">
                    <label>{{'prtl_auth_lbl_mobile' | translate}}</label>
                    <div class="phoneinput">
                      <app-phone-input formControlName="mobile" [readonly]="true"></app-phone-input>
                      <img class="type-image" src="assets/icons/signin/pen.svg">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isExtIdLogin">
                    <label>{{'prtl_auth_lbl_natid' | translate}}</label>
                    <div class="input-group">
                      <input formControlName="natid" placeholder="{{'prtl_auth_plh_natid' | translate}}"
                        [attr.type]="'text'" [readonly]="isAuthorizeState">
                    </div>
                  </ng-container>
                  <div class="phoneinput" *ngIf="loginWithContext">
                    <app-phone-input [masked]="true" [maskedNumber]="loginContext?loginContext['displayMaskedNumber']:''"
                      [readonly]="true"></app-phone-input>
                  </div>
                </div>
              </div>
              <div class="group" *ngIf="!isExtIdLogin">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_otp'|translate}}</label>
                  <div class="input-group">
                    <input formControlName="otp" placeholder="{{'otp_placeholder'|translate}}" type="password" required>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_cpin' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="pin" placeholder="{{'pin_placeholder'|translate}}" type="password" required>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_rpin'|translate}}</label>
                  <div class="input-group">
                    <input formControlName="confirmPin" placeholder="{{'rpin_placeholder'|translate}}" type="password"
                      required>
                  </div>
                </div>
              </div>
              <button type="submit" (click)="continueCreateAccount()" *ngIf="!isResetPin" [disabled]="!signupForm.valid" type="button" class="primary-button"> {{'btn_continue'|translate}} </button>
              <button type="submit" (click)="resetPin()" *ngIf="isResetPin" [disabled]="!signupForm.valid" type="button" class="primary-button"> {{'btn_continue'|translate}} </button>
            </form>
          </div>
          <div class="sign-up-container" *ngIf="isRegister2">
            <form [formGroup]="signupContinueForm" class="signup-form">
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'frm_first_name' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="fname" placeholder="{{'enter_first_name'|translate}}" type="text" required>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'frm_last_name' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="lname" placeholder="{{'enter_last_name'|translate}}" type="text" required>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'frm_email' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="email" placeholder="{{'enter_email_address' | translate}}" type="email" required>
                  </div>
                </div>
              </div>
              <div class="group justify-center">
                <div class="input-container checkbox-input ">
                  <div class="input-group">
                    <input id="accept" formControlName="accept" type="checkbox">
                    <label for="accept" class="input-label">{{'prtl_auth_accepttc' | translate}}</label>
                  </div>
                </div>
              </div>
              <button type="submit" (click)="createAccount()" [disabled]="!signupContinueForm.valid" type="button"
                class="primary-button"> {{'btn_create_account' | translate}} </button>
            </form>
          </div>
          <div class="terms-cond-footer" *ngIf="isRegister2">
            <label>Please read our <a>Terms and Conditions</a> of our policy</label>
          </div>
          <div class="sign-in-container" *ngIf="isSSOLogin">
            <form [formGroup]="ssoLoginForm" class="signin-form sso-login">
              <div class="group">
                <div class="mobile-no-country-code">
                  <label class="label1">{{'prtl_auth_lbl_enter_mobile_no' | translate}}</label>
                  <div class="phoneinput">
                    <app-phone-input formControlName="mobile"></app-phone-input>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label class="label1">{{'prtl_auth_lbl_enter_pwd' | translate}}</label>
                  <div class="input-group password-direc-change">
                    <input formControlName="password" placeholder=""
                      [attr.type]="showPin ? 'text' : 'password'">
                    <img class="type-image" [src]="showPin ? 'assets/icons/signin/eye_active.svg' :'assets/icons/signin/eye_inactive.svg'" (mouseenter)="showPin = true;" (mouseleave)="showPin = false" />
                  </div>
                  <div class="help-link cursor-pointer padding-top">
                    <div class="input-group keep_signin">
                      <input id="remaindme" [(ngModel)]="remaindme" [ngModelOptions]="{standalone: true}" type="checkbox" [appRemoveFeatureOff]="'FT_APP_KEEP_SIGNIN'">
                      <label for="remaindme" class="input-label" [appRemoveFeatureOff]="'FT_APP_KEEP_SIGNIN'">{{'prtl_auth_kpsgin' | translate}}</label>
                    </div>
                    <div class="padding-top float-right" (click)="toggleScreen('SSOFORGOTPASSWORD')" [appRemoveFeatureOff]="'FT_APP_RESET_PASSWORD'">{{'prtl_auth_fgpwd' | translate}}</div>
                  </div>
                </div>
              </div>
            <!-- </div> -->
            <!-- <button type="submit" (click)="ssoLogin()" [disabled]="!ssoLoginForm.valid" type="button"
              class="primary-button"> {{'btn_login' | translate}} </button> -->
              <div class="flex">
                <button type="submit" (click)="ssoLogin()" [disabled]="!ssoLoginForm.valid" type="button"
                class="primary-button"> {{'btn_login' | translate}} </button>
                <label for="text" class="account-label" [appRemoveFeatureOff]="'FT_APP_SIGNUP'">{{'lbl_or' | translate}}</label>
                <button class="secondary-button reg_btn" (click)="toggleScreen('SSOREGISTER')" type="button"
                  [appRemoveFeatureOff]="'FT_APP_SIGNUP'"> {{'prtl_register_now'|translate}} </button>
                <button type="submit" (click)="ssoRedirect(null)" type="button" *ngIf="false"
                  class="primary-button"> {{'btn_login' | translate}} </button>
              </div>
              <!-- <div class="group justify-center">
                <div class="input-container checkbox-input ">
                  <div class="input-group label-center">
                    <label for="text" class="account-label">{{'prtl_txt_dont_have_an_acct' | translate}}</label>
                    <label for="create_acct" class="input-label" (click)="toggleScreen('SSOREGISTER')">{{'prtl_create_new_acct' | translate}}</label>
                    <button class="secondary-button" (click)="toggleScreen('SSOREGISTER')" type="button"> {{'prtl_register_now'|translate}} </button>
                  </div>
                </div>
              </div> -->
            </form>
          </div>
          <div class="sign-up-container class-register-pat" *ngIf="isSSORegister && regPatientOnSignup">
            <app-register-new-patient [isPatientSignup]="true" (signup)="initSignup($event)"></app-register-new-patient>
          </div>
          <div class="sign-up-container" *ngIf="isSSORegister && !regPatientOnSignup">
            <form [formGroup]="ssoSignUpForm" class="sso-signup-form">
              <div class="signup-form-container">
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_first_name' | translate}}</label>
                    <div class="input-group">
                      <input formControlName="fname" placeholder="{{'enter_first_name'|translate}}" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_last_name' | translate}}</label>
                    <div class="input-group">
                      <input formControlName="lname" placeholder="{{'enter_last_name'|translate}}" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_create_pwd' | translate}}</label>
                    <div class="input-group">
                      <input formControlName="pin" placeholder="{{'enter_password' | translate}}" type="password" required>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_reenter_pwd'|translate}}</label>
                    <div class="input-group">
                      <input formControlName="confirmPin" placeholder="{{'enter_password' | translate}}" type="password" required>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_gender' | translate}}</label>
                    <div class="segmented-control">
                        <input name="gender" formControlName="gender" type="radio" value="male" id="gender-male" />
                        <label for="gender-male">{{'frm_male_opt'|translate}}</label>
                        <input name="gender" formControlName="gender" type="radio" value="female" id="gender-female" />
                        <label for="gender-female">{{'frm_female_opt'|translate}}</label>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_language' | translate}}</label>
                    <div class="segmented-control">
                      <div  *ngFor="let item of languageList">
                      <input type="radio" name="language" formControlName="language"
                        [id]="'lang'+item.localeCode" value={{item.localeCode}}>
                    <label [for]="'lang'+item.localeCode">{{item.localName}} </label></div>
                    </div>
                  </div>
                </div>
                <div class="group">
                  <div class="input-container text-input">
                    <label>{{'frm_dob' | translate}}</label>
                    <div class="value">
                      <vaadin-date-picker id="dob" class="vaddin-form-date" [max]="curDate"  placeholder="mm dd yyyy" (change)="vaadinChange($event, 'dob')" theme="date-picker"> </vaadin-date-picker>
                      <app-form-input-error [control]="ssoSignUpForm.controls.dob"></app-form-input-error>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" (click)="ssoCreateAccount()" [disabled]="!ssoSignUpForm.valid" type="button"
                class="primary-button sign-up-btn"> {{'btn_signup' | translate}} </button>
            </form>
          </div>

          <div class="sso-otp-container pre-check-container" *ngIf="isSSOVerifyOtp">
            <form [formGroup]="ssoVerifyOtpForm" class="precheck-form">
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_enter_otp'|translate}}</label>
                  <div class="input-group">
                    <input formControlName="otp" placeholder="{{'otp_placeholder'|translate}}" type="password" required>
                  </div>
                </div>
              </div>
              <div class="group text-input">
                <div class="input-container checkbox-input ">
                  <label class="padding-top default-label">{{'lbl_please_enter_otp_message' | translate}} {{ setMobile }}</label>
                  <div class="input-group resend-container">
                    <button class="input-label resend-btn" (click)="ssoResendOTP()" [disabled]="!resendOTP">{{'prtl_auth_lbl_resend_otp'|translate}}</button>
                    <span class="input-label " *ngIf="!resendOTP">{{ timeLeft }}</span>
                  </div>
                </div>
              </div>
              <button type="submit" (click)="ssoAccountVerification()" [disabled]="!ssoVerifyOtpForm['valid']"
                class="primary-button"> {{'btn_verify_otp'|translate}} </button>

                <div class="group justify-center padding-top">
                  <div class="input-container checkbox-input ">
                    <div class="input-group label-center ">
                      <label for="text" class="input-label" (click)="changeNumber()">{{'prtl_txt_change_mobile_no' | translate}}</label>
                    </div>
                  </div>
                </div>
            </form>
          </div>

          <div class="pre-check-container" *ngIf="isSSOForgotPassword">
            <div class="group padding-top">
              <label class="bold padding-top">{{'lbl_recover_your_pwd'|translate}}</label>
            </div>
            <form [formGroup]="ssoForgotPwdForm" class="precheck-form">
              <div class="group">
                <div class="mobile-no-country-code">
                  <label>{{'prtl_auth_lbl_mobile' | translate}}</label>
                  <div class="phoneinput">
                    <app-phone-input formControlName="mobile"></app-phone-input>
                  </div>
                </div>
                <p class="error-p" [ngClass]="{show: ssoForgotPwdForm['controls']['mobile']['value'] && ssoForgotPwdForm['controls']['mobile']['dirty'] && ssoForgotPwdForm['controls']['mobile']['touched'] && !ssoForgotPwdForm['controls']['mobile']['pending'] && !ssoForgotPwdForm['controls']['mobile']['valid']}">
                  * Invalid mobile number *
                </p>
              </div>
              <button type="submit" (click)="ssoForgotPassword()" [disabled]="!ssoForgotPwdForm['valid']"
                type="button"
                class="primary-button"> {{'btn_continue'|translate}} </button>
                <div class="group justify-center padding-top">
                  <div class="input-container checkbox-input ">
                    <div class="input-group label-center">
                      <label for="create_acct" class="input-label" (click)="toggleScreen('SSOLOGIN')">{{'prtl_auth_lbl_cancel' | translate}}</label>
                    </div>
                  </div>
                </div>
            </form>
          </div>

          <div class="sign-up-container" *ngIf="isSSOResetPassword">
            <form [formGroup]="ssoResetPwdForm" class="signup-form sso-verifyotp-form">
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_enter_otp'|translate}}</label>
                  <div class="input-group">
                    <input formControlName="otp" placeholder="{{'otp_placeholder'|translate}}" type="password" required>
                  </div>
                </div>
                  <label class="padding-top default-label">{{'lbl_please_enter_otp_message' | translate}} {{ setMobile }}</label>
                  <div class="input-group resend-container">
                    <button class="input-label resend-btn" (click)="ssoForgotPassword()" [disabled]="!resendOTP">{{'prtl_auth_lbl_resend_otp'|translate}}</button>
                    <span class="input-label" *ngIf="!resendOTP">{{ timeLeft }}</span>
                  </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_pls_enter_new_pwd' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="pin" placeholder="{{'pin_placeholder'|translate}}" type="password" required>
                  </div>
                  <!-- <p class="error-p" [ngClass]="{show: ssoResetPwdForm['controls']['pin']['value'] && ssoResetPwdForm['controls']['pin']['dirty'] && ssoResetPwdForm['controls']['pin']['touched'] && !ssoResetPwdForm['controls']['pin']['valid']}">
                    * Password should be minimum 6 digit
                  </p> -->
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_pls_reenter_new_pwd'|translate}}</label>
                  <div class="input-group">
                    <input formControlName="confirmPin" placeholder="{{'rpin_placeholder'|translate}}" type="password"
                      required>
                  </div>
                </div>
              </div>
              <!-- <button type="submit" (click)="continueCreateAccount()" *ngIf="!isResetPin" [disabled]="!ssoResetPwdForm.valid" type="button" class="primary-button"> {{'prtl_auth_lbl_resend_otp'|translate}} </button> -->
              <button type="submit" (click)="ssoResetPassword()"  [disabled]="!ssoResetPwdForm.valid" type="button" class="primary-button"> {{'btn_update_pwd'|translate}} </button>
            </form>
          </div>
          <div class="prefilled-register-container" *ngIf="isRegisterWithPrefill">
            <div class="not-registered-title">
              {{'Not_registered' | translate}}
            </div>
            <div class="not-registered-message">
              {{'not_registered_message' | translate}}<br/>
              <span class="font-danger-small">{{'password_must_contain_message' | translate}}</span>
            </div>
            <form [formGroup]="prefilledSignupForm" class="signup-form">
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_cpin' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="pin" placeholder="{{'pin_placeholder'|translate}}" type="password" required>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="input-container text-input">
                  <label>{{'prtl_auth_lbl_rpin' | translate}}</label>
                  <div class="input-group">
                    <input formControlName="confirmPin" placeholder="{{'rpin_placeholder'|translate}}" type="password"
                      required>
                  </div>
                </div>
              </div>
              <button type="submit" (click)="signupPrefilledForm()" [disabled]="!prefilledSignupForm.valid" type="button" class="primary-button"> {{'btn_signup'|translate}} </button>
            </form>
          </div>
          <div class="invalid-mobile-number" *ngIf="isPreCheckError">
            <button type="submit" (click)="toggleScreen('PRECHECK')"type="button" class="primary-button"> {{'btn_gotohome'|translate}} </button>
          </div>
        </ng-container>
        <div class="footer-details font-primary-small" *ngIf="loginWithOTP && (isPreCheck||isLogin) && false">
          {{'lbl_footer_msg_createacc'|translate}}
        </div>
        <div class="footer-details font-primary-small" *ngIf="isRegisterWithPrefill && false" (click)="toggleScreen('PRECHECK')">
          {{'lbl_footer_msg_goto_precheck'|translate}}
        </div>
        <div class="version-number font-light-small1">{{'Version' | translate}} {{versionNumber}}</div>
      </div>
    </div>
  </div>
</div>
<app-footer [isLogin]="true" [appRemoveFeatureOff]="'SHOW_APP_FOOTER'"></app-footer>

<div class="webview">
  <div id="pre-bootstrap-container" *ngIf="webViewState == 'LOADING' || checkingAutoSignon">
    <div id="pre-bootstrap"></div>
  </div>
  <div class="errorModule" *ngIf="webViewState == 'ERR_REFRESH_TOKEN' || webViewState == 'NO_REFRESH_TOKEN'">
    <app-unauthorized-page message="Unauthorized access"></app-unauthorized-page>
  </div>
</div>