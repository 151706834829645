import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFacilityComponent } from './search-facility.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { PersonAvatarComponent } from '../person-avatar/person-avatar.component';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';

@NgModule({
  declarations: [SearchFacilityComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, PersonAvatarModule
  ],
  
  exports:[SearchFacilityComponent]
})
export class SearchFacilityModule { }
