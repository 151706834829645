<div id="home-component">
  <div class="header-component" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header (updateLinkedPatient)="initLinkedRecords()" [refresh]="refresh.asObservable()" [showWelcome]="true" (openLogin)="showLoginAlert($event)" [pagesource]="'HOME'">
    </app-header>
  </div>
  <div class="home-component">
    <div class="custom-home-msg-container" *ngIf="enableAlertOnServiceUpdateNotification && false">
      <div class="custom-home-msg-item">
        <span class="">
          <img class="type-image" src="assets/icons/Update.svg" />
          <label>
            {{updateAlertInfo['title'] || 'HOME#NOTIFY#SERVICEREQUPDATED' | translate}}
          </label>
        </span>
        <div class="close-action-icon pointer">
          <img class="type-image"  src="assets/icons/close_grey.svg" (click)="enableAlertOnServiceUpdateNotification=false;updateAlertInfo={}" />
        </div>
      </div>
      <p class="custom-home-msg-sub-header" *ngIf="updateAlertInfo && updateAlertInfo.body">
        <span>
          {{updateAlertInfo.body}}
        </span>
      </p>
      <p class="custom-home-msg-action" (click)="clearAlertInfo(updateAlertInfo)">
        <span >{{'HOME#NOTIFY#VIEW#DETAIL' | translate}}</span>
        <img class="type-image" src="assets/icons/next arrow.svg" />
      </p>
    </div>
    <!-- FOR NAHDI-->
    <div class="home-wrapper" *ngIf="!newUI">
      <div class="desc-quick-links">
        <div class="company-description" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_SLIDER'">
          <app-company-description (openPackages)="openPkg($event)"></app-company-description>
        </div>
        <div class="quick-links-wrapper">
          <p class="home-section-title">{{'Quick_links' | translate}}</p>
          <div class="quick-action-item cursor-pointer" (click)="openBookAppointment()">
            <div class="quick-icon"><img src="./assets/icons/Home/Book appt.svg" alt="" class="type-image"></div>
            <div class="quick-label">{{'Book_an_appointment' | translate}}</div>
          </div>
          <div class="quick-action-item-row">
            <div class="quick-action-item cursor-pointer" (click)="openRegisterPatient()">
              <div class="quick-icon"><img src="./assets/icons/Home/add user copy 3.svg" alt=""></div>
              <div class="quick-label">{{'Register_a_patient' | translate}}</div>
            </div>
            <div class="quick-action-item cursor-pointer" (click)="openContactUsModal()">
              <div class="quick-icon"><img src="./assets/icons/Home/contact us.svg" alt=""></div>
              <div class="quick-label">{{'Contact_us' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="appointments" *ngIf="upcomingAppointments && upcomingAppointments.length" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_REMAINDERS'">
          <app-appointments [showAsSlide]="true" [upcomingAppointments]="upcomingAppointments" [appointment_history]="allAppointments" (openBookAppt)="openBookAppointment()" (changeInAppt)="fetchUpcomingAppointment()"></app-appointments>
        </div>
        <div class="appointments" *ngIf="consultAgainAppts && consultAgainAppts.length" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_REMAINDERS'">
          <app-consult-again [showAsSlide]="true" [consultAgain]="consultAgainAppts" (openConsultAgainAppt)="openRCAppointment()"></app-consult-again>
        </div>
      </div>
      <div class="services-wrapper">
        <p class="home-section-title">{{'Our_services' | translate}}</p>
        <div class="service-list-wrapper" [appRemoveFeatureOff]="'FT_APP_BOOK_VC_APPT'">
          <p class="service-list-header" (click)="showVideoOpt = !showVideoOpt">{{'Tele_medicine' | translate}}
            <img class="down-arrrow md-hidden" src="./assets/icons/arrow_white.svg">
          </p>
          <div class="service-list" [ngClass]="{'show-service': showVideoOpt}">
            <div class="service-list-item" (click)="openAppointment('VIDEO-CONSULT-NOW')">
              <div class="service-icon">
                <img src="./assets/icons/Home/onspot.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'hme_lbl_consult_doctor_now'|translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_consult_doctor_now_desc'|translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="openAppointment('VIDEO')">
              <div class="service-icon">
                <img src="./assets/icons/Home/vc.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'hme_lbl_online_consultation' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_online_consultation_desc' | translate}}
                </p>
              </div>
            </div>
          </div>
        
        </div>
        <div class="service-list-wrapper clinic" [appRemoveFeatureOff]="'FT_APP_BOOK_DIRECT_APPT'">
          <p class="service-list-header" (click)="showClinicOpt = !showClinicOpt">{{'Clinic_and_Home_care' | translate}}
            <img class="down-arrrow md-hidden" src="./assets/icons/arrow_white.svg">
          </p>
          <div class="service-list" [ngClass]="{'show-service': showClinicOpt}">
            <div class="service-list-item" (click)="openAppointment('HOSPITAL')">
              <div class="service-icon">
                <img src="./assets/icons/Home/clinicc.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'Clinic_appointment' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_clinic_appointment_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="openExpressClinic()" *ngIf="isExpressClinicEnabled">
              <div class="service-icon">
                <img src="./assets/icons/Home/care express.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'Express_clinic' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_express_appointment_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="openHomeCareServices()"
            [appRemoveFeatureOff]="'FT_APP_HOMESERVICES'">
              <div class="service-icon">
                <img src="./assets/icons/Home/home health copy.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'Home_health_care_services' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_home_care_desc' | translate}}
                </p>
              </div>
            </div>
          </div>
        
        </div>
        <div class="service-list-wrapper account" >
          <p class="service-list-header" (click)="showPersonalOpt = !showPersonalOpt">{{'My_account' | translate}}
            <img class="down-arrrow md-hidden" src="./assets/icons/arrow_white.svg">
          </p>
          <div class="service-list" [ngClass]="{'show-service': showPersonalOpt}">
            <div class="service-list-item" (click)="navigateToCalendar()">
              <div class="service-icon">
                <img class="type-image" src="./assets/icons/Home/Book appt.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'My_appointments' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_my_appointment_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="toggleMyPackages()">
              <div class="service-icon">
                <img src="./assets/icons/Home/my_packages.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'hme_lbl_my_pkg' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_my_pk_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="showServiceRequests()">
              <div class="service-icon">
                <img class="type-image" src="./assets/icons/Home/service requests.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'Service_requests' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_service_request_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="openModal('manage-identity-modal')">
              <div class="service-icon">
                <img src="./assets/icons/Home/family.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'My Family' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_my_family_desc' | translate}}
                </p>
              </div>
            </div>
            <div class="service-list-item" (click)="openUserActions()">
              <div class="service-icon">
                <img src="./assets/icons/Home/add user copy 3.svg" alt="">
              </div>
              <div class="service-context">
                <p class="service-label">
                  {{'Manage_My_Account' | translate}}
                </p>
                <p class="service-desc">
                  {{'hme_lbl_my_family_desc' | translate}}
                </p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <div class="health-packages-preview-wrapper">
        <app-health-package-preview (package)="openHealthPkg($event)"></app-health-package-preview>
      </div>
    </div>
    <!--For MOH-->
    <div class="align-container flx-row" *ngIf="newUI">
      <div class="wrapper">
        <div class="company-description" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_SLIDER'">
          <app-company-description (openPackages)="openPkg($event)"></app-company-description>
        </div>
        <div class="row-container" >
          <div class="list-item cursor-pointer" [appRemoveFeatureOff]="'FT_APP_BOOK_DIRECT_APPT'">
            <div class="visit-clinic-bg" (click)="showClinicOpt = !showClinicOpt">
              <img class="type-image" src="assets/icons/calendar.svg" />
              <span class="title">
                {{'visit_our_clinic'|translate}}
              </span>
              <span class="item-status type-image" [ngClass]="{active: showClinicOpt == true}">
                <img src="assets/icons/next_arrow_white.svg">
              </span>
            </div>
            <div class="component-container" *ngIf="showClinicOpt == true">
              <div class="component-row" (click)="openAppointment('HOSPITAL')">
                <img class="type-image" src="assets/icons/clinic.svg" />
                <span class="border-bottom default-title">
                  {{'visit_our_clinic'|translate}}
                </span>
              </div>
              <div class="component-row" (click)="openExpressClinic()" *ngIf=isExpressClinicEnabled>
                <img class="type-image" src="assets/icons/walk.svg" />
                <span class="border-bottom default-title">
                  {{'visit_near_clinic'|translate}}
                </span>
              </div>
            </div>
          </div>
          <div class="list-item cursor-pointer" [appRemoveFeatureOff]="'FT_APP_BOOK_VC_APPT'">
            <div class="Consult-online-bg" (click)="showVideoOpt = !showVideoOpt">
              <img class="type-image" src="assets/icons/video_white.svg" />
              <span class="title">{{'hme_lbl_online_consultation'|translate}}</span>
              <span class="item-status type-image" [ngClass]="{active: showVideoOpt == true}"> <img
                  src="assets/icons/next_arrow_white.svg"></span>
            </div>
            <div class="component-container" *ngIf="showVideoOpt == true">
              <div class="component-row" (click)="openAppointment('VIDEO')">
                <img class="type-image" src="assets/icons/video.svg" />
                <span class="border-bottom default-title">{{'hme_lbl_online_consultation'|translate}}</span>
              </div>
              <div class="component-row" (click)="openAppointment('VIDEO-CONSULT-NOW')" *ngIf=isOnspot>
                <img class="type-image" src="assets/icons/search_blue.svg" />
                <span class="border-bottom default-title">{{'hme_lbl_consult_doctor_now'|translate}}</span>
              </div>
            </div>
          </div>
          <div class="list-item cursor-pointer" (click)="openHomeCareServices()"
            [appRemoveFeatureOff]="'FT_APP_HOMESERVICES'">
            <div class="home-services-bg" (click)="openHomeCareServices()">
              <img class="type-image" src="assets/icons/navigation/home.svg" />
              <span class="title">{{'hme_lbl_home_services'|translate}}</span>
              <span class="item-status type-image"><img src="assets/icons/next_arrow_white.svg"></span>
            </div>
          </div>
          <div class="list-item cursor-pointer" (click)="openHealthPkg()"
            [appRemoveFeatureOff]="'FT_APP_HEALTH_PACKAGE'">
            <div class="hlth-record" (click)="showHealthOpt = !showHealthOpt">
              <img class="type-image" src="assets/icons/health-insurance.svg" />
              <span class="title">{{'hme_lbl_pkg'|translate}}</span>
              <span class="item-status type-image"><img src="assets/icons/next_arrow_white.svg"></span>
            </div>
          </div>
          <div class="list-item cursor-pointer">
            <div class="account-service-bg" (click)="showPersonalOpt = !showPersonalOpt">
              <img class="type-image" src="assets/icons/user_white.svg" />
              <span class="title">{{'hme_lbl_acct_summary'|translate}}</span>
              <span class="item-status type-image" [ngClass]="{active: showPersonalOpt == true}"> <img
                  src="assets/icons/next_arrow_white.svg"></span>
            </div>
            <div class="component-container" *ngIf="showPersonalOpt == true">
              <div class="component-row" (click)="openModal('manage-identity-modal')"
                [appRemoveFeatureOff]="'FT_APP_MANAGE_EXIST_IDENTITY'">
                <img class="type-image" src="assets/icons/health_records_clr.svg" />
                <span class="border-bottom default-title">{{'app_v8_my_family'|translate}}</span>
              </div>
              <div class="component-row" (click)="openUserActions()">
                <img class="type-image" src="assets/icons/user_clr.svg" />
                <span class="border-bottom default-title">{{'ttl_account'|translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-container " >
        <div class="appointments" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_REMAINDERS'">
          <app-ongoing-appointment [upcomingAppointments]="ongoingAppointments"
            *ngIf="ongoingAppointments &&  ongoingAppointments.length != 0"></app-ongoing-appointment>
          <app-appointments [upcomingAppointments]="upcomingAppointments" [appointment_history]="allAppointments"
            (openBookAppt)="openBookAppointment()" (changeInAppt)="fetchUpcomingAppointment()"></app-appointments>
          <app-openreferrals [openReferrals]="openReferrals" (openReferralAppt)="openReferralAppointment($event)"
            *ngIf="openReferrals && openReferrals.length>0">
          </app-openreferrals>
        </div>
        <div class="to-do" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_TODO'">
          <app-to-do></app-to-do>
        </div>
        <div class="care-plan" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_CARE_PLAN'">
          <app-care-plan></app-care-plan>
        </div>
        <div class="recent-visits" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_RECENT_VISITS'">
          <app-recent-visits></app-recent-visits>
        </div>
        <div class="patient-records-card" *ngIf="recordsToLink.length != 0"
          [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_EXIST_UNLINKED'">
          <app-link-records [recordsToLink]="recordsToLink" [relations]="relations"
            (updateLinkedPatient)="initLinkedRecords()" [entityId]="entity"></app-link-records>
        </div>

        <div class="feedback-card" [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_COMMON_FEEDBACK'">
          <app-home-feedback-card (openFeedbackForm)="openFeedbackForm()"></app-home-feedback-card>
        </div>
      </div>
    </div>
    <app-footer [appRemoveFeatureOff]="'SHOW_APP_FOOTER'" [isCrm]="true"></app-footer>
  </div>
</div>

<app-register-new-patient *ngIf="isRegisterPatient" (close)="closeRegisterPatient()"></app-register-new-patient>
<app-home-feedback (close)="closeFeedbackForm()" *ngIf="isFeedback"></app-home-feedback>
<app-custom-modal-container id="manage-identity-modal" (complete)="closeManageIdentities()">
  <app-manage-identities *ngIf="isManageIdentities" [isPatReg]="isPatReg" (updateLinkedPatient)="initLinkedRecords()">
  </app-manage-identities>
</app-custom-modal-container>
<app-manage-account *ngIf="openUserProfileActions" (close)="closeUserActions()"></app-manage-account>
<app-service-request *ngIf="openServiceRequests" (close)="openServiceRequests = false"></app-service-request>

<!-- <app-custom-modal-container id="book-appointment-modal" (complete)="closeBookAppointment($event)"> -->
  <app-book-appointment *ngIf="isBookAppointment" (close)="closeBookAppointment($event)" (complete)="closeBookAppointment($event)" (openLogin)="showLoginAlert($event)" [reserve]="bookingAttributes"  [isReferral]="isReferral"
  [orderdetails]="orderdetails" [expressdetails]="expressdetails" [chooseSlot]="chooseSlot" [prefillData]="prefillData" [ongoingAppts]="allAppointments">
</app-book-appointment>
<app-express-clinic-modal *ngIf="isExpressClinic" [orderdetails]="orderdetails" (close)="closeExpressClinic()"></app-express-clinic-modal>
<!-- <app-health-package *ngIf="openHealthPkgs" (close)="closeHealthPkg()" [pkgdetails]="pkgdetails" [selectedPackage]="selectedPkg"></app-health-package> -->
<app-health-package-flow *ngIf="openHealthPkgs" (close)="closeHealthPkg()" [pkgdetails]="pkgdetails" [selectedPackage]="selectedPkg"></app-health-package-flow>
<app-home-care-services *ngIf="homeServices" (close)="closehomeServices($event)"></app-home-care-services>
<app-pre-login-modal *ngIf="showPreLoginModal" [context]="preloginContext" (close)="showPreLoginModal = preloginContext = null"></app-pre-login-modal>
<app-my-packages *ngIf="openMyPackages" (close)="closeMyPackages()"></app-my-packages>
<app-contact-us-modal *ngIf="showContactusModal" [contactUs]="true" (close)="showContactusModal = false">
</app-contact-us-modal>
<app-alert-modal *ngIf="openAlertModal" (close)="openAlertModal = false"></app-alert-modal>
