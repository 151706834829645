<div class="ui form" id="check-list">
  <div class="grouped fields">
    <div class="identifer">
      <p 
        class="check-label"
      > <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
        <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
      </p>
    </div>
    <div class="check-list">
      <div class="field" *ngFor="let a of section['options']">
        <div class="ui checkbox">
          <label class="check-opt cursor-pointer {{'type-'+section['trueType']}}"
            [ngClass]="{valid: section['trueType']=='radio'?section['selected'] === a.localName[0].text:section['checked'].includes(a.localName[0].text)}"
            [for]="'check_'+UUID+section['domainCode']+'_'+a.localName[0].text">
            <input
              [checked]="section['trueType']=='radio'?section['selected'] === a.localName[0].text:section['checked'].includes(a.localName[0].text)" [tabindex]="tabindex"
              (click)="listenToEvent(a.localName[0].text, a.localName)" [name]="'check_'+UUID+section['domainCode']"
              type="{{ section['trueType'] }}" [id]="'check_'+UUID+section['domainCode']+'_'+a.localName[0].text">
            {{a.localName | translate:'LOCALE':'text'}}</label>
        </div>
      </div>
    </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
      <p>{{'form_field_mandatory' | translate}}</p>
    </div>
  </div>
</div>
