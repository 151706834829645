import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { enterAnimation, switchAnimation ,enterAnimationAxisX} from "../animations";
import { ModalService} from '../services/modal.service';
import {AppointmentService} from '../services/appointment.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslatePipe } from '../pipes/translate.pipe';
@Component({
  selector: 'app-ongoing-appointment',
  templateUrl: './ongoing-appointment.component.html',
  styleUrls: ['./ongoing-appointment.component.scss'],
  animations: [enterAnimation, switchAnimation, enterAnimationAxisX],
  providers: [TranslatePipe]
})
export class OngoingAppointmentComponent implements OnInit {

  private appConfig: any;
  public translateType = "LOCALE";
  public appointmentDetail = false;
  public appointment_history: any;
  public appointmentData: any;
  public hasPaymentToShow:boolean;
  public apptRefNumber:any;
  public callBack: string;
  public isApptView: boolean;
  public rescheduleData: Object = null;
  public orderdetails: Object = null;
  public isBookAppointment: boolean;
  public bookingAttributes: any;
  public prefillData: Object = null;
  public isRegisterPatient: boolean;
  public isUploadQuestionnarie: boolean;
  public isUploadDocs: boolean;
  public selectedAppt: Object=null;
  // public upcomingAppointments: any;
  public facility: any;
  public facilityList: any;
  @Input() upcomingAppointments: any;
  @Output() openBookAppt: EventEmitter<any>
  constructor(private user: UserService, private modalService: ModalService, private appointmentService: AppointmentService, private activatedRoute: ActivatedRoute, private location: Location, private translatePipe: TranslatePipe) { 
    this.openBookAppt = new EventEmitter<any>();
  }

  ngOnInit() {
    // this.checkUrl();
    this.user.getConfigurationInfo.subscribe(data => {
      this.appConfig = data;
    })
    // this.fetchUpcomingAppointment();
  }


  public openAppointment(url) {
    this.user.openLocation(url + "&source=px&lan=" + this.user.currentLanguage);
  }

}
