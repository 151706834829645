import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import { visitType, apptFlowType } from '@type';

@Component({
  selector: 'app-express-clinic-practitioner',
  templateUrl: './express-clinic-practitioner.component.html',
  styleUrls: ['./express-clinic-practitioner.component.scss']
})
export class ExpressClinicPractitionerComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Input() flowType: apptFlowType;
  public practList: Array<any>;
  public translateType = 'LOCALE';

  constructor(private user: UserService, private linkPatient: LinkPatientService ) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
  }

  ngOnInit() {
    this.practList = this._input['in']['inputService']['practDetails'];
  }

  public completeSelection(event: any) {
    let obj = {
      practitionerId: event.practitionerId,
      name: event.practitionerName
    }
    this.complete.emit({ practitioner: obj });
  }

  public changeFacility() {
    this.action.emit({
      name: 'change-facility'
    })
  }
}
