<div id="contact-us" class="right-navigation showModal">
  <!-- <div (click)="closeModal()" class="empty"></div> -->
  <div class="component">
    <div class="header border-bottom" [ngClass]="{'hide': selectedPat}">
      <div class="header-row">
        <div (click)="closeModal()" class="back-btn float-left closeicon pointer">
          <img src="assets/icons/arrow.svg">
        </div>
        <div class="font-primary-subtitle bold">{{"INSURANCE#MODAL#MY#INSURANCE" | translate}}</div>
      </div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>

    <div class="content">
      <div class="profile-section">
        <div class="options" [ngClass]="{'hide': selectedPat}">
          <div id="pat-list-card">
            <ng-container>
              <div class="card-layout" *ngFor="let pat of patientList">
                <div class="card cursor-pointer" [ngClass]="{'selected-card': pat.selected}" (click)="choosePatient(pat)">
                  <div class="row">
                    <!-- <input type="radio" [id]="'random'+pat['uhid']" [name]="'random'"> -->
                    <div class="image">
                      <app-person-avatar [url]="pat.imageurl"> </app-person-avatar>
                    </div>
                    <label class="card-content" [for]="'random'+pat.uhid">
                      <div class="subheader-font bold txt_flow">{{pat.title}}</div>
                      <div class="subtitle-primary" *ngIf="pat['summary']">
                        {{pat['summary']}}
                      </div>
                      <div class="subtitle txt_flow" *ngIf="!pat['summary']">
                        {{pat['actualData']['gender'] == 'F'? 'Female':'Male'}}, {{pat['actualData']['dob'] | age}}, {{pat['actualData']['relationshiptype'] | translate : 'RELATION'}}, {{pat['actualData']['uhid']}}
                      </div>
                    </label>
                    <!-- <div class="restrict-applied" (click)="$event.stopPropagation();gotoPolicyAction(p)" *ngIf="pat['insuranceDetails'] && pat['insuranceDetails'].length>0 && pat['insuranceDetails'][0]['billingGrpId']!=='CASH'">
                      <img src="./assets/icons/manage-insurance/edit.svg" class="restrict-app-info">
                    </div> -->
                  </div>
                  <div class="row">
                    <p class="billing-grp">{{'Billing group: '}} <span *ngIf="pat['insuranceDetails'] && pat['insuranceDetails'].length>0;else noBillingGroup"><strong *ngIf="pat['insuranceDetails'][0]['billingGrpId']=='CASH'">{{"MANAGEPOLICY#LABEL#BILLINGGRP#CASH" | translate}}</strong><strong *ngIf="pat['insuranceDetails'][0]['billingGrpId']!=='CASH'">{{"MANAGEPOLICY#LABEL#BILLINGGRP#INS" | translate}}</strong></span></p>
                    <ng-template #noBillingGroup>
                      <p class="no-bill-grp" *ngIf="!retrievePolicy">{{"MANAGEPOLICY#MSG#BILLINGGRP#CANNOT#RETRIEVE" | translate}}</p>
                    </ng-template>
                  </div>
                  <div class="row">
                    <ng-container *ngIf="pat['insuranceDetails'] && pat['insuranceDetails'].length>0 && pat['insuranceDetails'][0]['billingGrpId']!=='CASH'">
                      <div class="policy-con">
                        <label class="info-con">
                          <p class="policy-name">
                            <span ><strong>{{pat['insuranceDetails'][0]['billingGrpName']}}</strong></span>
                          </p>
                          <p class="policy-num">
                          <span>{{'Policy number: '}}</span>
                          <span color="primary" *ngIf="pat['summary']"><strong>{{pat['insuranceDetails'][0]['policyNumber']}}</strong></span>
                          </p>
                        </label>
                        <div class="status-con">
                          <p class="eff-con">{{"MANAGEPOLICY#LABEL#EFFECT#TILL" | translate}}<span><strong>{{getEffectedDate(pat['insuranceDetails'][0]['policyExpiryDate'])}}</strong></span></p>
                          <p class="expired-p" *ngIf="!checkIsExpired(pat['insuranceDetails'][0]['policyExpiryDate'])">{{"MANAGEPOLICY#LABEL#STATUS#EXPIRED" | translate}}</p>
                          <p class="active-p" color="success" *ngIf="checkIsExpired(pat['insuranceDetails'][0]['policyExpiryDate'])">{{"MANAGEPOLICY#LABEL#STATUS#ACTIVE" | translate}}</p>
                      </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="components" *ngIf="showInsuranceDtl">
          <app-insurance-details [patient]="selectedPat" (close)="closeInfo()" (refresh)="refreshPolicyDtl()" [payGroupList]="payGroup"></app-insurance-details>
        </div>
      </div>
    </div>
  </div>
