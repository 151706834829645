import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { inOutData } from '@type';
import { UserService } from '../services/user.service';
import { SocketService } from '../services/socket.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  
  @Input() routeItem: inOutData;
  @Input() facilityId: string;

  @Output() complete: EventEmitter<Object>;

  public searchKey: string;
  public searchKeyCompleted: string;
  public mapOptions: any;
  public mapZoom: any;
  public mapCenter: any;
  public markers: any;
  public actualduration: any;
  public hospLoc: any;
  public showDirection: boolean = false;
  public showAlert: boolean = true;
  public directionsService: any;
  public locationError: any;

  private directionsRenderer: any;
  private viewMap: any;
  private startLocationMarker: any;
  private endLocationMarker: any;
  @ViewChild('addresstext') addresstext: any;
  @ViewChild('map') map: any;
  constructor(private user: UserService, private socket:SocketService) {
    this.complete = new EventEmitter<Object>();
    this.mapZoom = 12;
    this.markers = []
    this.mapOptions = {
      mapTypeId: 'roadmap',
      zoomControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      scaleControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      maxZoom: 15,
      minZoom: 8,
    }
    this.locationError = { status: false, message:''}
  }

  ngOnInit() {
    if (this.routeItem['homecareActivityDetails'] && this.routeItem['homecareActivityDetails']['status'] =='PRACT_STARTED') {
      this.showDirection = true;
    } 
    this.joinSocket();
    this.getLocation();
    this.socket.homecareLocationUpdateSubject.subscribe(data => {
      if (this.routeItem['appointmentId'] == data.apptId) {
        if ((this.hospLoc && this.hospLoc.latitude == data.latitude && this.hospLoc.longitude == data.longitude) || (this.locationError && this.locationError['status'])) {
          console.log('STOP STATUS', this.hospLoc, this.locationError)
        } else {
          this.hospLoc = {
            lat: data.latitude,
            lng: data.longitude
          };
          if (this.showDirection && this.directionsService) {
            this.calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, this.hospLoc, this.mapCenter, this.viewMap);
          } else {
            this.showDirection = true;
            this.getLocation();
          }
        }
      }
    })
    // let autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
    //   types: []
    // });
    // autocomplete.addListener('place_changed', () => {
    //   let place = autocomplete.getPlace();
    //   this.mapCenter = {
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng(),
    //   }
    //   this.pushMarker();
    // });
  }

  private getLocation() {
    if (navigator.geolocation) {
      this.pushMarker();
      // navigator.geolocation.getCurrentPosition(position => {
      //   this.mapCenter = {
      //     lat: position.coords.latitude,
      //     lng: position.coords.longitude,
      //   }
      //   this.pushMarker();
      // });
    } else {
      this.pushMarker();
    }
  }

  private pushMarker() {
    
    let geocoder = new google.maps.Geocoder();
    let searchStr =null
    if (this.routeItem['homeCareServices'] && this.routeItem['homeCareServices']['location']) {
      let lat = this.routeItem['homeCareServices']['location']['lat'];
      let lng = this.routeItem['homeCareServices']['location']['lng'];
      let add = this.routeItem['homeCareServices']['location']['address'];
      if (lat && lng) {
        searchStr = {
          location: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          }
        }
      } else if (add) {
        searchStr = {
          address: add
        }
      } else {
        return;
      }
    } else {
      return;
    }
    geocoder.geocode(searchStr, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.mapCenter = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }
          if (!this.viewMap) {
            this.viewMap = new google.maps.Map(
              document.getElementById("map") as HTMLElement,
              {
                zoom: 12,
                center: { ...this.mapCenter }
              }
            );
          }
          if (this.hospLoc) {
            this.initMap(this.hospLoc, this.mapCenter);
          }
        }
      } else {
        this.locationError = {
          status: true,
          value: status
        }
      }
    })
  }
  public searchFocus() {
    this.searchKey = '';
  }
  public searchBlur() {
    this.searchKey = this.searchKeyCompleted;
  }
  public close() {
    this.socket.leaveHomecareLocationUpdate(this.routeItem['appointmentId'], this.routeItem['facilityId'], this.routeItem['resourceId']);
    this.complete.emit();
  }

  initMap(from, to): void {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true});
    this.directionsRenderer.setMap(this.viewMap);
    this.calculateAndDisplayRoute(this.directionsService, this.directionsRenderer, from, to, this.viewMap);
    
  }

  calculateAndDisplayRoute( directionsService: google.maps.DirectionsService, directionsRenderer: google.maps.DirectionsRenderer, from, to, map) {
    directionsService.route(
      {
        origin: { lat: from.lat, lng: from.lng },
        destination: { lat: to.lat, lng: to.lng },
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === "OK") {
          this.showAlert = false;
          directionsRenderer.setDirections(response);
          var leg = response.routes[0].legs[0];
          if (this.startLocationMarker) {
            this.startLocationMarker.setMap(null);
          }
          this.startLocationMarker = new google.maps.Marker({
            position: leg.start_location,
            map: map,
            icon: user64,
          });
          if (!this.endLocationMarker) {
            this.endLocationMarker = new google.maps.Marker({
              position: leg.end_location,
              map: map,
              icon: location64,
            });
          }
          this.actualduration = leg.duration.text
        } else {
          this.locationError = {
            status: true,
            value: status
          }
        }
      }
    );
  }
  private joinSocket() {
    this.socket.joinHomecareLocationUpdate(this.routeItem['appointmentId'], this.routeItem['facilityId'], this.routeItem['resourceId'])
  }
}


const user64 = "./assets/icons/homecare/doctor.svg"
const location64 = 'https://img.icons8.com/color/32/000000/marker.png';
const alocation64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAD40lEQVR4Xu2Y22tcVRTGv7XPTBKdi7U1USwi6FMtLdi5NaFK/wCFigaq+CCU2pe+i7dKqYr4KoL2QRERFTR9811UJmfmJIXaBn1RKBTFtLZ6ZiZNZmYtORNTMxfs2QPnAmfP00xmbfb3/da31hlCSPiLEu4fBoBJQMIJmBFIeADMEjQjYEYg4QTMCCQ8AOYpYEbAjEDCCZgRSHgAonkKyOzsHa60DxBoN5hFLHUlh9R5qlbXwm5IqCPQmC3sQ0deYeCIlZ6YIktBmCGdDpj5prKsBRH1Vs62V8ICEQoAAahVLp7qQl6fmJ6xrB07Qen0LY+d339D5/q13mdm7lik3sjUnLfDgBAKALdSOGtNTB1P734A3N4AN1zI+jpEGCDqvQdzn18C3s/YzsmgIQQOwC0XT6p06r3U9Aw6V1ch7bZvTwScyNjOWd8HxigMFID72KPTat36hfL5rNd1iOhK/Kur0g/dVa3+qXvQb32gAJoHC68J1JmeGH3zmx4EL2Vrzrt+DenWBQrArRQdAgrDovgaiXqVhL7xvuta/AR1+U0oa+dgrQDVnO3M6RrzWx8YAJmft9zLv64p4L9132soWoBVGnzUeY9I7khNKTW1XTwzr+Xry3f6NaRbFxiAG4f23Z1qTw7N7v9td7dU/JAUXhw0kVnbyNKFC01dc37qAwPg/dprcrs1KIKAYxnb+WiUuGa5eEIIHwwBcFuTtLKy4ceQbk1gADwhf5eKV5TC/f2i5OWsvfTOSACV0imBnN7+nRAu5xadB3WN+a0PFECjUvwUwPP9YvjnjHtz/2BH5fDhqVarcVEID/ftAKJP8ov1F/wa0q0LFIBbKj1OSr4d2uwk57qpjWM7vv/xei8p5fIuBf4YhCcHa5lwKL/o/KBrzG99oAA8EW6leI6AIyMENQGuen8XqDkChjY9MX+dqS8/49fMOHWBA2iUSvdBdS8CapeWQMGqTHb35r47v6p1TrM4cACenka58ByIPtPRJsB8zna+0jkzTm0oAG4zCqN0f5m1naPjGNI9ExqARqVyL9C+dNtRYPqDmffml5au6poZpz40AH5HIazob8EKFUBvFA4WFkjoqZHdYv4iW19+dpxOjnsmfABz+2eoO3EJwD19or3oEz2Sr9U2/zcW0it0AP8uxKMEfL7doxA9nVusL4Tk+9Y1kQAYGoUIoh/ZDti62N0aBSaOIvqRA9hMwYE9QiT56tJPYUc/FgCiMr393sh2QBzMexoMgLh0IiodJgFRkY/LvSYBcelEVDpMAqIiH5d7TQLi0omodJgEREU+LveaBMSlE1HpMAmIinxc7k18Av4BfmY/UJPaigoAAAAASUVORK5CYII="

  // < img src = "https://img.icons8.com/color/48/000000/marker.png" />