<div id="appt-confirm">
  <div class="appt-review-details">
    <div class="success-container" *ngIf="_input.in['confirm']&&(_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS') && flowType != 'RESCHEDULE'">
      <img src="assets/icons/check.svg" class="type-image">
      <span class="font-default-small bold">{{'cal_modal_success_msg'|translate:'LABELS'}}</span>
    </div>

    <div class="info-container">
      <div class="font-default-small col-9">{{'appt_dtl_msg_download_app_now'|translate:'LABELS'}}</div>
      <div class="logo-container col-3">
        <div class="logo">
          <img class="type-image" src="assets/images/app-googleplay.png" alt="">
        </div>
        <div class="logo">
          <img class="type-image" src="assets/images/apple_play.png" alt="">
        </div>
        <div class="logo" *ngIf="false">
          <img class="type-image" src="assets/images/apple_play.png" alt="">
        </div>
      </div>
    </div>


    <div class="appt-card-wrapper">
      <div class="appt-container">
        <div class="appt-card">
          <div class="appt-header border-bottom">
            <div class="ternary-text">{{'Appointment_type'|translate:'LABELS'}}</div>
            <div class="title-text">{{'Clinic_consultation_-_Care_Express'|translate:'LABELS'}}</div>
          </div>
          <div class="appt-header border-bottom">
            <div class="title ternary-text">{{'Appointment_for' | translate}}</div>
            <div class="appt-facility-pract">
              <div class="image">
                <app-person-avatar [url]="_input['in']['patient']['image']" [additionalClass]="'circular'"> </app-person-avatar>
              </div>
              <div class="appt-facility-pract-details">
                <span class="secondary-text bold">{{_input['in']['patient']['name']}}</span>
                <span class="ternary-text">{{_input['in']['patient']['relation'] | translate : 'RELATION'}}</span>
              </div>
            </div>
          </div>
          <div class="appt-header border-bottom" *ngIf="practDetails">
            <div class="title ternary-text">{{'Consulting_doctor' | translate}}</div>
            <div class="appt-facility-pract">
              <div class="image">
                <app-practitioner-avatar [url]="practDetails['photoUrl']" [additionalClass]="'circular'"></app-practitioner-avatar>
              </div>
              <div class="appt-facility-pract-details">
                <span class="secondary-text bold">
                  {{ 'dr_label' |translate: 'LABELS'}} {{ practDetails['practitionerName'] | translate : translateType : 'name' }}
                </span>
                <span class="ternary-text">
                  {{ practDetails['specialityName'] | translate : translateType : 'name' }}
                </span>
              </div>
            </div>
          </div>
          <div class="appt-service">
            <div class="title ternary-text service-title">{{'Services'|translate}}</div>
            <div class="services" *ngIf="_input['in']['service']['services']">
              <div class="service" *ngFor="let service of _input['in']['service']['services'];let indexOfService=index">
                <!-- {{service['conceptName']}} -->
                  <span class="service__text">{{ getServiceName(service['details'],service['conceptName'])}}</span>
                  <span class="bold service__price">{{service['details']['servicecost']}} {{currency_code}}</span>
                  <!-- <span *ngIf="indexOfService < _input['in']['service']['services'].length - 1">, </span> -->
              </div>
              <div class="service">
                <span class="bold service__text secondary-text">{{ 'Total_services_charges' | translate }}</span>
                <span class="secondary-text bold service__price service__price--dark">{{_input['in']['service']['totalAmount']}} {{currency_code}}</span>
              </div>
            </div>
            <!-- <div class="secondary-text show">
              <ng-container *ngFor="let service of _input['in']['service']['services'];let indexOfService=index">
                <div>
                  <_!-- {{service['conceptName']}} --_>
                    {{ getServiceName(service['details'],service['conceptName'])}}
                    <span class="bold"> {{currency_code}} {{service['details']['servicecost']}}</span>
                    <span *ngIf="indexOfService < _input['in']['service']['services'].length - 1">, </span>
                </div>
              </ng-container>
            </div> -->
            <!-- <div class="appt-service-amt-time">
              <span class="bold">{{currency_code}} {{_input['in']['service']['totalAmount']}}</span>
              <span class="secondary-text"><span *ngIf="totalServiceTime.days() > 0">{{ totalServiceTime.days() }}
                  {{'lbl_los_unit'|translate}} </span><span
                  *ngIf="totalServiceTime.hours()>0">{{totalServiceTime.hours()}}
                  {{'lbl_hrs'|translate}} </span><span
                  *ngIf="totalServiceTime.minutes()>0">{{totalServiceTime.minutes()}}
                  {{'lbl_min'|translate}}</span></span>
            </div> -->
          </div>
          <!-- <div class="appt-facility border-bottom">
            <div class="appt-facility-details" *ngIf="flowType != 'RESCHEDULE'">
              <div>
                <img class="type-image" src="assets/images/signin/logo_minimal.png" />
                <span> {{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}</span>
              </div>
              <div class="appt-facility-addr">
                <img class="type-image" src="assets/icons/pin_blue.svg" />
                <span class="secondary-text"> {{ _input['in']['facility']['address'] }}
                  <span class="secondary-text" *ngIf="clinicMobileNumber">{{ clinicMobileNumber }}</span></span>
              </div>
              <div class="appt-facility-contact">
                <span class="secondary-text responsive-padding"> {{_input['in']['facility']['distance']}}
                  {{'lbl_kms'|translate}}</span>
                <span class="responsive-float-right">
                  <img class="nav-icon cursor-pointer type-image" src="assets/icons/navigation.svg"
                    (click)="navigateUser()" />
                  <img class="call-icon cursor-pointer type-image" src="assets/icons/phone.svg"
                    *ngIf="clinicMobileNumber" (click)="call()" />
                </span>
              </div>
            </div>
          </div>
          <div class="appt-slot">
            <div class="appt-slot-content">
              <img class="type-image" src="./assets/icons/calendar-simple.svg" />
              <span>{{ slotDate }}</span>
            </div>
            <div class="appt-slot-content bor-left">
              <img class="type-image" src="./assets/icons/clock.svg" />
              <span>{{ _input['in']['slot']['name'] }}</span>
            </div>
          </div> -->
          <div class="appt-header">
            <div class="title ternary-text">{{'Date_&_location' | translate}}</div>
            <div class="title-text no-bold pad-top">
              <app-appt-time [type]="'DETAILEXPRESS'" [time]="_input.in['slot']['fromDate']" [toTime]="_input.in['slot']['toDate']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'DD MMM YYYY, hh:mm a'" [toFormat]="'hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: _input.in['facility'])" [facilityId]="_input.in['facility']"></app-appt-time>
            </div>
            <div class="title-text no-bold">{{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}</div>
          </div>
        </div>
      </div>

      <div class="appt-card-side">
        <div class="payment-details"
          *ngIf="_input.in['confirm']&&(_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS') && flowType != 'RESCHEDULE'">
          <div class="card">
            <div class="card-head">
              <span class="font-default-small bold secondary-text">{{'appt_dtl_pmt_details'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content">
              <div class="payment-group">
                <div class="payment-content">
                  <div class="font-light-small secondary-text ternary-color">{{'appt_dtl_pmt_details_category'|translate:'LABELS'}}</div>
                  <div class="bold secondary-text">{{_input.in['confirm']['category']}}</div>
                </div>
                <div class="payment-content" *ngIf="_input.in['confirm']['amount']">
                  <div class="font-light-small secondary-text ternary-color">{{'amount_paid'|translate:'LABELS'}}</div>
                  <div class="secondary-text bold color-notify">{{_input.in['confirm']['amount']}}
                    {{_input.in['confirm']['currency']}}</div>
                </div>
                <div class="payment-content" *ngIf="_input.in['confirm']['amount_to_be_paid']">
                  <div class="font-light-small secondary-text ternary-color">{{'amount_to_be_paid'|translate:'LABELS'}}</div>
                  <div class="secondary-text bold font-danger-default">{{_input.in['confirm']['amount_to_be_paid']}}
                    {{_input.in['confirm']['currency']}}</div>
                </div>
                <div class="payment-content" *ngIf="_input.in['confirm']['prev_amt_paid']">
                  <div class="font-light-small secondary-text ternary-color">{{'prev_amt_paid'|translate:'LABELS'}}</div>
                  <div class="secondary-text bold color-notify">{{_input.in['confirm']['prev_amt_paid']}}
                    {{_input.in['confirm']['currency']}}</div>
                </div>
                <div class="payment-content" *ngIf="_input.in['confirm']['medium']=='ONLINE'">
                  <div class="font-light-small secondary-text ternary-color">{{'payment_medium'|translate:'LABELS'}}</div>
                  <div class="secondary-text">{{_input.in['confirm']['medium']}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-details"
          *ngIf="_input.in['confirm']&&!(_input.in['confirm']['status']=='S'|| _input.in['confirm']['status']=='SUCCESS')">
          <div class="card">
            <div class="card-head">
              <span class="font-default-small bold secondary-text">{{'appt_dtl_pmt_failed'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content">
              <div class="payment-group payment-error">
                <img class="type-image" src="assets/icons/alert_red.svg" />
                <span class="font-danger-small secondary-text">{{'msg_payment_err'|translate}} <span class="secondary-text click_here cursor-pointer"
                    (click)="payForAppt()">{{'appt_click_here'|translate}}</span></span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="transaction-details" *ngIf="_input.in['confirm']&&_input.in['confirm']['medium']=='ONLINE'">
          <div class="card">
            <div class="card-head border-bottom">
              <span class="font-default-small bold">{{'transaction_details'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content">
              <div class="transaction-group">
                <p class="font-default-small">{{'transaction_note'|translate}}</p>
                <div class="transaction-content">
                  <div class="font-light-small">{{'transaction_id'|translate:'LABELS'}}</div>
                  <div>{{_input.in['confirm']['transactionId']||'-'}}</div>
                </div>
                <div class="transaction-content">
                  <div class="font-light-small">{{'gateway_ref_no'|translate:'LABELS'}}</div>
                  <div>{{_input.in['confirm']['gatewayRefId']||'-'}}</div>
                </div>
                <div class="transaction-content">
                  <div class="font-light-small">{{'bank_ref_no'|translate:'LABELS'}}</div>
                  <div>{{_input.in['confirm']['bankRefId']||'-'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="appt-guidelines" *ngIf="_input.in['confirm']&&_input.in['confirm']['status']=='S'">
          <div class="card">
            <div class="card-head">
              <span class="font-default-small bold secondary-text">{{'ttl_appt_guidelines'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content font-default-small">
              <p class="secondary-text">{{'cont_appt_guidelines'|translate:'LABELS'}}</p>
            </div>
          </div>
        </div>
        <div class="visit-guidelines" *ngIf="_input.in['confirm']&&_input.in['confirm']['status']=='S'">
          <div class="card">
            <div class="card-head">
              <span class="font-default-small secondary-text bold">{{'ttl_visit_reg_checkin'|translate:'LABELS'}}</span>
            </div>
            <div class="card-content font-default-small secondary-text">
              <p>{{'cont_visit_reg_checkin'|translate:'LABELS'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button class="primary-button btn-customize" (click)="closeModal()">
    {{'Back_to_home' | translate}}
  </button>
</div>