import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitBillsComponent } from './hr-visit-bills.component';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import {HrVisitBillDetailsModule} from '../hr-visit-bill-details/hr-visit-bill-details.module';
import { PipesModule } from '../pipes/pipes.module';
import { NodataModule } from '../nodata/nodata.module';
import { FormsModule } from '@angular/forms';
import { HrVisitBillPreviewModule } from '../hr-visit-bill-preview/hr-visit-bill-preview.module';
@NgModule({
  declarations: [HrVisitBillsComponent],
  imports: [
    CommonModule,
    CustomModalModule,
    HrVisitBillDetailsModule,
    PipesModule,
    NodataModule,
    FormsModule,
    HrVisitBillPreviewModule
  ],
  exports: [
    HrVisitBillsComponent
  ]
})
export class HrVisitBillsModule { }
