import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.scss']
})
export class ManageAddressComponent implements OnInit {

  @Output() close: EventEmitter<any>;
  @Output() editOrAddAddress: EventEmitter<any>;
  public patientDetails: Array<Object>;
  public configDetails: Object = {};
  public showAlert: boolean = false;
  public deleteAddressDetails: object;

  constructor(private user: UserService) {
    this.close = new EventEmitter<any>();
    this.editOrAddAddress = new EventEmitter<any>();
  }

  ngOnInit() {
    this.getUserAddress();
  }

  public closeModal() {
    this.close.emit();
  }

  public getUserAddress() {
    console.log("get User address called -------");
    this.user.getPatientAddress().subscribe(data => {
      this.configDetails = data["config"];
      this.patientDetails = data["patientDtls"];
      this.formatAddressDetails();
    });
  }

  private formatAddressDetails() {
    this.patientDetails.map((data) => {
      if (data["patientAddress"]) {
        data["patientAddress"].map((addr) => {
          let primaryAddress;
          if (addr["line1"] && addr["line2"])
            primaryAddress = addr["line1"].concat(", ", addr["line2"]);
          else primaryAddress = addr["line1"];
          if (addr["cityDesc"])
            primaryAddress = primaryAddress.concat(", ", addr["cityDesc"]);
          else addr["cityCode"] = "";
          if (addr["pinCode"])
            primaryAddress = primaryAddress.concat("-", addr["pinCode"]);
          if (addr["stateDesc"])
            primaryAddress = primaryAddress.concat(", ", addr["stateDesc"]);
          else addr["stateCode"] = "";
          let filteredData = this.configDetails["applicableAddressTypes"].find(
            (data) => data["type"] === addr["addressType"]
          );
          if (filteredData && filteredData["mandatory"])
            addr["isDeleteDisable"] = true;
          addr["primaryAddress"] = primaryAddress;
        });
      }
    });
    console.log("inside userAddress end->", this.patientDetails);
  }

  public addAddress(isEdit, addr, patientDetails, index?) {
    let data = {
      isEditPatient: isEdit,
      addressDetails: addr,
      configDetails: this.configDetails,
      addressIndex: index,
      patientDetails: patientDetails,
    }
    this.editOrAddAddress.emit(data);
  }

  public showAlertBox(index, patientDetails) {
    this.showAlert = true;
    this.deleteAddressDetails = {index:index,patientDetails: patientDetails};
  }

  public updateAddress(index, patientDetails) {
    let patientAddress = patientDetails["patientAddress"];
    patientAddress.splice(index, 1);
    patientAddress.map((data) => {
      delete data["primaryAddress"];
      delete data["isDeleteDisable"];
      delete data["linkAddress"];
    });
    this.user.updateAddress(patientDetails['patientId'], patientAddress, false).subscribe(data => {
      console.log("data after deletion->",data);
      this.getUserAddress();
    })
  }

  public deleteAddress() {
    console.log("Delte address");
    this.showAlert=false;
    this.updateAddress(this.deleteAddressDetails['index'],this.deleteAddressDetails['patientDetails']);
  }
}
