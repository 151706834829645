<div class="certificate-wrapper">
    <div class="certificate-titles">
        <p class="certificate-title">{{'INTERNATIONAL CERTIFICATE OF VACCINATION'}}</p>
        <p class="approved-by">{{'Approved by'}}</p>
        <p class="apporoved-by-org no-margin">{{'WORLD HEALTH ORGANISATION'}}</p>
    </div>
    <ng-container *ngIf="isCertificateDetails; else qrView">
        <div class="card-view cert-patient-info">
            <div class="card-view-header">
                <p class="card-title">{{patientInfo['patientName']}}</p>
                <p class="card-subtitle">{{patientInfo['dob'] | moment:'D MMM YYYY'}}</p>
            </div>
            <div class="card-view-content">
                <div class="card-row">
                    <div class="card-col">
                        <p class="card-label">{{'Nationality'}}</p>
                        <p class="card-value">Saudi Arabia</p>
                    </div>
                    <div class="card-col">
                        <p class="card-label">{{'National Id'}}</p>
                        <p class="card-value">{{patientInfo['patientId']}}</p>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-col">
                        <p class="card-label">{{'Passport No'}}</p>
                        <p class="card-value">{{'Z12345P'}}</p>
                    </div>
                    <div class="card-col">
                    </div>
                </div>
            </div>
        </div>
        <div class="card-view">
            <div class="card-view-header">
                <p class="card-title">{{'COVAXIN'}}</p>
                <p class="card-subtitle">{{'Pfier pharmaeutical company'}}</p>
            </div>
            <div class="card-view-content">
                <div class="card-row">
                    <div class="card-col">
                        <p class="card-label">{{'Vaccination date'}}</p>
                        <p class="card-value">{{undefined | moment:'D MMM YYYY'}}</p>
                    </div>
                    <div class="card-col">
                        <p class="card-label">{{'Batch no'}}</p>
                        <p class="card-value">{{'628944'}}</p>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-col">
                        <p class="card-label">{{'Valid from'}}</p>
                        <p class="card-value">{{undefined | moment:'D MMM YYYY'}}</p>
                    </div>
                    <div class="card-col">
                        <p class="card-label">{{'Valid until'}}</p>
                        <p class="card-value">{{undefined | moment:'D MMM YYYY'}}</p>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-col">
                        <p class="card-label">{{'Supervising Clinician'}}</p>
                        <p class="card-value">{{'Mr William Zones'}}</p>
                        <p class="card-label">{{'Registered Nurse (RN)'}}</p>
                    </div>
                    <div class="card-col">
                        <img class="col-img" src="./assets/images/premier-health_logo.jpeg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #qrView>
        <div class="certificate-info-wrapper">
            <div class="cert-name">{{'Vaccination against COVID-19'}}</div>
            <div class="issued-to">
                <p class="issued-to-label">{{'Issued to'}}</p>
                <p class="issued-to-patient">{{patientInfo['patientName']}}</p>
                <p class="patient-info">
                    <img src="./assets/icons/cake.svg" alt=""> {{patientInfo['dob'] | moment:'D MMM YYYY'}}
                    <img src="./assets/icons/id-card.svg" alt=""> {{patientInfo['patientId']}}
                </p>
            </div>
            <div class="issued-on">
                <p>{{'Date of Vaccination : '}} {{undefined | moment:'D MMM YYYY'}}</p>
                <p class="validity">{{'Certificate valid for 6 months from the date of issue'}}</p>
                <div class="issued-logo-wrapper">
                    <div class="issued-logo"><img src="./assets/images/icmr_logo.png"></div>
                    <div class="issued-logo"><img src="./assets/images/WHO_logo.png"></div>
                </div>
            </div>
        </div>
        <div class="qr-wrapper">
            <p class="qr-label">{{'To view details scan the QR Code below'}}</p>
            <ngx-qrcode [elementType]="'url'" [value]="qrcodeUrl" cssClass="qrcode" errorCorrectionLevel="L">
            </ngx-qrcode>
            <button class="primary-button download-btn">{{'Download'}}</button>
        </div>
    </ng-template>
</div>