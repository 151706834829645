<div class="display-direction-{{displayDirection}}" id="display-currency">
  <div class="correct-amount amount-size-{{amountSize}}" [ngClass]="{'add-margin': actualAmount}">
    <span [ngStyle]="{color: theme == 'app'? (!actualAmount ? 'primary' : 'danger') : theme}">
      <strong><span *ngIf="prefix">{{prefix}}</span> {{amount}} {{currency}} <span *ngIf="suffix">{{suffix}}</span></strong>
    </span>
  </div>
  <div *ngIf="actualAmount" class="strikethrough" class="before-amount amount-size-{{actAmountSize}}">
    <span [ngStyle]="{color: 'grey'}">
      <strong>{{actualAmount}} {{currency}}</strong>
    </span>
  </div>
</div>