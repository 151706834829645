<div id="calendar-component">
  <div class="header-component" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header></app-header> 
  </div>
  <div class="calendar-component">
    <div class="show-calendar" *ngIf="false">
      <div class="card calendar-view hide">
        <div class="show">
            <p class="title">
              April, 2020
                <img class="transform-down" src="assets/icons/calendar/arrow.svg" alt="">
                <span class="title float-right">
                  <span><img src="assets/icons/calendar/arrow.svg" alt=""></span>
                  <img class="transform-right" src="assets/icons/calendar/arrow.svg" alt="">
                </span>
              </p>
        </div>
      </div>  
      <div class="card filter-view hide">
        <div class="input-group border-bottom" *ngFor="let item of filters">
          <input type="checkbox" name="accept"/>
          <span>{{item.name}}</span>
        </div>
      </div>  
  </div>

<div class="appt-detail">

    <div class="appointment">
      <app-upcoming-appointment></app-upcoming-appointment>
    </div>
  </div>
  <app-footer [appRemoveFeatureOff]="'SHOW_APP_FOOTER'" class="sm-hidden"></app-footer>
  </div>
</div>
