import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';
import { UtilitiesService } from '../services/utilities.service';
import { UserService } from '../services/user.service';
import { Subject } from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() isCrm: boolean = false;
  @Input() isLogin: boolean = false;

  public hideAppLink: boolean = false;
  showContactusModal : boolean = false;
  refresh: Subject<boolean> = new Subject<boolean>();

  constructor(private utils: UtilitiesService, private user: UserService) { }

  ngOnInit(): void {
    this.hideAppLink = environment['HIDE_APP_LINK'] ? true : false;
  }

  public openSocialMedia(type) {
    console.log('type',type)
    if(type == 'fb'){
      let link = environment['urlInfo']['facebook'];
      this.user.openLocation(link, "_blank");
    }
    if(type == 'yt'){
      let link =  environment['urlInfo']['youtube'];
      this.user.openLocation(link, "_blank");
    }
    if(type == 'insta'){
      let link =  environment['urlInfo']['instagram'];
      this.user.openLocation(link, "_blank");
    }
    if(type == 'linkedin'){
      let link =  environment['urlInfo']['linkedin'];
      this.user.openLocation(link, "_blank");
    }
    if(type == 'twitter'){
      let link =  environment['urlInfo']['twitter'];
      this.user.openLocation(link, "_blank");
    }

  }

  public download(type){
    if(type == 'android'){
      let link =  environment['urlInfo']['android'];
      this.user.openLocation(link, "_blank");
    }
    if(type == 'apple'){
      let link =  environment['urlInfo']['iOS'];
      this.user.openLocation(link, "_blank");
    }
  }
     
  public openLink(type) {
    if (type == 'pp') {
      let link = environment['urlInfo']['privacypolicy'];
      if (environment['urlInfo']['specifyLanguage'])
        link = this.utils.replaceLanguage(link, this.user.currentLanguage);
      this.user.openLocation(link, "_blank");
    }
    if (type == 'cc') {
      let link = environment['urlInfo']['contact'];
      if (environment['urlInfo']['specifyLanguage'])
        link = this.utils.replaceLanguage(link, this.user.currentLanguage);
      this.user.openLocation(link, "_blank");
    }
  }
  
  public openModal() {
    this.showContactusModal = true;
  }
  public closeActions() {
    this.showContactusModal = false;
    this.refresh.next(true);
  }
}
