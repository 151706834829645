<div id="book-appointment" class="right-nav showModal">
  <div class="flip-card" [ngClass]="{ flipped:isCouponCode }">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <div class="component">
          <ng-container *ngIf="!paymentCheckout">
            <div class="header border-bottom">
              <div class="header-row">
                <div class="back-btn cursor-pointer" *ngIf="_index > 0 && _index < 7" (click)="navBack()">
                  <img  src="./assets/icons/appt-preview/arrow.svg">
                </div>
                <div class="font-primary-subtitle bold color-notify">{{ flow[_index].header |translate:'LABELS'}} </div>
              </div>
              <div *ngIf="isPatientSwap == -1" (click)="closeModal()" class="float-right closeicon cursor-pointer">
                <img class="type-image" src="./assets/icons/appt-preview/close.svg">
              </div>
            </div>
          </ng-container>
          <ng-template bookAppoinment></ng-template>
          <ng-container *ngIf="paymentCheckout">
            <div class="header border-bottom">
              <div class="font-primary-subtitle bold">{{'sub_schedule_clinic_appt'|translate}}
              </div>
              <div (click)="closePayemntCheckout()" class="float-right closeicon pointer">
                <img class="type-image" src="./assets/icons/close_black.svg">
              </div>
            </div>
            <div class="content payment-checkout-content">
              <app-payment-checkout></app-payment-checkout>
            </div>
          </ng-container>
          <ng-container *ngIf="showTC">
            <div class="tc-modal">
              <div class="content">
                <div class="modal-header border-bottom">

                   <div class="header-row">
                    <div class="back-btn" (click)="closetc(false)">
                      <img _ngcontent-cja-c108="" src="./assets/icons/appt-preview/arrow.svg">
                    </div>
                  <div class="font-primary-subtitle bold">{{'lbl_terms_conditions'|translate}}
                  </div>
                  </div>
                </div>
                <div class="modal-content font-default-small" [innerHTML]="tandc|translate:'LOCALE':'packageContent'">
                </div>
                <div class="footer">
                  <div class="button">
                    <button (click)="onAgree()">{{'I_agree' | translate}}</button>
                  </div>
                </div>
              </div>
              
            </div>
          </ng-container>
          <ng-container *ngIf="showFaq">
            <div class="tc-modal">
              <div class="content">
                <div class="modal-header border-bottom">
                  <div class="font-primary-subtitle bold">{{'frequently_asked'|translate}}</div>
                  <div (click)="closeFaq()" class="float-right closeicon pointer">
                    <img class="type-image" src="./assets/icons/close_black.svg">
                  </div>
                </div>
                <div class="modal-content font-default-small" [innerHTML]="faq|translate:'LOCALE':'packageContent'">
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="isManageIdentities">
            <div class="tc-modal">
              <div class="content">
                <div class=" modal-header-identity border-bottom">
                  <div class="font-primary-subtitle bold">{{'manage_identities'|translate:'LABELS'}}</div>
                  <div (click)="closeManageIdentity()" class="float-right closeicon pointer">
                    <img class="type-image" src="./assets/icons/close_black.svg">
                  </div>
                </div>
                <div class="font-default-small manage-content">
                  <app-manage-identities></app-manage-identities>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isRegisterPatient">
            <app-register-new-patient (close)="closeRegPatient($event)"></app-register-new-patient>
          </ng-container>
        </div>
      </div>
      <div class="flip-card-back" *ngIf="isCouponCode">
        <app-coupon [type]="'COUPON'" [listType]="'COUPONLIST'" [couponParam]="couponParams" (completeCoupon)="closeCouponModal($event)" [refresh]="refreshFlip.asObservable()" [couponInput]="couponInputs"></app-coupon>
      </div>
    </div>
  </div>
</div>
