<div id="appointments">
    <div class="card">
      <div class="header border-bottom">
        <div class="identity">
          <div class="icon">
            <img class="type-image" src="assets/icons/video_consul.svg" />
          </div>
          <div class="font-default-title title">{{'hme_lbl_ongoing_consult'|translate:'LABELS'}}</div>
        </div>
     
      </div>
      <div class="list">
     
        <ng-container *ngIf="upcomingAppointments && upcomingAppointments.length>0">
          <app-list-item *ngFor="let item of upcomingAppointments" >
          <!-- <app-list-item *ngFor="let item of upcomingAppointments" (click)="getAppointmentDetail(item, 'calendar-appointment-details-modal')"> -->
            <img class="thumbnail type-image" src="assets/icons/calendar_app.svg" *ngIf="item['appointmentType']!='VIDEO'" />
            <img class="thumbnail type-image" src="assets/icons/video-icon.svg" *ngIf="item['appointmentType']=='VIDEO'" />
            <span class="title">
              <img class="prefix type-image" src="assets/icons/calendar_app.svg" *ngIf="item['appointmentType']!='VIDEO'" />
              <img class="prefix type-image" src="assets/icons/video-icon.svg" *ngIf="item['appointmentType']=='VIDEO'" />
              <ng-container *ngIf="item.practitionerLocalName">
                {{item.practitionerLocalName | translate : translateType : 'name'}}
              </ng-container>
              <ng-container *ngIf="!item.practitionerLocalName && item.practitionerName">
                {{item.practitionerName}}
              </ng-container>
              <ng-container *ngIf="!item.practitionerLocalName && !item.practitionerName">
                {{(item['resourceId']|partitionerDetails:item['facilityId']:'resourceName'| translate : translateType : 'name') || 'Doctor'}}
              </ng-container>
              </span>
            <span
              class="sub-title">{{item['resourceId']|partitionerDetails:item['facilityId']:'specialityId' | specialityDetails:item['facilityId']:'specialityName'| translate : translateType : 'name'}}</span>
  
            <span class="primary-content">
              <img class="type-image" src="assets/icons/user.svg">
              <span>
                  <!-- {{item.appointmentDateTime | moment : 'DD MMM YYYY, hh:mm a'}} -->
                {{item.personName}}
              </span>
            </span>
            <!-- <span class="secondary-content">
              <img class="type-image" src="assets/icons/doctor.svg">
              <span>{{item.facilityId | facilityDetails : 'facilityName'| translate : translateType : 'name'}}</span>
            </span> -->
            <span class='list-action'>
              <button class="font-button cursor-pointer primary-button" (click)="openAppointment(item.joinUrl)">{{'hme_btn_join'|translate:'LABELS'}}</button>
            </span>
          </app-list-item>
        </ng-container>
      </div>
    </div>
  </div>
  
  