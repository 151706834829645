<!-- <div id="hr-visit-report-component">
  <div class="search-header">
    <div class="search" [ngClass]="{active: (searchExpand === true)}" >
      <div class="input-group">
        <div class="icon" (click)="searchIcon()">
          <img class="type-image" src="assets/icons/search.svg"/>
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_report'|translate:'LABELS'}}" [(ngModel)]="search" />
        </div>
      </div>
    </div>
    <div class="extras">
      <div class="uploadDocs">
        <button class="button-primary" (click)="openDocUpload()"
          [appRemoveFeatureOff]="'FT_APP_HR_UPLOADDOCS'">
          {{'upload_docs'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="table">
      <div class="content">
        <div class="no-data"
          *ngIf="(!clinicalHistory || (clinicalHistory| hrReportFilter:'recordType':selectedFilter:search|keyvalue)['length']==0 ) &&  uploadedDocs['length'] == 0">
          <app-nodata></app-nodata>
        </div>
        <ng-container
          *ngIf="clinicalHistory && (clinicalHistory| hrReportFilter:'recordType':selectedFilter:search|keyvalue)['length']>0">
          <div class="group"
            *ngFor="let item of (clinicalHistory| hrReportFilter:'recordType':selectedFilter:search|keyvalue)" #clinicalHistoryLoop>
            <div class="group-title">
              <div class="title-facility">
                <p class="title-facility bold">{{(item.value[0]['practitionerId'] | partitionerDetails:item.value[0]['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || item.value[0]['performedBy'] }}</p>
                <span class="font-light-small">{{(item.value[0]['facilityId']| facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name')|| item.value[0]['facilityName']}}</span>
              </div>
              <div class="title-sub font-light-small">
                {{item.value[0]['reportDateTime']| moment : 'DD MMM YYYY'}}
              </div>
            </div>
            <div class="group-data cursor-pointer" *ngFor="let report of item.value" (click)="chooseReport(report)">
              <div class="data-cell bold l-align">
                <span [ngSwitch]="report['recordType']">
                  <img class="type-image" src="assets/icons/xray.svg" *ngSwitchCase="'RDIN'" />
                  <img class="type-image" src="assets/icons/report.svg" *ngSwitchCase="'CLNT'" />
                  <img class="type-image" src="assets/icons/lab.svg" *ngSwitchCase="'LBIN'" />
                  <img class="type-image" src="assets/icons/report.svg" *ngSwitchDefault />
                </span>
                <span *ngIf="report['format']=='JSONFORMAT'">{{report['eventGroupDesc']}}</span>
                <span *ngIf="report['format']!='JSONFORMAT'">{{report['eventDesc']}}</span>
              </div>
              <div class="data-cell r-align">
                <div class="button-style cursor-pointer" *ngIf="canDownload"
                  (click)="$event.stopPropagation();initDownload(report)"
                  [appRemoveFeatureOff]="'FT_APP_HR_DOWNLOADDOCS'">
                  <img src="assets/icons/download.svg" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="group"
          *ngFor="let report of uploadedDocs | searchFilter:search:'note'" >
          <div class="group-data cursor-pointer" (click)="initViewPersonal(report)">
            <div class="data-cell  l-align flex">
              <div class="align_center">
                <span >
                  <img class="type-image" src="assets/icons/uploadreport.svg" />
                </span>
              </div>
              <div class="line-spacing"> 
                <span class="bold">{{report['header']['chatHeader']? report['header']['fileName'] : report['header']['note']}}</span>
                <br>
                <span class="title-sub font-light-small">
                  {{report['header']['reportDate'] | moment : 'DD MMM YYYY'}}
                </span>
              </div>
            </div>
            <div class="data-cell r-align">
              <div class="button-style danger cursor-pointer"
                (click)="$event.stopPropagation();removeDownloadPersonal(report)"
                [appRemoveFeatureOff]="'FT_APP_HR_DELETEDOCS'">
                <img src="assets/icons/delete.svg" />
              </div>
              <div class="button-style cursor-pointer"
                (click)="$event.stopPropagation();initDownloadPersonal(report)"
                [appRemoveFeatureOff]="'FT_APP_HR_DOWNLOADDOCS'">
                <img src="assets/icons/download.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="hr-visit-upload-container" *ngIf="type == 'UPLOADS'">
  <div class="hr-visit-upload-header sm-hidden">
    <div class="doc-name header-item">{{'Document_name' | translate}}</div>
    <div class="doc-date header-item">{{'Date_and_time' | translate}}</div>
  </div>
  <div class="hr-visit-upload-content">
    <div class="hr-visit-upload-item" *ngFor="let report of uploadedDocs" (click)="initViewPersonal(report)">
      <div class="doc-name content-item"> <img src="./assets/icons/Health_records/file.svg" alt=""> {{report['header']['chatHeader']? report['header']['fileName'] : report['header']['note']}}</div>
      <div class="doc-date content-item">{{report['header']['reportDate'] | moment : 'D MMM YYYY, hh:mm A'}}</div>
    </div>
  </div>
</div>

<div class="hr-visit-reports-wrapper" *ngIf="type == 'REPORTS'">
  <div class="hr-visit-reports-list">
    <div class="search sm-hidden" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon"  (click)="searchIcon()">
          <img class="type-image" src="./assets/icons/Book_new/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_report'|translate:'LABELS'}}" [(ngModel)]="searchTerm" />
        </div>
      </div>
    </div>
    <div class="hr-visit-reports-item" *ngFor="let report of clinicalHistory | searchFilter:searchTerm:'eventDesc'" (click)="openReports(report)" [ngClass]="{'selected-report': selectedReportToView == report}">
      <p class="hr-visit-report-title">
        <span [ngSwitch]="report['recordType']">
          <img class="type-image" src="assets/icons/xray.svg" *ngSwitchCase="'RDIN'" />
          <img class="type-image" src="assets/icons/report.svg" *ngSwitchCase="'CLNT'" />
          <img class="type-image" src="assets/icons/lab.svg" *ngSwitchCase="'LBIN'" />
          <img class="type-image" src="assets/icons/report.svg" *ngSwitchDefault />
        </span>
        <span *ngIf="report['format']=='JSONFORMAT'">{{report['eventGroupDesc']}}</span>
        <span *ngIf="report['format']!='JSONFORMAT'">{{report['eventDesc']}}</span>
      </p>
      <p class="hr-visit-report-subtitle">{{report['reportDateTime'] | moment : 'DD MMM YYYY'}}
        </p>

    </div>
  </div>
  <div class="hr-visit-reports-details" *ngIf="!isMobileView || selectedReportToView">
    <app-reports-view-modal *ngIf="selectedReportToView" [reports]="clinicalHistory" [selectedPatient]="_selectedPatient" [patientMetaData]="patientMetaData" [selectedReport]="selectedReportToView" [isCommonView]="true" (close)="selectedReportToView = null" (initDownload)="initDownload($event)"></app-reports-view-modal>
  </div>
</div>