<div class="event-container">
    <div class="event-wrapper">
        <div *ngIf="languageList && languageList.length > 1" class="cursor-pointer lang-wrapper" (click)="switchLanguageAction()">
            <img src="assets/icons/header/world.svg" />
            <!-- <span>{{ isSelected }}</span> -->
            <!-- <img class="arrow-img" src="assets/icons/arrow.svg"> -->
        </div>
        <div class="header-wrapper">
            <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
            <p class="event-name">{{task['taskDescLocale'] || task['taskDesc'] | translate:'LOCALE':'text':lang}}</p>
            <img class="event-icon" src="assets/icons/task/mobile.svg" alt="">
        </div>
        <div class="patient-context">
            <app-person-avatar
                [name]="patientInfo['patientNameLocale'] || patientInfo['patientName'] | translate:'LOCALE':'text':lang"
                [fromPatientTask]="true"></app-person-avatar>
            <div class="pat-dtls">
                <p class="pat-name">
                    {{patientInfo['patientNameLocale'] || patientInfo['patientName'] | translate:'LOCALE':'text':lang}} <span
                        class="pat-id">{{patientInfo['sex'] | translate : 'LABELS' : null : lang}},
                        {{patientInfo['dob'] | age}}</span></p>
            </div>
        </div>
        <div *ngIf="task['_guidelines'] && task['landingPageDisplay'] === 'TASK-GUIDELINES'" class="task-guidelines">
            {{task['_guidelines']['message']}}
        </div>
        <div class="form-preview" *ngIf="task['landingPageDisplay'] === 'EVENT-FORM'">
            <app-formpreview isEventInfo="true" [topics]="workflow['eventInfo']['parentEventNoteContent']['topics']"
                [borderColor]="'#DDE5EC'" [showTopicTitle]="false"
                *ngIf="workflow['eventInfo']['parentEventNoteContent'] && workflow['eventInfo']['parentEventNoteContent']['topics']">
            </app-formpreview>
            <app-formpreview isEventInfo="true" [topics]="topics" [borderColor]="'#DDE5EC'" *ngIf="topics"
                [showTopicTitle]="false">
            </app-formpreview>
        </div>
        <div class="actions" *ngIf="switchLanguage">
            <ul class="list-group">
              <li class="list-group-item"  *ngFor="let item of languageList">
                <input type="radio" name="label" [checked]="lang === item.localeCode ? true: false"
                  (change)="onItemChange(item)" [id]="'lang'+item.localeCode">
                <label class="label" [for]="'lang'+item.localeCode">{{item.localName}} </label>
              </li>
            </ul>
          </div>
          <button class="print-task-button" *ngIf="this.task['allowPrintTaskBeforeSubmit'] == 'Y'" (click)="printTask()">{{'Print Task'}}</button>

    </div>
    <div class="event-footer">
        <ng-container *ngIf="(task && task['noteApplicable'] == 'Y' || APPOINTMENTS.includes(this.task['externalEventType']) || CERTIFICATE.includes(this.task['externalEventType']));else notApplicable">
            <button [disabled]="disableBtn" class="primary-button" (click)="performAction({})">{{'but_prcd' | translate : 'LABELS' : null : lang}}</button>
        </ng-container>
        <ng-template #notApplicable>
            <button class="primary-button" *ngFor="let a of applicableStatus"
                (click)="performAction(a)">{{a['actionLocale'] || a['actionDescription'] || a['name'] | translate:'LOCALE':'text'}}</button>
        </ng-template>
    </div>
</div>