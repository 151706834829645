import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import * as AppConstant from '../app.string';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  private repoUrl: string;
  constructor(private http: HttpClient, private user: UserService) {
    this.repoUrl = this.user.getRepoUrl();
  }

  public getPromo(data): Observable<any> {
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
      localeCode: this.user.getCurrentLanguage()
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/getPromoCodes`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(data);
        observer.complete();
      }, error => {
        observer.error()
        observer.complete();
      })
    })
  }

  public getPromoCodes(patInfo: any,service: any, chargeStr?:string): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      selectedServices: service,
      "applyDiscountYn": "N",
      chargeStr: chargeStr,
      ...patInfo
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/getPromoCodes`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public validatePromoCode(data): Observable<any> {
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
      localeCode: this.user.getCurrentLanguage()
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/applyPromoCode`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getCoupons(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${this.repoUrl}${AppConstant.API_COUPON}/getCoupons`, payload).subscribe(success => {
        observer.next(success);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
        observer.complete();
      })
    })
  }

  public getPointsInfo(data): Observable<any> {
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
    }
    return new Observable(observer => {
      this.http.post(`${this.repoUrl}${AppConstant.API_COUPON}/getPointsInfo`, payload, { reportProgress: false }).subscribe(response => {
        if (response && response['status'] == 'SUCCESS') {
          observer.next(response['data']);
          observer.complete();
        } else {
          observer.error()
          observer.complete();
        }
      }, error => {
        observer.error()
        observer.complete();
      })
    })
  }

  public validateCoupon(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${this.repoUrl}${AppConstant.API_OUTPATIENTS}/validateCoupon`, payload).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    if (error.error && error.error.err && error.error.err.message) {
      return error.error.err.message
    } else if (error.error && error.error.message) {
      return error.error.message
    }
    return 'Something went wrong';
  }
}
