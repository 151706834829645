import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { REPO_STATE, REPO_TOKEN, TEMP_TOKEN } from '@string';
import { Observable } from 'rxjs';
import { InterceptorService } from './interceptor.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class VisitService {
  facilityId: any;

  constructor(private http:HttpClient, private user:UserService, private interceptor:InterceptorService) { }


  public validateVisitQR(params) {
    const payload = {
      ...params,
      locale: 'en',
      visitTypeCode:'N,F,FV'
    }
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl(params['facilityId'])}api/patientKiosk/verifyPatientQR`, payload, { reportProgress: false }).subscribe((data:any) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }


  public precheck({countryCode, mobile, patientId}) {
    const payload = {
      countryCode, 
      mobile,
      patientId
    }
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/precheck`, payload, { reportProgress: false }).subscribe((data:any) => {
        let userInfo = {
          countryCode,
          mobile,
        }
        this.user.updateUserInfo(userInfo)
        if (data?.data?.token)
          this.setRepoToken(data?.data?.token)  
        observer.next(data['data'] || data);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  public patientSignin({ countryCode, mobile, patientId }, otp) {
    const payload = {
      countryCode, 
      mobile,
      patientId,
      otp
    }
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/patientSignin`, payload, { reportProgress: false }).subscribe((data:any) => {
        let userInfo = {
          countryCode,
          mobile,
        }
        this.user.updateUserInfo(userInfo)
        if (data?.data?.token)
          this.setRepoToken(data?.data?.token)  
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  public getVisitCharges(facilityId, clinicCode, practitionerId, patientId?) {
    const payload = {
      facilityId, 
      clinicCode,
      practitionerId,
      patientId
    }
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/getVisitCharges`, payload, { reportProgress: false })
        .subscribe((data: any) => {
        observer.next(data);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  public createFollowupVisit(payload) {
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/createFollowUpVisit`, payload, { reportProgress: false })
        .subscribe((data: any) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  public startTransaction(payload) {
    return Observable.create(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/paymentKiosk/initPaymentTransaction`, payload, { reportProgress: false }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    })
  }

  public setRepoToken(token, state=null, tempToken=null) {
    this.interceptor.setRepoToken(token);
    this.interceptor.setTempToken(tempToken);
    this.user.setStorage(REPO_TOKEN, token);
    this.user.setStorage(TEMP_TOKEN, tempToken);
    if(state)
      this.user.setStorage(REPO_STATE, state);
  }
  printVisitBill(payload) {
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/printVisitBill`, payload, { reportProgress: false })
        .subscribe((data: any) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  resendOTP(payload) {
    return new Observable(observer => {
      this.http.post(`${this.getVisitRepoUrl()}api/patientKiosk/resendOtp`, payload, { reportProgress: false })
        .subscribe((data: any) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    });
  }

  public checkTransactionStatus(orderId, facilityId): Observable<any>{
    let payload = {
      orderId: orderId
    }
    return new Observable(observer => { 
      this.http.post(`${this.getVisitRepoUrl(facilityId)}api/patientKiosk/checkTransactionStatus`, payload, { reportProgress: false })
        .subscribe((data: any) => {
          observer.next(data['data']);
          observer.complete();
        }, error => {
          observer.error(this.user.getErrorMessage(error));
        })
    })
  }

  public openCheckout(appointment, paymentInfo) {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', paymentInfo.paymentgatewayurl);
    const params = {
      currency: paymentInfo.currency,
      amount: paymentInfo.charge,
      merchant_reference: appointment['_orderId'],
      order_description: appointment['uhid'],
      customer_email: appointment['email'],
      merchant_extra: appointment['mobile_no'],
      merchant_extra4: paymentInfo['_returnURL']
    }
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  getVisitRepoUrl(fid?) {
    if (!this.facilityId)
      this.facilityId = fid;
    let url = environment['VISIT_API_URLS']?.[this.facilityId];
    if (!url)
      url = environment['VISIT_API_URL'];
    return url;
  }
  public validatePatientVisit(facilityId, practId, clinicCode, patientId): Observable<any>{
    let payload = {
      facilityId, practId, patientId, clinicCode
  };
    return new Observable(observer => { 
      this.http.post(`${this.getVisitRepoUrl(facilityId)}api/patientKiosk/validatePatientVisits`, payload, { reportProgress: false })
        .subscribe((data: any) => {
          observer.next(data['data']);
          observer.complete();
        }, error => {
          observer.error(this.user.getErrorMessage(error));
        })
    })
  }
}
