<div id="hr-visit-summary-component">
  <!-- <div class="search-header">
    <div class="search" [ngClass]="{active: (searchExpand === true)}">
      <div class="input-group">
        <div class="icon" (click)="searchIcon()">
          <img class="type-image" src="assets/icons/search.svg" />
        </div>
        <div class="input" [ngClass]="{active: (searchExpand === true)}">
          <input type="text" placeholder="{{'frm_search_summary'|translate:'LABELS'}}" [(ngModel)]="search" />
        </div>
      </div>
    </div>
    <div class="extras">
      <div class="filter" [ngClass]="{active: (searchExpand === true)}">
        <div class="dp-down cursor-pointer">
          <div class="dp-select" (click)="openCloseFilter()">
            <span class="font-primary-default">{{selectedFilter.value|translate:'LABELS'}}</span>
          </div>
          <div class="dp-option" *ngIf="isFilterOpen">
            <span class="option" (click)="changeFilter(item)"
              *ngFor="let item of filters">{{item.value|translate:'LABELS'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="content sm-hidden">
    <div class="no-data"
    *ngIf="((!allergy && !diagnosis && !procedure) || 
    ((allergy | hrSummaryFilter:'problemType':selectedFilter:search)['length']==0  &&  (procedure| hrSummaryFilter:'problemType':selectedFilter:search)['length']==0  &&  (diagnosis| hrSummaryFilter:'problemType':selectedFilter:search)['length']==0) )">
    <app-nodata></app-nodata>
    </div>

    <ng-container *ngIf="allergy && allergy.length > 0 && (allergy| hrSummaryFilter:'problemType':selectedFilter:search)['length']>0">
      <div class="list-item cursor-pointer">
        <div class="visit-clinic-bg" (click)="showAllergy = !showAllergy">
          <span class="title">
            {{'Lbl_allergy'|translate}}
          </span>
          <span class="item-status type-image" [ngClass]="{active: showAllergy == true}">
            <img src="assets/icons/Home/right arrow.svg">
          </span>
        </div>
        <div class="component-container" *ngIf="showAllergy == true">
          <div class="billing-card-table border-bottom">
            <div class="bill-table">
              <div class="bill-table-row table-header col-12" *ngIf="(allergy | hrSummaryFilter:'problemType':selectedFilter:search).length">
                <div class=" font-light-small1 bold col-4">{{'Allergy_name' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_by' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_on'|translate}}</div>
              </div>
              <div class="table-content">
                <div class="bill-table-row  col-12" *ngFor="let a of (allergy | hrSummaryFilter:'problemType':selectedFilter:search)">
                  <div class="ttl col-4"> 
          <img class="type-image" src="assets/icons/report.svg" />
          {{ a['problemDesc'] }} </div>
                  <div class="font-light-small col-4">{{ a['recordedByName'] }}</div>
                  <div class="font-light-small col-4">{{ a['onsetDate'] | moment : 'DD MMM YYYY' }}</div>
                </div>
              </div>
              <div class="no-data"  *ngIf="!allergy || (allergy | hrSummaryFilter:'problemType':selectedFilter:search).length == 0">
                <app-nodata></app-nodata>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="diagnosis && diagnosis.length > 0 && (diagnosis| hrSummaryFilter:'problemType':selectedFilter:search)['length']>0">
      <div class="list-item cursor-pointer">
        <div class="visit-clinic-bg" (click)="showDiagnostics = !showDiagnostics">
          <span class="title">
            {{'Lbl_diagnosis'|translate}}
          </span>
          <span class="item-status type-image" [ngClass]="{active: showDiagnostics == true}">
            <img src="assets/icons/header/next_arrow.svg">
          </span>
        </div>
        <div class="component-container" *ngIf="showDiagnostics == true">
          <div class="billing-card-table border-bottom">
            <div class="bill-table">
              <div class="bill-table-row table-header col-12" *ngIf="(diagnosis| hrSummaryFilter:'problemType':selectedFilter:search).length">
                <div class=" font-light-small1 bold col-4">{{'Dianosis_name' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_by' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_on'|translate}}</div>
              </div>
              <div class="table-content">
                <div class="bill-table-row  col-12" *ngFor="let a of (diagnosis| hrSummaryFilter:'problemType':selectedFilter:search)">
                  <div class="ttl col-4"> 
          <img class="type-image" src="assets/icons/report.svg" />
          {{ a['problemDesc'] }} </div>
                  <div class="font-light-small col-4">{{ a['recordedByName'] }}</div>
                  <div class="font-light-small col-4">{{ a['onsetDate'] | moment : 'DD MMM YYYY' }}</div>
                </div>
              </div>
              <div class="no-data"  *ngIf="!diagnosis || (diagnosis| hrSummaryFilter:'problemType':selectedFilter:search).length == 0">
                <app-nodata></app-nodata>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="procedure && procedure.length > 0 && (procedure| hrSummaryFilter:'problemType':selectedFilter:search)['length'] > 0">
      <div class="list-item cursor-pointer">
        <div class="visit-clinic-bg" (click)="showProcedure = !showProcedure">
          <img class="type-image" src="assets/icons/report.svg" />
          <span class="title">
            {{'Lbl_allergy'|translate}}
          </span>
          <span class="item-status type-image" [ngClass]="{active: showProcedure == true}">
            <img src="assets/icons/header/next_arrow.svg">
          </span>
        </div>
        <div class="component-container" *ngIf="showProcedure == true">
          <div class="billing-card-table border-bottom">
            <div class="bill-table">
              <div class="bill-table-row table-header col-12" *ngIf="(procedure| hrSummaryFilter:'problemType':selectedFilter:search).length">
                <div class=" font-light-small1 bold col-4">{{'Procedure_name' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_by' | translate}}</div>
                <div class=" font-light-small1 bold col-4">{{'Recorded_on'|translate}}</div>
              </div>
              <div class="table-content">
                <div class="bill-table-row  col-12" *ngFor="let a of (procedure| hrSummaryFilter:'problemType':selectedFilter:search)">
                  <div class="ttl col-4"><img class="type-image" src="assets/icons/report.svg" />
                    {{ a['problemDesc'] }} </div>
                  <div class="font-light-small col-4">{{ a['recordedByName'] }}</div>
                  <div class="font-light-small col-4">{{ a['onsetDate'] | moment : 'DD MMM YYYY' }}</div>
                </div>
              </div>
              <div class="no-data"  *ngIf="!procedure || (procedure| hrSummaryFilter:'problemType':selectedFilter:search).length == 0">
                <app-nodata></app-nodata>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="md-hidden summary-mobile-view">
    <div class="no-data"
    *ngIf="((!allergy && !diagnosis && !procedure) || 
    ((allergy | hrSummaryFilter:'problemType':selectedFilter:search)['length']==0  &&  (procedure| hrSummaryFilter:'problemType':selectedFilter:search)['length']==0  &&  (diagnosis| hrSummaryFilter:'problemType':selectedFilter:search)['length']==0) )">
    <app-nodata></app-nodata>
    </div>
    <ng-container *ngIf="allergy && allergy.length">
      <p class="section-title">{{'Lbl_allergy'|translate}}</p>
      <ng-container [ngTemplateOutlet]="summaryTemplate" [ngTemplateOutletContext]="{data:allergy}"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="diagnosis && diagnosis.length">
      <p class="section-title">{{'Lbl_diagnosis'|translate}}</p>
      <ng-container [ngTemplateOutlet]="summaryTemplate" [ngTemplateOutletContext]="{data:diagnosis}"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="procedure && procedure.length">
      <p class="section-title">{{'Lbl_procedures'|translate}}</p>
      <ng-container [ngTemplateOutlet]="summaryTemplate" [ngTemplateOutletContext]="{data:procedure}"></ng-container>
    </ng-container>
    
      
      <ng-template #summaryTemplate let-data="data">
        <div class="sucard-list-wrapper">
          <div class="sucard-li" *ngFor="let a of data">
            <div class="sucard-context">
              <p class="sutitle"><img class="type-image" src="assets/icons/report.svg" /> {{a['problemDesc']}}</p>
              <p class="susubtitle">{{a['recordedByName']}}</p>
            </div>
            <p class="sudate">{{ a['onsetDate'] | moment : 'DD MMM YYYY' }}</p>
          </div>
        </div>
      </ng-template>
      
  </div>
</div>