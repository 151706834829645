import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicPractitionerComponent } from './express-clinic-practitioner.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { BrowserModule } from '@angular/platform-browser';
import { NodataModule } from '../nodata/nodata.module';
@NgModule({
  declarations: [ExpressClinicPractitionerComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, PersonAvatarModule, PractitionerAvatarModule,
    BrowserModule, NodataModule
  ],
  
  exports: [ExpressClinicPractitionerComponent]
})
export class ExpressClinicPractitionerModule { }
