<div id="express-home">
  <div class="tile title-section">
    <!-- <span class="home-title bold">{{'express_clinic_title'|translate}}</span> -->
    <div class="left-item">
      <img class="type-image" src="assets/icons/clinic_home.svg" id="image-express-clinic-home-default"/>
      <img src="assets/icons/clinic_home_alnahdi.png" class="type-image" id="image-express-clinic-home-alnahdi" />
    </div>
    <div class="right-item">
      <div class="home-header">
        <span class="bold">{{'express_clinic_about_title' | translate}}</span>
      </div>
      <div class="home-text">
        {{'express_clinic_about_content'|translate}}
      </div>
    </div>
    <!-- <span class="secondart-text">{{'express_clinic_more'|translate}}</span> -->
  </div>
  <!-- <div class="home-wrapper">
    <div class="home-card">
      <div class="home-header border-bottom">
        <span class="bold">{{'express_clinic_about_title' | translate}}</span>
      </div>
      <div class="home-text">
        {{'express_clinic_about_content'|translate}}
      </div>
    </div>
  </div> -->
  <!-- <div class="home-wrapper" *ngFor="let skillSet of listOfSkillSets">
    <div class="home-card" *ngIf="skillSet['applicableSkillSet'].length">
      <div class="home-header border-bottom">
        <span class="bold">{{skillSet['localNames'] ? (skillSet['localNames']['names'] | translate: 'LOCALE' : 'name') : skillSet['desc']}}</span>
      </div>
      <div class="home-content">
        <div *ngFor="let applicableskillset of skillSet['applicableSkillSet']">
          <input type="radio" name="{{skillSet['desc']}}" value="{{applicableskillset['code']}}"
            id="{{applicableskillset['desc']}}" [(ngModel)]="skillSet['selected']"
            *ngIf="skillSet['single_or_muti_select'] != 'M'"/>
          <input type="checkbox" name="{{skillSet['desc']}}" value="{{applicableskillset['code']}}"
            id="{{applicableskillset['desc']}}" [(ngModel)]="applicableskillset['selected']"
            *ngIf="skillSet['single_or_muti_select'] == 'M'"/>
          <label class="secondart-text cursor-pointer"
          for="{{applicableskillset['desc']}}">{{applicableskillset['localNames'] ? (applicableskillset['localNames']['names'] | translate: 'LOCALE' : 'name') : applicableskillset['desc']}}</label>
        </div>
      </div>
    </div>
  </div>
  <button class="primary-button btn-customize" (click)="findClinic()">{{'find_nearby_clinic'|translate}}</button> -->
  <div class="field-list">
    <div class="field-item"  *ngFor="let skillSet of listOfSkillSets; let even=even">
      <div [ngClass]="{'field-item__wrapper': even}" *ngIf="skillSet['applicableSkillSet'].length">
        <div class="field-title">
          <span class="bold">
            {{skillSet['localNames'] ? (skillSet['localNames']['names'] | translate: 'LOCALE' : 'name') : skillSet['desc']}}
          </span>
        </div>
        <div class="tile field-values">
          <div class="field-value form__group" *ngFor="let applicableskillset of skillSet['applicableSkillSet']">
            <input type="radio" class="form__input form__radio-input" name="{{skillSet['desc']}}" value="{{applicableskillset['code']}}"
              id="{{applicableskillset['desc']}}" [(ngModel)]="skillSet['selected']"
              *ngIf="skillSet['single_or_muti_select'] != 'M'" />
            <input type="checkbox" class="form__input form__check-input" name="{{skillSet['desc']}}" value="{{applicableskillset['code']}}"
              id="{{applicableskillset['desc']}}" [(ngModel)]="applicableskillset['selected']"
              *ngIf="skillSet['single_or_muti_select'] == 'M'" />
            <label class="field-label secondary-text cursor-pointer form__label" for="{{applicableskillset['desc']}}">
              <span class="form__button"></span>
              {{applicableskillset['localNames'] ? (applicableskillset['localNames']['names'] | translate: 'LOCALE' :
              'name') : applicableskillset['desc']}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <button class="primary-button btn-customize" (click)="findClinic()">{{'find_nearby_clinic'|translate}}</button>
</div>
