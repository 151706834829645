import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, Subject } from 'rxjs'
import * as AppConstant from '../app.string';
import * as moment from 'moment';
import { CookieService } from './cookie.service';
import { environment } from "../../environments/environment";
import { Object, HpApiResponse } from '../app.type';
import { UserService } from "./user.service";
import { isEqual as _isEqual} from 'lodash';
// import * as printJS from 'print-js'
import { debounceTime, tap } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class ClinicalformService {
  public searchedConcepts = [];
  public sectionValueListener: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient, private cookie: CookieService, private user: UserService) {
  }

  public getParamsForCreateEvent(eventOBJECT, directives, patient, referral, locale) {
    const linkedDirectives = [];
    if (eventOBJECT.type === 'submit' && directives && Array.isArray(directives)) {
      directives.forEach((f, fIndex) => {
        if (this.isOPD(f) && Array.isArray(f.noteContent.topics)) {
          f.noteContent.topics.forEach((t, tIndex) => {
            t.sections.forEach((s, sIndex) => {
              if (s.type === 'directiveLinkedField') {
                if (s._isVital && Array.isArray(s._vitalsData) && s._vitalsData.length > 0) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._vitalsData.map(m => delete m['history']);
                if (s._history) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._history = undefined;
                if (s._gHistory) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._gHistory = undefined;
                if (s._historyMedication) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._historyMedication = undefined;
                if (s._previousNotes) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._previousNotes = undefined;
                if (Array.isArray(s._filledConcepts)) {
                  s._filledConcepts.forEach(fc => {
                    if (s['domainControls'] && Array.isArray(s['domainControls'].directives) && (!fc._copied || (fc._copied && fc.copiedToForm))) {
                      let _directive = s['domainControls'].directives.find(d => d['directiveCode'] === fc.directiveCode);
                      if (!fc.type) fc['type'] = 'submit';
                      if (_directive) {
                        fc.form['directiveControls'] = _directive.directiveControls;
                        fc['data'] = [fc.form];
                        if (!directives[fIndex].noteContent.topics[tIndex].sections[sIndex]['_isSingleInstance'] || !directives[fIndex].noteContent.topics[tIndex].sections[sIndex]['domainControls']['updateHistoryAvailable'] || !directives[fIndex].noteContent.topics[tIndex].sections[sIndex]['_prefillingForm'] || !fc['data'] || !fc['data'][0] || !fc['data'][0]['noteContent'] || !this.compareNoteContent(fc['data'][0]['noteContent'], directives[fIndex].noteContent.topics[tIndex].sections[sIndex]['_prefillingForm'])) {
                          linkedDirectives.push(this.createEvent(fc, fc['data'], patient, s._isReferral, locale));
                        } else
                          console.log('form already exists');
                      }
                    }
                  });
                  directives[fIndex].noteContent.topics[tIndex].sections[sIndex]._filledConcepts = undefined;
                }
                if (s._prefillingForm) directives[fIndex].noteContent.topics[tIndex].sections[sIndex]['_prefillingForm'] = undefined;
              }
            })
          })
        }
      })
    }
    return {
      event: this.createEvent(eventOBJECT, directives, patient, referral, locale),
      linkedDirectives: linkedDirectives
    };
  }

  public createEvent(eventOBJECT, directives, patient, referral, locale) {
    const personInfo = this.user.getPersonInfo();
    const person = personInfo['personDetails'] ? personInfo['personDetails'] : {};
    var message = {
      type: 'outcome',
      name: person['personName'],
      url: "",
      iconUrl: '',
      concepts: [],
      message: '',
      result: eventOBJECT['outcomes'],
      topicOutcomes: eventOBJECT['topicOutcomes'],
      theForm: JSON.stringify(eventOBJECT['data']),
      dateTime: this.user.getMoment().format()
    };
    message['concepts'].push(eventOBJECT['data'][0]['label']);


    var data = {
      fbMessage: message,
      channelId: patient['fbChannel'],
      eventTriggerType: 'CARETEAM',
      eventInfo: {
        directives: []
      },
      locale:locale
    }
    console.log(patient);
    directives.map(directive => {
      let event = {
        facilityId: patient['facilityid'],
        entityId: patient['entityid'],
        patientId: patient['patientId'],
        patientName: patient['patientInfo'] ? patient['patientInfo']['patientName'] : patient['patientName'],
        fbChannel: patient['fbChannel'],
        hpencounterId: patient['encSeq'] || patient['_srcencounterId'],
        encounterId: patient['_srcencounterId'] ? patient['_srcencounterId'] : null,
        issuedBy: person['personId'],
        issuedDttm: this.user.getMoment().format(),
        directiveCode: directive['directiveCode'],
        directiveControls: directive['directiveControls'],
        outcomes: eventOBJECT['outcome'],
        // outcome: eventOBJECT['outcome'],
        topicOutcomes: eventOBJECT['topicOutcomes'],
        reportingFields: eventOBJECT['reportingOutComes'],
        conceptCode: eventOBJECT['data'][0]['id'],
        conceptName: eventOBJECT['data'][0]['label'],
        noteCode: eventOBJECT['data'][0]['noteCode'],
        clinicalNote: eventOBJECT['data'][0]['noteContent'],
        integrateHis: directive['directiveControls'] && directive['directiveControls']['integrateHis'] ? directive['directiveControls']['integrateHis'] : 'N',
        integrateHisType: directive['directiveControls'] && directive['directiveControls']['integrateHisType'] ? directive['directiveControls']['integrateHisType'] : 'X',
        isPrescription: directive['directiveControls'] && directive['directiveControls']['isPrescription'] ? directive['directiveControls']['isPrescription'] : 'N',
        conceptDescription: eventOBJECT['data'][0]['label']
      }

      if (!referral)
        event['orderingPractId'] = person['personId'];
      else
        event['refTeamId'] = referral;

      if (eventOBJECT.eventAscnNo)
        event['eventAscnNo'] = typeof eventOBJECT['eventAscnNo'] === 'string' ? parseInt(eventOBJECT['eventAscnNo']) : eventOBJECT['eventAscnNo'];

      if (eventOBJECT._actionType)
        event['actionType'] = 'UPDATE';

      if (eventOBJECT.type === 'save')
        event['eventStatus'] = 'DRFT';

      if (eventOBJECT['data'][0]['_order']) {
        event['performingStartDate'] = eventOBJECT['data'][0]['_order']['selectedStartDate'];
        event['priority'] = eventOBJECT['data'][0]['_order']['flagType'];
        event['performingFacility'] = eventOBJECT['data'][0]['_order']['performingFacility'];
      }

      data['eventInfo']['directives'].push(event);

    });

    return data;
  }

  public saveEvent(event): Observable<any> {
    return Observable.create(observer => {
      console.log(event);
      this.http.post(environment['API_URL'] + 'api/events/createEvent', event, { reportProgress: true }).subscribe((result: HpApiResponse) => {
        if (result['status'] != 'SUCCESS')
          observer.error(result['error']);
        observer.next(result['data']);
      }, error => {
        observer.error(error);
      });
    });
  }

  

  uploadFiles(formData, payload): Observable<any> {
    return Observable.create(observer => {

      this.http.post(environment['API_URL'] + AppConstant.API_UPLOADFILE, formData, payload)
        .subscribe(data => {
          observer.next(data);
        }, err => {
          console.log(err);
          observer.error(err);
        });
    });

  }

  public clearTopic(topic) {
    if (!topic || !Array.isArray(topic.sections) || topic.sections.length === 0) return null;
    topic.sections.map(s => {
      s.selected = undefined;
      s.checked = undefined;
      if (s.type === 'check')
        s.codify === 'Y' && s.domainControls ? s.domainControls.answer.forEach(a => a.selected = undefined) : s.answer.forEach(a => a.selected = undefined);
    });
    topic.subTopic = true;
    topic.enterFlag = true;
    delete topic._selectedIndex;
    return topic;
  }


  public createAddField(topics, author?: string, authorName?: string) {
    // if (!author) author = this.user.getResourceID();
    // if (!authorName) authorName = this.user.getUserName();
    const adId = Date.now();
    const t = {
      topic: 'Add a note here',
      type: 'addendum',
      adId: adId,
      id: adId,
      sections: []
    }
    let s = {
      type: 'addendum',
      question: [
        {
          locale: "en",
          text: "Addendum"
        }
      ],
      answer: [],
      codify: 'N',
      addendum: true,
      author: author,
      authorName: authorName,
      authoredDate: this.user.getMoment().format()
    }
    t.sections.push(s);
    topics.push(t);
    return { topics: topics, currentId: adId };
  }


  public getRecentParams(dCodes, patientId) {
    const payload = {
      codes: dCodes,
      patientId: patientId
    }
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + 'api/catalogs/getRecentParams', payload, { reportProgress: true }).subscribe((response: HpApiResponse) => {
        if (Array.isArray(response) && response.length)
          return observer.next(response);
        return observer.next();
      });
    });
  }

  public directiveLinkedControls(directivesList, facilityId, needDirectiveControls?: boolean): Observable<any> {
    const controls = {
      isReferral: false,
      isPrescription: false,
      isVital: false,
      isCrossReferral: false,
      isOrder: false,
      getPreviousNotes: false,
      isDiagnosis: false,
      checkStockForFav: false
    };

    if (needDirectiveControls)
      controls['directiveControls'] = {};

    return Observable.create(observer => {
      if (!Array.isArray(directivesList) || !directivesList.length) observer.next(controls);
      // if (!facilityId) facilityId = this.user.getFacilityId();
      let directives = []
      // let directives = this.user.getUserCatalogs();
      console.log(directives);
      if (!Array.isArray(directives)) observer.next(controls);
      directives = directives.filter(d => d.facilityId === facilityId && directivesList.includes(d['directiveCode']));
      directives.forEach(d => {
        const directiveControls = d.directiveControls;
        if (directiveControls) {
          if (directiveControls.referralRelated && directiveControls.referralRelated === 'Y') {
            controls.isReferral = true;
            controls.isCrossReferral = directiveControls.referralTarget === 'X';
            controls['referralDirective'] = directiveControls;
          }
          if (directiveControls.isPrescription && directiveControls.isPrescription === 'Y') {
            controls.isPrescription = true;
            if (directiveControls.checkStockForFav === 'Y')
              controls.checkStockForFav = true;
          }
          if (directiveControls.relatedToVitals && directiveControls.relatedToVitals === 'Y') {
            controls.isVital = true;
          }

          if (directiveControls.integrateHisType === 'O') {
            controls.isOrder = true;
          }

          if (directiveControls.integrateHisType === 'D') {
            controls.isDiagnosis = true;
          }
          const userControls = { prescriptionYn: '', investigationsYn: '', chiefComplaintsYn: '', diagnosisYn:''};
          // const userControls = this.user.getUserControls();

          if (userControls) {
            if ((directiveControls.integrateHisType === 'CC' && userControls.chiefComplaintsYn === 'Y') ||
              (directiveControls.integrateHisType === 'D' && userControls.diagnosisYn === 'Y') ||
              (directiveControls.integrateHisType === 'O' && directiveControls.isPrescription === 'Y' && userControls.prescriptionYn === 'Y') ||
              (directiveControls.integrateHisType === 'O' && directiveControls.isPrescription !== 'Y' && userControls.investigationsYn === 'Y')
            ) {
              controls.getPreviousNotes = true;
            }
          }
        }

        if (needDirectiveControls)
          controls['directiveControls'][d.directiveCode] = directiveControls;

      })
      observer.next(controls);
    })
  }

  public getRecordedNotes(directiveCode: string, conceptCode: string, patientId: string, encounterId: string, conceptName: string = '') {
    const payload = {
      directiveCode: directiveCode,
      conceptCode: conceptCode,
      patientId: patientId,
      encounterId: encounterId
    }

    return new Observable(observer => {
      this.http.post(environment.API_URL + '/events/getRecordedNotes', payload, { reportProgress: true }).subscribe((response: any) => {
        if (Array.isArray(response))
          response.map(r => {
            r['directiveCode'] = directiveCode;
            r['conceptCode'] = conceptCode;
            r['conceptName'] = conceptName;
          });
        return observer.next(response);
      })
    })
  }

  public isOPD = (concept) => {
    return concept.careElementType == 'SMRTCNSLT';
  }
  compareNoteContent = (note1, note2) => {
    return _isEqual(note1, note2);
  };

  public getMasterLinkedData(domain: any, searchStr: String, facilityId?: string): Observable<Array<Object>> {
    const payload = {
      domain: domain
    };
    if (searchStr)
      payload['searchStr'] = searchStr;
    if (facilityId)
      payload['facilityId'] = facilityId;
    return Observable.create(oberserver => {
      this.http.post(environment['API_URL'] + 'api/catalogs/getDomainCodes', payload, { reportProgress: true }).subscribe(result => {
        if (!Array.isArray(result))
          oberserver.error('Error in retriving masters');
        oberserver.next(result);
        oberserver.complete();
      }, error => {
        oberserver.error(error);
      });
    });
  }

  public commonEvalMethod(args) {
    return eval(args);
  }
  public calculateInterpretationScore(topic: any) {
    let totolScore = 0, value = '';
    topic['sections'].map(s => {
      if (s['type'] != 'check' && (s['type'] != 'segment' || !s['multiSelect'])) {
        if (s['codify'] != 'Y') {
          if (Array.isArray(s['answer'])) {
            s['answer'].map(a => {
              if (a['score']) {
                if (s['selected'] === a['localName'][0]['text']) {
                  totolScore += Number(a['score']);
                }
              }
            })
          }
        } else {
          s['domainControls']['answer'].map(a => {
            if (a['score']) {
              if (s['selected'] === a['localName'][0]['text']) {
                totolScore += Number(a['score']);
              }
            }
          })
        }
      } else {
        if (s['codify'] != 'Y') {
          if (Array.isArray(s['answer'])) {
            s['answer'].map(a => {
              if (a['score']) {
                if (s['selected']) {
                  totolScore += Number(a['score']);
                }
              }
            })
          }
        } else {
          s['domainControls']['answer'].map(a => {
            if (a['score']) {
              if (s['selected']) {
                totolScore += Number(a['score']);
              }
            }
          })
        }
      }
    })

    topic['results'].map(r => {
      if (r['from'] && r['to']) {
        if (r['from'] <= totolScore && r['to'] >= totolScore)
          value = r['name'] || r['value'];
      } else if (r['from'] && !r['to']) {
        if (r['from'] <= totolScore)
          value = r['name'] || r['value'];
      }
      else if (!r['from'] && r['to']) {
        if (r['to'] >= totolScore)
          value = r['name'] || r['value'];
      }
    })

    return { score: totolScore, value: value }
  }
  public changeInValues() {
    this.sectionValueListener.next(this.user.getMoment().valueOf());
  }
}
