import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { HealthRecordsService } from '../services/health-records.service';
import * as _ from "lodash";
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hr-visit-summary',
  templateUrl: './hr-visit-summary.component.html',
  styleUrls: ['./hr-visit-summary.component.scss']
})
export class HrVisitSummaryComponent implements OnInit {

  @Input() set selectedVisit(value: any) {
    this._selectedVisit = value;
    this.clinicalHistory = [];
    this.getClinicalHistory();
  }
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
    this.clinicalHistory = [];
    this.getClinicalHistory();
  }
  @Input() refresh: Subject<boolean> = new Subject<boolean>();

  @Output() selectReport: EventEmitter<any>;
  @Output() openDocsUpload: EventEmitter<any>;
  @ViewChildren('clinicalHistoryLoop') clinicalHistoryLoop;
  public _selectedVisit: any;
  public _selectedPatient: any;
  public clinicalHistory: any;
  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: any;
  public search: string;
  public searchExpand : boolean;
  diagnosis:any=[];
  allergy:any=[];
  procedure:any=[];
  patientData: any;
  public showAllergy: boolean = true;
  public showDiagnostics: boolean = true;
  public showProcedure: boolean = true;

  constructor(private hrService: HealthRecordsService, private user: UserService) { }

  ngOnInit(): void {
    this.filters = [
      { key: '', value: 'all' },
      { key: 'Diagnosis', value: 'ttl_diagnosis' },
      { key: 'Allergy', value: 'ttl_allergy' },
      { key: 'Procedure', value: 'ttl_procedure' }
    ];
    this.selectedFilter = this.filters[0];
  }

  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
  }
  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  searchIcon(){
    if(this.searchExpand){
      this.searchExpand = false;
    }
    else{
      this.searchExpand = true;
    }
  }

  getClinicalHistory(){
    this.diagnosis = [];
    this.allergy = [];
    this.procedure = [];

    var self=this;
    let arr = []

    let payload = {
      "patientId": this._selectedPatient && this._selectedPatient['uhid'] ? this._selectedPatient['uhid'] : null,
      "visitId": this._selectedVisit && this._selectedVisit['encounterId'] ? this._selectedVisit['encounterId'] : null ,
      "patientName": this._selectedPatient && this._selectedPatient['personname'] ? this._selectedPatient['personname'] : null,
    }

    if(!this._selectedVisit && this._selectedPatient){
      this.user.getVisitHistory(this._selectedPatient.uhid).subscribe(data => {
        if(data['visits'] && data['visits'].length > 0){
          data['visits'].map(ele =>{
            arr.push(ele['encounterId'])
          })
          if(arr && arr.length > 0){
            let uniqueChars = [...new Set(arr)];
            payload['visitId'] = uniqueChars
            this.getVisitSummary(payload);
          }
        }
      })
    }else{
      if(this._selectedPatient)
        this.getVisitSummary(payload);
    }
  }

  public getVisitSummary(payload){
    this.hrService.getVisitSummary(payload).subscribe(data => {
      // console.log('history',data);
      if(data && !_.isEmpty(data)){
          if(data['patient'] && data['patient'].bloodGroup){
            this.patientData.bloodGroup=data['patient'].bloodGroup;
          }
          if(data['patient'] && data['patient'].rhFactor){
            this.patientData.rhFactor=data['patient'].rhFactor;
          }
          if(data['Diagnosis']){
            this.diagnosis= data['Diagnosis'].sort((a,b)=>new Date(b["onsetDate"]).getTime() - new Date(a["onsetDate"]).getTime());
          }
          if(data['Allergy']){
            this.allergy=data['Allergy'].sort((a,b)=>new Date(b["onsetDate"]).getTime() - new Date(a["onsetDate"]).getTime());
          }
          if(data['Procedure']){
            this.procedure=data['Procedure'].sort((a,b)=>new Date(b["onsetDate"]).getTime() - new Date(a["onsetDate"]).getTime());
          }
      }
    })
  }
}
