<div id="manage-saved-cards">
  <div class="header">
    <div>{{'Manage_cards' | translate}}</div>
    <div (click)="closeModal()" class="pointer">
      <img class="type-image" src="./assets/icons/close_grey.svg">
    </div>
  </div>
  <div class="content">
    <ng-container *ngIf="savedCards && savedCards.length > 0 && !fetchingCards; else CHECKLOADING">
      <div class="text-item">
        <div class="slot-start">
          <img class="prefix-icon type-image" src="assets/icons/cards/secure.svg" />
          <div class="slot-label">
            <p checktranslate>{{'All_your_information_is_safe_and_secure' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="text-item">
        <div class="slot-start">
          <div class="slot-label bold">
            <p checktranslate>{{'Saved_cards' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="cards-list">
        <div class="card-container" *ngFor="let item of savedCards">
          <div class="card" [ngClass]="{expCard: item.exp =='EXPIRED'}">
            <div class="card-item credit-card-item-loading">
              <div class="slot-start">
                <img class="card-type" [src]="cardMapping[item.cardType]? cardMapping[item.cardType]['icon'] : cardMapping['DEFAULT']['icon']"/>
                <div class="slot-label">
                  <h4>{{item.cardNo}}</h4>
                  <p>
                    <ng-container *ngIf="item.exp !='EXPIRED'">{{'CARD#EXPIRES#ON' | translate : 'LABELS': null: null: item}}</ng-container>
                    <ng-container *ngIf="item.exp =='EXPIRED'">{{'CARD#EXPIRED#ON' | translate : 'LABELS': null: null: item}}</ng-container>
                  </p>
                </div>
              </div>
              <div class="slot-end">
                <div class="expired-text" *ngIf="item.exp =='EXPIRED'">
                  {{'CARD#EXPIRED' | translate}}
                </div>
                <div class="delete-img-cont" (click)="deleteCard(item)">
                  <img class="delete-img" src="assets/icons/cards/delete.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #CHECKLOADING>
      <ng-container *ngIf="fetchingCards; else NOSAVEDCARDS">
        <div class="cards-list">
          <div class="card-container" *ngFor="let item of [1,2,3,4,5]">
            <div class="card">
              <div class="card-item credit-card-item-loading">
                <div class="slot-start">
                  <app-skeleton-loader type="image" imgWidth="3rem" imgHeight="3rem"></app-skeleton-loader>
                  <div class="slot-label">
                    <h4><app-skeleton-loader type="text" txtWidth="85%" txtHeight="0.75rem"></app-skeleton-loader></h4>
                    <p><app-skeleton-loader type="text" txtWidth="50%" txtHeight="0.75rem"></app-skeleton-loader></p>
                  </div>
                </div>
                <div class="slot-end">
                  <app-skeleton-loader type="image" imgWidth="2rem" imgHeight="2rem" imgShape="round"></app-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #NOSAVEDCARDS>
        <div id="no-saved-cards">
          <div class="image-wrapper">
            <img src="./assets/icons/payment/card_primary_nodata.svg" alt="">
          </div>
          <p class="bold">{{'No_cards_added' | translate}}</p>
          <p >{{'No_cards_added_desc' | translate}}</p>
        </div>
      </ng-template>
    </ng-template>
    
  </div>
</div>

<div id="delete-confirm-modal" *ngIf="showDeleteModal">
  <div class="delete-modal">
    <div class="delete-header">
      <div>{{'Delete_cards' | translate}}</div>
      <div (click)="closeDelete(false)" class="pointer">
        <img src="./assets/icons/close_grey.svg">
      </div>
    </div>
    <div class="delete-content">
      <img src="assets/icons/error.svg">
      {{'Confirm_delete_saved_card' | translate}}
    </div>
    <div class="delete-footer">
      <button class="cancel-btn" (click)="closeDelete(false)">{{'Cancel'| translate}}</button>
      <button class="confirm-btn" (click)="closeDelete(true)">{{'Delete'| translate}}</button>
    </div>
  </div>
</div>