<div [@enterAnimationAxisY] *ngIf="toastMessage" class="toast-wrapper">
  <div [ngClass]="{
        'info':'info' == toastMessage['type'] ,
        'warning':'warning'  == toastMessage['type'],
        'error':'error'  == toastMessage['type'],
        'success':'success'  == toastMessage['type']
    }" class="container toast show align-middle" style="padding: 10px">
    <div *ngIf="moreNotification >  0" class="row">
      <div class="col-12">
        <img height="25px" src="assets/icons/fav.svg">
        <p class="">{{ moreNotification }} new notifications</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 message">
        <img *ngIf="moreNotification ==  0" height="25px" src="assets/icons/fav.svg">
        <p class="no-margin " *ngIf="!toastMessage['translate']">{{ toastMessage['msg'] }}</p>
        <p class="no-margin " *ngIf="toastMessage['translate']">{{ toastMessage['msg'] | translate}}</p>
      </div>
    </div>
  </div>
</div>