import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonAvatarComponent } from './person-avatar.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [PersonAvatarComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [PersonAvatarComponent]
})
export class PersonAvatarModule { }
