import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AltpaymentInputComponent } from './altpayment-input.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AltpaymentModalModule } from '../altpayment-modal/altpayment-modal.module';
import { DisplayCurrencyModule } from '../display-currency/display-currency.module';



@NgModule({
  declarations: [
    AltpaymentInputComponent
  ],
  imports: [
    CommonModule, PipesModule, AltpaymentModalModule, DisplayCurrencyModule
  ], exports: [
    AltpaymentInputComponent
  ]
})
export class AltpaymentInputModule { }
