import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientChatComponent } from './patient-chat.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { NodataModule } from '../nodata/nodata.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';

@NgModule({
  declarations: [PatientChatComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, InfiniteScrollModule,
    FilePreviewModule, NodataModule, PersonAvatarModule
  ],
  exports: [PatientChatComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PatientChatModule { }
