import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
const COUNTER_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PartitionInputComponent),
  multi: true
};
@Component({
  selector: 'app-partition-input',
  templateUrl: './partition-input.component.html',
  styleUrls: ['./partition-input.component.scss'],
  providers: [COUNTER_CONTROL_ACCESSOR],
})
export class PartitionInputComponent implements OnInit, ControlValueAccessor {
  @Input() type: 'number';
  @Input() readonly: boolean = false;
  @Input() length: number = 6;
  public onTouch: Function;
  public onModelChange: Function;
  private value: any;
  private otp: any;
  public otpArray: Array<any> = [];
  

  ngOnInit(): void {
    for (let i = 0; i < this.length; i++) {
      this.otpArray.push({ value: null })
    }
    setTimeout(_ => {
      document.getElementById('codeBox1').focus();
    }, 500)
  }

  writeValue(value: string) {
    this.value = value;
    this.otp = this.value;
  }
  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: Function) {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
  goNext(index) {
    if (this.otpArray[index].value >= 10) {
      this.otpArray[index].value = this.otpArray[index].value.charAt(0);
    }
    if (this.otpArray[index].value >= 0) {
      if (index < this.length - 1)
        document.getElementById('codeBox' + (index + 2)).focus()
      if (index == this.length - 1)
        document.getElementById('codeBox' + (index + 1)).blur()
    }
    if (this.onModelChange) {
      this.value = this.otpArray.map(arr => arr.value).filter(arr => arr >= 0).join('');
      this.onModelChange(this.value)
    };
  }
}
