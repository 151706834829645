import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseSlotComponent } from './choose-slot.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
@NgModule({
  declarations: [ChooseSlotComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, PersonAvatarModule,
    PractitionerAvatarModule
  ],
  
  exports: [ChooseSlotComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChooseSlotModule { }
