<div id="care-plan">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img src="assets/icons/careplan.svg" />
        </div>
        <div class="title">{{'care_plan' | translate}}</div>
      </div>
      <div class="actions captilize">
        <span>{{'btn_settings'|translate:'LABELS'}}</span>
      </div>
    </div>
    <div class="list">
      <app-list-item>
        <img class="thumbnail" src="assets/icons/care_blue.svg" />
        <span class="title">
          <img class="prefix" src="assets/icons/care_blue.svg" />Cancer Care Plan</span>
        <span class="chip">Active</span>
        <span class="primary-content">
          <img src="assets/icons/calendar-simple.svg">
          <span>3 Dec 2019</span>
        </span>
        <span class="secondary-content">
          <img src="assets/icons/doctor.svg">
          <span>Dr Bernarr Dominik</span>
        </span>
        <div class='list-action'>
          <div>Mr James Williams</div>
          <div><span class="ternary-text-color">Last updated: </span><span>1 Apr 2020</span></div>
        </div>
      </app-list-item>
    </div>
    <div class="care-details">
      <div class="care-description">
        <app-list-item>
          <span class="title bold">Throat Cancer</span>
          <span class="description">
            <span class="ternary-text-color">Care team</span>
            <span>Jenna James(Nursing coordinator)</span>
          </span>
          <div class="primary-content">
            <div>Maintain patient's health and take medicines for throat cancer</div>
            <div><img src="assets/icons/calendar-simple.svg"><span>12 Apr 2018 - 12 Apr 2020</span></div>
          </div>
        </app-list-item>
      </div>
    </div>
    <div class="care-list" *ngFor="let item of sample.carelist">
      <div class="care-description">
        <app-list-item>
          <span class="prefix"><img [src]="item.prefix"> </span>
          <span class="title bold">{{item.title}}</span>
          <span class="sub-title">{{item.subtitle}}</span>
          <span class="description ternary-text-color" *ngIf="item.desc">{{item.desc}}</span>
          <span class="primary-content"> <span><img [src]="item.content.img"><span>{{item.content.date}}</span></span> </span>
          <span class="secondary-content"> <img [src]="item.location.img"> <span>{{item.location.content}}</span> 
          </span>
          <span class='list-action'> <img [src]="item.status"> </span>
        </app-list-item>
      </div>
    </div>
  </div>
</div>
