import { Component, EventEmitter, OnInit, Output, ViewChildren, ViewEncapsulation } from '@angular/core';
import { VisitService } from 'src/app/services/visit.service';
import { VisitAbstractClass } from '../visit-standalone.abstract';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-step-signin',
  templateUrl: './step-signin.component.html',
  styleUrls: ['./step-signin.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class StepSigninComponent extends VisitAbstractClass implements OnInit {
  @Output() patient: EventEmitter<any> = new EventEmitter();
  @Output() registerPatient: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @ViewChildren('formRow') rows: any;

  public selectedLoginType: 'P' | 'M' = 'M';
  viewOTPModal: boolean = false;
  viewPatientsModal: boolean;
  phonNumber;
  otp: string = '';
  public patients: any[];
  isRegisterPatient: boolean;
  maskedMobile;
  patientId;
  timer;
  timerInterval;
  isPhonValid: any;
  
  constructor(private visitS:VisitService) { 
    super();
  }

  ngOnInit(): void {
    console.log(this.selectedPract);
  }

  continue() {
    if ((this.selectedLoginType == 'M' && !this.checkValidPhone()) || (this.selectedLoginType == 'P' && !this.patientId))
      return;
    const payload = this.selectedLoginType == 'M' ? this.phonNumber : { patientId: this.patientId };
    this.visitS.precheck(payload).subscribe(data => {
      if (data['patients']) {
        this.patients = data['patients'];
        // this.patients.length = 4
        if (this.patients.length) {
          this.selectedPatient = this.patients[0];
          if (this.selectedLoginType == 'P')
            this.choosePatient();
          this.viewPatientsModal = true;
        }
        else if (!this.patients.length && this.selectedLoginType == 'M')
          this.chooseRegister();
      } else {
        this.maskedMobile = this.selectedLoginType == 'M' ? `${this.phonNumber['fullPhone'].substring(0, 7)}XXXXXX` : "+" + data['maskedMobile'];
        this.viewOTPModal = true;
        this.resetResendTimer();
      }
    })
  }

  setPhoneNumber(ev) {
    console.log('setPhoneNumber',ev);
    this.phonNumber = ev;
    this.isPhonValid = this.checkValidPhone();
  }
  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
  }
  
  signinPatient() {
    if (this.otp.length < 6)
      return;
    const payload = this.selectedLoginType == 'M' ? this.phonNumber : { patientId: this.patientId };
    this.visitS.patientSignin(payload, this.otp).subscribe(data => {
      this.closeOTPModal();
      this.patients = data['patients'];
      // this.patients.length = 4
      if (this.patients.length) {
        this.selectedPatient = this.patients[0];
        if (this.selectedLoginType == 'P')
          this.choosePatient();
        else
          this.viewPatientsModal = true;
      }
      else if (!this.patients.length && this.selectedLoginType == 'M')
        this.chooseRegister();
    })
  }

  chooseRegister() {
    this.selectedPatient = null;
    this.isRegisterPatient = true;
    this.registerPatient.emit(true);
  }

  choosePatient() {
    if(this.selectedPatient?.type == 'REG_PAT') this.chooseRegister();
    else if (this.selectedPatient)
      this.visitS.validatePatientVisit(this.queryParams['fid'],
        this.selectedPract['practId'],
        this.selectedPract['clinicCode'],
        this.selectedPatient['patientId'])
        .subscribe(d => {
          this.patient.emit(this.selectedPatient);
    })
  }

  closeOTPModal() {
    this.viewOTPModal = false;
    this.otp = '';
  }

  resetResendTimer() {
    if (this.timerInterval)
      clearInterval(this.timerInterval);
    const toTime = moment().startOf('second').add(30, 'seconds');
    console.log(toTime.format())
    const diff = toTime.diff(moment())
      this.timer = moment().startOf('d').add(diff, 'milliseconds').format('mm:ss');
   this.timerInterval = setInterval(() => {
      const diff = toTime.diff(moment())
      if (diff <= 0)
        return this.timer = null;
      this.timer = moment().startOf('d').add(diff, 'milliseconds').format('mm:ss');
    }, 1000)
  }

  resendOTP() {
    const payload = this.selectedLoginType == 'M' ? this.phonNumber : { patientId: this.patientId };
    this.visitS.resendOTP(payload).subscribe(data => {
      this.resetResendTimer();
    })
  }

  checkValidPhone() {
    if (!this.phonNumber || !this.phonNumber['mobile'] || this.phonNumber['mobile'].length<=2)
      return false;
    var phoneUtil = PhoneNumberUtil.getInstance();
    var number = phoneUtil.parse(this.phonNumber['fullPhone']);
    return phoneUtil.isValidNumber(number);
  }  

  backToSelect() {
    this.back.emit();
  }
}
