import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import * as  _ from 'lodash';
import { CouponService } from 'src/app/services/coupon.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-altpayment-modal',
  templateUrl: './altpayment-modal.component.html',
  styleUrls: ['./altpayment-modal.component.scss']
})
export class AltpaymentModalComponent {
  @Input() discountDetails;
  @Input() parsedChargeInfo;
  @Input() couponProps;
  @Input() pointsProps;
  @Output() complete: EventEmitter<any>

  public isFetchingCoupon: boolean = false
  public patientPayable: any;
  private localtyID: any;
  public couponDetails: any = {
    selectedCoupons: [],
    couponList: [],
    couponBalPatientPayable: 0,
    isInValidCoupon: false,
    couponDiscount: 0
  }
  public appliedCoupons: any;
  public currency: any;
  public type: 'COUPON' | 'RESELECT' | 'COUPONNEW' | 'VIEWONLY' | 'POINTS';
  public couponAlert: boolean = false;
  public showCouponAlert: boolean = true;
  public showNuhdeekPoints: boolean
  public availableNuhdeekPoints: any;
  public pointsAvailable: boolean = false;
  public appliedPoints: any;
  public pointsAdded: number = 0;
  public pointsInfo: any = null;
  public pointsDerivative: any = {}
  public isFetchingPoints: boolean = false;
  public pointsGuideline: any = ''
  constructor(private user: UserService, private cdf: ChangeDetectorRef, private couponService: CouponService) {
    // this.showCouponAlert = this.permission.canViewOption('SHOWCOUPONALERT')
    this.complete = new EventEmitter<any>()
  }

  private mapCouponProps() {
    if (!this.couponProps) return;
    this.type = this.couponProps.type
    this.patientPayable = this.couponProps.patientPayable
    this.appliedCoupons = this.couponProps.appliedCoupons
  }
  private mapPointsProps() {
    if (!this.pointsProps) return;
    this.type = this.pointsProps.type
    this.patientPayable = this.pointsProps.patientPayable
    this.appliedPoints = this.pointsProps.appliedPoints
  }
  ngOnInit() {
    this.mapCouponProps()
    this.mapPointsProps()
    if (this.type == 'COUPON' || this.type == 'COUPONNEW' || this.type == 'VIEWONLY') {
      this.currency = this.user.getCurrency();
      this.couponDetails.couponBalPatientPayable = this.patientPayable
      this.localtyID = this.user.getloyaltyId();
      this.getPointsInfo()
      this.getPointsGuidelines()
      if (this.localtyID)
        this.getCouponDetails();
    } else if (this.type == 'POINTS') {
      this.currency = this.user.getCurrency();
      this.localtyID = this.user.getloyaltyId();
      this.getPointsInfo()
      this.getPointsGuidelines()
    }
    console.log('parsedChargeInfo',this.parsedChargeInfo)
  }

  private getPointsGuidelines() {
    this.user.getUpdateInfo('directives').subscribe(data => {
      let tc = data.userDefined.filter(def => {
        return def.directiveGroup == 'GUIDELINES'
      })
      if (tc) {
        let guidelines = [].concat.apply([], tc.map(t => t.concepts))
        console.log('guidelines', guidelines)
        let guideline = guidelines.find(c => c.code == 'POINTSGUIDELINES')
        console.log('guideline', guideline)
        if (guideline && guideline.detail) {
          this.pointsGuideline = guideline.detail;
        }
      }
    });
    
  }
  private getCouponDetails() {
    this.isFetchingCoupon = true;
    this.cdf.detectChanges()
    let payLoad: any = {
      loyaltyId: this.localtyID,
      isBackgroundProcess: true
    };
    this.couponService.getCoupons(payLoad).subscribe(res => {
      if (res['status'] == "SUCCESS") {
        let couponList = res.data;
        // couponList = coupons_mock;

        let selectedCouponsequence = []
        if (this.appliedCoupons && this.appliedCoupons.appliedcouponcodedtls && this.appliedCoupons.appliedcouponcodedtls.length > 0) {
          selectedCouponsequence = this.appliedCoupons.appliedcouponcodedtls.map(c => c.couponSequenceNo);
        }
        if (couponList && couponList.length > 0) {
          this.couponDetails.couponList = couponList.map(item => {
            item['loyaltyId'] = this.localtyID
            item.selected = selectedCouponsequence.indexOf(item.couponSequenceNo) > -1
            return item
          })
        }
      }
      this.isFetchingCoupon = false;
      console.log('couponDetails', this.couponDetails)
    }, err => {
      this.isFetchingCoupon = false;
    });
  }

  public goBack() {
    this.complete.emit({type: 'CLOSE'})
  }

  public selectCoupon(coupon) {
    if (this.type == 'VIEWONLY') return;
    if (this.couponDetails.couponBalPatientPayable == 0 && !coupon.selected) return;
    this.couponDetails.couponList.forEach(element => {
      if (element.couponSequenceNo == coupon.couponSequenceNo) {
        element.selected = !element.selected;
      }
    });
    this.checkCouponApplyStatus('MANUAL')
  }

  public checkCouponApplyStatus(event?) {
    console.log('checkCouponApplyStatus', event)
    this.couponDetails.selectedCoupons = []
    this.couponDetails.couponDiscount = this.couponDetails.couponList.reduce((discount, data, index) => {
      if (data.selected) {
        let couponDiscount = Number(data.cashDiscount)
        let balancePayable = Number(this.patientPayable) - discount
        let couponBurnt = 0;
        if (balancePayable > 0) {
          couponBurnt = Math.min(couponDiscount, balancePayable);
        }
        this.couponDetails.couponList[index]['couponBurnt'] = couponBurnt;
        discount += couponBurnt
        this.couponDetails.selectedCoupons.push(data)
      }
      return discount
    }, 0)
    if (this.showCouponAlert) {
      let totalCouponAmount = this.couponDetails.selectedCoupons.reduce((r, a) => {
        r += Number(a.cashDiscount)
        return r;
      }, 0)
      if (totalCouponAmount > Number(this.patientPayable)) {
        this.couponAlert = true;
      } else {
        this.couponAlert = false;
      }
    }
    this.couponDetails.couponBalPatientPayable = this.patientPayable - this.couponDetails.couponDiscount
    if (event == 'MANUAL') {
      this.couponDetails.couponList = _.cloneDeep(this.couponDetails.couponList)
    }
    console.log('couponDetails', this.couponDetails)
  }

  public apply() {
    const response = {
      appliedcouponcodedtls: this.couponDetails.selectedCoupons,
      value: this.couponDetails.couponDiscount
    }
    let payload = {
      data: response,
      type: 'COUPON',
    }
    this.complete.emit({type: 'APPLYCOUPON', payload: payload})
  }

  // public updatePointsDerivatives() {
  //   console.log('updatePointsDerivatives')
  //   let multiple = this.pointsInfo?.pointsMultiple;

  //   let appliedPoints = this.pointsAdded || 0
  //   let maxPoints = this.pointsInfo?.maxIncrement

  //   let valid = appliedPoints <= maxPoints && (appliedPoints % (this.pointsInfo?.allowedMultiple || 1)) == 0
  //   this.pointsDerivative = {
  //     points: this.pointsAdded,
  //     valid: valid,
  //     loyaltyId: this.localtyID,
  //     info: this.pointsInfo
  //   }
  //   // if (appliedPoints <= maxPoints) {
  //   this.pointsDerivative.amount = this.user.fixDecimalPlaces(Number(this.pointsAdded) / multiple)
  //   this.pointsDerivative.balance = this.user.fixDecimalPlaces(this.patientPayable - this.pointsDerivative.amount)
  //   // }
  //   console.log('updatePointsDerivatives', this.pointsDerivative)

  // }

  public updatePointsDerivatives() {
    let multiple = this.pointsInfo?.pointsMultiple;

    let appliedPoints = this.pointsAdded
    let maxPoints = this.pointsInfo?.maxIncrement
      
    let valid = appliedPoints <= maxPoints && (appliedPoints % (this.pointsInfo?.allowedMultiple || 1)) == 0
    this.pointsDerivative = {
      points: this.pointsAdded,
      valid: valid,
      loyaltyId: this.localtyID,
      info: this.pointsInfo
    }
    this.pointsDerivative.amount = this.user.fixDecimalPlaces(Number(this.pointsAdded) / multiple)
    let patientPayable = this.patientPayable;

    if (this.parsedChargeInfo && this.parsedChargeInfo.baseServiceAmount ) {
      patientPayable = parseFloat(this.parsedChargeInfo.baseServiceAmount) 
    }
  
    // Calculate VAT amount from base service amount and discounted points
    let baseServiceAmount = parseFloat(this.parsedChargeInfo.baseServiceAmount);
    let discountedPointsAmount = this.pointsDerivative.amount;
    let vatRate = this.parsedChargeInfo && this.parsedChargeInfo.vatDisplayAmount && parseFloat(this.parsedChargeInfo.vatDisplayAmount)>0 ? 15 : 0//parseFloat(this.parsedChargeInfo.vatRate) || 0;
  
    let vatAmount:any = this.user.fixDecimalPlaces((baseServiceAmount - discountedPointsAmount) * vatRate / 100);
    this.pointsDerivative.vatAmount = vatAmount;
  
    // Update balance
    this.pointsDerivative.balance = this.user.fixDecimalPlaces(patientPayable - discountedPointsAmount) + vatAmount;

    console.info('pointsDerivative', this.pointsDerivative,this.pointsAdded,this.patientPayable,patientPayable,this.parsedChargeInfo,vatAmount)
  }

  private getPointsInfo() {
    this.isFetchingPoints = true;
    this.cdf.detectChanges()
    let payLoad: any = {
      loyaltyId: this.localtyID,
      patientPayable: this.patientPayable,
      isBackgroundProcess: true,
      chargeStr: this.discountDetails ? this.discountDetails.charge : ""
    };
    this.couponService.getPointsInfo(payLoad).subscribe(data => {
      this.pointsInfo = data
      if (this.appliedPoints && this.appliedPoints.pointsDerivative && this.appliedPoints.pointsDerivative.points) {
        this.pointsAdded = this.appliedPoints.pointsDerivative.points || 0
        this.updatePointsDerivatives()
      }
      if(data && data.availablePoints !== null && data.availablePoints !== undefined) {
        this.pointsAvailable = true;
        this.availableNuhdeekPoints = data.availablePoints;
        if(data && data.expiryDetails && data.expiryDetails.value) {
          this.pointsDerivative = {
            expiry_points: data.expiryDetails.value
          }
        }
      }
      this.isFetchingPoints = false;
      this.cdf.detectChanges()
    }, err => {
      this.isFetchingPoints = false;
      this.cdf.detectChanges()
    })
  }

  public applyPoints() {
    const response = {
      pointsInfo: this.pointsInfo,
      pointsDerivative: this.pointsDerivative,
      netchargedtls: this.pointsInfo['netchargedtls']
    }
    let payload = {
      data: response,
      type: 'POINTS',
    }
    this.complete.emit({type: 'APPLYPOINTS', payload: payload})
  }
}

export const coupons_mock = [
  {
    "cashDiscount": "90",
    "couponSequenceNo": "NMC100000011240000000000007521",
    "groupID": "-1",
    "couponDescription": "Coupon Amount 90 OFF",
    "couponDescriptionArabic": "Coupon Amount 90 OFF",
    "couponType": "CASH",
    "couponTitle": "Coupon Amount 90 OFF"
  },
  {
    "cashDiscount": "10",
    "couponSequenceNo": "NMC100000011240000000000007519",
    "groupID": "-1",
    "couponDescription": "Coupon Amount 10 OFF",
    "couponDescriptionArabic": "Coupon Amount 10 OFF",
    "couponType": "CASH",
    "couponTitle": "Coupon Amount 10 OFF"
  },
  {
    "cashDiscount": "10",
    "couponSequenceNo": "NMC100000011240000000000007520",
    "groupID": "-1",
    "couponDescription": "Coupon Amount 10 OFF",
    "couponDescriptionArabic": "Coupon Amount 10 OFF",
    "couponType": "CASH",
    "couponTitle": "Coupon Amount 10 OFF"
  },
  {
    "cashDiscount": "30",
    "couponSequenceNo": "NMC100000011240000000000007523",
    "groupID": "-1",
    "couponDescription": "Coupon Amount 30 OFF",
    "couponDescriptionArabic": "Coupon Amount 30 OFF",
    "couponType": "CASH",
    "couponTitle": "Coupon Amount 30 OFF"
  },
  {
    "cashDiscount": "20",
    "couponSequenceNo": "NMC100000011240000000000007522",
    "groupID": "-1",
    "couponDescription": "Coupon Amount 20 OFF",
    "couponDescriptionArabic": "Coupon Amount 20 OFF",
    "couponType": "CASH",
    "couponTitle": "Coupon Amount 20 OFF"
  }
]