import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {
  public sample: any;

  ngOnInit() {
    this.sample = {
      carelist:[
        {
          prefix: 'assets/icons/medicine.svg',
          title: 'Nolvadex',
          subtitle: 'Home Thearapy',
          desc: '5.4mg/ml, Morning, Noon, Evening',
          content: {
            img: 'assets/icons/calendar-simple.svg',
            date: '12 Apr 2018 - 12 Apr 2020'
          },
          location: {
            img: 'assets/icons/pin.svg',
            content:'Home'
          },
          status: 'assets/icons/success.svg'
        },
        {
          prefix: 'assets/icons/medicine-care.svg',
          title: 'Taxol',
          subtitle: 'Parenteral chemothearapy',
          content: {
            img: 'assets/icons/calendar-simple.svg',
            date: '12 Apr 2018 - 12 Apr 2020'
          },
          location: {
            img: 'assets/icons/pin.svg',
            content:'Alnahdi care clinics'
          },
          status: 'assets/icons/progress.svg'
        }
      ]
    }
  }

}
