import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeCareServicesComponent } from './home-care-services.component';
import { HomeCareActivityModule } from '../home-care-activity/home-care-activity.module';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';




@NgModule({
  declarations: [HomeCareServicesComponent],
  imports: [
    CommonModule,
    HomeCareActivityModule, PipesModule, DirectiveModule
  ],
  exports: [HomeCareServicesComponent]
})
export class HomeCareServicesModule { }
