import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonViewComponent } from './common-view.component';
import { HrVisitBillDetailsModule } from '../hr-visit-bill-details/hr-visit-bill-details.module';
import { PipesModule } from '../pipes/pipes.module';
import { TaskLoginModule } from '../task-login/task-login.module';
import { HealthRecordsModule } from '../health-records/health-records.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [CommonViewComponent],
  imports: [
    BrowserModule, CommonModule, PipesModule, HrVisitBillDetailsModule, TaskLoginModule, HealthRecordsModule, FormsModule, ReactiveFormsModule
  ],
  exports: [CommonViewComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonViewModule { }
