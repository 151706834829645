import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import { AppointmentService } from '../services/appointment.service';
import { Subject } from "rxjs";
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-manage-payment-card',
  templateUrl: './manage-payment-card.component.html',
  styleUrls: ['./manage-payment-card.component.scss']
})
export class ManagePaymentCardComponent implements OnInit {

  public cardDetail: any;
  public showModal: boolean = false;
  public selectedCard: any;
  @Output() close: EventEmitter<any>;
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  constructor( private apptService:AppointmentService, private user: UserService) {
    this.close = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.refresh.subscribe(response => {
      if (response) {
        this.getSavedCard();
      }
    })
    this.getSavedCard();
  }

  public removeCard(){
    this.showModal = false;
    let payload = {
      "paymentMode": this.selectedCard.paymentMode,
      "cardNumber": this.selectedCard.cardNumber,
      "savedCardToken": this.selectedCard.savedCardToken,
      "expiryDate": this.selectedCard.expiryDate,
      "transactionId": this.selectedCard.transactionId
    }
    this.apptService.removeSavedCard(payload).subscribe( response => {
      console.log("removeSavedCard", response)
      this.getSavedCard();
    })
  }

  public deleteCard(item){
    this.selectedCard = item
    // this.showModal = true;
    this.close.emit({name: 'removeCard', item: item});
  }

  public closeModal() {
    this.showModal = false;
  }

  public getSavedCard(){
    this.apptService.getSavedCard().subscribe((response: any) => {
      console.log("ManagePaymentCardComponent", response)
      this.cardDetail  = response.data
    })
  }

  public checkCardExpiry(date: string) {
    return this.user.getMoment(this.user.getMoment(date, 'YYMM').format()).isSameOrAfter(this.user.getMoment().format(), 'm')
  }

}
