import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageAddressComponent } from './manage-address.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';

@NgModule({
  declarations: [ManageAddressComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule
  ],
  exports: [
    ManageAddressComponent
  ]
})
export class ManageAddressModule { }
