import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from "rxjs";
import { ModalService } from '../services/modal.service';
import { ServicerequestService } from '../services/servicerequest.service';

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})

export class ServiceRequestComponent implements OnInit {

  @Output() close: EventEmitter<any>;
  @Input() selectedService: any;
  refresh: Subject<boolean> = new Subject<boolean>();

  public isServiceRequest: boolean = true;
  public showModal: boolean = false;
  public serviceRequestData: Array<any>;
  public addMoreOptionModal: boolean = false;
  public showComponents: boolean = false;
  public showHeader: boolean = false;

  constructor(public modalService: ModalService, public serviceRequest: ServicerequestService) {
    this.close = new EventEmitter<any>();
    console.info(this.serviceRequestData)
  }

  ngOnInit(): void {
    this.getServiceReqList();
  }

  public getServiceReqList() {
    this.serviceRequest.getServiceReqList().subscribe(data => {
      if (data.length > 0) {
        this.serviceRequestData = data;
        this.selectedService = this.serviceRequestData[0].list[0];
      }
    })
  }

  public closeModal() {
    this.close.emit();
  }

  public selecteServiceRequest(item) {
    this.selectedService = item;
    this.showComponents = true;
    this.showHeader = true;
  }

  public reset() {
    this.showComponents = false;
    this.showHeader = false;
    this.refresh.next(true);
  }

  public moreActions(item) {
    item['addMoreOptionModal'] = !item['addMoreOptionModal'];
    this.showComponents = false;
    this.showHeader = false;
  }

  public cancelRequest(data) {
    this.serviceRequest.dismissRequest(data).subscribe(data => {
      if (data.status === 'SUCCESS') {
        this.getServiceReqList();
      }
    })
  }


}
