<div *ngIf="true" id="patient-billing-component">
  <div class="header-component" [appRemoveFeatureOff]="'SHOW_APP_HEADER'">
    <app-header></app-header>
  </div>
  <div class="patient-billing-component">
    <div id="patient-billing-outstanding" *ngIf="false">
      <div class="billing-card-container">
        <div class="billing-card">
          <div class="billing-card-title">
            <img class="title-image" src="assets/icons/billing/bills_clr.svg" />
            <span class="font-default-title">{{'Outstanding_bills' | translate}}</span>
          </div>
          <div class="billing-card-table border-bottom">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="font-light-small1">{{'Bill_details' | translate}}</th>
                  <th class="font-light-small1">{{'Patient_details' | translate}}</th>
                  <th class="font-light-small1">{{'Bill_reciept_no' | translate}}</th>
                  <th class="font-light-small1">{{'Amount'|translate}}</th>
                  <th class="font-light-small1">{{'Payment_details'|translate}}</th>
                  <th class="font-light-small1">{{'Actions'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of unsettledBill">
                  <td>
                    <div class="view-type-thumbnail">
                    <img class="tumbnail-image type-image" src="assets/icons/billing/bills_blue.svg" />
                    </div>
                  </td>
                  <td>
                    <div class="view-type-1">
                      <div class="primary-section font-default-subtitle">{{item['serviceGroups']}}</div>
                      <div class="secondary-section font-primary-small1">
                        <img class="prefix-image type-image" src="assets/icons/calendar_app.svg" />
                        {{item['billDate'] | moment : 'DD MMM YYYY, hh:mm a'}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{item['patientName']}}</div>
                      <div class="secondary-section font-light-small1">{{item['patientId'] || '-'}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{item['billNo']}}</div>
                      <div class="secondary-section font-light-small1">{{item['recieptNo'] || '-'}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{currencyCode}} {{item['billAmount']}}</div>
                      <div class="secondary-section font-light-small1">{{item['billingGroup']}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{currencyCode}} {{item['billOutstandingAmount']}} {{'due' | translate}}</div>
                      <div class="secondary-section font-light-small1"
                        *ngIf="item['installmentDueObj'] && item['installmentDueObj']['date']">
                        {{'Next_installment_due_by' | translate}}
                        {{item['installmentDueObj']?(item['installmentDueObj']['date'] | moment : 'DD MMM YYYY'):''}}</div>
                      <div class="secondary-section font-light-small1"
                        *ngIf="!item['installmentDueObj'] || !item['installmentDueObj']['date']">-</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-action">
                      <button class="infolight-font-primary-button" (click)="getPatientBillDetails(item, 'VIEW')">{{'View'|translate}}</button>
                      <button class="primary-button" (click)="getPatientBillDetails(item, 'PAY')">{{'Pay_now'|translate}}</button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="!unsettledBill || unsettledBill.length == 0">
                  <td colspan="6" class="no-data-td font-primary-default">{{'No_unsettled_bills'|translate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="billing-card-pagination">
            <app-table-pagination  
              (navigateTable)="navigateUnsettledTable($event)"
              [currentPage]="unsettledBillsPagination.currentPage"
              [totalRecords]="unsettledBillsPagination.totalRecords" 
              [recordsPerPage]="unsettledBillsPagination.recordsPerPage"
              (changePerPageCount)="changePerPageCount($event, 'UNSETTLED')"
            ></app-table-pagination>
          </div>
        </div>
      </div>
    </div>
    <div id="patient-billing-settled" *ngIf="false">
      <div class="billing-card-container">
        <div class="billing-card">
          <div class="billing-card-title">
            <img class="title-image" src="assets/icons/billing/bills_clr.svg" />
            <span class="font-default-title">{{'Settled_bills' | translate}}</span>
          </div>
          <div class="billing-card-table border-bottom">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="font-light-small1">{{'Bill_details' | translate}}</th>
                  <th class="font-light-small1">{{'Patient_details' | translate}}</th>
                  <th class="font-light-small1">{{'Bill_reciept_no' | translate}}</th>
                  <th class="font-light-small1">{{'Amount'|translate}}</th>
                  <th class="font-light-small1">{{'Status'|translate}}</th>
                  <th class="font-light-small1">{{'Actions'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of settledBills">
                  <td>
                    <div class="view-type-thumbnail">
                    <img class="tumbnail-image type-image" src="assets/icons/billing/bills_blue.svg" />
                    </div>
                  </td>
                  <td>
                    <div class="view-type-1">
                      <div class="primary-section font-default-subtitle">{{item['serviceGroups']}}</div>
                      <div class="secondary-section font-primary-small1">
                        <img class="prefix-image type-image" src="assets/icons/calendar_app.svg" />
                        {{item['billDate'] | moment : 'DD MMM YYYY, hh:mm a'}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{item['patientName']}}</div>
                      <div class="secondary-section font-light-small1">{{item['patientId'] || '-'}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{item['billNo']}}</div>
                      <div class="secondary-section font-light-small1">{{item['recieptNo'] || '-'}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">{{currencyCode}} {{item['billAmount']}}</div>
                      <div class="secondary-section font-light-small1">{{item['billingGroup']}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-2">
                      <div class="primary-section">Paid</div>
                      <div class="secondary-section font-light-small1">Paid on
                        {{item['billDate'] | moment : 'DD MMM YYYY'}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="view-type-action">
                      <button class="infolight-font-primary-button" (click)="getPatientBillDetails(item, 'VIEW')">{{'View'|translate}}</button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="!settledBills || settledBills.length == 0">
                  <td colspan="6" class="no-data-td font-primary-default">{{'No_settled_bills'|translate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="billing-card-pagination">
            <app-table-pagination 
              [currentPage]="settledBillsPagination.currentPage"
              [totalRecords]="settledBillsPagination.totalRecords"
              [recordsPerPage]="settledBillsPagination.recordsPerPage"
              (navigateTable)="navigateSettledTable($event)"
              (changePerPageCount)="changePerPageCount($event, 'SETTLED')"></app-table-pagination>
          </div>
        </div>
      </div>
    </div>
    <div id="patient-all-bills" *ngIf="true">
      <div class="billing-card-container">
        <div class="billing-card">
          <div class="billing-card-title">
            <div>
              <img class="title-image" src="assets/icons/billing/bills_clr.svg" />
              <span class="font-default-title">{{'Bills' | translate}}</span>
            </div>
            <div class="filter" [ngClass]="{active: (searchExpand === true)}">
              <div class="dp-down cursor-pointer">
                <div class="dp-select" (click)="openFilter()">
                  <span class="font-primary-default">{{selectedFilter.value|translate:'LABELS'}}</span>
                </div>
                <div class="dp-option" *ngIf="isFilterOpen">
                  <span class="option" (click)="changeFilter(item)"
                    *ngFor="let item of filters">{{item.value|translate:'LABELS'}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="billing-card-table border-bottom">
            <div class="bill-table">
              <div class="bill-table-row table-header border-bottom">
                  <div class="bill-table-cell font-default-small1" style="grid-area: a;"></div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: b;">{{'Bill_details' | translate}}</div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: c;">{{'Patient_details' | translate}}</div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: d;">{{'Bill_reciept_no' | translate}}</div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: e;">{{'Amount'|translate}}</div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: f;">{{'Status'|translate}}</div>
                  <div class="bill-table-cell font-default-small1" style="grid-area: g;">{{'Actions'|translate}}</div>
              </div>
              <div class="bill-table-row table-content" *ngFor="let item of allBills">
                <div class="bill-table-cell" style="grid-area: a;">
                  <div class="view-type-thumbnail">
                    <img class="thumbnail-image type-image" *ngIf="item.billType == 'US'" src="assets/icons/billing/bills_unsettled.svg" />
                      <img class="thumbnail-image type-image" *ngIf="item.billType == 'SB'" src="assets/icons/billing/bills_settled.svg" />
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: b;">
                  <div class="view-type-1">
                    <div class="primary-section font-default-subtitle">{{item['serviceGroups']}}</div>
                    <div class="secondary-section font-primary-small1">
                      <img class="prefix-image type-image" src="assets/icons/calendar_app.svg" />
                      {{item['billDate'] | moment : 'DD MMM YYYY, hh:mm a'}}
                    </div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: c;">
                  <div class="view-type-2 patientdetails">
                    <div class="primary-section">
                      <img class="user-image type-image" src="assets/icons/user.svg" />
                      {{item['patientName']}}
                    </div>
                    <div class="secondary-section font-light-small1">{{item['patientId'] || '-'}}</div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: d;">
                  <div class="view-type-2 billdetails">
                    <div class="primary-section">{{item['billNo']}}</div>
                    <div class="secondary-section font-light-small1">{{item['recieptNo'] || '-'}}</div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: e;">
                  <div class="view-type-2 payamount">
                    <div class="primary-section">{{currencyCode}} {{item['billAmount']}}</div>
                    <div class="secondary-section font-light-small1">{{item['billingGroup']}}</div>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: f;">
                  <div class="view-type-2 paydetails">
                    <ng-container *ngIf="item.billType == 'US'">
                      <div class="primary-section">{{currencyCode}} {{item['billOutstandingAmount']}}
                        {{'due' | translate}}</div>
                      <div class="secondary-section font-light-small1"
                        *ngIf="item['installmentDueObj'] && item['installmentDueObj']['date']">
                        {{'Next_installment_due_by' | translate}}
                        {{item['installmentDueObj']?(item['installmentDueObj']['date'] | moment : 'DD MMM YYYY'):''}}
                      </div>
                      <div class="secondary-section font-light-small1"
                        *ngIf="!item['installmentDueObj'] || !item['installmentDueObj']['date']">-</div>
                    </ng-container>
                    <ng-container *ngIf="item.billType == 'SB'">
                      <div class="primary-section">Paid</div>
                      <div class="secondary-section font-light-small1">Paid on
                        {{item['billDate'] | moment : 'DD MMM YYYY'}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="bill-table-cell" style="grid-area: g;">
                  <div class="view-type-action">
                    <button class="infolight-font-primary-button view-button"
                      (click)="getPatientBillDetails(item, 'VIEW')">{{'View'|translate}}</button>
                    <button class="primary-button pay-button" *ngIf="item.showPayButton"
                        (click)="getPatientBillDetails(item, 'PAY')">{{'Pay_now'|translate}}</button>
                  </div>
                </div>
                <div class="click-overlay" (click)="getPatientBillDetails(item, 'VIEW')"></div>
              </div>
              <tr *ngIf="!allBills || allBills.length == 0">
                <td colspan="6" class="no-data-td font-primary-default">{{'No_bills'|translate}}</td>
              </tr>
            </div>
          </div>
          <div class="billing-card-pagination">
            <app-table-pagination [currentPage]="allBillsPagination.currentPage"
              [totalRecords]="allBillsPagination.totalRecords"
              [recordsPerPage]="allBillsPagination.recordsPerPage" (navigateTable)="navigateTable($event)"
              (changePerPageCount)="changePerPageCount($event, 'ALL')"></app-table-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-patient-bill-details *ngIf="isOpenPatientBillDetails" [selectedBill]="selectedBill"
  [selectedBillDetails]="selectedBillDetails"
  (close)="closePatientBillDetails($event)"></app-patient-bill-details>
<app-payment-checkout [showModalView]="true" *ngIf="isCheckout" (close)="closeCheckout()"></app-payment-checkout>