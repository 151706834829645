import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { ClinicalformComponent } from '../clinicalform/clinicalform.component';

@Component({
  selector: 'app-appointment-upload-questionnarie',
  templateUrl: './appointment-upload-questionnarie.component.html',
  styleUrls: ['./appointment-upload-questionnarie.component.scss'],
})
export class AppointmentUploadQuestionnarieComponent implements OnInit {
  @Input() selectedAppt: any;
  @Output() close: EventEmitter<any>;
  @ViewChild('clinicalForm') clinicalForm: ElementRef;
  public params: any;
  public showPreview: boolean;
  public _topicsToPreview: Array<any>;
  public formContent: Array<any>;
  constructor(private apptService: AppointmentService) { 
    this.close = new EventEmitter<any>();
    this.params = {
      patient: null,
      directiveCode: null,
      preview: false,
      enableDraft: false,
      taskApplicableStatus: null,
      editNote: null,
      addToNote:null,
      concept: {
        noteContent: {}
      }
    }
  }

  ngOnInit(): void {
    this.getQuestionnarieForm();
  }
  public closeModal() {
    this.close.emit();
  }
  private getQuestionnarieForm() {
    console.log(this.selectedAppt);
    let payload = {
      facilityId: this.selectedAppt.facilityId,
      patientId: this.selectedAppt.uhid,
      refNo: this.selectedAppt.appointmentId,
      category: "VIDEOCONS"
    }
    this.apptService.getFormContent(payload).subscribe(data=>{
      this.params['concept']['noteContent'] = {
        topics: data.formContent.topics
      }
      this.formContent = data;
    })
  }
  public togglePreview() {
    this.showPreview = !this.showPreview;
  }
  public saveResponse(event) {
    console.log(event);
    let d = event['response'];
    let body = {
      status: event.save ? 'COMP' : 'DRFT',
      patientId: this.selectedAppt.uhid,
      facilityId: this.selectedAppt.facilityId,
      directiveCode: this.formContent['directiveCode'],
      conceptCode: this.formContent['conceptCode'],
      refNo: this.selectedAppt.appointmentId,
      form: {
        clinicalNote: (Array.isArray(d.data) && d.data.length >= 1 && d.data[0].noteContent) ? d.data[0].noteContent : null,
        topicOutcomes: (Array.isArray(d.topicOutcomes) && d.topicOutcomes.length > 0) ? d.topicOutcomes : null,
        outcomes: (Array.isArray(d.outcome) && d.outcome.length > 0) ? d.outcome : null
      }
    };
    this.apptService.saveFormContent(body).subscribe(_ => {
      this.closeModal();
    })
  }
}