import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkOtherMobileComponent } from './link-other-mobile.component';
import { PipesModule } from '../pipes/pipes.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../directive/directive.module';
import { PartitionInputModule } from '../partition-input/partition-input.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { UpdateRelationModalModule } from '../update-relation-modal/update-relation-modal.module';



@NgModule({
  declarations: [LinkOtherMobileComponent],
  imports: [
    CommonModule, PipesModule, PhoneInputModule, FormsModule, ReactiveFormsModule, DirectiveModule, PartitionInputModule, PersonAvatarModule, UpdateRelationModalModule
  ],
  exports: [
    LinkOtherMobileComponent
  ]
})
export class LinkOtherMobileModule { }
