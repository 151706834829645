import { Directive, Input, HostListener, ElementRef, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { } from 'events';

@Directive({
  selector: '[appPopover]'
})
export class PopoverDirective implements OnInit {

  @Input() host: string;
  @Input() context: string;
  @Input() multiselect: boolean;
  @Input() triggerEvent: 'click' | 'hover' = 'click';
  @Output() emitter: EventEmitter<boolean>;
  private popover
  constructor(private elementRef: ElementRef) {
    this.emitter = new EventEmitter<boolean>();
  }

  ngOnInit() {
    setTimeout(_ => this.popover = document.getElementById(this.host), 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['host'] && !changes['host'].firstChange)
      this.popover = changes['host']['currentValue'] ? document.getElementById(this.host) : null;
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {

    const targetElement = event.target as HTMLElement;
    if (targetElement && document.getElementById('popover-head') && document.getElementById('popover-head').contains(targetElement))
      return;
    if (this.multiselect && targetElement && this.popover && this.popover.contains(targetElement))
      return;

    if (this.popover && targetElement && this.elementRef.nativeElement && !this.elementRef.nativeElement.contains(targetElement))
      this.clickElsewhere();
  }


  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (this.triggerEvent == 'click')
      this.handleEvent(event);
  }

  @HostListener('mouseenter', ['$event'])
  mouseEnter(event) {
    if (this.triggerEvent == 'hover') {
      console.log('hover', event);
      this.handleEvent(event);
    }
  }

  @HostListener('mouseleave', ['$event'])
  mouseLeave(event) {
    // if (this.triggerEvent == 'hover')
    //   this.clickElsewhere();
  }

  private clickElsewhere() {
    this.popover.classList.remove("display");
  }

  private handleEvent(event) {
    if (!this.popover)
      return;
    event.preventDefault();
    event.stopPropagation();
    this.emitter.emit(true);
    this.popover.classList.add("display");
    this.popover.style.position = "fixed";
    setTimeout(() => {
      this.popover.style.left = this.calculateLeft(event) + 'px';
      this.popover.style.top = this.calculateTop(event) + 'px';
    }, 20);
  }

  private calculateLeft(event): number {
    if (event.clientX + this.popover.clientWidth > window.innerWidth)
      return event.clientX - (this.popover.clientWidth + 5);
    return event.clientX;
  }
  private calculateTop(event): number {
    if (event.clientY + this.popover.clientHeight > window.innerHeight)
      return event.clientY - (this.popover.clientHeight + 5);
    return event.clientY;
  }
}
