import { trigger, transition, style, animate } from '@angular/animations';

export const enterAnimation = trigger(
    'enterAnimation', [
        transition(':enter', [
            style({ transform: 'translateX(100%)', opacity: 0, backgroundColor: 'transparent' }),
            animate('500ms', style({ transform: 'translateX(0)', opacity: 1, backgroundColor: 'transparent' }))
        ]),
        transition(':leave', [
            style({ transform: 'translateX(0)', opacity: 1, backgroundColor: 'transparent' }),
            animate('500ms', style({ transform: 'translateX(100%)', opacity: 0, backgroundColor: 'transparent' }))
        ])
    ]
);