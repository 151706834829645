import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpsRatingComponent } from './nps-rating.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';



@NgModule({
  declarations: [
    NpsRatingComponent
  ],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, CustomInputGroupModule
  ],
  exports: [
    NpsRatingComponent
  ]
})
export class NpsRatingModule { }
