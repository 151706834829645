<div id="home-care-services">
  <div class="modal-container">
    <div id="home-care-activity" *ngIf="true">
      <div class="model-container1">
        <div class="acitivity" [ngClass]="{'disabled-footer': !(!activity.disableCall || disableCall)}">
          <div class="home-care-activity-header border-bottom">
            <div class="font-primary-subtitle bold" *ngIf="currentservice.title && isArray(currentservice.title)">
              {{ currentservice.title | translate : 'LOCALE': 'label' }}
            </div>
            <div class="font-primary-subtitle bold" *ngIf="currentservice.title && !isArray(currentservice.title)">
              {{ currentservice.title | translate }}
            </div>
            <div class="float-right closeicon pointer" (click)="closemodel()"> <img src="./assets/icons/close_black.svg"> </div>
          </div>
          <div class="search-bar">
            <div class="search border-bottom">
              <div class="input-group ">
                <div class="icon">
                  <img class="type-image" src="./assets/icons/Book_new/search.svg" />
                </div>
                <div class="input">
                  <input type="text" placeholder="{{ 'search_placeholder_schedule' | translate }}" [(ngModel)]="searchString" />
                </div>
              </div>
            </div>
          </div>
          <div class="activity-content">
            <div class="card border-0"
              *ngFor="let location of currentserviceoptions | searchFilter : searchString : ['title', 'subtitle'] : null : 'list'">
              <div class="card-header border-bottom  bold" *ngIf="location.group && location.group !=''">
                {{location.group}}
              </div>
              <!-- <div class="card-body"> -->
                <ul class="list-group list-group-flush font-primary-subtitle" >
                  <li class="list-group-item card-content border-bottom"  *ngFor="let content of location.list" >
                    <div class="image" *ngIf="isdoctorconsultation">
                      <!-- <app-practitioner-avatar [url]="item.resourceAvatarURL"></app-practitioner-avatar> -->
                      <img src={{content.img}}>
                    </div>
                    <div class="title_bar">
                      <span *ngIf="isdoctorconsultation">
                        <div class="title">{{content.title}}</div>
                        <div class="sub-title font-light-small">
                          <span>{{content.subtitle}}</span>
                        </div>
                      </span>
                      <span *ngIf="!isdoctorconsultation">
                        {{content.title}}
                      </span>
                    </div>
                    <div class="cost" *ngIf="!islocation">
                      <span class="unit font-light-small">{{content.cost}} {{currency}}</span>
                    </div>
                  </li>
                </ul>
              <!-- </div> -->
            </div>
            <app-nodata *ngIf="!currentserviceoptions || currentserviceoptions.length == 0"></app-nodata>
          </div>
          <div class="home-care-activity-footer border-top" *ngIf="!activity.disableCall || disableCall">
            <button class="primary-button" (click)="callus()">{{'btn_call_us' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
