<div class="vaccination-main-wrapper">
    <div class="vaccination-form-container">
        <div *ngIf="state == 'INIT'">
            <div class="event-container">
                <div class="event-wrapper">
                    <div *ngIf="languageList && languageList.length > 1" class="cursor-pointer lang-wrapper" (click)="switchLanguageAction()">
                        <img src="assets/icons/header/world.svg" />
                        <!-- <span>{{ isSelected }}</span> -->
                        <!-- <img class="arrow-img" src="assets/icons/arrow.svg"> -->
                    </div>
                    <div class="header-wrapper">
                        <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
                        <p class="event-name">{{'Vaccination_Registration' | translate : 'LABELS' : null : lang}}</p>
                        <img class="event-icon" src="assets/icons/task/mobile.svg" alt="">
                    </div>
                    <div *ngIf="!authSurveyMessage" class="content-wrapper">
                        <p class="title">{{'Enter_National_ID' | translate : 'LABELS' : null : lang}}</p>
                        <input class="text-type-width" [(ngModel)]="nationalId" type="text">
                    </div>
                    <div *ngIf="authSurveyMessage" class="content-wrapper">
                        <p class="title">{{'Enter_OTP' | translate : 'LABELS' : null : lang}}</p>
                        <input class="text-type-width" [(ngModel)]="otp" type="number">

                        <p class="msg">{{authSurveyMessage}}</p>
                    </div>
                </div>
                <div class="event-footer">
                    <button *ngIf="!authSurveyMessage" [disabled]="!nationalId" (click)="startSurvey()"
                        class="primary-button">{{'btn_submit' | translate : 'LABELS' : null : lang}}</button>
                    <button *ngIf="authSurveyMessage" [disabled]="!otp" (click)="authSurvey()"
                        class="primary-button">{{'btn_submit' | translate : 'LABELS' : null : lang}}</button>
                </div>
                <div class="actions" *ngIf="switchLanguage">
                    <ul class="list-group">
                      <li class="list-group-item"  *ngFor="let item of languageList">
                        <input type="radio" name="label" [checked]="lang === item.localeCode ? true: false"
                          (change)="onItemChange(item)" [id]="'lang'+item.localeCode">
                        <label class="label" [for]="'lang'+item.localeCode">{{item.localName}} </label>
                      </li>
                    </ul>
                  </div>
            </div>
        </div>
        <div class="form-container" *ngIf="state === 'FORM' && clinicalParams">
            <div class="header">
                <p class="title">{{clinicalParams['concept']['conceptLabelLocale'] || clinicalParams['concept']['label'] | translate:'LOCALE':'text': lang}}</p>
                <img src="./assets/icons/close_white.svg" alt="" (click)="state='INIT'">
            </div>
            <app-clinicalform [params]="clinicalParams" (response)="createEvent($event)">
            </app-clinicalform>
        </div>
        <div *ngIf="state == 'COMPLETE'" class="complete-wrapper">
            <div class="header-wrapper">
                <img class="logo" src="assets/images/signin/logo_grey.png" alt="">
            </div>
            <div class="content-wrapper">
                <div class="success-wrapper">
                    <div class="success-bg">
                        <img class="tick-image" src="assets/icons/task/done.svg" alt="">
                        <p class="title-txt">{{content['title']}}</p>
                    </div>
                    <p class="sub-title-txt">{{content['subTitle']}}</p>
                </div>
            </div>
        </div>
    </div>
</div>