import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicChooseSlotComponent } from './express-clinic-choose-slot.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';

@NgModule({
  declarations: [ExpressClinicChooseSlotComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, PractitionerAvatarModule, PersonAvatarModule
  ],
  exports:[ExpressClinicChooseSlotComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ExpressClinicChooseSlotModule { }
