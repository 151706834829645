import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFacilityComponent } from './list-facility.component';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';

@NgModule({
  declarations: [ListFacilityComponent],
  imports: [
    CommonModule, PipesModule, DirectiveModule
  ],
  exports: [
    ListFacilityComponent
  ]
})
export class ListFacilityModule { }
