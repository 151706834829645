import { Component, OnInit, Input, forwardRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ALL_COUNTRIES } from '@const';
const COUNTER_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true
};
@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [COUNTER_CONTROL_ACCESSOR],
  host: {
    '(document:click)': 'hideDropdown($event)',
  },
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor{
  @Input() defaultCountry: string;
  @Input() formControlName: string;
  @Input() readonly: boolean;
  @Input() direction: 'row' | 'column' = 'column'
  @Input() masked: boolean = false;
  @Input() maskedNumber: string;
  @Output() phoneInput: EventEmitter<any> = new EventEmitter();
  public country: string;
  public currentCountryCode: string;
  public phonenumber: any;
  private allCountries: Array<any>;
  public newCountry: Array<any>;
  private value: any;
  public showDropdown: boolean;
  public hideCountry: boolean;
  public countryName: string;
  public onTouch: Function;
  public onModelChange: Function;
  phoneComponent: ElementRef;
  public selectedData;
  constructor(phoneComponent: ElementRef) {
    this.phoneComponent = phoneComponent;
  }

  ngOnInit(): void {
    this.allCountries = ALL_COUNTRIES
    this.initializeItems();
  }
  public toggleDropDown() {
    if (this.readonly) return;
    this.showDropdown = !this.showDropdown;
  }
  public hideDropdown(event: Event) {
    if (!this.phoneComponent.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }
  public toggleCountry(item) {
    this.country = item.iso;
    this.countryName = item['name'];
    let regStr = '+' + this.currentCountryCode;
    let newStr = '+' + item.dialCode;
    this.phonenumber = this.phonenumber.replace(regStr, newStr);
    this.hideCountry = false;
    if (this.phonenumber.length < newStr.length) {
      this.phonenumber = newStr;
    }
    this.currentCountryCode = item.dialCode;
    this.value = this.phonenumber;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
    this.selectedData = {
      countryCode: this.currentCountryCode,
      phone: this.phonenumber.split('+' + this.currentCountryCode)?.[1],
      fullPhone:this.phonenumber
    }
    this.phoneInput.emit(this.selectedData);
  }
  private initializeItems() {
    this.newCountry = [];
    for (var i = 0; i < this.allCountries.length; i++) {
      var c = this.allCountries[i];
      this.newCountry.push({
        name: c[0],
        iso: c[1],
        dialCode: c[2],
        priority: c[3] || 0,
        areaCodes: c[4] || null
      });
    }
    this.newCountry = this.newCountry.sort((a, b)=>{
      return a.priority - b.priority;
    });
    if (this.defaultCountry) {
      this.country = this.defaultCountry;
      let val = this.newCountry.find(country => {
        return country['iso'] == this.country;
      });
      this.phonenumber = val ? val['dialCode'] : this.phonenumber;
      this.countryName = val['name'];
    } else {
      this.country = 'in'
      this.phonenumber = '+91'
      this.countryName = 'India';
    }
  }
  public getItems() {
    let val= this.newCountry.find(country => {
      return this.phonenumber.startsWith(('+'+country.dialCode))
    });
    if (!val) {
      this.hideCountry = true;
      this.currentCountryCode = '';
      this.countryName = '';
    }
    this.country = val ? val['iso'] : this.country;
    this.value = this.phonenumber;
    if (this.onModelChange) {
      this.onModelChange(this.value)
    };
    this.extractCountryCode();
    this.selectedData = {
      countryCode: this.currentCountryCode,
      mobile: this.phonenumber.split('+' + this.currentCountryCode)?.[1],
      fullPhone:this.phonenumber
    }
    this.phoneInput.emit(this.selectedData);
  }
  private extractCountryCode() {
    let temp = this.newCountry.filter(country => {
      return this.phonenumber.startsWith(('+' + country.dialCode))
    });
    if (temp.length == 1) {
      this.currentCountryCode = temp[0].dialCode
      this.country = temp[0].iso;
      this.countryName = temp[0]['name'];
      this.hideCountry = false;
    }
  }
  public isPhoneNumber(evt: any) {
    let charCode = evt['which'] ? event['which'] : event['keyCode'];
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode!=43) {
      return false;
    } else {
      return true;
    }
  }
  writeValue(value: string) {
    this.value = value;
    this.phonenumber = this.value;
    this.getItems();
  }
  registerOnChange(fn:Function) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: Function) {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
