import { Component, OnInit, Input , OnDestroy, ChangeDetectorRef} from '@angular/core';
import { ModalService} from '../services/modal.service';
import * as moment from 'moment';
import { MyHammerConfig } from '../app.module';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { APPTCONST , NOTIFICATION_ICON, FILTERS, NOTIFY_FILTERS} from '@const';
import {AppointmentService} from '../services/appointment.service';
import { UserService } from '../services/user.service';
import { AlertService } from '../services/alert.service';
import { APPTACTION, APPTFLAGS, APPTVIEWTYPE, CHECKINSTATUS, } from '@type';
import * as _ from 'lodash';

@Component({
  selector: 'app-notification-dtl',
  templateUrl: './notification-dtl.component.html',
  styleUrls: ['./notification-dtl.component.scss']
})
export class NotificationDtlComponent implements OnInit, OnDestroy{

  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: string = '';
  // public notifications: Array<any> = [];
  public alive: boolean = true;
  public myAlerts={};
  public messages:any={
    events:  {},
    alerts:  {}
  };
  public notificationIcon:any = NOTIFICATION_ICON
  public readonly APPTCONST= APPTCONST;
  private messagesOriginal:any;
  public filterdata: any;
  public viewType: APPTVIEWTYPE = 'MYALERT';
  public openApptDtl: boolean = false;
  public selectedAppointment: any;
  public isManageIdentities: boolean;

  constructor(private modalService: ModalService, private appointmentService: AppointmentService, private user: UserService, private alerts: AlertService, public cdf:ChangeDetectorRef){
    // this.notifications = notificationList
  }

  ngOnInit(): void {
    this.filters = _.cloneDeep(NOTIFY_FILTERS) 
    // this.filters = NOTIFY_FILTERS// FILTERS;
    this.filterdata =  this.filters[0]['options'] //FILTERS;
    this.initSwipe();
    // this.filters = [
    //   { key: 'REM', value: 'Reminders' , checked: false},
    //   { key: 'UPD', value: 'Updates' , checked: false},
    //   { key: 'ANC', value: 'Announcements', checked: false }
    // ];
    // this.initFilter();
    // this.getMyAlerts();
    this.getUpcomingAppointments();
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
  }

  public initFilter(){
    let count = this.filters.filter(ele => {return ele.checked})
    this.selectedFilter = this.filters && count && (this.filters.length == count.length || count.length == 0)  ? 'All' : count.length.toString()
    console.log("==== selectedFilter ===", count, count.length, "===>", this.filters.length  , this.selectedFilter)
    this.filterdata = this.filters
  }

  public chooseFilter(ev, item){
    console.log("==== ev ===", ev)
    item['checked'] = ev.target.checked
    this.initFilter();
  }

  public openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  public getAgo(endDate){
    if(endDate){
        return moment(endDate).fromNow();
    }else {
        return endDate
    }
  }

  public initSwipe(){
    const hammerConfig = new HammerGestureConfig();
    const hammer = hammerConfig.buildHammer(document.documentElement);
    fromEvent(hammer, 'swipe')
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
      });
  }

  swiper(res, id){
    // console.log("===touchStart swipe 123====", res, id)
    let mainDiv = document.getElementById('gesture-'+id)
    let dc = document.getElementById('hide-'+id);
    let vcard = document.getElementById('vcard-'+id)
    if (res.deltaX > 0) {
      mainDiv.classList.add('rm-margin');
      dc.classList.add('show');
      vcard.classList.add('r-swipe');
    }
    else {
      mainDiv.classList.remove('rm-margin');
      dc.classList.remove('show');
      vcard.classList.remove('r-swipe');
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  remove(item) {
    console.log('==rm', item);
  }

  public closeFilter(){
    this.isFilterOpen = false;
  }


  public getUpcomingAppointments() {
    this.user.getUpcomingAppointments().subscribe(data => {
      this.getAppointmentCharges(data.data.appointments);
      this.getMyAlerts();
    })
  }

  private getAppointmentCharges(appts) {
    this.appointmentService.handleChargeDataForUpcomingAppointments(appts).subscribe((respAppts) => {
      // console.log("= respAppts===", respAppts)
      // this.appointmentService.setUpcomingAppointments(respAppts);
    });
  }

  private getMyAlerts(){
    this.alerts.getAlerts(true).subscribe(data => {
      setTimeout(_=>{
        this.getAlerts();
      },1000)
    })
  }

  public async getAlerts(){
    this.messages=this.alerts.getMyAlerts();
    this.messagesOriginal = this.alerts.getMyAlerts();
    let tmpPres=[];
    if(this.messages.alerts && this.messages.alerts.length>0){
      // this.notifications = this.messages.alerts
      // console.log('alerts with patient info--',this.messages.alerts)
    }
    if(this.messages.events && this.messages.events.length>0){
      // this.notifications = this.messages.alerts
      // console.log('alerts with patient info--',this.messages.events)
    }
  }

  public updateAlertStatus(alert, i, type: string) {
    if (alert.notificationReadStatus) return
    var ids;
    if(alert['_sameEvents'] && alert['_sameEvents'].length>0){
      ids=alert['_sameEvents'].map((e)=>e.id)
    }else
    ids=alert.id;
    this.alerts.updateAlertStatus(ids,'readstatus').subscribe(data => {
      if(data['status']=="SUCCESS"){
        if(type == 'alert'){
          this.messages.alerts[i].notificationReadStatus =true;
        }else if(type == 'event'){
          this.messages.events[i].notificationReadStatus =true;
          this.messages.events[i]['_sameEvents'].forEach((s)=>s['notificationReadStatus']=true)
        }
      }
    })
  }

  public deleteAlert(alert,i,type:string){
    let tmpOriginal=this.alerts.getOriginalAlerts();
    let ids=[];
    if(alert.msgId){
      tmpOriginal.forEach((o)=>{
        if(alert.msgId==o['msgId'])
          ids.push(o['id'])
      })
    }else 
    ids.push(alert.id)
    // console.log('delete alert',alert,i,type,this.messages.alerts,ids)
    this.alerts.updateAlertStatus(ids,'DELETE').subscribe(data => {
      // console.log('delete data',data)
      if(data['status']=="SUCCESS"){
        if(type == 'alert'){
          this.messages.alerts.splice(i,1);
          this.messagesOriginal.alerts.splice(i,1);
          this.cdf.detectChanges();
        }
      }
        this.getAlerts();
    })
  }

  public dimissAll(){
    var ids = [];
    var messageTemp = this.alerts.getMyAlerts();
    messageTemp.alerts.filter(res =>{
      ids.push(res.id)
        return res;
    })
    messageTemp.events.forEach((e)=>{
      if(e['_sameEvents'] && e['_sameEvents'].length>0){
        e['_sameEvents'].forEach((s)=>{
          ids.push(s['id'])
        })
      }else
        ids.push(e['id'])
    })
    messageTemp.events.filter(res =>{
      // ids.push(res.id)
        return res;
    })
    this.alerts.updateAlertStatus(ids,'DELETE').subscribe(data => {
      if(data['status']=="SUCCESS"){
        this.messages.alerts = [];
        this.messages.events =[];
        this.messagesOriginal.alerts = [];
        this.messagesOriginal.events = [];

        }
    })
  }

  public openAppointment(alert) {
    this.selectedAppointment = alert.upcomingAppointments
    this.viewAppt();
  } 

public viewAppt(){
  this.openApptDtl = true;
  this.modalService.open('notify-calendar-appointment-details-modal');
}

closeApptDetail(){
  this.openApptDtl = false;
}

public navigateToDetail(alert){
  console.log('alert detail---',alert)
  switch (alert['updateCategory']) {
    case 'PRESCRIPTION':
      // this.navigateToPres(alert)
      break;
    case 'REPORT':
      // this.navigateToReport(alert)
      break;
    case 'PATLINK':
      this.openModal('notify-manage-identity-modal');
      break;
  }
}

public openModal(id: string) {
  if (id == 'notify-manage-identity-modal') {
    this.isManageIdentities = true;
  }
  this.modalService.open(id);
}

public closeManageIdentities() {
  this.isManageIdentities = false;
}

public filterPackages(event){
  // console.log("===== filterPackages", event)
  if(event && event.length > 0)
    this.filterdata = event[0]['options']
}
  
  //undefined functions
  public openApptView(ev){}
  public openExpressView(ev){}
  public openuploadQuestionnarie(ev){}
  public openuploadDocs(ev){}

}

export const notificationList = [
  {
    type: "UPDATE",
    name: "Service_request_update",
    dttm: "2023-05-30 20:04",
    content: "Your request has been closed"
  },
  {
    type: "REMINDER",
    name: "Upcoming_appointment",
    dttm: "2022-09-30 11:04",
    content: "",
    practitionerId: "U17030",
    specialtyCode: "CRDC",
    facilityId: "JA",
    personName: 'John Doe',
    appointmentDateTime : "2023-06-30 09:00"
  },
  {
    type: "ANNOUNCEMENT",
    name: "New_announcement",
    dttm: "2022-09-30 15:04",
    content: "Welcome_the_guests_of_our_strategic_partners"
  },
  {
    type: "ERROR",
    name: "Payment_unsucessful",
    dttm: "2023-05-31 12:04",
    content: "We_are_unable_to_process_your_payment.Try contacting your bank if amount is deducted"
  },
  {
    type: "UPDATE",
    name: "New_report",
    dttm: "2022-09-30 12:04",
    content: "New_radiology_report_available_for_James"
  },
  {
    type: "REMINDER",
    name: "Upcoming_appointment",
    dttm: "2022-09-30 11:04",
    content: "",
    practitionerId: "U17030",
    specialtyCode: "CRDC",
    facilityId: "JA",
    personName: 'John Doe',
    appointmentDateTime : "2023-06-30 09:00"
  },
  {
    type: "ANNOUNCEMENT",
    name: "New_announcement",
    dttm: "2022-09-30 15:04",
    content: "Welcome_the_guests_of_our_strategic_partners"
  }
]
