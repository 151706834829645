<div class="avatar-container {{additionalClass}}">
    <ng-container *ngIf="isImage">
      <div class="avatar-content">
        <img [src]="imgUrl" alt="" class="person-image type-image">
      </div>
    </ng-container>
    <ng-container *ngIf="isInitial">
      <div class="avatar-content font-primary-title">
        {{ imgUrl | personinitial}}
      </div>
    </ng-container>
    <ng-container *ngIf="isDummy">
      <div class="avatar-content dummy">
        <!-- <img src="assets/icons/bookappt/doctor_grey.svg" alt="" class="person-image"> -->
        <img src="assets/icons/doctor_blue.svg" alt="" class="person-image type-image">
      </div>
    </ng-container>
  </div>