import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CARDARRAY } from '@const';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-manage-saved-cards',
  templateUrl: './manage-saved-cards.component.html',
  styleUrls: ['./manage-saved-cards.component.scss']
})
export class ManageSavedCardsComponent implements OnInit{
  @Output() close: EventEmitter<any>;

  public savedCards: Array<any> = []
  public cardMapping: any = {}
  public fetchingCards: boolean = false;
  public showDeleteModal: boolean = false;
  public selectedCard: any = null

  constructor(private payment: PaymentService, private cdf: ChangeDetectorRef) {
    this.close = new EventEmitter<any>();
  }
  ngOnInit(): void {
    this.cardMapping = CARDARRAY
    this.getSavedCards()
  }

  public closeModal() {
    this.close.emit()
  }

  private getSavedCards() {
    this.fetchingCards = true;
    this.payment.getSavedCards().subscribe(data => {
      let savedCards = data.savedCards
      this.savedCards = this.payment.getCardArray(savedCards)
      this.fetchingCards = false;
      this.cdf.detectChanges()
      console.log('savedCards', data, this.savedCards)
    }, err => {
      this.fetchingCards = false;
      this.cdf.detectChanges()
    })
  }

  public deleteCard(item) {
    this.selectedCard = item;
    this.showDeleteModal = true
  }

  public closeDelete(confirm) {
    this.showDeleteModal = false
    if (confirm) {
      this.confirmDelete();
    }
  }

  private confirmDelete() {
    this.fetchingCards = true;
    this.payment.confirmDeleteCard(this.selectedCard).subscribe(data => {
      this.selectedCard = null
      this.getSavedCards();
    }, err => {
      this.fetchingCards = false;
      this.selectedCard = null
      this.cdf.detectChanges()
    })
  }
}
