<div class="form-wrapper">
  <div class="task-header border-bottom" *ngIf="(formViewType=='category')">
      <div class="task-details">
        <div class="toggle-button" (click)="toggleSideNav()" [ngClass]="{flipped: closedSideNav}">
          <img src="assets/icons/task-form/menu.svg"/>
        </div>
        <div class="task-title bold">{{taskDesc}}</div>
      </div>
      <div class="task-actions buttons-ungrouped">
        <div class="save-draft action-button" *ngIf="currentStatus">
          <button class="primary-button-outline" (click)="updateTask(currentStatus,null, currentStatus, true, true)">
            <img src="assets/icons/task-form/floppy_disk.svg" />
            <span class="text">{{'Save_draft' | translate}}</span>
          </button>
        </div>
        <div class="save-draft action-button" *ngFor="let a of taskAction;let lt = last">
          <button class="primary-button" (click)="updateTask(a,null, a, false, false)"  [ngClass]="{disabled: !isFormFilled}">
            <img src="assets/icons/task-form/paper_plane.svg" />
            <span class="text" *ngIf="a['actionLocale']">{{a['actionLocale'] | translate:'LOCALE':'text'}}</span>
            <span class="text" *ngIf="!a['actionLocale']">{{a | translate:'TASKACTION'}}</span>
          </button>
        </div>
      </div>
      <div class="task-actions buttons-grouped" *ngIf="currentStatus">
        <div class="dropdown-group" id="clinicalform-dropdown-menu">
          <button class="dropdown-toggle-button primary-button" type="button" (click)="toggleActionList()">
            {{'Actions' | translate}}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu-list" [ngClass]="{visible: actionOpen}">
            <li>
              <button (click)="updateTask(currentStatus,null, currentStatus, true, true)">{{'Save_draft' | translate}}</button>
            </li>
            <li *ngFor="let a of taskAction;let lt = last">
              <button [ngClass]="{disabled: !isFormFilled}" (click)="updateTask(a,null, a, false, false)">{{a['actionLocale'] || a['actionDescription'] || a['name'] | translate:'LOCALE':'text'}}</button>
            </li>
          </ul>
        </div>
        <!-- <div class="save-draft action-button" *ngIf="currentStatus">
          <button class="primary-button-outline" (click)="updateTask(currentStatus,null, currentStatus, true, true)">
            <img src="assets/icons/task-form/floppy_disk.svg" />
            <span class="text">{{'Save_draft' | translate}}</span>
          </button>
        </div>
        <div class="save-draft action-button" *ngFor="let a of taskAction;let lt = last">
          <button class="primary-button" (click)="updateTask(a,null, a, false, false)" [disabled]="!isFormFilled">
            <img src="assets/icons/task-form/paper_plane.svg" />
            <span class="text">{{a['actionLocale'] || a['actionDescription'] || a['name'] | translate:'LOCALE':'text'}}</span>
          </button>
        </div> -->
      </div>
    </div>
    <div class="container" [ngClass]="{'category-view-type': (formViewType=='category'), 'container-height-sidebar': ((topics|
      conditionaltopic).length >1), 'container-height-breadcrumb':!((topics| conditionaltopic : null: _triggerPoint).length >1)}">
      <ng-container *ngIf="formViewType=='category'">
        <div class="available-topics-sidebar-overlay" [ngClass]="{'mobile-nav-open': openMobileNav}" (click)="toggleMobileNav()"></div>
        <div class="available-topics-sidebar" [ngClass]="{'side-nav-closed': closedSideNav, 'mobile-nav-open': openMobileNav}">
          <ng-container *ngFor="let topic of topics|conditionaltopic: topics.length : _triggerPoint | clinicalformMandatoryFilter:filterMandatory; let ti = index">
            <ng-container *ngIf="!topic.subTopic && topic['sections'] && topic.sections.length">
              <div class="topic-name cursor-pointer" [ngClass]="{activeTopic: topic['_isOpen'], bold:topic['_isOpen'], 'disabled-topic': (ti>currentTopicIndex && (currentTopicIndex+1 != ti || !isTopicFilled))}"
                (click)="toggleTopics(ti, true)">
                <div class="pointer">
                  <img [ngClass]="{showArrow:topic['_isOpen']}" src="assets/icons/calendar/drop.svg" class="arrow-img" />
                </div>
                <div class="topic-text border-bottom">
                  <span>{{topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}</span>
                  <span>
                    <img src="assets/icons/accept.svg" class="accept-img type-image-light"
                      *ngIf="(ti < currentTopicIndex && utils.checkFormFilled(topic))" />
                    <img src="assets/icons/alert.svg" class="accept-img type-image-light"
                      *ngIf="(ti < currentTopicIndex && !utils.checkFormFilled(topic))" />
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="available-topics-content" [ngClass]="{'side-nav-closed': closedSideNav}">
      <div class="form" [ngClass]="{'scroll-form': _showPreview && isFromActivityModal , 'opd-form': _isOPDForm}">
        <form class="content" *ngIf="currentTopic && !_showPreview" id="F_Container"
          onsubmit="return false;">
          <div class="topics-header" *ngIf="concept.noteControls && concept.noteControls.topicDisplayType === 'ROADMAP'">
            <div class="topics-header-item" [ngClass]="{'open': topic['_isOpen'], 'passed':currentTopicIndex > ti}" *ngFor="let topic of topics|conditionaltopic: topics.length: _triggerPoint | clinicalformMandatoryFilter:filterMandatory; let ti = index" (click)="toggleTopics(ti)">
              <div class="header-item-icon" >
                <img src="{{currentTopicIndex > ti ? './assets/icons/form_white.svg':'./assets/icons/form.svg'}}" alt="">
              </div>
            {{topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}
            </div>
          </div>
          <ng-container *ngFor="let topic of topics|conditionaltopic: topics.length: _triggerPoint | clinicalformMandatoryFilter:filterMandatory; let ti = index">
            <ng-container *ngIf="!topic.subTopic && topic['sections'] && topic.sections.length">
              <div *ngIf="topic['displayOption'] == 'E' && (!_addToNote || (_addToNote && topic['adId'] === _currentAddendumId))"
                  [attr.id]="'F_T_'+topic.id" class="sections" [ngClass]="{minimize: !topic['_isOpen']}">
                <div class="topic-name" *ngIf="showTopicTitle && !(concept.noteControls && concept.noteControls.topicDisplayType == 'ROADMAP') &&(topic['_isOpen'] || formViewType!=='category')" (click)="toggleTopics(ti, true)">
                  <div class="topic-descs">
                    <span class="current-topic-desc bold">{{topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}</span>
                    <span class="next-topic-desc" *ngIf="formViewType=='category' && currentTopicIndex < ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ) && nextTopicIndex>0">
                      <span class="next-prefix">{{'Next' | translate}}:</span>
                      <span class="bold">{{topics[nextTopicIndex]['topicLocale'] || topics[nextTopicIndex]['topic'] | translate:'LOCALE':'text'}}</span></span>
                  </div>
                  <span> <img *ngIf="(formViewType!='category')"  [ngClass]="{'accd-img' : topic['_isOpen']}"
                      src="assets/icons/drop_arrow_grey.svg" alt=""> </span>
                  <div class="progress-bar-container" *ngIf="topic">
                    <div class="progress-bar" [ngStyle]="{width: utils.calculateProgress(topic, topics, currentTopicIndex)}" [ngClass]="{'progress-success': utils.checkFormFilled(topic)}"></div>
                  </div>
                </div>
                <div class="section-container" *ngIf="topic['_isOpen']">
                  <ng-container *ngIf="topic['multiple']">
                    <div class="multi-topic-bar">
                      <ng-container
                        *ngFor="let rTopic of (topics| conditionaltopic: topics.length : _triggerPoint) | repeatableTopics : topic.id;let ind = index;">
                        <div class="repeat-topic-name"
                          [ngClass]="{'selected': topic._selectedIndex === ind || (!topic._selectedIndex && ind === 0)}">
                          <span (click)="topic._selectedIndex = ind">{{'lbl_section'|translate:'LABELS'}} {{ind+1}}</span>
                          <span (click)="removeTopic(topic,ind)" class="margin-left">
                            <img style="width: 10px;" src="./assets/icon/close_grey.svg">
                          </span>
                        </div>
                      </ng-container>
                      <img class="type-image" src="assets/new/add_blue.svg" (click)="addAnotherTopic(topic)">
                    </div>
                    <ng-container *ngIf="!topic._selectedIndex">
                      <div *ngFor="let section of topic['sections'] | sectionfilter:topic:filterMandatory; let sectionIndex = index"
                        class="section section-type-{{section.type}}"
                        [ngClass]="{'conditional-section': section.conditional,'non-conditional-section': !section.conditional}">
                        <app-section id="{{currentTopicIndex}}#{{section['domainCode']}}" [sections]="topic['sections']"
                          [section]="section" [topic]="topic" [patient]="patient"
                          [prescriptionRules]="params['prescriptionRules']" [frequencyApplicable]="frequencyApplicable"
                          [routeApplicable]="routeApplicable" [encounter]="encounter" [topics]="topics"
                          [appointmentsList]="appointmentsList" [formViewType]="formViewType"></app-section>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="topic._selectedIndex">
                      <ng-container
                        *ngFor="let rTopic of (topics| conditionaltopic: topics.length : _triggerPoint) | repeatableTopics :
                        topic.id;let ind = index;">
                        <ng-container *ngIf="topic._selectedIndex === ind">
                          <div *ngFor="let section of rTopic['sections'] | sectionfilter:rTopic:filterMandatory; let sectionIndex = index"
                            class="section section-type-{{section.type}}"
                            [ngClass]="{'conditional-section': section.conditional,'non-conditional-section': !section.conditional}">
                            <app-section id="{{currentTopicIndex}}#{{section['domainCode']}}" [sections]="rTopic['sections']"
                              [topic]="topic" [patient]="patient" [section]="section"
                              [prescriptionRules]="params['prescriptionRules']" [frequencyApplicable]="frequencyApplicable"
                              [routeApplicable]="routeApplicable" [encounter]="encounter" [topics]="topics"
                              [appointmentsList]="appointmentsList" [formViewType]="formViewType"></app-section>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!topic['multiple']">
                    <div *ngFor="let section of topic['sections'] | sectionfilter:topic:filterMandatory; let sectionIndex = index"
                      class="section section-type-{{section.type}}"
                      [ngClass]="{'conditional-section': section.conditional,'non-conditional-section': !section.conditional}">
                      <app-section id="{{currentTopicIndex}}#{{section['domainCode']}}" [sections]="topic['sections']"
                        [section]="section" [topic]="topic" [patient]="patient"
                        [prescriptionRules]="params['prescriptionRules']" [frequencyApplicable]="frequencyApplicable"
                        [routeApplicable]="routeApplicable" [encounter]="encounter"
                        (openConsultationNote)="openConsultationNote(section)" [topics]="topics"
                        [appointmentsList]="appointmentsList" [formViewType]="formViewType"></app-section>
                    </div>
                  </ng-container>
                  <app-topic-outcomes *ngIf="topic['results']" [topic]="topic"></app-topic-outcomes>
                </div>
              </div>
              <div class="sections"
                *ngIf="topic['displayOption'] == 'V' || (_addToNote && topic['adId'] !== _currentAddendumId)"
                [attr.id]="'F_T_'+topic.id">
                <div class="topic-name" *ngIf="topic.type!=='addendum' else addendumTopicName">{{topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}</div>
                <ng-template #addendumTopicName>
                  <div class="topic-name addendum-topic-name" *ngIf="topic.sections && topic.sections[0]">
                    <div class="name">{{topic.sections[0].key || topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}</div>
                    <div class="info">
                      {{'lbl_added_by'|translate:'LABELS'}}
                      <span>{{topic.sections[0].authorName}}</span> on
                      <span>{{topic.sections[0].authoredDate | moment:'MMM DD YYYY, h:mm a'}}</span>
                    </div>
                  </div>
                </ng-template>
                <app-formpreview [topics]="[topic]"></app-formpreview>
              </div>
            </ng-container>
          </ng-container>
        </form>
        <div *ngIf="_showPreview" class="c-f-a-m-form-preview">
          <div class="topic-name">{{'lbl_preview'|translate:'LABELS'}}</div>
          <app-formpreview [topics]="_topicsToPreview"></app-formpreview>
        </div>
      </div>
      <div class="c-f-a-m-footer" *ngIf="isFromActivityModal||true && ( formViewType!='category'
              || topics.length > 1)" [ngClass]="{'category-view-type': (formViewType=='category')}">
        <div class="c-f-a-m-footer-left">
          <button (click)="goBack()" *ngIf="canShowBack">
            << {{'lbl_back'|translate:'LABELS'}}
            </button>
        </div>
        <div class="c-f-a-m-footer-right" >
          <ng-container *ngIf="!taskAction">
          <button class="primary-button btn-small"
            (click)="_showPreview ? goPrevFromPreview()  : navigateToPreviousTopic(currentTopicIndex-1)"
            *ngIf="!_showPreview && currentTopicIndex < ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1
              )"
            [disabled]="((currentTopicIndex- 1 )<0)">
            <!-- [disabled]="((currentTopicIndex- 1 )<0) && !_showPreview" -->
            < {{'lbl_previous'|translate:'LABELS'}}
          </button>
          <button class="primary-button btn-small" (click)="navigateToNextTopic(currentTopicIndex+1)" [disabled]="currentTopicIndex > ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 )" *ngIf="currentTopicIndex < ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ) else nextPreviewBtn">
            {{'lbl_next'|translate:'LABELS'}} >
          </button>
          <ng-template #nextPreviewBtn>
            <button class="primary-button btn-small" (click)="openPreview()" *ngIf="!_showPreview || _isOPDForm">{{'btn_preview'|translate:'LABELS'}}</button>
            <button class="primary-button btn-small" (click)="goPrevFromPreview()"
              *ngIf="_showPreview || _isOPDForm">{{'btn_edit'|translate:'LABELS'}}</button>
          </ng-template>
          <!-- <button class="primary-button btn-small" (click)="saveDraft()" *ngIf="showSaveDraftBtn">{{'btn_save_as_draft'|translate:'LABELS'}}</button> -->
          <button class="primary-button btn-small" (click)="taskApplicableStatus ? updateTask(taskApplicableStatus) : save(taskApplicableStatus)">{{'btn_submit'|translate:'LABELS'}}</button>
          <button class="primary-button btn-small" (click)="save(null,true)" *ngIf="printable && !taskApplicableStatus">{{'btn_submit_and_print'|translate:'LABELS'}}</button>
        </ng-container>
        <ng-container *ngIf="taskAction">
          <ng-container
            *ngIf="(concept.noteControls && concept.noteControls.topicDisplayType == 'ROADMAP')
            && currentTopicIndex < ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ) || ( formViewType=='category'
              && topics.length > 1); else finlTopicAction">
            <button class="primary-button btn-small" (click)="toggleTopics(currentTopicIndex -1)"
              *ngIf="!((currentTopicIndex- 1 )<0)">
              < {{'lbl_previous'|translate:'LABELS'}} 
            </button>
            <button class="primary-button-outline footer-quick-options" (click)="updateTask(currentStatus,null, currentStatus, true, true)" *ngIf="(currentTopicIndex >= ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ))">
              <img src="assets/icons/task-form/floppy_disk.svg" />
              <span class="text">{{'Save_draft' | translate}}</span>
            </button>
            <button class="primary-button btn-small" [ngClass]="{disabled: !isTopicFilled}" (click)="toggleTopics(currentTopicIndex+1)"  *ngIf="!(currentTopicIndex >= ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ))">
              {{'lbl_next'|translate:'LABELS'}} >
            </button>
            <ng-container *ngIf="(currentTopicIndex >= ((topics | conditionaltopic:topics.length: _triggerPoint).length - 1 ))">
              <ng-container *ngFor="let a of taskAction;let lt = last">
                <button class="primary-button footer-quick-options" [ngClass]="{disabled: !isFormFilled}"
                  (click)="updateTask(a,null, a, false, false)">
                  <img src="assets/icons/task-form/paper_plane.svg" />
                  <span class="text" *ngIf="a['actionLocale']">{{a['actionLocale'] | translate:'LOCALE':'text'}}</span>
                  <span class="text" *ngIf="!a['actionLocale']">{{a | translate:'TASKACTION'}}</span>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #finlTopicAction>
            <!-- <div class="event-footer"> -->
              <ng-container *ngIf="formViewType!=='category'">
                  <button class="primary-button" *ngFor="let a of taskAction"
                  (click)="updateTask(a,null, a)">{{a['actionLocale'] || a['actionDescription'] || a['name'] | translate:'LOCALE':'text'}}</button>
              </ng-container>
          <!-- </div> -->
            <!-- <button class="primary-button btn-small" (click)="updateTask(taskAction)">{{taskAction['name']}}</button> -->
          </ng-template>
        </ng-container>
        </div>
      </div>
      </div>
    </div>
</div>
<div class="save-alert" *ngIf="showSaveAlert">
  <div class="alert-card">
    <div class="card-banner">
      <img src="assets/icons/task-form/paper_plane.svg" />
    </div>
    <div class="card-content">
      <div class="content-header bold font-primary-default">
        {{'Submit_assessment' | translate}}
      </div>
      <div class="content-detail font-default-small1">
        {{'Submit_details' | translate}}
      </div>
    </div>
    <div class="card-footer border-top">
      <button class="footer-button primary-button-outline cancel" (click)="closeSaveAlert(false)">{{'Cancel' | translate}}</button>
      <button class="footer-button primary-button-outline confirm" (click)="closeSaveAlert(true)">{{'Confirm' | translate}}</button>
    </div>
  </div>
</div>
