<div id="book-appointment" class="right-nav showModal display-modal">
  <div (click)="closeModel(false)" class="empty"></div>
  <div class="flip-card" [ngClass]="{ flipped:isFlip }">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <ng-container>
          <div class="component" [ngSwitch]="state">
            <ng-container *ngSwitchCase="'CHOOSE_TYPE'">
              <div class="header border-bottom">
                <div class="font-primary-subtitle bold">{{'book_a_appt'| translate}}</div>
                <div (click)="closeModel(false)" class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
              <app-appointment-visit-type (complete)="selectVisitType($event)"></app-appointment-visit-type>
            </ng-container>
            <ng-container *ngSwitchCase="'PAYMENT_CHECKOUT'">
              <div class="payment-checkout header border-bottom">
                
                <div class="header-row" [attr.index]="_index">
                  <div class="back-btn" (click)="closePayment()">
                    <img src="./assets/icons/appt-preview/arrow.svg">
                  </div>
                  <div class="font-primary-subtitle bold" *ngIf="visitType!='VIDEO'">{{'sub_schedule_clinic_appt'|translate}}
                  </div>
                  <div class="font-primary-subtitle bold" *ngIf="visitType=='VIDEO'">{{'sub_schedule_video_appt'|translate}}
                  </div>
                </div>
                <div (click)="closeModel(false)" class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
              <div class="payment-checkout  content">
                <app-payment-checkout></app-payment-checkout>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'START_SELECTION'">
              <div class="header border-bottom">
                <div class="header-row" [attr.index]="_index">
                  <div class="back-btn" *ngIf="_index > 3 && _index < 7" (click)="navBack()">
                    <img  src="./assets/icons/appt-preview/arrow.svg">
                  </div>
                  <div class="font-primary-subtitle bold">{{this.flow[_index].name | translate}}</div>
                </div>
                <div (click)="closeModel(false)" class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'ONSPOT'" >
              <app-onspot-consult-doctor (eventRequest)="setEvent($event)" (complete)="selectVisitType($event)" (action)="selectActionType($event)" (close)="closeModel(false)" [refresh]="refresh.asObservable()" [apptInfo]="apptInfo" [apptParams]="apptParams" [ongoingAppts]="ongoingAppts">
              </app-onspot-consult-doctor>
            </ng-container>
            <ng-container *ngSwitchCase="'REFERRAL_DETAIL'">
              <div class="header border-bottom">
                <div class="font-primary-subtitle bold">
                  {{'mdl_ttl_referral_details'|translate}}
                </div>
                <div (click)="closeModel(false)" class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
              <app-referral-actions [referralDetail]="referralDetail" (referralAction)="referralAction($event)"></app-referral-actions>
            </ng-container>
            <div class="book-appt" [ngClass]="{ 'template-content' : !state }">
              <ng-template bookAppoinment></ng-template>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showTC">
          <div class="tc-modal " *ngIf="showTC">
            <div class="content">
              <div class="modal-header border-bottom">
                <div class="font-primary-subtitle bold">{{'lbl_terms_conditions'|translate}}</div>
                <div (click)="closetc(false)" class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
              <div class="modal-content font-default-small" [innerHTML]="tandc|translate:'LOCALE':'packageContent'">
              </div>
              <div class="modal-footer" (click)="closetc(true)" *ngIf="false">
                <button class="primary-button">
                  {{'agree_and_continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isManageIdentities">
          <div class="tc-modal manageidentities " *ngIf="isManageIdentities" >
            <div class="content" >
              <div class=" modal-header-identity border-bottom">
                <div class="font-primary-subtitle bold">{{'manage_identities'|translate:'LABELS'}}</div>
                <div (click)="closeManageIdentity(false)"  class="float-right closeicon pointer">
                  <img src="./assets/icons/close_black.svg">
                </div>
              </div>
              <div class="font-default-small manage-content">
                <app-manage-identities *ngIf="isManageIdentities"  ></app-manage-identities>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isRegisterPatient">
          <app-register-new-patient *ngIf="isRegisterPatient"
          (close)="closeRegPatient($event)">
        </app-register-new-patient>
        </ng-container>
      </div>

      <div class="flip-card-back">
        <div class="component" *ngIf="isFlip">
          <app-coupon [couponParam]="couponParams"  [type]="'COUPON'" [promoInput]="promoInputs" [listType]="'COUPONLIST'"
          [refresh]="refreshFlip.asObservable()" [couponInput]="couponInputs" (completeCoupon)="closeCoupon($event)" ></app-coupon>
          <!-- <app-coupon [couponParam]="couponParams" (completeCoupon)="closeCoupon($event)" [promoInput]="promoInputs" [refresh]="refreshFlip.asObservable()" [couponInput]="couponInputs"></app-coupon> -->
        </div>
      </div>
    </div>
  </div>
</div>

