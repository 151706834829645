<div class="ui input focus fluid">
  <p class="txt-label">
    <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
     <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
  <input class="input-field" value="{{section['value']}}" list="section-timeperido-datalist"
    (change)="onChange($event.target.value)" (keyup)="onChange($event.target.value)" min="{{section.minValue}}"
    [tabindex]="tabindex"
    max="{{section.maxValue}}" step="{{section.stepCounter}}" type="number" [(ngModel)]="section.value">
  <datalist name="" id="section-timeperido-datalist">
    <option value="{{item}}" *ngFor="let item of inputList"> {{item}}</option>
  </datalist>
  <select class="select-time" [(ngModel)]="section['option']" (change)="onChange($event)">
      <option value="{{option['name']}}" [selected]="option['name'] === section['option']" *ngFor="let option of section['dropdownOptions']">{{option['name']}}</option>    
  </select>
</div>
