import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import * as moment from 'moment';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent extends Type implements OnInit,SectionInterface {


  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;

  public UUID: string;

  constructor(cfs: ClinicalformService, private user: UserService) {
    super(cfs);
    this.UUID = this.user.getMoment().valueOf().toString(36);
  }

  ngOnInit() {
    if(this.section['selected']) {
      this.section['checked'] = this.section['selected'].split(',');
    }
    super.checkForMandatory(this.section);
    super.decideCheckBoxType(this.section);
    super.getValuesforSection(this.section);
    this.domainLevelDefaultValue();
  }

  listenToEvent(value, locale) {
    console.log('selected value ', value);
    if ((this.section['_noOfSelectionsAllowedBySourceDomain'] ? this.section['checked'].length >= this.section['_noOfSelectionsAllowedBySourceDomain'] : this.section['checked'].length >= this.section['noOfSelectionsAllowed']) && !this.section['checked'].includes(value))
      this.section['checked'].shift();//so that user can switch to other option without deselecting current value.
    // return;
    let valueChanged = false;
    switch (this.section['trueType']) {
      case 'radio':
        if (this.section['selected'] == value) {
          this.section['selected'] = '';
          this.section['selectedLocale'] = null
          this.section['completed'] = false;
        }
        else {
          this.section['selected'] = value;
          this.section['selectedLocale'] = [locale];
          super.complete(this.section);
        }
        valueChanged = true;
        break;
      case 'checkbox':
        this.verifyCheckedValue(value, locale);
        // if (this.section['checked'].length === 1) {
        //   this.section['selected'] = value;
        //   super.complete(this.section);
        // } else 
        if (this.section['checked'].length > 0) {
          this.section['selected'] = this.section['checked'][0];
          for (let i = 1; i < this.section['checked'].length; i++) {
            this.section['selected'] = this.section['selected'] + ',' + this.section['checked'][i];
          }
          super.complete(this.section);
        } else {
          super.incomplete(this.section);
        }
        break;
    }
  }

  // listenToEvent(value) {
  //   console.log(this.section);
  //   switch (this.section['trueType']) {
  //     case 'radio':
  //       if (this.section['selected'] == value) {
  //         this.section['selected'] = '';
  //         this.section['completed'] = false;
  //       } else {
  //         this.section['selected'] = value;
  //         super.complete(this.section);
  //       }
  //       break;
  //     case 'checkbox':
  //       this.verifyCheckedValue(value);
  //       // if (this.section['checked'].length === 1) {
  //       //   this.section['selected'] = value;
  //       //   super.complete(this.section);
  //       // } else 
  //       if (this.section['checked'].length > 0) {
  //         this.section['selected'] = this.section['checked'][0];
  //         for (let i = 1; i < this.section['checked'].length; i++) {
  //           this.section['selected'] = this.section['selected'] + ',' + this.section['checked'][i];
  //         }
  //         super.complete(this.section);
  //       } else {
  //         super.incomplete(this.section);
  //       }
  //       break;
  //   }
  //   console.log(this.section['selected']);
  // }


  // verifyCheckedValue(value: any) {
  //   console.log('checked value',value)
  //   if (this.section['checked'].includes(value)) {
  //     this.section['checked'].splice(this.section['checked'].indexOf(value), 1);
  //   } else {
  //     this.section['checked'].push(value);
  //   }
  //   console.log('checked array',this.section['checked'])
  // }

    verifyCheckedValue(value: any, locale) {
      console.log('checked value', value)
      if (!this.section['selectedLocale']) this.section['selectedLocale'] = [];
      if (this.section['checked'].includes(value)) {
        this.section['checked'].splice(this.section['checked'].indexOf(value), 1);
        this.section['selectedLocale'].splice(this.section['selectedLocale'].findIndex(locale => locale.text == value), 1);
      } else {
        this.section['checked'].push(value);
        this.section['selectedLocale'].push(locale);
      }
    }

  public domainLevelDefaultValue() {
    if (!this.section['defaultOnFormLoad'])
      return;
    var answers = this.section['domainControls']['answer']
    for (var i = 0; i < answers.length && Array.isArray(answers); i++) {
      if (!answers[i]['default'])
        continue;
      if (!this.section['selected'])
        this.section['selected'] = '';
      if (!this.section['selectedOption'])
        this.section['selectedOption'] = [];

      if (!this.section['multiSelect'] || this.section['multiSelect'] == 'false')
        this.section['selected'] = answers[i]['localName'][0]['text'];

      if (this.section['multiSelect'] || this.section['multiSelect'] == 'true')
        this.section['selected'] = this.section['selected'] + ',' + answers[i]['localName'][0]['text'];

      this.section['selectedOption'].push(answers[i]['localName'][0]['text'])
      console.log(this.section['selectedOption']);
    }

  }

}
