<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
  <div class="component">
    <div class="content">
      <ng-container>
        <div class="appt-overlap-icon">
          <img class="existing-icon" slot="icon-only" src="./assets/icons/existing.svg" />
        </div>
      </ng-container>
      <ng-container>
        <div class="appt-overlap-title">
          <h3 class="title">
            {{"cons_now_appt_overlap_title" | translate}}
          </h3>
        </div>
        <div class="appt-overlap-msg">
          <h3 class="msg">
            {{'cons_now_appt_overlap_msg'  | translate}}
          </h3>
        </div>
      </ng-container>
      <ng-container>
        <div class="button-bar">
          <button class="primary-button-outline" (click)="back()">{{'cons_now_appt_btn_goBack'  | translate}}</button>
          <button class="primary-button" (click)="continue()">{{'cons_now_appt_btn_confirm_continue'  | translate}}</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
