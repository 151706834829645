import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentUploadQuestionnarieComponent } from './appointment-upload-questionnarie.component';
import { PipesModule } from '../pipes/pipes.module';
import { ClinicalformModule } from '../clinicalform/clinicalform.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';



@NgModule({
  declarations: [AppointmentUploadQuestionnarieComponent],
  imports: [
    CommonModule, PipesModule, ClinicalformModule, PersonAvatarModule,
    PractitionerAvatarModule
  ],
  exports: [
    AppointmentUploadQuestionnarieComponent
  ]
})
export class AppointmentUploadQuestionnarieModule { }
