import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { ContactUsModalComponent } from './contact-us-modal.component';
import { ContactUsDetailsModule } from '../contact-us-details/contact-us-details.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    ContactUsModalComponent
  ],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,ContactUsDetailsModule,PipesModule, GoogleMapsModule
  ],
  exports: [ ContactUsModalComponent ]
})
export class ContactUsModalModule { }




