import { TOAST_ERROR } from '@string';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as AppConstant from '../app.string';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { CookieService } from './cookie.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastServiceService } from './toast-service.service';
import { SESSION_EXPIRED } from '@string';
import { EventsService } from '../services/events.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private FILE_ACCESS_TOKEN: string;
  private ENTITY_TOKEN: string;
  private USER_TOKEN: string;
  private REPO_TOKEN: string;
  private ACCESS_TOKEN: string;
  private TEMP_TOKEN: string;
  private toast_block = [
    '/getConsultationDiscount',
    '/externalAuth/logout',
    '/persons/facilitiesforpatient',
    '/outpatients/getinsuranceDetails',
    '/appointments/checkApptOverlapForPatient',
    '/outpatients/getDemographicsFromInsurance'
  ]
  private tempTokenRoutes = ['/patientKiosk/verifyPatientQR', '/patientKiosk/getVisitCharges', '/patientKiosk/precheck', 'patientKiosk/patientSignin']
  private _requests: HttpRequest<any>[] = [];
  constructor(private cookie: CookieService, public loader: LoaderService, private toast: ToastServiceService,private events:EventsService) {
    this.ENTITY_TOKEN = environment['ENTITY_TOKEN']
    this.ACCESS_TOKEN = this.cookie.getStorage(AppConstant.ACCESS_TOKEN) ? this.cookie.getStorage(AppConstant.ACCESS_TOKEN) : ''
  }
  public setUserToken(token: string) {
    this.USER_TOKEN = token;
  }
  public setRepoToken(token: string) {
    this.REPO_TOKEN = token;
  }
  public setTempToken(token: string) {
    this.TEMP_TOKEN = token;
  }

  public setAccessToken(token: string) {
    this.ACCESS_TOKEN = token;
  }
  public getRepoToken() {
    return this.REPO_TOKEN;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.sendRequest(request, next);
  }
  sendRequest(request: HttpRequest<any>, next: HttpHandler) {
    if(request.url.includes('getTaskInfo')){
      // this.loader.changeState(false);
    }
    else{
      this.loader.changeState(true);
    }
    request = this.setUserLang(request);
    let isToastBlock;
    let isToastBlockOnCondition;
    if (request['body'] && Array.isArray(request['body'])) {
      isToastBlockOnCondition = request['body'][0]['isToastBlockOnCondition']
    } else if (request['body']) {
      isToastBlockOnCondition = request['body']['isToastBlockOnCondition']
    }
    if (request.url.startsWith(`${environment['VISIT_API_URL']}api/patientKiosk`))
      request = this.setState(request);
    this.ACCESS_TOKEN = this.cookie.getStorage(AppConstant.ACCESS_TOKEN) ? this.cookie.getStorage(AppConstant.ACCESS_TOKEN) : ''
    if (request['reportProgress']) {
      request = this.setAuthTokens(request, this.cookie.getStorage(AppConstant.USER_TOKEN), this.ACCESS_TOKEN, this.ENTITY_TOKEN);
    } else if (request.url.includes('download')) {
      request = this.setAuthTokens(request, request.headers.get('Authorization'));
    }else if (request.url.includes('getavailability')) {
      request = this.setAuthTokens(request);
    }
    else if (this.tempTokenRoutes.findIndex(r => request.url.endsWith(r)) > -1){
      request = this.setAuthTokens(request, this.cookie.getStorage(AppConstant.TEMP_TOKEN));
    } else  {
      request = this.setAuthTokens(request, this.cookie.getStorage(AppConstant.REPO_TOKEN));
    }
    if(!request.url.includes('getTaskInfo')) { this._requests.push(request); }
    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        this.removeRequest(request);
        // this.loader.changeState(false);
      }
    }, error => {
      console.trace('error handling request', request.url, error);
      if (error.status == 401) {
        this.events.broadcast(SESSION_EXPIRED);
      }
      let found = this.toast_block.some(tb => {
        return request.url.includes(tb)
      })
      if (error.error && error.error.error && error.error.error.showErrorLabel) {
        console.log('showErrorLabel')
        found = true
      }
      if (isToastBlockOnCondition && isToastBlockOnCondition.enable) {
        isToastBlock = this.checkToastBlockOnCondition(isToastBlockOnCondition.condition, error, isToastBlock)
      }
      console.log(found)
      if (!found && !isToastBlock) {
        let translate = false;
        let toastMessage = error.error ? (error.error.err && error.error.err.message ? error.error.err.message : (error.error.message ? error.error.message : (error.error.error ? error.error.error : null))) : null
        console.log('errorerror', error);
        if (error?.error?.errorLabel) {
          toastMessage = error.error.errorLabel
          translate = true
        }
        if (!toastMessage) {
          toastMessage = 'Something went wrong'
        }
        if (toastMessage != 'invalid_token')
          this.toast.showToast(null, toastMessage, TOAST_ERROR, translate);
        this.removeRequest(request);
        // this.loader.changeState(false);
      } else {
        this.removeRequest(request);
        // this.loader.changeState(false);
      }
    }, () => {
      this.removeRequest(request);
        // this.loader.changeState(false);
    }));
  }

  private checkToastBlockOnCondition(condition, error, isToastBlock) {
    switch (condition.conditionType) {
      case 'INCLUDES':
        let errorStr = typeof error == 'string' ? error : JSON.stringify(error)
        if (errorStr.includes(condition.conditionValue)) {
          return true
        }
        break;
    }
    return isToastBlock
  }


  private setAuthTokens(request, bearerToken?:any, accessToken?:any, appToken?:any) {
    let url = request.url;
    if (request['reportProgress'] && environment['isDomainSpecific']) {
      url = (environment['dev'] == true) ? (environment['APPEND_URL'] + request.url) : location.origin + request.url;
    }
    if (bearerToken){
      let head = {
        'hp-source': 'PXPORTAL',
        'Authorization': `BEARER ${bearerToken}`,
        'hpApp-Token': appToken || undefined,
        'access-token': accessToken || undefined
      }
      try {
        let { personId } = JSON.parse(this.cookie.getStorage(AppConstant.USER_OBJECT))
        if (personId) head['hp-user-id'] = personId;
      } catch (e) {
        console.error('error getting person id', e)
      }
      head = JSON.parse(JSON.stringify(head));
      request = request.clone({
        url: url,
        setHeaders: head
      });
    } else if (accessToken){
      request = request.clone({
        setHeaders: {
          'access-token': accessToken
        }
      });
    } else if (request.url.includes('getavailability')){
      request = request.clone({
        url: url,
        setHeaders: {
        }
      });
    } else{
        request = request.clone({
          url: url,
          setHeaders: {
            'hpApp-Token': this.ENTITY_TOKEN
          }
        });
    }
    localStorage.setItem('REQ', JSON.stringify(request));
    return request;
  }

  private setUserLang(request) {
    let selectedLang = this.cookie.getStorage(AppConstant.USER_LANGUAGE);
    selectedLang = selectedLang ? JSON.parse(selectedLang): null;
    if(selectedLang && selectedLang['localeCode']){
      if (request.method.toLowerCase() === 'post') {
        if (request.body instanceof FormData) {
          if (!Array.isArray(request.body))
            request =  request.clone({
              body: request.body.append('locale', selectedLang['localeCode'])
            })
        } else {
          if (!Array.isArray(request.body))
           request = request.clone({body: {...request.body, locale: selectedLang['localeCode']}});
        }
      }
      /* if (request.method.toLowerCase() === 'get') {
      request = request.clone({
        params: request.params.append('locale', selectedLang['localeCode'])
      });
      }  */
    }

    return request;
  }

  private setState(request) {
    const disableState = false;
    if (disableState) return request;
    let state = this.cookie.getStorage(AppConstant.REPO_STATE);
    if (state) {
      if (request.method.toLowerCase() === 'post') {
        if (request.body instanceof FormData) {
          if (!Array.isArray(request.body))
            request = request.clone({
              body: request.body.append('cookieState', state)
            })
        } else {
          if (!Array.isArray(request.body))
            request = request.clone({ body: { ...request.body, cookieState: state } });
        }
      }
    }
    return request;
  }
  
  removeRequest(req: HttpRequest<any>) {
    const i = this._requests.indexOf(req);
    if (i >= 0) {
      this._requests.splice(i, 1);
    }
    this.loader.changeState(this._requests?.length > 0);
  }
}
