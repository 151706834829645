import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './appointments.component';
import { ListItemModule } from '../list-item/list-item.module';
import { PipesModule } from '../pipes/pipes.module';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { NodataModule } from '../nodata/nodata.module';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { AppointmentUploadQuestionnarieModule } from '../appointment-upload-questionnarie/appointment-upload-questionnarie.module';
import { HrDocumentUploadModule } from '../hr-document-upload/hr-document-upload.module';
import { ExpressClinicModalModule } from '../express-clinic-modal/express-clinic-modal.module';
import { DirectiveModule } from '../directive/directive.module';
import { MapModule } from '../map/map.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';

@NgModule({
  declarations: [AppointmentsComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule, BookAppointmentModule, RegisterPatientModule, NodataModule,
    CustomModalModule,
    PersonAvatarModule, AppointmentUploadQuestionnarieModule, HrDocumentUploadModule,
    UpcomingAppointmentDetailModule, ExpressClinicModalModule, DirectiveModule, MapModule, RegisterNewPatientModule, ApptTimeModule
  ],
  exports: [
    AppointmentsComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppointmentsModule { }
