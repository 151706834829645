import * as CONST from './app.string';
import { NavigationMenuItem}  from "./app.type";


export const Obj = "tdrt";
export let LANG_SELECTED= "en";
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const MENU: Array<NavigationMenuItem> = [
  {
    name: CONST['TITLE_NAV_HOME'],
    activeIcon: CONST['ICON_NAV_HOME'],
    inactiveIcon: CONST['ICON_NAV_HOME_INACTIVE'],
    routeTo: CONST['ROUTE_CRM_HOME'],
    context: CONST['CONTEXT_HOME'],
    id: CONST['ID_HOME'],
    permission: 'FT_APP_TAB_HOME'
  },
  {
    name: CONST['TITLE_NAV_CALENDAR'],
    activeIcon: CONST['ICON_NAV_CALENDAR'],
    inactiveIcon: CONST['ICON_NAV_CALENDAR_INACTIVE'],
    routeTo: CONST['ROUTE_CRM_CALENDAR'],
    context: CONST['CONTEXT_CALENDAR'],
    id: CONST['ID_CALENDAR'],
    permission: 'FT_APP_TAB_CALENDAR',
  },
  // {
  //   name: CONST['TITLE_NAV_TIMELINE'],
  //   activeIcon: CONST['ICON_NAV_TIMELINE'],
  //   routeTo: CONST['ROUTE_CRM_TIMELINE'],
  //   context: CONST['CONTEXT_TIMELINE'],
  //   id: CONST['ID_TIMELINE'],
  // },
  {
    name: CONST['TITLE_NAV_HEALTH_RECORDS'],
    activeIcon: CONST['ICON_NAV_HEALTH_RECORDS'],
    inactiveIcon: CONST['ICON_NAV_HEALTH_RECORDS_INACTIVE'],
    routeTo: CONST['ROUTE_CRM_HEALTH_RECORDS'],
    context: CONST['CONTEXT_HEALTH_RECORDS'],
    id: CONST['ID_HEALTH_RECORDS'],
    permission: 'FT_APP_TAB_HR',
  },
  {
    name: CONST['TITLE_NAV_PATIENT_BILLING'],
    activeIcon: CONST['ICON_NAV_PATIENT_BILLING'],
    inactiveIcon: CONST['ICON_NAV_PATIENT_BILLING_INACTIVE'],
    routeTo: CONST['ROUTE_CRM_PATIENT_BILLING'],
    context: CONST['CONTEXT_PATIENT_BILLING'],
    id: CONST['ID_PATIENT_BILLING'],
    permission: 'FT_APP_TAB_BILLING',
  },
  // {
  //   name: CONST['TITLE_NAV_CARE_PLAN'],
  //   activeIcon: CONST['ICON_NAV_CARE_PLAN'],
  //   routeTo: CONST['ROUTE_CRM_CARE_PLAN'],
  //   context: CONST['CONTEXT_CARE_PLAN'],
  //   id: CONST['ID_CARE_PLAN'],
  // }
]
export const I18N_CUSTOM_TYPE = {
  monthNames: [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  firstDayOfWeek: 0,
  week: 'Week',
  calendar: 'Calendar',
  clear: 'Clear',
  today: 'Today',
  cancel: 'Cancel',
  formatDate: d => {
    const yearStr = String(d.year).replace(/\d+/, y => '0000'.substr(y.length) + y);
    return [d.day, d.month + 1, yearStr].join('/');
  },
  parseDate: text => {
    const parts = text.split('/');
    const today = new Date();
    let date, month = today.getMonth(), year = today.getFullYear();

    if (parts.length === 3) {
      year = parseInt(parts[2]);
      if (parts[2].length < 3 && year >= 0) {
        year += year < 50 ? 2000 : 1900;
      }
      month = parseInt(parts[1]) - 1
      date = parseInt(parts[0]);
    } else if (parts.length === 2) {
      month = parseInt(parts[1]) - 1;
      date = parseInt(parts[0]);
    } else if (parts.length === 1) {
      date = parseInt(parts[0]);
    }

    if (date !== undefined) {
      return { day: date, month, year };
    }
  },
  formatTitle: (monthName, fullYear) => {
    return monthName + ' ' + fullYear;
  }
};
export enum DATA_STATES {
  LOADING = 'Loading',
  LOADED = 'Loaded',
  NODATA = 'No-Data',
  ERROR = 'Error'
}

export const GENDER = [
  { name: 'Male', lang_key: 'frm_male_opt', value: 'M'},
  { name: 'Female', lang_key: 'frm_female_opt', value: 'F'}
]

export const DOB_CAL = [
  { name: 'Gregorian', lang_key: 'dob_gregorian', value: 'G'},
  { name: 'Hijiri', lang_key: 'dob_hijiri', value: 'H'}
]

export const CITIZENLIST = [
  { name: 'Citizen', lang_key: 'frm_citizen_Yes', value: 'N'},
  { name: 'Muqeem', lang_key: 'frm_citizen_No', value: 'INT'},
  { name: 'Visitor', lang_key: 'frm_citizen_Visitor', value: 'INTV'},
]

export const BILLGROUP = [
  { name: 'Cash', lang_key: 'cash', value: 'C'},
  { name: 'Insurance', lang_key: 'ins', value: 'X'},
]

export const DIRECTIVE_CODE = 'ONSPOTCONS'
export const FT_APP_ENABLED = 'FT_APP_ENABLED';
export const FT_APP_KEEP_SIGNIN = 'FT_APP_KEEP_SIGNIN';
export const FT_APP_SIGNUP = 'FT_APP_SIGNUP';
export const FT_APP_RESET_PASSWORD = 'FT_APP_RESET_PASSWORD';
export const FT_APP_BOOK_DIRECT_APPT = 'FT_APP_BOOK_DIRECT_APPT';
export const FT_APP_BOOK_VC_APPT = 'FT_APP_BOOK_VC_APPT';
export const FT_APP_HEALTH_PACKAGE = 'FT_APP_HEALTH_PACKAGE';
export const FT_APP_HOMESERVICES = 'FT_APP_HOMESERVICES';
export const FT_APP_MANAGE_EXIST_IDENTITY = 'FT_APP_MANAGE_EXIST_IDENTITY';
export const FT_APP_MANAGE_ACCOUNT = 'FT_APP_MANAGE_ACCOUNT';
export const FT_APP_BOOK_APPT = 'FT_APP_BOOK_APPT';
export const FT_APP_HOME_VIEW_APPT = 'FT_APP_HOME_VIEW_APPT';
export const FT_APP_HOME_VIEW_TODO = 'FT_APP_HOME_VIEW_TODO';
export const FT_APP_HOME_VIEW_CARE_PLAN = 'FT_APP_HOME_VIEW_CARE_PLAN';
export const FT_APP_HOME_VIEW_REMAINDERS = 'FT_APP_HOME_VIEW_REMAINDERS';
export const FT_APP_HOME_VIEW_RECENT_VISITS = 'FT_APP_HOME_VIEW_RECENT_VISITS';
export const FT_APP_HOME_VIEW_EXIST_UNLINKED = 'FT_APP_HOME_VIEW_EXIST_UNLINKED';
export const FT_APP_HOME_VIEW_SLIDER = 'FT_APP_HOME_VIEW_SLIDER';
export const FT_APP_HOME_VIEW_COMMON_FEEDBACK = 'FT_APP_HOME_VIEW_COMMON_FEEDBACK';
export const FT_APP_REGISTER_FAMILY_MEMBER = 'FT_APP_REGISTER_FAMILY_MEMBER';
export const FT_APP_FIND_FAMILY_MEMBER = 'FT_APP_FIND_FAMILY_MEMBER';
export const FT_APP_REGISTER_UNLINK_MEMBER = 'FT_APP_REGISTER_UNLINK_MEMBER';
export const FT_APP_REGISTER_LINK_MEMBER = 'FT_APP_REGISTER_LINK_MEMBER';
export const FT_APP_HR_VW_VST_HIS = 'FT_APP_HR_VW_VST_HIS';
export const FT_APP_HR_REPORT = 'FT_APP_HR_REPORT';
export const FT_APP_HR_PRESCRIPTION = 'FT_APP_HR_PRESCRIPTION';
export const FT_APP_HR_BILLS = 'FT_APP_HR_BILLS';
export const FT_APP_HR_VISIT_SECTION = 'FT_APP_HR_VISIT_SECTION';
export const FT_APP_HR_VISIT_FEEDBACK = 'FT_APP_HR_VISIT_FEEDBACK';
export const FT_APP_HR_UPLOADDOCS = 'FT_APP_HR_UPLOADDOCS';
export const FT_APP_HR_DOWNLOADDOCS = 'FT_APP_HR_DOWNLOADDOCS';
export const FT_APP_HR_DELETEDOCS = 'FT_APP_HR_DELETEDOCS';
export const FT_APP_TAB_HR = 'FT_APP_TAB_HR';
export const FT_APP_TAB_CALENDAR = 'FT_APP_TAB_CALENDAR';
export const FT_UNAUTHORIZED = 'FT_UNAUTHORIZED';
export const FT_APP_TAB_BILLING = 'FT_APP_TAB_BILLING';
export const FT_APP_EDIT_LINK_MEMBER = 'FT_APP_EDIT_LINK_MEMBER';
export const FT_APP_CXTWITH_PIN = 'FT_APP_CXTWITH_PIN';
export const FT_APP_LOGWITH_OTP = 'FT_APP_LOGWITH_OTP';
export const FT_APP_CXTWITH_PRECHECK = 'FT_APP_CXTWITH_PRECHECK';
export const FT_APP_TAB_HOME = 'FT_APP_TAB_HOME';
export const FT_APP_OLY_CMN_VIEW = 'FT_APP_OLY_CMN_VIEW';
export const FT_SSO_REGPAT_ON_SIGNUP = 'FT_SSO_REGPAT_ON_SIGNUP';
export const SHOW_APP_HEADER = 'SHOW_APP_HEADER';
export const SHOW_APP_FOOTER = 'SHOW_APP_FOOTER';
export const FT_APP_EXTIDLOGIN = 'FT_APP_EXTIDLOGIN';
export const FT_APP_UNLINKSAMEIDENTITY = 'FT_APP_UNLINKSAMEIDENTITY';
export const FT_APP_UNLINKDIFFIDENTITY = 'FT_APP_UNLINKDIFFIDENTITY';
export const FT_APP_UNLINKPROVIDEDIDENTITY = 'FT_APP_UNLINKPROVIDEDIDENTITY';
export const FT_APP_HOFLINKDIFFIDENTITY = 'FT_APP_HOFLINKDIFFIDENTITY';
export const FT_APP_NONHOFLINKDIFFIDENTITY = 'FT_APP_NONHOFLINKDIFFIDENTITY';
export const FT_APP_LOGINCAPTCHA = 'FT_APP_LOGINCAPTCHA';
export const FT_APP_SIGNUPCAPTCHA = 'FT_APP_SIGNUPCAPTCHA';
export const FT_APP_BOOKWITHRESTRICTIONS = 'FT_APP_BOOKWITHRESTRICTIONS';
export const FT_APP_LINKWITHOUTRELATION = 'FT_APP_LINKWITHOUTRELATION';
export const FT_APP_HR_SUMMARY = 'FT_APP_HR_SUMMARY';

export const COUPON_PARAMS = {
  title : 'lbl_apply_coupon',
  placeholder: 'lbl_enter_couponcode',
  type: 'COUPON',
  listTitle: 'lbl_your_coupons'
}

export const PROMO_PARAMS = {
  title : 'lbl_Promocode',
  placeholder: 'lbl_enter_promocode',
  type: 'PROMO',
  listTitle: 'lbl_available_promocode'
}

export const GTAGFLOW = {
  'Clinic Appointments': {
    'clinic_appointment_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'choose_clinic': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'step_option_choose_clinic': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'choose_specialty': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'step_option_choose_specialty': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'step_option_choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'select_date_time': {
      'event_cat': 'Patient Portal',
      'step_no': '4'
    },
    'select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '5'
    },
    'step_option_select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '5'
    },
    'payment': {
      'event_cat': 'Patient Portal',
      'step_no': '6'
    },
    'step_option_payment': {
      'event_cat': 'Patient Portal',
      'step_no': '6'
    },
    'appointment_confirmation': {
      'event_cat': 'Patient Portal',
    },
  },
  'Online Consultation': {
    'online_consultation_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'choose_specialty': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'step_option_choose_specialty': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'step_option_choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'select_date_time': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '4'
    },
    'step_option_select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '4'
    },
    'payment': {
      'event_cat': 'Patient Portal',
      'step_no': '5'
    },
    'step_option_payment': {
      'event_cat': 'Patient Portal',
      'step_no': '5'
    },
    'appointment_confirmation': {
      'event_cat': 'Patient Portal',
    },
  },
  'Instant Consultation': {
    'consult_doctor_now_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'step_option_select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'request_accepted': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'step_option_request_accepted': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'payment': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'consultation_started': {
      'event_cat': 'Patient Portal',
      'step_no': '4'
    },
  },
  'Express Clinics': {
    'express_clinics_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'step_option_select_person': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'select_facility': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'step_option_choose_practitioner': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'choose_services': {
      'event_cat': 'Patient Portal',
      'step_no': '4'
    },
    'select_date_time': {
      'event_cat': 'Patient Portal',
      'step_no': '5'
    },
    'payment': {
      'event_cat': 'Patient Portal',
      'step_no': '6'
    },
    'step_option_payment': {
      'event_cat': 'Patient Portal',
      'step_no': '6'
    },
    'appointment_confirmation': {
      'event_cat': 'Patient Portal',
    },
  },
  'Health Packages': {
    'health_packages_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'choose_package': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'package_details': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'package_request_confirmation': {
      'event_cat': 'Patient Portal',
      'step_no': '3'
    },
    'appointment_confirmation': {
      'event_cat': 'Patient Portal',
    },
  },
  'Home Health Care': {
    'care_at_home_button': {
      'event_cat': 'Patient Portal',
      'step_no': '0'
    },
    'care_at_home': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'step_option_care_at_home': {
      'event_cat': 'Patient Portal',
      'step_no': '1'
    },
    'services_list': {
      'event_cat': 'Patient Portal',
      'step_no': '2'
    },
    'call_us_button': {
      'event_cat': 'Patient Portal',
    },
  },

  common: {
    'global_variables': {
      'event_cat': ''
    },
    'login': {
      'event_cat': 'UserProperty',
      'event_act': 'Login',
      'event_label':'Login Success'
    },
    'signup': {
      'event_cat': 'UserProperty',
      'event_act': 'Signup',
      'event_label':'Signup Success'
    }
  }
}
export const PAGINATION_NUMBERS = [10, 20, 30];
export const HOMECARE = 'HC';
export const INSTALLMENT = 'EMI';
export const LOGIN_CONTEXT = {
  REPORT: 'R',
  APPOINTMENT: 'A',
  BILL: 'B',
  TASK: 'T'
}
export const CTX = 'CTX';

export const HOME_CARE_SERVICES = {
  "services": [
      {
        "title": [
          {"label": "Doctor consultation", "locale":"en"},
        ],
        "sub_title": [
          {"label": "Doctor Consultation at Home", "locale":"en"},
        ],
        "icon": "https://healtht.nahdi.sa/at/uploads/hp-app-resource-dev/EN_ALNAHDI/gallery/RzHm4oBCOrRz.png",
        "type":"doctor",
        "careservicecode": ""
      },
      {
        "title": [
          { "label": "Nurse at home", "locale":"en"},
        ],
        "sub_title": [
          { "label": "Nurse homecare services", "locale":"en"},
        ],
        "icon": "https://healtht.nahdi.sa/at/uploads/hp-app-resource-dev/EN_ALNAHDI/gallery/drxaev9FRPaA.png",
        "type":"careservice",
        "careservicecode": "CH006"
      },
      {
        "title": [
          { "label": "Blood test or sample collection", "locale":"en"},
        ],
        "sub_title": [
          { "label": "Home sample collection", "locale":"en"},
        ],
        "icon": "https://healtht.nahdi.sa/at/uploads/hp-app-resource-dev/EN_ALNAHDI/gallery/HbG05O3dPcYZ.png",
        "type":"careservice",
        "careservicecode": "CH009"
    },
    {
      "title": [
        { "label": "Blood test or sample collection", "locale": "en" },
      ],
      "sub_title": [
        { "label": "Home sample collection", "locale": "en" },
      ],
      "icon": "https://healtht.nahdi.sa/at/uploads/hp-app-resource-dev/EN_ALNAHDI/gallery/HbG05O3dPcYZ.png",
      "type": "careservice",
      "careservicecode": "CH009"
    }
  ],
  "location":{
    "enable":"true"
  },
  "call_option":{
    "enable": "true",
    "number": "+966 503457577"
  },
  "home":{
    "icon":""
  }
}

export class DateType {
  public static Hijri = 1;
  public static Gregorian = 2;
}

export const BILLSTATUS_COLOR_MAPPING  = {
  'entered-in-error': {
    class: 'dangerlight-button font-danger-small',
    label: 'entered-in-error'
  },
  'active': {
    class: 'successlight-button font-success-small',
    label:'active'
  }
}

export const HEALTHPACKFILTER = [
	{
		type: 'gender',
		label: 'HEALTHPACK#FILTER#TITLE#GENDER',
		enable: true,
		options: [
			{
				key: 'M',
				label: 'HEALTHPACK#FILTER#GENDER#MALE',
				selection: 'key',
				checked: false
			},
			{
				key: 'F',
				label: 'HEALTHPACK#FILTER#GENDER#FEMALE',
				selection: 'key',
				checked: false
			}
		]
	},
	{
		type: 'age',
		label: 'HEALTHPACK#FILTER#TITLE#AGE',
		enable: true,
		options: [
			{
				label: 'HEALTHPACK#FILTER#AGE#LESS40',
				selection: 'range',
				range: {
					min: 0,
					max: 40
				},
        key: 'range1',
				checked: false
			},
			{
				label: 'HEALTHPACK#FILTER#AGE#MORE40',
				selection: 'range',
				range: {
					min: 41,
					max: 120
				},
        key: 'range2',
				checked: false
			}
		]
	},
	{
		type: 'facility',
		label: 'HEALTHPACK#FILTER#TITLE#FACILITY',
		enable: true,
		options: [],
    "multiSelect": true,
    "searchApplicable": true,
    "searchLbl": 'HEALTHPACK#FILTER#PLACEHOLDER#FACILITY',
    "enableSelectAll": true
	},
  {
		type: 'speciality',
		label: 'HEALTHPACK#FILTER#TITLE#SPECIALITY',
		enable: true,
		options: [],
    "multiSelect": true,
    "searchApplicable": true,
    "searchLbl": 'HEALTHPACK#FILTER#PLACEHOLDER#SPECIALITY',
    "enableSelectAll": true
	}
];

export const APPTCONST = {
  CHECKIN: {
    VIDEO: './assets/icon/actionsheet/videocall.svg',
    HOSPITAL: './assets/icon/appt-preview/walkin.svg',
    CLINIC: './assets/icon/appt-preview/walkin.svg',
    HC: './assets/icon/homecare_prim.svg',
	N:'./assets/icon/appt-preview/walkin.svg'
  },
  APPT_IMAGE: {
    VIDEO: './assets/icon/appt-preview/videocam.svg',
    HOSPITAL: './assets/icon/appt-preview/walkin.svg',
    CLINIC: './assets/icon/appt-preview/walkin.svg',
    HC: './assets/icon/homecare_prim.svg',
    N:'./assets/icon/appt-preview/walkin.svg'
  },
  PAY: {
	  VIDEO: './assets/icon/actionsheet/videocall.svg',
	  HOSPITAL: './assets/icon/actionsheet/paycheckin.svg',
	  CLINIC: './assets/icon/actionsheet/paycheckin.svg',
	  HC: './assets/icon/homecare_prim.svg',
	  N: './assets/icon/appt-preview/walkin.svg'
  },
  PAYCHECKIN: {
    VIDEO: './assets/icon/actionsheet/videocall.svg',
		HOSPITAL: './assets/icon/actionsheet/paycheckin.svg',
		CLINIC: './assets/icon/actionsheet/paycheckin.svg',
    HC: './assets/icon/homecare_prim.svg',
	N:'./assets/icon/appt-preview/walkin.svg'
  },
  APPT_GUIDLINES: {
    VIDEO: 'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',
    HOSPITAL: 'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',// 'APPTPREVIEW#CONFIRM#CONTENT#HOSAPPT_GUIDLINES',
    CLINIC:'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',// 'APPTPREVIEW#CONFIRM#CONTENT#ECAPPT_GUIDLINES',
    HC:'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',// 'APPTPREVIEW#CONFIRM#CONTENT#HCAPPT_GUIDLINES',
    N:'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',//'APPTPREVIEW#CONFIRM#CONTENT#ECAPPT_GUIDLINES',
	F:'APPTPREVIEW#CONFIRM#CONTENT#APPT_GUIDLINES',//'APPTPREVIEW#CONFIRM#CONTENT#FLAPPT_GUIDLINES'
  },
  VISIT_INFO: {
    VIDEO: 'APPTPREVIEW#CONFIRM#CONTENT#VCVISIT_INFO',
    HOSPITAL: 'APPTPREVIEW#CONFIRM#CONTENT#HOSVISIT_INFO',
    CLINIC: 'APPTPREVIEW#CONFIRM#CONTENT#ECVISIT_INFO',
    HC: 'APPTPREVIEW#CONFIRM#CONTENT#HCVISIT_INFO',
    N:'APPTPREVIEW#CONFIRM#CONTENT#HOSVISIT_INFO',
	F:'APPTPREVIEW#CONFIRM#CONTENT#FLVISIT_INFO'
  },
  MODE:{
	C:'./assets/icon/actionsheet/mode_card.svg',
	AP:'./assets/icon/actionsheet/mode_ap.svg'
  }
}

export const NOTIFICATION_ICON = {
	VIDEO: 'assets/icons/video.svg',
	HOSPITAL: 'assets/icon/appt-preview/location.svg',
	CLINIC: 'assets/icon/appt-preview/location.svg',
	INFO: 'assets/icon/info_prim.svg',
	SUCCESS: 'assets/icons/check.svg',
	ERROR: 'assets/icons/error.svg',
	ANNOUNCEMENT : 'assets/icons/announcement.svg',
	lABCLR : 'assets/icon/lab_clr.svg',
	MEDICALREPORT : 'assets/icon/medical_report_clr.svg',
	// announcement : 'assets/icons/announcement.svg',
	UPDATES:'assets/icons/Update.svg'
}

export const FILTERS = [
  {
    'label':'ALERT#FILTER#MODAL#ITEM#REMINDER',//'reminders',
    'checked':true,
    'code':'reminders',
    'key':'reminders',
    selection: 'key'

  },
  {
    'label':'ALERT#FILTER#MODAL#ITEM#UPDATE',//'updates',
    'checked':true,
    'code':'updates',
    'key':'updates',
    selection: 'key'
  },
  {
    'label':'ALERT#FILTER#MODAL#ITEM#ANNOUNCEMENT',//'announcement',
    'checked':true,
    'code':'announcement',
    'key':'announcement',
    selection: 'key',
  }
];


export const NOTIFY_FILTERS = [
	{
		type: 'notification',
    "placeholderLbl": 'ALERT#FILTER#PLACEHOLDER#FILTERS',
		// label: 'ALERT#FILTER#TITLE#GENDER',
		enable: true,
    "multiSelect": true,
    "count": "All",
		options: [
      {
        'label':'ALERT#FILTER#MODAL#ITEM#REMINDER',//'reminders',
        'checked':true,
        'code':'reminders',
        'key':'reminders',
        selection: 'key'

      },
      {
        'label':'ALERT#FILTER#MODAL#ITEM#UPDATE',//'updates',
        'checked':true,
        'code':'updates',
        'key':'updates',
        selection: 'key'
      },
      {
        'label':'ALERT#FILTER#MODAL#ITEM#ANNOUNCEMENT',//'announcement',
        'checked':true,
        'code':'announcement',
        'key':'announcement',
        selection: 'key',
      }
		]
	}
]
export const VISITOR = [
  { name: 'PAT_REGISTRATION#FORM#VISITOR', checked: false }
];

export const GENDER_ALNAHDI = [
  { name: 'PAT_REGISTRATION#FORM#GENDER_OPT#MALE', lang_key: 'frm_male_opt', value: 'M' },
  { name: 'PAT_REGISTRATION#FORM#GENDER_OPT#FEMALE', lang_key: 'frm_female_opt', value: 'F' }
];

export const ALL_COUNTRIES = [
  [
    "Afghanistan (‫افغانستان‬‎)",
    "af",
    "93"
  ],
  [
    "Albania (Shqipëri)",
    "al",
    "355"
  ],
  [
    "Algeria (‫الجزائر‬‎)",
    "dz",
    "213"
  ],
  [
    "American Samoa",
    "as",
    "1684"
  ],
  [
    "Andorra",
    "ad",
    "376"
  ],
  [
    "Angola",
    "ao",
    "244"
  ],
  [
    "Anguilla",
    "ai",
    "1264"
  ],
  [
    "Antigua and Barbuda",
    "ag",
    "1268"
  ],
  [
    "Argentina",
    "ar",
    "54"
  ],
  [
    "Armenia (Հայաստան)",
    "am",
    "374"
  ],
  [
    "Aruba",
    "aw",
    "297"
  ],
  [
    "Australia",
    "au",
    "61"
  ],
  [
    "Austria (Österreich)",
    "at",
    "43"
  ],
  [
    "Azerbaijan (Azərbaycan)",
    "az",
    "994"
  ],
  [
    "Bahamas",
    "bs",
    "1242"
  ],
  [
    "Bahrain (‫البحرين‬‎)",
    "bh",
    "973"
  ],
  [
    "Bangladesh (বাংলাদেশ)",
    "bd",
    "880"
  ],
  [
    "Barbados",
    "bb",
    "1246"
  ],
  [
    "Belarus (Беларусь)",
    "by",
    "375"
  ],
  [
    "Belgium (België)",
    "be",
    "32"
  ],
  [
    "Belize",
    "bz",
    "501"
  ],
  [
    "Benin (Bénin)",
    "bj",
    "229"
  ],
  [
    "Bermuda",
    "bm",
    "1441"
  ],
  [
    "Bhutan (འབྲུག)",
    "bt",
    "975"
  ],
  [
    "Bolivia",
    "bo",
    "591"
  ],
  [
    "Bosnia and Herzegovina (Босна и Херцеговина)",
    "ba",
    "387"
  ],
  [
    "Botswana",
    "bw",
    "267"
  ],
  [
    "Brazil (Brasil)",
    "br",
    "55"
  ],
  [
    "British Indian Ocean Territory",
    "io",
    "246"
  ],
  [
    "British Virgin Islands",
    "vg",
    "1284"
  ],
  [
    "Brunei",
    "bn",
    "673"
  ],
  [
    "Bulgaria (България)",
    "bg",
    "359"
  ],
  [
    "Burkina Faso",
    "bf",
    "226"
  ],
  [
    "Burundi (Uburundi)",
    "bi",
    "257"
  ],
  [
    "Cambodia (កម្ពុជា)",
    "kh",
    "855"
  ],
  [
    "Cameroon (Cameroun)",
    "cm",
    "237"
  ],
  [
    "Cape Verde (Kabu Verdi)",
    "cv",
    "238"
  ],
  [
    "Caribbean Netherlands",
    "bq",
    "599"
  ],
  [
    "Cayman Islands",
    "ky",
    "1345"
  ],
  [
    "Central African Republic (République centrafricaine)",
    "cf",
    "236"
  ],
  [
    "Chad (Tchad)",
    "td",
    "235"
  ],
  [
    "Chile",
    "cl",
    "56"
  ],
  [
    "China (中国)",
    "cn",
    "86"
  ],
  [
    "Colombia",
    "co",
    "57"
  ],
  [
    "Comoros (‫جزر القمر‬‎)",
    "km",
    "269"
  ],
  [
    "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    "cd",
    "243"
  ],
  [
    "Congo (Republic) (Congo-Brazzaville)",
    "cg",
    "242"
  ],
  [
    "Cook Islands",
    "ck",
    "682"
  ],
  [
    "Costa Rica",
    "cr",
    "506"
  ],
  [
    "Côte d’Ivoire",
    "ci",
    "225"
  ],
  [
    "Croatia (Hrvatska)",
    "hr",
    "385"
  ],
  [
    "Cuba",
    "cu",
    "53"
  ],
  [
    "Curaçao",
    "cw",
    "599"
  ],
  [
    "Cyprus (Κύπρος)",
    "cy",
    "357"
  ],
  [
    "Czech Republic (Česká republika)",
    "cz",
    "420"
  ],
  [
    "Denmark (Danmark)",
    "dk",
    "45"
  ],
  [
    "Djibouti",
    "dj",
    "253"
  ],
  [
    "Dominica",
    "dm",
    "1767"
  ],
  [
    "Ecuador",
    "ec",
    "593"
  ],
  [
    "Egypt (‫مصر‬‎)",
    "eg",
    "20"
  ],
  [
    "El Salvador",
    "sv",
    "503"
  ],
  [
    "Equatorial Guinea (Guinea Ecuatorial)",
    "gq",
    "240"
  ],
  [
    "Eritrea",
    "er",
    "291"
  ],
  [
    "Estonia (Eesti)",
    "ee",
    "372"
  ],
  [
    "Ethiopia",
    "et",
    "251"
  ],
  [
    "Falkland Islands (Islas Malvinas)",
    "fk",
    "500"
  ],
  [
    "Faroe Islands (Føroyar)",
    "fo",
    "298"
  ],
  [
    "Fiji",
    "fj",
    "679"
  ],
  [
    "Finland (Suomi)",
    "fi",
    "358"
  ],
  [
    "France",
    "fr",
    "33"
  ],
  [
    "French Guiana (Guyane française)",
    "gf",
    "594"
  ],
  [
    "French Polynesia (Polynésie française)",
    "pf",
    "689"
  ],
  [
    "Gabon",
    "ga",
    "241"
  ],
  [
    "Gambia",
    "gm",
    "220"
  ],
  [
    "Georgia (საქართველო)",
    "ge",
    "995"
  ],
  [
    "Germany (Deutschland)",
    "de",
    "49"
  ],
  [
    "Ghana (Gaana)",
    "gh",
    "233"
  ],
  [
    "Gibraltar",
    "gi",
    "350"
  ],
  [
    "Greece (Ελλάδα)",
    "gr",
    "30"
  ],
  [
    "Greenland (Kalaallit Nunaat)",
    "gl",
    "299"
  ],
  [
    "Grenada",
    "gd",
    "1473"
  ],
  [
    "Guadeloupe",
    "gp",
    "590"
  ],
  [
    "Guam",
    "gu",
    "1671"
  ],
  [
    "Guatemala",
    "gt",
    "502"
  ],
  [
    "Guinea (Guinée)",
    "gn",
    "224"
  ],
  [
    "Guinea-Bissau (Guiné Bissau)",
    "gw",
    "245"
  ],
  [
    "Guyana",
    "gy",
    "592"
  ],
  [
    "Haiti",
    "ht",
    "509"
  ],
  [
    "Honduras",
    "hn",
    "504"
  ],
  [
    "Hong Kong (香港)",
    "hk",
    "852"
  ],
  [
    "Hungary (Magyarország)",
    "hu",
    "36"
  ],
  [
    "Iceland (Ísland)",
    "is",
    "354"
  ],
  [
    "India (भारत)",
    "in",
    "91",
    -1
  ],
  [
    "Indonesia",
    "id",
    "62"
  ],
  [
    "Iran (‫ایران‬‎)",
    "ir",
    "98"
  ],
  [
    "Iraq (‫العراق‬‎)",
    "iq",
    "964"
  ],
  [
    "Ireland",
    "ie",
    "353"
  ],
  [
    "Israel (‫ישראל‬‎)",
    "il",
    "972"
  ],
  [
    "Italy (Italia)",
    "it",
    "39"
  ],
  [
    "Jamaica",
    "jm",
    "1876"
  ],
  [
    "Japan (日本)",
    "jp",
    "81"
  ],
  [
    "Jordan (‫الأردن‬‎)",
    "jo",
    "962"
  ],
  [
    "Kazakhstan (Казахстан)",
    "kz",
    "7"
  ],
  [
    "Kenya",
    "ke",
    "254"
  ],
  [
    "Kiribati",
    "ki",
    "686"
  ],
  [
    "Kuwait (‫الكويت‬‎)",
    "kw",
    "965"
  ],
  [
    "Kyrgyzstan (Кыргызстан)",
    "kg",
    "996"
  ],
  [
    "Laos (ລາວ)",
    "la",
    "856"
  ],
  [
    "Latvia (Latvija)",
    "lv",
    "371"
  ],
  [
    "Lebanon (‫لبنان‬‎)",
    "lb",
    "961"
  ],
  [
    "Lesotho",
    "ls",
    "266"
  ],
  [
    "Liberia",
    "lr",
    "231"
  ],
  [
    "Libya (‫ليبيا‬‎)",
    "ly",
    "218"
  ],
  [
    "Liechtenstein",
    "li",
    "423"
  ],
  [
    "Lithuania (Lietuva)",
    "lt",
    "370"
  ],
  [
    "Luxembourg",
    "lu",
    "352"
  ],
  [
    "Macau (澳門)",
    "mo",
    "853"
  ],
  [
    "Macedonia (FYROM) (Македонија)",
    "mk",
    "389"
  ],
  [
    "Madagascar (Madagasikara)",
    "mg",
    "261"
  ],
  [
    "Malawi",
    "mw",
    "265"
  ],
  [
    "Malaysia",
    "my",
    "60"
  ],
  [
    "Maldives",
    "mv",
    "960"
  ],
  [
    "Mali",
    "ml",
    "223"
  ],
  [
    "Malta",
    "mt",
    "356"
  ],
  [
    "Marshall Islands",
    "mh",
    "692"
  ],
  [
    "Martinique",
    "mq",
    "596"
  ],
  [
    "Mauritania (‫موريتانيا‬‎)",
    "mr",
    "222"
  ],
  [
    "Mauritius (Moris)",
    "mu",
    "230"
  ],
  [
    "Mexico (México)",
    "mx",
    "52"
  ],
  [
    "Micronesia",
    "fm",
    "691"
  ],
  [
    "Moldova (Republica Moldova)",
    "md",
    "373"
  ],
  [
    "Monaco",
    "mc",
    "377"
  ],
  [
    "Mongolia (Монгол)",
    "mn",
    "976"
  ],
  [
    "Montenegro (Crna Gora)",
    "me",
    "382"
  ],
  [
    "Montserrat",
    "ms",
    "1664"
  ],
  [
    "Morocco (‫المغرب‬‎)",
    "ma",
    "212"
  ],
  [
    "Mozambique (Moçambique)",
    "mz",
    "258"
  ],
  [
    "Myanmar (Burma) (မြန်မာ)",
    "mm",
    "95"
  ],
  [
    "Namibia (Namibië)",
    "na",
    "264"
  ],
  [
    "Nauru",
    "nr",
    "674"
  ],
  [
    "Nepal (नेपाल)",
    "np",
    "977"
  ],
  [
    "Netherlands (Nederland)",
    "nl",
    "31"
  ],
  [
    "New Caledonia (Nouvelle-Calédonie)",
    "nc",
    "687"
  ],
  [
    "New Zealand",
    "nz",
    "64"
  ],
  [
    "Nicaragua",
    "ni",
    "505"
  ],
  [
    "Niger (Nijar)",
    "ne",
    "227"
  ],
  [
    "Nigeria",
    "ng",
    "234"
  ],
  [
    "Niue",
    "nu",
    "683"
  ],
  [
    "Norfolk Island",
    "nf",
    "672"
  ],
  [
    "North Korea (조선 민주주의 인민 공화국)",
    "kp",
    "850"
  ],
  [
    "Northern Mariana Islands",
    "mp",
    "1670"
  ],
  [
    "Norway (Norge)",
    "no",
    "47"
  ],
  [
    "Oman (‫عُمان‬‎)",
    "om",
    "968"
  ],
  [
    "Pakistan (‫پاکستان‬‎)",
    "pk",
    "92"
  ],
  [
    "Palau",
    "pw",
    "680"
  ],
  [
    "Palestine (‫فلسطين‬‎)",
    "ps",
    "970"
  ],
  [
    "Panama (Panamá)",
    "pa",
    "507"
  ],
  [
    "Papua New Guinea",
    "pg",
    "675"
  ],
  [
    "Paraguay",
    "py",
    "595"
  ],
  [
    "Peru (Perú)",
    "pe",
    "51"
  ],
  [
    "Philippines",
    "ph",
    "63"
  ],
  [
    "Poland (Polska)",
    "pl",
    "48"
  ],
  [
    "Portugal",
    "pt",
    "351"
  ],
  [
    "Qatar (‫قطر‬‎)",
    "qa",
    "974"
  ],
  [
    "Réunion (La Réunion)",
    "re",
    "262"
  ],
  [
    "Romania (România)",
    "ro",
    "40"
  ],
  [
    "Russia (Россия)",
    "ru",
    "7"
  ],
  [
    "Rwanda",
    "rw",
    "250"
  ],
  [
    "Saint Barthélemy (Saint-Barthélemy)",
    "bl",
    "590"
  ],
  [
    "Saint Helena",
    "sh",
    "290"
  ],
  [
    "Saint Kitts and Nevis",
    "kn",
    "1869"
  ],
  [
    "Saint Lucia",
    "lc",
    "1758"
  ],
  [
    "Saint Martin (Saint-Martin (partie française))",
    "mf",
    "590"
  ],
  [
    "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    "pm",
    "508"
  ],
  [
    "Saint Vincent and the Grenadines",
    "vc",
    "1784"
  ],
  [
    "Samoa",
    "ws",
    "685"
  ],
  [
    "San Marino",
    "sm",
    "378"
  ],
  [
    "São Tomé and Príncipe (São Tomé e Príncipe)",
    "st",
    "239"
  ],
  [
    "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    "sa",
    "966"
  ],
  [
    "Senegal (Sénégal)",
    "sn",
    "221"
  ],
  [
    "Serbia (Србија)",
    "rs",
    "381"
  ],
  [
    "Seychelles",
    "sc",
    "248"
  ],
  [
    "Sierra Leone",
    "sl",
    "232"
  ],
  [
    "Singapore",
    "sg",
    "65"
  ],
  [
    "Sint Maarten",
    "sx",
    "1721"
  ],
  [
    "Slovakia (Slovensko)",
    "sk",
    "421"
  ],
  [
    "Slovenia (Slovenija)",
    "si",
    "386"
  ],
  [
    "Solomon Islands",
    "sb",
    "677"
  ],
  [
    "Somalia (Soomaaliya)",
    "so",
    "252"
  ],
  [
    "South Africa",
    "za",
    "27"
  ],
  [
    "South Korea (대한민국)",
    "kr",
    "82"
  ],
  [
    "South Sudan (‫جنوب السودان‬‎)",
    "ss",
    "211"
  ],
  [
    "Spain (España)",
    "es",
    "34"
  ],
  [
    "Sri Lanka (ශ්‍රී ලංකාව)",
    "lk",
    "94"
  ],
  [
    "Sudan (‫السودان‬‎)",
    "sd",
    "249"
  ],
  [
    "Suriname",
    "sr",
    "597"
  ],
  [
    "Swaziland",
    "sz",
    "268"
  ],
  [
    "Sweden (Sverige)",
    "se",
    "46"
  ],
  [
    "Switzerland (Schweiz)",
    "ch",
    "41"
  ],
  [
    "Syria (‫سوريا‬‎)",
    "sy",
    "963"
  ],
  [
    "Taiwan (台灣)",
    "tw",
    "886"
  ],
  [
    "Tajikistan",
    "tj",
    "992"
  ],
  [
    "Tanzania",
    "tz",
    "255"
  ],
  [
    "Thailand (ไทย)",
    "th",
    "66"
  ],
  [
    "Timor-Leste",
    "tl",
    "670"
  ],
  [
    "Togo",
    "tg",
    "228"
  ],
  [
    "Tokelau",
    "tk",
    "690"
  ],
  [
    "Tonga",
    "to",
    "676"
  ],
  [
    "Trinidad and Tobago",
    "tt",
    "1868"
  ],
  [
    "Tunisia (‫تونس‬‎)",
    "tn",
    "216"
  ],
  [
    "Turkey (Türkiye)",
    "tr",
    "90"
  ],
  [
    "Turkmenistan",
    "tm",
    "993"
  ],
  [
    "Turks and Caicos Islands",
    "tc",
    "1649"
  ],
  [
    "Tuvalu",
    "tv",
    "688"
  ],
  [
    "U.S. Virgin Islands",
    "vi",
    "1340"
  ],
  [
    "Uganda",
    "ug",
    "256"
  ],
  [
    "Ukraine (Україна)",
    "ua",
    "380"
  ],
  [
    "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    "ae",
    "971"
  ],
  [
    "United Kingdom",
    "gb",
    "44"
  ],
  [
    "United States",
    "us",
    "1"
  ],
  [
    "Uruguay",
    "uy",
    "598"
  ],
  [
    "Uzbekistan (Oʻzbekiston)",
    "uz",
    "998"
  ],
  [
    "Vanuatu",
    "vu",
    "678"
  ],
  [
    "Vatican City (Città del Vaticano)",
    "va",
    "39"
  ],
  [
    "Venezuela",
    "ve",
    "58"
  ],
  [
    "Vietnam (Việt Nam)",
    "vn",
    "84"
  ],
  [
    "Wallis and Futuna",
    "wf",
    "681"
  ],
  [
    "Yemen (‫اليمن‬‎)",
    "ye",
    "967"
  ],
  [
    "Zambia",
    "zm",
    "260"
  ],
  [
    "Zimbabwe",
    "zw",
    "263"
  ]
];

export const REG_DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const RATINGARRAY = [
  { name: 'Too Bad', value: 1, checked: false, unselectedicon: './assets/icons/rating/1_unselected.svg', selectedicon: './assets/icons/rating/1_selected.svg' },
  { name: 'Bad', value: 2, checked: false, unselectedicon: './assets/icons/rating/2_unselected.svg', selectedicon: './assets/icons/rating/2_selected.svg' },
  { name: 'Good', value: 3, checked: false, unselectedicon: './assets/icons/rating/3_unselected.svg', selectedicon: './assets/icons/rating/3_selected.svg' },
  { name: 'Very Good', value: 4, checked: false, unselectedicon: './assets/icons/rating/4_unselected.svg', selectedicon: './assets/icons/rating/4_selected.svg' },
  { name: 'Excellent', value: 5, checked: false, unselectedicon: './assets/icons/rating/5_unselected.svg', selectedicon: './assets/icons/rating/5_selected.svg' }
];
export const CARDARRAY = {
  "AMEX": {
    icon: 'assets/icons/cards/ae.svg'
  },
  "VISA": {
    icon: 'assets/icons/cards/visa.svg'
  },
  "MASTERCARD": {
    icon: 'assets/icons/cards/mastercard.svg'
  },
  "MADA": {
    icon: 'assets/icons/cards/mada.svg'
  },
  "DEFAULT": {
    icon: 'assets/icons/cards/othercards.svg'
  }
}

export const NORMALCYARROWS = {
  H: 'assets/icons/trends/arrow_up_1.svg',
  HH: 'assets/icons/trends/arrow_up_2.svg',
  HHH: 'assets/icons/trends/arrow_up_3.svg',
  L: 'assets/icons/trends/arrow_down_1.svg',
  LL: 'assets/icons/trends/arrow_down_2.svg',
  LLL: 'assets/icons/trends/arrow_down_3.svg',
  N: 'assets/icons/trends/normal.svg'
}

export const DEFAULTNORMALCY = [
  {
    "code": "N",
    "value": [
      { "locale": "en", "name": "Normal" }
    ],
    "controls": {
      "section": {
        "normalIndColor": "#4BB65E",
        "normalIndType": "N"
      }
    }
  },
  {
    "code": "L",
    "value": [
      { "locale": "en", "name": "Low" }
    ],
    "controls": {
      "section": {
        "normalIndColor": "#ED2626",
        "normalIndType": "L"
      }
    }
  },
  {
    "code": "H",
    "value": [
      { "locale": "en", "name": "High" }
    ],
    "controls": {
      "section": {
        "normalIndColor": "#ED2626",
        "normalIndType": "H"
      }
    }
  }
]
export const POINTSTYPEDEFAULT = 'DISCOUNT'