import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public filters;
  public selectedLanguage: string;
  constructor(private user: UserService, ) {
    this.selectedLanguage = this.user.currentLanguage;
   }

  ngOnInit() {
    this.filters = filterTypes;
  }
}

export const filterTypes = [
  {
    type: 'UA',
    name: 'Upcoming apppointments'
  },
  {
    type: 'PA',
    name: 'Past apppointments'
  },
  {
    type: 'UT',
    name: 'Upcoming tasks'
  },
  {
    type: 'PT',
    name: 'Past tasks'
  }
]