<div id="search-speciality" [dir]="dir">
  <div class="search-section">
    <div class="slot-options">
      <div class="info-msg-text-wrapper" 
      *ngIf="((visitType == 'VIDEO' ? this._input.in?.practitioner?.schedulerControls?.addNotesForVC :this._input.in?.practitioner?.schedulerControls?.addNotesForClinic) | translate : 'LOCALE' : 'name':null:false) as addinfo">
        <div class="image">
         <img src="assets/icons/slot/info.svg">
        </div>
        <div class="info-text">
         <span class="info-text-msg">{{addinfo}}</span>
        </div>
      </div>
      <div class="clinic-list">
        <ng-container *ngIf="visitType=='VIDEO' && !hideFacilityList">
          <div class="clinic-card" *ngFor="let item of this._input.in['practitioner']['facList']">
            <div class="clinic cursor-pointer" (click)="changeActiveFacility(item)"
              [ngClass]="{active: item['facilityId']==selectedFacility['facilityId']}">
              {{item['facilityName'] | translate : translateType : 'name'}}
            </div>
          </div>
        </ng-container>
      </div>
      <div class="date-selection">
        <div class="date-picker cursor-pointer" (click)="openPicker()">
          <div class="date font-light-small">
            {{slotList.dates.length>0?(slotList.dates[0] | moment : 'DD MMM' : 'YYYY-MM-DD'):''}} -
            <span class="hide">
              {{slotList.dates.length>0?(slotList.dates[endIndex - 1] | moment : 'DD MMM' : 'YYYY-MM-DD'):''}}
            </span>
            <span class="show">
              {{slotList.dates.length>0?(slotList.dates[_endIndex - 1] | moment : 'DD MMM' : 'YYYY-MM-DD'):''}}
            </span>
          </div>
          <img class="type-image" src="assets/icons/calendar-simple.svg">
        </div>
        <div class="vaadin-hidden-date">
          <vaadin-date-picker [min]="curDate" [value]="curDate" id="date-range" class="vaadin-hidden-date"
            (change)="vaadinChange($event)" #vaadinDp>
          </vaadin-date-picker>
        </div>
      </div>
    </div>
    <div class="visible-slots hide">
      <div class="slot-table">
        <div class="slot-header">
          <div class="slot-action prev cursor-pointer hide" (click)="changeStart(-1)">
            <img src="assets/icons/Home/up arrow.svg">
          </div>
          <div class="slot-date hide" *ngFor="let item of slotList.dates | slice : startIndex : endIndex">
            <span class="">{{item | moment : 'ddd, DD MMM YYYY' : 'YYYY-MM-DD'}}</span>
          </div>
          <div class="slot-action next cursor-pointer hide " (click)="changeStart(1)">
            <!-- <img src="assets/icons/slot/left_arrow.svg"> -->
            <img src="./assets/icons/Home/up arrow.svg">
          </div>
        </div>
        <div class="slot-content">
          <div class="slot-morning">
            <div class="slot-action prev">
              <!-- <img src="assets/icons/cloud.svg"> -->
            </div>
            <div class="slot-date hide" *ngFor="let date of slotList.dates | slice : startIndex : endIndex">
              <div class="slot-date-content" *ngIf="(slotList['slots'] && (slotList['slots'][date] | json) != '{}');else NOSLOT ">
              <ng-container *ngIf="slotList['slots']?.[date]?.['mrng']">
                <div class="slots cursor-pointer" [ngClass]="{selectedSlot: (date == selectedDate && slot['time'] == selectedSlot['time'])}"
                  *ngFor="let slot of slotList['slots'][date]['mrng']"
                  (click)="completeSelection(date, slot)">
                  <div class="slot">{{slot['time'] | moment : 'hh:mm A' : 'HH:mm'}} <img class="slot-icon" src="./assets/icons/Book_new/morning.svg" alt=""></div>
                </div>
              </ng-container>
              <ng-container *ngIf="slotList['slots']?.[date]?.noon">
                <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][date]['noon']"
                  (click)="completeSelection(date, slot)" [ngClass]="{selectedSlot: (date == selectedDate &&
                  slot['time'] == selectedSlot['time'])}">
                  <div class="slot">{{slot['time'] | moment : 'hh:mm A' : 'HH:mm'}} <img class="slot-icon" src="./assets/icons/Book_new/noon_.svg" alt=""></div>
                </div>
              </ng-container>
              <ng-container *ngIf="slotList['slots']?.[date]?.eve">
                <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][date]['eve'] "
                  (click)="completeSelection(date, slot)" [ngClass]="{selectedSlot: (date == selectedDate &&
                  slot['time'] == selectedSlot['time'])}">
                  <div class="slot">{{slot['time'] | moment : 'hh:mm A' : 'HH:mm'}} <img class="slot-icon" src="./assets/icons/Book_new/evening.svg" alt=""></div>
                </div>
              </ng-container>
            </div>
            <ng-template #NOSLOT>
              <div class="no-slot-wrapper">
                <div class="no-slot-image">
                  <img src="./assets/icons/slot/no_slots.svg"  class="no-slot-image-icon">
                </div>
                <div class="no-slot-text">
                 <p class="no-slot-text-label">{{'No_slots_available' | translate}}</p>
                </div>
              </div>
            </ng-template>
            </div>
            <div class="slot-action next">
            </div>
          </div>
          <div  *ngIf="noslot">
            <div class="no-slot-call-options">
              <div class="image">
                <img src="./assets/icons/slot/call_icon.svg">
    
              </div>
              <div class="text">
                <span class="contact-us-text">{{'Please_contact_us_for_any_help_in_booking_or_further_assistance._To_call_us,' | translate}}
                  <a class="web-click-here">{{facilityContactno}}</a>
                </span>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="visible-slots show" >
      <div class="slot-table">
        <div class="slot-header slot-header-wrapper">
          <!-- <div class="slot-action  cursor-pointer hide" (click)="changeStart(-1)">
            <img src="assets/icons/slot/left_arrow.svg">
          </div>
          <div class="show">
            <div class="slot-action cursor-pointer show" (click)="_changeStart(-1)">
              <img src="assets/icons/slot/left_arrow.svg">
            </div>
          </div> -->
          <div class="show show-icon short-date">
            <div class="slot-date cursor-pointer" *ngFor="let item of slotList.dates | slice : _startIndex : _endIndex; let i=index"
              [ngClass]="{'selected': item === selectedDate}" (click)="onDateSelect(item)">
              <span class="date-label">{{item | moment : 'ddd' : 'YYYY-MM-DD'}}</span>
              <span class="day-label bold">{{item | moment : 'DD' : 'YYYY-MM-DD'}}</span>
            <!-- <span class="font-default-title bold">{{item | moment : 'DD' : 'YYYY-MM-DD'}}</span>
            <span class="font-light-small day-name">{{item | moment : 'dddd':'YYYY-MM-DD'}}</span> -->
            </div>
          </div>
          <!-- <div class="show">
            <div class="slot-action cursor-pointer" (click)="_changeStart(1)">
              < !-- <img src="assets/icons/slot/left_arrow.svg"> -- >
              <img src="assets/icons/slot/right_arrow.svg">
            </div>
          </div> -->
        </div>
        <div class="slot-content slot-content-wrapper">
          <div class="slot-morning">
            <!-- <div class="slot-action prev">
              <img src="assets/icons/cloud.svg">
            </div> -->
            <div class="show show-icon" *ngIf="selectedSlots.length;else NOSLOTAPP">
              <!-- <div class="slot-date" *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots']&&slotList['slots'][date]"> -->
                  <div class="slots cursor-pointer"
                    [ngClass]="{
                      'selectedSlot': (selectedSlot['time'] && 
                        slot['time'] == selectedSlot['time']), 
                      'last-col': ((j + 1) % 3 === 0)
                    }"
                    *ngFor="let slot of selectedSlots; let j= index"
                    (click)="completeSelection(selectedDate, slot)">
                    <div class="slot">
                      <span>
                        {{slot['time'] | moment : 'hh:mm a' : 'HH:mm'}}
                      </span>
                      <div class="weather-icon">
                        <img [src]="slot['_icon']" alt="weather" class="weather">
                      </div>                  
                    </div>
                  </div>
                <!-- </ng-container>
              </div> -->
            </div>
            <!-- <div class="slot-action next">

            </div> -->
          </div>
            <ng-template #NOSLOTAPP>
              <div class="no-slot-icon-text-wrapper">
            <div class="no-slot-wrapper">
              <div class="no-slot-image">
                <img src="./assets/icons/slot/no_slots.svg"  class="no-slot-image-icon">
              </div>
              <div class="no-slot-text">
               <p class="no-slot-text-label">{{'No_slots_available' | translate}}</p>
              </div>
            </div>

            <div class="no-slot-call-options">
              <div class="image">
                <img src="./assets/icons/slot/call_icon.svg">

              </div>
              <div class="text">
                <span class="contact-us-text">{{'Please_contact_us_for_any_help_in_booking_or_further_assistance._To_call_us,' | translate}}
                  <a class="mobile-click-here" [href]="'tel:' + facilityContactno" target="_blank">{{'Click_here'| translate}}</a>
                </span>

              </div>
            </div>
          </div>
          </ng-template>
          <!-- <div class="slot-noon">
            <div class="slot-action prev">
              <img src="assets/icons/sun.svg">
            </div>
            <div class="show show-icon">
              <div class="slot-date" *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots']&&slotList['slots'][date]">
                  <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][date]['noon']"
                    (click)="completeSelection(date, slot)" [ngClass]="{selectedSlot: (date == selectedDate &&
                    slot['time'] == selectedSlot['time'])}">
                    <div class="slot">
                      <span>
                        {{slot['time'] | moment : 'hh:mm a' : 'HH:mm'}}
                      </span>
                      <div class="weather-icon">
                        <img src="assets/icons/slot/noon_.svg" alt="weather" class="weather">
                      </div>                  
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="slot-action next">

            </div>
          </div>
          <div class="slot-evening">
            <div class="slot-action prev">
              <img src="assets/icons/cloudy-night.svg">
            </div>
            <div class="show show-icon">
              <div class="slot-date " *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots']&&slotList['slots'][date]">
                  <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][date]['eve']"
                    (click)="completeSelection(date, slot)" [ngClass]="{selectedSlot: (date == selectedDate &&
                    slot['time'] == selectedSlot['time'])}">
                    <div class="slot">
                      <span>
                        {{slot['time'] | moment : 'hh:mm a' : 'HH:mm'}}
                      </span>
                      <div class="weather-icon">
                        <img src="assets/icons/slot/evening.svg" alt="weather" class="weather">
                      </div>                  
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="slot-action next">
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
