import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'geodistance'
})
export class GeodistancePipe implements PipeTransform {
  transform(value: string, currentLat: any, currentLon: any): any {
    if (currentLat || currentLon) {
      let unit = 'K';
      let valueArr = value.split(',');
      let lat1 = Number(currentLat);
      let lon1 = Number(currentLon);
      let lat2 = Number(valueArr[0].trim());
      let lon2 = Number(valueArr[1].trim());
      if ((lat1 == lat2) && (lon1 == lon2)) {
        dist = 0;
      }
      else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        let retVal = Math.round(dist).toString();
        return retVal;
      }
    } else {
      return ""
    }
  }

}
