import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmbedApptComponent } from './embed-appt.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    EmbedApptComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule, RouterModule
  ],
  exports:[EmbedApptComponent]
})
export class EmbedApptModule { }
