import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-manage-address-form',
  templateUrl: './manage-address-form.component.html',
  styleUrls: ['./manage-address-form.component.scss']
})
export class ManageAddressFormComponent implements OnInit {

  @Output() close: EventEmitter<any>;
  @Input() isEditPatient: any;
  @Input() addressDetails: any;
  @Input() configDetails: object;
  @Input() patientDetails: object;
  @Input() addressIndex: number;
  public changeAddressForm: UntypedFormGroup;
  public linkAddress: boolean = false;
  public applicableAddressTypes: Array<object>;
  public isAddressTypeDisabled: boolean = false;
  public cityList: Array<any>;
  public focusTown: boolean = false;
  public focusCity: boolean = false;
  public focusState: boolean = false;
  public focusAddressType: boolean = false;
  public hideFormInput: Object = {}

  constructor(private formBuilder: UntypedFormBuilder, private user: UserService) {
    this.close = new EventEmitter<any>();
    this.cityList = new Array<any>();
  }

  ngOnInit(): void {
    this.user.getCityDetails().subscribe(data => {
      this.cityList = data;
    });
    this.applicableAddressTypes = [];
    
    // Object.entries(view).forEach(v => {
    //   if (v[1]['hide']) {
    //     if (v[0] == 'careOf') {
    //       this.registerForm.get('guardianName').setValidators(null);
    //     }
    //     this.showHideForms[v[0]] = true;
    //   }
    let nonCheckType = ['acceptMultipleAddress', 'applicableAddressTypes']
    Object.entries(this.configDetails).forEach(v => {
      if (nonCheckType.includes(v[0])) return
      else if (v[1]['hide']) {
        this.hideFormInput[v[0]] = true;
      }
    });
    this.configDetails['applicableAddressTypes'].forEach(data=> {
      if(!this.patientDetails['patientAddress'].find(d=>d['addressType']===data['type']))
        this.applicableAddressTypes.push(data);
    });
    this.buildForm();
    if (this.isEditPatient) {
      let filterData = this.configDetails['applicableAddressTypes'].find(data => data['type'] === this.addressDetails['addressType']);
      if (filterData['mandatory'])
        this.isAddressTypeDisabled = true;
      this.fillAddressDetails();
    }
    else
      this.emptyAddressDetails();
  }
  public emptyAddressDetails() {
    this.addressDetails = {
      addressType: "",
      line1: "",
      line2: "",
      areaCode: "",
      areaDesc: "",
      cityCode: "",
      cityDesc: "",
      stateCode: "",
      stateDesc: "",
      countryCode: "",
      countryDesc: "",
      pinCode: "",
    }
  }

  public fillAddressDetails() {
    Object.entries(this.addressDetails).forEach(addr=>{
      switch(addr[0]) {
        case 'addressType':
          this.updateForm(addr[1],'addressType');
          break;
        case 'line1':
          this.updateForm(addr[1],'address');
          break;
        case 'line2':
          this.updateForm(addr[1],'address1');
          break;
        case 'areaDesc':
          this.updateForm(addr[1],'town');
          break;
        case 'pinCode':
          this.updateForm(addr[1],'pinCode');
          break;
        case 'cityDesc':
          this.updateForm(addr[1],'cityDesc');
          break;
        case 'stateDesc':
          this.updateForm(addr[1],'stateDesc');
          break;
      }
    });
  }

  public closeModal() {
    this.close.emit();
  }

  private buildForm() {
    this.changeAddressForm = this.formBuilder.group({
      addressType: new UntypedFormControl('', [Validators.required]),
      address: new UntypedFormControl('', null),
      address1: new UntypedFormControl('', null),
      pinCode: new UntypedFormControl('', null),
      city: new UntypedFormControl('', null),
      town: new UntypedFormControl('', null),
      state: new UntypedFormControl('', null)
    });
    this.checkMandatoryFields();
  }

  private checkMandatoryFields() {
    Object.entries(this.configDetails).forEach(v => {
      if(v[0]==='address'||v[0]==='address1'||v[0]==='pinCode'||v[0]==='city'||v[0]==='state'||v[0]==='town'){
        let mandatoryCrtl = v[1]['mandatory']?[Validators.required] : null;
        this.changeAddressForm.get(v[0]).setValidators(mandatoryCrtl);
        this.changeAddressForm.get(v[0]).updateValueAndValidity();
      }
    });
  }

  public isRequiredField(field: string) {
    let form_field = this.changeAddressForm.get(field);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  public resetForm() {
    this.closeModal();
    this.changeAddressForm.reset();
  }

  public updateAddress() {
    let patientAddress = this.patientDetails['patientAddress'];
    patientAddress.map(data => {
      delete data['primaryAddress'];
      delete data['isDeleteDisable'];
      delete data['linkAddress'];
    });
    if (this.isEditPatient) {
      patientAddress.splice(this.addressIndex, 1);
    }
    if (this.linkAddress)
      this.addressDetails['linkAddress'] = this.linkAddress;
    patientAddress.push(this.addressDetails);
    this.user.updateAddress(this.patientDetails['patientId'], patientAddress,this.linkAddress).subscribe(data => {
      this.closeModal();
    });
  }
  public print() {
    console.log("link address->",this.linkAddress);
  }
  public populateCityStateRegionField(x, source) {
    console.log("populateMultipleAddressField data->", source, x)
    this.addressDetails['areaCode'] = x['townCode'];
    this.addressDetails['areaDesc'] = x['townDesc'];
    this.addressDetails['cityCode'] = x['cityCode'];
    this.addressDetails['cityDesc'] = x['cityDesc'];
    this.addressDetails['stateCode'] = x['stateCode'];
    this.addressDetails['stateDesc'] = x['stateDesc'];
    console.log("populateMultipleAddressField data->", this.addressDetails);
  }

  public selectAddrType(value) {
    this.updateForm(value['type'], 'addressType');
  }

  public blurTown() {
    setTimeout(() => {
      if (!this.addressDetails['areaCode'] || !this.addressDetails['areaDesc'])
        this.updateForm(null, 'town');
      this.focusTown = false;
    }, 300);
  }

  public blurCity() {
    setTimeout(() => {
      if (!this.addressDetails['cityCode'] || !this.addressDetails['cityDesc'])
        this.updateForm(null, 'city');
      this.focusCity = false;
    }, 300);
  }

  public blurState() {
    setTimeout(() => {
      if (!this.addressDetails['stateCode'] || !this.addressDetails['stateDesc'])
        this.updateForm(null, 'state');
      this.focusState = false;
    }, 300);
  }

  public initAddressType() {
    if(!this.isAddressTypeDisabled)
      this.focusAddressType=true
  }

  public blurAddressType() {
    setTimeout(() => {
      console.log("change address form->", this.changeAddressForm);
      this.focusAddressType = false;
    }, 300);
  }

  public updateForm(value, key) {
    this.changeAddressForm.patchValue({
      [key]: value
    });
  }
  public joinString(arr) {
    return arr.filter(a => a && a != '' && a != null && a != undefined).join(',')
  }
}
