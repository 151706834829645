import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';


@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule, GoogleMapsModule, PersonAvatarModule, PipesModule
  ],
  exports: [
    MapComponent
  ]
})
export class MapModule { }
