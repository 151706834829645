import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myAlerts',
  pure: false
})
export class MyAlertsPipe implements PipeTransform {

  transform(events: any,filterdata:any): any {
    var filtetCategory = [];
    var filterTempdata= filterdata.filter(res =>{
       if(res.checked){
        filtetCategory.push(res.code);
        return res.code;
       }   
    });
    for (let e = 0; e < events.length; e++) {      
        if(events[e].readStatus != 'yes'){
          events[e].notificationReadStatus = false;
        }else{
          events[e].notificationReadStatus = true;
        }

      }
      var filterdata = events.filter(res => {
        let tmp=res.category;
        if(res.category == res.category.toUpperCase())
          tmp=res.category.toLowerCase();
        return filtetCategory.includes(tmp)
      })
      return filterdata;
  }
}
