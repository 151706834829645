import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { FeatureFlagsService } from './feature-flags.service';
import { ROUTE_UNAUTHORIZED, ROUTE_LOGIN, ROUTE_CRM_HOME, ROUTE_TASK, ROUTE_VIEW,  ROUTE_WEBACCESS, ROUTE_WEBVIEW } from '@string';
import { FT_APP_ENABLED, FT_APP_OLY_CMN_VIEW, FT_UNAUTHORIZED } from '@const';
import { Observable } from 'rxjs';
import { UnauthorizedPageComponent } from '../unauthorized-page/unauthorized-page.component';
import { UserService } from './user.service';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class AppEnabledService implements CanActivate {
  private isInitialized: boolean = false
  constructor(private router: Router, private featureFlagService: FeatureFlagsService, private user: UserService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (environment['ALLOW_ONLY_TASK']) {
      this.router.navigate(['/' + ROUTE_TASK + '/NOTVALID'])
    } else if (environment['ALLOW_ONLY_VIEW']){
      this.router.navigate(['/' + ROUTE_VIEW + '/NOTVALID']);
    } else if (!this.isInitialized) {
      return Observable.create(observer => {
        this.featureFlagService.rxjsFeatureInitSubject.subscribe(_ => {
          this.isInitialized = true;
          if (this.featureFlagService.featureOn(FT_APP_ENABLED)) {
            const enableOnlyCommonView = this.featureFlagService.featureOn(FT_APP_OLY_CMN_VIEW);
            const queryParams = route.queryParams;
            console.log("router==>", route.queryParams)
            if ((enableOnlyCommonView && queryParams['ctx']) || !enableOnlyCommonView) {
              if (route.data && route.data.permission && route.data.permission != FT_UNAUTHORIZED) {
                if (this.featureFlagService.featureOn(route.data.permission)) {
                  observer.next(true)
                } else {
                  if (enableOnlyCommonView)
                    this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
                  else
                    this.router.navigate([`/`]);
                }
              } else {
                observer.next(true)
              }
            } else {
              if (route.data && route.data.permission && route.data.permission == FT_UNAUTHORIZED) {
                return true
              } else
                this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
            }
          } else {
            if (!(route.data && route.data.permission) || (route.data && route.data.permission && route.data.permission != 'FT_UNAUTHORIZED'))
              this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
            else
              observer.next(true)
          }
        })
      });
    } else {
      if (this.featureFlagService.featureOn(FT_APP_ENABLED)) {
        const enableOnlyCommonView = this.featureFlagService.featureOn(FT_APP_OLY_CMN_VIEW);
        const queryParams = route.queryParams;
        console.log("router==>", route.queryParams, ((enableOnlyCommonView && queryParams['ctx']) || !enableOnlyCommonView))
        if ((enableOnlyCommonView && queryParams['ctx']) || !enableOnlyCommonView) {
          if (route.data && route.data.permission && route.data.permission != FT_UNAUTHORIZED) {
            if (this.featureFlagService.featureOn(route.data.permission)) {
              return true;
            } else {
              if (enableOnlyCommonView)
                this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
              else
                this.router.navigate([`/`]);
            }
          } else {
            return true;
          }
        } else {
          if (route.data && route.data.permission && route.data.permission == FT_UNAUTHORIZED) {
            return true
          } else
            this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
        }
      } else {
        if (!(route.data && route.data.permission) || (route.data && route.data.permission && route.data.permission != 'FT_UNAUTHORIZED'))
          this.router.navigate([`/${ROUTE_UNAUTHORIZED}`]);
        else
          return true;
      }
    }
  }
}
