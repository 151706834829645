import { Injectable } from '@angular/core';
import { GTAGFLOW } from '@const';
import { environment } from '@env/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UserService } from './user.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class GoogleTagsService {
  constructor(private gtm:GoogleTagManagerService) { }

  public pushGTMEvent(funnel: string, event: string, values?: Object) {
    if (!environment['isGTMEnabled'])
      return;
    let obj = {
      'event': event,
      'event_cat': 'Patient Portal'
    };
    if (funnel)
      obj['funnel_name'] = funnel;
    console.log('object', obj);
    obj = Object.assign(obj, GTAGFLOW[funnel || 'common'][event]);
    obj = Object.assign(obj, values);
    this.gtm.pushTag(obj);
    // this.gtmService.pushTag()
  }
}
