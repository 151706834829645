import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-patient-chat',
  templateUrl: './patient-chat.component.html',
  styleUrls: ['./patient-chat.component.scss']
})

export class PatientChatComponent implements OnInit {
  @Input() encounter: any;
  @Input() patient: any;
  @Input() lang: string;
  @Output() close: EventEmitter<any>;

  public messages: any;
  public patientId: string;
  public personId: string;
  public obs: any;
  public channelId;
  public files = [];
  public fileToPreview;
  public fetchedMessages: boolean = false;
  public participantDetails:any;
  constructor(private user: UserService) {
    this.close = new EventEmitter<any>();
    this.messages = {
      offset: 0,
      data: [],
      group: []
    };
  }

  ngOnInit() {
    if (!this.encounter)
      return;
    this.patientId = this.encounter['patientId'];
    this.personId = this.encounter['personId'] || this.patient['personid'];
    this.channelId = `${this.encounter['practitionerId'].split('#')[0]}#${this.encounter['patientId']}`;
    this.fetchMessages();
  }

  public fetchMessages() {
    this.user.fetchMessages(this.channelId, this.messages.offset, this.encounter.apptId, true).subscribe((data: Array<any>) => {
      console.log(data);
      this.messages.data = this.messages.data.concat(data['data']);
      this.messages.offset += data['data'].length;
      this.groupMessage();
      this.fetchedMessages = true;
      this.participantDetails=data['participantDetails'];
    }, err => {
      this.fetchedMessages = true;
    });
  }


  public onScroll() {
    console.log('scrolled!!');
    // this.fetchMessages();
  }

  public groupMessage(newMessage?: Object) {
    if (!_.isEmpty(newMessage)) {
      this.messages.data.unshift(newMessage)
      this.messages['offset']++;
    }
    this.messages.data = this.messages.data.map(m => {
      m['url'] = m['url'] ? m['url'] : null;
      return m
    })
      this.messages.group = _.values(_.groupBy(this.messages.data, (m) => moment(m['dateTime']).format('DD MM YYYY')));
    console.log(this.messages);
  }

  public closeModel() {
    this.close.emit();
  }
}
