import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormpreviewComponent } from './formpreview.component';
import { DirectiveLinkedPrintPreviewModule } from '../directive-linked-print-preview/directive-linked-print-preview.module';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [FormpreviewComponent],
  imports: [
    CommonModule, DirectiveLinkedPrintPreviewModule, PipesModule
  ],
  exports: [
    FormpreviewComponent
  ]
})
export class FormpreviewModule { }
