<div class="ui input fluid focus">
  <div class="task-section-title bold">
    <span
      *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span>
    <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
  </div>
  <div class="input-group text-input" [ngClass]="{valid: section['selected']}">
    <div class="label">
      <label for="inputId" class="input-label">
        <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
        <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
      </label>
    </div>
    <div class="value">
      <ng-container
        *ngIf="section.codify!=='Y' || (section.domainCode !== 'SYSPATSRCH' && section.domainCode !== 'PTEMAIL')">
        <input class="text-type-width" value="section['selected']" [tabindex]="tabindex"
          (keyup)="changeInText($event.target.value)" [(ngModel)]="section['selected']" type="text">
      </ng-container>
      <ng-container *ngIf="section.codify==='Y' && section.domainCode === 'SYSPATSRCH'">
        <input class="text-type-width" [disabled]="section.selectedPatient?true:null" [tabindex]="tabindex"
          value="section['_selected']" (keyup)="changeInText($event.target.value)" [(ngModel)]="section['_selected']"
          type="text">
        <button (click)="checkForPatient()" *ngIf="!section.selectedPatient" class="check-btn">
          <img class="type-image" src="assets/new/search_white.svg">
        </button>
        <button (click)="clearPatient()" *ngIf="section.selectedPatient" class="clear-btn">
          <img class="type-image" src="assets/new/close_white.svg">
        </button>
      </ng-container>
      <ng-container *ngIf="section.codify==='Y' && section.domainCode === 'PTEMAIL'">
        <input class="text-type-width" value="section['selected']" [tabindex]="tabindex"
          (keyup)="checkIsEmailValid(section['selected'])" [(ngModel)]="section['selected']" type="text">
      </ng-container>
      <!-- <app-form-input-error [control]="registerForm.controls.guardianName"></app-form-input-error> -->
      <p *ngIf="showAlertEmail" class="error-valid" padding-left padding-right>
        {{'enter_valid_mail' | translate}}
      </p>
      <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
        <p>{{'form_field_mandatory' | translate}}</p>
      </div>
      <div class="mandatory right" *ngIf="section._error">
        {{section._error}}
      </div>
    </div>
  </div>
  <ng-container *ngIf="section.selectedPatient">
    <div class="patient-card">
      <div class="_row">
        <div class="patient-name"><span>{{'frm_name' | translate}}</span>{{section.selectedPatient.patientName}}</div>
        <div class="patient-gender"><span>{{'frm_gender' | translate}}</span>{{section.selectedPatient.gender | sex}}</div>
      </div>
      <div class="_row">
        <div class="patient-dob"><span>{{'frm_date_birth' | translate}}</span>{{section.selectedPatient.dob | moment:'D MMM YYYY'}}</div>
        <div class="patient-mobile"><span>{{'frm_mobile' | translate}}</span>{{section.selectedPatient.mobile}}</div>
      </div>
    </div>
  </ng-container>
</div>
