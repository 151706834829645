import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PackageService } from '../services/package.service';
import { UserService } from '../services/user.service';
import { ConnectableObservable } from 'rxjs';

@Component({
  selector: 'app-my-package-details',
  templateUrl: './my-package-details.component.html',
  styleUrls: ['./my-package-details.component.scss']
})
export class MyPackageDetailsComponent {
  @Input() selectedPackage;
  @Output() close = new EventEmitter();
  showInclServAccord: boolean;
  showPkgAccord: boolean;
  packageDetails: any;

  constructor(private packageService: PackageService, private user:UserService) {

  }
  ngOnInit(): void {
    if (this.selectedPackage)
      this.getPackageDetail(this.selectedPackage);
  }

  getPackageDetail(pkg) {
    let inparam = {
      patientId: pkg.patientId,
      facilityId: pkg.facilityId,
      package: [
        {
          packageCode: pkg.packageCode,
          packageSeq: pkg.subscriptionId,
          appointmentId: pkg.appointment_id
        }
      ]
    }
    this.packageService.getPackageDetail(inparam).subscribe(data => {
      console.log('packageDtl--', data)
      if (data.packageServDtls && data.packageServDtls.length > 0) {
        pkg['pkgList'] = data.packageServDtls && data.packageServDtls.map((p) => {
          p['desc'] = p['serviceDesc'];
          return { desc: p['serviceDesc'] };
        })
        pkg['serviceIncluded'] = data.packageServDtls
        this.showPkgAccord = false;
        this.showInclServAccord = false;
        this.packageDetails = pkg;

        console.log('selected package--', pkg)
      }
    })
  }

  closeModal() {
    this.close.emit(true);
  }

  getPackageContent(detail){
    console.log("getPackageContent detail", detail)
    let t = detail.find(name => {
      return name.locale == this.user.currentLanguage
    })
    if (t) {
        return t.packageContent;
    } else {
        return detail[0].packageContent;
    }
  }
  onBack() {
     this.close.emit(false);
  }
}
