import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StageComponentComponent } from './stage-component.component';

@NgModule({
  declarations: [StageComponentComponent],
  imports: [
    CommonModule
  ],
  exports:[StageComponentComponent]
})
export class StageComponentComponentModule { }