<div class="pdfxviewer">
    <div class="header">
        <div class="font-primary-subtitle bold">
            <span (click)="closeWindow()" class="arrow">
                <img class="" src="./assets/icons/arrow.svg">
            </span>
            <span class="arrow">
                <img class="type-image" src="assets/icons/pdf.svg" >
                <span class="no-margin title">{{ title }}</span>
            </span>
        </div>
        <div class="float-right closeicon pointer">
            <span (click)="download()" class="arrow">
                <img class="type-image" src="assets/icons/download.svg" alt="">
            </span>
            <span  (click)="print()" class="arrow">
              <img class="type-image" src="assets/icons/printing.svg" >
            </span>
        </div>
    </div>

    <div class="content">
        <pdf-viewer (onError)="error($event)" [original-size]="false" [autoresize]="true" [show-borders]="true" (after-load-complete)="stopLoader()" [src]="URL" [render-text]="true" style="display: block;"></pdf-viewer>
    </div>
  </div>
