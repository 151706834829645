<p class="section-title">{{'Selected Doctor' | translate}}</p>
<div class="doctor-card" *ngIf="selectedPract">
    <div class="doctor-card-main">
        <div class="image">
            <app-person-avatar [url]="selectedPract['photoUrl']" [name]="selectedPract['practitionerName']">
            </app-person-avatar>
        </div>
        <div class="doctor-card-content">
            <p class="doc-name">{{selectedPract['practitionerName']}}</p>
            <p class="doc-text">{{selectedPract['clinicName']}}</p>
            <ng-container *ngIf="isAvailable(selectedPract); else nextAvailableTemp">
                <p class="doc-text">{{'Currently_Available' | translate}}</p>
            </ng-container>
            <ng-template #nextAvailableTemp>
                <p class="doc-text" *ngIf="selectedPract['nextAvailableTime']">{{'Next_available_in' | translate}} {{getNextAvailableTime(selectedPract)}}</p>
                <p class="doc-text" *ngIf="!selectedPract['nextAvailableTime']">{{'Currently_Unavailable' | translate}}</p>
            </ng-template>
        </div>
        <div class="doctor-edit-icon" *ngIf="!queryParams || !queryParams['pid'] || queryParams['pid'].split(',').length > 1">
            <img src="assets/icons/edit_grey.svg" (click)="backToSelect()" alt="">
        </div>
    </div>
    <div class="doc-card-bottom">
        <p class="bottom-text">
            <img src="assets/icons/calendar/cash_blue.svg" alt="">
            {{visitCharges.currency}} {{visitCharges?.patientPayable || 0}}
        </p>
        <p class="bottom-text">
            <img src="assets/icons/pin.svg" alt="">
            {{facility?.facilityName}}
        </p>
    </div>
</div>
<p class="section-title">{{'Identify Patient' | translate}}</p>
<div class="section-card identify-patient">
    <div class="select-section">
        <div class="section-label" (click)="selectedLoginType = 'M'" [ngClass]="{'selected': selectedLoginType == 'M'}">
            <input type="radio" [checked]="selectedLoginType == 'M'">
            {{'By Mobile Number' | translate}}
        </div>
        <div class="section-label" (click)="selectedLoginType = 'P'" [ngClass]="{'selected': selectedLoginType == 'P'}">
            <input type="radio" [checked]="selectedLoginType == 'P'">
            {{'By UHID' | translate}}
        </div>
    </div>
    <div class="phone-input" *ngIf="selectedLoginType == 'M'">
        <app-phone-input direction="row" (phoneInput)="setPhoneNumber($event)">
        </app-phone-input>
    </div>
    <div class="phone-input" *ngIf="selectedLoginType == 'P'">
        <input type="text" class="patient-id" [(ngModel)]="patientId" placeholder="Enter UHID" autofocus>
    </div>
</div>
<div class="section-button-wrapper">
    <button class="section-action" [ngClass]="{'disabled': (selectedLoginType == 'M' && !isPhonValid) || (selectedLoginType == 'P' && !patientId)}"
     [disabled]="(selectedLoginType == 'M' && !isPhonValid) || (selectedLoginType == 'P' && !patientId)" (click)="continue()">{{'Continue'}}</button>
</div>

<div class="visit-modal-overlay" *ngIf="viewOTPModal || viewPatientsModal">
    <div class="visit-modal" *ngIf="viewOTPModal">
        <div class="visit-modal-header">
            <p>{{'OTP Verification' | translate}}</p>
            <img src="./assets/icons/close_black.svg" class="close-icon" (click)="closeOTPModal()" alt="">
        </div>
        <div class="visit-modal-content otp-modal">
            <div class="otp-wrapper">
                <app-partition-input [(ngModel)]="otp"></app-partition-input>
            </div>
            <div class="otp-sent-text">OTP has been sent successfully to the mobile number {{maskedMobile}}</div>
            <div class="resend-otp" *ngIf="timer == null" (click)="resendOTP()">Resend OTP</div>
            <div class="otp-sent-text"  *ngIf="timer">Resend OTP in {{timer}}</div>
        </div>
        <div class="visit-modal-footer">
            <button class="primary-button" [disabled]="otp.length < 6" [ngClass]="{'disabled': otp.length < 6}"
             (click)="signinPatient()">{{'Verify OTP' | translate}}</button>
        </div>
    </div>
    <div class="visit-modal" *ngIf="viewPatientsModal">
        <div class="visit-modal-header">
            <p>{{'Choose Patient' | translate}}</p>
            <img src="./assets/icons/close_black.svg" class="close-icon" (click)="viewPatientsModal = false" alt="">
        </div>
        <div class="visit-modal-content patient-modal">
            <div class="patient-item" *ngFor="let p of patients" (click)="selectedPatient = p">
                <div class="image">
                    <app-person-avatar [url]="p['photoUrl']" [name]="p['patientName']"> </app-person-avatar>
                </div>
                <div class="patient-card-content">
                    <p class="patient-name">{{p['patientName']}}</p>
                    <p class="patient-text">{{p['patientId']}}, {{p['gender'] == 'F'? 'Female':'Male'}}, {{p['dob'] | age}}</p>
                </div>
                <div class="patient-checkbox">
                    <input type="radio" name="" [checked]="selectedPatient?.['patientId'] == p['patientId']" id="">
                </div>
            </div>
            <div class="patient-item" (click)="selectedPatient = {type: 'REG_PAT'}">
                <div class="iimmgg">
                    <img src="./assets/icons/id-card.svg" alt="">
                </div>
                <div class="patient-card-content">
                    <p class="patient-name">{{'Register_a_new_Patient' | translate}}</p>
                    <p class="patient-text">{{'If_visiting_for_first_time_use_this_option' | translate}}</p>
                </div>
                <div class="patient-checkbox">
                    <input type="radio" name="" [checked]="selectedPatient?.['type'] == 'REG_PAT'" id="">
                </div>
            </div>
        </div>
        <div class="visit-modal-footer">
            <button class="primary-button" (click)="choosePatient()">{{'Continue ' | translate}}</button>
        </div>
    </div>
</div>