import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportGraphComponent } from './report-graph.component';
import { GraphComponent } from './graph/graph.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [ReportGraphComponent, GraphComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [
    ReportGraphComponent, GraphComponent
  ]
})
export class ReportGraphModule { }
