import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'facilityDetails'
})
export class FacilityDetailsPipe implements PipeTransform {
  private facilitiesList: any;
  constructor(private user: UserService) {
    this.user.getUpdateInfo('facilities').subscribe(data => {
      this.facilitiesList =  data ? data.facilities : [];
    });
  }
  transform(value: any, key: string): any {
    let val = this.facilitiesList.filter(fac => {
      return fac.facilityId == value;
    })[0]
    val=val?val[key]:'';
    return val;
  }
}
