import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionaltopic',
  pure: true
})
export class ConditionaltopicPipe implements PipeTransform {
  public transform(topics: Array<Object>, length?:any, ts?:any) {
    return topics.filter(topic => {
      if (!topic['conditionalTopicId'])
        return true;
      return this.checkConditionalTopic(topic, topics)
    });
    // return result || [];
  }
  checkConditionalTopic(topic, topics) {
    let tmp = true;
    try {
      const condTopic = topics.filter(t => t.id === topic.conditionalTopicId);
      if (condTopic.length > 0) {
        for (let m = 0; m < condTopic[0].sections.length; m++) {
          if (condTopic[0].sections[m].domainCode === topic.conditionalFieldCode) {
            if (condTopic[0].sections[m].conditional) {
              if (this.checkConditional(condTopic[0], condTopic[0].sections[m])) {
                tmp = this.checkConditional(condTopic[0], topic);
              } else {
                tmp = false;
              }
            } else {
              tmp = this.checkConditional(condTopic[0], topic);
            }
          }
        }
      }
    } catch (err) {
    }
    return tmp;
  }
  checkConditional(t, s) {
    let temp = true;
    if (!s.conditional) {
      temp = true;
    }
    else {
      for (let i in t.sections) {
        if (t.sections[i].codify === 'Y') {
          if (s.conditionalFieldCode === t.sections[i].domainCode) {
            let cc: any = '';
            if (t.sections[i].conditional) {
              cc = this.checkConditional(t, t.sections[i]);
            }
            if (cc == '' || cc) {
              if (!t.sections[i].selected) {
                temp = false;
              }
              else {
                if (!s.operation.lg || s.operation.lg === "null" || s.operation.lg === "NULL" || s.operation.lg === null) {
                  if (t.sections[i].type === 'check' || (t.sections[i].type === 'select' && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].type === 'check' ? t.sections[i].selected.split(',') : t.sections[i].selected;
                    if (Array.isArray(s.conditionalFieldValue)) {
                      if (selectedArray.indexOf(s.conditionalFieldValue[0].value) > -1) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selectedArray.indexOf(s.conditionalFieldValue) > -1) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    if (Array.isArray(s.conditionalFieldValue)) {
                      if (t.sections[i].selected === s.conditionalFieldValue[0].value) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (t.sections[i].selected === s.conditionalFieldValue) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else if (s.operation.lg == 'lt' && s.operation.eq) {
                  if (Array.isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected <= s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected <= s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'gt' && s.operation.eq) {
                  if (Array.isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected >= s.conditionalFieldValue[0].value) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected >= s.conditionalFieldValue) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'lt' && !s.operation.eq) {
                  if (Array.isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected < s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected < s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'gt' && !s.operation.eq) {
                  if (Array.isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected > s.conditionalFieldValue[0].value) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected > s.conditionalFieldValue) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'in') {
                  //
                  if (t.sections[i].type === 'check' || (t.sections[i].type === 'select' && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].type === 'check' ? t.sections[i].selected.split(',') : t.sections[i].selected;
                    //console.log("IN =>", selected, s.conditionalFieldValue);
                    if (Array.isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selectedArray.indexOf(s.conditionalFieldValue[i].value) > -1) {
                          // console.log("Matched in");
                          tmp = true;
                        }
                      }
                      if (tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selectedArray.indexOf(s.conditionalFieldValue) > -1) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    let selected = t.sections[i].selected;
                    //console.log("IN =>", selected, s.conditionalFieldValue);
                    if (Array.isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selected == s.conditionalFieldValue[i].value) {
                          // console.log("Matched in");
                          tmp = true;
                        }
                      }
                      if (tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selected == s.conditionalFieldValue) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else if (s.operation.lg == 'notin') {
                  if (t.sections[i].type === 'check' || (t.sections[i].type === 'select' && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].type === 'check' ? t.sections[i].selected.split(',') : t.sections[i].selected;
                    if (Array.isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selectedArray.indexOf(s.conditionalFieldValue[i].value) > -1) {
                          tmp = true;
                        }
                      }
                      if (!tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selectedArray.indexOf(s.conditionalFieldValue) == -1) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    let selected = t.sections[i].selected;
                    if (Array.isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selected == s.conditionalFieldValue[i].value) {
                          tmp = true;
                        }
                      }
                      if (!tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selected != s.conditionalFieldValue) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else {
                  if (Array.isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected === s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected === s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
              }
            }
            else {
              temp = false;
            }
          }
        }
      }
    }
    return temp;
  }

}
