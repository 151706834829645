<div id="search-service">
  <div class="service-wrapper service-wrapper__search">
    <!-- <div class="service-card">
      <div class="service-header border-bottom">
        <div class="bold">{{'ttl_appointment_details'|translate:'LABELS'}}</div>
      </div>
      <div class="appt-facility">
        <div class="appt-facility-details">
          <div>
            <img class="type-image" src="assets/images/signin/logo_minimal.png" />
            <span> {{ _input['in']['facility']['name'] | translate : 'LOCALE' : 'name'}}</span>
          </div>
          <div class="appt-facility-addr">
            <img class="type-image" src="assets/icons/pin_blue.svg" />
            <span class="secondary-text"> {{ _input['in']['facility']['address'] }}
              <span class="secondary-text" *ngIf="clinicMobileNumber">{{ clinicMobileNumber }}</span></span>
          </div>
          <div class="appt-facility-contact">
            <span class="secondary-text responsive-padding"> {{_input['in']['facility']['distance']}}
              {{'lbl_kms'|translate}}</span>
            <span class="responsive-float-right">
              <img class="nav-icon cursor-pointer type-image" src="assets/icons/navigation.svg" (click)="navigateUser()"/>
              <img class="call-icon cursor-pointer type-image" src="assets/icons/phone.svg" *ngIf="clinicMobileNumber" (click)="call()"/>
            </span>
          </div>
        </div>
        <div class="appt-facility-pract-patient" *ngIf="practDetails">
          <div class="appt-facility-pract">
            <div class="image">
              <app-practitioner-avatar [url]="practDetails['photoUrl']"></app-practitioner-avatar>
            </div>
            <div class="appt-facility-pract-details">
              <span>{{ 'dr_label' |translate: 'LABELS'}} {{ practDetails['practitionerName'] | translate : translateType : 'name' }}</span>
              <span class="secondary-text">{{ practDetails['specialityName'] | translate : translateType : 'name' }}</span>
            </div>
          </div>
          <div class="border-sperator"></div>
          <div class="appt-facility-pract">
            <div class="image">
              <app-person-avatar [url]="_input['in']['patient']['image']"> </app-person-avatar>
            </div>
            <div class="appt-facility-pract-details">
              <span>{{_input['in']['patient']['name']}}</span>
              <span class="secondary-text">{{_input['in']['patient']['relation'] | translate : 'RELATION'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="search" id="search-static">
      <img class="type-image" src="./assets/icons/Book_new/search.svg">
      <input class="search__input" [(ngModel)]="searchKey" type="text" placeholder="{{'plhd_search_service'|translate}}">
      <div class="clear-search cursor-pointer" (click)="searchKey=''" *ngIf="searchKey">
        <img src="assets/icons/close_grey.svg" alt="clear-icon" class="clear-icon">
      </div>
    </div>
  </div>
  <div class="service-wrapper service-wrapper__title">
    <div class="service-title">
      <div class="bold">{{'choose_services'|translate:'LABELS'}}</div>
    </div>
  </div>
  <div class="service-wrapper service-wrapper__card">
    <div class="service-card">
      <!-- <div class="service-header border-bottom">
        <div class="bold">{{'choose_services'|translate:'LABELS'}}</div>
      </div> -->
      <div class="service-details-cards">
        <div class="service-card-details" 
          *ngFor="let service of availableServices| searchFilter:searchKey:'conceptName'; let i = index">
          <div class="service-card__wrapper cursor-pointer" (click)="selectService(service)">
            <div class="service-web">
              <label class="custom-checkbox__group">
                <input class="custom-checkbox__input" 
                  [(ngModel)]="service['selected']" readonly type="checkbox"/>
                <span class="custom-checkbox__button"></span>
              </label>
              <div class="service-card-details-column">
                <!-- <span>{{service['conceptName']}}</span> -->
                <span class="bold">{{ getServiceName(service['details'],service['conceptName'])}}</span>
                <span class="secondary-text">Approx consultation time {{service['details']['servicetime']}}
                  {{ service['details']['servicetimehm'] == 'H'?'hours':'minutes' }}</span>
              </div>
            </div>
            <div class="service-mobile">
              <label class="custom-checkbox__group">
                <input class="custom-checkbox__input" 
                  [(ngModel)]="service['selected']" type="checkbox"
                  (change)="onSelectService(service, service['selected'])"/>
                <span class="custom-checkbox__button"></span>
                <span class="bold service-name">{{ getServiceName(service['details'],service['conceptName'])}}</span>
              </label>
              <div class="service-card-details-column">
                <!-- <span>{{service['conceptName']}}</span> -->
                <span class="secondary-text">Approx consultation time {{service['details']['servicetime']}}
                  {{ service['details']['servicetimehm'] == 'H'?'hours':'minutes' }}</span>
              </div>
            </div>
            <div class="service-card-details-amount">
              <span *ngIf="service['details']['originalcost']" class="secondary-amount bold strike-text">
                {{service['details']['originalcost']}} {{currency_code}}
              </span>
              <span *ngIf="service['details']['servicecost']" class="secondary-amount bold">
                {{service['details']['servicecost']}} {{currency_code}}
              </span>
            </div>
          </div>
        </div>
        <div class="no-data" *ngIf="!availableServices.length">
          <app-nodata></app-nodata>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="service-wrapper service-wrapper-customize service-footer">
  <div class="service-card service-card-customize">
    <div class="service-header service-header-customize">
      <!-- <span class="bold">{{'total'|translate}}</span> -->
      <span class="secondary-text">{{ selectedServices.length }} {{'services_selected'|translate}}</span>
    </div>
    <span *ngIf="totalAmount" class="pad-right bold amount-primary">{{currency_code}} {{ totalAmount }}</span>
  </div>
  <button class="primary-button btn-customize" (click)="completeSelection()" [disabled]="!selectedServices.length">{{'btn_continue'|translate}}</button>
</div>
