import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

@Component({
  selector: 'app-numericdecimalrange',
  templateUrl: './numericdecimalrange.component.html',
  styleUrls: ['./numericdecimalrange.component.css']
})
export class NumericdecimalrangeComponent extends Type implements OnInit, SectionInterface {

  @Input() public section;
  @Input() public sections;
  @Input() public tabindex;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideNumericSection(this.section);
  }
}
