import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/user.service';
import * as AppConstant from '../app.string';
@Component({
  selector: 'app-company-description',
  templateUrl: './company-description.component.html',
  styleUrls: ['./company-description.component.scss']
})
export class CompanyDescriptionComponent implements OnInit {

  @Output() openPackages: EventEmitter<any>;
  public slider: Array<any>;
  constructor(private user: UserService) {
    this.openPackages = new EventEmitter<any>();
    // this.config.interval = 5000;
    // this.config.wrap = true;
  }

  ngOnInit() {
    this.user.getConfigurationInfo.subscribe(data => {
      let slideList;
      if (data.profile && data.profile.webSlidesForLanguages) {
        slideList = data.profile.webSlidesForLanguages[this.user.currentLanguage]
        if (!slideList || !Array.isArray(slideList) || slideList.length == 0) {
          slideList = data.profile.webSlidesForLanguages['en']
          if (!slideList || !Array.isArray(slideList) || slideList.length == 0) {
            slideList = data.profile.slides
          }
        }
        else {
          slideList = data.profile.webSlidesForLanguages[this.user.currentLanguage]
        }
      }
      else {
        slideList = data.profile.webAppSlides;
      }
      this.slider = slideList.map((v, i) => {
        v.slideIndex = i;
        return v;
      });
    });
  }

  openRoute(slide, index) {
    var link = slide.hyperlink;
    var pkgList;
    this.user.getUpdateInfo('directives').subscribe(data => {
      pkgList = data.userDefined;
    });
    console.log('slide clck---', slide, pkgList)
    if (slide && slide.actionType == 'PACKAGE') {
      if (pkgList && pkgList.length > 0) {
        let userDefinedDirective = pkgList;
        let checkService = userDefinedDirective.some(s => {
          if (s.code == slide.serviceValue) {
            return true;
          }
        })
        console.log('check service is there---', checkService)
        if (checkService) {
          for (let i = 0; i < userDefinedDirective.length; i++) {
            let checkPackageIsthere = userDefinedDirective[i].concepts.some(c => {
              if (slide.packageValue && slide.packageValue == c['code']) {
                return true;
              }
            })
            console.log('check package is there---', checkPackageIsthere, userDefinedDirective[i])
            if (checkPackageIsthere) {
              console.log('check package is there--- open pkg', checkPackageIsthere)
              this.openPackages.emit({
                directive: userDefinedDirective[i],
                toFilterPackage: slide.packageValue
              });
            } else {
              console.log('check package is there--- not open pkg', checkPackageIsthere)
              this.openPackages.emit({
                directive: userDefinedDirective[i]
              });
            }
          }
        } else {
          if (link != '' && link != null) {
            this.user.openLocation(link, "_blank");
          }
        }
      }
    } else {
      console.log('clicked', link);
      if (link != '' && link != null) {
        this.user.openLocation(link);
      }
    }

  }
}
