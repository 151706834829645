import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitHistoryComponent } from './hr-visit-history.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { NodataModule } from '../nodata/nodata.module';
import { DirectiveModule } from '../directive/directive.module';
import { PatientChatModule } from '../patient-chat/patient-chat.module';



@NgModule({
  declarations: [HrVisitHistoryComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule, NodataModule, DirectiveModule, PatientChatModule
  ],
  exports: [
    HrVisitHistoryComponent
  ]
})
export class HrVisitHistoryModule { }
