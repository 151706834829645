<div class="about-hospital-wrapper">
    <div class="header border-bottom">
        <div class="back-button" (click)="closeModal()">
            <img src="./assets/icons/contact-us/left_arrow.svg">
            <div class="header-title bold">{{'Hospital_Information' | translate}}
            </div>
        </div>

        <div class="float-right closeicon pointer" (click)="closeModal()">
            <img src="./assets/icons/close_grey.svg">
        </div>
    </div>

    <div class="contact-us-details">
        <div class="card1 row cursor-pointer">
            <div class="clinic-card-header">
                <label class="card-header-name">
                  <ng-container *ngFor="let fn of selectedHospital['facilityName']">
                    <ng-container *ngIf="fn['locale'] === currentLang">
                      <div class="title">
                        {{ fn['name'] ?? '-' | translate }}
                      </div>
                    </ng-container>
                  </ng-container>
                </label>
                <label class="card-header-address">  
                  <ng-container *ngFor="let fa of selectedHospital['facilityAddress']">
                    <ng-container *ngIf="fa['locale'] === currentLang">
                      <div class="address">
                        {{ fa['text'] ?? '-' | translate}}
                      </div>
                    </ng-container>
                  </ng-container>
                </label>
                <div class="reach-us">
                    <label class="reach-us-name">{{'Reach_us_at' | translate }}</label>
                    <div class="reach-us-details">
                        <a class="reach-us-mobile-no" href="tel:{{mobileNo}}" target="_blank">
                            <div class="image">
                              <p><img class="mobile-icon" src="./assets/icons/contact-us/call.svg">
                                <span class="mobile-no">
                                  <span class="call-us">{{'call us' | translate }}
                                </span >{{mobileNo ?? '-' | translate}} </span></p>
                            </div>
                          </a>
                        <div class="directions-details" (click)="openMap()">
                            <span class="directions" *ngIf="selectedHospital['showDirection']">
                                <div class="image">
                                    <img class="location-icon" src="./assets/icons/contact-us/location.svg">
                                    <span class="directions-text"> {{'Directions' | translate }} </span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="hospitalProfile['sections']">
                  <ng-container *ngFor="let section of hospitalProfile['sections']">
                    <ng-container *ngIf="section['sectionType'] === 'slideShow'">
                      <div class="gallery-details">
                        <div class="gallery-name">{{'Gallery' | translate }} </div>
                        <div class="image">
                          <!-- <img src="./assets/icons/contact-us/nahdi.png"> -->
                          <ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
                            <ng-template ngbSlide *ngFor="let content of section['content']">
                              <div class="picsum-img-wrapper" *ngIf="content">
                                <img [src]="content['imageUrl']" alt="slide" class="type-image"/>
                              </div>
                            </ng-template>
                          </ngb-carousel>
                        </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="section['sectionType'] === 'text'">
                      <div class="about-hospital">
                        <div class="about-hospital-header"> {{ section['title'] ?? '-' | translate }}</div>
                        <div class="about-hospital-text">
                            <span [innerHTML]="section['content']"></span>
                        </div>
                    </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- <div class="about-hospital">
                    <div class="about-hospital-header"> {{'About_the_hospital' | translate }}</div>
                    <div class="about-hospital-text">
                        <p>Superior care coming from our heart to yours is our operating philosophy going beyond our way
                            to cater to
                            your personal situation and needs. This philosophy is at the core of Nahdi, now being lived
                            everyday by all
                            our NahdiCare Clinic doctors and staff.</p>
                    </div>
                </div>
                <div class="our-vision">
                    <div class="our-vision-header"> {{'Our_Vision' | translate }}</div>
                    <div class="our-vision-text">
                        <p>Superior care coming from our heart to yours is our operating philosophy going beyond our way
                            to cater to
                            your personal situation and needs. This philosophy is at the core of Nahdi, now being lived
                            everyday by all
                            our NahdiCare Clinic doctors and staff.</p>
                    </div>
                </div>
                <div class="our-mission">
                    <div class="our-mission-header">{{'Our_Mission' | translate }}</div>
                    <div class="our-mission-text">
                        <p>Superior care coming from our heart to yours is our operating philosophy going beyond our way
                            to cater to
                            your personal situation and needs. This philosophy is at the core of Nahdi, now being lived
                            everyday by all
                            our NahdiCare Clinic doctors and staff.</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>