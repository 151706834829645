import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-appointment-visit-type',
  templateUrl: './appointment-visit-type.component.html',
  styleUrls: ['./appointment-visit-type.component.scss']
})
export class AppointmentVisitTypeComponent implements OnInit {
  @Output() complete: EventEmitter<any>;
  public isOnspot: boolean = false;
  public isExpressClinicEnabled: boolean = false;
  showClinicOpt = true;
  showVideoOpt = true;
  constructor(public user: UserService, private featureFlag: FeatureFlagsService) { 
    this.complete = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getConfigurationInfo.subscribe(data => {
      if(data.pxAppSettings){
        this.isExpressClinicEnabled = data['pxAppSettings']['enableExpressClinicYn'] == 'Y';
        if(data.pxAppSettings.onSpotConsultationYn == 'Y'){
          this.isOnspot = true;
        }
        else{
          this.isOnspot = false;
        }
      }
    });
    if (this.featureFlag.featureOff('FT_APP_BOOK_VC_APPT')) {
      this.chooseType('HOSPITAL')
    }
  }

  public chooseType(type) {
      this.complete.emit(type)
  }
}
