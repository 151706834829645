import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { HealthPackageComponent } from '../health-package/health-package.component';
import { HealthPackageDetailsModule } from '../health-package-details/health-package-details.module';
import { HealthPackageConfirmRequestModule } from '../health-package-confirm-request/health-package-confirm-request.module';
import { FilterModule } from '../filter/filter.module';
import { FormsModule } from '@angular/forms';
import { ListFacilityModule } from '../list-facility/list-facility.module';
@NgModule({
  declarations: [HealthPackageComponent],
  imports: [
    CommonModule,
    PersonAvatarModule, 
    PipesModule,
    ManageIdentitiesModule,
    HealthPackageDetailsModule,
    HealthPackageConfirmRequestModule,
    FilterModule,
    FormsModule,
    ListFacilityModule
  ],
  exports: [
    HealthPackageComponent
  ],
  providers: [TitleCasePipe]
})
export class HealthPackageModule { }
