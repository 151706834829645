import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmComponent } from './crm.component';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../header/header.module';
import { HomeModule } from '../home/home.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { PipesModule } from '../pipes/pipes.module';
import { FooterModule } from '../footer/footer.module';
import { DirectiveModule } from '../directive/directive.module';
@NgModule({
  declarations: [CrmComponent],
  imports: [
    CommonModule, RouterModule, NavigationModule, HeaderModule, HomeModule, PaymentCheckoutModule,
    PipesModule, FooterModule, DirectiveModule
  ],
  exports: [ CrmComponent]
})
export class CrmModule { }
