import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent{
  @Input() message = 'This application is not available at this time. Please contact IT support for further information'
  constructor() { 
  }

}
