<div id="change-password">
  <div class="content">
    <div class="title bold">
      {{'lbl_manage_address_title'|translate}}
    </div>
    <div class="manage-address-scroll">
      <div class="manage-address-wrapper" *ngFor="let patient of patientDetails">
        <div class="border-bottom">
          <div class="manage-address-patient-details">
            <div class="image">
              <app-person-avatar [url]=""> </app-person-avatar>
            </div>
            <p class="no-margin pad-right">{{patient['patientName']}}</p>
            <p class="no-margin secondary-text">{{patient['patientId']}}</p>
            <img class="plus-icon" [ngClass]="{'disable-add':patient['patientAddress'].length === configDetails['applicableAddressTypes'].length}" (click)="addAddress(false, null, patient)" src="assets/icons/add_plain.svg" />
          </div>
        </div>
        <div class="manage-address-details">
          <div class="manage-address-item" *ngFor="let addr of patient['patientAddress'];let ind=index;">
            <div class="manage-address-title">
              <label class="no-margin">{{addr['addressType']}}</label>
              <span>{{addr['primaryAddress']}}</span>
            </div>
            <div class="manage-address-content">
              <img class="manage-address-content-icon-edit" src="assets/icons/edit_blue.svg"
              (click)="addAddress(true, addr, patient,ind)" />
              <img *ngIf="!addr['isDeleteDisable']" class="manage-address-content-icon-delete" src="assets/icons/delete.svg"
                (click)="showAlertBox(ind, patient)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert-wrapper" *ngIf="showAlert">
      <div class="alert-modal">
        <div class="alert-title">
          {{'lbl_delete_address' | translate}}
        </div>
        <div class="alert-content">
          {{'lbl_delete_address_txt' | translate}}
        </div>
        <div class="alert-btn">
          <span class="alert-btn-style" (click)="deleteAddress()">{{'btn_yes' | translate}}</span>
          <span class="alert-btn-style" (click)="showAlert=false;deleteAddressDetails=null">{{'btn_no' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
