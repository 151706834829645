import { Component, Input, ElementRef , OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() isShowExternalFilter: boolean;
  @Input() filterInput: any;
  @Input() isHideMobileView: boolean;
  @Output() complete: EventEmitter<any>;
  @Output() showExternalFilter: EventEmitter<any>;
  @Output() close: EventEmitter<any>;

  public isFilterOpen: boolean;
  public filters: Array<any>;
  public selectedFilter: string = '';
  public selectedFilterList: Array<any>;
  private element: any;
  public searchkey: string = '';
  public selectedIndex: any = 0;
  public selectedOption: any;
  public isSelectAll: boolean = false;

  constructor(private el: ElementRef){
    this.element = el.nativeElement;
    this.complete = new EventEmitter<any>();
    this.close = new EventEmitter<any>();
    this.showExternalFilter = new EventEmitter<any>();

  }

  ngOnInit(): void {
    if(this.isShowExternalFilter)
      this.openCloseExternalFilter(this.filterInput[0], this.selectedIndex)
    console.log("==== filters on init ==", this.filters, this.filterInput, this.selectedIndex )
  }

  public changeFilter(key) {
    this.selectedFilter = key;
    this.isFilterOpen = false;
  }

  public initFilter(group){
    let count = group.options.filter(ele => {return ele.checked});
    this.selectedFilterList = count
    if(this.isHideMobileView)
      group['count'] = group.options && count && (count.length == group.options.length || count.length == 0  )  ? (count.length == 0 ? '' :  'All') : count.length.toString()
    else 
      group['count'] = group.options && count && (count.length == 0)  ? '' : count.length.toString()
    // console.log("==== selectedFilter ===", count, count.length, "===>", group.options.length  , this.selectedFilter, this.filterInput);
    if(!this.isShowExternalFilter)
      this.complete.emit(this.filterInput)
  }

  public chooseFilter(ev, item, group){
    // console.log("==== ev ===", ev, ev.target.checked, ev.target.type, ev.target.value)
    if(ev.target.type == 'radio'){
      group.options.map(ele => {
        console.log("", ele['key'],  ev.target.value, ele['key'] == ev.target.value)
        if(ele['key'] == ev.target.value) ele['checked'] = true
        else ele['checked'] = false
      })
    }
    if(ev.target.type == 'checkbox'){
      item['checked'] = ev.target.checked
    }
    this.initFilter(group);
  }

  public openCloseFilter(grp) {
    grp.openFilter = !grp.openFilter;
    if(!this.isHideMobileView)
    this.showMobileView(grp);
  }

  public closeFilter(){
    // console.log(event, this.filterInput);
    this.filterInput.map(ele => {
      ele.openFilter = false

      //For multiselect checkbox, check if none of the options are selected, then select all options
      if(ele['enable'] && ele['multiSelect'] && 
        Array.isArray(ele['options']) && ele['options'].length > 0 && 
        !ele['count']) {
        ele.options.forEach(item => {
          item['checked'] = false;
        });
        this.initFilter(ele);
      }
    })
  }

  public showMobileView(grp){
    const query = window.matchMedia("(max-width: 780px)")
    if (query.matches) { 
      grp.openFilter = !grp.openFilter;
      this.showExternalFilter.emit();
    }
  }

  public openCloseExternalFilter(grp, index){
    this.selectedIndex = index
    this.filterInput[index]['openFilter'] = true
    this.selectedOption = this.filterInput[index]
  }

  public apply(){
    this.complete.emit(this.filterInput)
    this.close.emit(this.filterInput)
  }

  public clearFilter(){
    this.filterInput.map( item => {
      item.options.map(ele => {
        ele['checked'] = false
      })
      item.count = ''
    })
    this.complete.emit(this.filterInput)
  }

  onClick(ev, group, type) {
    group.options.map(x=> {
      x.checked = (group.count == group.options.length) ? false: true
    })
    this.initFilter(group);
    ev.stopPropagation();
    console.log('group', ev, group, type)
  }

  public closeFilterModal(){
    this.close.emit();
  }

}
