import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedPageComponent } from './unauthorized-page.component';



@NgModule({
  declarations: [UnauthorizedPageComponent],
  imports: [
    CommonModule
  ], exports: [
    UnauthorizedPageComponent
  ]
})
export class UnauthorizedPageModule { }
