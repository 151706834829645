import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { LANG_SELECTED } from "../app.const";


@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  public labels: any;
  public relationList: any = [];
  public languageList: any;
  constructor(private user: UserService) {
    this.user.getLabels().subscribe(data => {
      this.labels=data;
    });
    this.user.getMasterConfig.subscribe(data => {
      if(data && data['list']) {
        this.relationList = data.list.filter(d => {
          return d.domain === 'RELATIONSHIP_TYPE'
        })[0]['codes'];
      }
    });
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });

  }
  transform(value: any, type: string = 'LABELS', key?: string, lang?: string, replace?: any, returnNull?: boolean, defaultValue: string = ''): any {
    if(lang) {
      this.labels = this.user.getLocaleLabels();
    }
    if (type === 'FACTIMEZONE') {
      let facilityId = key; key = null;
      type = 'LABELS'

      let facKey = `${key}_${facilityId}`;
      if (this.labels && this.labels[facKey]) {
        return this.labels[facKey]
      }
    }
    if (type === 'LABELS' || type == 'LABEL' || type === 'TASK_LABELS') {
      let retLabel = this.labels && this.labels[value] ? this.labels[value] : (value && !returnNull ? value.split('_').join(' '): null);
      if (replace && Array.isArray(replace)) {
        replace.forEach(l => {
          if (l.subtranslate) {
            l.with = this.labels && this.labels[l.with] ? this.labels[l.with] : '';
          }
          retLabel = retLabel.replace(`%${l.key}%`, l.with)
        })
      } else if (replace) {
        Object.keys(replace).forEach(key => {
          retLabel = retLabel.replace(`%${key}%`, replace[key])
        })
      }
      return retLabel
    }
    if (type === 'LOCALE') {
      if(typeof value === 'string') {
        return value;
      }
      else if (!value || value.length == 0) {
        return defaultValue;
      }
      else {
        let temp = value.find(obj => {
          return obj.locale == this.user.currentLanguage;
        });
        if(temp){
          return temp[key];
        } else{
          return value[0][key];
        }
      }
    }
    if (type === 'RELATION') {
      let val = this.relationList.find(obj => {
        return obj.code === value;
      })
      if (val) {
        let val1 = val['value'].find(obj => {
          return obj.locale == this.user.currentLanguage;
        })
        if (val1) {
          return val1['name'];
        } else {
          return val['value'][0]['name'];
        }
      } else {
        return value;
      }
    }
    if (type == 'LANG') {
      let obj = this.languageList.find(obj => {
        return obj.localeCode == value
      });
      return obj ? obj.localName : '';
    }
    if (type == 'TASKACTION') {
      let desckey = `TASKACTION_${value.code}`;
      console.log('TASKACTION', desckey);
      if (this.labels && this.labels[desckey]) {
        return this.labels[desckey]
      } else {
        return value['actionDescription'] || value['name']
      }
    }
    if (type == 'LANGARRAY') {
      if (!value) {
        return ''
      }
      if (value && !Array.isArray(value) && value.names) {
        value = value.names;
      }
      let temp = value.find(k => { return k['locale'] == this.user.currentLanguage })
      if (!temp) temp = value[0];
      if (temp && temp[key] && temp[key].trim()) {
        return temp[key];
      } else {
        return ''
      }
    }
  }

}
