import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-recent',
  templateUrl: './appointment-recent.component.html',
  styleUrls: ['./appointment-recent.component.scss']
})
export class AppointmentRecentComponent {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  constructor() { 
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
  }


  public completeSelection(event: any) {
    this.complete.emit({ home: event });
  }

}
