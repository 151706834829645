import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';

@Component({
    selector: 'app-draw',
    templateUrl: './draw.component.html',
    styleUrls: ['./draw.component.scss'],
})
export class DrawField extends Type implements OnInit, SectionInterface, OnChanges, AfterViewInit {

    @Input() public section;
    @Input() public sections;
    @Input() public patient;
    @ViewChild('signaturepad') signaturePad: SignaturePad;
    public signaturePadOptions: Object = {
        'minWidth': 0.5,
    };
    constructor(cfs: ClinicalformService) {
        super(cfs);
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        setTimeout(() => {
            let drawcontainer = document.getElementById('drawcontainer');
            console.log(this.signaturePad, SignaturePad, this.section)
            if (this.signaturePad) {
                this.signaturePad.set('minWidth', 0.5);
                if(!this.section['selectedPic'])
                    this.signaturePad.clear();
                if(this.section['selectedPic'])
                    this.signaturePad.fromDataURL(this.section['selectedPic'])
                console.log(this.signaturePad);
            }
        },1000)
    }

    ngOnChanges() {

    }

    clearSignaturePad() {
        this.signaturePad.clear();
        if (this.section.selectedPic) {
            this.section.selectedPic = null;
        }
        this.section.selected = null;
    }
    drawComplete() {
        this.section.selectedPic = this.signaturePad.toDataURL();
        this.section.selected = this.section.selectedPic;

    }



}
