import { Component, OnInit, HostListener,EventEmitter,Output,Input, ViewChild } from '@angular/core';
import { HomeCareService } from '../services/home-care.service';
import {Router} from'@angular/router'
import { UserService } from '../services/user.service';
import { GoogleTagsService } from '../services/google-tags.service';

@Component({
  selector: 'app-home-care-services',
  templateUrl: './home-care-services.component.html',
  styleUrls: ['./home-care-services.component.scss']
})
export class HomeCareServicesComponent implements OnInit {
  @Output() close: EventEmitter<any>;
   activityEvent:any;
   activityInput: any;

  public availableServices;
  public location;
  public call_option;
  public Activity: boolean = false;
  public number: number
  public home;
  constructor(private services: HomeCareService,private route:Router, private user: UserService, private gtmService:GoogleTagsService) {
    this.close = new EventEmitter<any>();
    let homecareOptions = this.services.getHomecareOptions()
    this.availableServices = homecareOptions.availableServices || [];
    this.location = homecareOptions.location || {enable: true, options:[]};
    this.location.type = 'location'
    this.location.title = 'hc_ttl_location'
    this.call_option = homecareOptions.call_option || { enable: false, number: null};
    this.home = homecareOptions.home ? homecareOptions.home : {};

  }

  ngOnInit(): void {
    // this.getHomeCareServiceDetails();
    this.gtmService.pushGTMEvent('Home Health Care', 'care_at_home');
  }

  public closeModal() {
    this.close.emit('CLOSE');
  }

  public closeactivity(){
    this.Activity = false
  }

  activityfun(name, event?:any) {
    let type = event.type ? event.type : ""
    if (type && type == 'location'){
      this.services.getAvailableLocations().subscribe(data => {
        console.log(event)
        this.openInnerModal(data,event);
      });
    }else if(type && type == 'doctor'){
        this.services.getAvailableDoctors(event).subscribe(data => {
          this.openInnerModal(data,event);
        });
    }else if(type && type == 'careservice'){
        this.services.getAvailableCareServices(event).subscribe(data => {
          this.openInnerModal(data,event);
        });
    }
    this.gtmService.pushGTMEvent('Home Health Care', 'step_option_care_at_home',  {service_type:name['0']['label']});

  }

  public openInnerModal(data,event){
    this.activityInput = data
    this.activityEvent = event
    this.Activity = true
  }

  public getTitle(names){
    return this.services.getTitle(names)
  }

  public call(){
    this.user.openLocation("tel:" + this.call_option.number);
    this.gtmService.pushGTMEvent('Home Health Care', 'call_us_button');
  }

  public copy() {
    this.user.openLocation("tel:" + this.call_option.number);
  }

  public getIcon(icons){
    if (Array.isArray(icons)){
      if (icons.length == 0) {
          return '';
      } else {
        let t = icons.find(icon => {
            return icon.locale == this.user.currentLanguage
          })
          if (t) {
              return t.src;
          } else {
            return icons[0].src;
          }
      }
    }else{
      return icons;
    }
  }

}
