import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import * as moment from 'moment';
import { AppointmentService } from '../services/appointment.service';

@Component({
  selector: 'app-express-clinic-patient',
  templateUrl: './express-clinic-patient.component.html',
  styleUrls: ['./express-clinic-patient.component.scss']
})
export class ExpressClinicPatientComponent implements OnInit {
  @Input() _input: any;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  public linkedPatients: Array<any>;
  public selectedPatient: any = false;

  public recentConsultatedPatient: any;
  @HostListener('document:click', ['$event'])
  onCardClick(e){
    const target = e.target;
    const selectedCard = document.querySelector('.card.selected');
    if(selectedCard && !selectedCard.contains(target)) {
      this.selectedPatient = false;
      selectedCard.classList.remove('selected');
    }
  }

  constructor(private user: UserService, private linkPatient: LinkPatientService,private appointment:AppointmentService ) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
  }

  ngOnInit() {
    this.setLinkedPatients();
  }

  private setLinkedPatients() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.recentConsultatedPatient = this.appointment.getRecentConsultatedPatientValue();
        this.linkPatient.getLinkedPatient(data, true).subscribe(result => {
          if (result.status === 'SUCCESS') {
            this.linkedPatients = result.data;
            this.linkedPatients =this.linkedPatients.map(patient=> {
              const currentDate = moment();
              const patientDob = moment(patient['dob']);
              patient['age'] = currentDate.diff(patientDob, 'years');
              return patient;
            })
            console.log(this.linkedPatients);
            this.setDefaultPatient()
          }
        })
      }
    })
  }
  public setDefaultPatient() {
    this.linkedPatients.forEach((p) => {
      if(p['uhid'] == this.recentConsultatedPatient['uhid']) {
        p['recentConsultated'] = true;
      }
    });
    this.linkedPatients.splice(0, 0, this.linkedPatients.splice(this.linkedPatients.findIndex(p => p['recentConsultated']), 1)[0]);
    console.log('this.linkedPatients', this.linkedPatients)
    this.selectedPatient = this.linkedPatients[0];
  }

  public completeSelection(event: any) {
    let obj = {
      id: event.uhid,
      name: event.personname,
      personid: event.personid,
      relation: event.relationshiptype,
      image: event.imageurl,
      gender: event.gender,
      dob: event.dob,
      patientPayload: event
    }
    this.complete.emit({ patient: obj });
  }

  openModal(id: string) {
    this.action.emit({
      name: 'registerpatient'
    })
  }

  selectCard(patient, event) {
    this.selectedPatient = patient;
    event.stopPropagation();
  }
}
