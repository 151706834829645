<div class="ui">
<p class="check-label"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
   <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
<!--<p class="no-margin">Date</p>-->
<!--<div style="display: -webkit-inline-flex">-->
  <!--&lt;!&ndash;<sm-select class="fluid" [(model)]="section['day']" (onChange)="onChange()">&ndash;&gt;-->
    <!--&lt;!&ndash;<option [selected]="day === section['day']" *ngFor="let day of section['days']">{{day}}</option>&ndash;&gt;-->
  <!--&lt;!&ndash;</sm-select>&ndash;&gt;-->
  <!--&lt;!&ndash;<sm-select class="fluid select-month" [(model)]="section['month']" (onChange)="onChange()">&ndash;&gt;-->
    <!--&lt;!&ndash;<option [selected]="month['name'] === section['month']" *ngFor="let month of section['months']">{{month['name']}}</option>&ndash;&gt;-->
  <!--&lt;!&ndash;</sm-select>&ndash;&gt;-->
  <!--&lt;!&ndash;<sm-select class="fluid" [(model)]="section['year']" (onChange)="onChange()">&ndash;&gt;-->
    <!--&lt;!&ndash;<option [selected]="year === section['year']" *ngFor="let year of section['years']">{{year}}</option>&ndash;&gt;-->
  <!--&lt;!&ndash;</sm-select>&ndash;&gt;-->
  <!--<vaadin-date-picker  val [_i18n_]="DATE_PICKER_FORMAT" placeholder="Enter {{section.question[0].text}}" (change)="onChange($event)">-->
  <!--</vaadin-date-picker>-->
<!--</div>-->
<!--<br>-->
<!--<p class="no-margin">Time</p>-->
<!--<div style="display: -webkit-inline-flex;margin-top: 10px;">-->
  <!--&lt;!&ndash;<vaadin-date-picker  placeholder="Enter {{section.question[0].text}}" (change)="onChange($event)">&ndash;&gt;-->
  <!--&lt;!&ndash;</vaadin-date-picker>&ndash;&gt;-->

  <!--<select class="fluid" [(ngModel)]="section['hour']" (change)="onChange1()">-->
    <!--<option [selected]="hour === section['hour']" *ngFor="let hour of section['hours']" [ngValue]="hour">{{hour}}</option>-->
  <!--</select>-->
  <!--<select class="fluid" [(ngModel)]="section['minute']" (change)="onChange1()">-->
    <!--<option [selected]="minute === section['minute']" *ngFor="let minute of section['minutes']"[ngValue]="minute">{{minute}}</option>-->
  <!--</select>-->
  <!--<select class="fluid" [(ngModel)]="section['meridian']" (change)="onChange1()">-->
    <!--<option [selected]="meridian === section['meridian']" *ngFor="let meridian of section['meridians']" [ngValue]="meridian">{{meridian}}</option>-->
  <!--</select>-->
<!--</div>-->
<input placeholder="Select date & time"
       [(ngModel)]="section['selected']"
       [min]="_min"
       [max]="_max"
       [pickerMode]="'dialog'"
       [tabindex]="tabindex"
       [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" class="custom-date-time" (dateTimeInput)="onDataTimeInput($event)">
<owl-date-time #dt1 [hour12Timer]="true" [pickerMode]="'dialog'" panelClass="form-date-time"></owl-date-time>
<!--<p>{{ section['selected'] | datetime}}</p>-->
<div class="mandatory right" *ngIf="((section['mandatory']=='true') && ( (section['selected']=='')))">
  <p>{{'form_field_mandatory' | translate}}</p>
</div>
<div class="mandatory right" *ngIf="section['_error']">
  <p>{{section['_error']}}</p>
</div>
</div>