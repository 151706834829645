import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { UtilitiesService } from '../services/utilities.service';
import { GoogleTagsService } from '../services/google-tags.service';
import { TranslatePipe } from  '../pipes/translate.pipe';
import * as _ from 'lodash';
import { healthPackageFlowType } from '../app.type';
import { LinkPatientService } from '../services/link-patient.service';
import { PackageService } from '../services/package.service';
import * as AppConstant from '../app.string';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-health-package-flow',
  templateUrl: './health-package-flow.component.html',
  styleUrls: ['./health-package-flow.component.scss']
})
export class HealthPackageFlowComponent {

  @Output() close: EventEmitter<any>;
  @Input() pkgdetails: any;
  @Input() selectedPackage: any;
  public state: healthPackageFlowType;
  public input: Object = {
    "selectedPkg": {},
    "selectedFacility": {},
    "selectedPatient": {},
    "selectedSlot": {},
    "selectedDirective": {}
  };
  public index = 0;
  public linkedPatients: Array<any>;
  public isRegisterPatient: boolean;
  public selectedPatient: any;
  public  selectedSlot: any;
  public requestInfo: any;
  private isBacktoConfirmReq: boolean;
  public reqConfirm: any;
  flowType:'OLD' | 'NEW' = 'NEW';

  //undefined
  public selectedPatientWithAppt: any
  serviceCatagories: any;
  constructor(private user: UserService, private util: UtilitiesService, private gtmService:GoogleTagsService, private translate: TranslatePipe, private linkPatService:LinkPatientService, 
    private packageService: PackageService, private paymentService:PaymentService) {
    this.close = new EventEmitter<any>();
  }

  ngOnInit(): void {
    if (this.pkgdetails) {
      this.checkOrderDetails();
    }
    this.user.getUpdateInfo('directives').subscribe(data => {
     this.serviceCatagories = data.userDefined.filter(def =>def.directiveGroup == 'HOSPSERV');
     if(this.serviceCatagories.length)
     this.input['selectedDirective'] = this.serviceCatagories[0];
    });
    this.state = 'LIST_PACKAGES';
    this.setLinkedPatients();
    if(this.selectedPackage)
    this.toggleState({ data: this.selectedPackage, state: 'CHOOSE_PACKAGE', key: 'selectedPkg'} )
  }

  public toggleState(event){
    this.state = event.state
    this.input[event.key] = event.data
    console.log("======= toggle state =====", event, this.input)
    if(this.isBacktoConfirmReq) {
      if(this.index == 2){
        this.setInpuForSlot();
        this.index = 4
      }else{
        this.generateConfirmReqParams();
        this.index = 5;
      }
      this.isBacktoConfirmReq = false;
    }
    else this.index++;

    if(this.index == 3){
      this.setInpuForSlot();
    }
  }

  public setInpuForSlot(){
    this.input['in'] = {
      facility: {
        id: this.input['selectedFacility']['facilityId']
      },
      speciality:{
        id: this.input['selectedFacility'].controls.packageCalendarClinic,
      },
      practitioner: {
        id: this.input['selectedFacility'].controls.packageCalendarResourceCode,
        availDays: this.input['selectedFacility'].controls.daysAvailable
      }
    }
  }
  public checkAction(event){
    if(event == 'BACK'){
      this.index--;
    }else{
      this.close.emit()
    }
    console.log("======= checkAction state =====", event, this.index)
  }

  private setLinkedPatients() {
    this.user.getUserInfo('linkedPatients').subscribe(data => {
      this.linkedPatients = data
      this.getMyPackages();
    });
    // this.user.getUserId().subscribe(data => {
    //   if (data) {
    //     this.linkPatService.getLinkedPatient(data).subscribe(result => {
    //       if (result.status === 'SUCCESS') {
    //         this.linkedPatients = result.data;
    //         this.getMyPackages();
    //       }
    //     })
    //   }
    // })
  }

  public choosePat(event){
    this.selectedPatient = event
  }

  public setSelectedPatient(event){
    this.toggleState({ data: event, state: 'CHOOSE_SLOT', key: 'selectedPatient'} );
  }

  public setSlot(event){
    this.selectedSlot = event
    this.generateConfirmReqParams();
    this.toggleState({ data: event, state: 'REVIEW', key: 'selectedSlot'} );
  }

  openModal(id: string) {
    this.gtmService.pushGTMEvent('Health Packages', 'step_option_select_person',{patient_type:'New'});
    this.isRegisterPatient = true;
  }

  public closeRegPatient(ev){
    this.isRegisterPatient = false
    this.setLinkedPatients();
  }

  public getMyPackages(){ 
    let patientList = this.linkedPatients
    let tmp=[];
    patientList.forEach((p)=>{
      tmp.push(p['uhid']
      )
    })
    let inparam={
      "patientIds":tmp,
      "status":'A'
    }
    this.packageService.getPackages(inparam,true).subscribe(data=>{
      if(data.length>0){
      }
    })
  }

  public generateConfirmReqParams(){
    this.requestInfo = {
      "appointmentType": "HP",
      "selectedPkg": this.input['selectedPkg'],
      "selectedFacility": this.input['selectedFacility'],
      "selectedSlot": this.selectedSlot,
      "selectedPatient": this.input['selectedPatient'],
      "selectedDirective": this.input['selectedDirective'],
      ...this.input['selectedPatient'],
    }
    console.log("==== requestInfo =====", this.requestInfo)
  }

  public editAction(type){
    this.isBacktoConfirmReq = true;
    if(type == 'FACILITY'){
      this.index = 2
    }
    if(type == 'PATIENT'){
      this.index = 3
    }
    if(type == 'SLOT'){
      this.index = 4
    }
  }

  private checkOrderDetails() {
    console.log("hlthpkg checkOrderDetails", this.pkgdetails)
    let orderData = atob(this.pkgdetails);
    let arr = orderData.split('&');
    let params = {};
    for (var i = 0; i < arr.length; i++) {
      var pair = arr[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    console.log("params",params)
    this.paymentService.getTransactionDetail(params['orderid']).subscribe(data => {
        console.log("getTransactionDetail", data, params['h']);
        if(data.status=='S'){
          this.requestInfo = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_REQ))
          // this.openPkgReq =true;
          this.reqConfirm = {
            confirmReq : true,
            status:data.status,
            data: data
          }
        }
        else if(data.status=='R' || data.status=='F' || data.status == 'P'){
          this.requestInfo = JSON.parse(this.user.getStorage(AppConstant.HEALTHPKG_REQ))
          // this.openPkgReq =true;
          this.reqConfirm = {
            confirmReq : true,
            status:data.status,
            data: data
          }
        }
    })
  }


}
