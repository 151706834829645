<div id="table-pagination-component">
  <div class="showing-section">
    <div class="from-to-section">
      <span class="font-light-small">{{'Showing'|translate}}</span> {{paginationOptions.fromRecords}}-{{paginationOptions.toRecords}} of {{paginationOptions.totalRecords}}
    </div>
    <div class="prev-next-section">
      <div class="prev prev-next" (click)="navigate(-1)">
        <img [ngClass]="{disabled: !paginationOptions.navigatePrev}" src="./assets/icons/header/next_arrow.svg" />
      </div>
      <div class="next prev-next" (click)="navigate(1)">
        <img [ngClass]="{disabled: !paginationOptions.navigateNext}" src="./assets/icons/header/next_arrow.svg" />
      </div>
    </div>
  </div>
  <div class="per-page-dropdown" (click)="openPageCount()">
    <div class="select-per-page">{{paginationOptions.recordsPerPage}}</div>
    <span class="text">Per page</span>
  </div>
  <div class="available-page-counts no-width" #menu [ngClass]="{'show': showPageCount}">
    <div class="page-count-card border cursor-pointer">
      <div class="page-count-option border-bottom" (click)="changePerPage(item)"
        *ngFor="let item of pagination_numbers">
        {{item}}</div>
    </div>
  </div>
</div>
