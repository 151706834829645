import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponComponent } from './coupon.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { DirectiveModule } from '../directive/directive.module';

@NgModule({
  declarations: [CouponComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule, PhoneInputModule, DirectiveModule
  ],
  exports: [
    CouponComponent
  ]
})
export class CouponModule { }
