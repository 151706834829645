import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageAccountComponent } from './manage-account.component';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { EditProfileModule } from '../edit-profile/edit-profile.module';
import { ChangeMobileNumberModule } from '../change-mobile-number/change-mobile-number.module';
import { ChangeEmailModule } from '../change-email/change-email.module';
import { from } from 'rxjs';
import { ViewProfileModule } from '../view-profile/view-profile.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManagePaymentCardModule } from '../manage-payment-card/manage-payment.module';
import { ManageAddressModule } from '../manage-address/manage-address.module';
import { ManageAddressFormModule } from '../manage-address-form/manage-address-form.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { ManageIdentitiesModule } from '../manage-identities/manage-identities.module';
import { ManageSavedCardsModule } from '../manage-saved-cards/manage-saved-cards.module';
import { ContactUsModalModule } from '../contact-us-modal/contact-us-modal.module';
import { CouponModalModule } from '../coupon-modal/coupon-modal.module';
import { ManageInsuranceModule } from '../manage-insurance/manage-insurance.module';
import { InsuranceDetailsModule } from '../insurance-details/insurance-details.module';
import { ManageMyConsentModule } from '../manage-my-consent/manage-my-consent.module';
@NgModule({
  declarations: [ManageAccountComponent],
  imports: [
    CommonModule, PipesModule, PersonAvatarModule, ChangePasswordModule, EditProfileModule, ChangeMobileNumberModule,
    ChangeEmailModule, ViewProfileModule, FormsModule, ReactiveFormsModule,
    ManagePaymentCardModule, ManageAddressModule, ManageAddressFormModule, CustomModalModule, ManageIdentitiesModule, ManageSavedCardsModule, ContactUsModalModule, CouponModalModule, ManageInsuranceModule, InsuranceDetailsModule, ManageMyConsentModule
  ], exports: [
    ManageAccountComponent
  ]
})
export class ManageAccountModule { }
