import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookAppointmentModule } from '../book-appointment/book-appointment.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { ListItemModule } from '../list-item/list-item.module';
import { NodataModule } from '../nodata/nodata.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { RecentlyConsultedComponent } from './recently-consulted.component';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';



@NgModule({
  declarations: [RecentlyConsultedComponent],
  imports: [
    CommonModule, ListItemModule, PipesModule, BookAppointmentModule, NodataModule,
    CustomModalModule,
    PersonAvatarModule, PractitionerAvatarModule,ApptTimeModule
  ],
  exports: [
    RecentlyConsultedComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecentlyConsultedModule { }
