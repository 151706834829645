import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { CookieService } from './cookie.service';
import { InterceptorService } from './interceptor.service';
import { SocketService } from './socket.service';
import { HttpClient } from '@angular/common/http';
import { Idle } from '@ng-idle/core';
import { UtilitiesService } from './utilities.service';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private appointmentInfo: any;
  private paymentInfo: any;
  constructor(private user: UserService, private cookie: CookieService, private interceptor: InterceptorService, public socket: SocketService, private http: HttpClient, private idle: Idle, private util: UtilitiesService) { }

  public setPaymentParams(appointment: any, paymentInfo:any): Observable<any> {
    return Observable.create(observer => {
      this.appointmentInfo = {
        ...appointment,
        mobile_no: this.user.getPersonMobile() || appointment['mobile_no'],
      };
      this.paymentInfo = paymentInfo;
      observer.next();
    })
  }
  public getAppointmentInfo() {
    return this.appointmentInfo;
  }
  public getPaymentInfo() {
    return this.paymentInfo;
  }
  public getTransactionDetail(transactionId: any):Observable<any> {
    let payload = {
      transactionId: transactionId
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/payments/transactionDetail`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data);
        this.user.npsRatingSubject.next(true)
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    })
  }
  public getDepositBalance(): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      patients: '0'
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/payments/getDepositBalance`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data);
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    })
  }

  public getSavedCards(): Observable<any> {
    return new Observable(observer => {
      let payload = {
        personId: this.user.getPersonId(),
        isBackgroundProcess: true
      }
      this.http.post(`${environment['API_URL']}api/payments/getSavedCards`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error()
      })
    })
  }

  public getCardArray(cards) {
    if (!cards || cards.length == 0) return [];
    let cardArray = cards.map((c, i) => {
      return {
        tokenName: c['cardsInfo'].savedCardToken,
        cardNo: c['cardsInfo'].cardNumber,
        cardHolderName: c['cardsInfo'].cardHolderName,
        exp: this.checkCardExpiry(c['cardsInfo'].expiryDate) ? moment(c['cardsInfo'].expiryDate, 'YYMM').format('MM/YYYY') : 'EXPIRED',
        expDate: moment(c['cardsInfo'].expiryDate, 'YYMM').format('MM/YYYY'),
        cardType: c['cardsInfo'].paymentMode
      };
    });
    return cardArray;
  }

  private checkCardExpiry(date: string) {
    return moment(moment(date, 'YYMM').format()).isSameOrAfter(moment().format(), 'm');
  }

  public confirmDeleteCard(card): Observable<any> {
    return new Observable(observer => {
      let payload = [{
        personId: this.user.getPersonId(),
        isBackgroundProcess: true,
        ...card
      }]
      this.http.post(`${environment['API_URL']}api/payments/deleteSavedCards`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error()
      })
    })
  }

  public unBlockSlot(payload):Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/payments/unBlockSlot`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data);
        this.user.npsRatingSubject.next(true)
      }, error => {
        observer.error(this.user.getErrorMessage(error));
      })
    })
  }
}
