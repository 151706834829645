import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventInfoComponent } from './event-info.component';
import { FormpreviewModule } from '../formpreview/formpreview.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [EventInfoComponent],
  imports: [
    CommonModule, FormpreviewModule, PersonAvatarModule, PipesModule
  ],
  exports: [EventInfoComponent]
})
export class EventInfoModule { }
