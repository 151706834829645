import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpressClinicAppointmentConfirmComponent } from './express-clinic-appointment-confirm.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NodataModule } from '../nodata/nodata.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { CouponModule } from '../coupon/coupon.module';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';
import { CouponModalModule } from '../coupon-modal/coupon-modal.module';
import { PromoInputModule } from '../components/promo-input/promo-input.module';
import { AltpaymentInputModule } from '../components/altpayment-input/altpayment-input.module';
import { DisplayCurrencyModule } from '../components/display-currency/display-currency.module';

@NgModule({
  declarations: [ExpressClinicAppointmentConfirmComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, NodataModule, PractitionerAvatarModule, PersonAvatarModule, CouponModule, CouponModalModule, ApptTimeModule, PromoInputModule, AltpaymentInputModule, DisplayCurrencyModule
  ],

  exports:[ExpressClinicAppointmentConfirmComponent]
})
export class ExpressClinicAppointmentConfirmModule { }
