import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(inputArray: Array<any>, search: string, key: any, filter:Array<any> = null, subObjectKey: string = null): any {
    if ((!search && ( !filter || filter.length == 0)) || !key || !inputArray)
      return inputArray;
    if (filter && filter.length > 0) {
      let stateSelected = filter[0];
      let citySelected = filter[1];

      if (citySelected) {
        inputArray = inputArray.filter(all => {
          return all['cityCode'] == citySelected
        })
      }
      if (stateSelected) {
        inputArray = inputArray.filter(all => {
          return all['stateCode'] == stateSelected
        })
      }
    }
    if (!search)
      return inputArray
    if(!Array.isArray(key) && typeof key == 'string')
      return inputArray.filter(el => el[key].toLowerCase().includes(search.toLowerCase()));
    else if (Array.isArray(key)) {
      if (subObjectKey) {
        let filtered = _.cloneDeep(inputArray).map(option => {
          option[subObjectKey] = option[subObjectKey].filter(item => {
            key.some(k => (item[k] && item[k].toLowerCase().includes(search.toLowerCase())))
            return !search || key.some(k => (item[k] && item[k].toLowerCase().includes(search.toLowerCase())))
          })
          return option;
        }).filter(option => option[subObjectKey].length > 0)
        return filtered;
      }
    }
  }

}
