import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterNewPatientComponent } from './register-new-patient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxHijriGregorianDatepickerModule } from 'ngx-hijri-gregorian-datepicker';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { PipesModule } from '../pipes/pipes.module';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { DropdownModule } from 'primeng/dropdown';
import { InsuranceDetailsModule } from '../insurance-details/insurance-details.module';



@NgModule({
  declarations: [RegisterNewPatientComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, PipesModule, FormInputErrorModule, PaymentCheckoutModule, NgxHijriGregorianDatepickerModule, PhoneInputModule, CustomInputGroupModule, DropdownModule
  ],
  exports: [
    RegisterNewPatientComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegisterNewPatientModule { }
