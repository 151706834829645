import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillPaymentComponent } from './bill-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PaymentCheckoutModule } from '../payment-checkout/payment-checkout.module';



@NgModule({
  declarations: [
    BillPaymentComponent
  ],
  exports:[BillPaymentComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, PipesModule, PaymentCheckoutModule
  ]
})
export class BillPaymentModule { }
