<div class="modal-closure">
  <div class="empty" (click)="skip()"></div>
  <div class="modal-wrapper">
    <div class="rating-header border-bottom">
      <div class="title font-primary-default"><strong>{{"NPSRATING#HEADER#TITLE#RATEUS" | translate}}</strong></div>
      <div class="header-space close-btn cursor-pointer" (click)="skip()">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="rating-content" *ngIf="rating && rating.length">
      <form *ngIf="formInitialised" [formGroup]="ratingForm" autocomplete="off">
        <ng-container *ngFor="let groups of rating;let index=index;">
          <ng-container *ngFor="let controls of groups.child">
            <fieldset [formGroupName]="groups.name" [ngClass]="{'box-form-css':groups.name=='basic_box'}">
              <div class="relative-div" *ngIf="!controls.hide">
                <ng-container *ngIf="controls.input=='rating'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="rating"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [minLength]="controls.minLength" [addclass]="controls.addclass"
                    [hasError]="getError(groups.name,controls.name)" [radioList]="controls.radioList"
                    [radioOptions]="controls.radioOptions">
                  </app-custom-input-group>
                </ng-container>
                <ng-container *ngIf="controls.input=='textarea'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="textarea" [resize]="false"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [minLength]="controls.minLength"
                    [hasError]="getError(groups.name,controls.name)">
                  </app-custom-input-group>
                </ng-container>
              </div>
            </fieldset>
          </ng-container>
        </ng-container>
      </form>
    </div>
    <div class="rating-footer">
      <div class="button-row">
        <div class="button-item">
          <button (click)="skip()" class="skip-btn">{{"NPSRATING#FOOTER#BTN#SKIP" | translate}}</button>
        </div>
        <div class="button-item">
          <button class="submit-btn" (click)="submit()" [disabled]="ratingForm.invalid">{{"NPSRATING#FOOTER#BTN#SUBMIT" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>