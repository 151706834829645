import { PipesModule } from './../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfxviewerComponent } from './pdfxviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [PdfxviewerComponent],
  
  exports: [PdfxviewerComponent],
  imports: [
    CommonModule,PipesModule,PdfViewerModule
  ]
})
export class PdfxviewerModule { }