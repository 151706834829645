<div class="ui form">
    <div class="grouped fields">
        <p class="check-label">
          <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
           <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup></p>
      <!--<div class="field" *ngFor="let a of section['options']">-->
        <!--<div class="ui  checkbox">-->
          <!--<input [checked]="section['trueType']=='radio'?section['selected'] === a.localName[0].text:section['checked'].includes(a.localName[0].text)"-->
            <!--(click)="listenToEvent(a.localName[0].text)" name="check" type="{{ section['trueType'] }}">-->
          <!--<label class="check-opt">{{a.localName[0].text}}</label>-->
        <!--</div>-->
      <!--</div>-->
        <ng-multiselect-dropdown
                tabindex="0"
                [data]="section.selectTypeArray"
                [(ngModel)]="section['selectedOption']"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)" *ngIf="dropdownSettings"
                [tabindex]="tabindex"
        >
        </ng-multiselect-dropdown>
        <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
            <p>{{'form_field_mandatory' | translate}}</p>
        </div>
    </div>
</div>
<!--<div>-->
    <!--<div *ngFor="let a of section['options']">-->
        <!--<vaadin-checkbox >{{a.localName[0].text}}</vaadin-checkbox>-->
    <!--</div>-->
<!--</div>-->