import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewComponent } from './file-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilePdfviewerComponent } from './file-pdfviewer/file-pdfviewer.component';

@NgModule({
  declarations: [FilePreviewComponent, FilePdfviewerComponent],
  imports: [
    CommonModule, PdfViewerModule
  ],
  exports: [FilePreviewComponent, FilePdfviewerComponent]
})
export class FilePreviewModule { }
