import { Component, HostListener } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { visitType } from '@type';

@Component({
  selector: 'app-embed-appt',
  templateUrl: './embed-appt.component.html',
  styleUrls: ['./embed-appt.component.scss']
})
export class EmbedApptComponent {
  public flow;
  state = 'FACILITY';
  facilityList = [];
  specialityList = [];
  bookingInfo = {};
  searchStr = '';
  practitionerList: any;
  currency: any;
  noslot: boolean;
  slotList: any = {};
  selectedDate: any;
  curDate;
  startIndex: number;
  _startIndex: number;
  endIndex: number;
  daysMapping = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday'
  };
  visitType: visitType = 'CLINIC';
  isMobileView: boolean;
  expandedSidebar: any;
  lang: any;
  serviceCatagoryList: any;
  serviceList: any;
  queryParams = {};

  constructor(private user: UserService, private route: ActivatedRoute) {
    this.currency = user.getCurrency() || environment['CURRENCY'];
    this.flow = this.getFlow();
    this.state = this.flow[0].state;
    this.route.params.subscribe((data) => {
      let key = data['key'];
      if (key) {
        this.bookingInfo = {};
        key = window.atob(key);
        const params = key.split('&');
        this.visitType = params.find(p => p.includes('t='))?.split('t=')?.[1]?.toUpperCase() ? params.find(p => p.includes('t='))?.split('t=')?.[1]?.toUpperCase() : 'CLINIC';
        this.flow = this.getFlow(); 
        this.queryParams = {
          facilityId: params.find(p => p.includes('f='))?.split('f=')?.[1]?.split(','),
          specialityId: params.find(p => p.includes('s='))?.split('s=')?.[1]?.split(','),
          resourceId: params.find(p => p.includes('p='))?.split('p=')?.[1]?.split(','),
          alias:params.find(p => p.includes('a='))?.split('a=')?.[1],
        }
        this.state = this.flow[0].state;
        let d = this.flow[0].onInit();
        if (d?.length == 1)
          this.setNextState(d[0])
        console.log(this.flow, this.state, this.bookingInfo);
      }
    })
  }

  ngOnInit() {
    this.isMobileView = document.body.clientWidth < 480;
    this.curDate = this.user.getMoment().format('YYYY-MM-DD HH:mm');
    let qplang = this.route.snapshot.queryParams.lang;
    this.state = this.flow[0].state;
    let d = this.flow[0].onInit();
    if (d?.length == 1)
      this.setNextState(d[0])
    this.user.getLanguages().subscribe(data => {
      if (!qplang) qplang = 'en';
      let lang = data.find(d => d['localeCode'] == qplang);
      if (!lang) lang = data[0];
      this.user.setCurrentLanguage(lang);
      this.user.getLabels(true).subscribe(labels => {
        this.user.setLocaleLabels(labels);
        this.lang = qplang;
      })
    })
  }

  @HostListener('window:resize')
  private onWindowResize() {
    this.isMobileView = document.body.clientWidth < 480;
  }

  public fetchPractitionerSlots(pracitioners, facilityId) {
    const dates = this.getNext7Days()
    const offset = this.user.getDirectOffset(facilityId)
    const payload = {
      practitioners: pracitioners.map(a => a.resourceId),
      appointmentType: this.visitType,
      facilities: [facilityId],
      dates: dates,
      offset: offset
    }
    console.log(payload)
    this.user.getAvailableDaysForPract(payload).subscribe(data => {
      console.log('getAvailableDaysForPract', data)
      this.mapDays(data)
    })
  }

  private getNext7Days() {
    let count = 0;
    const days = []
    while (count < 7) {
      days.push(moment().add(count, 'day').format('YYYY-MM-DD'));
      count++;
    }
    return days;
  }

  public mapDays(data) {
    this.practitionerList = this.practitionerList.map(p => {

      p['daysAvailable'] = data.find(dat => dat.practitioner == p['resourceId'])?.daysAvailable;
      return p;
    })
  }
  mapCharges() {
    this.practitionerList = this.practitionerList.map(p => {
      let discountCharge = 0;
      let charge = 0;

      if (p && p.schedulerControls && Array.isArray(p.schedulerControls.locationCharges) && p.schedulerControls.locationCharges.length > 0)
        charge = p.schedulerControls.locationCharges[0].charges
      else
        charge = p.schedulerControls.charge

      if (p && p.schedulerControls && Array.isArray(p.schedulerControls.locationCharges) && p.schedulerControls.locationCharges.length > 0)
        discountCharge = p.schedulerControls.locationCharges[0]['discountCharge']
      else if (p.schedulerControls && p.schedulerControls['discountCharge'])
        discountCharge = p.schedulerControls['discountCharge']
      p.preview = { hasDiscount: !!discountCharge, originalCharge: charge, payableCharge: discountCharge || charge }
      p['daysAvailableString'] = Object.keys(p['schedulerControls']?.['daysAvailable']).filter(a => p['schedulerControls']?.['daysAvailable'][a] == 'true').map(a => this.daysMapping[a]).join(', ')
      return p;
    })
  }

  editFlow(state) {
    let index = this.flow.findIndex(a => a.state == state);
    if (this.flow[index]?.['static']) return;
    this.state = state;
    this.searchStr = '';
    this.flow.forEach((a, i) => {
      if (i >= index) {
        this.bookingInfo[a.attribute] = null;
      }
    });
  }

  chooseSlot(s) {
    console.log(s);
    this.bookingInfo['slot'] = {
      time: s.time,
      date: this.selectedDate,
      id: s['clinic'] + '__' + this.selectedDate + '__' + s.time,
      name: moment(this.selectedDate + ' ' + s.time).format('DD MMM YYYY hh:mm A'),
    }
    this.proceedToBooking();
  }

  private getSlotList(dates: Array<string>) {
    return new Promise((resolve, reject) => {
      this.user.getSlotList(this.bookingInfo['practitioner']?.id, this.bookingInfo['facility']?.id, dates, this.visitType).subscribe(data => {
        let slotsList = { facility: this.bookingInfo['facility']?.id, slots: data.slots };
        this.setSlotList(dates, [slotsList]);
      }, _ => {
        let slotsList = { facility: this.bookingInfo['facility']?.id, slots: {} };
        this.setSlotList(dates, [slotsList]);
      })
    })
  }

  private setSlotList(dates, slotList) {

    let combinedList = slotList.reduce((comb, obj) => {
      Object.entries(obj['slots']).forEach(([dateKey, dateValue]) => {
        if (!comb[dateKey]) {
          comb[dateKey] = {}
        }
        Object.entries(dateValue).forEach(([clinicKey, clinicValue]) => {
          if (!comb[dateKey][clinicKey]) {
            comb[dateKey][clinicKey] = {};
          }
          comb[dateKey][clinicKey][obj['facility']] = clinicValue
        })
      })
      return comb;
    }, {})
    let clinicObj = {};
    let clinicList = [];
    let dateList = [];
    // dates.forEach(d => {
    //   clinicObj = {
    //     ...clinicObj,
    //     [d]: {}
    //   }
    // })
    this.startIndex = 0;
    this.endIndex = 5;
    Object.entries(combinedList).map(slot => {
      let date = slot[0];
      if (dateList.indexOf(date) == -1) {
        dateList.push(date);
      }
      let clinics = slot[1];
      Object.entries(clinics).map((clinic: any) => {
        let c = clinic[0];
        clinicList.push(c);
        Object.entries(clinic[1]).forEach(([facilityId, times]: any) => {
          let s = times.split(',');
          let noonstarttime = date + ' 13:00';
          let evestarttime = date + '16:30';
          let mrng = [];
          let noon = [];
          let eve = [];
          let facilityTimezone = this.user.getFacilityTimezone(facilityId);
          const directoffset = this.user.getDirectOffset(facilityId);
          s.forEach(x => {
            let checkTime = date + ' ' + x;
            let checkTimeMoment = moment(checkTime, 'YYYY-MM-DD HH:mm').tz(facilityTimezone, true)
            if (date == moment().tz(facilityTimezone).format("YYYY-MM-DD")) {
              let momentVar = moment().tz(facilityTimezone).add(directoffset, 'hours');
              if (checkTimeMoment.isSameOrAfter(momentVar)) {
                // momentVar = this.user.getMoment().add(this.directoffset,'hours').format("HH:mm");
                let noondifftime = checkTimeMoment.diff(this.user.getMoment(noonstarttime, 'YYYY-MM-DD HH:mm'));
                let evedifftime = checkTimeMoment.diff(this.user.getMoment(evestarttime, 'YYYY-MM-DD HH:mm'));
                if (noondifftime < 0) {
                  mrng.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/morning.svg' });
                } else if (evedifftime < 0) {
                  noon.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/noon_.svg' });
                } else {
                  eve.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/Book_new/evening.svg' })
                }
              }
            }
            else if (checkTimeMoment.isSameOrAfter(this.user.getMoment(this.curDate, 'YYYY-MM-DD HH:mm'))) {
              let noondifftime = checkTimeMoment.diff(this.user.getMoment(noonstarttime, 'YYYY-MM-DD HH:mm'));
              let evedifftime = checkTimeMoment.diff(this.user.getMoment(evestarttime, 'YYYY-MM-DD HH:mm'));
              if (noondifftime < 0) {
                mrng.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/morning.svg' });
              } else if (evedifftime < 0) {
                noon.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/noon_.svg' });
              } else {
                eve.push({ clinic: c, facility: facilityId, time: x, '_icon': 'assets/icons/slot/evening.svg' })
              }
            }
          });
          if (clinicObj) {
            mrng = mrng.sort(this.sortTime);
            noon = noon.sort(this.sortTime);
            eve = eve.sort(this.sortTime);
            clinicObj[date] = { mrng: mrng, noon: noon, eve: eve };
          }
        })
      })
    });
    this.slotList = {
      dates: Object.keys(clinicObj).sort((a, b) => this.user.getMoment(a).isAfter(this.user.getMoment(b)) ? 1 : -1),
      clinics: clinicList,
      slots: clinicObj
    }
    console.log(this.slotList);
    this.selectedDate = this.slotList.dates[0];
    this.slotList.dates.forEach(element => {
      if ((_.isEmpty(this.slotList['slots'][element])) || (this.slotList['slots'][element]['mrng'].length == 0 &&
        this.slotList['slots'][element]['noon'].length == 0 && this.slotList['slots'][element]['eve'].length == 0)) {
        this.noslot = true;
        this.slotList['slots'][element] = {};
      }
    });
    // this.selectedSlot = this.selectedSlots[0];
  }
  private sortTime = (a, b) => {
    if (this.user.getMoment(a.time, 'HH:mm').isAfter(this.user.getMoment(b.time, 'HH:mm'))) {
      return 1;
    } else return -1
  }

  public changeSlots(count) {
    console.log(this.slotList['slots']?.[this.selectedDate]);
    if ((this.startIndex <= 0 && count < 0) || (this.endIndex >= this.slotList.dates.length && count > 0))
      return;
    this.startIndex += count;
    this.endIndex += count;
    this.selectedDate = this.slotList.dates[this.startIndex];
  }


  proceedToBooking() {
    const params = {
      action: 'schedule',
      type: this.visitType?.toLowerCase(),
      facility: this.bookingInfo['facility'].id,
      speciality: this.bookingInfo['speciality'].id,
      resource: this.bookingInfo['practitioner'].id,
      slot: this.bookingInfo['slot'].id,
      alias: this.queryParams['alias']
    }
    // this.router.navigate(['login'], {queryParams:params, relativeTo:this.route});
    let baseUrl = environment['PORTAL_BASE_URL'];
    const qp = Object.keys(params).reduce((a, b) => a ? a + '&' + (b + '=' + params[b]) : (b + '=' + params[b]), '');
    // this.user.openLocation(`${baseUrl}login?${qp}`, '_this');
    window.location.href = `${baseUrl}login?${qp}`;
    setTimeout(() => {
      window?.location?.reload();
    }, 1000);
    console.log('${baseUrl}login${qp}', `${baseUrl}login?${qp}`)

  }

  setNextState(d) {
    let index = this.flow.findIndex(a => a.state == this.state);
    this.bookingInfo[this.flow[index].attribute] = { id:d[this.flow[index]?.id], name:d[this.flow[index]?.name], ...d };
    if(this.queryParams[this.flow[index]?.id]?.length == 1)
      this.flow[index]['static'] = true;
    if (this.flow[index + 1]) {
      this.state = this.flow[index + 1].state;
      let d = this.flow[index + 1].onInit();
      if(d?.length == 1) 
        this.setNextState(d[0])
    }
    this.searchStr = '';
  }

  getFlow() {
    const flow = {
      CLINIC: [{
        label: 'Facility',
        title: 'Select Facility',
        placeholder: 'Search Facility',
        state: 'FACILITY',
        attribute: 'facility',
        activeIcon: 'hospital_grad',
        inactiveIcon: 'hospital_grey',
        id:'facilityId',
        name:'facilityName',
        onInit: () => {
          this.facilityList = this.user.getAttributeForListingType('facList', true);
          if (this.queryParams?.['facilityId']?.length) {
            this.facilityList = this.facilityList.filter(a => this.queryParams?.['facilityId'].includes(a['facilityId']));
          }
          return this.facilityList;
        },
      }, {
        label: 'Speciality',
        title: 'Choose Speciality',
        placeholder: 'Search Speciality',
        state: 'SPECIALITY',
        attribute: 'speciality',
        activeIcon: 'hermes_grad',
        inactiveIcon: 'hermes_grey',
        id:'specialityId',
        name:'specialityName',
        onInit: () => {
          this.specialityList = this.user.getSpecialities(this.bookingInfo['facility']?.['id'], this.visitType, true, true);
          if (this.queryParams?.['specialityId']?.length) {
            this.specialityList = this.specialityList.filter(a => this.queryParams?.['specialityId'].includes(a['specialityId']));
          }
          return this.specialityList;
        },
      }, {
        label: 'Practitioner',
        title: 'Choose Practitioner',
        placeholder: 'Search Practitioner',
        state: 'PRACTITIONER',
        attribute: 'practitioner',
        activeIcon: 'doctor',
        inactiveIcon: 'doctor_grey',
        id:'resourceId',
        name:'resourceName',
        onInit: () => {
          this.practitionerList = this.user.getPractitioners(this.bookingInfo['speciality']?.['id'], this.visitType, this.bookingInfo['facility']?.['id']);
          if (this.queryParams?.['resourceId']?.length) {
            this.practitionerList = this.practitionerList.filter(a => this.queryParams?.['resourceId'].includes(a['resourceId']));
          }
          this.mapCharges();
          return this.practitionerList;
        },
      }, {
        label: 'Slot',
        title: 'Choose Slot',
        state: 'SLOT',
        attribute: 'slot',
        activeIcon: 'calendar_and_time_grad',
        inactiveIcon: 'calendar_grey',
        onInit: () => {
          const dates = this.getNext7Days()
          this.getSlotList(dates);
        }
      }],
      PACKAGE: [{
        label: 'Service Catagory',
        title: 'Choose service catagory',
        placeholder: 'Search service catagory',
        state: 'SERVICE_CATAGORY',
        attribute: 'serviceCatagory',
        activeIcon: 'heart_grad',
        inactiveIcon: 'heart_grey',
      }, {
        label: 'Service',
        title: 'Choose Service',
        placeholder: 'Search service',
        state: 'SERVICE',
        attribute: 'service',
        activeIcon: 'service_grad',
        inactiveIcon: 'service_grey',
      }, {
        label: 'Facility',
        title: 'Select Facility',
        placeholder: 'Search Facility',
        state: 'FACILITY',
        attribute: 'facility',
        activeIcon: 'hospital_grad',
        inactiveIcon: 'hospital_grey',
      }, {
        label: 'Slot',
        title: 'Choose Slot',
        state: 'SLOT',
        attribute: 'slot',
        activeIcon: 'calendar_and_time_grad',
        inactiveIcon: 'calendar_grey',
      }]
    }
    return flow[this.visitType] ? flow[this.visitType] : flow['CLINIC'];
  }
}
