import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'partitionerDetails'
})
export class PartitionerDetailsPipe implements PipeTransform {
  private facilitiesList: any;
  constructor(private user: UserService) {
    this.user.getUpdateInfo('facilityServices').subscribe(data => {
      this.facilitiesList = data ? data.facilities : [];
    });
  }
  transform(value: any, fId: any, key: string): any {
    let val = this.facilitiesList.filter(fac => {
      return fac.facilityId == fId;
    })[0];
    if (val) {
      val = val['practitioners'].filter(prac => {
        return prac.resourceId == value;
      })[0]
      val=val?val[key]:'';
    }
    return val;
  }

}
