<div id="change-password" class="right-nav">
  <div class="content">
    <div class="form">
      <div class="title bold">
        {{'header_opt_profile_edit'|translate}}
        <div (click)="closeModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_grey.svg">
        </div>
      </div>
      <form [formGroup]="editProfileForm">
        <ng-container *ngIf="!extInterface">
          <div class="form-container">
            <app-custom-input-group label="{{'username'|translate}}" formControlName="userName" type="text"
              textValidation="alphabet" [required]="isRequiredField('userName')"></app-custom-input-group>
            <app-custom-input-group label="{{'email'|translate}}" formControlName="email" type="text"
              [required]="isRequiredField('email')"></app-custom-input-group>
          </div>
        </ng-container>
        <ng-container *ngIf="extInterface">
          <div class="form-container">
            <app-custom-input-group label="{{'lbl_sign_first_name'|translate}}" formControlName="firstName" type="text"
              [required]="isRequiredField('firstName')" textValidation="alphabet"></app-custom-input-group>
          </div>
          <div class="form-container">
            <app-custom-input-group label="{{'lbl_sign_last_name'|translate}}" formControlName="lastName" type="text"
              [required]="isRequiredField('lastName')" textValidation="alphabet"></app-custom-input-group>
          </div>
          <div class="form-container">
            <app-custom-input-group label="{{'frm_dob'|translate}}" type="date" [dateMax]="maxDate"
              [dateValue]="editProfileForm.get('dob').value" formControlName="dob" [required]="isRequiredField('name')"
              (dateChange)="dobDateChange($event)"></app-custom-input-group>
            <app-form-input-error [control]="editProfileForm.controls.dob"></app-form-input-error>
          </div>
          <div class="gender-form-container">
            <label for="gender" class="gender">{{'frm_gender'|translate}}</label>

            <input type="radio" formControlName="gender" group="gender" value="M" (change)="selectGender('M')" [ngClass]="{selected: genderSelected=='M'}">
            <span [ngClass]="{selected: genderSelected=='M'}"> {{'Male' | translate }}</span>

            <input type="radio" formControlName="gender" group="gender" value="F"
            (change)="selectGender('F')" [ngClass]="{selected: genderSelected=='F'}">
            <span [ngClass]="{selected: genderSelected=='F'}">{{'Female'| translate}}</span>
          </div>
          
          <div class="lang-form-container">
            <label for="language" class="language">{{'frm_language'|translate}}</label>

            <input type="radio" formControlName="language" group="language"
              value="en" (change)="selectLanguage('en')" [ngClass]="{selected: languageSelected=='en'}">
              <span [ngClass]="{selected: languageSelected=='en'}">{{'English' |translate }}</span>

            <input type="radio" formControlName="language" group="language" value="ar"(change)="selectLanguage('ar')" [ngClass]="{selected: languageSelected=='ar'}"> <span
            [ngClass]="{selected: languageSelected=='ar'}">{{'عربى' | translate}}</span>
          </div>

        </ng-container>
      </form>
    </div>
  </div>
  <div class="footer desktop-view">
    <button class="cancel-btn" (click)="resetForm()">{{'but_cancel'|translate}}</button>
    <button class="primary-button" (click)="editProfile()"
      [disabled]="editProfileForm.invalid">{{'btn_update'|translate}}</button>
  </div>
</div>