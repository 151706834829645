import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clinicalformMandatoryFilter'
})
export class ClinicalformMandatoryFilterPipe implements PipeTransform {

  transform(topics: any, filter: boolean): any {
    if (!(Array.isArray(topics) && topics.length && filter))
      return topics;
    return topics.filter(t => t['sections'].some(s => s['mandatory'] === "true" || s['mandatory'] === true));
  }

}
