<div id="manage_consent" class="right-nav">
  <div class="content" *ngFor="let opt of options; let i = index; let last = last; let first = first">
    <ng-container *ngIf="first" class="init-content">
    <div class="title bold title-content">
      {{"MANAGE#DATA#PRIVACY#HEADER#TITLE#DATAPRIVACY#CONSENT" | translate}}
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_grey.svg" />
      </div>
    </div>
    <div class="consent-enclosure" *ngIf="!isConsented">
      <div class="consent-toggle-text">
        {{'MANAGE#DATA#PRIVACY#SUBHEADER#CONSENTCONFIRM' | translate}}
      </div>
      <div class="consent-toggle">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="isConsented" (change)="onChange1()"/>
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    </ng-container>

    <ng-container>
      <div class="card1 row cursor-pointer" [ngClass]="{'card-rtl': appDirection == 'rtl'}" (click)="chooseConsentOpt(opt)">
        <div class="image">
          <img [src]="opt.icon">
        </div>
        <div class="card-content">
          <div class="opt-title"> {{opt.name | translate}}
          </div>
        </div>
        <div class="image arrow">
          <img class="arrow-icon" src="./assets/icons/manage-account/arrow.svg">
        </div>
      </div>
    </ng-container>
    </div>
    <div class="last-padding-btm">
    </div>
  </div>
