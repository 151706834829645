import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrReportJsonformatComponent } from './hr-report-jsonformat.component';
import { PipesModule } from '../pipes/pipes.module';
import { ReportTableModule } from '../report-table/report-table.module';
import { ReportGraphModule } from '../report-graph/report-graph.module';
import { DirectiveModule } from '../directive/directive.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';



@NgModule({
  declarations: [HrReportJsonformatComponent],
  imports: [
    CommonModule, PipesModule, ReportTableModule, ReportGraphModule, DirectiveModule, NgxQRCodeModule
  ],
  exports: [
    HrReportJsonformatComponent
  ]
})
export class HrReportJsonformatModule { }
