<!-- <p class="check-label">{{ section.question[0].text || section.domainName}} <sup *ngIf="section['mandatory']=='true'"
    style="color: red">*</sup></p>
<div style="display: inline-flex;width: 100%;">
  <vaadin-date-picker min="{{ _min }}" max="{{ _max }}" val [i18n]="DATE_PICKER_FORMAT"
    [attr.id]="'vaadin-dp-'+section.domainCode" placeholder="Enter {{section.question[0].text}}"
    [(value)]="section['selected']" (change)="onChange($event)">
  </vaadin-date-picker>
</div>
<div class="mandatory right" *ngIf="((section['mandatory']=='true') && ( (section['selected']=='')))">
  <p>This Field is Mandatory</p>
</div> -->
<div class="task-section-title bold">
  <span
    *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span>
  <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
</div>
<div class="input-group date-input ui" [ngClass]="{valid: section['selected']}">
  <div class="label">
    <label for="inputId" class="input-label">
      <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
      <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
    </label>
  </div>
  <div class="value">
    <vaadin-date-picker min="{{ _min }}" max="{{ _max }}" val [i18n]="DATE_PICKER_FORMAT"
      [attr.id]="'vaadin-dp-'+section.domainCode" [tabindex]="tabindex"
      [(value)]="section['selected']" (change)="onChange($event)">
    </vaadin-date-picker>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && ( (section['selected']=='')))">
      <p>{{'form_field_mandatory' | translate}}</p>
    </div>
  </div>
</div>