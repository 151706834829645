<div id="choose-slot">
  <div class="slot-options slot-main">
    <div class="info-message">
      <div class="info-message__icon">
        <img src="assets/icons/slot/info.svg" alt="info-icon" class="info-icon">
      </div>
      <div class="info-details">{{'lbl_slot_info_details' | translate : 'LABELS': null:null:slotInfoLabel}}</div>
    </div>
    <div class="date-selection">
      <div class="date-picker cursor-pointer" (click)="openPicker()">
        <img class="type-image" src="assets/icons/slot/calendar_prim.svg">
        <div class="date">
          {{slotList.dates.length>0?(slotList.dates[0] | moment : 'DD MMM' : 'YYYY-MM-DD'):''}} -
          {{slotList.dates.length>0?(slotList.dates[slotList.dates.length-1] | moment : 'DD MMM' : 'YYYY-MM-DD'):''}}
        </div>
      </div>
      <div class="vaadin-hidden-date">
        <vaadin-date-picker [min]="curDate" [value]="curDate" id="date-range" class="vaadin-hidden-date"
          (change)="vaadinChange($event)" #vaadinDp>
        </vaadin-date-picker>
      </div>
    </div>
  </div>
  <div class="choose-slot-wrapper">
    <div class="visible-slots hide">
      <div class="slot-table">
        <div class="slot-header">
          <div class="slot-action cursor-pointer hide" (click)="changeStart(-1)">
            <img src="assets/icons/slot/left_arrow.svg">
          </div>
          <div class="slot-date hide" *ngFor="let item of slotList.dates | slice : startIndex : endIndex">
            <span class="date-label bold">{{item | moment : 'ddd, DD MMM YYYY' : 'YYYY-MM-DD'}}</span>
            <!-- <span class="font-default-title bold">&#x200e;{{item | moment : 'DD' : 'YYYY-MM-DD'}}&#x200e;</span>
            <span class="font-light-small day-name">&#x200e;{{item | moment : 'dddd':'YYYY-MM-DD'}}&#x200e;</span> -->
          </div>
          <div class="slot-action cursor-pointer hide" (click)="changeStart(1)">
            <!-- <img src="assets/icons/arrow.svg"> -->
            <img src="assets/icons/slot/right_arrow.svg">
          </div>
        </div>
        <div class="slot-content">
          <div class="slot-morning">
            <div class="slot-action prev">
              <!-- <img class="type-image" src="assets/icons/slot/morning.svg"> -->
            </div>
            <div class="slot-date hide" *ngFor="let date of slotList.dates | slice : startIndex : endIndex">
              <ng-container *ngIf="slotList['slots']?.[selectedClinic]&&slotList['slots']?.[selectedClinic]?.[date]">
                <div class="slots cursor-pointer" [ngClass]="{'disable-slot': !slot['isSelectable']}"
                  *ngFor="let slot of slotList['slots']?.[selectedClinic]?.[date]?.['mrng']||[]"
                  (click)="completeSelection(date, slot, selectedClinic)">
                  <!-- <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div> -->
                  <div class="slot">
                    <span>
                      {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                    </span>
                    <div class="weather-icon">
                      <img src="assets/icons/slot/morning.svg" alt="weather" class="weather">
                    </div>                  
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="slotList['slots']?.[selectedClinic]&&slotList['slots']?.[selectedClinic]?.[date]">
                <div class="slots cursor-pointer" [ngClass]="{'disable-slot': !slot['isSelectable']}" *ngFor="let slot of slotList['slots']?.[selectedClinic]?.[date]?.['noon']"
                  (click)="completeSelection(date, slot, selectedClinic)">
                  <!-- <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div> -->
                  <div class="slot">
                    <span>
                      {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                    </span>
                    <div class="weather-icon">
                      <img src="assets/icons/slot/noon_.svg" alt="weather" class="weather">
                    </div>                  
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="slotList['slots']?.[selectedClinic]&&slotList['slots']?.[selectedClinic]?.[date]">
                <div class="slots cursor-pointer" [ngClass]="{'disable-slot': !slot['isSelectable']}" *ngFor="let slot of slotList['slots']?.[selectedClinic]?.[date]?.['eve']"
                  (click)="completeSelection(date, slot, selectedClinic)">
                  <!-- <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div> -->
                  <div class="slot">
                    <span>
                      {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                    </span>
                    <div class="weather-icon">
                      <img src="assets/icons/slot/evening.svg" alt="weather" class="weather">
                    </div>                  
                  </div>
                </div>
              </ng-container>
              <div class="no-data" *ngIf="!slotList['slots']?.[selectedClinic]?.[date]?.['mrng']?.length && !slotList['slots']?.[selectedClinic]?.[date]?.['noon']?.length && !slotList['slots']?.[selectedClinic]?.[date]?.['eve']?.length">
                <app-nodata></app-nodata>
              </div>
            </div>
            <div class="slot-action next">

            </div>
          </div>
          <!-- <div class="slot-noon">
            <div class="slot-action prev">
              <img class="type-image" src="assets/icons/slot/noon_.svg">
            </div>
            <div class="slot-date hide" *ngFor="let date of slotList.dates | slice : startIndex : endIndex">
              <ng-container *ngIf="slotList['slots'][selectedClinic]&&slotList['slots'][selectedClinic][date]">
                <div class="slots cursor-pointer" [ngClass]="{'disable-slot': !slot['isSelectable']}" *ngFor="let slot of slotList['slots'][selectedClinic][date]['noon']"
                  (click)="completeSelection(date, slot, selectedClinic)">
                  <_!-- <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div> --_>
                  <div class="slot">
                    <span>
                      {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                    </span>
                    <div class="weather-icon">
                      <img src="assets/icons/slot/noon_.svg" alt="weather" class="weather">
                    </div>                  
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="slot-action next">

            </div>
          </div>
          <div class="slot-evening">
            <div class="slot-action prev">
              <img class="type-image" src="assets/icons/slot/evening.svg">
            </div>
            <div class="slot-date hide" *ngFor="let date of slotList.dates | slice : startIndex : endIndex">
              <ng-container *ngIf="slotList['slots'][selectedClinic]&&slotList['slots'][selectedClinic][date]">
                <div class="slots cursor-pointer" [ngClass]="{'disable-slot': !slot['isSelectable']}" *ngFor="let slot of slotList['slots'][selectedClinic][date]['eve']"
                  (click)="completeSelection(date, slot, selectedClinic)">
                  <_!-- <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div> --_>
                  <div class="slot">
                    <span>
                      {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                    </span>
                    <div class="weather-icon">
                      <img src="assets/icons/slot/evening.svg" alt="weather" class="weather">
                    </div>                  
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="slot-action next">
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="visible-slots show">
      <div class="slot-table">
        <div class="slot-header slot-header-wrapper">
          <!-- <div class="slot-action cursor-pointer hide" (click)="changeStart(-1)">
            <img src="assets/icons/arrow.svg">
          </div>
          <div class="show">
            <div class="slot-action cursor-pointer show" (click)="_changeStart(-1)">
              <img src="assets/icons/arrow.svg">
            </div>
          </div> -->
          <div class="show show-icon short-date">
            <div class="slot-date cursor-pointer" 
              *ngFor="let item of slotList.dates | slice : _startIndex : _endIndex; let i=index"
              [ngClass]="{'selected': item === selectedDate}" (click)="onDateSelect(item)">
              <span class="date-label">{{item | moment : 'ddd' : 'YYYY-MM-DD'}}</span>
              <span class="day-label bold">{{item | moment : 'DD' : 'YYYY-MM-DD'}}</span>
              <!-- <span class="font-default-title bold">&#x200e;{{item | moment : 'DD' : 'YYYY-MM-DD'}}&#x200e;</span>
              <span class="font-light-small day-name">&#x200e;{{item | moment : 'MMM':'YYYY-MM-DD'}}&#x200e;</span> -->
            </div>
          </div>
          <!-- <div class="show show-icon short-date">
            <div class="slot-action cursor-pointer" (click)="_changeStart(1)">
              <img src="assets/icons/header/next_arrow.svg">
            </div>
          </div> -->
        </div>
        <div class="slot-content slot-content-wrapper">
          <div class="slot-morning slot-morning__web">
            <!-- <div class="slot-action prev">
              <img class="type-image" src="assets/icons/cloud.svg">
            </div> -->
            <!-- <div class="show show-icon">
              <div class="slot-date" *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots'][selectedClinic]&&slotList['slots'][selectedClinic][date]">
                  <div class="slots cursor-pointer"
                    *ngFor="let slot of slotList['slots'][selectedClinic][date]['mrng']||[]" [ngClass]="{'disable-slot': !slot['isSelectable']}"
                    (click)="completeSelection(date, slot, selectedClinic)">
                    <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div>
                  </div>
                </ng-container>
              </div>
            </div> -->
            <div class="show show-icon" *ngIf="selectedSlots">
              <!-- <div class="slot-date" *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots']&&slotList['slots'][date]"> -->
                  <div class="slots cursor-pointer"
                    [ngClass]="{
                      'selectedSlot': (selectedSlot['time'] && 
                        slot['time'] == selectedSlot['time']), 
                      'last-col': ((j + 1) % 3 === 0)
                    }"
                    *ngFor="let slot of selectedSlots; let j= index"
                    (click)="completeSelection(selectedDate, slot, selectedClinic)">
                    <div class="slot">
                      <span>
                        {{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}
                      </span>
                      <div class="weather-icon">
                        <img [src]="slot['_icon']" alt="weather" class="weather">
                      </div>                  
                    </div>
                  </div>
                <!-- </ng-container>
              </div> -->
            </div>
            <!-- <div class="slot-action next">

            </div> -->
          </div>
          <!-- <div class="slot-noon">
            <div class="slot-action prev">
              <img class="type-image" src="assets/icons/sun.svg">
            </div>
            <div class="show show-icon">
              <div class="slot-date" *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots'][selectedClinic]&&slotList['slots'][selectedClinic][date]">
                  <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][selectedClinic][date]['noon']"
                    [ngClass]="{'disable-slot': !slot['isSelectable']}"
                    (click)="completeSelection(date, slot, selectedClinic)">
                    <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="slot-action next">

            </div>
          </div>
          <div class="slot-evening">
            <div class="slot-action prev">
              <img class="type-image" src="assets/icons/cloudy-night.svg">
            </div>
            <div class="show show-icon">
              <div class="slot-date " *ngFor="let date of slotList.dates | slice : _startIndex : _endIndex">
                <ng-container *ngIf="slotList['slots'][selectedClinic]&&slotList['slots'][selectedClinic][date]">
                  <div class="slots cursor-pointer" *ngFor="let slot of slotList['slots'][selectedClinic][date]['eve']"
                    [ngClass]="{'disable-slot': !slot['isSelectable']}"
                    (click)="completeSelection(date, slot, selectedClinic)">
                    <div class="slot">&#x200e;{{slot['slot'] | moment : 'hh:mm a' : 'HH:mm'}}&#x200e;</div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="slot-action next">
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
