import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaccinationFormComponent } from './vaccination-form.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { ClinicalformModule } from '../clinicalform/clinicalform.module';



@NgModule({
  declarations: [VaccinationFormComponent],
  imports: [
    CommonModule, FormsModule, PipesModule, ClinicalformModule
  ],
  exports: [VaccinationFormComponent]
})
export class VaccinationFormModule { }
