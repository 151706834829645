import { Component, OnInit, Input } from '@angular/core';
import { Type } from '../type.abstract';
import { SectionInterface } from '../../section.component';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
// import '@vaadin/vaadin-button/vaadin-button.js';
// import '@vaadin/vaadin-combo-box/vaadin-combo-box.js';
@Component({
  selector: 'app-datetimesegment',
  templateUrl: './datetimesegment.component.html',
  styleUrls: ['./datetimesegment.component.css']
})
export class DatetimesegmentComponent extends Type implements OnInit, SectionInterface {
  @Input() public section;
  @Input() public sections;
  public show = false;
  constructor(cfs: ClinicalformService) {
    super(cfs);
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.decideDate(this.section);
  }

  onChange() {
    super.getDays(this.section);
    super.getSelectedDate(this.section);
  }

  today() {
    super.decideDate(this.section);
    super.getSelectedDate(this.section);
  }

  tommorow() {
    super.selectTommorrow(this.section);
    super.getSelectedDate(this.section);
  }

  showChange() {
    this.show = true;
  }

  hideChange() {
    this.show = false;
  }

}

