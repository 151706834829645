import { Component, OnInit, HostListener, ElementRef,ViewChild, ViewContainerRef, Input,Output, EventEmitter} from '@angular/core';
import { ROUTE_LOGIN } from '../app.string';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService} from '../services/modal.service';
import { enterAnimation, switchAnimation } from "../animations";
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { UtilitiesService } from '../services/utilities.service';
import { environment } from '@env/environment';
import { Subject } from "rxjs";
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';
import { SocketService } from '../services/socket.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [enterAnimation, switchAnimation]
})
export class HeaderComponent implements OnInit {
  @Input() headerClass: any;
  @Input() patientInfo: any;
  @Input() isTaskView: any;
  @Input() showWelcome: boolean;
  @Input() pagesource: string = '';
  @Output() toggleSection: EventEmitter<any>;
  @Output() updateLinkedPatient: EventEmitter<any>;
  @Output() openLogin: EventEmitter<any> = new EventEmitter();

  public openUserProfileActions: boolean;
  public isBookAppointment: boolean;
  public personDetails: any = {};
  public isMenu= false;
  public switchLanguage: boolean;
  public languageList;
  public path;
  public isSelected;
  public isNotSelected: any;
  public isManageIdentities:boolean;
  public isChangePassword:boolean;
  public externalInterface:boolean;
  public extInterface: boolean;
  public isTaskActions: boolean = false
  public unreadAlertCount: number = 0;
  public isMyAlerts: boolean;
  public enableAlertOnServiceUpdateNotification:boolean=false;
  public updateAlertInfo:any;
  public isOpenNotification: boolean = false;
  public showMyAccountOptions : boolean =  false;

  public readonly isLoginTaskVersion: boolean = environment['LOGIN_VERSION']=='TASK';
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  public notificationRecieved: Subject<any> = new Subject();
  public isLoggedIn;

  constructor(private elementRef: ElementRef, private router: Router, private route: ActivatedRoute, public modalService: ModalService, private user: UserService, private authService: AuthService, private utils : UtilitiesService, private alert: AlertService, private socket: SocketService) {
    this.updateLinkedPatient = new EventEmitter<any>();
    this.toggleSection = new EventEmitter<any>();
    this.user.getExternalInterface().subscribe(data => {
      this.extInterface = data;
    })
    this.user.userUpdateSubject.subscribe(_ => {
      this.getPersonDetail();
    })
    this.user.getUpdateInfo('dashboard').subscribe(data => {
      this.isMyAlerts =  data.header.getAlerts && this.user.canViewOption('ENBALEMYALERTS');
    });

    setTimeout(()=>{
      if (this.isMyAlerts && this.user.isLoggedIn()) {
        this.getUnReadAlertsCount();
        this.socket.onPatNotification().subscribe(_ => {
          this.getUnReadAlertsCount();
        })
      }
    },2000)
  }

  ngOnInit() {
    this.isLoggedIn = this.user.isLoggedIn();
    if(this.isLoggedIn) {
      this.refresh.subscribe(response => {
        if (response) {
          this.getPersonDetail();
        }
      })
      this.getPersonDetail();
    }
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });
    this.path = this.route;
    this.isSelected = this.user.currentLanguage;
    this.checkNotSelected()
    this.user.getExternalInterface().subscribe(data => {
      this.externalInterface = data;
    })
    console.log("LOGIN TASK VERSION-->", this.isLoginTaskVersion);
    if (this.pagesource == 'HOME' && !this.isLoggedIn && false) {
      if (sessionStorage.getItem('LOGIN_PROMPT_DISPLAYED') != 'Y') {
        this.openLogin.emit(
          sessionStorage.setItem('LOGIN_PROMPT_DISPLAYED', 'Y')
        )
      }
    }
  }

  public checkNotSelected() {
    this.languageList.every(item=>{
      if(item['localeCode'] === this.isSelected)
        return true;
      else {
        this.isNotSelected = item;
        return false;
      }
    })
  }
  
  onItemChange(item){
    this.user.setDirection(item);
    this.isSelected = item.localeCode;
    this.switchLanguage = false;
    this.checkNotSelected();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && document.getElementById('user-profile-actions') && document.getElementById('user-profile-actions').contains(targetElement)) {
      return;
    }
    if (targetElement && document.getElementById('task-actions') && document.getElementById('task-actions').contains(targetElement)) {
      return;
    }

    if (targetElement && document.getElementById('language-actions') && !document.getElementById('language-actions').contains(targetElement) && document.getElementById('language-action-options') && !document.getElementById('language-action-options').contains(targetElement)) {
      this.switchLanguageClose();
    }
    if (targetElement && document.getElementById('user-action-tasks') && !document.getElementById('user-action-tasks').contains(targetElement) && document.getElementById('user-action-tasks-options')  && !document.getElementById('user-action-tasks-options').contains(targetElement)) {
      // this.closeUserActions();
      if(this.isTaskActions)
        this.closeTaskActions();
    }
  }
  public openUserActions() {
    this.openUserProfileActions = true;
    this.switchLanguage = false;
  }

  public closeUserActions() {
    this.openUserProfileActions = false;
    this.getPersonDetail();
  }

  public switchLanguageAction(){
    // this.switchLanguage = true;
    // this.openUserProfileActions = false;
    setTimeout(_ => {
      this.switchLanguage = !this.switchLanguage;
    }, 100)
  }

  public switchLanguageClose(){
    this.switchLanguage = false;
  }

  async editProfile(id){
    this.openModal(id);
  }

  openModal(id: string) {
    if (id == 'hdr-manage-identity-modal') {
      this.isManageIdentities = true;
    }
    else if(id == 'notification-modal'){
      this.isOpenNotification = true
    }
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }

  public openMenu() {
    this.isMenu = true;
  }

  public closeMenu() {
    this.isMenu = false;
  }
  public openChangePassword() {
    this.isChangePassword = true;
  }

  public closeChangePassword() {
    this.isChangePassword = false;
  }

  public closeManageIdentities() {
    this.updateLinkedPatient.emit();
    this.isManageIdentities = false;
  }

  public logout(focus?:string) {
    // this.auth.signOut();
    let params = {}
    if (focus) {
      params = {[focus]:1}
    }
    if(this.externalInterface === true){
      this.authService.ssoSignOut().subscribe(_ =>{
        this.user.clearStorage();
        this.router.navigate(['/'], {queryParams:params})
      location.reload();
      })
    }
    else {
      this.user.clearStorage();
      this.router.navigate(['/'], { queryParams: params })
      location.reload();
      // this.router.navigate([`../../${ROUTE_LOGIN}`], { relativeTo: this.route });
    }
  }

  public openInfo() {
    if(this.isLoginTaskVersion)
      return;
    let link = environment['urlInfo']['index'];
    if(link == '#') return;
    if(environment['urlInfo']['specifyLanguage'])
      link = this.utils.replaceLanguage(link, this.user.currentLanguage);
    this.user.openLocation(link, "_blank");
  }

  public getPersonDetail(){
    if (this.extInterface) {
      this.user.getSSOUserObject().subscribe( data => {
        this.personDetails = {
          personName: data.firstname + " "+data.lastname
        }
      })
     }
     else{
      this.user.getUserInfo('personDetails').subscribe(data => {
        this.personDetails = data || {};
        console.log("this.userInfo.personDetails['personName']",data)
      })
     }
  }
  public openTaskActions() {
    this.isTaskActions = !this.isTaskActions;
  }
  public closeTaskActions() {
    this.isTaskActions = false;
  }

  public openMyAssessments() {
    this.toggleSection.emit('ASSESSMENTS');
    this.closeTaskActions()
  }

  public getUnReadAlertsCount() {
    this.unreadAlertCount= 0
    this.alert.getAlerts(true).subscribe(data => {
      // if (data['status'] == "SUCCESS") {
        var events = [];
        var myalerts = [];
        if (data['events']) {
          events = data['events'].filter(function (value) {
            if (value.readStatus != 'yes')
              return value;
          });
        }
        if (data['alerts']) {
          myalerts = data['alerts'].filter(function (value) {
            if (value.readStatus != 'yes')
              return value;
          });
          let tmpUpdate;
          tmpUpdate=data['alerts'].map(a=>{
            if (a.category == 'UPDATES' && (a.updateCategory == 'SERVICEREQ' || a.updateCategory == 'PATLINK'))
              return a;
          });
          if(tmpUpdate && tmpUpdate.length>0)
          tmpUpdate=tmpUpdate.sort((a, b) => {
            return new Date(moment(b.startDate).format()).getTime() - new Date(moment(a.startDate).format()).getTime();
          })
          // tmpUpdate.sort((a,b)=>a.startDate-b.startDate);
          if(tmpUpdate.length>0 && tmpUpdate[0] && tmpUpdate[0].readStatus===''){
            this.enableAlertOnServiceUpdateNotification=true;
            this.updateAlertInfo=tmpUpdate[0];
          }
          console.log('tmp update home--',tmpUpdate,this.updateAlertInfo)
        }
        console.log('get alert count---',data,events,myalerts)
        this.unreadAlertCount = events.length + myalerts.length;
      // }
    })
  }

  public clearAlertInfo(alertInfo){
    console.log('clear')
    this.updateAlertInfo={}
    this.enableAlertOnServiceUpdateNotification = false;
    switch (alertInfo.updateCategory) {
      case 'SERVICEREQ':
        this.openModal('notification-modal');
        this.updateAlertStatus(alertInfo)
        break;
      case 'PATLINK':
        this.openModal('hdr-manage-identity-modal');
        this.updateAlertStatus(alertInfo)
        break;
    }
  }
  public updateAlertStatus(alert) {
    var ids= alert.id;
    this.alert.updateAlertStatus(ids, 'readstatus', true).subscribe(data => {
    })
  }

  public openOptions() {
  
  }

  public closeNotification(){
    this.isOpenNotification = false;
  }
 public openUserOptions() {
  this.openUserProfileActions = true;
  this.showMyAccountOptions = false;
 }


 @HostListener('document:click', ['$event'])
 public onClick(event: MouseEvent): void {
   const targetElement = event.target as HTMLElement;
   if (this.showMyAccountOptions && !(['user-account-options-wrapper', 'user-account-button-wrapper'].some(d => document.getElementById(d) && document.getElementById(d).contains(targetElement))))
     this.showMyAccountOptions = false;
 }
}
