import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-remove-poliy-modal',
  templateUrl: './remove-poliy-modal.component.html',
  styleUrls: ['./remove-poliy-modal.component.scss']
})
export class RemovePoliyModalComponent implements OnInit {
  @Output() complete: EventEmitter<any>;
  @Output() closeModal: EventEmitter<any>;
  constructor() {
    this.complete = new EventEmitter<any>();
    this.closeModal = new EventEmitter<any>();
  }

  ngOnInit() {}

  public changeBlnGrp() {
    this.complete.emit('CASH');
  }


  public close() {
    this.closeModal.emit();
  }
}
