<!-- <input (keyup)="change($event.target.value)" *ngIf="section.codify=='Y'" type="single" [(ngModel)]="section.selected" min="{{s.minValue}}" max="{{section.maxValue}}" step="{{section.stepCounter}}"> -->
<div class="ui">
<p *ngIf="section['selected']" class="range-head"><span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
     <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup> - <span class="range-val">{{section['selected']}}</span></p>
<div class="ui fluid input top-input">
    <div class="slidecontainer">
        <input class="slider" type="range" name="points" min="{{section.minValue}}" max="{{section.maxValue}}" step="{{section.stepCounter}}" [(ngModel)]="section['selected']" [tabindex]="tabindex"
        />
    </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
        <p>{{'form_field_mandatory' | translate}}</p>
    </div>
</div>

</div>