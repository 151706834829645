import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePaginationComponent } from './table-pagination.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [TablePaginationComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [
    TablePaginationComponent
  ]
})
export class TablePaginationModule { }
