import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'apptFilter'
})
export class ApptFilterPipe implements PipeTransform {

  transform(appts: any[], filterUpdatedOn: string, selectedFilters:any[]): unknown {
    if (!Array.isArray(appts))
      return [];
    return appts.filter(a => {
      if (selectedFilters && selectedFilters.length > 0) {
        let apptType = a['appointmentType']
        if (!['VIDEO', 'CLINIC'].includes(apptType)) apptType = 'HOSPITAL'
        return selectedFilters.includes(apptType)
      }
      return true;
    })
  }
}
