<div id="promo-popup" [ngClass]="{visible:showPopup}">
  <ng-container [ngSwitch]="popupType">
    <div *ngSwitchCase="'APPPROMO'" class="app-promo-pop">
      <div class="promo-title">{{'view_in_app' | translate}}</div>
      <div class="view-options">
        <div class="view-item" *ngFor="let item of appOpenOptions">
          <div class="icon">
            <img class="type-image" [src]="item.icon"/>
          </div>
          <div class="name">
            {{item.name | translate}}
          </div>
          <div class="action">
            <button [ngClass]="{'primary-button': item.action == 'primary', 'secondary-button': item.action ==
              'secondary'}" (click)="promoAction(item.payload)">{{item.button | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>