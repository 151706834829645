import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vaccination-certificate',
  templateUrl: './vaccination-certificate.component.html',
  styleUrls: ['./vaccination-certificate.component.scss']
})
export class VaccinationCertificateComponent implements OnInit {
  @Input() patientInfo: Object;
  @Input() taskInfo: Object;
  @Input() isCertificateDetails: boolean = false;
  public qrcodeUrl;
  

  ngOnInit(): void {
    this.qrcodeUrl = location.href + '#details';
  }
  
}
