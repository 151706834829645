import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import * as config from '../../assets/json/taskConfig.json';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit, OnChanges {

  @Input() workflow: any;
  @Input() patientInfo: any;
  @Input() task: any;
  @Input() applicableStatus: any;
  @Input() lang: string;
  @Input() languageList: any;
  @Input() APPOINTMENTS: any;
  @Input() CERTIFICATE: any;
  @Input() disableBtn: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  public topics: any
  public config: Object = (config as any).default;
  public switchLanguage: boolean;
  constructor(private user: UserService, private router: Router, private activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    console.log('appl', this.applicableStatus, this.config);
    if (this.workflow && this.workflow['eventInfo'] && Array.isArray(this.workflow['eventInfo']['events']) && this.workflow['eventInfo']['events'][0] && this.workflow['eventInfo']['events'][0]['noteContent'] && this.workflow['eventInfo']['events'][0]['noteContent']['topics'])
      this.topics = this.workflow['eventInfo']['events'][0]['noteContent']['topics'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['lang'])
      this.task['_guidelines'] = this.task['taskGuidelineContent'] && this.task['taskGuidelineContent'][this.lang] ? this.task['taskGuidelineContent'][this.lang] : '';
  }


  public performAction(status: Object) {
    this.action.emit(status);
  }

  public switchLanguageAction() {
    // this.switchLanguage = true;
    // this.openUserProfileActions = false;
    if (this.switchLanguage) {
      this.switchLanguage = false;
    }
    else {
      this.switchLanguage = true;
    }
  }

  public switchLanguageClose() {
    this.switchLanguage = false;
  }

  onItemChange(item) {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { lang: item.localeCode } })
    // this.user.setDirection(item); 
    this.switchLanguage = false;
  }

  printTask() {
    this.performAction({actionCode:"PRINT#TASK"})
  }
}
