<div class="service-request-wrapper">

    <div class="header border-bottom">
        <div>
            <div class="header-title bold">{{'Service_requests' | translate}}
            </div>
        </div>

        <div class="float-right closeicon pointer" (click)="closeModal()">
            <img src="./assets/icons/close_grey.svg">
        </div>
    </div>

    <div class="service-request-details">
        <div class="request-header">
            <div class="request-header-label" *ngIf="selectedServiceRequest.reqType === 'ENQ'">{{'Enquiry' ?? '-' |
                translate }}
            </div>
            <div class="request-header-label" *ngIf="selectedServiceRequest.reqType === 'SERVICE'">{{'Service' ?? '-' |
                translate }}
            </div>
            <div class="request-header-label" *ngIf="selectedServiceRequest.reqType === 'APPT'">{{'Appointment_request'
                ??
                '-' | translate }}
            </div>
            <div class="request-header-value">{{selectedServiceRequest.dttm | moment: 'DD MMM YYYY, hh:mm A' ?? '-' |
                translate }}</div>
        </div>

        <div class="card1 row cursor-pointer">

            <div class="service-request-card-header">
                <label class="card-header-name">{{'Service_details' | translate}}</label>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Type' | translate }}:</span>
                <span class="request-type" *ngIf="selectedServiceRequest.reqType === 'ENQ'">
                    {{ 'Enquiry' ?? '-' | translate}}
                </span>
                <span class="request-type" *ngIf="selectedServiceRequest.reqType === 'SERVICE'">
                    {{ 'Service' ?? '-' | translate}}
                </span>
                <span class="request-type" *ngIf="selectedServiceRequest.reqType === 'APPT'">
                    {{ 'Appointment_request' ?? '-' | translate}}
                </span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Practitioner_name' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.practitionerName
                    ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Specility_name' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.specialityName
                    ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Preferred_date' | translate }}:</span>
                <span class="request-type">
                    {{ selectedServiceRequest.reqBody.addedDate | moment: 'ddd, DD MMM YYYY' ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Hospital' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.facilityName
                    ?? '-' | translate}}</span>
            </div>

            <div class="service-request-card-header">
                <label class="card-header-name">{{'Patient_details' | translate}}</label>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Patient_name' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.patientName
                    ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Patient_ID' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.patientId
                    ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'E-mail_ID' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.email
                    ?? '-' | translate}}</span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Mobile_number' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.mobile
                    ?? '-' | translate}}</span>
            </div>

            <div class="service-request-card-header">
                <label class="card-header-name">{{'Payment_details' | translate}}</label>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Charges' | translate }}:</span>
                <span class="request-type charges-active">
                    {{'SAR '}} {{ selectedServiceRequest.reqBody.amount ?? '-' | translate}}
                </span>
            </div>
            <div class="service-details">
                <span class="type-label">{{'Reference_number' | translate }}:</span>
                <span class="request-type">{{ selectedServiceRequest.reqBody.gatewayRefNo
                    ?? '-' | translate}}</span>
            </div>

        </div>
    </div>


    <div class="footer">
        <button class="cancel-btn" (click)="cancelRequest(selectedServiceRequest)">{{'Cancel_request'|
            translate}}</button>
    </div>

</div>