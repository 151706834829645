import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionPopupComponent } from './promotion-popup.component';
import { PlatformModule } from '@angular/cdk/platform';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    PromotionPopupComponent
  ],
  imports: [
    CommonModule, PlatformModule, PipesModule
  ],
  exports: [
    PromotionPopupComponent
  ]
})
export class PromotionPopupModule { }
