import { Injectable } from '@angular/core';
import { isArray as _isArray, indexOf as _indexOf} from 'lodash';
import * as moment from 'moment';
import { PhoneNumberUtil } from "google-libphonenumber";
import { ClinicalformService } from '../services/clinicalform.service';
import { UserService } from '../services/user.service';
@Injectable()
export class ClinicalFormUtils {

  constructor(private formService:ClinicalformService, private user: UserService) {
  }

  checkConditionalTopic(topic, topics) {
    let tmp = true;
    try {
      const condTopic = topics.filter(t => t.id === topic.conditionalTopicId);
      if (condTopic.length > 0) {
        for (let m = 0; m < condTopic[0].sections.length; m++) {
          if (condTopic[0].sections[m].domainCode === topic.conditionalFieldCode) {
            if (condTopic[0].sections[m].conditional) {
              if (this.checkConditional(condTopic[0], condTopic[0].sections[m])) {
                tmp = this.checkConditional(condTopic[0], topic);
              } else {
                tmp = false;
              }
            } else {
              tmp = this.checkConditional(condTopic[0], topic);
            }
          }
        }
      }
    } catch (err) {
    }
    return tmp;
  }

  checkConditional(t, s) {
    let temp = true;
    if (!s.conditional) {
      temp = true;
    }
    else {
      for (let i in t.sections) {
        if (t.sections[i].codify === 'Y') {
          if (s.conditionalFieldCode === t.sections[i].domainCode) {
            let cc: any = '';
            if (t.sections[i].conditional) {
              cc = this.checkConditional(t, t.sections[i]);
              //console.log("CC =>", t.sections[i].domainCode, cc);
            }
            if (cc == '' || cc) {
              if (!t.sections[i].selected) {
                temp = false;
              }
              else {
                if (!s.operation.lg || s.operation.lg === "null" || s.operation.lg === "NULL" || s.operation.lg === null) {
                  if (t.sections[i].type === 'check' || ((t.sections[i].type === 'select' || t.sections[i].type === 'segment') && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].selected.split(',');
                    if (_isArray(s.conditionalFieldValue)) {
                      if (_indexOf(selectedArray, s.conditionalFieldValue[0].value) > -1) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (_indexOf(selectedArray, s.conditionalFieldValue) > -1) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    if (_isArray(s.conditionalFieldValue)) {
                      if (t.sections[i].selected === s.conditionalFieldValue[0].value) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (t.sections[i].selected === s.conditionalFieldValue) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else if (s.operation.lg == 'lt' && s.operation.eq) {
                  if (_isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected <= s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected <= s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'gt' && s.operation.eq) {
                  if (_isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected >= s.conditionalFieldValue[0].value) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected >= s.conditionalFieldValue) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'lt' && !s.operation.eq) {
                  if (_isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected < s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected < s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'gt' && !s.operation.eq) {
                  if (_isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected > s.conditionalFieldValue[0].value) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected > s.conditionalFieldValue) {
                      temp = true
                    }
                    else {
                      temp = false;
                    }
                  }
                }
                else if (s.operation.lg == 'in') {
                  //
                  if (t.sections[i].type === 'check' || ((t.sections[i].type === 'select' || t.sections[i].type === 'segment') && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].selected.split(',');
                    //console.log("IN =>", selected, s.conditionalFieldValue);
                    if (_isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (_indexOf(selectedArray, s.conditionalFieldValue[i].value) > -1) {
                          // console.log("Matched in");
                          tmp = true;
                        }
                      }
                      if (tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (_indexOf(selectedArray, s.conditionalFieldValue) > -1) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    let selected = t.sections[i].selected;
                    //console.log("IN =>", selected, s.conditionalFieldValue);
                    if (_isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selected == s.conditionalFieldValue[i].value) {
                          // console.log("Matched in");
                          tmp = true;
                        }
                      }
                      if (tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selected == s.conditionalFieldValue) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else if (s.operation.lg == 'notin') {
                  if (t.sections[i].type === 'check' || ((t.sections[i].type === 'select' || t.sections[i].type === 'segment') && (t.sections[i].multiSelect || t.sections[i].domainControls.multiSelect))) {
                    let selectedArray = t.sections[i].selected.split(',');
                    if (_isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (_indexOf(selectedArray, s.conditionalFieldValue[i].value) > -1) {
                          tmp = true;
                        }
                      }
                      if (!tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (_indexOf(selectedArray, s.conditionalFieldValue) == -1) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  } else {
                    let selected = t.sections[i].selected;
                    if (_isArray(s.conditionalFieldValue)) {
                      let tmp = false;
                      for (let i = 0; i < s.conditionalFieldValue.length; i++) {
                        if (selected == s.conditionalFieldValue[i].value) {
                          tmp = true;
                        }
                      }
                      if (!tmp) {
                        temp = true;
                      }
                      else {
                        temp = false;
                      }
                    }
                    else {
                      if (selected != s.conditionalFieldValue) {
                        temp = true
                      }
                      else {
                        temp = false;
                      }
                    }
                  }
                }
                else {
                  if (_isArray(s.conditionalFieldValue)) {
                    if (t.sections[i].selected === s.conditionalFieldValue[0].value) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                  else {
                    if (t.sections[i].selected === s.conditionalFieldValue) {
                      temp = true;
                    }
                    else {
                      temp = false;
                    }
                  }
                }
              }
            }
            else {
              temp = false;
            }
          }
        }
      }
    }
    if (!temp && s.selected)
      s.selected = null;
    if (temp && !s.selected) {
      if (s.codify === 'Y') {
        if (s.domainControls && s.domainControls.answer && Array.isArray(s.domainControls.answer))
          for (let l = 0; l < s.domainControls.answer.length; l++) {
            if (s.domainControls.answer[l].default) {
              s.selected = s.domainControls.answer[l].localName[0].text;
            }
          }
        if (s.type === 'longText' && s.domainControls && Array.isArray(s.domainControls.templates)) {
          for (let l = 0; l < s.domainControls.templates.length; l++) {
            if (s.domainControls.templates[l].default) {
              s.selected = s.domainControls.templates[l].content;
            }
          }
        }
        if (s.type === 'timePeriod') {
          for (let l = 0; l < s.domainControls.dropdownOptions.length; l++) {
            if (s.domainControls.dropdownOptions[l].default) {
              s.dropdownOptionSelected = s.domainControls.dropdownOptions[l].name;
            }
          }
        }
      }
      else {
        for (let l = 0; l < s.answer.length; l++) {
          if (s.answer[l].default) {
            s.selected = s.answer[l].localName[0].text;
          }
        }
        if (s.type === 'timePeriod') {
          for (let l = 0; l < s.dropdownOptions.length; l++) {
            if (s.dropdownOptions[l].default) {
              s.dropdownOptionSelected = s.dropdownOptions[l].name;
            }
          }
        }
      }
    }
    // if (s.default)
    //     s.selected = s.default;
    return temp;
  }

  checkFormFilled(topic): boolean {
    let bool = true;
    topic['sections'].map(section => {
      if (section['mandatory'] == 'true' && this.checkConditional(topic, section)) {
        if (section['domainCode'] === 'PHNO') {
          if (!this.checkPhoneNumber(section)) {
            section['notValid'] = true;
            bool = false;
          }
        }
        if (section['domainCode'] === 'SYSPATSRCH') {
          if (!section['isValid'] && section['selected']) {
            section['_error'] = 'Invalid Patient ID';
            bool = false;
          }
        }
        if (!section['selected'] || section['selected'] == '') {
          section['selected'] = '';
          bool = false;
        }
        if (section['type'] == 'dateAndTime' && section['codify'] == 'Y' && section['domainControls'] && section['domainControls']['restrictPastDateEntry'] == 'Y' && this.user.getMoment(section['selected']).isBefore(this.user.getMoment(), 'm')) {
          section['_error'] = 'Past date is restricted';
          bool = false;
        }
      }
      if (section.type === 'numericInteger' && section['_validationErr'])
        bool = false;
    });
    return bool;
  }

  checkMandatory(topic) {
    for (let section of topic['sections']) {
      if (section['mandatory'] == 'true' && this.checkConditional(topic, section)) {
        if (section['domainCode'] === 'PHNO') {
          if (!this.checkPhoneNumber(section))
            return section;
        }
        if (section['domainCode'] === 'SYSPATSRCH') {
          if (!section['isValid'] || !section['selected'])
            return section;

        }
        if (!section['selected'] || section['selected'] == '')
          return section
      }
    }
  }

  checkPhoneNumber(section) {
    try {
      var phoneUtil = PhoneNumberUtil.getInstance();
      var number = phoneUtil.parse(section['selected']);
      if (phoneUtil.isValidNumber(number))
        return true;
      else
        return false
    } catch (error) {
      return false;
    }
  }

  checkFormBeforeSubmit(topics, xid, patient) {
    topics.map(topic => {
      topic = this.checkForAddendum(topic);
      if (topic['conditional'] || this.checkConditionalTopic(topics, topic)) {
        this.checkSomeConditions(topic, patient);
      }
    });

  }
  checkForAddendum(topic) {
    if (topic['type'] === 'addendum' && topic['adId'] == '') {
      topic.topic = 'Added by ' + 'HPWEBACCESS' + 'on ' + this.user.getMoment().format('DD MMM YYYY hh:mm:ss a');
    }
    return topic;
  }

   checkSomeConditions(topic, patient) {
    topic['sections'].map(async section => {

      /** Showing topic in Preview **/
      topic['showTopicInPreview'] = section['selected'] ? true : false;

      /** Add Selected Code **/
      section['selectedCode'] = this.checkIfSectionIsCodified(section);

      if (section['type'] == 'formula') {
        this.checkFormula(topic, section);
      }
    });
  }

  private checkIfSectionIsCodified(section): any {
    let selectedCode;
    if (section['codify'] == 'Y') {
      section['domainControls']['answer'].map(ans => {
        if (ans['localName'][0]['text'] == section['selected']) {
          selectedCode = ans['code'] ? ans['code'] : null;
        }
      });
    }
    return selectedCode;

  }

  checkFormula(t, s) {
    let vars = s.formulaVariables.map(v => {
      return {
        name: v,
        value: this.getValueForCode(t, v)
      }
    })
    let formula = s.formula;
    let dontCalc = false;
    let noValue = [];
    if (vars.every(v => v.value !== 0 && !v.value))
      dontCalc = true;
    vars.forEach(v => {
      if (v.value !== 0 && !v.value)
        noValue.push(v.name);
      formula = formula.split(v.name).join(v.value);
    })

    if (dontCalc) {
      s.selected = null;
      let err = this.getFirstEmptyFormulaField(t.sections, noValue);
      return { status: 'E', err: err }
    }
    else {
      let result = this.formService.commonEvalMethod(formula);
      console.log(typeof result);
      s.selected = (typeof result !== 'string') ? result.toFixed(2) : result;
      return { status: 'S', data: s.selected, name: t.topic }
    }
  }

  getValueForCode(t, code) {
    if (code == '|SYSAGE|')
      return this.user.getMoment().diff(this.user.getMoment('12-09-1996'), 'years');
    else if (code == '|SYSGENDER|')
      return 'M';
    let s = t.sections.filter(s => s.domainCode === code)[0];
    if (s.type == 'numericInteger' || s.type == 'numericDecimal' || s.type == 'numericIntegerRange' || s.type == 'numericDecimalRange' || s.type == "dateAndTime" || s.type == 'select') {
      return s.selected;
    }
    else {
      let filtered:Array<any>;
      if (s.trueType == 'checkBox') {
        filtered = s.domainControls.answer.filter(a => s.selected.split(',').includes(a.localName[0].text));
      } else {
        filtered = s.domainControls.answer.filter(a => a.localName[0].text === s.selected);
      }

      return s.selected && filtered.length ? (filtered.some(f => f['score']) ? filtered.reduce((t, n) => t + (+n),0) :s.selected) : null;
    }
  }

  getFirstEmptyFormulaField(sections, noValue) {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].domainCode && _indexOf(noValue, sections[i].domainCode) != -1) {
        return sections[i]['domainName'];
      }
    }
  }




  save(topics, noteCode, patient?) {
    this.checkFormBeforeSubmit(topics, '', patient);
    let codifiedOutComes = this.getCodifiedOutcomes(topics);
    let reportingOutComes = this.getReportingOutcomes(topics, noteCode);
    let object = this.computeOutComes(topics);
    let outcome = object['outcomes'];
    let topicOutcomes = object['topicOutcomes'];
    let response = {
      codifiedOutComes: codifiedOutComes,
      reportingOutComes: reportingOutComes,
      outcome: outcome,
      topicOutcomes: topicOutcomes,
      topics: topics,
      type: 'submit'
    };
    return response;
  }

  getCodifiedOutcomes(topics) {
    if (!topics)
      return null;
    let outcomes = [];
    topics.forEach(topic => {
      let index = this.findInData(topic, outcomes);
      let tmp: any;
      if (index == -1) {
        tmp = {
          topic: topic['topic'],
          outcomes: []
        };
      }
      else {
        tmp = outcomes[index];
      }
      topic.sections.forEach(s => {
        if (s.codify == 'Y' && s.selected) {
          tmp.outcomes.push({
            code: s.domainCode,
            value: s.selected
          })
        }
      })
      if (tmp.outcomes.length > 0 && index == -1)
        outcomes.push(tmp);
    })
    return outcomes;


  }

  findInData(topic, outcome) {
    let index = -1;
    for (let i in outcome) {
      if (outcome[i].topic === topic['topic'])
        index = parseInt(i);
    }
    return index;
  }

  public getReportingOutcomes(topics, noteCode, staticOutcomes?:any[]) {
    if (!topics)
      return null;
    let outcome = {
      noteCode: noteCode,
      topics: []
    };

    let seqNo = 0;

    topics.forEach((topic, index) => {
      let toPush = {
        topicName: topic['topic'],
        codes: [],
        seqNo: 0
      }
      topic['sections'].forEach(section => {
        if (section.type === 'compositeField' && Array.isArray(section['multipleFields'])) {
          let tempCodes = { name: [], code: [], values: [], displayTxt: [] };
          section['multipleFields'].forEach(m => {
            if (m['codify'] == 'Y' && m['domainControls'].reportingFieldYn == 'Y' && m['selected']) {
              tempCodes.name.push(m['domainName']);
              tempCodes.code.push(m['domainCode']);
              tempCodes.values.push(m['selected']);
              tempCodes.displayTxt.push(this.getTextFromAnswer(m));
            }
          });
          let questionTxt = section.codify === 'Y' ? section.domainName : section.question[0].text;
          let finalCodes = {
            name: questionTxt || tempCodes.name.join('#_#'),
            code: tempCodes.code.join('#_#'),
            values: tempCodes.values.join(' '),
            displayTxt: tempCodes.displayTxt.join(' ')
          }
          if (finalCodes.values)
            toPush['codes'].push(finalCodes);
          if (section['externalEvent'] === 'BOOK_APPT' && section.apptRefNumber) {
            let val = this.generateAppointmentMetaDataForPreview(section);
            toPush['codes'].push({
              name: 'Appointment booked',
              code: section['externalEvent'],
              values: val,
              displayTxt: val,
              apptRefNumber: section.apptRefNumber
            })
          }

        }
        if (section['codify'] == 'Y' && section['domainControls']['reportingFieldYn'] == 'Y' && section['selected']) {
          let code = {};
          code = this.reportingOutcomeForSection(section);
          if (section.type === 'masterLinked') {
            code['selectedCode'] = section['selectedCode'];
            code['selectedvalue'] = section['selectedValue'];
          }

          toPush['codes'].push(code)
        }
      })

      if (toPush['codes'].length > 0) {
        toPush.seqNo = seqNo + 1;
        seqNo = seqNo + 1;
        outcome['topics'].push(toPush)
      }

    });

    if (Array.isArray(staticOutcomes) && staticOutcomes.length) {
      if (!outcome['topics'].length)
        outcome.topics.push({
          topicName: '',
          codes: [],
          seqNo: 1
        });
      outcome.topics[outcome.topics.length - 1]['codes'] = outcome.topics[outcome.topics.length - 1]['codes'].concat(staticOutcomes);
    }

    return outcome['topics'].length ? outcome : null;
  }

  public reportingOutcomeForSection = (s, frequencyApplicable: Array<any> = [], routeApplicable: Array<any> = []) => {
    let code = {}, val;
    switch (s.type) {
      case 'resultLinked':
        val = (Array.isArray(s.selected) && s.selected.length > 0) ? s.selected[0].resultNum + ' ' + s.selected[0].uom : '';
        return {
          name: s.domainName,
          code: s.domainCode,
          values: val,
          type: s.type,
          displayTxt: val
        };
      case 'mergeFields':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          _format: 'HTML',
          type: s.type,
          displayTxt: s.selected
        };
      case 'segment':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: this.getValueForSegment(s.selected, s.domainCode, frequencyApplicable || [], routeApplicable || [])
        };
      case 'timePeriod':
        val = s.selected && s.dropdownOptionSelected ? s.selected + ' ' + s.dropdownOptionSelected : '' + s.selected;
        return {
          name: s.domainName,
          code: s.domainCode,
          values: val,
          type: s.type,
          displayTxt: val,
        };
      case 'draw':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.publicPath || val
        };
      case 'masterLinked':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.selected,
          selectedCode: s.selectedMaster && s.selectedMaster.code ? s.selectedMaster.code : s.selectedMaster
        };
      case 'date':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.selected ? this.user.getMoment(s.selected).format('DD-MMM-YYYY') : null
        };
      case 'dateAndTime':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.selected ? this.user.getMoment(s.selected).format('DD-MMM-YYYY h:mm a') : null
        };
      case 'time':
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.selected ? this.user.getMoment(s.selected).format('h:mm a') : null
        };
      default:
        return {
          name: s.domainName,
          code: s.domainCode,
          values: s.selected,
          type: s.type,
          displayTxt: s.selected
        };
    }
  };


  public getValueForSegment(selected, domainCode, frequencyApplicable: Array<any> = [], routeApplicable: Array<any> = []) {
    if (domainCode === 'PRESFRQ') {
      let val = frequencyApplicable.find(f => f.Freqcode === selected);
      return val ? val.Freqdesc : selected;
    } else if (domainCode === 'PRESROUTE') {
      let val = routeApplicable.find(f => f.routecode === selected);
      return val ? val.routedesc : selected;
    } else {
      return selected;
    }
  }

  public computeOutComes(topics) {
    if (topics.length == 0)
      return null;
    let outcomes = [];
    let topicOutcomes = [];
    topics.map(topic => {
      topic['sections'].map(section => {
        if (section['codify'] === 'Y') {
          if (section['domainControls']['normalHigh'] && section['domainControls']['normalLow']) {
            if (section['selected'] > section['domainControls']['normalHigh']) {
              section['resultIndicator'] = 'H';
            }
            else if (section['selected'] < section['domainControls']['normalLow']) {
              section['resultIndicator'] = 'L'
            }
            else {
              section['resultIndicator'] = null;
            }
          }
        }
        const outcome = this.getOutcome(section)
        if (outcome)
          outcomes.push(outcome);
      });
      topicOutcomes.push(this.getScores(topic));
      topicOutcomes.forEach(t => {
        if (t.scoreDomain && t.scoreValue) {
          outcomes.push({ name: t.scoreDomain, value: t.scoreValue })
        }
      })
    });
    return {
      outcomes: outcomes,
      topicOutcomes: topicOutcomes
    }

  }


  getOutcome(section): Array<any> {
    let outcomes;
    if (section['outcomeField'])
      if (section['outcomeField']['available']) {
        let outcome = {
          name: section.question[0].text,
          value: section.selected,
          significant: false
        }
        outcomes = outcome;

      }

    if (section['domainControls'] && section['domainControls']['outcomeField'])
      if (section['domainControls']['outcomeField']['available']) {
        if (section['type'] === 'select' && section['domainControls']['multiSelect'] == 'Y') {
          for (let i = 0; i < section['selected'].length; i++) {
            let outcome = {
              name: section['domainCode'],
              value: (section['selected'][i]) ? section['selected'][i] : null,
              significant: false
            }
            if (section['domainControls']['significant']) {
              for (let t = 0; t < section['domainControls']['answer'].length; t++) {
                if ((section['domainControls']['answer'][t]['localName'][0].text === section['selected'][i]) && section['domainControls']['answer'][t]['significant']) {
                  outcome['significant'] = true;
                }
              }
            }
            outcomes.push(outcome)
          }
        }
        else {
          let outcome = {
            name: section['domainCode'],
            value: (section['selected']) ? section['selected'] : null,
            significant: false
          }
          if (section['domainControls']['significant']) {
            for (let t = 0; t < section['domainControls']['answer'].length; t++) {
              if ((section['domainControls']['answer'][t]['localName'][0]['text'] === section['selected']) && section['domainControls']['answer'][t]['significant']) {
                outcome['significant'] = true;
              }
            }
          }
          outcomes = outcome;

        }
      }
    return outcomes;
  }

  getScores(topic): any {
    let outcomes = {
      topic: topic['topic'],
      outcomes: '',
      scoreValue: null,
      scoreDomain: null
    };

    let totalScore = 0;
    topic['sections'].map(section => {
      if (section['answer'])
        section['answer'].map(ans => {
          if (ans['score'] && ans) {
            if (section['selected'] === ans['localName'][0]['text']) {
              totalScore += ans['score'];
            }
          }
        });
    });

    outcomes['scoreValue'] = totalScore;

    var result = "";
    if (topic['results']) {
      topic['results'].map(tr => {
        if (tr['from'] <= totalScore && totalScore <= tr['to']) {
          result = tr['value'];
          outcomes.scoreDomain = tr['scoreDomainCode'];
        }
      });

    }
    outcomes['outcome'] = result;

    return outcomes;

  }

  public isFormDirty = (forms) => {
    if (!Array.isArray(forms)) forms = [forms];
    let isDirty: boolean = false;
    for (let f in forms) {
      let form = forms[f];
      if (form.noteContent && form.noteContent.topics) {
        for (let t in form.noteContent.topics) {
          let topic = form.noteContent.topics[t];
          for (let s in topic.sections) {
            let section = topic.sections[s];
            if (section && section.selected)
              isDirty = true;
          }
        }
      }
    }
    return isDirty;
  };

  public checkNoteFilled(noteContent) {
    let bool = true;
    if (!noteContent || !Array.isArray(noteContent['topics']))
      return false;
    console.log('NoteContent', noteContent);
    noteContent['topics'].forEach(topic => {
      if (this.checkConditionalTopic(topic, noteContent['topics'])) {
        topic['sections'].map(section => {
          if (section['mandatory'] == 'true' && this.checkConditional(topic, section)) {
            if (section['domainCode'] === 'PHNO') {
              if (!this.checkPhoneNumber(section)) {
                section['notValid'] = true;
                bool = false;
              }
            }
            if (section['domainCode'] === 'SYSPATSRCH') {
              if (!section['isValid'] && section['selected']) {
                section['_error'] = 'Invalid Patient ID';
                bool = false;
              }
            }
            if (!section['selected'] || section['selected'] == '') {
              section['selected'] = '';
              bool = false;
            }
          }
        });
      }
    })
    return bool;
  }

  public generateAppointmentMetaDataForPreview = (section: any) => {
    if (!section.apptData || !section.apptData.apptDate || !section.apptData.apptTime) return section.apptRefNumber;
    return this.user.getMoment(section.apptData.apptDate, 'YYYY-MM-DD').format('DD MMMM YYYY') + ' ' + this.user.getMoment(section.apptData.apptTime, 'HH:mm').format('h:mm a') + ` (${section.apptRefNumber})`;
  };

  public getTextFromAnswer = (s) => {
    if (s.type !== 'select')
      return s.selected;
    if (s.codify === 'Y' && s.domainControls && s.domainControls.answer) {
      let ans = s.domainControls.answer.find(a => a.code === s.selected);
      if (ans && ans.localName && ans.localName[0])
        return ans.localName[0].text || s.selected
    }
    return s.selected;
  };

  dataURItoFile(dataURI, filename) {
    var arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  calculateProgress(topic, topics, topicIndex) {
    if (topic.multiple) {
      let rptTopics: any = [];
      for (let i = 0; i < topics.length; i++) {
        if (topics[i].multiple) {
          rptTopics.push(i)
        } else {
          if (topics[i].subTopic) {
            rptTopics.push(i)
          } else {
            if (rptTopics.indexOf(topicIndex) != -1) {
              break;
            } else {
              rptTopics = [];
            }
          }
        }
      }
      let result = { total: 0, totalMandatory: 0, filled: 0, filledMandatory: 0 }
      rptTopics.forEach(t => {
        result = this.calculateForTopic(topics[t], result.total, result.totalMandatory, result.filled, result.filledMandatory)
      })
      return this.calculatePercentage(result);
    } else {
      let result = this.calculateForTopic(topic);
      return this.calculatePercentage(result);
    }
  }

  calculatePercentage(result) {
    let per = (result.filled / result.total) * 100;
    return `${per}%`
  }

  calculateForTopic(topic, total = 0, totalMandatory = 0, filled = 0, filledMandatory = 0) {
    let visibleSections = topic.sections.filter(s => this.getVisibleSection(topic, s));
    total += visibleSections.length;
    let filledSections = visibleSections.filter((s) => {
      //console.log(s)
      if (s.type == 'photo' || s.type == 'schematics') {
        if (s.selectedPic && Array.isArray(s.selectedPic) && s.selectedPic.length > 0)
          return s.selectedPic
      } else if (s.type == 'video') {
        return s.selectedVideo
      }
      else if (s.type == 'file') {
        if (s.selectedFile && Array.isArray(s.selectedFile) && s.selectedFile.length > 0)
          return s.selectedFile
      } else {
        return s.selected
      }
    }
    );
    filled += filledSections.length;
    return { total: total, totalMandatory: totalMandatory, filled: filled, filledMandatory: filledMandatory }
  }
  getVisibleSection(topic, section) {
    return section.type !== 'caption' && section.type !== "captionEnd" && this.checkConditional(topic, section)
  }
}


