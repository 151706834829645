import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaccinationCertificateComponent } from './vaccination-certificate.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [VaccinationCertificateComponent],
  imports: [
    CommonModule,NgxQRCodeModule, PipesModule
  ],
  exports: [VaccinationCertificateComponent]
})
export class VaccinationCertificateModule { }
