import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-person-avatar',
  templateUrl: './person-avatar.component.html',
  styleUrls: ['./person-avatar.component.scss']
})
export class PersonAvatarComponent implements OnInit {
  @Input() public url: string;
  @Input() public name: string;
  @Input() public classNames: string;
  @Input() public additionalClass: string;
  @Input() fromPatientTask: boolean;
  @Input() default: boolean = false;

  public imgUrl: string;
  public isImage: boolean;
  public isInitial: boolean;
  public isDummy: boolean;


  ngOnInit() {
    if (this.url) {
      this.isImage = true;
      this.imgUrl = this.url
    } else if (this.name) {
      this.isInitial = true;
      this.imgUrl = this.name;
    } else {
      this.isDummy = true;
    }
  }

}
