import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { LinkPatientService } from '../services/link-patient.service';
import { REPO_TOKEN } from '@string';

@Component({
  selector: 'app-update-relation-modal',
  templateUrl: './update-relation-modal.component.html',
  styleUrls: ['./update-relation-modal.component.scss']
})
export class UpdateRelationModalComponent {
  @Input() patient;
  @Output() close = new EventEmitter();
  relationType: any;
  relations: any;
  selectedRelation: any;
  entity: any;

  constructor(private user:UserService, private linkPatient:LinkPatientService) {}
  
  ngOnInit(): void {

    this.user.getConfigurationInfo.subscribe(data => {
      this.entity = data.entityId;
    })
    console.log(this.patient);
    if(this.patient) {
      this.selectedRelation = this.patient['relationshiptype'];
    }
    this.getRelationList();
  }
  getRelationList(){
    this.user.getMasterConfig.subscribe(data => {
      this.relationType = data.list;
      this.relationType = this.relationType.filter(arr => {
          return arr.domain === 'RELATIONSHIP_TYPE'
      })
      this.relations = this.relationType[0].codes
    });
  }

  toLinkPatients(patientInfo){
    this.user.getUserId().subscribe(_id => {
      if (_id) {
        let linkData = {
          entityId: this.entity,
          personId: _id,
          profilesToLink:[
            {
              uhid: patientInfo.uhid || patientInfo['patientUhid'],
              facilityId: patientInfo.facilityid || patientInfo['facilityId'],
              patientName: patientInfo.personname || patientInfo['patientName'],
              patientLocalName: `${patientInfo['firstNameLocal']} ${patientInfo['lastNameLocal']}`,
              dob: patientInfo.dob || patientInfo['birthDate'],
              gender: patientInfo.gender,
              relationshipType: this.selectedRelation,
              mobile: patientInfo.mobile,
              imageUrl: patientInfo['imageurl'],
              email: patientInfo.email
            }
          ]
        }
        this.initLinkData(patientInfo, linkData);
      }
    }) 
  }

  private initLinkData(patientInfo, linkData) {
    this.linkPatient.toLinkPatients(linkData).subscribe(result => {
      if (result.status === 'SUCCESS') {
        console.log("***After linking pat***")
        this.user.updateAfterPatientModification(result['repoToken'], result['token']['token']);
        this.close.emit(true);
      }
    })
  }

  closeModal() {
    this.close.emit();
  }
}
