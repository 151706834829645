<div id="upcoming-appt-preview">
    <div class="head-row border-bottom">
        <div class="visit-type">
          <img class="inactive-icon type-image" 
            src="assets/icons/reminder.svg" />
          <span *ngIf="selectedAppointment.alertApptTitle">{{ selectedAppointment.alertApptTitle}}</span>
        </div>
        <div class="visit-time font-light-xsmall" >
          <span *ngIf="alertInfo['startDate']">{{alertInfo.startDate | moment : 'fromNow' : 'YYYY-MM-DD HH:mm' }}</span>
          <img class="type-image hide-close" (click)="deleteMYAlert(alertInfo)" src="./assets/icons/close.svg">
        </div>
      </div>
      <ng-container *ngIf="alertInfo.enableupcomingAppointments" >
        <div class="practitoner-detail" (click)="viewAppt()">
          <div class="flx">
            <div class="image">
              <app-practitioner-avatar
                [url]="(selectedAppointment['resourceId']|partitionerDetails: selectedAppointment['facilityId']:'resourceAvatarURL')"></app-practitioner-avatar>
            </div>
            <div class="practitoner-content">
              <div class="title bold" *ngIf="alertInfo && selectedAppointment['resourceId'] ">
                {{(selectedAppointment['resourceId']|partitionerDetails:selectedAppointment['facilityId']:'resourceName'| translate : translateType : 'name') || 'Doctor'}}
              </div>
              <div class="font-light-xsmall" *ngIf="alertInfo && selectedAppointment['resourceId'] ">
                {{selectedAppointment['resourceId']|partitionerDetails:selectedAppointment['facilityId']:'specialityId' | specialityDetails:selectedAppointment['facilityId']:'specialityName'| translate : translateType : 'name'}}
              </div>
            </div>
          </div>

          <div *ngIf="false">
            <ng-container [ngSwitch]="actionType">
                <ng-container *ngSwitchCase="'PAY'">
                    <button class="primary-button btn-small" (click)="$event.stopPropagation();handleActions('PAY',$event)"  *ngIf="!disablePayBeforeCheckinTime">
                        {{"APPTPREVIEW#ACTIONS#BUTTON#PAY"| translate}}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'CHECKIN'">
                    <button class="primary-button btn-small" (click)="$event.stopPropagation();handleActions('CHECKIN',$event)" >
                        {{(selectedAppointment.appointmentType == 'VIDEO'?"APPTPREVIEW#ACTIONS#BUTTON#CHECKINVC": "APPTPREVIEW#ACTIONS#BUTTON#CHECKIN")| translate}}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'PAYCHECKIN'">
                    <button class="primary-button btn-small"  (click)="$event.stopPropagation();handleActions('PAYCHECKIN',$event)">
                    {{"APPTPREVIEW#ACTIONS#BUTTON#PAYCHECKIN"| translate}}
                  </button>
                </ng-container>
                <ng-container *ngSwitchDefault></ng-container>
              </ng-container>
              <ng-container *ngIf="checkinStatus == 'CHECKEDIN'">
                <button noshadow class="ion-align-self-start" >
                 {{"APPTPREVIEW#ACTIONS#BUTTON#CHECKEDIN"| translate}}
                  
                </button>
              </ng-container>
              <ng-container *ngIf="checkinStatus == 'PAUSE' && canRejoin">
                <button noshadow class="ion-align-self-start" (click)="$event.stopPropagation();handleActions('CHECKIN', $event)">
                   {{"APPTPREVIEW#ACTIONS#BUTTON#REJOIN" | translate}}
                  </button>
              </ng-container>

          </div>

        </div>
        <div >
          <p class="vcontent"><span class="font-light-small">{{ 'For' }}</span> <span
              class="font-black-small bold">
              {{selectedAppointment['personName']}} </span></p>
        </div>
        <div class="visit-footer" >
          <div class="actions ">
            <div class="option font-black-small" *ngIf="selectedAppointment.appointmentDateTime">
              <img class="inactive-icon type-image" src="assets/icons/calendar_notify.svg" />
              <span>{{ selectedAppointment.appointmentDateTime | moment : 'DD MMM YYYY, hh:mm a'}}</span>
            </div>
          </div>
          <div class="actions-right font-black-small hide-close">
            <ng-container *ngIf="selectedAppointment.appointmentType == 'VIDEO'">
                <img class="inactive-icon type-image" src="assets/icons/video-icon.svg" />
                <span>{{'cal_modal_video_consultation'|translate:'LABELS'}}</span>
            </ng-container>
            <ng-container *ngIf="selectedAppointment.appointmentType != 'VIDEO'">
                <img class="inactive-icon type-image" src="assets/icons/pin.svg" />
                <span>{{selectedAppointment.facilityId | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}</span>
            </ng-container>

          </div>
        </div>
      </ng-container>
</div>

<!-- <app-custom-modal-container id="calendar-appointment-details-modal"  (complete)="closeApptDetail()">
  <app-upcoming-appointment-detail *ngIf="openApptDtl" [viewAppointment]="selectedAppointment" 
    (reschedule)="openApptView($event)" (expressReschedule)="openExpressView($event)"
    (uploadQuestionnarie)="openuploadQuestionnarie($event)"(uploadDocs)="openuploadDocs($event)"
    >
  </app-upcoming-appointment-detail>
</app-custom-modal-container> -->
