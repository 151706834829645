import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  public changeEmailForm: UntypedFormGroup;
  public precheckForm: UntypedFormGroup;
  public isPreCheck: boolean = false;
  public isVerifyEmail:boolean = false;
  public txId: string = '';
  public setEmail: string = '';

  @Output() close: EventEmitter<any>;
  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private user:UserService) { 
    this.close = new EventEmitter<any>();
    this.precheckForm = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    this.buildForm();
    this.getUserProfile();
  }
  public buildForm() {
    this.precheckForm = this.formBuilder.group({
      oldEmail:  new UntypedFormControl('', [Validators.required, Validators.email]),
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    this.changeEmailForm = this.formBuilder.group({
      otp: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    this.isPreCheck = true;
  }

  public sendOTP(){
    let email = this.precheckForm['value']['email'];
    this.changeEmailForm.patchValue({ email: email });
    this.setEmail = email;
    this.authService.updateEmail(email).subscribe( data => {
      this.txId = data.txId;
      if(data.status === 'SUCCESS'){
        this.isPreCheck = false;
        this.toggleScreen('VERIFYEMAIL')
      }
    })
  }

  public verifyOTP(){
    let email = this.changeEmailForm['value']['email'];
    let otp = this.changeEmailForm['value']['otp'];
    this.user.getUserId().subscribe(data => {
    this.authService.verifyUpdateEmail(this.txId,otp,email,data).subscribe( data => {
      console.log("data verify otp",data)
      this.txId = data.txId;
      if(data.status === 'SUCCESS'){
        this.precheckForm.patchValue({ 
          oldEmail: email,
          email: ''
        });
        this.toggleScreen('VERIFYEMAIL')
      }
      this.closeModal();
    })
  })
    // this.toggleScreen('VERIFYMOBILE')
  }
  public toggleScreen(val) {
    this.isPreCheck = (val === 'PRECHECK');
    this.isVerifyEmail = (val === 'VERIFYEMAIL');
  }

  public changeEmail(){
    this.toggleScreen('PRECHECK');
    this.isVerifyEmail = false;
  }

  private getUserProfile() {
    this.authService.getUserProfile().subscribe(data => {
      this.precheckForm.patchValue({ 
        oldEmail: data['email']
      });
    })
  }
  public closeModal() {
    this.close.emit();
  }

}
