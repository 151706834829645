import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import {AppointmentService} from '../services/appointment.service';
import { ModalService} from '../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { APPTACTION, visitType } from '@type';
import { TranslatePipe } from '../pipes/translate.pipe';
import { GoogleTagsService } from '../services/google-tags.service';
import { LinkPatientService } from '../services/link-patient.service';
import { HomeCareService } from '../services/home-care.service';
import { HC_PRACT_STARTED } from '@string';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { combineLatest } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
import { environment } from '@env/environment';

@Component({
  selector: 'app-upcoming-appointment',
  templateUrl: './upcoming-appointment.component.html',
  styleUrls: ['./upcoming-appointment.component.scss'],
  providers: [TranslatePipe]
})
export class UpcomingAppointmentComponent implements OnInit {

  public appointment_history: Array<any> = [];
  public menu=[];
  public appointmentDetail = false;
  public appointmentData: any;
  public translateType = "LOCALE";
  public hasPaymentToShow:boolean;
  public apptRefNumber:any;
  public callBack: string;
  public isApptView: boolean;
  public rescheduleData: Object = null;
  public orderdetails: Object = null;
  public isBookAppointment: boolean;
  public bookingAttributes: any;
  public prefillData: Object = null;
  public isRegisterPatient: boolean;
  public isUploadQuestionnarie: boolean;
  public isUploadDocs: boolean;
  public selectedAppt: Object=null;
  public isExpress: boolean;
  public homeServices: boolean;
  public homecareappts: any = [];
  public routeItem: any = null;
  public openRoute: boolean = false;
  public isCommonView: boolean = false;
  public toggleOptions = [
    {label: 'Upcoming', code: 'upcoming'},
    {label: 'In Progress', code: 'inpg'},
    {label: 'Past', code: 'past', showMissed: false, showCompleted: false,showCancelled :false},
  ]
  public tableHeaders = [];
  public selectedType: string = 'upcoming';
  public hasApptToShow: boolean = false;
  public selectedTab :'LIST' | "CAL" = "LIST";
  public showMenu: boolean = false;
  public cachedAppointments: any = {};
  calendarOptions: CalendarOptions;
  pastCompleted: any;
  pastMissed: any;
  pastCancelled : any;
  inProgress: any[];
  facilityDetailsPipe;
  selectedMonth = moment().format();
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public appointmentTypeFilters: any = 
  [ {'key':'Clinic_consultation','value':'CLINIC'},
    {'key':'Hospital_consultation','value':'HOSPITAL'},
    {'key':'Video_consultation','value':'VIDEO'}
  ];
  public selectedTypes: string[] = ['HOSPITAL', 'VIDEO', 'CLINIC']
  public initCheckin: APPTACTION;
  public statusMap = STATUS_LABEL;
  public years = [];
  public selectedYear;
  public showYearFilter: boolean = false
  public minDate: any;
  public datePickerValue;
  public filterUpdatedOn;
  private allEvents: any = [];

  constructor(private user: UserService, private appointmentService: AppointmentService, private modalService: ModalService, private activatedRoute: ActivatedRoute, private location: Location, private translatePipe: TranslatePipe, private gtmService: GoogleTagsService, private linkPatient: LinkPatientService, private homeCare: HomeCareService, private cdf: ChangeDetectorRef) {
    this.menu = menu_list;
    this.isApptView = false;
    this.isExpress = false;
    this.tableHeaders = [
      {label: 'Practitioner', code: 'practitioner', type: 'content', class:'a'},
      {label: 'Speciality', code: 'speciality', type: 'content', class:'b'},
      {label: 'Appointment_date_time', code: 'date', type: 'content', class:'c'},
      {label: 'Patient', code: 'patient', type: 'content', class:'d'},
      {label: 'Type', code: 'type', type: 'content', class:'e'},
      {label: '', code: 'action', type: 'action', class: 'f'},
    ]
    this.facilityDetailsPipe = new FacilityDetailsPipe(this.user);
    this.fetchEvents()
  }

  ngOnInit() {
    this.checkUrl();
    this.fetchAppointments();
    this.datePickerValue = moment().toDate();
  }

  private fetchEvents() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, bootstrapPlugin],
      initialView: 'dayGridMonth',
      slotMinTime: "00:00:00",
      slotMaxTime: "24:00:00",
      slotDuration: "00:30:00",
      headerToolbar: false,
      events: this.allEvents,
      themeSystem: 'bootstrap',
      aspectRatio:1.75,
      dayMaxEvents: 1, // for all non-TimeGrid views
      height: '100%',
      eventContent: (arg) => this.renderEventContent(arg),
      eventWillUnmount: (arg) => this.unrenderEvent(arg),
      moreLinkContent: (arg) => this.renderMoreContent(arg),
      eventClick: (arg) => this.eventClick(arg),
    }
  }

  private fetchAppointments() {
    switch (this.selectedType) {
      case 'upcoming':
        this.getUpcomingAppts()
        break;
      case 'inpg':
        break;
      case 'past':
        const month = moment(this.selectedYear).format('MM')
        const year = moment(this.selectedYear).format('YYYY')
        this.checkPastAppointments(month, year)
        break;
    }
  }

  private getUpcomingAppts() {
    this.appointmentService.getUpcomingAppointment(this.user.getPersonId()).subscribe(upcoming=> {
      this.appointment_history = upcoming.data.appointments;
      upcoming.data.appointments = upcoming.data.appointments.map(i => {
        i['_state'] = 'UPCOMING';
        i['_actions'] = this.appointmentService.processScheduleFlag(i, 'CARD')
        return i
      })
      this.pushApptToCalendarEvents(_.cloneDeep(upcoming.data.appointments), 'UPCOMING')
      if (this.hasPaymentToShow) {
        let appt = this.appointment_history.find(appt => {
          return appt.appointmentId == this.apptRefNumber;
        })
        if (appt) {
          appt['forceCallBack'] = this.callBack;
          this.callBack = null;
          this.getAppointmentDetail(appt, 'calendar-appointment-details-modal');
        }
      }
      if (this.hasApptToShow) {
        this.hasApptToShow = false
        let appt = this.appointment_history.find(appt => {
          return appt.appointmentId == this.apptRefNumber;
        })
        if (appt) {
          this.getAppointmentDetail(appt, 'calendar-appointment-details-modal');
        }
      }
    })
  }

  private checkPastAppointments(month = null, year = null) {
    let key = year + month;
    if (this.cachedAppointments && this.cachedAppointments[key]) {
      let past = _.cloneDeep(this.cachedAppointments[key]['past'])
      let cancelled = _.cloneDeep(this.cachedAppointments[key]['cancelled'])
      this.processAppointments(past, cancelled, month, year)
    } else {
      this.getPastAppointments(month, year)
    }
  }

  private getPastAppointments(month = null, year = null) {
    combineLatest([this.appointmentService.getCompletedAppointments(month, year),
      this.appointmentService.getCancelledAppointments(month, year)]).subscribe(([past, cancelled]) => { 
        past['appointments'] = past['appointments'].map(i => { i['_state'] = i['visitId'] ? 'COMP' : 'MISSED'; return i })
        cancelled['appointments'] = cancelled['appointments'].map(i => { i['_state'] = 'CANCELLED'; return i })
        if (month && year) {
          let key = year + month;
          this.cachedAppointments[key] = {
            past: past,
            cancelled: cancelled
          }
        }
        this.pushApptToCalendarEvents(_.cloneDeep([...past['appointments'], ...cancelled['appointments']]), 'PAST')
        this.processAppointments(past, cancelled, month, year)
      })
  }

  private pushApptToCalendarEvents(appointments, source) {
    if (source == 'UPCOMING') {
      this.allEvents = this.allEvents.filter(ev => ev.options.source !='UPCOMING')
    }
    appointments.forEach(event => {
      let then = moment(event['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
      let now = then.endOf('d');
      let difference = now.diff(then);
      let duration = moment.duration(difference);
      var durationStr = Math.floor(duration.asHours()) + ' hr ' + moment.utc(difference).format("mm") + ' mins';
      let eve = {
        title: event['personName'],
        date: moment(event['appointmentDateTime'], 'YYYY-MM-DD').format(),
        options: {
          ...event,
          sameDayEvent: false,
          duration: durationStr,
          startTime: then.format('hh:mm a'),
          endTime: now.format('hh:mm a'),
          source: source
        }
      }
      this.allEvents.push(eve)
    })
  }
  
  private processAppointments(past, cancelled, month, year) {
    this.pastCompleted = past['appointments'].filter(i => i['visitId']);
    this.pastMissed = past['appointments'].filter(i => !i['visitId'])
    this.pastCancelled = cancelled['appointments'];
    this.allEvents = this.allEvents.slice()
    this.calendarOptions.events = this.allEvents;
    if (this.selectedTab == 'LIST') {
      this.toggleOptions[2]['showCompleted'] = true;
      if (Array.isArray(this.pastMissed) && this.pastMissed.length > 0) {
        this.toggleOptions[2]['showMissed'] = true;
      } else {
        this.toggleOptions[2]['showMissed'] = false;
      }
      if (Array.isArray(this.pastCancelled) && this.pastCancelled.length > 0) {
        this.toggleOptions[2]['showCancelled'] = true;
      } else {
        this.toggleOptions[2]['showCancelled'] = false;
      }
    } else if (this.selectedTab == 'CAL') {
      console.log('calendarOptions', this.calendarOptions.events)
      let calendarApi = this.calendarComponent.getApi();
      let y = Number(year);
      let m = Number(month) - 1
      calendarApi.gotoDate(new Date(y, m, 1));
    }
    
  }

  getAppointmentDetail(data , id){
    if (data && data.appointmentType == 'HC' && data['homecareActivityDetails'] && data['homecareActivityDetails']['status'] && data['homecareActivityDetails']['status'] == HC_PRACT_STARTED) {
      this.openEnRoute(data)
    } else {
      this.initCheckin = null;
      this.appointmentDetail = true;
      this.appointmentData = data;
      this.openModal(id);
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  closeApptDetail() {
    this.hasPaymentToShow = false;
    this.appointmentDetail = false;
    this.appointmentData = null;
    this.callBack = null;
    this.apptRefNumber = null;
    this.fetchAppointments();
  }
  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      let payment = params['s'];
      if (payment==1) {
        let method = params['method'];
        let orderdetails = params['p'];
        switch (method) {
          case 'APTPAYMENT':
            this.hasPaymentToShow = true;
            let orderData = atob(orderdetails);
            let arr = orderData.split('&');
            let params = {};
            for (var i = 0; i < arr.length; i++) {
              var pair = arr[i].split('=');
              params[pair[0]] = decodeURIComponent(pair[1]);
            }
            this.apptRefNumber = params['apptRefNumber'];
            this.callBack = params['callback'];
            this.location.replaceState('/portal/calendar');
            break;
          case 'APPTBK':
            this.orderdetails = orderdetails;
            this.isApptView = true;
            this.location.replaceState('/portal/calendar')
            break;
        }
      }
      let ctx = params['ctx'];
      if (ctx) {
        this.isCommonView = true;
        let loginContext = this.user.getLoginContext();
        if (loginContext && loginContext.apptRefNo) {
          this.apptRefNumber = loginContext.apptRefNo
          this.hasApptToShow = true;
          if (!this.isCommonView) {
            this.user.removeLoginContext()
          }
        }
      }
    });
  }
  public openApptView(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.rescheduleData = event;
    this.isApptView = true;
  }

  public openExpressView(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.rescheduleData =event;
    this.isExpress = true;
  }
  public closeExpressClinic() {
    this.isExpress = false;
    this.rescheduleData = null;
    this.bookingAttributes = null
    this.fetchAppointments();
  }
  public closeApptView(event) {
    this.rescheduleData = null;
    this.orderdetails = null;
    this.isApptView = false;
    this.prefillData = null;
    if (event.bookingAttributes) {
      this.bookingAttributes = event.bookingAttributes;
      this.openRegisterPatient();
    } else {
      this.fetchAppointments();
      this.bookingAttributes = null
    }
  }

  public openBookAppointment() {
    this.isBookAppointment = true;
  }
  public closeBookAppointment(event) {
    console.log('event ', event)
    if(event == 'EXPRESS') {
      this.isExpress = true;
    } else if (event == 'HOME-CARE') {
        this.homeServices = true;
    }
      this.isBookAppointment = false;
      this.orderdetails = null;
      if (event.bookingAttributes) {
        this.bookingAttributes = event.bookingAttributes;
        this.openRegisterPatient();
      }
      else {
        this.fetchAppointments();
        this.bookingAttributes = null
      }
  }

  public closehomeServices(event){
    this.homeServices = false
  }
  public openRegisterPatient() {
    this.isRegisterPatient = true;
  }
  public closeRegisterPatient() {
    this.isRegisterPatient = false;
    if (this.bookingAttributes) {
      this.openBookAppointment()
    }
  }
  public openApptType(type) {
    switch (type) {
      case 'direct_consultation':
      case 'video_consultation':
        let t: visitType = type == 'direct_consultation' ? 'HOSPITAL' : 'VIDEO';
        this.prefillData = { type: t };
        this.isApptView = true;
          let obj = {
            'event': 'clinic_appointment_button',
            'funnel_name': 'Clinic Appointments',
          };
          if (t == 'VIDEO') {
            obj['event'] = 'online_consultation_button';
            obj['funnel_name'] = 'Online Consultation';
          }
          this.gtmService.pushGTMEvent(obj['funnel_name'], obj['event']);
        break;
    }
  }
  public openuploadQuestionnarie(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.openModal('preconsult-questionnaire-modal');
    this.selectedAppt = event;
    this.isUploadQuestionnarie = true;
  }

  public closeuploadQuestionnarie() {
    this.closeModal('preconsult-questionnaire-modal');
    this.isUploadQuestionnarie = false;
    this.selectedAppt = null;
  }
  public openuploadDocs(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.openModal('upload-document-modal');
    this.selectedAppt = event;
    this.isUploadDocs = true;
  }
  public closeuploadDocs() {
    this.closeModal('upload-document-modal');
    this.isUploadDocs = false;
    this.selectedAppt = null;
  }
  public openQuestionnarie(item) {
    this.openuploadQuestionnarie(item);
  }
  public openUploadDoc(item) {
    this.openuploadDocs(item);
  }
  public openEnRoute(item) {
    this.routeItem = item;
    this.openRoute = true;
  }
  public closeMap() {
    this.routeItem = null;
    this.openRoute = false;
  }

  public selectType(type) {
    this.selectedType = type['code']
    this.openMenu(null)
    if (type == 'past') {
      this.selectedYear=moment().format()
    }
    this.fetchAppointments();
  }

  renderEventContent(arg) {
    console.log('arggg', arg);
    const appt = _.cloneDeep(arg.event.extendedProps.options);
    console.log(appt);
    let icon = null;
    let bgColor = null;
    
    if(appt['consultationStatus']) {
      switch (appt['consultationStatus']) {
        case 'FINISH':
        case 'AUTOFINISH':
          icon = 'assets/icons/bookappt/tick_white.svg';
          bgColor = 'bg-success';
          break;
        case 'PAUSE':
        case 'STARTED':
          icon = 'assets/icons/Appointments/error_copy_3.svg';
          bgColor = 'bg-warning';
          break;
        case 'WAITING':
        case 'MISSED':
          icon = 'assets/icons/Appointments/Missed_Copy.svg';
          bgColor = 'bg-danger';
          break;
        case 'CANCEL':
          icon = 'assets/icons/close_white.svg';
          bgColor = 'bg-danger';
          break;
          default:
            if(['COMP','MISSED'].includes(appt['_state'])) {
              icon = 'assets/icons/close_white.svg';
              bgColor = 'bg-danger';
            }
            break;
      }
    }
    let html = `<div class="md-hidden appointment-dot cursor-pointer"></div>
    <div class='appointment-item sm-hidden cursor-pointer ${appt['_state'] == 'CANCELLED' ? 'blurred' :''}'> `
    if(appt['appointmentType'] == 'VIDEO')            
    html +=`<div class="action-icon video-icon">
    <img class="type-image" src="assets/icons/Appointments/video.svg" alt=""></div>`;
    if(appt['appointmentType'] != 'VIDEO' && appt['appointmentType'] != 'HC')            
    html +=`<div class="action-icon clinic-icon"> <img
    class="type-image" src="assets/icons/Appointments/clinic.svg" alt=""></div>`;
    if(appt['appointmentType'] == 'HC')            
    html +=`<div class="action-icon hc-icon"> <img
    class="type-image" src="assets/icons/Appointments/HC.svg" alt=""></div>`;
    html +=`<div class="appointment-info">
    <p class="pract-name">${appt['practitionerName'] || 'DOCTOR'}</p>
    <p class="facility-name">${this.translatePipe.transform(this.facilityDetailsPipe.transform(appt['facilityId'],'facilityName'),  'LOCALE', 'name')}</p>
  </div>`;

    if (icon) {
      html += `<div class="appointment-icon ${bgColor}">
        <img src="${icon}" class="appt-icon type-image" alt="appt-icon">
        </div>
        </div>`;
    } else {
      html += `</div>`
    }

    return {html}
  }

  unrenderEvent(arg) {
  }

  renderMoreContent(arg) {
    return { html: `<div class="more-content sm-hidden">${arg.text}</div>` }
  }

  public chooseDate(direction: 'PREV' | 'NEXT') {
    if (direction == 'PREV') {
      this.selectedMonth = moment(this.selectedMonth).subtract(1, 'month').format();
    } else {
      this.selectedMonth = moment(this.selectedMonth).add(1, 'month').format();
    }
    this.renderCalendarEvents()
    this.datePickerValue = moment(this.selectedMonth).toDate();
  }

  public gotoDate() {
    this.selectedMonth = moment().startOf('month').format();
    this.renderCalendarEvents()
  }

  public handleActions(appt, event, data) {
    this.initCheckin = event;
    this.appointmentDetail = true;
    this.appointmentData = appt;
    this.openModal('calendar-appointment-details-modal');
  }

  openMenu(type) {
    this.showMenu = type == 'APPTTYPE'?!this.showMenu:false;
    this.showYearFilter = type == 'YEARFILTER' ? !this.showYearFilter : false;
  }
  public selectYear() {
    console.log(this.selectedYear)
    this.fetchAppointments()
  }

  onChangeFilter(event) {
    console.log(event.target.value);
    if (event.target.checked) {
      this.selectedTypes.push(event.target.value);
    } else {
      const index = this.selectedTypes.findIndex(st => st === event.target.value);
      this.selectedTypes.splice(index, 1);
    }
    this.filterUpdatedOn = moment().valueOf()
  }
  private eventClick(arg) {
    console.log(arg.event.extendedProps.options);
    if (arg.event && arg.event.extendedProps && arg.event.extendedProps.options) {
      this.getAppointmentDetail(arg.event.extendedProps.options, 'calendar-appointment-details-modal')
    }
  }
  public onDateChange() {
    this.selectedMonth = moment(this.datePickerValue).format();
    this.renderCalendarEvents()
  }

  public updateSelectedTab(type: 'LIST' | 'CAL') {
    this.renderCalendarEvents()
    this.selectedTab = type;
  }
  private renderCalendarEvents() {
    const year = moment(this.selectedMonth).format('YYYY');
    const month = moment(this.selectedMonth).format('MM');
    this.checkPastAppointments(month, year);
  }
}

export const menu_list = [
  {
    'icon': 'assets/icons/navigation/calendar.svg',
    'description': 'consult_directly',
    'action': 'direct_consultation',
    'display':'FT_APP_BOOK_DIRECT_APPT'
  },
  {
    'icon': 'assets/icons/calendar/app_type_white.svg',
    'description': 'consult_online',
    'action': 'video_consultation',
    'display':'FT_APP_BOOK_VC_APPT'
  },

]

const STATUS_LABEL = {
  AUTOFINISH:"Completed",
  FINISH:"Completed",
  PAUSE:"Checked_In",
  STARTED:"Checked_In",
  WAITING:"Waiting",
  MISSED:"Missed",
  CANCEL:"Cancelled",
  PAID:"Paid",
  PAH:"Payment_pending",
}
