import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TOAST_ERROR } from '@string';
import { LoaderService } from '../services/loader.service';
import { ToastServiceService } from '../services/toast-service.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  @Input() file;
  @Output() close = new EventEmitter();
  public loading: boolean = false;
  public format: string;
  private viewerType: 'google' | 'office' = 'google';
  public docUrl: SafeResourceUrl;
  constructor(private toast: ToastServiceService, private loader: LoaderService, private domSanitizer: DomSanitizer, private cdf: ChangeDetectorRef, private http: HttpClient) {
  }

  ngOnInit() {
    this.loader.changeState(true);
    this.loading = true;
    this.mapFileType()
  }

  closeModel() {
    this.close.emit();
  }
  public error(event: any) {
    this.toast.showToast(null, 'Error in rendering PDF', TOAST_ERROR);
  }

  public stopLoader(ev) {
    console.log("ev", ev)
    this.loader.changeState(false);
    setTimeout(_ => {
      this.cdf.detectChanges();
    }, 500)
  }

  private mapFileType() {
    switch (this.file['mimeType']) {
      case 'application/pdf':
        this.format = 'PDF';
        this.loader.changeState(false);
        break;
      case 'image/jpeg':
      case 'image/gif':
        this.format = 'IMAGE';
        this.loader.changeState(false);
        break;
      default:
        switch (this.viewerType) {
          case 'google':
            `https://docs.google.com/gview?url=http://ieee802.org/secmail/docIZSEwEqHFr.doc&embedded=true`
            this.docUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${this.file['filePublicLink']}&embedded=true`);
            break;
          case 'office':
            this.docUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${this.file['filePublicLink']}`);
            break;
        }
        this.format = 'DOC';
        this.loader.changeState(false);
        break;
    }
    this.loading = false;
  }
}
