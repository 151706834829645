import {Directive, ElementRef, Output, EventEmitter, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {
    }
    @Input() triggerElementId: string; 
    @Output()
    public clickOutside = new EventEmitter<MouseEvent>();

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        let clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if(this.triggerElementId) {
            let parentElement = document.getElementById(this.triggerElementId);
            clickedInside = !clickedInside && parentElement.contains(targetElement);
        }
        if (!clickedInside) {
            this.clickOutside.emit(event);
        }
    }
}