import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrVisitBillDetailsComponent } from './hr-visit-bill-details.component';
import { PipesModule } from '../pipes/pipes.module';
@NgModule({
  declarations: [HrVisitBillDetailsComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    HrVisitBillDetailsComponent
  ],
  
})
export class HrVisitBillDetailsModule { }