  <div class="web-footer">
    <div class="cursor-pointer copyrights">
      <p>
        <label>{{'login_footer_lbl_copyright' |translate}} {{'login_footer_lbl_msg_copyright' |translate}}
        </label>
        </p>
    </div>
    <div class="cursor-pointer policies">
      <p class="align-center">
        <label (click)="openLink('pp')" class="border-right" style="margin-right: 5px;">{{'msg_priv_policy'|translate}}</label>
        <!-- <label (click)="openLink('cc')">{{'msg_contact_us'|translate}}</label> -->
        <label (click)="openModal()">{{'msg_contact_us'|translate}}</label>

      </p>

    </div>
    <div class="cursor-pointer socials" >
      <p class="align-center">
      <label>{{'login_footer_lbl_follow_us'|translate}}</label>
      <img alt="fb" class="type-image" src="assets/icons/Home/facebook copy 2.svg" (click)="openSocialMedia('fb')" />
      <img alt="tw" class="type-image" src="assets/icons/Home/x twitter.svg" (click)="openSocialMedia('twitter')" />
      <img alt="ig" class="type-image" src="assets/icons/Home/instagram.svg" (click)="openSocialMedia('insta')" />
      <img alt="yt" class="type-image" src="assets/icons/Home/youtube copy.svg"  (click)="openSocialMedia('yt')"/>
      <img alt="in" class="type-image" src="assets/icons/Home/linkedin copy.svg"  (click)="openSocialMedia('linkedin')"/></p>

    </div>
    <div class="cursor-pointer downloads" *ngIf="!hideAppLink">
      <p class="align-center">
      <label>{{'login_footer_lbl_dwd_nahdi_app'|translate}}</label>
       <img alt="android" class="type-image app-image" src="assets/icons/Home/google-play-badge.svg" (click)="download('android')" />
      <img alt="ios" class="type-image app-image" src="assets/icons/Home/apple_play.png" (click)="download('apple')" />
    </p>
    </div>
  </div>

 <app-contact-us-modal *ngIf="showContactusModal" [contactUs]="true" (close)="closeActions()">
</app-contact-us-modal>
