import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrReportFormformatComponent } from './hr-report-formformat.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormpreviewModule } from '../formpreview/formpreview.module';
import { DirectiveModule } from '../directive/directive.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';



@NgModule({
  declarations: [HrReportFormformatComponent],
  imports: [
    CommonModule, PipesModule, FormpreviewModule, DirectiveModule, NgxQRCodeModule
  ],
  exports: [
    HrReportFormformatComponent
  ]
})
export class HrReportFormformatModule { }
