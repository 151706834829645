<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
  <div class="component" [ngClass]="{'coupon-list': type == 'VIEWONLY'}">
    <div class="header border-bottom">
      <div class="header-row">
        <div (click)="goBack()" class="back-btn float-left closeicon pointer">
          <img src="assets/icons/arrow.svg">
        </div>
        <div class="font-primary-subtitle bold" *ngIf="type == 'COUPON' || type == 'COUPONNEW'">{{"COUPONS#MODAL#LABEL#MYCOUPONS" | translate}}</div>
        <div class="font-primary-subtitle bold" *ngIf="type == 'POINTS'">{{"POINTS#MODAL#TITLE#APPLY" | translate}}</div>
      </div>
      <div (click)="goBack()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content" [ngClass]="{'coupon-content': type == 'VIEWONLY'}" style="overflow-y: auto; overflow-x: hidden;">
      <ng-container *ngIf="type == 'COUPON' || type == 'COUPONNEW'" [ngTemplateOutlet]="COUPONCONTENT"></ng-container>
      <ng-container *ngIf="type == 'POINTS'" [ngTemplateOutlet]="POINTSCONTENT"></ng-container>
    </div>
    <div class="modal-footer" *ngIf="type != 'VIEWONLY'">
      <ng-container *ngIf="type == 'COUPON' || type == 'COUPONNEW'" [ngTemplateOutlet]="COUPONFOOTER"></ng-container>
      <ng-container *ngIf="type == 'POINTS'" [ngTemplateOutlet]="POINTSFOOTER"></ng-container>
    </div>
  </div>
</div>

<ng-template #COUPONCONTENT>
  <div class="list-container">
    <ng-container *ngIf="!isFetchingCoupon && couponDetails && couponDetails.couponList.length>0">
      <div class="coupon-container" *ngFor="let coupon of couponDetails.couponList" (click)="selectCoupon(coupon)">
        <div class="coupon-card"
          [ngClass]="{'selected-coupon': coupon.selected, 'disabled-coupon': (couponDetails.couponBalPatientPayable == 0 && !coupon.selected)}">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="display-row">
            <div class="display-container">
              <div class="display-amt">
                <app-display-currency [amountSize]="'large'" [theme]="coupon.selected? 'app': 'black'"
                  [displayDirection]="'row'" [amount]="coupon.cashDiscount" slot="end"
                  [suffix]="'OFF' | translate"></app-display-currency>
              </div>
              <div class="coupon-name">
                <label>
                  <p>{{coupon['desc'] | translate : 'LOCALE' : 'name'}}</p>
                </label>
              </div>
              <div class="coupon-id">
                <label>
                  <p>{{coupon['couponSequenceNo']}}</p>
                </label>
              </div>
            </div>
            <div class="checked-btn">
              <!-- <ion-icon src="./assets/icon/coupon_check.svg" class="no-flip"></ion-icon> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="no-data">
    <ng-container *ngIf="!isFetchingCoupon && (!couponDetails || !couponDetails.couponList || couponDetails.couponList.length==0)">
      <app-nodata [source]="'COUPONS'" [pointsView]="pointsAvailable" [availablePoints]="availableNuhdeekPoints"></app-nodata>
    </ng-container>
  </div>
</ng-template>
<ng-template #COUPONFOOTER>
  <div class="total-bill">
    <p>{{"COUPON#LABEL#NO#TOTAL#BILL#AMOUNT" | translate}}</p>
    <app-display-currency [amountSize]="'small'" [displayDirection]="'row'"
      [amount]="couponDetails.couponBalPatientPayable"
      [actualAmount]="couponDetails.couponBalPatientPayable != patientPayable? patientPayable: null" slot="end">
    </app-display-currency>
  </div>
  <div class="btn-container">
    <ng-container>
      <button class="primary-button"
        [disabled]="isFetchingCoupon || !couponDetails || !couponDetails.couponList || couponDetails.couponList.length==0"
        (click)="apply()">
        {{'btn_apply' | translate}}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #POINTSCONTENT>
  <div class="points-grid-row">
    <div class="grid-column">
      <div class="points-card">
        <div class="available-points">
          <div class="label">
            <img src="./assets/icons/nudheek-icon.svg" class="nudheek-icon">
            {{'POINTS#CARD#TITLE#AVAILABLE' | translate}}
          </div>
          <div class="points">
            <p *ngIf="!isFetchingPoints"><strong>{{pointsInfo?pointsInfo.availablePoints : ''}}</strong></p>
            <app-skeleton-loader *ngIf="isFetchingPoints" type="text" txtWidth="85%" txtHeight="0.75rem"></app-skeleton-loader>
          </div>
        </div>
        <div class="points-to-redeem">
          <div class="label">
            {{'POINTS#CARD#TITLE#SELECT' | translate}}
          </div>
          <app-custom-input-group class="incrementnumber-input" slot="end" type="incrementnumber" [(ngModel)]="pointsAdded" *ngIf="pointsInfo && !isFetchingPoints" [incrementMultiple]="pointsInfo.incrementMultiple"
            (ngModelChange)="updatePointsDerivatives()" [minIncrement]="pointsInfo.minIncrement"
            [maxIncrement]="pointsInfo.maxIncrement"></app-custom-input-group>
        </div>
      </div>
      <div class="guidelines-card" *ngIf="pointsGuideline">
        <div class="font-default-small" [innerHTML]="(pointsGuideline|translate:'LOCALE':'packageContent') | safe:'html'"></div>
      </div>
    </div>
    <div class="grid-column">
      <div class="charges-card" >
        <div class="charge-item" *ngIf="!parsedChargeInfo">
          <div class="item-label font-light-small">{{"POINTS#LABEL#AMOUNT#PAYABLE" | translate}}</div>
          <div class="item-amount font-primary-small"><app-display-currency [amountSize]="'small'" [displayDirection]="'row'" [amount]="patientPayable" slot="end">
          </app-display-currency></div>
        </div>
        <div class="charge-item" *ngIf="parsedChargeInfo && parsedChargeInfo.baseServiceAmount">
          <div class="item-label font-light-small">{{"POINTS#LABEL#AMOUNT#PAYABLE" | translate}}</div>
          <div class="item-amount font-primary-small"><app-display-currency [amountSize]="'small'" [displayDirection]="'row'" [amount]="parsedChargeInfo.baseServiceAmount" slot="end">
          </app-display-currency></div>
        </div>
        <div class="charge-item">
          <div class="item-label font-light-small">{{"POINTS#LABEL#AMOUNT#POINTS" | translate}}</div>
          <div class="item-amount font-primary-small"><app-display-currency [amountSize]="'small'" [displayDirection]="'row'"
            [amount]="pointsDerivative&&pointsDerivative.amount ? pointsDerivative.amount : '0'" slot="end" [prefix]="'-'">
          </app-display-currency></div>
        </div>
        <div class="charge-item" *ngIf="(pointsDerivative && pointsDerivative.vatAmount) || (parsedChargeInfo && parsedChargeInfo.vatDisplayAmount)">
          <div class="item-label font-light-small">{{"POINTS#LABEL#TAX#VATAMT" | translate}}</div>
          <div class="item-amount font-primary-small"><app-display-currency [amountSize]="'small'" [displayDirection]="'row'"
            [amount]="(pointsDerivative && pointsDerivative.vatAmount) ? pointsDerivative.vatAmount: 0 " slot="end">
          </app-display-currency></div>
        </div>
        <div class="charge-item">
          <div class="item-label bold">{{"POINTS#LABEL#AMOUNT#BALANCE" | translate}}</div>
          <div class="item-amount bold"><app-display-currency [amountSize]="'small'" [displayDirection]="'row'" [amount]="pointsDerivative&&pointsDerivative.balance ? pointsDerivative.balance : '0'" slot="end" [theme]="'black'"> </app-display-currency></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #POINTSFOOTER>
  <div class="btn-container">
    <ng-container>
      <button class="primary-button" [disabled]="pointsDerivative && pointsDerivative.valid === false" (click)="applyPoints()">
        {{"COUPON#LABEL#APPLY#AND#CONTINUE" | translate}}
      </button>
    </ng-container>
  </div>
</ng-template>