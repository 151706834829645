<div id="upload-questionnarie" class="right-nav">
  <div (click)="closeModal()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">{{'feedback_form' | translate}}</div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content">
      <app-clinicalform [params]="params" *ngIf="params['concept']['noteContent']['topics'] && !isComplete"
        (response)="saveResponse($event)" [showSaveDraftBtn]="true" #clinicalForm [showTopicTitle]="false"></app-clinicalform>

        <div *ngIf="isComplete" class="c-f-a-m-form-preview">
          <div class="topic-name">Preview</div>
          <app-formpreview [topics]="params['concept']['noteContent']['topics']"></app-formpreview>
        </div>
    </div>
  </div>
</div>