import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { HeaderModule } from '../header/header.module';
import {UpcomingAppointmentModule} from '../upcoming-appointment/upcoming-appointment.module';
import {UpcomingAppointmentDetailModule} from '../upcoming-appointment-detail/upcoming-appointment-detail.module';
import { PipesModule } from '../pipes/pipes.module';
import { FooterModule } from '../footer/footer.module';
import { DirectiveModule } from '../directive/directive.module';
@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CommonModule,
    HeaderModule,
    UpcomingAppointmentModule, 
    UpcomingAppointmentDetailModule,
    PipesModule,
    FooterModule,
    DirectiveModule
  ],
  exports:[CalendarComponent]
})
export class CalendarModule { }