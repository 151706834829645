import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { VisitAbstractClass } from '../visit-standalone.abstract';

@Component({
  selector: 'app-step-welcome',
  templateUrl: './step-welcome.component.html',
  styleUrls: ['./step-welcome.component.scss', '../visit-standalone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepWelcomeComponent extends VisitAbstractClass implements OnInit {
  @Input() practitioners = [];
  @Output() selectPract: EventEmitter<any> = new EventEmitter();
  constructor() {
    super();
   }

  ngOnInit(): void {
  }

  choosePract(p) {
    if(this.isWorking(p))
      this.selectPract.emit(p);
  }

}
