import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkRecordsComponent } from './link-records.component';
import { PipesModule } from '../pipes/pipes.module';
import { CustomModalModule } from '../custom-modal-container/custom-modal-container.module';
import { RegisterPatientModule } from '../register-patient/register-patient.module';
import { ListItemModule } from '../list-item/list-item.module';
import { DirectiveModule } from '../directive/directive.module';
import { LinkOtherMobileModule } from '../link-other-mobile/link-other-mobile.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';

@NgModule({
  declarations: [LinkRecordsComponent],
  imports: [
    CommonModule, PipesModule, CustomModalModule, RegisterPatientModule, ListItemModule, DirectiveModule,LinkOtherMobileModule, RegisterNewPatientModule
  ],
  exports: [
    LinkRecordsComponent
  ]
})
export class LinkRecordsModule { }
