<div class="main">
  <!-- <div class="card1 row cursor-pointer">
    <div class="image">
      <app-person-avatar [url]=""> </app-person-avatar>
    </div>
    <div class="card-content">
      <div class="title" >{{personDetails.personName}}</div>
      <div class="subtitle font-light-default">{{viewProfileForm.email}}</div>
      <div class="subtitle font-light-default">{{viewProfileForm.phone}}</div>
    </div>
  </div> -->
  <span class="personal-information">{{'Personal_Information' | translate }}</span>

  <div class="list" *ngIf="extInterface">
    <div class="user-list-item">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/photo_round.svg"/>
      </div>
      <span class="first-and-last-name">{{viewProfileForm.firstname}} {{viewProfileForm.lastname}}</span>
    </div>
    <div class="list-item" *ngIf="viewProfileForm.dob">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/birthday-cake.svg"/>
      </div>
      <span>{{viewProfileForm.dob }}</span>
      <!-- <span>{{viewProfileForm.dob | moment : 'DD MMM YYYY': 'YYYY-MM-DD'}}</span> -->
    </div>
    <div class="list-item">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/male-gender.svg" *ngIf="viewProfileForm.gender =='male'" />
      <img class="type-image" src="assets/icons/manage-account/female.svg" *ngIf="viewProfileForm.gender =='female'" />
      </div>
      <span>{{viewProfileForm.gender | translate}}</span>
    </div>
    <div class="list-item">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/world.svg"/>
      </div>
      <span>{{viewProfileForm.language | translate : 'LANG'}}</span>
    </div>
    <div class="list-item">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/change_mobile_number.svg"/>
      </div>
      <span>{{viewProfileForm.phone | translate }}</span>
    </div>
    <div class="list-item">
      <div class="image">
      <img class="type-image" src="assets/icons/manage-account/mail_outline.svg"/>
      </div>
      <span>{{viewProfileForm.email | translate }}</span>
    </div>
   
  </div>
  <div class="list" *ngIf="!extInterface">
    <div class="list-item">
      <img src="assets/icons/profile/user_clr.svg"/>
      <span>{{viewProfileForm.personName}}</span>
    </div>
  </div>
</div>