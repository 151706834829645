import { Pipe, PipeTransform } from '@angular/core';
import { LANG_SELECTED } from "../app.const";
import { UserService } from '../services/user.service';

@Pipe({
  name: 'localefilter'
})
export class LocalefilterPipe implements PipeTransform {

  constructor(private user: UserService) {}

  transform(value: Array<any>, filter: string, property: string, key: string, backupKey: string=null): any {
    if (value.length == 0) {
      return [];
    }
    else {
      let retval = value.filter(val => {
        if (val[property]) {
          let v= val[property].find(obj => {
            return obj.locale === this.user.currentLanguage;
          })
          if (v) {
            return v[key].toLowerCase().includes(filter.toLowerCase());
          } else {
            return val[property][0][key].toLowerCase().includes(filter.toLowerCase());
          }
        } else if (val[backupKey]) {
          return val[backupKey].toLowerCase().includes(filter.toLowerCase())
        } else {
          return false
        }
      });
      return retval;
    }
  }

}
