import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentRecentComponent } from './appointment-recent.component';

@NgModule({
  declarations: [AppointmentRecentComponent],
  imports: [
    CommonModule
  ],
  
  exports: [
    AppointmentRecentComponent
  ]
})
export class AppointmentRecentModule { }
