import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthRecordsComponent } from './health-records.component';
import { HeaderModule } from '../header/header.module';
import { HrPatientSelectModule } from '../hr-patient-select/hr-patient-select.module';
import { HrVisitHistoryModule } from '../hr-visit-history/hr-visit-history.module';
import { HrVisitDetailsModule } from '../hr-visit-details/hr-visit-details.module';
import { HrReportClobformatModule } from '../hr-report-clobformat/hr-report-clobformat.module';
import { HrReportFormformatModule } from '../hr-report-formformat/hr-report-formformat.module';
import { HrReportJsonformatModule } from '../hr-report-jsonformat/hr-report-jsonformat.module';
import { HrDocumentUploadModule } from '../hr-document-upload/hr-document-upload.module';
import { HrFeedbackFormModule } from '../hr-feedback-form/hr-feedback-form.module';
import { PipesModule } from '../pipes/pipes.module';
import { HrReportPdfformatModule } from '../hr-report-pdfformat/hr-report-pdfformat.module';
import { FooterModule } from '../footer/footer.module';
import { DirectiveModule } from '../directive/directive.module';
import { ImagePreviewModule } from '../image-preview/image-preview.module';
import { DocumentPreviewModule } from '../document-preview/document-preview.module';
import { HrVisitSummaryModule } from '../hr-visit-summary/hr-visit-summary.module';
import { HrVisitReportModule } from '../hr-visit-report/hr-visit-report.module';
import { HrVisitPrescriptionModule } from '../hr-visit-prescription/hr-visit-prescription.module';



@NgModule({
  declarations: [HealthRecordsComponent],
  imports: [
    CommonModule, HeaderModule, HrPatientSelectModule, HrVisitHistoryModule, HrVisitDetailsModule, HrReportClobformatModule, HrReportFormformatModule, HrReportJsonformatModule, HrDocumentUploadModule, HrFeedbackFormModule, PipesModule, HrReportPdfformatModule, FooterModule, DirectiveModule, ImagePreviewModule, DocumentPreviewModule,
    HrVisitSummaryModule, HrVisitReportModule, HrVisitPrescriptionModule
  ],
  exports: [
    HealthRecordsComponent
  ]
})
export class HealthRecordsModule { }
