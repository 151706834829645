<div id="view-report" class="right-nav showModal">
  <div (click)="closeModel()" class="empty"></div>
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">
        <span>
          {{title}}
        </span>
      </div>
      <div (click)="closeModel()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="options">
      <div class="practitioner font-primary-subtitle bold">
      </div>
      <div class="actions">
        <div class="button-style cursor-pointer" [appRemoveFeatureOff]="'FT_APP_HR_DOWNLOADDOCS'"
          (click)="startDownload()">
          <img src="assets/icons/download.svg" />
        </div>
        <div class="button-style cursor-pointer" *ngIf="false" (click)="startDownload()">
          <img class="type-image" src="assets/icons/share.svg" />
        </div>
      </div>
    </div>
    <div class="container-content">
      <img class="full-width" [src]="url" style="display: block;"/>
    </div>
  </div>
</div>