import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ComponentFactoryResolver, ViewChild, HostListener, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SectionDirective } from './section.directive';
import { SegmentComponent } from './type/segment/segment.component';
import { CheckComponent } from './type/check/check.component';
import { RadioComponent } from './type/radio/radio.component';
import { NumericintegerComponent } from './type/numericinteger/numericinteger.component';
import { TextComponent } from './type/text/text.component';
import { CaptionComponent } from './type/caption/caption.component';
import { LongtextComponent } from './type/longtext/longtext.component';
import { SelectComponent } from './type/select/select.component';
import { ToggleComponent } from './type/toggle/toggle.component';
import { NumericdecimalrangeComponent } from './type/numericdecimalrange/numericdecimalrange.component';
import { RatingComponent } from './type/rating/rating.component';
import { DateComponent } from './type/date/date.component';
import { DatetimesegmentComponent } from './type/datetimesegment/datetimesegment.component';
import { DateandtimeComponent } from './type/dateandtime/dateandtime.component';
import { TimeComponent } from './type/time/time.component';
import { TimeperiodComponent } from './type/timeperiod/timeperiod.component';
import { FormulaComponent } from './type/formula/formula.component';
import { MasterlinkedComponent } from './type/masterlinked/masterlinked.component';
import { FileUploadComponent } from './type/file-upload/file-upload.component';
import { CompositeFieldComponent } from './type/composite-field/composite-field.component';
import { DirectiveLinkedComponent } from './type/directive-linked/directive-linked.component';
import { RichTextEditor } from './type/richTextEditor/richTextEditor.component';
import { DrawField } from './type/draw/draw.component';
import { SectionPreviewComponent } from '../section-preview/section-preview.component';
import { EventsService } from '../services/events.service';
import { UserService } from '../services/user.service';
import { ClinicalFormUtils } from '../clinicalform/clinicalform.utils';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss', '../../assets/styles/catagoryinputgroup.scss'],
  providers:[EventsService]
})
export class SectionComponent implements OnInit, OnChanges {

  @Input() public section: any;
  @Input() public sections: any;
  @Input() public topic: any;
  @Input() public prescriptionRules: any;
  @Input() public frequencyApplicable: any;
  @Input() public routeApplicable: any;
  @Input() public patient: any;
  @Input() public encounter: any;
  @Input() public topics:any;
  @Input() public appointmentsList:any;
  @Input() public formViewType: any;
  public sectionIndex: number;
  @Input() public sectionIdentifier: number;
  @Output() public openConsultationNote: EventEmitter<null>;
  @ViewChild(SectionDirective, { static: true }) appSection: SectionDirective;
  @ViewChild('sectionReference', { static: true }) sectionReference: ElementRef;
  private type: any;
  public componentRef;
  public canCheckFocus: boolean;
  public sectionIsPending: boolean = true;
  public sectionIsEditable: boolean = true;
  public changedTimestamp: any;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key) {
      if (event.key && event.key.toUpperCase() == 'ENTER') {
        this.scrollToNext();
      }
    }
  }

  private scrollToNext() {
    let taskSettings = this.user.getTaskViewSettings()
    let timeout = 10
    let canMinimize = true
    if (taskSettings) {
      timeout = taskSettings.scrollTime
      canMinimize = taskSettings.minimizeFulfilledSection
    }
    setTimeout(_ => {
      let nextSectionindex = this.getNextVisibleSection(this.sectionIndex)
      let domaincode = this.sections[nextSectionindex] ? this.sections[nextSectionindex].domainCode : null;
      // if (!domaincode) {
        this.section._sectionIsPending = this.section.selected == null || this.section.selected == '' || this.section.selected == undefined;
      if (canMinimize) this.section._sectionIsEditable = this.section._sectionIsPending;
        this.changedTimestamp = this.user.getMoment().valueOf()
        this.changeDetectorRef.detectChanges();
        // return;
      // }
      let nextSectionId = `#section-ref-${this.topic.id}-${domaincode}`
      let elmnt = document.querySelectorAll(nextSectionId); // Don't remove 1 since need some margin
      if (elmnt && elmnt.length > 0 && this.sectionReference.nativeElement.contains(document['_activeElement'])) {
        this.user.sectionFocusListener.next({ action: 'scrollToNext', to: nextSectionId, topicId: this.topic.id, sectionIndex: this.sectionIndex, element: elmnt[0] })
      }
    }, timeout)
  }
  private scrollToCurrent() {
    // return;
    let nextSectionindex = this.sectionIndex
    let domaincode = this.sections[nextSectionindex] ? this.sections[nextSectionindex].domainCode : null;
    if (!domaincode) return
    let nextSectionId = `#section-ref-${this.topic.id}-${domaincode}`
    let elmnt = document.querySelectorAll(nextSectionId); // Don't remove 1 since need some margin
    let activeElement = document['_activeElement'];
    if (elmnt && elmnt.length > 0 && this.sectionReference.nativeElement.contains(activeElement)) {
      activeElement.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }

  private getNextVisibleSection(sectionIndex) {
    let nextSectionindex = Number(sectionIndex) + 1;
    let nextSection = this.sections[nextSectionindex]
    if (nextSection && !this.utils.checkConditional(this.topic, nextSection)) {
      return this.getNextVisibleSection(nextSectionindex)
    } else {
      return nextSectionindex;
    }
  }


  constructor(public cfr: ComponentFactoryResolver, private user: UserService, private changeDetectorRef: ChangeDetectorRef, private utils: ClinicalFormUtils) {
    this.openConsultationNote = new EventEmitter();
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes) {
    console.log(changes)
    // this.sectionIdentifier = [this.topic.id, this.section.type, this.section.domainCode].join('-')
    if (this.sections && Array.isArray(this.sections)) {
      this.sectionIndex = this.sections.findIndex(s => s.domainCode == this.section.domainCode) + 1;
    }
    let currentSectionId = `#section-ref-${this.topic.id}-${this.section.domainCode}`
    if (this.section && !this.section.hasOwnProperty('_sectionIsPending') && typeof this.section._sectionIsPending  != "boolean") {
      this.section._sectionIsPending = true;
    }
    if (this.section && !this.section.hasOwnProperty('_sectionIsEditable') && typeof this.section._sectionIsEditable != "boolean") {
      this.section._sectionIsEditable = true;
    }
    let taskSettings = this.user.getTaskViewSettings()
    let timeout = 10
    let canMinimize = true
    if (taskSettings) {
      timeout = taskSettings.scrollTime
      canMinimize = taskSettings.minimizeFulfilledSection
    }
    this.section._sectionIsPending = this.section.selected == null || this.section.selected == '' || this.section.selected == undefined;
    if (canMinimize) this.section._sectionIsEditable = this.section._sectionIsPending;
    this.user.sectionFocusListener.subscribe(data => {
      
      if (data.to == currentSectionId && data.action == 'scrollToNext') {
        // console.log('scrollToNext', data, currentSectionId)
        let sectionView = document.querySelector(currentSectionId);
        
        setTimeout(() => {
          if (this.section['type'] === 'masterLinked' && sectionView.getElementsByTagName('select').length > 0)
            sectionView.getElementsByTagName('select')[0].focus();
          else if (this.section['type'] === 'longText' && sectionView.getElementsByTagName('textarea').length > 0)
            sectionView.getElementsByTagName('textarea')[0].focus();
          else if (this.section['type'] === 'date' && sectionView.querySelectorAll('vaadin-date-picker').length > 0)
            (sectionView.querySelectorAll('vaadin-date-picker')[0] as HTMLElement).focus();
          else if (sectionView.querySelectorAll('input').length > 0) {
            //console.log("SECTION VIEW--->",sectionView);
            sectionView.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
            sectionView.querySelectorAll('input')[0].focus({preventScroll: true});
          }
        }, timeout)
      }
      if (data.action == 'focused') {
        if (this.topic.id == data.topicId && this.sectionIndex < data.sectionIndex) {
          this.section._sectionIsPending = this.section.selected == null || this.section.selected == '' || this.section.selected == undefined;
          if (canMinimize && this.section.type!='formula') this.section._sectionIsEditable = false;
          this.changedTimestamp = this.user.getMoment().valueOf()
          this.changeDetectorRef.detectChanges();
        } else if(this.topic.id == data.topicId && this.sectionIndex > data.sectionIndex) {
          this.section._sectionIsEditable = true;
        } else {
          this.scrollToCurrent()
        }
      }
    });
    this.detectActiveElement();
    this.loadType();
  }

  private detectActiveElement() {
    let activeElement = document['_activeElement'];
    window.addEventListener('focus', () => {
      if (this.sectionReference.nativeElement.contains(activeElement)) {
        setTimeout(_ => {
          this.user.sectionFocusEmitter({ action: 'focused', topicId: this.topic.id, sectionIndex: this.sectionIndex })
        }, 0)
      }
    }, true);
  }

  loadType() {
    this.canCheckFocus = true;
    if (this.section) {
      switch (this.section['type']) {
        case 'segment':
          this.type = SegmentComponent;
          break;
        case 'check':
          this.type = CheckComponent;
          break;
        case 'radio':
          this.type = RadioComponent;
          break;
        case 'numericInteger':
          this.type = NumericintegerComponent;
          break;
        case 'numericDecimal':
          this.type = NumericintegerComponent;
          break;
        case 'text':
          this.type = TextComponent;
          break;
        case 'caption':
          this.type = CaptionComponent;
          this.canCheckFocus = false;
          break;
        case 'longText':
        case 'addendum':
          this.type = LongtextComponent;
          break;
        case 'select':
          this.type = SelectComponent;
          break;
        case 'toggle':
          this.type = ToggleComponent;
          break;
        case 'numericDecimalRange':
          this.type = NumericdecimalrangeComponent;
          break;
        case 'numericIntegerRange':
          this.type = NumericdecimalrangeComponent;
          break;
        case 'rating':
          this.type = RatingComponent;
          break;
        case 'date':
          this.type = DateComponent;
          break;
        case 'dateTimeSegment':
          this.type = DatetimesegmentComponent;
          break;
        case 'dateAndTime':
          this.type = DateandtimeComponent;
          break;
        case 'time':
          this.type = TimeComponent;
          break;
        case 'timePeriod':
          this.type = TimeperiodComponent;
          break;
        case 'formula':
          this.type = FormulaComponent;
          break;
        case 'masterLinked':
          this.type = MasterlinkedComponent;
          break;
        case 'file':
          this.type = FileUploadComponent;
          break;
        case 'compositeField':
          this.type = CompositeFieldComponent;
          break;
        case 'directiveLinkedField':
          this.type = DirectiveLinkedComponent;
          break;
        case 'richTextEditor':
          this.type = RichTextEditor; 
          break;
        case 'draw':
          this.type = DrawField;
          break;   
        default:
          //this.type = ErrorComponent;
          this.canCheckFocus = false;
          break;
      }
      if (!this.type) return;
      if (this.section.isReadOnlyYn === 'Y' || this.section._conditionalReadOnly)
        this.type = SectionPreviewComponent;
      if (this.section.updateOnlyBlankYn === 'Y' && this.section.selected && !this.section._isEditMode) {
        this.type = SectionPreviewComponent;
      }
      const componentFactory = this.cfr.resolveComponentFactory(this.type);
      const viewContainerRef = this.appSection.vcf;
      viewContainerRef.clear();
      this.componentRef = viewContainerRef.createComponent(componentFactory);
      (<SectionInterface>this.componentRef.instance).section = this.section;
      (<SectionInterface>this.componentRef.instance).sections = this.sections;
      (<SectionInterface>this.componentRef.instance).patient = this.patient;
      (<SectionInterface>this.componentRef.instance).tabindex = this.sectionIndex;
      if (this.prescriptionRules && (this.section['type'] == 'segment' || this.section['type'] == 'compositeField')) {
        (<SectionInterface>this.componentRef.instance).prescriptionRules = this.prescriptionRules;
        (<SectionInterface>this.componentRef.instance).topic = this.topic;
      }
      if (this.section.type === 'directiveLinkedField') {
        (<SectionInterface>this.componentRef.instance).encounter = this.encounter;
        this.componentRef.instance.openConsultationNote.subscribe((event: any) => this.openConsultationNote.emit());
        this.componentRef.changeDetectorRef.detectChanges();
      }
      if (this.section['type'] === 'segment') {
        if (this.section['domainCode'] === 'PRESFRQ')
          (<SectionInterface>this.componentRef.instance).frequencyApplicable = this.frequencyApplicable;
        if (this.section['domainCode'] === 'PRESROUTE')
          (<SectionInterface>this.componentRef.instance).routeApplicable = this.routeApplicable;
      }
      if(this.section['type'] === 'richTextEditor'){
        (<SectionInterface>this.componentRef.instance).topics = this.topics;
        (<SectionInterface>this.componentRef.instance).appointmentsList = this.appointmentsList;
      }
      if (this.section['type'] == 'date' || this.section['type'] == 'datetime' || this.section['type'] == 'select' || this.section['type'] == 'radio' || this.section['type'] == 'segment') {
        try {
          (<SectionInterface>this.componentRef.instance).valueChangeComplete.subscribe((changeCompleted) => {
            if (changeCompleted) {
              this.scrollToNext();
            }
          })
        } catch (e) {
          console.log(this.section, e);
        }
      }
      if(this.section.isReadOnlyYn === 'Y')
      (<SectionInterface>this.componentRef.instance).isFromReadOnlySection = true;
    }
  }

  public enableEdit(ev) {
    this.section._sectionIsEditable = ev;
  }
}
export interface SectionInterface {
  section;
  sections;
  topic?;
  prescriptionRules?;
  patient?: Object;
  encounter?: Object;
  frequencyApplicable?;
  routeApplicable?;
  topics?;
  isFromReadOnlySection?;
  appointmentsList?;
  tabindex?,
  valueChangeComplete?: EventEmitter<any>
}
