<div class="package-detail-modal-wrapper">
    <div class="package-detail-modal">
        <div class="package-detail-header">
            <img src="./assets/icons/Home/down arrow.svg" class="cursor-pointer back-icon" (click)="onBack()">
            {{'Package_details' | translate}}
            <span class="close-icon cursor-pointer" (click)="closeModal()"><img src="./assets/icons/close_black.svg" alt=""></span>
        </div>
        <div class="package-detail-content">
            <div class="package-status-wrapper md-hidden">
                <p class="status-wrapper"><span class="status-text" [ngClass]="{'cancelled': selectedPackage['status'] == 'Cancelled'}">{{selectedPackage['status']}}</span> 
                    <span *ngIf="selectedPackage['status'] !== 'Cancelled'"> - {{selectedPackage['completedPercent']+'%'}}</span>
                </p>
                <p class="package-progress-bar">
                    <span class="package-progress" [ngClass]="{'cancelled': selectedPackage['status'] == 'Cancelled'}"
                        [ngStyle]="{'width': selectedPackage['status'] == 'Cancelled' ? '100%':selectedPackage['completedPercent']+'%'}">
                    </span>
                </p>
            </div>
            <div class="my-packages-item">
                <div class="image" >
                    <img *ngIf="selectedPackage['imageURL']" class="type-image "  [src]="selectedPackage['imageURL']" alt="">
                  </div>
                <!-- <img *ngIf="selectedPackage['imageURL']" class="my-packages-img" [src]="selectedPackage['imageURL']" alt=""> -->
                <div class="my-package-context">
                    <p class="my-package-title">{{selectedPackage['name']}}</p> 
                    <p class="status-wrapper sm-hidden"><span class="status-text" [ngClass]="{'cancelled': selectedPackage['status'] == 'Cancelled'}">{{selectedPackage['status']}}</span> 
                        <span *ngIf="selectedPackage['status'] !== 'Cancelled'"> - {{selectedPackage['completedPercent']+'%'}}</span>
                    </p>
                    <p class="package-progress-bar sm-hidden">
                        <span class="package-progress" [ngClass]="{'cancelled': selectedPackage['status'] == 'Cancelled'}"
                            [ngStyle]="{'width': selectedPackage['status'] == 'Cancelled' ? '100%':selectedPackage['completedPercent']+'%'}">
                        </span>
                    </p>
                </div>
                <div class="my-packge-html" *ngIf="selectedPackage['_healthPackage']">
                    <div class="innerImg type-image" [innerHTML]="getPackageContent(selectedPackage['_healthPackage']['detail'])"></div>
                </div>
            </div>
            <div class="package-information-wrapper">
                <div class="package-info-accordian">
                    <div class="package-info-acc-header">
                        {{'Payment_information' |translate}} <img src="./assets/icons/Home/down arrow.svg" alt="">
                    </div>
                    <div class="package-info-acc-content">
                        <div class="package-info-list">
                            <div class="package-info-li">
                                <p class="package-info-label">{{'Purchased_on' | translate}}</p>
                                <p class="package-info-value">{{selectedPackage['bookedDttm'] | moment:'D MMM YYYY hh:mm A': 'YYYY-MM-DD HH:mm:ss'}}</p>
                            </div>
                            <div class="package-info-li">
                                <p class="package-info-label">{{'Valid_from' | translate}}</p>
                                <p class="package-info-value">{{selectedPackage['validFrom'] | moment:'D MMM YYYY hh:mm A': 'YYYY-MM-DD HH:mm:ss'}}</p>
                            </div>
                            <div class="package-info-li">
                                <p class="package-info-label">{{'Valid_to' | translate}}</p>
                                <p class="package-info-value">{{selectedPackage['validTo'] | moment:'D MMM YYYY hh:mm A': 'YYYY-MM-DD HH:mm:ss'}}</p>
                            </div>
                            <div class="package-info-li">
                                <p class="package-info-label">{{'Facility' | translate}}</p>
                                <p class="package-info-value">{{(selectedPackage['facilityId']|facilityDetails:'facilityName') | translate : 'LOCALE' : 'name'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="package-info-accordian">
                    <div class="package-info-acc-header">
                        {{'Included_services' |translate}} <img src="./assets/icons/Home/down arrow.svg" alt="">
                    </div>
                    <div class="package-info-acc-content">
                        <div class="package-info-list">
                            <div class="package-info-li" *ngFor="let s of packageDetails['serviceIncluded']">
                                <div class="package-li-context">
                                    <p class="package-info-value">{{s['desc']}}</p>
                                    <p class="package-info-subtext" *ngIf="s['bookedDttm']">{{s['bookedDttm'] | moment : 'D MMM YYYY, hh:mm a' :
                                        'YYYY-MM-DD HH:mm'}}</p>
                                    <p class="package-info-subtext" *ngIf="s['completedDttm']">{{s['completedDttm'] | moment : 'D MMM YYYY, hh:mm a' :
                                        'YYYY-MM-DD HH:mm'}}</p>
                                </div>
                                <button class="button-primary" *ngIf="s['bookedDttm']">View</button>
                                <img *ngIf="s['completedDttm']" src="./assets/icons/health-package/check.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>