<div id="referral-details">
  <div class="referral-card-container">
    <div class="referral-card">
      <div class="card-title">{{'head_referral_detail' | translate}}</div>
      <div class="referral-for">
        <div class="details">
          <div class="image">
            <app-person-avatar [url]="patientDetail['image']"> </app-person-avatar>
          </div>
          <div class="detail">
            <div class="main-content">{{patientDetail['personname']}}</div>
            <div class="sub-content">{{patientDetail['uhid']}}</div>
          </div>
        </div>
        <div class="details">
          <div class="image">
            <img class="type-image" src="assets/icons/bookappt/hermes_grey.svg" alt="">
          </div>
          <div class="detail">
            <div class="main-content">{{referralDetail['specialityDesc']}}</div>
            <div class="sub-content">{{referralDetail.facilityId | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name'}}</div>
          </div>
        </div>
      </div>
      <div class="referral-at">
        <div class="details">
          <div class="image">
            <img class="type-image small" src="assets/icons/user.svg">
          </div>
          <div class="detail">
            <span>
              {{"referred_by" | translate}}
              <ng-container *ngIf="referralDetail.referredPractitionerLocalName">
                {{referralDetail.referredPractitionerLocalName | translate : 'LOCALE' : 'name'}}
              </ng-container>
              <ng-container *ngIf="!referralDetail.referredPractitionerLocalName && referralDetail.referredPractitionerName">
                {{referralDetail.practitionerName}}
              </ng-container>
              <ng-container *ngIf="!referralDetail.referredPractitionerLocalName && !referralDetail.referredPractitionerName">
                {{(referralDetail['referredResourceId']|partitionerDetails:referralDetail['facilityId']:'resourceName'| translate : 'LOCALE' : 'name') || 'Doctor'}}
              </ng-container>
            </span>
          </div>
        </div>
        <div class="details">
          <div class="image">
            <img class="type-image small" src="assets/icons/bookappt/calendar_grey.svg">
          </div>
          <div class="detail">
            <span>{{'valid_till'|translate}} {{referralDetail.referralEndDate | moment : 'DD MMM YYYY': 'YYYY-MM-DD'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="referral-card-container view-small">
    <div class="referral-card">
      <div class="card-title">{{'head_referral_actions' | translate}}</div>
      <div class="available-actions">
        <div class="action-list">
          <div class="action-item" *ngFor="let action of availableActions" (click)="openAction(action)">
            {{action.desc | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>