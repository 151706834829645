import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentComponent } from './segment/segment.component';
import { FormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';

import { CheckComponent } from './check/check.component';
import { RadioComponent } from './radio/radio.component';
import { NumericintegerComponent } from './numericinteger/numericinteger.component';
import { TextComponent } from './text/text.component';
import { CaptionComponent } from './caption/caption.component';
import { LongtextComponent } from './longtext/longtext.component';
import { SelectComponent } from './select/select.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ErrorComponent } from './error/error.component';
import { NumericdecimalrangeComponent } from './numericdecimalrange/numericdecimalrange.component';
import { RatingComponent } from './rating/rating.component';
import { DateComponent } from './date/date.component';
import { DatePipe } from './date.pipe';
import { DatetimesegmentComponent } from './datetimesegment/datetimesegment.component';
import { DateandtimeComponent } from './dateandtime/dateandtime.component';
import { DatetimePipe } from './datetime.pipe';
import { TimeComponent } from './time/time.component';
import { TimePipe } from './time.pipe';
import { TimeperiodComponent } from './timeperiod/timeperiod.component';

import { MandatoryComponent } from './mandatory/mandatory.component';
import { FormulaComponent } from './formula/formula.component';
import { FormulaPipe } from './formula.pipe';
import { MasterlinkedComponent } from "./masterlinked/masterlinked.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { PipesModule } from "../../pipes/pipes.module";
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SplitPipe } from './split.pipe';
import { CompositeFieldComponent } from './composite-field/composite-field.component';
import { DirectiveLinkedComponent } from './directive-linked/directive-linked.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { LeadService } from 'src/app/services/lead.service';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DrawField } from './draw/draw.component';
import { SectionPreviewModule } from 'src/app/section-preview/section-preview.module';
import { RichTextEditor } from './richTextEditor/richTextEditor.component';

@NgModule({
  imports: [CommonModule, FormsModule, PipesModule, NgxFileDropModule,DirectiveModule, SignaturePadModule, OwlDateTimeModule, OwlNativeDateTimeModule, SectionPreviewModule],
  declarations: [
    DatePipe,
    SegmentComponent,
    CheckComponent,
    RadioComponent,
    NumericintegerComponent,
    TextComponent,
    CaptionComponent,
    LongtextComponent,
    SelectComponent,
    ToggleComponent,
    ErrorComponent,
    NumericdecimalrangeComponent,
    RatingComponent,
    DateComponent,
    DatetimesegmentComponent,
    DateandtimeComponent,
    DatetimePipe,
    TimeComponent,
    TimePipe,
    TimeperiodComponent,
    MandatoryComponent,
    FormulaComponent,
    FormulaPipe,
    MasterlinkedComponent,
    FileUploadComponent,
    SplitPipe,
    CompositeFieldComponent,
    DirectiveLinkedComponent,
    DrawField,
    RichTextEditor
  ],
  exports: [
    SegmentComponent,
    FormulaComponent,
    CheckComponent,
    RadioComponent,
    NumericintegerComponent,
    TextComponent,
    CaptionComponent,
    LongtextComponent,
    SelectComponent,
    ToggleComponent,
    NumericdecimalrangeComponent,
    ErrorComponent,
    RatingComponent,
    DateandtimeComponent,
    TimeComponent,
    TimeperiodComponent,
    DateComponent,
    DatetimesegmentComponent,
    MandatoryComponent,
    MasterlinkedComponent,
    FileUploadComponent,
    SplitPipe,
    CompositeFieldComponent,
    DirectiveLinkedComponent,
    DrawField
  ],
  providers: [LeadService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class TypeModule { }
