import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '../services/user.service';
import { UtilitiesService } from '../services/utilities.service';

@Component({
  selector: 'app-task-login',
  templateUrl: './task-login.component.html',
  styleUrls: ['./task-login.component.scss']
})
export class TaskLoginComponent implements OnInit {
  @Input() selectedPatient: any;
  public languageList: any = [];
  public currentState: 'GENOTP' | 'ENTOTP' = 'GENOTP';
  public selectedLanguage: any;
  public otp: any;
  public invalidOtp: boolean = false;
  @Output() complete: EventEmitter<any>
  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>();
  }
  
  ngOnInit(): void {
    this.user.getLanguages().subscribe(data => {
      this.languageList = data;
    });
    this.selectedLanguage = this.user.currentLanguage;
  }
  public openInfo() {
    // let base = 'https://nahdicareclinics.sa';
    // window.open(base, "_blank");
    let link = environment['urlInfo']['index'];
    // if (environment['urlInfo']['specifyLanguage'])
    //   link = this.utils.replaceLanguage(link, this.user.currentLanguage);
    window.open(link, "_blank");
  }

  public generateOtp() {
    this.user.generateOtpForPatientId(this.selectedPatient.uhid).subscribe(_ => {
      this.currentState = 'ENTOTP';
    })
  }
  public selectLanguge(data) {
    this.user.setDirection(data);
  }

  public validateOTP() {
    this.user.validatePatientView(null, this.selectedPatient.uhid, this.otp).subscribe(response => {
      if (response['token']) {
        this.complete.emit(response)
      } else {
        this.invalidOtp = true
      }
    }, err => {
      this.invalidOtp = true
    })
  }

}
