import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormControlGenerateService {

  public generateForm(formArray: any) {
    let parentGroup = formArray.reduce((acc, form) => {
      if (form.type == 'group') {
        let group = this.generateForm(form.child)
        return { ...acc, [form.name]: group }
      }
      else if (form.type == 'array') {
        let array = this.generateFormArray(form.child)
        console.log("arr", array)
        return { ...acc, [form.name]: array }
      }
      else {
        let control = this.generateFormControl(form);
        return { ...acc, [form.name]: control }
      }
    }, {});
    return new FormGroup(parentGroup);
  }
  private generateFormControl(object) {
    return new FormControl(object.value, object.validators);
  }
  private generateFormArray(object) {
    return new FormArray([], object.validators);
  }
}
