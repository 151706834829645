import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { UserService } from '../services/user.service';
@Pipe({
  name: 'apptTime'
})
export class ApptTimePipe implements PipeTransform {

  constructor(private user: UserService) { }

  transform(apptTime: string, pipeType: 'CHECK' | 'FORMAT', displayFormat: string, sourceFormat: string, timezone: 'LOCAL' | 'FACILITY', facilityId): string | boolean {
    let facilityTimezone = this.user.getFacilityTimezone(facilityId);
    if (pipeType == 'FORMAT') {
      let apptMoment = sourceFormat ? moment(apptTime, sourceFormat) : moment(apptTime);
      apptMoment = apptMoment.tz(facilityTimezone, true);
      if (timezone == 'LOCAL') {
        apptMoment = apptMoment.tz(moment.tz.guess());
      }
      return displayFormat ? apptMoment.format(displayFormat) : apptMoment.format();
    } else if (pipeType == 'CHECK') { 
      let localZone = moment().utcOffset();
      let facilityZone = moment().tz(facilityTimezone).utcOffset();
      console.log('CHECK TIMEZONE', localZone, facilityZone);
      return localZone !== facilityZone;
    }
    return '';
  }

}
