import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentPreviewComponent } from './appointment-preview.component';
import { PipesModule } from '../pipes/pipes.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { ChooseSlotModule } from '../choose-slot/choose-slot.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [AppointmentPreviewComponent, RescheduleComponent],
  imports: [
    CommonModule, PipesModule, PractitionerAvatarModule, PersonAvatarModule, ChooseSlotModule, FormsModule, ReactiveFormsModule
  ], exports: [
    AppointmentPreviewComponent,
    RescheduleComponent
  ]
})
export class AppointmentPreviewModule { }
