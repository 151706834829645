import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { VisitService } from 'src/app/services/visit.service';
import { VisitAbstractClass } from '../visit-standalone.abstract';

@Component({
  selector: 'app-step-register-review',
  templateUrl: './step-register-review.component.html',
  styleUrls: ['./step-register-review.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class StepRegisterReviewComponent extends VisitAbstractClass implements OnInit {
  @Input() regParams;
  constructor(private visitS: VisitService) { 
    super();
  }

  ngOnInit(): void {
    // if (this.regParams)
    //   this.initPayment();
  }


  public initPayment() {
    this.regParams['transactionType'] = 'REGPAT';
    const params = {
      amount: this.visitCharges.registrationCharges.toString(),
      deviceId: 'AAAAA',
      ipAddress: null,
      orderDetails: "REG_" + this.regParams?.mobile,
      facilityId: this.facility.facilityId,
      mobileNo:this.regParams?.mobile,
      reqParams: this.regParams,
    }
    this.visitS.startTransaction(params).subscribe(data => { 
      this.navigateToCheckOut(data);
    })
  }

  private navigateToCheckOut(data) {
    let appointment = {
      _orderId: data.orderId,
      saved_card_token: null,
      saved_card_details: [],
      uhid: this.selectedPatient?.patientId,
      email: this.regParams?.email,
      mobile_no: this.regParams?.mobile,
      isStandalone: true
    }
    let orderId = data.orderId;
    let paymentUrl = data.transactionUrl
    let queryParams = {}
    queryParams['o'] = orderId;
    console.log(queryParams)
    let params = Object.keys(queryParams).reduce((r, p) => {
      let val = [p, queryParams[p]].join('=');
      r.push(val);
      return r;
    }, []).join('&')
    let url = window['location'];
    let diff = (href, origin) => href.split(origin).join('');


    let mainParams = {
      r: 1,
      method: 'REGPAT',
      p: btoa(params),
      fid: this.queryParams['fid'],
      sid: this.queryParams['sid'],
      pid: this.queryParams['pid'],
      printid:this.queryParams['printid']
    }
    let mainParamString = Object.keys(mainParams).reduce((r, p) => {
      if (p && mainParams[p]) {
        let val = [p, mainParams[p]].join('=');
        r.push(val);
      }
      return r;
    }, []).join('&')
    let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?${mainParamString}`;

    let paymentParams = {
      _returnURL: returnURL,
      currency:  this.visitCharges?.currency,
      charge: this.visitCharges?.registrationCharges,
      paymentgatewayurl: paymentUrl
    }

    console.log(appointment, paymentParams)
    this.visitS.openCheckout(appointment, paymentParams);
    // this.initPay.emit({appointment, paymentParams})

  }

}
