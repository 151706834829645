import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';
import * as moment from 'moment';
import { ClinicalformService } from 'src/app/services/clinicalform.service';

// import '@vaadin/vaadin-radio-button/vaadin-radio-button.js';
@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends Type implements OnInit, SectionInterface, OnChanges {


  @Input() public section;
  @Input() public sections;
  @Input() public route;
  @Input() public frequency;
  @Input() public tabindex;
  @Output() valueChangeComplete: EventEmitter<any>;

  public UUID: string;
  constructor(cfs: ClinicalformService) {
    super(cfs);
    this.valueChangeComplete = new EventEmitter<any>();
  }

  ngOnInit() {
    super.checkForMandatory(this.section);
    super.getValuesforSection(this.section);
    this.domainLevelDefaultValue();
    let i = this.section.question[0].text.replace(/ /g, '');
    this.UUID = Math.random().toString(36) + i.substr((i.length / 2 - i.length / 4), (i.length / 2 + i.length / 4));

  }

  ngOnChanges() {
  }

  getSelected() {
    if (this.section['selected']) {
      return this.section['selected'];
    } else {
      return '';
    }
  }

  setValue(value) {
    if (this.section['selected'] == value) {
      this.section['selected'] = '';
      this.section['completed'] = false;
    }
    else {
      this.section['selected'] = value;
      this.section['completed'] = true;
    } 
  }

  public domainLevelDefaultValue() {
    if (!this.section['defaultOnFormLoad'])
      return;
    var answers = this.section['domainControls']['answer']
    for (var i = 0; i < answers.length && Array.isArray(answers); i++) {
      if (!answers[i]['default'])
        continue;
      if (!this.section['selected'])
        this.section['selected'] = answers[i]['localName'][0]['text'];
    }
    this.valueChangeComplete.emit(true)

  }
  public nospace(val) {
    return val.replace(/ /g, '');
  }

}
