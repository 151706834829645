    <div class="tc-modal" *ngIf="false">
      <div class="content">
        <div class="modal-header border-bottom">
            <!-- <div (click)="dismiss()" class="float-left closeicon pointer">
                <img src="assets/icons/arrow.svg">
              </div> -->
          <div class="font-primary-subtitle bold">{{ couponParam.title |translate}}</div>
          <div (click)="closeModal()" class="float-right closeicon pointer">
            <!-- <img src="./assets/icons/close.svg"> -->
              <img class="type-image" src="assets/icons/back.svg" />
          </div>
        </div>
        <div class="container"  *ngIf="couponParam.type != 'COUPON'">
            <p class="input-container">
              <!-- <input type="text"  *ngIf="couponParam.type == 'COUPON'" placeholder="{{ couponParam.placeholder |translate}}" [(ngModel)]="couponCode" (input)="onchange($event)">
              <button class="primary-button"  *ngIf="couponParam.type == 'COUPON'" (click)="applyCoupon()" [disabled]="isEmpty()" >{{ 'btn_apply' | translate }}</button> -->
                <input type="text"  *ngIf="couponParam.type != 'COUPON'" placeholder="{{ couponParam.placeholder |translate}}" [(ngModel)]="promoCode" (input)="onchangePromo($event)">
                <button class="primary-button"   *ngIf="couponParam.type != 'COUPON'" (click)="applyCode()" [disabled]="isEmptyPromo()" >{{ 'btn_apply' | translate }}</button>
            </p>
            <p *ngIf="isInValidPromo" class="error-msg">
              {{ 'lbl_invalid_promo' | translate }}
            </p>
            <p *ngIf="false" class="error-msg">
              {{ 'lbl_invalid_coupon' | translate }}
            </p>
        </div>
        <div class="modal-content font-default-small">
            <div class="" *ngIf="couponParam.type == 'COUPON'">
              <div class="no-data" *ngIf="((!couponList || couponList.length == 0) && !selectedCoupons) || showNoCoupon">
                <span class="font-default-text">{{ 'no_available_coupons' | translate }}</span>
              </div>
              <div class="" *ngIf="selectedCoupons && selectedCoupons.length>0">
                <div class="payable_container hide">
                  <span *ngIf="_couponInput" class="ternary-subtitle">
                    {{ 'lbl_total_bill_amount' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ _couponInput }} </span>
                    {{ 'lbl_coupons_applied' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ couponDiscount }} </span>
                    {{ 'lbl_balance_payable' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ couponBalPatientPayable }}</span>
                  </span>
                </div>
                <div class="coupon-title space-btw">
                  <span class="default-subtitle bold">{{ 'lbl_applied_coupons' | translate}}</span>
                  <p *ngIf="_couponInput" class="ternary-subtitle show">
                    {{ 'lbl_total_bill_amount' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ _couponInput }} </span>
                    {{ 'lbl_coupons_applied' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ couponDiscount }} </span>
                    {{ 'lbl_balance_payable' | translate }} <span class="default-subtitle bold"> {{ currency_code }} {{ couponBalPatientPayable }}</span>
                  </p>
                </div>
                <div class="cards">
                    <div class="coupon-select-container"  *ngFor="let item of selectedCoupons">
                      <div class="align-start flex">
                        <div ><p class="blue-bg primary-subtitle">
                          <img class="type-image" src="assets/icons/nadhi_logo.svg">
                          {{item['couponTitle']}} 
                          </p> 
                          <div class="primary-subtitle bold" *ngIf="item['couponSubtitle']">{{ item.couponSubtitle }}</div>
                          <div class="ternary-paragraph">
                            <span [ngClass]="{ 'strike-text': item['cashDiscount'] > item['couponBurnt']}">{{ currency_code }} {{item['cashDiscount']}} </span>
                            <span *ngIf="item['cashDiscount'] > item['couponBurnt']">{{ currency_code }} {{item['couponBurnt']}}</span>
                          </div>
                        </div>
                        <div class="delete cursor-pointer" (click)="removeCoupon(item)">
                          <img class="type-image" src="assets/icons/delete.svg">
                        </div>
                      </div>
                  </div>
                </div>
                <div class="btn-container pointer" (click)="dismiss()" *ngIf="selectedCoupons && selectedCoupons.length>0">
                  <button class="primary-button"  *ngIf="couponParam.type == 'COUPON'">{{ 'btn_apply_and_continue' | translate }}</button>
                </div>
              </div>
                <div class="coupon-title default-subtitle bold" *ngIf="couponList && couponList.length > 0">{{ couponParam.listTitle | translate}}</div>
                <div *ngFor="let item of couponList" class="card-identifier" (click)="chooseCoupon(item)">
                    <div class="card-container border-bottom">
                      <div class="align-start">
                            <p class="blue-bg primary-subtitle">
                            <img class="type-image" src="assets/icons/nadhi_logo.svg">
                            {{item['couponTitle']}} 
                            </p>
                            <div class="primary-subtitle bold" *ngIf="item['couponSubtitle']">{{ item.couponSubtitle }}</div>
                        </div>
                        <div class="default-subtitle">{{ currency_code }} {{item['cashDiscount']}}</div>
                      <div class="ternary-subtitle" *ngIf="item['expires']">{{ 'lbl_valid_till' | translate }} {{item['expires'] | moment : 'MMM YYYY':'YYYY-MM-DD'}}</div>
                      <div class="primary-subtitle capitalize">{{ 'btn_apply' | translate }}</div>
                    </div>
                </div>
                <!-- <p *ngIf="isInValidCoupon" class="error-msg">
                  {{ 'lbl_invalid_coupon' | translate }}
                </p> -->
            </div>
            <ng-container *ngIf="showAvailPromoList && promoList">
              <div class="" *ngIf="couponParam.type != 'COUPON'">
                <div class="coupon-title">{{ couponParam.listTitle | translate}}</div>
                <div *ngFor="let item of promoList" class="card-identifier" (click)="chooseCoupon(item)">
                  <div class="border-bottom">
                    <div class="card-container">
                      <div class="align-start">
                          <p class="blue-bg primary-subtitle">
                          <img class="type-image" src="assets/icons/nadhi_logo.svg">
                          {{item['promocode']}} 
                          </p>
                      </div>   
                      <div class="primary-subtitle capitalize">{{ 'btn_apply' | translate }}</div>
                    </div>
                    <div class="align-start">
                      <div class="primary-subtitle bold">  {{ getPromoDesc(item.localName) || item.promodesc }}</div>
                      <!-- <ng-container *ngFor="let i of item.localName">
                        <div class="primary-subtitle bold" *ngIf="i.name == lang">{{ i.value || item.promodesc }}</div>
                      </ng-container> -->
                      <span class="default-subtitle">{{ item.longdesc }}</span>
                    </div>
                  </div>
                </div>
                <!-- <p *ngIf="isInValidPromo" class="error-msg">
                  {{ 'lbl_invalid_promo' | translate }}
                </p> -->
              </div>
            </ng-container>
        </div>
      </div>
      
    </div>

    
    <div class="tc-modal" *ngIf="(type == 'PROMO' || type == 'COUPON' ) && !listType" (clickOutside)="closeFilter();">
      <div class="content">
        <div class="container"  *ngIf="type == 'PROMO'">
          <div class="promo-content">
            <div class="font-light-small promo-container">
              <input type="text" placeholder="{{ 'Enter_code_here' | translate }}"
              [(ngModel)]="promoCode" (input)="onchangePromo($event)">
                <div class="close float-right closeicon cursor-pointer" (click)="removePromo()" *ngIf="(isPromoApplied || isInValidPromo) || promoCode">
                  <img src="./assets/icons/close_grey.svg">
                </div>
            </div>
            <div><button class="primary-button promo-apply-btn" (click)="applyCode()" [disabled]="isEmptyPromo()">
                {{ 'btn_apply' | translate }} </button></div>
          </div>
          <ng-container *ngIf="promoCode">
            <p *ngIf="isInValidPromo" class="error-msg">
              {{ 'lbl_invalid_promo' | translate }}
            </p>
            <p *ngIf="isPromoApplied" class="success-msg">
              <img class="type-image" src="./assets/icons/check.svg">
              {{ 'lbl_promo_applied_succesfully' | translate }}
            </p>
          </ng-container>
        </div>
        <div class="modal-content font-default-small" *ngIf="type == 'COUPON'">

          <div class="filter" *ngIf="type == 'COUPON'">
            <div class="dp-down cursor-pointer" >
              <div class="dp-select"  [ngClass]="{ 'hide-rl-bdr': isFilterOpen }">
                <span class="sel-coupon">
                  <span class="dp-lbl" *ngIf="isFilterOpen || (!selectedCoupons || (selectedCoupons && selectedCoupons.length == 0))" (click)="openCloseFilter()" >{{ 'lbl_select_coupons' | translate }}
                    <!-- <img src="./assets/icons/arrow drop.svg"> -->
                  </span>
                  <ng-container  *ngIf="!isFilterOpen && selectedCoupons && selectedCoupons.length > 0">
                    <div class="coupon-box " *ngFor="let coupon of selectedCoupons">
                      <span class="coupon-prim-title bold">{{coupon['cashDiscount']}} {{ currency_code }}</span>
                      <span class="align-center" (click)="removeInternalCoupon(coupon)">
                        <img src="./assets/icons/close_black.svg">
                      </span>
                    </div>
                  </ng-container>
                </span>
                <img src="./assets/icons/arrow drop.svg"  (click)="openCloseFilter()">
              </div>
              <div class="dp-option show" *ngIf="isFilterOpen">
                <ng-container *ngIf="((!couponList || couponList.length == 0)) || showNoCoupon">
                  <div class="" >
                    <div class="no-data">
                      <span class="font-default-text">{{ 'no_available_coupons' | translate }}</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="couponList && couponList.length > 0">
                  <div class="dp-list">
                  <div class="input-group " *ngFor="let item of couponList" >
                    <input type="checkbox" id="{{item.couponSequenceNo}}" [checked]="item.checked" (click)="chooseFilter($event, item)" />
                    <label for="{{item.couponSequenceNo}}">
                      <!-- <span class="font-primary-title bold">{{item['couponTitle']}}</span>
                      <span class="responsive-float-right font-default-small">{{ 'lbl_expires_on' | translate }} {{item['expires'] | moment : 'MMM YYYY':'YYYY-MM-DD'}}</span> -->
                    </label>
                    <div class="flx-spc">
                      <span class="coupon-prim-title bold">{{item['cashDiscount']}} {{ currency_code }}</span>
                      <span class="responsive-float-right font-default-small coupon-subtitle" *ngIf="false"> {{item['expires'] | moment : 'MMM YYYY':'YYYY-MM-DD'}}</span>
                    </div>
                  </div>
                  </div>
                  <div class="button-bar">
                    <button class="primary-button-outline" (click)="dismiss()">{{'btn_cancel' | translate}}</button>
                    <button class="primary-button" (click)="dismiss('APPLY')" [disabled]="!selectedCoupons">{{'btn_apply' | translate}}</button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="tc-modal" *ngIf="listType == 'COUPONLIST'">
      <div class="content">
        <div class="modal-header border-bottom"  >
          <div (click)="dismiss()" class="float-left closeicon pointer">
            <img src="assets/icons/arrow.svg">
          </div>
          <div class="font-primary-subtitle bold" >{{ 'title_available_coupons' |translate}}</div>
          <div (click)="dismiss()" class="float-right closeicon pointer">
              <img class="type-image" src="assets/icons/close_grey.svg" />
          </div>
        </div>
        <div class="modal-content">
          <ng-container *ngIf="((!couponList || couponList.length == 0)) || showNoCoupon">
            <div class="" >
              <div class="no-data">
                <span class="font-default-text">{{ 'no_available_coupons' | translate }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="couponList && couponList.length > 0">
            <div class="dp-option" >
              <div class="dp-list">
              <div class="input-group " *ngFor="let item of couponList" >
                <input type="checkbox" id="{{item.couponSequenceNo}}" [checked]="item.checked"  (change)="chooseFilter($event, item)" />
                <label for="{{item.couponSequenceNo}}">
                </label>
                <div class="flx-spc">
                  <span class="coupon-prim-title bold">{{item['cashDiscount']}} {{ currency_code }}</span>
                  <span class="responsive-float-right font-default-small coupon-subtitle" *ngIf="false">{{ 'lbl_expires_on' | translate }} {{item['expires'] | moment : 'MMM YYYY':'YYYY-MM-DD'}}</span>
                </div>
              </div>
            </div>
            </div>
          </ng-container>
        </div>
        <div class="button-bar" *ngIf="couponList && couponList.length > 0">
          <button class="primary-button" (click)="dismiss('APPLY')" [disabled]="!selectedCoupons">{{'btn_apply' | translate}}</button>
        </div>
      </div>
    </div>

