import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-hr-report-pdfformat',
  templateUrl: './hr-report-pdfformat.component.html',
  styleUrls: ['./hr-report-pdfformat.component.scss']
})
export class HrReportPdfformatComponent implements OnInit {

  @Input() report: any;
  @Input() reportData: any;
  @Input() selectedPatient: any;
  @Input() isCommonView: boolean;
  @Output() close: EventEmitter<any>;
  @Output() initDownload: EventEmitter<any>;
  @Output() toggleQrCode: EventEmitter<any>;
  @Input() set qrcodeUrl(value) {
    this._qrcodeUrl = value;
  }
  get qrcodeUrl() {
    return this._qrcodeUrl;
  }
  public canDownload: boolean;
  public isPreviewImage: boolean;
  public isPreviewVideo: boolean;
  public isPreviewPdf: boolean;
  public previewUrl: string;
  public _qrcodeUrl: any;
  @Input() expandQRCode: boolean;
  constructor(private user: UserService, private loader: LoaderService) {
    this.close = new EventEmitter<any>();
    this.initDownload = new EventEmitter<any>();
    this.toggleQrCode = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getConfigurationInfo.subscribe(data => {
      this.canDownload = data.features.documents.download;
    })
    console.log(this.report);
    this.loader.changeState(true);
  }
  public startDownload() {
    this.initDownload.emit(this.report);
  }
  public closeModel() {
    this.close.emit();
  }
  public openPreviewImage(url: string) {
    this.isPreviewImage = true;
    this.previewUrl = url;
  }
  public closePreviewImage() {
    this.isPreviewImage = false;
    this.previewUrl = '';
  }
  public openPreviewVideo(url: string) {
    this.isPreviewVideo = true;
    this.previewUrl = url;
  }
  public closePreviewVideo() {
    this.isPreviewVideo = false;
    this.previewUrl = '';
  }
  public openPreviewPdf(url: string) {
    this.isPreviewPdf = true;
    this.previewUrl = url;
  }
  public closePreviewPdf() {
    this.isPreviewPdf = false;
    this.previewUrl = '';
  }
  public stopLoader() {
    this.loader.changeState(false);
  }
  public expandQR() {
    this.toggleQrCode.emit()
  }
}
