<div id="change-mobile-number" class="right-nav">
  <div class="content">

    <div class="form">
      <div class="title bold">
        {{'title_change_email_id'|translate}}
        <div (click)="closeModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_grey.svg">
        </div>
      </div>

      <div class="precheck-form" *ngIf="isPreCheck">
        <form [formGroup]="precheckForm" class="">
          <div class="group">
            <app-custom-input-group 
                label="{{'lbl_registered_email'|translate}}"
                formControlName="oldEmail"
                type="text"
                [required]="true"
                [readonly]="true"
              ></app-custom-input-group>
            <app-custom-input-group 
              label="{{'holder_mail_ID'|translate}}"
              formControlName="email"
              type="text"
              [required]="true"
            ></app-custom-input-group>
          </div>
        </form>
      </div>

      <div class="footer" *ngIf="isPreCheck">
        <button type="submit" (click)="sendOTP()" [disabled]="!precheckForm['valid']" class="primary-button">
          {{'btn_send_otp'|translate}} </button>
      </div>

      <div class="precheck-form" *ngIf="isVerifyEmail">
        <form [formGroup]="changeEmailForm" class="">
          <div class="group">
            <app-custom-input-group 
              label="{{'holder_mail_ID'|translate}}"
              formControlName="email"
              type="text"
              [required]="true"
              [readonly]="true"
              icon="assets/icons/signin/pen.svg"
              (click)="changeEmail()"
            ></app-custom-input-group>
          </div>
          <div class="group">
            <!-- <div class="input-container text-input">
              <label>{{'lbl_enter_otp'|translate}}</label>
              <div class="input-group">
                <input formControlName="otp" placeholder="000000" type="password" required>
              </div>
            </div> -->
            <app-custom-input-group 
              label="{{'lbl_enter_otp'|translate}}"
              formControlName="otp"
              type="password"
              [required]="true"
            ></app-custom-input-group>
          </div>
          <div class="group text-input adjust-margin">
            <label class="wrap-text">{{'lbl_please_enter_otp_message_email' | translate}} {{setEmail}}</label>
          </div>

        </form>
      </div>

      <div class="footer" *ngIf="isVerifyEmail">
        <button type="submit" (click)="verifyOTP()" [disabled]="!changeEmailForm['valid']" class="primary-button">
          {{'btn_verify_otp'|translate}} </button>
      </div>

    </div>
  </div>
</div>
