import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ModalService } from '../services/modal.service';
import { CustomModalContainerComponent } from '../custom-modal-container/custom-modal-container.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  declarations: [CustomModalContainerComponent ],
  
  exports: [CustomModalContainerComponent ],
  providers: []
})
export class CustomModalModule { }