import { Pipe, PipeTransform } from '@angular/core';
import { ClinicalFormUtils } from '../clinicalform/clinicalform.utils';
@Pipe({
  name: 'sectionfilter',
  pure:false
})
export class SectionfilterPipe implements PipeTransform {

  constructor(private utils: ClinicalFormUtils) {

  }
  transform(sections: Array<Object>, topic: Object, filterMandatory?: boolean): any {
    var response = sections;
    response = response.filter(item => {
      return this.utils.checkConditional(topic, item);
    });
    if (filterMandatory)
      response = response.filter(s => s['mandatory'] === "true" || s['mandatory'] === true);
    return response;
  }

}
