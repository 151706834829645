import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { visitType, apptFlowType } from '@type';
import { environment } from '@env/environment';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';

@Component({
  selector: 'app-express-clinic-choose-slot',
  templateUrl: './express-clinic-choose-slot.component.html',
  styleUrls: ['./express-clinic-choose-slot.component.scss']
})
export class ExpressClinicChooseSlotComponent implements OnInit {
  @Input() _input: any;
  @Input() flowType: apptFlowType;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @ViewChild('vaadinDp') dateRange: ElementRef;
  
  public translateType = 'LOCALE';
  public availableSlots: Array<any>;
  public slotTimeDiff: number;
  public serviceDetails: Array<Object>;
  public totalServiceTime: any;
  public slotsNeeded: number;
  public availableSlotStartTime: Array<any>;
  public startIndex: number;
  public endIndex: number;
  public _startIndex: number;
  public _endIndex: number;
  public slotList: any;
  public selectedClinic: string;
  public availDateList: Array<any>;
  public curDate: string;
  public practitionerId: string;
  public facilityId: string;
  public slotsAvailToBook: Array<string>;
  public practDetails: object;
  public clinicMobileNumber: any;
  public position: object;
  public clinicLatLng: object;
  public currency_code: any;
  public hideApptDtl: boolean;
  public selectedDate: any = '';
  public selectedSlot: any = {};
  public selectedSlots: any = [];
  public slotInfoLabel: any = {}
  constructor(private user: UserService) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    this.availableSlots = new Array<any>();
    this.availableSlotStartTime = new Array<any>();
    this.availDateList = new Array<any>();
    this.slotsAvailToBook = new Array<string>();
    this.startIndex = 0;
    this._startIndex = 0;
    this.endIndex = 3;
    this._endIndex = 6;
    this.selectedClinic = '';
    this.curDate = this.user.getMoment().format('YYYY-MM-DD HH:mm');
    this.slotList = {
      dates: [],
      clinics: '',
      slots: {}
    };
  }

  ngOnInit() {
    console.log('input',this._input);
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.serviceDetails = this._input['in']['service']['services'];
    this.practDetails = this._input['in']['inputService']['practDetails'][0];
    this.practitionerId = this._input['in']['inputService']['practDetails'][0]['practitionerId'];
    this.facilityId = this._input['in']['facility']['facilityId'];
    this.totalServiceTime = moment.duration(this._input['in']['service']['totalTime'], 'minutes');
    this.slotInfoLabel = [{
      key: 'totalServiceTime',
      with: this.totalServiceTime.asMinutes()
    }]
    this.selectedClinic = this._input['in']['facility']['clinicCode'];
    //this.getAvailableSlots();
    this.initAvailDates(this.user.getMoment().format('YYYY-MM-DD'));
    this.clinicMobileNumber = this._input['in']['facility']['mobileNo'];
    this.position = this._input['in']['facility']['position'];
    this.clinicLatLng = this._input['in']['facility']['facilityLocation'];
    this.user.getConfigurationInfo.subscribe(data => {
      console.log("getupdate data", data);
      this.hideApptDtl = data['pxAppSettings'] && data['pxAppSettings']['hideApptDtlInSlotYn'] === 'Y';
    })
  }

  private initAvailDates(date) {
    let initDate = this.user.getMoment(date, 'YYYY-MM-DD');
    this.availDateList = [];
    while (this.availDateList.length !== 10) {
      this.availDateList.push(initDate.format('YYYY-MM-DD'))
      initDate = this.user.getMoment(initDate).add(1, 'day');
    }
    this.getSlotList(this.availDateList);
  }

  private getSlotList(dates: Array<string>) {
    this.user.getSlotList(this.practitionerId, this.facilityId, dates, null).subscribe(data => {
      let clinicObj = {};
      this.startIndex = 0;
      this._startIndex = 0;
      this.endIndex = 3;
      this._endIndex = 6;
      clinicObj[this.selectedClinic] = {};
      Object.entries(data.actualSlots).map(slot => {
        let date = slot[0];
        let s = [];
        if (dates.includes(date)) {
          let clinics = slot[1];
          let clinic = clinics[this.selectedClinic];
          if (clinic) {
            s = clinic.split(',');
          }
          let noonstarttime = date + ' 13:00';
          let evestarttime = date + '16:30';
          let mrng = [];
          let noon = [];
          let eve = [];
          console.log("slots from backend->",s, date);
          this.slotsAvailToBook =[];
          if (s.length)
            this.slotsAbleToBook(s);
          s.forEach(x => {
            let selected = true;
            if (!this.slotsAvailToBook.includes(x))
              selected = false;
            let checkTime = date + ' ' + x;
            let facilityTimezone = this.user.getFacilityTimezone(this.facilityId);
            let checkTimeMoment = moment(checkTime, 'YYYY-MM-DD HH:mm').tz(facilityTimezone, true)
            let momentVar = moment().tz(facilityTimezone);
            if (checkTimeMoment.isSameOrAfter(momentVar)) {
              let noondifftime = checkTimeMoment.diff(this.user.getMoment(noonstarttime, 'YYYY-MM-DD HH:mm'));
              let evedifftime = checkTimeMoment.diff(this.user.getMoment(evestarttime, 'YYYY-MM-DD HH:mm'));
              if (noondifftime < 0) {
                mrng.push({ isSelectable: selected, slot: x.split('-')[0], '_icon': 'assets/icons/slot/morning.svg' });
              } else if (evedifftime < 0) {
                noon.push({ isSelectable: selected, slot: x.split('-')[0], '_icon': 'assets/icons/slot/noon_.svg' });
              } else {
                eve.push({ isSelectable: selected, slot: x.split('-')[0], '_icon': 'assets/icons/slot/evening.svg' });
              }
            }
          });
          clinicObj[this.selectedClinic][date] = { mrng: mrng, noon: noon, eve: eve }
        }
      });
      this.slotList = {
        dates: dates,
        clinics: this.selectedClinic,
        slots: clinicObj
      }
      console.log("use this to show->", this.slotList);
      this.selectedDate = this.slotList.dates[0];
      if(this.slotList['slots']?.[this.selectedClinic]?.[this.selectedDate]) {
        this.selectedSlots = [
          ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['mrng'],
          ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['noon'],
          ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['eve']
        ];
      }
      if(this.selectedSlots.length > 0)
        this.selectedSlot = this.selectedSlots[0];
    });
  }

  public slotsAbleToBook(slotsList) {
    this.findSlotTimeDifference(slotsList);
    let slotcount = 1;
    this.slotsNeeded = Math.ceil((this.totalServiceTime.asMinutes() / this.slotTimeDiff));
    console.log(" slot check->",this.totalServiceTime.asMinutes(),this.slotTimeDiff,this.slotsNeeded);
    if (this.totalServiceTime.asMinutes() > this.slotTimeDiff) {
      for (var i = 0; i < slotsList.length; i++) {
        var prev = slotsList[i].split('-')[1];
        slotcount = 1;
        for (var j = i + 1; j < slotsList.length; j++) {
          if (slotsList[j].split('-')[0] !== prev) {
            slotcount = 0;
            break;
          } else {
            slotcount++
          }
          if (slotcount == this.slotsNeeded)
            break
          prev = slotsList[j].split('-')[1];
        }
        if (slotcount >= this.slotsNeeded)
          this.slotsAvailToBook.push(slotsList[i]);
      }
    } else {
      this.slotsAvailToBook = slotsList;
    }
    console.log()
  }

  public findSlotTimeDifference(slotsList) {
    let times = slotsList[0].split('-');
    let startTime = this.user.getMoment(times[0], "HH:mm");
    let endTime = this.user.getMoment(times[1], "HH:mm");
    this.slotTimeDiff = endTime.diff(startTime, 'minutes');
  }

  public changeStart(count) {
    if ((this.startIndex <= 0 && count < 0) || (this.endIndex >= this.slotList.dates.length && count > 0)) {
      return;
    } else {
      this.startIndex += count;
      this.endIndex += count;
    }
  }

  public _changeStart(count) {
    if ((this._startIndex <= 0 && count < 0) || (this._endIndex >= this.slotList.dates.length && count > 0)) {
      return;
    } else {
      this._startIndex += count;
      this._endIndex += count;
    }
  }

  public navigateUser() {
    this.user.openLocation("https://maps.google.com/?saddr=" + this.position['lat'] + "," + this.position['lng'] + "&daddr="+ this.clinicLatLng['lat'] + "," + this.clinicLatLng['lng']);
  }

  public call(){
    this.user.openLocation("tel:" + this.clinicMobileNumber);
  }

  public completeSelection(date, slot, selectedClinic) {
    let fromTime = this.user.getMoment(slot['slot'], 'LT').locale(this.user.currentLocale).format('LT').toLowerCase();
    let toTime = this.user.getMoment(slot['slot'], 'LT').add(this.totalServiceTime.asMinutes(), 'm').locale(this.user.currentLocale).format('LT').toLowerCase();
    let obj = {
      name:  `${fromTime} - ${toTime}`,
      date: date,
      slot: slot,
      fromTime: fromTime,
      toTime: toTime,
      fromDate: `${date} ${fromTime}`,
      toDate: `${date} ${toTime}`,
      clinicCode: selectedClinic,
      slotsNeeded: this.slotsNeeded
    }
    this.complete.emit({ slot: obj });
  }

  public getServiceName(data, defaultName){
    let names = data['localNames'] ? data['localNames']['names'] : null;
    if (!names || names.length == 0) {
      return defaultName ? defaultName : '';
    } else {
        let t = names.find(name => {
            return name.locale == this.user.currentLanguage
        })
        if (t) {
            return t.name;
        } else {
            return names[0].name;
        }
    }
  }

  public openPicker() {
    this.dateRange.nativeElement.click();
  }

  public vaadinChange(event) {
    if (!event || !event['target'] || !event['target']['__data'] || !event['target']['__data'].value) return;
    this.initAvailDates(event['target']['__data'].value);
  }

  public onDateSelect(date) {
    this.selectedDate = date;
    this.selectedSlots = [
      ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['mrng'],
      ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['noon'],
      ...this.slotList['slots'][this.selectedClinic][this.selectedDate]['eve']
    ];
    const selectedIndex = this.availDateList.indexOf(this.selectedDate);
    let diff = 0;
    diff = this._endIndex - selectedIndex;
    if (diff < 2 &&
      (this._endIndex < this.availDateList.length) &&
      (this._startIndex < this.availDateList.length - 6)
    ) {
      this._startIndex++;
      this._endIndex++;
    } else if (diff > 2 &&
      (this._endIndex > 6) &&
      (this._startIndex > 0)
    ) {
      this._startIndex--;
      this._endIndex--;
    }
  }
}
