import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFSPComponent } from './search-f-s-p.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PractitionerAvatarModule } from '../practitioner-avatar/practitioner-avatar.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';



@NgModule({
  declarations: [
    SearchFSPComponent
  ],
  imports: [
    CommonModule, FormsModule, PipesModule, PractitionerAvatarModule,InfiniteScrollModule,
  ],
  exports:[SearchFSPComponent]
})
export class SearchFSPModule { }
