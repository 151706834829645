import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskAssessmentListsComponent } from './task-assessment-lists.component';
import { PipesModule } from '../pipes/pipes.module';
import { PercentageCircleModule } from '../percentage-circle/percentage-circle.module';
import { SpinnerModule } from '../spinner/spinner.module';



@NgModule({
  declarations: [
    TaskAssessmentListsComponent
  ],
  imports: [
    CommonModule, PipesModule, PercentageCircleModule, SpinnerModule, 
  ],
  exports: [
    TaskAssessmentListsComponent
  ]
})
export class TaskAssessmentListsModule { }
