import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-graph',
  templateUrl: './report-graph.component.html',
  styleUrls: ['./report-graph.component.scss']
})
export class ReportGraphComponent {
  @Input() reportEvents:any;
  
}
