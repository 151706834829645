import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PackageService } from '../services/package.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {

  @Input() linkedPatients;
  @Input() _selectedPatient;
  @Input() selectedPkg;
  @Input() source: string;
  @Input() packageFacilityId:any;
  @Input() ageGenderRestrictions:any;
  @Output() selcetedPatient: EventEmitter<any>;
  public selectedPat : any;
  public packages: any;

  constructor(private packageService: PackageService) { 
    this.selcetedPatient = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.selectedPat = this._selectedPatient || this.linkedPatients[0];
    console.log("==== selectedPat ====", this.selectedPat);
    if(this.selectedPkg)
      this.packages=this.packageService.getSubscribedPackages();
    this.setPatientList();
  }

  choosePatient(pat){
    this.selectedPat = pat
    if(pat['_hidePatient'] || pat['disablePatient']){
      this.selcetedPatient.emit(null);
    }else{
      this.selcetedPatient.emit(pat);
    }
  }

  public setPatientList(){
    if(this.source=='PACKAGE'){
      this.linkedPatients.forEach((p)=>{
        this.packages.forEach((pac)=>{
          if(p['uhid']==pac['patientId'] && pac['packageCode']==this.selectedPkg['code'] && pac['facilityId']==this.packageFacilityId)
            p['_hidePatient']=true;
        })
      })
      let tmp,tmpHide=[],tmpNormal=[];
      tmp= _.values(_.groupBy(this.linkedPatients, (p) => p['_hidePatient']))
      if (tmp && tmp[0] && tmp[1]) {
        if (tmp[0]['_hidePatient']) {
          tmpHide.push(tmp[0])
          tmpNormal.push(tmp[1])
        }
        else
          if (!tmp[0]['_hidePatient']) {
            tmpNormal.push(tmp[0])
            tmpHide.push(tmp[1])
          }
        this.linkedPatients = tmpNormal.concat(tmpHide);
      } else {
        this.linkedPatients = tmp;
      }
      console.log("==== linkedPatients ====", this.linkedPatients);
    } else if (this.source == 'ONSPOT') {
      if (this.ageGenderRestrictions && this.ageGenderRestrictions.check) {
        this.restrictionLogic(this.linkedPatients, this.ageGenderRestrictions.patients);
      }
    }
  }
  ngOnChanges(){
    this.selectedPat = this._selectedPatient || this.linkedPatients[0];
  }

  private restrictionLogic(patients, restrictedList) {
    this.linkedPatients = patients.map(pat => {
      pat.ageRestricted = false;
      pat.genderRestricted = false;
      pat.ageGenderRestricted = false;
      pat.disablePatient = false;
      pat.order = 0
      let listPat = restrictedList.find(r => r.patientId == pat.uhid);
      if (listPat) {
        pat.ageRestricted = listPat.ageRestricted
        pat.genderRestricted = listPat.genderRestricted
        pat.ageGenderRestricted = listPat.ageRestricted && listPat.genderRestricted
        pat.disablePatient = listPat.ageRestricted || listPat.genderRestricted;
        pat.order = listPat.ageRestricted || listPat.genderRestricted ? 1 : 0
      }
      return pat;
    }).sort((a, b) => Number(a.order) - Number(b.order))
    console.log('this.linkedPatients', this.linkedPatients, restrictedList)
    this.selectedPat = this.linkedPatients[0];
  }
}
