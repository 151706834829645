import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrDocumentUploadComponent } from './hr-document-upload.component';
import { PipesModule } from '../pipes/pipes.module';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HrPatientSelectModule } from '../hr-patient-select/hr-patient-select.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';


@NgModule({
  declarations: [HrDocumentUploadComponent],
  imports: [
    CommonModule, PipesModule, CustomInputGroupModule, FormsModule, ReactiveFormsModule, HrPatientSelectModule,
    FormInputErrorModule
  ], exports: [
    HrDocumentUploadComponent
  ]
})
export class HrDocumentUploadModule { }
