<div id="contact-us" class="right-navigation showModal" *ngIf="isContactUs">
  <!-- <div (click)="closeModal()" class="empty"></div> -->
  <div class="component">
    <div class="header border-bottom" [ngClass]="{'hide': selectedHospital}">
      <div *ngIf="!contactUs" class="font-primary-subtitle bold contact-us-header">
        {{'About_us' | translate}}
      </div>
      <div *ngIf="contactUs" class="font-primary-subtitle bold contact-us-header">
        {{'Contact_us' | translate}}
      </div>
      <div (click)="closeModal()" class="float-right closeicon pointer">
        <img src="./assets/icons/close_grey.svg">
      </div>
    </div>

    <div class="content">
      <div class="profile-section" *ngIf="!contactUs">
        <div class="options" [ngClass]="{'hide': selectedHospital}">
          <div class="card row cursor-pointer about-nahdi" (click)="aboutNahdi()"
            [ngClass]="{active: selectedAboutNahdi}">
            <div class="card-content">
              <div class="title">
                <img class="arrow-icon" src="./assets/icons/contact-us/nahdi_small_logo.svg">
                <a class="touch-name mobile" target="_blank">
                  {{'About_Nahdi_Care_Clinics' | translate }}
                </a>
              </div>
            </div>
            <div class="image">
              <img class="arrow-icon" src="./assets/icons/contact-us/arrow.svg">
            </div>
          </div>
          <label class="content-label-name">{{'our_locations' | translate }}</label>
          <div *ngFor="let hospital of hospitals" class="clinic-details-wrapper">
            <div class="card row cursor-pointer" (click)="onSelectHospital(hospital)"
              [ngClass]="{active: hospital===selectedHospital}">
              <div class="card-content">
                <ng-container *ngFor="let fn of hospital['facilityName']">
                  <ng-container *ngIf="fn['locale'] === currentLang">
                    <div class="title">
                      {{ fn['name'] ?? '-' | translate}}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngFor="let fa of hospital['facilityAddress']">
                  <ng-container *ngIf="fa['locale'] === currentLang">
                    <div class="address">
                      {{ fa['text'] ?? '-' | translate}}
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="image">
                <img class="arrow-icon" src="./assets/icons/contact-us/arrow.svg">
              </div>
            </div>


            <div class="sub-card-wrapper">
              <div class="sub-card row cursor-pointer" [ngClass]="{active: hospital===selectedHospital}"
                (click)="onSelectHospital(hospital)">

                <div class="card-content">
                  <ng-container *ngFor="let fn of hospital['facilityName']">
                    <ng-container *ngIf="fn['locale'] === currentLang">
                      <div class="title">
                        {{ fn['name'] ?? '-' | translate}}
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngFor="let fa of hospital['facilityAddress']">
                    <ng-container *ngIf="fa['locale'] === currentLang">
                      <div class="address">
                        {{ fa['text'] ?? '-' | translate}}
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="image">
                  <div class="image-wrapper">
                    <img class="location-icon" src="./assets/icons/contact-us/location.svg">
                  </div>
                  <div class="image-wrapper">
                    <img class="call-icon" src="./assets/icons/contact-us/call.svg">
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- <div class="subname row cursor-pointer">
              <div class="subname-card-content">
                <div class="title">
                  <span class="touch-name">Jeddah</span>
                </div>
              </div>
              <div class="image">
                <img class="arrow-icon" src="./assets/icons/contact-us/next_arrow_white.svg">
              </div>
            </div>

            <div class="card row cursor-pointer">
              <div class="other-card-content">
                <div class="title">
                  <span class="touch-name">Mecca</span>
                </div>
              </div>
              <div class="image">
                <span class="coming-soon-text">Coming Soon</span>
              </div>
            </div> -->

          <!-- <label class="content-label-touch-name"> {{'Get_in_touch_with_us' | translate }}</label>

            <div class="touch-with-us-wrapper">
              <div class="touch-with-us row cursor-pointer" *ngFor="let item of GetInTouchWithOptions">
                <div class="card-content">
                  <div class="title">
                    <img class="arrow-icon" [src]="item.icon">
                    <a *ngIf="!item['isMail']" class="touch-name mobile"
                    [href]="'tel:' + item.name" target="_blank">
                      {{item.name ?? '-' | translate}}
                    </a>
                    <a *ngIf="item['isMail']" class="touch-name mail"
                      [href]="'mailto:' + item.name">
                      {{item.name ?? '-' | translate}}
                    </a>
                  </div>
                </div>
                <div class="image">
                  <img class="arrow-icon" src="./assets/icons/contact-us/arrow.svg">
                </div>
              </div>
            </div> -->

          <!-- <label class="content-label-touch-name"> {{'Submit_an_enquiry_form' | translate }}</label>
            <div class="enquiry-form-wrapper">
              <div class="enquiry-form row cursor-pointer" *ngFor="let item of submitEnquiryForm">
                <div class="card-content">
                  <div class="title" (click)="showEnquiry=true">
                    <img class="arrow-icon" [src]="item.icon">
                    <span class="touch-name">{{item.name ?? '-' | translate}}</span>
                  </div>
                </div>
                <div class="image">
                  <img class="arrow-icon" src="./assets/icons/contact-us/arrow.svg">
                </div>
              </div>
            </div> -->
        </div>

        <div class="components" *ngIf="selectedHospital">
          <app-contact-us-details [selectedHospital]="selectedHospital" [currentLang]="currentLang" (close)="reset()">
          </app-contact-us-details>
        </div>

        <div class="components-about-nahdi" *ngIf="selectedAboutNahdi">
          <div class="about-section">
            <div class="about-section__content">
              <div class="title-label">
                <img src="./assets/images/signin/nahdi_logo.png" alt="">
              </div>
              <p>{{'about_nahdi_care_clinics_content' | translate}}</p>
            </div>
          </div>
        </div>

      </div>

      <div class="enquiry-section" *ngIf="contactUs">
        <div class="enquiry-section__content">
          <div class="title-label">
            {{'Submit_an_enquiry_form' | translate}}
          </div>
          <div class="user-details">
            <div class="mobile">
              <div class="mobile__label">{{'Mobile_number' | translate}}</div>
              <div class="mobile__input">
                <input type="text" class="mobile__text" (input)="checkIfPhoneNumber($event.target.value)" [(ngModel)]="userMobile">
              </div>
              <p class="error-msg" *ngIf="hasPhError">{{'Enter_valid_mobile_number' | translate}}</p>
            </div>
            <div class="mail">
              <div class="mail__label">{{'E-mail' | translate}}</div>
              <div class="mail__input">
                <input type="text" class="mail__text" (input)="emailValidation($event.target.value)" [(ngModel)]="userMail">
              </div>
              <p class="error-msg" *ngIf="emailvalidErrorMsg">{{'Enter_valid_mail_id' | translate}}</p>
            </div>
          </div>
          <div class="query">
            <div class="query__label">{{'Explain_your_query' | translate}}</div>
            <div class="query__input">
              <textarea rows="4" type="text" class="query__text" [(ngModel)]="userQuery"
                placeholder="Enter here"></textarea>
            </div>
          </div>
          <div class="enquiry-section__footer">
            <button class="primary-button" (click)="submitEnquiry()" [disabled]="(userQuery.length===0 && !emailvalidErrorMsg && !hasPhError)">
              {{'Submit' | translate}}
            </button>
          </div>
          <div class="get-in-touch__content">
            <div class="title-label">
              {{'Get_in_touch_with_us' | translate}}
            </div>
            <div>
              <div class="row">
                <div class="column">
                  <div class="contact-card">
                    <a class="touch-name mail" [href]="'mailto:' + mailId" target="_blank">
                      <div class="card-icon">
                        <img src="assets/icons/contact-us/mail_us.svg">
                      </div>
                      <p>{{'Mail_us' | translate}}</p>
                    </a>
                  </div>
                </div>

                <div class="column">
                  <div class="contact-card" (click)="callUs(mobileNo)">
                    <div class="card-icon">
                      <img src="assets/icons/contact-us/call_us.svg">
                    </div>
                    <p>{{'Call_us' | translate}}</p>
                  </div>
                </div>

                <div class="column">
                    <div class="contact-card" (click)="openWhatsApp(whatsAppLink)">
                      <div class="card-icon">
                        <img src="assets/icons/contact-us/whatsapp.svg">
                      </div>
                      <p>{{'WhatsApp' | translate}}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- <ng-template #currentComponents>
    <div [ngSwitch]="curAction" class="manage-address">

      <div *ngSwitchDefault>
        <app-contact-us-details [refresh]="refresh.asObservable()"></app-contact-us-details>
      </div>

      <div *ngSwitchCase="'first'">
        <app-contact-us-details (close)="reset()"></app-contact-us-details>
      </div>
    </div>
  </ng-template> -->
