import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APPTACTION, APPTFLAGS, APPTVIEWTYPE, CHECKINSTATUS, PERMISSIONSAVAILABLE } from '@type';
import { APPTCONST } from '@const';
import { UserService } from '../services/user.service';
import {AppointmentService} from '../services/appointment.service';
import { ModalService } from '../services/modal.service';


@Component({
  selector: 'app-upcoming-appt-preview',
  templateUrl: './upcoming-appt-preview.component.html',
  styleUrls: ['./upcoming-appt-preview.component.scss']
})
export class UpcomingApptPreviewComponent implements OnInit{

  @Input() alertInfo:any;
  @Input() readStatus:any;
  @Input() selectedAppointment: any;
  @Input() viewType: APPTVIEWTYPE;
  @Output() openAppointment: EventEmitter<any>;
  @Output() closeAppointment: EventEmitter<any>;
  @Output() deleteAlert: EventEmitter<any>;
  @Input() apptsType:any;
  @Input() cardsClass:string = '';
  public actionType: APPTACTION;
  public readonly APPTCONST= APPTCONST;

  public canCancelAppt: boolean = false;
  public canRescheduleAppt: boolean = false;
  public canCheckInVC: boolean = false;
  public canCheckInIP: boolean = false;
  public checkinStatus: CHECKINSTATUS = 'UNKNOWN';
  public canRejoin: boolean = true;
  public openApptDtl: boolean = false;
  public translateType = "LOCALE";
  public disablePayBeforeCheckinTime: any;

  constructor(private user: UserService, private appointmentService: AppointmentService, private modalService: ModalService) {
    this.openAppointment = new EventEmitter<any>();
    this.closeAppointment = new EventEmitter<any>();
    this.deleteAlert = new EventEmitter<any>();

  }

  ngOnInit(): void {
    this.viewType = 'MYALERT';
    this.processScheduleFlags()
  }

  public viewAppt(){
    this.openAppointment.emit(true);
    // this.openApptDtl = true;
    // this.modalService.open('calendar-appointment-details-modal');
  }

  closeApptDetail(){
    this.openApptDtl = false;
  }

  private processScheduleFlags() {
    const recievedFlags: APPTFLAGS = this.appointmentService.processScheduleFlag(this.selectedAppointment, this.viewType);

    console.log("=== recievedFlags ====", recievedFlags)
    // To have sync between appt page. Please dont remove this
    const mappedFlag: APPTFLAGS = {
      canCancelAppt: recievedFlags.canCancelAppt,
      canRescheduleAppt: recievedFlags.canRescheduleAppt,
      canCheckInVC: recievedFlags.canCheckInVC,
      canCheckInIP: recievedFlags.canCheckInIP,
      actionType: recievedFlags.actionType,
      checkinStatus: recievedFlags.checkinStatus,
      canRejoin: recievedFlags.canRejoin
    }
    this.canCancelAppt = mappedFlag.canCancelAppt;
    this.canRescheduleAppt = mappedFlag.canRescheduleAppt;
    if (this.selectedAppointment.appointmentType == 'VIDEO') {
      this.canCheckInVC = mappedFlag.canCheckInVC;
      this.actionType = mappedFlag.actionType;
      this.checkinStatus = mappedFlag.checkinStatus;
      this.canRejoin = mappedFlag.canRejoin;
    } else {
      this.canCheckInIP = mappedFlag.canCheckInIP;
      this.actionType = mappedFlag.actionType;
      this.checkinStatus = mappedFlag.checkinStatus;
    }
    console.log(this.canCheckInVC);
    console.log(this.actionType);
    
  }

  public deleteMYAlert(info){
    this.deleteAlert.emit(info)
  }

  public handleActions(type,event) {
    console.log('checkin event->', type, event);
    // switch (type) {
    //   case 'PAY':
    //     this.initPay(null);
    //     break;
    //   case 'CHECKIN':
    //     if (this.selectedAppointment.appointmentType == 'VIDEO') this.checkinVC();
    //     else this.checkinIV();
    //     break;
    //   case 'PAYCHECKIN':
    //     if (this.selectedAppointment.appointmentType == 'VIDEO') this.initPay('checkinVC');
    //     else this.initPay('checkinIV');
    //     break;
    // }
  }

}

