import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { CARDARRAY } from '@const';

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout.component.html',
  styleUrls: ['./payment-checkout.component.scss']
})
export class PaymentCheckoutComponent implements OnInit {
  @Input() showModalView: boolean = false;
  @Output() close: EventEmitter<any>;
  public lang: string;
  public appointment: any;
  public paymentInfo: any;
  public showPayment: boolean;
  public isLoading: boolean = false;
  public selectedPayment: 'STC' | 'CARD'
  public selectedPaymentCode: 'STC' | 'CARD'
  public selectedOption: Array<any> = []
  public readonly cardMapping = CARDARRAY

  public canPaySTC: boolean = true
  public canPayCard: boolean = true;
  public payableOption = [
    {
      selected: true,
      code: 'STC',
      callback: () => {
        this.setSelectedPayment('STC', true);
      },
      check: () => {
        if (this.appointment && this.appointment['saved_stc_details'] && this.appointment['saved_stc_details'].length) {
          this.setSelectedPayment('STC', true);
        } else {
          this.payNewSTC();
        }
      }
    },
    {
      selected: true,
      code: 'CARD',
      callback: () => {
        this.setSelectedPayment('CARD', true);
      },
      check: () => {
        if (this.appointment && this.appointment['saved_card_details'] && this.appointment['saved_card_details'].length) {
          this.setSelectedPayment('CARD', true);
        } else {
          this.payNewCard();
        }
      }
    }
  ]

  public defaultEmail: any;

  constructor(private paymentService: PaymentService, private cdf: ChangeDetectorRef, private user: UserService) {
    this.close = new EventEmitter<any>();
  }

  ngOnInit() {
    this.appointment = this.paymentService.getAppointmentInfo();
    this.paymentInfo = this.paymentService.getPaymentInfo();
    // setTimeout(_ => { 
      if(this.appointment['blockedExpiryDateTime']){
        let expiryDate = this.user.getMoment(this.appointment['blockedExpiryDateTime']).format('YYYY-MM-DD HH:mm:ss')
        let now = this.user.getMoment().format('YYYY-MM-DD HH:mm:ss')
        let diff = this.user.getMoment(expiryDate).diff(now, 'seconds')
        let minutes = Math.floor(diff / 60)
        let seconds = diff % 60;
        var self = this;
        countdown('clock', minutes, seconds,self);
      }else{
        setTimeout(function() {
          var x = document.getElementById("clock-con");
          x.style.display = "none";
        }, 1000)
      }
      // countdown('clock', , 0);
    // }, 500)
    this.user.getUpdateInfo('configuration').subscribe(data => { 
      let paymentGateway = data.features['paymentGateway'];
      if (paymentGateway.checkApplicablePaymentTypes === true) {
        this.canPaySTC = paymentGateway.canPaySTC === true
        this.payableOption[0].selected = this.canPaySTC
        this.canPayCard = paymentGateway.canPayCard === true
        this.payableOption[1].selected = this.canPayCard
      }
    })
    let filtered = this.payableOption.filter(p => p.selected)
    if (filtered && filtered.length == 1) {
      filtered[0].callback()
    }
    this.defaultEmail = this.user.getPersonEmail();
    if (this.appointment.email && !this.defaultEmail) {
      this.defaultEmail = this.appointment.email;
    }
    this.lang = this.user.currentLanguage;
    if (!this.appointment || !this.paymentInfo) {
      // this.location.back();
      this.showPayment = false;
    } else {
      this.showPayment = true;
    }
  }

  public isCardExpired(date: string) {
    return this.user.getMoment(this.user.getMoment(date, 'YYMM').format()).isBefore(this.user.getMoment().format(), 'month')
  }

  public selectCard(card: Object) {
    if (this.isCardExpired(card['expiryDate'])) return;
    this.appointment['saved_card_token'] = card['savedCardToken'];
    document['customerData'].token_name.value = card['savedCardToken'];
    console.log('customerData------>', document['customerData'])
    document['customerData'].submit();
  }

  public payWithSTC() {
    let walletName = 'STCPAY'
    this.appointment['digital_wallet'] = walletName;
    document['customerData'].digital_wallet.value = walletName;
    document['customerData'].submit();
  }
  public closeModal() {
    this.close.emit();
  }

  public setSelectedPayment(type: 'STC' | 'CARD', fromCallback:boolean = false) {
    if (this.isLoading) return
    this.selectedPaymentCode = type
    this.cdf.detectChanges()
    if (!fromCallback) {
      let option = this.payableOption.find(t => t.code == type);
      if (option) {
        option.check()
      } else {
        this.selectedPayment = type
      }
    } else {
      this.selectedPayment = type
    }
  }

  public payExistingCard(card) {
    if (this.isCardExpired(card['expiryDate'])) return;
    if (this.isLoading) return;
    this.appointment['saved_card_token'] = card['savedCardToken'];
    document['customerData'].token_name.value = card['savedCardToken'];
    this.submitForm()
    this.setLoader(['card', card['savedCardToken']])
  }

  public payNewCard() {
    if (this.isLoading) return;
    this.submitForm()
    this.setLoader(['card', 'UNSET'])
  }

  public payExistingSTC(stc) {
    if (this.isLoading) return;
    this.appointment['saved_card_token'] = stc['savedCardToken'];
    document['customerData'].token_name.value = stc['savedCardToken'];
    document['customerData'].digital_wallet.value = 'STCPAY';
    this.submitForm()
    this.setLoader(['stc', stc['savedCardToken']])

  }

  public payNewSTC() {
    if (this.isLoading) return;
    document['customerData'].digital_wallet.value = 'STCPAY';
    this.submitForm()
    this.setLoader(['stc', 'UNSET'])

  }
  private setLoader(flag) {
    this.isLoading = true
    this.selectedOption = flag;
  }

  private submitForm() {
    // setTimeout(_ => {
    //   this.isLoading = false
    //   this.selectedOption = [];
    // }, 5000)
    // return;
    document['customerData'].submit();

  }
}


function countdown(element, minutes, seconds,self) {
  // set time for the particular countdown
  console.log('countdown', minutes, seconds)
  var time = minutes*60 + seconds;
  var interval = setInterval(function() {
      var el = document.getElementById(element);
      // if the time is 0 then end the counter
      if(time == 0) {
          // setTimeout(function() {
          //     el.innerHTML = "Move on to next date...";
          // }, 10);
  
  
          clearInterval(interval);
  
          // setTimeout(function() {
          //     countdown('clock', 0, 5);
          // }, 2000);
      }
      var minutes:any = Math.floor( time / 60 );
      if (minutes < 10) minutes = "0" + minutes;
      var seconds:any = time % 60;
      if (seconds < 10) seconds = "0" + seconds; 
      var text = minutes + ':' + seconds;
      if(el)
      el.innerHTML = text;
      time--;
  }, 1000);
  console.log('interval', interval)
  if(interval)
    self.user.setPaymentIntervalForReference(interval)
}