import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { HealthRecordsService } from '../services/health-records.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-hr-visit-history',
  templateUrl: './hr-visit-history.component.html',
  styleUrls: ['./hr-visit-history.component.scss']
})
export class HrVisitHistoryComponent {
  @Output() chooseVisit: EventEmitter<any>;
  @Output() getFeedback: EventEmitter<any>;
  @Input() selectedVisit: any;
  selectedForTranscript: any;
  allVisits: any[];
  @Input() set selectedPatient(value: any) {
    this._selectedPatient = value;
    this.getVisitHistory();
  }
  @Input() visitId: any;
  // public visitHistory: Array<any>;
  public visitHistory: any;
  private _selectedPatient: any;
  constructor(
    private user: UserService,
    private hrService: HealthRecordsService
  ) {
    this.chooseVisit = new EventEmitter<any>();
    this.getFeedback = new EventEmitter<any>();
  }

  ngOnInit(): void {
    // console.info("_selectedPatient", this._selectedPatient, this.visitId);
  }

  // private getVisitHistory() {
  //   if (this._selectedPatient) {
  //     this.user.getVisitHistory(this._selectedPatient.uhid).subscribe(data => {
  //       this.visitHistory = data.visits.reduce((r, a) => {
  //         let date = this.user.getMoment(a.admnDate).format('YYYY-MM-DD');
  //         r[date] = [...r[date] || [], a];
  //         return r
  //       }, {});
  //     })
  //   }
  // }
  private getVisitHistory() {
    if (this._selectedPatient) {
      this.user
        .getVisitHistory(this._selectedPatient.uhid)
        .subscribe((data) => {
          let unordered = data.visits.reverse().reduce((r, a) => {
            let date = this.user
              .getMoment(a.admnDate, "YYYY-MM-DD HH:mm")
              .format("YYYY-MM-DD");
            r[date] = [...(r[date] || []), a];
            return r;
          }, {});
          const ordered = {};
          let k = Object.keys(unordered).sort((a, b) => {
            return (
              this.user.getMoment(b, "YYYY-MM-DD").valueOf() -
              this.user.getMoment(a, "YYYY-MM-DD").valueOf()
            );
          });

          k.forEach(function (key, index) {
            ordered[index] = [...unordered[key]];
          });
          this.visitHistory = ordered;
          this.allVisits = _.orderBy(
            data.visits,
            (a) =>
              this.user.getMoment(a.admnDate, "YYYY-MM-DD HH:mm").valueOf(),
            "desc"
          );
          if(this.visitId)
            this.selectVisit(this.allVisits)
        });
    }
  }

  public updateSelectedVisit(visit, tab) {
    this.chooseVisit.emit({ visit, tab });
  }
  public selectVisit(visits) {
    // console.info('selectVisit', visits, this.visitId)
    let result = visits.find(item => item.encounterId == this.visitId);
    this.updateSelectedVisit(result, 'REPORTS');
  }
  public openFeedback(visit) {
    this.getFeedback.emit(visit);
  }

  public openTranscript(visit) {
    this.selectedForTranscript = visit;
    this.selectedForTranscript['personId'] = this._selectedPatient['personid'];
  }
}
