import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApptDtlCardComponent } from './appt-dtl-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
import { ApptTimeModule } from '../components/appt-time/appt-time.module';

@NgModule({
  declarations: [
    ApptDtlCardComponent
  ],
  exports:[ApptDtlCardComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, PipesModule,
    PersonAvatarModule, PractitionerAvatarModule, ApptTimeModule
  ]
})
export class ApptDtlCardModule { }
