import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookappointmentDirective } from './bookappointment.directive';
import { RemoveFeatureOffDirective } from './remove-feature-off.directive';
import { RemoveFeatureOnDirective } from './remove-feature-on.directive';
import { CssEllipsisDirective } from './css-ellipsis.directive';
import { PopoverDirective } from './popover.directive';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  declarations: [BookappointmentDirective, RemoveFeatureOffDirective, RemoveFeatureOnDirective, CssEllipsisDirective, PopoverDirective, ClickOutsideDirective],
  imports: [
    CommonModule
  ],
  exports: [
    BookappointmentDirective, RemoveFeatureOffDirective, RemoveFeatureOnDirective, CssEllipsisDirective, PopoverDirective, ClickOutsideDirective
  ]
})
export class DirectiveModule { }
