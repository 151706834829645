import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '@env/environment';


import { FT_APP_ENABLED, FT_APP_KEEP_SIGNIN, FT_APP_SIGNUP, FT_APP_RESET_PASSWORD, FT_APP_BOOK_DIRECT_APPT, FT_APP_BOOK_VC_APPT, FT_APP_MANAGE_EXIST_IDENTITY, FT_APP_MANAGE_ACCOUNT, FT_APP_BOOK_APPT, FT_APP_HOME_VIEW_APPT, FT_APP_HOME_VIEW_TODO, FT_APP_HOME_VIEW_CARE_PLAN, FT_APP_HOME_VIEW_REMAINDERS, FT_APP_HOME_VIEW_RECENT_VISITS, FT_APP_HOME_VIEW_EXIST_UNLINKED, FT_APP_HOME_VIEW_SLIDER, FT_APP_HOME_VIEW_COMMON_FEEDBACK, FT_APP_REGISTER_FAMILY_MEMBER, FT_APP_FIND_FAMILY_MEMBER, FT_APP_REGISTER_UNLINK_MEMBER, FT_APP_REGISTER_LINK_MEMBER, FT_APP_HR_VW_VST_HIS, FT_APP_HR_REPORT, FT_APP_HR_PRESCRIPTION, FT_APP_HR_BILLS, FT_APP_HR_VISIT_SECTION, FT_APP_HR_VISIT_FEEDBACK, FT_APP_HR_UPLOADDOCS, FT_APP_HR_DOWNLOADDOCS, FT_APP_HR_DELETEDOCS, FT_APP_TAB_HR, FT_APP_TAB_CALENDAR, FT_UNAUTHORIZED, FT_APP_TAB_BILLING, FT_APP_HEALTH_PACKAGE, FT_APP_EDIT_LINK_MEMBER, FT_APP_HOMESERVICES, FT_APP_CXTWITH_PIN, FT_APP_LOGWITH_OTP, FT_APP_CXTWITH_PRECHECK, FT_APP_TAB_HOME, LOGIN_CONTEXT, FT_APP_OLY_CMN_VIEW, FT_SSO_REGPAT_ON_SIGNUP, SHOW_APP_HEADER, SHOW_APP_FOOTER, FT_APP_BOOKWITHRESTRICTIONS, FT_APP_EXTIDLOGIN, FT_APP_HOFLINKDIFFIDENTITY, FT_APP_LINKWITHOUTRELATION, FT_APP_LOGINCAPTCHA, FT_APP_NONHOFLINKDIFFIDENTITY, FT_APP_SIGNUPCAPTCHA, FT_APP_UNLINKDIFFIDENTITY, FT_APP_UNLINKPROVIDEDIDENTITY, FT_APP_UNLINKSAMEIDENTITY , FT_APP_HR_SUMMARY } from '@const';
import { APPTYPE } from '@type';




@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private _featureFlags: Array<string> = []; // A list of all features turned ON
  private _initialized = false;
  private checkHOF: boolean = false;
  private isHOF: boolean = false;

  public rxjsFeatureInitSubject: Subject<any>;

  constructor() { 
    this.rxjsFeatureInitSubject = new Subject<any>();
    this._featureFlags[SHOW_APP_HEADER] = true;
    this._featureFlags[SHOW_APP_FOOTER] = true;
  }

  featureOff(featureName: string) { 
    return !this.featureOn(featureName);
  }

  featureOn(featureName: string) {
    if (!featureName || !this.initialized) {
      return true;
    }
    // Find the feature flag that is turned on
    let checkHOFForFeatures = ['FT_APP_HOME_VIEW_EXIST_UNLINKED'];
    if (checkHOFForFeatures.includes(featureName) && this.checkHOF) {
      return this._featureFlags && this._featureFlags[featureName] && this.isHOF;
    } else {
      return this._featureFlags && this._featureFlags[featureName];
    }
    // if feature not found, default to turned off
  }

  get initialized() {
    return this._initialized;
  }

  public setHOF(value) {
    this.isHOF = value;
  }

  public removeHOF() {
    this.isHOF = undefined;
  }

  public getIsHOF() {
    return this.isHOF;
  }

  // This method is called once and a list of features is stored in memory
  initialize(object) {
    const APP_FEATURES = object['configuration']['features'];
    const PX_APP_SETTINGS = object['configuration']['pxAppSettings'];
    const FACILITY_LIST = object['facilities']['facilities'];

    this._featureFlags[FT_APP_ENABLED] = APP_FEATURES['portal_enabled'] || environment['APP_ENABLED_DEFAULT'];
    this._featureFlags[FT_UNAUTHORIZED] = !this._featureFlags[FT_APP_ENABLED];
    //COMMON
    this._featureFlags[FT_APP_BOOK_VC_APPT] = false;
    this._featureFlags[FT_APP_HEALTH_PACKAGE] = true;
    this._featureFlags[FT_APP_HOMESERVICES] = false;
    this._featureFlags[FT_APP_BOOK_DIRECT_APPT] = true;
    this._featureFlags[FT_APP_MANAGE_EXIST_IDENTITY] = true;
    this._featureFlags[FT_APP_MANAGE_ACCOUNT] = true;
    this._featureFlags[FT_APP_REGISTER_FAMILY_MEMBER] = true;
    //DEFAULT
    this._featureFlags[FT_APP_FIND_FAMILY_MEMBER] = true;
    this._featureFlags[FT_APP_LINKWITHOUTRELATION] = false;
    //LOGIN
    this._featureFlags[FT_APP_KEEP_SIGNIN] = true;
    this._featureFlags[FT_APP_SIGNUP] = true;
    this._featureFlags[FT_APP_RESET_PASSWORD] = true;
    this._featureFlags[FT_APP_CXTWITH_PIN] = true;
    this._featureFlags[FT_APP_LOGWITH_OTP] = true;
    this._featureFlags[FT_APP_CXTWITH_PRECHECK] = true;
    this._featureFlags[FT_APP_EXTIDLOGIN] = false;
    this._featureFlags[FT_APP_LOGINCAPTCHA] = true;
    this._featureFlags[FT_APP_SIGNUPCAPTCHA] = false;
    //HOME PAGE
    this._featureFlags[FT_APP_HOME_VIEW_SLIDER] = true;
    this._featureFlags[FT_APP_HOME_VIEW_REMAINDERS] = true;
    this._featureFlags[FT_APP_HOME_VIEW_EXIST_UNLINKED] = true;
    //HOME PAGE DEFAULT
    this._featureFlags[FT_APP_HOME_VIEW_TODO] = false;
    this._featureFlags[FT_APP_HOME_VIEW_CARE_PLAN] = false;
    this._featureFlags[FT_APP_HOME_VIEW_RECENT_VISITS] = false;
    this._featureFlags[FT_APP_HOME_VIEW_COMMON_FEEDBACK] = false;
    //REGISTER PATIENT
    this._featureFlags[FT_APP_REGISTER_UNLINK_MEMBER] = true;
    this._featureFlags[FT_APP_REGISTER_LINK_MEMBER] = true;
    this._featureFlags[FT_APP_EDIT_LINK_MEMBER] = false;
    this._featureFlags[FT_APP_UNLINKSAMEIDENTITY] = true;
    this._featureFlags[FT_APP_UNLINKDIFFIDENTITY] = true;
    this._featureFlags[FT_APP_UNLINKPROVIDEDIDENTITY] = true;
    this._featureFlags[FT_APP_HOFLINKDIFFIDENTITY] = true;
    this._featureFlags[FT_APP_NONHOFLINKDIFFIDENTITY] = true;
    //HEALTH RECORDS
    this._featureFlags[FT_APP_HR_VW_VST_HIS] = true;
    this._featureFlags[FT_APP_HR_REPORT] = true;
    this._featureFlags[FT_APP_HR_PRESCRIPTION] = true;
    this._featureFlags[FT_APP_HR_BILLS] = true;
    this._featureFlags[FT_APP_HR_VISIT_FEEDBACK] = true;
    this._featureFlags[FT_APP_HR_SUMMARY] = true;

    this._featureFlags[FT_APP_HR_UPLOADDOCS] = APP_FEATURES['documents']?APP_FEATURES['documents']['upload']:false;
    this._featureFlags[FT_APP_HR_DOWNLOADDOCS] = APP_FEATURES['documents']?APP_FEATURES['documents']['download']:false;
    this._featureFlags[FT_APP_HR_DELETEDOCS] = true;

    this._featureFlags[FT_APP_TAB_CALENDAR] = true;

    this._featureFlags[FT_APP_TAB_BILLING] = false;
    
    this._featureFlags[FT_APP_TAB_HOME] = true;
    this._featureFlags[FT_SSO_REGPAT_ON_SIGNUP] = true;

    this._featureFlags[FT_APP_BOOKWITHRESTRICTIONS] = false;


    // //MODIFIED
    if (FACILITY_LIST) {
      this._featureFlags[FT_APP_BOOK_VC_APPT] = FACILITY_LIST.reduce((original, facility) => {
        if (facility && facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['videoConsult'] && facility['facilityControls']['schedulingRules']['videoConsult']['enabled']) {
          return true;
        } else return original
      }, false);
    }


    if (PX_APP_SETTINGS) {
      if (PX_APP_SETTINGS['feedbackByVisitsYn'] != 'Y') {
        this._featureFlags[FT_APP_HR_VISIT_FEEDBACK] = false;
      }
      if (PX_APP_SETTINGS['hideAppointmentReminder']) {
        this._featureFlags[FT_APP_HOME_VIEW_REMAINDERS] = false;
      }
      if (PX_APP_SETTINGS['hideVisitTab']) {
        this._featureFlags[FT_APP_HR_VW_VST_HIS] = false;
      }
      if (PX_APP_SETTINGS['hideReportsTab']) {
        this._featureFlags[FT_APP_HR_REPORT] = false;
      }
      if (PX_APP_SETTINGS['hidePrescribTab']) {
        this._featureFlags[FT_APP_HR_PRESCRIPTION] = false;
      }
      if (PX_APP_SETTINGS['hideBillTab']) {
        this._featureFlags[FT_APP_HR_BILLS] = false;
      }
      if (PX_APP_SETTINGS['hideLinkListOnHome']) {
        this._featureFlags[FT_APP_HOME_VIEW_EXIST_UNLINKED] = false;
      }
      if (PX_APP_SETTINGS['hideFeedbackOnHome']) {
        this._featureFlags[FT_APP_HOME_VIEW_COMMON_FEEDBACK] = false;
      }
      if (PX_APP_SETTINGS['hide_portal_calendar_tab']) {
        this._featureFlags[FT_APP_TAB_CALENDAR] = false;
      }
      if (PX_APP_SETTINGS['hide_health_packages']) {
        this._featureFlags[FT_APP_HEALTH_PACKAGE] = false;
      }
      if (PX_APP_SETTINGS['show_home_services']) {
        this._featureFlags[FT_APP_HOMESERVICES] = true;
      }
      if (PX_APP_SETTINGS['hide_direct_appt']) {
        this._featureFlags[FT_APP_BOOK_DIRECT_APPT] = false;
      }
      if (PX_APP_SETTINGS['enable_edit_patient']) {
        this._featureFlags[FT_APP_EDIT_LINK_MEMBER] = true;
      }
      if (PX_APP_SETTINGS['show_settle_bill_tab']) {
        this._featureFlags[FT_APP_TAB_BILLING] = true;
      }
      if (PX_APP_SETTINGS['hide_pin_for_context_login']) {
        this._featureFlags[FT_APP_CXTWITH_PIN] = false;
      }
      if (PX_APP_SETTINGS['hide_otp_login']) {
        this._featureFlags[FT_APP_LOGWITH_OTP] = false;
      }
      if (PX_APP_SETTINGS['disable_precheckscreen_for_context']) {
        this._featureFlags[FT_APP_CXTWITH_PRECHECK] = false;
      }
      if (PX_APP_SETTINGS['enableOnlyCommonView']) {
        this._featureFlags[FT_APP_OLY_CMN_VIEW] = true;
        this._featureFlags[FT_UNAUTHORIZED] = true;
      }
      if (PX_APP_SETTINGS['disableRegisterPatientOnSignup']) {
        this._featureFlags[FT_SSO_REGPAT_ON_SIGNUP] = false;
      }
      if (PX_APP_SETTINGS['restrict_manage_identity_for_hof']) {
        this.checkHOF = true
      }
      if (PX_APP_SETTINGS['loginWithExternalId']) {
        this._featureFlags[FT_APP_EXTIDLOGIN] = true
      }
      if (PX_APP_SETTINGS['disableUnlinkRecordsWithSameMobile']) {
        this._featureFlags[FT_APP_UNLINKSAMEIDENTITY] = false
      }
      if (PX_APP_SETTINGS['disableUnlinkRecordsWithDifferentMobile']) {
        this._featureFlags[FT_APP_UNLINKDIFFIDENTITY] = false
      }
      if (PX_APP_SETTINGS['disableUnlinkMyRecordLinked']) {
        this._featureFlags[FT_APP_UNLINKPROVIDEDIDENTITY] = false
      }
      if (PX_APP_SETTINGS['disableLinkingDifferentIdentityForNonHOF']) {
        this._featureFlags[FT_APP_NONHOFLINKDIFFIDENTITY] = false
      }
      if (PX_APP_SETTINGS['disableLinkingDifferentIdentityForHOF']) {
        this._featureFlags[FT_APP_HOFLINKDIFFIDENTITY] = false
      }
      if (PX_APP_SETTINGS['captchaVerifySignup']) {
        this._featureFlags[FT_APP_SIGNUPCAPTCHA] = true
      }
      if (PX_APP_SETTINGS['patientBasedBookingRestrictions']) {
        this._featureFlags[FT_APP_BOOKWITHRESTRICTIONS] = true
      }
      if (PX_APP_SETTINGS['linkpatientWithoutRelation']) {
        this._featureFlags[FT_APP_LINKWITHOUTRELATION] = true
      }
      if (PX_APP_SETTINGS['hideSummaryTab']) {
        this._featureFlags[FT_APP_HR_SUMMARY] = false;
      }
      if (PX_APP_SETTINGS['hideFindFamilyMember']) {
        this._featureFlags[FT_APP_FIND_FAMILY_MEMBER] = false;
      }
      if (PX_APP_SETTINGS['hideRegisterFamilyMember']) {
        this._featureFlags[FT_APP_REGISTER_FAMILY_MEMBER] = false;
      }
    }

    //COMPUTED
    this._featureFlags[FT_APP_BOOK_APPT] = this._featureFlags[FT_APP_BOOK_DIRECT_APPT] || this._featureFlags[FT_APP_BOOK_VC_APPT];
    this._featureFlags[FT_APP_HR_VISIT_SECTION] = this._featureFlags[FT_APP_HR_REPORT] || this._featureFlags[FT_APP_HR_PRESCRIPTION] || this._featureFlags[FT_APP_HR_BILLS] || this._featureFlags[FT_APP_HR_SUMMARY]

    this._featureFlags[FT_APP_TAB_HR] = this._featureFlags[FT_APP_HR_VISIT_SECTION] || this._featureFlags[FT_APP_HR_VW_VST_HIS]

    // this._featureFlags[FT_APP_TAB_HR] = 
    this._initialized = true;
    this.rxjsFeatureInitSubject.next(true);
  }

  public getFirstIndexOfHRTabs():Observable<any> {
    return Observable.create(observer => {
      if (this._initialized) {
        if (this._featureFlags[FT_APP_HR_SUMMARY]) {
          observer.next('summary')
        } else if (this._featureFlags[FT_APP_HR_REPORT]) {
          observer.next('reports')
        } else if (this._featureFlags[FT_APP_HR_PRESCRIPTION]) {
          observer.next('prescription');
        } else if (this._featureFlags[FT_APP_HR_BILLS]) {
          observer.next('bills');
        } else {
          return '';
        }
      } else {
        this.rxjsFeatureInitSubject.subscribe(_ => {
          if (this._featureFlags[FT_APP_HR_SUMMARY]) {
            observer.next('summary')
          } else if (this._featureFlags[FT_APP_HR_REPORT]) {
            observer.next('reports')
          } else if (this._featureFlags[FT_APP_HR_PRESCRIPTION]) {
            observer.next('prescription');
          } else if (this._featureFlags[FT_APP_HR_BILLS]) {
            observer.next('bills');
          } else {
            return '';
          }
        })
      }
    })
  }
  public getInitStatus(): Observable<any>{
    return Observable.create(observer => {
      if (this._initialized) {
        observer.next(true)
      } else {
        this.rxjsFeatureInitSubject.subscribe(_ => { 
          observer.next(true)
        })
      }
    })
  }

  public setAppType(type: APPTYPE) {
    this._featureFlags[SHOW_APP_HEADER] = type != 'webview'
    this._featureFlags[SHOW_APP_FOOTER] = type != 'webview'
    if(environment['ENTITY'] == 'MOHEGY') this._featureFlags[SHOW_APP_FOOTER] = false
    if (!this._featureFlags[SHOW_APP_HEADER])
      document.querySelector(':root')['style'].setProperty('--adjust-app-top', '4em')
  }

  public restrictViewsOnPermission(type: string) {
    switch (type) {
      case LOGIN_CONTEXT.REPORT:
      case LOGIN_CONTEXT.BILL:
        this._featureFlags[FT_APP_TAB_CALENDAR] = false;        
        this._featureFlags[FT_APP_TAB_HOME] = false;
        this._featureFlags[FT_APP_TAB_BILLING] = false;
        break;
    
      case LOGIN_CONTEXT.APPOINTMENT:
        this._featureFlags[FT_APP_TAB_HOME] = false;
        this._featureFlags[FT_APP_TAB_BILLING] = false;
        this._featureFlags[FT_APP_TAB_HR] = false; 
        break;
    
      default:
        break;
    }
  }

  public initFacility(object){
    const FACILITY_LIST = object['facilities']['facilities'];
    //MODIFIED
    if (FACILITY_LIST) {
      this._featureFlags[FT_APP_BOOK_VC_APPT] = FACILITY_LIST.reduce((original, facility) => {
        if (facility && facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['videoConsult'] && facility['facilityControls']['schedulingRules']['videoConsult']['enabled']) {
          return true;
        } else return original
      }, false);
    }
  }
}
