import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputErrorComponent } from './form-input-error.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [FormInputErrorComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports: [FormInputErrorComponent]
})
export class FormInputErrorModule { }
