import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartitionInputComponent } from './partition-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PartitionInputComponent
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule
  ], exports: [
    PartitionInputComponent
  ]
})
export class PartitionInputModule { }
