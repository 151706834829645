import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UtilitiesService } from '../services/utilities.service';
import { environment } from '@env/environment';
import { UserService } from "../services/user.service";
import { TranslatePipe } from "../pipes/translate.pipe";
import { ToastServiceService } from "../services/toast-service.service";

@Component({
  selector: "app-manage-my-consent",
  templateUrl: "./manage-my-consent.component.html",
  styleUrls: ["./manage-my-consent.component.scss"],
})
export class ManageMyConsentComponent {
  @Output() close: EventEmitter<any>;
  @Output() completeConsent: EventEmitter<any>;
  @Input() isConsented: any;
  public options: Array<any>;
  public consentDetails: any;
  public appDirection: any;
  constructor(private user: UserService, private translate: TranslatePipe, private utils: UtilitiesService, public toast: ToastServiceService,) {
    this.options = options;
    console.info("opt", this.options);
    this.appDirection = this.user.getAppDirection();
    this.close = new EventEmitter<any>();
    this.completeConsent = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getConfigurationInfo.subscribe(data => {
      console.log('profile', data)
      this.consentDetails = data.profile.manageconcent;
    })
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  onChange1() {
    console.log("is Consented", this.isConsented);
    this.completeConsent.emit(this.isConsented);
  }

  chooseConsentOpt(val) {
    if(val.action == 'my_consent')
      this.openMyConsent(this.consentDetails)
    else if(val.action == 'privacy_policy')
      this.openLink('pp')
  }
  closeModal() {
    this.close.emit();
  }

  public openMyConsent(value) {
    console.log('openMyConsent', value);
    let consent = value.concentUrls.find(a => a.locale == this.user.getCurrentLanguage());
    let type
    if(value.browserType == 'BLANK') {
      type = '_blank'
    } else {
      type = '_system'
    }
    if (consent.url) {
    this.user.openLocation(consent.url, "_blank")
    } else {
      let message = this.translate.transform('MANAGE#MY#CONSENT#URL#NOT#AVAILABLE');
      this.toast.showToast(true, message, "error")
    }
  }

  public openLink(type) {
    console.log('type', environment);
    if (type == 'pp') {
      let link = environment['urlInfo']['privacypolicy'];
      if (environment['urlInfo']['specifyLanguage'])
        link = this.utils.replaceLanguage(link, this.user.currentLanguage);
      this.user.openLocation(link, "_blank");
    }
    if (type == 'tos') {
      let link = environment['urlInfo']['termscondition'];
      if (environment['urlInfo']['specifyLanguage'])
        link = this.utils.replaceLanguage(link, this.user.currentLanguage);
      this.user.openLocation(link, "_blank");
    }
  }
}

const options = [
  {
    name: "MANAGE#DATA#PRIVACY#CARD#MANAGEMYCONSENT",
    action: "my_consent",
    icon: "assets/icons/manage-account/manage_my_consent.svg",
    visible: false,
  },
  {
    name: "MANAGE#DATA#PRIVACY#CARD#PRIVACYNOTICE",
    action: "privacy_policy",
    icon: "assets/icons/manage-account/privacy.svg",
    visible: false,
  },
];
