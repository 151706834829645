import { trigger, transition, style, animate } from '@angular/animations';

export const enterAnimationAxisY = trigger(
    'enterAnimationAxisY', [
        transition(':enter', [
            style({ transform: 'translateY(100%)', opacity: 1, backgroundColor: 'transparent' }),
            animate('500ms', style({ transform: 'translateY(0)', opacity: 1, backgroundColor: 'transparent' }))
        ]),
        transition(':leave', [
            style({ transform: 'translateY(0)', opacity: 1, backgroundColor: 'transparent' }),
            animate('500ms', style({ transform: 'translateY(100%)', opacity: 1, backgroundColor: 'transparent' }))
        ])
    ]
);

export const enterAnimationAxisX = trigger(
    'enterAnimationAxisX', [
        transition(':enter', [
            style({ transform: 'translate(-350px,0)', opacity: 1, backgroundColor: 'transparent' }),
            animate('500s', style({ transform: 'translate(-350px,0)', opacity: 1, backgroundColor: 'transparent' }))
        ]),
        transition(':leave', [
            style({ transform: 'translateY(0)', opacity: 1, backgroundColor: 'transparent' }),
            animate('500s', style({ transform: 'translateX(100%)', opacity: 1, backgroundColor: 'transparent' }))
        ])
    ]
);