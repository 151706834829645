<div id="report-table-new">
  <div class="report-grid">
    <div class="report-grid-item grid-content" *ngFor="let event of reportEvents">
      <div class="report-grid-row" style="--bg-color: {{event['Normalcy Ind'] | normalcyind: 'color'}}" (click)="getReportHistory(event)">
        <div class="report-grid-cell result-name" style="grid-area: a;">{{event.Test}}</div>
        <div class="report-grid-cell result-value bold" style="grid-area: b;">
          <span *ngIf="event.Result_num">{{event.Result_num}}</span>
          <!-- <span *ngIf="event.Result_Str" [innerHtml]="event.Result_Str"></span> -->
        </div>
        <div class="report-grid-cell result-uom" style="grid-area: c;">{{event.Uom}}</div>
        <div class="report-grid-cell indicator" style="grid-area: d;" *ngIf="event['Normalcy Ind'] && event['Normalcy Ind']!=''">
          <ng-container>
            <app-svg-icon class="normalcyind-img" [src]="event['Normalcy Ind'] | normalcyind : 'arrow'"></app-svg-icon>
          </ng-container>
        </div>
        <div class="report-grid-cell result-range" style="grid-area: e;">
          <span *ngIf="!event.Result_Str">{{event['Range']}}</span>
          <span *ngIf="event.Result_Str" [innerHtml]="event.Result_Str"></span>
        </div>
        <div class="report-grid-cell history" style="grid-area: f;">
          <img class="cursor-pointer"
            [ngClass]="{is_open: (eventHistory[event['Code']] && eventHistory[event['Code']]['open'])}"
            *ngIf="(event.Result_num && (event['Event History'] || this.query))"
             src="assets/icons/arrow.svg">
        </div>
      </div>
      <div class="report-event-graph" *ngIf="eventHistory[event['Code']] && eventHistory[event['Code']]['open']">
        <ng-container *ngIf="eventHistory[event['Code']]['history']">
          <app-graph [eventhistory]="eventHistory[event['Code']]['history']['Event History']" [event]="eventHistory[event['Code']]['history']">
          </app-graph>
        </ng-container>
        <div class="no-history font-danger-small" *ngIf="!eventHistory[event['Code']]['history']">
          {{'No_history_found' | translate :'LABELS'}}
        </div>
      </div>
    </div>
  </div>
</div>