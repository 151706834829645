<!--<div [style.border-color]=" borderColor ? borderColor : '#d6d6d6' " class="topic" *ngFor="let topic of topics">-->
<ng-container *ngFor="let topic of topics">
  <div [style.border-color]=" borderColor ? borderColor : '#d6d6d6' " class="topic"
    [ngClass]="{'event-form':isEventInfo}" *ngIf="topic['displayOption'] ? topic['displayOption'] != 'H' : true">
    <ng-container *ngIf="showTopicTitle">
      <h6 id="{{topic['id']}}" class="topic-name font-default-small1 bold"
        *ngIf="topic.type!=='addendum' else addendumTopicName">
        {{topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}</h6>
      <ng-template #addendumTopicName>
        <h6 id="{{topic['id']}}" class="topic-name font-default-small1 bold" *ngIf="topics.length > 1">
          <ng-container *ngIf="topic.sections && topic.sections[0]">
            {{topic.sections[0].key || topic['topicLocale'] || topic['topic'] | translate:'LOCALE':'text'}}
            <div class="addendum-title font-light-small" *ngIf="topic.sections[0].addendum">
              Added by <span>{{ topic.sections[0].authorName || 'N/A' }}</span> on
              <span>{{ topic.sections[0].authoredDate | moment: 'MMM DD, YYYY h:mm a' }}</span>
            </div>
          </ng-container>
        </h6>
      </ng-template>
    </ng-container>
    <div class="section" *ngFor="let section of topic.sections | sectionfilter:topic">
      <app-directive-linked-print-preview *ngIf="section.type === 'directiveLinkedField'" [s]="section">
      </app-directive-linked-print-preview>
      <ng-container *ngIf="section.type !== 'directiveLinkedField'">
        <div *ngIf="codify(section.codify)">
          <div *ngIf="type(section.type)">
            <div *ngFor="let ques of section.question">
              <p class="no-padding no-margin secondary-text">
                <span class="que-txt font-light-small1">{{ ques['text'] }}</span>
                <span style="font-weight: 500" class="ans-txt font-default-small1"> {{ section['value'] }}</span>
              </p>
            </div>
          </div>
          <div *ngIf="!type(section.type)">
            <div *ngFor="let ques of section.question">
              <p class="no-padding no-margin secondary-text" *ngIf="section.type !== 'addendum'"><span
                  class="que-txt font-light-small1">{{ ques['text'] }}</span>
                <span class="spanData ans-txt font-default-small1"
                  *ngIf="!((section.type == 'photo') || (section['type'] == 'draw') || (section['type'] == 'file') )">{{ section['value'] }}</span>
              </p>
              <div *ngIf="section.type === 'addendum'" class="no-padding no-margin secondary-text">
                <div class="addendum-note font-default-small1">
                  {{ section.value }}
                </div>
              </div>
              <ng-container *ngIf="section['type'] === 'draw'">
                <img class="rpt-img" [ngClass]="{'rpt-img-large':section._zoom}"
                  (click)="section._zoom = !section._zoom" src="{{section.selected}}">
              </ng-container>
              <ng-container *ngIf="section['type'] === 'photo' && section.selected.length>0">
                <ng-container *ngFor="let s of section.selected">
                  <img class="rpt-img" *ngIf="s.url" [ngClass]="{'rpt-img-large':s._zoom}" (click)="s._zoom = !s._zoom"
                    [src]="s.url" style="margin: 0.5em;">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="section['type'] === 'file' && section['selectedFile'].length">
                <div class="file-wrapper" *ngFor="let s of section['selectedFile'];let i = index">
                  <div class="file-item">
                    <img src="assets/icons/download.svg" alt="">
                    <a class="primary" [href]="s['url']">{{s['fileEntry']['name']}}</a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="!codify(section.codify)">
          <div>
            <p class="no-padding no-margin secondary-text"><span
                class="que-txt font-light-small1">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text'}}</span>
              <span class="ans-txt font-default-small1" *ngIf="section.type != 'richTextEditor'">{{ section['value'] && section['value'].toString().includes('#') ? section['value'].toString().split('#')[1] : section['value']  }}</span>
              <span class="ans-txt font-default-small1" *ngIf="section.type == 'richTextEditor'"[innerHtml]="section['value']"></span>
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>