import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../pipes/translate.pipe';
import { AppointmentService } from '../services/appointment.service';
import { HomeCareService } from '../services/home-care.service';
import { LinkPatientService } from '../services/link-patient.service';
import { ModalService } from '../services/modal.service';
import { UserService } from '../services/user.service';
import { enterAnimation, switchAnimation, enterAnimationAxisX } from '../animations';
import * as _ from 'lodash';
import { visitType } from '@type';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';

@Component({
  selector: 'app-consult-again',
  templateUrl: './consult-again.component.html',
  styleUrls: ['./consult-again.component.scss'],
  animations: [enterAnimation, switchAnimation, enterAnimationAxisX],
  providers: [TranslatePipe]
})
export class ConsultAgainComponent implements OnInit {
  @Input() consultAgain: any;
  @Input() showAsSlide: boolean = false
  @Output() openConsultAgainAppt: EventEmitter<any>;

  @ViewChild('apptSwiper', { static: false }) apptSwiper: ElementRef

  private appConfig: any;
  public showConsultAgainView: boolean = false;
  public recentlyConsulted: any;
  public showConsultAgainList: boolean;
  public prefillData: any;
  public bookingAttributes: Object;
  public visitType: visitType;
  public bookAppt: boolean = false;
  public _input: any;
  public consultAgainLmt: any = [];

  constructor(private user: UserService, private modalService: ModalService, private appointmentService: AppointmentService, private activatedRoute: ActivatedRoute, private translatePipe: TranslatePipe, private linkPatient: LinkPatientService, private homeCare: HomeCareService, private router: Router, private pracDetailsPipe: PartitionerDetailsPipe,) {
    this.openConsultAgainAppt = new EventEmitter<any>();
    // this.openRoute=false
    this.bookingAttributes = {};
  }

  ngAfterViewInit() {
    console.log('ConsultAgainComponent')
    this.getPatientDetails();
    if (this.apptSwiper) {
      const swiperEl = this.apptSwiper.nativeElement;
      const swiperParams = {
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      };
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
      var sheet = new CSSStyleSheet
      sheet.replaceSync(`.swiper{ padding-bottom: 0.75rem;--swiper-pagination-bottom: -3px;}`)
      swiperEl.shadowRoot.adoptedStyleSheets.push(sheet)
    }
  }

  ngOnInit() {
    this.user.getConfigurationInfo.subscribe(data => {
      this.appConfig = data;
    })
  }


  getAppointmentDetail(item, type) {
    console.log('get appt dtl', item, type);
  }
  public openBookAppointment() {
    this.openConsultAgainAppt.emit();
    // this.isBookAppointment = true;
  }

  public getPatientDetails() {
    this.user.getUserId().subscribe(data => {
      if (!data)
        return;
      this.linkPatient.getLinkedPatient(data).subscribe(result => {
        if (result.status === 'SUCCESS')
          this.consultAgain.forEach(a => {
            const linkedPatient = result?.['data']?.find(p => a.patient_id == p['uhid']);
            a['patientDetails'] = {
              ...linkedPatient
            };
          })
          this.consultAgainLmt = this.consultAgain.slice(0, 7);
      })
    });
  }


  public onSeeAll() {
    this.showConsultAgainList = true;
  }

  public closeModal() {
    this.showConsultAgainList = false;
  }

  complete(ev) {
    this.showConsultAgainList = false;
    console.log('complete', ev)
    this.bookAgain(ev);
  }

  bookAgain(item) {
    console.log('book again', item);
    this.initConsultAgainData(item)
  }

  initConsultAgainData(item) {
    let practitionerId = item.resourceId;
    let facilityId = item.facility_id;
    let specialityId = this.pracDetailsPipe.transform(practitionerId, facilityId, 'specialityId');
    let patientUhid = item.patient_id;

    console.log('consult again data', practitionerId, facilityId, specialityId, patientUhid);
    let practitioner = this.user.findPractitioner(facilityId, practitionerId);
    this.bookingAttributes['practitioner'] = {
      id: practitioner.resourceId,
      personId: practitioner.personId,
      name: practitioner.resourceName,
      resourceAvatarURL: practitioner.resourceAvatarURL,
      info: practitioner.additionalInfo['info'],
      availDays: practitioner['schedulerControls']['daysAvailable'],
      schedulerControls: practitioner['schedulerControls'],
      additionalInfo: practitioner['additionalInfo'],
      facList: practitioner['facList']
    }
    let speciality = this.user.findSpeciality(facilityId, specialityId);
    this.bookingAttributes['speciality'] = {
      id: speciality.specialityId,
      code: speciality.sourceSpecialityCode,
      desc: speciality.specialityDesc,
      name: speciality.specialityName,
    }
    this.visitType = (item.appointmenttype == 'VIDEO' || item.appointmenttype == 'video') ? 'VIDEO' : 'HOSPITAL';
    this.bookingAttributes['home'] = { visitType: this.visitType };
    if (this.visitType == 'VIDEO')
      this.bookingAttributes['consultAgain'] = true;
    this.bookingAttributes['exist'] = {
      slot: {
        date: this.user.getMoment(item['consultDttm'], 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
        slot: this.user.getMoment(item['consultDttm'], 'YYYY-MM-DD HH:mm').format('HH:mm'),
        facilityId: facilityId
      },
    }
    let patient = this.user.findLinkedPatient(patientUhid);
    this.bookingAttributes['patient'] = {
      id: patient.uhid,
      name: patient.personname,
      personid: patient.personid,
      relation: patient.relationshiptype,
      image: patient.imageurl,
      gender: patient.gender,
      dob: patient.dob
    }
    let facility = this.user.findFacility(facilityId);
    this.bookingAttributes['facility'] = {
      id: facility['facilityId'],
      name: facility['facilityName'],
      address: facility['facilityAddress']
    };
    console.log('bookingAttribute', this.bookingAttributes);
    this.prefillData = {
      appointmentDateTime: item['consultDttm'],
      facilityId: facilityId,
      patientId: patientUhid,
      personId: patient.personid,
      personName: patient.personname,
      practitionerName: practitioner.resourceName,
      resourceId: practitioner.resourceId,
      uhid: patientUhid,
      specialityId: speciality.specialityId,
      appointmentType: this.visitType,
      type: this.visitType,
    }
    this._input = { in: this.bookingAttributes }
    console.log(this._input)
    this.bookAppt = true;
  }

  closeApptView(ev) {
    console.log('event', ev);
    this.bookAppt = false;
  }
}
