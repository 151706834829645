<div class="my-packages-modal-wrapper">
    <div class="my-packages-modal">
        <div class="my-packages-header">{{'My_Packages' | translate}}
            <span class="close-icon" (click)="closeModal()"><img src="./assets/icons/close_black.svg" alt=""></span>
        </div>
        <div class="my-packages-toolbar">
            <div class="toggle-option">
                <ng-container *ngFor="let t of tabs">
                    <div class="option" [ngClass]="{selected: selectedTab == t['id']}" (click)="changeActiveTab(t)">
                        {{t['label'] | translate}}</div>
                </ng-container>
            </div>
        </div>
        <div class="my-packages-content">
            <div class="my-packages-item" *ngFor="let p of packageList">
                <div class="image" >
                    <img *ngIf="p['imageURL']" class="type-image" [src]="p['imageURL']" alt="">
                </div>
                <!-- <img *ngIf="p['imageURL']" class="my-packages-img" [src]="p['imageURL']" alt=""> -->
                <div class="my-package-context">
                    <p class="my-package-title">{{p['name']}}</p>
                    <p class="my-package-subtitle">{{'Parchased_on' | translate}} 
                        {{p['bookedDttm'] | moment:'D MMM YYYY, hh:mm A':'YYYY-MM-DD HH:mm:ss'}}</p>
                    <p class="package-progress-bar">
                        <span class="package-progress" [ngClass]="{'cancelled': p['status'] == 'Cancelled'}"
                            [ngStyle]="{'width': p['status'] == 'Cancelled' ? '100%':p['completedPercent']+'%'}">
                        </span>
                    </p>
                    <div class="package-info">
                        <p class="package-info-item">
                            <span class="package-info-label">{{'Valid_from' | translate}}</span>
                            <span class="package-info-value">
                                {{p['validFrom'] | moment:'D MMM YYYY, hh:mm A':'YYYY-MM-DD HH:mm:ss'}}</span>
                        </p>
                        <p class="package-info-item">
                            <span class="package-info-label">{{'Valid_to' | translate}}</span>
                            <span class="package-info-value">{{p['validTo'] | moment:'D MMM YYYY, hh:mm A':'YYYY-MM-DD HH:mm:ss'}}</span>
                        </p>
                        <p class="package-info-item">
                            <span class="package-info-label">{{'Facility' | translate}}</span>
                            <span class="package-info-value">{{(p['facilityId']|facilityDetails:'facilityName') |
                                translate : 'LOCALE' : 'name'}}</span>
                        </p>
                    </div>
                    <p class="view-info">
                        <span class="view-button" (click)="openPackageDetails(p)">{{'View'}} <img
                                src="./assets/icons/health-package/Next.svg" alt=""></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-my-package-details *ngIf="selectedPackage" [selectedPackage]="selectedPackage"
    (close)="onClose($event)"></app-my-package-details>