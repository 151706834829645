import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageAddressFormComponent } from './manage-address-form.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';

@NgModule({
  declarations: [ManageAddressFormComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule
  ],
  exports: [
    ManageAddressFormComponent
  ]
})
export class ManageAddressFormModule { }
