import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(private http: HttpClient, private user: UserService) { }

  public myPackages:any;
  public getPackages(inparam,isBackground?): Observable<any> {
    let payload = {
      'personId': this.user.getPersonId(),
      isBackgroundProcess: isBackground,
      ...inparam
    }
    return new Observable(observer => {
      this.http.post(`${this.user.getRepoUrl()}api/packages/getMyPackages`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
        this.myPackages=data['data']
        observer.complete();
      }, error => {
        observer.error()
        observer.complete();
      })
    })
  }

  public getSubscribedPackages(){
    return this.myPackages;
  }
  
  public getPackageDetail(inparam): Observable<any> {
    let payload = {
      'personId': this.user.getPersonId(),
      ...inparam
    }
    return new Observable(observer => {
      this.http.post(`${this.user.getRepoUrl()}api/packages/packageSubUtilization`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error()
        observer.complete();
      })
    })
  }
}
