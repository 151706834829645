import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ClinicalformService } from 'src/app/services/clinicalform.service';
import { UserService } from 'src/app/services/user.service';
import { SectionInterface } from '../../section.component';
import { Type } from '../type.abstract';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends Type implements OnInit, SectionInterface, OnChanges {

  @Input() public section;
  @Input() public sections;

  public files: NgxFileDropEntry[] = [];

  constructor(cfs: ClinicalformService, private user: UserService) {
    super(cfs);
  }

  ngOnInit() {

    console.log(this.section);
  }

  ngOnChanges() {

  }

  getSelected() {
    return '';
  }

  setValue(value) {

  }

  public dropped(files: NgxFileDropEntry[]) {
    files = files.map(file => {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      const reader = new FileReader();
        fileEntry.file(fE => {
            reader.readAsDataURL(fE);
            reader.onload = () => {
              file['dataURL'] = reader.result;
            };
        });
      file["uploaded"] = false;
      return file;
    })
    this.files = [...this.files, ...files];
    this.section['selectedFile'] = this.files;
    console.log(this.section['selectedFile']);
  }


  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  removeFile(i) {
    console.log(i);
    this.files.splice(i, 1);
    this.section["selectedFile"] = this.files;
  }

  openBrowse() {
    (<HTMLElement>document.getElementsByClassName('browser-button')[0]).click();
  }

  public openPreview(file) { 
    let w = this.user.openLocation("", '_blank');
    w.document.title = file['relativePath'];
    w.document.write("<iframe style='margin:-8px; border:0px;width:calc(100% + 16px); height:calc(100% + 16px);' src='" + file['dataURL'] + "'></iframe>");
  }
}
