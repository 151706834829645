import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPractitionerComponent } from './search-practitioner.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { PersonAvatarModule } from '../person-avatar/person-avatar.module';
import { NodataModule } from '../nodata/nodata.module';
import { PractitionerAvatarModule }  from '../practitioner-avatar/practitioner-avatar.module';
@NgModule({
  declarations: [SearchPractitionerComponent],
  imports: [
    CommonModule, FormsModule, PipesModule, PersonAvatarModule, NodataModule,
    PractitionerAvatarModule
  ],
  
  exports: [SearchPractitionerComponent]
})
export class SearchPractitionerModule { }
