import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import { enterAnimation, switchAnimation ,enterAnimationAxisX} from "../animations";
import { ModalService} from '../services/modal.service';
import {concat as _concat} from 'lodash';
import * as moment from 'moment';
import {AppointmentService} from '../services/appointment.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { APPTACTION, visitType } from '@type';
import { TranslatePipe } from '../pipes/translate.pipe';
import { LinkPatientService } from '../services/link-patient.service';
import { HomeCareService } from '../services/home-care.service';
import { HC_PRACT_STARTED } from '@string';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
  animations: [enterAnimation, switchAnimation, enterAnimationAxisX],
  providers: [TranslatePipe]
})
export class AppointmentsComponent implements OnInit {
  @Input() upcomingAppointments: any;
  @Input() appointment_history: any;
  @Input() showAsSlide: boolean = false
  @Output() openBookAppt: EventEmitter<any>;
  @Output() changeInAppt: EventEmitter<any> = new EventEmitter();

  private appConfig: any;
  public translateType = "LOCALE";
  public appointmentDetail = false;
  public appointmentData: any;
  public hasPaymentToShow:boolean;
  public apptRefNumber:any;
  public callBack: string;
  public isApptView: boolean;
  public rescheduleData: Object = null;
  public orderdetails: Object = null;
  public isBookAppointment: boolean;
  public bookingAttributes: any;
  public prefillData: Object = null;
  public isRegisterPatient: boolean;
  public isUploadQuestionnarie: boolean;
  public isUploadDocs: boolean;
  public selectedAppt: Object = null;
  routeItem: any;
  openRoute: any;
  // public upcomingAppointments: any;
  public facility: any;
  public facilityList: any;
  public isExpress: boolean;
  public homecareappts: any;
  public initCheckin:APPTACTION=null;
  public upcomingAppointments_main: any = {};
  public toggle: boolean = false;

  @ViewChild('apptSwiper', {static: false}) apptSwiper: ElementRef

  constructor(private user: UserService, private modalService: ModalService, private appointmentService: AppointmentService, private activatedRoute: ActivatedRoute, private location: Location, private translatePipe: TranslatePipe, private linkPatient: LinkPatientService, private homeCare: HomeCareService,private router: Router,) {
    this.openBookAppt = new EventEmitter<any>();
    this.openRoute=false
  }

  ngAfterViewInit() {
    if (this.apptSwiper) {
      const swiperEl = this.apptSwiper.nativeElement;
      const swiperParams = {
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      };
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
      var sheet = new CSSStyleSheet
      sheet.replaceSync(`.swiper{ padding-bottom: 0.75rem;--swiper-pagination-bottom: -3px;}`)
      swiperEl.shadowRoot.adoptedStyleSheets.push(sheet)
    }
  }

  ngOnInit() {
    this.checkUrl();
    this.user.getConfigurationInfo.subscribe(data => {
      this.appConfig = data;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes?.['upcomingAppointments']) {
      this.upcomingAppointments_main =  this.upcomingAppointments;
      this.upcomingAppointments.forEach((a)=>{
        a['_actions'] = this.appointmentService.processScheduleFlag(a, 'CARD');
        let fac=this.user.findFacility(a['facilityId'])
        console.log('facility info by appt----',fac)
        if(fac.facilityControls && fac.facilityControls.schedulingRules && fac.facilityControls.schedulingRules.others && fac.facilityControls.schedulingRules.others.showQueueNo
          && fac.facilityControls.schedulingRules.others.showQueueNo=='Y'){
            console.log('in condition---')
            a['enableQueueOnFacility']=true
            if(a['checkedIn']=="true" && a['enableQueueOnFacility'] && !a['queueNo'])
              this.getQueueNo(a)
          }
      })
    }
    
  }


  public openBookAppointment() {
    this.openBookAppt.emit();
    // this.isBookAppointment = true;
  }
  public closeBookAppointment(event) {
    this.isBookAppointment = false;
    this.orderdetails = null;
    this.fetchApptChanges();
    if (event.bookingAttributes) {
      this.bookingAttributes = event.bookingAttributes;
      this.openRegisterPatient();
    }
    else {
      this.fetchApptChanges();
      this.bookingAttributes = null
    }
  }
  fetchUpcomingAppointment() {
    this.user.getUserId().subscribe(data => {
      if (data) {
        this.appointmentService.getUpcomingAppointment(data).subscribe(result => {
          this.appointment_history = result.data.appointments;
          this.upcomingAppointments = result.data.appointments;
          console.log("upcomingAppointments",this.upcomingAppointments);
          let today= this.user.getMoment().format('DD/MM/YYYY')
          let nextAppointments = this.upcomingAppointments.filter(a => {
            if(this.user.getMoment(a.appointmentDateTime,'YYYY-MM-DD HH:mm').isAfter(this.user.getMoment(today,'DD/MM/YYYY'),'day')) return a;
          }).splice(0,4)
          this.upcomingAppointments=this.upcomingAppointments.filter((a)=>{//2020-04-23 08:00
              console.log('Check appointment is today ? ',this.user.getMoment(today,'DD/MM/YYYY').isSame(this.user.getMoment(a.appointmentDateTime,'YYYY-MM-DD HH:mm'),'day'))
              if(this.user.getMoment(today,'DD/MM/YYYY').isSame(this.user.getMoment(a.appointmentDateTime,'YYYY-MM-DD HH:mm'),'day')) return a;
          })
          this.upcomingAppointments = this.upcomingAppointments.filter((a)=>{
              if(!a['consultationStatus'] || (a.consultationStatus && a.consultationStatus !='FINISH' && a.consultationStatus !='CANCEL'))
                return a;
          })
          this.upcomingAppointments = this.upcomingAppointments.filter((a)=>{
              var apptDate = new Date(this.user.getMoment(a.appointmentDateTime, 'YYYY-MM-DD HH:mm').toDate())
              if(this.user.getMoment(apptDate).isAfter(this.user.getMoment().format())) return a;
              else {
                if(a['appointmentType']=='VIDEO'){
                    return this.allowJoin(a);
                }else{
                    return this.showCheckIn(a);
                }
              }
              // else if(a['_allowCheckin']) return a;
          })
          this.upcomingAppointments = _concat(this.upcomingAppointments, nextAppointments)
          if (this.hasPaymentToShow) {
            let appt= this.appointment_history.find(appt => {
              return appt.appointmentId == this.apptRefNumber;
            })
            if (appt) {
              appt['forceCallBack'] = this.callBack;
              this.callBack = null;
              this.getAppointmentDetail(appt, 'calendar-appointment-details-modal');
            }
          }
          this.upcomingAppointments.forEach((a)=>{
            a['_actions'] = this.appointmentService.processScheduleFlag(a, 'CARD');
            let fac=this.user.findFacility(a['facilityId'])
            console.log('facility info by appt----',fac)
            if(fac.facilityControls && fac.facilityControls.schedulingRules && fac.facilityControls.schedulingRules.others && fac.facilityControls.schedulingRules.others.showQueueNo
              && fac.facilityControls.schedulingRules.others.showQueueNo=='Y'){
                console.log('in condition---')
                a['enableQueueOnFacility']=true
                if(a['checkedIn']=="true" && a['enableQueueOnFacility'] && !a['queueNo'])
                  this.getQueueNo(a)
              }
          })
          this.upcomingAppointments_main = this.upcomingAppointments;
          
        })
      }
    })
    // this.fetchHomeCareAppts();
  }
  getAppointmentDetail(data, id) {
    console.log('clicked appt details')
    if (data && data.appointmentType == 'HC' && data['homecareActivityDetails'] && data['homecareActivityDetails']['status'] && data['homecareActivityDetails']['status'] == HC_PRACT_STARTED) {
      this.openEnRoute(data)
    } else {
      this.initCheckin = null;
      this.appointmentDetail = true;
      this.appointmentData = data;
      this.openModal(id);
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  public openApptType(type) {
    switch (type) {
      case 'direct_consultation':
      case 'video_consultation':
        let t: visitType = type == 'direct_consultation' ? 'HOSPITAL' : 'VIDEO';
        this.prefillData = { type: t };
        this.isApptView = true;
        break;
    }
  }

  public openuploadQuestionnarie(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.openModal('preconsult-questionnaire-modal');
    this.selectedAppt = event;
    this.isUploadQuestionnarie = true;
  }

  public closeuploadQuestionnarie() {
    this.closeModal('preconsult-questionnaire-modal');
    this.isUploadQuestionnarie = false;
    this.selectedAppt = null;
  }
  public openuploadDocs(event) {
    this.closeModal('calendar-appointment-details-modal');
    this.openModal('upload-document-modal');
    this.selectedAppt = event;
    this.isUploadDocs = true;
  }
  public closeuploadDocs() {
    this.closeModal('upload-document-modal');
    this.isUploadDocs = false;
    this.selectedAppt = null;
  }

  // public openuploadQuestionnarie(event) {
  //   this.closeModal('calendar-appointment-details-modal');
  //   this.selectedAppt = event;
  //   this.isUploadQuestionnarie = true;
  // }

  // public closeuploadQuestionnarie() {
  //   this.isUploadQuestionnarie = false;
  //   this.selectedAppt = null;
  // }
  // public openuploadDocs(event) {
  //   this.closeModal('calendar-appointment-details-modal');
  //   this.selectedAppt = event;
  //   this.isUploadDocs = true;
  // }
  // public closeuploadDocs() {
  //   this.isUploadDocs = false;
  //   this.selectedAppt = null;
  // }
  private checkUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      let payment = params['s'];
      let action = params['action'];
      if (payment==1) {
        let method = params['method'];
        let orderdetails = params['p'];
        switch (method) {
          case 'APTPAYMENT':
            this.hasPaymentToShow = true;
            let orderData = atob(orderdetails);
            let arr = orderData.split('&');
            let params = {};
            for (var i = 0; i < arr.length; i++) {
              var pair = arr[i].split('=');
              params[pair[0]] = decodeURIComponent(pair[1]);
            }
            this.apptRefNumber = params['apptRefNumber'];
            this.callBack = params['callback'];
            this.location.replaceState('/portal/calendar');
            break;
        }
      } else if (action == 'openappt') {
        console.log('aptrefno', params)
        if (params['aptrefno']) {
          this.hasPaymentToShow = true;
          this.apptRefNumber = params['aptrefno'];
          this.location.replaceState('/portal/home');
        }
      }
      console.log('init appt component---', this.upcomingAppointments)
    });
  }
  closeApptDetail() {
    this.isBookAppointment = false;
    this.hasPaymentToShow = false;
    this.appointmentDetail = false;
    this.appointmentData = null;
    this.callBack = null;
    this.apptRefNumber = null;
    this.fetchApptChanges();
    // this.closeModal('calendar-appointment-details-modal');
  }
  public openApptView(event) {
    // this.isBookAppointment = false;
    this.closeModal('calendar-appointment-details-modal');
    this.openModal('reschedule-appt-modal');
    this.rescheduleData = event;
    this.isApptView = true;
  }

  public openExpressView(event) {
    console.log("hello appt");
    this.closeModal('calendar-appointment-details-modal');
    this.rescheduleData =event;
    //this.isApptView = true;
    this.isExpress = true;
  }

  public closeExpressClinic() {
    this.isExpress = false;
    this.rescheduleData = null;
    this.bookingAttributes = null
    this.fetchApptChanges();
  }

  public closeApptView(event) {
    this.closeModal('reschedule-appt-modal');
    this.rescheduleData = null;
    this.orderdetails = null;
    this.isApptView = false;
    this.prefillData = null;
    if (event.bookingAttributes) {
      this.bookingAttributes = event.bookingAttributes;
      this.openRegisterPatient();
    } else {
      this.fetchApptChanges();
      this.bookingAttributes = null
    }
  }
  public openRegisterPatient() {
    this.isRegisterPatient = true;
  }
  public closeRegisterPatient() {
    this.isRegisterPatient = false;
    if (this.bookingAttributes) {
      this.openBookAppointment()
    }
  }

  showCheckIn(apt){
    this.user.getUpdateInfo('facilities').subscribe(data => {
      this.facilityList = data.facilities;
    })
    if (this.appConfig.features.appointmentCheckIn) {
      if (apt.checkedIn == 'false' && apt.checkInAllowed == 'true') {
        for (let f in this.facilityList) {
          if (this.facilityList[f].facilityId == apt.facilityId) {
            if (this.validateRule(apt.appointmentDateTime, this.facilityList[f].facilityControls.schedulingRules.checkInPolicy, apt['facilityId'])) {
              return true;
            } else {
              return false;
            }
          }
        }

      } else {
        return false;
      }
    } else {
      return false;
    }
}

allowJoin(apt){
  this.user.getUpdateInfo('facilities').subscribe(data => {
    this.facilityList = data.facilities;
  })
  this.facilityList = this.facilityList.filter( item =>{
    return item['facilityId'] == apt['facilityId']
  })
  var policy=this.facilityList[0];
  if(policy && policy.facilityControls && policy.facilityControls.schedulingRules){
    var rules = policy.facilityControls.schedulingRules;
    if (apt.isOnspot) {
      if (rules?.onspotConsult?.policy) {
        let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
        if (this.validateRule(dateTimeToCheck, rules.onspotConsult.policy, apt['facilityId'])) {
          return true;
        } else {
          return false;
        }
      }
    }
    else if (rules.videoConsult && rules.videoConsult.policy) {
      let dateTimeToCheck = this.user.getMoment(apt['appointmentDateTime'], 'YYYY-MM-DD HH:mm');
      if (this.validateRule(dateTimeToCheck, rules.videoConsult.policy, apt['facilityId'])) {
        return true;
      } else {
        return false;
      }
    }
  }
}
//   allowJoin(apt){
//     this.user.getUpdateInfo('facilities').subscribe(data => {
//       this.facilityList = data.facilities;
//     })
//     var policy=this.facilityList[0];
//     if(policy && policy.facilityControls && policy.facilityControls.schedulingRules){
//         var rules=policy.facilityControls.schedulingRules;
//         if (rules.videoConsult&&rules.videoConsult.policy){
//            if(this.validateRule(apt.appointmentDateTime, rules.videoConsult.policy)) {
//                return true;
//            }else{
//                return false;
//            }
//         }
//     }
// }


  validateRule(dateTimeToCheck, txnType, facilityId) {

    var policy = txnType;
  let facilityTimezone = this.user.getFacilityTimezone(facilityId);
    var validate = true;
    var currDateTime = this.user.getMoment();

    var lowerRefDateTime = this.user.getMoment('1999-01-01');
    var upperRefDateTime = this.user.getMoment('2099-12-31');

    if (policy) {
        if (policy.minPeriod != "") {
          lowerRefDateTime = this.getRefDateTime(policy.minPeriod, policy.minPeriodUnit, policy.minPeriodFactor, dateTimeToCheck, facilityTimezone);
        }
        if (policy.maxPeriod != "") {
          upperRefDateTime = this.getRefDateTime(policy.maxPeriod, policy.maxPeriodUnit, policy.maxPeriodFactor, dateTimeToCheck, facilityTimezone);
        }
    }

    if ((currDateTime.isBefore(lowerRefDateTime)) || (currDateTime.isAfter(upperRefDateTime))) {
        validate = false;
    }

    return validate;
}
getRefDateTime(period, unit, direction, dateTimeToCheck, timezone) {
    var refDateTime = this.user.getMoment(dateTimeToCheck, null, null, timezone);

    if (unit == "H") {
        if (direction == "BEFORE") {
            refDateTime.subtract(parseInt(period), 'hours');
        }
        else if (direction == "AFTER") {
            refDateTime.add(parseInt(period), 'hours');
        }
    }
    else if (unit == "M") {
        if (direction == "BEFORE") {
            refDateTime.subtract(parseInt(period), 'minutes');
        }
        else if (direction == "AFTER") {
            refDateTime.add(parseInt(period), 'minutes');
        }
    }
    else if (unit == "D") {
        if (direction == "BEFORE") {
            refDateTime.subtract(parseInt(period), 'days');
        }
        else if (direction == "AFTER") {
            refDateTime.add(parseInt(period), 'days');
        }
    }
    return refDateTime;

}
  openEnRoute(item) {
    this.routeItem = item;
    this.openRoute = true;
  }
  closeMap() {
    this.routeItem = null;
    this.openRoute = false;
  }

  public getQueueNo(appt){
    let param={
      facilityID :appt.facilityId,
      patientId:appt.patientId,
      encounterId:appt.visitStatus ? appt.visitStatus.encounterId : null,
      apptRefnumb:appt.appointmentId  
    }
    this.appointmentService.getQueue(param).subscribe((data)=>{
      if(data){
        appt['queueNo']=data;
      }
    },err=>{
    })
  }

  public onSeeAll() {
    this.router.navigate([`${this.user.getPortalBasePath()}/calendar`])
    return
  }

  public handleActions(appt, event, data) {
    this.initCheckin = event;
    this.appointmentDetail = true;
    this.appointmentData = appt;
    this.openModal('calendar-appointment-details-modal');
  }

  fetchApptChanges() {
    this.changeInAppt.emit(new Date().valueOf());
  }
}
