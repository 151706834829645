import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GoogleTagsService } from '../services/google-tags.service';
import { HomeCareService } from '../services/home-care.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-home-care-activity',
  templateUrl: './home-care-activity.component.html',
  styleUrls: ['./home-care-activity.component.scss']
})
export class HomeCareActivityComponent implements OnInit {
  public availableServices;
  public locations;
  public islocation: boolean = false;
  public locationdetails: any;
  public currentservice;
  public currentserviceoptions;
  public isdoctorconsultation: boolean = false;
  public currency: string;
  public searchString: string = '';
  @Input() service_name: any;
  @Input() activity: any;
  @Input() activityInput:any;
  @Input() disableCall:any;

  @Output() close: EventEmitter<any>;
  @Output() call: EventEmitter<any>;

  constructor(private services: HomeCareService, private user: UserService, private gtmService: GoogleTagsService) {
    this.currency = this.user.getCurrency()
    this.close = new EventEmitter<any>()
    this.call = new EventEmitter<any>()
    console.log("===== this.locationdetails ====", this.locationdetails)
    console.log("in constructor child=>", this.activity)

  }
  public closemodel() {
    this.close.emit("close")
  }

  ngOnInit(): void {
    this.availableServices = this.activityInput
    if(Array.isArray(this.availableServices)){
      for (var i of this.availableServices) {
        if (i._id == this.activity._id) {
          this.currentservice = i
          this.currentserviceoptions = i.options
        }
      }
    }else{
      this.currentservice = this.activity;
      this.currentserviceoptions = this.availableServices.options
    }
    console.log("in ngOnInit child=>", this.activity, this.locations, this.availableServices)
    if (this.activity && this.activity.type && this.activity.type=='location') {
      this.islocation = true;
    }
    else if(this.activity && this.activity.type && this.activity.type=='doctor'){
      this.isdoctorconsultation = true;
    }
    console.log("in ngOnInit  final serv=>", this.currentservice, this.currentserviceoptions)
    this.gtmService.pushGTMEvent('Home Health Care', 'services_list');


  }

  public getTitle(names){
    return this.services.getTitle(names)
  }

  public isArray(arr) {
    return Array.isArray(arr);
  }

  public callus() {
    this.call.emit();
  }

}
