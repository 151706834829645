<div id="change-password" class="right-nav">
  <div class="content">
    
    <div class="form">
      <div class="title bold">
        {{'change_password'|translate}}
        <div (click)="closeModal()" class="float-right closeicon pointer">
          <img src="./assets/icons/close_grey.svg">
        </div>
      </div>
      <form [formGroup]="changePasswordForm">
        <div class="form-container">
          <app-custom-input-group 
            label="{{'exist_password'|translate}}"
            formControlName="currentPassword"
            type="password"
            [required]="isRequiredField('currentPassword')"
          ></app-custom-input-group>
        </div>
        <div class="new_reenter-password">
        <div class="form-container">
          <app-custom-input-group 
            label="{{'new_password'|translate}}"
            formControlName="newPassword"
           type="password"
            [required]="isRequiredField('newPassword')"
          ></app-custom-input-group>
          <p *ngIf="changePasswordForm.get('newPassword').hasError('pattern') && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched)" class="error-text"> {{'invalid_password' | translate}}</p>
          <p *ngIf="isSameAsOldPin()" class="error-text"> {{'Your_new_password_must_be_different_from_the_previously_used_password.' | translate}}</p>
        </div>
        <div class="form-container">
          <app-custom-input-group 
            label="{{'confirm_password'|translate}}"
            formControlName="confirmPassword"
            type="password"
            [required]="isRequiredField('confirmPassword')"
          ></app-custom-input-group>
        </div>
      </div>
      <p class="info-text"> {{'password_policy_info' | translate}}</p>
      </form>
    </div>
  </div>
  <div class="footer">
    <button class="primary-button" (click)="changePassword()"
      [disabled]="changePasswordForm.invalid" *ngIf="extAuth">{{'btn_update'|translate}}</button>
    <button class="primary-button" (click)="changePin()"
      [disabled]="changePasswordForm.invalid" *ngIf="!extAuth">{{'btn_update'|translate}}</button>
  </div>
</div>