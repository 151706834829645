import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodataComponent } from './nodata.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { FormInputErrorModule } from '../form-input-error/form-input-error.module';


@NgModule({
  declarations: [NodataComponent],
  imports: [
    CommonModule, PipesModule, FormsModule, ReactiveFormsModule, FormInputErrorModule, CustomInputGroupModule
  ],
  exports:[ NodataComponent]
})
export class NodataModule { }
