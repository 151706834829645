<div id="feedback">
  <div class="card">
    <div class="header border-bottom">
      <div class="identity">
        <div class="icon">
          <img src="assets/icons/feedback_star.svg" />
        </div>
        <div class="font-default-title title">{{'lbl_feedback'|translate:'LABELS'}}</div>
      </div>
    </div>
    <div class="list">
      <app-list-item className="no-image flex-items">
        
        <span class="title">{{'hme_lbl_general_feedback'|translate:'LABELS'}}</span>
        <span class="secondary-content">
          <span>{{'hme_lbl_feedback_msg'|translate:'LABELS'}}</span>
        </span>
        <span class='list-action cursor-pointer' >
          <button class="secondary-font-primary-button btn-small" (click)="openFeedback()">
            <img src="assets/icons/star.svg" />
            <span class="font-primary-default">{{'hme_lbl_leave_feedback'|translate:'LABELS'}}</span>
          </button>
        </span>
      </app-list-item>
    </div>
  </div>
</div>
