import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-referral-actions',
  templateUrl: './referral-actions.component.html',
  styleUrls: ['./referral-actions.component.scss']
})
export class ReferralActionsComponent implements OnInit {
  @Input() referralDetail: any;
  @Output() referralAction: EventEmitter<any>;
  public patientDetail: any = {};
  public availableActions: Array<any>=[]
  constructor(private user: UserService) {
    this.referralAction = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.getPatientDetail();
    this.initAvailableActions()
  }

  private getPatientDetail() {
    let uhid = this.referralDetail.uhid;
    if (uhid) {
      let patient = this.user.findLinkedPatient(uhid);
      console.log(patient)
      if (patient) {
        this.patientDetail = patient;
      }
    }
  }

  private initAvailableActions() {
    this.availableActions = [
      {
        desc: 'cal_btn_book_new_appt',
        code: 'BOOK_APPT'
      }
    ]
  }

  public openAction(action) {
    this.referralAction.emit({
      action: action.code,
      referral: this.referralDetail
    })
  }

}
