<div id="appointment-recent">
  <div class="search-section border-bottom">
    <div class="search" id="search-static" (click)="completeSelection(true)">
      <img src="./assets/icons/Book_new/search.svg">
      <input type="text" placeholder="Search facility / speciality / partitoner">
    </div>
  </div>
  <div class="recent-doctors">
    <div class="header">
      <div class="title">Recent doctors</div>
      <div class="actions">View all doctors</div>
    </div>
    <div class="content">
      <div class="card-layout" *ngFor="let item of [79,80,81,82,83]">
        <div class="card column">
          <div class="image">
            <img class="card-image" [src]="'https://randomuser.me/api/portraits/men/'+item+'.jpg'">
          </div>
          <div class="card-title">Dr Farrokh Hashemi</div>
          <div class="card-subtitle">Cardiology</div>
        </div>
      </div>
    </div>
  </div>
  <div class="recent-doctors">
    <div class="header">
      <div class="title">Our famous specialities</div>
      <div class="actions">View all specialities</div>
    </div>
    <div class="content">
      <div class="card-layout" *ngFor="let item of [79,80,81,82,83]">
        <div class="card simple">
          <div class="card-title text-center">Cardiology</div>
        </div>
      </div>
    </div>
  </div>
  <div class="recent-doctors">
    <div class="header">
      <div class="title">Our popular facilities</div>
      <div class="actions">View all facilities</div>
    </div>
    <div class="content">
      <div class="card-layout" *ngFor="let item of [79,80,81,82,83]">
        <div class="card simple">
          <div class="card-title">CURI hospitals</div>
          <div class="card-subtitle">Chennai</div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="content">
      <div class="primary-font-small">Not sure? Tell us more about your problem</div>
      <div class="secondary-font-small">Find the specialist of your problem by filling some questionnaire</div>
    </div>
    <div class="action">
      <button class="primary-button">Check your symptoms</button>
    </div>
  </div>
</div>
