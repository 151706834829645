import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentPreviewComponent } from './document-preview.component';
import { DirectiveModule } from '../directive/directive.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    DocumentPreviewComponent
  ],
  imports: [
    CommonModule, DirectiveModule, PdfViewerModule
  ],
  exports: [
    DocumentPreviewComponent
  ]
})
export class DocumentPreviewModule { }
