<div class="longtext" id="longtext">
    <!-- <p class="lngText-label">{{ section.question[0].text || section.domainName}} 
      <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
    </p>
    <div class="ui input fluid focus lngTxt-aria">
        <textarea class="lngTxt" [placeholder]="section.type === 'addendum' ? 'Enter Addendum notes' : '' " (keyup)="changeInText($event.target.value)" [(ngModel)]="section['selected']" type="text"></textarea>
    </div> -->
    <div class="input-group text-input" [ngClass]="{valid: section['selected']}">
      <div class="label">
        <label for="inputId" class="input-label">
          <span *ngIf="section['codify'] == 'Y'">{{section['domainNameLocale'] || section['domainName'] | translate:'LOCALE':'text' }}</span> <span *ngIf="section['codify'] != 'Y'">{{section['question'] | translate:'LOCALE':'text' }}</span>
          <sup *ngIf="section['mandatory']=='true'" style="color: red">*</sup>
        </label>
      </div>
      <div class="value">
        <textarea class="lngTxt" [placeholder]="section.type === 'addendum' ? 'Enter Addendum notes' : '' "
          [tabindex]="tabindex"
          (keyup)="changeInText($event.target.value)" [(ngModel)]="section['selected']" type="text"></textarea>
      </div>
    </div>
    <div *ngIf="section['templates']" class="ui fluid opt-con">
        <select  class="opt-sel" [(ngModel)]="section['selected']" >
          <option  value="">{{'select_a_template' | translate}}</option>
          <option *ngFor="let item of section['templates']" value="{{item['content']}}">{{ item['title'] }}</option>
        </select>
      </div>
    <div class="mandatory right" *ngIf="((section['mandatory']=='true') && (section['selected']==''))">
        <p>{{'form_field_mandatory' | translate}}</p>
    </div>
</div>