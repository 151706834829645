
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { EventsService } from '../services/events.service';
import { Location } from '@angular/common';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ROUTE_LOGIN } from '@string';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  providers: [EventsService]
})
export class AlertModalComponent {
  @Input() context:any;
  public isErrorModal: boolean = true;
  public modalType = 'Session_Expired'
  constructor(public events:EventsService,public location:Location
    ,private user:UserService,private auth: AuthService,private router:Router) {

  }

  ngOnInit(): void {
  }

  public login() {
     this.user.getAutoSignon().subscribe(autoSignon => {
       if (autoSignon) {
         this.auth.initSSORedirection({})
       } else {
         this.router.navigate([`${ROUTE_LOGIN}`], { queryParams: this.context })
       }
     }, err => {
       this.router.navigate([`${ROUTE_LOGIN}`], { queryParams: this.context })
     })
  }
}
