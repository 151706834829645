import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import * as AppConstant from '../app.string';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OnspotConsultationService {
  constructor(private http: HttpClient, private user: UserService) { 
  }

  public getConceptDetailsForDirective(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SERVICES}/getConceptDetailsForDirective`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public recordForm(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SERVICES}/recordForm`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public recordFormForOnspot(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SERVICES}/recordFormForOnspot`, payload, { reportProgress: true }).subscribe((success) => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getTeamStatusForConcept(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/getTeamStatusForConcept`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getTaskInfo(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/getTaskInfo`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getTaskStatusForVC(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_WORKFLOW}/getTaskStatusForVC`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public cancelTask(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/cancelEvent`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public updateTaskStatus(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_ACTIVITY}/updateTaskStatus`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getAppointmentsByRefNo(payload: any): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getAppointmentsByRefNo`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    if (error.error && error.error.err && error.error.err.message) {
      return error.error.err.message
    } else if (error.error && error.error.message) {
      return error.error.message
    }
    return 'Something went wrong';
  }

}
