import { trigger, transition, style, animate, query, stagger, keyframes } from '@angular/animations';

export const staggerListAnimation =
    trigger('listAnimation', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), { optional: true }),

            query(':enter', stagger('100ms', [
                animate('0.2s ease-in-out', keyframes([
                    style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
                ]))]), { optional: true })
        ])
    ])