<div class="health-package-preview-wrapper">
  <p class="home-section-subtitle">
    <span class="title-label">
      {{'Health_packages' | translate}} 
    </span>
    <span class="see-all"
      (click)="openPackages()">
      {{'See_all' | translate}}
      <img class="title-icon" src="assets/icons/slot/right_arrow.svg" alt="arrow">
    </span>
  </p>
  <div class="cards">
    <div class="card-layout" *ngFor="let c of packs.concepts">
      <div class="card row cursor-pointer" (click)="openPackages(c)">
        <div class="image">
          <ng-container  *ngIf="c.detail | translate : 'LANGARRAY' : 'imageURL'; else DFLTIMG">
            <img class="type-image" [src]="c.detail | translate : 'LANGARRAY' : 'imageURL'" alt="">
          </ng-container>
          <ng-template #DFLTIMG>
            <img class="type-image" src="assets/icons/health-package/healthpack_dflt.png" alt="">
          </ng-template>
        </div>
        <div class="card-content font-default-subtitle">
          <div>
            <p class="health-package-title">{{c.name | translate : 'LANGARRAY' : 'name'}}</p>
          </div>
          <div class="font-primary-subtitle bold clmn">
            <span *ngIf="getDiscountChargeByFacility(c)">{{getDiscountChargeByFacility(c)}} {{currency_code}} </span>
            <span *ngIf="getChargeByFacility(c)" [ngClass]="{ 'lstrike' : getDiscountChargeByFacility(c) }">{{getChargeByFacility(c)}}
              {{currency_code}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>