<div id="list-facility-component">
    <div class="header border-bottom spac-btw">
        <div class="flex">
            <div (click)="closeModal('BACK')" class="float-left closeicon pointer">
                <img src="./assets/icons/arrow.svg">
            </div>
            <div class="font-primary-title bold">{{ 'title_select_facility'|translate:'LABELS'}} </div>
        </div>
        <div (click)="closeModal('CLOSE')" class="float-right closeicon pointer">
            <img src="./assets/icons/close_black.svg">
        </div>
    </div>
    <div class="modal-fac-content">
        <div class="cards">
            <div class="card-layout" *ngFor="let item of facilityList">
                <div class="card row cursor-pointer" (click)="completeSelection(item)">
                    <input  type="radio" 
                    [id]="'random'+item['facilityId']" 
                    [name]="'random'">
                    
                    <!-- <div class="card-content"> -->
                    <label class="card-content"  [for]="'random'+item['facilityId']">
                        <div class="font-default-title">{{item['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name' }}</div>
                        <div class="font-ternary-subtitle hide">{{(item['facilityId']|facilityDetails:'facilityAddress')| translate : 'LOCALE' : 'text'}}</div>
                    </label>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer ">
        <div class="btn-container">
            <button class="primary-button btn-customize" (click)="continue()" [disabled]="!selectedFacility">{{'btn_continue'|translate}}</button>
        </div>
    </div>
</div>
