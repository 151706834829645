import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormControlGenerateService } from '../services/form-control-generate.service';
import { RATINGARRAY } from '@const';

@Component({
  selector: 'app-nps-rating',
  templateUrl: './nps-rating.component.html',
  styleUrls: ['./nps-rating.component.scss']
})
export class NpsRatingComponent {
  public rating: any
  public ratingForm: FormGroup;
  public formInitialised: boolean = false;
  @Input() minRatingMandaroty: number = 3;
  @Output() close: EventEmitter<any>;

  constructor(private formService: FormControlGenerateService, private cdf: ChangeDetectorRef) { 
    this.close = new EventEmitter<any>()
  }

  ngOnInit() {
    this.initForm()
  }

  private initForm() {
    this.rating = [
      {
        type: 'group',
        name: 'basic',
        title: '',
        child: [
          { type: 'control', name: 'rating', value: null, validators: [Validators.required], input: 'rating', title: 'NPSRATING#FORM#LABEL#RATING', radioOptions: { name: 'name', value: 'value', selectedicon: 'selectedicon', unselectedicon: 'unselectedicon', image: true }, radioList: RATINGARRAY, addclass: 'label-text-center' },
          { type: 'control', name: 'comments', value: '', validators: null, input: 'textarea', title: 'NPSRATING#FORM#LABEL#COMMENTS' }
        ]
      }
    ]
    this.ratingForm = this.formService.generateForm(this.rating);
    this.ratingForm.valueChanges.subscribe(data => {
      console.log(data)
      if (data.basic.rating && Number(data.basic.rating) <= this.minRatingMandaroty) {
        this.ratingForm.get(['basic', 'comments']).setValidators([Validators.required])
      } else {
        this.ratingForm.get(['basic', 'comments']).setValidators(null)
      }
      this.cdf.detectChanges()
    })
    this.formInitialised = true;
  }

  public isRequiredField(group: string, field: string) {
    let form_field = this.ratingForm.get([group, field]);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  getError(group: string, field: string) {
    if (this.isRequiredField(group, field)) {
      if (this.ratingForm.controls[group]['controls'][field] && this.ratingForm.controls[group]['controls'][field].errors && (this.ratingForm.controls[group]['controls'][field].dirty || this.ratingForm.controls[group]['controls'][field].touched))
        return true;
      return false;
    } else
      return false;
  }
  getErrorValidation(group: string, field: string) {
    console.log('getErrorValidation', group, field)
    return this.ratingForm.controls[group]['controls'][field]
  }

  public skip() {
    console.log(this.ratingForm)
    this.close.emit({ action: 'SKIP' })
  }

  public submit() {
    if (this.ratingForm.invalid) {
      return;
    }
    let values = {
      rating: this.ratingForm.get(['basic', 'rating']).value,
      comments: this.ratingForm.get(['basic', 'comments']).value,
      source: "PXPORTAL"
    }
    this.close.emit({ action: 'SUBMIT', values: values })
  }
}
