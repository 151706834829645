<div id="appointment-preview">
  <div class="card-container">
    <div class="card" *ngIf="langSet">
      <div class="header">
        <div class="logo">
          <img class="logo type-image" src="assets/images/signin/logo_grey.png" alt="">
        </div>
      </div>
      <div class="appt-content">
        <div class="alert" *ngIf="exceedRescheduleWindow || exceedCancelWindow">
          <img class="type-image" src="assets/icons/appt-preview/info.svg" />
          <ng-container *ngIf="exceedCancelWindow && !exceedRescheduleWindow">
            {{'alert_cancel_window_exceed'|translate : 'LABELS' : null : curLang}}
          </ng-container>
          <ng-container *ngIf="exceedRescheduleWindow && !exceedCancelWindow">
            {{'alert_reschedule_window_exceed'|translate : 'LABELS' : null : curLang}}
          </ng-container>
          <ng-container *ngIf="exceedRescheduleWindow && exceedCancelWindow">
            {{'alert_reschedule_cancel_window_exceed'|translate : 'LABELS' : null : curLang}}
          </ng-container>
        </div>
        <div class="content" *ngIf="appointment && state != 'INVALID'">
          <div class="appt-confirm" *ngIf="appointment.confirmed == 'true'">
            <p class="appt-confirm-info">
              <img class="type-image" src="assets/icons/infoicon.svg">
              <span class="appt-confirm-label">{{'appt_dtl_appointment_info' | translate : 'LABELS' : null : curLang}}</span>
            </p>
          </div>
          <div class="appointment-details">
            <p class="font-default-small bold">{{'Appointment_details' | translate : 'LABELS' : null : curLang}}</p>
            <div class="details-row pract-patient">
              <div class="pract-details detail-column">
                <div class="pract-avatar">
                  <app-practitioner-avatar [url]="(appointment['resourceId']|partitionerDetails:
                      appointment['facilityId']:'resourceAvatarURL')">
                  </app-practitioner-avatar>
                </div>
                <div class="pract-name-designation">
                  <div class="pract-name">
                    <ng-container *ngIf="appointment.practitionerLocalName">
                      {{appointment.practitionerLocalName | translate : 'LOCALE' : 'name' : curLang}}
                    </ng-container>
                    <ng-container *ngIf="!appointment.practitionerLocalName && appointment.practitionerName">
                      {{appointment.practitionerName}}
                    </ng-container>
                    <ng-container *ngIf="!appointment.practitionerLocalName && !appointment.practitionerName">
                      {{(appointment['resourceId']|partitionerDetails:appointment['facilityId']:'resourceName'| translate : 'LOCALE' : 'name': curLang) || 'Doctor'}}
                    </ng-container>
                  </div>
                  <div class="pract-designation font-light-small">
                    {{(appointment['resourceId']|partitionerDetails: appointment['facilityId']:'specialityId') | specialityDetails: appointment['facilityId']:'specialityName'| translate : 'LOCALE' : 'name': curLang}}
                  </div>
                </div>
              </div>
              <div class="pat-details detail-column">
                <div class="pat-avatar">
                  <app-person-avatar [url]="appointment['imageurl']"> </app-person-avatar>
                </div>
                <div class="pat-name-relation">
                  <div class="pat-name">
                    {{appointment['personName'] ? appointment['personName']: ''}}
                  </div>
                </div>
              </div>
            </div>
            <div class="details-row location-time">
              <div class="time-details detail-column">
                <div class="time-icon">
                  <img class="type-image" src="assets/icons/calendar/calendar(4).svg">
                </div>
                <div class="time-data">
                  {{appointment['appointmentDateTime'] | moment : 'ddd DD MMM YYYY, hh:mm a':'YYYY-MM-DD HH:mm'}},
                </div>
              </div>
              <div class="location-details detail-column">
                <div class="location-icon">
                  <ng-container *ngIf="appointment['appointmentType'] === 'VIDEO'">
                    <img class="type-image" src="assets/icons/calendar/app_type copy 4.svg">
                  </ng-container>
                  <ng-container *ngIf="appointment['appointmentType'] !== 'VIDEO'">
                    <img class="type-image" src="assets/icons/pin.svg">
                  </ng-container>
                </div>
                <div class="location-data">
                  <ng-container *ngIf="appointment['appointmentType'] === 'VIDEO'">
                    {{'cal_modal_video_consultation'|translate: 'LABELS' : null : curLang}}
                  </ng-container>
                  <ng-container *ngIf="appointment['appointmentType'] == 'HC' && appointment['homeCareServices'] && appointment['homeCareServices']['location']">
                    {{appointment['homeCareServices']['location']['address'] }}
                  </ng-container>
                  <ng-container
                    *ngIf="appointment['appointmentType'] !== 'VIDEO' && !(appointment['appointmentType'] == 'HC' && appointment['homeCareServices'] && appointment['homeCareServices']['location'])">
                    {{appointment['facilityId'] | facilityDetails : 'facilityName'| translate : 'LOCALE' : 'name': curLang }}
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="appointment-actions">
            <ng-container *ngIf="canCancelAppt">
              <button class="primary-button-outline"
                (click)="confirmCancel()">{{'Cancel_appointment' | translate : 'LABELS' : null : curLang}}</button>
            </ng-container>
            <ng-container *ngIf="canRescheduleAppt">
              <button class="primary-button-outline"
                (click)="confirmReschedule()">{{'Reschedule_appointment' | translate : 'LABELS' : null : curLang}}</button>
            </ng-container>
            <div class="view-appt-details cursor-pointer" (click)="navigateToPortal()">
              {{'View_appointment_details' | translate : 'LABELS' : null : curLang}}
              <img class="type-image preview-icon" src="assets/icons/appt-preview/arrow.svg" /></div>
          </div>

        </div>
        <div class="content invalid-content" *ngIf="state == 'INVALID'">
          <img class="cursor-pointer" src="./assets/icons/appt-preview/alert.svg">
          <p class="bold">{{'invalid_appt' | translate : 'LABELS' : null : curLang}}</p>
          <p>{{'invalid_appt_assistance' | translate : 'LABELS' : null : curLang}} <span
              class="call-us cursor-pointer font-primary-small"
              (click)="call()">{{"Call_us" | translate : 'LABELS' : null : curLang}}</span></p>
        </div>
        <div class="footer" *ngIf="appointment && state != 'INVALID'">
          <p class="font-default-small bold">{{'cal_modal_appt_guidelines'|translate: 'LABELS' : null : curLang}}</p>
          <p>{{'cal_modal_appt_guidelines_brief' |translate: 'LABELS' : null : curLang}}</p>
          
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isCancelModal">
  <div class="modal-container">
    <div class="modal-content">
      <div class="card">
        <div class="header">
          <div class="font-default-small bold">{{'Cancel_appointment' | translate : 'LABELS' : null : curLang}}</div>
          <img (click)="confirmClose(false)" class="cursor-pointer" src="./assets/icons/close_black.svg">
        </div>
        <div class="content">
          <div class="font-default-small">{{'cancel_confirm_message' | translate : 'LABELS' : null : curLang}}</div>
        </div>
        <div class="footer">
          <button class="footer-button secondary-action"
            (click)="confirmClose(false)">{{'No' | translate : 'LABELS' : null : curLang}}</button>
          <button class="footer-button primary-action"
            (click)="confirmClose(true)">{{'cancel_confirm' | translate : 'LABELS' : null : curLang}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isCancelSuccessModal">
  <div class="modal-container">
    <div class="modal-content">
      <div class="card">
        <div class="header only-close">
          <img (click)="confirmSuccessClose()" class="cursor-pointer" src="./assets/icons/close_black.svg">
        </div>
        <div class="content direction-column">
          <img class="cursor-pointer" src="./assets/icons/appt-preview/check.svg">
          <div class="font-default-small">{{'cancel_success_message' | translate : 'LABELS' : null : curLang}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isRescheduleSuccessModal">
  <div class="modal-container">
    <div class="modal-content">
      <div class="card">
        <div class="header only-close">
          <img (click)="confirmRescheduleClose()" class="cursor-pointer" src="./assets/icons/close_black.svg">
        </div>
        <div class="content direction-column">
          <img class="cursor-pointer" src="./assets/icons/appt-preview/check.svg">
          <div class="font-default-small">{{'reschedule_success_message' | translate : 'LABELS' : null : curLang}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isRescheduleModal">
  <app-reschedule [appointment]="appointment" [patient]="patient" (close)="closeReschedule($event)"></app-reschedule>
</ng-container>